import React, { Children, useRef } from 'react'

import { useVirtualizer } from '@tanstack/react-virtual'

const defaultElementHeight = 47
const MenuList = ({
	maxHeight,
	children,
}: {
	maxHeight: number
	children: React.ReactNode
}) => {
	const elements: React.ReactNode[] = Children.toArray(children)
	const listContainerRef = useRef<HTMLDivElement>(null)

	const rowVirtualizer = useVirtualizer({
		count: elements.length,
		getScrollElement: () => listContainerRef.current,
		estimateSize: () => defaultElementHeight,
		overscan: 5,
	})
	const virtualRows = rowVirtualizer.getVirtualItems()
	const totalSize = rowVirtualizer.getTotalSize()

	const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
	const paddingBottom =
		virtualRows.length > 0
			? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
			: 0

	return (
		<div
			className='overflow-y-auto'
			ref={listContainerRef}
			style={{
				height: Math.min(rowVirtualizer.getTotalSize(), maxHeight),
				width: '100%',
			}}
		>
			{paddingTop > 0 && <div style={{ height: `${paddingTop}px` }} />}
			{virtualRows.map((virtualRow) => {
				return (
					<div
						key={virtualRow.key}
						data-index={virtualRow.index}
						ref={rowVirtualizer.measureElement}
					>
						{elements[virtualRow.index]}
					</div>
				)
			})}
			{paddingBottom > 0 && <div style={{ height: `${paddingBottom}px` }} />}
		</div>
	)
}
export default MenuList
