import intl from '@/locale'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { DonutViewDataItem } from './types'

function formatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	return `<div class="chart-tooltip">${self.point.name}: <strong>${formatNumber(
		self.point.y as number,
		NumberFormats.Percent
	)}</strong></div>`
}

export const getChartConfig = ({
	height = 190,
	data,
}: {
	height?: number
	data: DonutViewDataItem[]
}): Highcharts.Options => {
	return {
		chart: {
			height,
			backgroundColor: undefined,
			type: 'pie',
			animation: true,
			spacingTop: 0,
			spacingRight: 0,
			spacingBottom: 0,
			spacingLeft: 0,
			plotBorderWidth: 0,
			marginRight: 0,
			marginLeft: 0,
			marginTop: 0,
			marginBottom: 0,
		},
		tooltip: {
			outside: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter,
		},
		series: [
			{
				type: 'pie',
				animation: false,
				name: intl.get('status_panel_products_to_reprice'),
				innerSize: '60%',
				point: {
					events: {
						click: (event) => {
							event.preventDefault()
						},
					},
				},
				data,
			},
		],
	}
}
