import clsx from 'clsx'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import {
	Avatar,
	Badge,
	Col,
	FormGroup,
	FormLabel,
	Required,
	Row,
} from '@cmpkit/base'

import { DataOption } from '@/common.types'
import DateInput from '@/components/DateInput/DateInput'
import {
	RHFAsyncMultiSelect,
	RHFSwitch,
	RHFTextField,
} from '@/components/HookForm'
import { ScheduleModel } from '@/generated'
import intl from '@/locale'

import { weekdays } from './utils'

type FormData = Omit<ScheduleModel, 'id' | 'nearest_date'>
export default function Settings({
	methods,
	users,
}: {
	methods: UseFormReturn<FormData>
	users: DataOption[]
}) {
	return (
		<Row>
			<Col sm={6}>
				<RHFTextField
					required
					name='name'
					label={intl.get('schedule_form_name')}
					placeholder='Configuration 1'
				/>
				<h3>{intl.get('general_when')}</h3>
				<div className='flex flex-col'>
					<Controller
						name='start_date'
						control={methods.control}
						render={({ field: { value, onChange } }) => {
							return (
								<FormGroup>
									<FormLabel>
										{intl.get('general_date')} <Required />
									</FormLabel>
									<DateInput
										value={value}
										onChange={onChange}
										className='w-32'
									/>
								</FormGroup>
							)
						}}
					/>
					<FormGroup>
						<FormLabel>{intl.get('general_time')}</FormLabel>
						<Badge variant='info'>
							{intl.get('schedule_after_integration')}
						</Badge>
					</FormGroup>
					<RHFSwitch name='repeat' label={intl.get('general_repeat')} />
				</div>
				<div
					className={clsx('flex flex-col space-y-2', {
						'pointer-events-none opacity-50': !methods.watch('repeat'),
					})}
				>
					<Controller
						name='repeat_days'
						control={methods.control}
						render={({ field: { value, onChange } }) => {
							return (
								<div>
									<WeekdayPickerField value={value || []} onChange={onChange} />
								</div>
							)
						}}
					/>
					<RHFTextField
						type='number'
						name='repeat_week_interval'
						label={intl.get('general_repeat_every')}
						addonAfter={intl.get('general_weeks')}
					/>
				</div>
			</Col>
			<Col sm={6}>
				<h3>{intl.get('notif')}</h3>
				<p>{intl.get('autorun_notif_users_hint')}</p>
				<RHFAsyncMultiSelect
					name='notify_users'
					options={users}
					placeholder={intl.get('select_users')}
					formatOptionLabel={(data) => (
						<div className='flex items-center space-x-1'>
							<Avatar size='small' className='rounded-full' name={data.label} />
							<span>{data.label}</span>
						</div>
					)}
				/>
			</Col>
		</Row>
	)
}

type WeekDaysFieldProps = {
	value: number[]
	onChange: (value: number[]) => void
}
function WeekdayPickerField({ value, onChange }: WeekDaysFieldProps) {
	const handleToggleItem = (id: number) => () =>
		onChange(
			value?.includes?.(id) ? value.filter((i) => i != id) : [...value, id]
		)
	return (
		<div className='flex items-center gap-1'>
			{weekdays.map((day, i) => (
				<button
					type='button'
					data-state={value?.includes(i) ? 'on' : 'off'}
					className={clsx(
						'focus-visible:ring-ring inline-flex cursor-pointer items-center justify-center rounded-md border-none bg-transparent px-2 py-1 text-sm font-medium transition-colors hover:bg-accent-3 hover:text-muted focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-accent-4 data-[state=on]:text-foreground',
						value?.includes(i) && 'segment-toggle__item--active'
					)}
					onClick={handleToggleItem(i)}
					key={day}
				>
					{intl.get(day).substr(0, 3)}
				</button>
			))}
		</div>
	)
}
