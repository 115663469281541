import { formatDistanceToNow } from 'date-fns'
import { isNil } from 'lodash/fp'
import React, { forwardRef, useMemo, useState } from 'react'

import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'

import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretUpIcon from '@cmpkit/icon/lib/glyph/caret-up'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'
import { formatInTimeZone } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { CompetitiveDataPointExtended } from './types'

export default function PricesTable({
	rows,
	onToggleSerie,
	visibleOnChart,
}: {
	rows: CompetitiveDataPointExtended[]
	onToggleSerie: (label: string) => void
	visibleOnChart: string[]
}) {
	const [sorting, setSorting] = useState<SortingState>([])
	const columns = useMemo(
		() => getColumns({ onToggleSerie, visibleOnChart }),
		[visibleOnChart, onToggleSerie]
	)
	const table = useReactTable<CompetitiveDataPointExtended>({
		data: rows,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return (
		<table className='cmp-dt-table cmp-dt-table--divide-y w-full table-fixed text-xs'>
			<colgroup>
				<col width={'140px'} />
				<col width={'62px'} />
				<col width={'85px'} />
				<col width={'52px'} />
				<col width={'52px'} />
			</colgroup>
			<thead className='bg-accent-2 text-xs text-muted'>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<th
								key={header.id}
								className='overflow-hidden border-b p-2 text-left font-medium'
							>
								<div
									{...{
										className: header.column.getCanSort()
											? 'cursor-pointer select-none flex items-center'
											: '',
										onClick: header.column.getToggleSortingHandler(),
									}}
								>
									<span className='truncate'>
										{flexRender(
											header.column.columnDef.header,
											header.getContext()
										)}
									</span>
									{{
										asc: <CaretUpIcon />,
										desc: <CaretDownIcon />,
									}[header.column.getIsSorted() as string] ?? null}
								</div>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody className='divide-y divide-solid divide-base bg-accent-1'>
				{table.getRowModel().rows.map((row) => (
					<tr key={row.id} className='h-9'>
						{row.getVisibleCells().map((cell) => (
							<td key={cell.id} className='truncate px-2'>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}
const columnHelper = createColumnHelper<CompetitiveDataPointExtended>()
const getColumns = ({
	onToggleSerie,
	visibleOnChart,
}: {
	onToggleSerie: (label: string) => void
	visibleOnChart: string[]
}) => [
	columnHelper.accessor('competitor_name', {
		header: () => 'Name',
		cell: (info) => (
			<>
				<Tooltip content={intl.get('eastside_market_click_for_toggle')}>
					<Point
						color={info.row.original.color!}
						onChange={() => onToggleSerie(info.row.original.label)}
						checked={visibleOnChart?.includes(info.row.original.label)}
					/>
				</Tooltip>
				<MarketLabel item={info.row.original} />
			</>
		),
	}),
	columnHelper.accessor('price', {
		header: () => intl.get('app.price').d('Price'),
		cell: (info) => {
			const value = info.getValue()
			return value && !isNil(value) ? (
				<Tooltip content={formatNumber(value, NumberFormats.Fixed)}>
					<span>{formatNumber(value, NumberFormats.Fixed)}</span>
				</Tooltip>
			) : (
				'-'
			)
		},
	}),
	columnHelper.accessor('observed_on', {
		header: () => intl.get('general_period').d('Period'),
		cell: (info) => {
			const date = info.getValue()
			if (!date) {
				return 'No data'
			}
			return (
				<Tooltip content={formatInTimeZone(new Date(date), 'MMMM d yyyy p')}>
					<span>
						{formatDistanceToNow(new Date(date), { addSuffix: true })}
					</span>
				</Tooltip>
			)
		},
	}),
	columnHelper.accessor('availability', {
		header: () => intl.get('stock').d('Stock'),
		cell: (info) => {
			if (info.row.original.label === 'My price') {
				return '-'
			}
			return info.getValue() === 'InStock' ? (
				<CheckIcon className='fill-success' />
			) : (
				<CrossIcon className='fill-danger' />
			)
		},
	}),
	columnHelper.accessor('promo_type_id', {
		header: () => intl.get('promo').d('Promo'),
		cell: (info) => {
			if (info.row.original.label === 'My price') {
				return '-'
			}
			const value = info.getValue()
			// TODO: remove this hack in future PROD-4937
			return value === 'true' ? (
				<CheckIcon className='fill-success' />
			) : (
				<CrossIcon className='fill-danger' />
			)
		},
	}),
]
const MarketLabel = ({ item }: { item: CompetitiveDataPointExtended }) => {
	const link = item.url ? (
		<a className='truncate' target='_blanc' href={item.url}>
			{item.label}
		</a>
	) : (
		<span>{item.label}</span>
	)
	return <Tooltip content={item.label}>{link}</Tooltip>
}
type PointProps = {
	color: string
	onChange(checked: boolean): void
	checked?: boolean
}
const Point = forwardRef(
	(
		{ color, onChange, checked = false }: PointProps,
		ref: React.Ref<HTMLDivElement>
	) => (
		<div
			ref={ref}
			className={`color-dot mr-2 inline-block cursor-pointer rounded-lg ${!checked ? 'bg-accent-4' : ''}`}
			style={{ background: color }}
			onClick={() => onChange(!!checked)}
		/>
	)
)
