import clsx from 'clsx'
import React from 'react'

export function ListGroupItem({
	className,
	disabled,
	...props
}: {
	disabled?: boolean
} & React.HTMLAttributes<HTMLLIElement>) {
	const rootClassName = clsx(
		'list-group-item',
		{
			'list-group-item--disabled': disabled,
		},
		className
	)
	return <li className={rootClassName} {...props} />
}
export function ListGroupInner({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			className={clsx('flex items-center justify-between', className)}
			{...props}
		/>
	)
}
export function ListGroupStart({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return <div className={clsx('flex items-center', className)} {...props} />
}
export function ListGroupEnd({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div className={clsx('ml-auto flex items-center', className)} {...props} />
	)
}
export function ListGroupContent({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div className={clsx('mr-auto flex items-center', className)} {...props} />
	)
}
