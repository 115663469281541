import { TreeNodeType } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'

import {
	ControllerConfig,
	HasValueSignature,
	InitialValueSignature,
	UseSelectOptionsSignature,
	ValueState,
} from '../../types'
import { isObject } from '../../utils'
import FieldController from '../Controller'

export default class TreeSelectController extends FieldController {
	constructor(config: ControllerConfig) {
		super(config)
		this.useSelectOptions = config.useSelectOptions
		this.getInitialValue = config.getInitialValue || this.getInitialValue
		this.placeholder = config.placeholder
	}

	placeholder?: string

	useSelectOptions: () => UseSelectOptionsSignature<TreeNodeType>

	hasValue: HasValueSignature = ({ value }) =>
		Array.isArray(value) ? value.length > 0 : isObject(value)

	getInitialValue: InitialValueSignature = () => ({
		operation: Operators.ANY_OF,
		value: [],
	})

	formatLabel = (value: ValueState<string[]>) => {
		if (Array.isArray(value) && value.length > 0)
			return `${this.label} (${value.length})`
		return this.label
	}
}
