import React from 'react'

import {
	Button,
	Content,
	Header,
	Layout,
	SegmentGroup,
	SegmentGroupItem,
} from '@cmpkit/base'
import Drawer from '@cmpkit/drawer'
import { useDisclosure } from '@cmpkit/hooks'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import EyeOffIcon from '@cmpkit/icon/lib/glyph/eye-off'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'
import { ColumnsConfigController } from '@/tools/columns'

import { ColumnsList } from './ColumnsList'

type TableColumnsManagerProps = {
	columns: {
		key: string
		title: string
	}[]
	config: ColumnsConfigController
}
export default function TableColumnsManager({
	columns,
	config,
}: TableColumnsManagerProps) {
	const { isOpen, toggle: handlToggle, close: handleClose } = useDisclosure()
	return (
		<>
			<Tooltip
				content={intl.get('general_column_properties')}
				placement='top-end'
			>
				<Button iconBefore={<EyeOffIcon />} onClick={handlToggle} />
			</Tooltip>
			<Drawer
				className='cmp-border drawer-table-page'
				orient='right'
				isOpen={isOpen}
				disableFocusTrap
				disableBlanket
			>
				<Layout className='flex size-full flex-col overflow-hidden'>
					<Header className='relative flex flex-col px-5 pt-5'>
						<h3 className='text-xl font-bold'>
							{intl.get('app.show_columns')}
						</h3>
						<div className='absolute right-5 top-5'>
							<Button
								onClick={handleClose}
								variant='tertiary'
								iconBefore={<CrossIcon />}
							/>
						</div>
					</Header>
					<Content className='flex flex-col overflow-hidden px-0 pt-3'>
						<div className='mb-3 flex items-center justify-between px-5'>
							<Button
								onClick={config.restore}
								size='small'
								iconBefore={<UndoIcon />}
							>
								{intl.get('app.restore_to_default')}
							</Button>
							<div className='flex items-center space-x-2'>
								<span className='text-xs text-muted'>
									{intl.get('general_row_height')}:
								</span>
								<div className='flex'>
									<SegmentGroup size='small'>
										{sizes.map(({ value, label }) => (
											<SegmentGroupItem
												active={value === config.rowHeight}
												key={value}
												icon
												className='px-2'
												onClick={config.setRowHeight.bind(null, value)}
											>
												{label}
											</SegmentGroupItem>
										))}
									</SegmentGroup>
								</div>
							</div>
						</div>

						<ColumnsList columns={columns} config={config} />
					</Content>
				</Layout>
			</Drawer>
		</>
	)
}
const sizes = [
	{
		label: 'S',
		value: 34,
	},
	{
		label: 'M',
		value: 50,
	},
	{
		label: 'L',
		value: 80,
	},
]
