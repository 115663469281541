import { AxiosError } from 'axios'
import { find, map, orderBy, paths, pipe, prop, uniqBy } from 'lodash/fp'

import { useQuery } from '@tanstack/react-query'

import { DataChoice, FilterRuleEntity } from '@cmpkit/query-builder'

import {
	AlertInfoRequestModel,
	AlertsInfoResponseModel,
	BrandModel,
	CategoryModel,
	ColumnSchemaModel,
	CompetitorsResponseModel,
	Core,
	HTTPValidationError,
	ListBrandsParams,
	ListCategoriesParams,
	ListCompetitorsParams,
	ListInterpretationParams,
	MultipleAlertInfoRequestModel,
	MultipleAlertsInfoResponseModel,
	SalesEntitiesResponseData,
	StatisticsInterpretabilityRequest,
	StatisticsInterpretabilityResponse,
	StatisticsRepricingRequest,
	StatisticsRepricingResponse,
	StatisticsRequest,
	StatisticsResponse,
	StatisticsTargetProtectDistributionRequest,
	StopListSettingsModel,
	TargetProtectDistributionModel,
	TreeMapProductGroupModel,
	TreeMapRequestModel,
	TreeMapSalesEntityModel,
	V1StarboardMetricsCreateRequestModel,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'
import { toOption } from '@/tools/utils'

import {
	AlertAnnotationModel,
	OptimizationGroupModel,
	OptimizationModel,
	PreprocessingModel,
	ProductGroupModel,
	ReviewModel,
	ScheduleModel,
} from './types'

export function useMultiplePricingAlertsQuery<
	T = MultipleAlertsInfoResponseModel,
>(
	params: MultipleAlertInfoRequestModel,
	options?: UseQueryOptions<MultipleAlertsInfoResponseModel, Error, T>
) {
	return useQuery<MultipleAlertsInfoResponseModel, Error, T>({
		queryKey: ['multiple-pricing-alerts', params],
		queryFn: () => {
			return client.bi.getMultipleAlertsInfo(params)
		},
		...(options || {}),
	})
}
export function usePricingAlertsQuery<T = AlertsInfoResponseModel>(
	params: AlertInfoRequestModel,
	options?: UseQueryOptions<AlertsInfoResponseModel, Error, T>
) {
	return useQuery<AlertsInfoResponseModel, Error, T>({
		queryKey: ['pricing-alerts', params],
		queryFn: () => {
			return client.bi.getAlertsInfo(params)
		},
		...(options || {}),
	})
}
export function usePricingAlertsAnnotationsQuery<T = AlertAnnotationModel[]>(
	options?: UseQueryOptions<AlertAnnotationModel[], Error, T>
) {
	return useQuery<AlertAnnotationModel[], Error, T>({
		queryKey: ['pricing-alerts-annotations'],
		queryFn: () => {
			return client.core.listAlertsAnnotation()
		},
		...(options || {}),
	})
}
export function useReviewsQuery<T = ReviewModel[]>(
	options?: UseQueryOptions<ReviewModel[], Error, T>
) {
	return useQuery<ReviewModel[], Error, T>({
		queryKey: ['reviews'],
		queryFn: () => {
			return client.api.listReviews().then(prop('objects')) as Promise<
				ReviewModel[]
			>
		},
		...(options || {}),
	})
}
export function useOptimizationsQuery<T = Record<string, OptimizationModel>>(
	options?: UseQueryOptions<Record<string, OptimizationModel>, Error, T>
) {
	return useQuery<Record<string, OptimizationModel>, Error, T>({
		queryKey: ['optimizations'],
		queryFn: () => {
			return client.core
				.listOptimizations()
				.then(prop('optimizations')) as Promise<
				Record<string, OptimizationModel>
			>
		},
		...(options || {}),
	})
}
export function useSchedulesQuery<T = ScheduleModel[]>(
	options?: UseQueryOptions<ScheduleModel[], Error, T>
) {
	return useQuery<ScheduleModel[], Error, T>({
		queryKey: ['schedules'],
		queryFn: () => {
			return client.api.listSchedule()
		},
		...(options || {}),
	})
}
export function useOptimizationGroupsQuery<T = OptimizationGroupModel[]>(
	options?: UseQueryOptions<OptimizationGroupModel[], Error, T>
) {
	return useQuery<OptimizationGroupModel[], Error, T>({
		queryKey: ['optimization-groups'],
		queryFn: () => {
			return client.core
				.listOptimizationGroups()
				.then(prop('optimization_groups')) as Promise<OptimizationGroupModel[]>
		},
		...(options || {}),
	})
}
export function useOptimizationGroupQuery<T = OptimizationGroupModel>(
	optimizationGroupId: string,
	options?: UseQueryOptions<OptimizationGroupModel, Error, T>
) {
	return useQuery<OptimizationGroupModel, Error, T>({
		queryKey: ['optimization-groups', optimizationGroupId],
		queryFn: () => {
			return client.core.getOptimizationGroup(optimizationGroupId)
		},
		...(options || {}),
	})
}
export function useOptimizationGroup(
	optimizationGroupId: string
): OptimizationGroupModel {
	const { data: group } = useOptimizationGroupsQuery<OptimizationGroupModel>({
		select: find({ id: optimizationGroupId }) as (
			data: OptimizationGroupModel[]
		) => OptimizationGroupModel,
	})
	return group!
}
export function useProductGroupsQuery<T = ProductGroupModel[]>(
	options?: UseQueryOptions<ProductGroupModel[], Error, T>
) {
	return useQuery<ProductGroupModel[], Error, T>({
		queryKey: ['product-groups'],
		queryFn: () => client.core.listProductGroups(),
		...(options || {}),
		staleTime: 60 * 1000 * 60,
	})
}
export function useOptimizationQuery<T = OptimizationModel>(
	optimizationGroupId: string,
	options?: UseQueryOptions<OptimizationModel, Error, T>
) {
	return useQuery<OptimizationModel, Error, T>({
		queryKey: ['optimization', optimizationGroupId],
		queryFn: () => {
			return client.core
				.getOptimization(optimizationGroupId, 'last')
				.then(prop('optimization'))
		},
		...(options || {}),
	})
}
export function usePreproQuery<T = PreprocessingModel>(
	options?: UseQueryOptions<PreprocessingModel, Error, T>
) {
	return useQuery<PreprocessingModel, Error, T>({
		queryKey: ['prepro'],
		queryFn: () => {
			return client.core.getPreprocessing()
		},
		...(options || {}),
	})
}
export function useColumnsSchemaQuery<T = ColumnSchemaModel[]>(
	options?: UseQueryOptions<ColumnSchemaModel[], Error, T>
) {
	return useQuery<ColumnSchemaModel[], Error, T>({
		queryKey: ['schema'],
		queryFn: () => {
			return client.core.listColumnSchemas().then(prop('schema')) as Promise<
				ColumnSchemaModel[]
			>
		},
		...(options || {}),
	})
}
export const useCompetitorsOptionsQuery = <T = CompetitorsResponseModel>(
	params: ListCompetitorsParams,
	options?: UseQueryOptions<CompetitorsResponseModel, AxiosError, T>
) => {
	return useQuery<CompetitorsResponseModel, AxiosError, T>({
		queryKey: ['competitors', params],
		queryFn: () => client.cd.listCompetitors(params),
		...(options || {}),
	})
}
export const useExportTemplatesQuery = <
	T = {
		id: string
		name: string
		tier_ids: string[]
	},
>(
	options?: UseQueryOptions<
		{
			id: string
			name: string
			tier_ids: string[]
		}[],
		AxiosError,
		T
	>
) => {
	return useQuery<
		{
			id: string
			name: string
			tier_ids: string[]
		}[],
		AxiosError,
		T
	>({
		queryKey: ['export-templates'],
		queryFn: () => client.core.massExportTemplates().then(prop('objects')),
		...(options || {}),
	})
}

export const useCategoriesQuery = <T = CategoryModel[]>(
	queryParams: ListCategoriesParams,
	options?: UseQueryOptions<CategoryModel[], Error, T>
) => {
	return useQuery<CategoryModel[], Error, T>({
		queryKey: ['categories', queryParams],
		queryFn: () =>
			client.core
				.listCategories(queryParams)
				.then(prop('categories')) as Promise<CategoryModel[]>,
		...(options || {}),
	})
}
export const useDryCategoriesQuery = <T = CategoryModel[]>(
	options?: UseQueryOptions<CategoryModel[], Error, T>
) => {
	return useQuery<CategoryModel[], Error, T>({
		queryKey: ['dry-categories'],
		queryFn: () =>
			client.core.listCategoriesFull().then(prop('categories')) as Promise<
				CategoryModel[]
			>,
		...(options || {}),
	})
}

export const useBrandsQuery = <T = DataChoice[]>(
	queryParams: ListBrandsParams,
	options?: UseQueryOptions<BrandModel[], Error, T>
) => {
	return useQuery<BrandModel[], Error, T>({
		queryKey: ['brands', queryParams],
		queryFn: () =>
			client.core.listBrands(queryParams).then(prop('brands')) as Promise<
				BrandModel[]
			>,
		select: pipe([
			map(paths(['id', 'name'])),
			toOption,
			orderBy(['label'], ['asc']),
			uniqBy('value'),
		]),
		...(options || {}),
	})
}

export const useInterpretationQuery = (
	params: ListInterpretationParams,
	options?: UseQueryOptions<object[], HTTPValidationError>
) => {
	return useQuery<object[], HTTPValidationError>({
		queryKey: ['interpretation', params],
		queryFn: () => client.api.listInterpretation(params),
		staleTime: 60 * 1000 * 5,
		...(options || {}),
	})
}

// FIXME: Update query params type
export const useUnassignedProductsCountByOgQuery = (ogId: string) => {
	return useQuery({
		queryKey: ['new-products-count', ogId],
		queryFn: () =>
			client.products.listOgProducts(ogId, {
				pricing_campaign_id__in: 'null',
			} as any), // eslint-disable-line @typescript-eslint/no-explicit-any
		initialData: { data: [], success: true },
		select: ({ data }) => data?.length ?? 0,
	})
}

export const useInterpretabilityStatisticQuery = <
	T = StatisticsInterpretabilityResponse | null,
>(
	body: StatisticsInterpretabilityRequest,
	options?: UseQueryOptions<
		StatisticsInterpretabilityResponse | null,
		AxiosError<HTTPValidationError>,
		T
	>
) => {
	return useQuery<
		StatisticsInterpretabilityResponse | null,
		AxiosError<HTTPValidationError>,
		T
	>({
		queryKey: ['statistic-interpretability', body],
		queryFn: () => client.bi.getStatisticsInterpretability(body),
		...(options || {}),
	})
}
export const useRepricingStatisticQuery = <
	T = StatisticsRepricingResponse | null,
>(
	body: StatisticsRepricingRequest,
	options?: UseQueryOptions<
		StatisticsRepricingResponse | null,
		AxiosError<HTTPValidationError>,
		T
	>
) => {
	return useQuery<
		StatisticsRepricingResponse | null,
		AxiosError<HTTPValidationError>,
		T
	>({
		queryKey: ['statistic-repricing', body],
		queryFn: () => client.bi.getStatisticsRepricing(body),
		...(options || {}),
	})
}
export const useStatisticQuery = <T = StatisticsResponse>(
	body: StatisticsRequest,
	options?: UseQueryOptions<
		StatisticsResponse,
		AxiosError<HTTPValidationError>,
		T
	>
) => {
	return useQuery<StatisticsResponse, AxiosError<HTTPValidationError>, T>({
		queryKey: ['statistic', body],
		queryFn: () => client.bi.getStatistics(body),
		...(options || {}),
	})
}

export const useStopListSuggestsQuery = (
	ogId: string,
	settings: StopListSettingsModel
) => {
	return useQuery<
		Core.GetStopListMatchedProductsCounts.ResponseBody,
		Error,
		Core.GetStopListMatchedProductsCounts.ResponseBody
	>({
		queryKey: ['stop-list', ogId, settings],
		queryFn: () => client.core.getStopListMatchedProductsCounts(ogId, settings),
	})
}

type StarboardMetricsResponseModel = {
	/** @default 0 */
	gross_profit_init?: number
	/** @default 0 */
	gross_profit_opt?: number
	/** @default 0 */
	product_count?: number
	/** @default 0 */
	profit_margin_init?: number
	/** @default 0 */
	revenue_init?: number
	/** @default 0 */
	revenue_opt?: number
	/** @default 0 */
	sales_quantity?: number
	/** @default 0 */
	sold_items_cost?: number
}
export function useStarboardMetrics<T = StarboardMetricsResponseModel>(
	params: V1StarboardMetricsCreateRequestModel,
	options?: UseQueryOptions<StarboardMetricsResponseModel, AxiosError, T>
) {
	return useQuery<StarboardMetricsResponseModel, AxiosError, T>({
		queryKey: ['startboard-metrics', params],
		queryFn: () => client.starboard.v1StarboardMetricsCreate(params),
		...(options || {}),
	})
}

export function useSalesEntitiesQuery<T = SalesEntitiesResponseData>(
	options?: UseQueryOptions<SalesEntitiesResponseData, Error, T>
) {
	return useQuery<SalesEntitiesResponseData, Error, T>({
		queryKey: ['sales-points'],
		queryFn: () => client.core.getSalesEntities(),
		...(options || {}),
		staleTime: 60 * 1000 * 60,
	})
}
export function useOgTreeMapByProductGroupsQuery<
	T = TreeMapProductGroupModel[],
>(
	params: TreeMapRequestModel,
	options?: UseQueryOptions<TreeMapProductGroupModel[], Error, T>
) {
	return useQuery<TreeMapProductGroupModel[], Error, T>({
		queryKey: ['og-tree-map-product-groups', params],
		queryFn: () => client.bi.getOgTreeMapByProductGroups(params),
		...(options || {}),
		staleTime: 60 * 1000 * 60,
	})
}
export function useOgTreeMapBySalesEntitiesQuery<T = TreeMapSalesEntityModel[]>(
	params: TreeMapRequestModel,
	options?: UseQueryOptions<TreeMapSalesEntityModel[], Error, T>
) {
	return useQuery<TreeMapSalesEntityModel[], Error, T>({
		queryKey: ['og-tree-map-sale-entities', params],
		queryFn: () => client.bi.getOgTreeMapBySalesEntities(params),
		...(options || {}),
		staleTime: 60 * 1000 * 60,
	})
}
export const useStatisticsTargetProtectDistributionQuery = <
	T = TargetProtectDistributionModel[] | null,
>(
	body: StatisticsTargetProtectDistributionRequest,
	options?: UseQueryOptions<TargetProtectDistributionModel[] | null, Error, T>
) => {
	return useQuery<TargetProtectDistributionModel[] | null, Error, T>({
		queryKey: ['statistics-target-protect-distribution', body],
		queryFn: () => {
			return client.bi.getStatisticsTargetProtectDistribution(body)
		},
		...(options || {}),
	})
}

export const useOptimizationGroupsByProductFiltersQuery = <
	T = OptimizationGroupsByProductFiltersModel[] | null,
>(body: {
	filters: FilterRuleEntity[]
}) => {
	return useQuery<OptimizationGroupsByProductFiltersModel[] | null, Error, T>({
		queryKey: ['optimization-groups-by-product-filters', body],
		queryFn: async () => {
			const [optimizationGroupsByFilters, optimizations] = await Promise.all([
				client.util
					.getProductsCountsByOg(body)
					.then(map(prop('optimization_group_id'))),
				client.core.listOptimizations().then(prop('optimizations')),
			])
			return client.core
				.listOptimizationGroups()
				.then(prop('optimization_groups'))
				.then(
					(optimizationGroups) =>
						optimizationGroups
							?.filter(({ id }) => optimizationGroupsByFilters.includes(id))
							.map(({ id, name }) => ({
								id,
								name,
								status: optimizations?.[id]?.status || '',
								optimization: optimizations?.[id]?.id || '',
							})) as OptimizationGroupsByProductFiltersModel[]
				)
		},
	})
}
type OptimizationGroupsByProductFiltersModel = {
	id: string
	name: string
	status: string
	optimization: string
}
