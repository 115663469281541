import { prop } from 'lodash/fp'
import React from 'react'

import { Col, Row } from '@cmpkit/base'

import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useOptimizationGroupsQuery } from '@/modules/core/queries'

import { usePricingCampaignsQuery } from '../../queries'

type CheckoutProps = {
	formData: {
		campaigns: string[]
		groups: string[]
	}
}
function selectItemsIn<T = unknown>(selcted: string[]) {
	return (data: T[]): string[] =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data.filter(({ id }: any) => selcted.includes(id)).map(prop('name'))
}

function Checkout({ formData }: CheckoutProps) {
	const selectedCampaigns = formData.campaigns
	const selectedGroups = formData.groups
	const optimizationGroupId = useOptimizationGroupId()
	const pricingCampaignsQuery = usePricingCampaignsQuery<string[]>(
		{
			optimization_group_id: optimizationGroupId,
		},
		{
			select: selectItemsIn(selectedCampaigns),
		}
	)
	const optimizationGroupsQury = useOptimizationGroupsQuery<string[]>({
		select: selectItemsIn(selectedGroups),
	})

	return (
		<Row>
			<Col sm='6'>
				<h3>{intl.get('pc.reassign.want_to_reassign')}</h3>
				<ul className='ml-2 h-64 overflow-y-scroll'>
					{pricingCampaignsQuery.data?.map((name) => (
						<li key={name}>{name || 'None'}</li>
					))}
				</ul>
			</Col>
			<Col sm='6'>
				<h3>{intl.get('pc.copy.to_following_ogs')}:</h3>
				<ul className='ml-2 h-64 overflow-y-scroll'>
					{optimizationGroupsQury.data?.map((name) => (
						<li key={name}>{name || 'None'}</li>
					))}
				</ul>
			</Col>
		</Row>
	)
}

export default Checkout
