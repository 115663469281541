import { JSONSchemaType } from 'ajv'

import {
	getSchemaResolver,
	translateSchemaKeys,
} from '@/tools/json-schema-utils'

import { useSettingsSchemasQuery } from '../queries'
import { OptimizationGroupFormData } from '../types'

export const useOptimizationGroupSchemaQuery = () => {
	return useSettingsSchemasQuery<JSONSchemaType<OptimizationGroupFormData>>({
		select: (schemas) => {
			const resolver = getSchemaResolver(schemas)
			resolver.getDerefSchema('part:strategy')
			resolver.addSchema({
				$id: 'entity:optimization_group',
				type: 'object',
				required: ['name'],
				properties: {
					name: {
						type: 'string',
						minLength: 1,
					},
					description: {
						type: 'string',
					},
					sales_level_id: {
						type: 'number',
					},
					sales_entities: {
						type: 'array',
						items: {
							type: 'string',
						},
					},
					product_groups: {
						type: 'array',
						items: {
							type: 'string',
						},
					},
					strategy: {
						$ref: 'part:strategy',
					},
					business: {
						$ref: 'part:business',
					},
					stop_list: {
						$ref: 'part:stop_list',
					},
				},
			})
			return translateSchemaKeys(
				resolver.getDerefSchema('entity:optimization_group')
			)
		},
	})
}
