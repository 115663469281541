import React from 'react'

import { Loader } from '@cmpkit/base'

export default function SplashScreen() {
	return (
		<div className='splash-screen bg-gradient-to-br from-accent-1 to-accent-4'>
			<Loader />
		</div>
	)
}
