import React from 'react'
import { useForm } from 'react-hook-form'

import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Button } from '@cmpkit/base'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { FormProvider, RHFTextField } from '@/components/HookForm'
import intl from '@/locale'

type FormData = {
	name: string
}
const FormDataSchema = Yup.object().shape({
	name: Yup.string().min(2).max(500).required('Is required'),
})
function PricinCampaignEditNameModalContent({
	close,
	value,
	onChange,
}: {
	value?: string
	close: () => void
	onChange: (value: string) => void
}) {
	const onSubmit = (data: FormData) => {
		onChange(data.name)
		close()
	}
	const methods = useForm<FormData>({
		resolver: yupResolver<FormData>(FormDataSchema),
		defaultValues: {
			name: value,
		},
	})

	return (
		<Modal onClose={close} showCloseButton size='mini'>
			<FormProvider onSubmit={methods.handleSubmit(onSubmit)} methods={methods}>
				<ModalHeader>
					<ModalTitle>
						{intl.get('app.edit_pc_name').d('Edit pricing campaign name')}
					</ModalTitle>
				</ModalHeader>
				<ModalBody className='relative flex flex-col overflow-hidden'>
					<RHFTextField
						className='w-full'
						label={intl.get('general_name')}
						placeholder={intl.get('general_name')}
						name='name'
					/>
				</ModalBody>
				<ModalFooter className='justify-end space-x-2'>
					<Button onClick={close}>{intl.get('general_cancel')}</Button>
					<Button
						variant='primary-brand'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{intl.get('general_apply')}
					</Button>
				</ModalFooter>
			</FormProvider>
		</Modal>
	)
}

export default function PricinCampaignEditNameModal({
	isOpen,
	close,
	value,
	onChange,
}: {
	isOpen: boolean
	value?: string
	onChange: (value: string) => void
	close: () => void
}) {
	return (
		<ModalTransition>
			{isOpen && (
				<PricinCampaignEditNameModalContent
					value={value}
					onChange={onChange}
					close={close}
				/>
			)}
		</ModalTransition>
	)
}
