import { addMonths, format } from 'date-fns'
import { prop, sumBy } from 'lodash/fp'
import React from 'react'

import { Col, DeltaValue, Dot, Row } from '@cmpkit/base'
import { PALETTE } from '@cmpkit/theme'

import Chart from '@/components/Chart'
import intl from '@/locale'
import { useBIAnalyticQuery } from '@/modules/bi/queries'
import { zipSeries } from '@/modules/bi/utils'
import { getDifferenceInPercents } from '@/modules/dashboards/helpers'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { MainDashboardFilter } from '../../Filter'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetEmptyState from '../../WidgetEmptyState'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import {
	createRows,
	getStructuredData,
} from '../OptimizationHistoryTable/calculations'
import { WidgetProps } from '../types'

export default function OptimizationHistoryDonutWidget({
	widget,
	params,
	onChangeParams,
	commonFilters,
}: WidgetProps) {
	const filters = [...commonFilters, ...(params.filters ?? [])]
	const biQuery = useBIAnalyticQuery({
		label: widget.kind,
		start_date: format(addMonths(new Date(), -2), DateFormat.system),
		end_date: format(addMonths(new Date(), 1), DateFormat.system),
		metrics: ['assortment', 'average_price_change'],
		dimensions: ['optimization_lag', 'price_change_status'],
		filters: [
			...filters,
			{
				name: 'optimization_lag',
				operation: 'lte',
				value: 5,
			},
			{
				name: 'is_repricing_today',
				operation: 'bl',
				value: true,
			},
		],
		date_aggregation: null,
		skip_dates: true,
	})

	const data = createRows(
		getStructuredData(
			zipSeries(biQuery.data?.meta || [], biQuery.data?.data || [])
		)
	).map((row) => ({
		name: intl.get(`overview_price_${row.key}`).d(row.key),
		color: getColor(row.key),
		y: row.op1_assortment,
		prev: row.op2_assortment,
	}))
	const total = sumBy('y', data)
	const diffTotal = getDifferenceInPercents(sumBy('prev', data), total)

	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.last_repricing_sales_items.title')}
				tooltip={intl.get('widget.last_repricing_sales_items.tooltip')}
				subtitle={intl.get('app.based_on_last_repricing')}
			/>
			<WidgetContent>
				<div className='flex items-center'>
					<MainDashboardFilter
						irremovableCount={commonFilters.length}
						filters={filters ?? []}
						onChange={(filters) => {
							analytic.logEvent(`dashboard: bi: apply filter ${widget.kind}`, {
								'filter by': filters.map(prop('name')).join(','),
							})
							onChangeParams({ ...params, filters })
						}}
					/>
				</div>
				<WidgetLoadingState isTinted={biQuery.isLoading} />
				<WidgetErrorState isTinted={biQuery.isError} />
				{biQuery.data?.data?.length && !biQuery.isLoading ? (
					<Row>
						<Col sm={4}>
							<Chart config={getConfig({ data })} />
						</Col>
						<Col sm={8} className='flex items-center'>
							<div className='w-full space-y-2 text-xs'>
								<Row>
									<Col sm={4} className='font-bold'>
										{intl.get('app.total')}
									</Col>
									<Col sm={5} className='text-right font-bold'>
										{formatNumber(total)}
									</Col>
									<Col sm={3} className='flex items-center font-bold'>
										<DeltaValue value={diffTotal}>
											{formatNumber(diffTotal, NumberFormats.PercentSigned)}
										</DeltaValue>
									</Col>
								</Row>
								{data.map(({ name, color, y, prev }) => {
									const diff = getDifferenceInPercents(y, prev)
									const value = (y / total) * 100
									return (
										<Row key={name}>
											<Col sm={4} className='flex items-center space-x-2'>
												<Dot color={color} />
												<p>{intl.get(`overview_price_${name}`).d(name)}</p>
											</Col>
											<Col sm={5} className='text-right'>
												{!Number.isNaN(value)
													? formatNumber(value, NumberFormats.Percent)
													: 0}{' '}
												({formatNumber(y)})
											</Col>
											<Col sm={3} className='flex items-center'>
												{diff !== Infinity ? (
													<DeltaValue value={diff}>
														{formatNumber(diff, NumberFormats.PercentSigned)}
													</DeltaValue>
												) : null}
											</Col>
										</Row>
									)
								})}
							</div>
						</Col>
					</Row>
				) : (
					<WidgetEmptyState />
				)}
			</WidgetContent>
		</Widget>
	)
}

function formatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	return `<div class="chart-tooltip">${self.point.name}: <strong>${formatNumber(self.point.y as number)}</strong></div>`
}
const getColor = (name: string) => {
	switch (name) {
		case 'decreased':
			return PALETTE.orange[75]
		case 'increased':
			return PALETTE.green[75]
		case 'not_changed':
			return PALETTE.blue[75]
		case 'promo':
			return PALETTE.neutral[150]
		case 'locked':
			return PALETTE.neutral[200]
		default:
			return ''
	}
}
const getConfig = ({
	data,
}: {
	data: {
		name: string
		color: string
		y: number
		prev: number
	}[]
}): Highcharts.Options => {
	return {
		chart: {
			backgroundColor: undefined,
			height: 190,
			type: 'pie',
			animation: true,
			spacingTop: 0,
			spacingRight: 0,
			spacingBottom: 0,
			spacingLeft: 0,
			plotBorderWidth: 0,
			marginRight: 0,
			marginLeft: 0,
			marginTop: 0,
			marginBottom: 0,
		},
		tooltip: {
			outside: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter,
		},
		series: [
			{
				type: 'pie',
				animation: false,
				name: intl.get('status_panel_products_to_reprice'),
				innerSize: '60%',
				point: {
					events: {
						click: (event) => {
							event.preventDefault()
						},
					},
				},
				data,
			},
		],
	}
}
