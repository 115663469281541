import { propOr } from 'lodash/fp'
import { useMemo } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { PreferencesModel } from '@/generated'

import { useSaveFiltersPreferencesMutation } from '../mutations'
import { useFiltersPreferencesQuery } from '../queries'

export function useStoredFiltersKeys(
	preferenceKey: string
): [string[], (data: string[]) => void] {
	const queryClient = useQueryClient()

	/**
	 * Get saved quick filters keys from preferences
	 */
	const preferencesQuery = useFiltersPreferencesQuery<string[]>({
		select: propOr([], preferenceKey) as (data?: PreferencesModel) => string[],
	})
	const quickFiltersKeys = preferencesQuery.data || []

	/**
	 * Save quick filters keys to preferences
	 */
	const savePreferencesMutation = useSaveFiltersPreferencesMutation({
		// Optimistic update
		onMutate: ({ preferenceType, data }) => {
			queryClient.setQueryData(['preferences', 'filters'], {
				[preferenceType]: data,
			})
		},
		// Refresh data after mutation
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['preferences', 'filters'],
			})
		},
	})

	return useMemo(
		() => [
			quickFiltersKeys,
			(data: string[]) =>
				savePreferencesMutation.mutate({
					preferenceType: preferenceKey,
					data,
				}),
		],
		[quickFiltersKeys, savePreferencesMutation]
	)
}
