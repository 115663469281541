import { find } from 'lodash/fp'

import { useScenariosQuery } from '@/modules/scenarios/queries'
import { ScenarioModel } from '@/generated'

export function useMainScenarioId(optimizationGroupId?: string) {
	const { data: scenarios } = useScenariosQuery<ScenarioModel | undefined>(
		optimizationGroupId!,
		{
			select: find<ScenarioModel>({ is_main: true }),
			enabled: !!optimizationGroupId,
		}
	)
	return scenarios?.id
}
