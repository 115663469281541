import clsx from 'clsx'
import { orderBy } from 'lodash/fp'

import { PricingCampaignModel } from '@/generated'

import PricingAlertsGroup from './PricingAlertsGroup'

export interface PricingAlertsTreeAlert {
	id: string
	name: string
	products: number
	est_revenue: number
	est_revenue_share: number
	solution?: {
		description: string
		tooltip: string
		pricingCampaigns: PricingCampaignModel[] | null
		optimizationGroupId: string
	}
}
export interface PricingAlertsTree {
	level: string
	products: number
	alerts: PricingAlertsTreeAlert[]
	est_revenue_share: number
}
interface PricingAlertsProps {
	className?: string
	collapsedByDefault?: boolean
	analyticPerfix: string
	alertsTree: PricingAlertsTree[]
	onNavigate?: (ids: string[]) => void
}
export default function PricingAlerts({
	className,
	alertsTree,
	analyticPerfix,
	onNavigate,
	collapsedByDefault,
}: PricingAlertsProps) {
	return (
		<div className={clsx('space-y-3', className)}>
			{alertsTree.map(({ level, products, alerts, est_revenue_share }) => {
				return (
					<PricingAlertsGroup
						collapsedByDefault={collapsedByDefault}
						analyticPerfix={analyticPerfix}
						key={level}
						kind={level}
						count={products}
						percent={est_revenue_share}
						alerts={orderBy(['est_revenue_share'], ['desc'], alerts)}
						onFilterClick={onNavigate}
					/>
				)
			})}
		</div>
	)
}
