import React from 'react'

import PcGlobalIcon from '@cmpkit/icon/lib/glyph/pc-global'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'

export default function GlobalPcBadge() {
	return (
		<Tooltip
			content={intl
				.get('global_pc.hint')
				.d('Global pricing campaign: impacts various OGs')}
		>
			<PcGlobalIcon className='shrink-0 text-muted' />
		</Tooltip>
	)
}
