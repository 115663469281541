import clsx from 'clsx'
import React from 'react'

export default function Container({
	className,
	...props
}: {
	className?: string
} & React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			className={clsx('rounded-lg bg-accent-1 p-2 shadow', className)}
			{...props}
		/>
	)
}
