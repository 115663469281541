import { format } from 'date-fns'
import {
	all,
	any,
	find,
	flatten,
	indexBy,
	map,
	omitAll,
	orderBy,
	pipe,
	prop,
	uniq,
	values,
} from 'lodash/fp'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button } from '@cmpkit/base'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'
import { Step, Steps } from '@cmpkit/steps'

import { FormProvider } from '@/components/HookForm'
import Loader from '@/components/Loader'
import { MultiChoiceManager } from '@/components/MultiChoiceManager'
import { BoundaryWithUser } from '@/components/withErrorBoundary'
import useStep from '@/hooks/useStep'
import intl from '@/locale'
import { useAuth } from '@/modules/Auth/AuthContext'
import {
	useCreateScheduleMutation,
	useUpdateScheduleMutation,
} from '@/modules/core/mutations'
import {
	useOptimizationGroupsQuery,
	useSchedulesQuery,
} from '@/modules/core/queries'
import { OptimizationGroupModel, ScheduleModel } from '@/modules/core/types'
import { useAllUsersQuery } from '@/modules/Users/queries'
import { getUsername } from '@/modules/Users/utils'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'

import Confirmation from './Confirmation'
import Settings from './Settings'
import { weekdays } from './utils'

type FormData = Omit<ScheduleModel, 'id' | 'nearest_date'>
const noop = () => {}
const canGoNext = (step: number, formData: FormData) => {
	switch (step) {
		case 2: {
			if (!formData) return false
			const checks: (string | boolean)[] = [formData.name, formData.start_date]
			if (formData.repeat) {
				checks.push(!!formData?.repeat_days?.length)
				checks.push(!!formData.repeat_week_interval)
			}
			return all(Boolean, checks)
		}
		case 1:
			return formData.optimization_groups.length > 0
		case 3:
			return true
		case 4:
			return true
		default:
			return false
	}
}

export default function OptimizationScheduleModal({
	isOpen,
	close,
	id,
}: {
	isOpen: boolean
	close: () => void
	id?: string
}) {
	const isNew = !id
	const auth = useAuth()
	const [step, stepsControl] = useStep({ maxStep: 3 })
	/**
	 * Queries
	 */
	const scheduleQuery = useSchedulesQuery<ScheduleModel | undefined>({
		select: find<ScheduleModel>({ id }),
	})
	const { data: scheduledGroupsIds } = useSchedulesQuery<string[]>({
		select: pipe([values, map(prop('optimization_groups')), flatten, uniq]),
	})
	const groupsQuery = useOptimizationGroupsQuery<
		Record<string, OptimizationGroupModel>
	>({
		select: indexBy<OptimizationGroupModel>('id'),
	})

	/**
	 * Mutations
	 */
	const createSchedule = useCreateScheduleMutation()
	const updateSchedule = useUpdateScheduleMutation()

	const isPending = any(prop('isPending'), [createSchedule, updateSchedule])
	const isLoading = any(prop('isLoading'), [scheduleQuery, groupsQuery])
	const schedule = scheduleQuery.data
	const groups = groupsQuery.data

	const users = useAllUsersQuery()

	const methods = useForm<FormData>({
		defaultValues: schedule
			? omitAll(['id', 'nearest_date'], schedule)
			: {
					start_date: format(new Date(), DateFormat.system),
					name: '',
					repeat: false,
					repeat_days: [],
					repeat_week_interval: undefined,
					optimization_groups: [],
					notify_users: [],
				},
	})

	const closeModal = () => close()
	const _onSubmit = async (payload: FormData) => {
		if (isNew) {
			analytic.logEvent('autorun: Create schedule', {
				'start date': payload.start_date,
				'days of a week': (payload.repeat_days || []).map((d) => weekdays[d]),
				repeat: payload.repeat,
				'amount of ogs': payload.optimization_groups.length,
				'amount of notif users': payload.notify_users?.length,
			})
			createSchedule.mutateAsync(payload).then(() => {
				closeModal()
			})
		} else {
			analytic.logEvent('autorun: Update schedule', {
				'configuration name': payload.name,
			})
			updateSchedule.mutateAsync({ ...payload, id: schedule!.id }).then(() => {
				closeModal()
			})
		}
	}

	const availableUsers = orderBy(
		['me', 'label'],
		['desc', 'asc'],
		users.data
			.filter(({ apps }) =>
				any(
					(g) => groups?.[g],
					find({ name: 'pricing_platform' }, apps)?.entities
						?.optimization_group ?? []
				)
			)
			.map((user) => ({
				value: user.id,
				label: user.email,
				username: getUsername(user),
				me: auth.user?.id == user.id,
			}))
	)

	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					onClose={isLoading || isPending ? noop : closeModal}
					showCloseButton={!(isLoading || isPending)}
					size='large'
				>
					<ModalHeader>
						<ModalTitle>{intl.get('schedule_repricing')}</ModalTitle>
					</ModalHeader>
					<BoundaryWithUser config={{ showDialog: true }}>
						<FormProvider
							className='flex flex-col overflow-auto'
							methods={methods}
							onSubmit={methods.handleSubmit(_onSubmit)}
						>
							<ModalBody className='p-5'>
								{(isLoading || isPending) && (
									<Loader size={30} backdrop opacity className='overlay' />
								)}
								<Steps current={step}>
									<Step title={intl.get('schedule_repricing_step_1')} />
									<Step title={intl.get('schedule_repricing_step_2')} />
									<Step title={intl.get('schedule_repricing_step_3')} />
								</Steps>
								<div className='mt-5'>
									{step === 1 && (
										<Controller
											name='optimization_groups'
											control={methods.control}
											render={({ field }) => {
												return (
													<MultiChoiceManager
														value={field.value}
														onChange={field.onChange}
														groups={groups || {}}
														disabledGroups={
															schedule
																? scheduledGroupsIds?.filter(
																		(id) =>
																			!schedule.optimization_groups.includes(id)
																	)
																: scheduledGroupsIds
														}
													/>
												)
											}}
										/>
									)}
									{step === 2 && (
										<Settings methods={methods} users={availableUsers} />
									)}
									{step === 3 && (
										<Confirmation methods={methods} groups={groups!} />
									)}
								</div>
							</ModalBody>
						</FormProvider>
					</BoundaryWithUser>
					{!(isLoading || isPending) && (
						<ModalFooter className='justify-end space-x-2'>
							<Button className='mr-auto' onClick={closeModal}>
								{intl.get('general_cancel')}
							</Button>
							<Button
								onClick={stepsControl.goToPrevStep}
								disabled={!stepsControl.canGoToPrevStep}
								iconBefore={<CaretLeftIcon />}
							>
								{intl.get('general_back')}
							</Button>
							{stepsControl.canGoToNextStep && (
								<Button
									variant='primary-brand'
									className='ml-2'
									disabled={!canGoNext(step, methods.watch())}
									iconAfter={<CaretRightIcon />}
									onClick={stepsControl.goToNextStep}
								>
									{intl.get('general_next')}
								</Button>
							)}
							{!stepsControl.canGoToNextStep && (
								<Button
									variant='primary-brand'
									className='ml-2'
									onClick={methods.handleSubmit(_onSubmit)}
								>
									{intl.get('general_apply')}
								</Button>
							)}
						</ModalFooter>
					)}
				</Modal>
			)}
		</ModalTransition>
	)
}
