import { isNil, uniqBy } from 'lodash/fp'
import { CircleDollarSignIcon } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbList,
	BreadcrumbPage,
	Button,
	Content,
	Header,
	Layout,
	Loader,
	SegmentGroup,
	SegmentGroupItem,
	Separator,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import Tooltip from '@cmpkit/tooltip'

import CompactSearch from '@/components/CompactSearch'
import DocumentTitle from '@/components/DocumentTitle'
import NoData from '@/components/placeholders/NoData/NoData'
import intl from '@/locale'
import analytic from '@/services/analytics'
import Filter, {
	filterData,
	getFilterValue,
	searchData,
} from '@/tools/page-data-helpers'

import { usePromoCampaignsQuery } from '../queries'
import PromoCampaignsTable from './PromoCampaignsTable'

export default function PromoCampaigns() {
	const navigate = useNavigate()
	const promoCampaigns = usePromoCampaignsQuery()
	const dataList = promoCampaigns?.data
	const [searchText, setSearchText] = useState('')
	const [filters, setFilters] = useState<Filter[]>([])
	useEffect(() => {
		analytic.logEvent('promo: open Promo list Page')
	}, [])
	const addFilter = (filter: Filter) =>
		setFilters(uniqBy('columnId', [filter, ...filters]))
	const handleSearchChange = (searchText: string) => {
		analytic.logEvent('promo: search')
		setSearchText(searchText)
	}
	const handleStatusSelect = (value: boolean | null) => {
		analytic.logEvent('promo: filter by status')
		value != null
			? addFilter(
					// eslint-disable-next-line
					new Filter('is_active', (v: any) => v == value, value)
				)
			: setFilters([])
	}
	const handelCreate = () => navigate('create')

	const data = filterData(
		searchData(dataList || [], searchText, 'name'),
		filters
	)
	const filteringStatus = getFilterValue(filters, 'is_active', null)

	return (
		<DocumentTitle title={intl.get('promo_title_page')}>
			<Layout>
				<Header className='h-11 items-center border-b px-4 py-2'>
					<CircleDollarSignIcon className='size-4' />
					<Separator orientation='vertical' className='mx-2 h-5' />
					<Breadcrumb className='text-xs'>
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbPage>{intl.get('menu.promo')}</BreadcrumbPage>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
				</Header>
				<Layout className='relative overflow-hidden px-4'>
					<Header className='flex items-center justify-between py-3'>
						<div className='flex items-center gap-2'>
							<CompactSearch
								value={searchText}
								onChange={(event) => handleSearchChange(event.target.value)}
								placeholder={intl.get('general_search')}
								onClear={() => handleSearchChange('')}
							/>
							<SegmentGroup>
								<SegmentGroupItem
									onClick={() => handleStatusSelect(null)}
									active={filteringStatus === null}
								>
									{intl.get('all').d('All')}
								</SegmentGroupItem>
								<SegmentGroupItem
									onClick={() => handleStatusSelect(true)}
									active={filteringStatus === true}
								>
									{intl.get('app.live')}
								</SegmentGroupItem>
								<SegmentGroupItem
									onClick={() => handleStatusSelect(false)}
									active={filteringStatus === false}
								>
									{intl.get('app.paused')}
								</SegmentGroupItem>
							</SegmentGroup>
						</div>
						<div className='ml-auto flex items-center'>
							<Tooltip content={intl.get('general_create')}>
								<Button
									variant='primary-brand'
									iconBefore={<PlusIcon />}
									onClick={handelCreate}
								/>
							</Tooltip>
						</div>
					</Header>
					<Content id='promo-content' className='h-full overflow-hidden pb-5'>
						<Blanket
							className='absolute flex items-center justify-center bg-white/50 backdrop-blur-lg dark:bg-black/50'
							isTinted={promoCampaigns.isLoading}
						>
							<Loader />
						</Blanket>
						<div className='flex h-full flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base'>
							{data?.length === 0 && promoCampaigns.isFetched ? (
								data.length > 0 ||
								searchText.length > 0 ||
								!isNil(filteringStatus) ? (
									<NoData title='Looks like there are no items suitable for filters' />
								) : (
									<NoData
										title='Looks like there are no "Promo campaigns"'
										subtitle='Create new to use it for your repricing.'
									>
										<Button
											onClick={() => navigate('create')}
											variant='primary-brand'
										>
											{intl.get('general_create')}
										</Button>
									</NoData>
								)
							) : (
								<PromoCampaignsTable data={data} />
							)}
						</div>
					</Content>
				</Layout>
			</Layout>
		</DocumentTitle>
	)
}
