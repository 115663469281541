import { useNavigate } from 'react-router'

import { Layout, Result } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'
import CheckCircleIcon from '@cmpkit/icon/lib/glyph/check-circle'

import {
	usePricingAlertsAnnotationsQuery,
	usePricingAlertsQuery,
} from '@/modules/core/queries'
import FatalError from '@/components/FatalError'
import PricingAlerts from '@/components/PricingAlerts'

import { getQueryParams } from './helpers'
import { getPricingAlertsTree } from '../PricingAlerts/helpers'

export const PricingAlertsGlobalWidget = () => {
	const navigate = useNavigate()
	const pricingAlertsAnnotations = usePricingAlertsAnnotationsQuery()
	const pricingAlerts = usePricingAlertsQuery({})
	const isLoading =
		pricingAlertsAnnotations.isLoading || pricingAlerts.isLoading
	const isError = pricingAlertsAnnotations.isError || pricingAlerts.isError
	if (isLoading) {
		return <AlertsListSkeleton />
	}
	if (isError) {
		return <FatalError />
	}
	const alertsTree = getPricingAlertsTree({
		pricingAlerts: pricingAlerts.data,
		annotations: pricingAlertsAnnotations.data,
	})
	const handleNavigate = (ids: string[]) => {
		close()
		navigate(
			`${location.pathname}${getQueryParams([
				{ name: 'alerts', operation: Operators.ANY_OF, value: ids },
			])}`
		)
	}
	return (
		<div className='w-full'>
			{!alertsTree?.length ? (
				<Result
					className='flex h-full flex-col items-center justify-center'
					icon={<CheckCircleIcon height={30} width={30} />}
					title='Everything is ok'
				/>
			) : (
				<PricingAlerts
					analyticPerfix='alerts: products:'
					alertsTree={alertsTree}
					onNavigate={handleNavigate}
				/>
			)}
		</div>
	)
}
const AlertsListSkeleton = () => (
	<Layout className='h-full overflow-y-hidden'>
		<div className='flex w-full flex-col justify-center p-5'>
			<div className='w-full animate-pulse rounded-lg bg-accent-3'>
				<div className='h-12 w-full animate-pulse rounded-t-lg bg-accent-4' />
				<div className='h-12 w-full' />
			</div>
			<div className='mt-5 w-full animate-pulse rounded-lg bg-accent-3'>
				<div className='h-12 w-full animate-pulse rounded-t-lg bg-accent-4' />
				<div className='h-12 w-full' />
			</div>
			<div className='mt-5 w-full animate-pulse rounded-lg bg-accent-3'>
				<div className='h-12 w-full animate-pulse rounded-t-lg bg-accent-4' />
				<div className='h-12 w-full' />
			</div>
		</div>
	</Layout>
)
