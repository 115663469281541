import React from 'react'

import { AlertLevel } from '@/generated'

import AnchoringAlertsGroup from './AnchoringAlertsGroup'

interface AlerTreeItem {
	level: AlertLevel
	total: number
	alerts: {
		id: string
		name: string
		anchors: number
	}[]
}
type AnchoringAlertsProps = {
	analyticPerfix: string
	alertsTree: AlerTreeItem[]
	onNavigate(ids: string[]): void
}
export default function AnchoringAlerts({
	alertsTree,
	analyticPerfix,
	onNavigate,
}: AnchoringAlertsProps) {
	return (
		<div className='space-y-3'>
			{alertsTree.map(({ level, alerts, total }) => {
				return (
					<AnchoringAlertsGroup
						analyticPerfix={analyticPerfix}
						key={level}
						kind={level}
						count={total}
						alerts={alerts}
						onFilterClick={onNavigate}
					/>
				)
			})}
		</div>
	)
}
