import { setHours } from 'date-fns'
import { first, fromPairs, orderBy } from 'lodash/fp'

import {
	colorForTopic,
	durations,
	normalizeDate,
} from '@/components/Chart/utils'
import { CompetitiveDataPointModel } from '@/generated'

import {
	ChartSerie,
	CompetitiveDataPointExtended,
	CompetitorDataGroup,
} from './types'

export const createSerie = (
	name: string,
	color: string | null,
	data: [number, number | null][]
) => ({
	type: 'line',
	name,
	color: color || '#6B7176',
	pointInterval: durations.day,
	data: data,
})

/**
 * Get last available data from grouped datasets
 * @param {Array<Object>} objects  Serverside data
 * @returns {Array}
 */
export const pickLastData = (
	objects: CompetitorDataGroup[]
): CompetitiveDataPointExtended[] =>
	objects.map(
		(data) =>
			({
				...(first(
					orderBy(['observed_on'], ['desc'], data.objects)
				) as CompetitiveDataPointModel),
				label: data.competitor_name,
				color: data.color,
			}) as CompetitiveDataPointExtended
	)

/**
 *
 * @param {Array} data  Serverside data
 * @param {Number} interval  milseconds
 *
 * @returns {Array}  with normalized date and values [date,value]
 */
export const prepareSerieDataset = (
	data?: {
		observed_on: string
		price: number
	}[],
	interval = durations.day
): [number, number][] => {
	return (
		data?.map((d) => {
			return [
				normalizeDate(Number(setHours(new Date(d.observed_on), 3)), interval),
				+d.price,
			]
		}) || []
	)
}

/**
 *
 * @param {Array} list Serverside data
 *
 * @returns {Array} the same array with color
 */
export const enrichWithColors = (
	list: {
		competitor_name?: string
	}[],
	colors: Record<string, string> = {}
): CompetitorDataGroup[] =>
	list.map((data) => ({
		...data,
		color: data?.competitor_name ? colors[data?.competitor_name] : '',
	}))

/**
 * Allign all series to common dataset length
 *
 * @param {Array} series
 * @param {Number} interval
 */
export const alignDatasets = (
	series: ChartSerie[],
	interval = durations.day
): ChartSerie[] => {
	const end = normalizeDate(Number(new Date()), interval) * interval
	const start = end - durations.week * 6
	return series.map((serie) => {
		const dataMap = fromPairs(serie.data)
		const ds = createDataset(start, end)
		return {
			...serie,
			data: ds.map(([date]) => [date * interval, dataMap[date] || null]),
		}
	})
}

/**
 * Create new dataset with Dates and null values
 *
 * @param {Number} from
 * @param {Number} to
 * @param {Number} interval
 *
 * @returns {Array} array with normalized detes and empty values
 */
export const createDataset = (
	from: number,
	to: number,
	interval = durations.day
) => {
	const set: [number, null][] = []
	for (let i = from; i <= to; i += interval) {
		set.push([normalizeDate(i, interval), null])
	}
	return set
}
export const createColorMap = (
	list: {
		competitor_name?: string | undefined
		objects?: CompetitiveDataPointModel[] | undefined
	}[]
): Record<string, string> =>
	fromPairs(
		list.map(({ competitor_name }, index) => [
			competitor_name,
			colorForTopic(list.length, index),
		])
	)
