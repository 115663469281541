import {
	concat,
	entries,
	filter,
	fromPairs,
	indexBy,
	isEmpty,
	map,
	pickAll,
	pipe,
	prop,
	reverse,
	uniq,
	uniqBy,
} from 'lodash/fp'
import { useMemo } from 'react'

import { FilterRuleEntity, Operators } from '@cmpkit/query-builder'

import RefinementBar from '@/components/refinement-bar'
import FieldController from '@/components/refinement-bar/fields/Controller'
import {
	FieldConfigType,
	Meta,
	ValueState,
} from '@/components/refinement-bar/types'
import analytic from '@/services/analytics'

type QuickFiltersBarProps = {
	rules: FilterRuleEntity[]
	onApply: (filterRules: FilterRuleEntity[]) => void

	// Keys that are not allowed to be removed from quick filters
	irremovableKeys?: string[]

	// Keys that are not supported by refinement bar becaause inconsistency products page and global assotment page
	ignoredKeys?: string[]

	// Configuration for fields in refinement bar
	fieldsConfig?: FieldConfigType

	// Expandable quick filters bar
	isExpandable?: boolean

	// Limit the number of quick filters to be displayed
	limit?: number

	storedKeys?: string[]
	onSaveStoredKeys?: (keys: string[]) => void
}

export default function QuickFiltersBar({
	rules,
	onApply,
	irremovableKeys = [],
	ignoredKeys = [],
	storedKeys = [],
	fieldsConfig,
	isExpandable,
	onSaveStoredKeys,
	limit,
}: QuickFiltersBarProps) {
	/**
	 * Get columns configuration schema for quick filters
	 */
	const fieldConfig = useMemo(() => fieldsConfig, [fieldsConfig])
	/**
	 * Transform exited filters to value for refinement bar
	 */
	const value = useMemo(
		() =>
			pipe([
				indexBy(prop('name')),
				pickAll([...irremovableKeys, ...storedKeys]),
				entries,
				map(([key, val]) => {
					// Convert between array props to gt and lt
					if (val.operation === Operators.BETWEEN) {
						return [
							key,
							{ ...val, value: { gt: val.value[0], lt: val.value[1] } },
						]
					} else {
						return [key, val]
					}
				}),
				// Exculde filters if they are not supported by the field type in refirement bar
				filter(([key, val]) => {
					const typeName = fieldConfig?.[key]?.type.name
					if (!typeName) {
						// eslint-disable-next-line
						console.warn(`Field ${key} is not supported in refinement bar`)
						return false
					}
					const Controller = fieldConfig[key]!.type!
						.controller as typeof FieldController
					const field = Controller
						? new Controller(fieldConfig?.[key])
						: undefined
					const initOperation = field?.getInitialValue?.()?.operation

					// Check if the filter is supported by the field type
					if (
						['TreeSelect', 'Select'].includes(typeName) &&
						initOperation !== val?.operation
					) {
						return false
					}
					if (ignoredKeys.includes(key)) {
						return false
					}

					const unsupportedExpression =
						!['TreeSelect', 'Select'].includes(typeName) &&
						[
							Operators.IN,
							Operators.NOT_IN,
							Operators.ALL_OF,
							Operators.NONE_OF,
							Operators.ANY_OF,
							Operators.EXACT,
						].includes(val?.operation)

					return !unsupportedExpression
				}),
				fromPairs,
			])(rules),
		[rules, storedKeys, fieldConfig]
	)
	/**
	 * Handle change event from refinement bar
	 */
	const handleChange = (data: Record<string, ValueState>, meta: Meta) => {
		switch (meta.action) {
			case 'clear':
				analytic.logEvent('table: clear quick filter')

				onApply(rules.filter(({ name }) => name && name !== meta.key))
				break
			case 'add':
				analytic.logEvent('table: add quick filter')
				onSaveStoredKeys?.(uniq([...storedKeys, meta.key] as string[]))
				break
			case 'bulk-remove':
				analytic.logEvent('table: bulk remove quick filter')
				onSaveStoredKeys?.(
					storedKeys.filter((key) => !meta.keys?.includes(key))
				)
				onApply(rules.filter(({ name }) => !meta.keys?.includes(name)))
				break
			case 'remove':
				analytic.logEvent('table: remove quick filter')
				onSaveStoredKeys?.(storedKeys.filter((key) => key !== meta.key))
				onApply(rules.filter(({ name }) => name !== meta.key))
				break
			case 'update':
				analytic.logEvent('table: update quick filter')
				onApply(
					pipe([
						entries,
						// eslint-disable-next-line
						map(([name, other]: [string, ValueState<any>]) => {
							if (other.operation === Operators.BETWEEN) {
								// Convert gt and lt to between array props
								return {
									name,
									...other,
									value: [other.value.gt, other.value.lt],
								}
							}
							return { name, ...other }
						}),
						concat(rules),
						filter(Boolean),
						reverse,
						uniqBy('name'),
					])(data)
				)
				break
			default:
				return
		}
	}

	return (
		!isEmpty(fieldConfig) && (
			<RefinementBar
				initialKeys={storedKeys}
				irremovableKeys={irremovableKeys}
				fieldConfig={fieldConfig}
				onChange={handleChange}
				value={value}
				isExpandable={isExpandable}
				limit={limit}
			/>
		)
	)
}
