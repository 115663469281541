// wraps value in closure or returns closure
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const closure = (value: any) => {
	if (typeof value === 'function') {
		return value
	} else {
		return function closure() {
			return value
		}
	}
}
