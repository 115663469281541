/* eslint-disable sort-imports */
import {
	DefaultError,
	DefaultOptions,
	MutationCache,
	QueryCache,
	QueryClient,
	QueryKey,
	UseQueryOptions as NativeUseQueryOptions,
} from '@tanstack/react-query'

export const queryCache = new QueryCache()
export const mutationCache = new MutationCache()
const queryConfig: DefaultOptions = {
	queries: {
		experimental_prefetchInRender: true,
		refetchOnWindowFocus: false,
		staleTime: 60 * 1000 * 5,
	},
}

export const queryClient = new QueryClient({
	queryCache,
	mutationCache,
	defaultOptions: queryConfig,
})

/**
 * Proxy Query option to avoid type exeptions
 */
export type UseQueryOptions<
	TQueryFnData = unknown,
	TError = DefaultError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
> = Omit<
	NativeUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
	'queryKey' | 'queryFn'
>
