import React, { memo } from 'react'

import { LinkButton } from '@cmpkit/base'

import { useDrawersStore } from '@/modules/drawers/store'
import { selectSearchText, useProductsStore } from '@/modules/og-products/store'

import { CellProp } from '../fields'

export const getMarkedText = (text: string, searchTextValue?: string) => {
	if (!searchTextValue) {
		return <p>{text}</p>
	}
	const searchText = (searchTextValue || '').replace(
		/[-[\]{}()*+?.,\\^$|#\s]/g,
		'\\$&'
	)
	const regExp = new RegExp(searchText, 'gi')
	const matched = text && text.match(regExp)
	return (
		<span
			dangerouslySetInnerHTML={{
				__html:
					text &&
					text.replace(regExp, `<mark>${matched?.[0] ?? searchText}</mark>`),
			}}
		/>
	)
}
const RenderIdent = memo(
	({
		value,
		searchText,
		onClick,
	}: {
		value: string
		searchText: string
		onClick: () => void
	}) => (
		<LinkButton variant='brand' onClick={onClick}>
			{getMarkedText(value, searchText)}
		</LinkButton>
	)
)
export default function ProductIdentField({ value, row }: CellProp) {
	const searchText = useProductsStore(selectSearchText)
	const { openDrawer } = useDrawersStore()
	const onClick = () =>
		openDrawer('PRODUCT_EASTSIDE', {
			content: row,
		})
	return <RenderIdent value={value} searchText={searchText} onClick={onClick} />
}
