import { pipe, prop, propOr, size } from 'lodash/fp'
import React, { Fragment, useState } from 'react'

import { Checkbox, Loader } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'

import { PricingCampaignModel } from '@/generated'
import intl from '@/locale'
import { toggleSetItems } from '@/modules/og-products/utils'

import { usePricingCampaignQuery } from '../../queries'
import { getKeyTitle } from './helpers'

type SettingNode = {
	key: string
	label: string
	preview: string | number | null
	children: SettingNode[]
}
const getSettings = (entity: PricingCampaignModel): SettingNode[] => [
	{
		key: 'settings.product_assignments',
		label: intl.get('settings.assignment.short').d('Assignment'),
		preview: pipe([prop('settings.product_assignments'), size])(entity),
		children: [],
	},
	{
		key: 'settings.pricing_tactics',
		label: intl.get('settings.tactics.short').d('Tactics'),
		preview: propOr('-', 'settings.pricing_tactics.engine', entity),
		children: [],
	},
	{
		key: 'settings.price_limits',
		label: intl.get('settings.price_limits.short').d('Price limits'),
		preview: null,
		children: [
			{
				key: 'settings.price_limits.lower',
				label: intl.get('price_limits_lower_title').d('Lower price limit'),
				preview: pipe([prop('settings.price_limits.lower'), size])(entity),
				children: [],
			},
			{
				key: 'settings.price_limits.upper',
				label: intl.get('price_limits_upper_title').d('Upper price limit'),
				preview: pipe([prop('settings.price_limits.upper'), size])(entity),
				children: [],
			},
			{
				key: 'settings.price_limits.extra.on_conflict',
				label: intl.get('price_limits_on_conflict_title').d('On conflict'),
				preview: prop('settings.price_limits.extra.on_conflict', entity),
				children: [],
			},
		],
	},
	{
		key: 'settings.anchoring',
		label: intl.get('settings.anchoring.short').d('Anchoring'),
		preview: pipe([prop('settings.anchoring'), size])(entity),
		children: [
			{
				key: 'settings.anchoring.rules',
				label: intl.get('anchoring_rules').d('Rules'),
				preview: pipe([prop('settings.anchoring.rules'), size])(entity),
				children: [],
			},
			{
				key: 'settings.anchoring.on_conflict',
				label: intl.get('anchoring_on_conflict').d('On conflict'),
				preview: pipe([prop('settings.anchoring.on_conflict')])(entity),
				children: [],
			},
		],
	},
	{
		key: 'settings.rounding',
		label: intl.get('settings.rounding.short').d('Rounding'),
		preview: pipe([prop('settings.rounding'), size])(entity),
		children: [],
	},
	{
		key: 'settings.minimal_steps',
		label: intl.get('settings.minimal_steps.short').d('Minimal steps'),
		preview: pipe([prop('settings.minimal_steps'), size])(entity),
		children: [],
	},
]
export default function SelectSettingsView({
	pricingCmapaignId,
	selected,
	setSelected,
}: {
	pricingCmapaignId: string
	selected: string[]
	setSelected: (selected: string[]) => void
}) {
	const [expanded, setExpanded] = useState<string[]>([])
	const pricingCampaignQuery = usePricingCampaignQuery(pricingCmapaignId, {
		select: getSettings,
	})
	return (
		<div className='min-h-30 relative overflow-x-auto rounded-lg text-xs'>
			<Blanket
				className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-lg dark:bg-black/50'
				isTinted={pricingCampaignQuery.isLoading}
			>
				<Loader />
			</Blanket>
			<table className='w-full table-auto border-separate border-spacing-x-px overflow-hidden rounded-lg border border-solid border-base bg-accent-4 text-left'>
				<colgroup>
					<col
						style={{
							width: 34,
						}}
					/>
					<col
						style={{
							width: 280,
						}}
					/>
				</colgroup>
				<thead className='bg-accent-3 text-xs'>
					<tr>
						<th scope='col' className='p-2 pr-0' />
						<th scope='col' className='p-2'>
							Setting
						</th>
						<th scope='col' className='p-2'>
							Value
						</th>
					</tr>
				</thead>
				<tbody>
					{pricingCampaignQuery.data?.map((settingNode) => {
						const isSelected = selected.includes(settingNode.key)
						const ieExpandable = settingNode.children?.length > 0
						const isExpanded = expanded.includes(settingNode.key)
						const handleExpandChange = () =>
							setExpanded(toggleSetItems(expanded, [settingNode.key]))
						const handleSelectChange = () =>
							setSelected(toggleSetItems(selected, [settingNode.key]))
						return (
							<Fragment key={settingNode.key}>
								<tr className='border-b bg-accent-1 even:bg-accent-2'>
									<th
										scope='row'
										className='whitespace-nowrap p-2 pr-0 dark:text-white'
									>
										<Checkbox
											checked={isSelected}
											onChange={handleSelectChange}
										/>
									</th>
									<td className='p-2 font-bold'>
										<div className='flex items-center'>
											{ieExpandable ? (
												isExpanded ? (
													<CaretDownIcon
														className='cursor-pointer'
														onClick={handleExpandChange}
													/>
												) : (
													<CaretRightIcon
														className='cursor-pointer'
														onClick={handleExpandChange}
													/>
												)
											) : null}
											{intl
												.get(getKeyTitle(settingNode.label))
												.d(settingNode.label)}
										</div>
									</td>
									<td className='p-2'>{settingNode.preview}</td>
								</tr>
								{ieExpandable &&
									isExpanded &&
									settingNode.children.map((settingsChild) => {
										return (
											<tr
												key={settingsChild.key}
												className='border-b bg-accent-1 even:bg-accent-2'
											>
												<th
													scope='row'
													className='whitespace-nowrap p-2 dark:text-white'
												/>
												<td className='p-2 pl-6'>
													{intl
														.get(getKeyTitle(settingsChild.label))
														.d(settingsChild.label)}
												</td>
												<td className='p-2'>{settingsChild.preview}</td>
											</tr>
										)
									})}
							</Fragment>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
