import React from 'react'

import intl from '@/locale'

export default function ProductsBulkUpdateErrors({
	errorsList,
}: {
	errorsList?: Record<string, string[]>[]
}) {
	return (
		<div className='rounded-lg border border-solid border-base bg-accent-2'>
			<span className='px-2 py-1 text-xs font-bold'>
				{intl.get('import_error_validation_errors')}:
			</span>
			<div className='max-h-36 space-y-2 overflow-y-auto px-2 py-1'>
				{errorsList?.map((error, index) => (
					<div key={index} className='flex items-start text-xs'>
						<div className='mr-2 flex size-5 items-center justify-center rounded-lg bg-accent-3 text-center'>
							{index + 1}
						</div>
						<div className='mt-px'>
							{Object.entries(error).map(([field, fieldErrors]) => {
								return (
									<div key={field}>
										<strong>{field}</strong>:{' '}
										<span className='italic text-danger'>
											{fieldErrors.join(', ')}
										</span>
									</div>
								)
							})}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
