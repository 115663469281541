import { prop, sum } from 'lodash/fp'
import React from 'react'

import { PALETTE } from '@cmpkit/theme'

import intl from '@/locale'
import { useBIAnalyticQuery } from '@/modules/bi/queries'
import { sortIn } from '@/modules/bi/utils'
import { PricingEngineType } from '@/modules/pricing-campaigns/types'
import analytic from '@/services/analytics'
import { formatNumber, NumberFormats } from '@/tools/locale'

import DonutView from '../../DonutView/DonutView'
import { MainDashboardFilter } from '../../Filter'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetEmptyState from '../../WidgetEmptyState'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetProps } from '../types'

const getColor = (name: string) => {
	switch (name) {
		case PricingEngineType.RB:
			return PALETTE.orange[75]
		case PricingEngineType.ML:
			return PALETTE.blue[75]
		case PricingEngineType.MD:
			return PALETTE.purple[75]
		default:
			return PALETTE.neutral[150]
	}
}
const buildDataSeries = (data: [string, number][], total: number) => {
	return data
		?.sort(
			sortIn(
				[PricingEngineType.ML, PricingEngineType.RB, PricingEngineType.MD],
				prop('0')
			)
		)
		?.map(([name, value]) => {
			const subvalue = +((value / total) * 100).toFixed(1)
			return {
				name: intl.get(`campaigns_om_title_${name || 'not_reprice'}`),
				color: getColor(name),
				y: subvalue,
				subvalue,
				value,
			}
		})
}
export default function RevenueUnderManagementByEngine({
	widget,
	params,
	onChangeParams,
	commonPeriods,
	commonFilters,
}: WidgetProps) {
	const filters = [...commonFilters, ...(params.filters ?? [])]
	const biQuery = useBIAnalyticQuery({
		label: widget.kind,
		...commonPeriods.main,
		metrics: ['revenue'],
		dimensions: ['pricing_campaign_engine'],
		filters,
		date_aggregation: null,
	})
	const data = biQuery?.data?.data
	const total = sum(data?.map(prop('1')))
	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.revenue_under_management_by_engine.title')}
				tooltip={intl.get('widget.revenue_under_management_by_engine.tooltip')}
				subtitle={intl.get(
					'widget.revenue_under_management_by_engine.subtitle'
				)}
			/>
			<WidgetContent>
				<div className='flex items-center'>
					<MainDashboardFilter
						irremovableCount={commonFilters.length}
						filters={filters ?? []}
						onChange={(filters) => {
							analytic.logEvent(`dashboard: bi: apply filter ${widget.kind}`, {
								'filter by': filters.map(prop('name')).join(','),
							})
							onChangeParams({ ...params, filters })
						}}
					/>
				</div>
				<WidgetLoadingState isTinted={biQuery.isLoading} />
				<WidgetErrorState isTinted={biQuery.isError} />
				{data?.length && !biQuery.isLoading ? (
					<DonutView
						data={
							buildDataSeries(data as [string, number][], total)?.reverse() ||
							[]
						}
						formatValue={(val) => formatNumber(val, NumberFormats.Compact)}
						total={total}
					/>
				) : (
					<WidgetEmptyState />
				)}
			</WidgetContent>
		</Widget>
	)
}
