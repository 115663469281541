import { endOfDay, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns'
import { all, intersection, isEmpty, isNil, prop, trim } from 'lodash/fp'

import { Operators } from '@cmpkit/query-builder'

import { FilterRuleModel } from '@/components/filter/types'
import Storage from '@/services/local-storage'

type FilterValue = undefined | string | number | null | (string | number)[]
export const getRecentlyUsedFilter = () => Storage.get('recently_filter')
export const saveRecentlyUsedFilter = (search: string) =>
	Storage.set({
		recently_filter: {
			date: new Date().getTime(),
			search,
		},
	})
export const asArray = (val: FilterValue) =>
	!Array.isArray(val) ? [String(val)] : val

export const encodeRuleValue = (value: FilterValue): FilterValue => {
	if (Array.isArray(value)) {
		return value.map(encodeRuleValue) as FilterValue
	} else if (typeof value === 'string') {
		return encodeURIComponent(value)
	} else {
		return value
	}
}

/**
 * Check if filter value is valid by filter operation
 * @param operation  - filter operation (eq, lt, gt, etc)
 * @param value - filter value
 * @returns  true if filter value is valid
 */
export const isValidFilter = (
	operation: string,
	value: FilterValue
): boolean => {
	switch (operation) {
		case Operators.LT:
		case Operators.EQ:
		case Operators.LTE:
		case Operators.GTE:
		case Operators.NE:
		case Operators.GT:
		case Operators.CONTAINS:
		case Operators.NOT_CONTAINS:
		case Operators.IS:
		case Operators.IN:
		case Operators.IS_NOT:
			return !!value

		case Operators.BL:
			return ['0', '1'].includes(String(value))

		case Operators.IS_EMPTY:
		case Operators.IS_NOT_EMPTY:
			return String(value) === '1'

		case Operators.ANY_OF:
			return asArray(value).filter((i) => i != null).length > 0
		default:
			return true
	}
}

/**
 * Normalize value by filter operation (for example, for IN filter, value should be array)
 * @param operation - filter operation
 * @param value - filter value
 * @returns  normalized value
 */
export const normilizeValue = (
	operation: string,
	value: FilterValue
): FilterValue => {
	switch (operation) {
		case Operators.ALL_OF:
		case Operators.ANY_OF:
		case Operators.EXACT:
		case Operators.IN:
		case Operators.NOT_IN:
		case Operators.BETWEEN:
		case Operators.DATE_BETWEEN:
		case Operators.NONE_OF:
			return Array.isArray(value)
				? value
				: value === ''
					? [value]
					: (value as string).split(',') || []
		default:
			return value
	}
}
/**
 * Get filtered data by rules array and data array
 * @example
 * filterByRules(
 * 	[{ name: 'id', operation: 'eq', value: 1 }],
 * 	[{ id: 1, name: 'test' }]
 * ) => [{ id: 1, name: 'test' }]
 * @param rules - array of filter rules
 * @param data  - array of data to filter
 * @returns  filtered data
 */
export const filterByRules = <T = object>(
	rules: FilterRuleModel[],
	data: T[]
) => {
	return data.filter((item) => {
		return all((rule) => {
			return compareByOperation(
				prop(rule.name, item),
				rule.operation as Operators,
				rule.value as FilterValue
			)
		}, rules)
	})
}

export const compareByOperation = (
	fieldValue: FilterValue,
	operator: string,
	queryValue: FilterValue
): boolean => {
	const filter = filters[operator as keyof typeof filters] as (
		a: FilterValue,
		b: FilterValue
	) => boolean
	return filter?.(fieldValue, queryValue) ?? true
}

export const filters = {
	// Numeric
	[Operators.GT]: (a: number, b: number) => a > b,
	[Operators.LT]: (a: number, b: number) => a < b,
	[Operators.GTE]: (a: number, b: number) => a >= b,
	[Operators.LTE]: (a: number, b: number) => a <= b,
	[Operators.EQ]: (a: number, b: number) => a == b,
	[Operators.NE]: (a: number, b: number) => a != b,
	[Operators.BETWEEN]: (a: number, b: number) => {
		const range = b ? (asArray(b) as number[]) : []
		return a >= range[0] && a <= range[1]
	},

	// Boolean
	[Operators.BL]: (a: number, b: number) => Boolean(a) === Boolean(+b),

	// String
	[Operators.IN]: (a: string, b: string[] | string) =>
		Array.isArray(b) ? b.includes(String(a)) : [b].includes(String(a)),
	[Operators.NOT_IN]: (a: string, b: string[] | string) =>
		Array.isArray(b) ? !b.includes(String(a)) : ![b].includes(String(a)),
	[Operators.IS]: (a: string, b: string) =>
		isNil(a) ? a == b : a.toLowerCase() === b.toLowerCase(),
	[Operators.IS_NOT]: (a: string, b: string) =>
		a.toLowerCase() !== b.toLowerCase(),
	[Operators.CONTAINS]: (a: string, b: string) => {
		if (Array.isArray(b))
			return Boolean(a && intersection(asArray(a), asArray(b.map(trim))).length)
		return a.toLowerCase().includes(b.toLowerCase())
	},
	[Operators.NOT_CONTAINS]: (a: string, b: string) =>
		!a.toLowerCase().includes(b.toLowerCase()),

	// List
	[Operators.ANY_OF]: (a: FilterValue, b: FilterValue) =>
		Boolean(intersection(asArray(a), asArray(b)).length),

	// Any
	[Operators.IS_EMPTY]: (a: FilterValue) =>
		Array.isArray(a) ? isEmpty(a) : isNil(a) || !(a + '').length,

	[Operators.IS_NOT_EMPTY]: (a: FilterValue) =>
		Array.isArray(a) ? !isEmpty(a) : !isNil(a),

	// Date
	[Operators.DATE_BETWEEN]: (a: FilterValue, b: FilterValue) => {
		/**
		 * Element value
		 */
		const date = new Date(a as string)

		/**
		 * Unwrap query values as array of seconds
		 */
		const range = b ? asArray(b) : []

		/**
		 * Query value from date
		 */
		const from = startOfDay(new Date(range[0]))

		/**
		 * Query value to date
		 */
		const to = endOfDay(new Date(range[1]))

		/**
		 * Compare values including boundaries
		 */
		return (
			(isBefore(date, to) || isSameDay(date, to)) &&
			(isAfter(date, from) || isSameDay(date, from))
		)
	},
}
