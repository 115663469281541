import { last, props } from 'lodash/fp'
import React from 'react'

import Chart from '@/components/Chart'
import intl from '@/locale'
import { useBIAnalyticQuery } from '@/modules/bi/queries'
import { BIResponse, DateAggregation } from '@/modules/bi/types'
import { zipSeries } from '@/modules/bi/utils'
import { formatNumber, NumberFormats } from '@/tools/locale'

import PercentDifferenceBadge from '../../PercentDifferenceBadge'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetProps } from '../types'

const biPeriodQueryConfig = {
	select: (res: BIResponse | null): string[][] =>
		zipSeries(res?.meta, res?.data).map(
			props(['date_key', 'accepted'])
		) as string[][],
}
const biSummaryQueryConfig = {
	select: (res: BIResponse | null): number =>
		(last(zipSeries(res?.meta, res?.data))?.accepted_percent ?? 0) as number,
}

export default function MetricPerfomanceWidget({
	commonFilters,
	commonPeriods,
	widget,
}: WidgetProps) {
	const commonQueryParams = {
		label: widget.kind,
		start_date: null,
		end_date: null,
		skip_dates: true,
		metrics: [
			'accepted',
			'not_accepted',
			'locked',
			'unassigned',
			'changed_manually',
			'accepted_percent',
			'not_accepted_percent',
			'locked_percent',
			'unassigned_percent',
			'changed_manually_percent',
		],
		dimensions: [],
		filters: [
			{
				name: 'is_repricing_today',
				operation: 'bl',
				value: true,
			},
			...commonFilters,
		],
	}

	const biMainSummaryQuery = useBIAnalyticQuery<number>(
		{
			...commonQueryParams,
			...(commonPeriods?.main || {}),
			date_aggregation: null,
		},
		biSummaryQueryConfig
	)
	const biComparedSummaryQuery = useBIAnalyticQuery<number>(
		{
			...commonQueryParams,
			...(commonPeriods?.compared || {}),
			date_aggregation: null,
		},
		biSummaryQueryConfig
	)
	const biMainPeriodQuery = useBIAnalyticQuery<Array<Array<string>>>(
		{
			...commonQueryParams,
			...(commonPeriods?.main || {}),
			date_aggregation: DateAggregation.DateKey,
		},
		biPeriodQueryConfig
	)
	const diff =
		(biMainSummaryQuery?.data ?? 0) - (biComparedSummaryQuery?.data ?? 0)

	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetLoadingState
				isTinted={
					biMainSummaryQuery.isFetching || biComparedSummaryQuery.isLoading
				}
			/>
			<WidgetErrorState
				isTinted={
					biMainSummaryQuery.isError ||
					biComparedSummaryQuery.isError ||
					biMainPeriodQuery.isError
				}
			/>
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.price_acceptance.title')}
				tooltip={intl.get('widget.price_acceptance.tooltip')}
				subtitle={intl.get('widget.price_acceptance.subtitle')}
			/>
			<WidgetContent>
				<div className='mt-auto w-full'>
					<div className='space-x-2'>
						<span className='data-text text-success'>
							{formatNumber(
								biMainSummaryQuery?.data ?? 0,
								NumberFormats.Percent
							)}
						</span>
						{!Number.isNaN(diff) && (
							<PercentDifferenceBadge value={diff ?? 0} />
						)}
					</div>
					{biMainPeriodQuery.isFetched && (
						<Chart
							config={getChartConfig({
								data: biMainPeriodQuery?.data || [],
								name: intl.get('accepted'),
							})}
						/>
					)}
				</div>
			</WidgetContent>
		</Widget>
	)
}

const getChartConfig = ({
	data = [],
	name,
}: {
	data: Array<Array<string>>
	name: string
}): Highcharts.Options => ({
	chart: {
		type: 'areaspline',
		height: 160,
		animation: true,
		spacingTop: 0,
		spacingRight: 0,
		spacingBottom: 0,
		spacingLeft: 0,
		plotBorderWidth: 0,
		marginRight: 0,
		marginLeft: 0,
		marginTop: 0,
		marginBottom: 0,
	},
	legend: {
		enabled: false,
	},
	tooltip: {
		outside: true,
		followPointer: false,
		useHTML: true,
		backgroundColor: 'transparent',
		borderRadius: 10,
		borderWidth: 0,
		shadow: false,
		formatter: function () {
			const self = this as unknown as any // eslint-disable-line
			return `<div class="chart-tooltip">
					<div class="w-full flex justify-between mb-2.5"><small>${this.key}</small></div>
					${this.series.name}: <strong>${formatNumber(self.point.y as number)}</strong>
				</div>
			`
		},
	},
	xAxis: {
		tickmarkPlacement: 'on',
		title: {
			text: null,
		},
	},
	yAxis: {
		gridLineWidth: 0,
		title: {
			text: '',
		},
	},

	plotOptions: {
		areaspline: {
			fillOpacity: 0.1,
			marker: {
				enabled: false,
			},
		},
	},

	series: [
		{
			type: 'areaspline',
			color: '#00b557',
			name,
			data,
		},
	],
})
