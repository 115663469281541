import { Localization } from '@cmpkit/calendar'

import intl, { getDatesLocale } from '@/locale'

export const localization: Localization = {
	applyText: intl.get('general_apply').d('Apply'),
	cancelText: intl.get('general_cancel').d('Cancel'),
	backText: intl.get('app.back').d('Back'),
	resetText: intl.get('app.reset').d('Reset'),
	absoluteDateText: intl.get('app.absolute_date').d('Absolute date'),
	customRangePlaceholderText: intl.get('app.custom_range').d('Custom range'),

	getDateFnsLocalization() {
		return getDatesLocale()
	},
}
