/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @typedef {Object} ListenerDescriptor
 * @property {string} name The name of the event
 * @property {function} callback The callback
 * @property {function} un The function to call to remove the listener
 */

/**
 * Observer class
 */
export default class Observer {
	handlers?: Record<string, any> | null
	/**
	 * Instantiate Observer
	 */
	constructor() {
		/**
		 * @private
		 * @todo Initialise the handlers here already and remove the conditional
		 * assignment in `on()`
		 */
		this.handlers = null
	}
	/**
	 * Attach a handler function for an event.
	 *
	 * @param {string} event Name of the event to listen to
	 * @param {function} fn The callback to trigger when the event is fired
	 * @return {ListenerDescriptor} The event descriptor
	 */
	on(event: string, fn: any) {
		if (!this.handlers) {
			this.handlers = {}
		}

		let handlers = this.handlers[event]
		if (!handlers) {
			handlers = this.handlers[event] = []
		}
		handlers.push(fn)

		// Return an event descriptor
		return {
			name: event,
			callback: fn,
			off: () => this.off(event, fn),
		}
	}

	/**
	 * Remove an event handler.
	 *
	 * @param {string} event Name of the event the listener that should be
	 * removed listens to
	 * @param {function} fn The callback that should be removed
	 */
	off(event: string, fn?: any) {
		if (!this.handlers) {
			return
		}

		const handlers = this.handlers[event]
		let i
		if (handlers) {
			if (fn) {
				for (i = handlers.length - 1; i >= 0; i--) {
					if (handlers[i] === fn) {
						handlers.splice(i, 1)
					}
				}
				if (handlers.length === 0) {
					delete this.handlers[event]
				}
			} else {
				delete this.handlers[event] //handlers.length = 0
			}
		}
	}

	/**
	 * Remove all event handlers.
	 */
	offAll() {
		this.handlers = null
	}

	/**
	 * Attach a handler to an event. The handler is executed at most once per
	 * event type.
	 *
	 * @param {string} event The event to listen to
	 * @param {function} handler The callback that is only to be called once
	 * @return {ListenerDescriptor} The event descriptor
	 */
	once(event: string, handler: any) {
		const fn = (...args: any[]) => {
			handler.apply(this, args)

			setTimeout(() => {
				this.off(event, fn)
			}, 0)
		}
		return this.on(event, fn)
	}

	/**
	 * Manually fire an event
	 *
	 * @param {string} event The event to fire manually
	 * @param {...any} args The arguments with which to call the listeners
	 */
	fireEvent(event: string, ...args: any[]) {
		if (!this.handlers) {
			return
		}

		const handlers = this.handlers[event]
		handlers &&
			handlers.forEach((fn: any) => {
				fn(...args)
			})
	}
}
