import { prop } from 'lodash/fp'
import { ChartPieIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbList,
	BreadcrumbPage,
	Content,
	Header,
	Loader,
	Separator,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'

import DelayedMount from '@/components/DelayedMount'
import DocumentTitle from '@/components/DocumentTitle'
import { BIRevision } from '@/generated'
import intl from '@/locale'
import { Authorization } from '@/modules/Auth/authorization'
import { useBIRevisionQuery } from '@/modules/bi/queries'
import analytic from '@/services/analytics'

import { getDefaultPeriods } from '../../helpers'
import { useDashboardWidgets } from '../../useDashboardWidgets'
import { WidgetPeriods } from '../widgets/types'
import MainDashboardBusinessSection from './MainDashboardBusinessSection'
import MainDashboardMetricsSection from './MainDashboardMetricsSection'
import MainDashboardOperationsSection from './MainDashboardOperationsSection'
import MainDashboardPricingSection from './MainDashboardPricingSection'

export default function MainDashboard() {
	useEffect(() => {
		analytic.logEvent('browse: dashboard')
	}, [])
	const [periods, setPeriods] = useState<WidgetPeriods | null>(null)
	const dashboard = useDashboardWidgets('main')
	const biRevisionDateQuery = useBIRevisionQuery<string | null>({
		refetchOnMount: 'always',
		select: prop('date') as (data: BIRevision | null) => string | null,
	})
	useEffect(() => {
		biRevisionDateQuery.data &&
			!periods &&
			setPeriods(getDefaultPeriods(biRevisionDateQuery.data))
	}, [biRevisionDateQuery.data])
	return (
		<Authorization
			permissions={['ACCESS_BI']}
			fallback={<Navigate to='/p/og' />}
		>
			<DocumentTitle title={intl.get('main_dashboard_title')}>
				<Header className='h-11 items-center border-b px-4 py-2'>
					<ChartPieIcon className='size-4' />
					<Separator orientation='vertical' className='mx-2 h-5' />
					<Breadcrumb className='text-xs'>
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbPage>
									{intl.get('main_dashboard_title')}
								</BreadcrumbPage>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
				</Header>
				<Content className='overflow-y-auto' id='dashboard-container'>
					<div className='size-full overflow-hidden'>
						<div
							className='relative h-full overflow-y-scroll'
							id='dashboard-scrollable-area'
						>
							<Blanket
								className='absolute flex items-center justify-center bg-white/50 backdrop-blur-sm dark:bg-black/50'
								isTinted={!periods && biRevisionDateQuery.isFetching}
							>
								<Loader />
							</Blanket>
							<div>
								<MainDashboardOperationsSection
									dashboard={dashboard}
									commonPeriods={periods}
								/>
								{!!periods && !biRevisionDateQuery.isLoading && (
									<>
										<Authorization permissions={['ACCESS_BI']}>
											<MainDashboardPricingSection
												dashboard={dashboard}
												commonPeriods={periods}
											/>
										</Authorization>
										<Authorization permissions={['ACCESS_BI']}>
											<DelayedMount delay={500} fallback={<div />}>
												<MainDashboardBusinessSection
													dashboard={dashboard}
													commonPeriods={periods}
												/>
											</DelayedMount>
										</Authorization>
										<Authorization permissions={['ACCESS_BI']}>
											<DelayedMount delay={1000} fallback={<div />}>
												<MainDashboardMetricsSection
													dashboard={dashboard}
													commonPeriods={periods}
												/>
											</DelayedMount>
										</Authorization>
									</>
								)}
							</div>
						</div>
					</div>
				</Content>
			</DocumentTitle>
		</Authorization>
	)
}
