import clsx from 'clsx'
import React, { forwardRef } from 'react'

type ProgerssProps = {
	className?: string
	children?: React.ReactNode
}
function _Progress(
	{ className, children }: ProgerssProps,
	ref: React.Ref<HTMLDivElement>
) {
	return (
		<div ref={ref} className={clsx('progress', className)}>
			{children}
		</div>
	)
}
const Progress = forwardRef<HTMLDivElement, ProgerssProps>(_Progress)
export default Progress
