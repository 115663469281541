import clsx from 'clsx'
import React from 'react'
import toast, { Toast } from 'react-hot-toast'

import { Spinner } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'

const typeToIcon: Record<string, React.ReactNode> = {
	success: <CheckIcon />,
	info: <InfoIcon />,
	error: <AlertIcon />,
	warning: <AlertIcon />,
	loading: <Spinner size='large' variant='invert' />,
}

type ToastNotificationProps = {
	title?: string
	text?: string | React.ReactNode
	variant: string
	t: Toast
}
export default function ToastNotification({
	title,
	text,
	variant,
	t,
}: ToastNotificationProps) {
	const icon = typeToIcon?.[variant]
	return (
		<div
			className={clsx(
				`toast-notification ml-10 toast-notification-${variant} ${
					t.visible ? 'toast-notification-enter' : 'toast-notification-leave'
				}`
			)}
		>
			<div className={'toast-notification-with-icon'} role='alert'>
				{!!icon && (
					<span
						className={`toast-notification-icon toast-notification-icon-${variant}`}
					>
						{icon}
					</span>
				)}
				{title && <div className='toast-notification-title'>{title}</div>}
				<div className='toast-notification-message'>{text}</div>
			</div>
			<button
				onClick={() => toast.dismiss(t.id)}
				className='toast-notification-close'
			>
				<CrossIcon className='toast-notification-close-icon' />
			</button>
		</div>
	)
}
