import { AxiosError } from 'axios'
import {
	any,
	filter,
	find,
	flatten,
	map,
	prop,
	propEq,
	propOr,
	sum,
	uniq,
} from 'lodash/fp'
import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import toast from 'react-hot-toast'

import { ajvResolver } from '@hookform/resolvers/ajv'
import { useQueryClient } from '@tanstack/react-query'

import {
	FilterRuleModel,
	PcProductsCountsModel,
	PricingCampaignModel,
	PricingCampaignSettingsModel,
	PricingEngineType,
	ScenarioModel,
	SettingsTemplateType,
} from '@/generated'
import intl from '@/locale'
import { useCheckOptimizationForDeleteExport } from '@/modules/core/hooks/useCheckOptimizationForDeleteExport'
import { useOptimizationIsReadyForChange } from '@/modules/core/hooks/useOptimizationIsReadyForChange'
import {
	useBeforeGroupChange,
	useCommitOptimizationMutation,
} from '@/modules/core/mutations'
import { useAssortmentListQuery } from '@/modules/global-assortment/queries'
import { useModalStore } from '@/modules/modals/store'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import { useScenariosQuery } from '@/modules/scenarios/queries'
import { client } from '@/network/client'
import {
	getResolvedSchema,
	getSchemaDefaultState,
} from '@/tools/json-schema-utils'

import {
	useAssignProductsMutation,
	useCreatePricingCampaignMutation,
	useUpdatePricingCampaignMutation,
} from '../../mutations'
import {
	usePricingCampaignQuery,
	useProductsCountsByPcQuery,
} from '../../queries'
import {
	UsePricingCampaignActions,
	usePricingCampaignActions,
} from './usePricingCampaignActions'

interface PricingCampaignContextType {
	actions: UsePricingCampaignActions & {
		handleEditName: () => void
	}
	draft?: {
		name?: string
		engine?: string
		assignment_filters?: FilterRuleModel[]
	}
	isNew: boolean
	isGlobal: boolean
	isReadyToChange: boolean
	pricingCampaignId?: string
	optimizationGroupId?: string | null
	scenarioId?: string
	isScenarioContext: boolean
	methods: UseFormReturn<PricingCampaignModel>
	pricingCampaign?: PricingCampaignModel
	productsCount: number
	isLoading: boolean
	isPending: boolean
	handleSubmit: () => void
	handleSubmitAndUpdate: () => void
	handleReset: () => void
}
export const PricingCampaignContext = createContext<PricingCampaignContextType>(
	{
		actions: {} as UsePricingCampaignActions & {
			handleEditName: () => void
		},
		draft: undefined,
		isGlobal: false,
		isLoading: false,
		isPending: false,
		isReadyToChange: false,
		isNew: true,
		pricingCampaignId: '',
		isScenarioContext: false,
		methods: {} as UseFormReturn<PricingCampaignModel>,
		handleSubmit: () => {},
		handleSubmitAndUpdate: () => {},
		handleReset: () => {},
		productsCount: 0,
	}
)

export const PricingCampaignProvider = ({
	pricingCampaignId,
	optimizationGroupId,
	scenarioId,
	draft,
	children,
	onFinish,
}: {
	pricingCampaignId?: string
	children: React.ReactNode
	optimizationGroupId?: string | null
	scenarioId?: string | null
	onFinish?: () => void
	draft?: {
		name?: string
		engine?: string
		assignment_filters?: FilterRuleModel[]
	}
}) => {
	const queryClient = useQueryClient()
	const { showModal } = useModalStore()

	const isNew = !pricingCampaignId
	const isGlobal = !scenarioId && !optimizationGroupId

	const scenarionQuery = useScenariosQuery<ScenarioModel | undefined>(
		optimizationGroupId!,
		{
			select: find<ScenarioModel>({ is_main: true }),
			enabled: !!optimizationGroupId,
		}
	)
	const pricingCampaignQuery = usePricingCampaignQuery(pricingCampaignId!, {
		enabled: !!pricingCampaignId,
	})
	const schemaQuery = useSettingsSchemasQuery({
		select: getResolvedSchema(`entity:${SettingsTemplateType.PricingCampaign}`),
	})

	const isReadyToChange = useOptimizationIsReadyForChange(optimizationGroupId)
	const handleUseTemplate = ({
		settings,
	}: {
		settings: PricingCampaignSettingsModel
	}) => {
		if (settings) {
			/**
			 * 	Hack bacause of the useFiealdArray subscribed to the changes of the array not fot the values
			 *	https://github.com/react-hook-form/react-hook-form/blob/master/src/useFieldArray.ts#L122
			 * 	Issue [PP-1314]
			 */
			for (const _key in settings) {
				const key = _key as keyof PricingCampaignSettingsModel
				methods.setValue(
					`settings.${key}`,
					settings[key] as PricingCampaignSettingsModel[typeof key],
					{
						shouldDirty: true,
					}
				)
			}
		}
	}

	const quickActions = usePricingCampaignActions({
		pricingCampaign: pricingCampaignQuery.data,
		optimizationGroupId: optimizationGroupId,
		scenarioId: scenarioId,
		onFinish,
		handleUseTemplate: handleUseTemplate,
	})

	const checkExport = useCheckOptimizationForDeleteExport(optimizationGroupId)
	const commitMutation = useCommitOptimizationMutation()
	const { onMutate } = useBeforeGroupChange(optimizationGroupId)
	const assignProducts = useAssignProductsMutation({
		onMutate,
	})
	const createPricingCampaignMutation = useCreatePricingCampaignMutation({
		onMutate,
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			}),
	})
	const updatePricingCampaignMutation = useUpdatePricingCampaignMutation({
		onMutate,
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			}),
	})
	const methods = useForm<PricingCampaignModel>({
		resolver: ajvResolver(schemaQuery.data!, {
			strict: false,
		}),
	})
	const actions = {
		...quickActions,
		handleEditName: () =>
			showModal('EDIT_PRICING_CAMPAIGN_NAME', {
				value: methods.getValues('name'),
				onChange: (value: string) =>
					methods.setValue('name', value, {
						shouldDirty: true,
					}),
			}),
	}
	const mainScenarioId = scenarionQuery.data?.id

	useEffect(() => {
		if (!isNew && pricingCampaignQuery.data) {
			methods.reset({
				name: pricingCampaignQuery.data?.name,
				start_date: pricingCampaignQuery.data?.start_date,
				end_date: pricingCampaignQuery.data?.end_date,
				settings: pricingCampaignQuery.data?.settings,
			})
		}

		if (isNew && schemaQuery.data) {
			const defaultValues = getSchemaDefaultState(schemaQuery.data)

			if (draft?.engine) {
				const pricing_tactics = {
					[PricingEngineType.RB]: {
						engine: PricingEngineType.RB,
						params: {
							rule_id: 'follow_map',
							rule_params: {},
						},
					},
					[PricingEngineType.ML]: {
						engine: PricingEngineType.ML,
						params: {},
					},
					[PricingEngineType.MD]: {
						engine: PricingEngineType.MD,
						params: {
							periods: [],
						},
					},
				}[draft?.engine || PricingEngineType.ML]
				defaultValues.settings = {
					...(defaultValues.settings || {}),
					pricing_tactics,
				}
			}

			methods.reset({
				name: draft?.name || '',
				start_date: null,
				end_date: null,
				...defaultValues,
			})
		}
	}, [pricingCampaignQuery.data, schemaQuery.data, isNew])

	const confirmChanges = (optimizationGroupsIds: string[]): Promise<boolean> =>
		new Promise((resolve) =>
			showModal('AFFECTED_OPTIMIZATION_GROUPS_MODAL', {
				title: intl.get('global.pc.affect.modal.title'),
				subtitle: intl.get('global.pc.affect.modal.subtitle'),
				optimizationGroupsIds,
				onConfirm: () => resolve(true),
				onCancel: () => resolve(false),
			})
		)
	const createOrUpdatePricingCampaign = async (data: PricingCampaignModel) => {
		if (isGlobal) {
			const affectedOptimizationGroupsFilters = data.settings
				.product_assignments?.length
				? await Promise.all(
						data.settings.product_assignments.map(({ filters }) =>
							client.util
								.getProductsCountsByOg({
									filters,
								})
								.then(map(prop('optimization_group_id')))
						)
					)
				: []
			const affectedOptimizationGroups = flatten(
				uniq([
					...(productsCountQuery.data?.map(prop('optimization_group_id')) ||
						[]),
					...affectedOptimizationGroupsFilters,
				])
			) as string[]
			const confirmed = affectedOptimizationGroups?.length
				? await confirmChanges(affectedOptimizationGroups)
				: true

			if (!confirmed) return

			if (isNew) {
				return await createPricingCampaignMutation.mutateAsync(
					{
						...data,
						optimization_group_id: null,
						scenario_id: null,
					},
					{
						onSuccess: () =>
							toast.success(
								intl.get('toast.entity.created', {
									entity: intl.get('entity.pricing_campaign'),
								}),
								{
									duration: 5000,
									id: 'pricing-campaign',
								}
							),
					}
				)
			} else {
				return await updatePricingCampaignMutation.mutateAsync(
					{
						...data,
						id: pricingCampaignId!,
					},
					{
						onSuccess: () =>
							toast.success(
								intl.get('toast.entity.updated', {
									entity: intl.get('entity.pricing_campaign'),
								}),
								{
									duration: 5000,
									id: 'pricing-campaign',
								}
							),
					}
				)
			}
		} else {
			if (isNew) {
				return await createPricingCampaignMutation.mutateAsync(
					{
						...data,
						optimization_group_id: optimizationGroupId,
						scenario_id: scenarioId || mainScenarioId,
					},
					{
						onSuccess: () =>
							toast.success(
								intl.get('toast.entity.created', {
									entity: intl.get('entity.pricing_campaign'),
								}),
								{
									duration: 5000,
									id: 'pricing-campaign',
								}
							),
					}
				)
			} else {
				return await updatePricingCampaignMutation.mutateAsync(
					{
						...data,
						id: pricingCampaignId!,
					},
					{
						onSuccess: () =>
							toast.success(
								intl.get('toast.entity.updated', {
									entity: intl.get('entity.pricing_campaign'),
								}),
								{
									duration: 5000,
									id: 'pricing-campaign',
								}
							),
					}
				)
			}
		}
	}
	const _handleSubmit = async (data: PricingCampaignModel) => {
		try {
			const pricingCampaign = await createOrUpdatePricingCampaign(data)
			if (pricingCampaign && draft?.assignment_filters?.length) {
				await assignProducts.mutateAsync({
					pricingCampaignId: pricingCampaign.id,
					data: {
						filters: draft?.assignment_filters,
					},
				})
			}
			onFinish?.()
		} catch (error) {
			if (error instanceof AxiosError) {
				toast.error(
					`${intl.get('toast.entity.failed', {
						entity: intl.get('entity.pricing_campaign'),
					})} "${error.response?.data.detail}"`,
					{
						duration: 5000,
						id: 'pricing-campaign',
					}
				)
			} else {
				toast.error(intl.get('fatal_error_title'), {
					duration: 5000,
					id: 'pricing-campaign',
				})
			}
		}
	}
	const _handleSubmitAndUpdate = async (data: PricingCampaignModel) => {
		try {
			const pricingCampaign = await createOrUpdatePricingCampaign(data)
			if (pricingCampaign && draft?.assignment_filters?.length) {
				await assignProducts.mutateAsync({
					pricingCampaignId: pricingCampaign.id,
					data: {
						filters: draft?.assignment_filters,
					},
				})
			}
			if (optimizationGroupId) {
				await commitMutation.mutateAsync(optimizationGroupId)
			}
			onFinish?.()
		} catch (error) {
			toast.error(intl.get('fatal_error_title'), {
				duration: 5000,
				id: 'pricing-campaign',
			})
		}
	}

	const productsCountQuery = useProductsCountsByPcQuery<
		PcProductsCountsModel[]
	>(
		optimizationGroupId
			? {
					filters: [
						{
							name: 'optimization_group_id',
							operation: 'is',
							value: optimizationGroupId,
						},
					],
				}
			: {
					filters: [],
				},
		{
			select: filter(propEq('pricing_campaign_id', pricingCampaignId)),
			enabled: !!pricingCampaignId,
		}
	)
	const filtersProductsCountQuery = useAssortmentListQuery<number | undefined>(
		{
			limit: 1,
			filters: optimizationGroupId
				? [
						{
							name: 'optimization_group_id',
							operation: 'is',
							value: optimizationGroupId,
						},
						...(draft?.assignment_filters || []),
					]
				: draft?.assignment_filters,
		},
		{
			select: prop('total_count'),
			enabled: !!draft?.assignment_filters?.length,
		}
	)
	/**
	 * Calculate products count in this campaign or will be in this campaign
	 */
	const productsCount = useMemo(() => {
		if (isNew) {
			return filtersProductsCountQuery.data || 0
		} else {
			return (
				sum((productsCountQuery.data || []).map(propOr(0, 'product_count'))) ||
				0
			)
		}
	}, [productsCountQuery.data, filtersProductsCountQuery.data])

	const isPending = any(prop('isPending'), [
		createPricingCampaignMutation,
		updatePricingCampaignMutation,
		commitMutation,
	])
	const isLoading = any(prop('isLoading'), [
		pricingCampaignQuery,
		schemaQuery,
		scenarionQuery,
	])

	return (
		<PricingCampaignContext.Provider
			value={{
				actions,
				draft,
				isNew,
				isLoading,
				isPending,
				isGlobal,
				isReadyToChange: isGlobal || Boolean(isReadyToChange),
				pricingCampaignId,
				optimizationGroupId,
				scenarioId: scenarioId || mainScenarioId,
				isScenarioContext: !!scenarioId && scenarioId !== mainScenarioId,
				methods,
				pricingCampaign: pricingCampaignQuery.data,
				productsCount,
				handleSubmit: checkExport(methods.handleSubmit(_handleSubmit)),
				handleSubmitAndUpdate: checkExport(
					methods.handleSubmit(_handleSubmitAndUpdate)
				),
				handleReset: () => methods.reset(),
			}}
		>
			{children}
		</PricingCampaignContext.Provider>
	)
}

export const usePricingCampaignContext = () => {
	return useContext(PricingCampaignContext)
}
