import { all, any, filter, map, merge, pipe, prop } from 'lodash/fp'
import { LayersIcon } from 'lucide-react'
import * as qs from 'qs'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbList,
	BreadcrumbPage,
	Content,
	Header,
	Layout,
	Loader,
	Separator,
	StateButton,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import FilterIcon from '@cmpkit/icon/lib/glyph/filter'
import { ValueType } from '@cmpkit/query-builder'

import CompactSearch from '@/components/CompactSearch'
import { encodeComplexQuery } from '@/components/data-grid/helpers'
import { dialog } from '@/components/dialogs'
import FiltersPopover from '@/components/filter/FiltersPopover'
import NoData from '@/components/placeholders/NoData'
import { FilterRuleModel, SavedFilterModel } from '@/generated'
import intl from '@/locale'
import { useTableConfigStore } from '@/modules/global-assortment/store'
import { useModalStore } from '@/modules/modals/store'
import {
	useDeleteGlobalFilterMutation,
	useDeletePersonalFilterMutation,
} from '@/modules/preferences/mutations'
import {
	useGlobalFiltersQuery,
	usePersonalFiltersQuery,
} from '@/modules/preferences/queries'
import analytic from '@/services/analytics'
import { compareByOperation } from '@/tools/filtering'
import { actDeleted, errorMessage, successActionMessage } from '@/tools/message'

import { SavedReportsActionsHandlers } from './SavedReportsActionsDropdown'
import SavedReportsTable from './SavedReportsTable'

type SavedReportTableRow = SavedFilterModel & {
	is_global?: boolean
	id: string
}

export default function SavedReportsPage() {
	const { showModal } = useModalStore()
	const queryClient = useQueryClient()
	const { columnsConfig } = useTableConfigStore()
	const [rules, setRules] = useState<FilterRuleModel[]>([])
	const [searchText, setSearchText] = useState('')

	useEffect(() => {
		analytic.logEvent('browse: saved reports')
	}, [])

	/**
	 * Queries
	 */
	const globalFiltersQuery = useGlobalFiltersQuery<SavedReportTableRow[]>({
		select: map(merge({ is_global: true })),
	})
	const personalFiltersQuery = usePersonalFiltersQuery<SavedReportTableRow[]>({
		select: map(merge({ is_global: false })),
	})
	const deleteGlobalFilterMutation = useDeleteGlobalFilterMutation({
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['global-filters'],
			}),
	})
	const deletePersonalFilterMutation = useDeletePersonalFilterMutation({
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['personal-filters'],
			}),
	})

	/**
	 * Filtering functions
	 */
	const filterBySearchText = filter((item: SavedReportTableRow) =>
		searchText.length > 0
			? item?.name
					?.toLocaleLowerCase()
					?.includes?.(searchText.toLocaleLowerCase())
			: true
	)
	const filterByRules = filter((item: SavedReportTableRow) => {
		return all((filter) => {
			return compareByOperation(
				prop(filter.name, item),
				filter.operation,
				filter.value
			)
		}, rules)
	})

	/**
	 * Handlers
	 */
	const handleClearInputClick = () => setSearchText('')
	const onDeleteClick = async ({ is_global, id }: SavedReportTableRow) => {
		const answer = await dialog.confirmDelete({
			title: intl.get('general_detete_confirm_title'),
			text: intl.get('general_detete_confirm_subtitle'),
		})
		if (!answer) return
		try {
			if (is_global) {
				await deleteGlobalFilterMutation.mutateAsync(id)
			} else {
				await deletePersonalFilterMutation.mutateAsync(id)
			}
			toast.success(successActionMessage('Report', actDeleted()), {
				id: 'filters',
			})
		} catch (error) {
			toast.error(errorMessage(), {
				id: 'filters',
			})
		}
	}
	const onEditClick = (report: SavedReportTableRow) =>
		showModal('UPDTAE_ASSORTMENT_REPORT', { report })
	const onDownloadClick = (report: SavedReportTableRow) => {
		showModal('EXPORT_ASSORTMENT', {
			defaultName: report.name,
			columnsConfigs: columnsConfig,
			context: {
				filters: report.params.query,
			},
		})
	}
	const onOpenClick = (report: SavedReportTableRow) =>
		window.open(
			'/p/assortment/products' +
				qs.stringify(
					{
						qf: report.params.query?.length
							? encodeComplexQuery({ filters: report.params.query })
							: undefined,
					},
					{ addQueryPrefix: true }
				)
		)

	/**
	 * Computed values
	 */
	const getDataList = pipe([filterBySearchText, filterByRules])
	const isPending = any(prop('isPending'), [
		deletePersonalFilterMutation,
		deleteGlobalFilterMutation,
	])
	const isLoading = any(prop('isLoading'), [
		globalFiltersQuery,
		personalFiltersQuery,
	])

	const handlers: SavedReportsActionsHandlers = {
		onDeleteClick,
		onEditClick,
		onDownloadClick,
		onOpenClick,
	}

	const rows = getDataList([
		...(globalFiltersQuery.data || []),
		...(personalFiltersQuery.data || []),
	])

	return (
		<Layout className='relative h-full'>
			<Header className='h-11 items-center border-b px-4 py-2'>
				<LayersIcon className='size-4' />
				<Separator orientation='vertical' className='mx-2 h-5' />
				<Breadcrumb className='text-xs'>
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbPage>
								{intl.get('saved_reports.page.title')}
							</BreadcrumbPage>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
			</Header>

			<Layout className='relative h-full overflow-hidden px-4 py-3'>
				<div className='fade-in flex size-full flex-col overflow-hidden'>
					<Blanket
						className='absolute flex flex-col items-center justify-center space-y-5 rounded-lg bg-transparent'
						isTinted={isLoading || isPending}
					>
						<div className='fade-in z-50 rounded-lg bg-white/50 p-5 shadow backdrop-blur-md dark:bg-black/50'>
							<Loader />
						</div>
					</Blanket>
					<div className='mb-3 flex items-center'>
						<div className='mr-auto flex items-center space-x-2'>
							<CompactSearch
								onClear={handleClearInputClick}
								value={searchText}
								onChange={pipe([prop('target.value'), setSearchText])}
								placeholder={intl.get('general_search')}
							/>
							<FiltersPopover
								filters={rules || []}
								fields={[
									{
										value: 'name',
										label: intl.get('report_name'),
										valueType: ValueType.str,
										enum: [],
									},
									{
										value: 'params.description',
										label: intl.get('general_description'),
										valueType: ValueType.str,
										enum: [],
									},
									{
										value: 'is_global',
										label: intl.get('app.is_global'),
										valueType: ValueType.boolean,
										enum: [],
									},
								]}
								onApply={setRules}
							>
								<StateButton
									size='small'
									iconBefore={<FilterIcon />}
									onClear={() => setRules([])}
									active={!!rules.length}
								>
									{`${intl.get('general_filter')}`}{' '}
									{!!rules.length ? `(${rules.length})` : ''}
								</StateButton>
							</FiltersPopover>
						</div>
					</div>
					{rows.length === 0 &&
					globalFiltersQuery.isFetched &&
					personalFiltersQuery.isFetched ? (
						<div className='mx-5 flex h-full rounded-lg border border-solid border-base'>
							<NoData />
						</div>
					) : (
						<Content className='overflow-hidden'>
							<div className='flex h-full flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
								<SavedReportsTable rows={rows} handlers={handlers} />
							</div>
						</Content>
					)}
				</div>
			</Layout>
		</Layout>
	)
}
