import { isEmpty } from 'lodash/fp'
import { PlusIcon, XIcon } from 'lucide-react'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Button, InlineMessage } from '@cmpkit/base'

import { RHFTextField } from '@/components/HookForm'
import { SettingsTemplateType } from '@/generated'
import intl from '@/locale'
import {
	BusinessSettingsCard,
	StopListCard,
	StrateryCard,
} from '@/modules/og-settings'

import PricingCampaignsSection from './PricingCampaignsSection'
import { useTemplateBuilderContext } from './TemplateBuilderProvider'
import TemplateBuilderSection from './TemplateBuilderSection'

export default function TemplateBuilderCenterPanel() {
	const { activeType, setActiveType, methods } = useTemplateBuilderContext()
	return (
		<div className='mobile:border mobile:border-solid mobile:border-neutral-border mobile:p-12 relative flex size-full shrink-0 grow basis-0 flex-col items-center gap-2 self-stretch'>
			<div className='bg-pattern flex size-full w-full flex-col overflow-y-auto'>
				{!isEmpty(methods.formState.errors) && (
					<InlineMessage variant='danger' className='m-3 text-xs font-medium'>
						{intl
							.get('validation_error.full')
							.d('Validation failed. Please check the fields and try again.')}
					</InlineMessage>
				)}
				<div className='fade-in flex w-full flex-col items-start gap-4 p-5'>
					<h3>{intl.get('general_general').d('General')}</h3>
					<div className='w-full'>
						<RHFTextField
							name='name'
							label={intl.get('template.name.label').d('Template name')}
							className='w-full'
							autoComplete='off'
							placeholder={intl
								.get('template.name.placeholder')
								.d('Enter template name')}
						/>
					</div>
					<h3>{intl.get('general_settings')}</h3>
					<div className='grid w-full grid-cols-3 gap-4'>
						<StrategyBlockController
							active={activeType === 'scenario_strategy'}
							onClick={() => setActiveType('scenario_strategy')}
						/>
						<BusinessBlockController
							active={activeType === 'scenario_business_constraints'}
							onClick={() => setActiveType('scenario_business_constraints')}
						/>
						<StopListBlockController
							onClick={() => setActiveType('scenario_stop_list')}
							active={activeType === 'scenario_stop_list'}
						/>
					</div>
					<h3>{intl.get('general_pc')}</h3>
					<div className='grid w-full grid-cols-1 gap-2'>
						<PricingCampaignsBlockController
							active={activeType === 'pricing_campaign'}
							onClick={() => setActiveType('pricing_campaign')}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

function StrategyBlockController({
	active,
	onClick,
}: {
	active: boolean
	onClick: () => void
}) {
	const { control } = useFormContext()
	return (
		<Controller
			name={'body.settings.strategy'}
			control={control}
			render={({ field, fieldState: { error } }) => {
				return (
					<div
						onClick={onClick}
						className={'relative flex h-full min-h-44 flex-col'}
					>
						<TemplateBuilderSection
							id='strategy'
							active={active}
							error={!!error}
							accepts={[SettingsTemplateType.ScenarioStrategy]}
							empty={!field.value}
						>
							{field.value ? (
								<>
									<StrateryCard data={field.value} onClick={() => {}} />
									<Button
										variant='tertiary'
										className='absolute right-5 top-5'
										size='small'
										onClick={() => field.onChange(null)}
										iconBefore={<XIcon />}
									/>
								</>
							) : (
								<div className='inline-flex items-center gap-1 p-3 text-muted'>
									<PlusIcon className='size-4' />{' '}
									{intl.get('settings.straregy.short').d('Strategy')}
								</div>
							)}
						</TemplateBuilderSection>
						{!!error?.message && (
							<div className='text-xs text-danger'>{error?.message}</div>
						)}
					</div>
				)
			}}
		/>
	)
}
function BusinessBlockController({
	active,
	onClick,
}: {
	active: boolean
	onClick: () => void
}) {
	const { control } = useFormContext()
	return (
		<Controller
			name={'body.settings.business'}
			control={control}
			render={({ field, fieldState: { error } }) => {
				return (
					<div
						onClick={onClick}
						className={'relative flex h-full min-h-44 flex-col'}
					>
						<TemplateBuilderSection
							id='business'
							active={active}
							error={!!error}
							accepts={[SettingsTemplateType.ScenarioBusinessConstraints]}
							empty={!field.value}
						>
							{field.value ? (
								<>
									<BusinessSettingsCard data={field.value} onClick={() => {}} />
									<Button
										variant='tertiary'
										className='absolute right-5 top-5'
										size='small'
										onClick={() => field.onChange(null)}
										iconBefore={<XIcon />}
									/>
								</>
							) : (
								<div className='inline-flex items-center gap-1 p-3 text-muted'>
									<PlusIcon className='size-4' />{' '}
									{intl.get('settings.business.short').d('Constrains')}
								</div>
							)}
						</TemplateBuilderSection>
						{!!error?.message && (
							<div className='text-xs text-danger'>{error?.message}</div>
						)}
					</div>
				)
			}}
		/>
	)
}
function StopListBlockController({
	active,
	onClick,
}: {
	active: boolean
	onClick: () => void
}) {
	const { control } = useFormContext()
	return (
		<Controller
			name={'body.settings.stop_list'}
			control={control}
			render={({ field, fieldState: { error } }) => {
				return (
					<div
						onClick={onClick}
						className={'relative flex h-full min-h-44 flex-col'}
					>
						<TemplateBuilderSection
							id='stop_list'
							active={active}
							error={!!error}
							accepts={[SettingsTemplateType.ScenarioStopList]}
							empty={!field.value}
						>
							{field.value ? (
								<>
									<StopListCard data={field.value} onClick={() => {}} />
									<div className='absolute right-5 top-5 flex items-center gap-2'>
										<Button
											variant='tertiary'
											size='small'
											onClick={() => field.onChange(null)}
											iconBefore={<XIcon />}
										/>
									</div>
								</>
							) : (
								<div className='inline-flex items-center gap-1 p-3 text-muted'>
									<PlusIcon className='size-4' />{' '}
									{intl.get('settings.stop_list.short').d('Stop list')}
								</div>
							)}
						</TemplateBuilderSection>
						{!!error?.message && (
							<div className='text-xs text-danger'>{error?.message}</div>
						)}
					</div>
				)
			}}
		/>
	)
}
function PricingCampaignsBlockController({
	active,
	onClick,
}: {
	active: boolean
	onClick: () => void
}) {
	const { control } = useFormContext()
	return (
		<Controller
			name={'body.pricing_campaigns'}
			control={control}
			render={({ field, fieldState: { error } }) => {
				return (
					<TemplateBuilderSection
						id='pricing_campaigns'
						active={active}
						error={!!error}
						accepts={[SettingsTemplateType.PricingCampaign]}
						empty={!field.value}
					>
						<div className='flex flex-col rounded-lg'>
							<PricingCampaignsSection
								data={field.value || []}
								onChange={field.onChange}
							/>
							<div
								onClick={onClick}
								className={'text-mute flex items-center gap-2 rounded-lg p-3'}
							>
								<PlusIcon className='size-4' />
								{intl.get('add_pricing_campaign').d('Add pricing campaign')}
							</div>
							{!!error?.message && (
								<div className='text-xs text-danger'>{error?.message}</div>
							)}
						</div>
					</TemplateBuilderSection>
				)
			}}
		/>
	)
}
