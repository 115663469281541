import fastEq from 'fast-deep-equal'
import {
	any,
	entries,
	every,
	groupBy,
	includes,
	join,
	map,
	mapValues,
	merge,
	omitAll,
	paths,
	pipe,
	prop,
	props,
	uniqBy,
	values,
} from 'lodash/fp'
import { CircleDollarSignIcon, LayersIcon } from 'lucide-react'
import memoizeOne from 'memoize-one'
import * as qs from 'qs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router'

import { useQueryClient } from '@tanstack/react-query'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbList,
	BreadcrumbPage,
	Button,
	Header,
	InlineMessage,
	Layout,
	Loader,
	Pagination,
	Separator,
	TreeNodeType,
	usePagination,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import {
	Column,
	ColumnCheckboxHeaderTemplate,
	ColumnCheckboxRowTemplate,
	DataRow,
	Sort,
} from '@cmpkit/data-table'
import { useDebounce } from '@cmpkit/hooks'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import { FilterRuleEntity, Operators } from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'
import { assignProp, ProxyCell } from '@/components/data-grid/column-helpers'
import AlertsCountField from '@/components/data-grid/custom/AlertsCountField'
import OptimizationGroupField from '@/components/data-grid/custom/OptimizationGroup'
import TreeField from '@/components/data-grid/custom/TreeField'
import {
	BadgeField,
	ChipField,
	ChoiceField,
	FlagField,
} from '@/components/data-grid/fields'
import NumberField from '@/components/data-grid/fields/Number/TableCell'
import {
	decodeComplexQuery,
	encodeComplexQuery,
	fixQuery,
} from '@/components/data-grid/helpers'
import SelectionMessagePanel from '@/components/data-grid/SelectionMessagePanel'
import TableFilters from '@/components/data-grid/TableFilters'
import TextSearchField from '@/components/data-grid/TextSearchField'
import { dialog } from '@/components/dialogs'
import { toAdaptedSchema } from '@/components/filter/adapter'
import NoData from '@/components/placeholders/NoData'
import { getFiledConfig } from '@/components/refinement-bar/helpers'
import TableColumnsManager from '@/components/TableColumnsManager'
import {
	ColumnSchemaModel,
	FilterRuleModel,
	OptimizationAction,
	OptimizationModel,
	PricingCampaignModel,
	PricingLineModel,
	ProductModel,
	ProductsQueryRequestModel,
	SendOptimizationActionsRequestModel,
} from '@/generated'
import intl from '@/locale'
import { useAuthorization } from '@/modules/Auth/authorization'
import {
	AssortmentPricingAlertsButton,
	getAlertAnnotationDataChoices,
	OptimizationsSummaryTrigger,
} from '@/modules/core'
import { FINISHED, LOCKED } from '@/modules/core/constants'
import { getSalesEntitiesTree } from '@/modules/core/helpers'
import {
	useBrandsQuery,
	useCategoriesQuery,
	useOptimizationGroupsQuery,
	useOptimizationsQuery,
	usePricingAlertsAnnotationsQuery,
	useSalesEntitiesQuery,
} from '@/modules/core/queries'
import { toCategoriesTree } from '@/modules/core/utils'
import { useModalStore } from '@/modules/modals/store'
import { PRICING_TYPE_CHOICES } from '@/modules/og-products/constants'
import { unwrapLines } from '@/modules/og-products/data'
import { getEntityFieldValue } from '@/modules/og-products/utils'
import { QuickFiltersBar, SavedFiltersDrodown } from '@/modules/preferences'
import { FIELD_CONFIG } from '@/modules/preferences/field-configs'
import { useStoredFiltersKeys } from '@/modules/preferences/hooks/useStoredFiltersKeys'
import {
	AssignToPricingCampaignDropdown,
	getPcBadgeVariant,
} from '@/modules/pricing-campaigns'
import { getPricingCampaignEngine } from '@/modules/pricing-campaigns/helpers'
import {
	useAssignProductsMutation,
	usePricingCampaignUnassignProducts,
} from '@/modules/pricing-campaigns/mutations'
import { usePricingCampaignsQuery } from '@/modules/pricing-campaigns/queries'
import { SaveReportButton } from '@/modules/saved-reports'
import { client } from '@/network/client'
import analytic from '@/services/analytics'
import Sentry from '@/services/sentry'
import {
	ColumnDefinition,
	useColumnManagerControllerAdapter,
	useColumnsDefinitions,
} from '@/tools/columns'
import { formatNumber } from '@/tools/locale'
import { errorMessage } from '@/tools/message'
import { toOption } from '@/tools/utils'

import { useColumnsSchema } from '../columns'
import { getOptimizationGroupStatus } from '../helpers'
import { useOptimizationUpdateChannel } from '../hooks/useOptimizationUpdateChannel'
import { useBulkOptimizationMutation } from '../mutation'
import { useAssortmentListQuery } from '../queries'
import { useTableConfigStore } from '../store'
import GlobalAssortmentTable from './GlobalAssortmentTable'
import GlobalAssortmentUnassignedItems from './GlobalAssortmentUnassignedItems'
import { OptimizationActionsDropdown } from './OptimizationActionsDropdown'

const linesToProducts = memoizeOne(unwrapLines, fastEq)
const formatOptions = pipe([
	map(paths(['id', 'name'])),
	toOption,
	uniqBy('value'),
])
const parseRowIds = (rowIds: string[]): string[] => {
	return rowIds
		.map((id: string): string => {
			return id.split('__')[2]
		})
		.filter(Boolean)
}
const getSalesLevels = pipe([
	prop('sales_levels'),
	map(paths(['level', 'name'])),
	toOption,
])
type GlobalAssortmentPageProps = {
	type: 'assortment' | 'products'
}
export default function GlobalAssortmentPage({
	type,
}: GlobalAssortmentPageProps) {
	const [selected, setSelected] = useState<string[]>([])
	const [isAllProductsSelected, setAllProductsSelected] =
		useState<boolean>(false)
	const queryClient = useQueryClient()
	const location = useLocation()
	const { showModal } = useModalStore()
	const navigate = useNavigate()
	const schema = useColumnsSchema()
	const [storedQuickFiltersKeys, saveStoredQuickFiltersKeys] =
		useStoredFiltersKeys('quick_filters_keys')
	const [searchText, setSearchText] = useState<string>('')
	const [sort, setSort] = useState<Sort[]>([])
	const searchTextValue: string = useDebounce(searchText, 1000)
	const { checkPermissons } = useAuthorization()
	useOptimizationUpdateChannel()

	useEffect(() => {
		analytic.logEvent('browse: product assortment')
	}, [])

	/**
	 * Computed values
	 */
	const hasPermissionToUpdate = checkPermissons([
		'EDIT_GLOBAL_PRICING_CAMPAIGNS_SETTINGS',
	])
	const hasPermissionToCreate = checkPermissons([
		'CREATE_GLOBAL_PRICING_CAMPAIGNS',
	])
	const locationQuery = useMemo(
		() => qs.parse(location.search, { ignoreQueryPrefix: true }),
		[location.search]
	)
	const { filters = [] } = useMemo(
		(): {
			filters: FilterRuleEntity[]
		} =>
			decodeComplexQuery(locationQuery?.['qf'] as string) || {
				filters: [],
			},
		[location.search]
	)
	const productsFiltersToAssign = useMemo(() => {
		if (isAllProductsSelected) {
			return filters.length
				? filters
				: [
						{
							name: 'sku',
							operation: Operators.IS_NOT_EMPTY,
							value: 1,
						},
					]
		}
		return [
			{
				name: 'sku',
				operation: Operators.IN,
				value: parseRowIds(selected),
			},
		]
	}, [selected, isAllProductsSelected])

	useEffect(() => {
		handleClearSelected()
	}, [location.search])

	const requestBody: ProductsQueryRequestModel = {
		limit: Number(locationQuery?.limit) || 100,
		offset: Number(locationQuery?.offset) || 0,
		filters: fixQuery(filters),
		search: searchTextValue || undefined,
		order_by:
			sort
				?.filter(({ direction }) => direction === 'asc' || direction === 'desc')
				.map(({ columnId, direction }) => ({
					name: columnId,
					direction: direction as 'asc' | 'desc' | undefined,
				})) ?? [],
	}
	const isOutdated = sessionStorage.getItem('outdated') === 'true'

	/**
	 * Queires
	 */
	const salesEntitiesQuery = useSalesEntitiesQuery()

	const { data: categories } = useCategoriesQuery<TreeNodeType[]>(
		{},
		{ select: toCategoriesTree }
	)
	const { data: brands } = useBrandsQuery<DataOption[]>(
		{},
		{ select: formatOptions }
	)
	const { data: optimizationGroups } = useOptimizationGroupsQuery<DataOption[]>(
		{
			select: formatOptions,
		}
	)
	const { data: alerts } = usePricingAlertsAnnotationsQuery({
		select: getAlertAnnotationDataChoices,
	})
	const { data: pricingCampaigns } = usePricingCampaignsQuery<DataOption[]>(
		{},
		{
			select: (data: PricingCampaignModel[]) =>
				data.map((pricingCampaign) => ({
					value: pricingCampaign.id,
					label: pricingCampaign.name,
					variant: getPcBadgeVariant(getPricingCampaignEngine(pricingCampaign)),
				})),
		}
	)
	const optimizations = useOptimizationsQuery({
		refetchOnMount: isOutdated ? 'always' : false,
	})
	const assortmentQuery = useAssortmentListQuery(requestBody, {
		placeholderData: (previousData) => previousData,
		refetchOnMount: isOutdated ? 'always' : false,
	})
	const isSuccess = any(prop('isSuccess'), [assortmentQuery, optimizations])
	const isFetching = any(prop('isFetching'), [assortmentQuery, optimizations])
	useEffect(() => {
		if (isSuccess) {
			sessionStorage.setItem('outdated', 'false')
		}
	}, [isSuccess])

	/**
	 * Mutations
	 */

	const assignProducts = useAssignProductsMutation({
		onError() {
			toast.error(
				intl
					.get('global.pc.assign.error')
					.d('Error while assigning products to pricing campaign')
			)
		},
	})
	const unassignProducts = usePricingCampaignUnassignProducts()
	const bulkAction = useBulkOptimizationMutation({
		onSuccess() {
			setSelected([])
		},
	})

	/**
	 * Calculated
	 */
	const productsCountToRevert = isAllProductsSelected
		? formatNumber(assortmentQuery.data?.total_count || 0)
		: selected.length

	const finishedOgIds = useMemo(
		() =>
			map(
				'optimization_group_id',
				values(optimizations.data).filter(({ status }) => status === FINISHED)
			),
		[optimizations.data]
	)
	const hasFinishedLockedOptimizations = useMemo(
		() =>
			values(optimizations.data).some(
				({ status }) => status === FINISHED || status === LOCKED
			),
		[optimizations.data]
	)
	const isAllSelectedProductsFinished = useMemo(
		() =>
			selected.length &&
			every(
				(id) => includes(id, finishedOgIds),
				map((item) => parseRowId(item).optimization_group_id, selected)
			),
		[selected, finishedOgIds]
	)
	/**
	 * Handlers
	 */
	const handleClearSelected = () => {
		setSelected([])
		setAllProductsSelected(false)
	}
	const handleAllProductsSelection = () => {
		setAllProductsSelected(true)
		setSelected(products)
	}
	const handlePricingCampaignAssignProducts = async (
		campaign: PricingCampaignModel | string
	) => {
		if (campaign === '') {
			const answer = await dialog.confirm({
				title: intl.get('confirm.unassign_pricing_campaigns.title'),
				text: intl.get('confirm.unassign_pricing_campaigns.subtitle'),
			})
			if (answer) {
				analytic.logEvent('table: unassign from pricing campaigns')
				await unassignProducts.mutateAsync({
					filters: productsFiltersToAssign,
				})
				queryClient.invalidateQueries({
					queryKey: ['assortment-list'],
				})
				queryClient.invalidateQueries({
					queryKey: ['products-counts-by-pc'],
				})
				handleClearSelected()
			}
		} else if (typeof campaign !== 'string') {
			try {
				const answer = await dialog.confirm({
					title: intl.get('confirm.assign_to.static.title'),
					text: intl.get('confirm.assign_to.static.subtitle'),
				})
				if (answer) {
					analytic.logEvent('table: assign to pricing campaign', {
						'Assign to': 'Existing',
					})
					await assignProducts.mutateAsync({
						pricingCampaignId: campaign.id,
						data: {
							filters: productsFiltersToAssign,
						},
					})
					queryClient.invalidateQueries({
						queryKey: ['assortment-list'],
					})
					queryClient.invalidateQueries({
						queryKey: ['products-counts-by-pc'],
					})
					handleClearSelected()
				}
			} catch (error) {
				Sentry.captureException(error)
			}
		}
	}
	const handleApplyFilter = useCallback(
		(filters: FilterRuleEntity[]) =>
			navigate(
				qs.stringify(
					{
						...locationQuery,
						offset: 0,
						qf: filters?.length ? encodeComplexQuery({ filters }) : undefined,
					},
					{ addQueryPrefix: true }
				)
			),
		[filters]
	)

	/**
	 * Handler for open modal to download assortment report
	 */
	const assignPricingCampaignProxyAction =
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(callback: (p: any) => void) => (params: any) => {
			client.util
				.getProductsCountsByOg({
					filters: productsFiltersToAssign,
				})
				.then(map(prop('optimization_group_id')))
				.then((optimizationGroupsIds) =>
					setTimeout(() => {
						showModal('AFFECTED_OPTIMIZATION_GROUPS_MODAL', {
							title: intl.get('global.pc.affect.modal.title'),
							subtitle: intl.get('global.pc.affect.modal.subtitle'),
							optimizationGroupsIds:
								optimizationGroupsIds as unknown as string[],
							onConfirm: () => callback(params),
						})
					}, 500)
				)
		}
	const handleExportReportClick = () => {
		analytic.logEvent('global assortment: download assortment report')
		showModal('EXPORT_ASSORTMENT', {
			defaultName: 'Assortment',
			columnsConfigs: columnsConfig.columnsConfig,
			context: {
				filters,
			},
		})
	}
	const handleAssignToExistedPricingCampaign = useCallback(
		assignPricingCampaignProxyAction(handlePricingCampaignAssignProducts),
		[productsFiltersToAssign, showModal]
	)
	const handleAssignToNewPricingCampaign = useCallback(
		assignPricingCampaignProxyAction(
			({
				draft,
			}: {
				optimizationGroupId?: string | null
				scenarioId?: string
				draft?: {
					name?: string
					engine?: string
					assignment_filters?: FilterRuleModel[]
				}
			}) =>
				showModal('PRICING_CAMPAIGN_MODAL', {
					draft: {
						...(draft || {}),
						assignment_filters: productsFiltersToAssign,
					},
				})
		),
		[productsFiltersToAssign, showModal]
	)
	const handleChangePagination = useCallback(
		(paginationMeta: { limit?: number; offset?: number }) => {
			navigate(
				qs.stringify(
					pipe([omitAll(['limit', 'offset']), merge(paginationMeta)])(
						locationQuery
					),
					{
						addQueryPrefix: true,
					}
				)
			)
		},
		[locationQuery]
	)
	const handleRevertPricies = async () => {
		const answer = await dialog.confirm({
			title: intl
				.get('confirm.revert_prices.title')
				.d('Revert to current price'),
			text: intl
				.get('confirm.revet_prices.description')
				.d(
					'The recommended prices will be changed to the current prices. Do you want to proceed?'
				),
			cancelText: intl.get('general_cancel'),
			okText: intl.get('general_revert'),
		})
		if (answer) {
			try {
				if (isAllProductsSelected) {
					const payload = await client.util
						.getProductsCountsByOg({ filters })
						?.then(
							map(({ optimization_group_id }) => ({
								action: OptimizationAction.RevertPrices,
								optimization_group_id: optimization_group_id,
								optimization_id:
									optimizations.data?.[optimization_group_id as string].id,
								filters,
							}))
						)
					if (!payload) {
						return
					}
					return await bulkAction.mutateAsync(
						payload as SendOptimizationActionsRequestModel
					)
				}
				const payload = getRevertPricesPayload(
					selected,
					optimizations.data as Record<string, OptimizationModel>
				)
				if (!payload.length) {
					return
				}
				await bulkAction.mutateAsync(
					payload as SendOptimizationActionsRequestModel
				)
			} catch {
				toast.error(errorMessage(), {
					duration: 3000,
				})
			}
		}
	}

	const cellsConfig = useMemo(
		() => ({
			/* Described by schema.ui_schema.type or schema.type */
			extendFieldsTypes: {
				og: OptimizationGroupField,
				og_status: ChipField,
				pc: BadgeField,
				final_data: NumberField,
				tier: ChoiceField,
				lock: FlagField,
				size: AlertsCountField,
				pricing_type: BadgeField,
				badge: BadgeField,
				chip: ChipField,
				tree: TreeField,
			},
			/* Described by schema schema.name */
			dataChoices: {
				alerts: alerts || [],
				optimization_group_status:
					getOptimizationGroupStatus(
						optimizations.data as Record<string, OptimizationModel>
					) || [],
				pricing_type: PRICING_TYPE_CHOICES,
				category_ids: categories || [],
				brands: brands || [],
				sales_level_id: getSalesLevels(salesEntitiesQuery.data) || [],
				pos_ids: getSalesEntitiesTree(salesEntitiesQuery.data) || [],
				optimization_group_id: optimizationGroups || [],
				pricing_campaign_id: pricingCampaigns || [],
			},
		}),
		[categories, brands, optimizationGroups, pricingCampaigns, optimizations]
	)

	/**
	 * Pagination business logic
	 */
	const pagination = usePagination({
		total: assortmentQuery.data?.total_count ?? 0,
		limit: Number(requestBody.limit),
		offset: Number(requestBody.offset),
		onChange: (props) => {
			if (isAllProductsSelected) {
				setSelected([])
				setAllProductsSelected(false)
			}
			return handleChangePagination(props)
		},
	})

	/**
	 * Modifiers for columns behavior
	 */

	const enrichRenderers = useCallback(assignRenderers(cellsConfig), [
		cellsConfig,
	])
	const columnExtensions = useMemo(
		() => [
			enrichRenderers,
			enrichValueGetters,
			enrichLocked,
			enrichTitle,
			enrichWidth,
			enrichSortable,
		],
		[enrichRenderers]
	)

	/**
	 * Columns business logic formation based on `schema` and `columnExtensions`
	 */
	const columnsDefinitions = useColumnsDefinitions(
		[{ name: 'control' } as ColumnSchemaModel, ...(schema || [])],
		columnExtensions
	)

	/**
	 * Create columns configuration controller
	 */
	const config = useTableConfigStore()
	const columnsConfig = useColumnManagerControllerAdapter(
		config,
		columnsDefinitions
	)

	/**
	 * Columns formation from `columnsConfig` and memoization for performance reasons (to avoid re-rendering)
	 * and add control column to the beginning of the columns list
	 */
	const columns: Column[] = useMemo(
		() =>
			columnsDefinitions.map(({ column }) =>
				column.id === 'control' ? getControlColumn() : new Column(column)
			),
		[columnsDefinitions, columnsConfig.rowHeight]
	)

	/**
	 * Prepare data for tree view
	 */
	const children = useMemo(
		() => groupBy(getNodeId, linesToProducts(assortmentQuery.data?.data || [])),
		[assortmentQuery.data?.data]
	)
	const rows = assortmentQuery.data?.data || []
	const getChildTreeItems = (row: DataRow<PricingLineModel>) => {
		return children[getNodeId(row.content)] || []
	}
	const products = useMemo(
		() =>
			linesToProducts(assortmentQuery.data?.data).map((row) => getRowId(row)),
		[assortmentQuery.data?.data]
	)
	const showSubHeader =
		!!products.length &&
		products.every((id) => selected.includes(id)) &&
		products.length >= (requestBody?.limit || 0)
	const filtersSchema = useMemo(
		() =>
			schema
				?.filter(
					({ name }) => !['optimization_group_status', 'tier'].includes(name)
				)
				// TODO: discuss with the team about the optimization_group_name field
				.map((schema) =>
					schema.name === 'optimization_group_name'
						? {
								...schema,
								name: 'optimization_group_id',
								translation_key: 'field_schema_optimization_group',
								filter_schema: {
									...schema.filter_schema,
									type: 'multiselect',
								},
							}
						: schema
				) || [],
		[schema]
	)
	const footer = (
		<>
			{pagination && (
				<div className='mr-auto flex items-center gap-6'>
					<Pagination pagination={pagination} />
					<div className='inline-flex items-center gap-1 text-xs'>
						<span>{intl.get('app.items_on_page')}:</span>
						<strong data-testid='page-items-count'>
							{formatNumber(assortmentQuery.data?.data?.length || 0)}
						</strong>
						<strong>{intl.get('general_of')}</strong>
						<strong data-testid='total-items-count'>
							{formatNumber(assortmentQuery.data?.total_count || 0)}
						</strong>
					</div>
					{!!assortmentQuery.data?.total_skus && (
						<div className='inline-flex items-center gap-1 text-xs'>
							<span>{intl.get('app.skus_on_page').d('SKUs on page')}:</span>
							<strong data-testid='page-items-flat-count'>
								{formatNumber(products?.length || 0)}
							</strong>
							<strong>{intl.get('general_of')}</strong>
							<strong data-testid='total-items-flat-count'>
								{formatNumber(assortmentQuery.data?.total_skus)}
							</strong>
						</div>
					)}
				</div>
			)}
			<div className='ml-auto flex items-center space-x-2'>
				<Button
					size='small'
					className='px-1 py-0'
					data-testid='download-table'
					onClick={handleExportReportClick}
					iconBefore={<DownloadIcon className='mr-1 text-muted' />}
				>
					{intl.get('app.download_table')}
				</Button>
			</div>
		</>
	)

	const toolbar = (
		<div className='flex w-full items-center justify-between'>
			<div className='flex items-center space-x-1'>
				<TextSearchField value={searchText} onChange={setSearchText} />
				<SavedFiltersDrodown
					onItemClick={(filterItem) => {
						analytic.logEvent('saved-filters: click on filter', {
							from: 'global assortment',
						})
						handleApplyFilter(filterItem.params.query!)
					}}
				/>
				<TableFilters
					enableSaveFilters
					analyticEventPrefix='global assortment:'
					choices={cellsConfig.dataChoices}
					schema={filtersSchema}
					filters={filters ?? []}
					onApply={handleApplyFilter}
				/>
				<div className='h-5 border-l border-solid border-base' />
				<QuickFiltersBar
					storedKeys={storedQuickFiltersKeys}
					onSaveStoredKeys={saveStoredQuickFiltersKeys}
					irremovableKeys={['category_ids', 'brands', 'pricing_campaign_id']}
					ignoredKeys={['optimization_group_status']}
					rules={filters ?? []}
					fieldsConfig={{
						...getFiledConfig(
							map(toAdaptedSchema, filtersSchema),
							cellsConfig.dataChoices
						),
						...FIELD_CONFIG,
					}}
					onApply={handleApplyFilter}
				/>
				{(hasPermissionToUpdate || hasPermissionToCreate) &&
					(!!selected?.length || isAllProductsSelected) && (
						<AssignToPricingCampaignDropdown
							scope={{
								only_global: true,
							}}
							onSelectCampaign={handleAssignToExistedPricingCampaign}
							onCreateCampaign={handleAssignToNewPricingCampaign}
						/>
					)}
			</div>
			<div className='flex items-center gap-1'>
				{!!isAllSelectedProductsFinished && type === 'products' && (
					<Tooltip
						content={intl
							.get('assortment.revert_prices_button.tooltip')
							.d('Revert selected prices from current to new')}
					>
						<Button
							size='small'
							onClick={handleRevertPricies}
							iconBefore={<UndoIcon />}
						>
							{`${intl.get('assortment.revert_prices_button').d('Revert prices')}`}{' '}
							{`(${productsCountToRevert})`}
						</Button>
					</Tooltip>
				)}
				<GlobalAssortmentUnassignedItems />
				<SaveReportButton filters={filters} disabled={!filters?.length} />
				<Separator orientation='vertical' className='mx-1 h-5' />
				<AssortmentPricingAlertsButton />
				<TableColumnsManager columns={columns} config={columnsConfig} />
			</div>
		</div>
	)
	const subHeader = showSubHeader && (
		<SelectionMessagePanel
			selectedProducts={selected?.length}
			totalProducts={assortmentQuery.data?.total_count || 0}
			isAllSelected={isAllProductsSelected}
			onClearSelection={handleClearSelected}
			onSelectAllProducts={handleAllProductsSelection}
		/>
	)
	return (
		<>
			<Header className='h-11 items-center border-b px-4 py-2'>
				{type === 'products' ? (
					<CircleDollarSignIcon className='size-4' />
				) : (
					<LayersIcon className='size-4' />
				)}
				<Separator orientation='vertical' className='mx-2 h-5' />
				<Breadcrumb className='text-xs'>
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbPage>
								{type === 'products'
									? intl.get('app.products')
									: intl.get('assortment.page.title')}
							</BreadcrumbPage>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
				<div className='ml-auto flex items-center'>
					{type === 'products' && (
						<div className='flex content-center space-x-2'>
							<OptimizationActionsDropdown
								rules={filters}
								onApply={handleApplyFilter}
								handleExport={handleExportReportClick}
							/>
							<OptimizationsSummaryTrigger
								disabled={!hasFinishedLockedOptimizations}
							/>
						</div>
					)}
					{type === 'assortment' && (
						<Button
							variant='primary-brand'
							onClick={handleExportReportClick}
							iconBefore={<DownloadIcon />}
						>
							{intl.get('app.download_report')}
						</Button>
					)}
				</div>
			</Header>
			<Layout row className='relative h-full px-4'>
				<Layout className='relative h-full overflow-hidden'>
					<div className='fade-in flex size-full flex-col overflow-hidden'>
						{assortmentQuery.isError && (
							<InlineMessage
								className='rounded-none border-b border-solid border-base'
								variant='danger'
							>
								{intl.get('assortment.table.error')}
							</InlineMessage>
						)}
						<Blanket
							data-testid={isFetching ? 'blanket-visible' : 'blanket-hidden'}
							className='absolute flex flex-col items-center justify-center space-y-5 rounded-lg bg-transparent'
							isTinted={isFetching}
						>
							<div className='fade-in z-50 rounded-lg bg-white/50 p-5 shadow backdrop-blur-md dark:bg-black/50'>
								<Loader />
							</div>
						</Blanket>
						<GlobalAssortmentTable
							selected={selected}
							setSelected={(items) => {
								if (isAllProductsSelected) {
									setAllProductsSelected(false)
								}
								setSelected(items)
							}}
							getRowId={getRowId}
							rowHeightHandler={() => columnsConfig.rowHeight}
							sort={sort}
							getChildTreeItems={getChildTreeItems}
							setSort={setSort}
							rows={rows}
							columns={columns}
							columnsConfig={columnsConfig}
							emptyDataPlaceholder={
								!isFetching && <NoData className='flex-col pt-10' />
							}
							footer={footer}
							toolbar={toolbar}
							subHeader={subHeader}
						/>
					</div>
				</Layout>
			</Layout>
		</>
	)
}

const getNodeId = (row: PricingLineModel | ProductModel) => {
	return join('__', props(['fields.optimization_group_id', 'line_id'], row))
}
const getRowId = (row: PricingLineModel | ProductModel) => {
	return join(
		'__',
		props(['fields.optimization_group_id', 'line_id', 'sku'], row)
	)
}
const parseRowId = (id: string) => {
	const [optimization_group_id, , sku] = id.split('__')
	return { optimization_group_id, sku }
}
const groupProductIdsByOg = (ids: string[]) =>
	pipe(
		map((id: string) => parseRowId(id)),
		groupBy('optimization_group_id'),
		mapValues((values: { sku: string }[]) => values.map((v) => v.sku)),
		entries
	)(ids)
const getRevertPricesPayload = (
	products: string[],
	optimization: Record<string, OptimizationModel>
) => {
	if (!products.length) return
	return groupProductIdsByOg(products)?.map(
		([optimization_group_id, value]: [string, string[]]) => ({
			action: OptimizationAction.RevertPrices,
			optimization_group_id,
			optimization_id: optimization[optimization_group_id]?.id,
			filters: [
				{
					name: 'sku',
					operation: Operators.IN,
					value,
				},
			],
		})
	)
}

function getControlColumn() {
	return new Column({
		id: 'control',
		title: '',
		headerRenderer: () => <ColumnCheckboxHeaderTemplate />,
		renderer: (id, item, row) => {
			return <ColumnCheckboxRowTemplate item={item} row={row} />
		},
		valueGetter: (e) => e.id,
		filterable: false,
		sortable: false,
		width: 60,
		locked: true,
		fixable: false,
	})
}

function assignRenderers({
	dataChoices,
	extendFieldsTypes,
}: {
	dataChoices: Record<string, DataOption[] | TreeNodeType[]>
	extendFieldsTypes: any // eslint-disable-line @typescript-eslint/no-explicit-any
}) {
	return function ({ schema, column }: ColumnDefinition): ColumnDefinition {
		return {
			schema,
			column: {
				...column,
				valueGetter: (row) => getEntityFieldValue(row, schema.name),
				renderer: (value, item) => {
					return (
						<ProxyCell
							originalValue={value}
							value={value}
							row={item}
							schema={schema}
							extendFieldsTypes={extendFieldsTypes}
							choices={dataChoices?.[schema.name]}
						/>
					)
				},
			},
		}
	}
}

const enrichValueGetters = assignProp('valueGetter', {
	sku: (item: PricingLineModel | ProductModel) =>
		(item as ProductModel).sku ?? (item as PricingLineModel).line_id,
	title: (item: PricingLineModel | ProductModel) =>
		(item as ProductModel).title ?? (item as PricingLineModel)?.fields?.name,
	optimization_group_name: (item: PricingLineModel) =>
		item?.fields?.optimization_group_id,
	optimization_group_status: (item: PricingLineModel) =>
		item?.fields?.optimization_group_id,
})
const enrichLocked = assignProp('locked', {
	control: true,
	sku: true,
	title: true,
})
const enrichTitle = assignProp('title', {
	sku: `${intl.get('field_schema_sku')}/${intl.get('field_schema_product_line_id')}`,
	title: `${intl.get('field_schema_title')}/${intl.get('field_schema_product_line_name')}`,
})
const enrichWidth = assignProp('width', {
	control: 60,
	sku: 150,
	title: 300,
	is_locked: 56,
	tier: 100,
})
const enrichSortable = assignProp('sortable', {
	optimization_group_id: false,
	optimization_group_name: false,
	optimization_group_status: false,
})
