import React from 'react'

import { SegmentGroup, SegmentGroupItem } from '@cmpkit/base'
import BurgerIcon from '@cmpkit/icon/lib/glyph/burger'
import CalendarIcon from '@cmpkit/icon/lib/glyph/calendar'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'
import analytic from '@/services/analytics'

export default function ViewSwitcher({
	value,
	onChange,
	analyticEventName,
}: {
	value: string
	onChange: (value: string) => void
	analyticEventName: string
}) {
	return (
		<SegmentGroup>
			<Tooltip
				content={intl
					.get('pc.view.switcher.table.tooltip')
					.d('This view visualise your Pricing campaigns data list view')}
			>
				<SegmentGroupItem
					icon
					active={value === 'table'}
					onClick={() => {
						analytic.logEvent(analyticEventName, {
							mode: 'table',
						})
						onChange('table')
					}}
				>
					<BurgerIcon />
				</SegmentGroupItem>
			</Tooltip>
			<Tooltip
				content={intl
					.get('pc.view.switcher.timeline.tooltip')
					.d('This view visualise your Pricing campaigns timeline view')}
			>
				<SegmentGroupItem
					icon
					active={value === 'timeline'}
					onClick={() => {
						analytic.logEvent(analyticEventName, {
							mode: 'timeline',
						})
						onChange('timeline')
					}}
				>
					<CalendarIcon />
				</SegmentGroupItem>
			</Tooltip>
		</SegmentGroup>
	)
}
