import React from 'react'

import crashImg from '@/assets/img/svg/crashed_block_placeholder.svg'
import intl from '@/locale'

import EmptyState from './EmptyState'

export default function FallbackError() {
	return (
		<div className='my-2.50 flex flex-auto items-center justify-center'>
			<EmptyState
				imageUrl={crashImg}
				imgClasses='h-52'
				wrapperClasses='fade-in'
			>
				<div className='text-center'>
					<p className='text-lg font-bold'>{intl.get('fatal_error_title')}🐞</p>
					<p className='text-muted'>{intl.get('fatal_error_desc')}</p>
				</div>
			</EmptyState>
		</div>
	)
}
