import React from 'react'

import { Button } from '@cmpkit/base'

import intl from '@/locale'

type PopupFooterProps = {
	isDisabledApply?: boolean
	onApply?(): void
	onCancel?(): void
	onRemove?(): void
}
export default function PopupFooter({
	isDisabledApply,
	onApply,
	onCancel,
	onRemove,
}: PopupFooterProps) {
	return (
		<div className='cmp-border-top flex bg-accent-3 px-4 py-2'>
			{onRemove && (
				<Button size='small' onClick={onRemove}>
					{intl.get('app.delete').d('Delete')}
				</Button>
			)}
			<div className='ml-auto flex items-center space-x-1'>
				<Button tabIndex={-2} size='small' onClick={onCancel}>
					{intl.get('app.cancel').d('Cancel')}
				</Button>
				<Button
					tabIndex={-2}
					variant='primary-brand'
					type='submit'
					size='small'
					onClick={onApply}
					disabled={isDisabledApply}
				>
					{intl.get('app.apply').d('Apply')}
				</Button>
			</div>
		</div>
	)
}
