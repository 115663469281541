import { JSONSchemaType } from 'ajv'
import { format } from 'date-fns'
import { filter, find, pipe, prop, set, sortBy } from 'lodash/fp'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
	Button,
	FormError,
	FormGroup,
	FormLabel,
	InlineMessage,
	LinkButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
	Textfield,
} from '@cmpkit/base'
import CalendarIcon from '@cmpkit/icon/lib/glyph/calendar'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import Select, { SingleValue } from '@cmpkit/select'

import { DataOption } from '@/common.types'
import DateInput from '@/components/DateInput'
import ErrorBoundary from '@/components/ErrorBoundary'
import { MarkdownPeriodModel, ScenarioModel } from '@/generated'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import { usePricingCampaignContext } from '@/modules/pricing-campaigns'
import { useScenarioQuery } from '@/modules/scenarios/queries'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'
import {
	asNumber,
	ErrorSchema,
	getEnumOptions,
	getResolvedSchema,
	translateOptions,
	translateSchemaKeys,
} from '@/tools/json-schema-utils'

type MarkdownPeriodsProps = {
	schema?: JSONSchemaType<MarkdownPeriodModel>
	maxDate?: Date
	minDate?: Date
	defaultEndDate?: Date
	periods: MarkdownPeriodModel[]
	onChange(periods: MarkdownPeriodModel[]): void
	error?: ErrorSchema
}
export function MarkdownPeriodsField({
	schema,
	defaultEndDate,
	maxDate,
	minDate,
	periods = [],
	onChange: _onChange,
	error,
}: MarkdownPeriodsProps) {
	const onChange = (data: MarkdownPeriodModel[]) =>
		_onChange(sortBy('start_date', [...data]))
	const handleChange = (index: number, name: string, value: string | number) =>
		onChange(
			periods.map((item, i) => {
				return i === index ? { ...item, ...set(name, value, item) } : item
			})
		)
	const handleAddPeriod = () => {
		analytic.logEvent(
			'settings: pricing campaigns: markdown period: add period'
		)
		const prev = periods[periods.length - 1]
		onChange([
			...periods,
			{
				start_date: prev?.start_date || format(new Date(), DateFormat.system),
				target: {
					name: '',
					value: 0,
				},
			},
		])
	}
	const handleRemoveStep = (index: number) => {
		analytic.logEvent(
			'settings: pricing campaigns: markdown period: delete period'
		)
		onChange(periods.filter((item, i) => i !== index))
	}

	return (
		<>
			<div className='mb-2 flex flex-col space-y-2 border-b border-solid'>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead className='w-full bg-transparent' />
						</TableRow>
					</TableHeader>
					<TableBody>
						{periods.length > 0 ? (
							periods.map((item: MarkdownPeriodModel, index: number) => {
								const endDate = periods?.[index + 1]?.start_date
									? format(
											new Date(periods?.[index + 1]?.start_date),
											DateFormat.default
										)
									: maxDate
										? format(maxDate, DateFormat.default)
										: defaultEndDate
											? format(defaultEndDate, DateFormat.default)
											: '∞'
								return (
									<PeriodItem
										schema={schema}
										maxDate={maxDate}
										minDate={minDate}
										errorSchema={error?.[index]}
										key={`${index}_${item.start_date}_${endDate}`}
										endDate={endDate}
										onRemoveClick={handleRemoveStep}
										onChange={handleChange}
										item={item}
										index={index}
									/>
								)
							})
						) : (
							<TableRow>
								<TableCell className='border-none'>
									<div className='p-5 text-center text-muted'>
										{intl.get('general_no_data')}
									</div>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<Button
				variant='tertiary'
				disabled={periods.length >= 15}
				iconBefore={<PlusIcon />}
				onClick={handleAddPeriod}
			>
				{intl.get('app.add_period').d('Add period')}
			</Button>
		</>
	)
}
type PeriodItemProps = {
	schema?: JSONSchemaType<MarkdownPeriodModel>
	maxDate?: Date
	minDate?: Date
	item: MarkdownPeriodModel
	index: number
	endDate: string
	onRemoveClick(index: number): void
	onChange(index: number, name: string, value?: string | number | null): void
	errorSchema?: ErrorSchema
}
function PeriodItem({
	schema,
	maxDate,
	minDate,
	item,
	index,
	endDate,
	onRemoveClick,
	onChange,
	errorSchema,
}: PeriodItemProps) {
	const availableConstraints = schema?.items?.properties?.target?.oneOf ?? []
	const paramsValue = item.target?.value
	const startDate = item.start_date

	const handleRemoveClick = () => onRemoveClick(index)
	const handleStartDateChange = (date: string | null) =>
		onChange(index, 'start_date', date)
	const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		onChange(index, 'target.value', asNumber(event.target.value))
	const handleStartegyChange = (option: SingleValue<DataOption>) =>
		onChange(index, 'target.name', option?.value)

	const strategiesOptions = availableConstraints.map(
		(schemaChoice: JSONSchemaType<MarkdownPeriodModel>) => ({
			label: intl.get(schemaChoice.title).d(schemaChoice.title),
			value: schemaChoice.properties.name.const,
		})
	)

	const measure = availableConstraints.find(
		({ properties: { name } }: JSONSchemaType<MarkdownPeriodModel>) =>
			item.target.name === name.const
	)?.properties?.value?.measure

	return (
		<TableRow>
			<TableCell className='border-none p-2'>
				<div className='flex gap-2'>
					<FormGroup className='mb-0'>
						<DateInput
							maxDate={maxDate}
							minDate={minDate}
							value={startDate}
							onChange={handleStartDateChange}
						/>
						{errorSchema?.start_date && (
							<FormError>{errorSchema?.start_date?.message}</FormError>
						)}
					</FormGroup>
					<span className='inline-flex h-8 items-center'>{'-'}</span>
					<FormGroup className='mb-0'>
						<Textfield
							value={endDate}
							disabled
							iconAfter={<CalendarIcon className='cursor-pointer' />}
						/>
					</FormGroup>

					<FormGroup className='mb-0 mr-2.5 w-64'>
						<Select
							invalid={errorSchema?.target}
							menuPortalTarget={document.body}
							menuPlacement='auto'
							value={filter({ value: item.target.name }, strategiesOptions)}
							options={strategiesOptions}
							onChange={handleStartegyChange}
						/>
						{errorSchema?.target && (
							<FormError>{intl.get('validation.required_filed')}</FormError>
						)}
					</FormGroup>
					<FormGroup className='mb-0 w-32 shrink-0'>
						<Textfield
							type='number'
							value={paramsValue}
							invalid={
								(!paramsValue && paramsValue != 0) || errorSchema?.target
							}
							onChange={handleValueChange}
							addonAfter={measure}
						/>
						{errorSchema?.target?.value && (
							<FormError>{errorSchema?.target?.value?.message}</FormError>
						)}
					</FormGroup>
					<Button
						variant='tertiary'
						className='ml-auto'
						iconBefore={<CrossIcon />}
						onClick={handleRemoveClick}
					/>
				</div>
			</TableCell>
		</TableRow>
	)
}

export function MarkdownTactic() {
	const { showModal } = useModalStore()
	const { scenarioId, isScenarioContext, optimizationGroupId, isGlobal } =
		usePricingCampaignContext()
	const settingsStrategy = useScenarioQuery<
		ScenarioModel['settings']['strategy']
	>(scenarioId!, {
		select: prop('settings.strategy'),
		refetchOnWindowFocus: 'always',
		enabled: !!scenarioId,
	})
	const schema = useSettingsSchemasQuery({
		select: pipe([
			getResolvedSchema('part:strategy'),
			prop('properties.markdown_strategy'),
			translateSchemaKeys,
		]),
		refetchOnWindowFocus: 'always',
	})

	const targetOptions = translateOptions(
		getEnumOptions(prop('properties.target', schema.data))
	)
	const markdownStrategyLabel =
		find(
			{ value: settingsStrategy.data?.markdown_strategy?.target },
			targetOptions
		)?.label || '-'
	const label = markdownStrategyLabel
	if (isGlobal) {
		return (
			<InlineMessage variant='info' className='text-xs font-medium'>
				{intl
					.get('global.pc.settings.demand_strategy.message')
					.d(
						'Pricing strategy will be inherited from the Optimization group settings.'
					)}
			</InlineMessage>
		)
	}
	return (
		<>
			<div className='flex items-center gap-2'>
				<FormLabel className='inline-flex w-auto text-sm text-muted'>
					{intl.get('pc.settings.markdown.strategy').d('Markdown strategy')}
				</FormLabel>
				<span className=''>
					{intl
						.get('app.grow_metric', {
							label,
						})
						.d(`Grow ${label}`)}
				</span>
				{!isScenarioContext && (
					<LinkButton
						variant='brand'
						onClick={() => {
							showModal('OPTIMIZATION_GROUP_MODAL', {
								optimizationGroupId: optimizationGroupId!,
							})
							analytic.logEvent(
								'settings: pricing campaigns: markdown: change click'
							)
						}}
					>
						{intl.get('app.change').d('Change')}
					</LinkButton>
				)}
			</div>

			{settingsStrategy.data?.markdown_strategy?.target === 'revenue' ? (
				<InlineMessage variant='warning' icon={<InfoIcon />}>
					{intl
						.get('pc.settings.markdown.strategy.revenue.warning')
						.d('Markdown goals aren`t available for Revenue strategy')}
				</InlineMessage>
			) : null}
		</>
	)
}
export function MarkdownPeriodsFieldController({ name }: { name: string }) {
	const { control, watch } = useFormContext()
	const endDate = watch('end_date')
	const periodsSchema = useSettingsSchemasQuery({
		select: pipe([
			getResolvedSchema('part:tactics_md'),
			prop('properties.params.properties.periods'),
		]),
		refetchOnWindowFocus: 'always',
	})

	return (
		<Controller
			name={`${name}.periods`}
			control={control}
			render={({ field: { value, onChange }, fieldState: { error } }) => {
				return (
					<ErrorBoundary>
						<h4 className='mb-1 mt-4'>
							{intl.get('app.markdown_periods.title').d('Markdown periods')}
						</h4>
						<MarkdownPeriodsField
							schema={periodsSchema.data}
							defaultEndDate={endDate ? new Date(endDate) : undefined}
							periods={value || []}
							error={error}
							onChange={onChange}
						/>
					</ErrorBoundary>
				)
			}}
		/>
	)
}
