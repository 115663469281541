import { format } from 'date-fns/fp'
import { all, prop, reduce } from 'lodash/fp'
import React, { useCallback, useMemo, useState } from 'react'

import {
	CellContext,
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	OnChangeFn,
	Row,
	RowSelectionState,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'

import { Button, Radio } from '@cmpkit/base'
import FilterIcon from '@cmpkit/icon/lib/glyph/filter'
import { ValueType } from '@cmpkit/query-builder'

import CompactSearch from '@/components/CompactSearch'
import NumberField from '@/components/data-grid/fields/Number/TableCell'
import FiltersPopover from '@/components/filter/FiltersPopover'
import { FilterRuleModel } from '@/components/filter/types'
import VirtualDataTable from '@/components/tables/VirtualDataTable'
import intl from '@/locale'
import {
	useOptimizationGroupsQuery,
	useOptimizationsQuery,
} from '@/modules/core/queries'
import { OptimizationGroupModel, OptimizationModel } from '@/modules/core/types'
import { isEditableStatus } from '@/modules/core/utils'
import { compareByOperation } from '@/tools/filtering'

import { getWarnings, warningsCellRenderer } from '../helpers'

type RowData = {
	optimization?: OptimizationModel
	group: OptimizationGroupModel
	warnings: string[]
}
export default function SelectOptimizationGroupView({
	selectedGroup,
	setSelectedGroup,
}: {
	selectedGroup: string | null
	setSelectedGroup: (value: string | null) => void
}) {
	const [searchText, setSearchText] = useState('')
	const [rules, setRules] = useState<FilterRuleModel[]>([])
	const optimizationGroupsQuery = useOptimizationGroupsQuery()
	const optimizations = useOptimizationsQuery({
		refetchOnMount: 'always',
	})

	const rows: RowData[] = (optimizationGroupsQuery?.data ?? [])
		.filter((group) =>
			searchText.length > 0
				? group.name
						.toLocaleLowerCase()
						.includes(searchText.toLocaleLowerCase())
				: true
		)
		?.map((group): RowData => {
			const optimization = optimizations?.data?.[group?.id]
			return {
				group,
				optimization,
				warnings: getWarnings(optimization),
			}
		})
		.filter(prop('group'))
		.filter((item) => {
			return all((filter) => {
				return compareByOperation(
					prop(filter.name, item),
					filter.operation,
					filter.value
				)
			}, rules)
		})
	//const columns = getColumns()
	return (
		<>
			<div className='flex items-center justify-between' id='modal-toolbar'>
				<div className='flex items-center space-x-2'>
					<CompactSearch
						onClear={() => setSearchText('')}
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder={intl.get('general_search')}
					/>
					<FiltersPopover
						fields={[
							{
								value: 'group.name',
								label: 'Name',
								valueType: ValueType.str,
								enum: [],
							},
							{
								value: 'warnings',
								label: 'Warnings',
								valueType: ValueType.str,
								enum: [],
							},
							{
								value: 'optimization.total_products',
								label: 'Items',
								valueType: ValueType.number,
								enum: [],
							},
						]}
						filters={rules ?? []}
						dataChoices={{
							warnings: [
								'optimization_is_locked',
								'optimization_should_be_recreated',
								'optimization_is_not_editable',
							].map((value) => ({
								value,
								label: intl.get(value).d(value),
							})),
						}}
						onApply={setRules}
					>
						<Button iconBefore={<FilterIcon />}>
							{intl.get('general_filter')}&nbsp;
							{rules.length ? `(${rules.length})` : ''}
						</Button>
					</FiltersPopover>
				</div>
			</div>
			<div className='flex h-96 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1 text-xs'>
				<TableView
					data={rows}
					selected={selectedGroup ? [selectedGroup] : []}
					setSelected={([g]) => setSelectedGroup(g)}
				/>
			</div>
		</>
	)
}

function TableView({
	data,
	setSelected,
	selected,
}: {
	data: RowData[]
	setSelected(items: string[]): void
	selected: string[]
}) {
	const [sorting, setSorting] = useState<SortingState>([])
	const rowSelection = useMemo(
		() =>
			reduce(
				(acc, id: string) => ({ ...acc, [id]: true }),
				{} as RowSelectionState
			)(selected),
		[selected]
	)
	const setRowSelection = useCallback(
		((updater: (old: RowSelectionState) => RowSelectionState) => {
			return setSelected(
				Object.entries(updater(rowSelection))
					.filter(([, flag]) => flag)
					.map(([id]) => id)
			)
		}) as OnChangeFn<RowSelectionState>,
		[rowSelection, setSelected]
	)

	const columns = useMemo(() => getColumns(), [])
	const enableRowSelection = (row: Row<RowData>) =>
		Boolean(
			!row.original.warnings?.length &&
				row.original.optimization &&
				isEditableStatus(row.original.optimization.status)
		)
	const table = useReactTable<RowData>({
		data,
		columns,
		state: {
			sorting,
			rowSelection,
		},
		enableMultiRowSelection: false,
		enableRowSelection: (row) =>
			Boolean(
				!row.original.warnings?.length &&
					row.original.optimization &&
					isEditableStatus(row.original.optimization.status)
			),
		defaultColumn: {
			minSize: 30,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		getRowId: (row) => row.group.id,
		onSortingChange: setSorting,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return (
		<VirtualDataTable
			getRowClassName={(row) =>
				enableRowSelection(row) ? 'h-8' : 'h-8  cursor-not-allowed'
			}
			table={table}
		/>
	)
}
const columnHelper = createColumnHelper<RowData>()
const getColumns = () => [
	columnHelper.accessor('group.id', {
		id: 'control',
		header: '',
		size: 30,
		cell: ({ row }) => (
			<Radio
				checked={row.getIsSelected()}
				disabled={!row.getCanSelect()}
				onChange={row.getToggleSelectedHandler()}
			/>
		),
	}),
	columnHelper.accessor('group.name', {
		id: 'group.name',
		header: 'Name',
		cell: defaultCell,
	}),
	columnHelper.accessor((row) => row.warnings?.length, {
		id: 'warnings',
		header: 'Warnings',
		cell: ({ row, getValue }) => {
			return warningsCellRenderer(String(getValue()), row.original)
		},
		size: 50,
	}),
	columnHelper.accessor('optimization.total_products', {
		id: 'optimization.total_products',
		header: 'Items',
		cell: (info) => {
			return (
				<DisabledCell disabled={Boolean(info.row.original.warnings?.length)}>
					<NumberField value={info.getValue()} />
				</DisabledCell>
			)
		},
		size: 50,
	}),
	columnHelper.accessor('optimization.last_updated_date', {
		id: 'optimization.last_updated_date',
		header: intl.get('general_last_updated'),
		cell: (info) => {
			const value = info.getValue()
			return (
				<DisabledCell disabled={Boolean(info.row.original.warnings?.length)}>
					{value && format('MM/dd/yyyy hh:mm', new Date(value))}
				</DisabledCell>
			)
		},
		size: 205,
	}),
]

const DisabledCell = ({
	disabled,
	children,
}: {
	disabled: boolean
	children: React.ReactNode
}) => {
	return <span className={disabled ? 'text-disabled' : ''}>{children}</span>
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultCell = (info: CellContext<RowData, any>) => {
	return (
		<DisabledCell disabled={Boolean(info.row.original.warnings?.length)}>
			{info.getValue()}
		</DisabledCell>
	)
}
