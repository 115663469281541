import clsx from 'clsx'
import React from 'react'

import { Result } from '@cmpkit/base'

import noData from '@/assets/img/svg/no-data.svg'
import intl from '@/locale'

interface Props {
	className?: string
	imgClassName?: string
	title?: string
	subtitle?: string
	children?: React.ReactNode
}
export default function NoData({
	className,
	title,
	subtitle,
	children,
	imgClassName,
}: Props) {
	return (
		<Result
			data-testid='no-data'
			className={clsx('m-auto', className)}
			icon={
				<img
					className={clsx('pointer-events-none select-none', imgClassName)}
					alt='No data'
					src={noData}
				/>
			}
			title={title ?? intl.get('no_data')}
			subtitle={subtitle}
		>
			{children}
		</Result>
	)
}
