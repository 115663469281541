import { any, prop } from 'lodash/fp'

import { InlineMessage } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import {
	FilterRuleModel,
	MetricModel,
	MetricsDataUnit,
	OptimizationIDModel,
} from '@/generated'
import intl from '@/locale'
import { useMetricsQuery } from '@/modules/bi/queries'
import { useInterpretabilityStatisticQuery } from '@/modules/core/queries'

import {
	getMetricDiffByType,
	getTypesByMetricSchema,
	MetricType,
	selectMetrics,
} from './helpers'
import { FormattedMetric, MetricRow } from './MetricRow'

export default function OptimizationMetricsWidget({
	queryParams,
	isLoading: isDataLoading,
}: {
	queryParams: {
		optimizations: OptimizationIDModel[]
		filters: FilterRuleModel[]
	} | null
	isLoading: boolean
}) {
	/**Queries */
	const metricsQuery = useMetricsQuery<MetricModel[]>()
	const interpretabilityStatistic = useInterpretabilityStatisticQuery(
		queryParams!,
		{
			enabled: !!queryParams || !!metricsQuery.data || !isDataLoading,
			refetchOnMount: true,
			staleTime: 0,
			select: (data) => {
				return selectMetrics(data?.metrics as MetricType).map(
					(data: [string, MetricsDataUnit]) =>
						formatMetric(data, metricsQuery.data as MetricModel[])
				)
			},
		}
	)

	const isLoading =
		any(prop('isLoading'), [interpretabilityStatistic, metricsQuery]) ||
		isDataLoading
	const isError = any(prop('isError'), [
		interpretabilityStatistic,
		metricsQuery,
	])
	if (isLoading) {
		return <LoadingPlaceholder />
	}
	if (isError) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger' className='w-full'>
				{interpretabilityStatistic.error?.message ||
					intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}

	return (
		<div>
			{!!interpretabilityStatistic.data &&
				!isLoading &&
				interpretabilityStatistic.data.map((item: FormattedMetric) => {
					return <MetricRow key={item.key} item={item} />
				})}
		</div>
	)
}

function LoadingPlaceholder() {
	return (
		<div className='space-y-2'>
			<div className='flex flex-col items-center space-y-2'>
				<div className='flex w-full items-center justify-between'>
					<div className='h-5 w-20 animate-pulse rounded bg-accent-4' />
					<div className='h-5 w-10 animate-pulse rounded bg-accent-4' />
				</div>
				<div className='flex w-full items-center justify-between'>
					<div className='h-5 w-24 animate-pulse rounded bg-accent-4' />
					<div className='h-5 w-12 animate-pulse rounded bg-accent-4' />
				</div>
				<div className='flex w-full items-center justify-between'>
					<div className='h-5 w-20 animate-pulse rounded bg-accent-4' />
					<div className='h-5 w-10 animate-pulse rounded bg-accent-4' />
				</div>
				<div className='flex w-full items-center justify-between'>
					<div className='h-5 w-20 animate-pulse rounded bg-accent-4' />
					<div className='h-5 w-10 animate-pulse rounded bg-accent-4' />
				</div>
			</div>
			<div className='h-44 w-full animate-pulse rounded bg-accent-4' />
		</div>
	)
}

const formatMetric = (
	[key, values]: [string, MetricsDataUnit],
	metricsSchema: MetricModel[]
): FormattedMetric => {
	const metricSchema = metricsSchema?.find(
		({ name }) => name.split('.')[0] === `${key}`
	)
	const { dataType, diffType } = getTypesByMetricSchema(
		metricSchema as MetricModel
	)
	const data = {
		type: dataType,
		values,
	}
	const diff = {
		type: diffType,
		value: getMetricDiffByType(values, diffType),
	}
	return {
		key,
		data,
		diff,
		values,
		description: metricSchema?.description || '',
	}
}
