import React, { useEffect } from 'react'

import { Button } from '@cmpkit/base'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { useCallbackPrompt } from '@/hooks/useCallbackPrompt'
import analytic from '@/services/analytics'

type NavigationPromptProps = {
	/**
	 * Flag to enable/disable prompt
	 */
	when: boolean

	/**
	 * Dialog message
	 */
	message: string

	/**
	 * Dialog title
	 */
	title: string

	/**
	 * Ok button text
	 */
	okText?: string

	/**
	 * Cancel button text
	 */
	cancelText?: string
}

export default function NavigationPrompt({
	when,
	message,
	title,
	okText = 'Ok',
	cancelText = 'Cancel',
}: NavigationPromptProps) {
	const [show, confirmNavigation, cancelNavigation] = useCallbackPrompt(when)

	useEffect(() => {
		if (show) {
			analytic.logEvent('navigation-prompt: show')
		}
	}, [show])

	return (
		<ModalTransition>
			{show && (
				<Modal size='small' testId='navigation-prompt'>
					<ModalHeader>
						<ModalTitle>{title}</ModalTitle>
					</ModalHeader>
					<ModalBody>{message}</ModalBody>
					<ModalFooter className='justify-between space-x-2'>
						<Button
							autoFocus
							data-testid='navigation-prompt-cancel'
							onClick={() => {
								analytic.logEvent('navigation-prompt: cancel')
								cancelNavigation()
							}}
						>
							{cancelText}
						</Button>
						<Button
							variant='primary-warning'
							data-testid='navigation-prompt-confirm'
							onClick={() => {
								analytic.logEvent('navigation-prompt: confirm')
								confirmNavigation()
							}}
						>
							{okText}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}
