import React from 'react'

import { Col, Row } from '@cmpkit/base'

import Chart from '@/components/Chart'
import intl from '@/locale'

import DonutLegend from './DonutLegend'
import { getChartConfig } from './helpers'
import { DonutViewDataItem } from './types'

export type DonutViewProps = {
	data: DonutViewDataItem[]
	total?: number
	formatValue?(value: number): string
	formatSubValue?(value: number): string
}
export default function DonutView({
	data = [],
	total,
	formatValue,
	formatSubValue,
}: DonutViewProps) {
	return (
		<Row>
			<Col sm={5}>
				<Chart config={getChartConfig({ data })} />
			</Col>
			<Col className='flex items-center' sm={7}>
				<div className='w-full space-y-2 text-xs'>
					{total !== undefined && (
						<div className='flex items-center justify-between font-semibold'>
							<span className='flex items-center'>
								{intl.get('app.total').d('Total')}
							</span>
							<span>{formatValue ? formatValue(total) : total}</span>
						</div>
					)}
					<DonutLegend
						formatValue={formatValue}
						formatSubValue={formatSubValue}
						data={data}
					/>
				</div>
			</Col>
		</Row>
	)
}
