import React from 'react'
import { OptionProps } from 'react-select'

import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import { CheckboxOption } from '@cmpkit/select'

export const Note = (props: React.HTMLAttributes<HTMLDivElement>) => (
	<div className='text-xs text-muted' {...props} />
)
export const Form = (props: React.HTMLAttributes<HTMLFormElement>) => (
	<form className='flex flex-col' {...props} />
)

export const DropdownIndicator = () => <SearchIcon className='mr-1' />
export const Text = (props: React.HTMLAttributes<HTMLDivElement>) => (
	<div className='py-2 text-xs font-medium' {...props} />
)
export const Option = (props: OptionProps<string>) => (
	<CheckboxOption {...props} />
)
export const Menu = ({
	innerRef,
	innerProps,
	children,
}: {
	innerRef: React.Ref<HTMLDivElement>
	innerProps: React.HTMLAttributes<HTMLDivElement>
	children: React.ReactNode
}) => (
	<div ref={innerRef} {...innerProps}>
		{children}
	</div>
)
export const Control = ({
	children,
	innerProps,
	innerRef,
}: {
	children: React.ReactNode
	innerProps: React.HTMLAttributes<HTMLDivElement>
	innerRef: React.Ref<HTMLDivElement>
}) => (
	<div ref={innerRef} className='cmp-border-bottom flex p-1' {...innerProps}>
		{children}
	</div>
)

export const ClearOption = ({
	children,
	innerProps,
}: {
	children: React.ReactNode
	innerProps: object
}) => (
	<div
		className='text-link w-full cursor-pointer select-none px-3 py-2 hover:underline'
		{...innerProps}
	>
		{children}
	</div>
)
