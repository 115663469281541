import clsx from 'clsx'
import React from 'react'

interface EmptyStateProps {
	imageUrl?: string
	description?: string | React.ReactNode
	wrapperClasses?: string
	imgClasses?: string
	children?: React.ReactNode
}
export default ({
	imageUrl,
	description,
	wrapperClasses,
	imgClasses,
	children,
}: EmptyStateProps) => {
	return (
		<div
			className={clsx(
				'justify-content-center flex w-full flex-col',
				wrapperClasses
			)}
		>
			{imageUrl && (
				<img alt='Empty' src={imageUrl} className={clsx('mb-4', imgClasses)} />
			)}
			{description && (
				<p className='px-12 text-center text-sm font-medium'>{description}</p>
			)}
			{children && <div className={'px-5'}>{children}</div>}
		</div>
	)
}
