import { addDays, format } from 'date-fns'
import { prop } from 'lodash/fp'
import React from 'react'

import { Badge } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'

import { BIRevenueResponse } from '@/generated'
import intl from '@/locale'
import { useBIRevenueAnalyticQuery } from '@/modules/bi/queries'
import { zipSeries } from '@/modules/bi/utils'
import { usePricingAlertsAnnotationsQuery } from '@/modules/core/queries'
import { getPercentOf } from '@/modules/dashboards/helpers'
import { DateFormat } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetEmptyState from '../../WidgetEmptyState'
import WidgetErrorState from '../../WidgetErrorState'
import { WidgetProps } from '../types'

type DataRow = {
	revenue: number
	assortment: number
}
export default function NumericBlockedForRepricingWidget({
	widget,
	commonPeriods,
	commonFilters,
}: WidgetProps) {
	const start_date = format(
		addDays(new Date(commonPeriods.main.end_date), -14),
		DateFormat.system
	)
	const end_date = commonPeriods.main.end_date
	const biCommonQuery = {
		start_date,
		end_date,
		revenue_start_date: start_date,
		revenue_end_date: end_date,
		label: widget.kind,
		metrics: ['revenue', 'assortment'],
		date_aggregation: null,
		dimensions: [],
		skip_dates: true,
	}

	const { data: annotations } = usePricingAlertsAnnotationsQuery()
	const biQueryConfig = {
		enabled: !!annotations?.length,
		select: (resp: BIRevenueResponse | null) =>
			zipSeries<DataRow>(resp!.meta, resp!.data)[0],
	}
	const totalRevenueQuery = useBIRevenueAnalyticQuery<DataRow>(
		{
			...biCommonQuery,
			filters: [
				{
					name: 'optimization_lag',
					operation: 'eq',
					value: 1,
				},
				{ name: 'is_repricing_today', operation: 'bl', value: true },
				...commonFilters,
			],
		},
		biQueryConfig
	)
	const { isLoading, data, isError } = useBIRevenueAnalyticQuery<{
		assortment: number
		revenue: number
	}>(
		{
			...biCommonQuery,
			filters: [
				{
					name: 'optimization_lag',
					operation: Operators.EQ,
					value: 1,
				},
				{ name: 'is_repricing_today', operation: 'bl', value: true },
				{
					name: 'price_change_status',
					operation: Operators.IS,
					value: 'not_changed',
				},
				{
					name: 'pricing_line_notifications',
					operation: Operators.ANY_OF,
					value: annotations
						?.filter((annotataion) =>
							['critical', 'unassigned'].includes(annotataion.level)
						)
						?.map(prop('id')),
				},
				...commonFilters,
			],
		},
		biQueryConfig
	)
	const { revenue, assortment } = data || {}
	const revenuePercent = getPercentOf(
		revenue || 0,
		totalRevenueQuery?.data?.revenue || 0
	)

	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				title={intl.get('widget.blocked_for_repricing.title')}
				tooltip={intl.get('widget.blocked_for_repricing.tooltip')}
			/>
			<WidgetErrorState isTinted={isError} size='small' />
			<WidgetContent className='mt-auto truncate'>
				{isLoading ? (
					<div className='col-span-2 mt-auto h-10 w-32 animate-pulse rounded-lg bg-accent-3' />
				) : !assortment ? (
					<WidgetEmptyState size='small' />
				) : (
					<div className='mt-auto flex flex-col space-y-1'>
						<div className='flex items-center space-x-2'>
							<span className='truncate text-xs text-muted'>
								{formatNumber(assortment)} SKUs
							</span>
						</div>
						<span className='flex items-center truncate text-3xl font-semibold'>
							{formatNumber(revenue as number, NumberFormats.Compact)}
							{totalRevenueQuery?.data?.revenue && (
								<Badge size='small' className='ml-2'>
									{formatNumber(revenuePercent, NumberFormats.Percent)}
								</Badge>
							)}
						</span>
					</div>
				)}
			</WidgetContent>
		</Widget>
	)
}
