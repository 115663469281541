import clsx from 'clsx'
import { format, intlFormat } from 'date-fns'

import { Button, Card, linkButtonVariants } from '@cmpkit/base'
import { Calendar } from '@cmpkit/calendar'
import { useDisclosure } from '@cmpkit/hooks'
//import CalendarIcon from '@cmpkit/icon/lib/glyph/calendar'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import Popover from '@cmpkit/popover'

import { getDatesLocale } from '@/locale'
import { DateFormat } from '@/tools/dates'

type DateInputProps = {
	value: string
	onChange?: (value: string | null) => void
	placeholder?: string
	minDate?: Date
	maxDate?: Date
	className?: string
	disabled?: boolean
	invalid?: boolean
}
export default function DateInputText({
	value,
	onChange,
	placeholder = 'Set date',
	minDate,
	maxDate,
	className,
	disabled,
	invalid,
}: DateInputProps) {
	const popover = useDisclosure()
	const handleChange = (value: string | Date) => {
		onChange?.(format(new Date(value), DateFormat.system))
		popover.close()
	}
	const date = value ? new Date(value) : undefined
	const content = (
		<Card className='border p-3 shadow'>
			<Calendar
				minDate={minDate}
				maxDate={maxDate}
				date={date}
				locale={getDatesLocale()}
				onChange={handleChange}
				color='var(--cmp-brand)'
			/>
		</Card>
	)
	return (
		<Popover
			inline
			isOpen={popover.isOpen}
			content={content}
			placement='bottom-start'
			onDismiss={popover.close}
		>
			<div
				onClick={popover.toggle}
				className={clsx(
					'inline-flex cursor-pointer items-center gap-1',
					className,
					{
						'pointer-events-none text-disabled': disabled,
						'text-danger': invalid,
					}
				)}
			>
				<span className={linkButtonVariants()}>
					{date ? intlFormat(date) : placeholder}
				</span>
				{!!date && (
					<Button
						size='mini'
						onClick={(e) => {
							e.stopPropagation()
							onChange?.(null)
						}}
						variant='tertiary'
						iconBefore={<CrossIcon />}
					/>
				)}
			</div>
		</Popover>
	)
}
