import { propOr } from 'lodash/fp'

import { useMainSourceProperty } from '@/hooks/data'

import { useProductsStore } from './store'
import { ProductEntity } from './types'
import { getAssortmentItemId } from './utils'

const getMargin = (cost?: number, price?: number) =>
	cost && price ? (1 - cost / price) * 100 : null

export type PriceManagerController = {
	isLocked: FieldState<number | boolean>
	initPrice: FieldState<null | number>
	finalPrice: FieldState<null | number>
	newMargin: number
}
export interface FieldState<T> {
	isChanged: boolean
	value: T
	localValue: T
	originalValue: T
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useFieldState<T extends any>(
	id: string,
	field: string,
	originalValue: T
): FieldState<T> {
	const localValue = useProductsStore(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(state) => (state.localData as any)[id]?.[field] as T
	)
	const isChanged =
		localValue !== undefined &&
		localValue !== null &&
		localValue != originalValue
	return {
		isChanged,
		value: isChanged ? localValue : originalValue,
		localValue,
		originalValue,
	}
}
export function usePriceManager({
	content,
}: {
	content: ProductEntity
}): PriceManagerController {
	const sourceProp = useMainSourceProperty()
	const INIT_FIELD = sourceProp
	const FINAL_FIELD = sourceProp === 'esp' ? 'final_esp' : 'final_price'
	const { cost } = content
	const id = getAssortmentItemId(content)
	const initPrice = useFieldState<null | number>(
		id,
		INIT_FIELD,
		propOr(0, ['fields', INIT_FIELD], content)
	)
	const finalPrice = useFieldState<null | number>(
		id,
		FINAL_FIELD,
		propOr(0, ['fields', FINAL_FIELD], content)
	)

	const isLocked = useFieldState<boolean | number>(
		id,
		'is_locked',
		propOr(0, ['fields', 'is_locked'], content)
	)
	const newMargin = getMargin(cost, Number(finalPrice.value))!

	return {
		isLocked,
		initPrice,
		finalPrice,
		newMargin,
	}
}
