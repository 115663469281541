import { JSONSchemaType } from 'ajv'
import { any, prop, values } from 'lodash/fp'
import React, { useCallback, useMemo } from 'react'

import { Separator, Spinner } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'

import { DataOption } from '@/common.types'
import { localization } from '@/components/filter/localization'
import {
	getAnchoringBehaviorChoices,
	getAnchoringOnConflictChoices,
	getAnchoringTypeChoices,
} from '@/components/FormSections/Anchoring'
import SchemaInterpretator, {
	getSchemaIntertpretationsList,
} from '@/components/SchemaInterpretator'
import {
	ColumnSchemaModel,
	CompetitorModel,
	CompetitorsResponseModel,
	SettingsTemplateModel,
	SettingsTemplateType,
} from '@/generated'
import intl from '@/locale'
import {
	useColumnsSchemaQuery,
	useCompetitorsOptionsQuery,
} from '@/modules/core/queries'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import {
	getEnumOptions,
	getResolvedSchema,
	translateOptions,
	translateSchemaKeys,
} from '@/tools/json-schema-utils'

import { SettingsTemplateModelType } from '../types'

function useSettingsTemplateInterpretationProps({ type }: { type: string }) {
	// eslint-disable-next-line
	const schemaQuery = useSettingsSchemasQuery<JSONSchemaType<any>>({
		select: getResolvedSchema(`template:${type}`),
	})
	const columnsQuery = useColumnsSchemaQuery({
		select: (columns) => {
			return columns.map(
				({ name, translate_key, visible_name }: ColumnSchemaModel) => ({
					value: name,
					label: translate_key
						? intl.get(translate_key).d(visible_name)
						: visible_name,
				})
			)
		},
	})

	const competitorsQuery = useCompetitorsOptionsQuery<DataOption[]>(
		{ limit: 0, order_by: 'name' },
		{
			select: selectCompetitorsAsOptions,
		}
	)
	const targetOptions = translateOptions(
		getEnumOptions(
			prop(
				'properties.body.properties.settings.properties.strategy.properties.demand_strategy.properties.target',
				schemaQuery.data
			) ||
				prop(
					'properties.body.properties.strategy.properties.demand_strategy.properties.target',
					schemaQuery.data
				)
		)
	)
	const protectOptions = translateOptions(
		getEnumOptions(
			prop(
				'properties.body.properties.settings.properties.strategy.properties.demand_strategy.properties.protect',
				schemaQuery.data
			) ||
				prop(
					'properties.body.properties.strategy.properties.demand_strategy.properties.protect',
					schemaQuery.data
				)
		)
	)
	const markdownOptions = translateOptions(
		getEnumOptions(
			prop(
				'properties.body.properties.settings.properties.strategy.properties.markdown_strategy.properties.target',
				schemaQuery.data
			) ||
				prop(
					'properties.body.properties.strategy.properties.markdown_strategy.properties.target',
					schemaQuery.data
				)
		)
	)
	const ruleOptions = translateOptions(
		(
			prop(
				'properties.body.properties.settings.properties.pricing_tactics.properties.params.allOf.0.oneOf',
				schemaQuery.data
			) ||
			prop(
				'properties.body.properties.pricing_tactics.properties.params.allOf.0.oneOf',
				schemaQuery.data
			)
		)
			// eslint-disable-next-line
			?.map((rule: any) => ({
				value: rule.properties.rule_id.const,
				label:
					rule.properties.rule_params.title ||
					`${rule.properties.rule_id.const}_title`,
			})) || []
	)

	const uiSchema = useMemo(() => {
		const price_limits = {
			lower: {
				name: {
					'ui:options': columnsQuery.data || [],
				},
			},
			upper: {
				name: {
					'ui:options': columnsQuery.data || [],
				},
			},
		}
		const pricing_tactics = {
			params: {
				rule_id: {
					'ui:options': ruleOptions || [],
				},
				rule_params: {
					other_competitors: {
						'ui:options': competitorsQuery.data || [],
					},
					main_competitors: {
						'ui:options': competitorsQuery.data || [],
					},
					column: {
						'ui:options': columnsQuery.data || [],
					},
				},
			},
		}

		const strategy = {
			demand_strategy: {
				target: {
					'ui:options': targetOptions,
				},
				protect: {
					'ui:options': protectOptions,
				},
			},
			markdown_strategy: {
				target: {
					'ui:options': markdownOptions,
				},
			},
		}
		const stop_list = {
			custom_rules: {
				name: {
					'ui:options': columnsQuery.data || [],
				},
				operation: {
					'ui:options': values(Operators).map((value) => ({
						value,
						label: localization.getOperatorLabel(value),
					})),
				},
			},
		}
		const anchoring = {
			rules: {
				anchoring: {
					'ui:options': getAnchoringTypeChoices(),
				},
				high_range_behavior: {
					'ui:options': getAnchoringBehaviorChoices(),
				},
				low_range_behavior: {
					'ui:options': getAnchoringBehaviorChoices(),
				},
			},
			on_conflict: {
				'ui:options': getAnchoringOnConflictChoices(),
			},
		}
		const product_assignments = {
			filters: {
				name: {
					'ui:options': columnsQuery.data || [],
				},
				operation: {
					'ui:options': values(Operators).map((value) => ({
						value,
						label: localization.getOperatorLabel(value),
					})),
				},
			},
		}

		return {
			price_limits,
			pricing_tactics,
			strategy,
			stop_list,
			anchoring,
			product_assignments,
			settings: {
				strategy,
				price_limits,
				pricing_tactics,
				stop_list,
				anchoring,
				product_assignments,
			},
		}
	}, [columnsQuery.data, competitorsQuery.data, schemaQuery.data])

	const isLoading = any(prop('isLoading'), [
		competitorsQuery,
		columnsQuery,
		schemaQuery,
	])
	return {
		isLoading,
		uiSchema,
		schema: schemaQuery.data,
	}
}
export function SettingsTemplateGenaratedShortDescription({
	template,
}: {
	template: SettingsTemplateModelType
}) {
	const { schema, uiSchema, isLoading } =
		useSettingsTemplateInterpretationProps({ type: template.template_type })

	const interpretation = useMemo(
		() =>
			getSchemaIntertpretationsList({
				schema: translateSchemaKeys(schema?.properties?.body || {}),
				data: template.body,
				uiSchema,
			}),
		[template.body, schema?.properties?.body]
	)
	const getFieldValue = useCallback(
		(name: string) => {
			const field = interpretation.find(({ key }) => key === name)
			if (field?.resolvedValue) {
				return Array.isArray(field?.resolvedValue)
					? field?.resolvedValue?.map(({ label }) => label).join(', ')
					: (field?.resolvedValue as DataOption)?.label
			} else {
				return field?.currentValue
			}
		},
		[interpretation]
	)
	if (isLoading) {
		return <Spinner />
	}

	switch (template.template_type) {
		case SettingsTemplateType.ScenarioStopList: {
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('stop_list_custom_rules_title')}:{' '}
						{template.body.stop_list.custom_rules?.length}
					</span>
					<Separator orientation='vertical' className='h-4' />
					<span>
						{intl.get('stop_list_default_rules_title')}:{' '}
						{
							Object.values(template.body.stop_list.default_rules).filter(
								Boolean
							)?.length
						}
					</span>
				</div>
			)
		}
		case SettingsTemplateType.ScenarioBusinessConstraints:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('og.settings.business')}:{' '}
						{Object.values(template.body.business).filter(Boolean)?.length}
					</span>
				</div>
			)
		case SettingsTemplateType.PricingCampaignLimits:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('price_limits_lower_title')}:{' '}
						{template.body.price_limits.lower?.length}
					</span>
					<Separator orientation='vertical' className='h-4' />
					<span>
						{intl.get('price_limits_upper_title')}:{' '}
						{template.body.price_limits.upper?.length}
					</span>
					<Separator orientation='vertical' className='h-4' />
					<span>
						{intl.get('price_limits_on_violation_title')}:{' '}
						{template.body.price_limits.extra.on_conflict}
					</span>
				</div>
			)
		case SettingsTemplateType.PricingCampaignRounding:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('rounding_rules_title')}: {template.body.rounding?.length}
					</span>
				</div>
			)
		case SettingsTemplateType.PricingCampaignMinimalSteps:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('minimal_steps').d('Minimal steps')}:{' '}
						{template.body.minimal_steps?.length}
					</span>
				</div>
			)
		case SettingsTemplateType.PricingCampaignTacticsMd:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('engine').d('Engine')}:{' '}
						{template.body.pricing_tactics.engine}
					</span>
					<Separator orientation='vertical' className='h-4' />
					<span>
						{intl.get('markdown_periods')}:{' '}
						{template.body.pricing_tactics.params.periods?.length}
					</span>
				</div>
			)
		case SettingsTemplateType.PricingCampaign:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('engine').d('Engine')}:{' '}
						{template.body.settings?.pricing_tactics?.engine}
					</span>
				</div>
			)
		case SettingsTemplateType.Scenario:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('og_metric_to_grow')}:{' '}
						{getFieldValue('settings.strategy.demand_strategy.target')}
					</span>
					<Separator orientation='vertical' className='h-4' />
					<span>
						{intl.get('og_metric_to_maintain')}:{' '}
						{getFieldValue('settings.strategy.demand_strategy.protect')}
					</span>
					<Separator orientation='vertical' className='h-4' />
					<span>
						{intl.get('general_pc')}: {template.body.pricing_campaigns?.length}
					</span>
				</div>
			)
		case SettingsTemplateType.ScenarioStrategy:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('og_metric_to_grow')}:{' '}
						{getFieldValue('strategy.demand_strategy.target')}
					</span>
					<Separator orientation='vertical' className='h-4' />
					<span>
						{intl.get('og_metric_to_maintain')}:{' '}
						{getFieldValue('strategy.demand_strategy.protect')}
					</span>
				</div>
			)
		case SettingsTemplateType.PricingCampaignAnchoring:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('rules').d('Rules')}:{' '}
						{template.body?.anchoring.rules?.length}
					</span>
				</div>
			)
		case SettingsTemplateType.PricingCampaignTacticsRb:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('rule').d('Rule')}:{' '}
						{getFieldValue('pricing_tactics.params.rule_id')}
					</span>
				</div>
			)
		case SettingsTemplateType.PricingCampaignProductAssignment:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{intl.get('segments').d('Segments')}:{' '}
						{template.body?.product_assignments?.length}
					</span>
				</div>
			)
		default:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>-</span>
				</div>
			)
	}
}

export default function SettingsTemplateGenaratedDescription({
	template,
}: {
	template: Pick<SettingsTemplateModelType, 'template_type' | 'body'>
}) {
	const { schema, uiSchema, isLoading } =
		useSettingsTemplateInterpretationProps({ type: template.template_type })
	if (isLoading) {
		return <Spinner />
	}

	return (
		<SchemaInterpretator
			schema={translateSchemaKeys(schema?.properties?.body || {})}
			uiSchema={uiSchema}
			data={template.body}
		/>
	)
}
const competitorToOption = ({ name }: CompetitorModel): DataOption => ({
	label: name!,
	value: name!,
})
const selectCompetitorsAsOptions = ({ objects }: CompetitorsResponseModel) =>
	objects?.map(competitorToOption) || []

export function SettingsTemplateGeneratedCardContent({
	template,
}: {
	template: SettingsTemplateModel
}) {
	const { schema, uiSchema, isLoading } =
		useSettingsTemplateInterpretationProps({ type: template.template_type })

	const interpretation = useMemo(
		() =>
			getSchemaIntertpretationsList({
				schema: translateSchemaKeys(schema?.properties?.body || {}),
				data: template.body,
				uiSchema,
			}),
		[schema, template.body, uiSchema]
	)
	const getFieldValue = useCallback(
		(name: string) => {
			const field = interpretation.find(({ key }) => key === name)
			if (field?.resolvedValue) {
				return Array.isArray(field?.resolvedValue)
					? field?.resolvedValue?.map(({ label }) => label).join(', ')
					: (field?.resolvedValue as DataOption)?.label
			} else {
				return field?.currentValue
			}
		},
		[interpretation]
	)
	if (isLoading) {
		return <Spinner />
	}

	switch (template.template_type) {
		case SettingsTemplateType.Scenario:
			return (
				<div className='flex flex-col space-y-1 text-xs font-medium'>
					<span>
						{intl.get('og_metric_to_grow')}:{' '}
						{getFieldValue('settings.strategy.demand_strategy.target')}
					</span>
					<span>
						{intl.get('og_metric_to_maintain')}:{' '}
						{getFieldValue('settings.strategy.demand_strategy.protect')}
					</span>
					<span>
						{
							(template.body as { pricing_campaigns: string[] })
								.pricing_campaigns.length
						}{' '}
						{intl.get('general_pc')}
					</span>
				</div>
			)

		default:
			return (
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>-</span>
				</div>
			)
	}
}
