import { addDays, format } from 'date-fns'
import { any, find, prop } from 'lodash/fp'
import React, { useMemo, useState } from 'react'

import CompareIcon from '@cmpkit/icon/lib/glyph/compare'
import Select, { SingleValue } from '@cmpkit/select'

import { DataOption } from '@/common.types'
import intl from '@/locale'
import { useBIAnalyticQuery, useBIRevisionQuery } from '@/modules/bi/queries'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'

import PercentDifferenceBadge from '../../PercentDifferenceBadge'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetPeriods, WidgetProps } from '../types'

const getCurrentPeriodTypeOptions = () => [
	{
		label: intl.get('current_week'),
		value: 'week_key',
	},
	{
		label: intl.get('current_day'),
		value: 'date_key',
	},
]
const getComparedPeriodTypeOptions = () => [
	{
		label: intl.get('prev_week'),
		value: 'week_key',
	},
	{
		label: intl.get('prev_day'),
		value: 'date_key',
	},
]
function getWeeklyPeriods(integrationDate: string) {
	const integration = new Date(integrationDate)
	return {
		main: {
			start_date: format(addDays(integration, -7), DateFormat.system),
			end_date: format(integration, DateFormat.system),
		},
		compared: {
			start_date: format(addDays(integration, -14), DateFormat.system),
			end_date: format(addDays(integration, -7), DateFormat.system),
		},
	}
}
function getDatePeriods(integrationDate: string) {
	const integration = new Date(integrationDate)
	return {
		main: {
			start_date: format(addDays(integration, -1), DateFormat.system),
			end_date: format(addDays(integration, -1), DateFormat.system),
		},
		compared: {
			start_date: format(addDays(integration, -2), DateFormat.system),
			end_date: format(addDays(integration, -2), DateFormat.system),
		},
	}
}
const periodTypeResolvers: Record<string, (d: string) => WidgetPeriods> = {
	week_key: getWeeklyPeriods,
	date_key: getDatePeriods,
}
const selectValue = prop('data[0][0]')
export default function MetricToCompareWidget({
	widget,
	commonFilters,
}: WidgetProps) {
	const METRIC = 'revenue'
	const [periodType, setPeriodType] = useState<string>('week_key')
	const handlePeriodTypeChange = (option: SingleValue<DataOption>) => {
		if (!option?.value) return
		analytic.logEvent(
			'dashboard: bi: switch revenue period in metric to compare',
			{
				value: option.value,
			}
		)
		setPeriodType(option.value)
	}

	/**
	 * Bi Request body
	 */
	const commonQueryParams = {
		label: widget.kind,
		filters: commonFilters,
		start_date: null as unknown as string,
		end_date: null as unknown as string,
		metrics: [METRIC],
		dimensions: [],
		date_aggregation: null,
	}

	/**
	 * Queries
	 */
	const biRevisionDateQuery = useBIRevisionQuery<WidgetPeriods>({
		select: (res) =>
			periodTypeResolvers[periodType](prop('date', res) as string),
	})

	const biQueryConfig = {
		select: selectValue,
		enabled: biRevisionDateQuery.isFetched,
	}

	const mainSummaryQuery = useBIAnalyticQuery<number>(
		{
			...commonQueryParams,
			...(biRevisionDateQuery.data?.main || {}),
		},
		biQueryConfig
	)

	const comparedSummaryQuery = useBIAnalyticQuery<number>(
		{
			...commonQueryParams,
			...(biRevisionDateQuery.data?.compared || {}),
		},
		biQueryConfig
	)

	/**
	 * Calculated data
	 */
	const isLoading = any(prop('isLoading'), [
		mainSummaryQuery,
		comparedSummaryQuery,
		biRevisionDateQuery,
	])
	const isError = any(prop('isError'), [
		mainSummaryQuery,
		comparedSummaryQuery,
		biRevisionDateQuery,
	])

	const diffPercent =
		mainSummaryQuery.data && comparedSummaryQuery.data
			? (mainSummaryQuery.data * 100) / comparedSummaryQuery.data - 100
			: 0
	const currentPeriodTypeOptions = useMemo(
		() => getCurrentPeriodTypeOptions(),
		[]
	)
	const comparedPeriodTypeOptions = useMemo(
		() => getComparedPeriodTypeOptions(),
		[]
	)
	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetLoadingState isTinted={isLoading} />
			<WidgetErrorState isTinted={isError} />
			<WidgetHeader titleAs='h2' title={intl.get('metric.revenue')} />
			<WidgetContent className='mt-5'>
				<div className='flex items-center space-x-1'>
					<Select
						value={find({ value: periodType }, currentPeriodTypeOptions)}
						className='w-full'
						components={{ DropdownIndicator: null }}
						onChange={handlePeriodTypeChange}
						options={currentPeriodTypeOptions}
					/>
					<CompareIcon className='size-4 shrink-0' />
					<Select
						value={find({ value: periodType }, comparedPeriodTypeOptions)}
						className='w-full'
						components={{ DropdownIndicator: null }}
						onChange={handlePeriodTypeChange}
						options={comparedPeriodTypeOptions}
					/>
				</div>
				<div className='mt-8 flex flex-col space-y-2'>
					<div className='flex items-center space-x-2'>
						<PercentDifferenceBadge value={diffPercent} />
						<span className='truncate text-xs text-muted'>
							({intl.get('past')}:{' '}
							{comparedSummaryQuery.data != null &&
								formatNumber(comparedSummaryQuery.data, NumberFormats.Integer)}
							)
						</span>
					</div>
					<div className='flex flex-col'>
						<span className='text-muted'>{intl.get('current')}</span>
						<span className='data-text truncate'>
							{mainSummaryQuery.data != null &&
								formatNumber(mainSummaryQuery.data, NumberFormats.Integer)}
						</span>
					</div>
				</div>
			</WidgetContent>
		</Widget>
	)
}
