import React, { ChangeEvent, useState } from 'react'

import { Input, TreeCheckboxPicker, TreeNodeType } from '@cmpkit/base'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'

import intl from '@/locale'

interface TreeSelectProps {
	placeholder?: string
	tree: TreeNodeType[]
	value: string[]
	onChange(values: string[]): void
}

function TreeSelect({
	placeholder,
	tree,
	value: selected = [],
	onChange: setSelected,
}: TreeSelectProps) {
	const [searchText, setSearchText] = useState<string>('')
	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) =>
		setSearchText(event.target.value)
	const searchFilter = (node: TreeNodeType): boolean => {
		const { name, children } = node
		if (name.toLowerCase().includes(searchText.toLowerCase())) {
			return true
		} else if (children?.length) {
			return children.some((child) => searchFilter(child))
		}
		return false
	}

	const getFilteredData = (
		tree: TreeNodeType[],
		filter: (el: TreeNodeType) => boolean
	): TreeNodeType[] =>
		tree.map((node) => ({
			...node,
			visible: filter(node),
			children: getFilteredData(node?.children || [], filter),
		}))

	const filteredTree = getFilteredData(tree, searchFilter)
	return (
		<>
			<div className='flex items-center border-b border-solid border-base px-3'>
				<Input
					className='rounded-none border-0 bg-accent-1 px-0 py-2'
					value={searchText}
					onChange={handleInputChange}
					placeholder={intl.get('general_search').d('Search')}
				/>
				<SearchIcon />
			</div>
			<div className='flex max-h-[400px] overflow-y-auto p-2'>
				{filteredTree?.length ? (
					<TreeCheckboxPicker
						tree={filteredTree}
						value={selected}
						onChange={setSelected}
					/>
				) : (
					<div className='p-2 text-muted'>{placeholder || 'Not found'}</div>
				)}
			</div>
		</>
	)
}
export default TreeSelect
