import clsx from 'clsx'
import React from 'react'

import Blanket from '@cmpkit/blanket'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import intl from '@/locale'

export default function WidgetErrorState({
	isTinted,
	size = 'medium',
}: {
	isTinted?: boolean
	size?: 'small' | 'medium'
}) {
	const iconSize = {
		small: 40,
		medium: 72,
		large: 120,
	}[size]
	return (
		<Blanket
			className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-lg dark:bg-black/50'
			isTinted={isTinted}
		>
			<div
				className={clsx('flex flex-col items-center space-y-2 text-center', {
					'p-5': size === 'medium',
				})}
			>
				<AlertIcon width={iconSize} height={iconSize} className='fill-danger' />
				<span className='text-danger'>
					{intl.get('error.unrecoverable.title')}
				</span>
			</div>
		</Blanket>
	)
}
