import classnames from 'classnames'
import React from 'react'

import LockIcon from '@cmpkit/icon/lib/glyph/lock'

import analytic from '@/services/analytics'

import { CellPropControlled } from '../fields'

export default function LockField({
	isChanged,
	onChange,
	row,
	value,
	schema,
}: CellPropControlled) {
	const lockCurrentPrice = () => {
		analytic.logEvent('table: lock price')
		onChange?.({
			row,
			schema,
			value: !value,
		})
	}
	return (
		<div
			className={classnames(
				'flex w-full cursor-pointer items-center',
				!value && 'group'
			)}
			onClick={lockCurrentPrice}
		>
			<LockIcon
				height={11}
				width={11}
				className={classnames(
					isChanged && value
						? 'text-brand'
						: value
							? 'text-muted'
							: 'invisible text-accent-5 group-hover:visible'
				)}
			/>
		</div>
	)
}
