import { AxiosError } from 'axios'

import { useQuery } from '@tanstack/react-query'

import {
	SettingsTemplateModel,
	SettingsTemplateQueryModel,
	SettingsTemplateQueryResponseModel,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'

export function useSettingsTemplatesQuery<
	T = SettingsTemplateQueryResponseModel,
>(
	payload: SettingsTemplateQueryModel,
	options?: UseQueryOptions<SettingsTemplateQueryResponseModel, AxiosError, T>
) {
	return useQuery<SettingsTemplateQueryResponseModel, AxiosError, T>({
		queryKey: ['settings-templates', payload],
		queryFn: () => client.settingsTemplate.settingsTemplateQuery(payload),
		...(options || {}),
	})
}
export function useSettingsTemplateQuery<T = SettingsTemplateModel>(
	settingsTemplateId: string,
	options?: UseQueryOptions<SettingsTemplateModel, AxiosError, T>
) {
	return useQuery<SettingsTemplateModel, AxiosError, T>({
		queryKey: ['settings-templates', settingsTemplateId],
		queryFn: () =>
			client.settingsTemplate.settingsTemplateGet(settingsTemplateId),
		...(options || {}),
	})
}
