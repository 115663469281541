import { AnchoringManagementAlertsDrawer } from '@/modules/anchoring-management'
import {
	OptimizationsSummaryDrawer,
	OptimizationSummaryDrawer,
	PricingAlertsDrawer,
} from '@/modules/core'
import { ProductEastsideDrawer } from '@/modules/og-products'

const registry = {
	PRICING_ALERTS: PricingAlertsDrawer,
	ANCHORING_MANAGEMENT_ALERTS: AnchoringManagementAlertsDrawer,
	PRODUCT_EASTSIDE: ProductEastsideDrawer,
	OPTIMIZATION_SUMMARY: OptimizationSummaryDrawer,
	OPTIMIZATIONS_SUMMARY: OptimizationsSummaryDrawer,
}
export default registry
