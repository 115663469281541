import { JSONSchemaType } from 'ajv'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { ajvResolver } from '@hookform/resolvers/ajv'
import { useQueryClient } from '@tanstack/react-query'

import { Button, InlineMessage, Spinner } from '@cmpkit/base'
import {
	Modal,
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { FormProvider, RHFTextArea, RHFTextField } from '@/components/HookForm'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useDublicatePricingCampaignsToScenarioMutation } from '@/modules/pricing-campaigns/mutations'
import analytic from '@/services/analytics'

import { useCreateScenarioMutation } from '../mutations'

const getSchema = (): JSONSchemaType<{
	name: string
	description: string
}> => ({
	type: 'object',
	required: ['name'],
	properties: {
		name: {
			type: 'string',
			title: intl.get('scenario.name'),
			minLength: 4,
			maxLength: 75,
			errorMessage: {
				minLength: intl.get('validation.min_length', { val: 4 }),
				maxLength: intl.get('validation.max_length', { val: 75 }),
			},
		},
		description: {
			type: 'string',
			title: intl.get('scenario.description'),
			maxLength: 500,
			errorMessage: {
				maxLength: intl.get('validation.max_length', { val: 500 }),
			},
		},
	},
})
function CreateScenarioModalContent({
	close,
	pricingCampaignsIds,
}: Omit<CreateScenarioModalProps, 'isOpen'>) {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const optimizationGroupId = useOptimizationGroupId()!
	const dublicate = useDublicatePricingCampaignsToScenarioMutation()
	const schema = useMemo(() => getSchema(), [])

	const createScenarioMutation = useCreateScenarioMutation({
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['scenarios'],
			}),
	})
	const methods = useForm({
		defaultValues: {
			name: '',
			description: '',
		},
		resolver: ajvResolver(schema, { strict: false }),
	})
	const _handleSubmit = async ({
		name,
		description,
	}: {
		name: string
		description: string
	}) => {
		if (createScenarioMutation.isPending) return
		analytic.logEvent('what-if: create scenario: modal: submit', {
			pcs: pricingCampaignsIds?.length,
		})
		try {
			const scenrio = await createScenarioMutation.mutateAsync({
				name,
				description,
				optimization_group_id: optimizationGroupId,
			})
			if (pricingCampaignsIds?.length) {
				// Assign pricing campaigns to scenario
				await dublicate.mutateAsync({
					src_pricing_campaign_ids: pricingCampaignsIds,
					dst_scenario_ids: [scenrio.id],
				})
				queryClient.invalidateQueries({
					queryKey: ['pricing-campaigns'],
				})
			}
			navigate(
				`/p/og/${optimizationGroupId}/scenarios?selectedScenario=${scenrio.id}`
			)
			close()
		} catch (error) {
			//close()
		}
	}
	const handleSubmit = methods.handleSubmit(_handleSubmit)

	return (
		<Modal onClose={close} showCloseButton size='mini' zIndex={1850}>
			<ModalHeader>
				<ModalTitle>
					{intl.get('scenario.create.modal.title').d('Create a scenario')}
				</ModalTitle>
				<ModalDescription>
					{intl
						.get('scenario.create.modal.subtitle')
						.d('Set the name and description')}
				</ModalDescription>
			</ModalHeader>
			<ModalBody className='flex flex-col'>
				{createScenarioMutation.error?.response?.status === 422 && (
					<InlineMessage variant='danger'>
						{intl.get('fatal_error_title')}
					</InlineMessage>
				)}
				{pricingCampaignsIds?.length === 1 && (
					<InlineMessage variant='brand'>
						{intl
							.get('scenario.create.modal.only_one_pc_hint')
							.d('Prediction would be limited by signle PC not the OG ')}
					</InlineMessage>
				)}
				<FormProvider methods={methods} onSubmit={handleSubmit}>
					<RHFTextField
						className='w-full'
						autoComplete='off'
						autoFocus
						disabled={createScenarioMutation.isPending}
						label={schema.properties.name.title}
						placeholder={intl.get('scenario.name.placeholder')}
						name='name'
						required
					/>
					<RHFTextArea
						disabled={createScenarioMutation.isPending}
						rows={5}
						label={schema.properties.description.title}
						name='description'
						placeholder={intl.get('scenario.description.placeholder')}
						className='w-full'
					/>
				</FormProvider>
			</ModalBody>
			<ModalFooter className='justify-between'>
				<Button onClick={close}>{intl.get('general_cancel')}</Button>
				<Button
					variant='primary-brand'
					iconBefore={createScenarioMutation.isPending && <Spinner />}
					disabled={createScenarioMutation.isPending}
					onClick={handleSubmit}
				>
					{intl.get('general_create')}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
interface CreateScenarioModalProps {
	isOpen: boolean
	close(): void
	pricingCampaignsIds: string[]
}
export default function CreateScenarioModal(props: CreateScenarioModalProps) {
	return (
		<ModalTransition>
			{props.isOpen && <CreateScenarioModalContent {...props} />}
		</ModalTransition>
	)
}
