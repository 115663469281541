import intl from '@/locale'
import { MLocalization } from './types'
const getTransaltions = () => ({
	clearSort: intl.get('table.action.clear_sort').d('Clear sort'),
	toggleVisibility: intl
		.get('table.action.toggle_visability')
		.d('Toggle visibility'),
	hideAll: intl.get('table.action.hide_all').d('Hide all'),
	hideColumn: intl.get('table.action.hide_column').d('Hide column'),
	noRecordsToDisplay: intl
		.get('table.state.no_records')
		.d('No records to display'),
	noResultsFound: intl.get('table.state.no_results').d('No results found'),
	pin: intl.get('table.action.pin').d('Pin'),
	pinToLeft: intl.get('table.action.pin_left').d('Pin to left'),
	pinToRight: intl.get('table.action.pin_right').d('Pin to right'),
	showAll: intl.get('table.action.show_all').d('Show all'),
	showAllColumns: intl
		.get('table.action.show_all_columns')
		.d('Show all columns'),
	showHideColumns: intl
		.get('table.action.show_hide_columns')
		.d('Show/Hide columns'),
	sortByColumnAsc: intl.get('table.action.sort_asc').d('Sort ascending'),
	sortByColumnDesc: intl.get('table.action.sort_desc').d('Sort descending'),
	sortedByColumnAsc: intl.get('table.state.sorted_asc').d('Sorted ascending'),
	sortedByColumnDesc: intl
		.get('table.state.sorted_desc')
		.d('Sorted descending'),
	unpin: intl.get('table.action.unpin').d('Unpin'),
	unpinAll: intl.get('table.action.unpin_all').d('Unpin all'),
})
export const en: MLocalization = getTransaltions()
