import { isNil, last, map, pipe, prop } from 'lodash/fp'
import React from 'react'

import { filterPricingAndBusinessMetrics } from '@/components/BI/utils'
import Chart from '@/components/Chart'
import intl from '@/locale'
import { useBIAnalyticQuery, useMetricsQuery } from '@/modules/bi/queries'
import { DateAggregation } from '@/modules/bi/types'
import { sanitiezeResponseBySelectedMetrics } from '@/modules/bi/utils'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetProps } from '../types'

const selectMainMatrics = pipe([
	filterPricingAndBusinessMetrics,
	map(prop('name')),
])

export default function MetricPerfomanceWidget({
	widget,
	commonFilters,
	commonPeriods,
	params,
}: WidgetProps) {
	const { data: metrics, ...metricsQuery } = useMetricsQuery<string[]>({
		placeholderData: [],
		select: selectMainMatrics,
	})
	const commonQueryParams = {
		label: widget.kind,
		...(commonPeriods.main || {
			start_date: null,
			end_date: null,
		}),
		metrics: metrics as string[],
		dimensions: [],
		filters: [...commonFilters],
	}

	const biQuerySummary = useBIAnalyticQuery(
		{
			...commonQueryParams,
			date_aggregation: null,
		},
		{
			enabled: !!metrics?.length,
			select: (res) =>
				sanitiezeResponseBySelectedMetrics(
					res!,
					[params.metric as string],
					false
				),
		}
	)
	const biQuery = useBIAnalyticQuery(
		{
			...commonQueryParams,
			date_aggregation: DateAggregation.DateKey,
		},
		{
			enabled: !!metrics?.length,
			select: (res) =>
				sanitiezeResponseBySelectedMetrics(res!, [params.metric!], true),
		}
	)
	const value = last(last(biQuerySummary?.data?.data))
	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetLoadingState
				isTinted={
					metricsQuery.isLoading ||
					biQuerySummary.isFetching ||
					biQuery.isFetching
				}
			/>
			<WidgetErrorState
				isTinted={
					metricsQuery.isError || biQuerySummary.isError || biQuery.isError
				}
			/>
			<WidgetHeader
				titleAs='h2'
				title={intl.get(params.metric as string)}
				subtitle={intl.get('app.based_on_last_integration')}
			/>
			<WidgetContent className='truncate'>
				{}
				<div className='mt-auto w-full'>
					<span className='data-text text-success'>
						{isNil(value)
							? '-'
							: formatNumber(value, params.format as NumberFormats)}
					</span>
					<Chart
						config={getChartConfig({
							data: (biQuery?.data?.data as unknown as number[]) || [],
							name: intl.get(params.metric as string),
						})}
					/>
				</div>
			</WidgetContent>
		</Widget>
	)
}

const getChartConfig = ({
	data = [],
	name,
}: {
	data: number[]
	name: string
}): Highcharts.Options => ({
	chart: {
		type: 'areaspline',
		height: 160,
		animation: true,
		spacingTop: 0,
		spacingRight: 0,
		spacingBottom: 0,
		spacingLeft: 0,
		plotBorderWidth: 0,
		marginRight: 0,
		marginLeft: 0,
		marginTop: 0,
		marginBottom: 0,
	},
	legend: {
		enabled: false,
	},
	tooltip: {
		outside: true,
		followPointer: false,
		useHTML: true,
		backgroundColor: 'transparent',
		borderRadius: 10,
		borderWidth: 0,
		shadow: false,
		formatter: function () {
			const self = this as unknown as any // eslint-disable-line
			return `<div class="chart-tooltip">
					<div class="w-full flex justify-between mb-2.5"><small>${self.key}</small></div>
					${this.series.name}: <strong>${formatNumber(self.point.y as number)}</strong>
				</div>
			`
		},
	},
	xAxis: {
		tickmarkPlacement: 'on',
		title: {
			text: null,
		},
	},
	yAxis: {
		gridLineWidth: 0,
		title: {
			text: '',
		},
	},

	plotOptions: {
		areaspline: {
			fillOpacity: 0.1,
			marker: {
				enabled: false,
			},
		},
	},

	series: [
		{
			type: 'areaspline',
			color: '#00b557',
			name,
			data,
		},
	],
})
