import { useContext } from 'react'

import { PageNavigatorContext } from './PageNavigator'

export default function PageNavigatorHost() {
	const { getHeader } = useContext(PageNavigatorContext)
	const header = getHeader()

	return header
}
