import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { StoredColumnConfig } from '@/tools/columns'

import { toggleSetItems } from './utils'

/**
 * Zustand store
 */
interface ProductsState {
	localData: Record<string, object>
	selected: string[]
	searchText: string
	expandedTreeItems: string[]
	toggleTree(items: string): void
	toggleSelect(items: string[]): void
	setExpandedTreeItems(items: string[]): void
	setLocalData(items: Record<string, object>): void
	setSearchText(items: string): void
	setSelected(items: string[]): void
	reset(): void
}
export const useProductsStore = create<ProductsState>((set) => ({
	localData: {},
	selected: [],
	searchText: '',
	expandedTreeItems: [],
	reset: () =>
		set((state) => ({
			...state,
			localData: {},
			selected: [],
			searchText: '',
			expandedTreeItems: [],
		})),
	toggleTree: (id) =>
		set((state) => {
			const items = state.expandedTreeItems
			const isTreeExpanded = state.expandedTreeItems.includes(id)
			return {
				...state,
				expandedTreeItems: isTreeExpanded
					? items.filter((b) => b != id)
					: [...items, id],
			}
		}),
	toggleSelect: (value) =>
		set((state) => {
			return {
				...state,
				selected: toggleSetItems(
					state.selected,
					Array.isArray(value) ? value : [value]
				),
			}
		}),
	setExpandedTreeItems: (expandedTreeItems) =>
		set((state) => ({ ...state, expandedTreeItems }), true),
	setLocalData: (localData) => set((state) => ({ ...state, localData }), true),
	setSearchText: (searchText) =>
		set(
			(state) => ({
				...state,
				searchText,
			}),
			true
		),
	setSelected: (selected) =>
		set(
			(state) => ({
				...state,
				selected,
			}),
			true
		),
}))

export interface TableConfigState {
	rowHeight: number
	groupKey: string | null
	columnsWidth: {
		[key: string]: number
	}
	columnsConfig: StoredColumnConfig[]
	setRowHeight: (rowHeight: number) => void
	setGroupedByKey: (groupKey: string | null) => void
	setColumnsWidth: (columnsWidth: { [key: string]: number }) => void
	setColumnsConfig: (columnsConfig: StoredColumnConfig[]) => void
	setConfig: (data: {
		rowHeight: number
		groupKey: string | null
		columnsConfig: StoredColumnConfig[]
		columnsWidth: {
			[key: string]: number
		}
	}) => void
}
export const useTableConfigStore = create<
	TableConfigState,
	[['zustand/persist', TableConfigState]]
>(
	persist(
		(set) => ({
			rowHeight: 34,
			groupKey: null,
			columnsWidth: {},
			columnsConfig: [],
			setRowHeight: (rowHeight) => set((state) => ({ ...state, rowHeight })),
			setGroupedByKey: (groupKey) => set((state) => ({ ...state, groupKey })),
			setColumnsWidth: (columnsWidth) =>
				set((state) => ({ ...state, columnsWidth })),
			setColumnsConfig: (columnsConfig) =>
				set((state) => ({ ...state, columnsConfig }), true),
			setConfig: (config) => set((state) => ({ ...state, ...config }), true),
		}),
		{
			name: 'products_table_config',
		}
	)
)

/**
 * Selectors
 */
export const selectSearchText = (state: ProductsState): string =>
	state.searchText
export const selectSelected = (state: ProductsState): string[] => state.selected
export const selectLocalData = (state: ProductsState): Record<string, object> =>
	state.localData
export const selectExpandedTreeItems = (state: ProductsState): string[] =>
	state.expandedTreeItems
