import { JSONSchemaType } from 'ajv'
import { entries, kebabCase, keys, last, prop, split } from 'lodash/fp'
import React from 'react'

import { MenuList } from '@/components/SelectComponents'
import {
	getEnumOptions,
	JSONSchemaString,
	UISchema,
} from '@/tools/json-schema-utils'

import {
	RHFAsyncMultiSelect,
	RHFCheckbox,
	RHFCreatableSelect,
	RHFSelect,
	RHFTextField,
} from '../HookForm'

type AnyJSONScheme = JSONSchemaType<any> // eslint-disable-line @typescript-eslint/no-explicit-any
interface HookSchemaFormProps {
	schema: AnyJSONScheme
	name: string
	uiSchema?: UISchema
}
interface SchemaFieldProps {
	schema: AnyJSONScheme
	name: string
	required?: string[]
	uiSchema?: UISchema
}
function getFieldLabel(name: string, schema: AnyJSONScheme) {
	return schema.title || last(split('.', name))
}
function getOrderedObjectFields(
	object: Record<string, AnyJSONScheme>,
	order?: string[]
): [string, AnyJSONScheme][] {
	return order?.length === keys(object)?.length
		? order.map((name) => [name, object?.[name]])
		: entries(object)
}
export function SchemaField({
	required,
	schema: _schema,
	name: _name,
	uiSchema: _uiSchema,
}: SchemaFieldProps) {
	const uiSchema = prop(_name, _uiSchema)
	const defaultValue = _schema?.default
	const label = getFieldLabel(_name, _schema)
	const hint = _schema.description
	const innerName = last(split('.', _name))
	const isRequired = required?.includes(innerName as string)
	const commonProps = {
		name: _name,
		label,
		hint,
		defaultValue,
		required: isRequired,
	}
	const getFieldDataChoices = () => {
		return (
			uiSchema?.['ui:options'] ||
			(_schema?.enum ? getEnumOptions(_schema as JSONSchemaString) : []) ||
			[]
		)
	}
	const hasFieldDataChoices = () => {
		return uiSchema?.['ui:options'] || _schema?.enum
	}

	switch (_schema?.type) {
		case 'object': {
			const fields = getOrderedObjectFields(
				_schema.properties,
				uiSchema?.['ui:order']
			)
			return (
				<>
					{fields?.map(([name, schema]) => {
						return (
							<SchemaField
								required={_schema.required || []}
								key={name}
								schema={schema}
								name={`${_name}.${name}`}
								uiSchema={_uiSchema}
							/>
						)
					})}
				</>
			)
		}
		case 'boolean':
			return <RHFCheckbox {...commonProps} />
		case 'number':
		case 'integer':
			return <RHFTextField {...commonProps} type='number' />
		case 'string': {
			if (hasFieldDataChoices()) {
				return (
					<RHFSelect
						instanceId={kebabCase(commonProps.name)}
						{...commonProps}
						options={getFieldDataChoices()}
					/>
				)
			}
			return <RHFTextField {...commonProps} />
		}
		case 'array': {
			if (hasFieldDataChoices()) {
				return (
					<RHFAsyncMultiSelect
						instanceId={kebabCase(commonProps.name)}
						{...commonProps}
						options={getFieldDataChoices()}
						components={{ MenuList }}
					/>
				)
			} else {
				return (
					<RHFCreatableSelect
						instanceId={kebabCase(commonProps.name)}
						{...commonProps}
					/>
				)
			}
		}
		default: {
			if (hasFieldDataChoices()) {
				return (
					<RHFSelect
						instanceId={kebabCase(commonProps.name)}
						{...commonProps}
						options={getFieldDataChoices()}
					/>
				)
			} else {
				return <>{JSON.stringify(_schema, null, 2)}</>
			}
		}
	}
}

export default function HookSchemaForm({
	schema,
	name,
	uiSchema,
}: HookSchemaFormProps) {
	return <SchemaField schema={schema} name={name} uiSchema={uiSchema} />
}
