import { filter, map, pipe, prop, sum } from 'lodash/fp'
import React, { useEffect } from 'react'

import { Button, Spinner } from '@cmpkit/base'
import AlertOctagonIcon from '@cmpkit/icon/lib/glyph/alert-octagon'
import Tooltip from '@cmpkit/tooltip'

import { AlertGroupBy } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { usePricingAlertsQuery } from '@/modules/core/queries'
import { useDrawersStore } from '@/modules/drawers/store'
import analytic from '@/services/analytics'

const getAlertsProductsTotalCount = pipe([
	prop('by_level'),
	map(prop('products')),
	sum,
])

export default function OptimizationGroupPricngAlertsButton() {
	const optimizationGroupId = useOptimizationGroupId()
	const { openDrawer, closeDrawer } = useDrawersStore()
	useEffect(() => {
		return () => {
			closeDrawer('PRICING_ALERTS')
		}
	}, [])
	const { data: pricingAlerts, isLoading } = usePricingAlertsQuery(
		{
			group_by: AlertGroupBy.OptimizationGroupId,
		},
		{
			select: (data) => ({
				...data,
				by_level: filter(
					{ optimization_group_id: optimizationGroupId },
					data.by_level
				),
				by_id: filter(
					{ optimization_group_id: optimizationGroupId },
					data.by_id
				),
			}),
		}
	)
	return (
		<Tooltip content={intl.get('app.pricing_alerts')} placement='top-end'>
			<Button
				onClick={() => {
					analytic.logEvent('alerts: og: click open sidebar')
					openDrawer('PRICING_ALERTS', {
						type: 'byOptimizationGroups',
						optimizationGroupIds: [optimizationGroupId as string],
						drawerClassName: 'assortment-alerts-eastside',
					})
				}}
				className='relative'
				iconBefore={
					<>
						{isLoading ? <Spinner /> : <AlertOctagonIcon />}
						{getAlertsProductsTotalCount(pricingAlerts) > 0 && (
							<span className='absolute -right-1 -top-1 flex size-3 ease-in-out'>
								<span className='absolute inline-flex size-full animate-ping rounded-full bg-danger-100' />
								<span className='relative inline-flex size-3 rounded-full bg-danger' />
							</span>
						)}
					</>
				}
			/>
		</Tooltip>
	)
}
