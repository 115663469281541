import { prop } from 'lodash/fp'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { CustomerRequestDTO, RequestCreateDTO } from '@/generated'
import { client } from '@/network/client'

import { JSD_API_HEADER, JSD_ID } from './constants'

export const jsdOptions = {
	headers: {
		Authorization: 'Basic ' + btoa(JSD_API_HEADER as string),
		'X-Atlassian-Token': 'no-check',
		'X-ExperimentalApi': 'opt-in',
	},
}
type CreateSupportTicketParams = {
	request: RequestCreateDTO
	attachments?: File[]
}
export function useCreateSupportTicketMutation(
	options?: UseMutationOptions<
		CustomerRequestDTO,
		Error,
		CreateSupportTicketParams
	>
) {
	return useMutation<CustomerRequestDTO, Error, CreateSupportTicketParams>({
		mutationFn: async ({ request, attachments }: CreateSupportTicketParams) => {
			const createdRequest = await client.jsd.createCustomerRequest(
				request,
				jsdOptions
			)
			if (attachments?.length && createdRequest.issueId) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const tempAttachments: any = await client.jsd.attachTemporaryFile(
					JSD_ID,
					{ file: [...attachments] } as any, // eslint-disable-line @typescript-eslint/no-explicit-any
					jsdOptions
				)
				await client.jsd.createAttachment(
					createdRequest.issueId,
					{
						additionalComment: {
							body: 'Please find the screenshot and the log file attached.',
						},
						public: true,
						temporaryAttachmentIds: tempAttachments.temporaryAttachments?.map(
							prop('temporaryAttachmentId')
						),
					},
					jsdOptions
				)
			}
			return createdRequest
		},
		...(options || {}),
	})
}
