import clsx from 'clsx'
import {
	differenceInDays,
	endOfDay,
	formatDistanceToNow,
	intlFormat,
	isAfter,
	isBefore,
	max,
	startOfDay,
} from 'date-fns'
import { filter, map, pipe, prop, propEq, sum, values } from 'lodash/fp'
import { MouseEvent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'

import {
	Badge,
	Button,
	FormError,
	FormHint,
	LinkButton,
	Spinner,
} from '@cmpkit/base'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'

import { DateInputText } from '@/components/DateInput'
import LabeledValue from '@/components/LabeledValue'
import UsernameById from '@/components/UsernameById'
import { PricingCampaignModel } from '@/generated'
import intl from '@/locale'
import { usePreproQuery } from '@/modules/core/queries'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'
import { isBetween } from '@/tools/dates'
import { formatNumber } from '@/tools/locale'

import {
	createPricingCampaignLinkForProducts,
	getAssortmentShare,
	getCountsByPc,
	isGlobalPricingCampaign,
} from '../../helpers'
import { useProductsCountsByPcQuery } from '../../queries'
import EngineBadge from '../EngineBadge'
import GlobalPcBadge from '../GlobalPcBadge'
import PricingCampaignActionsDropdown from '../PricingCampaignActionsDropdown'
import { usePricingCampaignContext } from './PricingCampaignProvider'

type ProductsCountProps = {
	productsCount: number
	pricingCampaign: PricingCampaignModel
}
export function PricingCampaignFormInformation() {
	const context = usePricingCampaignContext()

	const pricingCampaign = context?.pricingCampaign
	const engine = context.methods.watch('settings.pricing_tactics.engine')
	return (
		<div className='mt-2 flex items-start gap-10'>
			<LabeledValue label={intl.get('app.engine').d('Engine')}>
				<div className='inline-flex items-center space-x-1'>
					<EngineBadge engine={engine}>
						{intl.get(`campaigns_om_title_${engine}`)}
					</EngineBadge>
					{!context.optimizationGroupId && <GlobalPcBadge />}
				</div>
			</LabeledValue>
			<LabeledValue label={intl.get('last_modified_at').d('Last modified')}>
				{pricingCampaign?.updated_at ? (
					<span className='font-medium'>
						{formatDistanceToNow(new Date(pricingCampaign?.updated_at + 'Z'), {
							addSuffix: true,
						})}
						{pricingCampaign?.updated_at && ` ${intl.get('by').d('by')} `}
						{pricingCampaign?.updated_by && (
							<UsernameById userId={pricingCampaign?.updated_by} />
						)}
					</span>
				) : (
					'-'
				)}
			</LabeledValue>
			<LabeledValue label={intl.get('items_in_pc')}>
				{pricingCampaign ? (
					<ProductsCount
						productsCount={context.productsCount}
						pricingCampaign={pricingCampaign}
					/>
				) : (
					context.productsCount || '-'
				)}
			</LabeledValue>
			{isGlobalPricingCampaign(pricingCampaign) && (
				<LabeledValue
					label={intl.get('global.pc.presented_in').d('Presented in OGs')}
				>
					<AffectInOptimizationGroups pricingCampaign={pricingCampaign} />
				</LabeledValue>
			)}
			<LabeledValue label={intl.get('general_period').d('Period')}>
				<PeriodPicker />
			</LabeledValue>
		</div>
	)
}
export function PricingCampaignFormHeader() {
	const context = usePricingCampaignContext()
	const pricingCampaign = context?.pricingCampaign

	return (
		<div className='ml-auto flex shrink-0 items-center gap-2'>
			<PeriodInformationMessage
				startDate={context.methods.watch('start_date')}
				endDate={context.methods.watch('end_date')}
			/>
			{context.actions && (
				<PricingCampaignActionsDropdown
					pricingCampaign={pricingCampaign}
					actions={context.actions}
					isReadyToChange={context.isReadyToChange}
				>
					<Button
						data-testid={'pc-actions-popover-trigger'}
						variant='tertiary'
						size='small'
						iconBefore={<DotsFilledIcon />}
					/>
				</PricingCampaignActionsDropdown>
			)}
		</div>
	)
}

function AffectInOptimizationGroups({
	pricingCampaign,
}: {
	pricingCampaign?: PricingCampaignModel
}) {
	const { showModal } = useModalStore()
	const { isFetching, data: optimizationGroupsIds } =
		useProductsCountsByPcQuery(
			{},
			{
				enabled: !!pricingCampaign?.id,
				select: pipe([
					filter(propEq('pricing_campaign_id', pricingCampaign?.id)),
					map(prop('optimization_group_id')),
				]),
				refetchOnMount: 'always',
			}
		)

	const handleShow = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		if (!optimizationGroupsIds?.length) return

		showModal('AFFECTED_OPTIMIZATION_GROUPS_MODAL', {
			title:
				intl.get('global.pc.presented_in.modal.title').d('Presented in OGs') +
				` (${optimizationGroupsIds?.length})`,
			subtitle: intl.get('global.pc.presented_in.modal.subtitle'),
			optimizationGroupsIds,
		})
	}
	return !!pricingCampaign?.id ? (
		isFetching ? (
			<Spinner />
		) : (
			<LinkButton onClick={handleShow}>
				{optimizationGroupsIds?.length}
			</LinkButton>
		)
	) : (
		<strong>0</strong>
	)
}
function ProductsCount({
	productsCount = 0,
	pricingCampaign,
}: ProductsCountProps) {
	const handleProductsCountLinkClick = () =>
		analytic.logEvent('settings: pricing campaigns: products count click')
	const { isFetching, data } = useProductsCountsByPcQuery<
		Record<string, number>
	>(
		{
			filters: isGlobalPricingCampaign(pricingCampaign)
				? []
				: [
						{
							name: 'optimization_group_id',
							operation: 'is',
							value: pricingCampaign?.optimization_group_id,
						},
					],
		},
		{
			select: getCountsByPc,
			enabled: !!pricingCampaign?.optimization_group_id,
		}
	)
	const total = sum(values(data)) || 0
	const count = pricingCampaign?.id
		? data?.[pricingCampaign?.id]
		: productsCount || 0
	return (
		<LinkButton
			asChild
			disabled={!pricingCampaign?.id}
			className={clsx({
				'pointer-events-none': !pricingCampaign?.id,
			})}
		>
			<Link
				onClick={handleProductsCountLinkClick}
				to={createPricingCampaignLinkForProducts(pricingCampaign)}
			>
				{formatNumber(productsCount)}
				{isFetching ? (
					<Spinner />
				) : (
					` (${getAssortmentShare(total, count || 0)}%)`
				)}
			</Link>
		</LinkButton>
	)
}
function PeriodInformationMessage({
	startDate,
	endDate,
}: {
	startDate?: string | null
	endDate?: string | null
}) {
	const now = new Date()
	const start = startDate ? startOfDay(new Date(startDate)) : undefined
	const end = endDate ? endOfDay(new Date(endDate)) : undefined
	const { isActive, isNotStarted, isFinished } = getPricingCampaignStatus(
		now,
		start,
		end
	)
	if (isFinished) {
		return (
			<Badge size='small' variant='warning' className='text-xs font-medium'>
				{intl.get('pc.period.finished_at')} {end && intlFormat(end)}{' '}
				{end && `(${formatDistanceToNow(end, { addSuffix: true })})`}{' '}
			</Badge>
		)
	} else if (isNotStarted) {
		return (
			<Badge size='small' variant='info' className='text-xs font-medium'>
				{intl.get('pc.period.inactive_and_scheduled')}{' '}
				{start && intlFormat(start)}{' '}
			</Badge>
		)
	} else if (isActive) {
		return (
			<Badge size='small' variant='success' className='text-xs font-medium'>
				{intl.get('pc.period.is_active')}{' '}
				{end &&
					`(${differenceInDays(end, startOfDay(now))} ${intl.get('days_left')})`}{' '}
			</Badge>
		)
	} else {
		return null
	}
}
const getPricingCampaignStatus = (now: Date, start?: Date, end?: Date) => {
	if (start && !end) {
		const isActive = isAfter(now, start)
		const isNotStarted = isBefore(now, start)
		return {
			isActive,
			isNotStarted,
			isFinished: false,
		}
	} else if (end && !start) {
		const isActive = isBefore(now, end)
		return {
			isActive,
			isNotStarted: false,
			isFinished: !isActive,
		}
	} else if (start && end) {
		const isActive = isBetween(now, start, end)
		const isNotStarted = isBefore(now, start)
		const isFinished = isAfter(now, end)
		return {
			isActive,
			isNotStarted,
			isFinished,
		}
	} else {
		return {
			isActive: false,
			isNotStarted: false,
			isFinished: false,
		}
	}
}

function PeriodPicker({
	disabled,
	disabledReason,
}: {
	disabled?: boolean
	disabledReason?: string
}) {
	const prepro = usePreproQuery()
	const integrationDate = prepro.data?.date
	const { control, watch } = useFormContext()
	const start_date = watch('start_date')
	const end_date = watch('end_date')

	return (
		<>
			<div className='flex items-center gap-1 font-bold'>
				<Controller
					name={'start_date'}
					control={control}
					render={({ field, fieldState: { error } }) => {
						return (
							<div className='flex flex-col font-bold'>
								<DateInputText
									disabled={disabled}
									maxDate={
										end_date ? startOfDay(new Date(end_date)) : undefined
									}
									minDate={startOfDay(
										integrationDate ? new Date(integrationDate) : new Date()
									)}
									invalid={Boolean(error)}
									value={field.value}
									onChange={(e) => {
										analytic.logEvent(
											'settings: pricing campaigns: change period'
										)
										field.onChange(e)
									}}
									placeholder={intl.get('pc.start_date.placeholder')}
								/>
								{error && <FormError>{error.message}</FormError>}
							</div>
						)
					}}
				/>
				<span>-</span>
				<Controller
					name={'end_date'}
					control={control}
					render={({ field, fieldState: { error } }) => {
						return (
							<div className='flex flex-col'>
								<DateInputText
									disabled={disabled}
									value={field.value}
									invalid={Boolean(error)}
									minDate={max([
										endOfDay(new Date()),
										start_date ? endOfDay(new Date(start_date)) : new Date(),
									])}
									onChange={(e) => {
										analytic.logEvent(
											'settings: pricing campaigns: change period'
										)
										field.onChange(e)
									}}
									placeholder={intl.get('pc.end_date.placeholder')}
								/>
								{error && <FormError>{error.message}</FormError>}
							</div>
						)
					}}
				/>
			</div>
			{disabled && <FormHint>{disabledReason}</FormHint>}
		</>
	)
}
