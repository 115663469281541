import clsx from 'clsx'
import React, { ChangeEvent, useEffect, useState } from 'react'

import { InlineEdit, Input } from '@cmpkit/base'

import { asNumber } from '@/tools/utils'

import { CellPropControlled } from '../'
import TableCell from './TableCell'

const getValue = (editValue: string | number | null) => {
	if (editValue === '' || editValue === null) return null
	return asNumber(editValue as string)
}

export default function TableCellEditor(
	props: CellPropControlled<number | null>
) {
	const [editValue, setEditValue] = useState<string | number | null>(
		props.value
	)
	useEffect(() => {
		setEditValue(props.value)
	}, [props.originalValue])

	const isEdited = !!props?.isChanged

	const onChange = (event: ChangeEvent<HTMLInputElement>) =>
		setEditValue(event.target.value)
	const onConfirm = () => {
		const value = getValue(editValue) as number
		if (value === props.value) {
			return
		}
		props.onChange?.({
			row: props.row,
			schema: props.schema,
			value,
		})
		setEditValue(null)
	}

	return (
		<InlineEdit
			className={clsx(
				'w-full cursor-pointer text-right [&>div>div>div]:w-full',
				{
					'text-brand': isEdited,
				}
			)}
			areActionButtonsHidden
			shouldConfirmOnEnter
			editView={
				<Input
					type='number'
					defaultValue={props.value as number}
					value={editValue as string}
					autoFocus
					onChange={onChange}
				/>
			}
			readView={
				props.value === null ? (
					<div className='cell-data-number'>-</div>
				) : (
					<TableCell {...props} value={props.value} />
				)
			}
			onConfirm={onConfirm}
			onCancel={onConfirm}
		/>
	)
}
