import React, { memo } from 'react'

import { selectSearchText, useProductsStore } from '@/modules/og-products/store'

import { CellProp } from '../fields'
import { getMarkedText } from './ProductIdentField'

const RenderTitle = memo(
	({ value, searchText }: { value: string; searchText: string }) => {
		return <div>{getMarkedText(value, searchText)}</div>
	}
)
export default function TitleField({ value }: Pick<CellProp, 'value'>) {
	const searchText = useProductsStore(selectSearchText)
	return <RenderTitle value={value} searchText={searchText} />
}
