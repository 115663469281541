import { equals, fromPairs, isNumber, pipe, prop, props, zip } from 'lodash/fp'

import { colorForTopic, hslToHex } from '@/components/Chart/utils'
import intl from '@/locale'

import { METRIC_COLORS } from './constants'
import { BIResponse, MetricModel } from './types'

export type UnknownDataRow = Record<string, string | number | null | undefined>
export const formatMetrics = (metrics: MetricModel[]) =>
	metrics
		.reverse()
		.map((metric, i) => ({
			label: intl.get(metric.name).d(metric.name),
			value: metric.name,
			type: metric.type,
			color:
				(METRIC_COLORS as Record<string, string>)[metric.name as string] ??
				hslToHex(colorForTopic(metrics.length, i)),
		}))
		.reverse()

/**
 *
 * @param meta
 * @param data
 * @returns
 */
export function zipSeries<T = UnknownDataRow>(
	meta: string[] = [],
	data: unknown[][] = []
): T[] {
	return (data?.map(zip(meta)).map(fromPairs) as T[]) ?? []
}
/**
 *
 * @param main
 * @param compared
 * @param meta
 * @returns
 */
export function comparePeriod<T = UnknownDataRow>(
	main: T,
	compared: T | undefined | null,
	meta: string[]
): T {
	const getPercentDiff = (mainValue: number, compValue: number) => {
		if (compValue === 0 && mainValue > 0) {
			return 100
		}
		return (mainValue * 100) / compValue - 100
	}
	return fromPairs(
		meta.reduce(
			(acc: [string, number][], key: string) => {
				const compValue = compared?.[key as keyof T] ?? 0
				const mainValue = main[key as keyof T]

				const isValidToCompare =
					isNumber(mainValue) && isNumber(compValue) && mainValue !== compValue
				acc.push([key, mainValue as number])
				if (isNumber(mainValue)) {
					const difference = isValidToCompare ? mainValue - compValue : 0
					const differencePercent = isValidToCompare
						? getPercentDiff(mainValue, compValue)
						: 0
					acc.push([`${key}_difference`, difference])
					acc.push([
						`${key}_difference_percent`,
						Number.isFinite(differencePercent) ? differencePercent : 0,
					])
				}
				return acc
			},
			[] as [string, number][]
		)
	) as unknown as T
}
/**
 *
 * @param main
 * @param compared
 * @param meta
 * @returns
 */
export function comparePeriods(
	main: unknown[][] = [],
	compared: unknown[][] = [],
	meta: string[] = [],
	getUniqKey: (item: UnknownDataRow) => string
) {
	if (!main || !compared) {
		return []
	}
	const mainZipped: Array<UnknownDataRow> = zipSeries(meta, main)
	const comparedZipped: Array<UnknownDataRow> = zipSeries(meta, compared)
	const output: Array<UnknownDataRow> = []
	for (let i = 0; i < main.length; i++) {
		const current = mainZipped[i]
		const prev = comparedZipped.find(
			pipe([getUniqKey, equals(getUniqKey(current))])
		)
		output.push(comparePeriod(mainZipped[i], prev, meta))
	}
	return output
}

export function sanitiezeResponseBySelectedMetrics(
	{ data, meta, ...res }: BIResponse,
	slectedMetrics: string[],
	withDate = true
): BIResponse {
	const zipped = zipSeries(meta, data)
	const newMeta = withDate ? [meta[0], ...slectedMetrics] : slectedMetrics
	const newData = zipped.map(props(newMeta))
	return {
		...res,
		meta: newMeta,
		data: newData,
	}
}
export function foldData<T = UnknownDataRow>(meta: string[], data: T[]) {
	return fromPairs(meta?.map((name) => [name, data.map(prop(name))]))
}

export const sortIn =
	(range: string[], propertyGetter: (item: object) => string) =>
	(a: object, b: object) => {
		const aIndex = range.indexOf(propertyGetter(a))
		const bIndex = range.indexOf(propertyGetter(b))

		if (aIndex > bIndex) return 1
		if (aIndex < bIndex) return -1

		return 0
	}
