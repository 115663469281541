import clsx from 'clsx'
import { format } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import { find, orderBy } from 'lodash/fp'
import * as qs from 'qs'
import { useEffect, useMemo, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router'

import { useQueryClient } from '@tanstack/react-query'

import {
	Badge,
	Button,
	Checkbox,
	Col,
	Content,
	Header,
	LinkButton,
	ListGroup,
	ListGroupContent,
	ListGroupEnd,
	ListGroupInner,
	ListGroupItem,
	ListGroupStart,
	Loader,
	Row,
	Spinner,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import BarChartIcon from '@cmpkit/icon/lib/glyph/bar-chart'
import PlayIcon from '@cmpkit/icon/lib/glyph/play'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import Tooltip from '@cmpkit/tooltip'

import { dialog } from '@/components/dialogs'
import ErrorBoundary from '@/components/ErrorBoundary'
import notify from '@/components/toasts/helpers'
import { ScenarioModel, ScenarioRunRequestModel } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'

import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'

import {
	useDeleteScenarioMutation,
	useDuplicateScenarioMutation,
	useRunScenariosMutation,
} from '../../mutations'
import { useScenariosQuery, useScenariosRunsQuery } from '../../queries'
import ScenarioActionsDropdown from './ScenarioActionsDropdown'
import ScenarioForm from './ScenarioForm'

const animationFormVariants = {
	in: { opacity: 0, y: 20 },
	visible: { opacity: 1, y: 0 },
	out: { opacity: 0, y: -20 },
}
export default function Scenarios() {
	const [selected, setSelected] = useState<ScenarioModel[] | []>([])

	const location = useLocation()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const optimizationGroupId = useOptimizationGroupId()!
	const { showModal } = useModalStore()

	const { selectedScenario } = useMemo(
		() => qs.parse(location.search, { ignoreQueryPrefix: true }),
		[location.search]
	)

	useEffect(() => {
		analytic.logEvent('browse: scenarios')
	}, [])

	const isSelected = (scenarioId: string) =>
		selected.map(({ id }) => id).includes(scenarioId)

	const getRunScenariosPayload = (
		scenarios: ScenarioModel[]
	): ScenarioRunRequestModel => ({
		scenario_runs: scenarios.map((scenario) => ({
			scenario_id: scenario.id,
			optimization_group_id: optimizationGroupId,
			name: scenario.name,
			settings: scenario.settings,
		})),
		optimization_group_id: optimizationGroupId,
	})

	const scenariosQuery = useScenariosQuery<ScenarioModel[]>(
		optimizationGroupId,
		{
			select: orderBy<ScenarioModel>(['is_main'], ['desc']),
		}
	)
	const duplicateScenarioMutation = useDuplicateScenarioMutation({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['scenarios'],
			})
			notify.success({
				text: intl
					.get('scenario.duplicate.success')
					.d('Scenarios successfully copied'),
			})
		},
	})
	const deleteScenarioMutation = useDeleteScenarioMutation({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['scenarios'],
			})
			notify.success({
				text: intl
					.get('scenario.delete.success')
					.d('Scenarios successfully deleted'),
			})
		},
		onMutate(ids) {
			// Optimistic update for scenarios list query to remove deleted scenario
			queryClient.setQueryData(
				['scenarios', { optimization_group_id: optimizationGroupId }],
				(scenarios?: ScenarioModel[]) =>
					scenarios?.filter((scenario) => !ids.includes(scenario.id)) || []
			)
		},
	})
	const mainScenarioId = useMemo(
		() => find({ is_main: true }, scenariosQuery.data)?.id,
		[scenariosQuery.data]
	)
	const scenariosResultsQuery = useScenariosRunsQuery(optimizationGroupId)

	const runScenariosMutation = useRunScenariosMutation({
		onSuccess() {
			Promise.all([
				queryClient.removeQueries({
					queryKey: [
						'scenarios-run-sets',
						{ optimization_group_id: optimizationGroupId },
					],
				}),
				queryClient.removeQueries({
					queryKey: [
						'scenarios-optimization-status',
						{ optimization_group_id: optimizationGroupId },
					],
				}),
				queryClient.removeQueries({
					queryKey: [
						'scenarios-optimization-statistics',
						{ optimization_group_id: optimizationGroupId },
					],
				}),
				queryClient.removeQueries({
					queryKey: [
						'scenarios-optimization-multipleInterpretability-statistic',
						{ optimization_group_id: optimizationGroupId },
					],
				}),
				queryClient.removeQueries({
					queryKey: [
						'scenarios-runs-items',
						{ optimization_group_id: optimizationGroupId },
					],
				}),
			]).then(() => navigate('summary'))
		},
	})
	/**
	 * Handlers
	 */
	const handleRunScenario = async () => {
		analytic.logEvent('what-if: scenarios: run')
		const answer = await dialog.confirm({
			title: intl.get('scenario.run.confirm.title'),
			text: intl.get('scenario.run.confirm.subtitle'),
			okText: intl.get('scenario.run.confirm.ok').d('Run'),
		})
		if (answer) {
			const payload = getRunScenariosPayload(
				!!selected.length ? selected : scenariosQuery.data!
			)
			runScenariosMutation.mutate(payload)
		}
	}

	const handleOpenRecently = () => {
		analytic.logEvent('what-if: scenarios: open recently summary')
		navigate('summary')
	}

	const handleDuplicateScenario = async (scenarioId: string) => {
		analytic.logEvent('what-if: scenario: duplicate')
		duplicateScenarioMutation.mutate(scenarioId)
	}
	const handleDeleteScenario = async (ids: string[]) => {
		analytic.logEvent('what-if: scenario: bulk delete')
		const answer = await dialog.confirmDelete({
			title: intl.get('scenario.detete.confirm.title'),
			text: intl.get('scenario.detete.confirm.subtitle'),
		})
		if (answer) {
			navigate('.')
			/*remove main scenario from the list */
			const filteredIds = ids.filter((id) => id !== mainScenarioId)
			deleteScenarioMutation.mutate(filteredIds)
			const selectedIds = ids.filter((id) => isSelected(id))
			if (!!selectedIds) {
				/*remove deleted scenarios from the selected state if they are in there */
				setSelected((prev) =>
					prev.filter(({ id }) => !selectedIds.includes(id))
				)
			}
		}
	}
	const handleClick = (scenario: ScenarioModel) => {
		if (isSelected(scenario.id)) {
			setSelected(selected.filter(({ id }) => id !== scenario.id))
		} else {
			setSelected([...selected, scenario])
		}
	}
	const isMainSelected = selected.some(({ is_main }) => is_main)

	return (
		<Content className='flex-col overflow-hidden p-4'>
			<Header className='mb-5 items-center justify-between'>
				<div>
					<h1>{intl.get('scenario.page.title')}</h1>
					<p className='section-subtitle'>
						{intl.get('scenario.page.subtitle')}
					</p>
				</div>
				<div className='flex space-x-2'>
					{!!scenariosResultsQuery?.data?.scenario_runs?.length &&
					!selected.length ? (
						<Tooltip
							content={`${intl.get('app.last_run')}: ${format(
								new Date(scenariosResultsQuery.data.created_at + 'Z'),
								DateFormat.long
							)}`}
						>
							<Button
								iconBefore={<BarChartIcon />}
								onClick={handleOpenRecently}
							>
								{intl.get('scenario.show_latest_summary')}
							</Button>
						</Tooltip>
					) : isMainSelected ? (
						<Tooltip
							content={intl
								.get('app.delete_button.tooltip')
								.d('Main scenario cannot be deleted')}
						>
							<p>
								<Button
									variant='primary-danger'
									disabled
									iconBefore={<Trash2Icon className='mr-1' />}
								>
									{intl.get('general_delete')}
									{`(${selected?.length})`}
								</Button>
							</p>
						</Tooltip>
					) : (
						selected.length > 0 && (
							<Button
								variant='primary-danger'
								onClick={() =>
									handleDeleteScenario(selected.map(({ id }) => id))
								}
								iconBefore={<Trash2Icon className='mr-1' />}
							>
								{intl.get('general_delete')}
								{`(${selected?.length})`}
							</Button>
						)
					)}

					<Button
						disabled={
							scenariosQuery.isLoading || runScenariosMutation.isPending
						}
						iconBefore={
							runScenariosMutation.isPending ? <Spinner /> : <PlayIcon />
						}
						variant='primary-brand'
						onClick={handleRunScenario}
					>
						{intl.get('scenario.run_scenarios').d('Run What-if')}
						{!!selected.length && `(${selected.length})`}
					</Button>
				</div>
			</Header>
			<Content className='relative overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
				<Row className='w-full' noGutters>
					<Col sm={3} className='relative border-r border-solid border-base'>
						<Blanket
							className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-sm dark:bg-black/50'
							isTinted={scenariosQuery.isLoading}
						>
							<Loader />
						</Blanket>
						<div>
							<ListGroup className='rules-list-menu h-full overflow-y-auto bg-accent-2'>
								{scenariosQuery.data?.map((scenario: ScenarioModel, index) => {
									return (
										<li
											key={scenario.id}
											className={
												index === 0 ? 'border-b border-solid border-base' : ''
											}
										>
											<ListGroupItem
												className={clsx('text-foreground no-underline', {
													'font-medium': scenario.is_main,
												})}
												active={selectedScenario === scenario.id}
											>
												<ListGroupInner>
													<ListGroupStart>
														<Checkbox
															className='items-center'
															checked={isSelected(scenario.id)}
															onChange={() => {
																handleClick(scenario)
															}}
														/>
													</ListGroupStart>
													<ListGroupContent
														className='flex w-full items-center gap-2'
														onClick={() =>
															navigate(`?selectedScenario=${scenario.id}`)
														}
													>
														{scenario.name}
														{scenario.is_main && (
															<Badge size='small'>
																{intl.get('app.main').d('Main')}
															</Badge>
														)}
													</ListGroupContent>
													<ListGroupEnd>
														<ScenarioActionsDropdown
															scenario={scenario}
															onDeleteClick={(id) => handleDeleteScenario([id])}
															onDublicateClick={handleDuplicateScenario}
															isPending={duplicateScenarioMutation.isPending}
														/>
													</ListGroupEnd>
												</ListGroupInner>
											</ListGroupItem>
										</li>
									)
								})}
							</ListGroup>
							<div className='p-5'>
								<LinkButton
									onClick={() => {
										analytic.logEvent('what-if: create scenario: modal: open', {
											location: 'scenarios_list',
										})
										showModal('CREATE_SCENARIO')
									}}
								>
									<PlusIcon />
									{intl.get('scenario.add').d('Add scenario')}
								</LinkButton>
							</div>
						</div>
					</Col>
					<Col className='h-full overflow-hidden'>
						<ErrorBoundary>
							<AnimatePresence mode={'wait'}>
								{selectedScenario ? (
									<motion.div
										key={selectedScenario as string}
										className='flex size-full overflow-hidden'
										initial='in'
										animate='visible'
										exit='out'
										variants={animationFormVariants}
										transition={{
											duration: 0.1,
										}}
									>
										<ScenarioForm scenarioId={selectedScenario as string} />
									</motion.div>
								) : (
									<motion.div
										key={'empty'}
										className='flex size-full items-center justify-center'
										initial='in'
										animate='visible'
										exit='out'
										variants={animationFormVariants}
										transition={{
											duration: 0.1,
										}}
									>
										<span className='font-medium'>
											{mainScenarioId ? (
												<Navigate
													to={`?selectedScenario=${mainScenarioId}`}
													replace
												/>
											) : (
												intl
													.get('scenario.form.empty_text')
													.d('Select scenario on left side')
											)}
										</span>
									</motion.div>
								)}
							</AnimatePresence>
						</ErrorBoundary>
					</Col>
				</Row>
			</Content>
		</Content>
	)
}
