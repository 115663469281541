import { all, find } from 'lodash/fp'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Button } from '@cmpkit/base'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { dialog } from '@/components/dialogs'
import {
	FormProvider,
	RHFCheckbox,
	RHFMultiSelect,
} from '@/components/HookForm'
import Loader from '@/components/Loader'
import intl from '@/locale'
import { useMeQuery } from '@/modules/Auth/queries'
import {
	useCreateReviewMutation,
	useDeleteReviewMutation,
	useUpdateReviewMutation,
} from '@/modules/core/mutations'
import { useReviewsQuery } from '@/modules/core/queries'
import { ReviewModel } from '@/modules/core/types'
import { useAllUsersQuery } from '@/modules/Users/queries'
import { usersToOptions } from '@/modules/Users/utils'
import analytic from '@/services/analytics'
import {
	actCreated,
	actDeleted,
	actUpdated,
	successActionMessage,
} from '@/tools/message'

const formatReviewersBeforeSend = (ids: string[], review?: ReviewModel) =>
	ids.map((id) => {
		return review?.reviewers?.find((reviewer) => reviewer.id === id) ?? { id }
	})

type ReviewManagerModalProps = {
	isOpen: boolean
	close: () => void
	optGroupId: string
}

const FormDataSchema = Yup.object().shape({
	reviewers: Yup.array().required('Reviewers is required'),
	notify_author: Yup.boolean().required('Required field'),
})
type FormDataType = Yup.InferType<typeof FormDataSchema>

export default function ReviewManagerModal({
	isOpen,
	close,
	optGroupId,
}: ReviewManagerModalProps) {
	// track open review manager modal event
	useEffect(() => {
		analytic.logEvent('approval: open review manager modal')
	}, [])

	/**
	 * Queries
	 */
	const { data: review, isLoading } = useReviewsQuery<ReviewModel | undefined>({
		select: find<ReviewModel>({ optimization_group_id: optGroupId }),
	})
	const users = useAllUsersQuery()
	const sessionUser = useMeQuery()

	/**
	 * Mutations
	 */
	const createReview = useCreateReviewMutation()
	const updateReview = useUpdateReviewMutation()
	const deleteReview = useDeleteReviewMutation()

	const methods = useForm<FormDataType>({
		defaultValues: review
			? {
					reviewers: review.reviewers.map(({ id }) => id),
					notify_author: review.notify_author,
				}
			: {
					reviewers: [],
					notify_author: false,
				},
		resolver: yupResolver<FormDataType>(FormDataSchema),
	})

	useEffect(() => {
		if (review) {
			methods.reset({
				reviewers: review.reviewers.map(({ id }) => id),
				notify_author: review.notify_author,
			})
		}
	}, [review])
	const values = methods.watch()
	/**
	 * Calculations
	 */
	const usersData = (users?.data ?? []).filter(({ apps, id }) => {
		return (
			find(
				{ name: 'pricing_platform' },
				apps
			)?.entities?.optimization_group?.includes(optGroupId) &&
			id !== sessionUser?.data?.id
		)
	})
	const optionsUsers = usersToOptions(usersData)
	const isSessionUserOwner = review
		? review?.author_id === sessionUser?.data?.id
		: true

	/**
	 * Hanldlers
	 */
	const handleDelete = async () => {
		const answer = await dialog.confirmDelete({
			title: intl.get('general_detete_confirm_title'),
			text: intl.get('general_detete_confirm_subtitle'),
			okText: intl.get('general_delete'),
		})

		if (answer) {
			analytic.logEvent('approval: discard review', {
				'Reviewers count': values.reviewers?.length,
				'Is completed': all({ is_approved: true }, review?.reviewers),
			})
			deleteReview.mutateAsync(optGroupId).then(() => {
				toast.success(successActionMessage('Deleted', actDeleted()))
				close()
			})
		}
	}
	const onSubmit = (data: FormDataType) => {
		const payload = {
			optimizationGroupId: optGroupId,
			body: {
				...data,
				reviewers: formatReviewersBeforeSend(data.reviewers, review),
			},
		}
		if (review) {
			analytic.logEvent('approval: update review', {
				'Reviewers count': data.reviewers?.length,
				'Notify author': data.notify_author,
			})
			updateReview.mutateAsync(payload).then(() => {
				toast.success(successActionMessage('Review', actUpdated()))
				close()
			})
		} else {
			analytic.logEvent('approval: create review', {
				'Reviewers count': data.reviewers?.length,
				'Notify author': data.notify_author,
			})
			createReview.mutateAsync(payload).then(() => {
				toast.success(successActionMessage('Review', actCreated()))
				close()
			})
		}
	}

	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					onClose={close}
					size='small'
					zIndex={1850}
					shouldCloseOnOverlayClick
					showCloseButton
				>
					<FormProvider
						methods={methods}
						onSubmit={methods.handleSubmit(onSubmit)}
						className='flex flex-col'
					>
						<ModalHeader>
							<ModalTitle>{intl.get('review_manager_title')}</ModalTitle>
						</ModalHeader>
						<ModalBody className='flex flex-col'>
							{isLoading && <Loader size={30} backdrop opacity />}
							<p className='mb-2'>{intl.get('review_manager_subtitle')}</p>
							<RHFMultiSelect
								menuPortalTarget={document.body}
								label={intl.get('reviewers')}
								name='reviewers'
								options={optionsUsers || []}
							/>
							<RHFCheckbox
								label={intl.get('review_manager_field_notify')}
								name='notify_author'
							/>
						</ModalBody>
						<ModalFooter className='justify-between space-x-2'>
							<Button onClick={close}>{intl.get('general_cancel')}</Button>
							{review && isSessionUserOwner && values.reviewers.length === 0 ? (
								<Button variant='primary-brand' onClick={handleDelete}>
									{intl.get('discard_review')}
								</Button>
							) : (
								<Button
									variant='primary-brand'
									disabled={values.reviewers.length === 0 || isLoading}
									onClick={methods.handleSubmit(onSubmit)}
								>
									{intl.get('general_save')}
								</Button>
							)}
						</ModalFooter>
					</FormProvider>
				</Modal>
			)}
		</ModalTransition>
	)
}
