import React, { ChangeEvent, FormEvent } from 'react'

import { Label, Radio } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'

import intl from '@/locale'

import { Note } from '../../components/common'
import { Group } from '../../components/InputGroup'
import { DialogInner } from '../../components/Popup'
import { FieldViewProps } from '../../types'
import BooleanController from './Controller'

type Props = FieldViewProps<BooleanController>
export default function BooleanView({
	field,
	invalidMessage,
	isRemovable,
	isDirty,
	localValue,
	onReset,
	onRemove,
	onApply,
	onChange,
	closePopup,
}: Props) {
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		if (invalidMessage) return
		onApply?.()
		closePopup?.()
	}

	const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		onChange({ operation: Operators.BL, value })
	}
	const isInvalid = Boolean(invalidMessage)

	return (
		<Group
			onSubmit={handleSubmit}
			onRemove={isRemovable ? onRemove : undefined}
			isDisabledApply={
				!isDirty || Boolean(isInvalid || field.validate(localValue))
			}
			onCancel={() => {
				onReset?.()
				closePopup()
			}}
		>
			<DialogInner className='min-w-[260px] max-w-[260px] space-y-2 p-5'>
				<div className='flex flex-col space-y-3'>
					<div className='flex items-center space-x-2'>
						<Radio
							id={'radio-a-0'}
							checked={localValue.value == '0'}
							name='value'
							onChange={onChangeCheckbox}
							value={'0'}
						/>
						<Label htmlFor={'radio-a-0'}>{intl.get('false').d('False')}</Label>
					</div>
					<div className='flex items-center space-x-2'>
						<Radio
							id={'radio-a-1'}
							checked={localValue.value == '1'}
							name='value'
							onChange={onChangeCheckbox}
							value={'1'}
						/>
						<Label htmlFor={'adio-a-1'}>{intl.get('true').d('True')}</Label>
					</div>
				</div>
				{field.note && <Note>{field.note}</Note>}
			</DialogInner>
		</Group>
	)
}
