import React from 'react'

import { Button, Card, MenuItem, MenuList } from '@cmpkit/base'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'
import Edit2Icon from '@cmpkit/icon/lib/glyph/edit-2'
import LinkIcon from '@cmpkit/icon/lib/glyph/link'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import Popover from '@cmpkit/popover'

import { SavedFilterModel } from '@/generated'
import intl from '@/locale'

export type SavedReportsActionsHandlers = {
	onDeleteClick?: (
		reportItem: SavedFilterModel & {
			is_global: boolean
			id: string
		}
	) => void
	onDownloadClick?: (
		reportItem: SavedFilterModel & {
			is_global: boolean
			id: string
		}
	) => void
	onEditClick?: (
		reportItem: SavedFilterModel & {
			is_global: boolean
			id: string
		}
	) => void
	onOpenClick?: (
		reportItem: SavedFilterModel & {
			is_global: boolean
			id: string
		}
	) => void
}
type SavedReportsActionsDropdownProps = SavedReportsActionsHandlers & {
	reportItem: SavedFilterModel & {
		is_global: boolean
		id: string
	}
}
export default function SavedReportsActionsDropdown({
	reportItem,
	onDeleteClick,
	onEditClick,
	onDownloadClick,
	onOpenClick,
}: SavedReportsActionsDropdownProps) {
	const dropdownContent = ({ close }: { close: () => void }) => (
		<Card className='w-56 border py-2 shadow'>
			<MenuList>
				<MenuItem
					onClick={() => {
						onOpenClick?.(reportItem)
						close()
					}}
				>
					<LinkIcon />
					<span>{intl.get('app.open_in_new_window')}</span>
				</MenuItem>
				<MenuItem
					onClick={() => {
						onDownloadClick?.(reportItem)
						close()
					}}
				>
					<DownloadIcon />
					<span>{intl.get('app.download_report')}</span>
				</MenuItem>
				<MenuItem
					onClick={() => {
						onEditClick?.(reportItem)
						close()
					}}
				>
					<Edit2Icon />
					<span>{intl.get('app.edit')}</span>
				</MenuItem>
				<MenuItem
					variant='destructive'
					onClick={() => {
						onDeleteClick?.(reportItem)
						close()
					}}
				>
					<Trash2Icon />
					<span>{intl.get('general_delete')}</span>
				</MenuItem>
			</MenuList>
		</Card>
	)
	return (
		<Popover content={dropdownContent} placement='bottom-start'>
			<Button variant='tertiary' size='small' iconBefore={<DotsFilledIcon />} />
		</Popover>
	)
}
