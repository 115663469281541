import { filter } from 'lodash'
import { map, pipe, prop, propEq, reduce, sum } from 'lodash/fp'
import { useMemo } from 'react'

import { InlineMessage } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import ProgressRow from '@/components/Progress/ProgressRow'
import { FilterRuleModel, OptimizationIDModel } from '@/generated'
import intl from '@/locale'
import { formatNumber } from '@/tools/locale'

import { Metric, MetricGroup, MetricGroupValue } from '../../constants'
import { useRepricingStatisticQuery } from '../../queries'

export default function PricingLinesChangeTypeWidget({
	isLoading: isDataLoading,
	queryParams,
}: {
	queryParams: {
		optimizations: OptimizationIDModel[]
		filters: FilterRuleModel[]
	} | null
	isLoading: boolean
}) {
	const {
		data: repricingStatistic,
		isLoading,
		isError,
		error,
	} = useRepricingStatisticQuery(queryParams!, {
		enabled: !!queryParams && !isDataLoading,
		select: pipe(
			prop('metrics'),
			filter(propEq('group_type', MetricGroup.PricingType)),
			reduce(
				(acc, item: Metric) => {
					const groupValue = item.group_value as MetricGroupValue
					if (!acc[groupValue]) {
						acc[groupValue] = {}
					}
					acc[groupValue][item.name] = item.value
					return acc
				},
				{} as Record<MetricGroupValue, Record<string, number>>
			)
		),
	})
	const rows = useMemo(() => {
		const data = repricingStatistic
		return [
			{
				name: intl.get('status_panel_limit_of_price_change'),
				lines: data?.[MetricGroupValue.Markdown].pricing_line_count,
				products: data?.[MetricGroupValue.Markdown].product_count,
			},
			{
				name: intl.get('status_panel_price_change_by_rb'),
				lines: data?.[MetricGroupValue.RuleBased].pricing_line_count,
				products: data?.[MetricGroupValue.RuleBased].product_count,
			},
			{
				name: intl.get('status_panel_price_change_by_ml'),
				lines: data?.[MetricGroupValue.DemandBased].pricing_line_count,
				products: data?.[MetricGroupValue.DemandBased].product_count,
			},
			{
				name: intl.get('status_panel_price_change_by_manual'),
				lines: data?.[MetricGroupValue.Manual].pricing_line_count,
				products: data?.[MetricGroupValue.Manual].product_count,
			},
		]
	}, [repricingStatistic])
	const total = sum(map(prop('products'), rows))

	if (isLoading || isDataLoading) {
		return <LoadingPlaceholder />
	}
	if (isError) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger' className='w-full'>
				{error.message || intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}
	return (
		<div className='flex w-full flex-col space-y-2'>
			{rows.map((row) => (
				<ProgressRow
					label={row.name}
					relativeValue={(row.products / total) * 100}
					absoluteValue={formatNumber(row.products)}
					extraValue={formatNumber(row.lines)}
				/>
			))}
		</div>
	)
}

function LoadingPlaceholder() {
	return (
		<div className='flex flex-col items-center space-y-2'>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-20 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-10 animate-pulse rounded-lg bg-accent-4' />
			</div>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-24 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-12 animate-pulse rounded-lg bg-accent-4' />
			</div>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-28 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-12 animate-pulse rounded-lg bg-accent-4' />
			</div>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-24 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-12 animate-pulse rounded-lg bg-accent-4' />
			</div>
		</div>
	)
}
