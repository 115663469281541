/* @ts-igore */

/**
 * RegExp to match percent encoding escape.
 * @private
 */

const HEX_ESCAPE_REPLACE_REGEXP = /%([0-9A-Fa-f]{2})/g

/**
 * RegExp to match non-latin1 characters.
 * @private
 */

const NON_LATIN1_REGEXP = /[^\x20-\x7e\xa0-\xff]/g

/**
 * RegExp to match quoted-pair in RFC 2616
 *
 * quoted-pair = "\" CHAR
 * CHAR        = <any US-ASCII character (octets 0 - 127)>
 * @private
 */

const QESC_REGEXP = /\\([\u0000-\u007f])/g // eslint-disable-line no-control-regex

/**
 * RegExp for various RFC 2616 grammar
 *
 * parameter     = token "=" ( token | quoted-string )
 * token         = 1*<any CHAR except CTLs or separators>
 * separators    = "(" | ")" | "<" | ">" | "@"
 *               | "," | ";" | ":" | "\" | <">
 *               | "/" | "[" | "]" | "?" | "="
 *               | "{" | "}" | SP | HT
 * quoted-string = ( <"> *(qdtext | quoted-pair ) <"> )
 * qdtext        = <any TEXT except <">>
 * quoted-pair   = "\" CHAR
 * CHAR          = <any US-ASCII character (octets 0 - 127)>
 * TEXT          = <any OCTET except CTLs, but including LWS>
 * LWS           = [CRLF] 1*( SP | HT )
 * CRLF          = CR LF
 * CR            = <US-ASCII CR, carriage return (13)>
 * LF            = <US-ASCII LF, linefeed (10)>
 * SP            = <US-ASCII SP, space (32)>
 * HT            = <US-ASCII HT, horizontal-tab (9)>
 * CTL           = <any US-ASCII control character (octets 0 - 31) and DEL (127)>
 * OCTET         = <any 8-bit sequence of data>
 * @private
 */

const PARAM_REGEXP =
	/;[\x09\x20]*([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*=[\x09\x20]*("(?:[\x20!\x23-\x5b\x5d-\x7e\x80-\xff]|\\[\x20-\x7e])*"|[!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*/g // eslint-disable-line no-control-regex
/**
 * RegExp for various RFC 5987 grammar
 *
 * ext-value     = charset  "'" [ language ] "'" value-chars
 * charset       = "UTF-8" / "ISO-8859-1" / mime-charset
 * mime-charset  = 1*mime-charsetc
 * mime-charsetc = ALPHA / DIGIT
 *               / "!" / "#" / "$" / "%" / "&"
 *               / "+" / "-" / "^" / "_" / "`"
 *               / "{" / "}" / "~"
 * language      = ( 2*3ALPHA [ extlang ] )
 *               / 4ALPHA
 *               / 5*8ALPHA
 * extlang       = *3( "-" 3ALPHA )
 * value-chars   = *( pct-encoded / attr-char )
 * pct-encoded   = "%" HEXDIG HEXDIG
 * attr-char     = ALPHA / DIGIT
 *               / "!" / "#" / "$" / "&" / "+" / "-" / "."
 *               / "^" / "_" / "`" / "|" / "~"
 * @private
 */

const EXT_VALUE_REGEXP =
	/^([A-Za-z0-9!#$%&+\-^_`{}~]+)'(?:[A-Za-z]{2,3}(?:-[A-Za-z]{3}){0,3}|[A-Za-z]{4,8}|)'((?:%[0-9A-Fa-f]{2}|[A-Za-z0-9!#$&+.^_`|~-])+)$/

/**
 * RegExp for various RFC 6266 grammar
 *
 * disposition-type = "inline" | "attachment" | disp-ext-type
 * disp-ext-type    = token
 * disposition-parm = filename-parm | disp-ext-parm
 * filename-parm    = "filename" "=" value
 *                  | "filename*" "=" ext-value
 * disp-ext-parm    = token "=" value
 *                  | ext-token "=" ext-value
 * ext-token        = <the characters in token, followed by "*">
 * @private
 */

const DISPOSITION_TYPE_REGEXP =
	/^([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*(?:$|;)/ // eslint-disable-line no-control-regex

/**
 * Decode a RFC 5987 field value (gracefully).
 *
 * @param {string} str
 * @return {string}
 * @private
 */

function decodefield(str: string) {
	const match = EXT_VALUE_REGEXP.exec(str)

	if (!match) {
		throw new TypeError('invalid extended field value')
	}

	const charset = match[1].toLowerCase()
	const encoded = match[2]
	let value

	// to binary string
	const binary = encoded.replace(HEX_ESCAPE_REPLACE_REGEXP, pdecode)

	switch (charset) {
		case 'iso-8859-1':
			value = getlatin1(binary)
			break
		case 'utf-8':
			value = decodeURIComponent(encoded)
			break
		default:
			throw new TypeError('unsupported charset in extended field')
	}

	return value
}

/**
 * Get ISO-8859-1 version of string.
 *
 * @param {string} val
 * @return {string}
 * @private
 */

function getlatin1(val: string) {
	// simple Unicode -> ISO-8859-1 transformation
	return String(val).replace(NON_LATIN1_REGEXP, '?')
}

/**
 * Parse Content-Disposition header string.
 *
 * @param {string} string
 * @return {object}
 * @public
 */

export default function parse(string: string) {
	if (!string || typeof string !== 'string') {
		throw new TypeError('argument string is required')
	}

	let match = DISPOSITION_TYPE_REGEXP.exec(string)

	if (!match) {
		throw new TypeError('invalid type format')
	}

	// normalize type
	let index = match[0].length
	const type = match[1].toLowerCase()

	let key
	const names: string[] = []
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const params: any = {}
	let value

	// calculate index to start at
	index = PARAM_REGEXP.lastIndex =
		match[0].substr(-1) === ';' ? index - 1 : index

	// match parameters
	while ((match = PARAM_REGEXP.exec(string))) {
		if (match.index !== index) {
			throw new TypeError('invalid parameter format')
		}

		index += match[0].length
		key = match[1].toLowerCase()
		value = match[2]

		if (names.indexOf(key) !== -1) {
			throw new TypeError('invalid duplicate parameter')
		}

		names.push(key)

		if (key.indexOf('*') + 1 === key.length) {
			// decode extended value
			key = key.slice(0, -1)
			value = decodefield(value)

			// overwrite existing value
			params[key] = value
			continue
		}

		if (typeof params[key] === 'string') {
			continue
		}

		if (value[0] === '"') {
			// remove quotes and escapes
			value = value.substr(1, value.length - 2).replace(QESC_REGEXP, '$1')
		}

		params[key] = value
	}

	if (index !== -1 && index !== string.length) {
		throw new TypeError('invalid parameter format')
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return new (ContentDisposition as any)(type, params)
}

/**
 * Percent decode a single character.
 *
 * @param {string} str
 * @param {string} hex
 * @return {string}
 * @private
 */

function pdecode(str: string, hex: string) {
	return String.fromCharCode(parseInt(hex, 16))
}

/**
 * Class for parsed Content-Disposition header for v8 optimization
 *
 * @public
 * @param {string} type
 * @param {object} parameters
 * @constructor
 */

function ContentDisposition(type: string, parameters: object) {
	// @ts-expect-error: Ignore this context error
	this.type = type
	// @ts-expect-error: Ignore this context error
	this.parameters = parameters
}
