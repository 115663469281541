import { AxiosError } from 'axios'
import { entries, pickAll, size, sum } from 'lodash/fp'
/* eslint-disable */
import React, { MouseEvent, useState } from 'react'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	InlineMessage,
	LinkButton,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import { DragNDropArea } from '@/components/DragNDropArea'
import Loader from '@/components/Loader'
import { UploadPromoProductsErrorModel } from '@/generated'
import intl from '@/locale'
import { client } from '@/network/client'
import analytic from '@/services/analytics'
import Sentry from '@/services/sentry'
import { errorMessage } from '@/tools/message'
import { download } from '@/tools/report'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'

const getErrors = (errors: Record<string, any>) =>
	entries(errors).map(([key, val]) => [key, size(val)])
const ErrorsPanel = ({
	errors,
	onDownloadFile,
}: {
	errors: [string, number][]
	onDownloadFile: () => void
}) => {
	const count = sum(errors.map(([key, val]) => val))
	return (
		<InlineMessage variant='danger' className='w-full'>
			<div className='flex flex-col gap-2'>
				<h5>
					{count > 0
						? intl.get('import_errors_panel', { count })
						: intl.get('fatal_error_title')}
				</h5>
				<ul className='list-inside list-disc'>
					{errors
						.filter(([key, val]) => val > 0)
						.map(([key, val]) => (
							<li key={key}>
								{intl.get(`import_error_${key}`)}
								{`(${val})`}
							</li>
						))}
				</ul>
				{count > 0 && (
					<LinkButton onClick={onDownloadFile} variant='brand'>
						<DownloadIcon />
						{intl.get('download_file_with_import_errors')}
					</LinkButton>
				)}
			</div>
		</InlineMessage>
	)
}
const handleDownloadTemplate = async () => {
	analytic.logEvent('promo: Download template')

	try {
		const Excel = await import('exceljs')
		const rows = [
			{
				sku: 's1',
				title: 't1',
				discount_price: 10,
				discount_percent: 2,
				discount_sum: null,
				predicted_growth_rate: 1,
			},
		]

		const workbook = new Excel.Workbook()
		workbook.creator = 'Competera'
		const worksheet = workbook.addWorksheet('Sheet1')
		worksheet.columns = [
			'sku',
			'title',
			'discount_price',
			'discount_percent',
			'discount_sum',
			'predicted_growth_rate',
		].map((key) => ({ header: key, key, width: 50, outlineLevel: 1 }))
		worksheet.addRows(rows)
		const buffer = await workbook.xlsx.writeBuffer()
		const blob = new Blob([buffer], {
			type: 'application/octet-binary;charset=utf-8',
		})
		download(blob, 'promo_import_template.xlsx')
	} catch (error) {
		Sentry.captureException(error)
		toast(intl.get('fatal_error_title'), {
			icon: <AlertIcon className='fill-warn' />,
			duration: 5000,
		})
	}
}
const handleDownloadErrorsFile = async (errors: Record<string, any>) => {
	analytic.logEvent('promo: Download errors')
	try {
		const Excel = await import('exceljs')
		const rows = Object.values(errors)
			.reduce((acc, list) => [...acc, ...list], [])
			.map(pickAll(['pricing_tier_id', 'sku', 'reason']))

		const workbook = new Excel.Workbook()
		workbook.creator = 'Competera Validator'
		workbook.lastModifiedBy = 'Competera Validator'
		workbook.created = new Date()
		workbook.modified = new Date()
		workbook.lastPrinted = new Date()
		const worksheet = workbook.addWorksheet('Sheet1')
		worksheet.columns = [
			{ header: 'SKU', key: 'sku', width: 50, outlineLevel: 1 },
			{ header: 'Pricing tier id', key: 'pricing_tier_id', width: 50 },
			{ header: 'Reason', key: 'reason', width: 50 },
		]
		worksheet.addRows(rows)
		const buffer = await workbook.xlsx.writeBuffer()
		const blob = new Blob([buffer], {
			type: 'application/octet-binary;charset=utf-8',
		})
		download(blob, 'promo_errors.xlsx')
	} catch (error) {
		Sentry.captureException(error)
		toast(intl.get('fatal_error_title'), {
			icon: <AlertIcon className='fill-warn' />,
			duration: 5000,
		})
	}
}
export default function PromoImportFile({
	id,
	onDone,
}: {
	id: string
	onDone: () => void
}) {
	const [isLoading, setIsLoading] = useState(false)
	const [errors, setErrors] = useState(null)
	const queryClient = useQueryClient()
	const handleFileDropped = (file: File) => {
		analytic.logEvent('promo: import File')
		setIsLoading(true)
		setErrors(null)
		client.promoCampaigns
			.uploadPromoCampaignProducts(id, {
				file,
			})
			.then(() => {
				queryClient.invalidateQueries({
					queryKey: ['promo-products', id],
				})
				onDone()
			})
			.catch((error: AxiosError<UploadPromoProductsErrorModel>) => {
				Sentry.captureException(error)
				toast.error(error.message || errorMessage(), {
					duration: 5000,
				})
				setErrors(
					pickAll(
						[
							'duplicated_rows_errors',
							'multiple_discounts_errors',
							'validation_errors',
						],
						error?.response?.data?.detail
					) as any
				)
			})
			.finally(() => setIsLoading(false))
	}

	const handleDownloadErrors = () => handleDownloadErrorsFile(errors as any)
	return (
		<div className='relative flex h-full items-center justify-center'>
			<div className='w-[500px]'>
				<h3 className='mb-2 text-sm font-bold'>
					{intl.get('promo_upload_file_title')}
				</h3>
				<Blanket
					className='bg-backdrop blanket-abs blanket-products'
					isTinted={isLoading}
				>
					<Loader size={30} />
				</Blanket>
				<div className='mb-5'>
					<DragNDropArea
						className='min-h-28'
						isInvalid={!!errors}
						onChange={handleFileDropped}
					/>
				</div>
				{!!errors ? (
					<ErrorsPanel
						errors={getErrors(errors) as [string, number][]}
						onDownloadFile={handleDownloadErrors}
					/>
				) : (
					<Card className='border'>
						<CardHeader>
							<CardTitle>{intl.get('modal_import_guide')}</CardTitle>
						</CardHeader>
						<CardContent>
							<ul className='list-decimal space-y-2 pl-5'>
								<li>
									{intl.get('promo_import_step_1')}
									<LinkButton
										variant='brand'
										onClick={handleDownloadTemplate}
										className='ml-1'
									>
										{intl.get('promo_import_types')}
									</LinkButton>
								</li>
								<li>{intl.get('promo_import_step_2')}</li>
								<li>{intl.get('promo_import_step_3')}</li>
							</ul>
							<p className='text-xs font-bold'>
								{intl.get('promo_import_hint')}
							</p>
						</CardContent>
					</Card>
				)}
			</div>
		</div>
	)
}
