import { find } from 'lodash/fp'
import React, { memo, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Button, Loader } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import ErrorBoundary from '@/components/ErrorBoundary'
import { FormProvider } from '@/components/HookForm'
import { OptimizationGroupModel } from '@/generated'
import intl from '@/locale'
import { useOptimizationGroupsQuery } from '@/modules/core/queries'

import { isGlobalPricingCampaign } from '../../helpers'
import PricingCampaignsPriority, {
	useOrderedPricingCampaignsProductsCounts,
} from './PricingCampaignsPriority'
import { usePricingCampaignOrdering } from './usePricingCampaignOrdering'

export default function PricingCampaignsPriorityModal({
	isOpen,
	close,
	optimizationGroupId,
	scenarioId,
}: {
	isOpen: boolean
	close: () => void
	optimizationGroupId?: string | null
	scenarioId?: string | null
}) {
	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={close} showCloseButton size='large'>
					<PricingCampaignOrdering
						close={close}
						optimizationGroupId={optimizationGroupId}
						scenarioId={scenarioId}
					/>
				</Modal>
			)}
		</ModalTransition>
	)
}
function PricingCampaignOrdering({
	close,
	optimizationGroupId,
	scenarioId,
}: {
	close: () => void
	optimizationGroupId?: string | null
	scenarioId?: string | null
}) {
	const optimizationGroupQuery = useOptimizationGroupsQuery<
		OptimizationGroupModel | undefined
	>({
		select: find<OptimizationGroupModel>({ id: optimizationGroupId! }),
		enabled: !!optimizationGroupId,
	})

	const { methods, handleSubmit, isLoading, isProcessing } =
		usePricingCampaignOrdering({
			optimizationGroupId,
			scenarioId,
		})

	return (
		<FormProvider
			className='flex flex-col overflow-auto'
			methods={methods}
			onSubmit={handleSubmit}
		>
			<ModalHeader>
				<ModalTitle>
					{intl
						.get('pc.priority.modal.title')
						.d('Manage pricing campaigns priority')}
				</ModalTitle>
			</ModalHeader>
			<ErrorBoundary>
				{optimizationGroupQuery.data && (
					<div className='border-y bg-accent-2 px-5 py-3'>
						<h2>{optimizationGroupQuery.data?.name}</h2>
					</div>
				)}
				<ModalBody className='relative flex flex-1 overflow-hidden p-0'>
					{isLoading ? (
						<LoadingSkeleton />
					) : (
						<div className='relative flex flex-1 flex-col overflow-y-auto px-5'>
							<Blanket
								isTinted={isProcessing}
								className='absolute flex items-center justify-center rounded-lg bg-white/30 backdrop-blur-sm dark:bg-black/30'
							>
								<Loader />
							</Blanket>
							<PricingCampaignsOrderingSections
								optimizationGroupId={optimizationGroupId}
							/>
						</div>
					)}
				</ModalBody>
			</ErrorBoundary>
			<ModalFooter className='flex items-center justify-between'>
				<Button disabled={isProcessing} onClick={close}>
					{intl.get('general_cancel')}
				</Button>
				<div className='flex items-center gap-2'>
					{methods.formState.isDirty && (
						<Button
							variant='tertiary'
							onClick={() => methods.reset()}
							disabled={isProcessing}
							iconBefore={<UndoIcon />}
						>
							{intl.get('app.revert_changes')}
						</Button>
					)}
					<Button
						variant='primary-brand'
						disabled={!methods.formState.isDirty || isProcessing}
						onClick={handleSubmit}
					>
						{intl.get('pc.save_priority_changes').d('Save priority changes')}
					</Button>
				</div>
			</ModalFooter>
		</FormProvider>
	)
}
function PricingCampaignsOrderingSections({
	optimizationGroupId,
}: {
	optimizationGroupId?: string | null
}) {
	const { control, watch } = useFormContext()

	/**
	 * The global pricing campaigns are not draggable when the optimization group is presented
	 */
	const isItemDragDisabled = useMemo(
		() => (optimizationGroupId ? isGlobalPricingCampaign : undefined),
		[optimizationGroupId]
	)

	/**
	 * Predicts the number of products that will be assignet by each pricing campaign
	 * we must to use both global and local pricing campaigns to get the correct prediction
	 */
	const predicts = useOrderedPricingCampaignsProductsCounts(
		[...watch('global'), ...watch('local')],
		optimizationGroupId
	)
	return (
		<>
			<Controller
				name={'global'}
				control={control}
				render={({ field: { value, onChange } }) => {
					if (!value?.length) {
						return <div />
					}
					return (
						<div>
							<div className='sticky top-0 z-50 bg-accent-1 py-2'>
								<h4 className='text-xs'>
									{intl
										.get('app.global_pricing_campaigns')
										.d('Global pricing campaigns')}
								</h4>
							</div>
							<PricingCampaignsPriority
								predicts={predicts}
								pricingCampaigns={value}
								onChange={onChange}
								isItemDragDisabled={isItemDragDisabled}
							/>
						</div>
					)
				}}
			/>
			<Controller
				name={'local'}
				control={control}
				render={({ field: { value, onChange } }) => {
					if (!value?.length) {
						return <div />
					}
					return (
						<div>
							<div className='sticky top-0 z-50 bg-accent-1 py-2'>
								<h4 className='text-xs'>
									{intl
										.get('app.local_pricing_campaigns')
										.d('Local pricing campaigns')}
								</h4>
							</div>
							<PricingCampaignsPriority
								predicts={predicts}
								pricingCampaigns={value}
								onChange={onChange}
								isItemDragDisabled={isItemDragDisabled}
							/>
						</div>
					)
				}}
			/>
		</>
	)
}

const LoadingSkeleton = memo(() => (
	<div className='w-full'>
		<div className='relative space-y-1 p-5'>
			{[...Array(5)].map((_, index) => (
				<div
					key={index}
					className='flex h-14 w-full items-center space-x-2 rounded bg-accent-3 px-5'
				>
					<div className='size-4 animate-pulse rounded-lg bg-accent-4' />
					<div className='size-7 animate-pulse rounded-lg bg-accent-4' />
					<div className='space-y-2'>
						<div className='h-5 w-52 animate-pulse rounded-lg bg-accent-4' />
						<div className='h-3 w-72 animate-pulse rounded-lg bg-accent-4' />
					</div>
				</div>
			))}
		</div>
	</div>
))
