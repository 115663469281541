import { SeriesColumnOptions } from 'highcharts'
import { filter, isNil, map, prop } from 'lodash/fp'
import React from 'react'

import Chart from '@/components/Chart'
import intl from '@/locale'
import { useAiBIAnalyticQuery, useMetricsQuery } from '@/modules/bi/queries'
import analytic from '@/services/analytics'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { AiDashboardFilter } from '../../Filter'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetEmptyState from '../../WidgetEmptyState'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetProps } from '../types'

const translateMetric = (metric: string) => intl.get(`metric.${metric}`)

export default function SalesFactorsImpactWidget({
	commonFilters,
	commonPeriods,
	params,
	widget,
	onChangeParams,
}: WidgetProps) {
	const { data: metrics } = useMetricsQuery<string[]>({
		select: (data) => map('name', filter({ type: 'ai_importance' }, data)),
	})
	const commonQueryParams = {
		label: widget.kind,
		...(commonPeriods?.main || {
			start_date: null,
			end_date: null,
		}),
		skip_dates: true,
		metrics: metrics as string[],
		dimensions: [],
		date_aggregation: null,
		filters: [...commonFilters, ...(params.filters ?? [])],
	}

	const biQuery = useAiBIAnalyticQuery(commonQueryParams, {
		enabled: !!metrics?.length,
	})
	const categories = map(translateMetric, metrics) || []
	const series = [
		{
			type: 'column' as const,
			name: 'Impact',
			color: 'var(--cmp-primary)',
			data: (biQuery.data?.data?.[0] || []).map((y, i) => ({
				y,
				metric_key: metrics![i],
			})),
		},
	]
	const isEmpty = biQuery.data?.data?.[0].every(isNil)

	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetLoadingState isTinted={biQuery.isLoading} />
			<WidgetErrorState isTinted={biQuery.isError} />
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.sales_factors_impact.title')}
			/>
			<WidgetContent className='space-y-3 pb-0'>
				<div className='flex items-center justify-between'>
					<AiDashboardFilter
						filters={params.filters ?? []}
						onChange={(filters) => {
							analytic.logEvent(`dashboard: bi: apply filter ${widget.kind}`, {
								'filter by': filters.map(prop('name')).join(','),
							})
							onChangeParams({ ...params, filters })
						}}
					/>
				</div>
				{isEmpty ? (
					<WidgetEmptyState />
				) : (
					<Chart config={getChartConfig({ series, categories })} />
				)}
			</WidgetContent>
		</Widget>
	)
}

function formatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	const points = self.points
		// eslint-disable-next-line
		?.map((point: any) => {
			const metricKey = (point.point as any)['metric_key'] as string // eslint-disable-line
			return `
			<div class="w-full max-w-full overflow-hidden">
			<div class="flex justify-between">
				<div class="flex items-center">
					<div class="color-dot rounded-lg mr-4" style="background: ${point.color};"></div>
					<strong class="truncate">${this.x as number}</strong>
				</div>
				<div class="flex items-center">
					<strong class="flex items-center mr-2">${formatNumber(point.y as number, NumberFormats.Percent)}</strong>
				</div>
			</div>
			<div class="text-muted text-xs max-w-full whitespace-normal">
				${intl.get(`metric.${metricKey}.desc`)}
			</div>
			</div>`
		})
		.join('')
	return `<div class="chart-tooltip w-64">
		${points}
	</div>`
}
function getChartConfig({
	series,
	categories,
}: {
	series: SeriesColumnOptions[]
	categories: string[]
}) {
	return {
		chart: {
			type: 'column',
			height: 390,
			animation: true,
		},
		yAxis: {
			title: {
				text: null,
			},
			labels: { format: '{text}%' },
		},
		legend: {
			enabled: false,
		},
		xAxis: {
			categories,
		},
		tooltip: {
			animation: true,
			outside: true,
			shared: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter,
		},
		plotOptions: {
			column: {
				borderRadius: 4,
			},
		},
		series,
	}
}
