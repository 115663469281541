import { JSONSchemaType } from 'ajv'
import { filter, find, isEmpty, map, pipe, prop, propOr, set } from 'lodash/fp'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormError, InlineMessage } from '@cmpkit/base'
import Select, { OptionType } from '@cmpkit/select'

import { DataOption } from '@/common.types'
import ErrorBoundary from '@/components/ErrorBoundary'
import { toAdaptedSchema } from '@/components/filter/adapter'
import HookSchemaForm from '@/components/HookSchemaForm'
import {
	CompetitorModel,
	CompetitorsResponseModel,
	PricingRuleModel,
	PricinRuleUiSchemaModel,
} from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import {
	useColumnsSchemaQuery,
	useCompetitorsOptionsQuery,
} from '@/modules/core/queries'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import { usePricingRulesUiSchemasQuery } from '@/modules/pricing-campaigns/queries'
import {
	getResolvedSchema,
	getSchemaDefaultState,
	getUiSchema,
	translateSchemaKeys,
} from '@/tools/json-schema-utils'

// eslint-disable-next-line
type RuleOptionType = OptionType & { params_schema: any }
const competitorToOption = ({ name }: CompetitorModel): DataOption => ({
	label: name!,
	value: name!,
})
const selectCompetitorsAsOptions = ({ objects }: CompetitorsResponseModel) =>
	objects?.map(competitorToOption) || []

function RepricingRulesManager({
	prefix,
	formData,
	onChange,
	pricingRules,
}: {
	prefix: string
	formData: {
		rule_id: string | null
		rule_params: object
	}
	onChange(value: { rule_id: string | null; rule_params: object }): void
	pricingRules: PricingRuleModel[]
}) {
	const optimizationGroupId = useOptimizationGroupId()!

	// select current pricing rule
	const rule = useMemo(
		() => find({ id: formData?.rule_id }, pricingRules),
		[formData?.rule_id, pricingRules]
	)

	// select params schema of current pricing rule
	const selectedRuleParamsSchema = useMemo(
		() => translateSchemaKeys(prop('params_schema', rule) ?? {}),
		[rule]
	)

	const competitors = useCompetitorsOptionsQuery<DataOption[]>(
		optimizationGroupId
			? {
					optimization_group_id: optimizationGroupId,
					limit: 0,
					order_by: 'name',
				}
			: { limit: 0, order_by: 'name' },
		{
			select: selectCompetitorsAsOptions,
		}
	)
	const columnsSchemaQuery = useColumnsSchemaQuery({
		select: pipe([filter({ category: 'price' }), map(toAdaptedSchema)]),
	})

	const uiSchema = useMemo(() => {
		return set(
			`${prefix}.rule_params`,
			getUiSchema(prop('ui_schema', rule), {
				competitors: competitors.data || [],
				price_column: columnsSchemaQuery.data || [],
			}),
			{}
		)
	}, [rule, competitors.data, columnsSchemaQuery.data])
	const rulesOptions = useMemo(
		() =>
			pricingRules.map((rule): RuleOptionType => {
				return {
					label: intl
						.get(rule.params_schema?.title || `${rule.id}_title`)
						.d(rule.params_schema?.title),
					value: rule.id,
					params_schema: rule.params_schema,
				}
			}),
		[pricingRules]
	)

	return (
		<div>
			<Select<RuleOptionType, false>
				value={find({ value: formData?.rule_id }, rulesOptions)}
				className='max-w-52'
				menuPortalTarget={document.body}
				menuPlacement='auto'
				onChange={(option) => {
					option &&
						onChange({
							rule_id: option.value as string,
							rule_params: getSchemaDefaultState(option.params_schema),
						})
				}}
				options={rulesOptions}
			/>
			{isEmpty(selectedRuleParamsSchema) ? null : (
				<div className='mt-5 w-full'>
					{selectedRuleParamsSchema && (
						<InlineMessage variant='default' className='mb-5 w-full'>
							<h4>
								{intl
									.get(
										propOr(
											`${formData.rule_id}_title`,
											'title',
											selectedRuleParamsSchema
										)
									)
									.d(selectedRuleParamsSchema?.title)}
							</h4>
							<p
								dangerouslySetInnerHTML={{
									__html: intl
										.get(
											propOr(
												`${formData.rule_id}_desc`,
												'description',
												selectedRuleParamsSchema
											)
										)
										.d(selectedRuleParamsSchema?.description),
								}}
							/>
						</InlineMessage>
					)}
					<HookSchemaForm
						schema={selectedRuleParamsSchema}
						name={`${prefix}.rule_params`}
						uiSchema={uiSchema}
					/>
				</div>
			)}
		</div>
	)
}
export function RepricingRulesFieldController({ name }: { name: string }) {
	const { control } = useFormContext()

	const uiSchemasQuery = usePricingRulesUiSchemasQuery<
		Map<string, PricinRuleUiSchemaModel['ui_schema']>
	>({
		select: (data) =>
			data
				? new Map(data.map(({ id, ui_schema }) => [id, ui_schema]))
				: new Map(),
	})
	const pricingRules = useSettingsSchemasQuery<
		JSONSchemaType<PricingRuleModel>[]
	>({
		select: pipe([
			getResolvedSchema('part:pricing_rules'),
			translateSchemaKeys,
			prop('oneOf'),
			map(prop('properties')),
		]),
	})
	const rules = useMemo(() => {
		if (pricingRules.data && uiSchemasQuery?.data) {
			return pricingRules.data.map((rule) => {
				return {
					id: rule.rule_id.const,
					params_schema: rule.rule_params,
					ui_schema: uiSchemasQuery.data.get(rule.rule_id.const)!,
				}
			})
		}
	}, [pricingRules, uiSchemasQuery])

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value, onChange }, fieldState: { error } }) => {
				return (
					<>
						<ErrorBoundary>
							{pricingRules.isLoading ? (
								<div className='h-8 w-52 animate-pulse rounded-lg bg-accent-3' />
							) : (
								<RepricingRulesManager
									prefix={name}
									formData={value}
									onChange={onChange}
									pricingRules={rules || []}
								/>
							)}
						</ErrorBoundary>
						{error && (
							<FormError className='mt-1 w-full items-center text-danger'>
								{intl
									.get('pc.repricing_rule.invalid')
									.d(
										'Pricing rule is not valid, please select pricing rule and fill all params'
									)}
							</FormError>
						)}
					</>
				)
			}}
		/>
	)
}
