import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { showNewMessage } from '@intercom/messenger-js-sdk'

import { Button, InlineMessage, Loader, Spinner } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CommentIcon from '@cmpkit/icon/lib/glyph/comment'
import {
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@cmpkit/modal'

import { DataOption } from '@/common.types'
import {
	FormProvider,
	RHFFileInput,
	RHFSelect,
	RHFTextArea,
	RHFTextField,
} from '@/components/HookForm'
import notify from '@/components/toasts'
import { RequestCreateDTO, UserModel } from '@/generated'
import intl, { currentLocale, langCodeMapping } from '@/locale'
import { useAuth } from '@/modules/Auth/AuthContext'
import { getUsername } from '@/modules/Users/utils'
import analytic from '@/services/analytics'
import { getProject } from '@/tools/utils'

import {
	JSD_ID,
	JSD_REQUEST_TYPES,
	JSD_SPECIFIC_REQUEST_TYPES,
} from '../../constants'
import { useCreateSupportTicketMutation } from '../../mutations'
import {
	useJsdAccountIdQuery,
	useJsdOrganizationIdQuery,
	useJsdRequestTypesQuery,
} from '../../queries'

export default function ReportProblem({
	close,
	requesttype = undefined,
}: {
	close(): void
	requesttype?: string | undefined
}) {
	const { user } = useAuth()
	const { data: jsdAccounId } = useJsdAccountIdQuery(user?.email, {
		enabled: !!user?.email,
	})
	const { data: organizationId } = useJsdOrganizationIdQuery()
	const methods = useForm({
		resolver: yupResolver<FormData>(FormDataSchema),
		defaultValues: {
			requesttype,
			summary: '',
			message: '',
			attachments: [],
		},
	})

	useEffect(() => {
		analytic.logEvent('jsd: create ticket: open modal')
	}, [])

	const createSupportTicket = useCreateSupportTicketMutation()
	const requestTypes = useJsdRequestTypesQuery<DataOption[]>(
		{
			limit: 100,
			requestLanguage: langCodeMapping[currentLocale] || 'en-US',
		},
		{
			select: (data): DataOption[] =>
				data?.values?.map(
					(item) =>
						({
							value: item.id,
							label: item.name,
						}) as DataOption
				) || [],
		}
	)

	const { isPending } = createSupportTicket

	const onSubmit = async (data: FormData) => {
		analytic.logEvent('jsd: create ticket: submit form', {
			attachments: data.attachments?.length ?? 0,
		})
		try {
			const email = user?.email ?? ''
			const username = getUsername(user as UserModel) ?? ''
			const project = getProject()
			const requestFieldValues: RequestCreateDTO['requestFieldValues'] = {
				summary: data.summary,
				description: `
				${data.message}

				|*Email*|${email}|
				|*Name*|${username}|
				|*Project*|${project}|
				|*Reported from*|${window.location.href}|
				`,
			}

			// Organization field id in JSD
			const organizationIdFieldId = 'customfield_10700'

			// If organizationId is not null, then add it to requestFieldValues
			if (!!organizationId) {
				requestFieldValues[organizationIdFieldId] = [Number(organizationId)]
			}
			await createSupportTicket.mutateAsync({
				attachments: data.attachments,
				request: {
					requestFieldValues,
					raiseOnBehalfOf: jsdAccounId as string,
					requestTypeId: data.requesttype,
					serviceDeskId: JSD_ID,
				},
			})
			notify.success({
				text: intl.get('support.ticket.created'),
			})
		} catch (error) {
			notify.error({
				text: 'Error on creating ticket',
			})
		}
	}

	return (
		<>
			<ModalHeader>
				<ModalTitle>
					{intl.get('support.window.title').d('Operational support')}
				</ModalTitle>
				<ModalDescription>
					{intl
						.get('support.window.subtitle')
						.d('We are here to help you with any questions you may have.')}
				</ModalDescription>
			</ModalHeader>
			{createSupportTicket.data && (
				<InlineMessage variant='success'>
					{intl.get('support.ticket.created')}{' '}
					<a href={createSupportTicket.data._links?.web} target='_blank'>
						{createSupportTicket.data.issueKey}
					</a>
				</InlineMessage>
			)}

			<ModalBody className='flex flex-col'>
				<Blanket
					isTinted={isPending}
					className='absolute flex items-center justify-center bg-white/50 backdrop-blur-sm dark:bg-black/50'
				>
					<Loader />
				</Blanket>
				<FormProvider
					methods={methods}
					onSubmit={methods.handleSubmit(onSubmit)}
				>
					<fieldset disabled={Boolean(isPending || createSupportTicket.data)}>
						<div className='flex items-center justify-between'>
							<h4>
								{intl
									.get('support.window.form.subtitle_1')
									.d('What can we help you with?')}
							</h4>
							<Button
								onClick={() => {
									analytic.logEvent('jsd: create ticket: open intercom')
									showNewMessage(methods.watch('message'))
								}}
								size='small'
								iconBefore={<CommentIcon />}
							>
								{intl.get('app.live_chat').d('Live chat')}
							</Button>
						</div>

						<p className='section-subtitle mb-2 mt-1'>
							{intl
								.get('support.window.form.subtitle_2')
								.d(
									'You will get the answer to your request by email shortly after one of us receives your message.'
								)}
						</p>
						<RHFSelect
							required
							isLoading={requestTypes.isLoading}
							options={[
								{
									label: intl
										.get('jsd.requesttype.category.specific')
										.d('Specific types'),
									options: (requestTypes.data ?? []).filter((item) =>
										JSD_SPECIFIC_REQUEST_TYPES.includes(item.value)
									),
								},
								{
									label: intl
										.get('jsd.requesttype.category.common')
										.d('Common types'),
									options: (requestTypes.data ?? []).filter((item) =>
										JSD_REQUEST_TYPES.includes(item.value)
									),
								},
							]}
							className='w-full'
							placeholder={intl.get('app.select_category').d('Select category')}
							label={intl
								.get('support.window.form.category.label')
								.d('I’m writing about...')}
							name='requesttype'
						/>
						<RHFTextField
							placeholder={intl
								.get('support.window.form.summary.placeholder')
								.d('What does your issue mainly connect with?')}
							required
							autoComplete='off'
							autoCorrect='off'
							className='w-full'
							label={intl.get('support.window.form.summary.label').d('Subject')}
							name='summary'
						/>
						<RHFTextArea
							placeholder={intl
								.get('support.window.form.message.placeholder')
								.d('Describe your issue in details')}
							required
							rows={5}
							autoComplete='off'
							autoCorrect='off'
							className='w-full'
							label={intl.get('support.window.form.message.label').d('Message')}
							name='message'
						/>
						<RHFFileInput
							label={intl
								.get('support.window.form.attachment.label')
								.d('Attachment')}
							className='w-full'
							name='attachments'
						/>
					</fieldset>
				</FormProvider>
			</ModalBody>
			<ModalFooter className='justify-between'>
				<Button onClick={close}>{intl.get('general_cancel')}</Button>
				<Button
					variant='primary-brand'
					iconBefore={isPending && <Spinner variant='invert' />}
					disabled={Boolean(isPending || createSupportTicket.data)}
					onClick={methods.handleSubmit(onSubmit)}
				>
					{intl.get('general_send').d('Send')}
				</Button>
			</ModalFooter>
		</>
	)
}

const FormDataSchema = Yup.object().shape({
	requesttype: Yup.string().required('Request type is required'),
	summary: Yup.string().max(256).required('Summary is required'),
	message: Yup.string().max(256).required('Message is required'),
	attachments: Yup.mixed(),
})

type FormData = Yup.InferType<typeof FormDataSchema>
