import React from 'react'

import { Placement } from '@floating-ui/react-dom'

import { StateButton, TreeNodeType } from '@cmpkit/base'
import FilterIcon from '@cmpkit/icon/lib/glyph/filter'
import { FieldConfigType } from '@cmpkit/query-builder/dist/types'

import { DataOption } from '@/common.types'
import FiltersPopover from '@/components/filter/FiltersPopover'
import { FilterField, FilterRuleModel } from '@/components/filter/types'
import intl from '@/locale'

interface Props {
	filters: FilterRuleModel[]
	onChange(filters: FilterRuleModel[]): void
	placement?: Placement
	fields?: FilterField[]
	options?: Record<string, DataOption[] | TreeNodeType[]>
	extendedFieldConfig?: FieldConfigType
	irremovableCount?: number
}

export default function BaseFilter({
	filters,
	fields,
	options,
	onChange,
	placement = 'bottom-start',
	extendedFieldConfig,
	irremovableCount,
}: Props) {
	return (
		<FiltersPopover
			fields={fields || []}
			filters={filters ?? []}
			dataChoices={options}
			onApply={onChange}
			extendedFieldConfig={extendedFieldConfig}
			irremovableCount={irremovableCount}
			placement={placement}
		>
			<StateButton
				size='small'
				iconBefore={<FilterIcon />}
				onClear={() => onChange([])}
				active={!!filters.length}
			>
				{`${intl.get('general_filter')}`}{' '}
				{!!filters.length ? `(${filters.length})` : ''}
			</StateButton>
		</FiltersPopover>
	)
}
