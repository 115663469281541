import clsx from 'clsx'
import React, { ReactNode, useState } from 'react'

import { Button } from '@cmpkit/base'
import ChevronDownIcon from '@cmpkit/icon/lib/glyph/chevron-down'
import ChevronUpIcon from '@cmpkit/icon/lib/glyph/chevron-up'

export default function CollapsibleSection({
	header,
	className,
	subtitle,
	children,
	isOpen = true,
	disabled,
}: {
	header: ReactNode
	subtitle?: string
	className?: string
	children: React.ReactNode
	isOpen?: boolean
	disabled?: boolean
}) {
	const [isCollapsed, setCollapsed] = useState<boolean>(!isOpen)
	const ArrowIcon = isCollapsed ? ChevronDownIcon : ChevronUpIcon
	return (
		<div className={clsx('collapsible-section', className)}>
			<div className='flex items-center justify-between align-bottom'>
				<div className='flex w-full items-center'>{header}</div>
				{!disabled && (
					<Button
						size='small'
						variant='tertiary'
						/* disabled={disabled} */
						onClick={() => setCollapsed((prev) => !prev)}
						iconAfter={<ArrowIcon className='shrink-0' />}
					/>
				)}
			</div>
			{!!subtitle && <p className='section-subtitle'>{subtitle}</p>}
			<div className={clsx('mt-2', { hidden: isCollapsed })}>{children}</div>
		</div>
	)
}
