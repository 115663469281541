import { Operators, ValueEditorType, ValueType } from '@cmpkit/query-builder'

import { FilterField } from '../filter/types'
import {
	BooleanFilter,
	NumberFilter,
	SelectFilter,
	TextFilter,
	TreeSelectFilter,
} from './fields'

/**
 * Get editor depend on given editor type
 * @param type ValueEditorType - editor type
 * @returns - editor component
 */
export function getFieldByEditorType(type: ValueEditorType) {
	switch (type) {
		case ValueEditorType.text:
			return TextFilter
		case ValueEditorType.number:
			return NumberFilter
		case ValueEditorType.multiselect:
		case ValueEditorType.select:
			return SelectFilter
		case ValueEditorType.checkbox:
			return BooleanFilter
		case ValueEditorType.checkboxTree:
			return TreeSelectFilter
		default:
			return null
	}
}
/**
 * Get editor depend on given value type
 * @param type ValueEditorType - value type
 * @returns - editor component
 */
export function getFieldByValueType(type: ValueType) {
	switch (type) {
		case ValueType.float:
		case ValueType.int:
		case ValueType.number:
			return NumberFilter
		case ValueType.boolean:
			return BooleanFilter
		case ValueType.str:
			return TextFilter
		case ValueType.array:
			return SelectFilter
		default:
			return TextFilter
	}
}

export const getFiledConfig = (
	fields: FilterField[],
	dataChoices?: Record<string, unknown[]>
) =>
	fields.reduce((acc, field) => {
		const type =
			getFieldByEditorType(field.valueEditorType as ValueEditorType) ||
			getFieldByValueType(field.valueType)
		if (!type) {
			return acc
		}
		const config = {
			label: field.label,
			type,
			note: field.note,
		}

		if (
			field.valueType === ValueType.str &&
			[ValueEditorType.checkboxTree, ValueEditorType.multiselect].includes(
				field.valueEditorType as ValueEditorType
			)
		) {
			Object.assign(config, {
				getInitialValue() {
					return {
						operation: Operators.IN,
						value: [],
					}
				},
			})
		}

		Object.assign(config, {
			useSelectOptions: () => ({
				data: dataChoices?.[field.value] || [],
			}),
		})
		if (field.enum && field.enum.length > 0) {
			Object.assign(config, {
				useSelectOptions: () => ({
					data: field.enum!.map((value) => ({
						label: value,
						value,
					})),
				}),
			})
		} /*  else if ((field as any).dynamic) {
			Object.assign(config, {
				useSelectOptions: () => ({
					data: [
						{
							label: 'Dynamic',
							value: 'dynamic',
						},
					],
				}),
			})
		} */
		return {
			...acc,
			[field.value]: config,
		}
	}, {})
