import { filter, orderBy, pipe } from 'lodash/fp'

import { Dot, Spinner } from '@cmpkit/base'

import { OptimizationGroupModel } from '@/generated'
import {
	useOptimizationGroupsQuery,
	useOptimizationsQuery,
} from '@/modules/core/queries'
import { getOgStatus, getStatusBadgeVariant } from '@/modules/core/utils'

export default function OptimizationGroupsTooltipList({
	ids,
}: {
	ids: string[]
}) {
	const groupsQuery = useOptimizationGroupsQuery({
		select: pipe([
			filter((og: OptimizationGroupModel) => ids.includes(og.id)),
			orderBy(['name'], ['asc']),
		]),
	})
	const optimiztionsQuery = useOptimizationsQuery()
	return (
		<div className='space-y-1 py-1'>
			{groupsQuery.data?.map((og: OptimizationGroupModel) => {
				const optimization = optimiztionsQuery.data?.[og.id]
				return (
					<div key={og.id} className='flex items-center space-x-2'>
						{optimiztionsQuery.isFetching ? (
							<Spinner className='shrink-0' />
						) : (
							<Dot
								// FIXME: This is a bug in the cmpkit library
								variant={
									optimization
										? (getStatusBadgeVariant(
												getOgStatus(optimization.status!)
												// eslint-disable-next-line @typescript-eslint/no-explicit-any
											) as any)
										: undefined
								}
							/>
						)}
						<span>{og.name}</span>
					</div>
				)
			})}
		</div>
	)
}
