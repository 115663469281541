import {
	any,
	filter,
	map,
	merge,
	orderBy,
	paths,
	pipe,
	prop,
	uniqBy,
} from 'lodash/fp'
import { useMemo } from 'react'

import { TreeNodeType } from '@cmpkit/base'
import { Operators, ValueEditorType, ValueType } from '@cmpkit/query-builder'

import { toAdaptedSchema } from '@/components/filter/adapter'
import { FilterField } from '@/components/filter/types'
import { getFiledConfig } from '@/components/refinement-bar/helpers'
import intl from '@/locale'
import { useBiFieldsQuery, useBiFieldValuesQuery } from '@/modules/bi/queries'
import { getSalesEntitiesTree } from '@/modules/core/helpers'
import {
	useBrandsQuery,
	useCategoriesQuery,
	useOptimizationGroupsQuery,
	useSalesEntitiesQuery,
} from '@/modules/core/queries'
import { toCategoriesTree } from '@/modules/core/utils'
import { usePricingCampaignsQuery } from '@/modules/pricing-campaigns/queries'
import { toOption } from '@/tools/utils'
import { getPricingCampaignEngines } from '@/modules/pricing-campaigns'

const formatOptionsById = pipe([
	map(paths(['id', 'name'])),
	toOption,
	orderBy(['label'], ['asc']),
	uniqBy('value'),
])
const formatOptionsByName = pipe([
	map(paths(['name', 'name'])),
	toOption,
	orderBy(['label'], ['asc']),
	uniqBy('value'),
])

export function useFilterFieldsConfig({
	sanitizeFields,
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	sanitizeFields?: (fieldsConfig: Record<string, any>) => Record<string, any>
}) {
	const biFieldsQuery = useBiFieldsQuery({
		select: filter(prop('filterable')),
	})
	const brandsQuery = useBrandsQuery({})
	const salesEntitiesQuery = useSalesEntitiesQuery()
	const pricingCampaignsQuery = usePricingCampaignsQuery({})
	const optimizationGroupsQuery = useOptimizationGroupsQuery()
	const categoriesQuery = useCategoriesQuery<TreeNodeType[]>(
		{},
		{
			select: toCategoriesTree,
		}
	)
	const dataChoices = useMemo(
		() => ({
			brand: brandsQuery.data || [],
			category_id: categoriesQuery.data || [],
			optimization_group_id:
				formatOptionsById(optimizationGroupsQuery.data) || [],
			optimization_group:
				formatOptionsByName(optimizationGroupsQuery.data) || [],
			pos_id: getSalesEntitiesTree(salesEntitiesQuery.data) || [],
			pos_ids: getSalesEntitiesTree(salesEntitiesQuery.data) || [],
			pricing_campaign_name:
				formatOptionsByName(pricingCampaignsQuery.data) || [],
			pricing_campaign_engine: getPricingCampaignEngines(),
		}),
		[
			optimizationGroupsQuery.data,
			brandsQuery.data,
			pricingCampaignsQuery.data,
			categoriesQuery.data,
			salesEntitiesQuery.data,
		]
	)
	const fields = useMemo(
		() =>
			uniqBy('value', [
				...getDefautFields(),
				...map(
					pipe([
						(f) => ({ ...toAdaptedSchema(f), dynamic: f.is_dynamic_values }),
						extendEditorTypes,
					]),
					[...commonSchema, ...(biFieldsQuery.data || [])]
				),
			]) as unknown as FilterField[],
		[biFieldsQuery.data]
	)
	const fieldsConfig = useMemo(() => {
		const config = getFiledConfig(fields, dataChoices)
		const extendedConfig = biFieldsQuery.data
			?.filter((schema) => schema.is_dynamic_values)
			.reduce((acc, schema) => {
				acc[schema.name] = {
					useSelectOptions() {
						return useBiFieldValuesQuery(schema.name, {
							select: (list) => list?.map((value) => ({ value, label: value })),
						})
					},
				}
				return acc
			}, {})
		return merge(config, extendedConfig)
	}, [fields, dataChoices])

	const isLoading = any(prop('isLoading'), [
		optimizationGroupsQuery,
		brandsQuery,
		pricingCampaignsQuery,
		categoriesQuery,
		biFieldsQuery,
		salesEntitiesQuery,
	])

	return useMemo(
		() => ({
			fields,
			dataChoices,
			fieldsConfig: sanitizeFields
				? sanitizeFields(fieldsConfig)
				: fieldsConfig,
			isLoading,
		}),
		[fields, dataChoices, fieldsConfig, isLoading]
	)
}
/**
 * Helping function to extend editor types base on field options
 * @param field field to extend
 * @returns extended field
 */
const extendEditorTypes = (field: FilterField & { dynamic: boolean }) => {
	if (
		(field.enum?.length && field.valueType === ValueType.str) ||
		(field.dynamic && field.valueType === ValueType.str) ||
		(field.dynamic && field.valueType === ValueType.array)
	) {
		return {
			...field,
			valueEditorType: ValueEditorType.multiselect,
		}
	}
	return field
}

const commonSchema = [
	{
		name: 'optimization_group_id',
		type: 'str',
		translate_key: 'field_schema_optimization_group',
		filter_schema: {
			type: 'multiselect',
		},
		visible_name: 'Optimization group',
	},
	{
		name: 'pos_id',
		type: 'str',
		translate_key: 'field_schema_pos_id',
		filter_schema: {
			type: 'tree',
		},
		visible_name: 'Sales unit',
	},
	{
		name: 'pos_ids',
		type: 'array',
		translate_key: 'field_schema_pos_id',
		filter_schema: {
			type: 'tree',
		},
		visible_name: 'Sales unit',
	},
]

export const getDefautFields = () => [
	{
		value: 'pricing_campaign_engine',
		label: intl.get('app.pricing_type').d('Pricing type'),
		enum: [],
		valueType: ValueType.str,
		valueEditorType: ValueEditorType.multiselect,
		operations: [Operators.IN],
	},

	{
		value: 'optimization_group',
		label: intl.get('optimization_group').d('Optimization group'),
		valueType: ValueType.str,
		valueEditorType: ValueEditorType.multiselect,
		operations: [Operators.IN],
		enum: [],
	},
	{
		value: 'pricing_campaign_name',
		label: intl.get('pricing_campaign').d('Pricing Campaign'),
		valueType: ValueType.str,
		valueEditorType: ValueEditorType.multiselect,
		operations: [Operators.IN],
		enum: [],
	},
	{
		value: 'brand',
		label: intl.get('app.brand').d('Brand'),
		valueType: ValueType.str,
		operations: [Operators.IN],
		valueEditorType: ValueEditorType.multiselect,
		enum: [],
	},
	{
		value: 'category_id',
		label: intl.get('app.category').d('Category'),
		valueType: ValueType.str,
		valueEditorType: ValueEditorType.checkboxTree,
		enum: [],
	},
	{
		value: 'sku',
		label: 'SKU',
		valueType: ValueType.str,
		enum: [],
	},
	{
		value: 'pricing_line_id',
		label: intl.get('pricing_line_id'),
		valueType: ValueType.str,
		enum: [],
	},
	/* {
		value: 'pos_id',
		label: intl.get('field_schema_pos_id').d('Stores'),
		ValueType: ValueType.str,
		valueEditorType: ValueEditorType.checkboxTree,
		operations: [Operators.IN],
		enum: [],
	}, */
]
