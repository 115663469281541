import React from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import {
	Button,
	InlineMessage,
	Loader,
	SegmentGroup,
	SegmentGroupItem,
} from '@cmpkit/base'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@cmpkit/modal'
import { components } from '@cmpkit/select'

import ErrorBoundary from '@/components/ErrorBoundary'
import { FormProvider, RHFSelect, RHFTextField } from '@/components/HookForm'
import intl from '@/locale'
import analytic from '@/services/analytics'

import { getEnabledColumnsCount } from '../helpers'
import {
	ExportConfigurationContextValue,
	useExportConfiguration,
} from './ExportConfigurationProvider'

interface ExportParamsModalContentProps {
	isAdditional?: boolean
	isLoading?: boolean
	onSubmit(data: ExportParamsFormData): void
	onClose(): void
	defaultValues: Partial<ExportParamsFormData>
	options: ExportConfigurationContextValue['options']
}
export function ExportParamsModalContent({
	onSubmit,
	onClose,
	isLoading,
	defaultValues,
	options,
	isAdditional,
}: ExportParamsModalContentProps) {
	const {
		kind,
		state: { exportDestination, setExportDestination },
	} = useExportConfiguration()

	const methods = useForm<ExportParamsFormData>({
		defaultValues: {
			fields_preset: 'all',
			format: 'xlsx',
			aggregation: 'pricing_line',
			...defaultValues,
		},
		resolver: yupResolver(ExportParamsSchema),
	})

	const handleSubmit = methods.handleSubmit(onSubmit)
	if (isLoading) {
		return (
			<Modal onClose={onClose} size={'mini'} testId='export-data-modal-form'>
				<div className='flex items-center justify-center py-44'>
					<Loader />
				</div>
			</Modal>
		)
	}
	return (
		<Modal
			onClose={onClose}
			showCloseButton
			size={'mini'}
			testId='export-data-modal-form'
		>
			<ModalHeader>
				<ModalTitle>
					{intl.get('export.params.modal_title').d('Download report')}
				</ModalTitle>
			</ModalHeader>
			<ErrorBoundary
				fallback={
					<div className='flex flex-col justify-center py-20 text-center'>
						<p className='text-lg font-bold'>
							{intl.get('fatal_error_title')}🐞
						</p>
						<p className='text-muted'>{intl.get('fatal_error_desc')}</p>
					</div>
				}
			>
				<ModalBody>
					{!isAdditional && (
						<div className='mb-5 flex justify-center'>
							<SegmentGroup>
								<SegmentGroupItem
									onClick={() => {
										analytic.track('export: params: change type', {
											export_kind: kind,
											type: 'local',
										})
										setExportDestination('local')
									}}
									active={exportDestination === 'local'}
								>
									{intl.get('app.my_computer').d('My computer')}
								</SegmentGroupItem>
								<SegmentGroupItem
									onClick={() => {
										analytic.track('export: params: change type', {
											export_kind: kind,
											type: 'external',
										})
										setExportDestination('external')
									}}
									active={exportDestination === 'external'}
								>
									{intl.get('app.external_storage').d('External storage')}
								</SegmentGroupItem>
							</SegmentGroup>
						</div>
					)}
					{exportDestination === 'external' ? (
						<div className='h-44'>
							<InlineMessage
								variant='brand'
								className='w-full text-xs font-medium'
							>
								{intl
									.get('export.params.modal_external_storage_hint')
									.d(
										'Our integration team will process report to an external storage specified in the contract'
									)}
							</InlineMessage>
						</div>
					) : (
						<FormProvider methods={methods} onSubmit={handleSubmit}>
							<RHFTextField
								required
								label={intl.get('app.export_name').d('Export name')}
								className='w-full'
								autoComplete='off'
								name='name'
							/>
							<RHFSelect
								label={intl.get('app.columns_set').d('Columns set')}
								className='w-full'
								name='fields_preset'
								options={options['fields_preset']}
								components={{
									SingleValue: ({ children, ...props }) => (
										<components.SingleValue {...props}>
											<div className='flex items-center justify-between'>
												{children}
												<span className='ml-auto text-muted'>
													{getEnabledColumnsCount(props.data.preset)}
												</span>
											</div>
										</components.SingleValue>
									),
									Option: ({ children, ...props }) => (
										<components.Option {...props}>
											<div className='flex items-center justify-between'>
												{children}
												<span className='ml-auto text-muted'>
													{getEnabledColumnsCount(props.data.preset)}
												</span>
											</div>
										</components.Option>
									),
								}}
							/>
							<RHFSelect
								className='w-full'
								label={intl.get('app.file_format').d('File format')}
								name='format'
								options={options['format']}
							/>
							{!isAdditional && (
								<>
									<RHFSelect
										className='w-full'
										label={intl.get('app.group_by').d('Group by')}
										name='aggregation'
										options={options['aggregation']}
									/>
									{methods.watch('aggregation') === 'pricing_line' && (
										<InlineMessage
											variant='warning'
											className='w-full text-xs font-medium'
										>
											{intl
												.get('export.params.modal_group_by.pricing_line.hint')
												.d(
													'Grouping by "Pricing line" will disable "sku" and "title" columns'
												)}
										</InlineMessage>
									)}
								</>
							)}
						</FormProvider>
					)}
				</ModalBody>
				<ModalFooter className='justify-between'>
					<Button onClick={onClose} data-testid='export-data-modal-cancel'>
						{intl.get('general_cancel')}
					</Button>
					{exportDestination === 'external' ? (
						<Button
							variant='primary-brand'
							onClick={handleSubmit}
							data-testid='export-data-modal-export'
						>
							{intl.get('app.export').d('Export')}
						</Button>
					) : (
						<Button
							variant='primary-brand'
							onClick={handleSubmit}
							iconAfter={<CaretRightIcon />}
							data-testid='export-data-modal-next'
						>
							{intl.get('general_next')}
						</Button>
					)}
				</ModalFooter>
			</ErrorBoundary>
		</Modal>
	)
}

export const ExportParamsSchema = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	fields_preset: Yup.string().required('Columns set is required'),
	format: Yup.string()
		.oneOf(['xlsx', 'csv'])
		.required('File format is required'),
	aggregation: Yup.string().oneOf(['product', 'pricing_line']),
})
export type ExportParamsFormData = Yup.InferType<typeof ExportParamsSchema>
