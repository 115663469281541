import { any, pickAll, prop } from 'lodash/fp'
import React, { createContext, useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import toast from 'react-hot-toast'

import { ajvResolver } from '@hookform/resolvers/ajv'
import { useQueryClient } from '@tanstack/react-query'

import {
	SettingsTemplateCreateModel,
	SettingsTemplateModel,
	SettingsTemplateUpdateModel,
} from '@/generated'
import intl from '@/locale'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import analytic from '@/services/analytics'
import { getResolvedSchema } from '@/tools/json-schema-utils'

import {
	useCreateSettingsTemplateMutation,
	useUpdateSettingsTemplateMutation,
} from '../../mutations'
import { useSettingsTemplateQuery } from '../../queries'
import { ScenarioTemplateModel } from '../../types'

export default function TemplateBuilderProvider({
	children,
	templateId,
	onFinish,
}: {
	templateId?: string
	children: React.ReactNode
	onFinish?: () => void
}) {
	const isNew = !templateId

	const queryClient = useQueryClient()
	const templateType = 'scenario'
	useEffect(() => {
		analytic.logEvent('builder: open')
	}, [])
	const [activeType, setActiveType] = useState<string | null>(null)
	const schemaQuery = useSettingsSchemasQuery({
		select: getResolvedSchema(`template:${templateType}`),
	})
	const template = useSettingsTemplateQuery(templateId!, {
		enabled: !!templateId,
	})
	const createTemplateMutation = useCreateSettingsTemplateMutation({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['settings-templates'],
			})
		},
	})
	const updateTemplateMutation = useUpdateSettingsTemplateMutation(
		templateId!,
		{
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: ['settings-templates'],
				})
			},
		}
	)
	const methods = useForm<Omit<ScenarioTemplateModel, 'template_type'>>({
		resolver(values, context, options) {
			return ajvResolver(schemaQuery.data!, {
				strict: false,
			})(
				{
					...values,
					body: {
						...values.body,
						name: values.name,
					},
				},
				context,
				options
			)
		},
	})

	useEffect(() => {
		if (template.data) {
			methods.reset({
				name: template.data?.name,
				description: template.data?.description || '',
				body: template.data?.body,
			})
		} else if (!template.data && schemaQuery.data) {
			methods.reset({
				name: '',
				body: {
					name: '',
					settings: {
						stop_list: undefined,
						strategy: undefined,
						business: undefined,
					},
					pricing_campaigns: [],
				},
			})
			//getSchemaDefaultState(schemaQuery.data)
		}
	}, [template.data, schemaQuery.data])
	const isPending = any(prop('isPending'), [
		createTemplateMutation,
		updateTemplateMutation,
	])
	const isLoading = any(prop('isLoading'), [schemaQuery, template])
	const handleReset = () => {
		analytic.logEvent('builder: reset')
		methods.reset()
	}
	const handleSubmit = methods.handleSubmit(
		async (data: SettingsTemplateCreateModel | SettingsTemplateUpdateModel) => {
			setActiveType(null)
			if (isNew) {
				analytic.logEvent('builder: save', {
					is_new: true,
				})
				await createTemplateMutation.mutateAsync(
					{
						...data,
						body: {
							name: data.name,
							...data.body,
						},
						template_type: templateType,
					} as SettingsTemplateCreateModel,
					{
						onSuccess: () =>
							toast.success(
								intl.get('toast.entity.created', {
									entity: intl.get('entity.template'),
								}),
								{
									duration: 5000,
									id: 'settigs-template',
								}
							),
					}
				)
				onFinish?.()
			} else {
				analytic.logEvent('builder: save', {
					is_new: false,
				})
				await updateTemplateMutation.mutateAsync(
					{
						...data,
						body: {
							name: data.name,
							...data.body,
						},
					} as SettingsTemplateUpdateModel,
					{
						onSuccess: () =>
							toast.success(
								intl.get('toast.entity.updated', {
									entity: intl.get('entity.template'),
								}),
								{
									duration: 5000,
									id: 'settigs-template',
								}
							),
					}
				)
				onFinish?.()
			}
		}
	)

	return (
		<TemplateBuilderContext.Provider
			value={{
				isNew,
				template: template.data,
				meta: pickAll(
					['created_at', 'updated_at', 'created_by', 'updated_by'],
					template.data || {}
				),
				methods,
				isPending,
				isLoading,
				handleReset,
				handleSubmit,
				handleFinish: () => onFinish?.(),
				activeType,
				setActiveType,
			}}
		>
			{children}
		</TemplateBuilderContext.Provider>
	)
}
interface TemplateBuilderContextType {
	isNew: boolean
	template?: SettingsTemplateModel
	meta?: Partial<
		Pick<
			ScenarioTemplateModel,
			'created_at' | 'updated_at' | 'created_by' | 'updated_by'
		>
	>
	isPending: boolean
	isLoading: boolean
	methods: UseFormReturn<Omit<ScenarioTemplateModel, 'template_type'>>
	handleSubmit: () => void
	handleReset: () => void
	handleFinish: () => void
	activeType: string | null
	setActiveType: (type: string | null) => void
}
export const TemplateBuilderContext = createContext<TemplateBuilderContextType>(
	{
		isNew: true,
		isPending: false,
		isLoading: true,
		methods: {} as UseFormReturn<Omit<ScenarioTemplateModel, 'template_type'>>,
		handleSubmit: () => {},
		handleReset: () => {},
		handleFinish: () => {},
		activeType: null,
		setActiveType: () => {},
	}
)

export function useTemplateBuilderContext() {
	return React.useContext(TemplateBuilderContext)
}
