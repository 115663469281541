import ReactGridLayout from 'react-grid-layout'
import LazyLoad from 'react-lazyload'
//import { SizeMe } from 'react-sizeme'

import ErrorBoundary from '@/components/ErrorBoundary'
import { FilterRuleModel } from '@/generated'

import { filterWidgetsBySection } from '../helpers'
import { DashboardWidgetEntity } from '../types'
import { UseDashboardWidgets } from '../useDashboardWidgets'
import WidgetContainer from './WidgetContainer'
import { WidgetPeriods } from './widgets/types'

type WidgetsSectionProps = {
	id: string
	dashboard: UseDashboardWidgets
	periods?: WidgetPeriods | null
	filters?: FilterRuleModel[]
}
export default function WidgetsSection({
	dashboard,
	id,
	periods,
	filters = [],
}: WidgetsSectionProps) {
	const widgets = dashboard.widgets?.filter(filterWidgetsBySection(id))
	const handleLayoutChange = (layout: ReactGridLayout.Layout[]) =>
		dashboard.onLayoutChange(id, layout)

	return (
		<div className='w-full px-4 pb-8'>
			{/* <SizeMe noPlaceholder refreshRate={30000}>
				{({ size }) => ( */}
			<ReactGridLayout
				/* className='w-full' */
				width={1368}
				cols={12}
				rowHeight={152}
				margin={[16, 16]}
				/* measureBeforeMount={false} */
				compactType={null}
				//preventCollision={false}
				preventCollision
				//layouts={{ lg: layout }}
				draggableHandle='.widgwt-draggable-handle'
				//isBounded
				onLayoutChange={handleLayoutChange}
			>
				{widgets.map(({ widget, layout }: DashboardWidgetEntity) => {
					const { kind, id } = widget
					const key = `${kind}_${id}`
					return (
						<div key={key} data-grid={{ ...layout, i: key }}>
							<ErrorBoundary fallback={<WidgetError />}>
								<LazyLoad
									scrollContainer='#dashboard-scrollable-area'
									className='flex h-full flex-col'
									placeholder={
										<div className='flex size-full animate-pulse flex-col rounded-lg bg-accent-4' />
									}
								>
									<WidgetContainer
										widgetItem={widget}
										commonPeriods={periods!}
										commonFilters={filters!}
									/>
								</LazyLoad>
							</ErrorBoundary>
						</div>
					)
				})}
			</ReactGridLayout>
			{/* )}
			</SizeMe> */}
		</div>
	)
}
const WidgetError = () => (
	<div className='flex h-full flex-col items-center justify-center rounded-lg border border-dashed border-danger-50 text-center text-danger'>
		Something went wrong
	</div>
)
