import clsx from 'clsx'
//import { DateFormat, formatInTimeZone } from '@/tools/dates'
import { intlFormat } from 'date-fns'
import React, { forwardRef } from 'react'

import CalendarIcon from '@cmpkit/icon/lib/glyph/calendar'

type DateRangeButtonProps = {
	startDate?: Date | string
	endDate?: Date | string
	onClick?: () => void
	className?: string
	isBlue?: boolean
}
/* const formatDate = (dateString?: string | Date) =>
	formatInTimeZone(
		dateString ? new Date(dateString) : new Date(),
		DateFormat.default
	) */
const DateRangeButton = forwardRef(
	(
		{
			startDate,
			endDate,
			onClick = () => {},
			className,
			isBlue,
		}: DateRangeButtonProps,
		ref: React.Ref<HTMLDivElement>
	) => {
		return (
			<div
				ref={ref}
				onClick={onClick}
				className={clsx(
					'cmp-input inline-flex w-auto cursor-pointer items-center bg-transparent',
					className,
					isBlue && 'text-brand'
				)}
			>
				<CalendarIcon
					className={clsx(
						'mr-1',
						isBlue ? 'fill-brand' : 'fill-black dark:fill-white'
					)}
				/>
				{`${startDate ? intlFormat(new Date(startDate)) : '__/__/____'} - ${endDate ? intlFormat(new Date(endDate)) : '__/__/____'}`}
			</div>
		)
	}
)
export default DateRangeButton
