import { find, prop } from 'lodash/fp'
import { useMemo } from 'react'

import { Card, CardContent, CardHeader, CardTitle, Spinner } from '@cmpkit/base'

import LabeledValue from '@/components/LabeledValue'
import { StrategiesSettingsModel } from '@/generated'
import intl from '@/locale'
import { getEnumOptions, translateOptions } from '@/tools/json-schema-utils'

import { useOptimizationGroupSchemaQuery } from '../hooks/useOptimizationGroupSchema'

export function StrateryCard({
	data,
	onClick,
}: {
	data?: StrategiesSettingsModel
	onClick: () => void
}) {
	const schemaQuery = useOptimizationGroupSchemaQuery()
	const isLoading = schemaQuery.isLoading
	const { demand_grow, demand_maintain, markdown_logic } = useMemo(() => {
		const growOptions = translateOptions(
			getEnumOptions(
				prop(
					'properties.strategy.properties.demand_strategy.properties.target',
					schemaQuery.data
				)
			)
		)

		const markdownOptions = translateOptions(
			getEnumOptions(
				prop(
					'properties.strategy.properties.markdown_strategy.properties.target',
					schemaQuery.data
				)
			)
		)

		const maintainOptions = translateOptions(
			getEnumOptions(
				prop(
					'properties.strategy.properties.demand_strategy.properties.protect',
					schemaQuery.data
				)
			)
		)

		return {
			demand_grow: find({ value: data?.demand_strategy.target }, growOptions),
			demand_maintain: find(
				{
					value: data?.demand_strategy.protect,
				},
				maintainOptions
			),
			markdown_logic: find(
				{
					value: data?.markdown_strategy.target,
				},
				markdownOptions
			),
		}
	}, [schemaQuery.data, data])
	return (
		<Card
			className='fade-in group/card relative h-full cursor-pointer border hover:border-brand'
			onClick={onClick}
		>
			<CardHeader>
				<CardTitle>
					{intl.get('pricing_strategy').d('Pricing Strategy')}
				</CardTitle>
			</CardHeader>
			<CardContent>
				<LabeledValue label={intl.get('og_metric_to_grow').d('Grow')}>
					{isLoading ? <Spinner /> : <strong>{demand_grow?.label}</strong>}
				</LabeledValue>
				<LabeledValue label={intl.get('og_metric_to_maintain').d('Maintain')}>
					{isLoading ? <Spinner /> : <strong>{demand_maintain?.label}</strong>}
				</LabeledValue>
				<LabeledValue
					label={intl.get('og_metric_markdown').d('Markdown logic')}
				>
					{isLoading ? <Spinner /> : <strong>{markdown_logic?.label}</strong>}
				</LabeledValue>
			</CardContent>
		</Card>
	)
}
