import { format } from 'date-fns'
import { any, prop } from 'lodash/fp'
import { CircleDollarSignIcon } from 'lucide-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'

import {
	Badge,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
	Button,
	Content,
	Header,
	Layout,
	Result,
	Separator,
	TreeNodeType,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import Tooltip from '@cmpkit/tooltip'

import { dialog } from '@/components/dialogs'
import DocumentTitle from '@/components/DocumentTitle'
import { FormProvider } from '@/components/HookForm'
import Loader from '@/components/Loader'
import UsernameById from '@/components/UsernameById'
import { BoundaryWithUser } from '@/components/withErrorBoundary'
import { PromoCampaignModel } from '@/generated'
import intl from '@/locale'
import analytic from '@/services/analytics'
import Sentry from '@/services/sentry'
import { DateFormat } from '@/tools/dates'

import {
	mergeBy,
	useCreatePromoCampaignMutation,
	useDeletePromoCampaignMutation,
	useUpdatePromoCampaignMutation,
} from '../mutations'
import PromoCampaignFormFields from './PromoCampaignFormFields'
import PromoCampaignProducts from './PromoCampaignProducts'
import PromoCampaignProductsImport from './PromoCampaignProductsImport'

const onError = (error: Error) => {
	Sentry.captureException(error)
	toast.error(error?.message ?? intl.get('fatal_error_title'), {
		id: 'promo',
	})
}
const FormDataSchema = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	description: Yup.string().required('Description is required'),
	date: Yup.object()
		.shape({
			start: Yup.string(),
			end: Yup.string(),
		})
		.test('dates', 'Dates are required', (value) =>
			Boolean(value?.start && value?.end)
		)
		.required('Date is required'),
	pos_ids: Yup.array().required('Sales unit is required'),
	is_active: Yup.boolean(),
})
type FormData = Yup.InferType<typeof FormDataSchema>

function getFormData(promoCampaign?: PromoCampaignModel) {
	return {
		name: promoCampaign?.name ?? '',
		description: promoCampaign?.description ?? '',
		date: {
			start: promoCampaign?.date_start,
			end: promoCampaign?.date_end,
		},
		pos_ids: promoCampaign?.pos_ids ?? [],
		is_active: promoCampaign?.is_active ?? false,
	}
}
export default function PromoCampaignForm({
	promoCampaign,
	options,
	isNew,
}: {
	promoCampaign?: PromoCampaignModel
	options: Record<string, TreeNodeType[]>
	isNew?: boolean
}) {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [isImportShowing, setIsImportShowing] = useState(false)
	const createPromoCampaign = useCreatePromoCampaignMutation({
		onError,
		onSuccess: (campaign) => {
			queryClient.setQueryData(['promo-campaigns'], mergeBy(campaign))
			analytic.logEvent('promo: Create')
			toast.success(intl.get('promo_successfully_created'), {
				id: 'promo',
			})
			navigate(`../${campaign.id}`)
		},
	})
	const updatePromoCampaign = useUpdatePromoCampaignMutation({
		onError,
		onSuccess: (result, payload) => {
			queryClient.setQueryData(
				['promo-campaigns'],
				(old?: PromoCampaignModel[]) =>
					old?.map((campaign) =>
						payload.promoCampaignId === campaign.id ? result : campaign
					)
			)
			analytic.logEvent('promo: Update')
			toast.success(intl.get('promo_successfully_updated'), {
				id: 'promo',
			})
		},
	})
	const deletePromoCampaign = useDeletePromoCampaignMutation({
		onError,
		onSuccess: (result, id) => {
			queryClient.setQueryData(
				['promo-campaigns'],
				(old?: PromoCampaignModel[]) =>
					old?.filter((campaign) => campaign.id !== id)
			)
			toast.success(intl.get('success_deleted'), {
				id: 'promo',
			})
			navigate('..')
		},
	})
	const isLoading = any(prop('isLoading'), [
		deletePromoCampaign,
		createPromoCampaign,
		updatePromoCampaign,
	])
	const methods = useForm<FormData>({
		resolver: yupResolver(FormDataSchema),
		defaultValues: getFormData(promoCampaign),
	})

	const onSubmit = (formData: FormData) => {
		const body = {
			name: formData.name,
			pos_ids: formData.pos_ids,
			description: formData.description,
			date_start: format(new Date(formData.date.start!), DateFormat.system),
			date_end: format(new Date(formData.date.end!), DateFormat.system),
			is_active: Boolean(formData.is_active),
		}
		isNew
			? createPromoCampaign.mutate(body, {
					onSuccess: (promoCampaign) =>
						methods.reset(getFormData(promoCampaign)),
				})
			: updatePromoCampaign.mutate(
					{
						promoCampaignId: promoCampaign!.id!,
						data: body,
					},
					{
						onSuccess: (promoCampaign) =>
							methods.reset(getFormData(promoCampaign)),
					}
				)
	}
	const handleSave = methods.handleSubmit(onSubmit)
	const handleDelete = () =>
		dialog
			.confirmDelete({
				title: intl.get('promo_confirm_delete_title'),
				text: intl.get('promo_confirm_delete_text'),
			})
			.then((answer: boolean) => {
				if (answer) {
					analytic.logEvent('promo: Delete')
					toast.loading(intl.get('deleting'), {
						id: 'promo',
					})
					deletePromoCampaign.mutate(promoCampaign!.id)
				}
			})

	const handleFileUploaded = () => {
		toast.success(intl.get('promo_successfully_uploaded_file'), {
			id: 'promo',
		})
		setIsImportShowing(false)
	}
	return (
		<DocumentTitle title={intl.get('promo_title_page')}>
			<Layout>
				<Header className='h-11 items-center px-4 py-2'>
					<CircleDollarSignIcon className='size-4' />
					<Separator orientation='vertical' className='mx-2 h-5' />
					<Breadcrumb className='text-xs'>
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbLink asChild>
									<Link
										to={'/p/promo'}
										data-test-id='breadcrumb-link'
										className='max-w-32 truncate'
									>
										{intl.get('menu.promo')}
									</Link>
								</BreadcrumbLink>
							</BreadcrumbItem>
							<BreadcrumbSeparator />
							<BreadcrumbItem>
								<BreadcrumbPage>{promoCampaign?.name ?? 'New'}</BreadcrumbPage>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
					<div className='ml-auto flex items-center gap-2'>
						{!isNew && (
							<Tooltip content={intl.get('general_delete')}>
								<Button
									key='delete'
									icon
									onClick={handleDelete}
									iconBefore={<Trash2Icon />}
								/>
							</Tooltip>
						)}
						{methods.formState.isDirty && !isNew && (
							<Tooltip content={intl.get('app.revert_changes')}>
								<Button
									onClick={() => methods.reset()}
									iconBefore={<UndoIcon />}
								/>
							</Tooltip>
						)}

						<Button
							variant='primary-warning'
							onClick={handleSave}
							disabled={!methods.formState.isDirty}
							iconBefore={<CheckIcon />}
						>
							{isNew
								? intl.get('general_save_and_create')
								: intl.get('general_save')}
						</Button>
					</div>
				</Header>

				<Layout className='relative h-full overflow-hidden border-t'>
					<Content id='promo-content' className='overflow-hidden'>
						<Blanket
							className='bg-backdrop blanket-abs blanket-products'
							isTinted={isLoading}
						>
							<Loader size={30} />
						</Blanket>
						<div className='rounded-0 flex size-full flex-col overflow-hidden'>
							<FormProvider
								methods={methods}
								onSubmit={methods.handleSubmit(onSubmit)}
								className='h-full'
							>
								<div className='bg-default-background flex size-full flex-col items-start overflow-hidden'>
									<div className='flex size-full w-full shrink-0 grow basis-0 flex-wrap items-start'>
										<div className='flex h-full w-80 flex-none flex-col items-start justify-between self-stretch border-r border-solid bg-accent-2'>
											<div className='flex size-full flex-col gap-px overflow-y-auto border-b border-solid'>
												<Header className='h-10 items-center justify-between px-4 py-1'>
													<h3 className='text-sm font-bold'>
														General settings
													</h3>
													{!!promoCampaign && (
														<section>
															<div className='mt-1 capitalize'>
																<Badge>{promoCampaign?.source}</Badge>
															</div>
														</section>
													)}
												</Header>
												<div className='border-b px-4 py-2'>
													<PromoCampaignFormFields options={options} />
												</div>
												<div className='mt-auto flex flex-col gap-3 border-b px-4 py-2'>
													{promoCampaign?.user_created && (
														<section>
															<span className='text-xs font-medium text-muted'>
																{intl.get('general_created')}:
															</span>
															<p className='text-xs font-medium'>
																by{' '}
																<UsernameById
																	userId={promoCampaign.user_created}
																/>
																,{' '}
																{promoCampaign.created_on &&
																	format(
																		new Date(promoCampaign.created_on),
																		DateFormat.system
																	)}
															</p>
														</section>
													)}
													{promoCampaign?.user_modified && (
														<section>
															<span className='text-xs font-medium text-muted'>
																{intl.get('general_last_updated')}:
															</span>
															<p className='text-xs font-medium'>
																by{' '}
																<UsernameById
																	userId={promoCampaign.user_modified}
																/>
																,{' '}
																{promoCampaign.modified_on &&
																	format(
																		new Date(promoCampaign.modified_on),
																		DateFormat.system
																	)}
															</p>
														</section>
													)}
												</div>
											</div>
										</div>
										<div className='relative flex size-full shrink-0 grow basis-0 flex-col gap-2 self-stretch overflow-hidden'>
											{!isNew ? (
												<BoundaryWithUser config={{ showDialog: true }}>
													<Header className='h-10 items-center justify-between border-b px-4 py-1'>
														<h3 className='text-sm font-bold'>Products</h3>
														{isImportShowing ? (
															<Button
																size='small'
																onClick={() => setIsImportShowing(false)}
																iconBefore={<CrossIcon />}
															/>
														) : (
															<Button
																size='small'
																onClick={() => setIsImportShowing(true)}
															>
																{intl.get('app.import_from_file')}
															</Button>
														)}
													</Header>
													{isImportShowing ? (
														<PromoCampaignProductsImport
															id={promoCampaign!.id!}
															onDone={handleFileUploaded}
														/>
													) : (
														<PromoCampaignProducts campaign={promoCampaign!} />
													)}
												</BoundaryWithUser>
											) : (
												<Result
													className='flex h-full flex-col items-center justify-center'
													icon={<InfoIcon height={50} width={50} />}
													title='You must create a campaign first.'
													subtitle={'Save it and then you can add products.'}
												/>
											)}
										</div>
									</div>
								</div>
							</FormProvider>
						</div>
					</Content>
				</Layout>
			</Layout>
		</DocumentTitle>
	)
}
