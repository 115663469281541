import { Column, Row } from '@tanstack/react-table'

import { Viewport, ViewportDimmensions } from './types'
import { getColumnBoundaries, getRowsVisibleBoundary } from './utils'

export const getViewport = <T>(
	state: ViewportDimmensions & {
		skipItems: [number, number]
	},
	getters: {
		bodyRows: Row<T>[]
		columns: Column<T>[]
		getRowSize: (row?: Row<T>) => number
		getColSize:
			| ((column: Column<T>) => number)
			| ((column?: Column<T>) => undefined)
	}
): Viewport => {
	const { top, left, width, height, skipItems } = state
	const { bodyRows, columns, getRowSize, getColSize } = getters

	// Temporary hardcoded values
	const loadedRowsStart = 0
	const isDataRemote = false
	return {
		rows: getRowsVisibleBoundary<T>(
			bodyRows,
			top,
			height,
			getRowSize,
			skipItems,
			loadedRowsStart,
			isDataRemote
		),
		columns: getColumnBoundaries(columns, left, width, getColSize),
	}
}
