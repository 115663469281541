import { useMemo } from 'react'
import { any, prop } from 'lodash/fp'

import { FilterRuleEntity, Operators } from '@cmpkit/query-builder'

import { useBIAnalyticQuery, useBIRevisionQuery } from '@/modules/bi/queries'

import { useInterpretabilityStatisticQuery } from '@/modules/core/queries'
import {
	getPeriod,
	OptimizationGroupsType,
	PROGRESS_TAB_METRICS,
} from '../components/OptimizationSummarySidebar/helpers'

export const useOptimizationsProgressAnalytics = ({
	filters,
	optimizationGroups,
	enabled = true,
}: {
	filters: FilterRuleEntity[]
	optimizationGroups: OptimizationGroupsType[]
	enabled?: boolean
}) => {
	const optimizationGroupIds = useMemo(
		() => optimizationGroups?.map(({ id }) => id),
		[optimizationGroups]
	)
	const biRevisionDateQuery = useBIRevisionQuery<string>({
		refetchOnMount: 'always',
		select: (data) => data?.['date'] as string,
	})
	const interpretabilityStatisticQuery = useInterpretabilityStatisticQuery(
		{
			optimizations: optimizationGroups.map(({ id, optimization }) => ({
				optimization_id: optimization,
				optimization_group_id: id,
			})),
			filters,
		},
		{
			enabled: !!optimizationGroups && !!enabled,
		}
	)
	const biAnalyticQuery = useBIAnalyticQuery(
		{
			date_aggregation: 'date_key',
			metrics: PROGRESS_TAB_METRICS,
			product_filters: [
				{
					name: 'optimization_group_id',
					operation: Operators.IN,
					value: optimizationGroupIds,
				},
				...filters,
			],
			filters: [],
			...getPeriod(biRevisionDateQuery.data),
		},
		{
			enabled:
				!!biRevisionDateQuery.data && !!optimizationGroupIds && !!enabled,
		}
	)
	const isLoading = any(prop('isLoading'), [
		biRevisionDateQuery,
		biAnalyticQuery,
	])
	const isError = any(prop('isError'), [
		biRevisionDateQuery,
		biAnalyticQuery,
		interpretabilityStatisticQuery,
	])
	return {
		isLoading,
		isError,
		data: {
			biAnalytic: biAnalyticQuery.data,
			revisionDate: biRevisionDateQuery.data,
			interpretabilityStatistic: interpretabilityStatisticQuery.data,
		},
	}
}
