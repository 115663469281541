import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'

import { Button, InlineMessage, Loader } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { FormProvider, RHFTextArea, RHFTextField } from '@/components/HookForm'
import { useOptimizationGroup } from '@/hooks/data'

import intl from '@/locale'
import { useUpdateOptimizationGroupMutation } from '@/modules/core/mutations'
import analytic from '@/services/analytics'

type FormData = {
	name: string
	description?: string
}
const FormDataSchema = Yup.object().shape({
	name: Yup.string().min(2).max(500).required('Name is required'),
	description: Yup.string().max(500),
})
function UpdateGeneralInfoModalContent({
	close,
	optimizationGroupId,
}: {
	optimizationGroupId: string
	close: () => void
}) {
	const queryClient = useQueryClient()

	const optimizationGroup = useOptimizationGroup(optimizationGroupId)
	const updateOptimizationGroupMutation = useUpdateOptimizationGroupMutation(
		optimizationGroupId,
		{
			onSuccess() {
				queryClient.invalidateQueries({
					queryKey: ['optimization-groups'],
				})
				toast.success('Optimization group updated')
				close()
			},
		}
	)
	const onSubmit = (data: FormData) => {
		analytic.logEvent('og settings: description modal: submit form')
		updateOptimizationGroupMutation.mutate(data)
	}
	const methods = useForm<FormData>({
		resolver: yupResolver<FormData>(FormDataSchema),
	})
	useEffect(() => {
		if (optimizationGroup) {
			methods.reset({
				name: optimizationGroup.name,
				description: optimizationGroup.description || '',
			})
		}
	}, [optimizationGroup])

	return (
		<Modal onClose={close} showCloseButton size='mini'>
			<FormProvider onSubmit={methods.handleSubmit(onSubmit)} methods={methods}>
				<ModalHeader>
					<ModalTitle>{intl.get('app.edit_name').d('Edit name')}</ModalTitle>
				</ModalHeader>
				<ModalBody className='relative flex flex-col overflow-hidden'>
					<Blanket
						isTinted={updateOptimizationGroupMutation.isPending}
						className='absolute flex items-center justify-center rounded-lg bg-white/30 backdrop-blur-sm dark:bg-black/30'
					>
						<Loader />
					</Blanket>
					{updateOptimizationGroupMutation.error && (
						<InlineMessage variant='danger'>
							{intl.get('fatal_error_title')}{' '}
						</InlineMessage>
					)}
					<RHFTextField
						className='w-full'
						label={intl.get('general_name')}
						placeholder={intl.get('og.name.placeholder')}
						name='name'
					/>
					<RHFTextArea
						className='w-full'
						rows={7}
						label={intl.get('general_description')}
						placeholder={intl.get('og.description.placeholder')}
						name='description'
					/>
				</ModalBody>
				<ModalFooter className='justify-end space-x-2'>
					<Button
						onClick={close}
						disabled={updateOptimizationGroupMutation.isPending}
					>
						{intl.get('general_cancel')}
					</Button>
					<Button
						variant='primary-brand'
						disabled={updateOptimizationGroupMutation.isPending}
						onClick={methods.handleSubmit(onSubmit)}
					>
						{intl.get('general_save')}
					</Button>
				</ModalFooter>
			</FormProvider>
		</Modal>
	)
}

export default function UpdateGeneralInfoModal({
	isOpen,
	close,
	optimizationGroupId,
}: {
	isOpen: boolean
	close: () => void
	optimizationGroupId: string
}) {
	return (
		<ModalTransition>
			{isOpen && (
				<UpdateGeneralInfoModalContent
					optimizationGroupId={optimizationGroupId}
					close={close}
				/>
			)}
		</ModalTransition>
	)
}
