import clsx from 'clsx'
import React from 'react'

import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'

import { formatHeaderTitile } from './helpers'
import { useTimelineGridContext } from './TimelineGridContext'
import { TimelineRow } from './types'

export default function TimelineGridRightPane<T>({
	components: { RowContent },
}: {
	components: {
		RowContent: <T>({ row }: { row: TimelineRow<T> }) => React.ReactNode
	}
}) {
	const {
		rows,
		viewportHeight: height,
		viewportWidth,
		ranges,
		dimmensions,
		frequency,
		getTodayPosition,
		getContentPosition,
	} = useTimelineGridContext<T>()
	return (
		<div className='timeline-grid-right-pane' style={{ height }}>
			<div className='timeline-grid-overlay'>
				{ranges.map((range, index) => {
					const isPreLastRange = index === ranges.length - 2
					return (
						<div
							className={clsx(isPreLastRange && 'border-dashed')}
							style={{ width: range.daysCount * dimmensions.dayWidth }}
							key={`${range.frequency}_${index}`}
						/>
					)
				})}
			</div>
			<div className='timeline-grid-rows' style={{ height }}>
				<div className='timeline-grid-header'>
					{ranges.map((range, index) => {
						const isLastRange = index === ranges.length - 1
						return (
							<div
								key={`${frequency}_${index}`}
								className={clsx(
									'timeline-grid-header-cell',
									isLastRange && 'timeline-grid-header-cell--last'
								)}
								style={{ width: range.daysCount * dimmensions.dayWidth }}
							>
								{formatHeaderTitile(range)}
								{isLastRange && (
									<Tooltip content={intl.get('app.timaline.last_range')}>
										<InfoIcon className='ml-2' />
									</Tooltip>
								)}
							</div>
						)
					})}
				</div>
				{rows.map((row) => {
					const { width, ...style } = getContentPosition(row)
					return (
						<div className='timeline-grid-row' key={row.id}>
							<div
								style={{
									...style,
									maxWidth: viewportWidth - (style.left + style.right),
								}}
								className={clsx('timeline-grid-row-content', {
									['timeline-grid-row-content--has-start']: row.start,
									['timeline-grid-row-content--has-end']: row.end,
									['timeline-grid-row-content--truncated']:
										!!width && width < 300,
									['timeline-grid-row-content--invisible']:
										!!width && width < 24,
								})}
							>
								<RowContent<T> row={row} />
							</div>
						</div>
					)
				})}
			</div>
			<div style={getTodayPosition()} className='timeline-grid-today' />
		</div>
	)
}
