import { addMonths, max, min } from 'date-fns'
import { filter, map, sumBy } from 'lodash/fp'
import React, { createContext, useContext, useMemo } from 'react'

import { diffInDays, generateTimelineRanges, getDimmensions } from './helpers'
import { Frequency, TimelineRange, TimelineRow } from './types'

export type TimelineGridProviderProps<T> = {
	children: React.ReactNode
	frequency: Frequency
	rows: TimelineRow<T>[]
}
export type TimelineGridContextValue<T> = {
	getTodayPosition(): {
		left: number
	}
	getContentPosition(row: { start: Date | null; end: Date | null }): {
		left: number
		right: number
		width: number | null
	}
	ranges: TimelineRange[]
	frequency: Frequency
	dimmensions: {
		minDate: Date
		maxDate: Date
		dayWidth: number
	}
	rows: TimelineRow<T>[]
	viewportHeight: number
	viewportWidth: number
}

export const TimelineGridContext = createContext<
	TimelineGridContextValue<unknown>
>({
	getTodayPosition: () => ({
		left: 0,
	}),
	getContentPosition: () => ({
		left: 0,
		right: 0,
		width: null,
	}),
	ranges: [],
	frequency: 'week',
	dimmensions: {
		minDate: new Date(),
		maxDate: new Date(),
		dayWidth: 0,
	},
	rows: [],
	viewportHeight: 0,
	viewportWidth: 0,
})

export function useTimelineGridContext<T>() {
	return useContext(TimelineGridContext) as TimelineGridContextValue<T>
}

const ROW_HEIGHT = 80
const HEADER_HEIGHT = 40
export default function TimelineGridProvider<T>({
	children,
	frequency,
	rows,
}: TimelineGridProviderProps<T>) {
	const dimmensions = useMemo(() => {
		const minDate = min([
			...filter(
				Boolean,
				map((row: TimelineRow<T>) => row.start as string | number | Date, rows)
			),
			addMonths(new Date(), -1),
		])
		const maxDate = max([
			...filter(
				Boolean,
				map((row: TimelineRow<T>) => row.start as string | number | Date, rows)
			),
			addMonths(new Date(), 6),
		])
		return getDimmensions({
			minDate,
			maxDate,
			frequency,
		})
	}, [rows, frequency])
	const ranges = generateTimelineRanges({
		minDate: dimmensions.minDate,
		maxDate: dimmensions.maxDate,
		frequency,
	})
	const getContentPosition = ({ start, end }: { start: Date; end: Date }) => {
		const daysStartOffset = diffInDays(start, dimmensions.minDate)
		const daysEndOffset = diffInDays(dimmensions.maxDate, end)
		return {
			left: !start ? 0 : daysStartOffset * dimmensions.dayWidth,
			right: !end ? 0 : daysEndOffset * dimmensions.dayWidth,
			width:
				start && end ? diffInDays(end, start) * dimmensions.dayWidth : null,
		}
	}
	const getTodayPosition = () => ({
		left: diffInDays(new Date(), dimmensions.minDate) * dimmensions.dayWidth,
	})

	const viewportHeight = rows.length * ROW_HEIGHT + HEADER_HEIGHT
	const viewportWidth = sumBy('daysCount', ranges) * dimmensions.dayWidth
	const value = useMemo(
		() => ({
			getTodayPosition,
			getContentPosition,
			ranges,
			dimmensions,
			rows,
			viewportHeight,
			viewportWidth,
			frequency,
		}),
		[dimmensions]
	)
	return (
		<TimelineGridContext.Provider value={value}>
			{children}
		</TimelineGridContext.Provider>
	)
}
