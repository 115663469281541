import { useQuery } from '@tanstack/react-query'

import {
	ProductsQueryRequestModel,
	ProductsQueryResponseModel,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'

export function useProductsListQuery<T = ProductsQueryResponseModel>(
	optimizationGroupId: string,
	params?: ProductsQueryRequestModel,
	options?: UseQueryOptions<ProductsQueryResponseModel, Error, T>
) {
	return useQuery<ProductsQueryResponseModel, Error, T>({
		queryKey: ['og-assortment-list', optimizationGroupId, params],
		queryFn: () => {
			return client.products.listProducts(params || {})
		},
		...(options || {}),
	})
}
