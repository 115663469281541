import React, { ChangeEvent, useState } from 'react'

import { Checkbox, ListGroup, ListGroupItem } from '@cmpkit/base'

import SearchInput from '@/components/SearchInput'
import intl from '@/locale'

type DataBlob = { [id: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any
interface DataChoiceProps {
	data: string[]
	disabled?: string[]
	selected: string[]
	onSelectionChange(values: string[]): void
	blob: DataBlob
}
export default function DataChoice({
	data,
	selected,
	onSelectionChange,
	blob,
	disabled = [],
	...props
}: DataChoiceProps) {
	const [searchText, setSearchText] = useState<string>('')

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value)
	}
	const visibleList = searchData(data, blob, searchText)
	const isAllSelected =
		visibleList.length > 0 && visibleList.length === selected.length
	const handleSelectAll = () =>
		onSelectionChange(isAllSelected ? [] : visibleList)
	const handleCheck = (id: string) =>
		onSelectionChange(toggleItem(selected, id))
	const renderItem = (id: string) => (
		<ListGroupItem
			key={id}
			disabled={disabled.includes(id)}
			className='bg-transparent py-1 pl-2.5'
		>
			<label className='flex items-center space-x-2'>
				<Checkbox
					name={id}
					checked={selected.includes(id)}
					onChange={() => handleCheck(id)}
				/>
				<span className='select-none font-medium'>
					{blob[id]?.name || 'None'}
				</span>
			</label>
		</ListGroupItem>
	)

	return (
		<div {...props}>
			<div className='flex'>
				<SearchInput
					className='mb-2.5'
					value={searchText}
					onChange={handleInputChange}
					placeholder={intl.get('general_search')}
				/>
			</div>
			<div className='mb-2.5 flex items-center justify-between'>
				<label className='flex items-center space-x-2'>
					<Checkbox checked={isAllSelected} onChange={handleSelectAll} />
					<span className='select-none font-medium'>
						{intl.get('select_all_ogs')}
						{` (${visibleList.length})`}
					</span>
				</label>
			</div>
			<div className='cmp-border h-64 overflow-y-scroll rounded-lg bg-accent-2'>
				{visibleList.length > 0 ? (
					<ListGroup className='py-2'>{visibleList.map(renderItem)}</ListGroup>
				) : (
					<div className='pt-2.50 flex items-center justify-center p-5 text-center text-xs text-muted'>
						<p>{intl.get('cp_og_settings_targets_hint')}</p>
					</div>
				)}
			</div>
		</div>
	)
}

const searchData = (
	list: string[],
	blob: DataBlob,
	searchText: string
): string[] =>
	list.filter((id) =>
		searchText
			? blob[id]?.name &&
				blob[id]?.name.toLowerCase().includes(searchText.toLowerCase())
			: true
	)

const toggleItem = (list: string[], id: string) =>
	list.includes(id) ? list.filter((i) => i != id) : [...list, id]
