import { JSONSchemaType } from 'ajv'
import { map } from 'lodash/fp'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { ajvResolver } from '@hookform/resolvers/ajv'

import { Button, InlineMessage } from '@cmpkit/base'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import PcGlobalIcon from '@cmpkit/icon/lib/glyph/pc-global'
import {
	Modal,
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'
import { components } from '@cmpkit/select'

import { DataOption } from '@/common.types'
import {
	FormProvider,
	RHFRadioGroup,
	RHFSelect,
	RHFTextField,
} from '@/components/HookForm'
import { FilterRuleModel } from '@/generated'
import intl from '@/locale'
import { useOptimizationGroupsQuery } from '@/modules/core/queries'
import { useModalStore } from '@/modules/modals/store'
import { getEnumOptions } from '@/tools/json-schema-utils'

import { PricingEngineType } from '../types'

type PricingCampaignFormData = {
	name: string
	optimization_group_id: string | null
	engine: PricingEngineType
}
const getFormSchema = () =>
	({
		type: 'object',
		required: ['name'],
		properties: {
			optimization_group_id: {
				oneOf: [
					{
						type: 'string',
						minLength: 1,
					},
					{
						type: 'null',
					},
				],
			},
			name: {
				type: 'string',
				title: intl.get('pricing_campaign_name'),
				minLength: 1,
			},
			engine: {
				type: 'string',
				default: PricingEngineType.RB,
				title: intl.get('pricing_engine'),
				enum: [
					PricingEngineType.RB,
					PricingEngineType.ML,
					PricingEngineType.MD,
				],
				enumNames: [
					intl.get('pricing_engine_rule_based'),
					intl.get('pricing_engine_ml_based'),
					intl.get('pricing_engine_md_based'),
				],
			},
		},
	}) as JSONSchemaType<PricingCampaignFormData>

function CreatePricingCampaignModalContent({
	close,
	optimizationGroupId,
	scenarioId,
	draft,
	onCreateCampaign,
	disableOptimizationGroups,
}: Omit<CreatePricingCampaignModalProps, 'isOpen'>) {
	const { showModal } = useModalStore()
	const schema = getFormSchema()
	const optimizationGroupsQuery = useOptimizationGroupsQuery({
		select: map((og) => ({
			value: og.id,
			label: og.name,
		})),
	})
	const optimizationGroupsOptions = useMemo(
		() =>
			optimizationGroupsQuery.data
				? [
						{
							value: null,
							label: intl.get('pc.create.global').d('Global'),
						},
						...optimizationGroupsQuery.data,
					]
				: [],
		[optimizationGroupsQuery.data]
	)
	const methods = useForm<PricingCampaignFormData>({
		defaultValues: {
			optimization_group_id: optimizationGroupId || null,
			name: '',
			engine: PricingEngineType.RB,
		},
		resolver: ajvResolver(schema, { strict: false }),
	})
	const _handleSubmit = ({
		name,
		engine,
		optimization_group_id,
	}: PricingCampaignFormData) => {
		const data = {
			optimizationGroupId: optimization_group_id,
			scenarioId,
			draft: { ...(draft || {}), name, engine },
		}
		if (onCreateCampaign) {
			onCreateCampaign?.(data)
		} else {
			showModal('PRICING_CAMPAIGN_MODAL', data)
		}
		close()
	}
	const handleSubmit = methods.handleSubmit(_handleSubmit)
	return (
		<Modal
			onClose={close}
			showCloseButton
			size='mini'
			zIndex={1850}
			testId='create-pc-modal'
		>
			<ModalHeader>
				<ModalTitle>
					{intl.get('pc.create.title').d('Create a pricing campaign')}
				</ModalTitle>
				<ModalDescription>
					{intl
						.get('pc.create.subtitle')
						.d('Set the name and type of your new campaign.')}
				</ModalDescription>
			</ModalHeader>
			<ModalBody className='flex flex-col'>
				<FormProvider methods={methods} onSubmit={handleSubmit}>
					<RHFSelect
						label='Optmization group'
						className='w-full'
						isDisabled={disableOptimizationGroups}
						isLoading={optimizationGroupsQuery.isFetching}
						components={{
							Option: ({ children, ...props }) => (
								<components.Option {...props}>
									<span className='flex items-center gap-1'>
										{props.data.value === null && (
											<PcGlobalIcon className='shrink-0' />
										)}
										{children}
									</span>
								</components.Option>
							),
							SingleValue: ({ children, ...props }) => (
								<components.SingleValue {...props}>
									<span className='flex items-center gap-1'>
										{props.data.value === null && (
											<PcGlobalIcon className='shrink-0' />
										)}
										{children}
									</span>
								</components.SingleValue>
							),
						}}
						name='optimization_group_id'
						options={optimizationGroupsOptions as DataOption[]}
					/>
					<RHFTextField
						className='w-full'
						autoComplete='off'
						autoFocus
						label={schema.properties.name.title}
						name='name'
						placeholder={intl.get('pricing_campaign_name_placeholder')}
					/>

					<RHFRadioGroup
						label={schema.properties.engine.title}
						name='engine'
						options={getEnumOptions(schema.properties.engine)}
					/>

					<InlineMessage
						icon={<InfoIcon />}
						variant='brand'
						className='text-xs font-medium'
					>
						{intl
							.get('pc.create.info')
							.d(
								'Note, that products gonna be assigned to the new pricing campaign only after the optimization group is updated'
							)}
					</InlineMessage>
				</FormProvider>
			</ModalBody>
			<ModalFooter className='justify-between'>
				<Button onClick={close} data-testid='create-pc-modal-cancel'>
					{intl.get('general_cancel')}
				</Button>
				<Button
					variant='primary-brand'
					onClick={handleSubmit}
					data-testid='create-pc-modal-submit'
				>
					{intl.get('general_create')}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
interface CreatePricingCampaignModalProps {
	isOpen: boolean
	close(): void
	optimizationGroupId?: string | null
	disableOptimizationGroups?: boolean
	scenarioId?: string
	draft?: {
		name?: string
		engine?: string
		assignment_filters?: FilterRuleModel[]
	}
	onCreateCampaign?(temp: {
		optimizationGroupId?: string | null
		scenarioId?: string
		draft?: {
			name?: string
			engine?: string
			assignment_filters?: FilterRuleModel[]
		}
	}): void
}
export default function CreatePricingCampaignModal(
	props: CreatePricingCampaignModalProps
) {
	return (
		<ModalTransition>
			{props.isOpen && <CreatePricingCampaignModalContent {...props} />}
		</ModalTransition>
	)
}
