import clsx from 'clsx'
import { format, intlFormat } from 'date-fns'
import { YAxisOptions } from 'highcharts'
import { any, filter, isEqual, map, pipe, prop, sortedUniqBy } from 'lodash/fp'
import React, { useEffect, useMemo, useState } from 'react'

import { Button, Card, Dot, Result } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import Popover from '@cmpkit/popover'
import { FilterRuleEntity } from '@cmpkit/query-builder'
import { CheckboxSelect, MultiValue } from '@cmpkit/select'

import { DataOption } from '@/common.types'
import Chart from '@/components/Chart'
import CollapsibleSection from '@/components/CollapsibleSection'
import {
	BIResponse,
	MetricModel,
	StatisticsInterpretabilityResponse,
} from '@/generated'
import intl from '@/locale'
import { useMetricsQuery } from '@/modules/bi/queries'
import { DateFormat } from '@/tools/dates'
import { formatNumber } from '@/tools/locale'

import { METRIC_PREFIX, MetricDataType, METRICS_PREFIX } from '../../constants'
import { useOptimizationsProgressAnalytics } from '../../hooks/useOptimizationsProgressAnalytics'
import {
	getForecastPeriod,
	getMetricForecast,
	getMetricsAnalyticData,
	getPrefixedMetric,
	getTypesByMetricSchema,
	OptimizationGroupsType,
	PROGRESS_TAB_METRICS,
	roundIfFloat,
	SelectedOptimizationGroupType,
} from './helpers'

export const ProgressTab = ({
	productFilters,
	optimizationGroups,
	isLoading: isDataLoading,
}: {
	productFilters: FilterRuleEntity[]
	optimizationGroups: OptimizationGroupsType[]
	isLoading: boolean
}) => {
	const [optimizationGroupsToCompare, setOptimizationGroupsToCompare] =
		useState<OptimizationGroupsType[]>([])
	useEffect(() => setOptimizationGroupsToCompare([]), [optimizationGroups])
	const showAdditionalMetrics = optimizationGroupsToCompare.length > 0
	const filters = useMemo(
		() => productFilters.filter(({ name }) => name !== 'optimization_group_id'),
		[productFilters]
	)
	/** Queries */
	const metricQuery = useMetricsQuery<MetricModel[]>({
		select: pipe(filter(({ type }) => type === METRICS_PREFIX)),
	})
	const mainAnalytics = useOptimizationsProgressAnalytics({
		optimizationGroups: optimizationGroups,
		filters,
	})
	const additionalAnalytics = useOptimizationsProgressAnalytics({
		optimizationGroups: optimizationGroupsToCompare,
		filters,
		enabled: showAdditionalMetrics,
	})
	/** Calculations */
	const mainMetrics = useMemo(() => {
		return (
			!!mainAnalytics.data &&
			enrichMetrics({
				data: mainAnalytics.data as MetricAnalyticDataType,
				metricSchemes: metricQuery.data as MetricModel[],
				chartColors: METRICS_COLORS.main,
			})
		)
	}, [mainAnalytics.data, metricQuery.data])
	const additionalMetrics = useMemo(() => {
		return (
			!!additionalAnalytics.data &&
			enrichMetrics({
				data: additionalAnalytics.data as MetricAnalyticDataType,
				metricSchemes: metricQuery.data as MetricModel[],
				chartColors: METRICS_COLORS.additional,
			})
		)
	}, [additionalAnalytics.data, metricQuery.data])

	const isLoading =
		any(prop('isLoading'), [mainAnalytics, additionalAnalytics, metricQuery]) ||
		isDataLoading

	const isError = any(prop('isError'), [
		mainAnalytics,
		additionalAnalytics,
		metricQuery,
	])

	/**Handlers */
	const handleApply = (selected: SelectedOptimizationGroupType[]) => {
		const selectedOptimizationGroups = getOptimizationGroupsToCompare({
			selected,
			optimizationGroups,
		})
		setOptimizationGroupsToCompare(selectedOptimizationGroups)
	}
	if (isError) {
		return (
			<div className='flex h-full items-center justify-center px-5'>
				<Result
					icon={<AlertIcon width={72} height={72} />}
					title={intl.get('fatal_error_title')}
					subtitle={intl.get('fatal_error_desc')}
				/>
			</div>
		)
	}

	return (
		<div className='h-max w-full'>
			<ProgressTabHeader
				optimizationGroups={optimizationGroups}
				comparisonOgs={optimizationGroupsToCompare}
				handleClear={() => setOptimizationGroupsToCompare([])}
				handleApply={handleApply}
			/>
			<div className='px-5 py-4'>
				{isLoading ? (
					<Loader />
				) : (
					PROGRESS_TAB_METRICS.map((key, i) => {
						const mainAnalytic = (mainMetrics as MetricsAnalyticsType)?.[key]
						const metrics = showAdditionalMetrics
							? [
									mainAnalytic,
									(additionalMetrics as MetricsAnalyticsType)?.[key],
								]
							: [mainAnalytic]
						const config = getChartConfig(metrics)
						return (
							<div key={`${key}_${i}`}>
								<CollapsibleSection
									className={clsx(
										'cmp-border-bottom pb-4 pt-3',
										i === 0 && 'cmp-border-top'
									)}
									isOpen
									header={<h4>{intl.get(key).d(key)}</h4>}
								>
									<Chart config={config} />
								</CollapsibleSection>
							</div>
						)
					})
				)}
			</div>
		</div>
	)
}
type MetricType = {
	analyticKey: string
	data: [number, number][]
	name: string
	metricValueTypes: { dataType: string }
	color: string
	predictColor: (string | number)[][]
	analyticColor: (string | number)[][]
}
const getChartConfig = (metrics: MetricType[]): Highcharts.Options => {
	const zoneTransitionValue = (metric: MetricType) =>
		metric?.data?.[metric.data.length - 2]
	const yAxis = metrics.map((metric) => ({
		title: {
			text: '',
		},
		labels: {
			style: {
				fontSize: '10px',
			},
		},
		...(metric?.metricValueTypes?.dataType === MetricDataType.Percent && {
			labels: {
				formatter: ({ value }: { value: string }) => `${value}%`,
				style: {
					fontSize: '10px',
				},
			},
		}),
	}))
	return {
		chart: {
			type: 'area',
			height: 238,
		},
		//allowNegativeLog: true,
		tooltip: {
			animation: true,
			outside: true,
			shared: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter,
		},
		legend: {
			enabled: false,
		},
		plotOptions: {
			line: {
				dataLabels: {
					enabled: true,
				},
			},
			series: {
				dashStyle: 'Solid',
				cursor: 'pointer',
			},
		},
		yAxis: yAxis as YAxisOptions[],
		xAxis: {
			type: 'datetime',
			labels: {
				padding: 0,
				formatter: function () {
					return format(new Date(this.value), DateFormat.short)
				},
				style: {
					fontSize: '10px',
				},
			},
			endOnTick: true,
			tickmarkPlacement: 'on',
			startOnTick: false,
			minPadding: 0,
			maxPadding: 0,
			offset: 0,
		},
		series: metrics.map((metric) => ({
			...metric,
			fillOpacity: 1,
			fillColor: {
				linearGradient: [0, 0, 0, 250],
				stops: metric?.analyticColor,
			},
			data: metric?.data?.map((point: [number, number]) => {
				const isSalesItem = metric?.name === intl.get('metric.sales_items')
				return {
					x: point[0],
					y: isSalesItem ? roundIfFloat(point[1]) : point[1],
					marker: {
						lineWidth: 0,
						lineColor: undefined,
						symbol: 'circle',
						dashStyle: 'Solid',
						cursor: 'pointer',
						enabled: point === zoneTransitionValue(metric),
					},
				}
			}),
			dashStyle: 'Solid',
			lineWidth: 2,
			color: metric?.color,
			zoneAxis: 'x',
			zones: [
				{
					value: zoneTransitionValue(metric)?.[0],
				},
				{
					dashStyle: 'dash',
					fillColor: {
						linearGradient: [0, 0, 0, 400],
						stops: metric?.predictColor,
					},
				},
			],
		})) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
	}
}
const getOptimizationGroupsToCompare = ({
	selected,
	optimizationGroups,
}: {
	selected: SelectedOptimizationGroupType[]
	optimizationGroups: OptimizationGroupsType[]
}) =>
	optimizationGroups?.filter(({ id }) =>
		selected.map(({ id }) => id).includes(id)
	)
export function formatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	const head = `<div class="mb-2.5"><small>${intlFormat(new Date(this.x as number))}</small></div>`

	const body = self.points
		?.map((point: Highcharts.Point) => {
			return `<div class="flex justify-between">
				<div class="flex items-center">
					<div class="color-dot rounded-lg mr-4" style="background: ${point.color};"></div>
					<strong class="truncate">${point.series.name}</strong>
				</div>
				<div class="flex items-center">
					<strong class="flex items-center mr-2">${formatNumber(point.y as number)}</strong>
				</div>
			</div>`
		})
		.join('')
	return `<div class="chart-tooltip w-72">
		${head}
		${body}
	</div>`
}

const enrichMetrics = ({
	data,
	metricSchemes,
	chartColors,
}: EnrichMetricsArgsType): MetricsAnalyticsType => {
	/** 7 days period from revision date */
	const forecastPeriod = getForecastPeriod(data.revisionDate)
	/** metrics bi analytic  */
	const analyticsData = getMetricsAnalyticData(data.biAnalytic!)

	return PROGRESS_TAB_METRICS.reduce((acc, key) => {
		const prefixedKey = getPrefixedMetric({
			metric: key,
			prefix: METRIC_PREFIX,
		})
		const metricSchema = metricSchemes?.find(
			({ name }) => name.split('.')[0] === `${prefixedKey}`
		)
		const metricKey = `${prefixedKey}.final`
		/** 7 days prediction data for a metric */
		const forecastData =
			(
				data.interpretabilityStatistic?.metrics as {
					[key: string]: number
				}
			)?.[metricKey] || 0
		/** The 'final' value, returned for the margin metric, represents a raw value and needs to be multiplied by 100 to express it as a percentage */
		const metricValueTypes = getTypesByMetricSchema(metricSchema as MetricModel)
		const isMetricDataTypePercent =
			metricValueTypes.dataType === MetricDataType.Percent
		/** Format metric data as a percentage if its type is 'percent' */
		const formattedForcastData = isMetricDataTypePercent
			? forecastData * 100
			: forecastData

		return {
			...acc,
			[key]: {
				name: intl.get(`metric.${key}`),
				analyticKey: key,
				metricValueTypes,
				data: [
					...(analyticsData?.find((analytic) => analytic.key === key)?.data ||
						[]),
					isMetricDataTypePercent
						? [
								forecastPeriod,
								formattedForcastData,
							] /**  prediction for all forecastPeriod*/
						: getMetricForecast(
								forecastPeriod,
								formattedForcastData
							) /** prediction for one day */,
				] as [number, number][],
				...chartColors,
			},
		}
	}, {})
}
export const ProgressTabHeader = ({
	optimizationGroups,
	handleClear,
	handleApply,
	comparisonOgs,
}: HeaderProps) => {
	const [selected, setSelected] = useState<SelectedOptimizationGroupType[]>([])
	const isDisabled =
		isEqual(
			map(prop('id'), sortedUniqBy(prop('id'), selected)),
			map(prop('id'), sortedUniqBy(prop('id'), comparisonOgs))
		) || selected.length === 0
	useEffect(() => setSelected([]), [optimizationGroups])
	const dropdownOptions = useMemo(
		() =>
			optimizationGroups?.map(({ name, id }) => ({
				value: id,
				label: name,
			})) || [],
		[optimizationGroups]
	)
	const handleChange = (
		optimizationGroups: MultiValue<{ value: string; label: string }>
	) => {
		const selected = optimizationGroups.map(({ value, label }) => ({
			name: label,
			id: value,
		}))
		setSelected(selected)
	}
	return (
		<div className='cmp-border-bottom sticky-top flex justify-between bg-accent-1 px-5 py-2'>
			<div className='flex items-center'>
				<Dot className='mr-2 size-2 bg-brand' />
				<p className='text-xs font-medium'>
					{intl.get('opt.summary.selected_ogs').d('Selected OG(s)')}
				</p>
			</div>
			<ProgressTabHeaderDropdown
				isDisabled={isDisabled}
				values={selected.map(({ id }) => id)}
				handleClear={() => {
					setSelected([])
					handleClear()
				}}
				onApply={() => handleApply(selected)}
				options={dropdownOptions}
				handleChange={handleChange}
				totalOgsToCompare={comparisonOgs.length}
			/>
		</div>
	)
}
const ProgressTabHeaderDropdown = ({
	options,
	onApply,
	handleChange,
	values = [],
	handleClear,
	isDisabled,
	totalOgsToCompare,
}: DropdownProps) => {
	const popover = useDisclosure()
	const content = (
		<Card className='w-80 border text-xs shadow'>
			<CheckboxSelect
				styles={selectStyles}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				menuIsOpen
				components={{
					DropdownIndicator,
					Control,
				}}
				isClearable={false}
				isMulti
				options={options}
				value={options.filter(({ value }: { value: string }) =>
					values?.includes(value)
				)}
				placeholder='Search...'
				tabSelectsValue={false}
				onChange={handleChange}
			/>
			<div className='cmp-border-top flex justify-end space-x-2 bg-accent-2 px-4 py-2'>
				<Button size='small' onClick={popover.close}>
					{intl.get('general_cancel')}
				</Button>
				<Button
					disabled={isDisabled}
					variant={'primary-brand'}
					size='small'
					onClick={() => {
						onApply()
						popover.close()
					}}
				>
					{intl.get('general_apply')}
				</Button>
			</div>
		</Card>
	)
	return (
		<Popover
			onDismiss={popover.close}
			isOpen={popover.isOpen}
			content={content}
			placement='bottom-end'
			className='relative -top-2'
		>
			{totalOgsToCompare ? (
				<div className='cmp-border flex h-5 cursor-pointer items-center rounded-md pl-2'>
					<div
						className='p-r flex items-center'
						typeof='button'
						onClick={popover.toggle}
					>
						<Dot className='mr-2 size-2 bg-purple-75' />
						<p className='text-xs font-normal'>
							{intl.get('opt.summary.og_for_comparison').d('OG for comparison')}{' '}
							({totalOgsToCompare})
						</p>
					</div>
					<Button
						iconAfter={<CrossIcon onClick={handleClear} />}
						size='small'
						variant='tertiary'
					/>
				</div>
			) : (
				<Button
					variant='tertiary'
					onClick={popover.toggle}
					size='small'
					className='text-brand'
					iconBefore={<PlusIcon className='fill-brand' />}
				>
					{intl.get('opt.summary.add_comperison').d('Add Comparison')}
				</Button>
			)}
		</Popover>
	)
}
const Control = ({
	children,
	innerProps,
	innerRef,
}: {
	children: React.ReactNode
	innerProps: object
	innerRef: React.Ref<HTMLDivElement>
}) => (
	<div ref={innerRef} className='cmp-border-bottom flex p-1' {...innerProps}>
		{children}
	</div>
)
const DropdownIndicator = () => <SearchIcon className='mr-1' />
const selectStyles = {
	control: (provided: object) => ({ ...provided, margin: 8 }),
	menu: () => ({
		position: 'relative',
		'font-size': '12px',
	}),
} as any //eslint-disable-line
type EnrichMetricsArgsType = {
	data: MetricAnalyticDataType
	metricSchemes: MetricModel[]
	chartColors: {
		color: string
		predictColor: (string | number)[][]
		analyticColor: (string | number)[][]
	}
}
type MetricsAnalyticsType = {
	[key: string]: MetricType
}
type HeaderProps = {
	optimizationGroups: SelectedOptimizationGroupType[]
	handleClear: () => void
	handleApply: (selected: SelectedOptimizationGroupType[]) => void
	comparisonOgs: OptimizationGroupsType[]
}
type DropdownProps = {
	options: DataOption[]
	onApply: () => void
	handleChange: (value: MultiValue<{ value: string; label: string }>) => void
	values?: string[]
	handleClear: () => void
	isDisabled: boolean
	totalOgsToCompare: number
}
type MetricAnalyticDataType = {
	biAnalytic: BIResponse
	revisionDate: string
	interpretabilityStatistic: StatisticsInterpretabilityResponse
}
const METRICS_COLORS = {
	main: {
		color: '#32B2F3',
		predictColor: [
			[0, 'rgba(50, 178, 243, 0.32)'],
			[1, 'rgba(108, 163, 191, 0)'],
		],
		analyticColor: [
			[0, 'rgba(50, 178, 243, 0.72)'],
			[1, 'rgba(118, 162, 184, 0)'],
		],
	},
	additional: {
		color: '#8F98FF',
		predictColor: [
			[0, 'rgba(143, 152, 255, 0.32)'],
			[1, 'rgba(143, 152, 255, 0)'],
		],
		analyticColor: [
			[0, 'rgba(143, 152, 255, 0.72)'],
			[1, 'rgba(143, 152, 255, 0)'],
		],
	},
}
const Loader = () => (
	<div className='space-y-2'>
		<div className='mb-3 space-y-2'>
			<div className='h-5 w-full animate-pulse rounded bg-accent-4' />
			<div className='h-4 w-full animate-pulse rounded bg-accent-4' />
			<div className='h-96 w-full animate-pulse rounded bg-accent-4' />
		</div>
		<div className='mb-3 space-y-2'>
			<div className='h-5 w-full animate-pulse rounded bg-accent-4' />
			<div className='h-4 w-full animate-pulse rounded bg-accent-4' />
			<div className='h-96 w-full animate-pulse rounded bg-accent-4' />
		</div>
		<div className='mb-3 space-y-2'>
			<div className='h-5 w-full animate-pulse rounded bg-accent-4' />
			<div className='h-4 w-full animate-pulse rounded bg-accent-4' />
			<div className='h-96 w-full animate-pulse rounded bg-accent-4' />
		</div>
		<div className='mb-3 space-y-2'>
			<div className='h-5 w-full animate-pulse rounded bg-accent-4' />
			<div className='h-4 w-full animate-pulse rounded bg-accent-4' />
			<div className='h-96 w-full animate-pulse rounded bg-accent-4' />
		</div>
	</div>
)
