import { map, orderBy, pipe, uniqBy } from 'lodash/fp'

import { DataOption } from '@/common.types'
import { UserModel } from '@/generated'

const userToOption: (user: UserModel) => DataOption = (user) => ({
	value: user.id,
	label: getUsername(user),
})
export const usersToOptions: (users: UserModel[]) => DataOption[] = pipe([
	uniqBy('id'),
	map(userToOption),
	orderBy(['label'], ['asc']),
])
export function getUsername(user?: UserModel) {
	return [user?.first_name, user?.last_name].filter(Boolean).join(' ')
}
