import { all, any, isEmpty, prop } from 'lodash/fp'
import { MouseEvent } from 'react'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import { Button, Card } from '@cmpkit/base'
import SavedFiltersIcon from '@cmpkit/icon/lib/glyph/saved-filters'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import Popover from '@cmpkit/popover'

import { dialog } from '@/components/dialogs'
import { SavedFilterModel } from '@/generated'
import intl from '@/locale'
import analytic from '@/services/analytics'
import { errorMessage } from '@/tools/message'

import {
	useDeleteGlobalFilterMutation,
	useDeletePersonalFilterMutation,
} from '../mutations'
import { useGlobalFiltersQuery, usePersonalFiltersQuery } from '../queries'

function SavedFiltersList({
	onItemClick,
	close,
}: {
	onItemClick(item: SavedFilterModel): void
	close(): void
}) {
	const queryClient = useQueryClient()
	const globalFilters = useGlobalFiltersQuery()
	const personalFilters = usePersonalFiltersQuery()
	const deleteGlobal = useDeleteGlobalFilterMutation({
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['global-filters'],
			}),
	})
	const deletePersonal = useDeletePersonalFilterMutation({
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['personal-filters'],
			}),
	})
	const isLoading = any(prop('isLoading'), [globalFilters, personalFilters])
	const isEmptyData = all(isEmpty, [globalFilters?.data, personalFilters?.data])
	const handleDeletePersonal =
		(id: string) => async (e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation()
			e.preventDefault()
			analytic.logEvent('saved-filters: delete local filter')
			const answer = await dialog.confirmDelete({
				title: intl.get('general_detete_confirm_title'),
				text: intl.get('general_detete_confirm_subtitle'),
				okText: intl.get('general_delete'),
			})
			if (!answer) return
			try {
				await deletePersonal.mutateAsync(id)
			} catch (error) {
				toast.error(errorMessage())
			}
		}
	const handleDeleteGlobal =
		(id: string) => async (e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation()
			e.preventDefault()
			analytic.logEvent('saved-filters: delete global filter')
			const answer = await dialog.confirmDelete({
				title: intl.get('general_detete_confirm_title'),
				text: intl.get('general_detete_confirm_subtitle'),
				okText: intl.get('general_delete'),
			})
			if (!answer) return
			try {
				await deleteGlobal.mutateAsync(id)
			} catch (error) {
				toast.error(errorMessage())
			}
		}
	return (
		<Card
			className='overflow-hidden border shadow'
			data-testid='saved-filters-list-container'
		>
			<div className='p-2' style={{ minWidth: 300 }}>
				{isEmptyData && !isLoading && (
					<div className='flex items-center justify-center p-5 text-muted'>
						{intl.get('no_data')}
					</div>
				)}
				{isLoading ? (
					<div className='flex w-full flex-col justify-center'>
						<div className='space-y-1 p-2'>
							<div className='h-5 w-32 animate-pulse rounded-sm bg-accent-4' />
							<div className='h-4 w-full animate-pulse rounded-sm bg-accent-4' />
						</div>
						<div className='space-y-1 p-2'>
							<div className='h-5 w-20 animate-pulse rounded-sm bg-accent-4' />
							<div className='h-4 w-40 animate-pulse rounded-sm bg-accent-4' />
						</div>
						<div className='space-y-1 p-2'>
							<div className='h-5 w-16 animate-pulse rounded-sm bg-accent-4' />
							<div className='h-4 w-full animate-pulse rounded-sm bg-accent-4' />
						</div>
					</div>
				) : (
					!isEmptyData && (
						<div data-testid='saved-filters-list '>
							{globalFilters?.data?.map((filterItem) => {
								return (
									<ListItem
										onItemClick={() => {
											onItemClick(filterItem)
											close()
										}}
										key={filterItem.id}
										filterItem={filterItem}
										onDelete={handleDeleteGlobal(filterItem.id!)}
									/>
								)
							})}
							{personalFilters?.data?.map((filterItem) => {
								return (
									<ListItem
										onItemClick={() => {
											onItemClick(filterItem)
											close()
										}}
										key={filterItem.id}
										filterItem={filterItem}
										onDelete={handleDeletePersonal(filterItem.id!)}
									/>
								)
							})}
						</div>
					)
				)}
			</div>
		</Card>
	)
}
function ListItem({
	filterItem,
	onDelete,
	onItemClick,
}: {
	filterItem: SavedFilterModel
	onDelete(e: MouseEvent<HTMLButtonElement>): void
	onItemClick(): void
}) {
	/* const navigate = useNavigate()
	const search = toQueryString(rulesToQueryObject(filterItem.params.query)) */
	return (
		<div
			className='flex justify-between rounded px-3 py-1 hover:bg-accent-2'
			key={filterItem.id}
		>
			<div className='mr-5 flex cursor-pointer flex-col' onClick={onItemClick}>
				<span className='font-semibold'>{filterItem.name}</span>
				<span className='text-xs text-muted'>
					{filterItem.params.description}
				</span>
			</div>
			<Button
				variant='tertiary'
				iconBefore={<Trash2Icon />}
				onClick={onDelete}
				data-testid='saved-filter-delete-btn'
			/>
		</div>
	)
}
type SavedFiltersDrodownProps = {
	onItemClick(item: SavedFilterModel): void
}
export default function SavedFiltersDrodown({
	onItemClick,
}: SavedFiltersDrodownProps) {
	return (
		<Popover
			placement='bottom-start'
			content={({ close }) => (
				<SavedFiltersList close={close} onItemClick={onItemClick} />
			)}
		>
			<Button
				data-testid='saved-filters-btn'
				iconBefore={<SavedFiltersIcon />}
			/>
		</Popover>
	)
}
