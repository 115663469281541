import clsx from 'clsx'
import React from 'react'

import intl from '@/locale'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { PriceManagerController } from '../../useProductPriceManeger'

export default function PriceManager({
	initPrice,
	finalPrice,
	newMargin,
}: PriceManagerController) {
	return (
		<div className='flex items-center space-x-14'>
			<dl className='mb-0'>
				<dt className='text-xs text-muted'>{intl.get('field_schema_price')}</dt>
				<dd className='text-xs font-semibold'>
					{initPrice.value && !isNaN(initPrice.value)
						? formatNumber(initPrice.value, NumberFormats.Fixed)
						: '-'}
				</dd>
			</dl>
			<dl className='mb-0'>
				<dt className='text-xs text-muted'>
					{intl.get('field_schema_final_price')}
				</dt>
				<dd
					className={clsx('text-xs font-semibold', {
						'text-brand': finalPrice.isChanged,
					})}
				>
					{finalPrice.value && !isNaN(Number(finalPrice.value))
						? formatNumber(Number(finalPrice.value), NumberFormats.Fixed)
						: '-'}
				</dd>
			</dl>
			<dl className='mb-0'>
				<dt className='text-xs text-muted'>{intl.get('general_new_margin')}</dt>
				<dd
					className={clsx('text-xs font-semibold', {
						'text-brand': finalPrice.isChanged,
					})}
				>
					{newMargin && !isNaN(newMargin)
						? `${formatNumber(newMargin)} %`
						: '-'}
				</dd>
			</dl>
		</div>
	)
}
