import { PricingEngineType } from '@/generated'
import intl from '@/locale'

export const getPricingCampaignEngines = () => [
	{
		label: intl.get('pc.tactics.rb').d('Smart rules'),
		value: PricingEngineType.RB,
	},
	{
		label: intl.get('pc.tactics.ml').d('Demand based'),
		value: PricingEngineType.ML,
	},
	{
		label: intl.get('pc.tactics.md').d('Markdown'),
		value: PricingEngineType.MD,
	},
]
