import intl from '@/locale'

export const successMessage = () => intl.get('general_success')
export const errorMessage = () => intl.get('fatal_error_title')
export const processingMessage = () => intl.get('general_processing')

export const actCreated = () => intl.get('created').d('Created')
export const actUpdated = () => intl.get('updated').d('Updated')
export const actSaved = () => intl.get('saved').d('Saved')
export const actDeleted = () => intl.get('deleted').d('Deleted')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const successActionMessage = (value: any, action: string) =>
	intl.get('{value}_successfuly_{action}', { value, action })
