import { addMonths, format } from 'date-fns'
import { any, find, map, prop } from 'lodash/fp'
import React, { useEffect, useState } from 'react'

import overview from '@/assets/img/svg/overview.svg'
import { DynamicAnalyticsData } from '@/components/BI/types'
import Loader from '@/components/Loader'
import EmptyState from '@/components/placeholders/EmptyState'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useBIAnalyticQuery, useMetricsQuery } from '@/modules/bi/queries'
import { DateAggregation, MetricOption } from '@/modules/bi/types'
import { formatMetrics, zipSeries } from '@/modules/bi/utils'
import { PricingLineModel, ProductEntity } from '@/modules/og-products/types'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'

import ChartView from './ChartView'
import Controls from './Controls'

const getSerieData = (
	data: DynamicAnalyticsData[] | undefined,
	prop: keyof DynamicAnalyticsData
) =>
	data?.map((d, i) => ({
		x: i,
		y: d[prop],
	})) || []
type Props = {
	content: ProductEntity | PricingLineModel
}
export default function Content({ content }: Props) {
	const optimizationGroupId = useOptimizationGroupId()
	useEffect(() => {
		analytic.logEvent('table: open SKU panel: Performance')
	}, [])
	const { data: metrics, ...metricsQuery } = useMetricsQuery<MetricOption[]>({
		placeholderData: [],
		select: formatMetrics,
	})
	const { data, ...dataQuery } = useBIAnalyticQuery<DynamicAnalyticsData[]>(
		{
			metrics: [...(metrics || []).map(prop('value'))] as string[],
			dimensions: [],
			date_aggregation: DateAggregation.WeekKey,
			start_date: format(addMonths(new Date(), -1), DateFormat.system),
			end_date: format(new Date(), DateFormat.system),
			filters: !!(content as ProductEntity).sku
				? [
						{
							name: 'optimization_group_id',
							operation: 'is',
							value: optimizationGroupId,
						},
						{
							name: 'line_id',
							operation: 'is',
							value: content.line_id,
						},
						{
							name: 'sku',
							operation: 'is',
							value: (content as ProductEntity).sku,
						},
					]
				: [
						{
							name: 'optimization_group_id',
							operation: 'is',
							value: optimizationGroupId,
						},
						{
							name: 'line_id',
							operation: 'is',
							value: content.line_id,
						},
					],
		},
		{
			enabled: !!content.line_id && !!optimizationGroupId,
			select: (data) => zipSeries<DynamicAnalyticsData>(data?.meta, data?.data),
		}
	)

	const [businessMetric, setBusinessMetric] = useState<string>('revenue')
	const [priceMetric, setPriceMetric] = useState<string>('average_cost')
	if (metricsQuery.isLoading || dataQuery.isLoading) {
		return (
			<div className='relative h-52'>
				<Loader size={30} />
			</div>
		)
	}
	const categories = map(prop('week_key'), data)
	const activeBusinessMetric = find({ value: businessMetric }, metrics)
	const activePriceMetric = find({ value: priceMetric }, metrics)
	const mainSeries = [activeBusinessMetric!, activePriceMetric!]
		.filter(Boolean)
		.map(
			({ label, value, color }, yAxis) =>
				({
					marker: {
						symbol: 'circle',
					},
					name: label,
					color,
					yAxis,
					data: getSerieData(data, value as keyof DynamicAnalyticsData),
				}) as Highcharts.SeriesOptionsType
		)

	return (
		<div className='space-y-2 bg-accent-1 pb-16 pt-5'>
			{any(
				({ data }: { data: Highcharts.PointOptionsObject[] | undefined }) =>
					!!data?.length,
				mainSeries
			) ? (
				<ChartView series={mainSeries} categories={categories} />
			) : (
				<EmptyState
					imageUrl={overview}
					imgClasses='h-52'
					wrapperClasses='m-auto'
					description={intl.get('business_metric_no_data')}
				/>
			)}

			<Controls
				metrics={metrics}
				businessMetric={businessMetric}
				onBusinessMetricChange={setBusinessMetric}
				priceMetric={priceMetric}
				onPriceMetricChange={setPriceMetric}
			/>
		</div>
	)
}
