import { filter, map, matches, pipe, prop, sum } from 'lodash/fp'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { FilterRuleEntity } from '@cmpkit/query-builder'

import CollapsibleSection from '@/components/CollapsibleSection'
import ErrorBoundary from '@/components/ErrorBoundary'
import { OptimizationIDModel } from '@/generated'
import intl from '@/locale'
import { useRepricingStatisticQuery } from '@/modules/core/queries'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'

import {
	Metric,
	MetricGroup,
	MetricGroupValue,
	MetricName,
} from '../../constants'
import AverageValuesWidget from './AverageValuesWidget'
import { FinancialInsightsWidget } from './FinancialinsightsWidget'
import { getQueryParams } from './helpers'
import ItemsChangedGlobalWidget from './ItemsChangedGlobalWidget'
import { PricingAlertsGlobalWidget } from './PricingAlertsGlobalWidget'
import PricingLinesChangeTypeWidget from './PricingLinesChangeTypeWidget'
import PromoWidget from './PromoWidget'

export const StatisticsTab = ({
	queryParams,
	isLoading,
}: {
	isLoading: boolean
	queryParams: {
		filters: FilterRuleEntity[]
		optimizations: OptimizationIDModel[]
	} | null
}) => {
	useEffect(() => {
		analytic.logEvent('opt summary glob: open statistics tab')
	}, [])
	const navigate = useNavigate()
	/**Queries */
	const repricingStatisticQuery = useRepricingStatisticQuery(queryParams!, {
		enabled: !!queryParams && !isLoading,
		refetchOnMount: true,
		staleTime: 0,
		select: pipe(
			prop('metrics'),
			filter(
				matches({
					group_type: MetricGroup.PriceChangeStatus,
					name: MetricName.ProductCount,
				})
			)
		),
	})
	/**Calculated */
	const changed = formatNumber(
		sum(
			map(
				prop('value'),
				filter(
					({ group_value }) =>
						[MetricGroupValue.Increased, MetricGroupValue.Decreased].includes(
							group_value
						),
					repricingStatisticQuery.data as Metric[]
				)
			)
		) || 0
	)
	const total = sum(map(prop('value'), repricingStatisticQuery.data))
	const filters = useMemo(() => {
		return [
			{
				name: 'optimization_group_id',
				operation: 'in',
				value: map(
					prop('optimization_group_id'),
					queryParams?.optimizations || []
				),
			},
		]
	}, [queryParams])
	/**Handlers */
	const handleNavigate = ({
		widgetFilter,
		widget,
	}: {
		widgetFilter: FilterRuleEntity[]
		widget: string
	}) => {
		analytic.logEvent(`opt summary glob: ${widget}: click row`, {
			filters: [...widgetFilter, ...filters],
		})
		navigate(
			`${location.pathname}${getQueryParams([...widgetFilter, ...filters])}`
		)
	}
	return (
		<div className='flex w-full flex-col divide-y divide-solid divide-base bg-accent-1 px-5 pb-8 pt-3'>
			<ErrorBoundary>
				<CollapsibleSection
					className='pb-4'
					isOpen
					header={<h4>{intl.get('alerts')}</h4>}
				>
					<PricingAlertsGlobalWidget />
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-3'
					isOpen
					header={
						<h4>
							{intl
								.get('opt.summary.financial_insights.title')
								.d('Financial insights')}
						</h4>
					}
				>
					<FinancialInsightsWidget
						isLoading={isLoading}
						queryParams={queryParams}
					/>
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-3'
					isOpen
					header={<h4>{intl.get('skus.changed')}</h4>}
					subtitle={intl
						.get('skus.changed.subtitle', {
							changed,
							total,
						})
						.d(`${changed} of ${total} SKUs were optimized`)}
				>
					<ItemsChangedGlobalWidget
						isLoading={isLoading}
						queryParams={queryParams}
					/>
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-3'
					isOpen
					header={<h4>{intl.get('pls.change_types')}</h4>}
				>
					<PricingLinesChangeTypeWidget
						isLoading={isLoading}
						queryParams={queryParams}
					/>
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-3'
					isOpen
					header={<h4>{intl.get('promo')}</h4>}
				>
					<PromoWidget
						isLoading={isLoading}
						queryParams={queryParams}
						handleNavigate={handleNavigate}
					/>
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-3'
					isOpen
					header={<h4>{intl.get('average_values')}</h4>}
				>
					<AverageValuesWidget
						isLoading={isLoading}
						queryParams={queryParams}
					/>
				</CollapsibleSection>
			</ErrorBoundary>
		</div>
	)
}
