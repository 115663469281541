import clsx from 'clsx'
import { find } from 'lodash/fp'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import {
	closestCenter,
	defaultDropAnimationSideEffects,
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	DropAnimation,
	KeyboardSensor,
	PointerSensor,
	UniqueIdentifier,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
	arrayMove,
	defaultAnimateLayoutChanges,
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { Checkbox } from '@cmpkit/base'
import { ColumnGroup } from '@cmpkit/data-table'
import DotsDraggableIcon from '@cmpkit/icon/lib/glyph/dots-draggable'

import SearchInput from '@/components/SearchInput'
import intl from '@/locale'
import analytic from '@/services/analytics'
import { ColumnsConfigController, StoredColumnConfig } from '@/tools/columns'

const dropAnimationConfig: DropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: '0.5',
			},
		},
	}),
}
export function ColumnsList({
	config,
	columnGroups,
	columns,
}: ColumnsManagerProps) {
	const items = config.columnsConfig.map((columnConfig) => ({
		id: columnConfig.key,
		item: columnConfig,
	}))
	const getItemData = (item: StoredColumnConfig) => {
		const title = find({ key: item.key }, columns)?.title || item.key
		const group = find(
			(group) => Boolean(find({ columnKey: item.key }, group.children)),
			columnGroups
		)?.title
		return {
			enabled: !!item.enabled,
			title: title || item.key,
			group,
			name: item.key,
		}
	}
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
	const [searchText, setSearchText] = useState('')
	const timer = useRef<ReturnType<typeof setTimeout> | null>()
	useEffect(() => {
		if (timer.current) {
			clearTimeout(timer.current)
			timer.current = null
		}
		if (searchText) {
			timer.current = setTimeout(() => {
				scrollToNode(searchText)
				timer.current && clearTimeout(timer.current)
				timer.current = null
			}, 500)
		}
	}, [searchText])

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)
	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event
		setActiveId(active.id)
	}
	const ids: UniqueIdentifier[] = items.map(({ id }) => id)
	const getIndex = (id: UniqueIdentifier) => ids.indexOf(id)
	const activeIndex = activeId != null ? getIndex(activeId) : -1
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (active.id !== over?.id && over) {
			const oldIndex = ids.indexOf(active.id)
			const newIndex = ids.indexOf(over.id)
			const reordered = arrayMove(items, oldIndex, newIndex)
			analytic.logEvent('columns: order: changed')
			config.setColumnsConfig(reordered.map(({ item }) => item))
		}
		setActiveId(null)
	}

	const handleChangeCheckbox = (name: string, enabled: boolean) => {
		config.setColumnsConfig(
			config.columnsConfig.map((columnConfig: StoredColumnConfig) =>
				columnConfig.key === name ? { ...columnConfig, enabled } : columnConfig
			)
		)
	}

	return (
		<>
			<div className='mb-3 flex items-center px-5'>
				<SearchInput
					className={'w-full'}
					value={searchText}
					onChange={({ target: { value } }) => setSearchText(value)}
					placeholder={intl.get('table_manager_search')}
				/>
			</div>
			<div
				className='overflow-y-auto overflow-x-hidden px-5 pb-10'
				id='scrolllist'
			>
				<DndContext
					modifiers={[restrictToVerticalAxis]}
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
				>
					<SortableContext items={items} strategy={verticalListSortingStrategy}>
						{items.map(({ id, item }) => {
							const data = getItemData(item)
							const isSearched =
								!!searchText &&
								(data.title || item.key || '')
									.toLowerCase()
									.includes(searchText.toLowerCase())
							return (
								<SortableItem
									key={id}
									id={id}
									item={data}
									data-col-name={data.title || item.key}
									handlers={{
										onChangeCheckbox: handleChangeCheckbox,
									}}
									className={clsx('mb-1 flex items-center justify-between', {
										'rounded-lg bg-orange-10 dark:bg-orange-175': isSearched,
									})}
								/>
							)
						})}
					</SortableContext>
					{createPortal(
						<DragOverlay
							style={{ zIndex: 2000 }}
							dropAnimation={dropAnimationConfig}
						>
							{activeId ? (
								<div className='mb-1 flex cursor-grabbing items-center justify-between'>
									<ColumnItem item={getItemData(items[activeIndex].item)} />
								</div>
							) : null}
						</DragOverlay>,
						document.body
					)}
				</DndContext>
			</div>
			{!config.columnsConfig || config.columnsConfig.length === 0 ? (
				<p className='p-2.5 text-center'>None</p>
			) : null}
		</>
	)
}

const scrollToNode = (searchText: string) => {
	const scrl = document.getElementById('scrolllist')
	const nodes = [...(scrl as any).childNodes] // eslint-disable-line
	const node = nodes.find((item) =>
		item.dataset.colName.toLowerCase().includes(searchText.toLowerCase())
	)
	if (node) {
		scrl?.scrollTo({
			top: node.offsetTop - scrl?.offsetTop,
			left: 0,
			behavior: 'smooth',
		})
	}
}
type ColumnsManagerProps = {
	columns: {
		key: string
		title: string
	}[]
	config: ColumnsConfigController
	columnGroups?: ColumnGroup[]
}
type ColumnConfigItem = {
	enabled: boolean
	title: string
	group?: string
	name: string
}
type ColumnItemProps = {
	item: ColumnConfigItem
	handlers?: {
		onChangeCheckbox(name: string, enabled: boolean): void
	}
}
type SortableItemProps = {
	id: string
	item: ColumnConfigItem
	handlers?: {
		onChangeCheckbox(name: string, enabled: boolean): void
	}
	className?: string
}
function SortableItem({
	id,
	item,
	handlers,
	className,
	...props
}: SortableItemProps) {
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({
		id,
		animateLayoutChanges: defaultAnimateLayoutChanges,
	})
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
	}

	return (
		<div ref={setNodeRef} style={style} {...attributes} {...props}>
			<div
				className={clsx(className, {
					'opacity-0': isDragging,
				})}
			>
				<ColumnItem item={item} handlers={handlers} {...attributes} />
				<DotsDraggableIcon
					{...listeners}
					className='cursor-grab text-disabled'
				/>
			</div>
		</div>
	)
}
const ColumnItem = ({ item, handlers }: ColumnItemProps) => {
	const handleChangeCheckbox = () =>
		handlers?.onChangeCheckbox(item.name, !item.enabled)
	return (
		<div className='flex w-full items-center justify-between p-1'>
			<label className='flex items-center space-x-2'>
				<Checkbox
					name={item.name + ''}
					checked={item.enabled}
					onChange={handleChangeCheckbox}
				/>
				<span className='select-none font-medium'>{item.title}</span>
			</label>
			{item.group ? (
				<small className='ml-auto italic text-muted'>{item.group}</small>
			) : null}
		</div>
	)
}
