import { addWeeks, format } from 'date-fns'
import { filter, map, pipe, prop } from 'lodash/fp'

import intl from '@/locale'
import { MetricOption } from '@/modules/bi/types'
import { DateFormat } from '@/tools/dates'

import { BIAnalyticEntity, BIChartSerie } from './types'

export const createSerie = (
	data: {
		x: number
		y: number
	}[],
	name: string,
	color?: string
): BIChartSerie => ({
	type: 'spline',
	marker: {
		symbol: 'circle',
	},
	visible: true,
	name,
	color: color || '',
	data,
})
export const createSerieData = (
	data: BIAnalyticEntity[],
	metricKey: MetricOption['value']
) =>
	data.map((item, i) => ({
		x: i,
		y: item[metricKey as keyof BIAnalyticEntity],
	}))

export const splits = ['is_active', 'is_available', 'is_retail']
export const getSplitOptions = () =>
	splits.map((item) => ({
		label: intl.get(item),
		value: item,
	}))

export const getDateRangeFromatted = (value: string) =>
	format(new Date(value), DateFormat.short) +
	' - ' +
	format(addWeeks(new Date(value), 1), DateFormat.short)

export const filterPricingAndBusinessMetrics = filter(
	({ type }) => type === 'business' || type === 'pricing'
)
export const selectDefaultMetricsKeys = pipe([
	filterPricingAndBusinessMetrics,
	map(prop('name')),
])
