import { format, intlFormat } from 'date-fns'
import { prop } from 'lodash/fp'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'

import { Badge, linkButtonVariants, Tag } from '@cmpkit/base'
import ArrowRightIcon from '@cmpkit/icon/lib/glyph/arrow-right'

import NumberField from '@/components/data-grid/fields/Number/TableCell'
import { VirtualDataTable } from '@/components/tables'
import UsernameById from '@/components/UsernameById'
import { PromoCampaignModel } from '@/generated'
import intl from '@/locale'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'

export default function PromoCampaignsTable({
	data,
}: {
	data: PromoCampaignModel[]
}) {
	const [sorting, setSorting] = useState<SortingState>([
		{
			id: 'created_on',
			desc: true,
		},
	])

	useEffect(() => {
		analytic.logEvent('settings: promo campaigns: table: sort by', {
			value: sorting.map(prop('id')),
		})
	}, [sorting])

	const columns = useMemo(() => getColumns(), [])
	const table = useReactTable<PromoCampaignModel>({
		data,
		columns,
		state: {
			sorting,
		},
		defaultColumn: {
			minSize: 55,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return <VirtualDataTable table={table} />
}

const columnHelper = createColumnHelper<PromoCampaignModel>()
const getColumns = () => [
	columnHelper.accessor('name', {
		header: () => intl.get('promo_campaign_name'),
		cell: (info) => {
			return (
				<Link
					className={linkButtonVariants({ variant: 'brand' })}
					to={`/p/promo/${info.row.original.id}`}
				>
					{info.getValue()}
				</Link>
			)
		},
	}),
	columnHelper.accessor('is_active', {
		size: 80,
		header: () => intl.get('app.status'),
		cell: (info) => {
			const value = info.getValue()
			if (value) {
				return <Tag variant={'success'}>Live</Tag>
			} else {
				return <Tag variant='danger'>Paused</Tag>
			}
		},
	}),
	columnHelper.accessor((row) => row.pos_ids?.length, {
		size: 150,
		id: 'pos_ids',
		header: () => intl.get('sales_unit'),
		cell: (info) => {
			return <NumberField value={info.getValue()} />
		},
	}),
	columnHelper.accessor((row) => row.date_start + ' - ' + row.date_end, {
		size: 200,
		id: 'period',
		header: () => intl.get('general_period'),
		cell: (info) => {
			const { date_start, date_end } = info.row.original
			return (
				<div className='flex items-center space-x-1 font-normal'>
					{date_start && (
						<span>
							{format(new Date(date_start + 'Z'), DateFormat.default)}
						</span>
					)}
					{(date_start || date_end) && <ArrowRightIcon className='shrink-0' />}
					{date_end && (
						<span>{format(new Date(date_end + 'Z'), DateFormat.default)}</span>
					)}
				</div>
			)
		},
	}),
	columnHelper.accessor('source', {
		size: 100,
		header: () => intl.get('creation_source'),
		cell: (info) => {
			const value = info.getValue()
			return <Badge className='fade-in text-nowrap capitalize'>{value}</Badge>
		},
	}),
	columnHelper.accessor(
		(row) => (row.created_on ? new Date(row.created_on).getTime() : 0),
		{
			id: 'created_on',
			size: 200,
			header: () => intl.get('general_created'),
			cell: (info) => {
				const { user_created, created_on } = info.row.original
				return (
					user_created && (
						<div className='text-xs'>
							<strong>
								by <UsernameById userId={user_created} />
							</strong>{' '}
							<br />
							<span className='text-muted'>
								{created_on ? intlFormat(new Date(created_on)) : ''}
							</span>
						</div>
					)
				)
			},
		}
	),
	columnHelper.accessor(
		(row) => (row.modified_on ? new Date(row.modified_on).getTime() : 0),
		{
			id: 'created_on',
			size: 200,
			header: () => intl.get('general_last_updated'),
			cell: (info) => {
				const { user_modified, modified_on } = info.row.original
				return (
					user_modified && (
						<div className='text-xs'>
							<strong>
								by <UsernameById userId={user_modified} />
							</strong>{' '}
							<br />
							<span className='text-muted'>
								{modified_on ? intlFormat(new Date(modified_on)) : ''}
							</span>
						</div>
					)
				)
			},
		}
	),
]
