import React from 'react'

import { Content, Layout } from '@cmpkit/base'

import DocumentTitle from '@/components/DocumentTitle'

export default function Public({
	children,
	title,
}: {
	children: React.ReactNode
	title?: string
}) {
	return (
		<DocumentTitle title={title}>
			<Layout id='app-container'>
				<Content className='h-full'>{children}</Content>
			</Layout>
		</DocumentTitle>
	)
}
