import clsx from 'clsx'
import React, { ChangeEvent, useState } from 'react'

import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'

import InputAffix from '@/components/InputAffix'

interface CompactSearchProps {
	onChange(e: ChangeEvent<HTMLInputElement>): void
	onClear(): void
	className?: string
	placeholder?: string
	value: string
	count?: number
}
export default function CompactSearch({
	onChange,
	onClear,
	className,
	placeholder,
	value,
	count,
	...props
}: CompactSearchProps) {
	const [focused, setFocused] = useState(false)
	const handleFocus = () => setFocused(true)
	const handleBlur = () => setFocused(false)
	return (
		<InputAffix
			className={clsx('compact-search', className, {
				'w-28': !value && !focused,
			})}
			suffix={[
				<div key='1'> {value && count ? count : null}</div>,
				!value ? (
					<SearchIcon key='2' className='input-suffix' />
				) : (
					<CrossIcon
						key='2'
						onClick={onClear}
						className='input-suffix cursor-pointer'
					/>
				),
			]}
			onFocus={handleFocus}
			onBlur={handleBlur}
			placeholder={placeholder}
			required
			value={value}
			onChange={onChange}
			{...props}
		/>
	)
}
