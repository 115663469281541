import { find } from 'lodash/fp'
import React from 'react'

import { Col, Row } from '@cmpkit/base'

import { OptimizationGroupModel, PricingCampaignModel } from '@/generated'
import intl from '@/locale'
import { useOptimizationGroupsQuery } from '@/modules/core/queries'

import { usePricingCampaignsQuery } from '../../queries'
import { getKeyTitle } from './helpers'

type CheckoutProps = {
	formData: {
		campaigns: string[]
		settings: string[]
	}
}

const selectItemsIn =
	(selcted: string[]) =>
	(data: PricingCampaignModel[]): PricingCampaignModel[] =>
		data.filter(({ id }) => selcted.includes(id))
function Checkout({ formData }: CheckoutProps) {
	const selectedCampaigns = formData.campaigns
	const selectedSettings = formData.settings
	const optimizationGroupsQuery =
		useOptimizationGroupsQuery<OptimizationGroupModel[]>()
	const pricingCampaignsQuery = usePricingCampaignsQuery(
		{},
		{
			select: selectItemsIn(selectedCampaigns),
		}
	)
	const getOptimizationGroupName = (id: string) =>
		find({ id }, optimizationGroupsQuery.data)?.name || '-'
	return (
		<Row>
			<Col sm='6'>
				<h3>{intl.get('pc.copy_settings.want_to_copy_settings')}</h3>
				<ul className='ml-2 h-64 overflow-y-scroll'>
					{selectedSettings?.map((name) => (
						<li key={name}>{getKeyTitle(name) || 'None'}</li>
					))}
				</ul>
			</Col>
			<Col sm='6'>
				<h3>{intl.get('pc.copy_settings.to_following_pcs')}:</h3>
				<ul className='ml-2 h-64 overflow-y-scroll'>
					{pricingCampaignsQuery.data?.map(
						({ name, optimization_group_id }) => (
							<li
								key={name}
							>{`${name || 'None'} (${getOptimizationGroupName(optimization_group_id!)})`}</li>
						)
					)}
				</ul>
			</Col>
		</Row>
	)
}

export default Checkout
