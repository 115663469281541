/* eslint-disable @typescript-eslint/no-explicit-any */
import { TokenStorage } from '@/modules/Auth/token-storage'

import Observer from './observer'
import Socket from './socket'

//import { Message } from './types'
export class Auth extends Observer {
	socket: Socket
	pendingAuth: null | number
	authTimer?: any
	authIntervalMs = 10000
	authenticted = false
	constructor(socket: Socket) {
		super()
		this.socket = socket
		this.pendingAuth = null
	}
	authenticate(cb?: any) {
		this.pendingAuth = this.socket.makeRef()
		this.socket.push({
			topic: 'system',
			event: '_auth',
			payload: {
				token: TokenStorage.getToken(),
			},
			ref: this.pendingAuth,
		})
		this.once('_auth', (/* message: Message */) => {
			this.onAuthenticated(/* message */)
			cb?.()
		})
		this.once('_auth_expired', () => this.authenticate())
		this.once('_auth_jwt_refresh', async () => {
			await TokenStorage.getNewToken()
			this.authenticate()
		})
		this.authTimer = setTimeout(() => this.authTimeout(), this.authIntervalMs)
	}
	authTimeout() {
		this.authenticted = false
		if (this.pendingAuth) {
			this.socket.abnormalClose('auth timeout')
		}
	}
	onAuthenticated(/* message: Message */) {
		this.pendingAuth = null
		clearTimeout(this.authTimer)
		this.authenticted = true
	}
	destroy() {
		clearTimeout(this.authTimer)
		this.offAll()
	}
}
