import { pickAll } from 'lodash/fp'
import React, { useState } from 'react'

import { Header } from '@cmpkit/base'

import { QuickFiltersBar } from '@/modules/preferences'

import {
	selectDashboardFilters,
	selectDashboardFiltersKeys,
	useDashboardStore,
} from '../../store'
import { UseDashboardWidgets } from '../../useDashboardWidgets'
import { useFilterFieldsConfig } from '../MainDashboard/useFilterFiledsConfig'
import { SinglePeriodSelect } from '../Periods'
import { WidgetPeriods } from '../widgets/types'
import WidgetsSection from '../WidgetsSection'

export default function AiDashboardCommonSection({
	dashboard,
	commonPeriods,
}: {
	dashboard: UseDashboardWidgets
	commonPeriods: WidgetPeriods
}) {
	const [state, setState] = useState(commonPeriods.main)
	const { setFilters, filters } = useDashboardStore(
		selectDashboardFilters('ai_common')
	)
	const { storedKeys, setStoredKeys } = useDashboardStore(
		selectDashboardFiltersKeys('ai_common')
	)
	const { fieldsConfig, isLoading } = useFilterFieldsConfig({
		// remove fields that unsuported by BI widgets in this dashboard section
		sanitizeFields: pickAll([
			'optimization_group_id',
			'pos_id',
			'category_id',
			'pricing_line_id',
		]),
	})

	return (
		<div>
			<Header className='sticky top-0 z-10 items-center justify-between bg-white/50 px-4 py-2 backdrop-blur-md dark:bg-[#1e293b]/50'>
				<div>
					{isLoading ? (
						<div className='flex items-center space-x-2'>
							<div className='h-6 w-28 animate-pulse rounded-lg bg-accent-4' />
							<div className='h-6 w-20 animate-pulse rounded-lg bg-accent-4' />
							<div className='h-6 w-24 animate-pulse rounded-lg bg-accent-4' />
						</div>
					) : (
						<QuickFiltersBar
							irremovableKeys={['optimization_group_id', 'pos_id']}
							rules={filters}
							storedKeys={storedKeys}
							onSaveStoredKeys={setStoredKeys}
							onApply={setFilters}
							fieldsConfig={fieldsConfig}
							limit={7}
						/>
					)}
				</div>

				<SinglePeriodSelect
					period={state}
					defaultPeriod={commonPeriods.main || state}
					onChangePeriod={setState}
					maxDate={new Date()}
				/>
			</Header>
			<WidgetsSection
				dashboard={dashboard}
				id='common'
				periods={{ main: state, compared: state }}
				filters={filters}
			/>
		</div>
	)
}
