import { format } from 'date-fns'
import { cloneElement, JSX, useState } from 'react'

import { Card } from '@cmpkit/base'
import { DateRange, RangeShape } from '@cmpkit/calendar'
import { useDisclosure } from '@cmpkit/hooks'
import Popover from '@cmpkit/popover'

import { DateFormat } from '@/tools/dates'

import { localization } from './localization'

type SingleDateRangePickerProps = {
	value: [string, string] | []
	children: JSX.Element
	onApply(value: [string, string] | []): void
	maxDate?: Date
	minDate?: Date
}
export default function SingleDateRangePicker({
	value,
	onApply,
	children,
	maxDate,
	minDate,
}: SingleDateRangePickerProps) {
	const { isOpen, close, toggle } = useDisclosure()
	const startDate = value[0] ? new Date(value[0]) : new Date()
	const endDate = value[1] ? new Date(value[1]) : new Date()
	const [localValue, setLocalValue] = useState<RangeShape[]>([
		{ startDate, endDate, key: 'common' },
	])
	const childrenCloneElement: JSX.Element = cloneElement(children, {
		onClick: toggle,
	})
	return (
		<Popover
			placement='bottom-start'
			isOpen={isOpen}
			content={
				<Card className='border shadow'>
					<DateRange
						minDate={minDate}
						maxDate={maxDate}
						rangeColors={['#32b2f3']}
						ranges={localValue}
						months={1}
						locale={localization.getDateFnsLocalization?.()}
						showMonthAndYearPickers={false}
						direction='vertical'
						onChange={({ common }) => {
							setLocalValue([common])
							onApply([
								format(common.startDate, DateFormat.system),
								format(common.endDate, DateFormat.system),
							])
						}}
						className='p-3'
						onRangeFocusChange={(flags: number[]) => {
							// close the popover when the user select both dates
							if (flags.filter(Boolean).length === 0) {
								close()
							}
						}}
					/>
				</Card>
			}
		>
			{childrenCloneElement}
		</Popover>
	)
}
