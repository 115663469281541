import { omitAll } from 'lodash/fp'

import { PricingLineModel, ProductEntity } from './types'

export const unwrapPricingLine = (line: PricingLineModel): ProductEntity[] => {
	return line.products.map(
		(product) =>
			({
				...product,
				...omitAll(['products', 'fields.aggregated_fields'], line),
			}) as ProductEntity
	)
}
export const unwrapLines = (
	collection: PricingLineModel[] = []
): ProductEntity[] => {
	let products: ProductEntity[] = []
	for (let i = 0; i < collection?.length; i++) {
		products = products.concat(unwrapPricingLine(collection[i]) as any) // eslint-disable-line @typescript-eslint/no-explicit-any
	}
	return products
}
