import {
	Column,
	createRow,
	getMemoOptions,
	memo,
	Row,
	RowData,
	RowModel,
	Table,
} from '@tanstack/react-table'
import { CSSProperties } from 'react'
import { MHeader, TableInstance } from '../types'

export function getCoreRowModel<TData extends RowData>(): (
	table: Table<TData>
) => () => RowModel<TData> {
	return (table) =>
		memo(
			() => [table.options.data],
			(
				data
			): {
				rows: Row<TData>[]
				flatRows: Row<TData>[]
				rowsById: Record<string, Row<TData>>
			} => {
				const rowModel: RowModel<TData> = {
					rows: [],
					flatRows: [],
					rowsById: {},
				}

				const accessRows = (
					originalRows: TData[],
					depth = 0,
					parentRow?: Row<TData>
				): Row<TData>[] => {
					const rows = [] as Row<TData>[]

					for (let i = 0; i < originalRows.length; i++) {
						// This could be an expensive check at scale, so we should move it somewhere else, but where?
						// if (!id) {
						//   if (process.env.NODE_ENV !== 'production') {
						//     throw new Error(`getRowId expected an ID, but got ${id}`)
						//   }
						// }

						// Make the row
						const row = createRow(
							table,
							table._getRowId(originalRows[i]!, i, parentRow),
							originalRows[i]!,
							i,
							depth,
							undefined,
							parentRow?.id
						)

						// Add necessary props to support manual grouping
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						row.groupingColumnId = (originalRows[i] as any).groupingColumnId
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						row.groupingValue = (originalRows[i] as any)[
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							(row as any).groupingColumnId
						]

						// Keep track of every row in a flat array
						rowModel.flatRows.push(row)
						// Also keep track of every row by its ID
						rowModel.rowsById[row.id] = row
						// Push table row into parent
						rows.push(row)

						// Get the original sub rows
						if (table.options.getSubRows) {
							row.originalSubRows = table.options.getSubRows(
								originalRows[i]!,
								i
							)

							// Then recursively access them
							if (row.originalSubRows?.length) {
								row.subRows = accessRows(row.originalSubRows, depth + 1, row)
							}
						}
					}

					return rows
				}

				rowModel.rows = accessRows(data)

				return rowModel
			},
			getMemoOptions(table.options, 'debugTable', 'getRowModel', () =>
				table._autoResetPageIndex()
			)
		)
}

export function getCommonPinningStyles<T>(
	column: Column<T>,
	layoutMode?: string
): CSSProperties {
	const isPinned = column.getIsPinned()
	const isLastLeftPinnedColumn =
		isPinned === 'left' && column.getIsLastColumn('left')
	const isFirstRightPinnedColumn =
		isPinned === 'right' && column.getIsFirstColumn('right')

	const width =
		layoutMode === 'semantic'
			? column.getSize()
			: column.getSize() === Number.MAX_SAFE_INTEGER
				? 'auto'
				: column.getSize()

	return {
		boxShadow: isLastLeftPinnedColumn
			? '-4px 0 4px -4px gray inset'
			: isFirstRightPinnedColumn
				? '4px 0 4px -4px gray inset'
				: undefined,
		left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
		right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
		opacity: 1,
		position: isPinned ? 'sticky' : 'relative',
		width: width,
		minWidth: width,
		maxWidth: width,
		zIndex: isPinned ? 10 : 0,
	}
}

export function getHeaderPinningStyles<T>(
	header: MHeader<T>,
	{
		isPinned,
		left,
		right,
		layoutMode,
	}: {
		isPinned: boolean | 'left' | 'right'
		left: number
		right: number
		layoutMode?: string
	}
): CSSProperties {
	//const isPinned = header.column.getIsPinned();
	const width =
		layoutMode === 'semantic'
			? header.getSize()
			: header.getSize() === Number.MAX_SAFE_INTEGER
				? 'auto'
				: header.getSize()

	return {
		right: isPinned === 'right' ? `${right}px` : undefined,
		left: isPinned === 'left' ? `${left}px` : undefined,
		opacity: 1,
		position: isPinned ? 'sticky' : 'relative',
		width: width,
		minWidth: width,
		maxWidth: width,
		zIndex: isPinned ? 20 : 0,
	}
}

export function getHeaders<T>(table: TableInstance<T>) {
	const grouped = table.getLeafHeaders().reduce(
		(acc, header) => {
			if (acc[header.depth]?.length) {
				acc[header.depth].push(header)
			} else {
				acc[header.depth] = [header]
			}
			return acc
		},
		{} as Record<number, MHeader<T>[]>
	)
	return Object.values(grouped).map((headers) => {
		const hasSubheaders = (header: MHeader<T>) => header.subHeaders?.length
		return headers.map((header) => {
			const isPinned = hasSubheaders(header)
				? header.subHeaders.map((h) => h.column.getIsPinned())[0]
				: header.column.getIsPinned()
			const right = hasSubheaders(header)
				? Math.min(...header.subHeaders.map((h) => h.column.getAfter('right')))
				: header.column.getAfter('right')
			const left = hasSubheaders(header)
				? Math.min(...header.subHeaders.map((h) => h.column.getStart('left')))
				: header.column.getStart('left')
			return {
				id: header.id,
				depth: header.depth,
				isPinned,
				header,
				left,
				right,
			}
		})
	})
}
