import React from 'react'

import { Form } from './common'
import PopupFooter from './PopupFooter'

export const Group = ({
	children,
	isDisabledApply,
	onRemove,
	onCancel,
	...props
}: {
	children: React.ReactNode
	isDisabledApply: boolean
	onRemove?(): void
	onCancel?(): void
} & React.HTMLAttributes<HTMLFormElement>) => (
	<Form {...props}>
		{children}
		<PopupFooter
			isDisabledApply={isDisabledApply}
			onRemove={onRemove}
			onCancel={onCancel}
		/>
	</Form>
)
