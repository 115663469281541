import React from 'react'

import notFound from '@/assets/img/svg/not_found.svg'
import Public from '@/components/layouts/Public'
import EmptyState from '@/components/placeholders/EmptyState'
import intl from '@/locale'

export default function NotFound() {
	return (
		<Public title='Not Found'>
			<EmptyState
				imageUrl={notFound}
				wrapperClasses='h-full max-w-600 m-auto items-center justify-center'
				description={intl.get('not_found_text')}
			/>
		</Public>
	)
}
