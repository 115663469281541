import clsx from 'clsx'
import { formatDistanceToNow } from 'date-fns'
import { any, find, pipe, prop, propOr } from 'lodash/fp'
import React, { useMemo, useState } from 'react'
import { FieldErrors, useFormContext } from 'react-hook-form'

import {
	Card,
	CardContent,
	CardHeader,
	CardSubtitle,
	CardTitle,
	InlineMessage,
	Loader,
	TabItem,
	Tabs,
	TreeNodeType,
} from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import { ModalBody } from '@cmpkit/modal'

import ErrorBoundary from '@/components/ErrorBoundary'
import { toAdaptedSchema } from '@/components/filter/adapter'
import { FilterField } from '@/components/filter/types'
import {
	AnchoringRulesFiledController,
	getAnchoringOnConflictChoices,
} from '@/components/FormSections/Anchoring'
import { AssignmentSetsFieldController } from '@/components/FormSections/Assignment'
import { RepricingLimitsFieldController } from '@/components/FormSections/Limits'
import { MinimalPriceStepFiledController } from '@/components/FormSections/MinimalSteps'
import { MarkdownPeriodsFieldController } from '@/components/FormSections/PricingTactics/MarkdowTactic'
import { RepricingRulesFieldController } from '@/components/FormSections/PricingTactics/RepricingRulesTactic'
import { RoundingRulesFiledController } from '@/components/FormSections/Rounding'
import { RHFRadioGroup, RHFSelect, RHFTextField } from '@/components/HookForm'
import RHFSettingsWidget from '@/components/HookForm/RHFSettingsWidget'
import RHFStoplistCustomRules from '@/components/HookForm/RHFStoplistCustomRules'
import LabeledValue from '@/components/LabeledValue'
import UsernameById from '@/components/UsernameById'
import { PricingEngineType, SettingsTemplateType } from '@/generated'
import intl from '@/locale'
import {
	useBrandsQuery,
	useCategoriesQuery,
	useColumnsSchemaQuery,
} from '@/modules/core/queries'
import { toCategoriesTree } from '@/modules/core/utils'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import { getPricingCampaignEngines } from '@/modules/pricing-campaigns'
import {
	getEnumOptions,
	getResolvedSchema,
	translateOptions,
	translateSchemaKeys,
} from '@/tools/json-schema-utils'

import {
	PricingCampaignTemplateFormData,
	ScenarioTemplateFormData,
	SettingsTemplateFormDataGen,
} from '../../types'
import { useSettingsTemplateContext } from './SettingsTemplateProvider'

export default function SettingsTemplateResolver() {
	const { templateType } = useSettingsTemplateContext()
	switch (templateType) {
		case SettingsTemplateType.PricingCampaign:
			return <SettingsTemplatePricingCampaign />
		case SettingsTemplateType.Scenario:
			return <SettingsTemplateScenario />
		case SettingsTemplateType.ScenarioStopList:
			return (
				<>
					<div className='border-b border-solid'>
						<SettinsgTemplateFormHeader />
					</div>
					<ModalBody className='bg-accent-3'>
						<ScenarioStopListSettings settingsPathPrefix='body' />
					</ModalBody>
				</>
			)
		case SettingsTemplateType.ScenarioBusinessConstraints:
			return (
				<>
					<div className='border-b border-solid'>
						<SettinsgTemplateFormHeader />
					</div>
					<ModalBody className='bg-accent-3'>
						<ScenarioBusinessConstraintsSettings settingsPathPrefix='body' />
					</ModalBody>
				</>
			)
		case SettingsTemplateType.ScenarioStrategy:
			return (
				<>
					<div className='border-b border-solid'>
						<SettinsgTemplateFormHeader />
					</div>
					<ModalBody className='bg-accent-3'>
						<ScenarioStrategySettings settingsPathPrefix='body' />
					</ModalBody>
				</>
			)
		case SettingsTemplateType.PricingCampaignProductAssignment:
			return (
				<>
					<div className='border-b border-solid'>
						<SettinsgTemplateFormHeader />
					</div>
					<ModalBody className='bg-accent-3'>
						<PricingCampaignAssignmentSettings settingsPathPrefix='body' />
					</ModalBody>
				</>
			)
		case SettingsTemplateType.PricingCampaignLimits:
			return (
				<>
					<div className='border-b border-solid'>
						<SettinsgTemplateFormHeader />
					</div>
					<ModalBody className='bg-accent-3'>
						<PricingCampaignRepricingLimitsSettings settingsPathPrefix='body' />
					</ModalBody>
				</>
			)
		case SettingsTemplateType.PricingCampaignMinimalSteps:
			return (
				<>
					<div className='border-b border-solid'>
						<SettinsgTemplateFormHeader />
					</div>
					<ModalBody className='bg-accent-3'>
						<PricingCampaignMinimalPriceStepSettings settingsPathPrefix='body' />
					</ModalBody>
				</>
			)
		case SettingsTemplateType.PricingCampaignRounding:
			return (
				<>
					<div className='border-b border-solid'>
						<SettinsgTemplateFormHeader />
					</div>
					<ModalBody className='bg-accent-3'>
						<PricingCampaignRoundingRulesSettings settingsPathPrefix='body' />
					</ModalBody>
				</>
			)
		case SettingsTemplateType.PricingCampaignAnchoring:
			return (
				<>
					<div className='border-b border-solid'>
						<SettinsgTemplateFormHeader />
					</div>
					<ModalBody className='bg-accent-3'>
						<PricingCampaignAnchoringSettings settingsPathPrefix='body' />
					</ModalBody>
				</>
			)
		case SettingsTemplateType.PricingCampaignTacticsMl:
		case SettingsTemplateType.PricingCampaignTacticsRb:
		case SettingsTemplateType.PricingCampaignTacticsMd:
			return (
				<>
					<div className='border-b border-solid'>
						<SettinsgTemplateFormHeader />
					</div>
					<ModalBody className='bg-accent-3'>
						<PricingCampaignTacticsSettings settingsPathPrefix='body' />
					</ModalBody>
				</>
			)
		default:
			return <div>Not implemented</div>
	}
}
function SettinsgTemplateFormHeader() {
	const { template, templateType } = useSettingsTemplateContext()
	return (
		<div className='px-4 pb-3 pt-2'>
			<div className='relative flex items-center justify-between'>
				<RHFTextField
					className='w-96'
					name='name'
					autoComplete='off'
					label={intl.get('template.name.label').d('Template name')}
					required
				/>
			</div>
			<div className='mt-2 flex items-start gap-10'>
				<LabeledValue label={intl.get('created_at').d('Created at')}>
					{template?.created_at ? (
						<span className='font-medium'>
							{formatDistanceToNow(new Date(template?.created_at + 'Z'), {
								addSuffix: true,
							})}
							{template?.created_at &&
								template?.created_by &&
								` ${intl.get('by').d('by')} `}
							{template?.created_by && (
								<UsernameById userId={template?.created_by} />
							)}
						</span>
					) : (
						'-'
					)}
				</LabeledValue>
				<LabeledValue label={intl.get('last_modified_at').d('Last modified')}>
					{template?.updated_at ? (
						<span className='font-medium'>
							{formatDistanceToNow(new Date(template?.updated_at + 'Z'), {
								addSuffix: true,
							})}
							{template?.updated_at &&
								template?.updated_by &&
								` ${intl.get('by').d('by')} `}
							{template?.updated_by && (
								<UsernameById userId={template?.updated_by} />
							)}
						</span>
					) : (
						'-'
					)}
				</LabeledValue>
				<LabeledValue label={intl.get('template_type').d('Template type')}>
					<span className='font-medium'>
						{intl.get(`template.type.${templateType}`)}
					</span>
				</LabeledValue>
			</div>
		</div>
	)
}
function SettingsTemplateScenario() {
	const { methods } = useSettingsTemplateContext()
	const [currentTab, setCurrentTab] = useState('strategy')
	const tabs = useMemo(
		() => [
			/* {
				key: 'general',
				label: intl.get('settings.general.title').d('General'),
				hasError: (
					errors: FieldErrors<
						SettingsTemplateFormDataGen<PricingCampaignTemplateFormData>
					>
				) => errors?.body?.name,
				TabContent: TemplateGeneralSettings,
			}, */
			{
				key: 'strategy',
				label: intl.get('settings.straregy.short').d('Strategy'),
				hasError: (
					errors: FieldErrors<
						SettingsTemplateFormDataGen<ScenarioTemplateFormData>
					>
				) => errors?.body?.settings?.strategy,
				TabContent: ScenarioStrategySettings,
			},

			{
				key: 'business_constraints',
				label: intl.get('settings.business.short').d('Constrains'),
				hasError: (
					errors: FieldErrors<
						SettingsTemplateFormDataGen<ScenarioTemplateFormData>
					>
				) => errors?.body?.settings?.business,
				TabContent: ScenarioBusinessConstraintsSettings,
			},
			{
				key: 'stop_list',
				label: intl.get('settings.stop_list.short').d('Stop list'),
				hasError: (
					errors: FieldErrors<
						SettingsTemplateFormDataGen<ScenarioTemplateFormData>
					>
				) => errors?.body?.settings?.stop_list,
				TabContent: ScenarioStopListSettings,
			},
		],
		[]
	)
	const TabContent = useMemo(
		() => find({ key: currentTab }, tabs)?.TabContent ?? 'div',
		[currentTab]
	)
	return (
		<>
			<div className='border-b border-solid'>
				<SettinsgTemplateFormHeader />
				<Tabs className='-mb-px ml-3'>
					{tabs.map((tab) => (
						<TabItem
							data-testid={'settings-template-tab'}
							data-tabid={tab.key}
							key={tab.key}
							active={currentTab === tab.key}
							onClick={() => setCurrentTab(tab.key)}
							className={clsx({
								'text-danger': tab.hasError(methods.formState.errors),
							})}
						>
							{tab.label}
							{tab.hasError(methods.formState.errors) && <AlertIcon />}
						</TabItem>
					))}
				</Tabs>
			</div>
			<ModalBody className='bg-accent-3'>
				<TabContent settingsPathPrefix='body.settings' />
			</ModalBody>
		</>
	)
}
function SettingsTemplatePricingCampaign() {
	const { methods } = useSettingsTemplateContext()
	const [currentTab, setCurrentTab] = useState('assignment')
	const tabs = useMemo(
		() =>
			[
				/* {
					key: 'general',
					label: intl.get('settings.general.title').d('General'),
					hasError: (
						errors: FieldErrors<
							SettingsTemplateFormDataGen<PricingCampaignTemplateFormData>
						>
					) => errors?.body?.name,
					TabContent: TemplateGeneralSettings,
				}, */
				{
					key: 'assignment',
					label: intl.get('settings.assignment.short').d('Assignment'),
					hasError: (
						errors: FieldErrors<
							SettingsTemplateFormDataGen<PricingCampaignTemplateFormData>
						>
					) => errors?.body?.settings?.product_assignments,
					TabContent: PricingCampaignAssignmentSettings,
				},
				{
					key: 'tactics',
					label: intl.get('settings.tactics.short').d('Tactics'),
					hasError: (
						errors: FieldErrors<
							SettingsTemplateFormDataGen<PricingCampaignTemplateFormData>
						>
					) => errors?.name || errors?.body?.settings?.pricing_tactics,
					TabContent: () => {
						const { isNew } = useSettingsTemplateContext()
						return (
							<>
								{isNew && (
									<Card
										className='mb-3 border'
										data-testid={'settings-template-tactics-panel'}
									>
										<CardContent>
											<RHFRadioGroup
												label={intl.get('engine').d('Engine')}
												name='body.settings.pricing_tactics.engine'
												options={getPricingCampaignEngines()}
											/>
											<InlineMessage
												variant='warning'
												className='text-xs font-medium'
												icon={<InfoIcon />}
											>
												{intl
													.get('pc.tactics.disabled_warning')
													.d(
														'You can`t change pricing tactics engine after template creation'
													)}
											</InlineMessage>
										</CardContent>
									</Card>
								)}
								<PricingCampaignTacticsSettings settingsPathPrefix='body.settings' />
							</>
						)
					},
				},

				{
					key: 'repricing_limits',
					label: intl.get('settings.price_limits.short').d('Limits'),
					hasError: (
						errors: FieldErrors<
							SettingsTemplateFormDataGen<PricingCampaignTemplateFormData>
						>
					) => errors?.body?.settings?.price_limits,
					TabContent: PricingCampaignRepricingLimitsSettings,
				},
				{
					key: 'anchoring',
					label: intl.get('settings.anchoring.short').d('Anchoring'),
					hasError: (
						errors: FieldErrors<
							SettingsTemplateFormDataGen<PricingCampaignTemplateFormData>
						>
					) => errors?.body?.settings?.anchoring,
					TabContent: PricingCampaignAnchoringSettings,
				},
				{
					key: 'rounding',
					label: intl.get('settings.rounding.short').d('Rounding'),
					hasError: (
						errors: FieldErrors<
							SettingsTemplateFormDataGen<PricingCampaignTemplateFormData>
						>
					) => errors?.body?.settings?.rounding,
					TabContent: PricingCampaignRoundingRulesSettings,
				},
				{
					key: 'min_steps',
					label: intl.get('settings.minimal_steps.short').d('Minimal steps'),
					hasError: (
						errors: FieldErrors<
							SettingsTemplateFormDataGen<PricingCampaignTemplateFormData>
						>
					) => errors?.body?.settings?.minimal_steps,
					TabContent: PricingCampaignMinimalPriceStepSettings,
				},
			].filter(Boolean),
		[]
	)
	const TabContent = useMemo(
		() => find({ key: currentTab }, tabs)?.TabContent ?? 'div',
		[currentTab]
	)
	return (
		<>
			<div className='border-b border-solid'>
				<SettinsgTemplateFormHeader />
				<Tabs className='-mb-px ml-3'>
					{tabs.map((tab) => (
						<TabItem
							key={tab.key}
							active={currentTab === tab.key}
							onClick={() => setCurrentTab(tab.key)}
							className={clsx({
								'text-danger': tab.hasError(methods.formState.errors),
							})}
						>
							{tab.label}
							{tab.hasError(methods.formState.errors) && <AlertIcon />}
						</TabItem>
					))}
				</Tabs>
			</div>
			<ModalBody className='bg-accent-3'>
				<TabContent settingsPathPrefix='body.settings' />
			</ModalBody>
		</>
	)
}
function ScenarioStrategySettings({
	settingsPathPrefix,
}: {
	settingsPathPrefix: string
}) {
	const schemaQuery = useSettingsSchemasQuery({
		select: pipe([getResolvedSchema('part:strategy'), translateSchemaKeys]),
	})

	if (schemaQuery.isLoading) {
		return (
			<div className='flex min-h-96 w-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	return (
		<Card data-testid='settings-template-strategies-panel' className='border'>
			<CardHeader>
				<CardTitle as='h3'>
					{intl.get('settings.strategy.long').d('Pricing strategy')}
				</CardTitle>
			</CardHeader>
			<CardContent>
				<ErrorBoundary>
					<RHFSelect
						name={`${settingsPathPrefix}.strategy.demand_strategy.target`}
						className='w-56'
						label={prop(
							'properties.demand_strategy.properties.target.title',
							schemaQuery.data
						)}
						description={prop(
							'properties.demand_strategy.properties.target.description',
							schemaQuery.data
						)}
						options={translateOptions(
							getEnumOptions(
								prop(
									'properties.demand_strategy.properties.target',
									schemaQuery.data
								)
							)
						)}
					/>
					<RHFSelect
						name={`${settingsPathPrefix}.strategy.demand_strategy.protect`}
						className='w-56'
						label={prop(
							'properties.demand_strategy.properties.protect.title',
							schemaQuery.data
						)}
						description={prop(
							'properties.demand_strategy.properties.protect.description',
							schemaQuery.data
						)}
						options={translateOptions(
							getEnumOptions(
								prop(
									'properties.demand_strategy.properties.protect',
									schemaQuery.data
								)
							)
						)}
					/>
				</ErrorBoundary>
			</CardContent>
			<CardHeader>
				<CardTitle as='h3'>
					{intl.get('optimization_groups_settings_tab_markdown_title')}
				</CardTitle>
				<CardSubtitle>
					{intl.get('optimization_groups_settings_tab_markdown_subtitle')}
				</CardSubtitle>
			</CardHeader>
			<CardContent>
				<ErrorBoundary>
					<RHFSelect
						name={`${settingsPathPrefix}.strategy.markdown_strategy.target`}
						className='w-56'
						label={prop(
							'properties.markdown_strategy.properties.target.title',
							schemaQuery.data
						)}
						description={prop(
							'properties.markdown_strategy.properties.target.description',
							schemaQuery.data
						)}
						options={translateOptions(
							getEnumOptions(
								prop(
									'properties.markdown_strategy.properties.target',
									schemaQuery.data
								)
							)
						)}
					/>
				</ErrorBoundary>
			</CardContent>
		</Card>
	)
}
function ScenarioBusinessConstraintsSettings({
	settingsPathPrefix,
}: {
	settingsPathPrefix: string
}) {
	const schemaQuery = useSettingsSchemasQuery({
		select: pipe([getResolvedSchema('part:business'), translateSchemaKeys]),
	})

	if (schemaQuery.isLoading) {
		return (
			<div className='flex min-h-96 w-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	return (
		<Card data-testid='settings-template-business-panel' className='border'>
			<CardHeader>
				<CardTitle as='h3'>
					{intl.get('settings.business.long').d('Business constrains')}
				</CardTitle>
				<CardSubtitle>
					{intl.get('optimization_groups_settings_business_subtitle')}
				</CardSubtitle>
			</CardHeader>
			<CardContent>
				<ErrorBoundary>
					<RHFSettingsWidget
						name={`${settingsPathPrefix}.business`}
						schema={schemaQuery.data}
					/>
				</ErrorBoundary>
			</CardContent>
		</Card>
	)
}
function ScenarioStopListSettings({
	settingsPathPrefix,
}: {
	settingsPathPrefix: string
}) {
	const schemaQuery = useSettingsSchemasQuery({
		select: pipe([getResolvedSchema('part:stop_list'), translateSchemaKeys]),
	})
	const fieldsQuery = useColumnsSchemaQuery<FilterField[]>({
		select: (data) =>
			data
				.filter(({ stage }) => ['prepro', 'pre_init_fields'].includes(stage!))
				.map(toAdaptedSchema),
	})
	const brandsQuery = useBrandsQuery({})
	const categoriesQuery = useCategoriesQuery<TreeNodeType[]>(
		{},
		{
			select: toCategoriesTree,
		}
	)
	const fields = useMemo(() => fieldsQuery.data || [], [fieldsQuery.data])
	const dataChoices = useMemo(() => {
		return {
			brands: brandsQuery.data || [],
			category_ids: categoriesQuery.data || [],
		}
	}, [brandsQuery.data, categoriesQuery.data])

	const isLoading = any(prop('isLoading'), [
		fieldsQuery,
		brandsQuery,
		categoriesQuery,
	])
	if (isLoading) {
		return (
			<div className='flex min-h-96 w-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	return (
		<div className='flex flex-col gap-3'>
			<Card data-testid='settings-template-stop-list-panel' className='border'>
				<CardHeader>
					<CardTitle as='h3'>
						{intl.get('stop_list.default_rules.title')}
					</CardTitle>
					<CardSubtitle>
						{intl.get('stop_list.default_rules.subtitle')}
					</CardSubtitle>
				</CardHeader>
				<CardContent>
					<ErrorBoundary>
						<RHFSettingsWidget
							name={`${settingsPathPrefix}.stop_list.default_rules`}
							schema={schemaQuery.data?.properties.default_rules}
						/>
					</ErrorBoundary>
				</CardContent>
				<CardHeader>
					<CardTitle as='h3'>
						{intl.get('stop_list.custom_rules.title')}
					</CardTitle>
					<CardSubtitle>
						{intl.get('stop_list.custom_rules.subtitle')}
					</CardSubtitle>
				</CardHeader>
				<CardContent>
					<ErrorBoundary>
						<RHFStoplistCustomRules
							name={`${settingsPathPrefix}.stop_list.custom_rules`}
							fields={fields}
							dataChoices={dataChoices}
						/>
					</ErrorBoundary>
				</CardContent>
			</Card>
		</div>
	)
}
function PricingCampaignTacticsSettings({
	settingsPathPrefix,
}: {
	settingsPathPrefix: string
}) {
	const { watch } = useFormContext()
	const engine = watch(`${settingsPathPrefix}.pricing_tactics.engine`)
	return (
		<>
			<Card className='border' data-testid='settings-template-tactics-panel'>
				<CardHeader>
					<CardTitle as='h2'>
						{intl.get('settings.tactics.long').d('Pricing tactics')}
					</CardTitle>
				</CardHeader>
				<CardContent>
					{engine === PricingEngineType.MD && (
						<MarkdownPeriodsFieldController
							name={`${settingsPathPrefix}.pricing_tactics.params`}
						/>
					)}
					{engine === PricingEngineType.RB && (
						<RepricingRulesFieldController
							name={`${settingsPathPrefix}.pricing_tactics.params`}
						/>
					)}
					{engine === PricingEngineType.ML && (
						<InlineMessage variant='info' className='text-xs font-medium'>
							{intl
								.get('global.pc.settings.demand_strategy.message')
								.d(
									'Pricing strategy will be inherited from the Optimization group settings.'
								)}
						</InlineMessage>
					)}
				</CardContent>
			</Card>
		</>
	)
}
function PricingCampaignRepricingLimitsSettings({
	settingsPathPrefix,
}: {
	settingsPathPrefix: string
}) {
	const settingsSchema = useSettingsSchemasQuery({
		select: pipe([getResolvedSchema('part:price_limits'), translateSchemaKeys]),
	})
	if (settingsSchema.isLoading) {
		return (
			<div className='flex min-h-96 w-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	const schema = settingsSchema.data
	return (
		<Card data-testid='settings-template-price-limits-panel' className='border'>
			<CardHeader>
				<CardTitle as='h2'>
					{intl.get('settings.price_limits.long').d('Repricing limits')}
				</CardTitle>
			</CardHeader>
			<CardContent className='space-y-5'>
				<ErrorBoundary>
					<RepricingLimitsFieldController
						name={`${settingsPathPrefix}.price_limits.lower`}
						defaultValue={propOr([], 'properties.lower.default', schema)}
						schema={prop('properties.lower', schema)}
					/>
					<RepricingLimitsFieldController
						name={`${settingsPathPrefix}.price_limits.upper`}
						defaultValue={propOr([], 'properties.upper.default', schema)}
						schema={prop('properties.upper', schema)}
					/>
				</ErrorBoundary>
			</CardContent>
			<CardHeader>
				<CardTitle>{prop('properties.extra.title', schema)}</CardTitle>
				<CardSubtitle>
					{prop('properties.extra.description', schema)}
				</CardSubtitle>
			</CardHeader>
			<CardContent>
				<RHFSelect
					name={`${settingsPathPrefix}.price_limits.extra.on_conflict`}
					defaultValue={propOr(
						[],
						'properties.extra.properties.on_conflict.default',
						schema
					)}
					label={prop('properties.extra.properties.on_conflict.title', schema)}
					options={getEnumOptions(
						prop('properties.extra.properties.on_conflict', schema)
					)}
					menuPlacement='top'
				/>
			</CardContent>
		</Card>
	)
}
/* function TemplateGeneralSettings() {
	return (
		<Card className='border'>
			<CardHeader>
				<CardTitle as='h2'>
					{intl.get('settings.general.title').d('General')}
				</CardTitle>
			</CardHeader>
			<CardContent>
				<RHFTextField
					required
					description={intl
						.get('template.entity_name.description')
						.d(
							'This name will be used to create an instances of this template.'
						)}
					label={intl.get('template.entity_name.label').d('Entity name')}
					name={'body.name'}
				/>
			</CardContent>
		</Card>
	)
} */
function PricingCampaignAssignmentSettings({
	settingsPathPrefix,
}: {
	settingsPathPrefix: string
}) {
	return (
		<AssignmentSetsFieldController
			name={`${settingsPathPrefix}.product_assignments`}
		/>
	)
}
function PricingCampaignAnchoringSettings({
	settingsPathPrefix,
}: {
	settingsPathPrefix: string
}) {
	const anchoringOnConflictChoices = useMemo(
		() => getAnchoringOnConflictChoices(),
		[]
	)
	return (
		<Card className='border' data-testid='settings-template-anchoring-panel'>
			<CardHeader>
				<CardTitle as='h2'>
					{intl.get('settings.anchoring.long').d('Anchoring price behavior')}
				</CardTitle>
			</CardHeader>
			<CardContent>
				<AnchoringRulesFiledController
					name={`${settingsPathPrefix}.anchoring`}
				/>
			</CardContent>
			<CardHeader>
				<CardTitle>
					{intl
						.get('anchoring.section.additional_settings.title')
						.d('Additional settings')}
				</CardTitle>
				<CardSubtitle>
					{intl
						.get('anchoring.section.additional_settings.subtitle')
						.d('Define the prior limit parameter in special cases.')}
				</CardSubtitle>
			</CardHeader>
			<CardContent>
				<RHFSelect
					required
					className='w-64'
					name={`${settingsPathPrefix}.anchoring.on_conflict`}
					label={intl
						.get('anchoring.field.on_conflict.label')
						.d('In case of limits conflict')}
					options={anchoringOnConflictChoices}
					menuPlacement='top'
				/>
			</CardContent>
		</Card>
	)
}
function PricingCampaignRoundingRulesSettings({
	settingsPathPrefix,
}: {
	settingsPathPrefix: string
}) {
	const settingsSchema = useSettingsSchemasQuery({
		select: pipe([getResolvedSchema('part:rounding'), translateSchemaKeys]),
	})

	if (settingsSchema.isLoading) {
		return (
			<div className='flex min-h-96 w-full items-center justify-center'>
				<Loader />
			</div>
		)
	}

	const schema = settingsSchema.data

	return (
		<Card data-testid='settings-template-rounding-panel' className='border'>
			<CardHeader>
				<CardTitle as='h2'>
					{intl.get('settings.rounding.long').d('Rounding rules')}
				</CardTitle>
			</CardHeader>
			<CardContent>
				<RoundingRulesFiledController
					name={`${settingsPathPrefix}.rounding`}
					schema={schema}
				/>
			</CardContent>
		</Card>
	)
}
function PricingCampaignMinimalPriceStepSettings({
	settingsPathPrefix,
}: {
	settingsPathPrefix: string
}) {
	const settingsSchema = useSettingsSchemasQuery({
		select: pipe([
			getResolvedSchema('part:minimal_steps'),
			translateSchemaKeys,
		]),
	})
	if (settingsSchema.isLoading) {
		return (
			<div className='flex min-h-96 w-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	const schema = settingsSchema.data
	return (
		<Card
			data-testid='settings-template-minimal-steps-panel'
			className='border'
		>
			<CardHeader>
				<CardTitle as='h2'>
					{intl.get('settings.minimal_steps.long').d('Minimal price change')}
				</CardTitle>
			</CardHeader>
			<CardContent>
				<MinimalPriceStepFiledController
					name={`${settingsPathPrefix}.minimal_steps`}
					schema={schema}
				/>
			</CardContent>
		</Card>
	)
}
