import { map, pipe, prop, sum } from 'lodash/fp'
import React, { useEffect } from 'react'

import { Button, Spinner } from '@cmpkit/base'
import AlertOctagonIcon from '@cmpkit/icon/lib/glyph/alert-octagon'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'
import { usePricingAlertsQuery } from '@/modules/core/queries'
import { useDrawersStore } from '@/modules/drawers/store'
import analytic from '@/services/analytics'

const getAlertsProductsTotalCount = pipe([
	prop('by_level'),
	map(prop('products')),
	sum,
])

export default function AssortmentPricingAlertsButton() {
	const { openDrawer, closeDrawer } = useDrawersStore()
	useEffect(() => {
		return () => {
			closeDrawer('PRICING_ALERTS')
		}
	}, [])

	const { data, isLoading } = usePricingAlertsQuery<number>(
		{},
		{
			select: getAlertsProductsTotalCount,
		}
	)
	return (
		<Tooltip content={intl.get('app.pricing_alerts')} placement='top-end'>
			<Button
				onClick={() => {
					analytic.logEvent('alerts: assortment: click open sidebar')
					openDrawer('PRICING_ALERTS', {
						type: 'global',
						drawerClassName: 'assortment-alerts-eastside',
					})
				}}
				className='relative'
				iconBefore={
					<>
						{isLoading ? <Spinner /> : <AlertOctagonIcon />}
						{data ? (
							<span className='absolute -right-1 -top-1 flex size-3 ease-in-out'>
								<span className='absolute inline-flex size-full animate-ping rounded-full bg-danger-100' />
								<span className='relative inline-flex size-3 rounded-full bg-danger' />
							</span>
						) : null}
					</>
				}
			/>
		</Tooltip>
	)
}
