import clsx from 'clsx'
import { findIndex, flatten, map, prop, propEq, sum } from 'lodash/fp'
import React, { useRef } from 'react'

import {
	Cell,
	Column,
	flexRender,
	Header,
	HeaderGroup,
	Row,
	Table as TableReact,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@cmpkit/base'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretUpIcon from '@cmpkit/icon/lib/glyph/caret-up'
import Tooltip from '@cmpkit/tooltip'

export default function VirtualDataTable<T>({
	table,
	divideX,
	divideY,
	getRowClassName,
	getCellClassName,
	compact,
	className,
	estimateSize = () => 42,
}: {
	className?: string
	table: TableReact<T>
	getRowClassName?: (row: Row<T>) => string
	getCellClassName?: (row: Row<T>, cell: Cell<T, unknown>) => string
	compact?: boolean
	estimateSize?: (index: number) => number
	divideX?: boolean
	divideY?: boolean
}) {
	const tableContainerRef = useRef<HTMLDivElement>(null)
	const headerCellClassName = clsx({
		'px-2 py-1': compact,
		'px-3 py-2': !compact,
		'border-r': divideX,
		'border-b': divideY,
	})
	const bodyCellClassName = clsx({
		'px-2': compact,
		'px-3': !compact,
		'border-r': divideX,
		'border-b': divideY,
	})
	const { rows } = table.getRowModel()
	const tableHeaderGroups = table.getHeaderGroups()

	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		getScrollElement: () => tableContainerRef.current,
		estimateSize,
		overscan: 10,
	})
	const virtualRows = rowVirtualizer.getVirtualItems()
	const totalSize = rowVirtualizer.getTotalSize()
	const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
	const paddingBottom =
		virtualRows.length > 0
			? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
			: 0
	return (
		<div
			className={clsx('relative w-full overflow-auto', className)}
			ref={tableContainerRef}
		>
			<Table className='table-fixed border-separate border-spacing-0'>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead
										key={header.id}
										className={headerCellClassName}
										style={getCellStyle(
											header.column,
											getLeftOffset(header.id, tableHeaderGroups)
										)}
									>
										<div
											{...{
												className: header.column.getCanSort()
													? 'cursor-pointer select-none flex items-center'
													: '',
												onClick: header.column.getToggleSortingHandler(),
											}}
										>
											<Tooltip
												// FIXME: remove this when we have a better way to handle tooltips
												hidden={header.column.columnDef.id === 'control'}
												content={header.column.columnDef.header as string}
											>
												<span className='truncate'>
													{flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}
												</span>
											</Tooltip>
											{{
												asc: <CaretUpIcon className='flex-none' />,
												desc: <CaretDownIcon className='flex-none' />,
											}[header.column.getIsSorted() as string] ?? null}
										</div>
										{header.column.getCanResize() && (
											<div
												{...{
													onDoubleClick: () => header.column.resetSize(),
													onMouseDown: header.getResizeHandler(),
													onTouchStart: header.getResizeHandler(),
													className: `resizer ${
														header.column.getIsResizing() ? 'isResizing' : ''
													}`,
													style: {
														transform: header.column.getIsResizing()
															? `translateX(${
																	(table.options.columnResizeDirection === 'rtl'
																		? -1
																		: 1) *
																	(table.getState().columnSizingInfo
																		.deltaOffset ?? 0)
																}px)`
															: '',
													},
												}}
											/>
										)}
									</TableHead>
								)
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{paddingTop > 0 && (
						<tr>
							<td style={{ height: `${paddingTop}px` }} />
						</tr>
					)}
					{virtualRows.map((virtualRow) => {
						const row = rows[virtualRow.index] as Row<T>
						return (
							<TableRow
								key={row.id}
								className={getRowClassName?.(row) || 'h-12'}
							>
								{row.getVisibleCells().map((cell) => {
									return (
										<TableCell
											key={cell.id}
											className={clsx(
												bodyCellClassName,
												getCellClassName?.(row, cell)
											)}
											style={getCellStyle<T>(
												cell.column,
												getLeftOffset<T>(cell.column.id, tableHeaderGroups)
											)}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</TableCell>
									)
								})}
							</TableRow>
						)
					})}
					{paddingBottom > 0 && (
						<tr>
							<td style={{ height: `${paddingBottom}px` }} />
						</tr>
					)}
				</TableBody>
			</Table>
		</div>
	)
}
function getCellStyle<T>(
	column: Column<T, unknown>,
	left: number
): React.CSSProperties {
	const width =
		column.getSize() === Number.MAX_SAFE_INTEGER ? 'auto' : column.getSize()
	if (column.getIsPinned() === 'left') {
		return {
			width,
			position: 'sticky',
			backgroundColor: 'inherit',
			zIndex: 1,
			left,
		}
	} else {
		return {
			width,
			position: 'relative',
		}
	}
}
function getLeftOffset<T>(
	id: string,
	tableHeaderGroups: HeaderGroup<T>[]
): number {
	const isHeaderPinned = (header: Header<T, unknown>) =>
		header.column.getIsPinned() === 'left'
	const headers = flatten(map(prop('headers'), tableHeaderGroups))
	const index = findIndex(propEq('id', id), headers)
	const firstPinnedIndex = findIndex(isHeaderPinned, headers)
	const widths = headers
		.slice(firstPinnedIndex, index)
		.filter(isHeaderPinned)
		.map((header) => header.getSize())
	return sum(widths)
}
