import React from 'react'

import { Col, InlineMessage, Row } from '@cmpkit/base'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'

import { OptimizationGroupModel } from '@/generated'
import intl from '@/locale'

type FormData = {
	settings: string[]
	groups: string[]
}
type Confirmation = {
	formData: FormData
	groups?: Record<string, OptimizationGroupModel>
}
function Confirmation({ formData, groups }: Confirmation) {
	const selectedSettings = formData.settings
	const selectedGroups = formData.groups

	return (
		<Row>
			<Col sm='6'>
				<h3>{intl.get('cp_og_settings_confirmation_settings')}</h3>
				<ul className='ml-5 h-64 space-y-2 overflow-y-scroll'>
					{selectedSettings.map((key: string) => {
						return (
							<li key={key}>
								<p>{intl.get(`setting_${key}`)}</p>
							</li>
						)
					})}
				</ul>
			</Col>
			<Col sm='6'>
				<h3>
					{intl.get('cp_og_settings_confirmation_ogs')}({selectedGroups.length}
					):
				</h3>
				<ul className='ml-5 h-64 space-y-2 overflow-y-scroll'>
					{selectedGroups.map((id: string) => (
						<li key={id}>{groups?.[id]?.name || 'None'}</li>
					))}
				</ul>
			</Col>
			<Col sm='12'>
				<InlineMessage
					icon={<InfoIcon />}
					variant='warning'
					className='w-full items-center p-3 text-xs'
				>
					{intl.get('cp_og_settings_confirmation_hint')}
				</InlineMessage>
			</Col>
		</Row>
	)
}

export default Confirmation
