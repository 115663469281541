import clsx from 'clsx'
import React from 'react'

import SearchIcon from '@cmpkit/icon/lib/glyph/search'

import intl from '@/locale'

export default function WidgetEmptyState({
	size = 'medium',
}: {
	size?: 'small' | 'medium'
}) {
	const iconSize = {
		small: 32,
		medium: 72,
		large: 120,
	}[size]
	return (
		<div className='flex w-full flex-1 items-center justify-center'>
			<div
				className={clsx('flex flex-col items-center space-y-1 text-center', {
					'p-5': size === 'medium',
				})}
			>
				<SearchIcon
					width={iconSize}
					height={iconSize}
					className='fill-danger-75'
				/>
				<span
					className={clsx('truncate text-danger-75', {
						'text-xs': size === 'small',
					})}
				>
					{intl.get('dashboard.empty_widget').d('No data found to load')}
				</span>
			</div>
		</div>
	)
}
