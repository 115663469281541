import React from 'react'

import { Button, Panel } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import CollapseIcon from '@cmpkit/icon/lib/glyph/collapse'
import ExpandIcon from '@cmpkit/icon/lib/glyph/expand'
import Popover from '@cmpkit/popover'

import analytic from '@/services/analytics'

export function PricingAlertsSolutionPopup({
	event,
	children,
}: {
	event: string
	children: React.ReactNode
}) {
	const { isOpen, toggle, close } = useDisclosure()
	const handleClick = () => {
		analytic.logEvent(event)
		toggle()
	}
	const ButtonIcon = isOpen ? CollapseIcon : ExpandIcon
	const Content = () => {
		return (
			<Panel className='cmp-border max-h-80 w-[360px] overflow-hidden'>
				{children}
			</Panel>
		)
	}
	return (
		<Popover
			onDismiss={close}
			placement='left-start'
			isOpen={isOpen}
			content={<Content />}
			fallbackBehaviour='flip'
		>
			<Button
				size='small'
				variant='tertiary'
				className='ml-4'
				onClick={handleClick}
				iconBefore={<ButtonIcon />}
			/>
		</Popover>
	)
}
