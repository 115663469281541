import clsx from 'clsx'
import { find, map, prop, sum } from 'lodash/fp'
import * as qs from 'qs'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { Button, Content, Header, Layout, Result } from '@cmpkit/base'
import Drawer from '@cmpkit/drawer'
import CheckCircleIcon from '@cmpkit/icon/lib/glyph/check-circle'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import { FilterRuleEntity, Operators } from '@cmpkit/query-builder'

import AnchoringAlerts from '@/components/AnchoringAlerts'
import {
	decodeComplexQuery,
	encodeComplexQuery,
} from '@/components/data-grid/helpers'
import ErrorBoundary from '@/components/ErrorBoundary'
import FatalError from '@/components/FatalError'
import {
	AlertAnnotationModel,
	AlertLevel,
	AnchoringAlertModel,
	FilterRuleModel,
} from '@/generated'
import intl from '@/locale'

import {
	useAnchoringAlertsAnnotationsQuery,
	useAnchoringAlertsQuery,
} from '../queries'

export default function AnchoringManagementAlertsDrawer({
	isOpen,
	close,
	drawerClassName,
}: {
	isOpen: boolean
	close: () => void
	drawerClassName?: string
}) {
	return (
		<Drawer
			disableFocusTrap
			className={clsx('cmp-border shadow', drawerClassName)}
			orient='right'
			isOpen={isOpen}
			disableBlanket
		>
			<Layout className='h-full overflow-y-hidden'>
				<Header className='flex justify-between border-b border-solid border-base p-5'>
					<div>
						<h3 className='font-bold'>
							{intl.get('anchors.alerts.drawer.title').d('Anchors alerts')}
						</h3>
					</div>
					<div className='absolute right-5 top-4'>
						<Button
							variant='tertiary'
							onClick={() => close()}
							iconBefore={<CrossIcon />}
						/>
					</div>
				</Header>
				<ErrorBoundary>
					<AnchoringAlertsView close={close} />
				</ErrorBoundary>
			</Layout>
		</Drawer>
	)
}

export function AnchoringAlertsView({ close }: { close(): void }) {
	const navigate = useNavigate()

	const location = useLocation()
	const filters = useMemo(
		(): FilterRuleEntity[] =>
			decodeComplexQuery(
				qs.parse(location.search, { ignoreQueryPrefix: true }).qf as string
			)?.filters || [],
		[location.search]
	)

	const { data: annotations } = useAnchoringAlertsAnnotationsQuery()
	const {
		data: alerts,
		isLoading,
		isError,
	} = useAnchoringAlertsQuery({ filters })
	if (isLoading) {
		return <AlertsListSkeleton close={close} />
	}
	if (isError) {
		return <FatalError />
	}

	const alertsTree = getAnchoringAlertsTree(alerts || [], annotations)
	const handleNavigate = (ids: string[]) => {
		close()
		navigate(
			`/p/assortment/management${queryParamsForGlobalAssortment([
				{ name: 'alerts', operation: Operators.ANY_OF, value: ids },
			])}`
		)
	}
	return (
		<Content className='overflow-y-auto bg-accent-3'>
			<div className='w-full p-5'>
				{!alertsTree?.length ? (
					<Result
						className='flex h-full flex-col items-center justify-center'
						icon={<CheckCircleIcon height={50} width={50} />}
						title='Everything is ok'
					/>
				) : (
					<AnchoringAlerts
						analyticPerfix='alerts: anchoring:'
						alertsTree={alertsTree}
						onNavigate={handleNavigate}
					/>
				)}
			</div>
		</Content>
	)
}
const AlertsListSkeleton = ({ close }: { close(): void }) => (
	<Layout className='h-full overflow-y-hidden'>
		<Header className='flex items-center justify-between border-b border-solid border-base p-5'>
			<div>
				<div className='h-6 w-24 animate-pulse rounded-lg bg-accent-4' />
				<div className='mt-2 h-4 w-72 animate-pulse rounded-lg bg-accent-4' />
			</div>
			<Button
				variant='tertiary'
				onClick={() => close()}
				iconBefore={<CrossIcon />}
			/>
		</Header>
		<div className='flex w-full flex-col justify-center p-5'>
			<div className='w-full animate-pulse rounded-lg bg-accent-3'>
				<div className='h-12 w-full animate-pulse rounded-t-lg bg-accent-4' />
				<div className='h-12 w-full' />
			</div>
			<div className='mt-5 w-full animate-pulse rounded-lg bg-accent-3'>
				<div className='h-12 w-full animate-pulse rounded-t-lg bg-accent-4' />
				<div className='h-12 w-full' />
			</div>
			<div className='mt-5 w-full animate-pulse rounded-lg bg-accent-3'>
				<div className='h-12 w-full animate-pulse rounded-t-lg bg-accent-4' />
				<div className='h-12 w-full' />
			</div>
		</div>
	</Layout>
)
const queryParamsForGlobalAssortment = (filters: FilterRuleModel[]) => {
	return qs.stringify(
		{
			qf: encodeComplexQuery({
				filters,
			}),
		},
		{ addQueryPrefix: true }
	)
}
/* const queryParamsForOgAssortment = (filters: FilterRuleModel[]) => {
	return qs.stringify(rulesToQueryObject(filters), { addQueryPrefix: true, arrayFormat: 'comma' })
} */
/**
 * Get the pricing alerts tree from the alerts and annotations
 * @param pricingAlerts - The alerts
 * @param annotations - The annotations
 * @returns - The pricing alerts tree
 */
export function getAnchoringAlertsTree(
	alerts: AnchoringAlertModel[],
	annotations?: AlertAnnotationModel[]
) {
	return [
		{
			level: 'critical' as AlertLevel,
			total: sum(map(prop('anchors'), alerts)),
			alerts: alerts?.map(({ id, anchors }) => {
				const name = find({ id }, annotations)?.name ?? id
				return {
					id,
					name,
					anchors,
				}
			}),
		},
	]
}
