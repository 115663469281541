import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

export class TokenStorage {
	private static readonly LOCAL_STORAGE_TOKEN = 'access_token'

	/**
	 * Check if the user is authenticated
	 * @returns {boolean}
	 */
	public static isAuthenticated(): boolean {
		return this.getToken() !== null
	}

	/**
	 * Get the authentication header for axios
	 */
	public static getAuthentication() {
		return {
			Authorization: 'Bearer ' + this.getToken(),
		}
	}

	/**
	 * Get a new token from the server and store it
	 * @returns {Promise<string>}
	 */
	public static getNewToken(): Promise<string> {
		return new Promise((resolve, reject) => {
			axios
				.post('/auth/refresh-token/', null, { withCredentials: true })
				.then((response) => {
					this.storeToken(response.data.access_token)
					resolve(response.data.access_token)
				})
				.catch((error) => {
					reject(error)
				})
		})
	}

	/**
	 * Store the token in the local storage
	 * @param token
	 */
	public static storeToken(token: string): void {
		localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token)
	}

	/**
	 * Clear the token from the local storage
	 */
	public static clear(): void {
		localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN)
	}

	/**
	 * Get the token from the local storage or null if it doesn't exist
	 * @returns {string | null}
	 */
	public static getToken(): string | null {
		return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN)
	}

	/**
	 * Check if the token is valid (not expired)
	 * @returns {boolean}
	 */
	public static isValidToken(): boolean {
		const time = new Date().getTime()
		const { exp } = jwtDecode<{
			exp: number
		}>(this.getToken() || '')
		return exp * 1000 - time - 813947 > 900
	}
}
