import { JSONSchemaType } from 'ajv'
import { any, find, isEmpty, merge, prop } from 'lodash/fp'
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import toast from 'react-hot-toast'

import { ajvResolver } from '@hookform/resolvers/ajv'
import { useQueryClient } from '@tanstack/react-query'

import { LinkButton, Loader, treeHelpers, TreeNodeType } from '@cmpkit/base'

import { dialog } from '@/components/dialogs'
import {
	FilterRuleModel,
	OptimizationGroupModel,
	OptimizationModel,
	OptimizationStatus,
	ScheduleModel,
	SettingsTemplateType,
} from '@/generated'
import intl from '@/locale'
import { useAuthorization } from '@/modules/Auth/authorization'
import {
	flattifyProductGroups,
	getSalesEntitiesTree,
	stratifyProductGroups,
} from '@/modules/core/helpers'
import { useCheckOptimizationForDeleteExport } from '@/modules/core/hooks/useCheckOptimizationForDeleteExport'
import useOptimization from '@/modules/core/hooks/useOptimization'
import { useOptimizationIsReadyForChange } from '@/modules/core/hooks/useOptimizationIsReadyForChange'
import { useProductGroupsTreeQuery } from '@/modules/core/hooks/useProductGroupsTreeQuery'
import {
	useDeleteOptimizationGroupMutation,
	useUpdateOptimizationGroupMutation,
} from '@/modules/core/mutations'
import {
	useOptimizationGroupsQuery,
	useSalesEntitiesQuery,
	useSchedulesQuery,
} from '@/modules/core/queries'
import { getScheduleByOptimizationGroupId } from '@/modules/core/utils'
import { useModalStore } from '@/modules/modals/store'
import { waitGroupStatus } from '@/providers/SocketProvider'
import analytic from '@/services/analytics'
import Sentry from '@/services/sentry'
import { useCreateSettingsTemplateMutation } from '@/modules/settings-templates'
import { processingMessage } from '@/tools/message'
import notify from '@/components/toasts/helpers'

import { useOptimizationGroupSchemaQuery } from '../hooks/useOptimizationGroupSchema'
import { useUpdateMainScenarioMutation } from '../mutations'
import { useOptimizationGroupSettingsQuery } from '../queries'
import { OptimizationGroupFormData } from '../types'
import { getSkuRules } from '../utils'

const groupsQueryParams = {
	queryKey: ['optimization-groups'],
}
interface OptimizationGroupItemContextType {
	skuRules: FilterRuleModel[]
	schema?: JSONSchemaType<OptimizationGroupFormData>
	optimizationGroup?: OptimizationGroupModel
	optimization?: OptimizationModel
	schedule?: ScheduleModel
	optimizationGroupId?: string | null
	methods: UseFormReturn<OptimizationGroupFormData>
	isReadyToChange?: boolean
	isLoading: boolean
	isPending: boolean
	canManage?: boolean
	salesEntitiesTree?: TreeNodeType[]
	productGroupsTree?: TreeNodeType[]
	canCopySettings?: boolean
	showCopySettingsModal: () => void
	handleSubmit: () => void
	handleSubmitAndUpdate: () => void
	handleReset: () => void
	handleDelete: () => void
	handleEditInfo: () => void
	handleSaveAsTemplate: () => void
}
export const OptimizationGroupItemContext =
	createContext<OptimizationGroupItemContextType>({
		skuRules: [],
		methods: {} as UseFormReturn<OptimizationGroupFormData>,
		isLoading: false,
		isPending: false,
		showCopySettingsModal: () => {},
		handleSubmit: () => {},
		handleSubmitAndUpdate: () => {},
		handleReset: () => {},
		handleDelete: () => {},
		handleEditInfo: () => {},
		handleSaveAsTemplate: () => {},
	})

export const OptimizationGroupItemProvider = ({
	optimizationGroupId,
	children,
	onFinish,
}: {
	children: React.ReactNode
	optimizationGroupId: string
	onFinish?: () => void
}) => {
	const { showModal } = useModalStore()
	const queryClient = useQueryClient()
	const { checkPermissons } = useAuthorization()
	const canManage = checkPermissons(['MANAGE_OPTIMIZATION_GROUP'])
	const isReadyToChange = useOptimizationIsReadyForChange(optimizationGroupId)
	const { commit, isOptimizationLoading, optimization } =
		useOptimization(optimizationGroupId)
	const checkExport = useCheckOptimizationForDeleteExport(optimizationGroupId)
	const settingsQuery = useOptimizationGroupSettingsQuery(optimizationGroupId)
	const salesEntitiesQuery = useSalesEntitiesQuery()
	const productGroupsTreeQuery = useProductGroupsTreeQuery()
	const salesEntitiesTree = useMemo(
		() => getSalesEntitiesTree(salesEntitiesQuery.data),
		[salesEntitiesQuery.data]
	)

	const productGroupsTree = productGroupsTreeQuery.data
	const schemaQuery = useOptimizationGroupSchemaQuery()
	const optimizationGroupQuery = useOptimizationGroupsQuery<
		OptimizationGroupModel | undefined
	>({
		select: find<OptimizationGroupModel>({ id: optimizationGroupId }),
	})

	const initialSkuRules = useMemo(
		() =>
			settingsQuery.data?.settings?.stop_list
				? getSkuRules(settingsQuery.data.settings.stop_list)
				: [],
		[settingsQuery.data?.settings.stop_list]
	)

	const [skuRules, setSkuRules] = useState(initialSkuRules || [])
	useEffect(() => {
		setSkuRules(initialSkuRules)
	}, [initialSkuRules])
	const optimizationGroup = optimizationGroupQuery.data
	const scheduleQuery = useSchedulesQuery<ScheduleModel | undefined>({
		select: (schedules) =>
			optimizationGroup
				? getScheduleByOptimizationGroupId(schedules, optimizationGroup.id)
				: undefined,
	})
	const methods = useForm<OptimizationGroupFormData>({
		resolver: getValidationResolver(schemaQuery.data!, {
			salesEntitiesTree,
		}),
	})

	useEffect(() => {
		if (
			!isEmpty(salesEntitiesTree) &&
			optimizationGroup &&
			settingsQuery.data
		) {
			const { sales_entities, product_groups } = optimizationGroup
			methods.reset({
				name: optimizationGroup.name,
				sales_entities: treeHelpers.getChildrenIds(
					treeHelpers.findNodes(salesEntitiesTree, sales_entities)
				),
				product_groups: treeHelpers.getChildrenIds(
					treeHelpers.findNodes(
						productGroupsTree,
						flattifyProductGroups(product_groups)
					)
				),
				...settingsQuery.data.settings,
			})
		}
	}, [
		productGroupsTreeQuery.data,
		salesEntitiesTree,
		productGroupsTree,
		optimizationGroup,
		optimizationGroupQuery.data,
		settingsQuery.data,
	])
	const updateMainScenarioMutation = useUpdateMainScenarioMutation(
		optimizationGroupId,
		{
			onSuccess() {
				queryClient.invalidateQueries({ queryKey: ['optimization-groups'] })
				queryClient.invalidateQueries({ queryKey: ['main-scenario'] })
			},
		}
	)
	const deleteOptimizationGroupMutation = useDeleteOptimizationGroupMutation({
		onSuccess() {
			queryClient.invalidateQueries({ queryKey: ['optimization-groups'] })
			queryClient.invalidateQueries({ queryKey: ['main-scenario'] })
		},
	})
	const updateOptimizationGroupMutation =
		useUpdateOptimizationGroupMutation(optimizationGroupId)
	const createTemplateMutation = useCreateSettingsTemplateMutation({
		onMutate() {
			toast.loading(processingMessage(), {
				id: 'saving_template',
			})
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['settings-templates'],
			})
		},
		onSettled() {
			toast.dismiss('saving_template')
		},
	})
	const isLoading =
		any(prop('isLoading'), [
			salesEntitiesQuery,
			productGroupsTreeQuery,
			schemaQuery,
			optimizationGroupQuery,
			createTemplateMutation,
		]) || isOptimizationLoading

	const isPending = any(prop('isPending'), [
		deleteOptimizationGroupMutation,
		updateOptimizationGroupMutation,
		updateMainScenarioMutation,
		createTemplateMutation,
	])
	const getOptimizationGroupPayload = (data: OptimizationGroupFormData) => {
		const { level, selected } = treeHelpers.getCalculatedSelection(
			treeHelpers.findNodes(salesEntitiesTree, data.sales_entities),
			data.sales_entities
		)
		return {
			sales_level_id: level,
			sales_entities: selected.map(prop('id')),
			product_groups: stratifyProductGroups(data.product_groups),
			name: data.name,
			description: data.description,
		}
	}
	const getScenarioPayload = (data: OptimizationGroupFormData) => {
		return {
			settings: {
				strategy: data.strategy,
				business: data.business,
				stop_list: data.stop_list,
			},
		}
	}
	const canCopySettings = checkPermissons([
		'CREATE_PRICING_CAMPAIGNS',
		'EDIT_PRICING_CAMPAIGNS_SETTINGS',
		'EDIT_PRICING_CAMPAIGNS_PRODUCTS',
		'DELETE_PRICING_CAMPAIGNS',
		'CHANGE_DEFAULT_PRICING_CAMPAIGN',
	])
	const showCopySettingsModal = () => {
		analytic.logEvent('settings: copy settings: start')
		showModal('COPY_SETTINGS', {
			optimizationGroupId,
		})
	}
	const handleOpenTemplate = ({
		id,
		template_type,
	}: {
		id: string
		template_type: SettingsTemplateType
	}) => {
		showModal('SETTINGS_TEMPLATE_MODAL', {
			templateId: id,
			templateType: template_type,
		})
	}
	const handleSubmit = methods.handleSubmit(
		checkExport(async (data) => {
			try {
				await updateMainScenarioMutation.mutateAsync(getScenarioPayload(data))
				await waitGroupStatus(optimizationGroupId, [
					OptimizationStatus.New,
					OptimizationStatus.Pending,
				])
				await updateOptimizationGroupMutation.mutateAsync(
					getOptimizationGroupPayload(data)
				)

				queryClient.invalidateQueries(groupsQueryParams)
				methods.reset(methods.watch())
				toast.success(
					intl.get('toast.entity.saved', {
						entity: intl.get('entity.optimization_group'),
					}),
					{
						duration: 5000,
						id: 'optimization-group',
					}
				)
				onFinish?.()
			} catch (error) {
				Sentry.captureException(error)
				toast.error(
					intl.get('toast.entity.failed', {
						entity: intl.get('entity.optimization_group'),
					}),
					{
						duration: 5000,
						id: 'optimization-group',
					}
				)
			}
		})
	)
	const handleSubmitAndUpdate = methods.handleSubmit(
		checkExport(async (data) => {
			try {
				await updateMainScenarioMutation.mutateAsync(getScenarioPayload(data))
				await waitGroupStatus(optimizationGroupId, [
					OptimizationStatus.New,
					OptimizationStatus.Pending,
				])
				await updateOptimizationGroupMutation.mutateAsync(
					getOptimizationGroupPayload(data)
				)
				await commit()
				toast.success(
					intl.get('toast.entity.saved', {
						entity: intl.get('entity.optimization_group'),
					}),
					{
						duration: 5000,
						id: 'optimization-group',
					}
				)
				onFinish?.()
			} catch (error) {
				Sentry.captureException(error)
				toast.error(
					intl.get('toast.entity.failed', {
						entity: intl.get('entity.optimization_group'),
					}),
					{
						duration: 5000,
						id: 'optimization-group',
					}
				)
			}
		})
	)

	const handleReset = () => {
		analytic.logEvent('og settings: reset form')
		methods.reset()
	}
	const handleEditInfo = () => {
		analytic.logEvent('og settings: description modal: open')
		showModal('UPDATE_OPTIMIZATION_GROUP_INFO', {
			optimizationGroupId,
		})
	}
	const handleDelete = async () => {
		const answer = await dialog.confirmDelete({
			title: intl.get('general_detete_confirm_title'),
			text: intl.get('general_detete_confirm_subtitle'),
			okText: intl.get('general_delete'),
		})
		if (answer) {
			analytic.logEvent('og settings: delete og')
			await toast.promise(
				deleteOptimizationGroupMutation.mutateAsync(optimizationGroupId),
				{
					loading: intl.get('deleting.proccess').d('Deleting...'),
					success: intl.get('deleting.success').d('Deleted successfully'),
					error: intl.get('deleting.error').d('Error while deleting'),
				}
			)
		}
	}
	const handleSaveAsTemplate = () => {
		analytic.logEvent('settings: og: dropdown: save og as template')
		createTemplateMutation.mutate(
			{
				template_type: SettingsTemplateType.Scenario,
				name: optimizationGroup?.name || '',
				body: {
					name: optimizationGroup?.name || '',
					settings: {
						strategy: methods.watch('strategy'),
						business: methods.watch('business'),
						stop_list: methods.watch('stop_list'),
					},
				},
			},
			{
				onSuccess: (data) => {
					notify.success({
						text: (
							<div>
								{intl.get('templates').d('Template')}{' '}
								<LinkButton
									variant='brand'
									onClick={() => {
										handleOpenTemplate({
											id: data.id,
											template_type: SettingsTemplateType.Scenario,
										})
									}}
								>
									{data.name}
								</LinkButton>{' '}
								{intl
									.get('template_saved_successfully')
									.d('was saved successfully')}
							</div>
						),

						variant: 'success',
					})
				},
			}
		)
	}
	return (
		<OptimizationGroupItemContext.Provider
			value={{
				canCopySettings,
				canManage,
				skuRules,
				isReadyToChange,
				schema: schemaQuery.data,
				optimizationGroupId,
				optimizationGroup: optimizationGroupQuery.data,
				optimization,
				schedule: scheduleQuery.data,
				methods,
				isLoading,
				isPending,
				salesEntitiesTree,
				productGroupsTree,
				showCopySettingsModal,
				handleSubmit,
				handleSubmitAndUpdate,
				handleReset,
				handleDelete,
				handleEditInfo,
				handleSaveAsTemplate,
			}}
		>
			{isLoading ? (
				<div className='flex min-h-96 items-center justify-center'>
					<Loader />
				</div>
			) : (
				children
			)}
		</OptimizationGroupItemContext.Provider>
	)
}
const getValidationResolver = (
	schema: JSONSchemaType<OptimizationGroupFormData>,
	{
		salesEntitiesTree,
	}: {
		salesEntitiesTree: TreeNodeType[]
	}
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return async (data: OptimizationGroupFormData, ctx: any, options: any) => {
		const { level, selected } = treeHelpers.getCalculatedSelection(
			treeHelpers.findNodes(salesEntitiesTree, data.sales_entities),
			data.sales_entities
		)
		const errors: Record<
			string,
			{
				message: string
			}
		> = {}
		if (data.product_groups.length === 0) {
			errors['product_groups'] = {
				message: 'No product groups selected',
			}
		}
		if (selected.length === 0) {
			errors['sales_entities'] = {
				message: 'No sales points selected',
			}
		}
		if (level === 1 && selected.length > 1) {
			errors['sales_entities'] = {
				message: 'Multiple sales points with sales level 1',
			}
		}

		const baseValidationResult = await ajvResolver(schema, { strict: false })(
			data,
			ctx,
			options
		)

		return merge(baseValidationResult, errors)
	}
}

export const useOptimizationGroupItemContext = () => {
	return useContext(OptimizationGroupItemContext)
}
