import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Col, Row } from '@cmpkit/base'

import PublicLayout from '@/components/layouts/Public'
import Loader from '@/components/Loader'
import { toQueryObject } from '@/tools/location'

import { useMeQuery, useOauthExchange } from '../queries'
import { getLoginUrl } from '../utils'
import ContactLink from './ContactLink'
import CoverBackground from './CoverBackground'

const Redirect = ({ query }: { query: object }) => {
	const navigate = useNavigate()
	const { isLoading, error, data } = useOauthExchange(query)
	if (isLoading) {
		return <Loader size={30} className='overlay fade-in' backdrop opacity />
	} else if (error || !data?.redirect_uri) {
		navigate(getLoginUrl())
		return null
	} else {
		window.location.href = data.redirect_uri
		return (
			<Loader
				size={30}
				className='overlay fade-in'
				label='Redirecting...'
				backdrop
				opacity
			/>
		)
	}
}
export const OAuth2Login = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const query = toQueryObject(location.search)
	const me = useMeQuery()

	if (!(query.response_type && query.client_id)) {
		navigate(getLoginUrl())
		return null
	}
	const isLoading = me.isLoading
	return (
		<PublicLayout title='Auth: Competera'>
			<Row className='h-full overflow-hidden' noGutters>
				<Col lg={6} className='bg-accent-1'>
					<CoverBackground />
				</Col>
				<Col lg={6} className='h-full overflow-y-scroll'>
					<div>
						<div className='m-auto max-w-md p-5 pt-24'>
							<h1 className='text-3xl'>
								Welcome to <b className='text-brand'>Competera</b>
								<br />
								<b className='text-brand'>Pricing Platform</b>
							</h1>
							<p className='pb-12 pt-5 text-muted'>
								Machine Learning & Maths & Big Data to build predictable sales
								and margins
							</p>

							<div className='relative mb-2.5'>
								{me.data && query.response_type && query.client_id && (
									<Redirect query={query} />
								)}
								{isLoading && (
									<Loader
										size={30}
										className='overlay fade-in'
										backdrop
										opacity
									/>
								)}
							</div>
							<ContactLink />
						</div>
					</div>
				</Col>
			</Row>
		</PublicLayout>
	)
}
export default OAuth2Login
