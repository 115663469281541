import { format } from 'date-fns'
import { useCallback, useMemo } from 'react'

import { Button, Card } from '@cmpkit/base'
import {
	createStaticRange,
	DateRangeMenuPanel,
	DatesRange,
	defaultPresets,
	useSelectedPreset,
} from '@cmpkit/calendar'
import { useDisclosure } from '@cmpkit/hooks'
import CalendarIcon from '@cmpkit/icon/lib/glyph/calendar'
import CompareIcon from '@cmpkit/icon/lib/glyph/compare'
import Popover from '@cmpkit/popover'

import { DateFormat } from '@/tools/dates'

import { WidgetPeriods } from '../widgets/types'

type MultiPeriodsSelectProps = {
	periods: WidgetPeriods
	defaultPeriods: WidgetPeriods
	onChangePeriods: (periods: WidgetPeriods) => void
	maxDate?: Date
}
const toRangeValues = (value: {
	start_date: string
	end_date: string
}): DatesRange =>
	[value.start_date, value.end_date].map((v) => new Date(v)) as DatesRange
export default function MultiPeriodsSelect({
	periods,
	defaultPeriods,
	onChangePeriods,
	maxDate = new Date(),
}: MultiPeriodsSelectProps) {
	const values = useMemo(
		() => ({
			main: toRangeValues(periods.main),
			compared: toRangeValues(periods.compared),
		}),
		[periods]
	)
	const defaultValues = useMemo(
		() => ({
			main: toRangeValues(defaultPeriods.main),
			compared: toRangeValues(defaultPeriods.compared),
		}),
		[defaultPeriods]
	)
	const handleMainChanged = useCallback(
		(range: DatesRange) =>
			onChangePeriods({
				compared: {
					start_date: format(new Date(range[0] as Date), DateFormat.system),
					end_date: format(new Date(range[1] as Date), DateFormat.system),
				},
				main: {
					start_date: format(new Date(range[0] as Date), DateFormat.system),
					end_date: format(new Date(range[1] as Date), DateFormat.system),
				},
			}),
		[values]
	)
	const handleComparedChanged = useCallback(
		(range: DatesRange) => {
			onChangePeriods({
				...periods,
				compared: {
					start_date: format(new Date(range[0] as Date), DateFormat.system),
					end_date: format(new Date(range[1] as Date), DateFormat.system),
				},
			})
		},
		[values]
	)

	return (
		<div className='flex items-center space-x-1'>
			<MainPeriodPicker
				value={values.main}
				defaultValue={defaultValues.main}
				setValue={handleMainChanged}
				title={'Main period'}
				maxDate={maxDate}
			/>
			<ComparePeriodPicker
				value={values.compared}
				defaultValue={defaultValues.compared}
				setValue={handleComparedChanged}
				title={'Compared period'}
				maxDate={maxDate}
			/>
		</div>
	)
}

function MainPeriodPicker({
	value,
	setValue,
	title,
	defaultValue,
	maxDate,
}: {
	value: DatesRange
	setValue: (value: DatesRange) => void
	title: string
	defaultValue: DatesRange
	maxDate: Date
}) {
	const { isOpen, close, toggle } = useDisclosure()
	const staticRanges = createStaticRange(defaultPresets)

	const selected = useSelectedPreset({
		value,
		staticRanges,
	})

	return (
		<Popover
			placement='bottom-start'
			isOpen={isOpen}
			onDismiss={close}
			content={
				<Card className='flex flex-col border shadow'>
					<div className='border-b px-4 py-2 text-xs font-semibold'>
						{title}
					</div>
					<DateRangeMenuPanel
						value={value}
						maxDate={maxDate}
						defaultValue={defaultValue}
						defaultPresets={staticRanges}
						onChange={(range) => {
							setValue(range)
							close()
						}}
					/>
				</Card>
			}
		>
			<Button onClick={toggle} iconBefore={<CalendarIcon />}>
				{selected?.label || 'Select dates'}
			</Button>
		</Popover>
	)
}
function ComparePeriodPicker({
	value,
	setValue,
	title,
	defaultValue,
	maxDate,
}: {
	value: DatesRange
	setValue: (value: DatesRange) => void
	title: string
	defaultValue: DatesRange
	maxDate: Date
}) {
	const { isOpen, close, toggle } = useDisclosure()
	const staticRanges = createStaticRange(defaultPresets)
	const selected = useSelectedPreset({
		value,
		staticRanges,
	})
	return (
		<Popover
			placement='bottom-start'
			isOpen={isOpen}
			onDismiss={close}
			content={
				<Card className='flex flex-col border shadow'>
					<div className='border-b px-4 py-2 text-xs font-semibold'>
						{title}
					</div>
					<DateRangeMenuPanel
						value={value}
						maxDate={maxDate}
						defaultValue={defaultValue}
						defaultPresets={staticRanges}
						onChange={(range) => {
							setValue(range)
							close()
						}}
					/>
				</Card>
			}
		>
			<Button variant='tertiary' onClick={toggle} iconBefore={<CompareIcon />}>
				{selected?.label || 'Select dates'}
			</Button>
		</Popover>
	)
}
