import { filter, map, paths, pipe } from 'lodash/fp'

import { OptimizationModel } from '@/generated'
import { client } from '@/network/client'

import { LOCKED, NEW, UPDATING } from './constants'

export default class CoreService {
	static async resetOptimizations(
		optimizations: Record<string, OptimizationModel>,
		groupsIds: string[]
	) {
		const resetOptimizations = pipe([
			paths(groupsIds),
			filter(({ status }) => ![NEW, LOCKED, UPDATING].includes(status)),
			map(({ optimization_group_id }) =>
				client.core.createOptimization(optimization_group_id)
			),
		])
		return await Promise.all(resetOptimizations(optimizations))
	}
}
