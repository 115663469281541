/* eslint-disable @typescript-eslint/no-explicit-any */
import Channel from './channel'

/**
 * Initializes the Push
 * @param {Channel} channel - The Channel
 * @param {string} event - The event, for example `"phx_join"`
 * @param {Object} payload - The payload, for example `{user_id: 123}`
 * @param {number} timeout - The push timeout in milliseconds
 */
export default class Push {
	channel: Channel
	event: string
	payload: any
	receivedResp: any
	timeout: number
	timeoutTimer?: any
	recHooks: any[]
	sent: boolean
	ref?: any
	refEvent?: any
	constructor(channel: Channel, event: string, payload: any, timeout: number) {
		this.channel = channel
		this.event = event
		this.payload =
			payload ||
			function () {
				return {}
			}
		this.receivedResp = null
		this.timeout = timeout
		this.timeoutTimer = null
		this.recHooks = []
		this.sent = false
	}

	/**
	 *
	 * @param {number} timeout
	 */
	resend(timeout: number) {
		this.timeout = timeout
		this.reset()
		this.send()
	}

	/**
	 *
	 */
	send() {
		if (this.hasReceived('timeout')) {
			return
		}
		this.startTimeout()
		this.sent = true
		this.channel.socket.push({
			topic: this.channel.topic,
			event: this.event,
			payload: this.payload(),
			ref: this.ref,
		})
	}

	/**
	 *
	 * @param {*} status
	 * @param {*} callback
	 */
	receive(status: string, callback: (r: any) => void) {
		if (this.hasReceived(status)) {
			callback(this.receivedResp.response)
		}

		this.recHooks.push({ status, callback })
		return this
	}

	/**
	 * @private
	 */
	reset() {
		//this.cancelRefEvent()
		this.ref = null
		this.refEvent = null
		this.receivedResp = null
		this.sent = false
	}

	/**
	 * @private
	 */
	matchReceive({ status, payload }: { status: string; payload: any }) {
		this.recHooks
			.filter((h) => h.status === status)
			.forEach((h) => h.callback(payload))
	}

	/**
	 * @private
	 */
	cancelTimeout() {
		clearTimeout(this.timeoutTimer)
		this.timeoutTimer = null
	}

	/**
	 * @private
	 */
	startTimeout() {
		if (this.timeoutTimer) {
			this.cancelTimeout()
		}
		this.ref = this.channel.socket.makeRef()
		this.refEvent = this.channel.replyEventName(this.ref)
		this.channel.once(
			[this.event, this.ref].filter(Boolean).join(':'),
			(message: any) => {
				this.cancelTimeout()
				this.receivedResp = message
				this.matchReceive(message)
			}
		)

		this.timeoutTimer = setTimeout(() => {
			this.trigger('timeout', {})
		}, this.timeout)
	}

	/**
	 * @private
	 */
	hasReceived(status: string) {
		return this.receivedResp && this.receivedResp.status === status
	}

	/**
	 * @private
	 */
	trigger(status: string, message: any) {
		this.channel.trigger(this.refEvent, { status, message })
	}
}
