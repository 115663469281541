import { Operators } from '@cmpkit/query-builder'

import intl from '@/locale'

import { ControllerConfig, ValueState } from '../../types'
import { isObject, objectMap } from '../../utils'
import FieldController from '../Controller'

export default class NumberController extends FieldController {
	constructor(config: ControllerConfig) {
		super(config)
		this.note = config.note
		this.validate = config.validate || this.defaultValidate
	}

	note?: string

	formatLabel = () => {
		return this.label
	}

	hasValue = ({ operation, value }: ValueState) => {
		if (operation === Operators.IS_EMPTY) return true

		let bool = typeof value === 'number'

		if (isObject(value)) {
			Object.values(value).forEach((v) => {
				bool = typeof v === 'number'
			})
		}

		return bool
	}

	getInitialValue = () => ({
		operation: Operators.EQ,
		value: '',
	})

	getFilterTypes = () => [
		{
			operation: Operators.EQ,
			label: intl.get('eq.long').d('equal'),
			hasInput: true,
		},
		{
			operation: Operators.NE,
			label: intl.get('ne.long').d('not equal'),
			hasInput: true,
		},
		{
			operation: Operators.GT,
			label: intl.get('gt.long').d('greater than'),
			hasInput: true,
		},
		{
			operation: Operators.GTE,
			label: intl.get('gte.long').d('greater or equal than'),
			hasInput: true,
		},
		{
			operation: Operators.LT,
			label: intl.get('lt.long').d('less than'),
			hasInput: true,
		},
		{
			operation: Operators.LTE,
			label: intl.get('lte.long').d('less or equal than'),
			hasInput: true,
		},
		{
			operation: Operators.BETWEEN,
			label: intl.get('num_between.long').d('between'),
			hasInput: true,
		},
		{
			operation: Operators.IS_EMPTY,
			label: intl.get('is_empty.long').d('has no value'),
		},
		{
			operation: Operators.IS_NOT_EMPTY,
			label: intl.get('is_not_empty.long').d('has value'),
		},
	]

	defaultValidate = ({
		operation,
		value,
	}:
		| ValueState<{
				lt: number
				gt: number
		  }>
		| ValueState<string>) => {
		let result: string | null = null
		const nameMap = { lt: 'to', gt: 'from' }

		if (operation === Operators.IS_EMPTY) {
			return result
		}

		if (isObject(value) && value) {
			const betweenValue = value as {
				lt: number
				gt: number
			}
			// make sure both values are present
			if (betweenValue.lt === undefined || betweenValue.gt === undefined) {
				return intl
					.get('validation.both_inputs_required')
					.d('Both inputs are required.')
			}
			if (betweenValue.lt < 0 || betweenValue.gt < 0) {
				return intl
					.get('validation.both_only_positive')
					.d('Only positive values are available.')
			}

			// check for a valid range
			if (betweenValue.lt <= betweenValue.gt) {
				return intl
					.get('validation.invalid_between_range')
					.d('Invalid range; the second input must be greater than the first.')
			}

			objectMap(betweenValue, (val: string, key: string) => {
				const r = validateInput(this.label, val, nameMap[key as 'lt' | 'gt'])
				if (r) result = r
				return null
			})
		} else {
			result = validateInput(this.label, value as string)
		}

		return result
	}
}

const validateInput = (label: string, _value: string, name?: string) => {
	let result: string | null = null
	const prefix = name ? `${label} "${name}"` : label
	const value = Number(_value)
	if (_value === '' || _value === null || _value === undefined) {
		result = `${prefix} ${intl.get('validation.must_be_number').d('must be a number')}`
	} else if (Number.isNaN(value)) {
		result = `${prefix} ${intl.get('validation.must_be_number').d('must be a number')}`
	} else if (!Number.isFinite(value)) {
		result = `${prefix} ${intl.get('validation.must_be_whole_number').d('must be a whole number')}`
	} else if (value < 0) {
		result = `${prefix} ${intl.get('validation.must_be_positive_number').d('must be a positive number')}`
	}

	return result
}
