import { MetricModel } from '@/generated'

export type {
	BIRequest,
	BIResponse,
	BIAIRequest,
	MetricModel,
} from '@/generated'
export enum DateAggregation {
	DateKey = 'date_key',
	WeekKey = 'week_key',
	MonthKey = 'month_key',
}
export type BIDimmensions =
	| 'product_id'
	| 'brand'
	| 'category_1_lvl_id'
	| 'optimization_group'
	| 'optimization_group_id'
	| 'product_group'
	| 'product_group_id'
	| 'pricing_campaign_name'
	| 'pricing_campaign_engine'

export type MetricOption = {
	label: MetricModel['name']
	value: MetricModel['name']
	type: MetricModel['type']
	color: string
}

export type MetricsDropDown = {
	className?: string
	value?: string
	values?: string[]
	options: MetricOption[]
	onClear?: () => void
}
