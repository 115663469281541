import { indexBy, map, prop, uniq } from 'lodash/fp'
import React, { useMemo } from 'react'

import { Col, Row, treeHelpers, TreeNodeType } from '@cmpkit/base'

import { CategoryModel, ProductGroupModel, SalesLevelModel } from '@/generated'
import intl from '@/locale'

import { stratifyProductGroups } from '../../helpers'

type State = {
	selectedSalesEntities: string[]
	selectedProductGroups: string[]
}
type CheckDataViewProps = {
	state: State
	salesLevels?: SalesLevelModel[]
	salesEntitiesTree: TreeNodeType[]
	productGroups?: ProductGroupModel[]
	productGroupsTree: TreeNodeType[]
	categories?: CategoryModel[]
}
export default function CheckDataView({
	state,
	salesLevels,
	salesEntitiesTree,
	productGroups,
	productGroupsTree,
	categories,
}: CheckDataViewProps) {
	const salesEntitiesSummary = useMemo(() => {
		const statistics = treeHelpers.getStatisticsByLevels(
			treeHelpers.findNodes(salesEntitiesTree, state.selectedSalesEntities)
		)
		return salesLevels
			?.map((salesLevel: SalesLevelModel) => ({
				...salesLevel,
				statistics: statistics[salesLevel.level] || [],
			}))
			.filter((item) => item.statistics.length)
	}, [])
	const productGroupsSummary = useMemo(() => {
		const statistics = treeHelpers.getCalculatedSelection(
			treeHelpers.findNodes(productGroupsTree, state.selectedProductGroups),
			state.selectedProductGroups
		)
		const summary = stratifyProductGroups(statistics.selected.map(prop('id')))
		const productGroupsMap = indexBy('id', productGroups)
		const categoriesMap = indexBy('id', categories)
		return [
			{
				name: intl.get('product_groups'),
				statistics: summary.map(
					(item) => productGroupsMap[item.product_group_id]
				),
			},
			{
				name: intl.get('field_schema_categories'),
				statistics: uniq(map(prop('filters[0].value'), summary).flat()).map(
					(id: string) => categoriesMap[id]
				),
			},
		]
	}, [])

	return (
		<div className={'relative flex flex-col overflow-y-auto px-5 py-4'}>
			<Row>
				<Col sm='4'>
					<span className='font-medium'>{intl.get('sales_entities')}</span>
					<SummaryList summary={salesEntitiesSummary || []} />
				</Col>
				<Col className='overflow-hidden'>
					<span className='font-medium'>{intl.get('product_groups')}</span>
					<SummaryList summary={productGroupsSummary || []} />
				</Col>
			</Row>
		</div>
	)
}

const SummaryList = ({
	summary,
}: {
	summary: {
		statistics: TreeNodeType[] | CategoryModel[] | ProductGroupModel[]
		level?: number
		name: string
	}[]
}) => (
	<div className='mt-2 space-y-1'>
		{summary
			.filter((item) => item.statistics?.length)
			.map((item) => (
				<p className='ml-2' key={item.name}>
					{item.name}: {item.statistics.map(({ name }) => name).join(', ')}
				</p>
			))}
	</div>
)
