import React, { useEffect } from 'react'

import { Button } from '@cmpkit/base'
import RefreshIcon from '@cmpkit/icon/lib/glyph/refresh'

import crashImg from '@/assets/img/svg/crashed_block_placeholder.svg'
import intl from '@/locale'
import analytic from '@/services/analytics'

import EmptyState from './placeholders/EmptyState'

export default function FatalError(props: { resetError?: () => void }) {
	useEffect(() => {
		analytic.logEvent('error: error displayed')
	}, [])
	return (
		<div className='my-2 flex flex-auto items-center justify-center'>
			<EmptyState
				imageUrl={crashImg}
				imgClasses='max-h-80 p-3'
				wrapperClasses='fade-in'
			>
				<div className='text-center'>
					<p className='text-lg font-bold'>{intl.get('fatal_error_title')}🐞</p>
					<p className='text-muted'>{intl.get('fatal_error_desc')}</p>
					<div className='mt-5 flex items-center justify-center space-x-2'>
						{!!props.resetError && (
							<Button variant='tertiary' onClick={() => props.resetError?.()}>
								Retry
							</Button>
						)}
						<Button
							iconBefore={<RefreshIcon />}
							onClick={() => window.location.reload()}
						>
							Refresh the page
						</Button>
					</div>
				</div>
			</EmptyState>
		</div>
	)
}
