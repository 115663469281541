import { filter, pipe, prop, propEq, reduce } from 'lodash/fp'

import { DeltaValue, InlineMessage } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import LabeledValue from '@/components/LabeledValue'
import { FilterRuleModel, OptimizationIDModel } from '@/generated'
import intl from '@/locale'
import { useRepricingStatisticQuery } from '@/modules/core/queries'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { MetricName } from '../../constants'

export const FinancialInsightsWidget = ({
	queryParams,
	isLoading: isDataLoading,
}: {
	queryParams: {
		optimizations: OptimizationIDModel[]
		filters: FilterRuleModel[]
	} | null
	isLoading: boolean
}) => {
	/**Queries */
	const {
		data: repricingStatistic,
		isLoading,
		isError,
		error,
	} = useRepricingStatisticQuery(queryParams!, {
		enabled: !!queryParams && !isDataLoading,
		refetchOnMount: true,
		staleTime: 0,
		select: pipe(
			prop('metrics'),
			filter(propEq('group_type', null)),
			reduce((acc: { [key: string]: number }, item) => {
				acc[item.name] = item.value
				return acc
			}, {})
		),
	})

	if (isLoading || isDataLoading) {
		return <div className='h-10 animate-pulse rounded bg-accent-4' />
	}
	if (isError) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger' className='w-full'>
				{error.message || intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}
	return (
		repricingStatistic &&
		!isLoading &&
		!isDataLoading && (
			<div className='flex justify-between'>
				<LabeledValue label={intl.get('opt.summary.new_disc').d('New Disc.')}>
					<span className='text-lg font-semibold'>
						{formatNumber(
							repricingStatistic[MetricName.DiscountFinal] || 0,
							NumberFormats.Percent
						)}
					</span>
					<DeltaValue
						className='ml-1 text-xs'
						value={getDeltaValue({
							current: repricingStatistic[MetricName.DiscountInit] || 0,
							new: repricingStatistic[MetricName.DiscountFinal] || 0,
						})}
					>
						{formatNumber(
							getDeltaValue({
								current: repricingStatistic[MetricName.DiscountInit] || 0,
								new: repricingStatistic[MetricName.DiscountFinal] || 0,
							}) || 0,
							NumberFormats.PercentSigned
						)}
					</DeltaValue>
				</LabeledValue>
				<LabeledValue
					label={intl.get('opt.summary.current_disc').d('Current Disc.')}
				>
					<span className='text-lg font-semibold'>
						{formatNumber(
							repricingStatistic[MetricName.DiscountInit] || 0,
							NumberFormats.Percent
						)}
					</span>
				</LabeledValue>
				<LabeledValue
					label={intl.get('opt.summary.new_margin').d('New Margin')}
				>
					<span className='text-lg font-semibold'>
						{formatNumber(
							repricingStatistic[MetricName.MarginFinal] || 0,
							NumberFormats.Percent
						)}
					</span>
					<DeltaValue
						className='ml-1 text-xs'
						value={getDeltaValue({
							current: repricingStatistic[MetricName.MarginInit] || 0,
							new: repricingStatistic[MetricName.MarginFinal] || 0,
						})}
					>
						{formatNumber(
							getDeltaValue({
								current: repricingStatistic[MetricName.MarginInit] || 0,
								new: repricingStatistic[MetricName.MarginFinal] || 0,
							}) || 0,
							NumberFormats.PercentSigned
						)}
					</DeltaValue>
				</LabeledValue>
				<LabeledValue
					label={intl.get('opt.summary.current_margin').d('Current Margin')}
				>
					<span className='text-lg font-semibold'>
						{formatNumber(
							repricingStatistic[MetricName.MarginInit] || 0,
							NumberFormats.Percent
						)}
					</span>
				</LabeledValue>
			</div>
		)
	)
}

const getDeltaValue = (values: { current: number; new: number }) =>
	Number(values.new) - Number(values.current) || 0
