import React, { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { Button, InlineMessage } from '@cmpkit/base'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import {
	Modal,
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import ErrorBoundary from '@/components/ErrorBoundary'
import { OptimizationModel, PricingCampaignReassignRequest } from '@/generated'
import useStep from '@/hooks/useStep'
import intl from '@/locale'
import CoreService from '@/modules/core/service'

import { useReassignPricingCampaignsMutation } from '../../mutations'
import SelectOptimizationGroupView from '../SelectOptimizationGroupView'
import Checkout from './CheckoutView'
import ExecutionView from './ExecutionView'

const getModalHead = (step: number) => {
	switch (step) {
		case 1:
			return {
				title: intl.get('pc.modal.reassign_1.title'),
				subtitle: intl.get('pc.modal.reassign_1.subtitle'),
			}
		case 2:
			return {
				title: intl.get('pc.modal.reassign_2.title'),
				subtitle: intl.get('pc.modal.reassign_2.subtitle'),
			}

		default:
			return {
				title: intl.get('pc.modal.reassign_3.title'),
				subtitle: intl.get('pc.modal.reassign_3.subtitle'),
			}
	}
}
type Props = {
	isOpen: boolean
	close(): void
	selected: string[]
	resetSelectedItems?(): void
}
export default function MovePricingCampaigns({
	isOpen,
	close,
	selected: selectedCampaigns,
	resetSelectedItems,
}: Props) {
	const [selectedGroup, setSelectedGroup] = useState<string | null>(null)
	const [currentStep, helpers] = useStep({ maxStep: 3 })
	const queryClient = useQueryClient()
	const reassign = useReassignPricingCampaignsMutation({
		mutationKey: ['reassignPricingCampaigns'],
		async onMutate(variables: PricingCampaignReassignRequest) {
			const optimizations = queryClient.getQueryData<
				Record<string, OptimizationModel>
			>(['optimizations'])
			await CoreService.resetOptimizations(optimizations!, [
				variables.dst_optimization_group_id,
			])
		},
		onSuccess() {
			resetSelectedItems?.()
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns-segments'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricings-settings'],
			})
			queryClient.invalidateQueries({
				queryKey: ['products-counts-by-pc'],
			})
		},
	})
	const { canGoToPrevStep, canGoToNextStep, goToNextStep, goToPrevStep } =
		helpers
	const { title, subtitle } = getModalHead(currentStep)
	const formData = {
		campaigns: selectedCampaigns,
		groups: selectedGroup ? [selectedGroup] : [],
	}
	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={close} showCloseButton size='large'>
					<ModalHeader>
						<ModalTitle>{title}</ModalTitle>
						<ModalDescription>{subtitle}</ModalDescription>
					</ModalHeader>
					<ErrorBoundary>
						{currentStep === 1 && (
							<ModalBody className='space-y-3'>
								<SelectOptimizationGroupView
									selectedGroup={selectedGroup}
									setSelectedGroup={setSelectedGroup}
								/>
								<InlineMessage
									icon={<InfoIcon />}
									variant='brand'
									className='text-xs font-medium'
								>
									{intl.get('note.copy_pc_general_note')}
								</InlineMessage>
							</ModalBody>
						)}
						{currentStep === 2 && (
							<ModalBody className='space-y-3'>
								<Checkout formData={formData} />
								<InlineMessage
									icon={<InfoIcon />}
									variant='brand'
									className='text-xs font-medium'
								>
									{intl.get('note.copy_pc_general_note')}
								</InlineMessage>
							</ModalBody>
						)}
						{currentStep === 3 && (
							<ModalBody className='space-y-3'>
								<ExecutionView formData={formData} run={reassign} />
							</ModalBody>
						)}
					</ErrorBoundary>
					<ModalFooter>
						{canGoToNextStep && (
							<Button
								disabled={reassign.isPending || reassign.isSuccess}
								className='mr-auto'
								onClick={close}
							>
								{intl.get('general_cancel')}
							</Button>
						)}
						{canGoToPrevStep && (
							<Button
								disabled={reassign.isPending || reassign.isSuccess}
								onClick={goToPrevStep}
								iconBefore={<CaretLeftIcon />}
							>
								{intl.get('general_back')}
							</Button>
						)}
						{canGoToNextStep && (
							<Button
								variant='primary-brand'
								disabled={currentStep === 1 && !selectedGroup}
								onClick={goToNextStep}
								iconAfter={<CaretRightIcon />}
							>
								{currentStep === 2
									? intl.get('app.reassign')
									: intl.get('general_next')}
							</Button>
						)}
						{!canGoToNextStep && (
							<Button
								variant='primary-brand'
								disabled={reassign.isPending}
								onClick={close}
							>
								{intl.get('general_close_and_finish')}
							</Button>
						)}
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}
