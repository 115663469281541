import { any, orderBy, prop } from 'lodash/fp'
import { useMemo, useState } from 'react'

import { Column } from '@cmpkit/data-table'
import { ValueType } from '@cmpkit/query-builder'

import { FilterRuleModel } from '@/generated'
import intl from '@/locale'
import { useBIAnalyticQuery } from '@/modules/bi/queries'
import { comparePeriods } from '@/modules/bi/utils'
import { ColumnConfig } from '@/modules/exports/types'
import analytic from '@/services/analytics'

import MetricPerfomanceHeader from '../../MetricPerfomanceHeader'
import TableView from '../../TableView'
import { WidgetPeriods, WidgetProps } from '../types'
import getColumns from './columns'

const metrics = [
	'sales_items',
	'revenue',
	'gross_profit',
	'gross_profit_margin',
]
const getRowId = prop('optimization_group')

const getReportSchema = (columns: Column[]): ColumnConfig[] => [
	...columns.map(({ key, title }) => ({
		key: key === 'name' ? 'optimization_group' : key,
		type: key === 'name' ? ValueType.str : ValueType.number,
		name: title,
		custom_name: title,
		static_value: null,
		enabled: true,
	})),
]
const getExportName = (name: string, periods: WidgetPeriods): string =>
	`${name} ${periods.main.start_date}-${periods.main.end_date} -> ${periods.compared.start_date}-${
		periods.compared.end_date
	}`

export default function MetricsByOptimizationGroup({
	widget,
	params,
	onChangeParams,
	commonPeriods,
	commonFilters,
}: WidgetProps) {
	const [localPeriods, setLocalPeriods] = useState<WidgetPeriods | null>(null)
	const defaultPeriods = commonPeriods
	/**
	 * Common Query Parameters
	 */
	const commonQueryParams = {
		label: widget.kind,
		metrics,
		dimensions: ['optimization_group'],
		date_aggregation: null,
		filters: [...commonFilters, ...(params.filters ?? [])],
	}

	/**
	 * Queries
	 */
	const mainPeriodQuery = useBIAnalyticQuery({
		...(localPeriods ? localPeriods?.main : commonPeriods.main),
		...commonQueryParams,
	})

	const comparisonPeriodQuery = useBIAnalyticQuery({
		...(localPeriods ? localPeriods?.compared : commonPeriods.compared),
		...commonQueryParams,
	})

	/**
	 * Calculated Values
	 */
	const isLoading = any(prop('isLoading'), [
		mainPeriodQuery,
		comparisonPeriodQuery,
	])
	const columns = useMemo(() => getColumns({ metrics }), [])
	const fields = getReportSchema(columns)
	const rows = comparePeriods(
		orderBy([0], ['asc'], mainPeriodQuery?.data?.data),
		orderBy([0], ['asc'], comparisonPeriodQuery?.data?.data),
		mainPeriodQuery?.data?.meta,
		getRowId
	)
	const name = getExportName(
		intl.get('widget.metric_by_og.title'),
		localPeriods || commonPeriods
	)
	/**
	 * Handlers
	 */
	const handleFilterChange = (filters: FilterRuleModel[]) => {
		analytic.logEvent(`dashboard: bi: apply filter ${widget.kind}`, {
			'filter by': filters.map(prop('name')).join(','),
		})
		onChangeParams({ ...params, filters })
	}

	return (
		<div className='flex-auto space-y-3'>
			<h3>{intl.get('widget.metric_by_og.title')}</h3>
			<MetricPerfomanceHeader
				data={{ fields, rows, name, kind: 'metric_by_og' }}
				params={params}
				commonFilters={commonFilters}
				onFilterChange={handleFilterChange}
				periods={localPeriods || commonPeriods}
				setPeriods={setLocalPeriods}
				defaultPeriods={defaultPeriods}
			/>
			<TableView
				rows={rows}
				columns={columns}
				isLoading={isLoading}
				getRowId={getRowId}
			/>
		</div>
	)
}
