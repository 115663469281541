import { useMemo, useState } from 'react'

import { ColumnSchemaModel, FilterRuleModel } from '@/generated'

/**
 * Function to fix filter queries
 * Temporary solution, will be removed after BE fix
 * @deprecated
 * @param filters
 */
export function fixQuery(filters: FilterRuleModel[]) {
	return filters?.map((filter) => {
		if (filter.operation === 'date') {
			return {
				...filter,
				value: (filter.value as string[])?.join(','),
			}
		}
		return filter
	})
}

/**
 * Function to decode complex query from url
 * @param encodedString - encoded string to decode
 * @returns - decoded object
 */
export function decodeComplexQuery(encodedString: string) {
	try {
		return JSON.parse(decodeURIComponent(atob(encodedString)))
	} catch (e) {
		return undefined
	}
}

/**
 * Function to encode complex query to url
 * @param decodedObject - decoded object to encode
 * @returns - encoded string
 */
export function encodeComplexQuery(decodedObject: object) {
	try {
		return btoa(encodeURIComponent(JSON.stringify(decodedObject)))
	} catch (e) {
		return undefined
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TableChanges<TVal extends any = any, TRow extends any = any> {
	/**
	 * Function to handle change in table cell
	 * @param changeEvent - change event object with row, column schema and value
	 */
	onChange(changeEvent: {
		schema: ColumnSchemaModel
		row: TRow
		value: TVal
	}): void
	/**
	 * Get changes for row and column schema
	 * @param row - row to get changes for
	 * @param columnSchema - column schema to get changes for
	 * @returns - changes value or undefined if no changes
	 */
	getChanges(
		row: TRow,
		columnSchema: ColumnSchemaModel
	): Partial<TRow> | undefined

	/**
	 * Check if there are any changes in table cell
	 * @param row - row to check changes for
	 * @param columnSchema - column schema to check changes for
	 */
	hasChanges(row: TRow, columnSchema: ColumnSchemaModel): boolean
	/**
	 * Get snapshot of changes in table
	 */
	getSnapshot(): Partial<TRow>
	/**
	 * Reset changes in table
	 */
	reset(): void
	/**
	 * Flag to indicate if there are any changes in the table
	 */
	isDirty: boolean
}
/**
 * Hook for tracking changes in table cells
 * @param getRowId - function to get row id from row object
 * @returns - TableChanges object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useTableChanges(getRowId: (row: any) => string): TableChanges {
	const [storage, setStorage] = useState(new Map())
	const onChange: TableChanges['onChange'] = ({ row, schema, value }) => {
		const key = getRowId(row)
		const rowOriginalValue = row?.[schema.name]
		setStorage((prevStorage) => {
			const storage = new Map(prevStorage)
			if (storage.has(key)) {
				const prev = new Map(storage.get(key))
				if (rowOriginalValue === value) {
					prev.delete(schema.name)
					if (prev.size === 0) {
						storage.delete(key)
					} else {
						storage.set(key, prev)
					}
				} else {
					prev.set(schema.name, value)
					storage.set(key, prev)
				}
			} else {
				const newValue = new Map()
				newValue.set(schema.name, value)
				storage.set(key, newValue)
			}

			return storage
		})
	}

	const getChanges: TableChanges['getChanges'] = (row, schema) => {
		const key = getRowId(row)
		if (storage.has(key)) {
			return storage.get(key).get(schema.name)
		}
		return undefined
	}
	const hasChanges: TableChanges['hasChanges'] = (row, schema) => {
		const key = getRowId(row)
		if (storage.has(key)) {
			return storage.get(key).has(schema.name)
		}
		return false
	}
	const reset = () => setStorage(new Map())
	const getSnapshot = () => {
		const snapshot: Record<string, any> = {} // eslint-disable-line @typescript-eslint/no-explicit-any
		storage.forEach((value, key) => {
			snapshot[key] = Object.fromEntries(value)
		})
		return snapshot
	}
	return useMemo(
		() => ({
			onChange,
			getChanges,
			hasChanges,
			getSnapshot,
			reset,
			isDirty: storage.size > 0,
		}),
		[storage]
	)
}
