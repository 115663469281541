import '@/config'
import '@/components/Chart/highcharts.config'
import '@/assets/styles/style.css'

import React from 'react'
import { createRoot } from 'react-dom/client'

import Storage from '@/services/local-storage'

import App from './app'

if (document && document.body) {
	document.body.setAttribute('data-theme', Storage.get('theme') || 'light')
}
if (process.env?.MOCK_ENABLED) {
	import('./mocks/index').then(({ worker }) => worker.start())
}
const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
root.render(<App />)
