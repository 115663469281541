import { filter, map, pipe, prop, size, uniq } from 'lodash/fp'
import React, { useEffect } from 'react'

import { UseMutationResult } from '@tanstack/react-query'

import { Result } from '@cmpkit/base'

import dataWarn from '@/assets/img/svg/data_proccesing_warning.svg'
import dataUpdating from '@/assets/img/svg/data_updating_proccess.svg'
import dataResult from '@/assets/img/svg/result.svg'
import { PricingCampaignDuplicateRequest } from '@/generated'
import intl from '@/locale'

import { PricingCampaignDuplicationResultModel } from '../../types'
import PricingCampaignManagementResults from '../PricingCampaignManagementResults'

type Props = {
	formData: {
		campaigns: string[]
		groups: string[]
	}
	run: UseMutationResult<
		PricingCampaignDuplicationResultModel[],
		Error,
		PricingCampaignDuplicateRequest,
		unknown
	>
}
const isAllSuccess = (data: PricingCampaignDuplicationResultModel[]) => {
	return data?.every(prop('success'))
}
const getResultCounts = (data: PricingCampaignDuplicationResultModel[]) => {
	return {
		campaigns: pipe([
			filter(prop('success')),
			map(prop('pricing_campaign_id')),
			uniq,
			size,
		])(data),
		groups: pipe([
			filter(prop('success')),
			map(prop('optimization_group_id')),
			uniq,
			size,
		])(data),
	}
}
export default function ExecutionView({ formData, run }: Props) {
	useEffect(() => {
		const requestBody: PricingCampaignDuplicateRequest = {
			src_pricing_campaign_ids: formData.campaigns,
			dst_optimization_group_ids: formData.groups,
		}
		run.mutateAsync(requestBody)
	}, [])
	if (run.isPending) {
		return (
			<Result
				icon={<img src={dataUpdating} />}
				className='flex h-full flex-col justify-center py-10'
				title={intl.get('pc.processing.loading.title')}
			/>
		)
	} else if (run.isError) {
		return (
			<Result
				icon={<img src={dataWarn} />}
				className='flex h-full flex-col justify-center py-10'
				title={intl.get('pc.processing.error.title')}
			/>
		)
	} else {
		if (isAllSuccess(run.data!)) {
			return (
				<Result
					icon={<img src={dataResult} />}
					className='flex h-full flex-col justify-center py-10'
					title={intl.get('pc.processing.success.title', {
						action: 'copied',
						campaigns: formData.campaigns.length,
						groups: formData.groups.length,
					})}
				/>
			)
		} else {
			const responseCounts = getResultCounts(run.data!)
			return (
				<Result
					icon={<img src={dataResult} />}
					className='flex h-full flex-col justify-center py-10'
					title={intl.get('pc.processing.indeterminate.title', {
						action: 'dublicate',
						campaigns: responseCounts.campaigns,
						totalCampaigns: formData.campaigns.length,
						groups: responseCounts.groups,
						totalGroups: formData.groups.length,
					})}
				>
					<PricingCampaignManagementResults response={run?.data} />
				</Result>
			)
		}
	}
}
