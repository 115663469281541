import React from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import { FormError, FormGroup, FormLabel, Input, Required } from '@cmpkit/base'
import HelpCircleIcon from '@cmpkit/icon/lib/glyph/help-circle'
import Tooltip from '@cmpkit/tooltip'

export default function RHFFileInput({
	name,
	label,
	hint,
	defaultValue,
	shouldUnregister,
	required,
	...other
}: UseControllerProps & {
	label?: string
	hint?: string
	required?: boolean
	className?: string
}) {
	const { control } = useFormContext()
	return (
		<FormGroup>
			<FormLabel>
				{label}
				{required && <Required />}
			</FormLabel>
			<Controller
				name={name}
				defaultValue={defaultValue}
				shouldUnregister={shouldUnregister}
				control={control}
				render={({ field, fieldState: { error } }) => {
					return (
						<>
							<div className='flex w-full items-center gap-2'>
								<Input
									{...field}
									{...other}
									multiple
									type='file'
									className='visible relative inset-auto w-full py-1 file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground'
								/>
								{hint && (
									<Tooltip placement='top' content={hint}>
										<HelpCircleIcon className='text-muted' />
									</Tooltip>
								)}
							</div>
							{error && <FormError>{error.message}</FormError>}
						</>
					)
				}}
			/>
		</FormGroup>
	)
}
