import { SeriesSplineOptions, XAxisPlotLinesOptions } from 'highcharts'
import React from 'react'

import Chart from '@/components/Chart'

type Props = {
	// eslint-disable-next-line
	series: SeriesSplineOptions[]
	// eslint-disable-next-line
	plotLines: XAxisPlotLinesOptions[]
	// eslint-disable-next-line
	maxY1: number
	// eslint-disable-next-line
	maxY2: number
}
const getConfig = ({
	series,
	plotLines,
	maxY1,
	maxY2,
}: Props): Highcharts.Options => ({
	chart: {
		backgroundColor: undefined,
		height: 300,
		type: 'spline',
	},
	xAxis: {
		title: { text: '' },
		plotLines,
		tickInterval: 0.1,
	},
	yAxis: [
		{
			//min: 0,
			title: {
				text: '',
			},
			max: maxY1,
			maxPadding: 0,
			endOnTick: false,
			//visible: false,
			labels: {
				enabled: false,
			},
		},
		{
			//min: 0,
			title: {
				text: '',
			},
			max: maxY2,
			opposite: true,
			//visible: false,
			labels: {
				enabled: false,
			},
		},
	],
	time: {
		timezone: undefined,
	},
	legend: {
		align: 'left',
	},
	tooltip: {
		enabled: false,
	},
	plotOptions: {
		series: {
			cursor: 'pointer',
			animation: false,
		},
		spline: {
			lineWidth: 1,
			marker: {
				enabled: false,
			},
			animation: false,
		},
	},
	series,
})
export default (props: Props) => <Chart config={getConfig(props)} />
