import clsx from 'clsx'
import { find, pipe, prop, propOr } from 'lodash/fp'
import React, { useMemo, useState } from 'react'
import { FieldErrors, useFormContext } from 'react-hook-form'

import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardSubtitle,
	CardTitle,
	InlineMessage,
	Loader,
	Separator,
	TabItem,
	Tabs,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { dialog } from '@/components/dialogs'
import ErrorBoundary from '@/components/ErrorBoundary'
import {
	AnchoringRulesFiledController,
	getAnchoringOnConflictChoices,
} from '@/components/FormSections/Anchoring'
import { AssignmentSetsFieldController } from '@/components/FormSections/Assignment'
import { RepricingLimitsFieldController } from '@/components/FormSections/Limits'
import { MinimalPriceStepFiledController } from '@/components/FormSections/MinimalSteps'
import DemandTactic from '@/components/FormSections/PricingTactics/DemandTactic'
import {
	MarkdownPeriodsFieldController,
	MarkdownTactic,
} from '@/components/FormSections/PricingTactics/MarkdowTactic'
import { RepricingRulesFieldController } from '@/components/FormSections/PricingTactics/RepricingRulesTactic'
import { RoundingRulesFiledController } from '@/components/FormSections/Rounding'
import { FormProvider, RHFSelect } from '@/components/HookForm'
import NavigationPrompt from '@/components/NavigationPrompt'
import {
	FilterRuleModel,
	MarkdownPricingTacticsModel,
	PricingEngineType,
	ProductAssignmentModel,
	RuleBasePricingTacticsModel,
	SettingsTemplateType,
} from '@/generated'
import intl from '@/locale'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import {
	getEnumOptions,
	getResolvedSchema,
	translateSchemaKeys,
} from '@/tools/json-schema-utils'

import { usePricingCampaignQuery } from '../../queries'
import { PricingCampaignFormData } from '../../types'
import SettingActionsDropdown from '../SettingActionsDropdown'
import { PricingCampaignAssignmentPrediction } from './PricingCampaignAssignmentPrediction'
import {
	PricingCampaignFormHeader,
	PricingCampaignFormInformation,
} from './PricingCampaignFormHeader'
import {
	PricingCampaignProvider,
	usePricingCampaignContext,
} from './PricingCampaignProvider'
import { SettingType } from './usePricingCampaignActions'
import { useSettingActions } from './useSettingActions'

type PricingCampaignModalProps = {
	isOpen: boolean
	close: () => void
	pricingCampaignId?: string
	optimizationGroupId?: string | null
	scenarioId?: string | null
	draft?: {
		name?: string
		engine?: string
		assignment_filters?: FilterRuleModel[]
	}
}
export default function PricingCampaignModal({
	isOpen,
	close,
	draft,
	pricingCampaignId,
	...props
}: PricingCampaignModalProps) {
	const pricingCampaignQuery = usePricingCampaignQuery(pricingCampaignId!, {
		enabled: !!pricingCampaignId,
	})
	const isLoading = pricingCampaignQuery.isLoading
	const pricingCampaign = pricingCampaignQuery.data
	const optimizationGroupId =
		pricingCampaign?.optimization_group_id || props.optimizationGroupId
	const scenarioId = pricingCampaign?.scenario_id || props.scenarioId

	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					disableFocusTrap
					size={'xlarge'}
					position='none'
					testId='pc-modal'
				>
					<ErrorBoundary>
						{isLoading ? (
							<PricingCampaignModalSkeleton />
						) : (
							<PricingCampaignProvider
								pricingCampaignId={pricingCampaignId}
								optimizationGroupId={optimizationGroupId}
								scenarioId={scenarioId}
								draft={draft}
								onFinish={close}
							>
								<PricingCampaignForm close={close} />
							</PricingCampaignProvider>
						)}
					</ErrorBoundary>
				</Modal>
			)}
		</ModalTransition>
	)
}

const PricingCampaignModalSkeleton = () => (
	<div className='flex size-full flex-col overflow-hidden rounded-lg'>
		<div className='h-16 w-full bg-accent-3 p-5' />
		<div className='flex w-full flex-col gap-2 border-b px-5 pb-2 pt-5'>
			<div className='h-10 w-96 animate-pulse rounded-lg bg-accent-3' />
			<div className='flex items-center gap-5'>
				<div className='h-11 w-52 animate-pulse rounded-lg bg-accent-3' />
				<div className='h-11 w-52 animate-pulse rounded-lg bg-accent-3' />
				<div className='h-11 w-52 animate-pulse rounded-lg bg-accent-3' />
			</div>
			<div className='flex items-center gap-5 pt-5'>
				<div className='h-5 w-24 animate-pulse rounded-lg bg-accent-3' />
				<div className='h-5 w-24 animate-pulse rounded-lg bg-accent-3' />
				<div className='h-5 w-24 animate-pulse rounded-lg bg-accent-3' />
			</div>
		</div>
		<div className='size-full bg-accent-2 p-5'>
			<div className='h-96 w-full animate-pulse rounded-lg bg-accent-3' />
		</div>
		<div className='mt-auto h-16 w-full bg-accent-3 p-5' />
	</div>
)
function PricingCampaignForm({ close }: { close: () => void }) {
	const context = usePricingCampaignContext()
	const [currentTab, setCurrentTab] = useState('assignment')
	const tabs = useMemo(() => getTabs(), [])
	const TabContent = useMemo(
		() => find({ key: currentTab }, tabs)?.TabContent ?? 'div',
		[currentTab]
	)
	const handleClose = async () => {
		if (context.methods.formState.isDirty || context.isNew) {
			const answer = await dialog.confirm({
				title: intl.get('navigation.blocked.pc_settings.title'),
				text: intl.getHTML('navigation.blocked.pc_settings.message'),
				okText: intl.get('navigation.blocked.pc_settings.ok'),
				cancelText: intl.get('navigation.blocked.pc_settings.cancel'),
			})
			if (answer) {
				close()
			}
		} else {
			close()
		}
	}
	if (context.isLoading) {
		return <PricingCampaignModalSkeleton />
	}
	return (
		<>
			<NavigationPrompt
				when={context.methods.formState?.isDirty}
				title={intl.get('navigation.blocked.pc_settings.title')}
				message={intl.getHTML('navigation.blocked.pc_settings.message')}
				okText={intl.get('navigation.blocked.pc_settings.ok')}
				cancelText={intl.get('navigation.blocked.pc_settings.cancel')}
			/>
			<FormProvider
				methods={context!.methods}
				onSubmit={context.handleSubmit}
				className='flex h-full flex-col overflow-hidden'
			>
				<Blanket
					className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-lg dark:bg-black/50'
					isTinted={context.isPending}
				>
					<div className='flex flex-col items-center justify-center gap-2'>
						<Loader />
						<span className='text-muted'>
							{intl.get('pricing_campaign_loading_hint')}
						</span>
					</div>
				</Blanket>
				<ModalHeader className='pr-10'>
					<div className='flex w-full items-start justify-between'>
						<ModalTitle data-testid={'pc-modal-title'}>
							{context.methods.watch('name') || 'None'}
						</ModalTitle>
						<div className='flex items-center gap-2'>
							<PricingCampaignFormHeader />
							<Separator orientation='vertical' className='mr-2 h-6' />
						</div>
					</div>
				</ModalHeader>
				<div className='border-b border-solid'>
					<div className='px-4 pb-3 pt-2'>
						{context.isScenarioContext && (
							<InlineMessage
								variant='info'
								icon={<InfoIcon />}
								className='mb-2 text-xs font-medium'
							>
								{intl
									.get('pc.scenario_pricing_campaign_hint')
									.d(
										'This is scenario pricing campign and will not nave effect to the master data'
									)}
							</InlineMessage>
						)}
						<PricingCampaignFormInformation />
					</div>
					<Tabs className='-mb-px ml-3'>
						{tabs.map((tab) => (
							<TabItem
								key={tab.key}
								data-testid={'pc-modal-tab'}
								data-tabid={tab.key}
								active={currentTab === tab.key}
								onClick={() => setCurrentTab(tab.key)}
								className={clsx({
									'text-danger': tab.hasError(context.methods.formState.errors),
								})}
							>
								{tab.label}
								{tab.hasError(context.methods.formState.errors) && (
									<AlertIcon />
								)}
							</TabItem>
						))}
					</Tabs>
				</div>
				<ModalBody className='bg-accent-3'>
					<TabContent />
				</ModalBody>
				<ModalFooter className='justify-between border-t'>
					<Button onClick={handleClose}>{intl.get('general_cancel')}</Button>
					<div className='flex items-center gap-2'>
						{!context.isReadyToChange && (
							<p className='max-w-96 text-right text-xs font-medium text-muted'>
								{intl
									.get('pc.optimization.update_is_forbidden')
									.d(
										'Optimization status don`t allow to make any chages, please wait or revert optimization manually.'
									)}
							</p>
						)}
						{(context.methods.formState.isDirty || context.isNew) && (
							<div className='flex items-center space-x-2'>
								{!context.isNew && (
									<Button
										data-testid={'pc-form-revert-changes'}
										onClick={context.handleReset}
										variant='tertiary'
										iconBefore={<UndoIcon />}
									>
										{intl.get('app.revert_changes')}
									</Button>
								)}
								{!context.isScenarioContext &&
									context.handleSubmitAndUpdate && (
										<Button
											disabled={!context.isReadyToChange}
											data-testid={'pc-form-save-and-update'}
											onClick={context.handleSubmitAndUpdate}
										>
											{intl.get('app.save_and_update')}
										</Button>
									)}
								<Button
									variant='primary-warning'
									data-testid={'pc-form-save'}
									disabled={!context.isReadyToChange}
									onClick={context.handleSubmit}
									iconBefore={<CheckIcon />}
								>
									{intl.get('general_save')}
								</Button>
							</div>
						)}
					</div>
				</ModalFooter>
			</FormProvider>
			<ModalCloseButton onClick={handleClose} />
		</>
	)
}

const getTabs = () =>
	[
		{
			key: 'assignment',
			label: intl.get('settings.assignment.short').d('Assignment'),
			hasError: (errors: FieldErrors<PricingCampaignFormData>) =>
				errors?.settings?.product_assignments,
			TabContent: AssignmentTab,
		},
		{
			key: 'tactics',
			label: intl.get('settings.tactics.short').d('Tactics'),
			hasError: (errors: FieldErrors<PricingCampaignFormData>) =>
				errors?.name || errors?.settings?.pricing_tactics,
			TabContent: PricingCampaignTacticsTab,
		},

		{
			key: 'repricing_limits',
			label: intl.get('settings.price_limits.short').d('Limits'),
			hasError: (errors: FieldErrors<PricingCampaignFormData>) =>
				errors?.settings?.price_limits,
			TabContent: RepricingLimitsTab,
		},
		{
			key: 'anchoring',
			label: intl.get('settings.anchoring.short').d('Anchoring'),
			hasError: (errors: FieldErrors<PricingCampaignFormData>) =>
				errors?.settings?.anchoring,
			TabContent: AnchoringTab,
		},
		{
			key: 'rounding',
			label: intl.get('settings.rounding.short').d('Rounding'),
			hasError: (errors: FieldErrors<PricingCampaignFormData>) =>
				errors?.settings?.rounding,
			TabContent: RoundingRulesTab,
		},
		{
			key: 'min_steps',
			label: intl.get('settings.minimal_steps.short').d('Minimal steps'),
			hasError: (errors: FieldErrors<PricingCampaignFormData>) =>
				errors?.settings?.minimal_steps,
			TabContent: MinimalPriceStepTab,
		},
	].filter(Boolean)
const getSettingType = (engine: string) => {
	switch (engine) {
		case PricingEngineType.ML:
			return SettingsTemplateType.PricingCampaignTacticsMl
		case PricingEngineType.MD:
			return SettingsTemplateType.PricingCampaignTacticsMd
		case PricingEngineType.RB:
			return SettingsTemplateType.PricingCampaignTacticsRb
		default:
			return SettingsTemplateType.PricingCampaignTacticsMl
	}
}
function PricingCampaignTacticsTab() {
	const { watch, setValue } = useFormContext()
	const context = usePricingCampaignContext()
	const pricingCampaignId = context?.pricingCampaign?.id
	const engine = watch('settings.pricing_tactics.engine')
	const pricingCampaignNameWatcher = watch('name')
	const pricingTacticsSettings = watch('settings.pricing_tactics')
	const handleUseSettingTemplate = ({
		templateSettings: { pricing_tactics },
	}: {
		templateSettings: { [key: string]: SettingType | SettingType[] }
	}) => {
		setValue(
			'settings.pricing_tactics.params',
			(
				pricing_tactics as
					| MarkdownPricingTacticsModel
					| RuleBasePricingTacticsModel
			).params,
			{
				shouldDirty: true,
			}
		)
	}
	const settingActions = useSettingActions({
		handleUseTemplate: handleUseSettingTemplate,
	})
	return (
		<>
			{[PricingEngineType.ML, PricingEngineType.MD].includes(engine) && (
				<Card className='mb-3 border'>
					<CardHeader>
						<div className='flex w-full justify-between'>
							<div>
								<CardTitle as='h2'>
									{intl
										.get('pc.pricing_tactics.inheritance.title')
										.d('Pricing strategy inheritance')}
								</CardTitle>
								<CardSubtitle>
									{intl
										.get('pc.pricing_tactics.inheritance.subtitle')
										.d(
											'Pricing strategy inheritance from optimization group settings'
										)}
								</CardSubtitle>
							</div>
						</div>
					</CardHeader>
					<CardContent>
						{engine === PricingEngineType.ML && <DemandTactic />}
						{engine === PricingEngineType.MD && <MarkdownTactic />}
					</CardContent>
				</Card>
			)}
			{[PricingEngineType.RB, PricingEngineType.MD].includes(engine) && (
				<Card className='border'>
					<CardHeader>
						<div className='flex w-full justify-between'>
							<CardTitle as='h2'>
								{intl.get('pc.pricing_tactics').d('Pricing tactics')}
							</CardTitle>
							<CardActions>
								<SettingActionsDropdown
									actions={settingActions}
									type={getSettingType(engine)}
									saveAsTemplateProps={{
										pricingCampaignName: pricingCampaignNameWatcher,
										pricingCampaignId: pricingCampaignId!,
										settings: {
											pricing_tactics: pricingTacticsSettings,
										},
									}}
									useTemplateProps={{
										isReadyToChange: context?.isReadyToChange,
									}}
									copyToPCProps={{ settingName: 'pricing_tactics' }}
								>
									<Button
										data-testid={'pc-actions-popover-trigger'}
										variant='tertiary'
										className='w-xs ml-2'
										iconBefore={<DotsFilledIcon />}
									/>
								</SettingActionsDropdown>
							</CardActions>
						</div>
					</CardHeader>
					<CardContent>
						{engine === PricingEngineType.MD && (
							<MarkdownPeriodsFieldController name='settings.pricing_tactics.params' />
						)}
						{engine === PricingEngineType.RB && (
							<RepricingRulesFieldController name='settings.pricing_tactics.params' />
						)}
					</CardContent>
				</Card>
			)}
		</>
	)
}
function RepricingLimitsTab() {
	const { watch, setValue } = useFormContext()
	const context = usePricingCampaignContext()
	const pricingCampaignId = context?.pricingCampaign?.id
	const pricingCampaignNameWatcher = watch('name')
	const priceLimitsWatcher = watch('settings.price_limits')

	const settingsSchema = useSettingsSchemasQuery({
		select: pipe([
			getResolvedSchema('entity:pricing_campaign'),
			prop('properties.settings.properties.price_limits'),
			translateSchemaKeys,
		]),
	})

	if (settingsSchema.isLoading) {
		return (
			<div className='flex min-h-96 w-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	if (settingsSchema.error?.message) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger'>
				{settingsSchema.error?.message}
			</InlineMessage>
		)
	}
	const schema = settingsSchema.data
	const handleUseSettingTemplate = ({
		templateSettings: { price_limits },
	}: {
		templateSettings: { [key: string]: SettingType | SettingType[] }
	}) => {
		setValue('settings.price_limits', price_limits, {
			shouldDirty: true,
		})
	}
	const settingActions = useSettingActions({
		handleUseTemplate: handleUseSettingTemplate,
	})

	return (
		<Card data-testid='pc-repricing-limits-panel' className='border'>
			<CardHeader>
				<div className='flex justify-between'>
					<CardTitle as='h2'>{prop('title', schema)}</CardTitle>
					<CardActions>
						<SettingActionsDropdown
							actions={settingActions}
							type={SettingsTemplateType.PricingCampaignLimits}
							saveAsTemplateProps={{
								pricingCampaignName: pricingCampaignNameWatcher,
								pricingCampaignId: pricingCampaignId!,
								settings: {
									price_limits: priceLimitsWatcher,
								},
							}}
							useTemplateProps={{
								isReadyToChange: context?.isReadyToChange,
							}}
							copyToPCProps={{ settingName: 'price_limits' }}
						>
							<Button
								data-testid={'pc-actions-popover-trigger'}
								variant='tertiary'
								className='w-xs ml-2'
								iconBefore={<DotsFilledIcon />}
							/>
						</SettingActionsDropdown>
					</CardActions>
				</div>
			</CardHeader>
			<CardContent className='space-y-5'>
				<ErrorBoundary>
					<RepricingLimitsFieldController
						name={'settings.price_limits.lower'}
						defaultValue={propOr([], 'properties.lower.default', schema)}
						schema={prop('properties.lower', schema)}
					/>
					<RepricingLimitsFieldController
						name={'settings.price_limits.upper'}
						defaultValue={propOr([], 'properties.upper.default', schema)}
						schema={prop('properties.upper', schema)}
					/>
				</ErrorBoundary>
			</CardContent>
			<CardHeader>
				<CardTitle>{prop('properties.extra.title', schema)}</CardTitle>
				<CardSubtitle>
					{prop('properties.extra.description', schema)}
				</CardSubtitle>
			</CardHeader>
			<CardContent>
				<RHFSelect
					name='settings.price_limits.extra.on_conflict'
					defaultValue={propOr(
						[],
						'properties.extra.properties.on_conflict.default',
						schema
					)}
					label={prop('properties.extra.properties.on_conflict.title', schema)}
					options={getEnumOptions(
						prop('properties.extra.properties.on_conflict', schema)
					)}
					menuPlacement='top'
				/>
			</CardContent>
		</Card>
	)
}

function AssignmentPredictedCount() {
	const { watch } = useFormContext()
	const assignments = watch(
		'settings.product_assignments'
	) as ProductAssignmentModel
	const { isGlobal, scenarioId, optimizationGroupId, pricingCampaign } =
		usePricingCampaignContext()

	return (
		<PricingCampaignAssignmentPrediction
			assignments={assignments}
			isGlobal={isGlobal}
			scenarioId={scenarioId}
			optimizationGroupId={optimizationGroupId}
			pricingCampaign={pricingCampaign}
		/>
	)
}
function AssignmentTab() {
	const { isNew, draft } = usePricingCampaignContext()
	return (
		<ErrorBoundary>
			{isNew && draft?.assignment_filters && (
				<InlineMessage
					variant='info'
					className='mb-3 text-xs font-medium'
					icon={<InfoIcon />}
				>
					{intl
						.get('pc.assignment_filters_hint')
						.d(
							'Ensure the pricing campaign has top priority to prevent product allocation to other campaigns via dynamic filters'
						)}
				</InlineMessage>
			)}
			<AssignmentSetsFieldController name={'settings.product_assignments'} />
			<AssignmentPredictedCount />
		</ErrorBoundary>
	)
}
function AnchoringTab() {
	const { watch, setValue } = useFormContext()
	const context = usePricingCampaignContext()
	const pricingCampaignId = context?.pricingCampaign?.id
	const pricingCampaignNameWatcher = watch('name')
	const anchoringWathcer = watch('settings.anchoring')
	const anchoringOnConflictChoices = useMemo(
		() => getAnchoringOnConflictChoices(),
		[]
	)
	const handleUseSettingTemplate = ({
		templateSettings: { anchoring },
	}: {
		templateSettings: { [key: string]: SettingType | SettingType[] }
	}) => {
		setValue('settings.anchoring', anchoring, {
			shouldDirty: true,
		})
	}
	const settingActions = useSettingActions({
		handleUseTemplate: handleUseSettingTemplate,
	})
	return (
		<Card data-testid={'pc-anchoring-panel'} className='border shadow-none'>
			<CardHeader>
				<div className='flex justify-between'>
					<CardTitle as='h2'>
						{intl
							.get('anchoring.section.beahvior.title')
							.d('Anchoring price behavior')}
					</CardTitle>
					<CardActions>
						<SettingActionsDropdown
							actions={settingActions}
							type={SettingsTemplateType.PricingCampaignAnchoring}
							saveAsTemplateProps={{
								pricingCampaignName: pricingCampaignNameWatcher,
								pricingCampaignId: pricingCampaignId!,
								settings: {
									anchoring: anchoringWathcer,
								},
							}}
							useTemplateProps={{
								isReadyToChange: context?.isReadyToChange,
							}}
							copyToPCProps={{ settingName: 'anchoring' }}
						>
							<Button
								data-testid={'pc-actions-popover-trigger'}
								variant='tertiary'
								className='w-xs ml-2'
								iconBefore={<DotsFilledIcon />}
							/>
						</SettingActionsDropdown>
					</CardActions>
				</div>
			</CardHeader>
			<CardContent>
				<AnchoringRulesFiledController name='settings.anchoring' />
			</CardContent>
			<CardHeader>
				<CardTitle>
					{intl
						.get('anchoring.section.additional_settings.title')
						.d('Additional settings')}
				</CardTitle>
				<CardSubtitle>
					{intl
						.get('anchoring.section.additional_settings.subtitle')
						.d('Define the prior limit parameter in special cases.')}
				</CardSubtitle>
			</CardHeader>
			<CardContent>
				<RHFSelect
					required
					className='w-64'
					name={'settings.anchoring.on_conflict'}
					label={intl
						.get('anchoring.field.on_conflict.label')
						.d('In case of limits conflict')}
					options={anchoringOnConflictChoices}
					menuPlacement='top'
				/>
			</CardContent>
		</Card>
	)
}
function RoundingRulesTab() {
	const { watch, setValue } = useFormContext()
	const context = usePricingCampaignContext()
	const pricingCampaignId = context?.pricingCampaign?.id
	const pricingCampaignNameWatcher = watch('name')
	const roundingWatcher = watch('settings.rounding')
	const settingsSchema = useSettingsSchemasQuery({
		select: pipe([
			getResolvedSchema('entity:pricing_campaign'),
			prop('properties.settings.properties.rounding'),
			translateSchemaKeys,
		]),
	})
	if (settingsSchema.isLoading) {
		return (
			<div className='flex min-h-96 w-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	if (settingsSchema.error?.message) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger'>
				{settingsSchema.error?.message}
			</InlineMessage>
		)
	}

	const schema = settingsSchema.data
	const handleUseSettingTemplate = ({
		templateSettings: { rounding },
	}: {
		templateSettings: { [key: string]: SettingType | SettingType[] }
	}) => {
		setValue('settings.rounding', rounding, {
			shouldDirty: true,
		})
	}
	const settingActions = useSettingActions({
		handleUseTemplate: handleUseSettingTemplate,
	})

	return (
		<Card className='border' data-testid={'pc-rounding-panel'}>
			<CardHeader>
				<div className='flex justify-between'>
					<CardTitle as='h2'>{intl.get('rounding_block_title')}</CardTitle>
					<CardActions>
						<SettingActionsDropdown
							actions={settingActions}
							type={SettingsTemplateType.PricingCampaignRounding}
							saveAsTemplateProps={{
								pricingCampaignName: pricingCampaignNameWatcher,
								pricingCampaignId: pricingCampaignId!,
								settings: {
									rounding: roundingWatcher,
								},
							}}
							useTemplateProps={{
								isReadyToChange: context?.isReadyToChange,
							}}
							copyToPCProps={{ settingName: 'rounding' }}
						>
							<Button
								data-testid={'pc-actions-popover-trigger'}
								variant='tertiary'
								className='w-xs ml-2'
								iconBefore={<DotsFilledIcon />}
							/>
						</SettingActionsDropdown>
					</CardActions>
				</div>
			</CardHeader>
			<CardContent>
				<RoundingRulesFiledController
					name='settings.rounding'
					schema={schema}
				/>
			</CardContent>
		</Card>
	)
}
function MinimalPriceStepTab() {
	const { watch, setValue } = useFormContext()
	const context = usePricingCampaignContext()
	const pricingCampaignId = context?.pricingCampaign?.id
	const pricingCampaignNameWatcher = watch('name')
	const minimalStepsWatcher = watch('settings.minimal_steps')
	const settingsSchema = useSettingsSchemasQuery({
		select: pipe([
			getResolvedSchema('entity:pricing_campaign'),
			prop('properties.settings.properties.minimal_steps'),
			translateSchemaKeys,
		]),
	})
	if (settingsSchema.isLoading) {
		return (
			<div className='flex min-h-96 w-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	if (settingsSchema.error?.message) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger'>
				{settingsSchema.error?.message}
			</InlineMessage>
		)
	}
	const schema = settingsSchema.data
	const handleUseSettingTemplate = ({
		templateSettings: { minimal_steps },
	}: {
		templateSettings: { [key: string]: SettingType | SettingType[] }
	}) => {
		setValue('settings.minimal_steps', minimal_steps, {
			shouldDirty: true,
		})
	}
	const settingActions = useSettingActions({
		handleUseTemplate: handleUseSettingTemplate,
	})
	return (
		<Card className='border shadow-none' data-testid={'pc-min-steps-panel'}>
			<CardHeader>
				<div className='flex justify-between'>
					<CardTitle as='h2'>{schema?.title}</CardTitle>
					<CardActions>
						<SettingActionsDropdown
							actions={settingActions}
							type={SettingsTemplateType.PricingCampaignMinimalSteps}
							saveAsTemplateProps={{
								pricingCampaignName: pricingCampaignNameWatcher,
								pricingCampaignId: pricingCampaignId!,
								settings: {
									minimal_steps: minimalStepsWatcher,
								},
							}}
							useTemplateProps={{
								isReadyToChange: context?.isReadyToChange,
							}}
							copyToPCProps={{ settingName: 'minimal_steps' }}
						>
							<Button
								data-testid={'pc-actions-popover-trigger'}
								variant='tertiary'
								className='w-xs ml-2'
								iconBefore={<DotsFilledIcon />}
							/>
						</SettingActionsDropdown>
					</CardActions>
				</div>
			</CardHeader>
			<CardContent>
				<MinimalPriceStepFiledController
					name='settings.minimal_steps'
					schema={schema}
				/>
			</CardContent>
		</Card>
	)
}
