import React, { useMemo, useState } from 'react'

import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'

import { DeltaValue, InlineMessage, Result } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'

import NumberField from '@/components/data-grid/fields/Number/TableCell'
import { ColumnDataType } from '@/components/data-grid/types'
import { VirtualDataTable } from '@/components/tables'
import { FilterRuleModel, OptimizationIDModel } from '@/generated'
import intl from '@/locale'
import { zipSeries } from '@/modules/bi/utils'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { useStatisticQuery } from '../../queries'

const emptyArray: [] = []

type AverageValuesRow = {
	brand: string
	statistics_average_init_price: number
	statistics_average_final_price: number
}
export default function AverageValuesWidget({
	queryParams,
	isLoading,
}: {
	queryParams: {
		optimizations: OptimizationIDModel[]
		filters: FilterRuleModel[]
	} | null
	isLoading: boolean
}) {
	const repricingStatisticQuery = useStatisticQuery<AverageValuesRow[]>(
		{
			...queryParams!,
			metrics: [
				'statistics_average_init_price',
				'statistics_average_final_price',
			],
			dimensions: ['brand'],
		},
		{
			enabled: !!queryParams && !isLoading,
			select: (data): AverageValuesRow[] =>
				zipSeries<AverageValuesRow>(data.meta, data.data),
		}
	)

	const rows = repricingStatisticQuery.data || emptyArray
	const [sorting, setSorting] = useState<SortingState>([
		{
			id: 'name',
			desc: true,
		},
	])
	const columns = useMemo(() => getColumns(), [])
	const table = useReactTable<AverageValuesRow>({
		data: rows,
		columns,
		state: {
			sorting,
		},
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	if (repricingStatisticQuery.isFetching || isLoading) {
		return <LoadingPlaceholder />
	}
	if (repricingStatisticQuery.isError) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger' className='w-full'>
				{repricingStatisticQuery.error.message || intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}
	if (!rows?.length) {
		return (
			<div className='flex max-h-[350px] w-full flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-4 py-5'>
				<Result
					icon={<SearchIcon width={72} height={72} />}
					subtitle={intl.get('no_data')}
				/>
			</div>
		)
	}
	return (
		<div className='flex max-h-[350px] w-full flex-col overflow-hidden rounded-lg border border-solid border-base'>
			<VirtualDataTable compact getRowClassName={() => 'h-9'} table={table} />
		</div>
	)
}
const columnHelper = createColumnHelper<AverageValuesRow>()
const getColumns = () => [
	columnHelper.accessor('brand', {
		header: () => intl.get('general_name'),
		cell: (info) => {
			return info.getValue()
		},
	}),
	columnHelper.accessor('statistics_average_init_price', {
		size: 80,
		header: () => intl.get('before').d('Before'),
		cell: (info) => {
			return (
				<NumberField
					value={info.getValue()}
					schema={{ type: ColumnDataType.Float }}
				/>
			)
		},
	}),
	columnHelper.accessor('statistics_average_final_price', {
		size: 80,
		header: () => intl.get('after').d('After'),
		cell: (info) => {
			return (
				<NumberField
					value={info.getValue()}
					schema={{ type: ColumnDataType.Float }}
				/>
			)
		},
	}),
	columnHelper.accessor(
		(row) =>
			(row.statistics_average_final_price / row.statistics_average_init_price) *
				100 -
			100,
		{
			id: 'delta',
			size: 80,
			header: () => '∆',
			cell: (info) => {
				const value = info.getValue()
				return (
					<DeltaValue value={value}>
						<div className='cell-data-number'>
							{formatNumber(value, NumberFormats.PercentSigned)}
						</div>
					</DeltaValue>
				)
			},
		}
	),
]

function LoadingPlaceholder() {
	return (
		<div className='flex max-h-[350px] w-full flex-col overflow-hidden rounded-lg border border-solid border-base p-3'>
			<div className='flex flex-col items-center space-y-2'>
				<div className='flex w-full items-center justify-between'>
					<div className='h-5 w-20 animate-pulse rounded-lg bg-accent-4' />
					<div className='h-5 w-10 animate-pulse rounded-lg bg-accent-4' />
				</div>
				<div className='flex w-full items-center justify-between'>
					<div className='h-5 w-24 animate-pulse rounded-lg bg-accent-4' />
					<div className='h-5 w-12 animate-pulse rounded-lg bg-accent-4' />
				</div>
				<div className='flex w-full items-center justify-between'>
					<div className='h-5 w-28 animate-pulse rounded-lg bg-accent-4' />
					<div className='h-5 w-12 animate-pulse rounded-lg bg-accent-4' />
				</div>
			</div>
		</div>
	)
}
