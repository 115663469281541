import { useRef } from 'react'

import { RowData, useReactTable } from '@tanstack/react-table'

import { MDefinedTableOptions, TableInstance } from '../types'

export const useTableInstance = <TData extends RowData>(
	definedTableOptions: MDefinedTableOptions<TData>
): TableInstance<TData> => {
	const tableContainerRef = useRef<HTMLDivElement>(null)
	const tableHeadCellRefs = useRef<Record<string, HTMLTableCellElement>>({})
	const tableHeadRef = useRef<HTMLTableSectionElement>(null)
	const tableFooterRef = useRef<HTMLTableSectionElement>(null)

	//@ts-expect-error error
	const table = useReactTable<TData>({
		...definedTableOptions,
	}) as TableInstance<TData>
	table.refs = {
		tableContainerRef,
		tableFooterRef,
		tableHeadCellRefs,
		tableHeadRef,
	}
	table.options = {
		...table.options,
	}
	return table
}
