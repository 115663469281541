import clsx from 'clsx'
import { any, groupBy, map, pipe, prop } from 'lodash/fp'
import React from 'react'
import toast from 'react-hot-toast'

import { Button, Spinner } from '@cmpkit/base'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import { FilterRuleEntity } from '@cmpkit/query-builder'

import { dialog } from '@/components/dialogs'
import intl from '@/locale'
import { ExportDialogActions } from '@/modules/core'
import {
	FAILED,
	FINISHED,
	LOCKED,
	NEW,
	PENDING,
	STARTED,
	UPDATING,
} from '@/modules/core/constants'
import { useOptimizationsQuery } from '@/modules/core/queries'
import { OptimizationAction, OptimizationStatus } from '@/modules/core/types'
import { getAssociatedStatus } from '@/modules/core/utils'
import { useProductsCountsByOgQuery } from '@/modules/pricing-campaigns/queries'
import analytic from '@/services/analytics'
import { errorMessage } from '@/tools/message'

import { useBulkOptimizationMutation } from '../mutation'

type OptimizationsByStatus = {
	[key in OptimizationStatus]: Optimizations
}
type OptimizationsActionsProps = {
	rules: FilterRuleEntity[]
	onApply: (rules: FilterRuleEntity[]) => void
	close: () => void
	handleExport: () => void
}
export const OptimizationsActions = ({
	rules,
	close,
	onApply,
	handleExport,
}: OptimizationsActionsProps) => {
	/**
	 * Queries
	 * */
	const optimizations = useOptimizationsQuery()
	const optimizationsByStatus =
		useProductsCountsByOgQuery<OptimizationsByStatus>(
			{
				filters: rules,
			},
			{
				enabled: !!optimizations.data,
				select: pipe([
					map(
						({ optimization_group_id }: { optimization_group_id: string }) => {
							const optimization = optimizations?.data?.[optimization_group_id!]
							return {
								optimization_group_id: optimization_group_id!,
								optimization_status:
									optimization?.status === OptimizationStatus.Updating
										? optimization.status
										: getAssociatedStatus(
												optimization?.status as OptimizationStatus
											),
								optimization_id: optimization?.id,
								optimization_is_exported: optimization?.is_exported,
							}
						}
					),
					groupBy('optimization_status'),
				]),
			}
		)
	/**
	 * Mutations
	 */
	const bulkAction = useBulkOptimizationMutation()
	/**
	 * Handlers
	 */
	const handleOptimizationBulkAction =
		(items: Optimizations) =>
		async ({
			action,
			analyticEvent,
		}: {
			action: OptimizationAction
			analyticEvent: string
		}) => {
			try {
				if (
					action === OptimizationAction.Lock &&
					hasExportedOptimization(items)
				) {
					const answer = await dialog.confirm({
						title: intl.get('optimization_export_again_title'),
						text: intl.get('optimization_export_again_message'),
						okText: intl.get('general_apply'),
					})
					if (!answer) return
				}
				if (action === OptimizationAction.Unlock) {
					const answer = await dialog.form({
						title: intl.get('optimization_remove_export_title'),
						text: intl.get('optimization_remove_export_message'),
						form(resolve) {
							return <ExportDialogActions resolve={resolve} />
						},
					})
					if (!answer || answer.action === 'close') return
					if (answer.action === 'ok') {
						await bulkAction.mutateAsync(
							items.map(({ optimization_id, optimization_group_id }) => ({
								optimization_group_id,
								optimization_id,
								action: OptimizationAction.DeleteExport,
							}))
						)
					}
				}
				analytic.logEvent(analyticEvent)
				await bulkAction.mutateAsync(
					items.map(({ optimization_id, optimization_group_id }) => ({
						optimization_group_id,
						optimization_id,
						action,
					}))
				)
			} catch (err) {
				toast.error(errorMessage(), {
					duration: 3000,
				})
			}
		}
	const isLoading = any(prop('isLoading'), [
		optimizations,
		optimizationsByStatus,
	])
	const isPending = bulkAction.isPending
	const handleApplyFilter = (ids: string[]) => {
		if (!ids.length) return
		onApply([
			...rules.filter(({ name }) => name !== 'optimization_group_id'),
			{
				name: 'optimization_group_id',
				operation: 'in',
				value: ids,
			},
		])
		close()
	}
	return (
		<div className='w-[300px] space-y-2 rounded-md border bg-accent-1 p-2'>
			<p className='text-xs font-normal text-muted'>
				{intl
					.get('optimization_actions_dropdown.title')
					.d('Update OG’s statuses for selected products')}
			</p>
			{actions({ isDisabled: optimizationsByStatus.isLoading }).map(
				({ key, getActionButtons, className }) => {
					const groupItems = optimizationsByStatus.data?.[key]

					const totalOGs = groupItems?.length
					return (
						<div
							className={clsx(
								totalOGs ? className : 'bg-accent-3',
								'flex w-full items-center justify-between rounded-md p-2'
							)}
						>
							<div
								className={clsx('w-full', {
									'cursor-pointer': totalOGs,
								})}
								typeof='button'
								onClick={() => handleApplyFilter(getOgIds(groupItems))}
							>
								{(isLoading || isPending) && <Spinner />}
								{optimizationsByStatus.data && !isLoading && !isPending && (
									<h4>{totalOGs || 0}</h4>
								)}
								<p className='text-xs text-muted'>
									{intl.get(`og_status_${key}`).d(key)}
								</p>
							</div>
							{totalOGs && getActionButtons && (
								<div className='flex items-center justify-between space-x-2'>
									{getActionButtons({
										handleClick: handleOptimizationBulkAction(groupItems!),
										handleExport,
									})}
								</div>
							)}
						</div>
					)
				}
			)}
		</div>
	)
}
const actions = ({ isDisabled }: { isDisabled: boolean }) => [
	{
		key: NEW,
		className: 'bg-accent-3',
		getActionButtons: ({ handleClick }: ActionButtonsProps) => [
			renderButton({
				isDisabled,
				onClick: () =>
					handleClick({
						action: OptimizationAction.Start,
						analyticEvent: getAnalyticEventName(),
					}),
				title: intl.get('optimization_groups_status_btn_start'),
			}),
		],
	},
	{
		key: UPDATING,
		className: 'bg-warning-25 dark:bg-warning-200',
	},
	{
		key: PENDING,
		className: 'bg-warning-25 dark:bg-warning-200',
		getActionButtons: ({ handleClick }: ActionButtonsProps) => [
			renderButton({
				isDisabled,
				onClick: () =>
					handleClick({
						action: OptimizationAction.Update,
						analyticEvent: getAnalyticEventName(),
					}),
				title: intl.get('app.update'),
			}),
		],
	},
	{
		key: STARTED,
		className: 'bg-warning-25 dark:bg-warning-200',
		getActionButtons: ({ handleClick }: ActionButtonsProps) => [
			renderButton({
				isDisabled,
				onClick: () =>
					handleClick({
						action: OptimizationAction.Stop,
						analyticEvent: getAnalyticEventName(),
					}),
				title: intl.get('general_cancel'),
			}),
		],
	},
	{
		key: FAILED,
		className: 'bg-danger-25 dark:bg-danger-200',
		getActionButtons: ({ handleClick }: ActionButtonsProps) => [
			renderButton({
				isDisabled,
				onClick: () =>
					handleClick({
						action: OptimizationAction.Start,
						analyticEvent: getAnalyticEventName(FAILED),
					}),
				title: intl.get('optimization_groups_status_btn_restart'),
			}),
		],
	},
	{
		key: FINISHED,
		className: 'bg-success-25 dark:bg-success-200',
		getActionButtons: ({ handleClick }: ActionButtonsProps) => [
			renderButton({
				isDisabled,
				onClick: () =>
					handleClick({
						action: OptimizationAction.Reset,
						analyticEvent: getAnalyticEventName(OptimizationAction.Reset),
					}),
				icon: <UndoIcon />,
			}),
			renderButton({
				isDisabled,
				onClick: () =>
					handleClick({
						action: OptimizationAction.Lock,
						analyticEvent: getAnalyticEventName(OptimizationAction.Lock),
					}),
				title: intl.get('general_apply'),
			}),
		],
	},
	{
		key: LOCKED,
		className: 'bg-brand-25 dark:bg-brand-200',
		getActionButtons: ({ handleClick, handleExport }: ActionButtonsProps) => [
			renderButton({
				isDisabled,
				onClick: () =>
					handleClick({
						action: OptimizationAction.Unlock,
						analyticEvent: getAnalyticEventName(OptimizationAction.Unlock),
					}),
				icon: <UndoIcon />,
			}),
			renderButton({
				isDisabled,
				onClick: handleExport,
				icon: <DownloadIcon />,
				title: intl.get('status_panel_modal_download'),
			}),
		],
	},
]
const hasExportedOptimization = any(prop('optimization_is_exported'))
const getOgIds = (groups: Optimizations = []) =>
	groups.map(({ optimization_group_id }) => optimization_group_id) || []
const getAnalyticEventName = (status?: string) => {
	switch (status) {
		case FAILED:
			return 'mass repricing: action: restart'
		case OptimizationAction.Lock:
			return 'mass repricing: action: apply'
		case OptimizationAction.Unlock:
			return 'mass repricing: action: discard'
		case OptimizationAction.Reset:
			return 'mass repricing: action: revert'
		default:
			return 'mass repricing: action: start'
	}
}
const renderButton = ({
	isDisabled,
	onClick,
	icon,
	title,
}: RenderButtonProps) => (
	<Button
		size='small'
		disabled={isDisabled}
		onClick={onClick}
		{...(icon && { iconBefore: icon })}
	>
		{title}
	</Button>
)
type RenderButtonProps = {
	isDisabled: boolean
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
	icon?: React.ReactNode
	title?: string
}
type Optimizations = {
	optimization_group_id: string
	optimization_status: string
	optimization_id: string
}[]
type ActionButtonsProps = {
	handleClick: (props: HandleClickPropsType) => Promise<void>
	handleExport: (e: React.MouseEvent<HTMLButtonElement>) => void
}

type HandleClickPropsType = {
	action: OptimizationAction
	analyticEvent: string
}
