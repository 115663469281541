import { Blocker, Transition } from 'history'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { useBlocker } from './useBlocker'

export function useCallbackPrompt(
	when: boolean
): [boolean, () => void, () => void] {
	const navigate = useNavigate()
	const location = useLocation()
	const [showPrompt, setShowPrompt] = useState(false)
	const [lastLocation, setLastLocation] = useState<Transition | null>(null)
	const [confirmedNavigation, setConfirmedNavigation] = useState(false)

	const cancelNavigation = useCallback(() => {
		setShowPrompt(false)
	}, [])

	// handle blocking when user click on another route prompt will be shown
	const handleBlockedNavigation: Blocker = useCallback(
		(nextLocation) => {
			// in if condition we are checking next location and current location are equals or not
			if (nextLocation.location.pathname === location.pathname) {
				setConfirmedNavigation(true)
				setLastLocation(nextLocation)
				return true
			}
			if (
				!confirmedNavigation &&
				nextLocation.location.pathname !== location.pathname
			) {
				setShowPrompt(true)
				setLastLocation(nextLocation)
				return false
			}
			return true
		},

		[confirmedNavigation, location]
	)

	const confirmNavigation = useCallback(() => {
		setShowPrompt(false)
		setConfirmedNavigation(true)
	}, [])

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.location)

			// Clean-up state on confirmed navigation
			setConfirmedNavigation(false)
		}
	}, [confirmedNavigation, lastLocation])

	useBlocker(handleBlockedNavigation, when)

	return [showPrompt, confirmNavigation, cancelNavigation]
}
