import { create } from 'zustand'

/**
 * Zustand store
 */
interface CoreState {
	optimizationGroupId: string | null
	setOptimizationGroupId(id: string | null): void
}
export const useCoreStore = create<CoreState>((set) => ({
	optimizationGroupId: null,
	setOptimizationGroupId: (optimizationGroupId) =>
		set((state) => ({ ...state, optimizationGroupId }), true),
}))
