import { useState } from 'react'

import { Loader } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import Grid, {
	Sort,
	Table,
	TableColumnFixed,
	TableColumnResizing,
	TableSelection,
	TableSorting,
	Template,
	TemplateConnector,
} from '@cmpkit/data-table'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import EmptySearchIcon from '@cmpkit/icon/lib/glyph/empty-search'

import intl from '@/locale'

import { ScenarioSummaryTableProps } from '../../types'

export function ScenarioSummaryTable({
	rows,
	columns,
	isLoading,
	getRowId,
	selected,
	setSelected,
	isError,
}: ScenarioSummaryTableProps) {
	const [columnsWidth, setColumnsWidth] = useState<Record<string, number>>({})
	const [sort, setSort] = useState<Sort[]>([])
	const defaultLocks = columns.filter((c) => c.locked).map((c) => c.id)
	return (
		<div className='relative h-fit overflow-hidden rounded-lg border border-solid border-base bg-accent-4'>
			<Blanket
				className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-lg dark:bg-black/50'
				isTinted={isLoading}
			>
				<div className='flex h-52 flex-col items-center justify-center space-y-2 text-center'>
					<Loader />
					<span>
						{intl
							.get('scenarios.summary.loading.text')
							.d('It could take some time.')}
					</span>
				</div>
			</Blanket>
			<Blanket
				isTinted={isError}
				className='absolute flex items-center justify-center rounded-lg border border-solid border-base bg-white/50 backdrop-blur-lg dark:bg-black/50'
			>
				<div
					className={
						'flex h-52 flex-col items-center justify-center space-y-2 text-center'
					}
				>
					<AlertIcon width={60} height={60} className='fill-danger' />
					<span className='text-danger'>
						{intl.get('error.unrecoverable.title')}
					</span>
				</div>
			</Blanket>

			<Grid
				rows={rows}
				columns={columns}
				getRowId={getRowId}
				getRowHeight={() => 50}
			>
				<Table
					className='layout-content size-full overflow-hidden'
					divideX
					divideY
				/>
				<TableSorting sort={sort} onSortChange={setSort} />
				<TableSelection selected={selected} setSelected={setSelected} />
				<TableColumnResizing
					columnsWidth={columnsWidth}
					onSaveColumnsWidth={setColumnsWidth}
				/>
				<TableColumnFixed
					defaultLocks={defaultLocks}
					columnsLocks={defaultLocks}
				/>
				<Template name='tableNoData'>
					<TemplateConnector>
						{() => (
							<div className='flex h-52 flex-col items-center justify-center space-y-2 text-center'>
								<EmptySearchIcon width={60} height={60} className='m-3' />
								<span className='font mb-2 text-xs text-muted'>
									{intl
										.get('scenarios.summary.empty')
										.d('No data found to load')}
								</span>
							</div>
						)}
					</TemplateConnector>
				</Template>
			</Grid>
		</div>
	)
}
