import initNumbers, { NumberFormats } from '@cmpkit/numbers'

import { currentLocale } from '@/locale'

export { NumberFormats } from '@cmpkit/numbers'

const numbers = initNumbers({
	locale: currentLocale,
	currencyCode: 'USD',
})
export const formatNumber = (
	value: number,
	format: NumberFormats = NumberFormats.Standart
) => numbers.formatNumber(value, format)
