import { clone, isArray } from 'lodash/fp'

import { TreeNodeType } from '@cmpkit/base'

export const toggleItem = (list: (string | number)[], id: string | number) =>
	list.includes(id) ? list.filter((i) => i != id) : [...list, id]

type HasChildren<T extends TreeNodeType> = T & {
	children?: readonly HasChildren<T>[]
}
/**
 * Filters the nodes of a tree based on a given condition.
 *
 * @template TItem - The type of the tree nodes.
 * @param data - The array of tree nodes.
 * @param check - The condition function to filter the nodes.
 * @returns An array of filtered tree nodes.
 */
export function filterNodesOfTree<TItem extends HasChildren<TreeNodeType>>(
	data: readonly TItem[],
	check: (item: TItem) => boolean
): TItem[] {
	const findNodes = (nodes: readonly TItem[] = []) => {
		const nextNodes: TItem[] = []
		for (let i = 0; i < nodes.length; i += 1) {
			if (isArray(nodes[i].children)) {
				const nextChildren = findNodes(nodes[i].children as TItem[])
				if (nextChildren.length) {
					const item = clone(nodes[i])
					item.children = nextChildren
					nextNodes.push(item)
					continue
				}
			}

			if (check(nodes[i])) {
				nextNodes.push(nodes[i])
			}
		}

		return nextNodes
	}

	return findNodes(data)
}

export function mapNodesOfTree<TItem extends HasChildren<TreeNodeType>>(
	data: readonly TItem[],
	mapper: (item: TItem) => TItem
): TItem[] {
	const findNodes = (nodes: readonly TItem[] = []) => {
		const nextNodes: TItem[] = []
		for (let i = 0; i < nodes.length; i += 1) {
			if (isArray(nodes[i].children)) {
				const nextChildren = findNodes(nodes[i].children as TItem[])
				if (nextChildren.length) {
					const item = clone(nodes[i])
					item.children = nextChildren
					nextNodes.push(item)
					continue
				}
			}
			nextNodes.push(mapper(nodes[i]))
		}

		return nextNodes
	}

	return findNodes(data)
}

export function flatTree(tree: TreeNodeType[]): TreeNodeType[] {
	return tree.reduce((acc, node) => {
		const children = node.children ? flatTree(node.children) : []
		return [...acc, node, ...children]
	}, [] as TreeNodeType[])
}
