import fastEq from 'fast-deep-equal'
import {
	filter,
	groupBy,
	join,
	map,
	merge,
	omitAll,
	paths,
	pipe,
	props,
	uniqBy,
} from 'lodash/fp'
import memoizeOne from 'memoize-one'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router'

import {
	Button,
	Footer,
	Header,
	InlineMessage,
	Layout,
	Loader,
	Pagination,
	TreeNodeType,
	usePagination,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import { DataRow, Sort } from '@cmpkit/data-table'
import { useDebounce } from '@cmpkit/hooks'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'
import { Operators } from '@cmpkit/query-builder'

import { DataOption } from '@/common.types'
//import Breadcrumbs from '@/components/Breadcrumbs'
import { fixQuery } from '@/components/data-grid/helpers'
import SelectionMessagePanel from '@/components/data-grid/SelectionMessagePanel'
import { dialog } from '@/components/dialogs'
import { toFilterRules } from '@/components/filter/utils'
import { PageNavigatorRegister } from '@/components/PageNavigator'
//import { PageNavigatorRegister } from '@/components/PageNavigator'
import NoData from '@/components/placeholders/NoData'
import {
	FilterRuleModel,
	PricingCampaignModel,
	PricingLineModel,
	ProductModel,
	ProductsQueryRequestModel,
} from '@/generated'
import { useCanEditModelInput, useOptimizationGroup } from '@/hooks/data'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import {
	getAlertAnnotationDataChoices,
	OptimizationGroupActions,
} from '@/modules/core'
import { useCheckOptimizationForDeleteExport } from '@/modules/core/hooks/useCheckOptimizationForDeleteExport'
import { useBeforeGroupChange } from '@/modules/core/mutations'
import {
	useBrandsQuery,
	useCategoriesQuery,
	usePricingAlertsAnnotationsQuery,
} from '@/modules/core/queries'
import { toCategoriesTree } from '@/modules/core/utils'
import { useDrawer, useDrawersStore } from '@/modules/drawers/store'
import { useModalStore } from '@/modules/modals/store'
import { getPcBadgeVariant } from '@/modules/pricing-campaigns'
import { getPricingCampaignEngine } from '@/modules/pricing-campaigns/helpers'
import {
	useAssignProductsMutation,
	usePricingCampaignUnassignProducts,
} from '@/modules/pricing-campaigns/mutations'
import { usePricingCampaignsQuery } from '@/modules/pricing-campaigns/queries'
import analytic from '@/services/analytics'
import { useColumnManagerControllerAdapter } from '@/tools/columns'
import { formatNumber, NumberFormats } from '@/tools/locale'
import { getLocationQuery, toQueryString } from '@/tools/location'
import { toOption } from '@/tools/utils'

import { useColumns } from '../columns'
import { PRICING_TYPE_CHOICES } from '../constants'
import { unwrapLines } from '../data'
import { useProductsListQuery } from '../queries'
import { useProductsStore, useTableConfigStore } from '../store'
import { getAssortmentItemId } from '../utils'
//import ProductsHeader from './ProductsHeader'
import ProductsTable from './ProductsTable'
import ProductsToolbar from './ProductsToolbar'

export default function ProductsPage() {
	const { closeDrawer } = useDrawersStore()
	const { showModal } = useModalStore()
	const navigate = useNavigate()
	const location = useLocation()
	const optimizationGroupId = useOptimizationGroupId()!
	const optimizationGroup = useOptimizationGroup(optimizationGroupId)
	const { onMutate } = useBeforeGroupChange(optimizationGroupId)
	const checkExport = useCheckOptimizationForDeleteExport(optimizationGroupId)
	const canEditModelInput = useCanEditModelInput(optimizationGroupId)
	const query = getLocationQuery(location.search)
	const config = useTableConfigStore()
	const { selected, setSelected, searchText } = useProductsStore()
	const dataChoices = useDataChoices(optimizationGroupId)
	const { columns, columnsDefinitions } = useColumns({
		dataChoices,
	})
	const columnsConfig = useColumnManagerControllerAdapter(
		config,
		columnsDefinitions
	)
	const [sort, setSort] = useState<Sort[]>([])
	const [isAllProductsSelected, setAllProductsSelected] =
		useState<boolean>(false)

	const filters = useMemo(
		() => toFilterRules(omitAll(['offset', 'limit', 'sort'], query)),
		[query]
	)
	const searchTextDebounces = useDebounce(searchText, 1000)
	useEffect(() => {
		handleClearSelected()
	}, [location.search, searchTextDebounces])
	useEffect(() => {
		return () => closeDrawer('PRODUCT_EASTSIDE')
	}, [])
	const requestBody: ProductsQueryRequestModel = {
		limit: Number(query?.limit) || 100,
		offset: Number(query?.offset) || 0,
		filters: [
			{
				name: 'optimization_group_id',
				operation: Operators.IS,
				value: optimizationGroupId,
			},
			...fixQuery(filters),
		],
		search: searchTextDebounces || undefined,
		order_by:
			sort
				?.filter(({ direction }) => direction === 'asc' || direction === 'desc')
				.map(({ columnId, direction }) => ({
					name: columnId,
					direction: direction as 'asc' | 'desc' | undefined,
				})) ?? [],
	}

	/**
	 * Queries
	 */
	const assortmentQuery = useProductsListQuery(
		optimizationGroupId,
		requestBody,
		{
			placeholderData: (previousData) => previousData,
		}
	)
	const assignProducts = useAssignProductsMutation({
		onMutate,
	})
	const unassignProducts = usePricingCampaignUnassignProducts({
		onMutate,
	})

	/**
	 * Pagination business logic
	 */
	const handleChangePagination = useCallback(
		(paginationMeta: { limit?: number; offset?: number }) => {
			navigate(
				toQueryString(
					pipe([omitAll(['limit', 'offset']), merge(paginationMeta)])(query)
				)
			)
		},
		[query]
	)
	const pagination = usePagination({
		total: assortmentQuery.data?.total_count ?? 0,
		limit: Number(requestBody.limit),
		offset: Number(requestBody.offset),
		onChange: (props) => {
			if (isAllProductsSelected) {
				setSelected([])
				setAllProductsSelected(false)
			}
			return handleChangePagination(props)
		},
	})

	/**
	 * Calculated values
	 */
	const rows = assortmentQuery.data?.data || []
	const childrenRows = useMemo(
		() =>
			groupBy(
				getNodeId,
				linesToProducts(assortmentQuery.data?.data || [])
			) as Record<string, ProductModel[]>,
		[assortmentQuery.data?.data]
	)
	const skus = useMemo(
		() => getSkus(assortmentQuery.data?.data || []),
		[assortmentQuery.data?.data]
	)
	const getChildTreeItems = (row: DataRow<PricingLineModel>): ProductModel[] =>
		childrenRows[getNodeId(row.content)] || []

	const selectedFilters = useMemo(() => {
		if (isAllProductsSelected) {
			return filters.length
				? filters
				: [
						{
							name: 'sku',
							operation: Operators.IS_NOT_EMPTY,
							value: 1,
						},
					]
		}
		return [
			{
				name: 'sku',
				operation: Operators.IN,
				value: parseRowIds(selected),
			},
		]
	}, [selected, isAllProductsSelected, filters])

	const doIfCanEdit = useCallback(
		(
			fn: any // eslint-disable-line @typescript-eslint/no-explicit-any
		) =>
			(
				...args: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
			) =>
				canEditModelInput
					? fn(...args)
					: toast(intl.get('model_input_change_denied'), {
							icon: <AlertIcon className='fill-warn' />,
						}),
		[canEditModelInput]
	)

	const handleOpenBulkModal = () =>
		showModal('BULK_UPDATE', {
			filters: selectedFilters,
			//selected: [...selected],
		})
	const handleClearSelected = () => {
		setSelected([])
		setAllProductsSelected(false)
	}
	const handleAllProductsSelection = () => {
		setAllProductsSelected(true)
		setSelected(getSkus(assortmentQuery.data?.data || []))
	}
	const handleOpenImportModal = () => showModal('IMPORT_UPDATE')
	const handleOpenExportModal = () =>
		showModal('EXPORT_ASSORTMENT', {
			context: requestBody,
			columnsConfigs: columnsConfig.columnsConfig,
			defaultName: `Products ${optimizationGroup.name}`,
		})
	const handleCreatePricingCampaign = useCallback(
		({
			draft,
		}: {
			optimizationGroupId?: string | null
			scenarioId?: string
			draft?: {
				name?: string
				engine?: string
				assignment_filters?: FilterRuleModel[]
			}
		}) => {
			showModal('PRICING_CAMPAIGN_MODAL', {
				optimizationGroupId,
				draft: {
					...(draft || {}),
					assignment_filters: selectedFilters,
				},
			})
		},
		[selectedFilters, showModal]
	)
	const handleAssignToPricingCampaign = async (
		campaign: PricingCampaignModel | string
	) => {
		if (campaign === '') {
			const answer = await dialog.confirm({
				title: intl.get('confirm.unassign_pricing_campaigns.title'),
				text: intl.get('confirm.unassign_pricing_campaigns.subtitle'),
			})
			if (answer) {
				analytic.logEvent('table: unassign from pricing campaigns')
				await unassignProducts.mutateAsync({
					filters: selectedFilters,
				})
				setSelected([])
			}
		} else if (typeof campaign !== 'string') {
			const answer = await dialog.confirm({
				title: intl.get('confirm.assign_to.static.title'),
				text: intl.get('confirm.assign_to.static.subtitle'),
			})
			if (answer) {
				analytic.logEvent('table: assign to pricing campaign', {
					'Assign to': 'Existing',
				})
				await assignProducts.mutateAsync({
					pricingCampaignId: campaign.id,
					data: {
						filters: selectedFilters,
					},
				})
				setSelected([])
			}
		}
	}
	const showSelectAllMessage = useMemo(
		() =>
			!!skus.length &&
			skus.every((id) => selected.includes(id)) &&
			skus.length >= (requestBody?.limit || 0),
		[selected, skus]
	)

	const eastside = useDrawer('PRODUCT_EASTSIDE')
	const selectedRow =
		eastside.props?.content && getAssortmentItemId(eastside.props?.content)

	const rowClassNameGetter = useCallback(
		(index: number, el: DataRow<PricingLineModel | ProductModel>) => {
			return selectedRow && getAssortmentItemId(el.content) === selectedRow
				? 'bg-brand-25 dark:bg-brand-175'
				: ''
		},
		[selectedRow]
	)

	return (
		<Layout className='relative h-full overflow-hidden px-4'>
			<PageNavigatorRegister>
				<OptimizationGroupActions requestBody={requestBody} />
			</PageNavigatorRegister>
			<div className='fade-in flex size-full flex-col overflow-hidden'>
				{assortmentQuery.isError && (
					<InlineMessage
						className='rounded-none border-b border-solid border-base'
						variant='danger'
					>
						{intl.get('assortment.table.error')}
					</InlineMessage>
				)}
				<Blanket
					data-testid={
						assortmentQuery.isFetching ? 'blanket-visible' : 'blanket-hidden'
					}
					className='absolute flex flex-col items-center justify-center space-y-5 rounded-lg bg-transparent'
					isTinted={assortmentQuery.isFetching}
				>
					<div className='fade-in z-50 rounded-lg bg-white/50 p-5 shadow backdrop-blur-md dark:bg-black/50'>
						<Loader />
					</div>
				</Blanket>
				<Header className='shrink-0 py-2'>
					<ProductsToolbar
						onOpenBulkModal={doIfCanEdit(handleOpenBulkModal)}
						onOpenImportModal={doIfCanEdit(handleOpenImportModal)}
						onAssignToPricingCampaign={checkExport(
							handleAssignToPricingCampaign
						)}
						onCreatePricingCampaign={handleCreatePricingCampaign}
						dataChoices={dataChoices}
						columns={columns}
						columnsDefinitions={columnsDefinitions}
					/>
				</Header>
				{showSelectAllMessage && (
					<SelectionMessagePanel
						selectedProducts={selected?.length}
						totalProducts={assortmentQuery.data?.total_skus || 0}
						isAllSelected={isAllProductsSelected}
						onClearSelection={handleClearSelected}
						onSelectAllProducts={handleAllProductsSelection}
					/>
				)}
				<ProductsTable
					selected={selected}
					rowClassNameGetter={rowClassNameGetter}
					setSelected={(items) => {
						if (isAllProductsSelected) {
							setAllProductsSelected(false)
						}
						setSelected(items as string[])
					}}
					getRowId={getAssortmentItemId}
					rowHeightHandler={() => columnsConfig.rowHeight}
					sort={sort}
					getChildTreeItems={getChildTreeItems}
					setSort={setSort}
					rows={rows}
					columns={columns}
					columnsConfig={columnsConfig}
					emptyDataPlaceholder={
						!assortmentQuery.isFetching && <NoData className='flex-col pt-10' />
					}
				/>
				<Footer className='h-8 grow-0 items-center px-3'>
					{pagination && (
						<div className='mr-auto flex items-center gap-6'>
							<Pagination pagination={pagination} />
							<div className='inline-flex items-center gap-1 text-xs'>
								<span>{intl.get('app.items_on_page')}</span>:
								<strong data-testid='page-items-count'>
									{formatNumber(
										assortmentQuery.data?.data?.length || 0,
										NumberFormats.Integer
									)}
								</strong>
								<strong>{intl.get('general_of')}</strong>
								<strong data-testid='total-items-count'>
									{formatNumber(
										assortmentQuery.data?.total_count || 0,
										NumberFormats.Integer
									)}
								</strong>
							</div>
							{!!assortmentQuery.data?.total_skus && (
								<div className='inline-flex items-center gap-1 text-xs'>
									<span>{intl.get('app.skus_on_page').d('SKUs on page')}:</span>
									<strong data-testid='page-items-flat-count'>
										{formatNumber(skus?.length || 0, NumberFormats.Integer)}
									</strong>
									<strong>{intl.get('general_of')}</strong>
									<strong data-testid='total-items-flat-count'>
										{formatNumber(
											assortmentQuery.data?.total_skus,
											NumberFormats.Integer
										)}
									</strong>
								</div>
							)}
						</div>
					)}
					<div className='ml-auto flex items-center space-x-2'>
						<Button
							size='small'
							className='px-1 py-0'
							data-testid='download-table'
							onClick={handleOpenExportModal}
							iconBefore={<DownloadIcon />}
						>
							{intl.get('app.download_table')}
						</Button>
					</div>
				</Footer>
			</div>
		</Layout>
	)
}

const getNodeId = pipe([props(['line_id']), join('__')])

const linesToProducts = memoizeOne(unwrapLines, fastEq)
const getSkus: (data: PricingLineModel[]) => string[] = pipe([
	linesToProducts,
	map(getAssortmentItemId),
])
const parseRowIds = pipe([
	map((id: string): string => id.split('__')[1]),
	filter(Boolean),
])
const formatOptions = pipe([
	map(paths(['id', 'name'])),
	toOption,
	uniqBy('value'),
])

function useDataChoices(optimizationGroupId: string) {
	const { data: categories } = useCategoriesQuery<TreeNodeType[]>(
		{},
		{ select: toCategoriesTree }
	)

	const { data: brands } = useBrandsQuery<DataOption[]>(
		{},
		{ select: formatOptions }
	)
	const { data: alerts } = usePricingAlertsAnnotationsQuery({
		select: getAlertAnnotationDataChoices,
	})
	const { data: pricingCampaigns } = usePricingCampaignsQuery<DataOption[]>(
		{
			optimization_group_id: optimizationGroupId,
		},
		{
			select: (data) =>
				data.map((pricingCampaign) => ({
					value: pricingCampaign.id,
					label: pricingCampaign.name,
					variant: getPcBadgeVariant(getPricingCampaignEngine(pricingCampaign)),
				})),
		}
	)

	return {
		alerts: alerts || [],
		pricing_type: PRICING_TYPE_CHOICES,
		category_ids: categories || [],
		brands: brands || [],
		pricing_campaign_id: pricingCampaigns || [],
		pricing_campaign_name: pricingCampaigns || [],
	}
}
