import React from 'react'

import * as config from '@/config'
import { UserModel } from '@/generated'
import { useAuth } from '@/modules/Auth/AuthContext'
import { getUsername } from '@/modules/Users/utils'
import { getProject } from '@/tools/utils'

import Intercom from './Intercom'

const intercomSettings = (user: UserModel, projectId?: string) => ({
	user_id: user.id,
	email: user.email,
	name: getUsername(user),
	project: projectId,
	project_type: 'platform',
	widget: { activator: '.fa-comment' },
})
export default function SupportChat() {
	const { user } = useAuth()
	const projectId = getProject()
	return (
		user && (
			<Intercom
				appID={config.REACT_APP_INTERCOM_APP_ID!}
				{...intercomSettings(user, projectId)}
			/>
		)
	)
}
