import { AxiosError } from 'axios'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { PromoCampaignModel, PromoProductModel } from '@/generated'
import { client } from '@/network/client'

export const usePromoCampaignsQuery = (
	options?: UseQueryOptions<PromoCampaignModel[], Error>
) => {
	return useQuery<PromoCampaignModel[], Error>({
		queryKey: ['promo-campaigns'],
		queryFn: () => client.promoCampaigns.listPromoCampaigns(),
		...(options || {}),
	})
}

export const usePromoProductsQuery = <T = PromoProductModel[]>(
	campaignId: string
) => {
	return useQuery<PromoProductModel[], AxiosError, T>({
		queryKey: ['promo-products', campaignId],
		queryFn: () => client.promoCampaigns.listPromoCampaignProducts(campaignId),
	})
}
