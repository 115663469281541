import React from 'react'

import { ModalTransition } from '@cmpkit/modal'

import analytic from '@/services/analytics'

import { useExportConfiguration } from './ExportConfigurationProvider'
import { ExportFieldsModalContent } from './ExportFieldsModal'
import { ExportParamsModalContent } from './ExportParamsModal'

interface ExportModalProps {
	isOpen: boolean
	close(): void
}

export default function ExportModal({ close, isOpen }: ExportModalProps) {
	const {
		kind,
		isAdditional,
		isLoading,
		state: { step, setStep, fields, draftFormData },
		options,
		handleColumnsSubmit,
		handleParamsSubmit,
	} = useExportConfiguration()

	const handleClose = () => close()

	return (
		<ModalTransition>
			{isOpen ? (
				step === 'params' ? (
					<ExportParamsModalContent
						isAdditional={isAdditional}
						isLoading={isLoading}
						onSubmit={handleParamsSubmit}
						onClose={handleClose}
						defaultValues={draftFormData}
						options={options}
					/>
				) : (
					<ExportFieldsModalContent
						isLoading={isLoading}
						onSubmit={handleColumnsSubmit}
						onClose={handleClose}
						onBack={() => {
							analytic.track('export: params: fields configuration: back', {
								export_kind: kind,
							})
							setStep('params')
						}}
						defaultValues={{
							fields,
						}}
					/>
				)
			) : null}
		</ModalTransition>
	)
}
