import { Locale, setDefaultOptions } from 'date-fns'
import { de as datesDe } from 'date-fns/locale/de'
import { enGB as datesEn } from 'date-fns/locale/en-GB'
import { es as datesEs } from 'date-fns/locale/es'
import { uk as datesUk } from 'date-fns/locale/uk'
import { merge } from 'lodash/fp'
import * as intl from 'react-intl-universal'

import otaClient from '@crowdin/ota-client'

import logger from '@/tools/logger'

import manifest from './locales/manifest.json'
import translations from './locales/translations.json'

type Translations = typeof initialLocales
type Lang = keyof Translations

const fallbackLocale = 'en'

const initialLocales = translations

/**
 * Get short language code from full language code
 * @example en-US -> en
 * @example en_US -> en
 * @param lang
 * @returnsу🥲
 */
const getShortLang = (lang: string): string => {
	let shortLang = lang
	if (shortLang.indexOf('-') !== -1) shortLang = shortLang.split('-')[0]
	if (shortLang.indexOf('_') !== -1) shortLang = shortLang.split('_')[0]
	return shortLang
}

export const getDatesLocale = (): Locale => {
	return dateLocales[currentLocale] || datesEn
}

/**
 * date-fns localization packages
 */
const dateLocales: Record<string, Locale> = {
	en: datesEn as unknown as Locale,
	uk: datesUk as unknown as Locale,
	de: datesDe as unknown as Locale,
	es: datesEs as unknown as Locale,
}

export const langCodeMapping: Record<string, string> = {
	de: 'de-DE',
	en: 'en-US',
	uk: 'uk-UA',
	es: 'es',
}
/**
 * Detect locale from url, cookie or local storage
 * @example http://localhost:3000/?lang=uk -> uk
 * @example http://localhost:3000/?lang=uk_UA -> uk
 * @example http://localhost:3000/?lang=uk-UA -> uk
 */
const originalLocale = intl.determineLocale({
	urlLocaleKey: 'lang',
	cookieLocaleKey: 'lang',
	localStorageLocaleKey: 'lang',
})

const detectedLocale = getShortLang(originalLocale)

/**
 * Set current locale or fallback locale
 */
export const currentLocale = (
	!!(initialLocales as any)[detectedLocale] // eslint-disable-line
		? detectedLocale
		: fallbackLocale
) as Lang

/**
 * Set date-fns locale to current locale or fallback locale
 * if current locale is not supported
 */
setDefaultOptions({ locale: dateLocales[currentLocale] || datesEn })

/**
 * Initialize react-intl-universal
 */
process.env.NODE_ENV !== 'test' &&
	logger.info('INTL', `build with ${new Date(manifest.timestamp * 1000)}`)

intl.init({
	currentLocale,
	fallbackLocale,
	locales: initialLocales,
	warningHandler: () => null,
})

async function getSyncTranslations() {
	try {
		const client = new otaClient(process.env.CROWDIN_DIST_HASH as string)
		const timestamp = await client.getManifestTimestamp()
		if (manifest.timestamp !== timestamp) {
			logger.info('INTL', `sync with ${new Date(timestamp * 1000)}`)
			const [{ content }] = await client.getLanguageTranslations(currentLocale)
			return {
				[currentLocale]: merge(initialLocales[currentLocale], content),
			}
		} else {
			return {}
		}
	} catch (error) {
		console.error('Crowdin OTA error', error) // eslint-disable-line
		return {}
	}
}
const syncInterval = 1000 * 60 * 60 * 24
export async function loadTranslations() {
	if (process.env.NODE_ENV !== 'development') {
		// check if we need to sync translations based on last sync time
		const lastSync = localStorage.getItem('_intl_sync')
		if (lastSync) {
			const diff = new Date().getTime() - parseInt(lastSync, 10)
			if (diff < syncInterval) {
				return
			}
		}
		localStorage.setItem('_intl_sync', new Date().getTime().toString())
		const locales = await getSyncTranslations()
		intl.load(locales)
	}
}
loadTranslations()

export default intl
