import {
	entries,
	groupBy,
	map,
	paths,
	pipe,
	prop,
	reduce,
	uniqBy,
} from 'lodash/fp'
import React from 'react'
import { components } from 'react-select'

import { Checkbox } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'
import { GroupBase, OptionProps, OptionType } from '@cmpkit/select'

import { SelectFilter, TreeSelectFilter } from '@/components/refinement-bar'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { getAlertAnnotationDataChoices } from '@/modules/core'
import { toOption } from '@/tools/utils'

import {
	useBrandsQuery,
	useCategoriesQuery,
	useOptimizationGroupsQuery,
	usePricingAlertsAnnotationsQuery,
} from '../core/queries'
import { toCategoriesTree } from '../core/utils'
import { EngineBadge } from '../pricing-campaigns'
import { usePricingCampaignsQuery } from '../pricing-campaigns/queries'
import { pcToOption } from '../pricing-campaigns/utils'

/**
 * Confoguration for specific filters that are not included in the schema
 */
export const FIELD_CONFIG = {
	alerts: {
		label: intl.get('field_schema_alerts'),
		type: SelectFilter,
		getInitialValue: () => ({
			operation: Operators.ANY_OF,
			value: [],
		}),
		useSelectOptions: () => {
			return usePricingAlertsAnnotationsQuery({
				select: getAlertAnnotationDataChoices,
			})
		},
	},
	optimization_group_id: {
		label: intl.get('field_schema_optimization_group'),
		getInitialValue: () => ({
			operation: Operators.IN,
			value: [],
		}),
		type: SelectFilter,
		useSelectOptions: () => {
			return useOptimizationGroupsQuery({
				select: pipe([map(paths(['id', 'name'])), toOption, uniqBy('value')]),
			})
		},
	},
	pricing_campaign_id: {
		label: intl.get('field_schema_pricing_campaign'),
		getInitialValue: () => ({
			operation: Operators.IN,
			value: [],
		}),
		type: SelectFilter,
		useSelectOptions: () => {
			const optimizationGroupId = useOptimizationGroupId()
			const optimizationGroupsQuery = useOptimizationGroupsQuery<
				Record<string, string>
			>({
				select: reduce(
					(acc, group) => ({ ...acc, [group.id]: group.name }),
					{}
				),
			})
			const pricingCampaignsQuery = usePricingCampaignsQuery(
				optimizationGroupId
					? {
							optimization_group_id: optimizationGroupId,
						}
					: {}
			)
			const data = pipe([
				groupBy(prop('optimization_group_id')),
				entries,
				reduce(
					(acc, [id, list]) => [
						...acc,
						{
							label: optimizationGroupsQuery.data?.[id] || 'Glob',
							options: map(pcToOption, list),
						},
					],
					[] as GroupBase<OptionType>[]
				),
			])(pricingCampaignsQuery.data || [])
			return {
				isLoading:
					pricingCampaignsQuery.isLoading || optimizationGroupsQuery.isLoading,
				data,
			}
		},
		components: {
			Option: (props: OptionProps<OptionType, true>) => (
				<components.Option
					{...props}
					className='flex w-96 justify-between hover:bg-accent-3'
				>
					<div
						className='mr-4 flex flex-1 items-center space-x-2 overflow-hidden text-sm'
						onClick={props.innerProps.onClick}
					>
						<Checkbox checked={props.isSelected} />
						<div className='line-clamp-3'>{props.children}</div>
					</div>
					<EngineBadge engine={props.data.engine}>
						{intl.get('campaigns_om_title_' + props.data.engine)}
					</EngineBadge>
				</components.Option>
			),
		},
	},
	brands: {
		label: intl.get('field_schema_brands'),
		type: SelectFilter,
		getInitialValue: () => ({
			operation: Operators.ANY_OF,
			value: [],
		}),
		useSelectOptions: () => {
			const optimizationGroupId = useOptimizationGroupId()
			return useBrandsQuery(
				optimizationGroupId
					? { optimization_group_id: optimizationGroupId }
					: {}
			)
		},
	},
	category_ids: {
		label: intl.get('field_schema_categories'),
		type: TreeSelectFilter,
		getInitialValue: () => ({
			operation: Operators.ANY_OF,
			value: [],
		}),
		useSelectOptions: () => {
			const optimizationGroupId = useOptimizationGroupId()
			return useCategoriesQuery(
				optimizationGroupId
					? {
							optimization_group_id: optimizationGroupId,
						}
					: {},
				{ select: toCategoriesTree }
			)
		},
	},
}
