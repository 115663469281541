import {
	Active,
	defaultDropAnimationSideEffects,
	DragOverlay,
	DropAnimation,
	useDndMonitor,
} from '@dnd-kit/core'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { BuilderBlockElementOverlay } from './BuilderBlockElement'
const dropAnimationConfig: DropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: '0.5',
			},
		},
	}),
}
export default function DragOverlayWrapper() {
	const [draggedItem, setDraggedItem] = useState<Active | null>(null)
	useDndMonitor({
		onDragStart(event) {
			setDraggedItem(event.active)
		},
		onDragCancel() {
			setDraggedItem(null)
		},
		onDragEnd() {
			setDraggedItem(null)
		},
	})
	if (draggedItem && draggedItem.data) {
		return createPortal(
			<DragOverlay dropAnimation={dropAnimationConfig}>
				<BuilderBlockElementOverlay block={draggedItem.data.current?.block} />
			</DragOverlay>,
			document.body
		)
	}
	return null
}
