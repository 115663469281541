import clsx from 'clsx'
import React from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import { FormError, FormGroup, FormLabel, Required } from '@cmpkit/base'
import HelpCircleIcon from '@cmpkit/icon/lib/glyph/help-circle'
import Tooltip from '@cmpkit/tooltip'

import { DragNDropArea } from '../DragNDropArea'

export default function RHFFileDropArea({
	name,
	label,
	hint,
	defaultValue,
	shouldUnregister,
	required,
	className,
	multiple,
	...other
}: UseControllerProps & {
	label?: string
	hint?: string
	multiple?: boolean
	required?: boolean
	className?: string
}) {
	const { control } = useFormContext()
	return (
		<FormGroup className={clsx('flex flex-col', className)}>
			{!!label && (
				<FormLabel>
					{label}
					{required && <Required />}
				</FormLabel>
			)}
			<Controller
				name={name}
				defaultValue={defaultValue}
				shouldUnregister={shouldUnregister}
				control={control}
				render={({ field, fieldState: { error } }) => {
					return (
						<>
							<DragNDropArea
								{...field}
								{...other}
								isInvalid={!!error}
								multiple={multiple}
							/>
							{hint && (
								<Tooltip placement='top' content={hint}>
									<span className='flex items-center'>
										<HelpCircleIcon className='mx-2.5 flex items-center text-muted' />
									</span>
								</Tooltip>
							)}

							{error && <FormError>{error.message}</FormError>}
						</>
					)
				}}
			/>
		</FormGroup>
	)
}
