import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
	ContentType,
	DiffValidationResponseModel,
	PostValidatorsRequestModel,
	ProductDiffsSchema,
	Products,
	UpdateProductsFieldsByFiltersRequestModel,
} from '@/generated'
import { client } from '@/network/client'

export function useValidateProductDiffFileMutation(
	options?: UseMutationOptions<
		DiffValidationResponseModel,
		Error,
		PostValidatorsRequestModel
	>
) {
	return useMutation<
		DiffValidationResponseModel,
		Error,
		PostValidatorsRequestModel
	>({
		mutationFn: (payload) => client.util.postValidators(payload),
		...(options || {}),
	})
}

export function useUpdateProductsModelFieldsByFiltersMutation(
	optimizationGroupId: string,
	options?: UseMutationOptions<
		Products.UpdateProducsModelFieldsByFilters.ResponseBody,
		Products.UpdateProducsModelFieldsByFilters.ResponseBody,
		UpdateProductsFieldsByFiltersRequestModel
	>
) {
	return useMutation<
		Products.UpdateProducsModelFieldsByFilters.ResponseBody,
		Products.UpdateProducsModelFieldsByFilters.ResponseBody,
		UpdateProductsFieldsByFiltersRequestModel
	>({
		mutationFn: (body) => {
			return client.products.updateProducsModelFieldsByFilters(
				optimizationGroupId,
				'last',
				body
			)
		},
		...(options || {}),
	})
}
export function useUpdateProductsFinalFieldsByFiltersMutation(
	optimizationGroupId: string,
	options?: UseMutationOptions<
		Products.UpdateProducsFinalFieldsByFilters.ResponseBody,
		Products.UpdateProducsFinalFieldsByFilters.ResponseBody,
		UpdateProductsFieldsByFiltersRequestModel
	>
) {
	return useMutation<
		Products.UpdateProducsFinalFieldsByFilters.ResponseBody,
		Products.UpdateProducsFinalFieldsByFilters.ResponseBody,
		UpdateProductsFieldsByFiltersRequestModel
	>({
		mutationFn: (body) => {
			return client.products.updateProducsFinalFieldsByFilters(
				optimizationGroupId,
				'last',
				body
			)
		},
		...(options || {}),
	})
}

export function useUpdateProductsDiffsMutation(
	optimizationGroupId: string,
	options?: UseMutationOptions<
		Products.UpdateProductDiffs.ResponseBody,
		{
			success: boolean
			message: string
		},
		ProductDiffsSchema
	>
) {
	return useMutation<
		Products.UpdateProductDiffs.ResponseBody,
		{
			success: boolean
			message: string
		},
		ProductDiffsSchema
	>({
		mutationFn: (body) => {
			return client.products.updateProductDiffs(
				optimizationGroupId,
				'last',
				body,
				{
					type:
						body.product_diffs instanceof File
							? ContentType.FormData
							: ContentType.Json,
				}
			)
		},
		...(options || {}),
	})
}
