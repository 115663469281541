import React from 'react'

import { Modal, ModalTransition } from '@cmpkit/modal'

import ErrorBoundary from '@/components/ErrorBoundary'
import { SettingsTemplateModel, SettingsTemplateType } from '@/generated'

import { ApplyToOptimizationGroups } from './ApplyToOptimizationGroups'
import { ApplyToPricingCampaigns } from './ApplyToPricingCampaigns'

export default function ApplySettingsTemplateModal({
	isOpen,
	close,
	template,
}: {
	isOpen: boolean
	close: () => void
	template: SettingsTemplateModel
}) {
	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					onClose={close}
					disableFocusTrap
					showCloseButton
					size={'xlarge'}
					testId='apply-template-modal'
				>
					<ErrorBoundary>
						{[
							SettingsTemplateType.PricingCampaignAnchoring,
							SettingsTemplateType.PricingCampaignLimits,
							SettingsTemplateType.PricingCampaignMinimalSteps,
							SettingsTemplateType.PricingCampaignProductAssignment,
							SettingsTemplateType.PricingCampaignRounding,
							SettingsTemplateType.PricingCampaignTacticsMd,
							SettingsTemplateType.PricingCampaignTacticsMl,
							SettingsTemplateType.PricingCampaignTacticsRb,
							SettingsTemplateType.PricingCampaign,
						].includes(template.template_type) && (
							<ApplyToPricingCampaigns template={template} close={close} />
						)}
						{[
							SettingsTemplateType.Scenario,
							SettingsTemplateType.ScenarioBusinessConstraints,
							SettingsTemplateType.ScenarioStopList,
							SettingsTemplateType.ScenarioStrategy,
						].includes(template.template_type) && (
							<ApplyToOptimizationGroups template={template} close={close} />
						)}
					</ErrorBoundary>
				</Modal>
			)}
		</ModalTransition>
	)
}
