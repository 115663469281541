import clsx from 'clsx'
import React from 'react'

export type LoaderProps = {
	className?: string
	size?: string | number
	backdrop?: boolean
	inline?: boolean
	label?: string
	opacity?: boolean
	[x: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}
export default function Loader({
	className,
	size,
	backdrop,
	inline,
	label,
	opacity,
	...props
}: LoaderProps) {
	return (
		<div
			className={clsx('loader', {
				'bg-accent-1': backdrop,
				'opacity-50': opacity,
				'loader--inline': inline,
			})}
		>
			<div
				className={clsx(
					'loading-spinner',
					className,
					size ? 'loading-spinner-size-' + size : ''
				)}
				{...props}
			>
				<div className='loading-spinner-part-1' />
				<div className='loading-spinner-part-2' />
			</div>
			{label && <span className='loader__label'>{label}</span>}
		</div>
	)
}
