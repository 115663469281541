import { filter, map, paths, pipe, prop } from 'lodash/fp'
import React, { useMemo } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardTitle,
	FormError,
	Header,
	InlineMessage,
	Separator,
	TreeNodeType,
} from '@cmpkit/base'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import QueryBuilder, {
	getFiledConfig,
	useFilters,
	ValueType,
} from '@cmpkit/query-builder'

import ErrorBoundary from '@/components/ErrorBoundary'
import { proxyRuleChange, toAdaptedSchema } from '@/components/filter/adapter'
import { localization } from '@/components/filter/localization'
import { FilterField } from '@/components/filter/types'
import { RHFInlineEditText } from '@/components/HookForm'
import { MenuList } from '@/components/SelectComponents'
import {
	DemandPricingTacticsModel,
	FilterModel,
	FilterRuleModel,
	MarkdownPricingTacticsModel,
	MinimalStepRuleModel,
	PriceLimitsModel,
	ProductAssignmentModel,
	RuleBasePricingTacticsModel,
	SettingsTemplateType,
} from '@/generated'
import intl from '@/locale'
import {
	useBrandsQuery,
	useCategoriesQuery,
	useColumnsSchemaQuery,
	useOptimizationGroupsQuery,
} from '@/modules/core/queries'
import { toCategoriesTree } from '@/modules/core/utils'
import {
	SettingActionsDropdown,
	usePricingCampaignContext,
	useSettingActions,
} from '@/modules/pricing-campaigns'
import { ErrorSchema } from '@/tools/json-schema-utils'
import { toOption } from '@/tools/utils'

export type SettingType =
	| {
			name: string
			filters: FilterModel[]
	  }
	| DemandPricingTacticsModel
	| MarkdownPricingTacticsModel
	| RuleBasePricingTacticsModel
	| MinimalStepRuleModel[]
	| PriceLimitsModel

const isPreproField = ({ stage }: { stage: string }) =>
	['prepro', 'pre_init_fields'].includes(stage)

type AssignmentSet = {
	name: string
	filters: FilterRuleModel[]
}
type AutoAssignmentPanelProps = {
	onChange(data: AssignmentSet): void
	formData: AssignmentSet
	error?: ErrorSchema
}
function AutoAssignmentPanel({
	formData,
	onChange,
	error,
}: AutoAssignmentPanelProps) {
	const filters = formData?.filters || []
	/**
	 * Queries
	 */
	const { data: brands } = useBrandsQuery(
		{},
		{
			select: pipe([map(paths(['id', 'name'])), toOption]),
		}
	)
	const { data: categories } = useCategoriesQuery<Array<TreeNodeType>>(
		{},
		{
			select: toCategoriesTree,
		}
	)
	const { data: optimizationGroups } = useOptimizationGroupsQuery({
		select: pipe([map(paths(['id', 'name'])), toOption]),
	})
	const fieldsQuery = useColumnsSchemaQuery<FilterField[]>({
		select: pipe([filter(isPreproField), map(toAdaptedSchema)]),
	})
	const fields = useMemo(() => {
		if (!fieldsQuery.data?.length) {
			return []
		} else {
			return [
				{
					enum: [],
					label: intl.get('field_schema_optimization_group'),
					value: 'optimization_group_id',
					//valueEditorType: ValueEditorType.multiselect,
					valueType: ValueType.str,
					//operations: [Operators.IN, Operators.NOT_IN],
				},
				...(fieldsQuery.data || []),
			]
		}
	}, [fieldsQuery.data])

	const handleFiltersChange = (filters: FilterRuleModel[]) => {
		onChange({
			name: formData?.name,
			filters,
		})
	}
	const rulesController = useFilters({
		filters,
		onChange: handleFiltersChange,
	})

	const handleAddRule = () =>
		rulesController.addRule({
			name: '',
			operation: '',
			value: '',
		})

	const fieldConfig = getFiledConfig(fields, {
		brands: brands || [],
		category_ids: categories || [],
		optimization_group_id: optimizationGroups || [],
	})

	return (
		<>
			{fieldsQuery.isLoading ? (
				<div className='flex flex-col space-y-2'>
					<div className='h-10 w-full animate-pulse rounded-lg bg-accent-3' />
					<div className='h-10 w-full animate-pulse rounded-lg bg-accent-3' />
				</div>
			) : (
				<ErrorBoundary
					fallback={() => (
						<InlineMessage variant='danger'>
							🐞{' '}
							{intl
								.get('ui_fragment_error')
								.d(
									'Oops ... it looks like an unexpected error has occurred. Our team`s been notified.'
								)}
						</InlineMessage>
					)}
				>
					<QueryBuilder
						rules={rulesController.rules}
						fieldConfig={{
							...fieldConfig,
							brands: {
								...fieldConfig.brands,
								components: {
									MenuList,
								},
							},
						}}
						locale={localization}
						errorSchema={error?.filters}
						onChange={proxyRuleChange(rulesController.changeRule)}
						onDelete={rulesController.removeRule}
						onAdd={handleAddRule}
					/>
				</ErrorBoundary>
			)}
			{prop('filters', error) && (
				<FormError>
					{prop('filters', error)?.message ||
						intl.get('validation.invalid_rules').d('Invalid rules')}
				</FormError>
			)}
			{filters?.length === 0 && (
				<Button
					variant='tertiary'
					iconBefore={<PlusIcon />}
					onClick={handleAddRule}
				>
					{intl.get('filters_add_filter')}
				</Button>
			)}
		</>
	)
}

export function AssignmentSetsFieldController({ name }: { name: string }) {
	const { watch, setValue, getValues, control } = useFormContext()
	const context = usePricingCampaignContext()
	const pricingCampaignId = context?.pricingCampaign?.id
	const pricingCampaignNameWatcher = getValues('name')
	const assignments: ProductAssignmentModel = watch(name)
	const { fields, append, remove } = useFieldArray({
		name,
	})
	const showActions = useMemo(() => {
		return name === 'settings.product_assignments'
	}, [name])
	const handleUseTemplate = ({
		templateSettings: { product_assignments },
	}: {
		templateSettings: { [key: string]: SettingType | ProductAssignmentModel }
	}) => {
		setValue('settings.product_assignments', product_assignments, {
			shouldDirty: true,
		})
	}
	const settingActions = useSettingActions({
		handleUseTemplate,
	})

	return (
		<Card className='border shadow-none'>
			<CardHeader className='pb-3'>
				<div className='flex justify-between'>
					<CardTitle as='h2'>
						{intl.get('settings.assignment.long').d('Product assignment')}
					</CardTitle>
					{showActions && (
						<CardActions>
							<SettingActionsDropdown
								actions={settingActions}
								type={SettingsTemplateType.PricingCampaignProductAssignment}
								saveAsTemplateProps={{
									pricingCampaignName: pricingCampaignNameWatcher,
									pricingCampaignId: pricingCampaignId!,
									settings: {
										product_assignments: assignments,
									},
								}}
								useTemplateProps={{
									isReadyToChange: context?.isReadyToChange,
								}}
								copyToPCProps={{ settingName: 'product_assignments' }}
							>
								<Button
									data-testid={'pc-actions-popover-trigger'}
									variant='tertiary'
									className='w-xs ml-2'
									iconBefore={<DotsFilledIcon />}
								/>
							</SettingActionsDropdown>
						</CardActions>
					)}
				</div>
			</CardHeader>
			<CardContent className='relative space-y-2'>
				{fields?.map((item, index) => (
					<div key={item.id}>
						<Separator />
						<Header className='justify-between p-2'>
							<RHFInlineEditText
								className='text-md mb-0 w-full font-bold'
								name={`${name}.${index}.name`}
								placeholder={intl.get('pc.assignment.segment').d('Segment')}
							/>
							<CardActions className='right-5 top-5'>
								<Button
									variant='tertiary'
									iconBefore={<CrossIcon />}
									onClick={() => remove(index)}
								/>
							</CardActions>
						</Header>
						<div className='w-full space-y-2 px-2'>
							<h6>{intl.get('app.filters')}</h6>
							<Controller
								name={`${name}.${index}`}
								control={control}
								render={({
									field: { value, onChange },
									fieldState: { error },
								}) => {
									return (
										<AutoAssignmentPanel
											formData={value}
											error={error}
											onChange={onChange}
										/>
									)
								}}
							/>
						</div>
					</div>
				))}
				<Separator />
				<Button
					variant='tertiary'
					iconBefore={<PlusIcon />}
					onClick={() =>
						append({
							name: 'Segment',
							filters: [
								{
									name: '',
								},
							],
						})
					}
				>
					{intl.get('pc.assignment.add_segment').d('Add segment')}
				</Button>
			</CardContent>
		</Card>
	)
}
