import { any, prop, propOr, uniqBy } from 'lodash/fp'

import { useQuery } from '@tanstack/react-query'

import { UserModel, UsersModel } from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { useMeQuery } from '@/modules/Auth/queries'
import { client } from '@/network/client'

export const useUsersQuery = <T = UsersModel>(
	options?: UseQueryOptions<UsersModel, Error, T>
) => {
	return useQuery<UsersModel, Error, T>({
		queryKey: ['users'],
		queryFn: () => client.users.getUsers(),
		...(options || {}),
	})
}

export const useAllUsersQuery = () => {
	const users = useUsersQuery()
	const me = useMeQuery()
	const data: UserModel[] = uniqBy(
		'id',
		[...propOr([], 'data.users', users), me.data].filter(Boolean)
	)
	return {
		...users,
		isLoading: any(prop('isLoading'), [users, me]),
		data,
	}
}
