import clsx from 'clsx'
import {
	entries,
	filter,
	fromPairs,
	indexBy,
	isNil,
	map,
	paths,
	pipe,
	prop,
} from 'lodash/fp'
import React, { useMemo, useState } from 'react'

import {
	closestCenter,
	defaultDropAnimationSideEffects,
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	DropAnimation,
	KeyboardSensor,
	PointerSensor,
	UniqueIdentifier,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
	arrayMove,
	defaultAnimateLayoutChanges,
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useIsFetching } from '@tanstack/react-query'

import { Badge, LinkButton, Spinner, TreeNodeType } from '@cmpkit/base'
import { useDebounce } from '@cmpkit/hooks'
import DotsDraggableIcon from '@cmpkit/icon/lib/glyph/dots-draggable'
import {
	FieldDefinition,
	getQueryDescription,
	Operators,
	ValueEditorType,
	ValueType,
} from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import { toAdaptedSchema } from '@/components/filter/adapter'
import { FilterField } from '@/components/filter/types'
import { PricingCampaignModel, ProductAssignmentModel } from '@/generated'
import intl from '@/locale'
import {
	useBrandsQuery,
	useCategoriesQuery,
	useColumnsSchemaQuery,
	useOptimizationGroupsQuery,
} from '@/modules/core/queries'
import { toCategoriesTree } from '@/modules/core/utils'
import { useModal } from '@/modules/modals/store'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'
import { toOption } from '@/tools/utils'

import {
	getPricingCampaignEngine,
	isGlobalPricingCampaign,
} from '../../helpers'
import { useMatchedProductsQuery } from '../../queries'
import {
	alignPricingCampaignsOrders,
	isValidAssignment,
	isValidMatchedProductsAssignments,
} from '../../utils'
import EngineBadge from '../EngineBadge'
import GlobalPcBadge from '../GlobalPcBadge'

const dropAnimationConfig: DropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: '0.5',
			},
		},
	}),
}

/**
 * Hook to get matched products counts for pricing campaigns with product assignments
 * @param pricingCampaigns - Full list of pricing campaigns
 * @param optimizationGroupId - Optimization group id
 * @returns - Result query with Object with pricing campaign id as key and number of products as value
 */
export const useOrderedPricingCampaignsProductsCounts = (
	pricingCampaigns: PricingCampaignModel[],
	optimizationGroupId?: string | null
) => {
	const items = useDebounce(pricingCampaigns, 1000)
	const assignments = useMemo(
		() =>
			alignPricingCampaignsOrders(items)
				.map((pc) => ({
					id: pc.id,
					order: pc.order,
					assignments: pc.settings.product_assignments.map(
						({ name, filters }) => ({
							id: name,
							filters,
						})
					),
				}))
				.filter(isValidAssignment),
		[items]
	)

	const isQueryEnabled = useMemo(
		() =>
			assignments?.length > 0 && isValidMatchedProductsAssignments(assignments),
		[assignments]
	)

	return useMatchedProductsQuery<Record<string, number>>(
		optimizationGroupId
			? {
					optimization_group_id: optimizationGroupId,
				}
			: {},
		assignments,
		{
			enabled: isQueryEnabled,
			select: pipe([
				prop('pricing_campaigns'),
				indexBy(prop('id')),
				entries,
				map(([id, { count }]) => [id, count]),
				fromPairs,
			]),
		}
	)
}
export default function PricingCampaignsPriority({
	predicts,
	pricingCampaigns,
	isItemDragDisabled,
	onChange,
}: {
	predicts: {
		data?: Record<string, number>
		isLoading: boolean
	}
	pricingCampaigns: PricingCampaignModel[]
	isItemDragDisabled?(item: PricingCampaignModel): boolean
	onChange: (items: PricingCampaignModel[]) => void
}) {
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)
	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event
		setActiveId(active.id)
	}
	const ids: UniqueIdentifier[] = pricingCampaigns.map(({ id }) => id)
	const getIndex = (id: UniqueIdentifier) => ids.indexOf(id)
	const activeIndex = activeId != null ? getIndex(activeId) : -1
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (active.id !== over?.id && over) {
			const initialOrders = pricingCampaigns.map(prop('order'))
			const oldIndex = ids.indexOf(active.id)
			const newIndex = ids.indexOf(over.id)
			const reordered = arrayMove(pricingCampaigns, oldIndex, newIndex).map(
				(item, i) => ({
					...item,
					order: initialOrders[i]!,
				})
			)
			analytic.logEvent('pcs: order: chnaged')
			onChange(reordered)
		}
		setActiveId(null)
	}
	return (
		<DndContext
			modifiers={[restrictToVerticalAxis]}
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
		>
			<SortableContext
				items={pricingCampaigns}
				strategy={verticalListSortingStrategy}
			>
				{pricingCampaigns.map((item) => {
					const isDisabled = isItemDragDisabled?.(item)
					return (
						<SortableItem
							key={item.id}
							id={item.id}
							pricingCampaign={item}
							count={predicts.data?.[item.id]}
							isDisabled={isDisabled}
						/>
					)
				})}
			</SortableContext>
			<DragOverlay dropAnimation={dropAnimationConfig}>
				{activeId ? (
					<div className='mb-1 flex'>
						<PricingCampaignItem
							pricingCampaign={pricingCampaigns[activeIndex]}
						/>
					</div>
				) : null}
			</DragOverlay>
		</DndContext>
	)
}
const PricingCampaignItem = ({
	pricingCampaign,
	isDisabled,
	listeners,
	count,
}: {
	pricingCampaign: PricingCampaignModel
	isDisabled?: boolean
	listeners?: SyntheticListenerMap
	count?: number
}) => {
	const isFetching = useIsFetching({
		queryKey: ['matched-products'],
	})
	const modal = useModal('PRICING_CAMPAIGN_MODAL')
	return (
		<div className='flex w-full items-center space-x-2'>
			{isDisabled ? (
				<Tooltip
					placement='left'
					content={
						<p className='w-64'>
							{intl
								.get('pc.ordering.disabled.hint')
								.d(
									'This is global pricing campaign. To change global priority, please go to the global pricing camapigns priority settings.'
								)}
						</p>
					}
				>
					<div className='flex items-center'>
						<DotsDraggableIcon
							{...listeners}
							className='shrink-0 cursor-not-allowed text-disabled'
						/>
					</div>
				</Tooltip>
			) : (
				<div className='flex items-center'>
					<DotsDraggableIcon {...listeners} className='shrink-0 cursor-grab' />
				</div>
			)}
			<Badge>{pricingCampaign.order}</Badge>
			<div
				className={clsx(
					'flex w-full flex-col justify-center space-y-1 overflow-hidden rounded-lg border bg-accent-1 p-2 shadow-sm hover:bg-accent-2',
					{
						'bg-accent-4 hover:bg-accent-4': isDisabled,
					}
				)}
			>
				<div className='flex w-full items-center gap-2'>
					<LinkButton
						className='text-xs'
						onClick={() =>
							modal.open({
								pricingCampaignId: pricingCampaign.id,
								optimizationGroupId: pricingCampaign.optimization_group_id,
								scenarioId: pricingCampaign.scenario_id,
							})
						}
					>
						{pricingCampaign.name}
					</LinkButton>

					<EngineBadge
						size='small'
						engine={getPricingCampaignEngine(pricingCampaign)}
					>
						{intl.get(
							`campaigns_om_title_${getPricingCampaignEngine(pricingCampaign)}`
						)}
					</EngineBadge>
					{isGlobalPricingCampaign(pricingCampaign) && <GlobalPcBadge />}
					<span className='ml-auto font-mono text-xs font-medium'>
						{isFetching ? (
							<Spinner />
						) : (
							!isNil(count) && (
								<span>{formatNumber(Math.floor(count || 0))}</span>
							)
						)}
					</span>
				</div>
				<div className='flex flex-1 flex-wrap gap-1'>
					{pricingCampaign.settings.product_assignments?.length > 0 && (
						<span className='truncate text-xs text-muted'>
							<AssignmentsList
								assignments={pricingCampaign.settings.product_assignments}
							/>
						</span>
					)}
				</div>
			</div>
		</div>
	)
}
function AssignmentsList({
	assignments,
}: {
	assignments: ProductAssignmentModel
}) {
	/**
	 * Queries
	 */
	const { data: brands } = useBrandsQuery(
		{},
		{
			select: pipe([map(paths(['id', 'name'])), toOption]),
		}
	)
	const { data: categories } = useCategoriesQuery<Array<TreeNodeType>>(
		{},
		{
			select: toCategoriesTree,
		}
	)
	const { data: optimizationGroups } = useOptimizationGroupsQuery({
		select: pipe([map(paths(['id', 'name'])), toOption]),
	})
	const fieldsQuery = useColumnsSchemaQuery<FilterField[]>({
		select: pipe([filter(isPreproField), map(toAdaptedSchema)]),
	})

	const options = useMemo(() => {
		let fields: FieldDefinition[] = []
		if (!!fieldsQuery.data?.length) {
			fields = [
				{
					enum: [],
					label: intl.get('field_schema_optimization_group'),
					value: 'optimization_group_id',
					valueEditorType: ValueEditorType.multiselect,
					valueType: ValueType.str,
					operations: [Operators.IN, Operators.NOT_IN],
				},
				...(fieldsQuery.data || []),
			]
		}
		return {
			fields,
			dataChoices: {
				brands: brands || [],
				category_ids: categories || [],
				optimization_group_id: optimizationGroups || [],
			},
		}
	}, [fieldsQuery.data, brands, categories, optimizationGroups])
	return useMemo(() => {
		return assignments
			.reduce((acc, assignment) => {
				acc.push(
					getQueryDescription(assignment?.filters || [], options)
						.map(
							(item) =>
								`${item?.label} ${item?.operation ? item?.operation : ''} ${item?.value ? item?.value : ''}`
						)
						.join(' AND ')
				)
				return acc
			}, [] as string[])
			.join(' OR ')
	}, [assignments, options])
}
const isPreproField = ({ stage }: { stage: string }) =>
	['prepro', 'pre_init_fields'].includes(stage)

function SortableItem({
	id,
	pricingCampaign,
	isDisabled,
	count,
}: {
	id: string
	count?: number
	pricingCampaign: PricingCampaignModel
	isDisabled?: boolean
}) {
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({
		id,
		animateLayoutChanges: defaultAnimateLayoutChanges,
		disabled: isDisabled,
	})
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
	}

	return (
		<div ref={setNodeRef} style={style} {...attributes}>
			<div
				className={clsx('mb-1 flex', {
					'z-50': isDisabled,
					'opacity-0': isDragging,
				})}
			>
				<PricingCampaignItem
					pricingCampaign={pricingCampaign}
					isDisabled={isDisabled}
					listeners={listeners}
					count={count}
					{...attributes}
				/>
			</div>
		</div>
	)
}
