import React, { useMemo } from 'react'

import { StateButton, TreeNodeType } from '@cmpkit/base'
import FilterIcon from '@cmpkit/icon/lib/glyph/filter'
import { FilterRuleEntity } from '@cmpkit/query-builder'

import { DataOption } from '@/common.types'
import { toAdaptedSchema } from '@/components/filter/adapter'
import FiltersPopover from '@/components/filter/FiltersPopover'
import { ColumnSchemaModel } from '@/generated'
import intl from '@/locale'
import analytic from '@/services/analytics'

type TableFiltersProps = {
	enableSaveFilters?: boolean
	enableRecentFilters?: boolean
	schema: ColumnSchemaModel[]
	filters: FilterRuleEntity[]
	onApply(filters: FilterRuleEntity[]): void
	choices?: Record<string, DataOption[] | TreeNodeType[]>
	analyticEventPrefix?: string
}
export default function TableFilters({
	analyticEventPrefix,
	schema,
	filters,
	onApply,
	choices,
	enableSaveFilters,
	enableRecentFilters,
}: TableFiltersProps) {
	/**
	 * Filter fields formation from `schema` and memoization for performance reasons (to avoid re-rendering)
	 */
	const fields = useMemo(() => schema.map(toAdaptedSchema), [schema])
	return (
		<FiltersPopover
			fields={fields}
			filters={filters ?? []}
			dataChoices={choices}
			onApply={(filters) => {
				analyticEventPrefix &&
					analytic.logEvent(`${analyticEventPrefix} apply filters`)
				onApply(filters)
			}}
			enableSaveFilters={enableSaveFilters}
			enableRecentFilters={enableRecentFilters}
		>
			<StateButton
				data-testid='table-filters-button'
				size='small'
				iconBefore={<FilterIcon />}
				onClear={() => onApply([])}
				active={!!filters.length}
			>
				{`${intl.get('general_filter')}`}{' '}
				{!!filters.length ? `(${filters.length})` : ''}
			</StateButton>
		</FiltersPopover>
	)
}
