import { AxiosError } from 'axios'

import { useQuery } from '@tanstack/react-query'

import {
	HTTPValidationError,
	PricingEngineType,
	PricingRuleModel,
	ScenarioModel,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'

import { SchemaParts, SettigSchemaModel } from './types'
//import schemas from './__mocked_schema__.json'
export const useSettingsSchemasQuery = <T = SettigSchemaModel>(
	options?: UseQueryOptions<SettigSchemaModel, AxiosError, T>
) => {
	return useQuery<SettigSchemaModel, AxiosError, T>({
		queryKey: ['settings-schema'],
		queryFn: () => {
			return client.core.listSettingsSchemas() as Promise<SettigSchemaModel>
		},
		...(options || {}),
	})
}
export const useOptimizationGroupSettingsQuery = <T = ScenarioModel>(
	optimizationGroupId: string,
	options?: UseQueryOptions<ScenarioModel, AxiosError<HTTPValidationError>, T>
) => {
	return useQuery<ScenarioModel, AxiosError<HTTPValidationError>, T>({
		queryKey: ['main-scenario', optimizationGroupId],
		queryFn: () => client.scenario.scenarioGetMain(optimizationGroupId),
		...(options || {}),
	})
}

// FIXME: This is a temporary solution to make the code compilable

export const tranformToSchemasList = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	settingsSchema: any,
	pricingRules: PricingRuleModel[]
) => {
	if (settingsSchema) {
		settingsSchema.pricing_limits.properties.price_limits.properties.lower.items.properties.on_violation.type =
			'string'
		settingsSchema.pricing_limits.properties.price_limits.properties.lower.items.properties.type.type =
			'string'
		settingsSchema.pricing_limits.properties.price_limits.properties.upper.items.properties.on_violation.type =
			'string'
		settingsSchema.pricing_limits.properties.price_limits.properties.upper.items.properties.type.type =
			'string'
		settingsSchema.pricing_limits.properties.price_limits.properties.extra.properties.on_conflict.type =
			'string'
	}

	return [
		{
			$id: SchemaParts.MinimalSteps,
			...(settingsSchema?.minimal_steps.properties.minimal_steps || {}),
		},
		{
			$id: SchemaParts.Rounding,
			...settingsSchema?.rounding.properties.rounding,
		},
		{
			$id: SchemaParts.PriceLimits,
			...settingsSchema?.pricing_limits.properties.price_limits,
		},
		{
			$id: SchemaParts.StopList,
			...settingsSchema?.stop_list.properties.stop_list,
		},
		{
			$id: SchemaParts.BusinessConstraints,
			...settingsSchema?.business_settings.properties.business,
		},
		{
			$id: SchemaParts.Strategy,
			...settingsSchema?.strategies.properties.strategy,
		},
		{
			$id: SchemaParts.ProductAssignments,
			title: 'Products assignments',
			type: 'array',
			default: [],
			uniqueItems: true,
			items: {
				type: 'object',
				additionalProperties: false,
				required: ['name', 'filters'],
				properties: {
					name: {
						title: 'Name',
						type: 'string',
						minLength: 2,
					},
					filters: {
						title: 'Filters',
						type: 'array',
						minItems: 1,
						items: {
							type: 'object',
							additionalProperties: false,
							required: ['name', 'operation', 'value'],
							properties: {
								name: { type: 'string' },
								operation: {
									type: 'string',
									enum: [
										'gt',
										'lt',
										'bl',
										'lte',
										'gte',
										'eq',
										'ne',
										'is',
										'is_not',
										'is_empty',
										'is_not_empty',
										'in',
										'not_in',
										'contains',
										'not_contains',
										'includes',
										'intersection_brands',
										'intersection_categories',
										'intersection_pricing_campaigns',
										'only_recently_repriced',
										'only_not_recently_repriced',
										'out_of_stocks',
										'promo_products',
										'date',
										'select',
										'num_between',
										'date_after',
										'date_before',
										'date_between',
										'date_at',
										'array_any_of',
										'array_all_of',
										'array_none_of',
										'array_exact',
									],
								},
								value: {
									type: ['number', 'string', 'boolean', 'array', 'null'],
								},
							},
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.Anchoring,
			type: 'object',
			title: 'Anchoring',
			default: {
				rules: [],
				on_conflict: 'ignore',
			},
			properties: {
				rules: {
					title: 'Anchoring rules',
					type: 'array',
					uniqueItems: true,
					items: {
						type: 'object',
						properties: {
							anchoring: {
								type: 'string',
								title: 'Anchoring type',
								enum: [
									'flat_anchor',
									'sized_anchor',
									'product_set',
									'ladder_anchor',
									'bundle_anchor',
								],
							},
							high_range: {
								title: 'High range',
								type: 'number',
							},
							high_range_behavior: {
								title: 'High range behavior',
								type: 'string',
								minLength: 1,
								enum: ['correct', 'highlight'],
							},
							low_range: {
								title: 'Low range',
								type: 'number',
							},
							low_range_behavior: {
								title: 'Low range behavior',
								type: 'string',
								minLength: 1,
								enum: ['correct', 'highlight'],
							},
						},
						required: [
							'anchoring',
							'high_range',
							'high_range_behavior',
							'low_range',
							'low_range_behavior',
						],
					},
				},
				on_conflict: {
					title: 'On conflict',
					type: 'string',
					enum: ['ignore', 'use_anchor_price'],
				},
			},
			required: ['rules', 'on_conflict'],
		},
		{
			$id: SchemaParts.EntityScenario,
			type: 'object',
			required: ['name', 'settings'],
			properties: {
				name: {
					type: 'string',
					minLength: 4,
					maxLength: 75,
				},
				description: {
					type: 'string',
					maxLength: 500,
				},
				settings: {
					type: 'object',
					required: ['stop_list', 'business', 'strategy'],
					properties: {
						stop_list: {
							$ref: SchemaParts.StopList,
						},
						business: {
							$ref: SchemaParts.BusinessConstraints,
						},
						strategy: {
							$ref: SchemaParts.Strategy,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TacticsRb,
			type: 'object',
			properties: {
				engine: {
					default: PricingEngineType.RB,
					type: 'string',
					const: 'RB',
				},
				params: {
					oneOf: (pricingRules || []).map((rule) => {
						return {
							type: 'object',
							required: ['rule_id', 'rule_params'],
							properties: {
								rule_params: rule.params_schema,
								rule_id: {
									type: 'string',
									const: rule.id,
								},
							},
						}
					}),
				},
			},
		},
		{
			$id: SchemaParts.TacticsMl,
			type: 'object',
			properties: {
				engine: {
					default: PricingEngineType.ML,
					type: 'string',
					const: 'ML',
				},
				params: {
					default: {},
					type: 'object',
				},
			},
		},
		{
			$id: SchemaParts.TacticsMd,
			type: 'object',
			properties: {
				engine: {
					default: PricingEngineType.MD,
					type: 'string',
					const: 'MD',
				},
				params: {
					type: 'object',
					properties: {
						periods:
							settingsSchema?.markdown?.properties?.markdown?.properties
								?.periods ?? {},
					},
				},
			},
		},
		{
			$id: SchemaParts.EntityPricingCampaign,
			type: 'object',
			required: ['name', 'settings'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				start_date: {
					oneOf: [
						{
							type: 'string',
						},
						{
							type: 'null',
						},
					],
				},
				end_date: {
					oneOf: [
						{
							type: 'string',
						},
						{
							type: 'null',
						},
					],
				},
				settings: {
					type: 'object',
					required: [
						'pricing_tactics',
						'price_limits',
						'rounding',
						'anchoring',
						'minimal_steps',
						'product_assignments',
					],
					properties: {
						product_assignments: {
							$ref: SchemaParts.ProductAssignments,
						},
						pricing_tactics: {
							default: {
								engine: 'ML',
								params: {},
							},
							oneOf: [
								{
									$ref: SchemaParts.TacticsRb,
								},
								{
									$ref: SchemaParts.TacticsMd,
								},
								{
									$ref: SchemaParts.TacticsMl,
								},
							],
						},
						minimal_steps: {
							$ref: SchemaParts.MinimalSteps,
						},
						price_limits: {
							$ref: SchemaParts.PriceLimits,
						},
						rounding: {
							$ref: SchemaParts.Rounding,
						},
						anchoring: {
							$ref: SchemaParts.Anchoring,
						},
					},
				},
			},
		},

		// Templates
		{
			$id: SchemaParts.TemplatePricingCampaign,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['settings'],
					properties: {
						name: {
							type: 'string',
						},
						settings: {
							type: 'object',
							required: [
								'pricing_tactics',
								'price_limits',
								'rounding',
								'anchoring',
								'minimal_steps',
								'product_assignments',
							],
							properties: {
								product_assignments: {
									$ref: SchemaParts.ProductAssignments,
								},
								pricing_tactics: {
									default: {
										engine: 'ML',
										params: {},
									},
									oneOf: [
										{
											$ref: SchemaParts.TacticsRb,
										},
										{
											$ref: SchemaParts.TacticsMd,
										},
										{
											$ref: SchemaParts.TacticsMl,
										},
									],
								},
								minimal_steps: {
									$ref: SchemaParts.MinimalSteps,
								},
								price_limits: {
									$ref: SchemaParts.PriceLimits,
								},
								rounding: {
									$ref: SchemaParts.Rounding,
								},
								anchoring: {
									$ref: SchemaParts.Anchoring,
								},
							},
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplatePricingCampaignAnchoring,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['anchoring'],
					properties: {
						anchoring: {
							$ref: SchemaParts.Anchoring,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplatePricingCampaignLimits,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['price_limits'],
					properties: {
						price_limits: {
							$ref: SchemaParts.PriceLimits,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplatePricingCampaignMinimalSteps,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['minimal_steps'],
					properties: {
						minimal_steps: {
							$ref: SchemaParts.MinimalSteps,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplatePricingCampaignProductAssignment,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['product_assignments'],
					properties: {
						product_assignments: {
							$ref: SchemaParts.ProductAssignments,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplatePricingCampaignRounding,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['rounding'],
					properties: {
						rounding: {
							$ref: SchemaParts.Rounding,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplatePricingCampaignTacticsMd,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['pricing_tactics'],
					properties: {
						pricing_tactics: {
							default: {
								engine: PricingEngineType.MD,
								params: {},
							},
							$ref: SchemaParts.TacticsMd,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplatePricingCampaignTacticsMl,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['pricing_tactics'],
					properties: {
						pricing_tactics: {
							default: {
								engine: PricingEngineType.ML,
								params: {},
							},
							$ref: SchemaParts.TacticsMl,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplatePricingCampaignTacticsRb,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['pricing_tactics'],
					properties: {
						pricing_tactics: {
							default: {
								engine: PricingEngineType.RB,
								params: {},
							},
							$ref: SchemaParts.TacticsRb,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplateScenario,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['settings', 'pricing_campaigns'],
					properties: {
						name: {
							type: 'string',
						},
						settings: {
							type: 'object',
							required: ['stop_list', 'business', 'strategy'],
							properties: {
								stop_list: {
									$ref: SchemaParts.StopList,
								},
								business: {
									$ref: SchemaParts.BusinessConstraints,
								},
								strategy: {
									$ref: SchemaParts.Strategy,
								},
							},
						},
						pricing_campaigns: {
							default: [],
							type: 'array',
							items: {
								name: {
									type: 'string',
								},
								order: {
									type: 'integer',
								},
								settings: {
									type: 'object',
									required: [
										'pricing_tactics',
										'price_limits',
										'rounding',
										'anchoring',
										'minimal_steps',
										'product_assignments',
									],
									properties: {
										product_assignments: {
											$ref: SchemaParts.ProductAssignments,
										},
										pricing_tactics: {
											default: {
												engine: 'ML',
												params: {},
											},
											oneOf: [
												{
													$ref: SchemaParts.TacticsRb,
												},
												{
													$ref: SchemaParts.TacticsMd,
												},
												{
													$ref: SchemaParts.TacticsMl,
												},
											],
										},
										minimal_steps: {
											$ref: SchemaParts.MinimalSteps,
										},
										price_limits: {
											$ref: SchemaParts.PriceLimits,
										},
										rounding: {
											$ref: SchemaParts.Rounding,
										},
										anchoring: {
											$ref: SchemaParts.Anchoring,
										},
									},
								},
							},
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplateScenarioBusinessConstraints,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['business'],
					properties: {
						business: {
							$ref: SchemaParts.BusinessConstraints,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplateScenarioStopList,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['stop_list'],
					properties: {
						stop_list: {
							$ref: SchemaParts.StopList,
						},
					},
				},
			},
		},
		{
			$id: SchemaParts.TemplateScenarioStrategy,
			type: 'object',
			required: ['name', 'body'],
			properties: {
				name: {
					type: 'string',
					minLength: 1,
				},
				body: {
					type: 'object',
					required: ['strategy'],
					properties: {
						strategy: {
							$ref: SchemaParts.Strategy,
						},
					},
				},
			},
		},
	]
}
