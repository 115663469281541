import React, { useMemo } from 'react'

import { Column, flexRender, Row, RowData } from '@tanstack/react-table'

import { Table, TableCell, TableFooter, TableRow } from '@cmpkit/base'

import { useGridVirtualizer } from './hooks/useGridVirtualizer'
import MasterTableBody from './MasterTableBody'
import MasterTableProvider from './MasterTableContext'
import MasterTableHeader from './MasterTableHeader'
import { TableInstance } from './types'
import { getHeaderPinningStyles, getHeaders } from './utils/utils'

function defaultGetRowHeight<T>(row?: Row<T>) {
	if (row?.groupingColumnId) {
		return 80
	}
	return 32
}
export default function MasterTable<T extends RowData>({
	table,
}: {
	table: TableInstance<T>
}) {
	const {
		options: {
			layoutMode,
			enableVirtualization,
			enableTableFooter,
			enableTableHeader,
			renderGroup,
			renderDetailPanel,
			getRowHeight = defaultGetRowHeight,
		},
		refs: { tableContainerRef },
	} = table

	const grid = useGridVirtualizer(table, {
		getRowHeight,
		getColSize: (column: Column<T>) => {
			const colSize = column?.getSize()

			return colSize === Number.MAX_SAFE_INTEGER ? 200 : colSize
		},
	})
	const totalSize = table.getTotalSize()
	const headers = getHeaders(table)

	const columns = table.getVisibleLeafColumns()
	const {
		columnSizing,
		columnSizingInfo,
		columnVisibility,
		rowPinning,
		columnPinning,
	} = table.getState()

	const columnSizes = useMemo(() => {
		const headers = table.getFlatHeaders()
		const colSizes = []
		for (let i = 0; i < headers.length; i++) {
			const header = headers[i]
			const colSize = header.getSize()
			colSizes.push({
				id: header.column.id,
				size: colSize === Number.MAX_SAFE_INTEGER ? undefined : colSize,
			})
		}
		return colSizes
	}, [
		columns,
		columnSizing,
		columnSizingInfo,
		columnVisibility,
		rowPinning,
		columnPinning,
	])
	return (
		<MasterTableProvider table={table}>
			<div
				className='relative flex min-h-0 flex-1 flex-col !overflow-auto'
				ref={tableContainerRef}
			>
				{enableTableHeader && (
					<Table
						className='sticky top-0 z-50 table-fixed bg-slate-100'
						style={layoutMode == 'semantic' ? { width: totalSize } : undefined}
					>
						<colgroup>
							{columnSizes.map(({ id, size }) => (
								<col key={id} style={size ? { width: size } : undefined} />
							))}
						</colgroup>
						<MasterTableHeader table={table} layoutMode={layoutMode} />
					</Table>
				)}

				<div
					className='flex flex-1 flex-col'
					style={layoutMode == 'semantic' ? { width: totalSize } : undefined}
				>
					<Table className='table-fixed'>
						<colgroup>
							{columnSizes.map(({ id, size }) => (
								<col key={id} style={size ? { width: size } : undefined} />
							))}
						</colgroup>
						<MasterTableBody
							table={table}
							layoutMode={layoutMode}
							grid={enableVirtualization ? grid?.bodyGrid : undefined}
							renderGroup={renderGroup}
							renderDetailPanel={renderDetailPanel}
						/>
					</Table>
				</div>
				{enableTableFooter && (
					<Table
						className='sticky bottom-0 z-50 bg-slate-100'
						style={layoutMode == 'semantic' ? { width: totalSize } : undefined}
					>
						<TableFooter>
							{headers.map((headers, i) => {
								return (
									<TableRow key={i}>
										{headers.map(({ header, isPinned, left, right }) => {
											return (
												<TableCell
													colSpan={header.colSpan}
													key={`${header.id}`}
													className='h-9 px-2'
													style={getHeaderPinningStyles(header, {
														isPinned,
														left,
														right,
														layoutMode,
													})}
												>
													<div className='whitespace-nowrap'>
														{header.isPlaceholder ? null : (
															<div className='flex items-center justify-between text-xs'>
																{header.column.id !== 'control' && (
																	<>
																		{flexRender(
																			header.column.columnDef.footer,
																			header.getContext()
																		)}
																	</>
																)}
															</div>
														)}
													</div>
												</TableCell>
											)
										})}
									</TableRow>
								)
							})}
						</TableFooter>
					</Table>
				)}
			</div>
		</MasterTableProvider>
	)
}
