import clsx from 'clsx'
import { formatDistance } from 'date-fns'
import { filter, pipe, prop, values } from 'lodash/fp'
import React from 'react'

import LabeledValue from '@/components/LabeledValue/LabeledValue'
import intl from '@/locale'
import { FINISHED, LOCKED } from '@/modules/core/constants'
import { useOptimizationsQuery } from '@/modules/core/queries'
import { OptimizationModel } from '@/modules/core/types'
import { getLastUpdateDate } from '@/modules/core/utils'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { Widget, WidgetContent, WidgetHeader } from '../../Widget'

const countFinishedOptimizations = (list: OptimizationModel[]) => {
	const finished = filter(
		pipe([prop('status'), (status) => [LOCKED, FINISHED].includes(status)]),
		list
	)
	return (finished?.length / list.length) * 100
}
const countApplieddOptimizations = (list: OptimizationModel[]) => {
	const locked = filter({ status: LOCKED }, list)
	return (locked?.length / list.length) * 100
}

export default function NumericOptimizationGroupsStatusWidget() {
	const optimizationsQuery = useOptimizationsQuery<OptimizationModel[]>({
		select: values,
	})
	const finishedCount = countFinishedOptimizations(
		optimizationsQuery.data ?? []
	)
	const appliedCount = countApplieddOptimizations(optimizationsQuery.data ?? [])
	const lastUpdateDate = getLastUpdateDate(optimizationsQuery.data || [])
	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				title={intl.get('widget.ogs_status.title')}
				tooltip={intl.get('widget.ogs_status.tooltip')}
				subtitle={
					lastUpdateDate &&
					`${intl.get('general_last_updated').d('Last updated')}: ${formatDistance(
						lastUpdateDate,
						new Date(),
						{
							addSuffix: true,
						}
					)}`
				}
			/>
			<WidgetContent>
				<div className='mt-auto flex space-x-4 truncate'>
					{optimizationsQuery.isLoading ? (
						<div className='col-span-2 h-10 w-32 animate-pulse rounded-lg bg-accent-3' />
					) : (
						<LabeledValue label={intl.get('opt_finished')}>
							<span
								className={clsx('text-2xl font-medium', {
									'text-danger': finishedCount < 50,
									'text-success': finishedCount > 50,
								})}
							>
								{formatNumber(finishedCount, NumberFormats.Percent)}
							</span>
						</LabeledValue>
					)}
					{optimizationsQuery.isLoading ? (
						<div className='col-span-2 h-10 w-32 animate-pulse rounded-lg bg-accent-3' />
					) : (
						<LabeledValue label={intl.get('opt_locked')}>
							<span
								className={clsx('text-2xl font-medium', {
									'text-danger': appliedCount < 50,
									'text-success': appliedCount > 50,
								})}
							>
								{formatNumber(appliedCount, NumberFormats.Percent)}
							</span>
						</LabeledValue>
					)}
				</div>
			</WidgetContent>
		</Widget>
	)
}
