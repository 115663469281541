import React from 'react'

import startOptImg from '@/assets/img/svg/start_opt.svg'
import EmptyState from '@/components/placeholders/EmptyState'
import intl from '@/locale'

const EmptyData = () => (
	<EmptyState
		imageUrl={startOptImg}
		imgClasses='h-80'
		wrapperClasses='fade-in'
		description={intl.get('interpretation_empty')}
	/>
)
export default EmptyData
