import { any } from 'lodash/fp'

export const isProdEnv = process.env.NODE_ENV === 'production'
export const isProdHost = !any(
	(e) => window.location.hostname.includes(e),
	['staging', 'dev', 'localhost']
)

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION
export const REACT_APP_AMPLITUDE_KEY = isProdHost
	? process.env.REACT_APP_AMPLITUDE_KEY
	: process.env.REACT_APP_AMPLITUDE_KEY_DEV
export const REACT_APP_SENTRY_DSN = isProdEnv
	? process.env.REACT_APP_SENTRY_DSN
	: null
export const REACT_APP_FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID
export const REACT_APP_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID
process.env.NODE_ENV !== 'test' &&
	console.log(`${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_BUILD}`) // eslint-disable-line
