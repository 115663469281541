import { find } from 'lodash/fp'
import React, { createContext, useCallback, useContext, useState } from 'react'

import intl from '@/locale'
import analytic from '@/services/analytics'

import { ColumnConfig } from '../types'
import { ExportFieldsFormData } from './ExportFieldsModal'
import { ExportParamsFormData } from './ExportParamsModal'

export interface ExportConfigurationContextValue {
	kind: string
	isAdditional?: boolean
	isLoading?: boolean
	state: {
		step: 'fields' | 'params'
		exportDestination: 'local' | 'external'
		fields: ColumnConfig[]
		draftFormData: Partial<ExportParamsFormData & ExportFieldsFormData>
		setStep(step: 'fields' | 'params'): void
		setFields(fields: ColumnConfig[]): void
		setExportDestination(destination: 'local' | 'external'): void
	}
	options: {
		fields_preset: {
			value: string
			label: string
			preset: ColumnConfig[]
		}[]
		format: {
			value: string
			label: string
		}[]
		aggregation: {
			value: string
			label: string
		}[]
	}
	handleColumnsSubmit(data: ExportFieldsFormData): void
	handleParamsSubmit(data: ExportParamsFormData): void
}

export const ExportConfigurationContext = createContext(
	{} as ExportConfigurationContextValue
)
export interface ExportConfigurationProviderProps {
	/**
	 * Unique identifier of the export kind (e.g. 'assortment', 'bi_revenue')
	 */
	kind: string

	/**
	 * Default values for the export parameters form
	 */
	defaultValues: Partial<ExportParamsFormData>

	/**
	 * Whether the export is not assortment-related
	 */
	isAdditional?: boolean
	isLoading?: boolean
	fieldsPresetOptions?: {
		value: string
		label: string
		preset: ColumnConfig[]
	}[]
	onSubmit(data: ExportParamsFormData & ExportFieldsFormData): void
	children: React.ReactNode
}
export default function ExportConfigurationProvider({
	kind,
	children,
	onSubmit,
	fieldsPresetOptions,
	defaultValues,
	isAdditional,
	isLoading,
}: ExportConfigurationProviderProps) {
	const [step, setStep] = useState<'fields' | 'params'>('params')
	const [fields, setFields] = useState<ColumnConfig[]>([])
	const [exportDestination, setExportDestination] = useState<
		'local' | 'external'
	>('local')
	const [draftFormData, setDraftFormData] = useState<
		Partial<ExportParamsFormData & ExportFieldsFormData>
	>({
		...(defaultValues || {}),
	})
	const getFieldsPreset = useCallback(
		(value: string) =>
			find(
				{
					value,
				},
				fieldsPresetOptions
			)?.preset || [],
		[fieldsPresetOptions]
	)

	const handleColumnsSubmit = (formData: ExportFieldsFormData) => {
		onSubmit({
			exportDestination,
			...draftFormData,
			...formData,
		} as ExportParamsFormData &
			ExportFieldsFormData & { exportDestination: 'local' | 'external' })
	}
	const handleParamsSubmit = (formData: ExportParamsFormData) => {
		const selectedPreset = getFieldsPreset(formData.fields_preset)
		if (exportDestination === 'external') {
			onSubmit({
				exportDestination,
				...formData,
				fields: selectedPreset,
			} as ExportParamsFormData &
				ExportFieldsFormData & { exportDestination: 'local' | 'external' })
		} else {
			analytic.track('export: params: set params', {
				export_kind: kind,
				...formData,
			})
			setStep('fields')
			setDraftFormData(formData)
			setFields(selectedPreset)
		}
	}
	return (
		<ExportConfigurationContext.Provider
			value={{
				kind,
				isAdditional,
				isLoading,
				state: {
					step,
					setStep,
					fields,
					setFields,
					exportDestination,
					setExportDestination,
					draftFormData,
				},
				options: {
					fields_preset: fieldsPresetOptions!,
					format: fileFormatOptions,
					aggregation: groupByOptions,
				},
				handleColumnsSubmit,
				handleParamsSubmit,
			}}
		>
			{children}
		</ExportConfigurationContext.Provider>
	)
}
export function useExportConfiguration() {
	return useContext(ExportConfigurationContext)
}
const fileFormatOptions = [
	{ value: 'xlsx', label: 'Excel' },
	{ value: 'csv', label: 'CSV' },
]
const groupByOptions = [
	{ value: 'product', label: intl.get('sku').d('SKU') },
	{ value: 'pricing_line', label: intl.get('pricing_line').d('Pricing line') },
]
