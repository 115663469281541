import React from 'react'

import { SegmentGroup, SegmentGroupItem } from '@cmpkit/base'
import BurgerIcon from '@cmpkit/icon/lib/glyph/burger'
import DashboardIcon from '@cmpkit/icon/lib/glyph/dashboard'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'
import analytic from '@/services/analytics'

export default function ViewSwitcher({
	value,
	onChange,
	analyticEventName,
}: {
	value: string
	onChange: (value: string) => void
	analyticEventName: string
}) {
	return (
		<SegmentGroup>
			<Tooltip
				content={intl
					.get('og.view.switcher.table.tooltip')
					.d('This view visualise your Optimization groups data as list view')}
			>
				<SegmentGroupItem
					icon
					active={value === 'table'}
					onClick={() => {
						analytic.logEvent(analyticEventName, {
							mode: 'table',
						})
						onChange('table')
					}}
				>
					<BurgerIcon />
				</SegmentGroupItem>
			</Tooltip>
			<Tooltip
				content={intl
					.get('og.view.switcher.tree.tooltip')
					.d('This view visualise your Optimization groups as tree view')}
			>
				<SegmentGroupItem
					icon
					active={value === 'tree'}
					onClick={() => {
						analytic.logEvent(analyticEventName, {
							mode: 'tree',
						})
						onChange('tree')
					}}
				>
					<DashboardIcon />
				</SegmentGroupItem>
			</Tooltip>
		</SegmentGroup>
	)
}
