import { JSONSchemaType } from 'ajv'

import {
	BusinessSettingsModel as GenBusinessSettingsModel,
	MarkdownPeriodModel,
	MinimalStepRuleModel,
	PriceLimitsModel,
	StopListSettingsModel,
	StrategiesSettingsModel,
} from '@/generated'

export interface MarkdownSettingsModel {
	/** List of markdown periods to apply custom logic for each period */
	periods?: MarkdownPeriodModel[] | null
}

export type RoundingRuleValueModel = {
	min_price?: number
	params?: any // eslint-disable-line @typescript-eslint/no-explicit-any
	strategy?: string
}

export type BusinessSettingsModel = GenBusinessSettingsModel & {
	max_pricing_lines_number: number | null
	promo_campaign_prices: boolean
	apply_anchor_management: boolean
	[key: string]: boolean | number | null
}

export type SettigSchemaModel = (
	| JSONSchemaType<BusinessSettingsModel>
	| JSONSchemaType<MarkdownSettingsModel>
	| JSONSchemaType<MinimalStepRuleModel[]>
	| JSONSchemaType<PriceLimitsModel>
	| JSONSchemaType<RoundingRuleValueModel[]>
	| JSONSchemaType<StopListSettingsModel>
	| JSONSchemaType<StrategiesSettingsModel>
	| JSONSchemaType<any> // eslint-disable-line @typescript-eslint/no-explicit-any
)[]

export type OptimizationGroupFormData = {
	name: string
	description: string
	sales_level_id: number
	sales_entities: string[]
	product_groups: string[]
	strategy: StrategiesSettingsModel
	business: BusinessSettingsModel
	stop_list: StopListSettingsModel
}

export enum SchemaParts {
	// Schemas parts
	BusinessConstraints = 'part:business',
	StopList = 'part:stop_list',
	Strategy = 'part:strategy',
	MinimalSteps = 'part:minimal_steps',
	PriceLimits = 'part:price_limits',
	PricingTactics = 'part:pricing_tactics',
	ProductAssignments = 'part:product_assignments',
	Rounding = 'part:rounding',
	Anchoring = 'part:anchoring',
	TacticsRb = 'part:tactics_rb',
	TacticsMl = 'part:tactics_ml',
	TacticsMd = 'part:tactics_md',

	// Complete Entities schemas
	EntityScenario = 'entity:scenario',
	EntityPricingCampaign = 'entity:pricing_campaign',

	// Settings templates schemas
	TemplateScenario = 'template:scenario',
	TemplatePricingCampaign = 'template:pricing_campaign',

	TemplateScenarioStrategy = 'template:scenario_strategy',
	TemplateScenarioStopList = 'template:scenario_stop_list',
	TemplateScenarioBusinessConstraints = 'template:scenario_business_constraints',
	TemplatePricingCampaignTacticsRb = 'template:pricing_campaign_tactics_rb',
	TemplatePricingCampaignTacticsMl = 'template:pricing_campaign_tactics_ml',
	TemplatePricingCampaignTacticsMd = 'template:pricing_campaign_tactics_md',
	TemplatePricingCampaignLimits = 'template:pricing_campaign_limits',
	TemplatePricingCampaignMinimalSteps = 'template:pricing_campaign_minimal_steps',
	TemplatePricingCampaignRounding = 'template:pricing_campaign_rounding',
	TemplatePricingCampaignAnchoring = 'template:pricing_campaign_anchoring',
	TemplatePricingCampaignProductAssignment = 'template:pricing_campaign_product_assignment',
}
