import { all, any, filter, pickAll, prop } from 'lodash/fp'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'

import {
	Button,
	InlineMessage,
	linkButtonVariants,
	Loader,
	Spinner,
	Tag,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import LinkIcon from '@cmpkit/icon/lib/glyph/link'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import {
	Modal,
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import ErrorBoundary from '@/components/ErrorBoundary'
import { VirtualDataTable } from '@/components/tables'
import { OptimizationStatus } from '@/generated'
import intl from '@/locale'
import {
	FAILED,
	FINISHED,
	LOCKED,
	NEW,
	PENDING,
	UPDATING_FAILED,
} from '@/modules/core/constants'
import { useCheckOptimizationForDeleteExport } from '@/modules/core/hooks/useCheckOptimizationForDeleteExport'
import { useCreateOptimizationMutation } from '@/modules/core/mutations'
import {
	useOptimizationGroupsQuery,
	useOptimizationsQuery,
} from '@/modules/core/queries'
import { getOptStatus, getStatusBadgeVariant } from '@/modules/core/utils'
import analytic from '@/services/analytics'

type AffectedOptimizationGroupsModalProps = {
	isOpen: boolean
	close: () => void
	optimizationGroupsIds: string[]
	title: string
	subtitle: string
	onConfirm?: () => void
	onCancel?: () => void
}
export default function AffectedOptimizationGroupsModal({
	isOpen,
	close,
	optimizationGroupsIds,
	title,
	subtitle,
	onConfirm,
	onCancel,
}: AffectedOptimizationGroupsModalProps) {
	const optimizationGroupsQuery = useOptimizationGroupsQuery({
		select: filter(({ id }) => optimizationGroupsIds.includes(id)),
	})
	const optimizationsQuery = useOptimizationsQuery({
		select: pickAll(optimizationGroupsIds),
	})

	const data = useMemo(
		() =>
			optimizationGroupsQuery.data?.map((og) => ({
				id: og.id,
				name: og.name,
				status: getOptStatus(optimizationsQuery.data?.[og.id]?.status),
			})) || [],
		[optimizationsQuery, optimizationGroupsQuery]
	)

	const isCorrectStatuses = all(
		({ status }) => [NEW, PENDING].includes(status!),
		data
	)
	const isLoading = any(prop('isLoading'), [
		optimizationGroupsQuery,
		optimizationsQuery,
	])

	const handleCancel = () => {
		onCancel?.()
		close()
	}

	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={handleCancel} showCloseButton size='large'>
					<ModalHeader>
						<ModalTitle>{title}</ModalTitle>
						<ModalDescription>{subtitle}</ModalDescription>
					</ModalHeader>

					<ErrorBoundary>
						<ModalBody className='space-y-3'>
							{!isCorrectStatuses && (
								<InlineMessage
									icon={<InfoIcon />}
									variant='warning'
									className='w-full text-xs font-medium'
								>
									{intl
										.get('global.pc.cant_be_changed.hint')
										.d(
											'You cant procceed because some of the optimizations are not in NEW or PENDING status.'
										)}
								</InlineMessage>
							)}
							<OptimizationGroupsDataTable isLoading={isLoading} data={data} />
						</ModalBody>
						<ModalFooter className='space-x-2'>
							{onConfirm ? (
								<Button className='ml-auto' onClick={handleCancel}>
									{intl.get('general_cancel')}
								</Button>
							) : (
								<Button className='ml-auto' onClick={handleCancel}>
									{intl.get('general_close')}
								</Button>
							)}
							{onConfirm && (
								<Button
									variant='primary-brand'
									disabled={!isCorrectStatuses}
									onClick={() => {
										analytic.logEvent('global pc: confirmation modal: confirm')
										onConfirm()
										close()
									}}
								>
									{intl.get('general_next')}
								</Button>
							)}
						</ModalFooter>
					</ErrorBoundary>
				</Modal>
			)}
		</ModalTransition>
	)
}
type TabelDataRow = {
	id: string
	name: string
	status?: OptimizationStatus
}
function OptimizationGroupsDataTable({
	data,
	isLoading,
}: {
	data: TabelDataRow[]
	isLoading: boolean
}) {
	return (
		<div className='flex h-96 flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
			<Blanket
				isTinted={isLoading}
				className='absolute flex items-center justify-center rounded-lg bg-white/30 backdrop-blur-sm dark:bg-black/30'
			>
				<Loader />
			</Blanket>
			<OptimizationGroupsTable data={data} />
		</div>
	)
}
function OptimizationGroupsTable({ data }: { data: TabelDataRow[] }) {
	const [sorting, setSorting] = useState<SortingState>([])
	const table = useReactTable<TabelDataRow>({
		data,
		columns,
		state: {
			sorting,
		},
		defaultColumn: {
			minSize: 55,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return <VirtualDataTable table={table} />
}
const columnHelper = createColumnHelper<TabelDataRow>()
const columns = [
	columnHelper.accessor('name', {
		size: 550,
		header: () => intl.get('optimization_group'),
		cell: (info) => {
			return (
				<Link
					target='_blank'
					className={linkButtonVariants({ variant: 'brand' })}
					onClick={() =>
						analytic.logEvent('global pc: confirmation modal: navigate to OG')
					}
					to={`/p/og/${info.row.original.id}/products`}
				>
					{info.getValue()}
					<LinkIcon />
				</Link>
			)
		},
	}),
	columnHelper.accessor('id', {
		size: 100,
		header: () => '',
		cell: (info) => {
			return (
				<div className='flex items-center justify-end space-x-2'>
					<RowAction raw={info.row.original} />
				</div>
			)
		},
	}),
	columnHelper.accessor('status', {
		size: 130,
		header: () => intl.get('app.status'),
		cell: (info) => {
			const value = info.getValue()
			return (
				<div className='flex items-center space-x-2'>
					<Tag variant={getStatusBadgeVariant(value)}>
						{intl.get(`opt_${value}`)}
					</Tag>
				</div>
			)
		},
	}),
]
function RowAction({ raw }: { raw: TabelDataRow }) {
	const queryClient = useQueryClient()
	const checkExport = useCheckOptimizationForDeleteExport(raw.id)
	const create = useCreateOptimizationMutation({
		onSuccess: (optimization) => {
			queryClient.setQueryData(
				['optimizations', optimization.optimization_group_id],
				optimization
			)
			queryClient.setQueryData(['optimizations'], {
				...queryClient.getQueryData(['optimizations']),
				[optimization.optimization_group_id]: optimization,
			})
		},
		onMutate() {
			analytic.logEvent('global pc: confirmation modal: revoke OG', {
				status: raw.status,
			})
		},
	})
	if (raw.status && [FINISHED, FAILED, UPDATING_FAILED].includes(raw.status)) {
		return (
			<Button
				size='small'
				disabled={create.isPending}
				iconBefore={create.isPending ? <Spinner /> : <UndoIcon />}
				onClick={() => create.mutate(raw.id)}
			>
				{intl.get('general_revoke').d('Revoke')}
			</Button>
		)
	}
	if (raw.status === LOCKED) {
		return (
			<Button
				size='small'
				disabled={create.isPending}
				iconBefore={create.isPending ? <Spinner /> : <UndoIcon />}
				onClick={checkExport(() => create.mutate(raw.id))}
			>
				{intl.get('general_revoke').d('Revoke')}
			</Button>
		)
	}
	return null
}
