import React from 'react'

import Container from '../Container'
import ChartView from './ChartView'

type GrowthDecompositionProps = {
	incremental_sales: number
	cannibalization: number
}
export default function GrowthDecomposition(props: GrowthDecompositionProps) {
	return (
		<Container>
			<ChartView {...props} />
		</Container>
	)
}
