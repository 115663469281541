import { filter, inRange, map, pipe, prop, sum } from 'lodash/fp'
import { useEffect, useState } from 'react'

import Chart from '@/components/Chart'
import { BIResponse } from '@/generated'
import intl from '@/locale'
import { useBIAnalyticQuery } from '@/modules/bi/queries'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { MainDashboardFilter } from '../../Filter'
import { SinglePeriodSelect } from '../../Periods'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetPeriod, WidgetProps } from '../types'

const ranges = [
	['-35', [-1000, -35.1]],
	['-35-10', [-35, -10.1]],
	['-10-5', [-10, -5.1]],
	['-5-0', [-5, 0]],
	['0', [0, 0.1]],
	['0-5', [0.1, 5]],
	['5-10', [5.1, 10]],
	['10-35', [10.1, 35]],
	['35', [35.1, 1000]],
]
const getSum = pipe([map(prop('1')), sum])
const getPercent = (num: number, total: number) => (num / total) * 100
const calculateRanges = (res: BIResponse | null): [number, number][] => {
	const { data } = res!
	const total = getSum(data)
	const getDataInRange = (isInRange: (a: number, b: number) => boolean) =>
		filter(pipe([prop('0'), isInRange]))(data)
	return ranges.map(prop('1')).map(([start, end]: [number, number]) => {
		const sum = getSum(getDataInRange(inRange(start, end)))
		return [sum, getPercent(sum, total)]
	})
}

const brandColor = 'var(--cmp-brand)'
export default function PriceChangeSegmented({
	widget,
	params,
	commonFilters,
	commonPeriods,
	onChangeParams,
}: WidgetProps) {
	const [period, setPeriod] = useState(commonPeriods.main)
	useEffect(() => {
		setPeriod(commonPeriods.main)
	}, [commonPeriods])
	const defaultPeriod = commonPeriods.main
	const handleSetPeriod = (period: WidgetPeriod) => {
		setPeriod(period)
	}
	const filters = [...commonFilters, ...(params.filters ?? [])]
	const priceChangeQuery = useBIAnalyticQuery<[number, number][]>(
		{
			label: widget.kind,
			...period,
			metrics: ['revenue'],
			dimensions: ['diff_esp_pp_int'],
			filters: [
				...filters,
				{
					name: 'optimization_group',
					operation: 'is_not_empty',
					value: 1,
				},
			],
			date_aggregation: null,
		},
		{
			select: calculateRanges,
		}
	)
	const data =
		priceChangeQuery?.data?.map(([extra, y]) => ({
			y,
			extra,
		})) || []
	return (
		<Widget className='relative flex-auto'>
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.price_change_segmented.title')}
				tooltip={intl.get('widget.price_change_segmented.tooltip')}
			>
				<div className='ml-auto flex items-center'>
					<MainDashboardFilter
						irremovableCount={commonFilters.length}
						filters={filters ?? []}
						onChange={(filters) => {
							onChangeParams({ ...params, filters })
						}}
					/>
					<SinglePeriodSelect
						period={period}
						defaultPeriod={defaultPeriod}
						onChangePeriod={handleSetPeriod}
						maxDate={new Date()}
					/>
				</div>
			</WidgetHeader>
			<WidgetLoadingState isTinted={priceChangeQuery.isLoading} />
			<WidgetErrorState isTinted={priceChangeQuery.isError} />
			<WidgetContent>
				<Chart config={getChartConfig({ data })} />
			</WidgetContent>
		</Widget>
	)
}
const getChartConfig = ({
	data = [],
}: {
	data: { y: number; extra: number }[]
}): Highcharts.Options => ({
	chart: {
		type: 'column',
		height: 215,
	},
	xAxis: {
		categories: [
			'<-35%',
			'-35-10%',
			'-10-5%',
			'-5-0%',
			'0',
			'0-5%',
			'5-10%',
			'10-35%',
			'>35%',
		],
		labels: {
			autoRotationLimit: 0,
			padding: 0,
			style: {
				fontSize: '11px',
			},
		},
	},
	legend: {
		enabled: false,
	},
	yAxis: [
		{
			title: {
				text: '',
			},
		},
	],

	plotOptions: {
		column: {
			borderRadius: 5,
		},
	},

	tooltip: {
		animation: true,
		outside: true,
		followPointer: false,
		useHTML: true,
		backgroundColor: 'transparent',
		borderRadius: 10,
		borderWidth: 0,
		shadow: false,
		formatter: function formatter() {
			const self = this as unknown as any // eslint-disable-line
			const value = self.point['extra']
			const head = `<div class="w-full flex justify-between mb-2.5"><small>${this.x}</small></div>`
			return `<div class="chart-tooltip w-52">
				${head}
				<div class="w-full flex justify-between mb-2.5">
					<div class="flex items-center">
						<strong class="truncate">${intl.get('app.total').d('Total')}</strong>
					</div>
					<strong class="flex items-center">${formatNumber(value, NumberFormats.Integer)}</strong>
				</div>
			</div>`
		},
	},
	series: [
		{
			type: 'column',
			colors: [
				brandColor,
				brandColor,
				brandColor,
				brandColor,
				'var(--cmp-accent-6)',
				brandColor,
				brandColor,
				brandColor,
				brandColor,
			],
			name: intl.get('metric.revenue'),
			colorByPoint: true,
			data,
			dataLabels: {
				enabled: true,
				rotation: 0,
				color: '#000',
				align: 'center',
				format: '{point.y:.0f}%',
				y: 0,
				style: {
					fontSize: '11px',
				},
			},
		},
	],
})
