import React from 'react'

import { Button, Loader, Separator, Spinner } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import {
	Modal,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import ErrorBoundary from '@/components/ErrorBoundary'
import { FormProvider } from '@/components/HookForm'
import { SettingsTemplateType } from '@/generated'
import intl from '@/locale'

import SettingsTemplateProvider, {
	useSettingsTemplateContext,
} from './SettingsTemplateProvider'
import SettingsTemplateResolver from './SettingsTemplateResolver'
import SettingsTemplateActions from '../SettingsTemplateActions'

export default function SettingsTemplateModal({
	isOpen,
	close,
	templateId,
	templateType,
}: {
	isOpen: boolean
	close: () => void
	templateId?: string
	templateType: SettingsTemplateType
}) {
	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					disableFocusTrap
					size={'xlarge'}
					position='none'
					testId='settings-template-modal'
				>
					<ErrorBoundary>
						<SettingsTemplateProvider
							templateId={templateId}
							templateType={templateType}
							onFinish={close}
						>
							<SettingTemplateForm handleClose={close} />
						</SettingsTemplateProvider>
					</ErrorBoundary>
				</Modal>
			)}
		</ModalTransition>
	)
}

function SettingTemplateForm({ handleClose }: { handleClose: () => void }) {
	const context = useSettingsTemplateContext()
	return (
		<>
			<FormProvider
				methods={context!.methods}
				onSubmit={context.handleSubmit}
				className='relative flex h-full flex-col overflow-hidden'
			>
				<ModalHeader className='pr-10'>
					<div className='flex w-full items-start justify-between'>
						<ModalTitle>
							{`${intl.get('entity.template')}- ${intl.get(`template.type.${context.templateType}`)}`}
						</ModalTitle>
						<div className='flex items-center gap-2'>
							{context.template && (
								<SettingsTemplateActions
									template={context.template}
									onFinish={context.handleFinish}
								/>
							)}
							<Separator orientation='vertical' className='mr-2 h-6' />
						</div>
					</div>
				</ModalHeader>

				<Blanket
					className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-lg dark:bg-black/50'
					isTinted={context.isPending}
				>
					<div className='flex flex-col items-center justify-center gap-2'>
						<Loader />
						<span className='text-muted'>
							{intl.get('template_pending_hint').d('Saving template...')}
						</span>
					</div>
				</Blanket>
				<SettingsTemplateResolver />
				<ModalFooter className='justify-between border-t'>
					<Button
						data-testid='settings-template-modal-cancel'
						onClick={handleClose}
					>
						{intl.get('general_cancel')}
					</Button>
					<div className='flex items-center gap-2'>
						{(context.methods.formState?.isDirty || context.isNew) && (
							<div className='flex items-center space-x-2'>
								{!context.isNew && (
									<Button
										data-testid={'settings-template-form-revert-changes'}
										onClick={context.handleReset}
										variant='tertiary'
										iconBefore={<UndoIcon />}
									>
										{intl.get('app.revert_changes')}
									</Button>
								)}

								<Button
									variant='primary-warning'
									data-testid={'settings-template-form-save'}
									disabled={context.isPending || context.isLoading}
									onClick={context.handleSubmit}
									iconBefore={context.isPending ? <Spinner /> : <CheckIcon />}
								>
									{intl.get('general_save')}
								</Button>
							</div>
						)}
					</div>
				</ModalFooter>
			</FormProvider>
			<ModalCloseButton onClick={handleClose} />
		</>
	)
}
