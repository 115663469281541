import React from 'react'
import { FormProvider as Form, UseFormReturn } from 'react-hook-form'

type FormProviderProps = {
	children: React.ReactNode
	className?: string
	onSubmit(e: React.FormEvent<HTMLFormElement>): void
	methods: UseFormReturn<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}
export default function FormProvider({
	children,
	className,
	onSubmit,
	methods,
}: FormProviderProps) {
	return (
		<Form {...methods}>
			<form onSubmit={onSubmit} className={className}>
				{children}
			</form>
		</Form>
	)
}
