import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highcharts'
import 'highcharts/highcharts-more'
import 'highcharts/modules/treemap'
import 'highcharts/modules/heatmap'
import 'highcharts/modules/xrange'
//import * as Xrange from 'highcharts/modules/xrange'

/* Xrange(Highcharts)
Solidgauge(Highcharts) */
export default function Chart({ config }: { config: Highcharts.Options }) {
	return <HighchartsReact highcharts={Highcharts} options={config} />
}
