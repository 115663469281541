import { SeriesOptionsType } from 'highcharts'
import React from 'react'

import Chart from '@/components/Chart'
import { durations, historicalPricesFormatter } from '@/components/Chart/utils'

import { ChartSerie } from './types'

const enrichWithSeries = (series: ChartSerie[]): Highcharts.Options => ({
	chart: {
		height: 200,
		width: 375,
		backgroundColor: undefined,
		plotBackgroundColor: 'transparent',
		plotBorderWidth: 1,
		plotBorderColor: 'transparent',
	},
	exporting: { enabled: false },
	legend: { enabled: false },
	tooltip: {
		animation: false,
		outside: true,
		shared: true,
		followPointer: false,
		useHTML: true,
		backgroundColor: 'transparent',
		borderRadius: 10,
		borderWidth: 0,
		shadow: false,
		formatter: historicalPricesFormatter,
	},
	xAxis: {
		type: 'datetime',
		tickInterval: durations.day * 5,
		title: { text: '' },
		crosshair: true,
	},
	yAxis: {
		title: { text: '' },
		opposite: false,
	},
	plotOptions: {
		areasplinerange: {
			color: 'rgba(143, 162, 172, 0.5)',
			animation: {
				duration: 800,
			},
		},
		line: {
			lineWidth: 1,
			connectNulls: true,
			marker: {
				enabled: true,
				symbol: 'circle',
				radius: 3,
				lineWidth: 1,
			},
			animation: false,
		},
	},
	series: series as SeriesOptionsType[],
})
export default function PriceHistoryChart({
	series,
}: {
	series: ChartSerie[]
}) {
	return (
		<div className='fade-in pl-2'>
			<Chart config={enrichWithSeries(series)} />
		</div>
	)
}
