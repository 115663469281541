import { AxiosError } from 'axios'
import { any, pipe, prop } from 'lodash/fp'
import { MouseEvent } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { dialog } from '@/components/dialogs'
import notify from '@/components/toasts'
import { OptimizationModel } from '@/generated'
import intl from '@/locale'
import { ExportDialogActions } from '@/modules/core'
import { FAILED } from '@/modules/core/constants'
import {
	useCommitOptimizationMutation,
	useCreateOptimizationMutation,
	useDeleteExportOptimizationMutation,
	useLockOptimizationMutation,
	useStartOptimizationMutation,
	useStopOptimizationMutation,
	useUnlockOptimizationMutation,
	useUpdateOptimizationFinalFieldsMutation,
	useUpdateOptimizationWithDiffsMutation,
} from '@/modules/core/mutations'
import {
	useOptimizationGroup,
	useOptimizationQuery,
} from '@/modules/core/queries'
import analytic from '@/services/analytics'
import { successActionMessage } from '@/tools/message'

const skipEvent = (event: MouseEvent<HTMLButtonElement>) =>
	event && event.stopPropagation()
const showSuccessMessage = (action: string, id: string) => () =>
	notify.success(
		{
			text: successActionMessage('Optimization', action),
		},
		{ id }
	)

interface OptimizationController {
	/**
	 * Current optimization data
	 */
	optimization: OptimizationModel

	isProcessAction: boolean
	isOptimizationLoading: boolean

	/**
	 * Start optimization process
	 * move optimization to status `new > started|scheduled`
	 */
	start(): void

	/**
	 * Commit changes to optimization
	 * Move optimization to status `pending > updating > new`
	 */
	commit(): void

	/**
	 * Create and start optimization process
	 * Move optimization to status `* > new > started|scheduled`
	 */
	createAndStart(): void

	create(): void
	/**
	 * Stop optimization process
	 * Move optimization to status `started|scheduled > canceled|new|scheduled`
	 */
	stop(): void

	/**
	 * Apply optimization
	 * Move optimization to status `finished > locked`
	 */
	lock(): void

	/**
	 * Unlock optimization
	 * Move optimization to status `locked > finished`
	 */
	unlock(): void
}
export default function useOptimization(
	optimizationGroupId: string
): OptimizationController {
	/**
	 * Queries
	 */
	const queryClient = useQueryClient()
	const { data: optimization, isLoading: isOptimizationLoading } =
		useOptimizationQuery(optimizationGroupId)
	const optimizationGroup = useOptimizationGroup(optimizationGroupId)

	/**
	 * Mutations
	 */
	const create = useCreateOptimizationMutation()
	const commit = useCommitOptimizationMutation()
	const diffUpdate = useUpdateOptimizationWithDiffsMutation(optimizationGroupId)
	const finalUpdate =
		useUpdateOptimizationFinalFieldsMutation(optimizationGroupId)
	const start = useStartOptimizationMutation()
	const stop = useStopOptimizationMutation()
	const lock = useLockOptimizationMutation()
	const unlock = useUnlockOptimizationMutation()
	const deleteExport = useDeleteExportOptimizationMutation()
	const isProcessAction = any(prop('isPending'), [
		deleteExport,
		create,
		diffUpdate,
		finalUpdate,
		start,
		stop,
		lock,
		unlock,
		commit,
		create,
	])

	const handleActionError = (
		error: AxiosError<{
			error: string
		}>
	) => {
		// Check if error is from backend with error message
		if (error?.response?.data?.error) {
			notify.error(
				{
					text: error?.response?.data?.error,
				},
				{ id: optimizationGroupId }
			)
			queryClient.invalidateQueries({
				queryKey: ['optimization', optimizationGroupId],
			})
		} else {
			notify.error(
				{
					text: intl.get('fatal_error_title'),
				},
				{ id: optimizationGroupId }
			)
		}
	}
	const startOptimization = async () => {
		analytic.logEvent(
			`repricing: ${optimization!.status === FAILED ? 'restart' : 'start'}`,
			{
				'OG name': optimizationGroup.name,
				'Products amount': optimization!.total_products,
			}
		)
		return start.mutateAsync(optimizationGroupId)
	}
	const commitOptimization = () => {
		analytic.logEvent('repricing: commit', {
			'OG name': optimizationGroup.name,
			'Products amount': optimization!.total_products,
		})
		return commit.mutateAsync(optimizationGroupId)
	}
	const createOptimization = () => create.mutateAsync(optimizationGroupId)
	const stopOptimization = () => {
		analytic.logEvent('repricing: stop', {
			'OG name': optimizationGroup.name,
			'Products amount': optimization!.total_products,
		})
		return stop.mutateAsync(optimizationGroupId)
	}

	const unlockOptimization = async () => {
		analytic.logEvent('repricing: cancel repricing', {
			'OG name': optimizationGroup.name,
			'Products amount': optimization!.total_products,
		})
		try {
			const answer = await dialog.form({
				title: intl.get('optimization_remove_export_title'),
				text: intl.get('optimization_remove_export_message'),
				form(resolve) {
					return <ExportDialogActions resolve={resolve} />
				},
			})
			if (!answer || answer.action === 'close') {
				return
			}

			if (answer.action === 'ok') {
				if (!answer?.state?.save) {
					await deleteExport.mutateAsync(optimizationGroupId)
				}
				await unlock.mutateAsync(optimizationGroupId)
				notify.success(
					{
						text: successActionMessage('Optimization', 'unlocked'),
					},
					{ id: optimizationGroupId }
				)
			}
		} catch (error) {
			handleActionError(
				error as AxiosError<{
					error: string
				}>
			)
		}
	}
	const lockOptimization = async () => {
		try {
			if (optimization!.is_exported) {
				const answer = await dialog.confirm({
					title: intl.get('optimization_export_again_title'),
					text: intl.get('optimization_export_again_message'),
					okText: intl.get('general_apply'),
				})
				if (answer) {
					await lock.mutateAsync(optimizationGroupId)
				}
			} else {
				await lock.mutateAsync(optimizationGroupId)
				notify.success(
					{
						text: successActionMessage('Optimization', 'applied'),
					},
					{ id: optimizationGroupId }
				)
			}
		} catch (error) {
			handleActionError(
				error as AxiosError<{
					error: string
				}>
			)
		}
	}

	const actionStart = () =>
		startOptimization()
			.then(showSuccessMessage('started', optimizationGroupId))
			.catch(handleActionError)
	const actionCommit = () =>
		commitOptimization()
			.then(showSuccessMessage('changed', optimizationGroupId))
			.catch(handleActionError)
	const actionCreateAndStart = () =>
		createOptimization().then(actionStart).catch(handleActionError)
	const actionStop = () =>
		stopOptimization()
			.then(showSuccessMessage('stopped', optimizationGroupId))
			.catch(handleActionError)
	return {
		optimization: optimization as OptimizationModel,
		isProcessAction,
		isOptimizationLoading,
		start: pipe([skipEvent, actionStart]),
		commit: pipe([skipEvent, actionCommit]),
		create: pipe([skipEvent, createOptimization]),
		createAndStart: pipe([skipEvent, actionCreateAndStart]),
		stop: pipe([skipEvent, actionStop]),
		lock: pipe([skipEvent, lockOptimization]),
		unlock: pipe([skipEvent, unlockOptimization]),
	}
}
