import { find, omitAll } from 'lodash/fp'
import React, { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { Outlet, useLocation, useNavigate } from 'react-router'
import {
	Navigate,
	Route,
	Routes,
	// eslint-disable-next-line sort-imports
	unstable_HistoryRouter as ReactRouter,
} from 'react-router-dom'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { Result } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import { AlertDialog } from '@/components/dialogs'
import ErrorBoundary from '@/components/ErrorBoundary'

import OptimizationGroupHost from '@/components/layouts/OptimizationGroupHost'
import PrivateLayout from '@/components/layouts/Private'
import NotFound from '@/components/placeholders/NotFound'
import SplashScreen from '@/components/SplashScreen'
import SupportChat from '@/components/SupportChat'
import history from '@/lib/history'
import { AnchoringManagementPage } from '@/modules/anchoring-management'
import { AuthExchange, OAuth2Login } from '@/modules/Auth'
import { useAuth } from '@/modules/Auth/AuthContext'
import { getLoginUrl } from '@/modules/Auth/utils'
import { OptimizationGroupLayout, OptimizationGroupsPage } from '@/modules/core'
import { AiDashboardPage, MainDashboardPage } from '@/modules/dashboards'
import { GlobalAssortmentPage } from '@/modules/global-assortment'
import { ProductsPage } from '@/modules/og-products'
import {
	PromoCampaignPage,
	PromoCampaignsPage,
} from '@/modules/promo-campaigns'
import { SavedReportsPage } from '@/modules/saved-reports'
import { ScenariosPage, ScenariosSummaryPage } from '@/modules/scenarios'

import Sentry from '@/services/sentry'

import { OptimizationGroupSettingsPage } from './modules/og-settings'
import { useModalStore } from './modules/modals/store'
import { toQueryObject, toQueryString } from './tools/location'
import {
	/* PricingCampaignsTemplatesPage,
	ScenarioTemplatesPage, */
	SettingsTemplatesPage,
} from './modules/settings-templates/components/SettingsTemplatesPage'
import TemplatesHost from './components/layouts/TemplatesHost'
import TemplateEditor from './modules/settings-templates/components/TemplateBuilder'
import { PricingCampaignsPage } from './modules/pricing-campaigns/components/Campaigns/PricingCampaigns'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export default function Router() {
	return (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		<ReactRouter history={history as any}>
			<ErrorBoundary>
				<>
					<SentryRoutes>
						<Route index element={<Navigate to='/p/dashboard' replace />} />
						<Route path='/authenticate' element={<AuthExchange />} />
						<Route path='/authorize' element={<OAuth2Login />} />
						<Route path='/p' element={<AuthenticatedRoute />}>
							<Route element={<PrivateLayout />}>
								<Route path='og' element={<OptimizationGroupsPage />} />
								<Route path='pc' element={<PricingCampaignsPage />} />
								<Route
									path='products'
									element={<GlobalAssortmentPage type='products' />}
								/>
								<Route path='templates'>
									<Route index element={<Navigate replace to='scenario' />} />
									<Route path='editor' element={<TemplateEditor />} />
									<Route
										path='editor/:template_id'
										element={<TemplateEditor />}
									/>
									<Route element={<TemplatesHost />}>
										<Route path=':type' element={<SettingsTemplatesPage />} />
									</Route>
									{/* <Route
										path='pc'
										element={<PricingCampaignsTemplatesPage />}
									/>
									<Route path='scenarios' element={<ScenarioTemplatesPage />} /> */}
								</Route>
								<Route
									path='og/:optimizationGroupId'
									element={<OptimizationGroupLayout />}
								>
									<Route element={<OptimizationGroupHost />}>
										<Route path='products' element={<ProductsPage />} />
										<Route
											path='settings'
											element={<OptimizationGroupSettingsPage />}
										/>
										<Route
											path='scenarios/summary'
											element={<ScenariosSummaryPage />}
										/>
										<Route path='scenarios' element={<ScenariosPage />} />
										<Route path='*' element={<NotFound />} />
									</Route>
								</Route>

								<Route path={'assortment'}>
									<Route index element={<Navigate to='products' replace />} />
									<Route
										path='products'
										element={<GlobalAssortmentPage type={'assortment'} />}
									/>
									<Route path='reports' element={<SavedReportsPage />} />
									<Route
										path='management'
										element={<AnchoringManagementPage />}
									/>
								</Route>

								<Route path='promo'>
									<Route index element={<PromoCampaignsPage />} />
									<Route path='create' element={<PromoCampaignPage />} />
									<Route path=':id' element={<PromoCampaignPage />} />
								</Route>

								<Route path={'dashboard'}>
									<Route index element={<Navigate to='/p/dashboard/main' />} />
									<Route path='main' element={<MainDashboardPage />} />
									<Route path='ai' element={<AiDashboardPage />} />
								</Route>
							</Route>
						</Route>
						<Route path='/404' element={<NotFound />} />
						<Route
							path='/login'
							element={<Navigate to='/p/dashboard' replace />}
						/>
						<Route path='*' element={<NotFound />} />
					</SentryRoutes>
					<SentryRoutes>
						<Route path='/p/pc' element={<PricingCampaignModalRoute />} />
					</SentryRoutes>
					<SupportChat />
					<div className='relative z-[999999]'>
						<AlertDialog />
					</div>
					<Toaster
						toastOptions={{
							duration: 3000,
							className: 'text-xs',
							loading: {
								duration: 999999,
							},
							success: {
								duration: 3000,
							},
							error: {
								duration: 3000,
							},
						}}
					/>
					<ReactQueryDevtools />
				</>
			</ErrorBoundary>
		</ReactRouter>
	)
}
function PricingCampaignModalRoute() {
	const location = useLocation()
	const navigate = useNavigate()
	const { showModal } = useModalStore()
	useEffect(() => {
		const query = toQueryObject(location.search)
		if (location.search && query['pc']) {
			navigate(`${location.pathname}${toQueryString(omitAll(['pc'], query))}`, {
				replace: true,
			})
			showModal('PRICING_CAMPAIGN_MODAL', {
				pricingCampaignId: query['pc'] as string,
			})
		}
	}, [location.search])
	return null
}
export function HardNavigate({ to }: { to: string }) {
	useEffect(() => {
		window.location.href = to
	}, [])

	return null
}

function AuthenticatedRoute() {
	const { user, isLoading } = useAuth()
	if (!isLoading) {
		if (user) {
			if (find({ name: 'pricing_platform', enabled: true }, user.apps)) {
				return <Outlet />
			} else {
				return (
					<Result
						className={
							'result m-auto flex h-full w-96 flex-col items-center justify-center font-bold'
						}
						icon={<AlertIcon width={80} height={80} />}
						title={'Access denied'}
						subtitle={
							'You do not have access to this project. Please contact your administrator or support team for more information.'
						}
					>
						<a href='mailto:po-support@competera.net'>
							po-support@competera.net
						</a>
					</Result>
				)
			}
		} else {
			return <HardNavigate to={getLoginUrl()} />
		}
	} else {
		return <SplashScreen />
	}
}
