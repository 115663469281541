import { addMonths, format, startOfMonth } from 'date-fns'

import { DateFormat } from '@/tools/dates'

import { DashboardWidgetEntity } from './types'

export function getDefaultPeriods(integrationDate: string) {
	const integration = new Date(integrationDate)
	return {
		main: {
			start_date: format(startOfMonth(integration), DateFormat.system),
			end_date: format(integration, DateFormat.system),
		},
		compared: {
			start_date: format(
				startOfMonth(addMonths(integration, -1)),
				DateFormat.system
			),
			end_date: format(addMonths(integration, -1), DateFormat.system),
		},
	}
}
export const filterWidgetsBySection =
	(section: string) =>
	({ widget: { config } }: DashboardWidgetEntity) =>
		config.section === section

export function getDifferenceInPercents(
	number1: number,
	number2: number
): number {
	const result = (number1 / number2 - 1) * 100
	return Number.isFinite(result) ? result : 0
}
export function getPercentOf(num: number, total: number): number {
	return (num / total) * 100
}
