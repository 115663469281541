import * as Highcharts from 'highcharts/highcharts'

const theme: Highcharts.Options = {
	chart: {
		backgroundColor: 'transparent',
		className: 'dark-container',
	},
	title: {
		text: '',
		style: {
			color: 'var(--cmp-foreground)',
		},
	},
	subtitle: {
		style: {
			color: 'var(--cmp-muted)',
		},
	},
	xAxis: {
		gridLineColor: 'var(--cmp-border)',
		gridLineWidth: 0,
		labels: {
			style: {
				color: 'var(--cmp-foreground)',
			},
		},
		lineColor: 'var(--cmp-border)',
		tickColor: 'var(--cmp-border)',
		title: {
			style: {
				color: 'var(--cmp-foreground)',
				fontWeight: 'bold',
				fontSize: '12px',
			},
		},
	},
	yAxis: {
		gridLineColor: 'var(--cmp-border)',
		labels: {
			style: {
				color: 'var(--cmp-foreground)',
			},
		},
		lineColor: 'var(--cmp-border)',
		//minorTickInterval: null,
		tickColor: 'var(--cmp-border)',
		tickWidth: 1,
		title: {
			style: {
				color: 'var(--cmp-foreground)',
				fontWeight: 'bold',
				fontSize: '12px',
			},
		},
	},
	tooltip: {
		backgroundColor: 'var(--cmp-backdrop)',
		style: {
			color: 'var(--cmp-foreground)',
		},
	},
	plotOptions: {
		treemap: {
			//borderColor: 'var(--cmp-border)',
			states: {
				hover: {
					borderColor: 'var(--cmp-brand)',
				},
			},
		},
		line: {
			dataLabels: {
				color: 'var(--cmp-foreground)',
			},
			marker: {
				lineColor: 'var(--cmp-border)',
			},
		},
		spline: {
			marker: {
				lineColor: 'var(--cmp-border)',
			},
		},
		scatter: {
			marker: {
				lineColor: 'var(--cmp-border)',
			},
		},
		xrange: {
			borderColor: 'var(--cmp-border)',
		},
		candlestick: {
			lineColor: 'var(--cmp-foreground)',
		},
		pie: {
			borderColor: 'var(--cmp-application)',
			allowPointSelect: true,
			cursor: 'pointer',
			dataLabels: {
				enabled: false,
			},
		},
		column: {
			borderColor: 'var(--cmp-application)',
		},
	},
	legend: {
		backgroundColor: 'transparent',
		itemStyle: {
			color: 'var(--cmp-foreground)',
		},
		itemHoverStyle: {
			color: 'var(--cmp-foreground)',
		},
		itemHiddenStyle: {
			color: '#444',
		},
		title: {
			style: {
				color: 'var(--cmp-foreground)',
			},
		},
	},
	credits: {
		enabled: false,
		style: {
			color: 'var(--cmp-muted)',
		},
	},
}
Highcharts.setOptions(theme)
