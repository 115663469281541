import { prop } from 'lodash/fp'
import React, { useEffect } from 'react'

import { UseMutationResult } from '@tanstack/react-query'

import { Result } from '@cmpkit/base'

import dataWarn from '@/assets/img/svg/data_proccesing_warning.svg'
import dataUpdating from '@/assets/img/svg/data_updating_proccess.svg'
import dataResult from '@/assets/img/svg/result.svg'
import { PricingCampaignReassignRequest } from '@/generated'
import intl from '@/locale'

import { PricingCampaignReassignResultModel } from '../../types'
import PricingCampaignManagementResults from '../PricingCampaignManagementResults'

type Props = {
	formData: {
		campaigns: string[]
		groups: string[]
	}
	run: UseMutationResult<
		PricingCampaignReassignResultModel[],
		Error,
		PricingCampaignReassignRequest,
		unknown
	>
}
const isAllSuccess = (data: PricingCampaignReassignResultModel[]) => {
	return data?.every(prop('success'))
}
export default function ExecutionView({ formData, run }: Props) {
	useEffect(() => {
		const requestBody: PricingCampaignReassignRequest = {
			src_pricing_campaign_ids: formData.campaigns,
			dst_optimization_group_id: formData.groups[0],
		}
		run.mutateAsync(requestBody)
	}, [])
	if (run.isPending) {
		return (
			<Result
				icon={<img src={dataUpdating} />}
				className='flex h-full flex-col justify-center py-10'
				title={intl.get('pc.processing.loading.title')}
			/>
		)
	} else if (run.isError) {
		return (
			<Result
				icon={<img src={dataWarn} />}
				className='flex h-full flex-col justify-center py-10'
				title={intl.get('pc.processing.error.title')}
			/>
		)
	} else {
		if (isAllSuccess(run.data!)) {
			return (
				<Result
					icon={<img src={dataResult} />}
					className='flex h-full flex-col justify-center py-10'
					title={intl.get('pc.processing.success.title', {
						action: 'reassigned',
						campaigns: formData.campaigns.length,
						groups: 1,
					})}
				/>
			)
		} else {
			return (
				<Result
					icon={<img src={dataResult} />}
					className='flex h-full flex-col justify-center py-10'
					title={intl.get('pc.processing.indeterminate.title', {
						action: 'reassigned',
						campaigns: run?.data?.filter(prop('success'))?.length,
						totalCampaigns: formData.campaigns.length,
						groups: 1,
						totalGroups: 1,
					})}
				>
					<PricingCampaignManagementResults response={run?.data} />
				</Result>
			)
		}
	}
}
