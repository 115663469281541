import clsx from 'clsx'
import React, { useMemo, useState } from 'react'

import { Loader } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import Grid, {
	Column,
	DataRow,
	Sort,
	Table,
	TableColumnFixed,
	TableColumnResizing,
	TableSorting,
	Template,
	TemplateConnector,
} from '@cmpkit/data-table'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'

import intl from '@/locale'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TableViewProps<T extends any = any> = {
	rows: T[]
	columns: Column[]
	isLoading: boolean
	className?: string
	getRowId: (row: DataRow<T>) => string
	getRowHeight?: (row: DataRow<T>) => number
}
export default function TableView<T>({
	className = 'h-96',
	rows,
	columns,
	isLoading,
	getRowId,
	getRowHeight,
}: TableViewProps<T>) {
	const [sort, setSort] = useState<Sort[]>([])
	const [columnsLocks, setColumnsLocks] = useState<string[]>([])
	const [columnsWidth, setColumnsWidth] = useState<Record<string, number>>({})
	const defaultLocks = useMemo(
		() => columns.filter((c) => c.locked).map((c) => c.id),
		[columns]
	)

	return (
		<div
			className={clsx(
				'relative overflow-hidden rounded-lg border border-solid border-base bg-accent-4',
				className
			)}
		>
			<Blanket
				className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-lg dark:bg-black/50'
				isTinted={isLoading}
			>
				<Loader />
			</Blanket>
			<Grid
				rows={rows}
				columns={columns}
				getRowId={getRowId}
				getRowHeight={getRowHeight}
			>
				<Table className='h-96 w-full' divideX divideY />
				<TableColumnResizing
					columnsWidth={columnsWidth}
					onSaveColumnsWidth={setColumnsWidth}
				/>
				<TableColumnFixed
					defaultLocks={defaultLocks}
					columnsLocks={columnsLocks}
					onColumnsLocksChange={(locks) => locks && setColumnsLocks(locks)}
				/>
				<TableSorting sort={sort} onSortChange={setSort} />
				<Template name='tableNoData'>
					<TemplateConnector>
						{() => (
							<div className='flex h-72 w-full flex-1 items-center justify-center'>
								<div className='flex flex-col items-center space-y-2 p-5 text-center'>
									<SearchIcon width={72} height={72} className='text-muted' />
									<span className='truncate text-muted'>
										{intl
											.get('dashboard.empty_widget')
											.d('No data found to load')}
									</span>
								</div>
							</div>
						)}
					</TemplateConnector>
				</Template>
			</Grid>
		</div>
	)
}
