import { Settings2Icon } from 'lucide-react'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { useIsFetching } from '@tanstack/react-query'
import {
	getCoreRowModel,
	getSortedRowModel,
	Row,
	SortingState,
} from '@tanstack/react-table'

import { Badge, Button, Checkbox, LinkButton, Tag } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import { DatetimeDistanceField } from '@/components/data-grid/fields'
import NumberField from '@/components/data-grid/fields/Number/TableCell'
import { encodeComplexQuery } from '@/components/data-grid/helpers'
import { ColumnDataType } from '@/components/data-grid/types'
import { useMasterTable } from '@/components/table/hooks/useMasterTable'
import MasterTable from '@/components/table/MasterTable'
import { createMasterColumnHelper } from '@/components/table/utils/tanstack.helpers'
import intl from '@/locale'
import { useDrawer, useDrawersStore } from '@/modules/drawers/store'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'
import { toQueryString } from '@/tools/location'

import { FINISHED } from '../../constants'
import { OptimizationGroupTableRow } from '../../types'
import { getOgStatus, getStatusBadgeVariant } from '../../utils'
import { Skeleton } from '../loaders'
import ScheduleDate from '../OptimizationScheduler/ScheduleDate'
import ReviewersPreview from '../Reviews/ReviewersPreview'
import { OptimizationGroupsContext } from './OptimizationGroupsProvider'

export default function OptimizationGroupsTable({
	data,
}: {
	data: OptimizationGroupTableRow[]
}) {
	const [sorting, setSorting] = useState<SortingState>([])
	const columns = useMemo(() => getColumns(), [])
	const drawer = useDrawer('PRICING_ALERTS')
	const getRowClassName = useCallback(
		({ row }: { row: Row<OptimizationGroupTableRow> }) => {
			return drawer.props?.optimizationGroupIds?.includes(
				row.original.group.id
			) && drawer.isOpen
				? 'bg-blue-25 dark:bg-blue-175 h-12'
				: 'h-12'
		},
		[drawer.props?.optimizationGroupIds]
	)
	const table = useMasterTable<OptimizationGroupTableRow>({
		data,
		columns,
		state: {
			sorting,
		},
		enableVirtualization: true,
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		getRowHeight: () => 48,
		getRowClassName,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})

	return <MasterTable table={table} />
}

const columnHelper = createMasterColumnHelper<OptimizationGroupTableRow>()
const getColumns = () => [
	columnHelper.accessor((row) => row.group.id, {
		id: 'control',
		size: 28,
		enableSorting: false,
		enableHeaderTooltip: false,
		header: () => {
			const { selection } = useContext(OptimizationGroupsContext)
			return (
				<Checkbox
					indeterminate={
						!selection.getIsAllRowsSelected() &&
						selection.getIsSomeRowsSelected()
					}
					checked={selection.getIsAllRowsSelected()}
					onChange={selection.getToggleAllRowsSelectedHandler()}
				/>
			)
		},
		cell: (info) => {
			const id = info.getValue()
			const { selection } = useContext(OptimizationGroupsContext)
			return (
				<Checkbox
					checked={selection.getIsRowSelected(id)}
					onChange={selection.getToggleSelectedHandler(id)}
				/>
			)
		},
	}),
	columnHelper.accessor('group.name', {
		header: () => intl.get('optimization_groups_name'),
		cell: (info) => {
			const { showModal } = useModalStore()
			return (
				<div className='flex items-center justify-between'>
					<Tooltip
						content={info.getValue()}
						hidePointer
						delay={400}
						placement='bottom-start'
					>
						<LinkButton asChild variant='brand' className='line-clamp-1'>
							<Link to={`/p/og/${info.row.original.group.id}/products`}>
								{info.getValue()}
							</Link>
						</LinkButton>
					</Tooltip>
					<Tooltip content={intl.get('settings').d('Settings')}>
						<Button
							data-testid='view-og-modal-trigger'
							variant='tertiary'
							iconBefore={<Settings2Icon />}
							onClick={() =>
								showModal('OPTIMIZATION_GROUP_MODAL', {
									optimizationGroupId: info.row.original.group.id,
								})
							}
						/>
					</Tooltip>
				</div>
			)
		},
	}),
	columnHelper.accessor('sales_level_name', {
		size: 80,
		header: () => intl.get('og.level'),
		cell: (info) => {
			return <Tag>{info.getValue()}</Tag>
		},
	}),
	columnHelper.accessor('group.pos_ids', {
		size: 80,
		header: () => intl.get('sales_unit').d('Sales unit'),
		cell: (info) => {
			const value = info.getValue()
			return (
				<NumberField
					value={value?.length || 0}
					schema={{ type: ColumnDataType.Integer }}
				/>
			)
		},
	}),
	columnHelper.accessor((row) => getOgStatus(row?.optimization?.status), {
		size: 150,
		id: 'optimization.status',
		header: () => intl.get('optimization_groups_repricing_status'),
		cell: (info) => {
			const value = info.getValue()
			return (
				<SyncWrapper queryKey={['optimizations']}>
					<Tag variant={getStatusBadgeVariant(value)}>
						{intl.get(`opt_${value}`)}
					</Tag>
				</SyncWrapper>
			)
		},
	}),
	columnHelper.accessor('schedule.nearest_date', {
		size: 136,
		header: () => intl.get('general_schedule'),
		cell: (info) => {
			const data = info.row.original.schedule
			return (
				<SyncWrapper queryKey={['schedules']}>
					{data ? <ScheduleDate schedule={data} /> : '-'}
				</SyncWrapper>
			)
		},
	}),
	columnHelper.accessor('optimization.total_products', {
		size: 100,
		header: () => intl.get('optimization_groups_total_products'),
		cell: (info) => {
			return (
				<SyncWrapper queryKey={['optimizations']}>
					<LinkButton className='w-full' variant='brand' asChild>
						<Link
							to={`/p/assortment/products${toQueryString({
								qf: encodeComplexQuery({
									filters: [
										{
											name: 'optimization_group_id',
											operation: Operators.IN,
											value: [info.row.original.group.id],
										},
									],
								}),
							})}`}
						>
							<NumberField
								value={info.getValue()}
								schema={{ type: ColumnDataType.Integer }}
							/>
						</Link>
					</LinkButton>
				</SyncWrapper>
			)
		},
	}),
	columnHelper.accessor('notifications_count', {
		size: 70,
		header: () => intl.get('alerts').d('Alerts'),
		cell: (info) => {
			const value = info.getValue()
			const { openDrawer } = useDrawersStore()
			return (
				<SyncWrapper
					queryKey={['pricing-alerts', { group_by: 'optimization_group_id' }]}
				>
					{value > 0 ? (
						<Badge
							interactive
							onClick={() => {
								analytic.logEvent('og list: alerts by og: open')
								openDrawer('PRICING_ALERTS', {
									type: 'byOptimizationGroups',
									optimizationGroupIds: [info.row.original.group.id],
									drawerClassName: 'og-alerts-eastside',
								})
							}}
							className='fade-in cursor-pointer text-nowrap font-mono'
							variant='danger'
						>
							{formatNumber(value)}
						</Badge>
					) : (
						'-'
					)}
				</SyncWrapper>
			)
		},
	}),
	columnHelper.accessor('review', {
		size: 150,
		header: () => intl.get('reviewers'),
		cell: (info) => {
			const review = info.getValue()
			const { showModal } = useModalStore()
			const _onSendToReview = () =>
				showModal('REVIEW_MANAGEMENT', {
					optGroupId: info.row.original.group.id,
				})
			return (
				<SyncWrapper queryKey={['reviews']}>
					{info.row.original.optimization?.status === FINISHED ? (
						review ? (
							<ReviewersPreview review={review} />
						) : (
							<Button onClick={_onSendToReview}>
								{intl.get('app.to_review').d('To review')}
							</Button>
						)
					) : (
						'-'
					)}
				</SyncWrapper>
			)
		},
	}),
	columnHelper.accessor('optimization.finished_date', {
		size: 150,
		header: () => intl.get('optimization_groups_last_repricing'),
		cell: (info) => {
			const date = info.getValue()
			return (
				<SyncWrapper width={50} queryKey={['optimizations']}>
					<DatetimeDistanceField value={date ? date + 'Z' : date} />
				</SyncWrapper>
			)
		},
	}),
]
const SyncWrapper = ({
	children,
	width = 60,
	queryKey,
}: {
	children: React.ReactNode
	width?: number
	queryKey: (string | object)[]
}) => {
	const isFetching = useIsFetching({
		queryKey,
		exact: true,
	})
	const random = useMemo(() => Math.random() * (1 - 0.7) + 0.7, [])
	return isFetching ? <Skeleton width={width * random} /> : children
}
