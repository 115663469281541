import { filter, find, prop, split, uniq } from 'lodash/fp'

import { treeHelpers, TreeNodeType } from '@cmpkit/base'

import { filterNodesOfTree } from '@/components/TreePicker'
import { TreeMapProductGroupModel, TreeMapSalesEntityModel } from '@/generated'
import { OptimizationGroupTableRow } from '@/modules/core/types'

import { ChartTreePoint } from './types'

export type GetterArgs = {
	row: OptimizationGroupTableRow
	entity?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}
export const buildTree = (
	data: OptimizationGroupTableRow[],
	{
		getEntities,
		getNodeValue,
		getAdditionalData,
		getChildrenStatistics,
		getNodeColoring,
	}: {
		getEntities: (row: OptimizationGroupTableRow) => TreeNodeType[]
		getNodeValue: (args: GetterArgs) => number
		getAdditionalData: (
			args: GetterArgs
		) => TreeMapSalesEntityModel | TreeMapProductGroupModel | undefined
		getChildrenStatistics: (
			filterIds: string[],
			ids: string[]
		) => Record<number, number>
		getNodeColoring: ({ row, entity }: GetterArgs) => {
			color?: string
			colorValue?: number
		}
	}
): ChartTreePoint[] => {
	const level = data.map((row) => {
		const { group } = row

		const entities = getEntities(row)
		const entitiesIds = entities.map(prop('id')) as string[]
		const sublevels =
			entities.map((entity) => {
				const coloring = getNodeColoring({
					row,
					entity,
				})
				const parentId = entity.parent?.id
				return {
					parent: parentId ? `${group.id}__${parentId}` : group.id,
					id: `${group.id}__${entity.id}`,
					name: entity.name,
					additional: () => {
						const statistics = getChildrenStatistics(entitiesIds, [entity.id])
						const other = getAdditionalData({
							row,
							entity,
						})

						const link = createNodeLink(other as any) // eslint-disable-line @typescript-eslint/no-explicit-any
						return {
							link,
							statistics,
							...other,
						}
					},
					value:
						getNodeValue({
							row,
							entity,
						}) || 0,
					...coloring,
				}
			}) || []

		const coloring = getNodeColoring({
			row,
		})

		return [
			{
				id: group.id,
				name: group.name,
				value: getNodeValue({ row }) || 0,
				additional: () => ({
					row,
					link: createNodeLink({
						optimization_group_id: group.id,
					}),
					statistics: getChildrenStatistics(entitiesIds, entitiesIds),
					...getAdditionalData({
						row,
					}),
				}),
				...coloring,
			},
			...sublevels,
		]
	})
	return level.flat() as ChartTreePoint[]
}

function createNodeLink(params: {
	sales_entity_id?: string
	category_id?: string
	optimization_group_id?: string
}) {
	return `/p/og/${params?.optimization_group_id}/products`
}
export const getTreeNodesIds = (tree: TreeNodeType[], ids: string[]) => {
	return uniq([
		...ids,
		...treeHelpers
			.findNodes(tree, ids)
			.map((node) => treeHelpers.getNodeParents(node).map(prop('id')))
			.flat(),
	])
}
export const getLevelsStatistics = (
	tree: TreeNodeType[],
	idsScope: string[],
	ids: string[]
) => {
	const filteredTree = filterNodesOfTree(tree, (node) =>
		idsScope.includes(node.id)
	)
	const getStat = (
		nodes: TreeNodeType[],
		byLevels: Record<number, number> = {}
	) => {
		for (const node of nodes) {
			byLevels[node.level] = (byLevels[node.level] || 0) + 1
			if (node.children) {
				getStat(node.children, byLevels)
			}
		}
		return byLevels
	}
	return getStat(
		filterNodesOfTree(filteredTree, (node) => ids.includes(node.id))
	)
}

export const getSalesEntitySatistics = (
	data: TreeMapSalesEntityModel[] | undefined,
	params: GetterArgs
): TreeMapSalesEntityModel | undefined => {
	if (!params.entity?.id) {
		return find(
			{
				optimization_group_id: params.row.group.id,
				sales_level_id: 1,
			},
			data || []
		) as TreeMapSalesEntityModel | undefined
	} else {
		return find(
			{
				optimization_group_id: params.row.group.id,
				sales_entity_id: params.entity.id,
			},
			data || []
		)
	}
}

export const getProductGroupsSatistics = (
	data: TreeMapProductGroupModel[] | undefined,
	params: GetterArgs
): TreeMapProductGroupModel | undefined => {
	if (!params.entity?.id) {
		const productGroupsIds = params.row.group.product_groups.map(
			prop('product_group_id')
		)
		return filter(
			(stat) =>
				productGroupsIds.includes(stat.product_group_id) &&
				stat.optimization_group_id === params.row.group.id &&
				!stat.category_id,
			data || []
		)?.reduce(cumulatedValue, {})
	} else {
		const [product_group_id, category_id] = split('|', params.entity.id)
		return find(
			{
				optimization_group_id: params.row.group.id,
				product_group_id,
				category_id: category_id || null,
			},
			data || []
		)
	}
}

function cumulatedValue(
	acc: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	item: any // eslint-disable-line @typescript-eslint/no-explicit-any
) {
	for (const key in item) {
		if (typeof item[key] === 'number') {
			acc[key] = (Number(acc[key]) || 0) + Number(item[key])
		} else {
			acc[key] = item[key]
		}
	}
	return acc
}
