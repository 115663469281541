import { any, last, prop } from 'lodash/fp'
import React from 'react'

import { selectDefaultMetricsKeys } from '@/components/BI/utils'
import { BIResponse } from '@/generated'
import intl from '@/locale'
import { useBIAnalyticQuery, useMetricsQuery } from '@/modules/bi/queries'
import { zipSeries } from '@/modules/bi/utils'
import { formatNumber, NumberFormats } from '@/tools/locale'

import PercentDifferenceBadge from '../../PercentDifferenceBadge'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import { WidgetProps } from '../types'

export default function NumericGrossProfitWidget({
	widget,
	commonPeriods,
	commonFilters,
}: WidgetProps) {
	const { data: metrics, ...metricsQuery } = useMetricsQuery<string[]>({
		placeholderData: [],
		select: selectDefaultMetricsKeys,
	})
	const biCommonQuery = {
		label: widget.kind,
		metrics: metrics!,
		dimensions: [],
		filters: [...commonFilters],
		date_aggregation: null,
	}
	const biQueryConfig = {
		enabled: !!metrics?.length,
		select: (data: BIResponse | null) =>
			prop('revenue', last(zipSeries(data?.meta, data?.data))) as number,
	}
	const biComparedPeriodQuery = useBIAnalyticQuery<number>(
		{
			...commonPeriods.compared,
			...biCommonQuery,
		},
		biQueryConfig
	)
	const biMainPeriodQuery = useBIAnalyticQuery<number>(
		{
			...commonPeriods.main,
			...biCommonQuery,
		},
		biQueryConfig
	)
	const isLoading = any(prop('isFetching'), [
		biMainPeriodQuery,
		biComparedPeriodQuery,
		metricsQuery,
	])
	const diff =
		(biMainPeriodQuery.data! * 100) / biComparedPeriodQuery.data! - 100
	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				title={intl.get('metric.revenue')}
				subtitle={intl.get('app.based_on_last_integration')}
			/>
			<WidgetContent className='truncate'>
				{isLoading ? (
					<div className='col-span-2 h-10 w-32 animate-pulse rounded-lg bg-accent-3' />
				) : (
					<div className='flex flex-col space-y-1'>
						<div className='flex items-center space-x-2'>
							<PercentDifferenceBadge value={diff} />
							<span className='truncate text-xs text-muted'>
								({intl.get('past')}:{' '}
								{formatNumber(
									biComparedPeriodQuery.data!,
									NumberFormats.Compact
								)}
								)
							</span>
						</div>
						<span className='truncate text-3xl font-semibold'>
							{formatNumber(biMainPeriodQuery.data!, NumberFormats.Compact)}
						</span>
					</div>
				)}
			</WidgetContent>
		</Widget>
	)
}
