import { all, find } from 'lodash/fp'
import React, { useCallback } from 'react'

import { useAuth } from './AuthContext'

export const useAuthorization = () => {
	const { user } = useAuth()

	if (!user) {
		throw Error('User does not exist!')
	}
	const app = find({ name: 'pricing_platform' }, user.apps)
	const permissions = app?.permissions
	const checkPermissons = useCallback(
		(permissions: string[]) => {
			return all((p) => app?.permissions?.includes(p), permissions)
		},
		[permissions]
	)

	return { checkPermissons, permissions }
}

type AuthorizationProps = {
	fallback?: React.ReactNode
	children: React.ReactNode
	permissions: string[]
}

export const Authorization = ({
	permissions,
	fallback = null,
	children,
}: AuthorizationProps) => {
	const { checkPermissons } = useAuthorization()

	let canAccess = false

	if (permissions) {
		canAccess = checkPermissons(permissions)
	}
	return <>{canAccess ? children : fallback}</>
}
