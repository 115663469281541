import { useMemo } from 'react'

import { Button } from '@cmpkit/base'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'

import { useModalStore } from '@/modules/modals/store'

import { MetricPerfomanceHeaderProp } from '../../types'
import { MainDashboardFilter } from '../Filter'
import { MultiPeriodsSelect } from '../Periods'

export default function MetricPerfomanceHeader({
	data,
	params,
	onFilterChange,
	periods,
	defaultPeriods,
	setPeriods,
	commonFilters,
}: MetricPerfomanceHeaderProp) {
	const { showModal } = useModalStore()
	const filters = useMemo(
		() => [...commonFilters, ...(params.filters ?? [])],
		[params.filters, commonFilters]
	)
	/**
	 * Handler for open modal to download metric perfomance report
	 */
	const handleExportReportClick = () =>
		showModal('EXPORT_ADDITIONAL_DATA', {
			kind: data.kind,
			fields: data.fields,
			rows: data.rows,
			defaultName: data.name,
		})
	return (
		<div className='flex justify-between'>
			<MainDashboardFilter
				irremovableCount={commonFilters.length}
				filters={filters ?? []}
				onChange={onFilterChange}
			/>
			<div className='flex items-center'>
				<MultiPeriodsSelect
					periods={periods}
					defaultPeriods={defaultPeriods}
					onChangePeriods={setPeriods}
				/>
				<Button
					className='ml-2'
					onClick={handleExportReportClick}
					iconBefore={<DownloadIcon />}
				/>
			</div>
		</div>
	)
}
