import clsx from 'clsx'
import {
	any,
	filter,
	groupBy,
	map,
	mapValues,
	pipe,
	prop,
	sum,
} from 'lodash/fp'
import * as qs from 'qs'
import { useNavigate } from 'react-router'

import { Button, Content, Header, Layout, Result } from '@cmpkit/base'
import Drawer from '@cmpkit/drawer'
import CheckCircleIcon from '@cmpkit/icon/lib/glyph/check-circle'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import { Operators } from '@cmpkit/query-builder'

import { encodeComplexQuery } from '@/components/data-grid/helpers'
import ErrorBoundary from '@/components/ErrorBoundary'
import FatalError from '@/components/FatalError'
import { rulesToQueryObject } from '@/components/filter/utils'
import PricingAlerts from '@/components/PricingAlerts'
import {
	AlertGroupBy,
	AlertsInfoResponseModel,
	FilterRuleModel,
	PricingCampaignModel,
} from '@/generated'
import intl from '@/locale'
import {
	useMultiplePricingAlertsQuery,
	useOptimizationGroup,
	usePricingAlertsAnnotationsQuery,
	usePricingAlertsQuery,
} from '@/modules/core/queries'
import { usePricingCampaignsQuery } from '@/modules/pricing-campaigns/queries'
import { formatNumber } from '@/tools/locale'

import { getPricingAlertsTree } from './helpers'

type AlertsDrawerCommonProps = {
	isOpen: boolean
	close(): void
	drawerClassName?: string
}
type AlertsDrawerByPricingCampaignProps = {
	pricingCampaign: PricingCampaignModel
	optimizationGroupIds?: string[]
	hasSolution?: boolean
}
type AlertsDrawerByOptimizationsProps = {
	scenarioId: string
	optimization: {
		optimization_id: string
		optimization_group_id: string
	}
	name: string
}
type AlertsDrawerByOptimizationGroupProps = {
	pricingCampaign?: PricingCampaignModel
	optimizationGroupIds: string[]
	hasSolution?: boolean
}
export type AlertsDrawerProps = AlertsDrawerCommonProps & {
	type:
		| 'byPricingCampaign'
		| 'byOptimizationGroups'
		| 'global'
		| 'byOptimizations'
	pricingCampaign?: PricingCampaignModel
	optimizationGroupIds?: string[]
	hasSolution?: boolean
	optimization?: {
		optimization_id: string
		optimization_group_id: string
	}
	name?: string
	scenarioId?: string
}

export default function PricingAlertsDrawer({
	isOpen,
	close,
	drawerClassName,
	type,
	...props
}: AlertsDrawerProps) {
	return (
		<Drawer
			className={clsx('cmp-border shadow', drawerClassName)}
			orient='right'
			isOpen={isOpen}
			disableBlanket
		>
			<ErrorBoundary>
				{type === 'byPricingCampaign' && (
					<PricingAlertsByPricingCampaign
						{...(props as AlertsDrawerByPricingCampaignProps)}
						close={close}
					/>
				)}
				{type === 'byOptimizationGroups' && (
					<PricingAlertsByOptimizationGroups
						{...(props as AlertsDrawerByOptimizationGroupProps)}
						close={close}
					/>
				)}
				{type === 'global' && <GlobalPricingAlerts close={close} />}
				{type === 'byOptimizations' && (
					<PricingAlertsByOptimizations
						close={close}
						{...(props as AlertsDrawerByOptimizationsProps)}
					/>
				)}
			</ErrorBoundary>
		</Drawer>
	)
}
export function PricingAlertsByOptimizations({
	close,
	optimization,
	name,
	scenarioId,
}: AlertsDrawerByOptimizationsProps & { close(): void }) {
	const { optimization_group_id } = optimization
	const pricingAlertsAnnotationsQuery = usePricingAlertsAnnotationsQuery()
	const pricingAlertsByOptimizationQuery = useMultiplePricingAlertsQuery({
		group_by: AlertGroupBy.OptimizationGroupId,
		optimizations: [optimization],
	})
	const pricingCampaignsQuery = usePricingCampaignsQuery({
		optimization_group_id,
		scenario_id: scenarioId,
	})
	const pricingAlertsByPricingCampaign = useMultiplePricingAlertsQuery<
		Record<string, string[]>
	>(
		{
			group_by: AlertGroupBy.PricingCampaignId,
			optimizations: [optimization],
		},
		{
			select: pipe(
				prop('by_id'),
				groupBy('id'),
				mapValues(map('pricing_campaign_id'))
			),
		}
	)
	const isLoading = any(prop('isLoading'), [
		pricingAlertsByOptimizationQuery,
		pricingAlertsAnnotationsQuery,
		pricingCampaignsQuery,
		pricingAlertsByPricingCampaign,
	])
	const isError = any(prop('isError'), [
		pricingAlertsByOptimizationQuery,
		pricingAlertsAnnotationsQuery,
		pricingCampaignsQuery,
		pricingAlertsByPricingCampaign,
	])
	if (isLoading) {
		return <AlertsListSkeleton close={close} />
	}
	if (isError) {
		return <FatalError />
	}
	const totalAlerts = sum(
		map(prop('products'), pricingAlertsByOptimizationQuery.data?.by_level) || 0
	)
	const totalAlertsFormatted = formatNumber(totalAlerts)
	const alertsTree = getPricingAlertsTree({
		pricingAlerts: pricingAlertsByOptimizationQuery.data,
		annotations: pricingAlertsAnnotationsQuery.data,
		solutionData: {
			pricingCampaigns: pricingCampaignsQuery.data!,
			alertsByPc: pricingAlertsByPricingCampaign.data!,
			optimizationGroupId: optimization_group_id,
		},
	})
	return (
		<Layout className='h-full overflow-y-hidden'>
			<Header className='flex items-center justify-between border-b border-solid border-base p-5'>
				<div>
					<h3 className='mb-2 mr-9 font-bold'>{name}</h3>{' '}
					<p className='section-subtitle'>
						{intl
							.get('pricing_alerts.drawer.optimization.subtitle', {
								total: totalAlertsFormatted,
							})
							.d(
								`${totalAlertsFormatted} alerts total for selected scenario, with the number of SKUs and Revenue share (%) highlighted for each alert type`
							)}
					</p>
				</div>
				<Button
					className='absolute right-5 top-4'
					variant='tertiary'
					onClick={() => close()}
					iconBefore={<CrossIcon />}
				/>
			</Header>
			<Content className='overflow-y-auto bg-accent-3'>
				<div className='w-full p-5'>
					{!alertsTree?.length ? (
						<Result
							className='flex h-full flex-col items-center justify-center'
							icon={<CheckCircleIcon height={50} width={50} />}
							title='Everything is ok'
						/>
					) : (
						<PricingAlerts
							className='pb-20'
							collapsedByDefault
							analyticPerfix='alerts: what-if:'
							alertsTree={alertsTree}
						/>
					)}
				</div>
			</Content>
		</Layout>
	)
}

export function PricingAlertsByOptimizationGroups({
	optimizationGroupIds,
	close,
}: {
	optimizationGroupIds: string[]
	close(): void
}) {
	const navigate = useNavigate()
	const optimizationGroupId = optimizationGroupIds[0]
	const optimizationGroup = useOptimizationGroup(optimizationGroupId)
	const pricingAlertsAnnotationsQuery = usePricingAlertsAnnotationsQuery()
	const pricingAlertsByOptimizationGroupId =
		usePricingAlertsQuery<AlertsInfoResponseModel>(
			{
				group_by: AlertGroupBy.OptimizationGroupId,
				total_by_group: true,
				optimization_group_ids: [optimizationGroupId],
			},
			{
				select: (data) => ({
					...data,
					by_level: filter(
						{ optimization_group_id: optimizationGroupId },
						data.by_level
					),
					by_id: filter(
						{ optimization_group_id: optimizationGroupId },
						data.by_id
					),
				}),
			}
		)
	const pricingAlertsByPricingCampaign = usePricingAlertsQuery<
		Record<string, string[]>
	>(
		{
			group_by: AlertGroupBy.PricingCampaignId,
			optimization_group_ids: [optimizationGroupId],
		},
		{
			select: pipe(
				prop('by_id'),
				groupBy('id'),
				mapValues(map('pricing_campaign_id'))
			),
		}
	)
	const pricingCampaignsQuery = usePricingCampaignsQuery({
		optimization_group_id: optimizationGroupId,
	})
	const isLoading = any(prop('isLoading'), [
		pricingAlertsByOptimizationGroupId,
		pricingAlertsAnnotationsQuery,
		pricingCampaignsQuery,
		pricingAlertsByPricingCampaign,
	])
	const isError = any(prop('isError'), [
		pricingAlertsByOptimizationGroupId,
		pricingAlertsAnnotationsQuery,
		pricingCampaignsQuery,
		pricingAlertsByPricingCampaign,
	])
	if (isLoading) {
		return <AlertsListSkeleton close={close} />
	}
	if (isError) {
		return <FatalError />
	}

	const alertsTree = getPricingAlertsTree({
		pricingAlerts: pricingAlertsByOptimizationGroupId.data,
		annotations: pricingAlertsAnnotationsQuery.data,
		solutionData: {
			pricingCampaigns: pricingCampaignsQuery.data!,
			alertsByPc: pricingAlertsByPricingCampaign.data!,
			optimizationGroupId,
		},
	})
	const handleNavigate = (ids: string[]) =>
		navigate(
			`/p/og/${optimizationGroupId}/products${queryParamsForOgAssortment([
				{
					name: 'alerts',
					operation: Operators.ANY_OF,
					value: ids,
				},
			])}`
		)

	const totalAlertsFormatted = formatNumber(
		sum(
			pricingAlertsByOptimizationGroupId?.data?.by_level?.map(prop('products'))
		) || 0
	)
	return (
		<Layout className='h-full overflow-y-hidden'>
			<Header className='flex items-center justify-between border-b border-solid border-base p-5'>
				<div>
					<h3 className='mb-2 mr-9 font-bold'>{optimizationGroup?.name}</h3>
					<p className='section-subtitle'>
						{intl
							.get('pricing_alerts.drawer.optimization_group.subtitle', {
								total: totalAlertsFormatted,
							})
							.d(`${totalAlertsFormatted} alerts total for selected OG`)}
					</p>
				</div>

				<Button
					className='absolute right-5 top-4'
					variant='tertiary'
					onClick={() => close()}
					iconBefore={<CrossIcon />}
				/>
			</Header>
			<Content className='overflow-y-auto bg-accent-3'>
				<div className='w-full p-5'>
					{!alertsTree?.length ? (
						<Result
							className='flex h-full flex-col items-center justify-center'
							icon={<CheckCircleIcon height={50} width={50} />}
							title='Everything is ok'
						/>
					) : (
						<PricingAlerts
							className='pb-20'
							collapsedByDefault
							analyticPerfix='alerts: og:'
							alertsTree={alertsTree}
							onNavigate={handleNavigate}
						/>
					)}
				</div>
			</Content>
		</Layout>
	)
}
export function PricingAlertsByPricingCampaign({
	pricingCampaign,
	optimizationGroupIds,
	close,
}: {
	pricingCampaign: PricingCampaignModel
	optimizationGroupIds?: string[]
	close(): void
}) {
	const navigate = useNavigate()
	const pricingAlertsAnnotations = usePricingAlertsAnnotationsQuery()
	const pricingAlerts = usePricingAlertsQuery(
		{
			group_by: AlertGroupBy.PricingCampaignId,
			optimization_group_ids: optimizationGroupIds,
		},
		{
			select: (data) => ({
				...data,
				by_level: filter(
					{ pricing_campaign_id: pricingCampaign.id },
					data.by_level
				),
				by_id: filter({ pricing_campaign_id: pricingCampaign.id }, data.by_id),
			}),
		}
	)
	const isLoading =
		pricingAlerts.isLoading || pricingAlertsAnnotations.isLoading
	const isError = pricingAlerts.isError || pricingAlertsAnnotations.isError

	if (isLoading) {
		return <AlertsListSkeleton close={close} />
	}
	if (isError) {
		return <FatalError />
	}

	const alertsTree = getPricingAlertsTree({
		pricingAlerts: pricingAlerts.data,
		annotations: pricingAlertsAnnotations.data,
	})
	const handleNavigate = (ids: string[]) => {
		close()
		navigate(
			optimizationGroupIds?.length === 1
				? `/p/og/${optimizationGroupIds[0]}/products${queryParamsForOgAssortment(
						[
							{
								name: 'pricing_campaign_id',
								operation: 'in',
								value: [pricingCampaign.id],
							},
							{
								name: 'alerts',
								operation: Operators.ANY_OF,
								value: ids,
							},
						]
					)}`
				: `/p/assortment/products${queryParamsForGlobalAssortment([
						{
							name: 'pricing_campaign_id',
							operation: 'in',
							value: [pricingCampaign.id],
						},
						{
							name: 'alerts',
							operation: Operators.ANY_OF,
							value: ids,
						},
					])}`
		)
	}
	const totalAlerts = sum(pricingAlerts?.data?.by_level?.map(prop('products')))
	const totalAlertsFormatted = formatNumber(totalAlerts || 0)
	return (
		<Layout className='h-full overflow-y-hidden'>
			<Header className='flex items-center justify-between border-b border-solid border-base p-5'>
				<div>
					<h3 className='mb-2 mr-9 font-bold'>{pricingCampaign?.name}</h3>
					<p className='section-subtitle'>
						{intl
							.get('pricing_alerts.drawer.pricing_campaign.subtitle', {
								total: totalAlertsFormatted,
							})
							.d(`${totalAlertsFormatted} alerts total for selected PC`)}
					</p>
				</div>
				<div className='absolute right-5 top-4'>
					<Button
						variant='tertiary'
						onClick={() => close()}
						iconBefore={<CrossIcon />}
					/>
				</div>
			</Header>
			<Content className='overflow-y-auto bg-accent-3'>
				<div className='w-full p-5'>
					{!alertsTree?.length ? (
						<Result
							className='flex h-full flex-col items-center justify-center'
							icon={<CheckCircleIcon height={50} width={50} />}
							title='Everything is ok'
						/>
					) : (
						<PricingAlerts
							className='pb-20'
							collapsedByDefault
							analyticPerfix='alerts: pc:'
							alertsTree={alertsTree}
							onNavigate={handleNavigate}
						/>
					)}
				</div>
			</Content>
		</Layout>
	)
}

export function GlobalPricingAlerts({ close }: { close(): void }) {
	const navigate = useNavigate()
	const pricingAlertsAnnotations = usePricingAlertsAnnotationsQuery()
	const pricingAlerts = usePricingAlertsQuery({})
	const isLoading =
		pricingAlertsAnnotations.isLoading || pricingAlerts.isLoading
	const isError = pricingAlertsAnnotations.isError || pricingAlerts.isError
	if (isLoading) {
		return <AlertsListSkeleton close={close} />
	}
	if (isError) {
		return <FatalError />
	}
	const alertsTree = getPricingAlertsTree({
		pricingAlerts: pricingAlerts.data,
		annotations: pricingAlertsAnnotations.data,
	})
	const handleNavigate = (ids: string[]) => {
		close()
		navigate(
			`/p/assortment/products${queryParamsForGlobalAssortment([
				{ name: 'alerts', operation: Operators.ANY_OF, value: ids },
			])}`
		)
	}
	return (
		<Layout className='h-full overflow-y-hidden'>
			<Header className='flex items-center justify-between border-b border-solid border-base p-5'>
				<div>
					<h3 className='mb-2 mr-9 font-bold'>
						{intl
							.get('pricing_alerts.drawer.assortment.title')
							.d('Company level alerts')}
					</h3>
					<p className='section-subtitle'>
						{intl
							.get('pricing_alerts.drawer.assortment.subtitle')
							.d(
								'Showing alerts for all OGs. % values are based on revenue difference.'
							)}
					</p>
				</div>
				<div className='absolute right-5 top-4'>
					<Button
						variant='tertiary'
						onClick={() => close()}
						iconBefore={<CrossIcon />}
					/>
				</div>
			</Header>
			<Content className='overflow-y-auto bg-accent-3'>
				<div className='w-full p-5'>
					{!alertsTree?.length ? (
						<Result
							className='flex h-full flex-col items-center justify-center'
							icon={<CheckCircleIcon height={50} width={50} />}
							title='Everything is ok'
						/>
					) : (
						<PricingAlerts
							className='pb-20'
							collapsedByDefault
							analyticPerfix='alerts: assortment:'
							alertsTree={alertsTree}
							onNavigate={handleNavigate}
						/>
					)}
				</div>
			</Content>
		</Layout>
	)
}
const AlertsListSkeleton = ({ close }: { close(): void }) => (
	<Layout className='h-full overflow-y-hidden'>
		<Header className='flex items-center justify-between border-b border-solid border-base p-5'>
			<div>
				<div className='h-6 w-24 animate-pulse rounded-lg bg-accent-4' />
				<div className='mt-2 h-4 w-72 animate-pulse rounded-lg bg-accent-4' />
			</div>
			<div className='absolute right-5 top-4'>
				<Button
					variant='tertiary'
					onClick={() => close()}
					iconBefore={<CrossIcon />}
				/>
			</div>
		</Header>
		<div className='flex w-full flex-col justify-center p-5'>
			<div className='w-full animate-pulse rounded-lg bg-accent-3'>
				<div className='h-12 w-full animate-pulse rounded-t-lg bg-accent-4' />
				<div className='h-12 w-full' />
			</div>
			<div className='mt-5 w-full animate-pulse rounded-lg bg-accent-3'>
				<div className='h-12 w-full animate-pulse rounded-t-lg bg-accent-4' />
				<div className='h-12 w-full' />
			</div>
			<div className='mt-5 w-full animate-pulse rounded-lg bg-accent-3'>
				<div className='h-12 w-full animate-pulse rounded-t-lg bg-accent-4' />
				<div className='h-12 w-full' />
			</div>
		</div>
	</Layout>
)
const queryParamsForGlobalAssortment = (filters: FilterRuleModel[]) => {
	return qs.stringify(
		{
			qf: encodeComplexQuery({
				filters,
			}),
		},
		{ addQueryPrefix: true }
	)
}
const queryParamsForOgAssortment = (filters: FilterRuleModel[]) => {
	return qs.stringify(rulesToQueryObject(filters), {
		addQueryPrefix: true,
		arrayFormat: 'comma',
	})
}
