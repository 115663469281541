import { omitAll } from 'lodash/fp'
import React from 'react'

import { Header } from '@cmpkit/base'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'
import { QuickFiltersBar } from '@/modules/preferences'

import {
	selectDashboardFilters,
	selectDashboardFiltersKeys,
	useDashboardStore,
} from '../../store'
import { UseDashboardWidgets } from '../../useDashboardWidgets'
import { WidgetPeriods } from '../widgets/types'
import WidgetsSection from '../WidgetsSection'
import { useFilterFieldsConfig } from './useFilterFiledsConfig'

export default function MainDashboardMetricsSection({
	dashboard,
	commonPeriods,
}: {
	dashboard: UseDashboardWidgets
	commonPeriods?: WidgetPeriods | null
}) {
	const { setFilters, filters } = useDashboardStore(
		selectDashboardFilters('main_metrics')
	)
	const { storedKeys, setStoredKeys } = useDashboardStore(
		selectDashboardFiltersKeys('main_metrics')
	)
	const { fieldsConfig, isLoading } = useFilterFieldsConfig({
		// remove fields that unsuported by BI widgets in this dashboard section
		sanitizeFields: omitAll(['optimization_group_id', 'pos_ids']),
	})
	return (
		<div>
			<Header className='sticky top-0 z-10 items-center justify-between bg-white/50 px-4 py-2 backdrop-blur-md dark:bg-[#1e293b]/50'>
				<h2 className='text-lg font-bold'>
					{intl.get('dashboard.section.metric.title')}
					<Tooltip content={intl.get('widget.metric_perfomance.tooltip')}>
						<InfoIcon className='ml-2' />
					</Tooltip>
				</h2>
				{isLoading ? (
					<div className='flex items-center space-x-2'>
						<div className='h-6 w-28 animate-pulse rounded-lg bg-accent-4' />
						<div className='h-6 w-20 animate-pulse rounded-lg bg-accent-4' />
						<div className='h-6 w-24 animate-pulse rounded-lg bg-accent-4' />
					</div>
				) : (
					<QuickFiltersBar
						irremovableKeys={['optimization_group', 'pos_id']}
						rules={filters}
						storedKeys={storedKeys}
						onSaveStoredKeys={setStoredKeys}
						onApply={setFilters}
						fieldsConfig={fieldsConfig}
						limit={7}
					/>
				)}
			</Header>
			<WidgetsSection
				id={'metrics'}
				dashboard={dashboard}
				periods={commonPeriods}
				filters={filters}
			/>
		</div>
	)
}
