import React from 'react'

import { Badge } from '@cmpkit/base'
import ArrowDownRightIcon from '@cmpkit/icon/lib/glyph/arrow-down-right'
import ArrowUpRightIcon from '@cmpkit/icon/lib/glyph/arrow-up-right'

import { formatNumber, NumberFormats } from '@/tools/locale'

export default function PercentDifferenceBadge({ value }: { value: number }) {
	if (Number.isNaN(value)) {
		return null
	}
	if (value > 0) {
		return (
			<Badge variant='success' size='small'>
				<ArrowUpRightIcon />
				{formatNumber(value, NumberFormats.Percent)}
			</Badge>
		)
	}
	if (value < 0) {
		return (
			<Badge variant='danger' size='small'>
				<ArrowDownRightIcon />
				{formatNumber(value, NumberFormats.Percent)}
			</Badge>
		)
	}
	return <Badge>{formatNumber(value, NumberFormats.Percent)}</Badge>
}
