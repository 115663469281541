import React, { useContext, useMemo, useReducer } from 'react'
import { Location, Params, useLocation, useParams } from 'react-router-dom'

import { ChatMessageContextItem } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import { uuid } from '@/tools/utils'

import {
	useSentChatMessageMutation,
	useSentChatMessageReactionMutation,
} from '../mutations'
import { useAiChatSettingssQuery } from '../queries'

export interface AssistantContextValue {
	isBusy: boolean
	welcome_text: string
	suggestions: string[]
	messages: Message[]
	sendMessage: (message: string) => void
	resetConversation: () => void
	messageReaction(id: string | number, reaction: 'like' | 'dislike'): void
}

export const AssistantContext = React.createContext<AssistantContextValue>({
	isBusy: false,
	welcome_text: '',
	suggestions: [],
	messages: [],
	sendMessage: () => {},
	resetConversation: () => {},
	messageReaction: () => {},
})
type Action =
	| {
			type: 'add'
			message: Message
	  }
	| {
			type: 'reset'
	  }
	| {
			type: 'reaction'
			id: number | string
			reaction: 'like' | 'dislike'
	  }
type Message = {
	id: string
	is_system?: boolean
	sender: 'user' | 'assistant'
	reaction?: 'like' | 'dislike'
	text: string
}
type State = {
	session_id: string
	messages: Message[]
}
const reducer = (state: State, action: Action): State => {
	const { type } = action
	switch (type) {
		case 'add':
			return { ...state, messages: [...state.messages, action.message] }
		case 'reset':
			return { session_id: uuid(), messages: [] }
		case 'reaction':
			return {
				...state,
				messages: state.messages.map((message) => {
					if (message.id === action.id) {
						return { ...message, reaction: action.reaction }
					}
					return message
				}),
			}
		default:
			return state
	}
}

export default function AssistantProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const settingsQuery = useAiChatSettingssQuery()
	const sendMutation = useSentChatMessageMutation()
	const reactionMutation = useSentChatMessageReactionMutation()
	const optimizationGroupId = useOptimizationGroupId()
	const location = useLocation()
	const params = useParams()
	const context: ChatMessageContextItem = useMemo(
		() => ({
			optimization_group_id: optimizationGroupId || null,
			page: getRoutePath(location, params),
		}),
		[optimizationGroupId, location, params]
	)

	const [state, dispatch] = useReducer(reducer, {
		messages: [],
		session_id: uuid(),
	})
	const resetConversation = () => {
		dispatch({ type: 'reset' })
	}
	const messageReaction = (
		id: string | number,
		reaction: 'like' | 'dislike'
	) => {
		reactionMutation.mutate({
			id: id.toString(),
			reaction,
		})
		dispatch({ type: 'reaction', id, reaction })
	}

	const sendMessage = async (text: string) => {
		dispatch({
			type: 'add',
			message: {
				id: uuid(),
				sender: 'user',
				text,
			},
		})
		try {
			const answer = await sendMutation.mutateAsync({
				text,
				session_id: state.session_id,
				context,
			})
			dispatch({
				type: 'add',
				message: {
					sender: 'assistant',
					reaction: undefined,
					...answer,
				},
			})
		} catch (error) {
			dispatch({
				type: 'add',
				message: {
					sender: 'assistant',
					is_system: true,
					reaction: undefined,
					id: uuid(),
					text: 'Sorry, I can`t proceed your request.',
				},
			})
		}
	}
	return (
		<AssistantContext.Provider
			value={{
				welcome_text: settingsQuery.data?.welcome_text || '',
				suggestions: settingsQuery.data?.suggestions || [],
				isBusy: sendMutation.isPending,
				messages: state.messages,
				sendMessage,
				resetConversation,
				messageReaction,
			}}
		>
			{children}
		</AssistantContext.Provider>
	)
}

export function useAssistantConversation() {
	return useContext<AssistantContextValue>(AssistantContext)
}
const getRoutePath = (location: Location, params: Params): string => {
	const { pathname } = location

	if (!Object.keys(params).length) {
		return pathname // we don't need to replace anything
	}

	return Object.entries(params).reduce(
		(path: string, [paramName, paramValue]) => {
			if (paramValue) {
				return path.replace(paramValue, `:${paramName}`)
			}
			return path
		},
		pathname
	)
}
