import { find, isNil, pipe, prop } from 'lodash/fp'
import { JSX, useMemo } from 'react'

import { Badge, DeltaValue, MenuItem, MenuList, Panel, Tag } from '@cmpkit/base'
import {
	Column,
	ColumnCheckboxHeaderTemplate,
	ColumnCheckboxRowTemplate,
} from '@cmpkit/data-table'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import Popover from '@cmpkit/popover'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'
import intl from '@/locale'
import { PENDING } from '@/modules/core/constants'
import { getStatusBadgeVariant } from '@/modules/core/utils'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import {
	getEnumOptions,
	getResolvedSchema,
	JSONSchema,
	translateOptions,
	translateSchemaKeys,
} from '@/tools/json-schema-utils'
import { formatNumber } from '@/tools/locale'
import { useDrawersStore } from '@/modules/drawers/store'
import analytic from '@/services/analytics'

const DropDownContent = ({
	options,
	close,
	onSelect,
}: {
	options: DataOption[]
	close(): void
	onSelect(value: string): void
}) => {
	const handleOptionClick = (value: string) => {
		onSelect(value)
		close()
	}
	return (
		<Panel className='w-full cursor-pointer border border-solid border-base py-2'>
			<MenuList>
				{options.map(({ label, value }) => {
					return (
						<MenuItem key={value} onClick={() => handleOptionClick(value)}>
							{label}
						</MenuItem>
					)
				})}
			</MenuList>
		</Panel>
	)
}
function SelectMetricDropdown({
	options,
	value,
	rowId,
	type,
	handleSelect,
}: {
	options: DataOption[]
	value?: string
	rowId: string
	type: string
	handleSelect({
		rowId,
		key,
		value,
	}: {
		rowId: string
		key: string
		value: string
	}): void
}) {
	return (
		<Popover
			placement='bottom'
			content={({ close }) => (
				<DropDownContent
					options={options}
					close={close}
					onSelect={(value) => handleSelect({ rowId, key: type, value })}
				/>
			)}
		>
			<div className='flex cursor-pointer items-center justify-between'>
				<div className='overflow-hidden'>{value ?? 'revenue'}</div>
				<CaretDownIcon />
			</div>
		</Popover>
	)
}
const DiffCell = ({
	className,
	value,
	unit = '',
}: {
	className?: string
	value: number | null | undefined
	unit?: string
}): JSX.Element => (
	<div className='flex justify-end'>
		<DeltaValue value={value} className={className}>
			{!isNil(value) ? `${formatNumber(value)}${unit}` : '-'}
		</DeltaValue>
	</div>
)

const getRenderSchema = ({
	status,
	name,
	value,
}: {
	status: string
	name: string
	value: number | null
}): JSX.Element => {
	if (!status) {
		return <div className='h-6 animate-pulse rounded-lg bg-accent-3' />
	}
	if (status === PENDING || (value != 0 && isNil(value))) {
		return <div className='flex items-center justify-end'>-</div>
	}
	if (name?.includes('profit_margin_diff')) {
		return <DiffCell className='text-warning' value={value * 100} unit='p.p' />
	}
	if (name === 'gross_profit_diff') {
		return <DiffCell value={value} />
	}
	if (name?.includes('diff')) {
		return <DiffCell value={value} unit='%' />
	}
	if (name === 'profit_margin_current' || name === 'profit_margin_final') {
		return (
			<div className='flex items-center justify-end'>
				{formatNumber(value * 100) + '%'}
			</div>
		)
	}
	return (
		<div className='flex items-center justify-end'>{formatNumber(value)}</div>
	)
}
const METRICS = [
	{
		name: 'revenue_current',
		visible_name: 'Revenue current',
	},
	{
		name: 'revenue_final',
		visible_name: 'Revenue final',
	},
	{
		name: 'revenue_diff',
		visible_name: 'Revenue diff',
	},
	{
		name: 'sales_items_current',
		visible_name: 'Sales items current',
	},
	{
		name: 'sales_items_final',
		visible_name: 'Sales items final',
	},
	{
		name: 'sales_items_diff',
		visible_name: 'Sales items diff',
	},
	{
		name: 'gross_profit_current',
		visible_name: 'Gross profit current',
	},
	{
		name: 'gross_profit_final',
		visible_name: 'Gross profit final',
	},
	{
		name: 'gross_profit_diff',
		visible_name: 'Gross profit diff',
	},
	{
		name: 'profit_margin_current',
		visible_name: 'Profit margin current %',
	},
	{
		name: 'profit_margin_final',
		visible_name: 'Profit margin final %',
	},
	{
		name: 'profit_margin_diff',
		visible_name: 'Profit margin diff',
	},
	{
		name: 'items_decreased',
		visible_name: 'Decreased items',
	},
	{
		name: 'items_increased',
		visible_name: 'Increased items',
	},
	{
		name: 'items_locked',
		visible_name: 'Locked items',
	},
	{
		name: 'items_not_changed',
		visible_name: 'Not changed',
	},
	{
		name: 'avg_price',
		visible_name: 'Avg price',
	},
]
export default function useColumns({
	onOptionClick,
}: {
	onOptionClick({
		rowId,
		key,
		value,
	}: {
		rowId: string
		key: string
		value: string
	}): void
}): Column[] {
	const settingsSchemaQuery = useSettingsSchemasQuery<JSONSchema>({
		select: pipe([
			getResolvedSchema('part:strategy'),
			prop('properties.demand_strategy.properties'),
			translateSchemaKeys,
		]),
	})
	const demandStrategyOptions = useMemo(
		() => ({
			target: translateOptions(
				getEnumOptions(prop('target', settingsSchemaQuery.data))
			),
			protect: translateOptions(
				getEnumOptions(prop('protect', settingsSchemaQuery.data))
			),
		}),
		[settingsSchemaQuery.data]
	)
	return [
		new Column({
			id: 'control',
			sortable: false,
			title: '',
			headerRenderer: () => <ColumnCheckboxHeaderTemplate />,
			renderer: (id, item, row) => (
				<ColumnCheckboxRowTemplate item={item} row={row} />
			),
			headerClassName: 'w-7',
			className: 'w-7 py-4',
			valueGetter: prop('id'),
			width: 50,
			locked: true,
		}),
		new Column({
			id: 'name',
			title: intl.get('general_name').d('Name'),
			locked: true,
			sortable: true,
			renderer: (value, { is_main, description }) => (
				<Tooltip placement='top' content={description}>
					<p>
						{value} {is_main && <Badge className='ml-2'>Main</Badge>}
					</p>
				</Tooltip>
			),
			valueGetter: prop('name'),
			width: 150,
		}),
		new Column({
			id: 'settings.strategy.demand_strategy.target',
			title: prop('target.title', settingsSchemaQuery.data),
			locked: true,
			sortable: true,
			renderer: (value, _item, row) => {
				return (
					<SelectMetricDropdown
						options={demandStrategyOptions.target}
						value={find({ value }, demandStrategyOptions.target)?.label}
						rowId={row?.key}
						type='target'
						handleSelect={onOptionClick}
					/>
				)
			},
			valueGetter: prop('settings.strategy.demand_strategy.target'),
			width: 175,
		}),
		new Column({
			id: 'settings.strategy.demand_strategy.protect',
			title: prop('protect.title', settingsSchemaQuery.data),
			locked: true,
			sortable: true,
			renderer: (value, _item, row) => {
				return (
					<SelectMetricDropdown
						options={demandStrategyOptions.protect}
						value={find({ value }, demandStrategyOptions.protect)?.label}
						rowId={row?.key}
						type='protect'
						handleSelect={onOptionClick}
					/>
				)
			},
			valueGetter: prop('settings.strategy.demand_strategy.protect'),
			width: 175,
		}),
		new Column({
			id: 'status',
			title: intl.get('app.status'),
			sortable: true,
			renderer: (value) =>
				value ? (
					<Tag variant={getStatusBadgeVariant(value)}>
						{intl.get(`opt_${value}`)}
					</Tag>
				) : (
					<div className='h-6 animate-pulse rounded-lg bg-accent-3' />
				),
			valueGetter: prop('status'),
			width: 125,
		}),
		new Column({
			id: 'notifications_count',
			title: intl.get('field_schema_alerts'),
			sortable: true,
			valueGetter: prop('notifications_count'),
			width: 123,
			renderer: (value, _i, row) => {
				const { openDrawer } = useDrawersStore()
				return value > 0 ? (
					<Badge
						interactive
						onClick={() => {
							analytic.logEvent('what-if summary: alerts by og: open')
							openDrawer('PRICING_ALERTS', {
								type: 'byOptimizations',
								name: row.content.name,
								scenarioId: row.content.id,
								optimization: {
									optimization_id: row.content.optimization_id,
									optimization_group_id: row.content.optimization_group_id,
								},
								drawerClassName: 'scenarios-summary-alerts-eastside',
							})
						}}
						className='fade-in cursor-pointer text-nowrap font-mono'
						variant='danger'
					>
						{formatNumber(value)}
					</Badge>
				) : (
					'-'
				)
			},
		}),
		...METRICS.map((item) => {
			return new Column({
				id: item.name,
				title: intl.get(item.name).d(item.visible_name),
				fixable: false,
				sortable: true,
				renderer: (value, { status }) =>
					getRenderSchema({ status, value, name: item.name }),
				valueGetter: prop(item.name),
				width: 125,
			})
		}),
	]
}
