import { prop } from 'lodash/fp'
import { ChartPieIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbList,
	BreadcrumbPage,
	Content,
	Header,
	Loader,
	Result,
	Separator,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CmpLogoIcon from '@cmpkit/icon/lib/glyph/cmp-logo'

import DocumentTitle from '@/components/DocumentTitle'
import { BIRevision } from '@/generated'
import intl from '@/locale'
import { Authorization } from '@/modules/Auth/authorization'
import { useBIRevisionQuery } from '@/modules/bi/queries'
import analytic from '@/services/analytics'

import { getDefaultPeriods } from '../../helpers'
import { useDashboardWidgets } from '../../useDashboardWidgets'
import { WidgetPeriod, WidgetPeriods } from '../widgets/types'
import AiDashboardCommonSection from './AiDashboardCommonSection'

export default function AiDashboard() {
	useEffect(() => {
		analytic.logEvent('browse: ai dashboard')
	}, [])
	const [period, setPeriod] = useState<WidgetPeriod | null>(null)
	const [defaultPeriod, setDefaultPeriod] = useState<WidgetPeriod | null>(null)
	useEffect(() => {
		setPeriod(defaultPeriod)
	}, [defaultPeriod])
	const dashboard = useDashboardWidgets('ai')
	const biRevisionDateQuery = useBIRevisionQuery<string | null>({
		refetchOnMount: 'always',
		select: prop('date') as (data: BIRevision | null) => string | null,
	})
	useEffect(() => {
		biRevisionDateQuery.data &&
			!period &&
			setDefaultPeriod(getDefaultPeriods(biRevisionDateQuery.data)?.main)
	}, [biRevisionDateQuery.data])

	/* const handleChangePeriod = (period: WidgetPeriod | null) => {
		setPeriod(period || getDefaultPeriods(biRevisionDateQuery.data!)?.main)
	} */
	// We should use only one period
	const periods = { main: defaultPeriod, compared: defaultPeriod }
	return (
		<Authorization
			permissions={['ACCESS_BI']}
			fallback={<Navigate to='/p/og' />}
		>
			<DocumentTitle title={intl.get('ai_dashboard_title')}>
				<Header className='h-11 items-center border-b px-4 py-2'>
					<ChartPieIcon className='size-4' />
					<Separator orientation='vertical' className='mx-2 h-5' />
					<Breadcrumb className='text-xs'>
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbPage>
									{intl.get('ai_dashboard_title')}
								</BreadcrumbPage>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
				</Header>
				<Content className='overflow-y-auto'>
					<div className='size-full overflow-hidden'>
						<div
							className='relative h-full overflow-y-scroll'
							id='dashboard-scrollable-area'
						>
							<Blanket
								className='absolute flex items-center justify-center bg-white/50 backdrop-blur-sm dark:bg-black/50'
								isTinted={!period && biRevisionDateQuery.isFetching}
							>
								<Loader />
							</Blanket>
							{!!period && !biRevisionDateQuery.isFetching ? (
								<AiDashboardCommonSection
									dashboard={dashboard}
									commonPeriods={periods as WidgetPeriods}
								/>
							) : (
								<Result
									icon={
										<CmpLogoIcon
											width={56}
											height={56}
											className='fill-brand'
										/>
									}
									className='flex h-full flex-col justify-center font-medium text-muted'
									title={intl
										.get('dashboard.unavailable.title')
										.d('This page is currently switched off for your domain')}
									subtitle={intl
										.get('dashboard.unavailable.subtitle')
										.d(
											'Please contact our support to switch it on for you and your team.'
										)}
								/>
							)}
						</div>
					</div>
				</Content>
			</DocumentTitle>
		</Authorization>
	)
}
