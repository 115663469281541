import { AnimatePresence, motion } from 'framer-motion'
import { isEmpty } from 'lodash/fp'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'

import { Button, LinkButton, Result } from '@cmpkit/base'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import {
	Modal,
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import dataUpdating from '@/assets/img/svg/data_updating_proccess.svg'
import { FormProvider, RHFCheckbox } from '@/components/HookForm'
import RHFFileDropArea from '@/components/HookForm/RHFFileDropArea'
import intl from '@/locale'
import analytic from '@/services/analytics'

import { downloadImportTemplate } from '../helpers'
import { useImportAnchoringFromFileMutation } from '../mutations'

type ImportDataFromFileModalContentProps = {
	close(): void
}
const ImportFromFileSchema = Yup.object().shape({
	product_diffs: Yup.mixed<File>()
		.required('File is required')
		.test({
			message: 'Please provide a supported file type. (xlsx, xls, csv)',
			test: (file, context) => {
				const isValid = ['xlsx', 'xls', 'csv'].includes(
					file?.name?.split('.').pop() || ''
				)
				if (!isValid) context?.createError()
				return isValid
			},
		}),
	ignore_empties: Yup.boolean(),
})
type ImportFromFileFormData = Yup.InferType<typeof ImportFromFileSchema>

function ImportDataFromFileModalContent({
	close,
}: ImportDataFromFileModalContentProps) {
	const [screen, setScreen] = useState<
		'form' | 'proccess' | 'errors' | 'result'
	>('form')

	const queryClient = useQueryClient()
	const uploadFile = useImportAnchoringFromFileMutation({
		onMutate: () => {
			setScreen('proccess')
		},
		onError: () => {
			setScreen('errors')
		},
		onSuccess: () => {
			setScreen('result')
		},
	})
	const methods = useForm<ImportFromFileFormData>({
		defaultValues: {
			ignore_empties: true,
		},
		resolver: yupResolver<ImportFromFileFormData>(ImportFromFileSchema),
	})

	const handleClose = () => close()
	const handleCloseAndFinish = () => {
		close()
		queryClient.invalidateQueries({
			queryKey: ['anchoring-list'],
		})
	}
	const handleBackToImport = () => setScreen('form')
	const handleDownloadTemplate = () => {
		analytic.logEvent('anchoring: import from file: download template')
		downloadImportTemplate()
	}
	const onSubmit = (formData: ImportFromFileFormData) => {
		analytic.logEvent('anchoring: import from file: upload')
		uploadFile.mutate({
			ignore_empties: String(formData.ignore_empties),
			anchors: formData.product_diffs,
		})
	}

	return (
		<Modal onClose={close} showCloseButton size='mini' zIndex={1850}>
			<ModalHeader>
				<ModalTitle>
					{intl.get('app.import_from_file').d('Update data from file')}
				</ModalTitle>
				<ModalDescription>
					{intl
						.get('anchoring.import.title')
						.d('Upload anchoring data with a file')}
				</ModalDescription>
			</ModalHeader>
			<ModalBody className='flex flex-col overflow-hidden'>
				<AnimatePresence mode={'wait'}>
					{screen === 'errors' && (
						<StepContent key='errors'>
							<div className='flex h-full flex-col overflow-auto'>
								<h3 className='text-center'>
									{intl
										.get('anchoring.import.error.title')
										.d('Error on importing file')}
								</h3>
								<p className='pb-5 text-center text-muted'>
									{intl
										.get('anchoring.import.error.subtitle')
										.d('Seems file is not valid')}
								</p>
								{uploadFile.isError &&
								!isEmpty(uploadFile.error?.response?.data?.errors?.anchors) ? (
									<div className='flex flex-col overflow-y-auto'>
										<h4 className='text-sm font-bold'>
											{intl.get('general_error_types')}
										</h4>
										<div className='mt-2 overflow-y-auto'>
											<div className='space-y-2'>
												{Object.entries(
													uploadFile.error?.response?.data?.errors?.anchors ||
														{}
												).map(([rowIndex, row]) => {
													const rowErrors = Object.entries(row).map(
														([colKey, errors]) => ({
															field: colKey,
															error: errors[0],
														})
													)
													return (
														<div key={rowIndex} className='text-xs'>
															<div className='sticky top-0 bg-accent-1'>
																Row {rowIndex}
															</div>
															{rowErrors.map((error) => (
																<div
																	key={error.field}
																	className='flex items-start justify-between space-x-2'
																>
																	<div className='max-w-xs truncate'>
																		{error.field}
																	</div>
																	<div className='shrink-0 text-danger'>
																		{error.error}
																	</div>
																</div>
															))}
														</div>
													)
												})}
											</div>
										</div>
									</div>
								) : (
									<div className='space-y-2'>
										<h4>{intl.get('general_possible_reasons')}:</h4>
										<p
											className='ml-5'
											dangerouslySetInnerHTML={{
												__html: intl.get('og_import_error_hint_html'),
											}}
										/>
									</div>
								)}
							</div>
						</StepContent>
					)}
					{screen === 'result' && (
						<StepContent key='result'>
							<div className='flex flex-col'>
								<h3 className='pb-5 text-center'>
									{intl
										.get('anchoring.import.result.title')
										.d('Import is finished')}
								</h3>
								{uploadFile.data && (
									<div>
										<p>
											{intl
												.get('anchoring.import.result.rows_inserted')
												.d('Rows inserted')}
											:
											<span className='mx-1 font-bold'>
												{uploadFile.data?.rows_inserted}
											</span>
										</p>
										<p>
											{intl
												.get('anchoring.import.result.rows_updated')
												.d('Rows updated')}
											:
											<span className='mx-1 font-bold'>
												{uploadFile.data?.rows_updated}
											</span>
										</p>
									</div>
								)}
							</div>
						</StepContent>
					)}
					{screen === 'proccess' && (
						<StepContent key='proccess'>
							<Result
								className='py-5'
								icon={<img src={dataUpdating} className='animate-pulse' />}
								title={intl.get('og_bulk_import_updating')}
								subtitle={intl.get('og_bulk_import_updating_hint')}
							/>
						</StepContent>
					)}
					{screen === 'form' && (
						<StepContent key='form'>
							<FormProvider
								methods={methods}
								onSubmit={methods.handleSubmit(onSubmit)}
								className='flex flex-col'
							>
								<RHFFileDropArea className='min-h-28' name='product_diffs' />
								<RHFCheckbox
									name='ignore_empties'
									label={intl.get('modal_import_ignore_empties')}
									/* description={intl.get('modal_import_ignore_empties_hint')} */
								/>
								<h4 className='mb-2 text-sm font-semibold'>
									{intl.get('modal_import_guide')}
								</h4>
								<ul className='mb-4 list-inside list-decimal'>
									<li>
										{intl.get('og_import_step_1')}
										<LinkButton
											variant='brand'
											onClick={handleDownloadTemplate}
											className='ml-1 text-blue-200'
										>
											{intl.get('og_import_types')}
										</LinkButton>
									</li>
									<li>{intl.get('og_import_step_2')}</li>
									<li>{intl.get('og_import_step_3')}</li>
								</ul>
							</FormProvider>
						</StepContent>
					)}
				</AnimatePresence>
			</ModalBody>
			{screen === 'form' && (
				<ModalFooter className='sapce-x-2 justify-between'>
					<Button onClick={handleClose}>{intl.get('general_cancel')}</Button>
					<Button
						variant='primary-brand'
						disabled={!methods.watch('product_diffs')}
						onClick={methods.handleSubmit(onSubmit)}
					>
						{intl.get('app.update').d('Update')}
					</Button>
				</ModalFooter>
			)}
			{screen === 'result' && (
				<ModalFooter className='sapce-x-2 justify-end'>
					<Button variant='primary-brand' onClick={handleCloseAndFinish}>
						{intl.get('general_close_and_finish')}
					</Button>
				</ModalFooter>
			)}
			{screen === 'errors' && (
				<ModalFooter className='sapce-x-2 justify-start'>
					<Button onClick={handleBackToImport} iconBefore={<CaretLeftIcon />}>
						{intl.get('general_back')}
					</Button>
				</ModalFooter>
			)}
		</Modal>
	)
}
export default function AnchorsImportFromFileModal(
	props: ImportDataFromFileModalContentProps & { isOpen: boolean }
) {
	return (
		<ModalTransition>
			{props.isOpen && <ImportDataFromFileModalContent {...props} />}
		</ModalTransition>
	)
}

function StepContent({ children }: { children: React.ReactNode }) {
	return (
		<motion.div
			initial={{ opacity: 0, x: 100 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: -30 }}
			className={'flex size-full flex-col'}
			transition={{
				duration: 0.2,
			}}
		>
			{children}
		</motion.div>
	)
}
