import { any, prop } from 'lodash/fp'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import { LinkButton } from '@cmpkit/base'

import { dialog } from '@/components/dialogs'
import notify from '@/components/toasts/helpers'
import {
	DemandPricingTacticsModel,
	FilterModel,
	MarkdownPricingTacticsModel,
	MinimalStepRuleModel,
	PriceLimitsModel,
	PricingCampaignModel,
	PricingCampaignSettingsModel,
	ProductAssignmentModel,
	RuleBasePricingTacticsModel,
	SettingsTemplateType,
} from '@/generated'
import intl from '@/locale'
import { useCheckOptimizationForDeleteExport } from '@/modules/core/hooks/useCheckOptimizationForDeleteExport'
import { useBeforeGroupChange } from '@/modules/core/mutations'
import { useModalStore } from '@/modules/modals/store'
import {
	useApplySettingsTemplateMutation,
	useCreateSettingsTemplateMutation,
} from '@/modules/settings-templates/mutations'

import analytic from '@/services/analytics'
import { errorMessage, processingMessage } from '@/tools/message'

import {
	useDeletePricingCampaignMutation,
	useDublicatePricingCampaignsMutation,
} from '../../mutations'
import { getAffectedOptimizationGroups } from '../../utils'

export type SettingType =
	| {
			name: string
			filters: FilterModel[]
	  }
	| DemandPricingTacticsModel
	| MarkdownPricingTacticsModel
	| RuleBasePricingTacticsModel
	| MinimalStepRuleModel[]
	| PriceLimitsModel

export type HandleSaveSettingAsTemplate = {
	pricingCampaignName: string
	templateType: SettingsTemplateType
	settings: SettingsEntity
}
export type HandleUseTemplate = {
	assignment?: string
	settingName: string
	templateType: SettingsTemplateType
}
export type HandleUseSettingTemplate = {
	templateSettings: SettingsEntity
	settingName?: string
}
export type SettingsEntity = {
	[key: string]: SettingType | ProductAssignmentModel
}
export type HandleCopySettingToPc = {
	settingName: string
	pricingCampaignId: string
}
export interface UseSettingActions {
	handleSaveAsTemplate(props: HandleSaveSettingAsTemplate): void
	handleUseTemplate(props: HandleUseSettingTemplate): void
	handleCopy(props: HandleCopySettingToPc): void
}
export type HandleApplyPCTemplate = {
	templateId: string
	pcId: string
}
export interface UsePricingCampaignActions {
	handleDelete?(id: string): void
	handleSetAsDefault?(id: string): void
	handleReassign?(id: string): void
	handleCopy?(id: string): void
	handleDublicate?(id: string): void
	handleSaveAsTemplate?(pc: PricingCampaignModel): void
	handleApplyTemplate(props: HandleApplyPCTemplate): Promise<void>
	handleUseTemplate?(props: { settings: PricingCampaignSettingsModel }): void
	isLoading: boolean
}
export function usePricingCampaignActions({
	pricingCampaign,
	optimizationGroupId,
	onFinish,
	handleUseTemplate,
	//scenarioId,
}: {
	pricingCampaign?: PricingCampaignModel
	optimizationGroupId?: string | null
	scenarioId?: string | null
	handleUseTemplate?: (props: {
		settings: PricingCampaignSettingsModel
	}) => void
	onFinish?(): void
}): UsePricingCampaignActions {
	const { showModal } = useModalStore()
	const queryClient = useQueryClient()
	const checkExport = useCheckOptimizationForDeleteExport(optimizationGroupId)
	const { onMutate } = useBeforeGroupChange(optimizationGroupId)

	/**
	 * Mutations
	 */
	const deletePricingCampaign = useDeletePricingCampaignMutation({
		onMutate,
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['products-counts-by-pc'],
			})
			return queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
		},
	})
	const dublicate = useDublicatePricingCampaignsMutation({
		mutationKey: ['dublicatePricingCampaign'],
		onMutate,
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
			queryClient.invalidateQueries({
				queryKey: ['products-counts-by-pc'],
			})
		},
	})
	const createTemplateMutation = useCreateSettingsTemplateMutation({
		onMutate() {
			toast.loading(processingMessage(), {
				id: 'saving_template',
			})
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['settings-templates'],
			})
		},
		onSettled() {
			toast.dismiss('saving_template')
		},
	})
	const applyTemplateMutation = useApplySettingsTemplateMutation({
		onMutate,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['optimizations'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaign'],
			})
		},
	})

	const isLoading = any(prop('isPending'), [dublicate, deletePricingCampaign])
	/**
	 * Handlers
	 */
	const confirmChanges = (optimizationGroupsIds: string[]): Promise<boolean> =>
		new Promise((resolve) =>
			showModal('AFFECTED_OPTIMIZATION_GROUPS_MODAL', {
				title: intl.get('global.pc.affect.modal.title'),
				subtitle: intl.get('global.pc.affect.modal.subtitle'),
				optimizationGroupsIds,
				onConfirm: () => resolve(true),
				onCancel: () => resolve(false),
			})
		)

	const isGlobalPricingCampaign =
		pricingCampaign?.optimization_group_id === null

	const handleOpenTemplate = ({
		id,
		template_type,
	}: {
		id: string
		template_type: SettingsTemplateType
	}) => {
		showModal('SETTINGS_TEMPLATE_MODAL', {
			templateId: id,
			templateType: template_type,
		})
	}
	const handleReassign = (id: string) => {
		analytic.logEvent(
			'settings: pricing campaigns: dropdown: reassign to another OG'
		)
		showModal('MOVE_PRICING_CANPAIGNS', {
			selected: [id],
		})
	}
	const handleCopy = (id: string) => {
		analytic.logEvent('settings: pricing campaigns: dropdown: copy PC')
		showModal('SELECT_COPY_PRICING_CAMPAIGN_FLOW', {
			selected: [id],
		})
	}
	const handleDublicate = async (id: string) => {
		analytic.logEvent('settings: pricing campaigns: dropdown: dublicate PC')
		if (isGlobalPricingCampaign) {
			const affectedOptimizationGroups =
				await getAffectedOptimizationGroups(pricingCampaign)
			const confirmed = affectedOptimizationGroups?.length
				? await confirmChanges(affectedOptimizationGroups)
				: true
			if (!confirmed) {
				return
			}
		}
		await toast.promise(
			dublicate.mutateAsync({
				src_pricing_campaign_ids: [id],
				dst_optimization_group_ids: [optimizationGroupId!],
			}),
			{
				loading: processingMessage(),
				success: intl.get('success_dublicated'),
				error: errorMessage(),
			}
		)
	}
	const handleDelete = async (id: string) => {
		analytic.logEvent('settings: pricing campaigns: delete PC', {
			page: 'PC list',
		})
		const answer = await dialog.confirmDelete({
			title: intl.get('pc_detete_confirm_title'),
			text: intl.get('pc_detete_confirm_subtitle'),
			okText: intl.get('general_delete'),
		})

		if (answer) {
			if (isGlobalPricingCampaign) {
				const affectedOptimizationGroups =
					await getAffectedOptimizationGroups(pricingCampaign)
				const confirmed = affectedOptimizationGroups?.length
					? await confirmChanges(affectedOptimizationGroups)
					: true
				if (!confirmed) {
					return
				}
			}
			onFinish?.()
			await toast.promise(deletePricingCampaign.mutateAsync(id), {
				loading: intl.get('toast.entity.deleting', {
					entity: intl.get('entity.pricing_campaign'),
				}),
				success: intl.get('deleting.success').d('Deleted successfully'),
				error: intl.get('deleting.error').d('Error while deleting'),
			})
		}
	}
	const handleSaveAsTemplate = (pc: PricingCampaignModel) => {
		analytic.logEvent(
			'settings: pricing campaigns: dropdown: save ps as template'
		)
		createTemplateMutation.mutate(
			{
				template_type: SettingsTemplateType.PricingCampaign,
				name: pc.name,
				body: {
					name: pc.name,
					settings: pc.settings as PricingCampaignSettingsModel,
				},
			},
			{
				onSuccess: (data) => {
					notify.success({
						text: (
							<div>
								{intl.get('templates').d('Template')}{' '}
								<LinkButton
									variant='brand'
									onClick={() => {
										handleOpenTemplate({
											id: data.id,
											template_type: SettingsTemplateType.PricingCampaign,
										})
									}}
								>
									{data.name}
								</LinkButton>{' '}
								{intl
									.get('template_saved_successfully')
									.d('was saved successfully')}
							</div>
						),

						variant: 'success',
					})
				},
			}
		)
	}

	const handleApplyTemplate = async ({
		templateId,
		pcId,
	}: HandleApplyPCTemplate) => {
		analytic.logEvent('settings: pricing campaigns: apply template', {
			page: 'PC list',
		})
		const answer = await dialog.confirm({
			title: intl.get('template.apply.confirm.title').d('Apply template'),
			text: intl
				.get('pc.apply_template.confirm.text')
				.d(
					'Are you sure you want to apply the template and overwrite the Pricing campaign(s) settings?'
				),
			okText: intl.get('general_apply'),
		})
		if (answer) {
			if (isGlobalPricingCampaign) {
				const affectedOptimizationGroups =
					await getAffectedOptimizationGroups(pricingCampaign)
				const confirmed = affectedOptimizationGroups?.length
					? await confirmChanges(affectedOptimizationGroups)
					: true
				if (!confirmed) {
					return
				}
			}
			await toast.promise(
				applyTemplateMutation.mutateAsync({
					templateId: templateId,
					data: { ids: [pcId] },
				}),
				{
					loading: intl.get('general_processing'),
					success: intl.get('toast.entity.updated', {
						entity: intl.get('entity.pricing_campaign'),
					}),
					error: intl.get('toast.entity.failed'),
				},
				{
					success: {
						duration: 5000,
					},
				}
			)
		}
	}
	return {
		handleDublicate: checkExport(handleDublicate),
		handleDelete: checkExport(handleDelete),
		handleReassign,
		handleCopy,
		isLoading,
		handleSaveAsTemplate,
		handleApplyTemplate,
		handleUseTemplate,
	}
}
