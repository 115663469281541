import {
	all,
	any,
	filter,
	find,
	indexBy,
	omitAll,
	pipe,
	prop,
	sum,
	uniq,
} from 'lodash/fp'
import React, {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router'

import { findNodes, getChildrenIds } from '@cmpkit/base/dist/tree/helpers'
//import { treeHelpers } from '@cmpkit/base'
import { usePrevious } from '@cmpkit/hooks'

import { toFilterRules } from '@/components/filter/utils'
import { AlertGroupBy } from '@/generated'
import analytic from '@/services/analytics'
import { filterByRules } from '@/tools/filtering'
import { getLocationQuery, toQueryString } from '@/tools/location'
import { searchData } from '@/tools/page-data-helpers'

import { NEW, STARTED } from '../../constants'
import { flattifyProductGroups } from '../../helpers'
import { useProductGroupsTreeQuery } from '../../hooks/useProductGroupsTreeQuery'
import {
	useOptimizationGroupsQuery,
	useOptimizationsQuery,
	usePricingAlertsQuery,
	useReviewsQuery,
	useSalesEntitiesQuery,
	useSchedulesQuery,
} from '../../queries'
import { OptimizationGroupTableRow, OptimizationModel } from '../../types'
import {
	getOptStatus,
	getScheduleByOptimizationGroupId,
	getUpdatingOptimizationsCount,
} from '../../utils'

type GridContext = {
	selected: string[]
	setSelected(value: string[]): void
	dataList: OptimizationGroupTableRow[]
	data: OptimizationGroupTableRow[]
	setSearchText(value: string): void
	searchText: string
	isLoading: boolean
	selection: {
		getIsRowSelected(id: string): boolean
		getIsAllRowsSelected(): boolean
		getIsSomeRowsSelected(): boolean
		getToggleAllRowsSelectedHandler(): () => void
		getToggleSelectedHandler(id: string): () => void
	}
	updatingCount: number
	getSelectedItems(): OptimizationGroupTableRow[]
}
export const OptimizationGroupsContext = createContext({} as GridContext)
export function OptimizationGroupsProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const navigate = useNavigate()
	const location = useLocation()
	const query = getLocationQuery(location.search)
	const groups = useOptimizationGroupsQuery()
	const schedules = useSchedulesQuery()
	const optimizations = useOptimizationsQuery({
		refetchOnMount: 'always',
	})
	const salesEntitiesQuery = useSalesEntitiesQuery()
	const productGroupsTreeQuery = useProductGroupsTreeQuery()

	const reviews = useReviewsQuery({
		refetchOnMount: 'always',
	})
	const alerts = usePricingAlertsQuery({
		group_by: AlertGroupBy.OptimizationGroupId,
	})
	const salesLevelsDict = useMemo(
		() =>
			pipe([prop('sales_levels'), indexBy(prop('level'))])(
				salesEntitiesQuery.data
			),
		[salesEntitiesQuery.data]
	)

	const optimizationGroupsList = (groups.data ?? [])?.map((group) => {
		const optimization = optimizations?.data?.[group.id]
		const schedule = getScheduleByOptimizationGroupId(
			schedules.data || [],
			group.id
		)
		const review = find({ optimization_group_id: group.id }, reviews.data ?? [])
		const notifications_count = sum(
			filter({ optimization_group_id: group.id }, alerts?.data?.by_level)?.map(
				prop('products')
			)
		)
		const sales_level_name = salesLevelsDict?.[group.sales_level_id]?.name
		return {
			group,
			optimization,
			schedule,
			review,

			// calculated fields
			notifications_count,
			sales_level_name,
			product_groups: uniq([
				...flattifyProductGroups(group.product_groups),
				...getChildrenIds(
					findNodes(
						productGroupsTreeQuery.data,
						flattifyProductGroups(group.product_groups)
					)
				),
			]),
		}
	})

	const isLoading = any(prop('isLoading'), [groups, productGroupsTreeQuery])
	const [searchText, setSearchText] = useState('')
	const [selected, setSelected] = useState<string[]>([])
	const updatingCount = getUpdatingOptimizationsCount(
		optimizationGroupsList?.map(
			({ optimization }) => optimization as OptimizationModel
		)
	)
	const updateQuery = (value: object) => toQueryString({ ...query, ...value })

	/**
	 * Side effects
	 */
	useEffect(() => {
		if (updatingCount === 0) {
			navigate(`/p/og${toQueryString(omitAll(['ui_status__in'], query))}`)
		}
	}, [updatingCount])

	useEffect(() => {
		analytic.logEvent('browse: og list', {
			'OG amount': optimizationGroupsList.length,
		})
	}, [optimizationGroupsList.length])

	useEffect(() => {
		if (!optimizations.isLoading) {
			prevUiFilteringStatus === NEW &&
				navigate(`/p/og${updateQuery({ ui_status__in: STARTED })}`)
		}
	}, [optimizations.isLoading])

	// All optimization groups
	const data: OptimizationGroupTableRow[] = enrichData(optimizationGroupsList)

	// Optimization groups filtered by text
	const dataFilteredByText = searchData(data, searchText, 'group.name')
	const filters = toFilterRules(query)
	// Optimization groups filtered by text and tier and filters
	const dataFilterdByFilters = filterByRules(filters, dataFilteredByText)

	// Optimization groups filtered by text and tier and filters and sorted
	const dataList = dataFilterdByFilters || []

	const uiFilteringStatus = prop('ui_status__in', query) as string
	const prevUiFilteringStatus = usePrevious(uiFilteringStatus)
	const isSelected = (id: string) => selected.includes(id)
	const isAllSelected: boolean = all(
		pipe([getOptGroupId, isSelected]),
		dataList
	)

	const selection = useMemo(
		() => ({
			getIsRowSelected(id: string) {
				return selected.includes(id)
			},
			getIsAllRowsSelected() {
				return all(pipe([getOptGroupId, isSelected]), dataList)
			},
			getIsSomeRowsSelected() {
				return any(pipe([getOptGroupId, isSelected]), dataList)
			},
			getToggleAllRowsSelectedHandler() {
				return () =>
					setSelected(isAllSelected ? [] : dataList.map(getOptGroupId))
			},
			getToggleSelectedHandler(id: string) {
				return () => {
					setSelected((selected) =>
						selected.includes(id)
							? selected.filter((s) => s !== id)
							: [...selected, id]
					)
				}
			},
		}),
		[selected, dataList]
	)
	const getSelectedItems = useCallback(
		() => dataList.filter(pipe([getOptGroupId, isSelected])),
		[selected, dataList]
	)

	return (
		<OptimizationGroupsContext.Provider
			value={{
				data,
				dataList,
				setSearchText,
				selected,
				setSelected,
				getSelectedItems,
				searchText,
				isLoading,
				selection,
				updatingCount,
			}}
		>
			{children}
		</OptimizationGroupsContext.Provider>
	)
}

const enrichWithUiStatus = (data: Partial<OptimizationGroupTableRow>[]) =>
	data.map((d) => ({ ...d, ui_status: getOptStatus(d?.optimization?.status) }))

const enrichWithReviewers = (data: Partial<OptimizationGroupTableRow>[]) =>
	data.map((d) => ({
		...d,
		reviewers: d?.review?.reviewers?.map(({ id }) => id),
	}))
const enrichWithReviewStatus = (data: Partial<OptimizationGroupTableRow>[]) =>
	data.map((d) => ({
		...d,
		review_completed:
			d?.review && all({ is_approved: true }, d?.review?.reviewers),
	}))
const enrichData = pipe([
	enrichWithReviewStatus,
	enrichWithReviewers,
	enrichWithUiStatus,
])
const getOptGroupId = ({ group }: OptimizationGroupTableRow) => group.id
