import { all, any, filter, indexBy, negate, prop } from 'lodash/fp'
import React, { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'
import {
	getCoreRowModel,
	getExpandedRowModel,
	getSortedRowModel,
	Row,
	RowSelectionState,
	SortingState,
} from '@tanstack/react-table'

import { Button, Checkbox, InlineMessage, Loader, Tag } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import {
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@cmpkit/modal'

import { dialog } from '@/components/dialogs'
import SearchField from '@/components/SearchField'
import { getGroupedRowModel } from '@/components/table/getGroupedRowModel'
import { useMasterTable } from '@/components/table/hooks/useMasterTable'
import MasterTable from '@/components/table/MasterTable'
import { createMasterColumnHelper } from '@/components/table/utils/tanstack.helpers'
import {
	OptimizationGroupModel,
	OptimizationStatus,
	PricingCampaignModel,
	PricingEngineType,
	SettingsTemplateModel,
} from '@/generated'
import intl from '@/locale'
import { NEW, PENDING } from '@/modules/core/constants'
import {
	useOptimizationGroupsQuery,
	useOptimizationsQuery,
} from '@/modules/core/queries'
import { getOptStatus, getStatusBadgeVariant } from '@/modules/core/utils'
import { EngineBadge } from '@/modules/pricing-campaigns'
import {
	getPricingCampaignEngine,
	isGlobalPricingCampaign,
} from '@/modules/pricing-campaigns/helpers'
import { usePricingCampaignsQuery } from '@/modules/pricing-campaigns/queries'

import { useApplySettingsTemplateMutation } from '../../mutations'
import RevokeOptimizationAction from './RevokeOptimizationAction'

export function ApplyToPricingCampaigns({
	template,
	close,
}: {
	template: SettingsTemplateModel
	close: () => void
}) {
	const [searchText, setSearchText] = useState('')
	const queryClient = useQueryClient()
	const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})

	const optimizationGroupsQuery = useOptimizationGroupsQuery<
		Record<string, OptimizationGroupModel>
	>({
		select: indexBy<OptimizationGroupModel>(prop('id')),
	})
	const pricingCampaignsQuery = usePricingCampaignsQuery(
		{
			only_global: false,
		},
		{ select: filter<PricingCampaignModel>(negate(isGlobalPricingCampaign)) }
	)
	const optimizationsQuery = useOptimizationsQuery()

	const applyMutation = useApplySettingsTemplateMutation({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['optimizations'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaign'],
			})
		},
	})

	const isLoading = any(prop('isLoading'), [
		optimizationGroupsQuery,
		optimizationsQuery,
	])

	const data = useMemo(
		() =>
			pricingCampaignsQuery.data?.map((pc) => ({
				id: pc.id,
				name: pc.name,
				engine: getPricingCampaignEngine(pc),
				optimization_group_id: pc.optimization_group_id!,
				optimization_group_name: pc.optimization_group_id
					? optimizationGroupsQuery?.data?.[pc.optimization_group_id]?.name
					: undefined,
				optimization_status: pc.optimization_group_id
					? getOptStatus(
							optimizationsQuery.data?.[pc.optimization_group_id]?.status
						)
					: undefined,
			})) || [],
		[optimizationsQuery, optimizationGroupsQuery, pricingCampaignsQuery]
	)
	const allSelectedItemsAreValid = useMemo(
		() =>
			all(
				({ optimization_status }) =>
					[NEW, PENDING].includes(optimization_status!),
				data.filter((row) => rowSelection[row.id])
			),
		[rowSelection, data]
	)

	const canGoNext = useMemo(
		() => Object.keys(rowSelection).length > 0 && allSelectedItemsAreValid,
		[allSelectedItemsAreValid, rowSelection]
	)
	const handleApply = useCallback(async () => {
		const answer = await dialog.confirm({
			title: intl
				.get('template.apply.confirm.title')
				.d('Apply settings template'),
			text: intl
				.get('template.apply.confirm.text')
				.d('Are you sure you want to apply the template'),
			okText: intl.get('app.apply').d('Apply'),
		})
		if (answer) {
			await applyMutation.mutateAsync({
				templateId: template.id,
				data: {
					ids: Object.keys(rowSelection),
				},
			})
			toast.success(
				intl.get('toast.entity.applied', {
					entity: intl.get('entity.template'),
				})
			)
			close()
		}
	}, [rowSelection])
	return (
		<>
			<Blanket
				isTinted={isLoading || applyMutation.isPending}
				className='absolute flex items-center justify-center rounded-lg bg-white/30 backdrop-blur-sm dark:bg-black/30'
			>
				<Loader />
			</Blanket>
			<ModalHeader>
				<ModalTitle>
					{intl
						.get('template.apply.modal.title', {
							name: template.name,
						})
						.d(`Apply settings template: ${template.name}`)}
				</ModalTitle>
				<ModalDescription>
					{intl
						.get('template.apply.modal.subtitle')
						.d(
							'This will apply the settings from the template to the current settings. Are you sure you want to apply the template'
						)}
				</ModalDescription>
			</ModalHeader>
			<ModalBody>
				{applyMutation.isError && (
					<InlineMessage
						variant='danger'
						className='mb-2 w-full text-xs font-medium'
					>
						{intl
							.get('template.apply.error')
							.d(
								'We could not apply the template. Please ensure that the selected items are in a valid state, or try again later.'
							)}
					</InlineMessage>
				)}
				<div className='pb-2'>
					<SearchField
						className='w-60'
						onClear={() => setSearchText('')}
						value={searchText}
						onChange={setSearchText}
						placeholder={intl.get('general_search')}
					/>
				</div>
				<div className='flex h-96 flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
					<PricingCampaignsTable
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						data={data.filter(({ name }) =>
							name.toLowerCase().includes(searchText.toLowerCase())
						)}
					/>
				</div>
			</ModalBody>
			<ModalFooter className='flex justify-between'>
				<Button data-testid={'apply-template-modal-cancel'} onClick={close}>
					{intl.get('general_cancel')}
				</Button>
				<Button
					data-testid={'apply-template-modal-apply'}
					variant='primary-brand'
					disabled={!canGoNext || applyMutation.isPending}
					onClick={handleApply}
					iconBefore={<CheckIcon />}
				>
					{intl.get('general_apply')}
				</Button>
			</ModalFooter>
		</>
	)
}

type PricingCampaignDataRow = {
	id: string
	name: string
	engine: PricingEngineType
	optimization_group_id: string
	optimization_group_name?: string
	optimization_status?: OptimizationStatus
}
function PricingCampaignsTable({
	data,
	rowSelection,
	setRowSelection,
}: {
	data: PricingCampaignDataRow[]
	rowSelection: RowSelectionState
	setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
}) {
	const [sorting, setSorting] = useState<SortingState>([
		{
			desc: false,
			id: 'name',
		},
	])
	const table = useMasterTable<PricingCampaignDataRow>({
		data,
		columns,
		state: {
			sorting,
			rowSelection,
		},
		initialState: {
			expanded: true,
			grouping: ['optimization_group_name'],
		},
		groupedColumnMode: 'remove',
		getRowId: (row) => row.id,
		enableRowSelection: true,
		enableColumnActions: false,
		enableSorting: true,
		enableColumnPinning: false,
		onRowSelectionChange: setRowSelection,
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		layoutMode: 'grid',
		enableVirtualization: true,
		getRowHeight(row) {
			return row?.depth === 0 ? 56 : 36
		},
		renderGroup: ({ row }) => <TableOptimzationGroupGroup row={row} />,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getGroupedRowModel: getGroupedRowModel(),
	})
	return <MasterTable table={table} />
}
const columnHelper = createMasterColumnHelper<PricingCampaignDataRow>()
const columns = [
	columnHelper.accessor((row) => row.id, {
		id: 'control',
		enableSorting: false,
		enableHeaderTooltip: false,
		size: 28,
		header: ({ table }) => (
			<Checkbox
				checked={table.getIsAllRowsSelected()}
				indeterminate={table.getIsSomeRowsSelected()}
				onChange={table.getToggleAllRowsSelectedHandler()}
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onChange={row.getToggleSelectedHandler()}
			/>
		),
	}),
	columnHelper.accessor('name', {
		header: () => intl.get('pricing_campaign'),
		cell: (info) => {
			return <div className='truncate text-xs'>{info.getValue()}</div>
		},
	}),
	columnHelper.accessor('optimization_group_name', {
		size: 200,
		header: () => intl.get('optimization_group'),
		cell: (info) => {
			return <div className='truncate text-xs'>{info.getValue()}</div>
		},
	}),
	columnHelper.accessor('engine', {
		header: () => intl.get('pricing_engine'),
		size: 200,
		cell: (info) => {
			const engine = info.getValue()
			return (
				<EngineBadge engine={engine}>
					{intl.get('campaigns_om_title_' + engine)}
				</EngineBadge>
			)
		},
	}),
	columnHelper.accessor('id', {
		size: 100,
		enableSorting: false,
		enablePinning: false,
		enableHiding: false,
		header: () => intl.get('actions').d('Actions'),
		cell: (info) => {
			return (
				<div className='flex items-center justify-end space-x-2'>
					<RevokeOptimizationAction
						optimizationGroupId={info.row.original.optimization_group_id}
						status={info.row.original.optimization_status}
					/>
				</div>
			)
		},
	}),
	columnHelper.accessor('optimization_status', {
		header: () => intl.get('app.status'),
		size: 130,
		cell: (info) => {
			const value = info.getValue()
			return (
				<div className='truncate'>
					<Tag className='truncate' variant={getStatusBadgeVariant(value)}>
						{intl.get(`opt_${value}`)}
					</Tag>
				</div>
			)
		},
	}),
]

function TableOptimzationGroupGroup({
	row,
}: {
	row: Row<PricingCampaignDataRow>
}) {
	const name =
		row.original.optimization_group_name || intl.get('global').d('Global')
	return (
		<div className='flex h-14 items-start gap-2 pt-2'>
			<Button
				variant='tertiary'
				size='small'
				onClick={row.getToggleExpandedHandler()}
				iconBefore={
					row.getIsExpanded() ? <CaretDownIcon /> : <CaretRightIcon />
				}
			/>
			<div className='flex flex-col gap-1'>
				<h4 className='font-bold'>{name}</h4>
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{row.subRows.length} {intl.get('general_pc')}
					</span>
				</div>
			</div>
		</div>
	)
}
