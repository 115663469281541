import { find } from 'lodash/fp'
import React from 'react'

import { Action, Getter, Plugin } from '@cmpkit/data-table'

import { Sort, SortDirection } from '@cmpkit/data-table'

type Props = {
	sort: Sort[]
	onSortChange(sort: Sort[]): void
}
// FIXME: This is temporary fix for the issue with the TableSorting plugin
export function TableSorting({ sort = [], onSortChange }: Props) {
	const handleSortChange = (
		{ columnId, direction }: { columnId: string; direction: SortDirection },
		{ sorting }: { sorting: Sort[] }
	) => {
		const exist = find({ columnId }, sorting)
		let newSort = []
		if (direction === 'none') {
			newSort = sorting.filter((s) => s.columnId !== columnId)
		} else if (!exist) {
			newSort = [...sorting, { columnId, direction }]
		} else {
			newSort = sorting.map((s) => {
				if (s.columnId === columnId) {
					return { columnId, direction }
				} else {
					return s
				}
			})
		}
		onSortChange?.(newSort)
	}
	return (
		<Plugin name='TableSorting' dependencies={[{ name: 'TableRows' }]}>
			<Getter name='sorting' value={sort} />
			{/* eslint-disable-next-line */}
			<Action name='onSortChange' action={handleSortChange as any} />
		</Plugin>
	)
}
