import clsx from 'clsx'
import React, { useMemo } from 'react'

import { Button, Panel } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import XCircleIcon from '@cmpkit/icon/lib/glyph/x-circle'
import Popover from '@cmpkit/popover'
import { CreatableSelect, SingleValue } from '@cmpkit/select'

import { DataOption } from '@/common.types'
import { ColumnSchemaModel } from '@/generated'
import intl from '@/locale'

import { CellPropControlled } from '../fields'

export default function SelectField({
	schema,
	value,
	onChange,
	row,
	isChanged,
	choices,
	originalValue,
}: CellPropControlled) {
	const popover = useDisclosure()
	const options: DataOption[] = useMemo(
		() => (choices ? choices : getOptions(schema) || []),
		[schema]
	)
	const handleChange = (value?: string) => {
		onChange?.({
			row,
			schema,
			value,
		})
		popover.close()
	}
	const handleButtonClick = () => {
		if (!value) {
			return popover.close()
		}
		onChange?.({
			row,
			schema,
			value: !originalValue ? originalValue : '',
		})
		popover.close()
	}
	const content = (
		<Panel className='cmp-border min-w-[200px]'>
			<CreatableSelect
				isMulti={false}
				styles={selectStyles}
				className='cmp-border-bottom w-full'
				closeMenuOnSelect
				onChange={(option: SingleValue<DataOption>) =>
					handleChange(option?.value)
				}
				value={
					options?.find((option: DataOption) => option.value === value) || {
						value,
						label: value,
					}
				}
				menuIsOpen
				options={options}
				onCreateOption={handleChange}
				createOptionPosition={'first'}
				placeholder={intl.get('general.search_create').d('Search or create')}
				controlShouldRenderValue={false}
				backspaceRemovesValue={false}
				hideSelectedOptions={false}
				isClearable={false}
				components={components}
				formatCreateLabel={(v: string) =>
					intl.get('general_add').d('Add') + `: "${v}"`
				}
			/>
			<Button
				className='cmp-border-top grow-0 justify-start rounded-none'
				iconBefore={<XCircleIcon />}
				onClick={handleButtonClick}
			>
				{intl.get('general_clear')}
			</Button>
		</Panel>
	)
	return (
		<>
			<Popover
				onDismiss={popover.close}
				isOpen={popover.isOpen}
				content={content}
				placement='bottom'
			>
				<div
					className='flex cursor-pointer justify-between'
					onClick={popover.toggle}
				>
					<div
						className={clsx('overflow-hidden text-ellipsis', {
							'text-brand': isChanged,
						})}
					>
						{value || '-'}
					</div>
					<Button
						variant='tertiary'
						iconAfter={<CaretDownIcon className='size-3' />}
					/>
				</div>
			</Popover>
		</>
	)
}

const selectStyles = {
	control: (provided: object) => ({ ...provided, margin: 8 }),
	menu: () => ({
		position: 'relative',
	}),
	option: (provided: object) => ({ ...provided, cursor: 'pointer' }),
} as any // eslint-disable-line @typescript-eslint/no-explicit-any
const Control = ({
	children,
	innerProps,
	innerRef,
}: {
	children: React.ReactNode
	innerProps: object
	innerRef: React.Ref<HTMLDivElement>
}) => {
	return (
		<div
			ref={innerRef}
			className={'cmp-border-bottom flex cursor-text p-1'}
			{...innerProps}
		>
			{children}
		</div>
	)
}
const DropdownIndicator = () => <SearchIcon className='mr-1' />
const components = {
	IndicatorSeparator: null,
	DropdownIndicator,
	Control,
}
const getOptions = (schema: Partial<ColumnSchemaModel>) =>
	schema.enum?.map(
		(item: string): DataOption => ({
			value: item,
			label: item,
		})
	) || []
