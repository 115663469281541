import { useNavigate, useParams } from 'react-router'
import TemplateBuilder from './TemplateBuilder'
import TemplateBuilderProvider from './TemplateBuilderProvider'

export default function TemplateEditorPage() {
	const { template_id } = useParams()
	const navigate = useNavigate()
	return (
		<TemplateBuilderProvider
			onFinish={() => navigate('/p/templates/scenario')}
			templateId={template_id}
		>
			<TemplateBuilder />
		</TemplateBuilderProvider>
	)
}
