import { first, fromPairs, join, map, over, pipe, propOr } from 'lodash/fp'

import { DataOption } from '@/common.types'

/**
 *  Pick value by property from one of two available datasources
 * @param {object} ds1
 * @param {object} ds2
 * @param {string} prop
 * @param {any} defaultValue
 */

export const pickFromAvailable = (
	ds1: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	ds2: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	prop: string,
	defaultValue = null
) => propOr(propOr(defaultValue, prop, ds2), prop, ds1)

export const roundTo = (value: number, n: number) =>
	parseFloat(value.toFixed(n))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dictBy = <T = Record<string, any>>(
	list: T[],
	key: keyof T,
	val: keyof T
): Record<string, unknown> =>
	fromPairs(list.map((item) => [item?.[key], item?.[val]]))

type ToOptionFn = (val: Array<[string, string]>) => DataOption[]
export const toOption: ToOptionFn = map(([value, label]) => ({ value, label }))

export const asNumber = (val: string) => {
	if (val === '') {
		return val
	}
	const value = Number(val)
	return Number.isNaN(value) ? val : value
}
export const getProjectFromHost = (hostname: string) =>
	first(hostname.split('.'))!.replace('dev-', '').replace('staging-', '')

export const getProject = (): string => getProjectFromHost(location.hostname)

export function truncate(fullStr: string, strLen: number, separator = '...') {
	if (fullStr.length <= strLen) return fullStr

	const sepLen = separator.length,
		charsToShow = strLen - sepLen,
		frontChars = Math.ceil(charsToShow / 2),
		backChars = Math.floor(charsToShow / 2)

	return (
		fullStr.substr(0, frontChars) +
		separator +
		fullStr.substr(fullStr.length - backChars)
	)
}
export const sprintf = (
	key = '',
	values?: Record<string, string | number>
): string =>
	!values
		? key
		: Object.entries(values).reduce(
				(message, [key, value]) => message.replace(`{${key}}`, value as string),
				key
			)

export const getEnvFromHost = (hostname: string) => {
	const subdomain = first<string>(hostname.split('.'))
	if (['dev', 'staging'].some((env) => subdomain?.includes(env))) {
		return 'dev'
	} else if (['localhost'].some((env) => subdomain?.includes(env))) {
		return 'local'
	} else {
		return 'prod'
	}
}

export const getSentryEnv = pipe([
	over([getProjectFromHost, getEnvFromHost]),
	join('-'),
])

export function uuid() {
	let dt = new Date().getTime()
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (dt + Math.random() * 16) % 16 | 0
		dt = Math.floor(dt / 16)
		return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
	})
}
/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, dp = 0) {
	const thresh = 1024
	if (Math.abs(bytes) < thresh) {
		return bytes + ' B'
	}

	const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	let u = -1
	const r = 10 ** dp

	do {
		bytes /= thresh
		++u
	} while (
		Math.round(Math.abs(bytes) * r) / r >= thresh &&
		u < units.length - 1
	)

	return bytes.toFixed(dp) + ' ' + units[u]
}

export const cyrb53 = (str: string, seed = 0) => {
	let h1 = 0xdeadbeef ^ seed,
		h2 = 0x41c6ce57 ^ seed
	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i)
		h1 = Math.imul(h1 ^ ch, 2654435761)
		h2 = Math.imul(h2 ^ ch, 1597334677)
	}
	h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
	h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
	h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
	h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)

	return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}
