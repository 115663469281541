import React from 'react'

import DRAWER_COMPONENTS from '../registry'
import { ActionType, dispatch, useStore } from '../store'

export default function DrawersStack() {
	const { drawers } = useStore()
	return (
		<>
			{drawers.map((drawer) => {
				const SpecificDrawer = DRAWER_COMPONENTS[drawer.id]
				const onRequestClose = () =>
					dispatch({ type: ActionType.DISMISS_DRAWER, drawerId: drawer.id })
				return SpecificDrawer ? (
					<SpecificDrawer
						isOpen={drawer.visible}
						key={drawer.id}
						close={onRequestClose}
						// eslint-disable-next-line
						{...(drawer?.props || ({} as any))}
					/>
				) : null
			})}
		</>
	)
}
