import React, { KeyboardEventHandler, useState } from 'react'
import {
	Controller,
	ControllerRenderProps,
	FieldValues,
	UseControllerProps,
	useFormContext,
} from 'react-hook-form'

import {
	FormError,
	FormGroup,
	FormHint,
	FormLabel,
	Required,
} from '@cmpkit/base'
import HelpCircleIcon from '@cmpkit/icon/lib/glyph/help-circle'
import {
	CreatableSelect,
	CreatableSelectProps,
	MultiValue,
} from '@cmpkit/select'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'
import { asNumber } from '@/tools/utils'

const createOption = (value: string) => ({
	label: value,
	value,
})
const CreatableField = <OptionType extends DataOption>({
	field,
	type,
	...props
}: {
	field: ControllerRenderProps<FieldValues, any> // eslint-disable-line @typescript-eslint/no-explicit-any
	type?: string
} & CreatableSelectProps<OptionType, true>) => {
	const itemsType = type ?? 'string'
	const [inputValue, setInputValue] = useState('')
	const value = field.value.map(createOption)
	const _onChange = (value: MultiValue<OptionType>) =>
		field.onChange([...value.map(({ value }) => value)])
	const _onInputChange = (inputValue: string) => setInputValue(inputValue)
	const _onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
		if (!inputValue) return
		switch (event.key) {
			case 'Enter':
			case 'Tab':
				setInputValue('')
				field.onChange([
					...value.map(({ value }: OptionType) => value),
					itemsType === 'integer' ? asNumber(inputValue) : inputValue,
				])
				event.preventDefault()
		}
	}
	return (
		<CreatableSelect<OptionType, true>
			menuIsOpen={false}
			placeholder={'Type here...'}
			{...props}
			components={{
				DropdownIndicator: null,
				...(props.components || {}),
			}}
			inputValue={inputValue}
			isMulti
			isClearable
			value={value}
			onChange={_onChange}
			onInputChange={_onInputChange}
			onKeyDown={_onKeyDown}
		/>
	)
}
export default function RHFCreatableSelect<OptionType extends DataOption>({
	name,
	label,
	hint,
	rules,
	shouldUnregister,
	defaultValue,
	required,
	description,
	...other
}: UseControllerProps &
	CreatableSelectProps<OptionType, true> & {
		label?: string
		required?: boolean
		hint?: string
		description?: string
	}) {
	const { control } = useFormContext()

	return (
		<FormGroup>
			<FormLabel>
				{label}
				{required && <Required />}
			</FormLabel>
			<Controller
				name={name}
				rules={rules}
				shouldUnregister={shouldUnregister}
				defaultValue={defaultValue}
				control={control}
				render={({ field, fieldState: { error } }) => {
					return (
						<>
							<div className='flex w-full items-center gap-2'>
								<CreatableField<OptionType>
									{...other}
									className='w-64'
									field={field}
								/>
								{hint && (
									<Tooltip placement='top' content={hint}>
										<HelpCircleIcon className='text-muted' />
									</Tooltip>
								)}
							</div>
							{!!description && (
								<FormHint className='items-center'>{description}</FormHint>
							)}
							{error && <FormError>{error.message}</FormError>}
						</>
					)
				}}
			/>
		</FormGroup>
	)
}
