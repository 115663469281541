import { useMemo } from 'react'

import { Spinner, TreeNodeType } from '@cmpkit/base'

import { DialogInner } from '../../components/Popup'
import PopupFooter from '../../components/PopupFooter'
import TreeSelect from '../../components/TreeSelect'
import { FieldViewProps, ValueState } from '../../types'
import TreeSelectController from './Controller'

type Props = FieldViewProps<TreeSelectController> & {
	localValue: ValueState<string[]>
}
export default function TreeSelectView({
	field,
	localValue,
	isDirty,
	isRemovable,
	onRemove,
	onChange,
	onApply,
	closePopup,
}: Props) {
	const { operation } = localValue
	const { useSelectOptions } = field
	const selectOptions = useSelectOptions()
	const options: TreeNodeType[] = useMemo(
		() => (selectOptions.data || []) as TreeNodeType[],
		[selectOptions.data]
	)
	const value = useMemo(() => localValue?.value || [], [localValue])

	const handleChange = (value: string[]) => onChange({ operation, value })
	const handleCancel = () => closePopup()
	const handleApply = () => {
		onApply?.()
		closePopup()
	}

	return (
		<DialogInner className='min-w-[320px]'>
			{selectOptions.isLoading ? (
				<div className='flex h-20 items-center justify-center'>
					<Spinner />
				</div>
			) : (
				<TreeSelect
					placeholder={field.placeholder}
					tree={options}
					value={value}
					onChange={handleChange}
				/>
			)}
			<PopupFooter
				isDisabledApply={!isDirty}
				onRemove={isRemovable ? onRemove : undefined}
				onCancel={handleCancel}
				onApply={handleApply}
			/>
		</DialogInner>
	)
}
