import { fromPairs } from 'lodash'
import {
	entries,
	find,
	indexBy,
	isEmpty,
	map,
	pipe,
	prop,
	propEq,
	sum,
	values,
} from 'lodash/fp'
import React, { useCallback, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { Badge, Button, Content, Header, Layout } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import SortIcon from '@cmpkit/icon/lib/glyph/sort'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'

import { dialog } from '@/components/dialogs'
import {
	MatchedProductsRequestModel,
	PricingCampaignModel,
	ScenarioModel,
} from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useOptimizationQuery } from '@/modules/core/queries'
import { useModalStore } from '@/modules/modals/store'
import {
	getAssortmentShare,
	getPreOrderedCampaigns,
	isGlobalPricingCampaign,
} from '@/modules/pricing-campaigns/helpers'
import { useBulkDeletePricingCampaignsMutation } from '@/modules/pricing-campaigns/mutations'
import {
	useMatchedProductsQuery,
	usePricingCampaignsQuery,
} from '@/modules/pricing-campaigns/queries'
import {
	alignPricingCampaignsOrders,
	isValidAssignment,
	isValidMatchedProductsAssignments,
} from '@/modules/pricing-campaigns/utils'
import analytic from '@/services/analytics'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { getPricingCampaignsTableRows } from '../../helpers'
import { PricingCampaignTableRow } from '../../types'
import CopyPricingCampaignToScenarioModal from '../CopyPricingCampaignToScenarioModal'
import PricingCampaignsTable from '../PricingCampaignsTable'

type ScenarioPricingCampaignsProps = {
	scenario: ScenarioModel
}

export default function ScenarioPricingCampaigns({
	scenario,
}: ScenarioPricingCampaignsProps) {
	return <ScenarioPricingCampaignsTable scenario={scenario} />
}

function ScenarioPricingCampaignsTable({
	scenario,
}: {
	scenario: ScenarioModel
}) {
	const { showModal } = useModalStore()
	const optimizationGroupId = useOptimizationGroupId()!
	const queryClient = useQueryClient()
	const [selected, setSelected] = useState<string[]>([])
	const copyPcModal = useDisclosure()

	const campaigns = usePricingCampaignsQuery<PricingCampaignModel[]>(
		{
			optimization_group_id: optimizationGroupId,
			scenario_id: scenario.id,
		},
		{
			select: getPreOrderedCampaigns,
		}
	)

	const totalProducts = useOptimizationQuery<number>(optimizationGroupId, {
		select: prop('total_products'),
	})
	const assignments = useMemo(
		() =>
			alignPricingCampaignsOrders(campaigns.data || [])
				?.map(
					(pc): MatchedProductsRequestModel => ({
						id: pc.id,
						order: pc.order,
						assignments:
							pc.settings.product_assignments?.map(({ name, filters }) => ({
								id: name,
								filters,
							})) || [],
					})
				)
				.filter(isValidAssignment) || [],
		[campaigns.data]
	)

	const predictedCounts = useMatchedProductsQuery<Record<string, number>>(
		{
			optimization_group_id: optimizationGroupId,
		},
		assignments,
		{
			select: pipe([
				prop('pricing_campaigns'),
				indexBy(prop('id')),
				entries,
				map(([id, { count }]) => [id, count]),
				fromPairs,
			]),
			enabled:
				!isEmpty(assignments) && isValidMatchedProductsAssignments(assignments),
		}
	)
	const unassignedCount =
		(totalProducts.data ?? 0) - (sum(values(predictedCounts.data ?? {})) ?? 0)
	const bulkDeletePricingCampaigns = useBulkDeletePricingCampaignsMutation()
	const handleDeleteSelectedPricingCampaigns = useCallback(async () => {
		const answer = await dialog.confirmDelete({
			title: intl.get('pc_detete_confirm_title'),
			text: intl.get('pc_detete_confirm_subtitle'),
			okText: intl.get('general_delete'),
		})
		if (answer) {
			bulkDeletePricingCampaigns.mutate(selected, {
				onSettled: () => {
					queryClient.invalidateQueries({
						queryKey: ['pricing-campaigns'],
					})
					queryClient.invalidateQueries({
						queryKey: ['pricing-campaigns-segments'],
					})
					setSelected([])
				},
			})
		}
	}, [selected, setSelected])

	const data: PricingCampaignTableRow[] = useMemo(() => {
		return getPricingCampaignsTableRows(
			campaigns.data || [],
			predictedCounts.data || {},
			totalProducts.data
		)
	}, [
		campaigns.data,
		predictedCounts.data,
		totalProducts.data,
		unassignedCount,
	])

	const isEnabledDeleting = useMemo(() => {
		return (
			selected.length > 0 &&
			selected.every((id) => {
				const pc = find(propEq('campaign.id', id), data)
				return pc?.campaign && !isGlobalPricingCampaign(pc.campaign)
			})
		)
	}, [selected, data])

	return (
		<Layout>
			<Header className='mb-3 justify-between'>
				<div className='flex items-center space-x-2'>
					<Button
						size='small'
						iconBefore={<SortIcon />}
						onClick={() => {
							analytic.logEvent('what-if: pricing campaigns: reorder: click')
							showModal('PRICING_CAMPAIGNS_PRIORITY', {
								scenarioId: scenario.id,
								optimizationGroupId: optimizationGroupId,
							})
						}}
					>
						{intl.get('scenario.change_priority').d('Change priority')}
					</Button>
					{!!unassignedCount && (
						<Badge variant='warning'>
							{`${intl.get('general_unassigned')}: ${formatNumber(unassignedCount)} (${formatNumber(
								getAssortmentShare(totalProducts.data || 0, unassignedCount),
								NumberFormats.Percent
							)})`}
						</Badge>
					)}
				</div>
				<div className='flex space-x-2'>
					<Button
						size='small'
						iconBefore={<PlusIcon />}
						onClick={() => {
							analytic.logEvent(
								'what-if: duplicate pcs to scenario: modal: open',
								{
									location: 'toolbar',
								}
							)
							copyPcModal.open()
						}}
					>
						{intl.get('scenario.add_pcs').d('Add PCs from the main scenario')}
					</Button>
					<Button
						size='small'
						disabled={!isEnabledDeleting}
						iconBefore={<Trash2Icon />}
						onClick={handleDeleteSelectedPricingCampaigns}
					>
						{intl.get('scenario.delete_pcs').d('Delete selected PC')}
					</Button>
				</div>
			</Header>
			<Content className='overflow-hidden'>
				<CopyPricingCampaignToScenarioModal
					scenarioId={scenario?.id}
					scenarioName={scenario?.name}
					isOpen={copyPcModal.isOpen}
					close={copyPcModal.close}
				/>
				<div className='flex h-72 flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
					{!data.length ? (
						<div className='flex size-full flex-col items-center justify-center space-y-2 bg-accent-3 text-xs'>
							<SearchIcon height={72} width={72} />
							<p className='font-medium text-muted'>
								{intl.get('scenario.empty_pcs.title')}
							</p>
							<Button
								variant='primary-brand'
								iconBefore={<PlusIcon />}
								onClick={() => {
									analytic.logEvent(
										'what-if: duplicate pcs to scenario: modal: open',
										{
											location: 'empty_screen',
										}
									)
									copyPcModal.open()
								}}
							>
								{intl.get('scenario.empty_pcs.add')}
							</Button>
						</div>
					) : (
						<PricingCampaignsTable
							data={data}
							selected={selected}
							setSelected={setSelected}
						/>
					)}
				</div>
			</Content>
		</Layout>
	)
}
