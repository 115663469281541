import React from 'react'

import {
	dialog as dialogApi,
	DialogEntity,
	DialogOptions,
} from '@cmpkit/alert-dialog'
import { Button } from '@cmpkit/base'

import intl from '@/locale'

type DialogArgs = {
	title?: string
	text?: string | React.ReactNode
	okText?: string
	cancelText?: string
}
const getDialogComponent =
	({
		title,
		text,
		actions,
	}: DialogArgs & { actions?(dialog: DialogEntity): React.ReactNode }) =>
	(d: DialogEntity) => (
		<AlertDialogContent title={title} text={text} actions={actions} d={d} />
	)
function deffer() {
	const self: Record<string, any> = {} // eslint-disable-line
	self.promise = new Promise(function (resolve, reject) {
		self.resolve = resolve
		self.reject = reject
	})
	return self
}
export const dialog = {
	confirm: (message: DialogArgs | string, options: DialogOptions = {}) => {
		const messageConf =
			typeof message === 'string' ? { text: message } : message
		const deferred = deffer()
		const actions = (d: DialogEntity) => (
			<div className='flex items-center justify-end space-x-2 px-5 pb-5 pt-2'>
				<Button
					onClick={() => {
						deferred.resolve(false)
						dialogApi.dismiss(d.id)
					}}
				>
					{messageConf.cancelText || intl.get('general_cancel')}
				</Button>
				<Button
					variant={'primary-brand'}
					onClick={() => {
						deferred.resolve(true)
						dialogApi.dismiss(d.id)
					}}
				>
					{messageConf.okText || intl.get('general_ok')}
				</Button>
			</div>
		)
		dialogApi.custom(getDialogComponent({ ...messageConf, actions }), {
			...options,
		})
		return deferred.promise
	},
	confirmDelete: (
		message: DialogArgs | string,
		options: DialogOptions = {}
	) => {
		const messageConf =
			typeof message === 'string' ? { text: message } : message
		const deferred = deffer()
		const actions = (d: DialogEntity) => (
			<div className='flex items-center justify-end space-x-2 px-5 pb-5 pt-2'>
				<Button
					onClick={() => {
						deferred.resolve(false)
						dialogApi.dismiss(d.id)
					}}
				>
					{messageConf.cancelText || intl.get('general_cancel')}
				</Button>
				<Button
					variant={'primary-danger'}
					onClick={() => {
						deferred.resolve(true)
						dialogApi.dismiss(d.id)
					}}
				>
					{messageConf.okText || intl.get('general_delete')}
				</Button>
			</div>
		)
		dialogApi.custom(getDialogComponent({ ...messageConf, actions }), {
			...options,
		})
		return deferred.promise
	},
	alert: (message: DialogArgs | string, options: DialogOptions = {}) => {
		const messageConf =
			typeof message === 'string' ? { text: message } : message
		const deferred = deffer()
		const actions = (d: DialogEntity) => (
			<div className='flex items-center justify-end space-x-2 px-5 pb-5 pt-2'>
				<Button
					onClick={() => {
						deferred.resolve(true)
						dialogApi.dismiss(d.id)
					}}
				>
					{messageConf.okText || intl.get('general_ok')}
				</Button>
			</div>
		)
		dialogApi.custom(getDialogComponent({ ...messageConf, actions }), {
			...options,
		})
		return deferred.promise
	},
	actions: (
		message: DialogArgs & ActionsProps,
		options: DialogOptions = {}
	) => {
		const deferred = deffer()
		const messageConf =
			typeof message === 'string' ? { text: message } : message
		const actions = (d: DialogEntity) =>
			message.actions((data) => {
				deferred.resolve(data)
				dialogApi.dismiss(d.id)
			})

		dialogApi.custom(getDialogComponent({ ...messageConf, actions }), {
			...options,
		})
		return deferred.promise
	},
	form: (message: DialogArgs & FormProps, options: DialogOptions = {}) => {
		const deferred = deffer()
		const messageConf =
			typeof message === 'string' ? { text: message } : message
		const actions = (d: DialogEntity) => {
			return message.form((data) => {
				deferred.resolve(data)
				dialogApi.dismiss(d.id)
			})
		}
		dialogApi.custom(getDialogComponent({ ...messageConf, actions }), {
			...options,
		})
		return deferred.promise
	},
}
type DialogProps = {
	title?: string
	text?: string | React.ReactNode
	actions?(d: DialogEntity): React.ReactNode
	d: DialogEntity
}
type FormProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	form(resolve: (data: any) => void): React.ReactNode
}
type ActionsProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	actions(resolve: (data: any) => void): React.ReactNode
}
function AlertDialogContent({ title, text, actions, d }: DialogProps) {
	return (
		<>
			<div className='px-5 pb-2 pt-5'>
				{title && <h4 className='text-lg font-semibold'>{title}</h4>}
				{text && <p>{text}</p>}
			</div>
			{actions?.(d)}
		</>
	)
}
