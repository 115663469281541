import React from 'react'

import { Badge } from '@cmpkit/base'

import intl from '@/locale'

import Container from '../Container'

type LifecycleProps = {
	lifecycle_weeks: number
}
export default function Lifecycle({ lifecycle_weeks }: LifecycleProps) {
	return (
		<Container>
			<div className='flex flex-col items-center'>
				<Badge className='text-xs'>
					{lifecycle_weeks} {intl.get('general_weeks_ago')}
				</Badge>
				<p className='mt-2 text-xs text-muted'>
					{intl.get('general_lifecycle_start')}
				</p>
			</div>
		</Container>
	)
}
