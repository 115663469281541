import { SeriesAreasplineOptions, XAxisPlotBandsOptions } from 'highcharts'
import { pickAll, sum, values } from 'lodash/fp'
import React from 'react'

import { InlineMessage, Result } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import { PALETTE } from '@cmpkit/theme'
import Tooltip from '@cmpkit/tooltip'

import Chart from '@/components/Chart'
import { FilterRuleModel } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { formatNumber, NumberFormats } from '@/tools/locale'

import {
	useInterpretabilityStatisticQuery,
	useOptimizationQuery,
} from '../../queries'

export default function ElasticityDistributionWidget({
	filters,
}: {
	filters: FilterRuleModel[]
}) {
	const optimizationGroupId = useOptimizationGroupId()!
	const optimizationQuery = useOptimizationQuery(optimizationGroupId)
	const interpretabilityStatistic = useInterpretabilityStatisticQuery(
		{
			optimizations: [
				{
					optimization_group_id: optimizationGroupId,
					optimization_id: optimizationQuery.data!.id,
				},
			],
			filters,
		},
		{
			enabled: !!optimizationQuery.data?.id,
		}
	)
	const elasticity = interpretabilityStatistic.data?.elasticity
	if (!elasticity) {
		return null
	}

	const seriesData = elasticity.raw ?? []
	const median = elasticity.median ?? 0
	const totalItems =
		sum(values(pickAll(['high', 'low', 'medium'], median))) ?? 0
	const totalOptimizationItems = optimizationQuery.data?.total_products ?? 0
	if (interpretabilityStatistic.isLoading) {
		return <LoadingPlaceholder />
	}
	if (interpretabilityStatistic.isError) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger' className='w-full'>
				{interpretabilityStatistic.error.message ||
					intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}

	return (
		<div>
			{seriesData.length > 0 ? (
				<Chart config={getConfig({ series: formatSeries(seriesData) })} />
			) : (
				<div className='flex max-h-[350px] w-full flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-4 py-5'>
					<Result
						icon={<SearchIcon width={72} height={72} />}
						subtitle={intl.get('no_data')}
					/>
				</div>
			)}

			<div className='flex flex-col space-y-1'>
				<div className='mt-2 flex justify-between'>
					<span className='text-xs font-medium'>
						{intl.get('products_count_items')}
					</span>
					<span className='text-xs font-medium'>
						{formatNumber(totalOptimizationItems)}
					</span>
				</div>
				<div className='mt-2 flex justify-between'>
					<span className='text-xs font-medium'>
						{intl.get('general_median')}
					</span>
					<span className='text-xs font-medium'>{formatNumber(median)}</span>
				</div>
			</div>
			<div className='mt-4 flex flex-col space-y-1'>
				<div className='mt-2 flex justify-between'>
					<span className='text-xs font-medium'>{intl.get('low')}</span>
					<span className='text-xs font-medium'>
						{formatNumber(elasticity.low)} (
						{formatNumber(
							(elasticity.low / totalOptimizationItems) * 100,
							NumberFormats.Percent
						)}
						)
					</span>
				</div>
				<div className='mt-2 flex justify-between'>
					<span className='text-xs font-medium'>{intl.get('high')}</span>
					<span className='text-xs font-medium'>
						{formatNumber(elasticity.high)} (
						{formatNumber(
							(elasticity.high / totalOptimizationItems) * 100,
							NumberFormats.Percent
						)}
						)
					</span>
				</div>
				<div className='mt-2 flex justify-between'>
					<span className='text-xs font-medium'>{intl.get('medium')}</span>
					<span className='text-xs font-medium'>
						{formatNumber(elasticity.medium)} (
						{formatNumber(
							(elasticity.medium / totalOptimizationItems) * 100,
							NumberFormats.Percent
						)}
						)
					</span>
				</div>

				<div className='mt-2 flex justify-between'>
					<Tooltip content={intl.get('elasticity_distribution_na_tooltip')}>
						<span className='text-xs font-medium'>
							{intl.get('n/a').d('n/a')}
						</span>
					</Tooltip>
					<span className='text-xs font-medium'>
						{formatNumber(totalOptimizationItems - totalItems)} (
						{formatNumber(
							((totalOptimizationItems - totalItems) / totalOptimizationItems) *
								100,
							NumberFormats.Percent
						)}
						)
					</span>
				</div>
			</div>
		</div>
	)
}

function LoadingPlaceholder() {
	return (
		<div className='flex flex-col items-center space-y-2'>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-20 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-10 animate-pulse rounded-lg bg-accent-4' />
			</div>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-24 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-12 animate-pulse rounded-lg bg-accent-4' />
			</div>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-28 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-12 animate-pulse rounded-lg bg-accent-4' />
			</div>
		</div>
	)
}

const formatSeries = (data: Array<{ bin: number; height: number }>) => [
	{
		type: 'areaspline' as const,
		name: 'Elasticity',
		color: PALETTE.blue[75],
		data: data.map(({ bin, height }) => [bin, height]),
	},
]

function formatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	const items = self.points && self.points[0] ? self.points[0].y : 0
	return `<div class="chart-tooltip">
			${intl.get('elasticity')}:${formatNumber(this.x as number)}
			<br> ${intl.get('products_count_items')}: ${formatNumber(items as number)}
		</div>`
}

const getPlots = (
	series: SeriesAreasplineOptions[]
): XAxisPlotBandsOptions[] => {
	// eslint-disable-next-line
	const data = series?.[0]?.data?.map(([bin]: any) => bin) || []
	const min = Math.min(...data)
	const max = Math.max(...data)
	const step = (max - min) / 3
	return [
		{
			from: min,
			to: min + step,
			label: {
				text: intl.get('high'),
				align: 'left',
				style: { color: 'var(--cmp-foreground)' },
			},
			color: 'rgba(0, 181, 87, 0.05)',
		},
		{
			from: min + step,
			to: min + step * 2,
			label: {
				text: intl.get('medium'),
				align: 'left',
				style: { color: 'var(--cmp-foreground)' },
			},
			color: 'transparent',
		},
		{
			from: min + step * 2,
			to: max,
			label: {
				text: intl.get('low'),
				align: 'left',
				style: { color: 'var(--cmp-foreground)' },
			},
			color: 'rgba(244, 66, 54, 0.05)',
		},
	]
}
const getConfig = ({
	series,
}: {
	series: SeriesAreasplineOptions[]
}): Highcharts.Options => ({
	chart: {
		backgroundColor: undefined,
		height: 200,
		spacingTop: 0,
		spacingRight: 0,
		spacingBottom: 0,
		marginTop: 0,
		spacingLeft: 0,
		plotBorderWidth: 0,
		marginRight: 0,
		marginLeft: 0,
		type: 'areaspline',
	},
	xAxis: {
		title: { text: '' },
		plotBands: getPlots(series),
		//the temporary solution to hide the x-axis labels until an elasticity error calculation will be fixed
		labels: {
			enabled: false,
		},
	},
	yAxis: [
		{
			min: 0,
			title: {
				text: '',
			},
			showEmpty: false,
			labels: {
				formatter: () => '',
			},
		},
	],
	time: {
		timezone: undefined,
	},
	legend: {
		enabled: false,
	},
	tooltip: {
		animation: false,
		outside: true,
		shared: true,
		followPointer: false,
		useHTML: true,
		backgroundColor: 'transparent',
		borderRadius: 10,
		borderWidth: 0,
		shadow: false,
		formatter,
	},

	plotOptions: {
		areaspline: {
			lineWidth: 1,
			marker: {
				enabled: false,
			},
			animation: false,
		},
	},
	series,
})
