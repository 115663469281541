import { flatten } from 'lodash/fp'
import React from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import {
	FormError,
	FormGroup,
	FormHint,
	FormLabel,
	Required,
} from '@cmpkit/base'
import HelpCircleIcon from '@cmpkit/icon/lib/glyph/help-circle'
import Select, { SelectProps } from '@cmpkit/select'
import { GroupBase } from '@cmpkit/select/dist/types'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'

export default function RHFSelect<OptionType extends DataOption>({
	name,
	label,
	hint,
	rules,
	shouldUnregister,
	defaultValue,
	description,
	required,
	...other
}: UseControllerProps & {
	label?: string
	hint?: string
	description?: string
	required?: boolean
} & SelectProps<OptionType, false>) {
	const { control } = useFormContext()
	const flatOptions = flatten(
		other.options?.map((v) => {
			if ((v as GroupBase<OptionType>).options) {
				return (v as GroupBase<OptionType>).options
			} else {
				return [v] as OptionType[]
			}
		})
	) as OptionType[]
	return (
		<FormGroup>
			<FormLabel>
				{label}
				{required && <Required />}
			</FormLabel>
			<Controller
				name={name}
				rules={rules}
				shouldUnregister={shouldUnregister}
				defaultValue={defaultValue}
				control={control}
				render={({ field, fieldState: { error } }) => {
					return (
						<>
							<div className='flex items-center gap-2'>
								<Select
									name={name}
									instanceId={name}
									invalid={!!error}
									menuPortalTarget={document.body}
									menuPlacement='auto'
									value={flatOptions?.find((v) => v.value === field.value)}
									onChange={(option) => field.onChange(option?.value)}
									{...other}
								/>
								{hint && (
									<Tooltip placement='top' content={hint}>
										<HelpCircleIcon className='text-muted' />
									</Tooltip>
								)}
							</div>
							{description && (
								<FormHint className='items-center'>{description}</FormHint>
							)}
							{error && <FormError>{error.message}</FormError>}
						</>
					)
				}}
			/>
		</FormGroup>
	)
}
