import { intlFormat } from 'date-fns'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Button, LinkButton } from '@cmpkit/base'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'

import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'
import { useOptimizationIsReadyForChange } from '@/modules/core/hooks/useOptimizationIsReadyForChange'

import {
	getPricingCampaignEngine,
	globalPricingCampaignProductsLink,
	isGlobalPricingCampaign,
	loclaPricingCampaignProductsLink,
} from '../../helpers'
import { usePricingCampaignActions } from '../Campaign/usePricingCampaignActions'
import EngineBadge from '../EngineBadge'
import GlobalPcBadge from '../GlobalPcBadge'
import PricingCampaignActionsDropdown from '../PricingCampaignActionsDropdown'
import { Frequency, TimelineGrid, TimelineRow } from '../TimelineGrid'
import { PricingCampaignTableRow } from './PricingCampaignsProvider'
import { PricingCampaignsScope } from '../../types'

type TimelineViewProps = {
	innerRef: React.MutableRefObject<unknown>
	frequency: Frequency
	data: PricingCampaignTableRow[]
	scope?: PricingCampaignsScope
}
export default function TimelineView({
	frequency,
	data,
	scope,
	innerRef,
}: TimelineViewProps) {
	const isAllGlobal = !scope?.optimization_group_id
	useEffect(() => {
		analytic.logEvent('pcs timeline: view')
	}, [])
	const RowContent = useCallback(
		(props: { row: TimelineRow<PricingCampaignTableRow> }) => (
			<RowContentBase {...props} />
		),
		[]
	)
	const PaneRowContent = useCallback(
		(props: {
			row: TimelineRow<PricingCampaignTableRow>
			isAllGlobal?: boolean
		}) => <PaneRowContentBase {...props} isAllGlobal={isAllGlobal} />,
		[]
	)
	const rows: TimelineRow<PricingCampaignTableRow>[] = useMemo(
		() =>
			data.map((row) => ({
				id: row.campaign.id,
				start: row.campaign.start_date
					? new Date(row.campaign.start_date + 'Z')
					: null,
				end: row.campaign.end_date
					? new Date(row.campaign.end_date + 'Z')
					: null,
				data: row,
			})),
		[data]
	)
	return (
		<TimelineGrid<PricingCampaignTableRow>
			innerRef={innerRef}
			PaneRowContent={
				PaneRowContent as <T = PricingCampaignTableRow>({
					row,
				}: {
					row: TimelineRow<T>
				}) => React.ReactNode
			}
			RowContent={
				RowContent as <T = PricingCampaignTableRow>({
					row,
				}: {
					row: TimelineRow<T>
				}) => React.ReactNode
			}
			frequency={frequency}
			rows={rows}
		/>
	)
}

function formatPeriod({
	start,
	end,
}: {
	start?: string | null
	end?: string | null
}) {
	if (start && end) {
		return `${intlFormat(new Date(start + 'Z'))} - ${intlFormat(new Date(end + 'Z'))}`
	}
	if (start && !end) {
		return `${intl.get('app.start_date').d('Start date')} ${intlFormat(new Date(start + 'Z'))}`
	}
	if (!start && end) {
		return `${intl.get('app.end_date').d('End date')} ${intlFormat(new Date(end + 'Z'))}`
	}
	return null
}

function RowContentBase({
	row,
}: {
	row: TimelineRow<PricingCampaignTableRow>
}) {
	const { showModal } = useModalStore()
	const campaign = row.data!.campaign
	const engine = getPricingCampaignEngine(campaign)
	return (
		<>
			<div className='timeline-content-left'>
				<div className='flex space-x-1'>
					<EngineBadge size='small' engine={engine} className='mr-1'>
						{intl.get('campaigns_om_title_' + engine)}
					</EngineBadge>
					{isGlobalPricingCampaign(campaign) && <GlobalPcBadge />}
				</div>
				<div className='truncate font-semibold'>
					<LinkButton
						className='block cursor-pointer truncate text-foreground no-underline hover:opacity-70'
						onClick={() => {
							analytic.logEvent('pcs timeline: campaign name: click', {
								destination: 'right_pane',
							})
							showModal('PRICING_CAMPAIGN_MODAL', {
								pricingCampaignId: campaign.id,
							})
						}}
					>
						{campaign.name}
					</LinkButton>
				</div>
			</div>
		</>
	)
}
function PaneRowContentBase({
	row,
	isAllGlobal,
}: {
	row: TimelineRow<PricingCampaignTableRow>
	isAllGlobal: boolean
}) {
	const { showModal } = useModalStore()
	const campaign = row.data!.campaign
	const engine = getPricingCampaignEngine(campaign)
	const actions = usePricingCampaignActions({
		pricingCampaign: campaign,
		optimizationGroupId: campaign.optimization_group_id,
		scenarioId: campaign.scenario_id,
	})
	const isGlobal = isGlobalPricingCampaign(campaign)
	const isOptimizationReady = useOptimizationIsReadyForChange(
		campaign.optimization_group_id
	)
	const isReadyToChange = isGlobal || Boolean(isOptimizationReady)
	return (
		<div className='flex w-full justify-between'>
			<div className='w-full space-y-1 truncate'>
				<div className='flex'>
					<div className='flex space-x-1'>
						<EngineBadge size='small' engine={engine} className='mr-1'>
							{intl.get('campaigns_om_title_' + engine)}
						</EngineBadge>

						{isGlobalPricingCampaign(campaign) && <GlobalPcBadge />}
					</div>
					<div className='ml-auto text-xs text-muted'>
						{formatPeriod({
							start: campaign.start_date,
							end: campaign.end_date,
						})}
					</div>
				</div>
				<div className='flex font-semibold'>
					<LinkButton
						className='cursor-pointer truncate text-foreground no-underline hover:opacity-70'
						onClick={() => {
							analytic.logEvent('pcs timeline: campaign name: click', {
								destination: 'left_pane',
							})
							showModal('PRICING_CAMPAIGN_MODAL', {
								pricingCampaignId: campaign.id,
							})
						}}
					>
						{campaign.name}
					</LinkButton>
					<div className='ml-auto flex items-center'>
						<Link
							className='text-link block cursor-pointer truncate no-underline'
							onClick={() =>
								analytic.logEvent('pcs timeline: products count: click', {
									destination: 'left_pane',
								})
							}
							to={
								campaign.optimization_group_id
									? loclaPricingCampaignProductsLink(
											campaign,
											campaign.optimization_group_id
												? {
														optimization_group_id:
															campaign.optimization_group_id!,
													}
												: undefined
										)
									: globalPricingCampaignProductsLink(campaign)
							}
						>
							{formatNumber(row.data!.products_count)} (
							{`${row.data!.assortment_share}%`})
						</Link>
						{isAllGlobal ? (
							<PricingCampaignActionsDropdown
								pricingCampaign={campaign}
								actions={actions}
								isReadyToChange={isReadyToChange}
							>
								<Button
									variant='tertiary'
									className='w-xs ml-1'
									iconBefore={<DotsFilledIcon />}
								/>
							</PricingCampaignActionsDropdown>
						) : !isGlobalPricingCampaign(campaign) && actions ? (
							<PricingCampaignActionsDropdown
								pricingCampaign={campaign}
								actions={actions}
								isReadyToChange={isReadyToChange}
							>
								<Button
									variant='tertiary'
									className='w-xs ml-1'
									iconBefore={<DotsFilledIcon />}
								/>
							</PricingCampaignActionsDropdown>
						) : (
							<span className='ml-1 w-4' />
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
