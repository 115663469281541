import { useState } from 'react'

import { FilterRuleModel } from '@/generated'

import { WidgetAnnotation } from '../types'
import { getWidget, getWidgetProp } from './widgets'
import { WidgetPeriods } from './widgets/types'

type WidgetContainerProps = {
	widgetItem: WidgetAnnotation
	commonPeriods: WidgetPeriods
	commonFilters: FilterRuleModel[]
}
export default function WidgetContainer({
	widgetItem,
	commonPeriods,
	commonFilters,
}: WidgetContainerProps) {
	const { kind, params } = widgetItem
	const [localParams, setLocalParams] = useState(null)
	const widget = getWidget(kind)
	const WidgetComponent = getWidgetProp('Widget', kind)

	return (
		<WidgetComponent
			widget={widget}
			params={{ ...params, ...(localParams || {}) }}
			commonPeriods={commonPeriods}
			commonFilters={commonFilters}
			onChangeParams={setLocalParams}
		/>
	)
}
