import { omitAll } from 'lodash/fp'
import { useMemo } from 'react'

import { Placement } from '@floating-ui/react-dom'

import { FieldConfig, FieldConfigType } from '@cmpkit/query-builder/dist/types'

import { FilterField, FilterRuleModel } from '@/components/filter/types'
import { ColumnSchemaModel } from '@/generated'
import { useBiFieldsQuery, useBiFieldValuesQuery } from '@/modules/bi/queries'

import BaseFilter from '../Filter/BaseFilter'
import { useFilterFieldsConfig } from './useFilterFiledsConfig'

interface Props {
	filters: FilterRuleModel[]
	onChange(filters: FilterRuleModel[]): void
	placement?: Placement
	fields?: FilterField[]
	irremovableCount?: number
}

export default function MainDashboardFilter(props: Props) {
	const biFieldsQuery = useBiFieldsQuery<ColumnSchemaModel[]>()
	const { fields, dataChoices } = useFilterFieldsConfig({
		sanitizeFields: omitAll(['optimization_group_id', 'pos_ids']),
	})
	const extendedFieldConfig = useMemo(() => {
		return (
			biFieldsQuery.data
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				?.filter((schema) => (schema as any).is_dynamic_values)
				.reduce((acc, schema) => {
					acc[schema.name] = {
						useSelectOptions() {
							return useBiFieldValuesQuery(schema.name, {
								select: (list) =>
									list?.map((value) => ({ value, label: value })),
							})
						},
					} as FieldConfig
					return acc
				}, {} as FieldConfigType) as FieldConfigType
		)
	}, [biFieldsQuery.data])

	return (
		<BaseFilter
			{...props}
			onChange={(filters) => {
				props.onChange(
					filters.slice(props.irremovableCount || 0, filters.length)
				)
			}}
			// Exclude optimization_group (deprecated) field from the filter fields
			fields={fields.filter(
				(field) => !['optimization_group_id', 'pos_ids'].includes(field.value)
			)}
			extendedFieldConfig={extendedFieldConfig}
			options={dataChoices}
		/>
	)
}
