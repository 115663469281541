import clsx from 'clsx'
import { PencilIcon, PuzzleIcon } from 'lucide-react'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useCopyToClipboard } from 'usehooks-ts'

import { Placement } from '@floating-ui/react-dom'

import {
	Button,
	Content,
	Footer,
	Header,
	Loader,
	MenuItem,
	MenuList,
	Panel,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import { useDisclosure } from '@cmpkit/hooks'
import ConnectIcon from '@cmpkit/icon/lib/glyph/connect'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import LayersIcon from '@cmpkit/icon/lib/glyph/layers'
import MatchingsIcon from '@cmpkit/icon/lib/glyph/matchings'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import QueueIcon from '@cmpkit/icon/lib/glyph/queue'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import Popover from '@cmpkit/popover'
import { Operators } from '@cmpkit/query-builder'

import ErrorBoundary from '@/components/ErrorBoundary'
import {
	PricingCampaignModel,
	PricingCampaignSettingsModel,
	SettingsTemplateModel,
	SettingsTemplateType,
} from '@/generated'
import intl from '@/locale'
import { SettingsTemplateGenaratedShortDescription } from '@/modules/settings-templates'
import { useSettingsTemplatesQuery } from '@/modules/settings-templates/queries'
import { SettingsTemplateModelType } from '@/modules/settings-templates/types'
import analytic from '@/services/analytics'

import { isGlobalPricingCampaign } from '../helpers'
import { UsePricingCampaignActions } from './Campaign/usePricingCampaignActions'

type PricingCampaignActionsDropdownProps = {
	inline?: boolean
	placement?: Placement
	children: React.ReactElement & {
		ref?: React.Ref<HTMLElement>
	}
	pricingCampaign?: PricingCampaignModel
	actions: UsePricingCampaignActions & { handleEditName?: () => void }
	isReadyToChange: boolean
}
export default function PricingCampaignActionsDropdown({
	inline,
	placement = 'bottom-end',
	children,
	pricingCampaign,
	actions: {
		handleDelete,
		handleDublicate,
		handleCopy,
		handleReassign,
		handleSaveAsTemplate,
		handleApplyTemplate,
		handleUseTemplate,
		handleEditName,
	},
	isReadyToChange,
}: PricingCampaignActionsDropdownProps) {
	const isNew = !pricingCampaign?.id
	const isGlobal = isGlobalPricingCampaign(pricingCampaign)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [value, copy] = useCopyToClipboard()
	const dropdownContent = ({ close }: { close: () => void }) => (
		<Panel className='w-56 border border-solid border-base py-2'>
			<MenuList>
				{!!pricingCampaign?.id && (
					<MenuItem
						onClick={() => {
							copy(`${window.location.host}/p/pc?pc=${pricingCampaign.id}`)
							toast.success(
								intl.get('pc.action.link_copied').d('Link copied to clipboard')
							)
							close()
						}}
					>
						<MatchingsIcon />
						{intl.get('pc.action.copy_link').d('Copy link')}
					</MenuItem>
				)}
				{!!handleEditName && (
					<MenuItem
						onClick={() => {
							handleEditName()
							close()
						}}
					>
						<PencilIcon className='size-4' />
						{intl.get('app.edit_name').d('Edit name')}
					</MenuItem>
				)}
				{!isNew && !isGlobal && handleCopy && (
					<MenuItem
						onClick={() => {
							handleCopy(pricingCampaign.id)
							close()
						}}
					>
						<QueueIcon />
						{intl.get('pc.action.copy')}
					</MenuItem>
				)}
				{!isNew && handleDublicate && (
					<MenuItem
						onClick={() => {
							handleDublicate(pricingCampaign.id)
							close()
						}}
					>
						<LayersIcon />
						{intl.get('general_dublicate')}
					</MenuItem>
				)}
				{!isNew && !isGlobal && handleReassign && (
					<MenuItem
						onClick={() => {
							handleReassign(pricingCampaign.id)
							close()
						}}
					>
						<ConnectIcon />
						{intl.get('pc.action.reassign')}
					</MenuItem>
				)}
				{!isNew && handleSaveAsTemplate && (
					<MenuItem
						onClick={() => {
							handleSaveAsTemplate(pricingCampaign)
							close()
						}}
					>
						<PlusIcon />
						{intl.get('settings.action.save_as_template').d('Save as template')}
					</MenuItem>
				)}

				<PricingCampaignTemplatesPopover
					handleApply={(props) => {
						handleUseTemplate
							? handleUseTemplate({ settings: props.settings })
							: handleApplyTemplate({
									pcId: pricingCampaign!.id,
									templateId: props.templateId,
								})
					}}
					isDisable={!isReadyToChange}
					onMenuClose={close}
					title={
						handleUseTemplate
							? intl.get('settings.action.use_template').d('Use template')
							: intl.get('pc.action.apply_template').d('Apply template')
					}
				/>

				{!isNew && handleDelete && (
					<MenuItem
						variant='destructive'
						onClick={() => {
							handleDelete(pricingCampaign.id)
							close()
						}}
					>
						<Trash2Icon />
						{intl.get('general_delete')}
					</MenuItem>
				)}
			</MenuList>
		</Panel>
	)
	return (
		<Popover content={dropdownContent} inline={inline} placement={placement}>
			{children}
		</Popover>
	)
}

const PricingCampaignTemplatesPopover = ({
	isDisable,
	title,
	className,
	onMenuClose,
	...props
}: {
	isDisable: boolean
	handleApply(props: {
		templateId: string
		settings: PricingCampaignSettingsModel
	}): void
	onMenuClose: () => void
	title: string
	className?: string
}) => {
	const { isOpen, open, close: handleClose } = useDisclosure()
	return (
		<Popover
			placement='left'
			className={className}
			isOpen={isOpen}
			content={
				<PopoverContent
					{...props}
					close={() => {
						handleClose()
						onMenuClose()
					}}
				/>
			}
		>
			<MenuItem
				disabled={isDisable}
				className={clsx(isDisable && 'pointer-events-none')}
				onClick={() => {
					analytic.logEvent(
						'settings: pricing campaigns: dropdown: apply template'
					)
					open()
				}}
			>
				<PuzzleIcon className='size-4' />

				{title}
			</MenuItem>
		</Popover>
	)
}

type PopoverContentProps = {
	close(): void
	handleApply(props: {
		templateId: string
		settings: PricingCampaignSettingsModel
	}): void
}
const PopoverContent = ({ handleApply, close }: PopoverContentProps) => {
	const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
	const { data: templates, isLoading } = useSettingsTemplatesQuery({
		limit: 100,
		offset: 0,
		filters: [
			{
				name: 'template_type',
				operation: Operators.IN,
				value: SettingsTemplateType.PricingCampaign,
			},
		],
	})
	const onApply = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const template: any = templates?.data.find(
			({ id }) => id === selectedTemplateId
		)
		handleApply({
			templateId: template!.id,
			settings: template!.body.settings,
		})
		close()
	}
	const isSelected = (id: string) => selectedTemplateId === id
	return (
		<Panel className='w-[700px] border px-5 py-2'>
			<ErrorBoundary>
				<Header className='mb-3 bg-inherit' as={'h3'}>
					{intl
						.get('templates.title.pricing_campaign')
						.d('Pricing campaign templates')}
					<Button
						variant='tertiary'
						onClick={close}
						className='ml-auto'
						iconBefore={<CrossIcon />}
					/>
				</Header>
				<Content>
					<div className='w-full rounded-lg border'>
						<Blanket
							isTinted={isLoading}
							className='absolute flex items-center justify-center rounded-lg bg-white/30 backdrop-blur-sm dark:bg-black/30'
						>
							<Loader />
						</Blanket>
						{!isLoading && (
							<div className='max-h-[300px] overflow-auto rounded-lg'>
								{!!templates?.data?.length ? (
									templates.data?.map((template) => (
										<TemplatesSection
											template={template}
											handleClick={setSelectedTemplateId}
											active={isSelected(template.id)}
										/>
									))
								) : (
									<div className='p-5 text-center text-muted'>
										{intl
											.get('general_no_templates')
											.d('There is no templates')}
									</div>
								)}
							</div>
						)}
					</div>
				</Content>
				<Footer className='bg-inherit'>
					<Button
						variant='primary-brand'
						onClick={onApply}
						disabled={!selectedTemplateId}
						className='ml-auto mt-2'
					>
						{intl.get('general_apply')}
					</Button>
				</Footer>
			</ErrorBoundary>
		</Panel>
	)
}
const TemplatesSection = ({
	template,
	handleClick,
	active,
}: {
	template: SettingsTemplateModel
	handleClick: (id: string) => void
	active: boolean
}) => {
	return (
		<div
			className={clsx(
				'w-full border-b',
				active && 'bg-accent-3 dark:bg-black/50',
				!active && 'hover:bg-accent-2 dark:hover:bg-black/30'
			)}
			key={`${template.id}_${template.name}`}
		>
			<div className={clsx('flex items-center space-x-2 p-2')}>
				<div
					className='w-full cursor-pointer'
					onClick={() => handleClick(template.id)}
				>
					<div className='text-sm font-medium'>{template.name}</div>
					<SettingsTemplateGenaratedShortDescription
						template={
							{
								template_type: template.template_type,
								body: template.body,
							} as SettingsTemplateModelType
						}
					/>
				</div>
			</div>
		</div>
	)
}
