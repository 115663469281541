import React from 'react'

import { useTimelineGridContext } from './TimelineGridContext'
import { TimelineRow } from './types'

export default function TimelineGridLeftPane<T>({
	components: { PaneRowContent },
}: {
	components: {
		PaneRowContent: <T>({ row }: { row: TimelineRow<T> }) => React.ReactNode
	}
}) {
	const { rows, viewportHeight: height } = useTimelineGridContext<T>()
	return (
		<div className='timeline-grid-left-pane' style={{ height }}>
			<div className='sticky top-0 z-50 h-10 border-b border-solid border-base bg-accent-3' />
			{rows.map((row) => {
				return (
					<div className='timeline-grid-left-pane-row' key={row.id}>
						<PaneRowContent<T> row={row} />
					</div>
				)
			})}
		</div>
	)
}
