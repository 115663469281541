import clsx from 'clsx'
import { filter, find, prop, propOr, size } from 'lodash/fp'
import React from 'react'

import { Badge, Header, InlineMessage } from '@cmpkit/base'

import {
	AlertAnnotationModel,
	AlertLevel,
	PricingCampaignModel,
	PricingLineModel,
} from '@/generated'
import intl from '@/locale'
import { usePricingAlertsAnnotationsQuery } from '@/modules/core/queries'
import { getAlertVariant } from '@/modules/core/utils'
import { ProductEntity } from '@/modules/og-products/types'

import { usePriceManager } from '../../../../useProductPriceManeger'
import PriceManager from '../../PriceManager'
import Interpretation from './Interpretation'
import ProductAlertsList from './ProductAlertsList'

type EastsidePanelRuleInfoProps = {
	content: ProductEntity | PricingLineModel
	pricingCampaign?: PricingCampaignModel
	localData: Record<string, object>
}

export default function EastsidePanelRuleInfo({
	content,
	...props
}: EastsidePanelRuleInfoProps) {
	const { data: alerts } = usePricingAlertsAnnotationsQuery({
		select: mapWithAnnotations(getProductsMessages(content as ProductEntity)),
	})
	const priceManager = usePriceManager({ content: content as ProductEntity })
	const alertsCounts = getAletsCountsByLevels(alerts || [])
	const alertsSummary = Object.entries(alertsCounts).filter(
		(summary) => summary[1] > 0
	)
	return (
		<>
			<Header
				className={clsx(
					'sticky top-0 z-[2] flex-col border-b border-solid border-base bg-neutral-200/5 px-5 py-2 backdrop-blur-md',
					{
						'border-l-warn border-l-2 border-solid':
							alertsCounts[AlertLevel.Warning],
						'border-l-2 border-solid border-l-danger':
							alertsCounts[AlertLevel.Critical] ||
							alertsCounts[AlertLevel.Unassigned],
					}
				)}
			>
				<PriceManager {...priceManager} />
				{alertsSummary?.length > 0 && (
					<div className='mt-2 space-x-1 space-y-1'>
						{alertsSummary.map(([level, count]) => {
							return (
								<Badge key={level} variant={getAlertVariant(level)}>
									{intl.get(`price_info_${level}_count`) + ': '}
									<strong>{count}</strong>
								</Badge>
							)
						})}
					</div>
				)}
			</Header>
			<div className='mb-2 w-full space-y-2 px-5 pt-5'>
				{priceManager.finalPrice.isChanged && (
					<InlineMessage
						className='w-full'
						data-testid={'info_0'}
						variant={'brand'}
					>
						<h5 className='mb-1'>
							{intl.get('price_info_manualy_changed_title')}
						</h5>
						<p>{intl.get('price_info_manualy_changed_desc')}</p>
					</InlineMessage>
				)}
				<ProductAlertsList alerts={alerts} />
			</div>
			<Interpretation content={content as ProductEntity} {...props} />
		</>
	)
}

const countOf = (level: string, data?: AlertAnnotationModel[]) =>
	size(filter({ level }, data))

const mapWithAnnotations =
	(messages: string[]) =>
	(types: AlertAnnotationModel[]): AlertAnnotationModel[] =>
		messages
			.map((id) => find({ id }, types) as AlertAnnotationModel)
			.filter(Boolean)

const getProductsMessages = (content: ProductEntity) => [
	...(prop('fields.alerts', content) ||
		propOr([], 'custom_fields.alerts', content)),
]

const getAletsCountsByLevels = (alerts: AlertAnnotationModel[]) => ({
	[AlertLevel.Unassigned]: countOf(AlertLevel.Unassigned, alerts),
	[AlertLevel.Critical]: countOf(AlertLevel.Critical, alerts),
	[AlertLevel.Warning]: countOf(AlertLevel.Warning, alerts),
	[AlertLevel.Info]: countOf(AlertLevel.Info, alerts),
})
