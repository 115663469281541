import {
	any,
	concat,
	entries,
	filter,
	flatten,
	fromPairs,
	isEmpty,
	join,
	map,
	pipe,
	prop,
	props,
	trim,
	uniq,
} from 'lodash/fp'

import { Operators } from '@cmpkit/query-builder'

import { FilterRuleModel } from '@/components/filter/types'
import { ColumnSchemaModel } from '@/generated'

import { PricingLineModel, ProductEntity } from './types'

export const toggleSetItems = (set: string[], items: string[]) => {
	const setArr = [...set]
	const isAllSelected = !any((i) => !setArr.includes(i), items)
	return isAllSelected
		? setArr.filter((i) => !items.includes(i))
		: uniq([...setArr, ...items])
}
/**
 * Normilize group value to string
 * @param value
 * @returns value
 */
export const getGroupValue = (value: string) => {
	switch (value) {
		case 'null':
			return null
		case 'undefined':
			return undefined
		default:
			return value
	}
}
export const createLocalDataMutatationFn = (
	source: Record<string, object>,
	id: string
) => {
	const data = source[id] ?? {}
	return (property: string, originalVal: unknown, newVal: unknown) => {
		return {
			[id]: fromPairs(
				filter(
					([key, val]) => !(key === property && val == originalVal),
					entries({ ...data, [property]: newVal })
				)
			),
		}
	}
}

export const sanitizeLocalData = pipe([
	entries,
	filter(([key, val]) => key && !isEmpty(val)),
	fromPairs,
])
export const sanitizeEmptyLocalData = (source: object, newData: object) =>
	sanitizeLocalData({ ...source, ...newData })

export const updateLocalDataBy = (
	localData: Record<string, Record<string, object>>,
	product: ProductEntity,
	field: string,
	data: object
) => {
	const aggId = getAggregationRowId(product)
	if (getEntityFieldValue(product, field) == data) {
		if (Object.keys(localData?.[aggId] ?? {}).length === 1) {
			delete localData[aggId]
		} else if (localData?.[aggId]) {
			delete localData[aggId][field]
		}
	} else {
		if (!localData[aggId]) localData[aggId] = {}

		localData[aggId][field] = data
	}
	return localData
}
/**
 * Try to match filtering field value and serch text
 * @param field
 * @param text
 * @returns boolean
 */
const fieldMatched = (field: string, text: string[]) =>
	any((s) => field && field.toLowerCase?.().includes(s), text)

/**
 * Get count of matched columns by search text in terget item
 * @param columnsIds
 * @param searchText
 * @param item
 * @returns string[]
 */
const matchedFields = <T>(
	columnsIds: string[],
	searchText: string[],
	item: T
) =>
	columnsIds.filter((key) =>
		fieldMatched(getEntityFieldValue(item, key), searchText)
	)

const getSearchFilter =
	<T extends PricingLineModel>(columnsIds: string[], searchText?: string[]) =>
	(item: T): string | null => {
		if (matchedFields<T>(columnsIds, searchText || [], item).length) {
			return getAggregationRowId(item)
		} else if (item?.products?.length) {
			return any(
				(product) =>
					matchedFields<typeof product>(columnsIds, searchText || [], product)
						.length,
				item?.products
			)
				? getAggregationRowId(item)
				: null
		} else {
			return null
		}
	}

/**
 * Split string by coma and return array of strings
 * @param text
 * @returns string[]
 */
const splitText = (text: string): string[] =>
	text.toLowerCase().split(',').map(trim)

/**
 * Get only searchable columns from schema array and return their ids
 * @param schema
 * @returns string[]
 */
const searchableColumnsIds: (schema: ColumnSchemaModel[]) => string[] = pipe([
	filter({ searchable: true }),
	map(prop('name')),
	concat(['line_id', 'name']),
])

/**
 * Filter collection by search text only on searchable columns
 * @param dataList
 * @param schema
 * @param searchText
 * @returns filtered datalist
 */
export const filterByText = <T = PricingLineModel>(
	dataList: T[],
	schema: ColumnSchemaModel[],
	searchText?: string
): T[] => {
	if (!searchText) return dataList
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const filterBy = getSearchFilter<any>(
		searchableColumnsIds(schema),
		splitText(searchText)
	)
	return flatten<T>(dataList.filter(filterBy))
}

export const TABLE_KEY = 'products_1'

export const getAggregationRowId = (item: PricingLineModel | ProductEntity) =>
	item.line_id
export const getElementId = (item: ProductEntity) => item.sku
export const isAggregated = (item: PricingLineModel) => item?.products

/**
 * Allow to select filed value from nested product scheme
 * @param item Searchable entity
 * @param field field name to search
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEntityFieldValue = (item: any, field: any) =>
	item?.fields?.aggregated_fields?.[field] ??
	item?.[field] ??
	item?.fields?.[field] ??
	item?.custom_fields?.[field] ??
	null

/**
 * Check if query and value is valid to make comparison
 * @param query FilterRuleModel query
 * @param value field value
 * @returns
 */
export const isValidToCompare = (
	query: FilterRuleModel,
	value?: string | number | null
) => {
	return (
		!value &&
		value !== 0 &&
		query.operation !== Operators.IS_EMPTY &&
		query.operation !== Operators.BL &&
		query.operation !== Operators.ANY_OF
	)
}

export const getNodeId = pipe([props(['line_id']), join('__')])
export const getAssortmentItemId = pipe([props(['line_id', 'sku']), join('__')])
