import {
	entries,
	filter,
	fromPairs,
	groupBy,
	map,
	negate,
	orderBy,
	pipe,
	prop,
	propOr,
	sum,
	uniqBy,
} from 'lodash/fp'
import * as qs from 'qs'

import { Badge } from '@cmpkit/base'
import Tooltip from '@cmpkit/tooltip'

import { encodeComplexQuery } from '@/components/data-grid/helpers'
import {
	DemandPricingTacticsModel,
	MarkdownPricingTacticsModel,
	PcProductsCountsModel,
	PriceLimitRuleModel,
	PricingCampaignModel,
	PricingEngineType,
	RuleBasePricingTacticsModel,
} from '@/generated'
import intl from '@/locale'
import { FINISHED, LOCKED, NEW } from '@/modules/core/constants'
import { OptimizationModel } from '@/modules/core/types'
import { PricingCampaignsScope } from '@/modules/pricing-campaigns/types'

export function getWarnings(optimization?: OptimizationModel) {
	const warnings: string[] = []
	if (optimization?.status !== NEW) {
		if (optimization?.status === LOCKED) {
			warnings.push('optimization_is_locked')
		} else if (optimization?.status === FINISHED) {
			warnings.push('optimization_should_be_recreated')
		} else {
			warnings.push('optimization_is_not_editable')
		}
	}
	return warnings
}
export function warningsCellRenderer(
	value: string,
	{
		warnings,
	}: {
		warnings: string[]
	}
) {
	if (Number(value)) {
		return (
			<Tooltip
				content={<>{warnings.map((val) => intl.get(val).d(val)).join('; ')}</>}
				inline
			>
				<Badge variant={Number(value) > 0 ? 'warning' : 'success'}>
					{value}
				</Badge>
			</Tooltip>
		)
	}
	return <Badge variant={'success'}>0</Badge>
}

export const percentOf = (total: number, count: number): number =>
	((count || 0) / (total || 0)) * 100
export const getAssortmentShare = (total: number, count: number): number => {
	const percent = percentOf(total, count)
	if (!Number.isFinite(percent)) {
		return 0
	} else {
		return Number(percent.toFixed(0))
	}
}

/**
 * Check if limits has duplicated rules by name & on_violation
 * @param list
 * @returns
 */
export const ruleHasDuplicates = (list: PriceLimitRuleModel[]) => {
	return (
		uniqBy(({ name, on_violation }) => `${name}:${on_violation}`, list)
			?.length !== list?.length
	)
}

export const isGlobalPricingCampaign = (campaign?: PricingCampaignModel) =>
	campaign?.optimization_group_id === null

export const globalPricingCampaignProductsLink = (
	campaign: PricingCampaignModel
) => {
	const queryParams = {
		qf: encodeComplexQuery({
			filters: [
				{
					name: 'pricing_campaign_id',
					operation: 'in',
					value: [campaign.id],
				},
			],
		}),
	}
	return `/p/assortment/products${qs.stringify(queryParams, { addQueryPrefix: true })}`
}
export const loclaPricingCampaignProductsLink = (
	campaign: PricingCampaignModel,
	scope?: PricingCampaignsScope
) => {
	const queryParams = {
		pricing_campaign_id__in: campaign.id,
	}
	return `/p/og/${scope?.optimization_group_id || campaign.optimization_group_id}/products${qs.stringify(
		queryParams,
		{
			addQueryPrefix: true,
		}
	)}`
}
export const createPricingCampaignLinkForProducts = (
	campaign: PricingCampaignModel
) => {
	if (isGlobalPricingCampaign(campaign)) {
		return globalPricingCampaignProductsLink(campaign)
	} else {
		return loclaPricingCampaignProductsLink(campaign)
	}
}

export const getCountsByPc: (
	data?: PcProductsCountsModel[]
) => Record<string, number> = pipe([
	groupBy(prop('pricing_campaign_id')),
	entries,
	map(([id, data]: [string, PcProductsCountsModel[]]) => [
		id,
		sum(map(propOr(0, 'product_count'), data)),
	]),
	fromPairs,
])
export const getOgsByPc: (
	data?: PcProductsCountsModel[]
) => Record<string, string[]> = pipe([
	groupBy(prop('pricing_campaign_id')),
	entries,
	map(([id, data]: [string, PcProductsCountsModel[]]) => [
		id,
		filter(Boolean, map(prop('optimization_group_id'), data)),
	]),
	fromPairs,
])

export const getPricingCampaignEngine = (campaign: {
	settings: {
		pricing_tactics:
			| MarkdownPricingTacticsModel
			| RuleBasePricingTacticsModel
			| DemandPricingTacticsModel
	}
}): PricingEngineType => {
	return campaign.settings?.pricing_tactics?.engine as PricingEngineType
}

export const getLocaPricingCampaigns = filter<PricingCampaignModel>(
	negate(isGlobalPricingCampaign)
)
export const getGlobalPricingCampaigns = filter<PricingCampaignModel>(
	isGlobalPricingCampaign
)
export const orderCampaigns = orderBy(['order'], ['asc'])

export const getPreOrderedCampaigns = (campaigns: PricingCampaignModel[]) => {
	return [
		...orderCampaigns(getGlobalPricingCampaigns(campaigns)),
		...orderCampaigns(getLocaPricingCampaigns(campaigns)),
	].map((pc, index) => ({
		...pc,
		order: index + 1,
	})) as PricingCampaignModel[]
}
