import { isNumber } from 'lodash/fp'
import React from 'react'

import { Button } from '@cmpkit/base'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import intl from '@/locale'
import { DiffMap } from '@/tools/diff-mapper'

type Props = {
	diff: DiffMap[]
	isOpen: boolean
	apply(): void
	close(): void
}

export default function PricingCampaignConfirmationUpdateModal({
	isOpen,
	close,
	diff,
	apply,
}: Props) {
	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={close} showCloseButton size='small' zIndex={1850}>
					<ModalHeader>
						<ModalTitle>{intl.get('app.save_settings')}</ModalTitle>
					</ModalHeader>
					<ModalBody className='flex flex-col space-y-5'>
						<p>{intl.get('pc.save.settings.confirmation_text')}</p>
						<ul className='ml-3 list-disc space-y-2'>
							{diff.map(({ action, path }: DiffMap) => {
								return (
									<li key={path.join('.')}>
										{`${action}: `}
										{path
											.map((v, index) => {
												return isNumber(v)
													? `#${v + 1} ${index !== path?.length - 1 ? '->' : ''}`
													: v
											})
											.join(' ')
											.replaceAll('_', ' ')}
									</li>
								)
							})}
						</ul>
					</ModalBody>
					<ModalFooter className='justify-between'>
						<Button onClick={close}>{intl.get('general_cancel')}</Button>
						<Button
							variant='primary-brand'
							onClick={() => {
								apply()
								close()
							}}
						>
							{intl.get('general_confirm')}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}
