import { filter, map, pipe, prop, propOr, sum } from 'lodash/fp'

import { Badge } from '@cmpkit/base'

import { ProductsCountByEngineModel } from '@/generated'
import intl from '@/locale'
import { useProductsCountByEngineQuery } from '@/modules/bi/queries'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import { WidgetProps } from '../types'

const getRevenuePercent = (data: ProductsCountByEngineModel[]) => {
	const estimatedRevenue = getEstimatedRevenue(data)
	const assignedRevenue = getAssignedRevenue(data)
	if (!assignedRevenue) return 0
	if (!estimatedRevenue) return 100
	return (assignedRevenue / estimatedRevenue) * 100
}
const isAssigned: (data: ProductsCountByEngineModel) => boolean = (i) =>
	i.pricing_campaign_engine !== 'unassigned'
const getSkus: (data: ProductsCountByEngineModel[]) => number = pipe([
	filter(isAssigned),
	map(propOr(0, 'product_count')),
	sum,
])
const getAssignedRevenue: (data: ProductsCountByEngineModel[]) => number = pipe(
	[filter(isAssigned), map(propOr(0, 'revenue')), sum]
)
const getEstimatedRevenue: (data: ProductsCountByEngineModel[]) => number =
	pipe([map(propOr(0, 'revenue')), sum])

export default function NumericReadyForOptimizationWidget({
	commonFilters,
}: WidgetProps) {
	const commonQueryParams = {
		filters: [...commonFilters],
	}
	const { isLoading, data } = useProductsCountByEngineQuery<
		ProductsCountByEngineModel[]
	>(commonQueryParams, {
		select: prop('data') as (data: {
			data?: ProductsCountByEngineModel[]
		}) => ProductsCountByEngineModel[],
	})

	const skus = getSkus(data!)
	const revenue = getAssignedRevenue(data!)
	const revenuePercent = getRevenuePercent(data!)

	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				title={intl.get('widget.ready_for_opt.title')}
				tooltip={intl.get('widget.ready_for_opt.tooltip')}
				subtitle={intl.get('widget.ready_for_opt.subtitle')}
			/>
			<WidgetContent className='truncate pt-0'>
				{isLoading ? (
					<div className='col-span-2 h-10 w-32 animate-pulse rounded-lg bg-accent-3' />
				) : (
					<div className='flex flex-col space-y-1'>
						<div className='flex items-center space-x-2'>
							<span className='truncate text-xs text-muted'>
								{formatNumber(skus)} SKUs
							</span>
						</div>
						<span className='flex items-center truncate text-3xl font-semibold'>
							{formatNumber(revenue, NumberFormats.Compact)}
							<Badge size='small' className='ml-2'>
								{formatNumber(revenuePercent, NumberFormats.Percent)}
							</Badge>
						</span>
					</div>
				)}
			</WidgetContent>
		</Widget>
	)
}
