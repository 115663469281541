import { find } from 'lodash/fp'

import { TreeNodeType } from '@cmpkit/base'
import { DataChoice, Operators } from '@cmpkit/query-builder'

import { DataOption } from '@/common.types'
import intl from '@/locale'

import { localization } from './localization'
import { FilterRuleModel } from './types'

/**
 * Create function that returns human readable string from rule by fields and dataChoices
 * @example const readRule = createRuleReader({ fields, dataChoices }) // readRule(rule)
 * @param params - fields, dataChioces
 * @returns - function that returns human readable string from rule
 */
export const createRuleReader = ({
	fields,
	dataChoices,
}: {
	fields: DataChoice[]
	dataChoices?: Record<string, DataOption[] | TreeNodeType[]>
}) => {
	const getName = (rule: FilterRuleModel): string => {
		if (!rule.name) return ''
		// Try to find field by name
		const field = find({ value: rule.name }, fields)
		return field?.label || intl.get(rule.name).d(rule.name)
	}
	const getOperation = (rule: FilterRuleModel) => {
		if (!rule.name) {
			// If rule has no name, it's default rule
			return intl
				.get(`og_stoplist_default_rules_${rule.operation}`)
				.d(rule.operation)
		}
		if (rule.operation === Operators.BL) {
			return (
				'= ' +
				intl
					.get(`app.${String(Boolean(rule.value))}`)
					.d(String(Boolean(rule.value)))
			)
		}
		return (
			localization.getOperatorLabel(rule.operation) ??
			(rule.operation ? intl.get(rule.operation).d(rule.operation) : '')
		)
	}

	const getValue = (rule: FilterRuleModel) => {
		if (rule.operation === Operators.BL) {
			return `${!Boolean(rule.value) ? 'not true' : ''} `
		}
		if (
			![Operators.IS_EMPTY, Operators.IS_NOT_EMPTY].includes(
				rule.operation as Operators
			)
		) {
			if (dataChoices?.[rule.name] && Array.isArray(rule.value)) {
				return rule.value
					.map(
						(val) =>
							(find({ value: val }, dataChoices[rule.name]) as DataOption)
								?.label ?? val
					)
					.join(', ')
			} else {
				return `${rule.value}`
			}
		}
		return ''
	}
	return (rule: FilterRuleModel) =>
		`${getName(rule)} ${getOperation(rule)} ${getValue(rule)}`
}
