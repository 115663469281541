import { find } from 'lodash/fp'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router'

import { FullStory, init as initFullStory } from '@fullstory/browser'
import { SidebarInset, SidebarProvider } from '@cmpkit/sidebar'
import { Layout } from '@cmpkit/base'

import ErrorBoundary from '@/components/ErrorBoundary'
import * as config from '@/config'
import { useAuth } from '@/modules/Auth/AuthContext'
import { DrawersStack } from '@/modules/drawers'
import { ModalsStack } from '@/modules/modals'
import { getUsername } from '@/modules/Users/utils'
import SocketProvider from '@/providers/SocketProvider'
import analytic from '@/services/analytics'
import Storage from '@/services/local-storage'
import Sentry from '@/services/sentry'
import { getProject } from '@/tools/utils'
import { AppSidebar } from '../AppSidebar'

export default function Private() {
	useInitilizeThirdPartyServices()
	return (
		<ErrorBoundary>
			<SocketProvider>
				<SidebarProvider defaultOpen={false}>
					<AppSidebar />
					<SidebarInset className='overflow-hidden'>
						<Layout className='h-screen overflow-hidden bg-accent-1'>
							<ErrorBoundary>
								<Outlet />
								<ModalsStack />
								<DrawersStack />
							</ErrorBoundary>
						</Layout>
					</SidebarInset>
				</SidebarProvider>
			</SocketProvider>
		</ErrorBoundary>
	)
}
export const useInitilizeThirdPartyServices = () => {
	const { user } = useAuth()
	const projectId = getProject()
	useEffect(() => {
		if (user) {
			const theme = Storage.get('theme')
			const app = find({ name: 'pricing_platform' }, user.apps)
			const { email, id } = user
			const username = getUsername(user)
			const hostname = window.location.hostname
			Sentry.setUser({
				role: app?.role_names,
				email,
				username: getUsername(user),
				id,
			})
			if (user && !email.includes('@competera.net')) {
				initFullStory({
					orgId: config.REACT_APP_FULLSTORY_ORG_ID as string,
					debug:
						hostname.includes('staging') ||
						hostname.includes('-dev') ||
						hostname.includes('localhost') ||
						process.env.NODE_ENV === 'development',
				})
				FullStory('setProperties', {
					type: 'user',
					properties: {
						displayName: username,
						email: email,
					},
				})
			}

			analytic.setUserId(user.id)
			const identifyObj = new analytic.Identify()
			identifyObj.setOnce('userEmail', user.email)
			identifyObj.setOnce('clientName', projectId)
			identifyObj.setOnce('userFirstName', user.first_name!)
			identifyObj.setOnce('userLastName', user.last_name!)
			identifyObj.setOnce('userRole', app!.role_names!)
			identifyObj.setOnce('uiLocale', navigator.language)
			identifyObj.setOnce('uiScreenW', window.innerWidth)
			identifyObj.setOnce('uiScreenH', window.innerHeight)
			identifyObj.set('uiTheme', theme || 'light')
			analytic.identify(identifyObj)
			analytic.setGroup('projectId', projectId)
		}
	}, [user])
}
