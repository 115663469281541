import { prop } from 'lodash/fp'
import React from 'react'

import { Column } from '@cmpkit/data-table'

import intl from '@/locale'
import { formatNumber, NumberFormats } from '@/tools/locale'

export default function getColumns({ metrics }: { metrics: string[] }) {
	return [
		new Column({
			id: 'name',
			title: intl.get('app.category').d('Category'),
			locked: true,
			sortable: true,
			valueGetter: (row) => row.category?.name || 'n/a',
			renderer: (value) => {
				return <div className='flex'>{value}</div>
			},
			width: 275,
		}),
		...metrics.map((metric) => {
			return new Column({
				id: metric,
				title: intl.get(`metric.${metric}`),
				sortable: true,
				fixable: true,
				locked: false,
				renderer: (value) => {
					return (
						<div className='flex items-center justify-end font-mono'>
							{value ? formatNumber(value, NumberFormats.Percent) : '-'}
						</div>
					)
				},
				valueGetter: prop(`metrics.${metric}`),
				width: 120,
			})
		}),
	]
}
