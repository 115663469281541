import { find } from 'lodash/fp'
import { LoaderCircleIcon } from 'lucide-react'
import React from 'react'

import intl from '@/locale'
import { useAllUsersQuery } from '@/modules/Users/queries'
import { getUsername } from '@/modules/Users/utils'

export default function UsernameById({ userId }: { userId: string }) {
	const { data: users, isLoading } = useAllUsersQuery()
	const user = find({ id: userId }, users)
	return (
		<>
			{isLoading ? (
				<LoaderCircleIcon className='size-4 animate-spin text-muted' />
			) : user ? (
				getUsername(user)
			) : (
				intl.get('app.unknown').d('Unknown')
			)}
		</>
	)
}
