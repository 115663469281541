import { XAxisPlotLinesOptions } from 'highcharts'
import { flatten, isNumber, map, props, uniq } from 'lodash/fp'
import React from 'react'

import Chart from '@/components/Chart'
import intl from '@/locale'

import { LimitationRangeProps, PlotBand } from './types'

function tooltipFormatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	return `<div class="chart-tooltip">${self.points
		// eslint-disable-next-line
		?.map(({ key, point }: any) => {
			const [from, to] = (point as any)['dat'] as [number, number] // eslint-disable-line @typescript-eslint/no-explicit-any
			return `${key}: ${from ?? 'Infinity'} - ${to ?? 'Infinity'}`
		})
		.join('\n')}</div>`
}
const getRange = (data: (number | undefined)[]) => {
	const ticks = uniq(flatten(data)).filter(isNumber)
	return {
		min: Math.min(...ticks),
		max: Math.max(...ticks),
	}
}
const getConfig = ({
	data,
	plotBand,
	plotLines: lines,
	schemaFieldNames,
}: LimitationRangeProps): Highcharts.Options => {
	const getTranslated = (name: string) =>
		intl
			.get(schemaFieldNames?.[name] ?? name)
			.d(schemaFieldNames?.[name] ?? name)
	const names = data.map(({ name }) => name)
	const plotBands: PlotBand[] = []
	const plotLines: XAxisPlotLinesOptions[] = lines.map(({ name, data }) =>
		name === 'price'
			? {
					value: data,
					width: 1,
					label: {
						text: getTranslated(name),
						style: { color: 'var(--cmp-muted,rgba(20, 42, 69, 0.65))' },
					},
					dashStyle: 'Dash',
					color: 'var(--cmp-muted,rgba(20, 42, 69, 0.65))',
					zIndex: 5,
				}
			: {
					value: data,
					width: 1,
					color: 'var(--cmp-foreground)',
					label: {
						text: getTranslated(name),
						style: { color: 'var(--cmp-foreground)' },
					},
					zIndex: 5,
				}
	)
	if (plotBand.from === plotBand.to) {
		plotLines.push({
			value: plotBand.from,
			width: 1,
			color: 'rgb(68, 170, 213, .5)',
		})
	} else {
		plotBands.push({
			...plotBand,
			color: 'rgba(68, 170, 213, .2)',
		} as never)
	}
	const tickPositions = uniq(
		flatten(map(props(['from', 'to']), plotBands))
	).filter(isNumber)

	const points = uniq(
		flatten([
			...plotLines.map(({ value }) => value),
			...plotBands.map(({ from, to }) => [from, to]),
			...data.map(({ data }) => data),
		])
	).filter((i) => i != null && isNumber(i))

	const range = getRange(points)
	const series = [
		{
			type: 'xrange' as const,
			name: '',
			pointWidth: 24,
			borderRadius: 12,
			pointPadding: 0.02,
			borderWidth: 0,
			data: data.map((serie, i) => ({
				x: serie.data[0] ?? range.min - range.max,
				x2: serie.data[1] ?? range.max + range.max,
				y: i,
				color: serie.color,
				name: getTranslated(serie.name),
				dat: serie.data,
			})),
			dataLabels: {
				enabled: true,
			},
		},
	]

	return {
		chart: {
			backgroundColor: undefined,
			height: 200,
			type: 'xrange',
		},
		xAxis: {
			min: range.min * 0.9,
			max: range.max * 1.1,
			showEmpty: false,
			title: { text: '' },
			labels: {
				y: 25,
			},
			tickPositions,
			plotLines,
			plotBands,
		},
		yAxis: {
			height: 80,
			min: 0,
			title: {
				text: '',
			},
			showEmpty: false,
			labels: {
				formatter: () => '',
			},
			gridLineWidth: 0,
			categories: names,
			reversed: true,
		},
		time: {
			timezone: undefined,
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			animation: false,
			shared: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter: tooltipFormatter,
		},
		series,
	}
}

export default function ChartView(props: LimitationRangeProps) {
	return <Chart config={getConfig(props)} />
}
