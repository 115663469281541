import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
	HTTPValidationError,
	SavedFilterCreateModel,
	SavedFilters,
	SavedFilterUpdateModel,
	SetCurrentUserPreferenceRequestModel,
} from '@/generated'
import { client } from '@/network/client'

export function useCreateGlobalFilterMutation(
	options?: UseMutationOptions<
		SavedFilters.CreateGlobalFilter.ResponseBody,
		Error,
		SavedFilterCreateModel
	>
) {
	return useMutation<
		SavedFilters.CreateGlobalFilter.ResponseBody,
		Error,
		SavedFilterCreateModel
	>({
		mutationFn: (payload) => {
			return client.savedFilters.createGlobalFilter(payload)
		},
		...(options || {}),
	})
}
export function useCreatePersonalFilterMutation(
	options?: UseMutationOptions<
		SavedFilters.CreateUserFilter.ResponseBody,
		Error,
		SavedFilterCreateModel
	>
) {
	return useMutation<
		SavedFilters.CreateUserFilter.ResponseBody,
		Error,
		SavedFilterCreateModel
	>({
		mutationFn: (payload) => {
			return client.savedFilters.createUserFilter(payload)
		},
		...(options || {}),
	})
}
export function useUpdateGlobalFilterMutation(
	options?: UseMutationOptions<
		SavedFilters.UpdateGlobalFilter.ResponseBody,
		Error,
		SavedFilterUpdateModel
	>
) {
	return useMutation<
		SavedFilters.UpdateGlobalFilter.ResponseBody,
		Error,
		SavedFilterUpdateModel
	>({
		mutationFn: (payload) => {
			return client.savedFilters.updateGlobalFilter(payload.id!, payload)
		},
		...(options || {}),
	})
}
export function useUpdatePersonalFilterMutation(
	options?: UseMutationOptions<
		SavedFilters.UpdateUserFilter.ResponseBody,
		Error,
		SavedFilterUpdateModel
	>
) {
	return useMutation<
		SavedFilters.UpdateUserFilter.ResponseBody,
		Error,
		SavedFilterUpdateModel
	>({
		mutationFn: (payload) => {
			return client.savedFilters.updateUserFilter(payload.id!, payload)
		},
		...(options || {}),
	})
}
export function useDeleteGlobalFilterMutation(
	options?: UseMutationOptions<
		SavedFilters.DeleteGlobalFilter.ResponseBody,
		Error,
		string
	>
) {
	return useMutation<
		SavedFilters.DeleteGlobalFilter.ResponseBody,
		Error,
		string
	>({
		mutationFn: (id) => {
			return client.savedFilters.deleteGlobalFilter(id)
		},
		...(options || {}),
	})
}
export function useDeletePersonalFilterMutation(
	options?: UseMutationOptions<
		SavedFilters.DeleteUserFilter.ResponseBody,
		Error,
		string
	>
) {
	return useMutation<SavedFilters.DeleteUserFilter.ResponseBody, Error, string>(
		{
			mutationFn: (payload) => {
				return client.savedFilters.deleteUserFilter(payload)
			},
			...(options || {}),
		}
	)
}
export const useSaveFiltersPreferencesMutation = (
	options?: UseMutationOptions<
		void,
		AxiosError<HTTPValidationError>,
		SetCurrentUserPreferenceRequestModel
	>
) => {
	return useMutation<
		void,
		AxiosError<HTTPValidationError>,
		SetCurrentUserPreferenceRequestModel
	>({
		mutationFn: ({ preferenceType, data }) =>
			client.users.setCurrentUserPreference(preferenceType, data),
		...(options || {}),
	})
}
