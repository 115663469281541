import { any, prop } from 'lodash/fp'
import React from 'react'
import { useNavigate } from 'react-router'

import {
	Button,
	LinkButton,
	Separator,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from '@cmpkit/base'
import ClockIcon from '@cmpkit/icon/lib/glyph/clock'
import LinkIcon from '@cmpkit/icon/lib/glyph/link'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import {
	Modal,
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { dialog } from '@/components/dialogs'
import ErrorBoundary from '@/components/ErrorBoundary'
import Loader from '@/components/Loader'
import NoData from '@/components/placeholders/NoData'
import intl from '@/locale'
import { useDeleteScheduleMutation } from '@/modules/core/mutations'
import {
	useOptimizationGroupsQuery,
	useSchedulesQuery,
} from '@/modules/core/queries'
import { ScheduleModel } from '@/modules/core/types'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'

import { formatSchedule } from './utils'

interface SchedulesListProps {
	close(): void
	isOpen: boolean
}

export default function SchedulesList({ close, isOpen }: SchedulesListProps) {
	const navigate = useNavigate()
	const { showModal } = useModalStore()
	const deleteSchedule = useDeleteScheduleMutation()
	const optimizationGroups = useOptimizationGroupsQuery()
	const schedules = useSchedulesQuery()

	const isLoading = any(prop('isLoading'), [optimizationGroups, schedules])
	const closeModal = () => close()
	const handleDeleteClick = async ({
		name,
		id,
	}: {
		name: string
		id: string
	}) => {
		const answer = await dialog.confirmDelete({
			title: intl.get('general_detete_confirm_title'),
			text: intl.get('general_detete_confirm_subtitle'),
			okText: intl.get('general_delete'),
		})
		if (answer) {
			analytic.logEvent('autorun: Delete schedule', {
				'configuration name': name,
			})
			await deleteSchedule.mutateAsync(id)
			closeModal()
		}
	}
	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={closeModal} showCloseButton size='large'>
					<ErrorBoundary>
						<ModalHeader>
							<ModalTitle>{intl.get('schedule_configurations')}</ModalTitle>
						</ModalHeader>
						<ModalBody className='min-h-96'>
							{isLoading && (
								<Loader size={30} backdrop opacity className='overlay' />
							)}
							<Table className='border-separate border-spacing-0 overflow-hidden'>
								<TableBody>
									{schedules.data?.length ? (
										schedules.data?.map((schedule: ScheduleModel) => {
											const handleNavigate = () =>
												showModal('OPTIMIZATION_SCHEDULE_MODAL', {
													id: schedule.id,
												})
											return (
												<TableRow key={schedule.id} className='h-12'>
													<TableCell className='flex items-center gap-3 px-3 py-2'>
														<ClockIcon className='size-5 text-muted' />
														<div>
															<div className='font-medium'>
																<LinkButton onClick={handleNavigate}>
																	{schedule.name}
																</LinkButton>
															</div>
															<div className='inline-flex h-full items-center gap-2 text-xs text-muted'>
																<span>
																	{schedule.optimization_groups?.length} OGs
																</span>
																<Separator
																	orientation='vertical'
																	className='h-4'
																/>
																<span>{formatSchedule(schedule)}</span>
															</div>
														</div>
													</TableCell>
													<TableCell className='px-3 py-2 text-right'>
														<Button
															variant='tertiary'
															iconBefore={<LinkIcon />}
															onClick={(e) => {
																e.stopPropagation()
																navigate(
																	`?schedule.id__array_any_of=${schedule.id}`
																)
																closeModal()
															}}
														/>
														<Button
															variant='tertiary'
															onClick={(e) => {
																e.stopPropagation()
																handleDeleteClick(schedule)
															}}
															iconBefore={<Trash2Icon />}
														/>
													</TableCell>
												</TableRow>
											)
										})
									) : (
										<NoData />
									)}
								</TableBody>
							</Table>
						</ModalBody>
					</ErrorBoundary>
				</Modal>
			)}
		</ModalTransition>
	)
}
