import React from 'react'

import { Checkbox, Col, InlineMessage, Row } from '@cmpkit/base'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'

import intl from '@/locale'

type SettingsTree = {
	onChange(d: string[]): void
	value: string[]
	settingsKeys: string[]
}

export default function SettingsTree({
	onChange,
	value,
	settingsKeys,
}: SettingsTree) {
	const selected = value
	return (
		<Row>
			<Col>
				<div className='h-64 space-y-2 overflow-y-scroll rounded-lg border bg-accent-2 p-2'>
					{settingsKeys.map((settingKey) => {
						const checked = selected.includes(settingKey)
						return (
							<div key={settingKey} className='px-2'>
								<label className='flex items-center space-x-2'>
									<Checkbox
										checked={checked}
										data-testid='copy-og-settings-modal-tree-checkbox'
										value={settingKey}
										onChange={() =>
											onChange(
												checked
													? selected.filter((i) => i != settingKey)
													: [...selected, settingKey]
											)
										}
									/>
									<span className='select-none font-medium'>
										{intl.get(`setting_${settingKey}`)}
									</span>
								</label>
							</div>
						)
					})}
				</div>
			</Col>
			<Col>
				<InlineMessage variant='brand'>
					<div className='mb-2.5 flex items-center'>
						<InfoIcon className='mr-2.5 fill-brand' />
						<h4 className='m-0'>{intl.get('general_how_its_works')}</h4>
					</div>
					<p
						dangerouslySetInnerHTML={{
							__html: intl.get('cp_og_settings_select_params_info'),
						}}
					/>
				</InlineMessage>
			</Col>
		</Row>
	)
}
