import clsx from 'clsx'
import { filter, isNumber, max, values } from 'lodash/fp'
import { useState } from 'react'

import {
	DeltaValue,
	ListGroup,
	ListGroupContent,
	ListGroupEnd,
	ListGroupInner,
	ListGroupItem,
	ListGroupStart,
} from '@cmpkit/base'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'

import ProgressRow from '@/components/Progress/ProgressRow'
import { MetricsDataUnit } from '@/generated'
import intl from '@/locale'

import { MetricDataType } from '../../constants'
import {
	formatAbsoluteValue,
	formatTotalDiffValue,
	getTranslationKey,
} from './helpers'

export const MetricRow = ({ item }: { item: FormattedMetric }) => {
	const [isOpen, setIsOpen] = useState(false)
	const ArrowIcon = isOpen ? CaretDownIcon : CaretRightIcon
	const maxValue = max(filter(isNumber, values(item.values))) || 0
	const translationKey = getTranslationKey(item.key, item.widgetType)
	const trimmedDescription = item.description.substring(0, 30)
	return (
		<ListGroup className='fade-in relative overflow-hidden'>
			<ListGroupItem className={clsx('relative p-0')}>
				<ListGroupInner>
					<ListGroupStart
						className='cursor-pointer'
						onClick={() => setIsOpen(!isOpen)}
					>
						<ArrowIcon
							width={18}
							height={18}
							className='flex items-center fill-gray-400 dark:fill-white'
						/>
					</ListGroupStart>
					<ListGroupContent className='text-xs font-medium'>
						{intl.get(translationKey).d(trimmedDescription)}
					</ListGroupContent>
					<ListGroupEnd>
						<DeltaValue className='text-xs font-medium' value={item.diff.value}>
							{formatTotalDiffValue(item.diff.value, item.diff.type)}
						</DeltaValue>
					</ListGroupEnd>
				</ListGroupInner>
			</ListGroupItem>
			{isOpen && (
				<>
					<ListGroupItem className='relative mt-2 p-0 pl-5'>
						<ListGroupInner>
							<ProgressRow
								disableTooltip
								barClassName={'bg-gray-50'}
								label={intl.get('status_panel_current')}
								relativeValue={((item.values['init'] || 0) / maxValue) * 100}
								absoluteValue={formatAbsoluteValue(
									item.values['init'] || 0,
									item.data.type
								)}
							/>
						</ListGroupInner>
					</ListGroupItem>
					<ListGroupItem className='relative mt-2 p-0 pl-5'>
						<ListGroupInner>
							<ProgressRow
								disableTooltip
								barClassName={'bg-brand-50'}
								label={intl.get('status_panel_optimized')}
								relativeValue={((item.values['optimal'] || 0) / maxValue) * 100}
								absoluteValue={formatAbsoluteValue(
									item.values['optimal'] || 0,
									item.data.type
								)}
							/>
						</ListGroupInner>
					</ListGroupItem>
					<ListGroupItem className='relative mt-2 p-0 pl-5'>
						<ListGroupInner>
							<ProgressRow
								disableTooltip
								label={'Final'}
								relativeValue={((item.values['final'] || 0) / maxValue) * 100}
								absoluteValue={formatAbsoluteValue(
									item.values['final'] || 0,
									item.data.type
								)}
							/>
						</ListGroupInner>
					</ListGroupItem>
				</>
			)}
		</ListGroup>
	)
}

export type FormattedMetric = {
	widgetType?: string
	key: string
	data: {
		type: MetricDataType
		values: MetricsDataUnit
	}
	diff: {
		type: MetricDataType
		value: number
	}
	values: MetricsDataUnit
	description: string
}
