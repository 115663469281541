import { Operators } from '@cmpkit/query-builder'

import intl from '@/locale'

import { ControllerConfig, OperationOption, ValueState } from '../../types'
import FieldController from '../Controller'

export default class TextController extends FieldController {
	constructor(config: ControllerConfig) {
		super(config)
		this.note = config.note
		this.validate = config.validate || this.defaultValidate
	}

	note?: string

	formatLabel = () => {
		return this.label
	}

	hasValue = ({ operation, value }: ValueState) => {
		if (
			[Operators.IS_EMPTY, Operators.IS_NOT_EMPTY].includes(
				operation as Operators
			)
		) {
			return true
		}

		return Boolean(typeof value === 'string' && value.length)
	}

	getInitialValue = () => ({
		operation: Operators.CONTAINS,
		value: '',
	})

	getFilterTypes = (): OperationOption[] => [
		{
			operation: Operators.CONTAINS,
			label: intl.get('contains.long').d('contains'),
			hasInput: true,
		},
		{
			operation: Operators.NOT_CONTAINS,
			label: intl.get('not_contains.long').d('does not contain'),
			hasInput: true,
		},
		{
			operation: Operators.IS,
			label: intl.get('is.long').d('is'),
			hasInput: true,
		},
		{
			operation: Operators.IS_EMPTY,
			label: intl.get('is_empty.long').d('is empty'),
		},
		{
			operation: Operators.IS_NOT_EMPTY,
			label: intl.get('is_not_empty.long').d('is not empty'),
		},
	]

	defaultValidate = ({ operation, value }: ValueState) => {
		if (
			[Operators.IS_EMPTY, Operators.IS_NOT_EMPTY].includes(
				operation as Operators
			)
		) {
			return null
		}
		if (!value || !value.length) {
			return intl.get('validation.provide_text').d('Please provide some text.')
		}

		return null
	}
}
