import { orderBy } from 'lodash/fp'
import { useMemo } from 'react'

import { ActionMeta, MultiValue } from '@cmpkit/select'

import intl from '@/locale'

import { OptionType, SelectMeta } from '../types'
import { ClearOption } from './common'
import { BaseSelect, selectComponents } from './Select'

type FilterManagerProps = {
	options: OptionType[]
	onChange(value: OptionType[], meta: SelectMeta): void
	value: OptionType[]
}

const CLEAR_DATA: OptionType = {
	value: '__remove-all',
	label: intl.get('app.remove_all_filters').d('Remove all filters'),
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Option = (props: any) =>
	props.data === CLEAR_DATA ? (
		<ClearOption {...props} />
	) : (
		<selectComponents.Option {...props} />
	)

const components = { ...selectComponents, Option }
export function FilterManager(props: FilterManagerProps) {
	const options = useMemo(
		() => getOptions(props.value, props.options),
		[props.value, props.options]
	)
	const filterOptionFn = useMemo(
		() => filterOptions(props.value),
		[props.value]
	)
	const handleChange = (
		value: MultiValue<OptionType>,
		meta: ActionMeta<OptionType>
	) => {
		const { onChange } = props
		if (value && Array.isArray(value) && value.includes(CLEAR_DATA)) {
			onChange(props.value, { action: 'clear-options' })
		} else {
			onChange(value as OptionType[], meta)
		}
	}

	return (
		<BaseSelect
			components={components}
			filterOption={filterOptionFn}
			placeholder={intl.get('general_search').d('Search')}
			{...props}
			onChange={handleChange}
			options={options}
			closeMenuOnSelect
		/>
	)
}

// ==============================
// Helper Utilities
// ==============================

const lcase = (str: string) => str.toLowerCase()
const trim = (str: string) => str.replace(/^\s+|\s+$/g, '')
const stringify = (option: OptionType) => `${option.label} ${option.value}`

// NOTE: determine which options should be visible to the user
// - reimplements react-select's input matching
// - checks (and hides) if the option already exists "above the fold"
const filterOptions =
	(storedValue: OptionType[]) =>
	(option: OptionType & { data: OptionType }, rawInput: string): boolean => {
		const { data, value } = option
		const notCurrentlySelected =
			!storedValue || !storedValue.some((o: OptionType) => o.value === value)

		if (rawInput) {
			const input = lcase(trim(rawInput))
			const candidate = lcase(trim(stringify(option)))
			const isMatch = candidate.includes(input)

			return isMatch && !data.aboveTheFold
		}

		return Boolean(notCurrentlySelected || data.aboveTheFold)
	}

// NOTE: prepends the options array with all the currently selected options
// - selected options are marked with an `aboveTheFold` data key
// - also adds a special "clear" option
const getOptions = (
	current: OptionType[],
	resolved: OptionType[]
): OptionType[] => {
	if (!current || !current.length) return resolved

	return current
		.map((o) => ({ ...o, aboveTheFold: true }) as OptionType)
		.concat([CLEAR_DATA])
		.concat(orderBy<OptionType>(['label'], ['asc'], resolved))
}
