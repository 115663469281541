import { find } from 'lodash/fp'
import { useCallback, useState } from 'react'
import ReactGridLayout from 'react-grid-layout'

import { ai, main } from './__mock__'
import { DashboardWidgetEntity } from './types'

const dashboards: {
	[key: string]: DashboardWidgetEntity[]
} = { main, ai }

export interface UseDashboardWidgets {
	widgets: DashboardWidgetEntity[]
	onLayoutChange(section: string, layout: ReactGridLayout.Layout[]): void
	onWidgetAdd(widget: DashboardWidgetEntity): void
	onWidgetRemove(widgetId: string): void
}
export function useDashboardWidgets(
	dashboardType: string
): UseDashboardWidgets {
	const [widgets, setWidgets] = useState<DashboardWidgetEntity[]>(
		dashboards?.[dashboardType] || []
	)
	const onLayoutChange = useCallback(
		(section: string, layout: ReactGridLayout.Layout[]) => {
			setWidgets((widgets) => {
				return widgets.map((dashboardWidget) => {
					if (dashboardWidget.widget.config.section !== section) {
						return dashboardWidget
					}
					const newLayoutValue = find(
						{
							i: `${dashboardWidget.widget.kind}_${dashboardWidget.widget.id}`,
						},
						layout
					)
					return {
						...dashboardWidget,
						layout: newLayoutValue!,
					}
				})
			})
		},
		[]
	)
	const onWidgetAdd = useCallback((widget: DashboardWidgetEntity) => {
		setWidgets((widgets) => [...widgets, widget])
	}, [])
	const onWidgetRemove = useCallback((widgetId: string) => {
		setWidgets((widgets) =>
			widgets.filter(
				(dashboardWidget) => dashboardWidget.widget.id !== widgetId
			)
		)
	}, [])
	return {
		widgets,
		onLayoutChange,
		onWidgetAdd,
		onWidgetRemove,
	}
}
