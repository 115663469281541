import clsx from 'clsx'
import React from 'react'

type LabeledValueProps = {
	label: string
	children: React.ReactNode
	className?: string
}
export default function LabeledValue({
	label,
	children,
	className,
}: LabeledValueProps) {
	return (
		<div className={clsx('flex flex-col items-start', className)}>
			<div className='text-xs text-muted'>{label}</div>
			<div className='leading-6'>{children}</div>
		</div>
	)
}
