import React, { useEffect, useMemo, useState } from 'react'

import type registry from './registry'

export type Registry = typeof registry
export type DrawerType = keyof Registry
export type DrawerProps<K extends DrawerType> = Omit<
	React.ComponentProps<Registry[K]>,
	'isOpen' | 'close'
>
export type DrawerEntity = {
	id: DrawerType
	props?: DrawerProps<DrawerType>
	visible: boolean
}

interface DrawersManagerState {
	closeDrawer(modalType: DrawerType): void
	openDrawer<K extends DrawerType>(
		drawerType: K,
		drawerProps?: DrawerProps<K>
	): void
}

const DRAWERS_LIMIT = 20

export enum ActionType {
	SHOW_DRAWER,
	UPDATE_DRAWER,
	UPSERT_DRAWER,
	DISMISS_DRAWER,
	REMOVE_DRAWER,
}

type Action =
	| {
			type: ActionType.SHOW_DRAWER
			drawer: DrawerEntity
	  }
	| {
			type: ActionType.UPSERT_DRAWER
			drawer: DrawerEntity
	  }
	| {
			type: ActionType.UPDATE_DRAWER
			drawer: Partial<DrawerEntity>
	  }
	| {
			type: ActionType.DISMISS_DRAWER
			drawerId?: DrawerType
	  }
	| {
			type: ActionType.REMOVE_DRAWER
			drawerId?: DrawerType
	  }

interface State {
	drawers: DrawerEntity[]
}
const drawerTimeouts = new Map<
	DrawerEntity['id'],
	ReturnType<typeof setTimeout>
>()
const addToRemoveQueue = (drawerId: DrawerType) => {
	if (drawerTimeouts.has(drawerId)) {
		return
	}

	const timeout = setTimeout(() => {
		drawerTimeouts.delete(drawerId)
		dispatch({
			type: ActionType.REMOVE_DRAWER,
			drawerId,
		})
	}, 300)

	drawerTimeouts.set(drawerId, timeout)
}

const clearFromRemoveQueue = (drawerId: DrawerType) => {
	const timeout = drawerTimeouts.get(drawerId)
	if (timeout) {
		clearTimeout(timeout)
	}
}

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case ActionType.SHOW_DRAWER:
			return {
				...state,
				drawers: [action.drawer, ...state.drawers].slice(0, DRAWERS_LIMIT),
			}

		case ActionType.UPDATE_DRAWER: {
			if (action.drawer.id) {
				clearFromRemoveQueue(action.drawer.id)
			}
			return {
				...state,
				drawers: state.drawers.map((t) =>
					t.id === action.drawer.id ? { ...t, ...action.drawer } : t
				),
			}
		}

		case ActionType.UPSERT_DRAWER: {
			const { drawer } = action
			return state.drawers.find((t) => t.id === drawer.id)
				? reducer(state, { type: ActionType.UPDATE_DRAWER, drawer })
				: reducer(state, { type: ActionType.SHOW_DRAWER, drawer })
		}

		case ActionType.DISMISS_DRAWER: {
			const { drawerId } = action
			if (drawerId) {
				addToRemoveQueue(drawerId)
			} else {
				state.drawers.forEach((drawer) => {
					addToRemoveQueue(drawer.id)
				})
			}
			return {
				...state,
				drawers: state.drawers.map((t) =>
					t.id === drawerId || drawerId === undefined
						? {
								...t,
								visible: false,
							}
						: t
				),
			}
		}
		case ActionType.REMOVE_DRAWER: {
			if (action.drawerId === undefined) {
				return {
					...state,
					drawers: [],
				}
			}
			return {
				...state,
				drawers: state.drawers.filter((t) => t.id !== action.drawerId),
			}
		}
	}
}

const listeners: Array<(state: State) => void> = []

let memoryState: State = { drawers: [] }

export const dispatch = (action: Action) => {
	memoryState = reducer(memoryState, action)
	listeners.forEach((listener) => {
		listener(memoryState)
	})
}

export const useStore = (): State => {
	const [state, setState] = useState<State>(memoryState)
	useEffect(() => {
		listeners.push(setState)
		return () => {
			const index = listeners.indexOf(setState)
			if (index > -1) {
				listeners.splice(index, 1)
			}
		}
	}, [state])

	return {
		...state,
		drawers: state.drawers,
	}
}

const manager: DrawersManagerState = {
	openDrawer: (id, props) =>
		dispatch({
			type: ActionType.UPSERT_DRAWER,
			drawer: {
				id,
				props,
				visible: true,
			},
		}),
	closeDrawer: (drawerId: DrawerType) =>
		dispatch({
			type: ActionType.DISMISS_DRAWER,
			drawerId,
		}),
}
export const useDrawersStore = () => {
	return manager
}

export function useDrawer<K extends DrawerType>(
	drawerId: K
): {
	id: K
	isOpen: boolean
	props: DrawerProps<K> | undefined
	open(props: DrawerProps<K>): void
	close(): void
} {
	const store = useStore()
	const state = useMemo(() => {
		const drawer = store.drawers?.find((drawer) => drawer.id === drawerId)
		return {
			id: drawerId,
			isOpen: !!drawer?.visible,
			props: drawer?.props as DrawerProps<K>,
		}
	}, [store.drawers])
	return {
		...state,
		open: (props: DrawerProps<K>) => {
			manager.openDrawer(drawerId, props)
		},
		close: () => {
			manager.closeDrawer(drawerId)
		},
	}
}
