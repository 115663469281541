import { any, map } from 'lodash/fp'
import React from 'react'
import { Link } from 'react-router-dom'

import { InlineMessage } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'

import { DataOption } from '@/common.types'
import { toAdaptedSchema } from '@/components/filter/adapter'
import { createRuleReader } from '@/components/filter/helpers'
import { FilterField, FilterRuleModel } from '@/components/filter/types'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import {
	useBrandsQuery,
	useCategoriesQuery,
	useColumnsSchemaQuery,
} from '@/modules/core/queries'

type LocksProps = {
	stop_list_rules: FilterRuleModel[]
}
export function Locks({ stop_list_rules: rules }: LocksProps) {
	const optimizationGroupId = useOptimizationGroupId()
	const { data: fields } = useColumnsSchemaQuery<FilterField[]>({
		select: map(toAdaptedSchema),
	})
	const { data: brands } = useBrandsQuery(
		optimizationGroupId ? { optimization_group_id: optimizationGroupId } : {}
	)
	const { data: categories } = useCategoriesQuery(
		optimizationGroupId ? { optimization_group_id: optimizationGroupId } : {}
	)
	const category_ids = categories?.map((c) => ({ value: c.id, label: c.name }))
	const dataChoices = { category_ids, brands }
	const humanizedRules = rules.map(
		createRuleReader({
			fields: fields || [],
			dataChoices: dataChoices as Record<string, DataOption[]>,
		})
	)
	const isForceLocked = any({ name: 'sku', operation: Operators.IS }, rules)
	return (
		<InlineMessage variant='neutral' className='w-full'>
			<h5 className='mb-0.5'>{intl.get('price_notif_is_locked_title')}</h5>
			{isForceLocked ? (
				intl.get('price_notif_is_locked_desc')
			) : (
				<p>
					{intl.get('price_notif_is_locked_desc_by_stop_list')}{' '}
					<Link to={`/p/og/${optimizationGroupId}/settings`}>
						{intl.get('stop_list')}
					</Link>
					<ul className='mb-0 ml-5 mt-2 list-disc'>
						{humanizedRules.map((rule, i) => (
							<li key={i}>{rule}</li>
						))}
					</ul>
				</p>
			)}
		</InlineMessage>
	)
}
export default Locks
