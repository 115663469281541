import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'

import { useCoreStore } from '../../store'

export default function OptimizationGroupLayout() {
	const { optimizationGroupId } = useParams()
	const queryClient = useQueryClient()
	const coreStore = useCoreStore()
	useEffect(() => {
		coreStore.setOptimizationGroupId(optimizationGroupId!)
		return () => {
			coreStore.setOptimizationGroupId(null)
			queryClient.removeQueries({
				queryKey: ['products', optimizationGroupId],
			})
		}
	}, [optimizationGroupId])
	return coreStore.optimizationGroupId ? <Outlet /> : null
}
