import { useMemo } from 'react'

import {
	getCoreRowModel,
	getExpandedRowModel,
	getGroupedRowModel,
	getSortedRowModel,
	RowData,
} from '@tanstack/react-table'

import { en } from '../localization'
import { MDefinedTableOptions, MTableOptions } from '../types'

export const MRT_DefaultColumn = {
	filterVariant: 'text',
	maxSize: 1000,
	minSize: 40,
	size: 180,
} as const

export const MRT_DefaultDisplayColumn = {
	columnDefType: 'display',
	enableClickToCopy: false,
	enableColumnActions: false,
	enableColumnDragging: false,
	enableColumnFilter: false,
	enableColumnOrdering: false,
	enableDescriptionTooltip: true,
	enableEditing: false,
	enableGlobalFilter: false,
	enableGrouping: false,
	enableHiding: false,
	enableResizing: false,
	enableSorting: false,
} as const

export const useMasterTableOptions: <TData extends RowData>(
	tableOptions: MTableOptions<TData>
) => MDefinedTableOptions<TData> = <TData extends RowData>({
	layoutMode,
	enableTableFooter,
	enableTableHeader = true,
	enableVirtualization,
	enableExpanding,
	enableGrouping,
	manualGrouping,
	enableSorting,
	enableDescriptionTooltip = true,
	enableHeaderTooltip = true,
	enableColumnActions = true,
	manualSorting,
	localization,
	...rest
}: MTableOptions<TData>) => {
	localization = useMemo(
		() => ({
			...en,
			...localization,
		}),
		[localization]
	)
	return {
		enableExpanding,
		enableGrouping,
		enableVirtualization,
		enableTableFooter,
		enableTableHeader,
		enableSorting,
		enableDescriptionTooltip,
		enableHeaderTooltip,
		enableColumnActions,
		localization,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel:
			enableExpanding || enableGrouping ? getExpandedRowModel() : undefined,
		getGroupedRowModel:
			enableGrouping && !manualGrouping ? getGroupedRowModel() : undefined,
		getSortedRowModel:
			enableSorting && !manualSorting ? getSortedRowModel() : undefined,
		layoutMode,
		...rest,
	} as MDefinedTableOptions<TData>
}
