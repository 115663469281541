import { cva } from 'class-variance-authority'
import { omitAll, prop } from 'lodash/fp'
import React, { Fragment, memo, MouseEvent, useContext } from 'react'
import ContentLoader from 'react-content-loader'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router'

import { Button, Separator, Spinner, Tag } from '@cmpkit/base'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import { Operators } from '@cmpkit/query-builder'

import { dialog } from '@/components/dialogs'
import intl from '@/locale'
import { useTableConfigStore } from '@/modules/global-assortment/store'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'
import { getLocationQuery, toQueryString } from '@/tools/location'
import { errorMessage } from '@/tools/message'

import { useBulkOptimizationMutation } from '../../mutations'
import {
	OptimizationAction,
	OptimizationGroupTableRow,
	OptimizationStatus,
} from '../../types'
import {
	AssociatedStatus,
	canMakeActionOnList,
	groupOptimizationsByStatus,
	hasExportedOptimization,
} from '../../utils'
import ExportDialogActions from '../SaveExportedPricesDialogActions'
import { OptimizationGroupsContext } from './OptimizationGroupsProvider'

export default function OptimizationGroupsStatusesManager() {
	const location = useLocation()
	const navigate = useNavigate()
	const { showModal } = useModalStore()
	const { columnsConfig } = useTableConfigStore()
	const { data, dataList, selected, setSelected, updatingCount } = useContext(
		OptimizationGroupsContext
	)
	const isSelected = (id: string) => selected.includes(id)

	/**
	 * Mutations
	 */
	const bulkAction = useBulkOptimizationMutation()

	const handleSetExpandedStatus = (status?: string) => {
		analytic.logEvent(`mass repricing: filter: ${status || 'all'}`)
		status
			? navigate(
					`/p/og${toQueryString(omitAll(['schedule__is_not_empty'], { ...query, ui_status__in: status }))}`
				)
			: navigate('/p/og')
	}
	const handleOptimizationBulkAction = async (
		items: OptimizationGroupTableRow[],
		action: OptimizationAction
	) => {
		try {
			if (
				action === OptimizationAction.Lock &&
				hasExportedOptimization(items)
			) {
				const answer = await askToApplyOptimization()
				if (!answer) return
			}
			if (action === OptimizationAction.Unlock) {
				const answer = await askToRemoveExportedPrices()
				if (!answer || answer.action === 'close') return
				if (answer.action === 'ok' && !answer?.state?.save) {
					await bulkAction.mutateAsync(
						items.map(({ group, optimization }) => ({
							optimization_group_id: group.id,
							optimization_id: optimization.id,
							action: OptimizationAction.DeleteExport,
						}))
					)
				}
			}
			setSelected([])
			analytic.logEvent(getAnalyticEventName(expandedStatus), {
				'Mass action by': selected.length > 0 ? 'Selected' : 'All',
			})
			await bulkAction.mutateAsync(
				items.map(({ group, optimization }) => ({
					optimization_group_id: group.id,
					optimization_id: optimization.id,
					action,
				}))
			)
		} catch (err) {
			toast.error(errorMessage(), {
				duration: 3000,
			})
		}
	}
	const handleOpenExportModal = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		showModal('EXPORT_ASSORTMENT', {
			defaultName: 'Multi OG Export',
			columnsConfigs: columnsConfig,
			context: {
				filters: [
					{
						name: 'optimization_group_id',
						operation: Operators.IN,
						value: dataList
							.filter(
								({ optimization, group }) =>
									optimization.status === OptimizationStatus.Locked &&
									(selected.length > 0 ? isSelected(group.id) : true)
							)
							.map(getOptGroupId),
					},
				],
			},
		})
	}
	const handleShowOnlyUpdating = () =>
		navigate(
			`/p/og${toQueryString({ ui_status__in: OptimizationStatus.Updating })}`
		)

	const query = getLocationQuery(location.search)
	const expandedStatus = prop('ui_status__in', query) as string | undefined
	const filteredBySchedule = prop('schedule.id__is_not_empty', query) as
		| string
		| undefined
	const totalCount = data.length

	const optimizationsByStatus = groupOptimizationsByStatus(data)
	const isAllButtonsDisabled = updatingCount > 0 && selected.length == 0

	const getActions = (type: string, items: OptimizationGroupTableRow[]) => {
		const isDisabled =
			bulkAction.isPending ||
			isAllButtonsDisabled ||
			!canMakeActionOnList(items)
		const prefix = bulkAction.isPending && (
			<Spinner variant='invert' className='mr-2.5' />
		)
		const postfix =
			!bulkAction.isPending &&
			(selected.length > 0 ? `(${items.length})` : intl.get('general_all'))
		const createOnClickAction =
			(items: OptimizationGroupTableRow[], action: OptimizationAction) =>
			(e: MouseEvent<HTMLButtonElement>) => {
				e.stopPropagation()
				handleOptimizationBulkAction(items, action)
			}
		switch (type) {
			case OptimizationStatus.New:
				return [
					<Button
						key='start'
						variant='primary-brand'
						disabled={isDisabled}
						className='relative ml-2 mr-1 flex items-center'
						onClick={createOnClickAction(items, OptimizationAction.Start)}
					>
						{prefix}
						{intl.get('optimization_groups_status_btn_start')} {postfix}
					</Button>,
				]
			case OptimizationStatus.Pending:
				return [
					<Button
						key='start'
						variant='primary-brand'
						disabled={isDisabled}
						className='relative ml-2 mr-1 flex items-center'
						onClick={createOnClickAction(items, OptimizationAction.Update)}
					>
						{prefix}
						Update {postfix}
					</Button>,
				]
			case OptimizationStatus.Started:
				return [
					<Button
						key='stop'
						variant='primary-warning'
						className='relative ml-2 mr-1 flex items-center text-white'
						onClick={createOnClickAction(items, OptimizationAction.Stop)}
					>
						{prefix}
						{intl.get('optimization_groups_status_btn_stop')} {postfix}
					</Button>,
				]
			case OptimizationStatus.Failed:
				return [
					<Button
						key='restart'
						disabled={isDisabled}
						className='relative ml-2 mr-1 flex items-center'
						onClick={createOnClickAction(items, OptimizationAction.Start)}
					>
						{prefix}
						{intl.get('optimization_groups_status_btn_restart')} {postfix}
					</Button>,
				]
			case OptimizationStatus.Finished:
				return [
					<Button
						key='apply'
						variant='primary-brand'
						disabled={isDisabled}
						className='relative ml-2 mr-1 flex items-center'
						onClick={createOnClickAction(items, OptimizationAction.Lock)}
					>
						{prefix}
						{intl.get('optimization_groups_status_btn_apply')} {postfix}
					</Button>,
				]
			case OptimizationStatus.Locked:
				return [
					<Button
						key='export'
						variant='primary-brand'
						disabled={isDisabled}
						className='relative ml-2 mr-1 flex items-center'
						onClick={handleOpenExportModal}
					>
						{prefix}
						{intl.get('optimization_groups_status_btn_export')} {postfix}
					</Button>,
					<Button
						key='unlock'
						disabled={isDisabled}
						className='relative ml-2 mr-1 flex items-center'
						onClick={createOnClickAction(items, OptimizationAction.Unlock)}
					>
						{prefix}
						{intl.get('optimization_groups_status_btn_unlock')} {postfix}
					</Button>,
				]
			default:
				return null
		}
	}
	return (
		<div className='relative flex items-center'>
			<div
				data-active={!expandedStatus && !filteredBySchedule}
				className={statusVariants()}
				tabIndex={0}
				onClick={() => handleSetExpandedStatus()}
			>
				<div>
					<div className='flex items-center space-x-2'>
						<span className='font-bold'>{totalCount ?? 0}</span>
						{updatingCount > 0 && (
							<Tag onClick={handleShowOnlyUpdating}>
								{`${updatingCount} updating`}
								<Spinner className='ml-1' />
							</Tag>
						)}
					</div>
					<div className='mt-1 text-xs font-medium text-muted'>
						{intl.get('general_all')}
					</div>
				</div>
			</div>
			<Separator orientation='vertical' className='mx-4 h-5' />
			{statusesFlow.map((key, index: number) => {
				const isNotLastItem = index < statusesFlow.length - 1
				const items = optimizationsByStatus?.[key] ?? []
				const isActive = expandedStatus === key
				const itemsToAction =
					selected.length > 0
						? items.filter(({ group }) => selected.includes(group.id))
						: items
				return (
					<Fragment key={key}>
						<div
							data-active={isActive}
							// eslint-disable-next-line
							className={statusVariants({ variant: key as any })}
							tabIndex={0}
							onClick={() => handleSetExpandedStatus(key)}
						>
							<div>
								<div className='flex items-center space-x-2'>
									<span className='font-bold'>{items?.length ?? 0}</span>
								</div>
								<div className='mt-1 text-xs font-medium text-muted'>
									{intl.get(`og_status_${key}`)}
								</div>
							</div>
							{isActive && (
								<div className='flex items-center'>
									{itemsToAction.length > 0 && getActions(key, itemsToAction)}
								</div>
							)}
						</div>
						{isNotLastItem && <CaretRightIcon />}
					</Fragment>
				)
			})}
		</div>
	)
}

export const Preloader = memo(() => {
	return (
		<div className='h-12'>
			<ContentLoader
				speed={3}
				width={700}
				height={48}
				viewBox={'0 0 700 48'}
				backgroundColor='var(--cmp-accent-4)'
				foregroundColor='var(--cmp-accent-3)'
			>
				<rect x='0' y='0' rx='15' ry='15' width={60} height={48} />
				<rect x='115' y='7' rx='10' ry='10' width={30} height={20} />
				<rect x='115' y='32' rx='6' ry='6' width={60} height={12} />
				<rect x='230' y='7' rx='10' ry='10' width={30} height={20} />
				<rect x='230' y='32' rx='6' ry='6' width={65} height={12} />
				<rect x='345' y='7' rx='10' ry='10' width={30} height={20} />
				<rect x='345' y='32' rx='6' ry='6' width={50} height={12} />
				<rect x='460' y='7' rx='10' ry='10' width={30} height={20} />
				<rect x='460' y='32' rx='6' ry='6' width={70} height={12} />
				<rect x='575' y='7' rx='10' ry='10' width={40} height={20} />
				<rect x='575' y='32' rx='6' ry='6' width={60} height={12} />
			</ContentLoader>
		</div>
	)
})

const statusesFlow: AssociatedStatus[] = [
	OptimizationStatus.New,
	OptimizationStatus.Pending,
	OptimizationStatus.Started,
	OptimizationStatus.Failed,
	OptimizationStatus.Finished,
	OptimizationStatus.Locked,
]
const statusVariants = cva(
	'flex cursor-pointer rounded-lg px-3 py-1 cursor-pointer transition-all',
	{
		variants: {
			variant: {
				neutral:
					'hover:bg-neutral-25 hover:dark:bg-neutral-300 data-[active=true]:bg-neutral-25 data-[active=true]:dark:bg-neutral-300',
				[OptimizationStatus.New]:
					'hover:bg-neutral-25 hover:dark:bg-neutral-300 data-[active=true]:bg-neutral-25 data-[active=true]:dark:bg-neutral-300',
				[OptimizationStatus.Pending]:
					'hover:bg-warning-25 hover:dark:bg-warning-175 data-[active=true]:bg-warning-25 data-[active=true]:dark:bg-warning-175',
				[OptimizationStatus.Started]:
					'hover:bg-warning-25 hover:dark:bg-warning-175 data-[active=true]:bg-warning-25 data-[active=true]:dark:bg-warning-175',
				[OptimizationStatus.Failed]:
					'hover:bg-danger-25 hover:dark:bg-danger-175 data-[active=true]:bg-danger-25 data-[active=true]:dark:bg-danger-175',
				[OptimizationStatus.Finished]:
					'hover:bg-success-25 hover:dark:bg-success-175 data-[active=true]:bg-success-25 data-[active=true]:dark:bg-success-175',
				[OptimizationStatus.Locked]:
					'hover:bg-brand-25 hover:dark:bg-brand-175 data-[active=true]:bg-brand-25 data-[active=true]:dark:bg-brand-175',
			},
		},
		defaultVariants: {
			variant: 'neutral',
		},
	}
)

//TODO: NEED refactor for filtering and selection data
const getAnalyticEventName = (status?: string) => {
	switch (status) {
		case OptimizationStatus.New:
			return 'mass repricing: action: start'
		case OptimizationStatus.Started:
			return 'mass repricing: action: stop'
		case OptimizationStatus.Failed:
			return 'mass repricing: action: restart'
		case OptimizationStatus.Finished:
			return 'mass repricing: action: apply'
		case OptimizationStatus.Locked:
			return 'mass repricing: action: revoke'
		case OptimizationStatus.Pending:
			return 'mass repricing: action: pending'
		default:
			return 'mass repricing: action: start'
	}
}
const getOptGroupId = ({ group }: OptimizationGroupTableRow) => group.id
const askToApplyOptimization = () =>
	dialog.confirm({
		title: intl.get('optimization_export_again_title'),
		text: intl.get('optimization_export_again_message'),
		okText: intl.get('general_apply'),
	})
const askToRemoveExportedPrices = () =>
	dialog.form({
		title: intl.get('optimization_remove_export_title'),
		text: intl.get('optimization_remove_export_message'),
		form(resolve) {
			return <ExportDialogActions resolve={resolve} />
		},
	})
