import React from 'react'

export default function ScenarioPreloader() {
	return (
		<div className='w-full p-5'>
			<div className='h-6 w-32 animate-pulse rounded-lg bg-accent-3' />
			<div className='mt-2 h-4 w-80 animate-pulse rounded-lg bg-accent-3' />
			<div className='mt-5 h-5 w-28 animate-pulse rounded-lg bg-accent-3' />
			<div className='mt-2 h-3 w-16 animate-pulse rounded-lg bg-accent-3' />
			<div className='mt-1 h-8 w-40 animate-pulse rounded-lg bg-accent-3' />
			<div className='mt-2 h-3 w-20 animate-pulse rounded-lg bg-accent-3' />
			<div className='mt-1 h-52 w-full animate-pulse rounded-lg bg-accent-3' />
			<div className='mt-5 h-5 w-32 animate-pulse rounded-lg bg-accent-3 opacity-70' />
			<div className='mt-2 h-3 w-full animate-pulse rounded-lg bg-accent-3 opacity-60' />
			<div className='mt-1 h-3 w-80 animate-pulse rounded-lg bg-accent-3 opacity-50' />
			<div className='mt-5 h-3 w-16 animate-pulse rounded-lg bg-accent-3 opacity-40' />
			<div className='mt-1 h-8 w-40 animate-pulse rounded-lg bg-accent-3 opacity-30' />
			<div className='flex items-center space-x-2 opacity-20'>
				<div className='mt-1 size-2 animate-pulse rounded-lg bg-accent-3' />
				<div className='mt-1 h-2 w-24 animate-pulse rounded-lg bg-accent-3' />
			</div>
			<div className='mt-5 h-3 w-16 animate-pulse rounded-lg bg-accent-3 opacity-25' />
			<div className='mt-1 h-8 w-40 animate-pulse rounded-lg bg-accent-3 opacity-10' />
		</div>
	)
}
