/* eslint-disable */
export const isNumberValid = (value: string) => {
	const regexp = /^-?\d*(\.\d*|,\d*)?$/
	return regexp.test(value)
}

const is = (Ctor: any) => (val: any) => {
	return (
		val instanceof Ctor ||
		(val != null &&
			(val.constructor === Ctor ||
				(Ctor.name === 'Object' && typeof val === 'object')))
	)
}

/* Number */
export const isNumber = is(Number)

export const isInteger = (x: any) => isNumber(x) && x % 1 === 0

export const isPositiveInteger = (x: any) => isInteger(x) && x >= 0

export const isPositiveNumber = (x: any) => isNumber(x) && x >= 0
