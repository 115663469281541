export enum METRIC_COLORS {
	stock_quantity = '#DE55ED',
	average_discount_percent = '#8D5EDE',
	average_cost = '#6D7EF4',
	average_shelf_price = '#65B6CB',
	average_selling_price = '#55F6C4',
	assortment = '#56EE75',
	gross_profit_margin = '#8CE847',
	gross_profit = '#DBE56A',
	revenue = '#EEAC62',
	sales_items = '#33B1F1',
}
