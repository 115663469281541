import { all, any, keyBy, prop, reduce } from 'lodash/fp'
import React, { useCallback, useMemo, useState } from 'react'

import {
	CellContext,
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	OnChangeFn,
	RowSelectionState,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'

import { Button, Checkbox, Loader } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'

import FilterIcon from '@cmpkit/icon/lib/glyph/filter'
import { ValueType } from '@cmpkit/query-builder'

import CompactSearch from '@/components/CompactSearch'
import NumberField from '@/components/data-grid/fields/Number/TableCell'
import FiltersPopover from '@/components/filter/FiltersPopover'
import { FilterField, FilterRuleModel } from '@/components/filter/types'
import VirtualDataTable from '@/components/tables/VirtualDataTable'
import { PricingCampaignModel } from '@/generated'
import intl from '@/locale'
import {
	useOptimizationGroupsQuery,
	useOptimizationsQuery,
} from '@/modules/core/queries'
import { OptimizationGroupModel, OptimizationModel } from '@/modules/core/types'
import { compareByOperation } from '@/tools/filtering'

import {
	getPricingCampaignEngine,
	getWarnings,
	warningsCellRenderer,
} from '../helpers'
import {
	usePricingCampaignsQuery,
	useProductsCountsByPcQuery,
} from '../queries'
import EngineBadge from './EngineBadge'

const filterFields: FilterField[] = [
	{
		value: 'campaign.name',
		label: 'Name',
		valueType: ValueType.str,
		enum: [],
	},
	{
		value: 'group.name',
		label: 'OG Name',
		valueType: ValueType.str,
		enum: [],
	},
	{
		value: 'campaign.products_count',
		label: 'Items',
		valueType: ValueType.number,
		enum: [],
	},
]

type Props = {
	pricingCmapaignId: string
	selected: string[]
	setSelected(items: string[]): void
	validateCampaign(campaign: PricingCampaignModel): string[]
}
type RowData = {
	products_count: number
	campaign: PricingCampaignModel
	optimization?: OptimizationModel
	group: OptimizationGroupModel
	warnings: string[]
}
type CountsItem = {
	/**
	 * optimization group id
	 * @format uuid
	 */
	optimization_group_id?: string
	/**
	 * pricing campaign id
	 * @format uuid
	 */
	pricing_campaign_id?: string
	/** count of products, cannot be 0, PCs with count 0 are not returned */
	product_count?: number
}
export default function SelectPricingCampaignView({
	validateCampaign,
	selected,
	setSelected,
	pricingCmapaignId,
}: Props) {
	/**
	 * Common hooks
	 */

	const [searchText, setSearchText] = useState<string>('')
	const [rules, setRules] = useState<FilterRuleModel[]>([])

	/**
	 * Queries
	 */
	const commonPricinCampaignsQuery = usePricingCampaignsQuery<
		PricingCampaignModel[]
	>({})
	const optimizations = useOptimizationsQuery({
		refetchOnMount: 'always',
	})
	const productsCountsByPc = useProductsCountsByPcQuery(
		{},
		{
			select: keyBy<CountsItem>('pricing_campaign_id'),
		}
	)
	const optimizationGroupsQuery = useOptimizationGroupsQuery<
		Record<string, OptimizationGroupModel>
	>({
		select: keyBy<OptimizationGroupModel>('id'),
	})

	/**
	 * Calculated values
	 */
	const isLoading = any(prop('isLoading'), [
		commonPricinCampaignsQuery,
		optimizationGroupsQuery,
	])
	//const columns = getColumns()
	const data: RowData[] = useMemo(() => {
		if (optimizationGroupsQuery.data && commonPricinCampaignsQuery.data) {
			return (commonPricinCampaignsQuery?.data ?? [])
				.filter((campaign) => campaign.id !== pricingCmapaignId)
				.filter((item) =>
					searchText.length > 0
						? item.name
								.toLocaleLowerCase()
								.includes(searchText.toLocaleLowerCase())
						: true
				)
				?.map((campaign): RowData => {
					const group =
						optimizationGroupsQuery.data?.[
							campaign.optimization_group_id as string
						]
					const optimization = optimizations?.data?.[group?.id]
					const warnings = [
						...validateCampaign(campaign),
						...getWarnings(optimization),
					]
					return {
						products_count:
							productsCountsByPc.data?.[campaign.id]?.product_count || 0,
						campaign,
						optimization,
						group,
						warnings,
					}
				})
				.filter(prop('group'))
				.filter((item) => {
					return all((filter) => {
						return compareByOperation(
							prop(filter.name, item),
							filter.operation,
							filter.value
						)
					}, rules)
				})
		} else {
			return []
		}
	}, [
		optimizationGroupsQuery,
		commonPricinCampaignsQuery,
		optimizations,
		productsCountsByPc,
		rules,
	])

	/**
	 * Handlers
	 */

	const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setSearchText(e.target.value)
	const handleSearchTextClear = () => setSearchText('')

	return (
		<>
			<div className='flex items-center justify-between' id='modal-toolbar'>
				<div className='flex items-center space-x-3'>
					<CompactSearch
						value={searchText}
						placeholder={intl.get('general_search')}
						onClear={handleSearchTextClear}
						onChange={handleSearchTextChange}
					/>
					<FiltersPopover
						fields={filterFields}
						filters={rules ?? []}
						onApply={setRules}
					>
						<Button iconBefore={<FilterIcon />}>
							{intl.get('general_filter')}&nbsp;
							{rules.length ? `(${rules.length})` : ''}
						</Button>
					</FiltersPopover>
				</div>
			</div>
			<div className='relative flex h-96 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1 text-xs'>
				<Blanket
					className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-lg dark:bg-black/50'
					isTinted={isLoading}
				>
					<Loader />
				</Blanket>
				<TableView data={data} selected={selected} setSelected={setSelected} />
			</div>
		</>
	)
}
function TableView({
	data,
	setSelected,
	selected,
}: {
	data: RowData[]
	setSelected(items: string[]): void
	selected: string[]
}) {
	const [sorting, setSorting] = useState<SortingState>([])
	const rowSelection = useMemo(
		() =>
			reduce(
				(acc, id: string) => ({ ...acc, [id]: true }),
				{} as RowSelectionState
			)(selected),
		[selected]
	)
	const setRowSelection = useCallback(
		((updater: (old: RowSelectionState) => RowSelectionState) => {
			return setSelected(
				Object.entries(updater(rowSelection))
					.filter(([, flag]) => flag)
					.map(([id]) => id)
			)
		}) as OnChangeFn<RowSelectionState>,
		[rowSelection, setSelected]
	)

	const columns = useMemo(() => getColumns(), [])
	const table = useReactTable<RowData>({
		data,
		columns,
		state: {
			sorting,
			rowSelection,
		},
		enableRowSelection: (row) => !row.original.warnings?.length,
		defaultColumn: {
			minSize: 30,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		getRowId: (row) => row.campaign.id,
		onSortingChange: setSorting,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return <VirtualDataTable table={table} />
}
const columnHelper = createColumnHelper<RowData>()
const getColumns = () => [
	columnHelper.accessor('group.id', {
		id: 'control',
		header: '',
		size: 30,
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				disabled={!row.getCanSelect()}
				onChange={row.getToggleSelectedHandler()}
			/>
		),
	}),
	columnHelper.accessor('campaign.name', {
		id: 'campaign.name',
		header: 'Name',
		cell: defaultCell,
	}),
	columnHelper.accessor((row) => row.warnings?.length, {
		id: 'warnings',
		header: 'Warnings',
		cell: ({ row, getValue }) => {
			return warningsCellRenderer(String(getValue()), row.original)
		},
		size: 50,
	}),
	//selectedPricingCampaign
	columnHelper.accessor('group.name', {
		id: 'group.name',
		header: 'OG Name',
		cell: defaultCell,
		size: 205,
	}),
	columnHelper.accessor('products_count', {
		id: 'products_count',
		header: 'Items',
		cell: (info) => {
			return <NumberField value={info.getValue()} />
		},
		size: 50,
	}),
	columnHelper.accessor('campaign.settings.pricing_tactics.engine', {
		id: 'campaign.engine',
		header: 'Pricing approach',
		cell: (info) => {
			const { campaign } = info.row.original
			const engine = getPricingCampaignEngine(campaign)
			return (
				<EngineBadge engine={engine} className={'mr-1'}>
					{intl.get('campaigns_om_title_' + engine)}
				</EngineBadge>
			)
		},
		size: 150,
	}),
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultCell = (info: CellContext<RowData, any>) => {
	return (
		<span className={info.row.original.warnings?.length ? 'opacity-50' : ''}>
			{info.getValue()}
		</span>
	)
}
