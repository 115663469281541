import Highcharts, { SeriesSolidgaugeOptions } from 'highcharts'
import React from 'react'

import Chart from '@/components/Chart'

type Props = {
	// eslint-disable-next-line
	series: SeriesSolidgaugeOptions[]
}
const getConfig = ({ series }: Props): Highcharts.Options =>
	({
		chart: {
			plotBackgroundColor: undefined,
			plotBorderWidth: undefined,
			plotShadow: false,
			type: 'solidgauge',
			width: 120,
			height: 120,
		},
		time: {
			timezone: undefined,
		},
		legend: {
			align: 'left',
		},
		tooltip: {
			outside: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter(this: Highcharts.Point) {
				const self = this as unknown as any // eslint-disable-line
				return `<div class="chart-tooltip">${this.series.name}: <strong>${self.point.y}%</strong></div>`
			},
		},
		pane: {
			startAngle: 0,
			endAngle: 360,
			background: [
				{
					outerRadius: '112%',
					innerRadius: '88%',
					backgroundColor: Highcharts.color('#00b557').setOpacity(0.3).get(),
					borderWidth: 0,
				},
				{
					outerRadius: '87%',
					innerRadius: '63%',
					backgroundColor: Highcharts.color('#32b2f3').setOpacity(0.3).get(),
					borderWidth: 0,
				},
			],
		},
		yAxis: {
			min: 0,
			max: 100,
			lineWidth: 0,
			tickPositions: [],
		},

		plotOptions: {
			solidgauge: {
				dataLabels: {
					enabled: false,
				},
				linecap: 'round',
				stickyTracking: false,
				rounded: true,
			},
		},
		series,
	}) as Highcharts.Options
export default function ChartView(props: Props) {
	return <Chart config={getConfig(props)} />
}
