import { useQuery } from '@tanstack/react-query'

import {
	ErrorResponse,
	GetAllRequestTypesParams,
	PagedDTORequestTypeDTO,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'

import { JSD_ID } from './constants'
import { jsdOptions } from './mutations'

export function useJsdAccountIdQuery(
	email?: string,
	options?: UseQueryOptions<string | null | undefined>
) {
	return useQuery({
		queryKey: ['jsd-account-id', email],
		queryFn: async () => {
			try {
				const resp = await client.jsd.getCustomers(
					{ serviceDeskId: JSD_ID, query: email },
					jsdOptions
				)
				if (resp?.size === 1) {
					return resp?.values?.[0].accountId
				}
			} catch (error) {
				return null
			}
		},
		...(options || {}),
	})
}
export function useJsdRequestTypesQuery<T = PagedDTORequestTypeDTO>(
	queryParams: GetAllRequestTypesParams & { requestLanguage: string },
	options?: UseQueryOptions<PagedDTORequestTypeDTO, ErrorResponse, T>
) {
	return useQuery<PagedDTORequestTypeDTO, ErrorResponse, T>({
		queryKey: ['jsd-request-types', queryParams],
		queryFn: () => client.jsd.getAllRequestTypes(queryParams, jsdOptions),
		...(options || {}),
	})
}
export function useJsdOrganizationIdQuery(
	options?: UseQueryOptions<string, Error>
) {
	return useQuery({
		queryKey: ['jsd-organization-id'],
		queryFn: () => client.apps.getHelpdeskOrganizationIdHandler(),
		...(options || {}),
	})
}
