import { Operators } from '@cmpkit/query-builder'

import { ControllerConfig, ValueState } from '../../types'
import FieldController from '../Controller'

export default class BooleanController extends FieldController {
	constructor(config: ControllerConfig) {
		super(config)
		this.note = config.note
		this.validate = config.validate || this.defaultValidate
	}

	note?: string

	formatLabel = () => {
		return this.label
	}

	hasValue = () => {
		return true
	}
	defaultValidate = ({ value }: ValueState) => {
		return value !== '0' && value !== '1' ? 'Please select a value.' : null
	}
	getInitialValue = () => ({
		operation: Operators.BL,
		value: '',
	})
}
