import React, { useLayoutEffect, useRef } from 'react'

import { useTimelineGridContext } from './TimelineGridContext'

type TimelineGridRootProps = {
	innerRef: React.MutableRefObject<unknown>
	children: React.ReactNode
}
export default function TimelineGridRoot({
	children,
	innerRef,
}: TimelineGridRootProps) {
	const rootRef = useRef<HTMLDivElement | null>(null)
	const { dimmensions, getTodayPosition } = useTimelineGridContext()
	useLayoutEffect(() => {
		if (!innerRef.current) {
			const scrollToCurrentDate = () => {
				const { left } = getTodayPosition()
				rootRef.current?.scrollTo({
					top: 0,
					left: left - 10,
					behavior: 'smooth',
				})
			}
			innerRef.current = {
				scrollToCurrentDate,
			}
			scrollToCurrentDate()
		}
		return () => {
			innerRef.current = undefined
		}
	}, [dimmensions])

	return (
		<div className='timeline-grid min-h-[500px]'>
			<div className='timeline-grid-root' ref={rootRef}>
				<div className='timeline-grid-viewport'>{children}</div>
			</div>
		</div>
	)
}
