import { AxiosResponse } from 'axios'
import { omitAll } from 'lodash/fp'
import * as qs from 'qs'

import notify from '@/components/toasts'
import intl from '@/locale'
import { client } from '@/network/client'

import contentDisposition from './content-disposition'
import { toQueryString } from './location'

const systemQuery = ['selected', 'fid']

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFileNameFromResponse = (response: AxiosResponse<any, any>) => {
	const cd = contentDisposition(response.headers['content-disposition'])
	return cd.parameters['filename*']
		? decodeURIComponent(cd.parameters['filename*'])
		: cd.parameters['filename']
}
export const exportFile = (
	optimizationGroupId: string,
	query?: Record<string, unknown>
) => {
	const optionQueryString = qs.stringify(omitAll(systemQuery, query), {
		arrayFormat: 'comma',
		addQueryPrefix: true,
		encodeValuesOnly: true,
	})
	downloadFile(
		`${window.location.origin}/api/v1/optimization_groups/${optimizationGroupId}/products/${optionQueryString}`
	)
}
export const exportStreamFile = (template: string, query?: object) => {
	const optionQueryString = toQueryString(query || {})
	downloadFile(
		`${window.location.origin}/api/v1/export/${template}/${optionQueryString}`
	)
}
export const download = (blob: Blob, name: string) => {
	const a = document.createElement('a')
	a.href = URL.createObjectURL(blob)
	a.setAttribute('download', name)
	document.body.appendChild(a)
	a.click()
}
export const downloadFile = (url: string, name?: string) => {
	notify.loading(
		{
			text: intl.get('report_file_loading'),
		},
		{ id: 'export' }
	)
	return client.instance
		.get(url, {
			responseType: 'blob',
		})
		.then((response) => {
			download(
				new Blob([response.data]),
				name ?? getFileNameFromResponse(response)
			)
			notify.success(
				{
					text: 'Success',
				},
				{ id: 'export' }
			)
		})
		.catch(() => {
			notify.error(
				{
					text: 'Downloading failed, please try again.',
				},
				{ id: 'export' }
			)
		})
}
