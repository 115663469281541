import { map, omitAll, prop } from 'lodash/fp'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { Separator, TreeNodeType } from '@cmpkit/base'
import { Column } from '@cmpkit/data-table'
import { FilterRuleEntity, Operators } from '@cmpkit/query-builder'

import { DataOption } from '@/common.types'
import TableFilters from '@/components/data-grid/TableFilters'
import TextSearchField from '@/components/data-grid/TextSearchField'
import { toAdaptedSchema } from '@/components/filter/adapter'
import { rulesToQueryObject, toFilterRules } from '@/components/filter/utils'
import { getFiledConfig } from '@/components/refinement-bar/helpers'
import TableColumnsManager from '@/components/TableColumnsManager'
import { ColumnSchemaModel, PricingCampaignModel } from '@/generated'
import { useCanEditModelInput, useOptimizationStatus } from '@/hooks/data'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import { OptimizationGroupPricngAlertsButton } from '@/modules/core'
import { LOCKED } from '@/modules/core/constants'
import { QuickFiltersBar, SavedFiltersDrodown } from '@/modules/preferences'
import { FIELD_CONFIG } from '@/modules/preferences/field-configs'
import { useStoredFiltersKeys } from '@/modules/preferences/hooks/useStoredFiltersKeys'
import { AssignToPricingCampaignDropdown } from '@/modules/pricing-campaigns'
import { SaveReportButton } from '@/modules/saved-reports'
import analytic from '@/services/analytics'
import {
	ColumnDefinition,
	useColumnManagerControllerAdapter,
} from '@/tools/columns'
import { getLocationQuery, toQueryString } from '@/tools/location'

import { useProductsStore, useTableConfigStore } from '../store'
import ProductsBulkUpdateDropdown from './ProductsBulkUpdateDropdown'

interface ProductsManagerProps {
	columns: Column[]
	columnsDefinitions: ColumnDefinition[]
	onAssignToPricingCampaign(
		pricingCampaign: PricingCampaignModel | string
	): void
	onCreatePricingCampaign(stat: object): void
	onOpenBulkModal(): void
	onOpenImportModal(): void
	dataChoices: Record<string, DataOption[] | TreeNodeType[]>
}
export default function ProductsToolbar({
	columns,
	dataChoices,
	columnsDefinitions,
	onOpenBulkModal,
	onAssignToPricingCampaign,
	onCreatePricingCampaign,
	onOpenImportModal,
}: ProductsManagerProps) {
	const navigate = useNavigate()
	const { search } = useLocation()
	const optimizationGroupId = useOptimizationGroupId()!
	const { setSearchText, selected, searchText } = useProductsStore()
	const config = useTableConfigStore()
	const [storedQuickFiltersKeys, saveStoredQuickFiltersKeys] =
		useStoredFiltersKeys('og_quick_filters_keys')
	const configController = useColumnManagerControllerAdapter(
		config,
		columnsDefinitions
	)

	const optimizationStatus = useOptimizationStatus(optimizationGroupId)
	const canEditModelInput = useCanEditModelInput(optimizationGroupId)

	const isOgStatusLocked = optimizationStatus === LOCKED
	const schema = columnsDefinitions.map(prop('schema')) as ColumnSchemaModel[]
	const filtersSchema = schema.filter(({ name }) => name !== 'control')

	/**
	 * Filters from url
	 */
	const filters = useMemo(
		() =>
			toFilterRules(
				omitAll(['limit', 'offset', 'sort', 'lang'], getLocationQuery(search))
			),
		[search]
	)
	/**
	 * Apply quick filters to the url and navigate
	 */
	const applySelectedRules = (rules: FilterRuleEntity[]) =>
		navigate(toQueryString(rulesToQueryObject(rules)))

	return (
		<div className='flex w-full items-center justify-between'>
			<div className='flex items-center space-x-1'>
				<TextSearchField value={searchText} onChange={setSearchText} />
				<SavedFiltersDrodown
					onItemClick={(filterItem) => {
						analytic.logEvent('saved-filters: click on filter', {
							from: 'og assortment',
						})
						applySelectedRules(filterItem.params.query!)
					}}
				/>
				<TableFilters
					enableRecentFilters
					enableSaveFilters
					analyticEventPrefix='og assortment:'
					choices={dataChoices}
					schema={filtersSchema}
					filters={filters ?? []}
					onApply={applySelectedRules}
				/>
				<div className='h-5 border-l border-solid border-base' />
				<QuickFiltersBar
					storedKeys={storedQuickFiltersKeys}
					onSaveStoredKeys={saveStoredQuickFiltersKeys}
					irremovableKeys={['category_ids', 'brands', 'pricing_campaign_id']}
					rules={filters ?? []}
					fieldsConfig={{
						...getFiledConfig(map(toAdaptedSchema, filtersSchema), dataChoices),
						...FIELD_CONFIG,
					}}
					onApply={applySelectedRules}
				/>
				<ProductsBulkUpdateDropdown
					canEditModelInput={canEditModelInput}
					onOpenBulkModal={onOpenBulkModal}
					onOpenImportModal={onOpenImportModal}
				/>
				{selected.length > 0 && (
					<AssignToPricingCampaignDropdown
						scope={{
							optimization_group_id: optimizationGroupId,
						}}
						isActionsDisabled={isOgStatusLocked}
						onSelectCampaign={onAssignToPricingCampaign}
						onCreateCampaign={onCreatePricingCampaign}
					/>
				)}
			</div>
			<div className='flex items-center gap-1'>
				<SaveReportButton
					disabled={!filters?.length}
					filters={[
						...filters,
						{
							name: 'optimization_group_id',
							operation: Operators.IN,
							value: [optimizationGroupId],
						},
					]}
				/>

				<Separator orientation='vertical' className='mx-1 h-5' />
				<OptimizationGroupPricngAlertsButton />
				<TableColumnsManager columns={columns} config={configController} />
			</div>
		</div>
	)
}
