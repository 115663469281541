import React, { useState } from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import {
	Button,
	FormError,
	FormGroup,
	FormLabel,
	InlineMessage,
	LinkButton,
	TreeNodeType,
} from '@cmpkit/base'
import EyeIcon from '@cmpkit/icon/lib/glyph/eye'
import EyeOffIcon from '@cmpkit/icon/lib/glyph/eye-off'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import QueryBuilder, {
	FieldDefinition,
	getFiledConfig,
	useFilters,
} from '@cmpkit/query-builder'

import { DataOption } from '@/common.types'
import ErrorBoundary from '@/components/ErrorBoundary'
import { localization } from '@/components/filter/localization'
import { trimFilterRuleValues } from '@/components/filter/utils'
import { FilterRuleModel } from '@/generated'
import intl from '@/locale'

import { isSkuRule } from '../../modules/og-settings/utils'
import { proxyRuleChange } from '../filter/adapter'

export default function RHFStoplistCustomRules({
	name,
	label,
	rules,
	shouldUnregister,
	defaultValue,
	fields,
	dataChoices,
	isLoading,
}: UseControllerProps & {
	isLoading?: boolean
	label?: string
	rules?: FilterRuleModel[]
	fields: FieldDefinition[]
	dataChoices: Record<string, DataOption[] | TreeNodeType[]>
}) {
	const { control } = useFormContext()
	return (
		<FormGroup>
			{!!label && <FormLabel>{label}</FormLabel>}
			<Controller
				name={name}
				rules={rules || []}
				shouldUnregister={shouldUnregister}
				defaultValue={defaultValue}
				control={control}
				render={({ field, fieldState: { error } }) => {
					const [isAllShown, setIsAllShown] = useState(false)
					const skuRules = field.value?.filter(isSkuRule) || []
					const notSkuRules =
						field.value?.filter((item: FilterRuleModel) => !isSkuRule(item)) ||
						[]

					const rulesController = useFilters({
						filters: isAllShown ? field.value : notSkuRules,
						onChange: (rules) =>
							field.onChange(
								trimFilterRuleValues(
									isAllShown ? rules : [...skuRules, ...rules]
								)
							),
					})
					const handleChangeRule = rulesController.changeRule
					const handleDeleteRule = rulesController.removeRule
					const handleAddRule = () =>
						rulesController.addRule({
							name: '',
							operation: '',
							value: '',
						})
					return (
						<>
							{isLoading ? (
								<div className='flex flex-col space-y-2'>
									<div className='h-10 w-full animate-pulse rounded-lg bg-accent-3' />
									<div className='h-10 w-full animate-pulse rounded-lg bg-accent-3' />
								</div>
							) : (
								<ErrorBoundary
									fallback={() => (
										<InlineMessage variant='danger'>
											🐞{' '}
											{intl
												.get('ui_fragment_error')
												.d(
													'Oops ... it looks like an unexpected error has occurred. Our team`s been notified.'
												)}
										</InlineMessage>
									)}
								>
									<div className='text-right'>
										{!!skuRules?.length && isAllShown ? (
											<LinkButton
												variant='brand'
												size='small'
												onClick={() => setIsAllShown(false)}
											>
												<EyeIcon />
												{intl
													.get('hide_sku_rule', {
														value: skuRules.length,
													})
													.d(`Hide ${skuRules?.length} SKU rules`)}
											</LinkButton>
										) : (
											<LinkButton
												variant='brand'
												size='small'
												onClick={() => setIsAllShown(true)}
											>
												<EyeOffIcon />
												{intl
													.get('show_sku_rule', {
														value: skuRules.length,
													})
													.d(`Show ${skuRules.length} SKU rules`)}
											</LinkButton>
										)}
									</div>
									<QueryBuilder
										className='-ml-1'
										rules={rulesController.rules || []}
										fieldConfig={getFiledConfig(fields, dataChoices)}
										locale={localization}
										onChange={proxyRuleChange(handleChangeRule)}
										onDelete={handleDeleteRule}
										onAdd={handleAddRule}
									/>
								</ErrorBoundary>
							)}

							{rulesController.rules?.length === 0 && (
								<>
									<div className='mb-2 w-full border-b border-base' />
									<Button
										iconBefore={<PlusIcon />}
										onClick={handleAddRule}
										data-testid='add-first-rule'
										variant='tertiary'
									>
										{intl.get('filters_add_filter')}
									</Button>
								</>
							)}
							{error && (
								<FormError>
									{error?.message || intl.get('validation.invalid_rules')}
								</FormError>
							)}
						</>
					)
				}}
			/>
		</FormGroup>
	)
}
