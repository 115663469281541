import { isNumber } from 'lodash/fp'

import { OptimizationStatisticModel, PricingCampaignModel } from '@/generated'
import { getDifferenceInPercents } from '@/modules/dashboards/helpers'
import { getAssortmentShare } from '@/modules/pricing-campaigns/helpers'
import {
	PricingCampaignTableRow,
	ScenariosTableDataType,
} from '@/modules/scenarios/types'

const escapeRegExp = (text: string) =>
	text.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
const createReVersion = (scenarioName: string) =>
	new RegExp(`^${escapeRegExp(scenarioName)}\\s+\\((\\d+)\\)$`)
const reCopy = /\s+\(\d+\)$/
const findVersions = (
	scenarioName: string,
	scenarios: string[]
): RegExpMatchArray[] => {
	const originalScenarioName: string = scenarioName.replace(reCopy, '')
	const reVersion: RegExp = createReVersion(originalScenarioName)
	const scenarioCopyMatches = scenarios.map((name: string) =>
		name.match(reVersion)
	)
	return scenarioCopyMatches.filter(
		(match) => match !== null && match.length > 0
	) as RegExpMatchArray[]
}
const getMaxVersion = (scenarioVersions: RegExpMatchArray[]) =>
	Math.max(
		0,
		...scenarioVersions.map((version: RegExpMatchArray) => Number(version[1]))
	)
const createNewNameVersion = (scenarioName: string, maxVersion: number) => {
	return `${scenarioName.replace(reCopy, '')} (${maxVersion + 1})`
}
export const getCopyName = ({
	scenarioName,
	scenarios,
}: {
	scenarioName: string
	scenarios: ScenariosTableDataType[]
}) => {
	const scenariosNames = scenarios.map(
		(scenario: ScenariosTableDataType) => scenario.name
	)
	const scenarioVersions = findVersions(scenarioName, scenariosNames)
	const maxVersion = getMaxVersion(scenarioVersions || [])
	return createNewNameVersion(scenarioName, maxVersion)
}
export function getPricingCampaignsTableRows(
	campaigns: PricingCampaignModel[],
	suggestedProducts: Record<string, number>,
	totalOptimizationProducts: number = 0
): PricingCampaignTableRow[] {
	return (
		campaigns?.map((campaign) => {
			const productsCount = suggestedProducts?.[campaign.id]
			return {
				campaign,
				products_count: productsCount,
				assortment_share: getAssortmentShare(
					totalOptimizationProducts,
					productsCount
				),
			}
		}) || []
	)
}
export const calculateMultipleInterpretabilityStatistics = (data: {
	metrics: {
		[key: string]: number | null
	}
}) => ({
	revenue_current: data?.metrics?.['predict_revenue.init'],
	revenue_final: data?.metrics?.['predict_revenue.final'],
	revenue_diff:
		calculateDiffInPercent(
			data?.metrics?.['predict_revenue.final'] || 0,
			data?.metrics?.['predict_revenue.init'] || 0
		) || 0,
	sales_items_current: data?.metrics?.['predict_sales_items.init'],
	sales_items_final: data?.metrics?.['predict_sales_items.final'],
	sales_items_diff:
		calculateDiffInPercent(
			data?.metrics?.['predict_sales_items.final'] || 0,
			data?.metrics?.['predict_sales_items.init'] || 0
		) || 0,
	gross_profit_current: data?.metrics?.['predict_gross_profit.init'],
	gross_profit_final: data?.metrics?.['predict_gross_profit.final'],
	gross_profit_diff:
		calculateDiff(
			data?.metrics?.['predict_gross_profit.final'] || 0,
			data?.metrics?.['predict_gross_profit.init'] || 0
		) || 0,
	profit_margin_current: data?.metrics?.['predict_gross_profit_margin.init'],
	profit_margin_final: data?.metrics?.['predict_gross_profit_margin.final'],
	profit_margin_diff:
		calculateDiff(
			data?.metrics?.['predict_gross_profit_margin.final'] || 0,
			data?.metrics?.['predict_gross_profit_margin.init'] || 0
		) || 0,
})
export const calculateOptimizationsStatistics = (
	data: OptimizationStatisticModel
) => ({
	items_decreased: data?.price_decreased,
	items_increased: data?.price_increased,
	items_locked: data?.price_locked,
	items_not_changed: data?.price_not_changed,
	avg_price: data?.average_price_opt,
})
export function calculateDiffInPercent(main: number, compared: number) {
	const isValidToCompare =
		compared !== undefined &&
		isNumber(main) &&
		isNumber(compared) &&
		main !== compared
	if (isValidToCompare) {
		return +getDifferenceInPercents(main, compared).toFixed(2)
	} else {
		return 0
	}
}
export function calculateDiff(main: number, compared: number) {
	const isValidToCompare =
		compared !== undefined &&
		isNumber(main) &&
		isNumber(compared) &&
		main !== compared
	if (isValidToCompare) {
		const difference = main - compared
		return +(Number.isFinite(difference) ? difference : 0).toFixed(2)
	} else {
		return 0
	}
}
