import { find, map, orderBy, prop, sum } from 'lodash/fp'
import { useMemo } from 'react'

import {
	MultipleAlertsInfoResponseModel,
	OptimizationModel,
	OptimizationStatisticModel,
	ScenarioRunModel,
} from '@/generated'
import { useOptimizationGroup } from '@/hooks/data'
import { useMainScenarioId } from '@/hooks/useMainScenarioId'
import {
	multipleInterpretabilityStatistics,
	useScenarioRunsItems,
} from '@/modules/scenarios/queries'

import {
	calculateMultipleInterpretabilityStatistics,
	calculateOptimizationsStatistics,
} from '../helpers'
import { ScenariosTableDataType } from '../types'

export function useScenariosSummary(optimizationGroupId: string): {
	data: ScenariosTableDataType[]
	isError: boolean
	isLoading: boolean
} {
	const mainScenarioId = useMainScenarioId(optimizationGroupId)
	const optimizationGroupQuery = useOptimizationGroup(optimizationGroupId)
	const scenarioRunsItemsQuery = useScenarioRunsItems<ScenariosTableDataType[]>(
		optimizationGroupId,
		optimizationGroupQuery,
		{
			enabled: !!optimizationGroupId,
			// FIXME: Describe the right type in both places
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			select: selectScenariosTableData as any,
		}
	)
	const data = useMemo(
		() =>
			orderBy(
				['is_main'],
				['desc'],
				scenarioRunsItemsQuery.data?.map(
					(row: ScenariosTableDataType): ScenariosTableDataType => ({
						...row,
						is_main: row.id === mainScenarioId,
					})
				) || []
			),
		[mainScenarioId, scenarioRunsItemsQuery.data]
	)
	return {
		data,
		isError: scenarioRunsItemsQuery.isError,
		isLoading: scenarioRunsItemsQuery.isLoading,
	}
}

type selectScenariosTableDataProps = {
	scenarioRuns: (Partial<ScenarioRunModel> & { description: string })[]
	optimizations: OptimizationModel[]
	optimizationStatistics: OptimizationStatisticModel[]
	multipleInterpretabilityStatistics: multipleInterpretabilityStatistics[]
	multipleAlertsInfo: MultipleAlertsInfoResponseModel
}
export function selectScenariosTableData({
	scenarioRuns,
	optimizations,
	optimizationStatistics,
	multipleInterpretabilityStatistics,
	multipleAlertsInfo,
}: selectScenariosTableDataProps): ScenariosTableDataType[] {
	if (!scenarioRuns?.length) {
		return []
	}
	return scenarioRuns.map(
		({ scenario_id, optimization_id, name, id, settings, description }) => {
			const scenarioOptimization = optimizations.find(
				({ id }) => id === optimization_id
			) as OptimizationModel
			const multipleInterpretabilityStatistic =
				calculateMultipleInterpretabilityStatistics(
					// FIXME: Describe the right type in both places
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					find({ optimization_id }, multipleInterpretabilityStatistics as any)
				)
			const optimizationsStatistic = calculateOptimizationsStatistics(
				find(
					{ optimization_id },
					optimizationStatistics
				) as OptimizationStatisticModel
			)
			const notifications_count =
				sum(
					map(
						prop('products'),
						multipleAlertsInfo?.by_level?.filter(
							(alertInfo) => alertInfo.optimization_id === optimization_id
						)
					)
				) || 0
			return {
				description,
				optimization_id,
				optimization_group_id: scenarioOptimization.optimization_group_id,
				scenario_run_id: id,
				name,
				settings,
				id: scenario_id,
				status: scenarioOptimization?.status,
				...multipleInterpretabilityStatistic,
				...optimizationsStatistic,
				is_copy: false,
				notifications_count,
			} as ScenariosTableDataType
		}
	) as ScenariosTableDataType[]
}
