import clsx from 'clsx'
import { intlFormat } from 'date-fns'
import { any, prop } from 'lodash/fp'
import {
	CheckCircleIcon,
	CircleXIcon,
	DatabaseBackup,
	DatabaseBackupIcon,
	DatabaseIcon,
	DatabaseZapIcon,
	LoaderCircleIcon,
} from 'lucide-react'
import React from 'react'

import { Card, CardContent, Tag } from '@cmpkit/base'
import Popover from '@cmpkit/popover'
import { SidebarMenuButton, SidebarMenuItem } from '@cmpkit/sidebar'

import { BIRevision, PreprocessingModel } from '@/generated'
import intl from '@/locale'
import { useBIRevisionQuery } from '@/modules/bi/queries'
import { usePreproQuery } from '@/modules/core/queries'

function isDemandEnabled(modes: string[] = []) {
	const mlModes = ['dense', 'dense_solver', 'rnn', 'rnn_solver']
	return any((m) => modes.includes(m), mlModes)
}
export default function AppSidebarIntegrationMenuItem() {
	const preproQuery = usePreproQuery()
	const status = preproQuery.data?.status
	const demandEnabled = isDemandEnabled(preproQuery.data?.modes ?? [])
	return (
		<SidebarMenuItem>
			<Popover placement='right-start' content={<IntegrationStatusCard />}>
				<SidebarMenuButton
					data-testid='exports-menu-item'
					className={clsx({
						'group-data-[collapsible=icon]:bg-success-50':
							status === 'finished',
						'group-data-[collapsible=icon]:bg-danger-50': status === 'failed',
						'group-data-[collapsible=icon]:bg-warning-50': status === 'started',
					})}
					tooltip={intl.get('app.integration_status').d('Integration status')}
				>
					{status === 'finished' && demandEnabled && (
						<CheckCircleIcon className='rounded-full text-success' />
					)}
					{status === 'finished' && !demandEnabled && (
						<DatabaseIcon className='rounded-full text-success' />
					)}
					{status === 'failed' && (
						<DatabaseZapIcon className='rounded-full text-danger' />
					)}
					{status === 'started' && (
						<DatabaseBackupIcon className='rounded-full text-warning' />
					)}
					{preproQuery.isPending && (
						<LoaderCircleIcon className='size-4 animate-spin' />
					)}
					<span className='truncate'>
						{intl.get('app.integration_status').d('Integration status')}
					</span>
				</SidebarMenuButton>
			</Popover>
		</SidebarMenuItem>
	)
}

function IntegrationStatusCard() {
	const preproQuery = usePreproQuery()
	const biRevisionDateQuery = useBIRevisionQuery<string | null>({
		refetchOnMount: 'always',
		select: prop('date') as (data: BIRevision | null) => string | null,
	})

	return (
		<Card className='min-w-72 border shadow'>
			<CardContent className='flex flex-col gap-2'>
				<DataFieldHorizontal
					label={intl.get('repricing.ml').d('Demand-based repricing') + ':'}
				>
					{preproQuery.isPending ? (
						<div className='h-6 w-20 animate-pulse rounded-full bg-accent-3' />
					) : (
						preproQuery.data &&
						(isDemandEnabled(preproQuery.data?.modes ?? []) ? (
							<Tag variant='success'>
								<CheckCircleIcon className='size-4' />
								{intl.get('avaliable').d('Available')}
							</Tag>
						) : (
							<Tag variant='danger'>
								<CircleXIcon className='size-4' />
								{intl.get('not_avaliable').d('Not abailable')}
							</Tag>
						))
					)}
				</DataFieldHorizontal>
				<DataFieldHorizontal
					label={intl.get('prepro.status').d('Integration status') + ':'}
				>
					<div className='fade-in flex items-center gap-1'>
						{preproQuery.isPending ? (
							<div className='h-6 w-24 animate-pulse rounded-full bg-accent-3' />
						) : (
							preproQuery.data && (
								<DataIntegrationBage prepro={preproQuery.data} />
							)
						)}
					</div>
				</DataFieldHorizontal>
				<DataFieldHorizontal
					label={intl.get('prepro.date').d('Integration date') + ':'}
				>
					<div className='fade-in flex items-center gap-1'>
						{preproQuery.isPending ? (
							<div className='h-6 w-24 animate-pulse rounded-full bg-accent-3' />
						) : preproQuery.data?.date ? (
							intlFormat(new Date(preproQuery.data?.date + 'Z'), {
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
								hour: 'numeric',
								minute: 'numeric',
							})
						) : (
							''
						)}
					</div>
				</DataFieldHorizontal>
				<DataFieldHorizontal
					label={intl.get('bi.revision.date').d('BI revision date') + ':'}
				>
					{biRevisionDateQuery.isPending ? (
						<div className='h-6 w-20 animate-pulse rounded-full bg-accent-3' />
					) : (
						biRevisionDateQuery.data && (
							<div className='fade-in flex items-center gap-1'>
								{intlFormat(
									new Date(biRevisionDateQuery.data + 'T00:00:00.000'),
									{
										year: 'numeric',
										month: 'numeric',
										day: 'numeric',
									}
								)}
							</div>
						)
					)}
				</DataFieldHorizontal>
			</CardContent>
		</Card>
	)
}

function DataIntegrationBage({ prepro }: { prepro: PreprocessingModel }) {
	if (!prepro) return null
	const { status } = prepro
	switch (status) {
		case 'finished':
			return (
				<Tag className={'flex items-center text-nowrap'} variant={'success'}>
					<DatabaseIcon className='size-4' />
					{intl.get('finished').d('Finished')}
				</Tag>
			)
		case 'failed':
			return (
				<Tag className={'flex items-center text-nowrap'} variant={'danger'}>
					<DatabaseZapIcon className='size-4' />
					{intl.get('failed').d('Failed')}
				</Tag>
			)
		case 'started':
			return (
				<Tag className={'flex items-center text-nowrap'} variant={'warning'}>
					<DatabaseBackup className='size-4' />
					{intl.get('in_progress').d('In progress')}
				</Tag>
			)
		default:
			return null
	}
}

interface DataFieldHorizontalRootProps
	extends React.HTMLAttributes<HTMLDivElement> {
	label?: React.ReactNode
	icon?: React.ReactNode
	children?: React.ReactNode
	className?: string
}

const DataFieldHorizontal = React.forwardRef<
	HTMLDivElement,
	DataFieldHorizontalRootProps
>(function DataFieldHorizontalRoot(
	{
		icon,
		label,
		children,
		className,
		...otherProps
	}: DataFieldHorizontalRootProps,
	ref
) {
	return (
		<div
			className={clsx('flex w-full items-center gap-2', className)}
			ref={ref}
			{...otherProps}
		>
			<div className='flex w-44 flex-none items-center gap-2'>
				{icon}
				{label ? (
					<span className='line-clamp-1 shrink-0 grow basis-0 text-muted'>
						{label}
					</span>
				) : null}
			</div>
			{children ? (
				<div className='flex min-h-8 shrink-0 grow basis-0 items-center gap-2'>
					{children}
				</div>
			) : null}
		</div>
	)
})
