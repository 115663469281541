import React from 'react'

import { QueryClientProvider } from '@tanstack/react-query'

import ErrorBoundary from '@/components/ErrorBoundary'
import { queryClient } from '@/lib/query-client'
import { AuthProvider } from '@/modules/Auth/AuthContext'
import Sentry from '@/services/sentry'

import Router from './router'

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<ErrorBoundary>
				<AuthProvider>
					<Router />
				</AuthProvider>
			</ErrorBoundary>
		</QueryClientProvider>
	)
}
export default Sentry.withProfiler(App)
