import { filter, pipe, prop, propEq, reduce } from 'lodash/fp'

import { InlineMessage } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import { Operators } from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import { FilterRuleModel, OptimizationIDModel } from '@/generated'
import intl from '@/locale'
import { formatNumber } from '@/tools/locale'

import { Metric, MetricGroup } from '../../constants'
import { useRepricingStatisticQuery } from '../../queries'

export default function PromoWidget({
	handleNavigate,
	isLoading,
	queryParams,
}: {
	isLoading: boolean
	queryParams: {
		optimizations: OptimizationIDModel[]
		filters: FilterRuleModel[]
	} | null
	handleNavigate: ({
		widgetFilter,
		widget,
	}: {
		widgetFilter: FilterRuleModel[]
		widget: string
	}) => void
}) {
	const repricingStatisticQuery = useRepricingStatisticQuery(queryParams!, {
		enabled: !!queryParams && !isLoading,
		select: pipe(
			prop('metrics'),
			filter(propEq('group_type', MetricGroup.WithDiscount)),
			reduce(
				(acc, item: Metric) => {
					acc[item.group_type!] = item.value
					return acc
				},
				{} as Record<MetricGroup, number>
			)
		),
	})
	if (repricingStatisticQuery.isLoading || isLoading) {
		return <LoadingPlaceholder />
	}
	if (repricingStatisticQuery.isError) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger' className='w-full'>
				{repricingStatisticQuery.error.message || intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}
	const promoFilter = [
		{ name: 'new_discount_sum', operation: Operators.NE, value: 0 },
	]
	return (
		<div
			className='mb-1 flex cursor-pointer items-center justify-between text-xs font-normal hover:opacity-70'
			onClick={() =>
				handleNavigate({ widgetFilter: promoFilter, widget: 'promo widget' })
			}
		>
			<span>{intl.get('status_panel_items_with_discount')}</span>
			<Tooltip content={'PLs'}>
				<span>
					{formatNumber(
						repricingStatisticQuery.data?.[MetricGroup.WithDiscount] || 0
					)}
				</span>
			</Tooltip>
		</div>
	)
}

function LoadingPlaceholder() {
	return (
		<div className='flex flex-col items-center space-y-2'>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-20 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-10 animate-pulse rounded-lg bg-accent-4' />
			</div>
		</div>
	)
}
