import { addDays, format, startOfMonth, subDays } from 'date-fns'
import { pipe, props, uniq, zipObject } from 'lodash/fp'
import * as qs from 'qs'
import { entries } from 'lodash'

import {
	BIResponse,
	FilterRuleModel,
	MetricDeltaType,
	MetricMeasure,
	MetricModel,
	MetricsDataUnit,
	SettingsDataUnit,
	StrategyDataUnit,
} from '@/generated'
import intl from '@/locale'
import { DateFormat, utcToZonedTime } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'
import { encodeComplexQuery } from '@/components/data-grid/helpers'

import { MetricDataType } from '../../constants'

export type MetricType = {
	[key: string]: number | null
}
export type ValueTypes = {
	diffType: MetricDataType
	dataType: MetricDataType
}
export type MetricsConfig = {
	analyticKey: string
	name: string
	prefixedKey: string
	metricValueTypes: ValueTypes
}
type PrefixType = 'custom_predict_' | 'predict_'
export const METRICS_WIDGET_TYPES = {
	TARGET: 'target',
	ADDITIONAL: 'additional',
	CUSTOM: 'custom',
}
export const METRIC_PREFIX = 'predict_'
export const CUSTOM_METRIC_PREFIX = 'custom_predict_'
export const PREDICT_METRICS = ['predict_default', 'predict_custom']

export type MetricsWidgetType =
	(typeof METRICS_WIDGET_TYPES)[keyof typeof METRICS_WIDGET_TYPES]

export const getUnprefixedMetric = ({
	key,
	prefix,
}: {
	key: string
	prefix: PrefixType
}) => key.replace(new RegExp(`^${prefix}`), '')

export const getTranslationKey = (key: string, widgetType?: string) =>
	widgetType === METRICS_WIDGET_TYPES.CUSTOM
		? getUnprefixedMetric({ key, prefix: CUSTOM_METRIC_PREFIX })
		: getUnprefixedMetric({ key, prefix: METRIC_PREFIX })

export const getPrefixedMetric = ({
	metric,
	prefix,
}: {
	metric: string
	prefix: string
}) => `${prefix}${metric}`

const buildMetricMap = (metrics: string[], data: { metrics: MetricType }) => {
	return metrics.reduce((acc, metric) => {
		return {
			...acc,
			[metric]: {
				init: data.metrics[`${metric}.init`],
				final: data.metrics[`${metric}.final`],
				optimal: data.metrics[`${metric}.optimal`],
			},
		}
	}, {})
}
export const selectTargetMetrics =
	(strategy: StrategyDataUnit) => (data: { metrics: MetricType }) => {
		const { target, protect } = strategy
		const targetMetrics = [target, protect]
			.filter((metric) => metric && metric != 'empty')
			.map((metric) =>
				getPrefixedMetric({
					metric: metric as string,
					prefix: METRIC_PREFIX,
				})
			)
		return buildMetricMap(targetMetrics, data)
	}
export const selectAdditionalMetrics =
	(strategy: { target: string; protect: string }) =>
	(data: { metrics: MetricType }) => {
		const uniqMetricsKeys = getUniqMetricNames(
			data.metrics,
			CUSTOM_METRIC_PREFIX
		)
		const { target, protect } = strategy
		const prefixedMainMetrics = [
			getPrefixedMetric({ metric: target, prefix: METRIC_PREFIX }),
			getPrefixedMetric({ metric: protect, prefix: METRIC_PREFIX }),
		]
		const hiddenMetrics = [...prefixedMainMetrics, 'empty']
		const additionalMetrics = uniqMetricsKeys.filter(
			(metric) => metric && !hiddenMetrics.includes(metric)
		)
		return buildMetricMap(additionalMetrics, data)
	}

export const selectCustomMetrics = () => (data: { metrics: MetricType }) => {
	const customMetrics = getUniqMetricNames(data.metrics, METRIC_PREFIX)
	return buildMetricMap(customMetrics, data)
}
/**
 * Extracts unique metric names from an object excluding those starting with a given prefix
 *
 * @param {MetricType} metrics The object containing metrics.
 * @param {PrefixType} prefix The prefix to exclude from metric names.
 * @returns {string[]} An array of unique metric names without the prefix.
 */
export const getUniqMetricNames = (metrics: MetricType, prefix: PrefixType) =>
	uniq(
		Object.keys(metrics)
			.filter((key) => !key.startsWith(prefix))
			.map((key) => key.split('.')[0])
	)

export const getTypesByMetricSchema = (metricSchema: MetricModel) => {
	const { measure, delta_type } = metricSchema
	switch (measure) {
		case MetricMeasure.Percent:
			return delta_type === MetricDeltaType.Absolute
				? {
						diffType: MetricDataType.Percent,
						dataType: MetricDataType.Percent,
					}
				: {
						diffType: MetricDataType.PercentPoint,
						dataType: MetricDataType.Percent,
					}

		case MetricMeasure.Money:
			return delta_type === MetricDeltaType.Absolute
				? {
						diffType: MetricDataType.Number,
						dataType: MetricDataType.Number,
					}
				: {
						diffType: MetricDataType.Percent,
						dataType: MetricDataType.Number,
					}
		case MetricMeasure.Unit:
			return delta_type === MetricDeltaType.Absolute
				? {
						diffType: MetricDataType.Unit,
						dataType: MetricDataType.Unit,
					}
				: {
						diffType: MetricDataType.Percent,
						dataType: MetricDataType.Unit,
					}

		default:
			return {
				diffType: MetricDataType.Number,
				dataType: MetricDataType.Number,
			}
	}
}

/**
 * Calculate metric diff based on DiffDataType
 * @param metricValue MetricsDataUnit
 * @param format DiffDataType
 * @returns calculated value
 */
export const getMetricDiffByType = (
	metricValue: MetricsDataUnit,
	format: MetricDataType
) => {
	if (metricValue.init && metricValue.final) {
		switch (format) {
			case MetricDataType.PercentPoint:
				return getPercentPointDifference(metricValue)
			case MetricDataType.Number:
				return getDifference(metricValue)
			case MetricDataType.Percent:
				return getPercentDifference(metricValue)
			default:
				return getDifference(metricValue)
		}
	} else {
		return 0
	}
}

const getPercentPointDifference = ({ init, final }: MetricsDataUnit) =>
	(final! - init!) * 100
const getPercentDifference = ({ init, final }: MetricsDataUnit) =>
	(final! / init!) * 100 - 100
const getDifference = ({ init, final }: MetricsDataUnit) => final! - init!

export const formatMericValue = ({
	value,
	type,
}: {
	value: number
	type: MetricDataType
}) => {
	return formatAbsoluteValue(value, type)
}
export const formatAbsoluteValue = (value: number, type: MetricDataType) => {
	switch (type) {
		case MetricDataType.Percent:
			return formatNumber(value * 100, NumberFormats.Percent)
		case MetricDataType.Unit:
			return formatNumber(roundIfFloat(value))
		default:
			return formatNumber(value)
	}
}
export const formatTotalDiffValue = (value: number, type: MetricDataType) => {
	switch (type) {
		case MetricDataType.Percent:
			return formatNumber(value, NumberFormats.PercentSigned)
		case MetricDataType.PercentPoint:
			return `${formatNumber(value, NumberFormats.Fixed)}${intl.get('pp')}`
		default:
			return formatNumber(value)
	}
}

export const getMetricsAnalyticData = (analytic: BIResponse): AnalyticData => {
	if (!analytic?.meta) {
		return []
	}
	return analytic?.meta
		.map((key, index) => ({
			key,
			data: analytic.data
				.map(pipe([props([0, index]), zipObject(['x', 'y'])]))
				.map((point) => [
					Number(utcToZonedTime(new Date(point.x), 'UTC')),
					point.y,
				]),
		}))
		.filter((_item, i) => i !== 0)
}

export const getMetricAnalytic = (
	name: string,
	analyticData: AnalyticData
): unknown[] => {
	return analyticData?.find(({ key }) => key === name)?.data || []
}
const FORECAST_DAYS = 7 /**forecast data covers a period of 7 days.*/

export const getForecastPeriod = (integrationDate: string | undefined) =>
	Number(
		utcToZonedTime(
			addDays(
				integrationDate ? new Date(integrationDate) : new Date(),
				FORECAST_DAYS
			),
			'UTC'
		)
	)

export const getMetricForecast = (
	period: number | undefined,
	forecastData: number
) => [
	period,
	forecastData /
		FORECAST_DAYS /**since charts are based on daily indicators, a weekly forecast is divided into daily segments.*/,
]

export const roundIfFloat = (number: number) =>
	number % 1 !== 0 ? Math.round(number) : number
export const getPeriod = (integrationDate: string | undefined) => {
	/** As the bi-analytic endpoint returns a 0 value for the integration date and incomplete data for the penultimate day, we need to exclude these days from the period before making request to avoid a data point dropping to zero in the line chart.*/
	const date = integrationDate
		? subDays(new Date(integrationDate), 2)
		: new Date()
	return {
		start_date: format(startOfMonth(date), DateFormat.system) || '',
		end_date: format(date, DateFormat.system) || '',
	}
}
const findMetric = (metric: string, data: MetricsConfig[]) =>
	data.find(({ analyticKey }) => analyticKey === metric)
const getTargetMetricHeader = (title: string) => ({
	title: `${title} (${intl.get('opt.summary.tab.progress.target.title').d('Grow')})`,
	subtitle: intl
		.get('opt.summary.tab.progress.target.subtitle')
		.d('This is the chart that represents metric, selected to grow.'),
})
const getProtectMetricHeader = (title: string) => ({
	title: `${title} (${intl.get('opt.summary.tab.progress.protect.title').d('Maintain')})`,
	subtitle: intl
		.get('opt.summary.tab.progress.protect.subtitle')
		.d('This is the chart that represents metric, selected to maintain.'),
})
export const sortMetricsConfigByStrategy = (
	config: MetricsConfig[],
	strategy: SettingsDataUnit['strategy']
) => {
	const protectMetricIsDefined =
		strategy.protect && strategy.protect !== 'empty'
	const targetMetricIsDefined = strategy.target && strategy.target !== 'empty'
	if (!protectMetricIsDefined && !targetMetricIsDefined) {
		/**if there is no strategy, return the default configuration */
		return config
	}
	if (!targetMetricIsDefined && protectMetricIsDefined) {
		/**if there is only a protect strategy, return the configuration with the protect metric at the top */
		const protectMetricConfig = findMetric(strategy.protect!, config)
		return [
			{
				...protectMetricConfig,
				...getProtectMetricHeader(protectMetricConfig!.name),
			},
			...config
				.filter(({ analyticKey }) => analyticKey !== strategy?.protect)
				.map((metric) => ({ ...metric, title: metric.name })),
		]
	}
	if (targetMetricIsDefined && !protectMetricIsDefined) {
		/**if there is only a target strategy, return the configuration with the target metric at the top */
		const targetMetricConfig = findMetric(strategy.target!, config)
		return [
			{
				...targetMetricConfig,
				...getTargetMetricHeader(targetMetricConfig!.name),
			},
			...config
				.filter(({ analyticKey }) => analyticKey !== strategy?.target)
				.map((metric) => ({ ...metric, title: metric.name })),
		]
	} else {
		/**if there are both strategies, return the configuration with the enriched "target" and "protect" metrics at the top */
		const protectMetricConfig = findMetric(strategy.protect!, config)
		const targetMetricConfig = findMetric(strategy.target!, config)
		return [
			{
				...targetMetricConfig,
				...getTargetMetricHeader(targetMetricConfig!.name),
			},
			{
				...protectMetricConfig,
				...getProtectMetricHeader(protectMetricConfig!.name),
			},
			...config
				.filter(
					({ analyticKey }) =>
						analyticKey !== strategy?.target &&
						analyticKey !== strategy?.protect
				)
				.map((metric) => ({ ...metric, title: metric.name })),
		]
	}
}

type AnalyticData = {
	key: string
	data: unknown[][]
}[]
export const PROGRESS_TAB_METRICS = [
	'gross_profit',
	'gross_profit_margin',
	'sales_items',
	'revenue',
]
export const getQueryParams = (filters: FilterRuleModel[]) => {
	return qs.stringify(
		{
			qf: encodeComplexQuery({
				filters,
			}),
		},
		{ addQueryPrefix: true }
	)
}
export const getUnprefixedMetricKey = (key: string) =>
	key.replace(new RegExp(`^${METRIC_PREFIX}`), '')
export type OptimizationGroupsType = {
	id: string
	name: string
	status: string
	optimization: string
}
export type SelectedOptimizationGroupType = {
	id: string
	name: string
}
export const selectMetrics = (
	metrics: MetricType
): [string, MetricsDataUnit][] => {
	const uniqMetrics = getUniqMetricNamess(metrics).filter(
		(name) => !name.includes('total')
	)
	return entries(buildMetricMapp(uniqMetrics, metrics))
}
const buildMetricMapp = (metrics: string[], data: MetricType) => {
	return metrics.reduce((acc, metric) => {
		return {
			...acc,
			[metric]: {
				init: data[`${metric}.init`],
				final: data[`${metric}.final`],
				optimal: data[`${metric}.optimal`],
			},
		}
	}, {})
}

export const getUniqMetricNamess = (metrics: MetricType) => {
	return uniq(Object.keys(metrics).map((key) => key.split('.')[0]))
}
