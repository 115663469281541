import { clsx } from 'clsx'
import React, { useState } from 'react'

import { Active, useDndMonitor, useDroppable } from '@dnd-kit/core'

import { SettingsTemplateType } from '@/generated'

interface TemplateBuilderSectionProps {
	id: string
	children: React.ReactNode
	active?: boolean
	error?: boolean
	empty?: boolean
	accepts: SettingsTemplateType[]
}

export default function TemplateBuilderSection({
	id,
	children,
	active,
	error,
	empty,
	accepts,
}: TemplateBuilderSectionProps) {
	const [draggedItem, setDraggedItem] = useState<Active | null>(null)
	useDndMonitor({
		onDragStart(event) {
			setDraggedItem(event.active)
		},
		onDragCancel() {
			setDraggedItem(null)
		},
		onDragEnd() {
			setDraggedItem(null)
		},
	})

	const { isOver, setNodeRef } = useDroppable({
		id: id,
		disabled: !accepts.includes(
			draggedItem?.data?.current?.block?.template_type
		),
		data: {
			accepts,
		},
	})

	return (
		<div
			ref={setNodeRef}
			data-testid={`builder-section-${id}-droppable`}
			className={clsx(
				'flex h-full flex-col rounded-lg border-2 border-dashed bg-accent-2 p-2',
				{
					'border-brand': isOver,
					'border-brand-50': active && !isOver,
					'cursor-pointer border-2 border-dashed bg-accent-2 hover:bg-accent-3':
						empty,
					/* 'border-brand': active, */
					'border-danger hover:border-danger-75': error,
				}
			)}
		>
			{children}
		</div>
	)
}
