import { map, orderBy, pick, pipe, spread, values, zipObj } from 'lodash/fp'
import zip from 'lodash/zip'

import intl from '@/locale'

export const getSortedSeriesData = pipe([
	pick(['price', 'sales_items', 'revenue', 'gross_profit']),
	values,
	spread(zip),
	map(zipObj(['price', 'sales_items', 'revenue', 'gross_profit'])),
	orderBy(['price'], ['asc']),
	map(values),
	spread(zip),
	zipObj(['price', 'sales_items', 'revenue', 'gross_profit']),
])
export const getElasticityProps = (
	// FIXME: [type-issue] describe types
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dat: any,
	init_price: number,
	new_price: number
) => {
	const price_init = init_price
	const price_opt = new_price
	const prices = dat?.price ?? []
	const sales_items = dat?.sales_items ?? []
	const revenue = dat?.revenue ?? []
	const gross_profit = dat?.gross_profit ?? []
	const useBondary = Math.max(...sales_items) < 1
	return {
		maxY1: (useBondary ? Math.max(price_init, price_opt) : undefined) as number,
		maxY2: (useBondary
			? Math.max(Math.max(...sales_items), 1)
			: undefined) as number,
		plotLines:
			price_init == price_opt
				? [
						{
							color: '#97dcff',
							width: 1,
							dashStyle: 'Dash' as const,
							label: {
								text: intl.get('init_price'),
								style: {
									color: 'var(--cmp-foreground)',
								},
							},
							value: price_init,
						},
					]
				: [
						{
							color: '#97dcff',
							width: 1,
							dashStyle: 'Dash' as const,
							label: {
								text: intl.get('init_price'),
								style: {
									color: 'var(--cmp-foreground)',
								},
							},
							value: price_init,
						},
						{
							color: '#ccc',
							width: 1,
							dashStyle: 'Dash' as const,
							label: {
								text: intl.get('general_new_price'),
								style: {
									color: 'var(--cmp-foreground)',
								},
							},
							value: price_opt,
						},
					],

		series: [
			{
				type: 'spline' as const,
				name: intl.get('sales_items'),
				color: '#FF9800',
				yAxis: 1,
				data: zip(prices, sales_items),
			},
			{
				type: 'spline' as const,
				name: intl.get('revenue'),
				color: '#00B557',
				yAxis: 0,
				data: zip(prices, revenue),
			},
			{
				type: 'spline' as const,
				name: intl.get('profit'),
				color: '#F44236',
				yAxis: 0,
				data: zip(prices, gross_profit),
			},
		],
	}
}
