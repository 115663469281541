import { filter } from 'lodash/fp'
import React from 'react'
import { useNavigate } from 'react-router'

import CheckCircleIcon from '@cmpkit/icon/lib/glyph/check-circle'

import PricingAlerts from '@/components/PricingAlerts'
import { AlertGroupBy } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import { getPricingAlertsTree } from '@/modules/core'
import {
	usePricingAlertsAnnotationsQuery,
	usePricingAlertsQuery,
} from '@/modules/core/queries'

export default function PricingAlertsWidget() {
	const navigate = useNavigate()
	const optimizationGroupId = useOptimizationGroupId()
	const annotations = usePricingAlertsAnnotationsQuery()
	const pricingAlerts = usePricingAlertsQuery(
		{
			group_by: AlertGroupBy.OptimizationGroupId,
			total_by_group: true,
			optimization_group_ids: [optimizationGroupId as string],
		},
		{
			select: (data) => ({
				...data,
				by_level: filter(
					{ optimization_group_id: optimizationGroupId },
					data.by_level
				),
				by_id: filter(
					{ optimization_group_id: optimizationGroupId },
					data.by_id
				),
			}),
		}
	)
	const alertsTree = getPricingAlertsTree({
		pricingAlerts: pricingAlerts.data,
		annotations: annotations.data,
	})
	const handleNavigate = (ids: string[]) =>
		navigate(
			`/p/og/${optimizationGroupId}/products?alerts__array_any_of=${ids.join(',')}`
		)
	const isLoading = pricingAlerts.isLoading || annotations.isLoading
	const isError = pricingAlerts.isError || annotations.isError
	if (isLoading) {
		return (
			<div className='flex w-full flex-col justify-center space-y-2'>
				<div className='h-10 w-full animate-pulse rounded-lg bg-accent-4' />
				<div className='h-10 w-full animate-pulse rounded-lg bg-accent-4' />
				<div className='h-10 w-full animate-pulse rounded-lg bg-accent-4' />
			</div>
		)
	}
	if (isError) {
		return null
	}
	return (
		<>
			<div className='w-full'>
				{!alertsTree?.length ? (
					<div className='flex items-center justify-center text-center text-success'>
						<CheckCircleIcon
							className='mr-2 fill-success'
							height={24}
							width={24}
						/>
						No alerts detected
					</div>
				) : (
					<PricingAlerts
						analyticPerfix='alerts: og:'
						alertsTree={alertsTree}
						onNavigate={handleNavigate}
					/>
				)}
			</div>
		</>
	)
}
