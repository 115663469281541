import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import {
	closestCenter,
	defaultDropAnimationSideEffects,
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	DropAnimation,
	KeyboardSensor,
	PointerSensor,
	UniqueIdentifier,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
	arrayMove,
	defaultAnimateLayoutChanges,
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { yupResolver } from '@hookform/resolvers/yup'

import {
	Button,
	Checkbox,
	InlineMessage,
	Loader,
	Textfield,
} from '@cmpkit/base'
import ArrowRightIcon from '@cmpkit/icon/lib/glyph/arrow-right'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import DotsDraggableIcon from '@cmpkit/icon/lib/glyph/dots-draggable'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@cmpkit/modal'
import { getFieldIcon } from '@cmpkit/query-builder/dist/helpers'

import ErrorBoundary from '@/components/ErrorBoundary'
import { FormProvider } from '@/components/HookForm'
import intl from '@/locale'
import analytic from '@/services/analytics'

import { getSchemaTypeString } from '../helpers'
import { ColumnConfig } from '../types'
import { useExportConfiguration } from './ExportConfigurationProvider'

interface ExportFieldsModalContentProps {
	isLoading?: boolean
	onClose(): void
	onSubmit(data: ExportFieldsFormData): void
	onBack(): void
	defaultValues: ExportFieldsFormData
}
export function ExportFieldsModalContent({
	isLoading,
	onClose,
	onSubmit,
	onBack,
	defaultValues,
}: ExportFieldsModalContentProps) {
	const { kind } = useExportConfiguration()
	useEffect(() => {
		analytic.track('export: params: fields configuration: open', {
			export_kind: kind,
		})
	}, [])
	const methods = useForm<ExportFieldsFormData>({
		defaultValues,
		resolver: yupResolver(ExportFieldsSchema),
	})

	const handleSubmit = methods.handleSubmit(onSubmit)
	if (isLoading) {
		return (
			<Modal onClose={onClose} size={'mini'} testId='export-data-modal-form'>
				<div className='flex items-center justify-center py-44'>
					<Loader />
				</div>
			</Modal>
		)
	}
	return (
		<Modal
			onClose={onClose}
			showCloseButton
			size={'large'}
			testId='export-data-modal-fields'
		>
			<ModalHeader>
				<ModalTitle>
					{intl
						.get('export.fields.modal_title')
						.d('Define columns to be downloaded')}
				</ModalTitle>
			</ModalHeader>
			<ErrorBoundary
				fallback={
					<div className='flex flex-col justify-center py-20 text-center'>
						<p className='text-lg font-bold'>
							{intl.get('fatal_error_title')}🐞
						</p>
						<p className='text-muted'>{intl.get('fatal_error_desc')}</p>
					</div>
				}
			>
				<ModalBody>
					{methods.formState.isDirty && (
						<InlineMessage
							variant='neutral'
							className='mb-2 text-xs font-medium'
						>
							{intl
								.get('export.fields.modal_save_custom_template_hint')
								.d('Changed view will be saved as Custom columns template')}
						</InlineMessage>
					)}
					<FormProvider methods={methods} onSubmit={handleSubmit}>
						<div className='mb-1 mr-5 flex'>
							<div className='w-1/2 font-semibold'>
								{intl
									.get('export.fields.modal_col_1')
									.d('1. Select columns you want to download:')}
							</div>
							<div className='w-1/2 font-semibold'>
								{intl
									.get('export.fields.modal_col_2')
									.d('2. Rename columns if needed:')}
							</div>
						</div>
						<div className='max-h-96 overflow-y-auto'>
							<Controller
								name='fields'
								render={({ field, fieldState }) => {
									return (
										<ExportFieldsConfig
											kind={kind}
											value={field.value}
											onChange={field.onChange}
											errors={
												fieldState.error as unknown as Record<
													string,
													FieldError
												>[]
											}
										/>
									)
								}}
							/>
						</div>
					</FormProvider>
				</ModalBody>
				<ModalFooter className='justify-between'>
					<Button onClick={onClose} data-testid='export-data-modal-cancel'>
						{intl.get('general_cancel')}
					</Button>
					<div className='flex items-center space-x-2'>
						<Button
							iconBefore={<CaretLeftIcon />}
							onClick={onBack}
							data-testid='export-data-modal-back'
						>
							{intl.get('general_back')}
						</Button>
						<Button
							variant='primary-brand'
							onClick={handleSubmit}
							data-testid='export-data-modal-submit'
						>
							{intl.get('app.export').d('Export')}
						</Button>
					</div>
				</ModalFooter>
			</ErrorBoundary>
		</Modal>
	)
}
const dropAnimationConfig: DropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: '0.5',
			},
		},
	}),
}
export function ExportFieldsConfig({
	value,
	onChange,
	errors,
	kind,
}: {
	kind: string
	value: ColumnConfig[]
	onChange: (value: ColumnConfig[]) => void
	errors?: Record<string, FieldError>[]
}) {
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
	const items = value.map((columnConfig) => ({
		id: columnConfig.key,
		item: columnConfig,
	}))
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)
	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event
		setActiveId(active.id)
	}
	const ids: UniqueIdentifier[] = items.map(({ id }) => id)
	const getIndex = (id: UniqueIdentifier) => ids.indexOf(id)
	const activeIndex = activeId != null ? getIndex(activeId) : -1
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (active.id !== over?.id && over) {
			const oldIndex = ids.indexOf(active.id)
			const newIndex = ids.indexOf(over.id)
			const reordered = arrayMove(items, oldIndex, newIndex)
			analytic.logEvent('columns: order: changed')
			onChange(reordered.map(({ item }) => item))
		}
		setActiveId(null)
	}

	const _handleChangeCheckbox = (field: string, enabled: boolean) => {
		analytic.track('export: params: fields configuration: toggle visability', {
			export_kind: kind,
			field,
			enabled,
		})
		onChange(
			value.map((config) =>
				config.key === field ? { ...config, enabled } : config
			)
		)
	}
	const _handleChangeTextField = (field: string, name: string) => {
		analytic.track('export: params: fields configuration: change field name', {
			export_kind: kind,
			field,
			name,
		})
		onChange(
			value.map((config) =>
				config.key === field ? { ...config, custom_name: name } : config
			)
		)
	}
	return (
		<DndContext
			modifiers={[restrictToVerticalAxis]}
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
		>
			<SortableContext items={items} strategy={verticalListSortingStrategy}>
				{items.map(({ id, item }, index) => {
					return (
						<SortableItem
							key={id}
							id={id}
							item={item}
							errors={errors?.[index]}
							handlers={{
								onChangeCheckbox: _handleChangeCheckbox,
								onChangeTextField: _handleChangeTextField,
							}}
						/>
					)
				})}
			</SortableContext>
			<DragOverlay dropAnimation={dropAnimationConfig}>
				{activeId ? (
					<div className='flex items-center justify-between p-1'>
						<ColumnItem item={items[activeIndex].item} />
						<span className='ml-2 flex cursor-grab items-center text-disabled'>
							<DotsDraggableIcon />
						</span>
					</div>
				) : null}
			</DragOverlay>
		</DndContext>
	)
}
type SortableItemProps = {
	id: string
	item: ColumnConfig
	errors?: Record<string, FieldError>
	handlers?: {
		onChangeCheckbox(name: string, enabled: boolean): void
		onChangeTextField(name: string, value: string): void
	}
	className?: string
}

function SortableItem({
	id,
	item,
	handlers,
	className,
	errors,
	...props
}: SortableItemProps) {
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({
		id,
		animateLayoutChanges: defaultAnimateLayoutChanges,
	})
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
	}

	return (
		<div ref={setNodeRef} style={style} {...attributes} {...props}>
			<div
				className={clsx('flex items-center justify-between p-1', className, {
					'opacity-0': isDragging,
				})}
			>
				<ColumnItem
					errors={errors}
					item={item}
					handlers={handlers}
					{...attributes}
				/>
				<span
					className='ml-2 flex cursor-grab items-center text-disabled'
					{...listeners}
				>
					<DotsDraggableIcon />
				</span>
			</div>
		</div>
	)
}
type ColumnItemProps = {
	item: ColumnConfig
	errors?: Record<string, FieldError>
	handlers?: {
		onChangeCheckbox(name: string, enabled: boolean): void
		onChangeTextField(name: string, value: string): void
	}
}
function ColumnItem({ item, handlers, errors }: ColumnItemProps) {
	return (
		<div className='flex w-full items-center'>
			<div className='flex w-1/2'>
				<Checkbox
					name={item.key + ''}
					checked={item.enabled}
					className='flex items-center'
					onChange={() => handlers?.onChangeCheckbox(item.key, !item.enabled)}
				/>
				<div className='ml-2 flex items-center space-x-1'>
					{getFieldIcon({
						name: getSchemaTypeString(item.type),
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} as any)}
					<span className='text-xs font-medium'>{item.name}</span>
					<span className='text-xs text-muted'>
						({intl.get(`type.${item.type}`).d(item.type)})
					</span>
				</div>
				<ArrowRightIcon className='ml-auto mr-2 text-muted' />
			</div>
			<div className='flex w-1/2'>
				<Textfield
					className='w-full'
					disabled={!item.enabled}
					value={item.custom_name}
					invalid={!!errors?.custom_name}
					onChange={(e) =>
						handlers?.onChangeTextField(item.key, e.target.value)
					}
				/>
			</div>
		</div>
	)
}
export const ExportFieldsSchema = Yup.object().shape({
	fields: Yup.array()
		.of(
			Yup.object().shape({
				key: Yup.string().required(),
				enabled: Yup.boolean().required(),
				name: Yup.string().required(),
				custom_name: Yup.string().required('Name is required'),
				static_value: Yup.string().nullable(),
				type: Yup.string(),
			})
		)
		.required(),
})

export type ExportFieldsFormData = Yup.InferType<typeof ExportFieldsSchema>
