import { kebabCase, prop } from 'lodash/fp'
import React, { useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import notify from '@/components/toasts/helpers'
import intl from '@/locale'
import analytic from '@/services/analytics'
import { download } from '@/tools/report'

import { createExcelFile, mergeConfigs } from '../helpers'
import { ColumnConfig } from '../types'
import ExportConfigurationProvider from './ExportConfigurationProvider'
import { ExportFieldsFormData } from './ExportFieldsModal'
import ExportModal from './ExportModal'
import { ExportParamsFormData } from './ExportParamsModal'

interface ExportAdditionalDataModalProps {
	kind: string
	fields: ColumnConfig[]
	rows: Record<string, unknown>[]
	defaultName?: string
	isOpen: boolean
	close(): void
}
export default function ExportAdditionalDataModal(
	props: ExportAdditionalDataModalProps
) {
	const [customExportColumns, setCustomExportColumns] = useLocalStorage<
		ColumnConfig[]
	>(`export_fields_${props.kind}`, [])
	const fieldsPresetOptions = useMemo(() => {
		return [
			{
				value: 'all',
				label: intl.get('app.all_columns').d('All columns'),
				preset: props.fields,
			},
			{
				value: 'custom',
				label: intl.get('app.custom_columns').d('Custom columns'),
				preset: mergeConfigs(props.fields || [], customExportColumns || []),
			},
		]
	}, [props.fields, customExportColumns])

	const handleSubmit = async (
		formData: ExportParamsFormData & ExportFieldsFormData
	) => {
		setCustomExportColumns(formData.fields as ColumnConfig[])
		analytic.logEvent('export: modal: create export', {
			export_kind: props.kind,
			...formData,
		})
		const filename = `${kebabCase(formData.name)}.${formData.format}`
		const blob = await createExcelFile({
			name: formData.name,
			fields: formData.fields.filter(prop('enabled')) as ColumnConfig[],
			rows: props.rows,
			format: formData.format,
		})
		download(blob, filename)
		notify.success(
			{
				text: intl
					.get('file.downloading.started', { filename })
					.d(`File "${filename}" downloading started`),
			},
			{ id: 'export' }
		)
		props.close()
	}
	return (
		<ExportConfigurationProvider
			kind={props.kind}
			isAdditional
			defaultValues={{
				name: props.defaultName || 'Report',
			}}
			fieldsPresetOptions={fieldsPresetOptions}
			onSubmit={handleSubmit}
		>
			<ExportModal isOpen={props.isOpen} close={props.close} />
		</ExportConfigurationProvider>
	)
}
