import Ajv, { JSONSchemaType } from 'ajv'
import clsx from 'clsx'
import { entries, isEmpty, isNil, keys, prop } from 'lodash/fp'
import React from 'react'

import { DataOption } from '@/common.types'
import {
	getEnumOptions,
	guessType,
	JSONSchemaString,
	UISchema,
} from '@/tools/json-schema-utils'

const ajv = new Ajv({
	strict: false,
})

type Data = any // eslint-disable-line @typescript-eslint/no-explicit-any
type AnyJSONScheme = JSONSchemaType<any> // eslint-disable-line @typescript-eslint/no-explicit-any
interface SchemaInterpretatorProps {
	schema: AnyJSONScheme
	data: Data
	uiSchema?: UISchema
}
interface SchemaFieldProps {
	className?: string
	schema: AnyJSONScheme
	name?: string
	data: Data
	uiSchema?: UISchema
}
function getFieldLabel(name: string, schema: AnyJSONScheme) {
	return schema.title || '' //last(split('.', name))
}
function getOrderedObjectFields(
	object: Record<string, AnyJSONScheme>,
	order?: string[]
): [string, AnyJSONScheme][] {
	return order?.length === keys(object)?.length
		? order.map((name) => [name, object?.[name]])
		: entries(object)
}

const getUiSchema = (name: string, uiSchema: UISchema) => {
	if (name) {
		const path = name
			.split('.')
			.filter((a) => Number.isNaN(+a))
			.join('.')
		return prop(path, uiSchema)
	} else {
		return uiSchema
	}
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function prepareSchema(schema: AnyJSONScheme, value: any) {
	if (
		!schema?.type &&
		(Array.isArray(schema.oneOf) || Array.isArray(schema.anyOf))
	) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const matchedSchema = schema.oneOf.find((s: any) => ajv.validate(s, value))

		if (!matchedSchema) {
			return schema
		}
		return {
			...schema,
			...matchedSchema,
		}
	}
	if (Array.isArray(schema.allOf)) {
		return { ...schema, ...Object.assign({}, ...schema.allOf) }
	}
	/* if (schema.type && Array.isArray(schema?.type)) {
		return {
			...schema,
			type: schema.type[0],
		}
	} */
	return schema
}
function Label(props: { children: string }) {
	return <span className='font-medium text-muted'>{props.children}</span>
}
export function SchemaField({
	className,
	schema,
	data,
	name: _name = '',
	uiSchema: _uiSchema,
}: SchemaFieldProps) {
	const value = prop(_name, data)
	const _schema = prepareSchema(schema, value)
	const uiSchema = getUiSchema(_name, _uiSchema)
	const label = getFieldLabel(_name, _schema)
	const defaultValue = _schema?.default

	const commonProps = {
		name: _name,
		label,
		value,
		defaultValue,
	}

	const getFieldDataChoices = (): DataOption[] =>
		uiSchema?.['ui:options'] ||
		(_schema?.enum ? getEnumOptions(_schema as JSONSchemaString) : []) ||
		[]

	const hasFieldDataChoices = () => uiSchema?.['ui:options'] || _schema?.enum
	let renderType = _schema?.type
	if (Array.isArray(_schema?.type) || !_schema?.type) {
		renderType = guessType(value)
	}
	switch (renderType) {
		case 'object': {
			const fields = getOrderedObjectFields(
				_schema.properties,
				uiSchema?.['ui:order']
			)
			return (
				<>
					{fields?.map(([name, schema]) => {
						return (
							<SchemaField
								className={className}
								key={name}
								data={data}
								schema={schema}
								name={_name ? `${_name}.${name}` : name}
								uiSchema={_uiSchema}
							/>
						)
					})}
				</>
			)
		}
		case 'boolean':
			return (
				<div data-type={renderType} className={clsx('flex gap-1', className)}>
					<Label>{commonProps.label}</Label>
					<span>{value ? 'Yes' : 'No'}</span>
				</div>
			)

		case 'number':
		case 'integer':
			return (
				<div data-type={renderType} className={clsx('flex gap-1', className)}>
					<Label>{commonProps.label}</Label>
					<span className='font-medium'>{isNil(value) ? '-' : value}</span>
				</div>
			)
		case 'string': {
			if (hasFieldDataChoices()) {
				const options = getFieldDataChoices()

				return (
					<div data-type={renderType} className={clsx('flex gap-1', className)}>
						<Label>{commonProps.label}</Label>
						<span className='font-medium'>
							{options.find((option) => option.value === value)?.label}
						</span>
					</div>
				)
			}
			return (
				<div data-type={renderType} className={clsx('flex gap-1', className)}>
					<Label>{commonProps.label}</Label>
					<span className='font-medium'>{isNil(value) ? '-' : value}</span>
				</div>
			)
		}
		case 'array': {
			if (value?.length === 0) {
				return (
					<div
						data-type={renderType}
						className={clsx('inline-flex gap-1', className)}
					>
						<Label>{commonProps.label}</Label> <span>-</span>
					</div>
				)
			}
			if (hasFieldDataChoices()) {
				return (
					<div
						data-type={renderType}
						className={clsx('inline-flex gap-1', className)}
					>
						<Label>{commonProps.label}</Label>
						<span className='font-medium'>
							{value
								?.map((v: Data) => {
									const options = getFieldDataChoices()
									return options.find((option) => option.value === v)?.label
								})
								.join(', ')}
						</span>
					</div>
				)
			} else if (_schema.items) {
				return (
					<div data-type={renderType} className={clsx('w-full', className)}>
						<Label>{commonProps.label}</Label>
						<div className='flex w-full flex-col'>
							{value?.map((v: Data, index: number) => (
								<div className='flex w-full gap-2'>
									<SchemaField
										className='flex flex-col'
										key={index}
										data={data}
										schema={prepareSchema(_schema.items, value)}
										name={_name ? `${_name}.${index}` : String(index)}
										uiSchema={_uiSchema}
									/>
								</div>
							))}
						</div>
					</div>
				)
			} else {
				return (
					<div
						data-type={renderType}
						className={clsx('inline-flex gap-1', className)}
					>
						<Label>{commonProps.label}</Label>{' '}
						<span className='font-medium'>
							{value?.length > 3 ? `${value?.length} Items` : value?.join(',')}{' '}
						</span>
					</div>
				)
			}
		}
		default: {
			return (
				<div data-type={renderType} className='flex items-center'>
					-
				</div>
			)
		}
	}
}

export default function SchemaInterpretator({
	schema,
	data,
	uiSchema,
}: SchemaInterpretatorProps) {
	return <SchemaField schema={schema} data={data} uiSchema={uiSchema} />
}

export function getSchemaIntertpretationsList({
	schema,
	data,
	uiSchema,
}: SchemaInterpretatorProps) {
	return interpretateSchema({ schema, data, uiSchema })
}
interface SchemaInterpretation {
	key: string
	type: string
	label: string
	currentValue: string | number | boolean | null
	defaultValue: string | number | boolean | null
	resolvedValue:
		| string
		| number
		| boolean
		| null
		| undefined
		| DataOption[]
		| DataOption
	options: DataOption[]
}
export function interpretateSchema(
	{ schema, data, name = '', uiSchema }: SchemaFieldProps,
	intertpretations: SchemaInterpretation[] = []
) {
	const currentValue = prop(name, data)
	const currentFieldSchema = prepareSchema(schema, currentValue)
	const currentFieldUiSchema = getUiSchema(name, uiSchema)
	const options: DataOption[] =
		currentFieldUiSchema?.['ui:options'] ||
		(currentFieldSchema?.enum
			? getEnumOptions(currentFieldSchema as JSONSchemaString)
			: []) ||
		[]

	const interpretation = {
		key: name,
		type: currentFieldSchema?.type,
		label: getFieldLabel(name, currentFieldSchema),
		currentValue,
		options,
		defaultValue: currentFieldSchema?.default,
		resolvedValue: options?.find((option) => option.value === currentValue),
	}
	if (interpretation.type === 'object') {
		const fields = getOrderedObjectFields(
			currentFieldSchema.properties,
			currentFieldUiSchema?.['ui:order']
		)
		for (let index = 0; index < fields.length; index++) {
			const [_name, schema] = fields[index]
			interpretateSchema(
				{
					data,
					schema,
					name: name ? `${name}.${_name}` : _name,
					uiSchema,
				},
				intertpretations
			)
		}
	} else if (interpretation.type === 'array') {
		if (interpretation.currentValue?.length === 0) {
			intertpretations.push(interpretation)
		} else if (!isEmpty(interpretation.options)) {
			intertpretations.push({
				...interpretation,
				resolvedValue: interpretation.currentValue?.map((v: Data) => {
					return interpretation.options.find((option) => option.value === v)
						?.label
				}),
			})
		} else {
			for (
				let index = 0;
				index < interpretation.currentValue?.length;
				index++
			) {
				interpretateSchema(
					{
						data: data,
						schema: prepareSchema(
							currentFieldSchema.items,
							interpretation.currentValue
						),
						name: name ? `${name}.${index}` : String(index),
						uiSchema,
					},
					intertpretations
				)
			}
		}
	} else {
		intertpretations.push(interpretation)
	}
	return intertpretations
}
