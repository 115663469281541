import { AxiosError } from 'axios'
import { omitAll } from 'lodash/fp'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
	HTTPValidationError,
	PricingCampaignCopySettingsRequestModel,
	PricingCampaignCopySettingsResponseModel,
	PricingCampaignCreateDataModel,
	PricingCampaignDuplicateRequest,
	PricingCampaignDuplicateScenariosRequest,
	PricingCampaignModel,
	PricingCampaignProductsFilters,
	PricingCampaignReassignRequest,
	PricingCampaignReorderRequestModel,
	PricingCampaignUpdateDataModel,
} from '@/generated'
import { client } from '@/network/client'

import {
	PricingCampaignDuplicationResultModel,
	PricingCampaignReassignResultModel,
} from './types'

export function useCreatePricingCampaignMutation(
	options?: UseMutationOptions<
		PricingCampaignModel,
		Error,
		PricingCampaignCreateDataModel
	>
) {
	return useMutation<
		PricingCampaignModel,
		Error,
		PricingCampaignCreateDataModel
	>({
		mutationFn: (newPricingCampaign) => {
			return client.pricingCampaign.pricingCampaignCreate(newPricingCampaign)
		},
		...(options || {}),
	})
}
export function useDeletePricingCampaignMutation(
	options?: UseMutationOptions<void, Error, string>
) {
	return useMutation<void, Error, string>({
		mutationKey: ['pricing-campaign-delete'],
		mutationFn: (pricingCampaignId) =>
			client.pricingCampaign.pricingCampaignDelete(pricingCampaignId),
		...(options || {}),
	})
}
export function useUpdatePricingCampaignMutation(
	options?: UseMutationOptions<
		PricingCampaignModel,
		Error,
		PricingCampaignUpdateDataModel & { id: string }
	>
) {
	return useMutation<
		PricingCampaignModel,
		Error,
		PricingCampaignUpdateDataModel & { id: string }
	>({
		mutationFn: (pricingCampaign) => {
			return client.pricingCampaign.pricingCampaignUpdate(
				pricingCampaign.id,
				omitAll(['id'], pricingCampaign)
			)
		},
		...(options || {}),
	})
}

export function useDublicatePricingCampaignsMutation(
	options?: UseMutationOptions<
		PricingCampaignDuplicationResultModel[],
		Error,
		PricingCampaignDuplicateRequest
	>
) {
	return useMutation<
		PricingCampaignDuplicationResultModel[],
		Error,
		PricingCampaignDuplicateRequest
	>({
		mutationFn: (body) => {
			return client.pricingCampaign.pricingCampaignsDuplicate(body)
		},
		...(options || {}),
	})
}
export function useBulkDeletePricingCampaignsMutation(
	options?: UseMutationOptions<PromiseSettledResult<void>[], Error, string[]>
) {
	return useMutation<PromiseSettledResult<void>[], Error, string[]>({
		mutationFn: (payload) => {
			return Promise.allSettled(
				payload.map((pricingCampaignId) =>
					client.pricingCampaign.pricingCampaignDelete(pricingCampaignId)
				)
			)
		},
		...(options || {}),
	})
}
export function useDublicatePricingCampaignsToScenarioMutation(
	options?: UseMutationOptions<
		any[], // eslint-disable-line @typescript-eslint/no-explicit-any
		Error,
		PricingCampaignDuplicateScenariosRequest
	>
) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return useMutation<any[], Error, PricingCampaignDuplicateScenariosRequest>({
		mutationFn: (body) => {
			return client.pricingCampaign.pricingCampaignsDuplicateToScenarios(body)
		},
		...(options || {}),
	})
}
export function useReassignPricingCampaignsMutation(
	options?: UseMutationOptions<
		PricingCampaignReassignResultModel[],
		Error,
		PricingCampaignReassignRequest
	>
) {
	return useMutation<
		PricingCampaignReassignResultModel[],
		Error,
		PricingCampaignReassignRequest
	>({
		mutationFn: (body) => {
			return client.pricingCampaign.pricingCampaignsReassign(body)
		},
		...(options || {}),
	})
}
export function useCopyPricingCampaignSettingsMutation(
	options?: UseMutationOptions<
		PricingCampaignCopySettingsResponseModel[],
		Error,
		PricingCampaignCopySettingsRequestModel
	>
) {
	return useMutation<
		PricingCampaignCopySettingsResponseModel[],
		Error,
		PricingCampaignCopySettingsRequestModel
	>({
		mutationFn: (body) => {
			return client.pricingCampaign.pricingCampaignsCopySettings(body)
		},
		...(options || {}),
	})
}
export type PricingCampaignAssignProductsArgs = {
	pricingCampaignId: string
	data: PricingCampaignProductsFilters
}
export function useAssignProductsMutation(
	options?: UseMutationOptions<
		void,
		AxiosError<HTTPValidationError>,
		PricingCampaignAssignProductsArgs
	>
) {
	return useMutation<
		void,
		AxiosError<HTTPValidationError>,
		PricingCampaignAssignProductsArgs
	>({
		mutationFn: ({
			pricingCampaignId,
			data,
		}: PricingCampaignAssignProductsArgs) => {
			return client.pricingCampaign.pricingCampaignAssignProducts(
				pricingCampaignId,
				data
			)
		},
		...(options || {}),
	})
}
export function usePricingCampaignUnassignProducts(
	options?: UseMutationOptions<void, Error, PricingCampaignProductsFilters>
) {
	return useMutation<void, Error, PricingCampaignProductsFilters>({
		mutationFn: (body: PricingCampaignProductsFilters) => {
			return client.pricingCampaign.pricingCampaignUnassignProducts(body)
		},
		...(options || {}),
	})
}
export function useReorderPricingCampaignsMutation(
	options?: UseMutationOptions<
		void,
		AxiosError<HTTPValidationError>,
		PricingCampaignReorderRequestModel
	>
) {
	return useMutation<
		void,
		AxiosError<HTTPValidationError>,
		PricingCampaignReorderRequestModel
	>({
		mutationFn: (payload) => {
			return client.pricingCampaign.pricingCampaignReorder(payload)
		},
		...(options || {}),
	})
}
