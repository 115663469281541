import clsx from 'clsx'
import { findIndex, flatten, map, prop, propEq, sum } from 'lodash/fp'
import React from 'react'

import {
	Cell,
	Column,
	flexRender,
	Header,
	HeaderGroup,
	Row,
	Table as TableReact,
} from '@tanstack/react-table'

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@cmpkit/base'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretUpIcon from '@cmpkit/icon/lib/glyph/caret-up'
import Tooltip from '@cmpkit/tooltip'

export default function DataTable<T>({
	table,
	divideX,
	divideY,
	getRowClassName,
	getCellClassName,
	compact,
	className,
}: {
	className?: string
	table: TableReact<T>
	getRowClassName?: (row: Row<T>) => string
	getCellClassName?: (row: Row<T>, cell: Cell<T, unknown>) => string
	compact?: boolean
	divideX?: boolean
	divideY?: boolean
}) {
	const headerCellClassName = clsx({
		'px-2 py-1': compact,
		'px-3 py-2': !compact,
		'border-r': divideX,
		'border-b': divideY,
	})
	const bodyCellClassName = clsx({
		'px-2': compact,
		'px-3': !compact,
		'border-r': divideX,
		'border-b': divideY,
	})
	const tableHeaderGroups = table.getHeaderGroups()

	return (
		<div className={clsx('relative w-full overflow-auto', className)}>
			<Table className='table-fixed border-separate border-spacing-0'>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead
										key={header.id}
										className={headerCellClassName}
										style={getCellStyle(
											header.column,
											getLeftOffset(header.id, tableHeaderGroups)
										)}
									>
										<div
											{...{
												className: header.column.getCanSort()
													? 'cursor-pointer select-none flex items-center'
													: '',
												onClick: header.column.getToggleSortingHandler(),
											}}
										>
											<Tooltip
												content={header.column.columnDef.header as string}
											>
												<span className='truncate'>
													{flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}
												</span>
											</Tooltip>
											{{
												asc: <CaretUpIcon className='flex-none' />,
												desc: <CaretDownIcon className='flex-none' />,
											}[header.column.getIsSorted() as string] ?? null}
										</div>
										{header.column.getCanResize() && (
											<div
												{...{
													onDoubleClick: () => header.column.resetSize(),
													onMouseDown: header.getResizeHandler(),
													onTouchStart: header.getResizeHandler(),
													className: `resizer ${
														header.column.getIsResizing() ? 'isResizing' : ''
													}`,
													style: {
														transform: header.column.getIsResizing()
															? `translateX(${
																	(table.options.columnResizeDirection === 'rtl'
																		? -1
																		: 1) *
																	(table.getState().columnSizingInfo
																		.deltaOffset ?? 0)
																}px)`
															: '',
													},
												}}
											/>
										)}
									</TableHead>
								)
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows.map((row) => {
						return (
							<TableRow
								key={row.id}
								className={getRowClassName?.(row) || 'h-12'}
							>
								{row.getVisibleCells().map((cell) => {
									return (
										<TableCell
											key={cell.id}
											className={clsx(
												bodyCellClassName,
												getCellClassName?.(row, cell)
											)}
											style={getCellStyle<T>(
												cell.column,
												getLeftOffset<T>(cell.column.id, tableHeaderGroups)
											)}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</TableCell>
									)
								})}
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</div>
	)
}
function getCellStyle<T>(
	column: Column<T, unknown>,
	left: number
): React.CSSProperties {
	const width =
		column.getSize() === Number.MAX_SAFE_INTEGER ? 'auto' : column.getSize()
	if (column.getIsPinned() === 'left') {
		return {
			width,
			position: 'sticky',
			backgroundColor: 'inherit',
			zIndex: 1,
			left,
		}
	} else {
		return {
			width,
			position: 'relative',
		}
	}
}
function getLeftOffset<T>(
	id: string,
	tableHeaderGroups: HeaderGroup<T>[]
): number {
	const isHeaderPinned = (header: Header<T, unknown>) =>
		header.column.getIsPinned() === 'left'
	const headers = flatten(map(prop('headers'), tableHeaderGroups))
	const index = findIndex(propEq('id', id), headers)
	const firstPinnedIndex = findIndex(isHeaderPinned, headers)
	const widths = headers
		.slice(firstPinnedIndex, index)
		.filter(isHeaderPinned)
		.map((header) => header.getSize())
	return sum(widths)
}

/* import clsx from 'clsx'
import React from 'react'

import { flexRender, Row, Table as TableReact } from '@tanstack/react-table'

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@cmpkit/base'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretUpIcon from '@cmpkit/icon/lib/glyph/caret-up'

type DataTableProps<T> = {
	table: TableReact<T>
	widths?: Array<string | number>
	getRowClassName?: (row?: Row<T>) => string
	compact?: boolean
}
export default function DataTable<T>({
	table,
	widths,
	getRowClassName,
	compact,
}: DataTableProps<T>) {
	const headerCellClassName = clsx({
		'px-2 py-1': compact,
		'px-3 py-2': !compact,
	})
	const bodyCellClassName = clsx({
		'px-2': compact,
		'px-3': !compact,
	})
	return (
		<div className='relative w-full overflow-y-auto'>
			<Table className='table-fixed border-separate border-spacing-0'>
				{widths && (
					<colgroup>
						{widths.map((width, index) => (
							<col key={index} width={width} />
						))}
					</colgroup>
				)}
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead key={header.id} className={headerCellClassName}>
										<div
											{...{
												className: header.column.getCanSort()
													? 'cursor-pointer select-none flex items-center'
													: '',
												onClick: header.column.getToggleSortingHandler(),
											}}
										>
											<span className='truncate'>
												{flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
											</span>
											{{
												asc: <CaretUpIcon />,
												desc: <CaretDownIcon />,
											}[header.column.getIsSorted() as string] ?? null}
										</div>
									</TableHead>
								)
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows.map((row) => (
						<TableRow
							key={row.id}
							className={clsx('', getRowClassName?.(row) ?? '')}
						>
							{row.getVisibleCells().map((cell) => (
								<TableCell className={bodyCellClassName} key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	)
}
 */
