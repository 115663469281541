import { omitAll } from 'lodash/fp'

import { Placement } from '@floating-ui/react-dom'

import { FilterField, FilterRuleModel } from '@/components/filter/types'

import BaseFilter from '../Filter/BaseFilter'
import { useFilterFieldsConfig } from '../MainDashboard/useFilterFiledsConfig'

interface Props {
	filters: FilterRuleModel[]
	onChange(filters: FilterRuleModel[]): void
	placement?: Placement
	fields?: FilterField[]
	irremovableCount?: number
}

const AI_INSIGHTS_FIELDS = [
	'optimization_group_id',
	'pos_id',
	'category_id',
	'pricing_line_id',
]
export default function AiDashboardFilter(props: Props) {
	const { fields, dataChoices } = useFilterFieldsConfig({
		sanitizeFields: omitAll(['optimization_group', 'pos_ids']),
	})

	return (
		<BaseFilter
			{...props}
			onChange={(filters) => {
				props.onChange(
					filters.slice(props.irremovableCount || 0, filters.length)
				)
			}}
			fields={fields.filter((field) =>
				AI_INSIGHTS_FIELDS.includes(field.value)
			)}
			options={dataChoices}
		/>
	)
}
