import toast from 'react-hot-toast'

import { LinkButton } from '@cmpkit/base'

import { queryClient } from '@/lib/query-client'
import { SettingsTemplateModel, SettingsTemplateType } from '@/generated'
import analytic from '@/services/analytics'
import intl from '@/locale'
import { processingMessage } from '@/tools/message'
import { useCreateSettingsTemplateMutation } from '@/modules/settings-templates/mutations'
import notify from '@/components/toasts/helpers'
import { useModalStore } from '@/modules/modals/store'

import {
	HandleCopySettingToPc,
	HandleSaveSettingAsTemplate,
	HandleUseSettingTemplate,
	UseSettingActions,
} from './usePricingCampaignActions'

export function useSettingActions({
	handleUseTemplate,
}: {
	handleUseTemplate: (props: HandleUseSettingTemplate) => void
}): UseSettingActions {
	const { showModal } = useModalStore()

	const createTemplateMutation = useCreateSettingsTemplateMutation({
		onMutate() {
			toast.loading(processingMessage(), {
				id: 'saving_template',
			})
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['settings-templates'],
			})
		},
		onSettled() {
			toast.dismiss('saving_template')
		},
	})
	const handleOpenTemplate = ({
		id,
		template_type,
	}: {
		id: string
		template_type: SettingsTemplateType
	}) => {
		showModal('SETTINGS_TEMPLATE_MODAL', {
			templateId: id,
			templateType: template_type,
		})
	}
	const handleSaveAsTemplate = ({
		pricingCampaignName,
		templateType,
		settings,
	}: HandleSaveSettingAsTemplate) => {
		analytic.logEvent(
			'settings: pricing campaigns: dropdown: save setting as template'
		)
		createTemplateMutation.mutate(
			{
				template_type: templateType,
				name: pricingCampaignName,
				body: settings,
			},
			{
				onSuccess: (data: SettingsTemplateModel) => {
					notify.success(
						{
							text: (
								<div>
									{intl.get('templates').d('Template')}{' '}
									<LinkButton
										variant='brand'
										onClick={() => {
											handleOpenTemplate({
												id: data.id,
												template_type: templateType,
											})
										}}
									>
										{data.name}
									</LinkButton>{' '}
									{intl
										.get('template_saved_successfully')
										.d('was saved successfully')}
								</div>
							),

							variant: 'success',
						},
						{
							duration: 5000,
						}
					)
				},
			}
		)
	}

	const handleCopy = ({
		settingName,
		pricingCampaignId,
	}: HandleCopySettingToPc) => {
		analytic.logEvent(
			`settings: pricing campaigns: setting: dropdown: copy PC setting: ${settingName} to PC`
		)
		showModal('COPY_PRICING_CAMPAIGN_SETTINGS', {
			selected: pricingCampaignId,
			initialStep: 2,
			selectedSetting: [settingName],
		})
	}
	return {
		handleSaveAsTemplate,
		handleUseTemplate,
		handleCopy,
	}
}
