export const globalSelf = typeof window !== 'undefined' ? window : null
export const global = globalSelf
export const DEFAULT_VSN = '2.0.0'
export const SOCKET_STATES = { connecting: 0, open: 1, closing: 2, closed: 3 }
export const DEFAULT_TIMEOUT = 10000
export const WS_CLOSE_NORMAL = 1000
export enum SOCKET_EVENTS {
	close = 'close',
	error = 'error',
	open = 'open',
	message = 'message',
}
export enum CHANNEL_STATES {
	closed = 'closed',
	errored = 'errored',
	subscribed = 'subscribed',
	subscribing = 'subscribing',
	leaving = 'leaving',
}
export enum CHANNEL_EVENTS {
	close = 'close',
	error = 'error',
	subscribe = 'subscribe',
	unsubscribe = 'unsubscribe',
	reply = 'reply',
}

export enum TRANSPORTS {
	websocket = 'websocket',
}
export const XHR_STATES = {
	complete: 4,
}
