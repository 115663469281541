import { OptimizationStatus } from './types'

export const FINISHED = OptimizationStatus.Finished
export const NEW = OptimizationStatus.New
export const STARTED = OptimizationStatus.Started
export const FAILED = OptimizationStatus.Failed
export const LOCKED = OptimizationStatus.Locked
export const UPDATING = OptimizationStatus.Updating
export const PENDING = OptimizationStatus.Pending
export const CANCELED = OptimizationStatus.Canceled
export const SCHEDULED = OptimizationStatus.Scheduled
export const UPDATING_FAILED = OptimizationStatus.UpdatingFailed

export const WAITING_STATUSES = new Set([
	OptimizationStatus.Scheduled,
	OptimizationStatus.Started,
	OptimizationStatus.Updating,
])
export const METRICS_PREFIX = 'predict_default'
export const METRIC_PREFIX = 'predict_'
export enum MetricGroupValue {
	Decreased = 'decreased',
	Increased = 'increased',
	Locked = 'locked',
	NotChanged = 'not_changed',
	RuleBased = 'rule_based_repricing',
	DemandBased = 'demand_based_repricing',
	Manual = 'manual_repricing',
	Markdown = 'markdown_repricing',
}
export enum MetricGroup {
	PriceChangeStatus = 'price_change_status',
	PricingType = 'pricing_type',
	WithDiscount = 'with_discount',
}
export enum MetricName {
	DiscountInit = 'discount_init',
	DiscountFinal = 'discount_final',
	MarginFinal = 'margin_final',
	MarginInit = 'margin_init',
	PricngLineCount = 'pricng_line_count',
	ProductCount = 'product_count',
}
export interface Metric {
	group_type: MetricGroup | null
	group_value: MetricGroupValue
	name: string
	value: number
}
export const GROUP_VALUE_COLORS: Partial<Record<MetricGroupValue, string>> = {
	[MetricGroupValue.Decreased]: 'bg-warning',
	[MetricGroupValue.Increased]: 'bg-epic',
	[MetricGroupValue.NotChanged]: 'bg-success',
	[MetricGroupValue.Locked]: 'bg-accent-5',
}
export enum MetricDataType {
	/**
	 * Relative
	 */
	Percent = 'percent',
	/**
	 * Absolute
	 */
	Number = 'number',
	/**
	 * Percent points
	 */
	PercentPoint = 'pp',
	/**
	 * Unit
	 */
	Unit = 'unit',
}
