import React from 'react'

import CompactSearch from '@/components/CompactSearch'
import intl from '@/locale'

type TextSearchField = {
	value: string
	onChange(value: string): void
	className?: string
	count?: number
}
export default function TextSearchField({
	className,
	count,
	value,
	onChange,
}: TextSearchField) {
	return (
		<CompactSearch
			data-testid='table-search-input'
			value={value}
			className={className}
			count={count}
			onChange={(event) => onChange(event.target.value)}
			placeholder={intl.get('products_search')}
			onClear={() => onChange('')}
		/>
	)
}
