import { any, find, orderBy, prop } from 'lodash/fp'
import React, { useEffect } from 'react'
import ContentLoader from 'react-content-loader'
import { useNavigate } from 'react-router'

import { Card, Tag } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import Popover from '@cmpkit/popover'
import Select, { components, SingleValue, StylesConfig } from '@cmpkit/select'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'
import { Menu, MenuList } from '@/components/SelectComponents'
import { OptimizationStatus } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import {
	useOptimizationGroupQuery,
	useOptimizationGroupsQuery,
	useOptimizationQuery,
	useOptimizationsQuery,
} from '@/modules/core/queries'
import { getOgStatus, getStatusBadgeVariant } from '@/modules/core/utils'

export default function OptimizationGroupSwitcher() {
	const optimizationGroupId = useOptimizationGroupId()!
	const optimizationGroupQuery = useOptimizationGroupQuery(optimizationGroupId)
	const optimizationQuery = useOptimizationQuery(optimizationGroupId, {
		refetchOnMount: 'always',
	})

	const { close: handleClose, toggle: handleToggle, isOpen } = useDisclosure()
	useEffect(() => {
		isOpen && handleClose()
	}, [optimizationGroupId])

	return (
		<div className='flex shrink-0'>
			<Popover
				placement='bottom-start'
				isOpen={isOpen}
				hidePointer
				onDismiss={handleClose}
				content={() => (
					<Card className='border shadow'>
						<OptimizationGroupsPopoverList />
					</Card>
				)}
			>
				<div
					className='flex cursor-pointer items-center gap-2'
					onClick={handleToggle}
				>
					<Tooltip
						delay={300}
						placement='bottom'
						hidePointer
						content={optimizationGroupQuery.data?.name}
					>
						<span className='max-w-60 truncate'>
							{optimizationGroupQuery.isLoading ? (
								<Skeleton width={120} height={18} />
							) : (
								optimizationGroupQuery.data?.name
							)}
						</span>
					</Tooltip>
					{optimizationQuery.isLoading ? (
						<Skeleton width={45} height={18} />
					) : (
						<StatusLabel status={getOgStatus(optimizationQuery.data!.status)} />
					)}
				</div>
			</Popover>
		</div>
	)
}

export const Skeleton = ({ width = 100, height = 12 }) => {
	return (
		<div className='my-2 flex h-full items-center'>
			<ContentLoader
				speed={2}
				width={width}
				height={height}
				viewBox={`0 0 ${width} ${height}`}
				backgroundColor='var(--cmp-accent-4)'
				foregroundColor='var(--cmp-accent-3)'
			>
				<rect x='0' y='0' rx='10' ry='10' width={width} height={height} />
			</ContentLoader>
		</div>
	)
}
const selectStyles = {
	control: (provided: object) => ({ ...provided, margin: 6 }),
	menu: (provided: object) => ({
		...provided,
		boxShadow: 'none',
		border: 0,
		position: 'relative',
	}),
} as StylesConfig<DataOption, false>
const Control = ({
	children,
	innerProps,
	innerRef,
}: {
	children: React.ReactNode
	innerProps: object
	innerRef: React.Ref<HTMLDivElement>
}) => (
	<div ref={innerRef} className='cmp-border-bottom flex p-1' {...innerProps}>
		{children}
	</div>
)
const StatusLabel = ({ status }: { status: OptimizationStatus }) => (
	<Tag
		size='small'
		className='shrink-0'
		variant={getStatusBadgeVariant(status)}
	>
		{intl.get(`opt_${status}`)}
	</Tag>
)
const DropdownIndicator = () => <SearchIcon className='mr-1' />
const Option = (
	props: {
		children: React.ReactNode
		data: { status: OptimizationStatus }
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} & any
) => {
	return (
		<components.Option
			{...props}
			children={
				<div className='flex cursor-pointer items-center justify-between gap-2'>
					<Tooltip delay={300} content={props.children}>
						<span className='line-clamp-3 text-xs'>{props.children}</span>
					</Tooltip>
					<StatusLabel status={getOgStatus(props.data?.status)} />
				</div>
			}
		/>
	)
}

function OptimizationGroupsPopoverList() {
	const navigate = useNavigate()
	const optimizationGroupId = useOptimizationGroupId()
	const groups = useOptimizationGroupsQuery()
	const optimizations = useOptimizationsQuery({
		refetchOnMount: 'always',
	})
	const isLoading = any(prop('isLoading'), [groups, optimizations])
	const list = groups.data?.map((group) => ({
		value: group.id,
		label: group.name,
		status: optimizations.data?.[group.id]?.status,
	}))
	const options = orderBy(['label'], ['asc'], list)
	const selected = find({ value: optimizationGroupId }, options)
	const handleSelectOptimizationGroup = (data: SingleValue<DataOption>) =>
		navigate(
			location.pathname.replace(
				`/p/og/${optimizationGroupId}/`,
				`/p/og/${data?.value}/`
			)
		)
	return (
		<Select
			autoFocus
			backspaceRemovesValue={false}
			components={{
				Option,
				Menu,
				MenuList,
				Control,
				IndicatorSeparator: null,
				DropdownIndicator,
			}}
			styles={selectStyles}
			className='w-80'
			menuIsOpen
			isLoading={isLoading}
			controlShouldRenderValue={false}
			hideSelectedOptions={false}
			isClearable={false}
			tabSelectsValue={false}
			value={selected}
			onChange={handleSelectOptimizationGroup}
			options={options}
		/>
	)
}
