import React, {
	ChangeEvent,
	FormEvent,
	Fragment,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'

import { Label, Radio, Textfield } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'

import { Note } from '../../components/common'
import { Group } from '../../components/InputGroup'
import { DialogInner } from '../../components/Popup'
import { FieldViewProps, ValueState } from '../../types'
import NumberController from './Controller'

type NumberViewProps = FieldViewProps<NumberController>
type State = {
	single: string
	operation: string
	gt: string
	lt: string
}

const getInitialState = (storedValue: ValueState<string | object>) => {
	const { operation, value } = storedValue
	const base = { gt: '', lt: '', operation, single: '' }

	return typeof value === 'number' || typeof value === 'string'
		? { ...base, single: value }
		: { ...base, ...value }
}

export default function NumberView({
	storedValue,
	field,
	invalidMessage,
	//isDirty,
	localValue,
	isRemovable,
	onRemove,
	onReset,
	onApply,
	onChange,
	closePopup,
}: NumberViewProps) {
	const focusTimeoutId = useRef<ReturnType<typeof setTimeout> | undefined>(
		undefined
	)
	const nextInputRef = useRef<HTMLInputElement | null>(null)
	const [state, setState] = useState<State>(getInitialState(storedValue))

	const focusNextInput = () => {
		const target = nextInputRef.current
		if (target) {
			focusTimeoutId.current = setTimeout(() => {
				nextInputRef.current?.focus()
			}, 10)
		}
	}

	useLayoutEffect(() => {
		focusNextInput()
		return () => clearTimeout(focusTimeoutId.current)
	}, [])

	const isBetween = state.operation === Operators.BETWEEN

	const filterTypes = field.getFilterTypes()

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		if (invalidMessage) return
		onApply?.()
		closePopup?.()
	}

	const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
		const operation = event.target.value as Operators
		const isNotSet = [Operators.IS_EMPTY, Operators.IS_NOT_EMPTY].includes(
			operation
		)
		const isBetween = operation === Operators.BETWEEN
		const isKeyboardEvent =
			(event.nativeEvent as PointerEvent).screenX === 0 &&
			(event.nativeEvent as PointerEvent).screenY === 0
		const newState = { ...state, operation }
		setState(newState)
		if (!isKeyboardEvent) {
			focusNextInput()
		}

		// avoid creating an invalid state where '' === NaN
		/* if (!isBetween && !isEmptyString(newState.single) && !isNotSet) {
			return
		} */

		const { gt, lt } = newState
		let value: string | { gt: string; lt: string } = newState.single
		if (isBetween) {
			value = { gt, lt }
		} else if (isNotSet) {
			value = '1'
		}

		onChange({ operation, value })
	}

	const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
		const { name } = event.target
		const val = event.target.value

		const { operation } = state
		const newState = { ...state, [name]: val }
		setState(newState)
		const { gt, lt } = newState
		const value = isBetween ? { gt, lt } : val
		onChange({ operation, value })
	}

	const { operation } = state
	const isInvalid = Boolean(invalidMessage)

	return (
		<Group
			onSubmit={handleSubmit}
			isDisabledApply={Boolean(isInvalid || field.validate(localValue))}
			onRemove={isRemovable ? onRemove : undefined}
			onCancel={() => {
				onReset?.()
				closePopup()
			}}
		>
			<DialogInner className='min-w-[260px] max-w-[260px] space-y-2 p-5'>
				<div className='flex flex-col space-y-3'>
					{filterTypes.map((m) => {
						const isCurrent = m.operation === operation
						return (
							<Fragment key={m.operation}>
								<div className='mt-2 flex items-center space-x-2'>
									<Radio
										id={`radio-mode-${m.operation}`}
										checked={isCurrent}
										name='mode'
										onChange={onChangeCheckbox}
										value={m.operation}
									/>
									<Label htmlFor={`radio-mode-${m.operation}`}>{m.label}</Label>
								</div>

								{isCurrent && m.hasInput ? (
									<>
										{m.operation === Operators.BETWEEN ? (
											<div className='flex items-center space-x-2'>
												<Textfield
													ref={nextInputRef}
													name='gt'
													invalid={isInvalid}
													onChange={onChangeInput}
													autoComplete='off'
													autoCorrect='off'
													type='number'
													value={state.gt}
												/>
												<Textfield
													name='lt'
													invalid={isInvalid}
													onChange={onChangeInput}
													type='number'
													autoComplete='off'
													autoCorrect='off'
													value={state.lt}
												/>
											</div>
										) : (
											<Textfield
												ref={nextInputRef}
												invalid={isInvalid}
												name='single'
												onChange={onChangeInput}
												type='number'
												autoComplete='off'
												autoCorrect='off'
												value={state.single}
											/>
										)}
										{invalidMessage && <Note>{invalidMessage}</Note>}
									</>
								) : null}
							</Fragment>
						)
					})}
				</div>
				{field.note && <Note>{field.note}</Note>}
			</DialogInner>
		</Group>
	)
}
