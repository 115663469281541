import React from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'
import clsx from 'clsx'

import {
	FormError,
	FormGroup,
	FormHint,
	FormLabel,
	Required,
} from '@cmpkit/base'
import HelpCircleIcon from '@cmpkit/icon/lib/glyph/help-circle'
import Tooltip from '@cmpkit/tooltip'

import InlineEditText from '../InlineEditText'

export default function RHFInlineEditText({
	name,
	label,
	hint,
	description,
	defaultValue,
	shouldUnregister,
	required,
	className,
	inputClassName,
	...other
}: UseControllerProps &
	React.HTMLAttributes<HTMLInputElement> & {
		label?: string
		hint?: string
		required?: boolean
		placeholder?: string
		description?: string
		rows?: number
		autoComplete?: string
		autoCorrect?: string
		inputClassName?: string
	}) {
	const { control } = useFormContext()
	return (
		<FormGroup className={className}>
			{!!label && (
				<FormLabel>
					{label}
					{required && <Required />}
				</FormLabel>
			)}
			<Controller
				name={name}
				defaultValue={defaultValue}
				shouldUnregister={shouldUnregister}
				control={control}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<>
						<div className='flex w-full items-center'>
							<InlineEditText className={clsx('w-full', inputClassName)}>
								<input {...other} value={value} onChange={onChange} />
							</InlineEditText>
							{hint && (
								<Tooltip placement='top' content={hint}>
									<HelpCircleIcon className='mx-2.5 flex items-center text-muted' />
								</Tooltip>
							)}
						</div>
						{!!description && (
							<FormHint className='items-center'>{description}</FormHint>
						)}
						{error && <FormError>{error.message}</FormError>}
					</>
				)}
			/>
		</FormGroup>
	)
}
