import { uniq } from 'lodash/fp'
import React, { useState } from 'react'

import { Button, Col, Row } from '@cmpkit/base'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'

import DataChoice from './DataChoice'

interface MultiChoiceManagerProps {
	value: string[]
	onChange(data: string[]): void
	groups: Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
	disabledGroups?: string[]
}
function MultiChoiceManager({
	value,
	onChange,
	groups,
	disabledGroups = [],
}: MultiChoiceManagerProps) {
	const blob = groups
	const source = Object.keys(blob)
	const prepared = value
	const setPrepared = (data: string[]) => onChange(data)
	const [selectedSource, setSelectedSource] = useState<string[]>([])
	const [selectedPrepared, setSelectedPrepared] = useState<string[]>([])
	const handleMoveToPrepared = () => {
		setPrepared(uniq([...prepared, ...selectedSource]))
		setSelectedSource([])
	}
	const handleMoveToSource = () => {
		setPrepared(prepared.filter((id) => !selectedPrepared.includes(id)))
		setSelectedPrepared([])
	}

	return (
		<Row>
			<Col sm='5'>
				<DataChoice
					data-testid='data-choice-left'
					selected={selectedSource}
					onSelectionChange={setSelectedSource}
					disabled={disabledGroups}
					data={source.filter((id) => !prepared.includes(id))}
					blob={blob}
				/>
			</Col>
			<Col sm='2' className='flex items-center justify-center'>
				<div className='mb-2.50 flex flex-col'>
					<Button
						className='mb-2.5'
						onClick={handleMoveToPrepared}
						iconBefore={<CaretRightIcon />}
						data-testid='data-choice-move-right'
					/>
					<Button
						onClick={handleMoveToSource}
						iconBefore={<CaretLeftIcon />}
						data-testid='data-choice-move-left'
					/>
				</div>
			</Col>
			<Col sm='5'>
				<DataChoice
					data-testid='data-choice-right'
					selected={selectedPrepared}
					onSelectionChange={setSelectedPrepared}
					data={prepared}
					blob={blob}
				/>
			</Col>
		</Row>
	)
}
export default MultiChoiceManager
