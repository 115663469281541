import { Modal, ModalTransition } from '@cmpkit/modal'

import ReportProblem from './ReportProblem'

export default function CreateJsdTicketModal({
	close: handleClose,
	isOpen,
}: {
	close(): void
	isOpen: boolean
}) {
	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={handleClose} showCloseButton size='mini'>
					<ReportProblem close={handleClose} />
				</Modal>
			)}
		</ModalTransition>
	)
}
