import { useForm } from 'react-hook-form'

import { Button } from '@cmpkit/base'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { FormProvider, RHFRadioGroup } from '@/components/HookForm'
import { SettingsTemplateType } from '@/generated'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'

export default function SettingsTemplateCreateModal({
	isOpen,
	close,
}: {
	isOpen: boolean
	close: () => void
}) {
	const { showModal, hideModal } = useModalStore()
	const methods = useForm<{ template_type: SettingsTemplateType }>({})
	const handleSubmit = methods.handleSubmit(
		(data: { template_type: SettingsTemplateType }) => {
			showModal('SETTINGS_TEMPLATE_MODAL', {
				templateType: data.template_type,
			})
			hideModal('SETTINGS_TEMPLATE_CREATE_MODAL')
		}
	)
	return (
		<ModalTransition>
			{isOpen && (
				<Modal size={'mini'} showCloseButton onClose={close}>
					<FormProvider
						methods={methods}
						onSubmit={handleSubmit}
						className='flex h-full flex-col overflow-hidden'
					>
						<ModalHeader>
							<ModalTitle>
								{intl.get('template.modal.create.title').d('Create template')}
							</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<RHFRadioGroup
								name='template_type'
								options={[
									SettingsTemplateType.PricingCampaign,
									SettingsTemplateType.PricingCampaignTacticsRb,
									SettingsTemplateType.PricingCampaignTacticsMd,
									SettingsTemplateType.PricingCampaignLimits,
									SettingsTemplateType.PricingCampaignMinimalSteps,
									SettingsTemplateType.PricingCampaignRounding,
									SettingsTemplateType.PricingCampaignAnchoring,
									SettingsTemplateType.PricingCampaignProductAssignment,
								].map((value) => ({
									value,
									label: intl.get(`template.type.${value}`).d(value),
								}))}
								label={intl.get('template_type').d('Template type')}
							/>
							<RHFRadioGroup
								name='template_type'
								options={[
									SettingsTemplateType.Scenario,
									SettingsTemplateType.ScenarioStrategy,
									SettingsTemplateType.ScenarioStopList,
									SettingsTemplateType.ScenarioBusinessConstraints,
								].map((value) => ({
									value,
									label: intl.get(`template.type.${value}`).d(value),
								}))}
								label={intl.get('template_type').d('Template type')}
							/>
						</ModalBody>
						<ModalFooter className='justify-between'>
							<Button onClick={close}>{intl.get('general_cancel')}</Button>
							<Button
								variant='primary-brand'
								data-testid={'pc-form-save'}
								disabled={!methods.watch('template_type')}
								onClick={handleSubmit}
								iconAfter={<CaretRightIcon />}
							>
								{intl.get('general_next')}
							</Button>
						</ModalFooter>
					</FormProvider>
				</Modal>
			)}
		</ModalTransition>
	)
}
