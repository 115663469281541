import { filter } from 'lodash/fp'
import React from 'react'

import { InlineMessage } from '@cmpkit/base'

import intl from '@/locale'
import { AlertAnnotationModel, AlertLevel } from '@/modules/core/types'
import { getAlertVariant } from '@/modules/core/utils'

type NotificationsProps = {
	alerts?: AlertAnnotationModel[]
}
export default function ProductAlertsList({ alerts }: NotificationsProps) {
	return (
		<>
			{filter({ level: AlertLevel.Unassigned }, alerts)?.map((message) => (
				<MessageListItem key={message.id} message={message} />
			))}
			{filter({ level: AlertLevel.Critical }, alerts)?.map((message) => (
				<MessageListItem key={message.id} message={message} />
			))}
			{filter({ level: AlertLevel.Warning }, alerts)?.map((message) => (
				<MessageListItem key={message.id} message={message} />
			))}
			{filter({ level: AlertLevel.Info }, alerts)?.map((message) => (
				<MessageListItem key={message.id} message={message} />
			))}
		</>
	)
}

function MessageListItem({ message }: { message: AlertAnnotationModel }) {
	return (
		<InlineMessage
			className='w-full'
			key={message.id}
			data-testid={message.id}
			variant={getAlertVariant(message.level)}
		>
			<h5 className='mb-1'>{intl.get(message.name).d(message.name)}</h5>
			<p>{intl.get(message.description).d(message.description)}</p>
		</InlineMessage>
	)
}
