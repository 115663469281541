import {
	assign,
	entries,
	fromPairs,
	groupBy,
	indexBy,
	map,
	pipe,
	sum,
} from 'lodash/fp'

type PriceChangeStatus =
	| 'increased'
	| 'decreased'
	| 'not_changed'
	| 'locked'
	| 'promo'
type PriceChangeRow = {
	optimization_lag: number
	price_change_status: PriceChangeStatus
	assortment: number
	average_price_change: number
}
type PriceChangeTableRow = {
	key: PriceChangeStatus
	/**
	 * Count of products in optimization with lag 0
	 */
	op1_assortment: number
	/**
	 * delta of avg_price compared to previous optimization with lag 1
	 * @example (lag0 - lag1) / 2
	 */
	op1_price_delta: number
	/**
	 * Count of products in optimization with lag 1
	 */
	op2_assortment: number
	/**
	 * delta of avg_price compared to previous optimization with lag 2
	 * @example (lag1 - lag2) / 2
	 */
	op2_price_delta: number
	/**
	 * delta of avg_price compared to all avg optimizations behind
	 * @example (((lag0 - lag1) / 2) + ((lag1 - lag2) / 2) ...N ) / N
	 */
	op4_price_delta: number
}

export const priceChangesStatuses: PriceChangeStatus[] = [
	'increased',
	'decreased',
	'not_changed',
	'locked',
	'promo',
]

/**
 * Get default serie for price change status and optimization lag
 * @param optimization_lag - optimization lag
 * @param price_change_status - price change status
 * @returns default serie for price change status
 */
export const getDefaultSerie = (
	optimization_lag: number,
	price_change_status: PriceChangeStatus
): PriceChangeRow => ({
	optimization_lag,
	price_change_status,
	assortment: 0,
	average_price_change: 0,
})

/**
 * Get default series for price change status and optimization lag from 0 to 4 (including)
 * @param status - price change status
 * @returns default series for price change status
 */
export const getDefaultLagSeries = (status: PriceChangeStatus) =>
	fromPairs([1, 2, 3, 4, 5].map((lag) => [lag, getDefaultSerie(lag, status)]))

/**
 * Get structured data from array of rows
 */
export const getStructuredData: (
	data: Array<Record<PriceChangeStatus, PriceChangeRow>>
) => Record<PriceChangeStatus, Record<number, PriceChangeRow>> = pipe([
	groupBy('price_change_status'),
	assign({
		increased: [],
		decreased: [],
		not_changed: [],
		locked: [],
		promo: [],
	}),
	entries,
	map(([status, val]: [PriceChangeStatus, PriceChangeRow[]]) => [
		status,
		{
			...getDefaultLagSeries(status),
			...indexBy('optimization_lag', val),
		},
	]),
	fromPairs,
])

/* const calculateDelta = (curr: PriceChangeRow, next: PriceChangeRow) => {
	const result = ((curr.average_price - next.average_price) / next.average_price) * 100
	return Number.isNaN(result) ? 0 : result
} */
const getOp4Delta = (statusDataSet: Record<number, PriceChangeRow>) => {
	const set = [
		statusDataSet[1].average_price_change,
		statusDataSet[2].average_price_change,
		statusDataSet[3].average_price_change,
		statusDataSet[4].average_price_change,
	].filter(
		(item) =>
			item !== Infinity &&
			item !== -Infinity &&
			!Number.isNaN(item) &&
			item !== 0
	)
	return sum(set) / set.length
}

/**
 * Create rows for table from structured data
 * @param structuredData - data from getStructuredData function
 * @returns array of rows for table
 */
export const createRows = (
	structuredData: Record<string, Record<number, PriceChangeRow>>
): PriceChangeTableRow[] => {
	return priceChangesStatuses.map((status: PriceChangeStatus) => {
		const statusDataSet = structuredData[status]

		return {
			key: status,
			op1_assortment: statusDataSet[1].assortment,
			op1_price_delta: statusDataSet[1].average_price_change,
			op2_assortment: statusDataSet[2].assortment,
			op2_price_delta: statusDataSet[2].average_price_change,
			op4_price_delta: getOp4Delta(statusDataSet),
		} as PriceChangeTableRow
	})
}
