import React from 'react'
import toast, { Toast, ToastOptions } from 'react-hot-toast'

import ToastNotification from './ToastNotification'

type ToastNotificationArgs = {
	title?: string
	text?: string | React.ReactNode
	variant?: 'success' | 'loading' | 'error' | 'warning' | 'info'
}
export const getToastNotification =
	({ title, text, variant }: ToastNotificationArgs) =>
	(t: Toast) => (
		<ToastNotification
			title={title}
			variant={variant as string}
			text={text}
			t={t}
		/>
	)

const position = 'bottom-left'
const notify = {
	success: (message: ToastNotificationArgs, options: ToastOptions = {}) => {
		const messageConf =
			typeof message === 'string' ? { text: message } : message
		return toast.custom(
			getToastNotification({ ...messageConf, variant: 'success' }),
			{
				position,
				...options,
			}
		)
	},
	warning: (message: ToastNotificationArgs, options: ToastOptions = {}) => {
		const messageConf =
			typeof message === 'string' ? { text: message } : message
		return toast.custom(
			getToastNotification({ ...messageConf, variant: 'warning' }),
			{
				position,
				...options,
			}
		)
	},
	info: (message: ToastNotificationArgs, options: ToastOptions = {}) => {
		const messageConf =
			typeof message === 'string' ? { text: message } : message
		return toast.custom(
			getToastNotification({ ...messageConf, variant: 'info' }),
			{
				position,
				...options,
			}
		)
	},
	error: (message: ToastNotificationArgs, options: ToastOptions = {}) => {
		const messageConf =
			typeof message === 'string' ? { text: message } : message
		return toast.custom(
			getToastNotification({ ...messageConf, variant: 'info' }),
			{
				position,
				...options,
			}
		)
	},
	loading: (message: ToastNotificationArgs, options: ToastOptions = {}) => {
		const messageConf =
			typeof message === 'string' ? { text: message } : message
		return toast.custom(
			getToastNotification({ ...messageConf, variant: 'loading' }),
			{
				position,
				...options,
			}
		)
	},
}
export default notify
