import { join, map, merge, omitAll, pipe, prop, props } from 'lodash/fp'
import { LayersIcon } from 'lucide-react'
import * as qs from 'qs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { useQueryClient } from '@tanstack/react-query'

import {
	Badge,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbList,
	BreadcrumbPage,
	Button,
	Footer,
	Header,
	InlineMessage,
	Layout,
	LinkButton,
	Loader,
	Pagination,
	Separator,
	usePagination,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import {
	Column,
	ColumnCheckboxHeaderTemplate,
	ColumnCheckboxRowTemplate,
	Sort,
} from '@cmpkit/data-table'
import { useDebounce } from '@cmpkit/hooks'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'
import RelationGraphIcon from '@cmpkit/icon/lib/glyph/relation-graph'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import UploadIcon from '@cmpkit/icon/lib/glyph/upload'
import { FilterRuleEntity } from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'
import {
	assignProp,
	assignRenderers,
	CellsConfigSchema,
} from '@/components/data-grid/column-helpers'
import AlertsCountField from '@/components/data-grid/custom/AlertsCountField'
import TreeField from '@/components/data-grid/custom/TreeField'
import {
	BadgeField,
	CellProp,
	ChoiceField,
} from '@/components/data-grid/fields'
import {
	decodeComplexQuery,
	encodeComplexQuery,
	useTableChanges,
} from '@/components/data-grid/helpers'
import TableFilters from '@/components/data-grid/TableFilters'
import TextSearchField from '@/components/data-grid/TextSearchField'
import { dialog } from '@/components/dialogs'
import { toAdaptedSchema } from '@/components/filter/adapter'
import NoData from '@/components/placeholders/NoData'
import { getFiledConfig } from '@/components/refinement-bar/helpers'
import TableColumnsManager from '@/components/TableColumnsManager'
import notify from '@/components/toasts'
import {
	AnchoringProductModel,
	AnchoringProductsRequestBody,
} from '@/generated'
import intl from '@/locale'
import { getSalesEntitiesTree } from '@/modules/core/helpers'
import { useSalesEntitiesQuery } from '@/modules/core/queries'
import { useModalStore } from '@/modules/modals/store'
import { QuickFiltersBar } from '@/modules/preferences'
import { useStoredFiltersKeys } from '@/modules/preferences/hooks/useStoredFiltersKeys'
import analytic from '@/services/analytics'
import { useColumnsConfig, useColumnsDefinitions } from '@/tools/columns'
import { actDeleted, actUpdated, successActionMessage } from '@/tools/message'

import { geColumnsSchema } from '../columns'
import { ANCHORS_TYPES_CHOICES } from '../contstants'
import { getAchorTypes } from '../helpers'
import {
	useDeleteAnchoringMutation,
	useUpdateAnchoringMutation,
} from '../mutations'
import {
	useAnchoringAlertsAnnotationsQuery,
	useAnchoringRelationsListQuery,
} from '../queries'
import AnchoringManagementAlertsButton from './AnchoringManagementAlertsButton'
import AnchoringManagementDatailsDrawer from './AnchoringManagementDatailsDrawer'
import AnchoringManagementTable from './AnchoringManagementTable'

const schema = geColumnsSchema()
export default function AnchoringManagementPage() {
	const location = useLocation()
	const { showModal } = useModalStore()
	const queryClient = useQueryClient()
	const [storedQuickFiltersKeys, saveStoredQuickFiltersKeys] =
		useStoredFiltersKeys('acnchors_quick_filters_keys')
	const navigate = useNavigate()

	const [searchText, setSearchText] = useState<string>('')

	const [sort, setSort] = useState<Sort[]>([])
	const [selected, setSelected] = useState<string[]>([])
	const searchTextValue: string = useDebounce(searchText, 1000)
	const tableDataChanges = useTableChanges(getRowId)

	useEffect(() => {
		analytic.logEvent('browse: anchoring management assortment')
	}, [])

	useEffect(() => {
		if (selected.length) {
			setSelected([])
		}
	}, [location.search])

	/**
	 * Computed values
	 */

	//Get location query as object from string and ignore prefix '?'
	const locationQuery = useMemo(
		() => qs.parse(location.search, { ignoreQueryPrefix: true }),
		[location.search]
	)
	const { qf, limit = 100, offset = 0, selectedSku } = locationQuery

	const clearSelectedSku = () => {
		const newQuery = locationQuery
		delete locationQuery['selectedSku']
		navigate(qs.stringify(newQuery, { addQueryPrefix: true }))
	}
	// Get filters from query string and decode them to FilterRuleEntity[] format
	const filters = useMemo(
		(): FilterRuleEntity[] => decodeComplexQuery(qf as string)?.filters || [],
		[location.search]
	)

	const requestBody: AnchoringProductsRequestBody = {
		limit: limit as number,
		offset: offset as number,
		format: 'json',
		filters,
		search: searchTextValue || undefined,
		order_by:
			sort
				?.filter(({ direction }) => direction === 'asc' || direction === 'desc')
				.map(({ columnId, direction }) => ({
					name: columnId,
					direction: direction as 'asc' | 'desc' | undefined,
				})) ?? [],
	}

	/**
	 * Queires
	 */
	const salesEntitiesQuery = useSalesEntitiesQuery()
	const { data: alerts } = useAnchoringAlertsAnnotationsQuery({
		select: (data) =>
			data.map(({ name, id }) => ({
				value: id,
				label: intl.get(name).d(id),
			})),
	})
	const assortmentQuery = useAnchoringRelationsListQuery(requestBody, {
		placeholderData: (previousData) => previousData,
	})

	const updateRelations = useUpdateAnchoringMutation({
		onSuccess: async () => {
			tableDataChanges.reset()
			await queryClient.invalidateQueries({
				queryKey: ['anchoring-list'],
			})
			await queryClient.invalidateQueries({
				queryKey: ['anchoring-relation-groups'],
			})
			notify.success(
				{
					text: successActionMessage('Anchors', actUpdated()),
				},
				{
					id: 'anchor_updating',
				}
			)
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (error: any) => {
			if (error.response?.data.errors?.anchors) {
				// eslint-disable-next-line
				const errors: any = Object.entries(
					error.response.data.errors?.anchors[0]
				)
				notify.error(
					{
						title: 'Validation error',

						text: `Field "${errors[0][0]}" has "${errors?.[0]?.[1]?.[0] || 0}"`,
					},
					{
						id: 'anchor_updating',
					}
				)
			} else {
				notify.error(
					{
						text: intl.get('fatal_error_title'),
					},
					{
						id: 'anchor_updating',
					}
				)
			}
		},
	})
	const deleteRelations = useDeleteAnchoringMutation({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['anchoring-list'],
			})
			queryClient.invalidateQueries({
				queryKey: ['anchoring-relation-groups'],
			})
			setSelected([])
			notify.success(
				{
					text: successActionMessage('Anchors', actDeleted()),
				},
				{
					id: 'anchor_deleting',
				}
			)
		},
		onError: () => {
			notify.error(
				{
					text: intl.get('fatal_error_title'),
				},
				{
					id: 'anchor_deleting',
				}
			)
		},
	})
	/**
	 * Handlers
	 */
	const handleApplyFilter = useCallback(
		(filters: FilterRuleEntity[]) =>
			navigate(
				qs.stringify(
					{
						...locationQuery,
						offset: 0,
						qf: filters?.length ? encodeComplexQuery({ filters }) : undefined,
					},
					{ addQueryPrefix: true }
				)
			),
		[filters]
	)

	// Handler for open modal to download assortment report
	const handleExportReportClick = () => {
		analytic.logEvent('anchoring: export table: click')
		showModal('EXPORT_ANCHORS_TABLE' /*  { filters } */)
	}

	// Handler for open modal to download assortment report
	const handleImportReportClick = () => {
		analytic.logEvent('anchoring: import from file: click')
		showModal('IMPORT_ANCHORS_FROM_FILE')
	}

	const handleChangePagination = useCallback(
		(paginationMeta: { limit: number; offset: number }) => {
			navigate(
				qs.stringify(
					pipe([omitAll(['limit', 'offset']), merge(paginationMeta)])(
						locationQuery
					),
					{
						addQueryPrefix: true,
					}
				)
			)
		},
		[locationQuery]
	)

	const handleDeleteSelectedRows = () => {
		dialog
			.confirmDelete({
				title: intl.get('general_detete_confirm_title'),
				text: intl.get('general_detete_confirm_subtitle'),
			})
			.then((answer: boolean) => {
				if (answer) {
					analytic.logEvent('anchoring: delete selected rows: click')
					notify.loading(
						{
							text: 'Deleting anchors...',
						},
						{
							id: 'anchor_deleting',
						}
					)
					deleteRelations.mutate({
						anchors: selected.map((item: string) => {
							const [sales_entity_id, sku] = item.split('__')
							return {
								sales_entity_id,
								sku,
							}
						}),
					})
				}
			})
	}

	const handleSaveChanges = () => {
		analytic.logEvent('anchoring: save manual changes: click')
		notify.loading(
			{
				text: 'Updating anchors...',
			},
			{
				id: 'anchor_updating',
			}
		)

		const changes = Object.entries(tableDataChanges.getSnapshot()).map(
			([key, value]) => {
				const [sales_entity_id, sku] = key.split('__')
				return {
					sales_entity_id,
					sku,
					...value,
				}
			}
		)
		updateRelations.mutate({
			anchors: changes,
		})
	}
	const handleRevertChanges = () => {
		analytic.logEvent('anchoring: revert manual changes: click')
		tableDataChanges.reset()
	}
	const CountWithLinkCell = (props: CellProp) => {
		const anchorType = props.schema?.name?.replace('_count', '')
		return props.value ? (
			<LinkButton
				variant='brand'
				onClick={() => {
					analytic.logEvent('anchoring: table: count: click')
					handleApplyFilter([
						{
							name: `${anchorType}_sku`,
							operation: 'is',
							value: props.row.sku,
						},
					])
				}}
			>
				{props.value}
			</LinkButton>
		) : (
			'-'
		)
	}
	const SkuWithLinkCell = (props: CellProp) => {
		const location = useLocation()
		const navigate = useNavigate()
		return (
			<LinkButton
				variant='brand'
				onClick={() => {
					analytic.logEvent('anchoring: table: sku: click')
					const newQuery: Record<string, unknown> = {
						...qs.parse(location.search, { ignoreQueryPrefix: true }),
						selectedSku: getRowId(props.row),
					}
					navigate(qs.stringify(newQuery, { addQueryPrefix: true }))
				}}
			>
				{props.value}
			</LinkButton>
		)
	}

	const cellsConfig = useMemo(
		(): CellsConfigSchema => ({
			extendFieldsTypes: {
				sales_entity_id: ChoiceField,
				anchor_count: CountWithLinkCell,
				ident: SkuWithLinkCell,
				alerts: AlertsCountField,
				anchor_type: AnchorTypeField,
				tree: TreeField as unknown as React.FC<CellProp>,
			},
			dataChoices: {
				anchor_type: ANCHORS_TYPES_CHOICES,
				sales_entity_id: getSalesEntitiesTree(salesEntitiesQuery.data) || [],
				alerts: alerts || [],
			},
			tableDataChanges,
		}),
		[alerts, tableDataChanges]
	)

	// Pagination business logic
	const pagination = usePagination({
		total: assortmentQuery.data?.total_count ?? 0,
		limit: Number(requestBody.limit),
		offset: Number(requestBody.offset),
		onChange: handleChangePagination,
	})

	// Modifiers for columns behavior
	const columnExtensions = useMemo(
		() => [
			enrichValueGetters,
			enrichSortable,
			assignRenderers(cellsConfig),
			enrichLocked,
			enrichWidth,
		],
		[cellsConfig]
	)

	// Columns business logic formation based on `schema` and `columnExtensions`
	const columnsDefinitions = useColumnsDefinitions(schema, columnExtensions)

	// Create columns configuration controller
	const columnsConfig = useColumnsConfig(columnsDefinitions, 'control')

	/**
	 * Columns formation from `columnsConfig` and memoization for performance reasons (to avoid re-rendering)
	 * and add control column to the beginning of the columns list
	 */
	const columns: Column[] = useMemo(
		() => [
			new Column({
				id: 'control',
				title: '',
				headerRenderer: () => <ColumnCheckboxHeaderTemplate />,
				renderer: (id, item, row) => (
					<ColumnCheckboxRowTemplate item={item} row={row} />
				),
				valueGetter: (row) => `${row.sales_entity_id}__${row.sku}`,
				filterable: false,
				sortable: false,
				width: 42,
				locked: true,
				fixable: false,
			}),
			...columnsDefinitions.map(({ column }) => new Column(column)),
		],
		[columnsDefinitions, columnsConfig.rowHeight]
	)
	const rows = assortmentQuery.data?.data || []

	return (
		<Layout>
			<Header className='h-11 items-center border-b px-4 py-2'>
				<LayersIcon className='size-4' />
				<Separator orientation='vertical' className='mx-2 h-5' />
				<Breadcrumb className='text-xs'>
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbPage>
								{intl
									.get('app.anchoring_management.page.title')
									.d('Anchor management')}
							</BreadcrumbPage>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
				<div className='ml-auto flex items-center'>
					{tableDataChanges?.isDirty ? (
						<div className='flex items-center space-x-2'>
							<Button
								key='revert'
								onClick={handleRevertChanges}
								iconBefore={<UndoIcon />}
							>
								{intl.get('app.revert_changes')}
							</Button>
							<Button
								key='save'
								variant='primary-warning'
								onClick={handleSaveChanges}
							>
								{intl.get('app.save_and_update')}
							</Button>
						</div>
					) : null}
				</div>
			</Header>

			<Layout row className='relative h-full px-4'>
				<Layout className='relative h-full overflow-hidden'>
					<AnchoringManagementDatailsDrawer
						onClose={() => clearSelectedSku()}
						selectedSku={selectedSku as string}
					/>
					<div className='fade-in flex size-full flex-col overflow-hidden'>
						{assortmentQuery.isError && (
							<InlineMessage
								className='rounded-none border-b border-solid border-base'
								variant='danger'
							>
								{intl.get('assortment.table.error')}
							</InlineMessage>
						)}
						<Blanket
							className='absolute flex flex-col items-center justify-center space-y-5 rounded-lg bg-transparent'
							isTinted={assortmentQuery.isFetching}
						>
							<div className='fade-in z-50 rounded-lg bg-white/50 p-5 shadow backdrop-blur-md dark:bg-black/50'>
								<Loader />
							</div>
						</Blanket>
						<Header className='shrink-0 items-center justify-between py-2'>
							<div className='flex items-center space-x-2'>
								<TextSearchField value={searchText} onChange={setSearchText} />
								<TableFilters
									analyticEventPrefix='anchoring:'
									choices={cellsConfig.dataChoices!}
									schema={schema.filter(prop('filterable'))}
									filters={filters ?? []}
									onApply={handleApplyFilter}
								/>
								<div className='h-5 border-l border-solid border-base' />
								<QuickFiltersBar
									irremovableKeys={[]}
									rules={filters ?? []}
									fieldsConfig={{
										...getFiledConfig(
											map(toAdaptedSchema, schema.filter(prop('filterable'))),
											cellsConfig.dataChoices
										),
									}}
									storedKeys={storedQuickFiltersKeys}
									onSaveStoredKeys={saveStoredQuickFiltersKeys}
									onApply={handleApplyFilter}
								/>
								{selected?.length > 0 && (
									<Button
										size='small'
										variant='secondary'
										onClick={handleDeleteSelectedRows}
										iconBefore={<Trash2Icon />}
									>
										{intl.get('pc.action.bulk_delete', {
											value: selected.length,
										})}
									</Button>
								)}
							</div>
							<div className='flex items-center space-x-1'>
								<Tooltip content={intl.get('app.update_data').d('Update data')}>
									<Button
										key='import'
										onClick={handleImportReportClick}
										iconBefore={<UploadIcon />}
									/>
								</Tooltip>
								<Separator orientation='vertical' className='h-5' />
								<AnchoringManagementAlertsButton />
								<TableColumnsManager columns={columns} config={columnsConfig} />
							</div>
						</Header>
						{!assortmentQuery.isFetching &&
						!filters.length &&
						!rows.length &&
						!assortmentQuery.data?.total_count ? (
							<NoData
								className='flex-col pt-10'
								title={intl
									.get('anchors.table.empty.title')
									.d('Looks like there are no Anchored products')}
								subtitle={intl
									.get('anchors.table.empty.subtitle')
									.d('Import from file to anchor your products')}
							>
								<Button
									onClick={handleImportReportClick}
									variant='primary-brand'
								>
									{intl.get('app.import_from_file').d('Update data from file')}
								</Button>
							</NoData>
						) : (
							<AnchoringManagementTable
								rowClassNameGetter={(i, row) => {
									return getRowId(row.content) === selectedSku
										? 'bg-brand-25 dark:bg-brand-175'
										: ''
								}}
								selected={selected}
								setSelected={setSelected}
								getRowId={getRowId}
								rowHeightHandler={() => columnsConfig.rowHeight}
								sort={sort}
								setSort={setSort}
								rows={rows}
								columns={columns}
								columnsConfig={columnsConfig}
								emptyDataPlaceholder={
									!assortmentQuery.isFetching && (
										<NoData className='flex-col pt-10' />
									)
								}
							/>
						)}
						<Footer className='h-8 grow-0 items-center justify-between py-3'>
							<Pagination pagination={pagination} />
							<div className='flex items-center space-x-2'>
								<Badge size='small'>
									{intl.get('app.items_on_page')}:{' '}
									{assortmentQuery.data?.data?.length}
								</Badge>
								<Badge size='small'>
									{intl.get('app.total_items')}:{' '}
									{assortmentQuery.data?.total_count}
								</Badge>
								<Button
									size='small'
									disabled={!assortmentQuery.data?.total_count}
									className='py-0'
									onClick={handleExportReportClick}
									iconBefore={<DownloadIcon />}
								>
									{intl.get('app.download_table')}
								</Button>
							</div>
						</Footer>
					</div>
				</Layout>
			</Layout>
		</Layout>
	)
}

const AnchorTypeField = ({
	value,
	row,
	choices,
	...other
}: CellProp<string, AnchoringProductModel>) => {
	const contextcChoices = choices?.map((choice: DataOption) => ({
		...choice,
		icon:
			(row[
				`${choice.value}_anchor_count` as keyof AnchoringProductModel
			] as number) > 0 ? (
				<RelationGraphIcon className='mr-1' />
			) : null,
	}))
	return (
		<BadgeField {...other} row={row} value={value} choices={contextcChoices} />
	)
}
const getRowId = pipe([props(['sales_entity_id', 'sku']), join('__')])

const enrichValueGetters = assignProp('valueGetter', {
	anchor_type: getAchorTypes,
})
const enrichLocked = assignProp('locked', {
	sku: true,
	title: true,
})
const enrichWidth = assignProp('width', {
	sku: 150,
	title: 300,
})
const enrichSortable = assignProp('sortable', {
	anchor_type: false,
	optimization_group_id: false,
})
