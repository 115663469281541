import clsx from 'clsx'
import { AnimatePresence } from 'framer-motion'
import {
	filter,
	map,
	pipe,
	prop,
	propEq,
	propOr,
	reduce,
	sum,
	values,
} from 'lodash/fp'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import {
	createColumnHelper,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table'

import {
	Content,
	DeltaValue,
	Dot,
	InlineMessage,
	Result,
	SegmentGroup,
	SegmentGroupItem,
} from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import { Operators } from '@cmpkit/query-builder'

import NumberField from '@/components/data-grid/fields/Number/TableCell'
import ProgressRow from '@/components/Progress/ProgressRow'
import { DataTable } from '@/components/tables'
import { FilterRuleModel, OptimizationIDModel } from '@/generated'
import { useMainSourceProperty } from '@/hooks/data'
import intl from '@/locale'
import { useRepricingStatisticQuery } from '@/modules/core/queries'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'

import {
	GROUP_VALUE_COLORS,
	Metric,
	MetricGroup,
	MetricGroupValue,
} from '../../constants'
import { getQueryParams } from './helpers'
import { TabContent } from './OptimizationsSummary'

type RepricingDataType = {
	groupValue: MetricGroupValue
	color: string
} & {
	[key in string]: string | number
}

export default function ItemsChangedGlobalWidget({
	isLoading: isDataLoading,
	queryParams,
}: {
	queryParams: {
		optimizations: OptimizationIDModel[]
		filters: FilterRuleModel[]
	} | null
	isLoading: boolean
}) {
	const [activeTab, setActiveTab] = useState('margin')
	const navigate = useNavigate()
	const sourceProperty = useMainSourceProperty()
	const { Locked, Increased, Decreased, NotChanged } = MetricGroupValue

	/**Queries */
	const {
		data: repricingStatistic,
		isLoading,
		isError,
		error,
	} = useRepricingStatisticQuery(queryParams!, {
		enabled: !!queryParams && !isDataLoading,
		refetchOnMount: true,
		staleTime: 0,
		select: pipe(
			prop('metrics'),
			filter(propEq('group_type', MetricGroup.PriceChangeStatus)),
			/*eslint-disable @typescript-eslint/no-explicit-any */
			reduce((acc: any, item: Metric) => {
				const groupValue = item.group_value as MetricGroupValue
				if (!acc[groupValue]) {
					acc[groupValue] = {
						color: GROUP_VALUE_COLORS[groupValue] || '',
						groupValue,
					}
				}
				acc[groupValue]![item.name] = item.value
				return acc
			}, {})
		),
	})
	const total = sum(map(propOr(0, 'product_count'), values(repricingStatistic)))
	const filters = useMemo(() => {
		return [
			{
				name: 'optimization_group_id',
				operation: 'in',
				value: map(
					prop('optimization_group_id'),
					queryParams?.optimizations || []
				),
			},
		]
	}, [queryParams])
	const rows = useMemo(() => {
		const data = repricingStatistic
		return [
			{
				name: intl.get('status_panel_decreased'),
				products: data?.[Decreased].product_count,
				lines: data?.[Decreased].pricing_line_count,
				color: data?.[Decreased].color,
				filters: [
					{
						name: `diff_${sourceProperty}_pp`,
						operation: Operators.LT,
						value: 0,
					},
				],
			},
			{
				name: intl.get('status_panel_increased'),
				products: data?.[Increased].product_count,
				lines: data?.[Increased].pricing_line_count,
				color: data?.[Increased].color,
				filters: [
					{
						name: `diff_${sourceProperty}_pp`,
						operation: Operators.GT,
						value: 0,
					},
				],
			},
			{
				name: intl.get('status_panel_price_locked'),
				products: data?.[Locked].product_count,
				lines: data?.[Locked].pricing_line_count,
				color: data?.[Locked].color,
				filters: [
					{
						name: 'is_locked',
						operation: Operators.BL,
						value: 1,
					},
				],
			},
			{
				name: intl.get('status_panel_price_not_changed'),
				products: data?.[NotChanged].product_count,
				lines: data?.[NotChanged].pricing_line_count,
				color: data?.[NotChanged].color,
				filters: [
					{
						name: `diff_${sourceProperty}_pp`,
						operation: Operators.EQ,
						value: 0,
					},
					{
						name: 'is_locked',
						operation: Operators.BL,
						value: 0,
					},
				],
			},
		]
	}, [repricingStatistic])

	if (isLoading || isDataLoading) {
		return <LoadingPlaceholder />
	}
	if (isError) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger' className='w-full'>
				{error.message || intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}
	return (
		!isLoading &&
		!isDataLoading &&
		repricingStatistic && (
			<div className='flex w-full flex-col space-y-3'>
				<div className='space-y-2'>
					{rows.map((row) => (
						<ProgressRow
							onClick={() => {
								analytic.logEvent(
									'opt summary glob: items changed widget: click row',
									{
										filters: [...row.filters, ...filters],
									}
								)
								navigate(
									`${location.pathname}${getQueryParams([...row.filters, ...filters])}`
								)
							}}
							barClassName={row.color}
							label={row.name}
							relativeValue={(row.products / total) * 100 || 0}
							absoluteValue={formatNumber(row.products) || 0}
							extraValue={formatNumber(row.lines)}
						/>
					))}
				</div>
				<div className='flex'>
					<SegmentGroup size='small'>
						<SegmentGroupItem
							active={'margin' === activeTab}
							onClick={() => setActiveTab('margin')}
						>
							{intl.get('general_margin').d('Margin')}
						</SegmentGroupItem>
						<SegmentGroupItem
							active={'discount' === activeTab}
							onClick={() => setActiveTab('discount')}
						>
							{intl.get('general_discount').d('Discount')}
						</SegmentGroupItem>
					</SegmentGroup>
				</div>
				<Content className='relative w-full'>
					<AnimatePresence mode={'wait'}>
						<TabContent key={activeTab}>
							<ItemsChangeTable
								metricType={activeTab}
								data={values(repricingStatistic)}
							/>
						</TabContent>
					</AnimatePresence>
				</Content>
			</div>
		)
	)
}
export const ItemsChangeTable = ({
	metricType,
	data = [],
}: {
	data: RepricingDataType[]
	metricType: string
}) => {
	const columns = useMemo(() => getColumns(metricType) || [], [])
	const table = useReactTable<RepricingDataType>({
		columns,
		data,
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		getCoreRowModel: getCoreRowModel(),
	})
	if (!data?.length) {
		return (
			<div className='flex max-h-[350px] w-full flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-4 py-5'>
				<Result
					icon={<SearchIcon width={72} height={72} />}
					subtitle={intl.get('no_data')}
				/>
			</div>
		)
	}

	return (
		<div className='cmp-border overflow-hidden rounded-lg'>
			<DataTable compact table={table} getRowClassName={() => 'h-8'} />
		</div>
	)
}

const columnHelper = createColumnHelper<RepricingDataType>()
const getColumns = (metricType: string) => [
	columnHelper.accessor('groupValue', {
		header: () => intl.get('metric.trend_feature').d('Trend'),
		cell: (info) => {
			const key = info.getValue()
			return (
				<div className='items-center space-x-1 text-xs'>
					<Dot className={clsx('size-2', info.row.original.color)} />
					<span>{intl.get(`overview_price_${key}`).d(key)}</span>
				</div>
			)
		},
	}),
	columnHelper.accessor(`${metricType}_init`, {
		size: 70,
		header: () => intl.get('"current"').d('Current'),
		cell: (info) => {
			return (
				<div className='text-xs'>
					<NumberField value={info.getValue()} />
				</div>
			)
		},
	}),
	columnHelper.accessor(`${metricType}_final`, {
		size: 70,
		header: () => intl.get('general_new').d('New'),
		cell: (info) => {
			return (
				<div className='text-xs'>
					<NumberField value={info.getValue()} />
				</div>
			)
		},
	}),
	columnHelper.accessor('margin_final', {
		size: 70,
		header: () => 'Δ',
		cell: (info) => {
			const value =
				Number(info.row.original[`${metricType}_final`]) -
					Number(info.row.original[`${metricType}_init`]) || 0
			return (
				<DeltaValue value={value} className='text-xs'>
					<NumberField value={value} />
				</DeltaValue>
			)
		},
	}),
]

function LoadingPlaceholder() {
	return (
		<div className='flex flex-col items-center space-y-2'>
			<div className='flex max-h-[400px] w-full items-center justify-between'>
				<div className='h-5 w-20 animate-pulse rounded bg-accent-4' />
				<div className='h-5 w-10 animate-pulse rounded bg-accent-4' />
			</div>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-24 animate-pulse rounded bg-accent-4' />
				<div className='h-5 w-12 animate-pulse rounded bg-accent-4' />
			</div>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-28 animate-pulse rounded bg-accent-4' />
				<div className='h-5 w-12 animate-pulse rounded bg-accent-4' />
			</div>
		</div>
	)
}
