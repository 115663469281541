import { indexBy, prop } from 'lodash/fp'
import { CheckCircle2Icon, SettingsIcon } from 'lucide-react'
import React from 'react'

import {
	Avatar,
	Badge,
	Button,
	Card,
	CardContent,
	CardHeader,
	CardSubtitle,
	CardTitle,
	Tag,
} from '@cmpkit/base'
import Popover from '@cmpkit/popover'

import { ReviewerItemModel, ReviewModel } from '@/generated'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'
import { useAllUsersQuery } from '@/modules/Users/queries'
import { getUsername } from '@/modules/Users/utils'

const ReviewersList = ({ review }: { review: ReviewModel }) => {
	const { showModal } = useModalStore()
	const users = useAllUsersQuery()
	const usersDict = indexBy('id', users.data)
	const renderUser = ({ id, is_approved }: ReviewerItemModel) => {
		const name = usersDict?.[id]
			? getUsername(usersDict?.[id])
			: intl.get('app.unknown').d('Unknown')
		const email = usersDict?.[id]?.email || ''
		return (
			<div key={id} className='flex items-center gap-2'>
				<Avatar name={name} className='rounded-lg' size='medium' />
				<div>
					<div className='text-xs font-medium'>{name}</div>
					<div className='text-xs text-muted'>{email}</div>
				</div>
				<div className='ml-auto'>
					{is_approved ? (
						<Tag variant='success'>{intl.get('approved').d('Approved')}</Tag>
					) : (
						<Tag variant='warning'>{intl.get('pending').d('Pending')}</Tag>
					)}
				</div>
			</div>
		)
	}
	return (
		<Card className='min-w-96 border shadow'>
			<CardHeader>
				<div className='flex justify-between'>
					<CardTitle>{intl.get('reviewers').d('Reviewers')}</CardTitle>
					<Button
						variant='tertiary'
						size='small'
						onClick={() =>
							showModal('REVIEW_MANAGEMENT', {
								optGroupId: review.optimization_group_id,
							})
						}
						iconBefore={<SettingsIcon />}
					/>
				</div>
				<CardSubtitle>
					{intl
						.get('reviewers.description')
						.d(
							'The list of users who will review the optimization group results.'
						)}
				</CardSubtitle>
			</CardHeader>
			<CardContent className='flex flex-col gap-4'>
				{review.reviewers.map(renderUser)}
			</CardContent>
		</Card>
	)
}
export default function ReviewersPreview({ review }: { review: ReviewModel }) {
	const approved = review.reviewers.filter(prop('is_approved'))
	const completed = approved.length === review.reviewers.length
	return (
		<Popover placement='bottom-end' content={<ReviewersList review={review} />}>
			<Badge interactive variant={completed ? 'success' : 'neutral'}>
				<CheckCircle2Icon className='size-4' />
				{`${approved?.length}/${review.reviewers?.length} ${intl.get('completed')}`}
			</Badge>
		</Popover>
	)
}
