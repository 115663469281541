import React, { createContext, useMemo, useState } from 'react'

export interface PageNavigatorContextValue {
	register(comp: React.ReactNode): void
	unregister(): void
	getHeader(): React.ReactNode
}

export const PageNavigatorContext = createContext(
	{} as PageNavigatorContextValue
)
export const PageNavigatorConsumer = PageNavigatorContext.Consumer

export default function PageNavigatorProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const [regiteredComponent, setRegiteredComponent] =
		useState<React.ReactNode | null>(null)
	const register = setRegiteredComponent

	const unregister = () => setRegiteredComponent(null)
	const getHeader = () => {
		return regiteredComponent
	}
	const value = useMemo(
		(): PageNavigatorContextValue => ({
			register,
			unregister,
			getHeader,
		}),
		[regiteredComponent, register, unregister]
	)

	return (
		<PageNavigatorContext.Provider value={value}>
			{children}
		</PageNavigatorContext.Provider>
	)
}
