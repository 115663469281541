import { prop } from 'lodash/fp'
import React, { useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import { SortingState } from '@tanstack/react-table'

import {
	Badge,
	Button,
	Content,
	Footer,
	Header,
	Layout,
	Loader,
	Separator,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'

import CompactSearch from '@/components/CompactSearch'
import NumberField from '@/components/data-grid/fields/Number/TableCell'
import { ColumnDataType } from '@/components/data-grid/types'
import { getSortedRowModel } from '@/components/table/getSortedRowModel'
import { useMasterTable } from '@/components/table/hooks/useMasterTable'
import MasterTable from '@/components/table/MasterTable'
import { createMasterColumnHelper } from '@/components/table/utils/tanstack.helpers'
import { getCoreRowModel } from '@/components/table/utils/utils'
import {
	ColumnSchemaModel,
	PromoCampaignModel,
	PromoProductModel,
} from '@/generated'
import intl from '@/locale'
import analytic from '@/services/analytics'
import Sentry from '@/services/sentry'
import { download } from '@/tools/report'

import { usePromoProductsQuery } from '../queries'

export default function PromoCampaignProducts({
	campaign,
}: {
	campaign: PromoCampaignModel
}) {
	const [searchText, setSearchText] = useState('')
	const { id, name, date_start, date_end } = campaign

	const promoProductsQuery = usePromoProductsQuery(id!)
	const data = promoProductsQuery.data || []
	const handeDownload = () =>
		createAndDownloadFile(
			schema,
			promoProductsQuery.data!,
			`Promo: ${name} ${date_start} - ${date_end}`
		)
	const filteredData = !!searchText
		? data?.filter(({ sku }) => sku.includes(searchText))
		: data

	return (
		<Layout className='relative overflow-hidden'>
			<Blanket
				className='absolute flex flex-col items-center justify-center space-y-5 rounded-lg bg-transparent'
				isTinted={promoProductsQuery.isLoading}
			>
				<div className='fade-in z-50 rounded-lg bg-white/50 p-5 shadow backdrop-blur-md dark:bg-black/50'>
					<Loader />
				</div>
			</Blanket>
			<Header className='grow-0 justify-between px-3 pb-2'>
				<CompactSearch
					value={searchText}
					count={filteredData?.length}
					onChange={(event) => setSearchText(event.target.value)}
					placeholder={intl.get('products_search')}
					onClear={() => setSearchText('')}
				/>
			</Header>
			<Content className='h-full overflow-hidden px-3'>
				<div className='flex h-full flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
					<PromoCampaignProductsTable data={filteredData || []} />
				</div>
			</Content>
			<Footer className='grow-0 px-3 py-1'>
				<div className='flex items-center space-x-2'>
					<Badge className='bg-transparent px-0'>
						{intl.get('products_count_items')}:{' '}
						<span className='font-semibold'>{data?.length || 0}</span>
					</Badge>
					{filteredData?.length != data?.length && (
						<>
							<CaretRightIcon />
							{intl.get('products_count_items_filtered')}:{' '}
							<span className='font-semibold'>{filteredData?.length}</span>
						</>
					)}
					<Separator orientation='vertical' className='h-4' />
					<Button
						className='px-1 py-0'
						data-testid='download-table'
						onClick={handeDownload}
						iconBefore={<DownloadIcon className='mr-1 text-muted' />}
					>
						{intl.get('app.download_table')}
					</Button>
				</div>
			</Footer>
		</Layout>
	)
}

function PromoCampaignProductsTable({ data }: { data: PromoProductModel[] }) {
	const [sorting, setSorting] = useState<SortingState>([])
	const columns = useColumns()
	const table = useMasterTable<PromoProductModel>({
		data,
		columns,
		state: {
			sorting,
		},
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		getRowId: (row) => row.sku,
		enableColumnPinning: false,
		enableVirtualization: true,
		getRowHeight: () => 32,
		getRowCanExpand: () => true,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return <MasterTable table={table} />
}
const columnHelper = createMasterColumnHelper<PromoProductModel>()
function useColumns() {
	return useMemo(
		() => [
			columnHelper.accessor('sku', {
				size: 120,
				header: () => intl.get('sku').d('SKU'),
				cell: (info) => {
					return info.getValue()
				},
			}),
			columnHelper.accessor('title', {
				size: 120,
				header: () => intl.get('products_title').d('Title'),
				cell: (info) => {
					return <span>{info.getValue()}</span>
				},
			}),
			columnHelper.accessor('discount_percent', {
				size: 120,
				header: () => intl.get('discount_percent').d('Discount percent'),
				cell: (info) => {
					return <NumberField value={info.getValue()} />
				},
			}),
			columnHelper.accessor('discount_sum', {
				size: 120,
				header: () => intl.get('discount_sum').d('Discount sum'),
				cell: (info) => {
					return <NumberField value={info.getValue()} />
				},
			}),
			columnHelper.accessor('discount_price', {
				size: 120,
				header: () => intl.get('discount_price').d('Discount price'),
				cell: (info) => {
					return <NumberField value={info.getValue()} />
				},
			}),
			columnHelper.accessor('predicted_growth_rate', {
				size: 120,
				header: () =>
					intl.get('predicted_growth_rate').d('Predicted growth rate'),
				cell: (info) => {
					return <NumberField value={info.getValue()} />
				},
			}),
		],
		[]
	)
}
const schema: ColumnSchemaModel[] = [
	{
		aggregation_type: 'first',
		editable: false,
		grouper: true,
		hidden: false,
		name: 'sku',
		translate_key: 'products_sku',
		propagate: false,
		searchable: true,
		type: ColumnDataType.String,
		ui_schema: {
			type: 'title',
		},
		visible_name: 'SKU',
	},
	{
		aggregation_type: 'first',
		editable: false,
		grouper: true,
		hidden: false,
		name: 'title',
		translate_key: 'products_title',
		propagate: false,
		searchable: true,
		type: ColumnDataType.String,
		ui_schema: {
			type: 'title',
		},
		visible_name: 'Title',
	},
	{
		aggregation_type: 'first',
		editable: false,
		grouper: true,
		hidden: false,
		name: 'discount_percent',
		translate_key: 'discount_percent',
		propagate: false,
		searchable: true,
		type: ColumnDataType.Float,
		visible_name: 'Discount percent',
	},
	{
		aggregation_type: 'first',
		editable: false,
		grouper: true,
		hidden: false,
		name: 'discount_sum',
		translate_key: 'discount_sum',
		propagate: false,
		searchable: true,
		type: ColumnDataType.Float,
		visible_name: 'Discount sum',
	},
	{
		aggregation_type: 'first',
		editable: false,
		grouper: true,
		hidden: false,
		name: 'discount_price',
		translate_key: 'discount_price',
		propagate: false,
		searchable: true,
		type: ColumnDataType.Float,
		visible_name: 'Discount price',
	},
	{
		aggregation_type: 'first',
		editable: false,
		grouper: true,
		hidden: false,
		name: 'predicted_growth_rate',
		propagate: false,
		searchable: true,
		type: ColumnDataType.Float,
		translate_key: 'predicted_growth_rate',
		visible_name: 'Predicted growth rate',
	},
]
const createAndDownloadFile = async (
	schema: ColumnSchemaModel[],
	data: PromoProductModel[],
	name: string
) => {
	analytic.logEvent('promo: Download table')
	try {
		const Excel = await import('exceljs')
		const rows = data
		const workbook = new Excel.Workbook()
		workbook.creator = 'Competera Promo'
		workbook.lastModifiedBy = 'Competera Promo'
		workbook.created = new Date()
		workbook.modified = new Date()
		workbook.lastPrinted = new Date()
		const worksheet = workbook.addWorksheet('Sheet1')
		worksheet.addTable({
			name: 'Products',
			ref: 'A1',
			headerRow: true,
			totalsRow: false,
			style: {
				theme: 'TableStyleMedium1',
			},
			columns: schema.map((s) => ({
				name: intl.get(s.visible_name).d(s.visible_name),
				key: s.name,
				filterButton: true,
				width: 150,
			})),
			rows: rows.map((row) => schema.map(({ name }) => prop(name, row))),
		})
		const buffer = await workbook.xlsx.writeBuffer()
		const blob = new Blob([buffer], {
			type: 'application/octet-binary;charset=utf-8',
		})
		download(blob, `${name}.xlsx`)
	} catch (error) {
		Sentry.captureException(error)
		toast.error((error as Error)?.message ?? intl.get('fatal_error_title'), {
			id: 'promo',
			duration: 5000,
		})
	}
}
