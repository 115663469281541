import { AxiosError } from 'axios'
import { pickAll, prop, propOr } from 'lodash/fp'

import { useQuery } from '@tanstack/react-query'

import {
	BIAIRequest,
	BIAIResponse,
	BIRequest,
	BIResponse,
	BIRevenueRequest,
	BIRevenueResponse,
	BIRevision,
	HTTPValidationError,
	MetricModel,
	OptimizationAnalyticsModel,
	ProductCountByEngineRequestModel,
	ProductsCountByEngineModel,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'

export const useBIAnalyticQuery = <T = BIResponse>(
	body: BIRequest,
	options: UseQueryOptions<
		BIResponse | null,
		AxiosError<HTTPValidationError>,
		T
	> = {}
) => {
	/**
	 * Sort request body to avoid unnecessary requests
	 */
	const bosySorted = pickAll(
		[
			'start_date',
			'end_date',
			'metrics',
			'dimensions',
			'date_aggregation',
			'filters',
			'skip_dates',
			'product_filters',
		],
		body
	)
	return useQuery<BIResponse | null, AxiosError<HTTPValidationError>, T>({
		queryKey: ['bi', bosySorted],
		queryFn: () => client.bi.getBi(body),
		gcTime: 5 * 60 * 1000,
		staleTime: 5 * 60 * 1000,
		...options,
	})
}

export const useBIRevenueAnalyticQuery = <T = BIRevenueResponse>(
	body: BIRevenueRequest,
	options: UseQueryOptions<
		BIRevenueResponse,
		AxiosError<HTTPValidationError>,
		T
	> = {}
) => {
	/**
	 * Sort request body to avoid unnecessary requests
	 */
	const bosySorted = pickAll(
		[
			'start_date',
			'end_date',
			'revenue_start_date',
			'revenue_end_date',
			'metrics',
			'dimensions',
			'date_aggregation',
			'filters',
			'skip_dates',
		],
		body
	)
	return useQuery<BIRevenueResponse, AxiosError<HTTPValidationError>, T>({
		queryKey: ['bi-revenue', bosySorted],
		queryFn: () => client.bi.getBiRevenue(body),
		gcTime: 5 * 60 * 1000,
		staleTime: 5 * 60 * 1000,
		...options,
	})
}

export const useAiBIAnalyticQuery = <T = BIAIResponse>(
	body: BIAIRequest,
	options: UseQueryOptions<
		BIAIResponse,
		AxiosError<HTTPValidationError>,
		T
	> = {}
) => {
	return useQuery<BIAIResponse, AxiosError<HTTPValidationError>, T>({
		queryKey: ['bi-ai', body],
		queryFn: () => client.bi.getBiAi(body),
		gcTime: 5 * 60 * 1000,
		staleTime: 5 * 60 * 1000,
		...options,
	})
}

export const useBIRevisionQuery = <T = BIRevision | null>(
	options?: UseQueryOptions<BIRevision | null, Error, T>
) => {
	return useQuery<BIRevision | null, Error, T>({
		queryKey: ['bi-revision'],
		queryFn: () => client.bi.biRevision(),
		...(options || {}),
	})
}

export const useMetricsQuery = <T = MetricModel[]>(
	options?: UseQueryOptions<MetricModel[], Error, T>
) => {
	return useQuery<MetricModel[], Error, T>({
		queryKey: ['metrics'],
		queryFn: () => client.bi.listMetrics(),
		...(options || {}),
	})
}

export const useApplianceAnalyticsQuery = (
	ogId: string,
	options?: UseQueryOptions<any, Error, any> // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
	return useQuery({
		queryKey: ['appliance-analytics', ogId],
		queryFn: () =>
			client.analytics
				.getApplicanceAnalytics(ogId)
				.then((res) => propOr([], 'data', res)),
		...(options || {}),
	})
}

export const useProductsCountByEngineQuery = <
	T = {
		data?: ProductsCountByEngineModel[] | undefined
	},
>(
	body: ProductCountByEngineRequestModel,
	options?: UseQueryOptions<
		{
			data?: ProductsCountByEngineModel[] | undefined
		},
		AxiosError,
		T
	>
) => {
	return useQuery<
		{
			data?: ProductsCountByEngineModel[] | undefined
		},
		AxiosError,
		T
	>({
		queryKey: ['products-count-by-engine', body],
		queryFn: () => client.bi.getProductsCountByEngine(body),
		gcTime: 5 * 60 * 1000,
		staleTime: 5 * 60 * 1000,
		...options,
	})
}

export const useOptimizationAnalyticsQuery = (
	ogId: string,
	options?: UseQueryOptions<
		OptimizationAnalyticsModel[] | undefined,
		Error,
		OptimizationAnalyticsModel[] | undefined
	>
) => {
	return useQuery<
		OptimizationAnalyticsModel[] | undefined,
		Error,
		OptimizationAnalyticsModel[] | undefined
	>({
		queryKey: ['optimization-analytics', ogId],
		queryFn: () =>
			client.analytics.getOptimizationAnalytics(ogId).then(prop('data')),
		...(options || {}),
	})
}

export const useBiFieldsQuery = <T = object[] | null>(
	options?: UseQueryOptions<object[] | null, Error, T>
) => {
	return useQuery<object[] | null, Error, T>({
		queryKey: ['bi-fields'],
		queryFn: () => client.bi.biFields(),
		...(options || {}),
	})
}
export const useBiFieldValuesQuery = <T = string[] | null>(
	field: string,
	options?: UseQueryOptions<string[] | null, Error, T>
) => {
	return useQuery<string[] | null, Error, T>({
		queryKey: ['bi-fields', { field }],
		queryFn: () => client.bi.biFieldsValues(field),
		...(options || {}),
	})
}
