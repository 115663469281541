import clsx from 'clsx'
import { GripVerticalIcon } from 'lucide-react'

import { useDraggable } from '@dnd-kit/core'

import { linkButtonVariants } from '@cmpkit/base'

import { SettingsTemplateModel } from '@/generated'
import { useModalStore } from '@/modules/modals/store'

interface BuilderBlockElementProps {
	block: SettingsTemplateModel
}

export default function BuilderBlockElement({
	block,
}: BuilderBlockElementProps) {
	const { showModal } = useModalStore()
	const { attributes, listeners, setNodeRef } = useDraggable({
		id: block.id,
		data: {
			block,
		},
	})

	return (
		<div
			ref={setNodeRef}
			data-testid={`builder-section-${block.id}-draggable`}
			{...attributes}
		>
			<div className='group flex flex-col items-start gap-2 rounded-lg border bg-accent-1 p-3 text-sm leading-tight hover:bg-accent-2'>
				<div className='flex w-full items-center gap-2'>
					<GripVerticalIcon
						{...listeners}
						className='size-4 shrink-0 cursor-move text-muted'
					/>

					<div
						className={clsx(
							linkButtonVariants({ variant: 'brand' }),
							'line-clamp-3 flex gap-1 text-left font-normal'
						)}
						onClick={() =>
							showModal('SETTINGS_TEMPLATE_MODAL', {
								templateId: block.id,
								templateType: block.template_type,
							})
						}
					>
						{block.name}
					</div>
				</div>
			</div>
		</div>
	)
}

export function BuilderBlockElementOverlay({
	block,
}: BuilderBlockElementProps) {
	return (
		<div className='group flex flex-col items-start gap-2 rounded-lg border border-brand bg-accent-1 p-3 text-sm leading-tight shadow-md hover:bg-accent-2'>
			<div className='flex w-full items-center gap-2'>
				<GripVerticalIcon className='size-4 shrink-0 cursor-move text-muted' />
				<div className={'line-clamp-3 flex gap-1 text-left font-normal'}>
					{block.name}
				</div>
			</div>
		</div>
	)
}
