import {
	AnchorsExportTableModal,
	AnchorsImportFromFileModal,
} from '@/modules/anchoring-management'
import {
	AffectedOptimizationGroupsModal,
	ImportOptGroupsModal,
	OptimizationScheduleListModal,
	OptimizationScheduleModal,
	ReviewManagerModal,
} from '@/modules/core'
import {
	ProductsBulkUpdateModal,
	ProductsImportModal,
} from '@/modules/og-products'
import {
	CopyPricingCampaignModal,
	CopyPricingCampaignSettingsModal,
	DublicatePricingCampaignsModal,
	MovePricingCampaignsModal,
	PricinCampaignEditNameModal,
	PricingCampaignConfirmationUpdateModal,
	PricingCampaignCreateModal,
	PricingCampaignModal,
	PricingCampaignsPriorityModal,
	SelectCopyPricingCampaignFlowModal,
} from '@/modules/pricing-campaigns'
import {
	SavedReportsCreateReportModal,
	SavedReportsUpdateReportModal,
} from '@/modules/saved-reports'
import { CreateScenarioModal } from '@/modules/scenarios'
import { CreateJsdTicketModal } from '@/modules/support'

import CreateOptimizationGroupModal from '../core/components/CreateOptimizationGroup/CreateOptimizationGroupModal'
import { ExportAssortmentModal } from '../exports'
import ExportAdditionalDataModal from '../exports/components/ExportAdditionalDataModal'
import ExportsModal from '../exports/components/ExportsModal'
import {
	CopySettingsModal,
	OptimizationGroupModal,
	UpdateGeneralInfoModal,
} from '../og-settings'
import { SaveFiltersModal } from '../preferences'
import {
	ApplySettingsTemplateModal,
	SettingsTemplateCreateModal,
	SettingsTemplateModal,
} from '../settings-templates'

const registry = {
	// Common modals
	CREATE_JSD_TICKET: CreateJsdTicketModal,

	OPTIMIZATION_GROUPS_BULK_IMPORT: ImportOptGroupsModal,
	OPTIMIZATION_GROUP_MODAL: OptimizationGroupModal,
	COPY_SETTINGS: CopySettingsModal,
	OPTIMIZATION_SCHEDULE_MODAL: OptimizationScheduleModal,
	OPTIMIZATION_SCHEDULE_LIST_MODAL: OptimizationScheduleListModal,
	REVIEW_MANAGEMENT: ReviewManagerModal,
	EXPORT_ADDITIONAL_DATA: ExportAdditionalDataModal,
	EXPORTS: ExportsModal,
	EXPORT_ASSORTMENT: ExportAssortmentModal,

	// pricing settings

	CREATE_OPTIMIZATION_GROUP: CreateOptimizationGroupModal,

	// Products modals
	BULK_UPDATE: ProductsBulkUpdateModal,
	IMPORT_UPDATE: ProductsImportModal,

	// Reports modals
	CREATE_ASSORTMENT_REPORT: SavedReportsCreateReportModal,
	UPDTAE_ASSORTMENT_REPORT: SavedReportsUpdateReportModal,
	SAVE_FILTERS: SaveFiltersModal,

	// Anchoring modals
	IMPORT_ANCHORS_FROM_FILE: AnchorsImportFromFileModal,
	EXPORT_ANCHORS_TABLE: AnchorsExportTableModal,

	// Scenarios modals
	CREATE_SCENARIO: CreateScenarioModal,

	// Pricing campaigns modals
	PRICING_CAMPAIGNS_PRIORITY: PricingCampaignsPriorityModal,
	PRICING_CAMPAIGN_MODAL: PricingCampaignModal,
	CREATE_PRICING_CAMPAIGN_MODAL: PricingCampaignCreateModal,
	COPY_PRICING_CAMPAIGN_SETTINGS: CopyPricingCampaignSettingsModal,
	UPDATE_OPTIMIZATION_GROUP_INFO: UpdateGeneralInfoModal,
	SELECT_COPY_PRICING_CAMPAIGN_FLOW: SelectCopyPricingCampaignFlowModal,
	COPY_PRICING_CAMPAIGN: CopyPricingCampaignModal,
	DUBLICATE_PRICING_CANPAIGNS: DublicatePricingCampaignsModal,
	MOVE_PRICING_CANPAIGNS: MovePricingCampaignsModal,
	CONFIRM_PRICING_CAMPAIGN_SAVE: PricingCampaignConfirmationUpdateModal,
	EDIT_PRICING_CAMPAIGN_NAME: PricinCampaignEditNameModal,

	AFFECTED_OPTIMIZATION_GROUPS_MODAL: AffectedOptimizationGroupsModal,
	SETTINGS_TEMPLATE_MODAL: SettingsTemplateModal,
	SETTINGS_TEMPLATE_CREATE_MODAL: SettingsTemplateCreateModal,
	SETTINGS_TEMPLATE_APPLY_MODAL: ApplySettingsTemplateModal,
}
export default registry
