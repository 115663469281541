import React, { memo } from 'react'
import ContentLoader from 'react-content-loader'

import { Row } from '@cmpkit/base'

interface SkeletonProps {
	width?: number
	height?: number
}
export const Skeleton = memo(({ width = 100, height = 12 }: SkeletonProps) => (
	<div className='flex h-full items-center'>
		<ContentLoader
			speed={2}
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			backgroundColor='var(--cmp-accent-4)'
			foregroundColor='var(--cmp-accent-3)'
		>
			<rect x='0' y='0' rx='6' ry='6' width={width} height={height} />
		</ContentLoader>
	</div>
))
export const SkeletonRow = ({ style }: { style?: React.CSSProperties }) => {
	const random = Math.random() * (1 - 0.7) + 0.7
	return (
		<div style={style} className='cmp-border-bottom mb-1 px-2.5 py-3'>
			<Row noGutters>
				<div className='col-4'>
					<div className='flex'>
						<Skeleton width={12} />
						<div className='ml-5' />
						<Skeleton width={200 * random} />
					</div>
				</div>
				<div className='col text-center'>
					<Skeleton width={89 * random} />
				</div>
				<div className='col text-center'>
					<Skeleton width={117 * random} />
				</div>
				<div className='col text-center'>
					<Skeleton width={130 * random} />
				</div>
				<div className='col text-center'>
					<Skeleton width={60 * random} />
				</div>
			</Row>
		</div>
	)
}
export const SkeletonLoad = memo(() => (
	<div className='border-b'>
		{Array(15)
			.fill('')
			.map((e, i) => (
				<SkeletonRow key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
			))}
	</div>
))
