import { find, pipe, prop, set } from 'lodash/fp'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
	Button,
	FormError,
	FormGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
	Textfield,
} from '@cmpkit/base'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import HelpCircleIcon from '@cmpkit/icon/lib/glyph/help-circle'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import { DataChoice } from '@cmpkit/query-builder'
import Select, { SingleValue } from '@cmpkit/select'
import Tooltip from '@cmpkit/tooltip'

import { AnchoringRuleModel } from '@/generated'
import intl from '@/locale'
import analytic from '@/services/analytics'
import {
	ErrorSchema,
	mapChangeEventValue,
	valueAsNumber,
} from '@/tools/json-schema-utils'

import {
	getAnchoringBehaviorChoices,
	getAnchoringTypeChoices,
} from './constants'

const getErrorString = prop('message')
type AnchoringRulesProps = {
	formData: AnchoringRuleModel[]
	onChange: (value: AnchoringRuleModel[]) => void
	errorSchema: ErrorSchema
}

export function AnchoringRulesFiledController({ name }: { name: string }) {
	const { control } = useFormContext()
	return (
		<Controller
			name={`${name}.rules`}
			control={control}
			render={({ field, fieldState: { error } }) => {
				return (
					<div className='space-y-2'>
						<AnchoringRules
							errorSchema={error}
							formData={field.value}
							onChange={field.onChange}
						/>
						{error?.message && <FormError>{error?.message}</FormError>}
					</div>
				)
			}}
		/>
	)
}
function AnchoringRules({
	formData,
	onChange,
	errorSchema,
}: AnchoringRulesProps) {
	const handleAddRule = () => {
		analytic.logEvent('pricing-settings: anchoring: add anchor rule')
		onChange([
			...(formData || []),
			{
				anchoring: null,
				high_range: 0,
				high_range_behavior: null,
				low_range: 0,
				low_range_behavior: null,
			} as unknown as AnchoringRuleModel,
		])
	}

	const handleRemoveRule = (index: number) => {
		analytic.logEvent('pricing-settings: anchoring: remove anchor rule')
		onChange(formData?.filter((item, i) => i !== index) || [])
	}
	const handleChange = (index: number) => {
		return ({
			target: { value, name },
		}: {
			target: { value: string | number; name: string }
		}) => {
			onChange(
				formData?.map((item, i) => {
					return i === index ? { ...item, ...set(name, value, item) } : item
				}) || []
			)
		}
	}
	return (
		<>
			<div className='w-full border-b border-solid'>
				<Table>
					<TableHeader className='!top-auto'>
						<TableRow>
							<TableHead className='bg-transparent p-2'>
								{intl.get('anchoring.field.anchoring.label').d('Anchor type')}{' '}
							</TableHead>
							<TableHead className='w-36 bg-transparent p-2'>
								{intl.get('anchoring.field.low_range.label').d('Low range')}
								<Tooltip content={intl.get('anchoring.field.low_range.help')}>
									<HelpCircleIcon className='ml-1' height={11} width={11} />
								</Tooltip>
							</TableHead>
							<TableHead className='w-52 bg-transparent p-2'>
								{intl
									.get('anchoring.field.low_range_behavior.label')
									.d('Low range behavior')}
								<Tooltip
									content={intl.get('anchoring.field.low_range_behavior.help')}
								>
									<HelpCircleIcon className='ml-1' height={11} width={11} />
								</Tooltip>
							</TableHead>
							<TableHead className='w-36 bg-transparent p-2'>
								{intl.get('anchoring.field.high_range.label').d('High range')}
								<Tooltip content={intl.get('anchoring.field.high_range.help')}>
									<HelpCircleIcon className='ml-1' height={11} width={11} />
								</Tooltip>
							</TableHead>
							<TableHead className='w-52 bg-transparent p-2'>
								{intl
									.get('anchoring.field.high_range_behavior.label')
									.d('High range behavior')}
								<Tooltip
									content={intl.get('anchoring.field.high_range_behavior.help')}
								>
									<HelpCircleIcon className='ml-1' height={11} width={11} />
								</Tooltip>
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{formData?.length > 0 ? (
							formData?.map((rule, index) => {
								return (
									<AnchoringRule
										errorSchema={errorSchema?.[index]}
										onChange={handleChange(index)}
										key={index}
										rule={rule}
										onRemove={() => handleRemoveRule(index)}
									/>
								)
							})
						) : (
							<TableRow>
								<TableCell colSpan={5} className='border-none'>
									<div className='p-5 text-center text-muted'>
										{intl.get('general_no_data')}
									</div>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<Button
				variant='tertiary'
				iconBefore={<PlusIcon />}
				onClick={handleAddRule}
			>
				{intl.get('app.add_anchor').d('Add anchors set')}
			</Button>
		</>
	)
}

type AnchoringRuleProps = {
	rule: AnchoringRuleModel
	onChange: (value: {
		target: { name: string; value: string | number }
	}) => void
	errorSchema: Partial<AnchoringRuleModel>
	onRemove: () => void
}
function AnchoringRule({
	errorSchema,
	onChange,
	rule,
	onRemove,
}: AnchoringRuleProps) {
	const anchoringError = getErrorString(errorSchema?.anchoring)
	const highRangeError = getErrorString(errorSchema?.high_range)
	const highRangeBehaviorError = getErrorString(
		errorSchema?.high_range_behavior
	)
	const lowRangeError = getErrorString(errorSchema?.low_range)
	const lowRangeBehaviorError = getErrorString(errorSchema?.low_range_behavior)

	const { anchoringBehaviorChoices, anchoringTypeChoices } = useMemo(
		() => ({
			anchoringBehaviorChoices: getAnchoringBehaviorChoices(),
			anchoringTypeChoices: getAnchoringTypeChoices(),
		}),
		[]
	)
	const handleSelectChange =
		(name: string) => (option: SingleValue<DataChoice>) =>
			onChange({ target: { name, value: option?.value } })

	return (
		<TableRow>
			<TableCell className='border-none p-2'>
				<FormGroup className='mb-0'>
					<Select
						menuPortalTarget={document.body}
						options={anchoringTypeChoices}
						placeholder={intl.get('general_select').d('Select')}
						onChange={handleSelectChange('anchoring')}
						name={'anchoring'}
						value={
							find(
								{ value: rule.anchoring },
								anchoringTypeChoices
							) as DataChoice
						}
						invalid={Boolean(anchoringError)}
					/>
					{anchoringError && <FormError>{anchoringError}</FormError>}
				</FormGroup>
			</TableCell>
			<TableCell className='border-none p-2'>
				<FormGroup className='mb-0'>
					<Textfield
						className='w-32'
						name={'low_range'}
						type='number'
						invalid={lowRangeError}
						autoComplete='off'
						autoCorrect='off'
						value={rule.low_range}
						onChange={pipe([mapChangeEventValue(valueAsNumber), onChange])}
						addonAfter={'%'}
					/>

					{lowRangeError && <FormError>{lowRangeError}</FormError>}
				</FormGroup>
			</TableCell>
			<TableCell className='border-none p-2'>
				<FormGroup className='mb-0 w-full'>
					<Select
						options={anchoringBehaviorChoices}
						invalid={lowRangeBehaviorError}
						onChange={handleSelectChange('low_range_behavior')}
						name={'type'}
						value={find(
							{ value: rule.low_range_behavior },
							anchoringBehaviorChoices
						)}
					/>
					{lowRangeBehaviorError && (
						<FormError>{lowRangeBehaviorError}</FormError>
					)}
				</FormGroup>
			</TableCell>
			<TableCell className='border-none p-2'>
				<FormGroup className='mb-0'>
					<Textfield
						className='w-32'
						name={'high_range'}
						type='number'
						invalid={highRangeError}
						autoComplete='off'
						autoCorrect='off'
						value={rule.high_range}
						onChange={pipe([mapChangeEventValue(valueAsNumber), onChange])}
						addonAfter={'%'}
					/>
					{highRangeError && <FormError>{highRangeError}</FormError>}
				</FormGroup>
			</TableCell>
			<TableCell className='border-none p-2'>
				<div className='flex items-center'>
					<FormGroup className='mb-0 w-full'>
						<Select
							options={anchoringBehaviorChoices}
							invalid={highRangeBehaviorError}
							onChange={handleSelectChange('high_range_behavior')}
							name={'type'}
							value={find(
								{ value: rule.high_range_behavior },
								anchoringBehaviorChoices
							)}
						/>
						{highRangeBehaviorError && (
							<FormError>{highRangeBehaviorError}</FormError>
						)}
					</FormGroup>
					<Button
						className='self-start'
						variant='tertiary'
						iconBefore={<CrossIcon />}
						onClick={onRemove}
					/>
				</div>
			</TableCell>
		</TableRow>
	)
}
