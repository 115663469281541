import clsx from 'clsx'
import React, { Fragment } from 'react'

import { Column, flexRender, Row, RowData } from '@tanstack/react-table'

import { TableBody, TableCell, TableRow } from '@cmpkit/base'

import { useRows } from './hooks/useRows'
import { TableInstance } from './types'
import { getCommonPinningStyles } from './utils/utils'
import { Grid, GridCellStub, GridRowStub } from './virt/types'
import { TABLE_STUB_TYPE } from './virt/utils'

export default function MasterTableBody<TData extends RowData>({
	table,
	grid,
	renderGroup,
	layoutMode,
	renderDetailPanel,
	renderNoData,
}: {
	table: TableInstance<TData>
	grid?: Grid<TData>
	layoutMode?: string
	renderGroup?: (props: {
		row: Row<TData>
		table: TableInstance<TData>
	}) => React.ReactNode
	renderNoData?: () => React.ReactNode
	renderDetailPanel?: (props: {
		row: Row<TData>
		table: TableInstance<TData>
	}) => React.ReactNode
}) {
	/* const {
		/*  getBottomRows, getIsSomeRowsPinned,  /* getRowModel getState,
	} = table */
	/* 	const { rowPinning } = getState() */
	const {
		options: { localization, getRowClassName },
	} = table
	const rows = useRows(table)
	//const rowVirtualizer = useRowVirtualizer(table, rows)
	//console.log('🚀 ~ rowVirtualizer:', rowVirtualizer)
	/* const pinnedRowIds = useMemo(() => {
		if (!rowPinning.bottom?.length && !rowPinning.top?.length) return []
		return getRowModel()
			.rows.filter((row) => row.getIsPinned())
			.map((r) => r.id)
	}, [rowPinning, getRowModel().rows]) */
	const isVirtulaized = !!grid?.rows

	if (!rows?.length) {
		return (
			<TableRow className='bg-transparent hover:bg-transparent'>
				<TableCell
					colSpan={table.getVisibleLeafColumns().length}
					className='h-20 border-none text-center'
				>
					{renderNoData?.() || (
						<span className='text-muted'>{localization.noResultsFound}</span>
					)}
				</TableCell>
			</TableRow>
		)
	}
	return (
		<TableBody>
			{isVirtulaized
				? grid?.rows.map((virtualRow) => {
						if (isVirtulaized && isGridRowStub(virtualRow.row)) {
							return (
								<tr key={virtualRow.row.key} className='h-8'>
									<td style={{ height: `${virtualRow.row.height}px` }} />
								</tr>
							)
						}
						const row = virtualRow.row as Row<TData>

						const visibleCells = row.getVisibleCells()
						const cells = virtualRow.cells
						if (row.groupingColumnId) {
							return (
								<TableRow
									key={row.id}
									data-testid='mt-group-row'
									data-rowid={row.id}
									className='h-8'
								>
									<TableCell
										colSpan={cells.length}
										className={clsx('min-h-8 px-2', {
											'bg-accent-2': !row.getIsExpanded(),
											'bg-accent-1': row.getIsExpanded(),
										})}
									>
										{renderGroup?.({ row, table }) || 'NONE'}
									</TableCell>
								</TableRow>
							)
						}
						return (
							<TableRow
								key={row.id}
								data-testid='mt-row'
								data-rowid={row.id}
								data-state={row.getIsSelected() ? 'selected' : undefined}
								className={clsx(getRowClassName?.({ row, table }))}
							>
								{cells.map((virtualCell, cellIndex) => {
									if (isGridCellStub(virtualCell.column)) {
										return (
											<TableCell
												data-testid={`mt-cell-${cellIndex}:${row.index}`}
												colSpan={virtualCell.colSpan}
												key={virtualCell.column.key}
												className='h-9 px-2'
												style={{
													width: virtualCell.column.width!,
													minWidth: virtualCell.column.width!,
													maxWidth: virtualCell.column.width!,
												}}
											/>
										)
									}
									const cell = visibleCells[virtualCell.index]

									return (
										<TableCell
											data-testid={`mt-cell-${cellIndex}:${row.index}`}
											data-columnid={cell.id}
											className='h-9 px-2'
											key={`${row.id}_${cell.id}`}
											style={getCommonPinningStyles<TData>(
												cell.column,
												layoutMode
											)}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</TableCell>
									)
								})}
							</TableRow>
						)
					})
				: rows.map((row) => {
						const visibleCells = row.getVisibleCells()

						if (row.groupingColumnId) {
							return (
								<TableRow
									data-testid='mt-group-row'
									data-rowid={row.id}
									key={row.id}
									className='h-8'
								>
									<TableCell
										colSpan={visibleCells.length}
										className={clsx('h-20 px-2', {
											'bg-accent-2': !row.getIsExpanded(),
											'bg-accent-1': row.getIsExpanded(),
										})}
									>
										{renderGroup?.({ row, table }) || 'NONE'}
									</TableCell>
								</TableRow>
							)
						}

						return (
							<Fragment key={row.id}>
								<TableRow
									data-testid='mt-row'
									data-rowid={row.id}
									data-state={row.getIsSelected() ? 'selected' : undefined}
									className={clsx(getRowClassName?.({ row, table }))}
								>
									{visibleCells.map((cell, cellIndex) => {
										return (
											<TableCell
												data-testid={`mt-cell-${cellIndex}:${row.index}`}
												data-columnid={cell.id}
												className='h-9 px-2'
												key={`${row.id}_${cell.id}`}
												style={getCommonPinningStyles<TData>(
													cell.column,
													layoutMode
												)}
											>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
											</TableCell>
										)
									})}
								</TableRow>
								{row.getIsExpanded() && row.getCanExpand() && (
									<TableRow data-testid='mt-detail-row' data-refrowid={row.id}>
										<TableCell colSpan={visibleCells.length}>
											{renderDetailPanel?.({ row, table }) || 'None'}
										</TableCell>
									</TableRow>
								)}
							</Fragment>
						)
					})}
		</TableBody>
	)
}
const isGridRowStub = <T,>(row: Row<T> | GridRowStub): row is GridRowStub => {
	return (row as GridRowStub).type === TABLE_STUB_TYPE
}
const isGridCellStub = <T,>(
	column: Column<T> | GridCellStub
): column is GridCellStub => {
	return (column as GridCellStub).type === TABLE_STUB_TYPE
}
