import clsx from 'clsx'
import { omitAll, prop } from 'lodash/fp'
import { XIcon } from 'lucide-react'
import React, { useState } from 'react'

import {
	defaultDropAnimationSideEffects,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	DropAnimation,
	UniqueIdentifier,
	useDndMonitor,
	useDroppable,
} from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import {
	arrayMove,
	defaultAnimateLayoutChanges,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { Badge, Button } from '@cmpkit/base'
import DotsDraggableIcon from '@cmpkit/icon/lib/glyph/dots-draggable'

import intl from '@/locale'
import { EngineBadge } from '@/modules/pricing-campaigns'
import { getPricingCampaignEngine } from '@/modules/pricing-campaigns/helpers'

import { PricingCampaignTemplateFormData } from '../../types'

const dropAnimationConfig: DropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: '0.5',
			},
		},
	}),
}
const simpleHash = (str: string) => {
	let hash = 0
	for (let i = 0; i < str.length; i++) {
		const char = str.charCodeAt(i)
		hash = (hash << 5) - hash + char
	}
	// Convert to 32bit unsigned integer in base 36 and pad with "0" to ensure length is 7.
	return (hash >>> 0).toString(36).padStart(7, '0')
}
export default function PricingCampaignsSection({
	data: _pricingCampaigns,
	onChange,
}: {
	data: PricingCampaignTemplateFormData[]
	onChange: (items: PricingCampaignTemplateFormData[]) => void
}) {
	const pricingCampaigns = _pricingCampaigns.map((pc) => ({
		id: simpleHash(JSON.stringify(pc)),
		...pc,
	}))
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event
		setActiveId(active.id)
	}
	const handleRemove = (id: UniqueIdentifier) => {
		onChange(
			pricingCampaigns
				.filter((item) => item.id !== id)
				.map(omitAll(['id']))
				.map((item, index) => ({ ...item, order: index + 1 }))
		)
	}

	const ids: UniqueIdentifier[] = pricingCampaigns.map(({ id }) => id)
	const getIndex = (id: UniqueIdentifier) => ids.indexOf(id)
	const activeIndex = activeId != null ? getIndex(activeId) : -1
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (active.id !== over?.id && over) {
			const initialOrders = pricingCampaigns.map(prop('order'))
			const oldIndex = ids.indexOf(active.id)
			const newIndex = ids.indexOf(over.id)
			const reordered = arrayMove(pricingCampaigns, oldIndex, newIndex).map(
				(item, i) => ({
					...item,
					order: initialOrders[i]!,
				})
			)

			onChange(reordered)
		}
		setActiveId(null)
	}
	useDndMonitor({
		onDragStart(event) {
			if (event.active.data.current?.type === 'pricing-campaign') {
				handleDragStart(event)
			}
		},
		onDragEnd(event) {
			if (event.active.data.current?.type === 'pricing-campaign') {
				handleDragEnd(event)
			}
		},
	})
	return (
		<>
			<SortableContext
				items={pricingCampaigns}
				strategy={verticalListSortingStrategy}
			>
				{pricingCampaigns.map((item, index) => {
					return (
						<SortableItem
							key={item.id}
							id={item.id}
							index={index}
							pricingCampaign={item}
							onRemove={() => handleRemove(item.id)}
						/>
					)
				})}
			</SortableContext>
			<DragOverlay dropAnimation={dropAnimationConfig}>
				{activeId !== null ? (
					<div className='mb-1 flex'>
						<PricingCampaignItem
							pricingCampaign={pricingCampaigns[activeIndex]}
						/>
					</div>
				) : null}
			</DragOverlay>
		</>
	)
}
const PricingCampaignItem = ({
	pricingCampaign,
	listeners,
	onRemove,
}: {
	pricingCampaign: PricingCampaignTemplateFormData
	listeners?: SyntheticListenerMap
	onRemove?: () => void
}) => {
	return (
		<div className='flex w-full items-center space-x-2'>
			<div
				className={
					'flex w-full items-center gap-2 overflow-hidden rounded-lg border bg-accent-1 p-2 shadow-sm hover:bg-accent-2'
				}
			>
				<div className='flex items-center'>
					<DotsDraggableIcon {...listeners} className='shrink-0 cursor-grab' />
				</div>
				<Badge>{pricingCampaign.order}</Badge>
				<div className='flex w-full items-center gap-2 font-medium'>
					{pricingCampaign.name}
				</div>
				<div className='flex items-center gap-2'>
					<EngineBadge engine={getPricingCampaignEngine(pricingCampaign)}>
						{intl.get(
							'campaigns_om_title_' + getPricingCampaignEngine(pricingCampaign)
						)}
					</EngineBadge>

					<Button
						onClick={onRemove}
						size='small'
						iconBefore={<XIcon />}
						variant='tertiary'
					/>
				</div>
			</div>
		</div>
	)
}

function SortableItem({
	id,
	pricingCampaign,
	onRemove,
	index,
}: {
	id: string
	index: number
	pricingCampaign: PricingCampaignTemplateFormData
	onRemove?: () => void
}) {
	const bottomHalf = useDroppable({
		id: id + '-bottom',
		data: {
			isPricingCampaign: true,
			index,
		},
	})
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({
		id,
		data: {
			block: pricingCampaign,
			type: 'pricing-campaign',
		},
		animateLayoutChanges: defaultAnimateLayoutChanges,
	})
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
	}
	return (
		<div ref={setNodeRef} style={style} {...attributes} className='relative'>
			<div
				className={clsx('fade-in relative mb-1 flex', {
					'opacity-0': isDragging,
				})}
			>
				<div
					ref={bottomHalf.setNodeRef}
					className='absolute bottom-0 h-3 w-full rounded-b-md'
				/>

				<PricingCampaignItem
					pricingCampaign={pricingCampaign}
					listeners={listeners}
					onRemove={onRemove}
					{...attributes}
				/>
			</div>
			{bottomHalf.isOver && (
				<div className='absolute bottom-0 flex h-1 w-full rounded-md bg-brand' />
			)}
		</div>
	)
}
