import {
	any,
	filter,
	groupBy,
	map,
	negate,
	orderBy,
	pipe,
	prop,
	sum,
	values,
} from 'lodash/fp'
import React, { createContext, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import { dialog } from '@/components/dialogs'
import { AlertGroupBy, AlertInfoModel, PricingCampaignModel } from '@/generated'
import intl from '@/locale'
import { useCheckOptimizationForDeleteExport } from '@/modules/core/hooks/useCheckOptimizationForDeleteExport'
import { useBeforeGroupChange } from '@/modules/core/mutations'
import { usePricingAlertsQuery } from '@/modules/core/queries'
import analytic from '@/services/analytics'
import { processingMessage } from '@/tools/message'

import {
	getAssortmentShare,
	getCountsByPc,
	getOgsByPc,
	isGlobalPricingCampaign,
} from '../../helpers'
import {
	useBulkDeletePricingCampaignsMutation,
	useDeletePricingCampaignMutation,
	useDublicatePricingCampaignsMutation,
} from '../../mutations'
import {
	usePricingCampaignsQuery,
	useProductsCountsByPcQuery,
} from '../../queries'
import { PricingCampaignsScope } from '../../types'

export type PricingCampaignTableRow = {
	campaign: PricingCampaignModel
	alerts_count: number
	assortment_share: number | null
	products_count: number
	optimization_groups?: string[]
	order: number
}
export interface PricingCampaignContextProps {
	deletePricingCampaign(id: string): Promise<void>
	duplicatePricingCampaign(id: string): Promise<void>
	deleteSelectedPricingCampaigns(): Promise<void>
	setSelected(selected: string[]): void
	selected: string[]
	isLoading: boolean
	scope: PricingCampaignsScope
	data: PricingCampaignTableRow[]
}

export const PricingCampaignsContext =
	createContext<PricingCampaignContextProps>({} as any) // eslint-disable-line

export default function PricingCampaignsProvider({
	children,
	scope,
	analyticsPrefix,
}: {
	children: React.ReactNode
	scope: PricingCampaignsScope
	analyticsPrefix: string
}) {
	const optimizationGroupId = scope?.optimization_group_id
	const queryClient = useQueryClient()
	/**
	 * Hooks below should be used only for Optimization Group Scope
	 * if it is not Optimization Group Scope, then hooks below will just pass
	 */
	const { onMutate } = useBeforeGroupChange(optimizationGroupId)
	const checkExport = useCheckOptimizationForDeleteExport(optimizationGroupId)
	const [selected, setSelected] = useState<string[]>([])

	/**
	 * Queries
	 */

	const campaigns = usePricingCampaignsQuery<PricingCampaignModel[]>(scope, {
		select: orderBy<PricingCampaignModel>(
			['optimization_group_id', 'name'],
			['desc', 'asc']
		),
	})

	const alerts = usePricingAlertsQuery<Record<string, AlertInfoModel[]>>(
		{
			group_by: AlertGroupBy.PricingCampaignId,
			optimization_group_ids: optimizationGroupId ? [optimizationGroupId] : [],
		},
		{
			select: pipe([prop('by_level'), groupBy(prop('pricing_campaign_id'))]),
		}
	)
	// Filters for products counts by pc query and for total products count (optimization group scope)
	const ogScopeFilters = useMemo(
		() => ({
			filters: !!scope?.optimization_group_id
				? [
						{
							name: 'optimization_group_id',
							operation: 'is',
							value: scope.optimization_group_id,
						},
					]
				: [],
		}),
		[scope]
	)

	const productsCountsByPc = useProductsCountsByPcQuery(ogScopeFilters)
	/**
	 * Mutations
	 */
	const deletePricingCampaign = useDeletePricingCampaignMutation({
		onMutate,
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['pricings-settings'],
			})
			queryClient.invalidateQueries({
				queryKey: ['products-counts-by-pc'],
			})

			return queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns', scope],
			})
		},
	})
	const bulkDeletePricingCampaigns = useBulkDeletePricingCampaignsMutation({
		onMutate,
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['pricings-settings'],
			})
			queryClient.invalidateQueries({
				queryKey: ['products-counts-by-pc'],
			})
			return queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns', scope],
			})
		},
	})
	const dublicate = useDublicatePricingCampaignsMutation({
		mutationKey: ['dublicatePricingCampaign'],
		onMutate,
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns-segments', scope],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
			queryClient.invalidateQueries({
				queryKey: ['products-counts-by-pc'],
			})
		},
	})

	/**
	 * Computed
	 */
	const isLoading = any(prop('isLoading'), [campaigns, productsCountsByPc])

	const pcIdsWithProducts = useMemo(
		() => productsCountsByPc.data?.map(prop('pricing_campaign_id')) || [],
		[productsCountsByPc.data]
	)
	/**
	 * Handlers
	 */

	const handleDublicatePricingCampaign = useCallback(
		(id: string) => {
			toast.loading(processingMessage(), {
				id: 'dublicate_action',
			})
			dublicate.mutate(
				{
					src_pricing_campaign_ids: [id],
					dst_optimization_group_ids: [optimizationGroupId!],
				},
				{
					onSuccess: () =>
						toast.success(intl.get('success_dublicated'), {
							id: 'dublicate_action',
						}),
				}
			)
		},
		[optimizationGroupId]
	)

	const handleDeleteSelectedPricingCampaigns = useCallback(async () => {
		const answer = await dialog.confirmDelete({
			title: intl.get('pc_detete_confirm_title'),
			text: intl.get('pc_detete_confirm_subtitle'),
			okText: intl.get('general_delete'),
		})
		if (answer) {
			toast.loading(processingMessage(), {
				id: 'delete_action',
			})
			analytic.logEvent(`${analyticsPrefix} bulk delete PCs`)
			bulkDeletePricingCampaigns.mutate(selected, {
				// TODO: Refactor in future
				onSuccess: (responses) => {
					const isSuccessResponse = ({ status }: { status: string }) =>
						status === 'fulfilled'
					const successResponses = responses.filter(isSuccessResponse)
					toast[responses.every(isSuccessResponse) ? 'success' : 'error'](
						`${intl.get('success_deleted')} ${successResponses?.length ?? 0} of ${
							responses?.length ?? 0
						} pricing campaigns`,
						{
							id: 'delete_action',
						}
					)
				},
				onError: () => {
					toast.error(intl.get('error.unrecoverable.title'), {
						id: 'delete_action',
					})
				},
				onSettled: () => {
					setSelected([])
				},
			})
		}
	}, [selected, setSelected])

	const handleDeletePricingCampaign = useCallback(async (id: string) => {
		const answer = await dialog.confirmDelete({
			title: intl.get('pc_detete_confirm_title'),
			text: intl.get('pc_detete_confirm_subtitle'),
			okText: intl.get('general_delete'),
		})
		if (answer) {
			toast.loading(processingMessage(), {
				id: 'delete_action',
			})
			analytic.logEvent(`${analyticsPrefix} delete PC`, { page: 'PC list' })
			deletePricingCampaign.mutate(id, {
				onSuccess: () =>
					toast.success(intl.get('success_deleted'), {
						id: 'delete_action',
					}),
			})
		}
	}, [])

	const getDataList = useCallback(
		(data?: PricingCampaignModel[]): PricingCampaignTableRow[] => {
			const countsDict = getCountsByPc(productsCountsByPc.data)
			const totalProductsCount = sum(values(countsDict))
			const groupsDict = getOgsByPc(productsCountsByPc.data)
			const extendWithCustomData = (
				campaign: PricingCampaignModel
			): PricingCampaignTableRow => ({
				order: campaign.order,
				campaign,
				alerts_count: getSumOfAlerts(alerts.data?.[campaign.id] || []),
				assortment_share: getAssortmentShare(
					totalProductsCount,
					countsDict?.[campaign.id] || 0
				),
				products_count: countsDict?.[campaign.id] || 0,
				optimization_groups: groupsDict?.[campaign.id] || [],
			})
			return pipe([filter(prop('id')), map(extendWithCustomData)])(data)
		},
		[alerts.data, productsCountsByPc.data]
	)
	const data: PricingCampaignTableRow[] = useMemo(() => {
		const list = getDataList(campaigns.data || [])

		if (!optimizationGroupId) {
			return sortByOrder(list)
		} else {
			const pcsRelatedToOg = list.filter(
				({ campaign }) =>
					!isGlobalPricingCampaign(campaign) ||
					pcIdsWithProducts.includes(campaign.id)
			)

			return sortByOrder([
				...getSortedGlobalPcs(pcsRelatedToOg).map((item, index) => ({
					...item,
					order: index + 1,
				})),
				...pcsRelatedToOg.filter(
					pipe([prop('campaign'), negate(isGlobalPricingCampaign)])
				),
			])
		}
	}, [campaigns.data, optimizationGroupId, alerts.data, pcIdsWithProducts])

	const value = useMemo(
		() => ({
			deletePricingCampaign: checkExport(handleDeletePricingCampaign),
			deleteSelectedPricingCampaigns: checkExport(
				handleDeleteSelectedPricingCampaigns
			),
			duplicatePricingCampaign: checkExport(handleDublicatePricingCampaign),
			isLoading,
			data,
			selected,
			setSelected,
			scope,
		}),
		[
			handleDeletePricingCampaign,
			handleDeleteSelectedPricingCampaigns,
			handleDublicatePricingCampaign,
			isLoading,
			data,
			selected,
			setSelected,
			scope,
		]
	)
	return (
		<PricingCampaignsContext.Provider value={value}>
			{children}
		</PricingCampaignsContext.Provider>
	)
}
/**
 * Helpers functions
 */
const getSumOfAlerts = pipe([map(prop('products')), sum])
const sortByOrder = orderBy<PricingCampaignTableRow>(['order'], ['asc'])
const getSortedGlobalPcs: (
	data: PricingCampaignTableRow[]
) => PricingCampaignTableRow[] = pipe([
	filter(pipe([prop('campaign'), isGlobalPricingCampaign])),
	sortByOrder,
])
