import { JSONSchemaType } from 'ajv'
import { pipe, sortBy } from 'lodash/fp'
import React, { ChangeEvent, MouseEvent } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import {
	Button,
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
	FormError,
	FormGroup,
	FormLabel,
	InlineMessage,
	Textfield,
} from '@cmpkit/base'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import HelpCircleIcon from '@cmpkit/icon/lib/glyph/help-circle'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import Select, { SingleValue } from '@cmpkit/select'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'
import ErrorBoundary from '@/components/ErrorBoundary'
import HookSchemaForm from '@/components/HookSchemaForm'
import intl from '@/locale'
import analytic from '@/services/analytics'
import { numericOnlyPositive } from '@/tools/constants'
import { ErrorSchema, translateSchemaKeys } from '@/tools/json-schema-utils'

import {
	RoundingRuleValueModel,
	RoundingStrategyDataModel,
} from '../../../modules/pricing-campaigns/types'

const update = (
	index: number,
	updater: (rule: Partial<RoundingRuleValueModel>) => RoundingRuleValueModel,
	source: Partial<RoundingRuleValueModel>[]
) => source.map((r, _index) => (_index === index ? updater(r) : r))

const updateRule =
	(data: RoundingRuleValueModel) =>
	(rule: Partial<RoundingRuleValueModel>): RoundingRuleValueModel => ({
		...rule,
		...data,
	})

const addRule = (rules: RoundingRuleValueModel[]) => {
	const data = [...rules]
	const prevStep = data[data.length - 1]
	return [
		...data,
		{
			min_price: +(prevStep.min_price || 0) + 500,
			strategy: 'ignore',
		},
	]
}
type RuleSettingsProps = {
	namePrefix: string
	strategies: RoundingStrategyDataModel[]
	rule: RoundingRuleValueModel
	activeIndex: number
	onChange(index: number, newData: Partial<RoundingRuleValueModel>): void
}
function RuleSettings({
	namePrefix,
	strategies,
	rule,
	activeIndex,
	onChange,
}: RuleSettingsProps) {
	const handleRoundingStrategyChange = (option: SingleValue<DataOption>) => {
		onChange(activeIndex, { strategy: option?.value, params: {} })
	}
	const activeStrategy = strategies.find(
		(item) => item.value === rule?.strategy
	)
	return (
		<div className='flex w-full flex-col p-5'>
			<FormGroup>
				<FormLabel>{intl.get('app.strategy').d('Strategy')}</FormLabel>
				<div className='flex items-center gap-2'>
					<Select
						menuPortalTarget={document.body}
						name='strategy'
						instanceId='strategy'
						className='w-72'
						value={activeStrategy}
						onChange={handleRoundingStrategyChange}
						options={strategies}
					/>
					<Tooltip content={intl.get('how_to_use_rounding_strategy_tooltip')}>
						<HelpCircleIcon className='text-muted' />
					</Tooltip>
				</div>
			</FormGroup>
			{activeStrategy?.schema?.description && (
				<InlineMessage className='mb-4' variant='default'>
					<div>
						<h4>{intl.get('strategy_description')}</h4>
						<p className='text-xs text-muted'>
							{intl
								.get(activeStrategy?.schema?.description)
								.d(activeStrategy?.schema?.description)}
						</p>
					</div>
				</InlineMessage>
			)}
			<div>
				{activeStrategy?.schema && (
					<HookSchemaForm
						key={`${activeIndex}_${activeStrategy?.value}_rounding_rule`}
						schema={translateSchemaKeys(activeStrategy?.schema)}
						name={`${namePrefix}.${activeIndex}.params`}
						uiSchema={{}}
					/>
				)}
			</div>
		</div>
	)
}
type RoundingRulesManagerProps = {
	namePrefix: string
	rules: RoundingRuleValueModel[]
	onChange(value: RoundingRuleValueModel[]): void
	errorSchema?: ErrorSchema
	schema: JSONSchemaType<RoundingRuleValueModel[]>
}
function RoundingRulesManager(props: RoundingRulesManagerProps) {
	const { rules, onChange, errorSchema, schema, namePrefix } = props

	const strategies: RoundingStrategyDataModel[] = (schema.items.oneOf || [])
		// eslint-disable-next-line
		.map((rule: JSONSchemaType<any>) => ({
			label: intl
				.get(rule.properties.strategy.title)
				.d(rule.properties.strategy.title),
			value: rule.properties.strategy.const,
			schema: rule.properties.params as JSONSchemaType<
				typeof rule.properties.params
			>,
		}))

	const handleAddRule = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		analytic.logEvent('settings: pricing campaigns: add rounding rule')
		onChange(addRule(rules))
	}
	const handleRuleDelete = (index: number) => {
		analytic.logEvent('settings: pricing campaigns: delete rounding rule')

		onChange(rules.filter((rule, i) => i !== index))
	}
	const onChangeRuleRange =
		(index: number) => (event: ChangeEvent<HTMLInputElement>) => {
			const maxValue = Number(
				event.target.value.replace(numericOnlyPositive, '')
			)
			const newData = { min_price: maxValue < 0 ? 1 : maxValue }
			onChange(update(index, updateRule(newData), rules))
		}
	const handleRuleChange = (
		index: number,
		newData: Partial<RoundingRuleValueModel>
	) => {
		onChange(update(index, updateRule(newData), rules))
	}
	const handleBlur = () => pipe([sortBy('min_price'), onChange])(rules)

	return (
		<>
			<div className='mb-2 flex w-full flex-col divide-y overflow-hidden border-y bg-accent-1'>
				{rules.map((rule, ruleIndex) => {
					const max = rules[ruleIndex + 1]
						? (rules[ruleIndex + 1].min_price || 0) - 0.01
						: 'max'
					const activeStrategy = strategies.find(
						(item) => item.value === rule?.strategy
					)
					const error = errorSchema?.[ruleIndex]?.__errors?.[0]
					return (
						<Collapsible
							className={
								'relative flex w-full flex-col justify-center px-3 py-2 data-[state=open]:bg-accent-1'
							}
						>
							<div className='flex w-full items-center gap-2'>
								<CollapsibleTrigger asChild>
									<Button
										variant='tertiary'
										size={'small'}
										className='ransition-all data-[state=open]:rotate-90'
										iconBefore={<CaretRightIcon />}
									/>
								</CollapsibleTrigger>
								<div className='w-full'>
									<div className='flex items-center gap-2'>
										<div className='flex shrink-0 items-center font-medium'>
											{intl.get('general_range')} {ruleIndex + 1}
										</div>
										<Textfield
											data-testid={'pc-rounding-rule-list-item-min'}
											value={rule.min_price}
											onBlur={handleBlur}
											disabled={ruleIndex === 0}
											invalid={!('' + rule.min_price).length || error}
											placeholder={intl.get('empty')}
											onChange={onChangeRuleRange(ruleIndex)}
										/>
										<span>-</span>
										<Textfield
											data-testid={'pc-rounding-rule-list-item-max'}
											value={max}
											disabled
											onChange={onChangeRuleRange(ruleIndex + 1)}
										/>
										<div className='flex w-full items-center justify-between gap-2 text-right text-xs text-muted'>
											{intl.get('strategy').d('Strategy')}:{' '}
											{activeStrategy?.label}
											{ruleIndex !== 0 ? (
												<Button
													variant='tertiary'
													onClick={() => handleRuleDelete(ruleIndex)}
													data-testid={'pc-rounding-rule-delete'}
													iconBefore={<Trash2Icon />}
												/>
											) : (
												<div className='size-8' />
											)}
										</div>
									</div>
									{errorSchema?.[ruleIndex] && (
										<FormError>
											{intl
												.get('rounding.invalid.params')
												.d('Invliad strategy parameters')}
										</FormError>
									)}
								</div>
							</div>
							<CollapsibleContent className='py-2'>
								<RuleSettings
									namePrefix={namePrefix}
									onChange={handleRuleChange}
									rule={rule}
									strategies={strategies}
									activeIndex={ruleIndex!}
								/>
								{errorSchema?.[ruleIndex] && (
									<InlineMessage
										variant='danger'
										className='ml-5 text-xs font-medium'
									>
										{intl
											.get('rounding.invalid.params.extended')
											.d(
												'Strategy parameters are invalid, please check them again and try to save'
											)}
									</InlineMessage>
								)}
							</CollapsibleContent>
						</Collapsible>
					)
				})}
			</div>
			<Button
				variant='tertiary'
				onClick={handleAddRule}
				iconBefore={<PlusIcon />}
			>
				{intl.get('rounding_add')}
			</Button>
		</>
	)
}
export function RoundingRulesFiledController({
	name,
	shouldUnregister,
	defaultValue,
	...props
}: {
	name: string
	shouldUnregister?: boolean
	defaultValue?: RoundingRuleValueModel
	errorSchema?: ErrorSchema
	schema: JSONSchemaType<RoundingRuleValueModel[]>
}) {
	const rules = useWatch({ name })
	const { control } = useFormContext()
	return (
		<Controller
			name={name}
			shouldUnregister={shouldUnregister}
			defaultValue={defaultValue}
			control={control}
			render={({ field, fieldState: { error } }) => {
				return (
					<ErrorBoundary>
						<RoundingRulesManager
							namePrefix={name}
							rules={rules || []}
							onChange={field.onChange}
							errorSchema={error}
							{...props}
						/>
					</ErrorBoundary>
				)
			}}
		/>
	)
}
