import clsx from 'clsx'
import React from 'react'

import Tooltip from '@cmpkit/tooltip'

export default function WidgetHeader({
	title,
	tooltip,
	titleAs: TitleComp = 'h5',
	subtitle,
	className,
	children,
	...props
}: {
	children?: React.ReactNode
	tooltip?: string
	title?: string
	titleAs?: React.ElementType
	subtitle?: string
	className?: string
} & React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div className={clsx('widget__header', className)} {...props}>
			<div>
				{title && (
					<Tooltip
						content={tooltip}
						boundary={
							document.getElementById('dashboard-container') || undefined
						}
					>
						<TitleComp className='font-bold'>{title}</TitleComp>
					</Tooltip>
				)}
				{subtitle && (
					<p className='mt-1 text-xs leading-3 text-muted'>{subtitle}</p>
				)}
			</div>
			{children}
		</div>
	)
}
