import {
	filter,
	map,
	pipe,
	prop,
	propEq,
	propOr,
	reduce,
	sum,
	values,
} from 'lodash/fp'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import { InlineMessage } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import { Operators } from '@cmpkit/query-builder'

import { rulesToQueryObject } from '@/components/filter/utils'
import { FilterRuleModel } from '@/generated'
import { useMainSourceProperty } from '@/hooks/data'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'
import { toQueryString } from '@/tools/location'
import ProgressRow from '@/components/Progress/ProgressRow'

import { useOptimizationQuery, useRepricingStatisticQuery } from '../../queries'
import {
	GROUP_VALUE_COLORS,
	MetricGroup,
	MetricGroupValue,
} from '../../constants'

export default function ItemsChangedWidget({
	filters,
}: {
	filters: FilterRuleModel[]
}) {
	const navigate = useNavigate()
	const optimizationGroupId = useOptimizationGroupId()!
	const sourceProperty = useMainSourceProperty()
	const optimizationQuery = useOptimizationQuery(optimizationGroupId)
	const repricingStatisticQuery = useRepricingStatisticQuery(
		{
			optimizations: [
				{
					optimization_id: optimizationQuery.data!.id,
					optimization_group_id: optimizationGroupId,
				},
			],
			filters,
		},

		{
			enabled: !!optimizationGroupId && !!optimizationQuery.data?.id,
			select: pipe(
				prop('metrics'),
				filter(propEq('group_type', MetricGroup.PriceChangeStatus)),
				/* eslint-disable @typescript-eslint/no-explicit-any */
				reduce((acc: any, item) => {
					const groupValue = item.group_value as MetricGroupValue
					if (!acc[groupValue]) {
						acc[groupValue] = {
							color: GROUP_VALUE_COLORS[groupValue] || '',
							groupValue,
						}
					}
					acc[groupValue][item.name] = item.value
					return acc
				}, {} as any)
			),
		}
	)
	const total = sum(
		map(propOr(0, 'product_count'), values(repricingStatisticQuery.data))
	)
	const rows = useMemo(() => {
		const data = repricingStatisticQuery.data
		const { Locked, Increased, Decreased, NotChanged } = MetricGroupValue

		return [
			{
				name: intl.get('status_panel_decreased'),
				products: data?.[Decreased].product_count,
				lines: data?.[Decreased].pricing_line_count,
				color: data?.[Decreased].color,
				filters: [
					{
						name: `diff_${sourceProperty}_pp`,
						operation: Operators.LT,
						value: 0,
					},
				],
			},
			{
				name: intl.get('status_panel_increased'),
				products: data?.[Increased].product_count,
				lines: data?.[Increased].pricing_line_count,
				color: data?.[Increased].color,
				filters: [
					{
						name: `diff_${sourceProperty}_pp`,
						operation: Operators.GT,
						value: 0,
					},
				],
			},
			{
				name: intl.get('status_panel_price_locked'),
				products: data?.[Locked].product_count,
				lines: data?.[Locked].pricing_line_count,
				color: data?.[Locked].color,
				filters: [
					{
						name: 'is_locked',
						operation: Operators.BL,
						value: 1,
					},
				],
			},
			{
				name: intl.get('status_panel_price_not_changed'),
				products: data?.[NotChanged].product_count,
				lines: data?.[NotChanged].pricing_line_count,
				color: data?.[NotChanged].color,
				filters: [
					{
						name: `diff_${sourceProperty}_pp`,
						operation: Operators.EQ,
						value: 0,
					},
					{
						name: 'is_locked',
						operation: Operators.BL,
						value: 0,
					},
				],
			},
		]
	}, [repricingStatisticQuery.data])

	if (repricingStatisticQuery.isLoading) {
		return <LoadingPlaceholder />
	}
	if (repricingStatisticQuery.isError) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger' className='w-full'>
				{repricingStatisticQuery.error.message || intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}
	return (
		<div className='flex w-full flex-col space-y-2'>
			{rows.map((row) => (
				<ProgressRow
					onClick={() => {
						analytic.logEvent('opt summary: items changed widget: click row', {
							filters: row.filters,
						})
						navigate(
							toQueryString(rulesToQueryObject([...filters, ...row.filters]))
						)
					}}
					barClassName={row.color}
					label={row.name}
					relativeValue={(row.products / total) * 100 || 0}
					absoluteValue={formatNumber(row.products) || 0}
					extraValue={formatNumber(row.lines)}
				/>
			))}
		</div>
	)
}
function LoadingPlaceholder() {
	return (
		<div className='flex flex-col items-center space-y-2'>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-20 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-10 animate-pulse rounded-lg bg-accent-4' />
			</div>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-24 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-12 animate-pulse rounded-lg bg-accent-4' />
			</div>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-28 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-12 animate-pulse rounded-lg bg-accent-4' />
			</div>
		</div>
	)
}
