/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AccessTokenModel */
export interface AccessTokenModel {
	/** Access Token */
	access_token: string
}

/** AddDepartmentUsersModel */
export interface AddDepartmentUsersModel {
	/** User Ids */
	user_ids: string[]
}

/** AdminRoleModel */
export interface AdminRoleModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
	/** App Name */
	app_name: string
	/** Admin */
	admin: boolean
	/** Inner */
	inner: boolean
	/** Permissions */
	permissions: string[]
}

/** AppModel */
export interface AppModel {
	/** Name */
	name: string
	/** Is Active */
	is_active: boolean
	/** Roles */
	roles: string[]
	/** Entities */
	entities: Record<string, EntityModel[]>
}

/** ClientSecretDataModel */
export interface ClientSecretDataModel {
	/** Grant Type */
	grant_type: 'client_credentials'
	/** Client Id */
	client_id: string
	/** Client Secret */
	client_secret: string
}

/** DepartmentDataModel */
export interface DepartmentDataModel {
	/** Name */
	name: string
	/** Apps */
	apps: UserAppDataModel[]
}

/** DepartmentModel */
export interface DepartmentModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
	/** Apps */
	apps: UserAppModel[]
}

/** DepartmentShortModel */
export interface DepartmentShortModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
	/** Users Count */
	users_count: number
	/** Enabled Apps */
	enabled_apps: string[]
	/** Role Names */
	role_names: string[]
}

/** EntityModel */
export interface EntityModel {
	/** Id */
	id: string
	/** Name */
	name?: string
}

/** HTTPValidationError */
export interface HTTPValidationError {
	/** Detail */
	detail?: ValidationError[]
}

/** PermissionModel */
export interface PermissionModel {
	/** App Name */
	app_name: string
	/** Name */
	name: string
	/** Inner */
	inner: boolean
}

/** RoleDataModel */
export interface RoleDataModel {
	/** App */
	app: string
	/** Name */
	name: string
	/** Permissions */
	permissions: string[]
}

/** RoleModel */
export interface RoleModel {
	/** App */
	app: string
	/** Name */
	name: string
	/** Permissions */
	permissions: string[]
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Read Only */
	read_only: boolean
	/** Users Count */
	users_count: number
}

/** RoleUpdateDataModel */
export interface RoleUpdateDataModel {
	/** Name */
	name?: string
	/** Permissions */
	permissions: string[]
}

/** ServiceAccountDataModel */
export interface ServiceAccountDataModel {
	/**
	 * Client Id
	 * @pattern ^[a-z]+(-[a-z]+)*$
	 */
	client_id: string
	/** Description */
	description?: string
	/** Apps */
	apps: UserAppDataModel[]
}

/** ServiceAccountModel */
export interface ServiceAccountModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Client Id */
	client_id: string
	/** Description */
	description?: string
	user: UserModel
}

/** ServiceAccountSecretModel */
export interface ServiceAccountSecretModel {
	/** Token Endpoint */
	token_endpoint: string
	data: ClientSecretDataModel
}

/** UserAppDataModel */
export interface UserAppDataModel {
	/** Name */
	name: string
	/** Enabled */
	enabled: boolean
	/**
	 * Direct Role Names
	 * @default []
	 */
	direct_role_names?: string[]
	/**
	 * Entities
	 * @default {}
	 */
	entities?: Record<string, string[]>
}

/** UserAppModel */
export interface UserAppModel {
	/** Name */
	name: string
	/** Enabled */
	enabled: boolean
	/**
	 * Direct Role Names
	 * @default []
	 */
	direct_role_names?: string[]
	/**
	 * Entities
	 * @default {}
	 */
	entities?: Record<string, string[]>
	/**
	 * Role Names
	 * @default []
	 */
	role_names?: string[]
	/**
	 * Permissions
	 * @default []
	 */
	permissions?: string[]
	/**
	 * Unavailable Permissions
	 * @default []
	 */
	unavailable_permissions?: string[]
}

/** UserDataModel */
export interface UserDataModel {
	/**
	 * Email
	 * @format email
	 */
	email: string
	/** First Name */
	first_name?: string
	/** Last Name */
	last_name?: string
	/** Apps */
	apps: UserAppDataModel[]
}

/** UserDepartmentModel */
export interface UserDepartmentModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
}

/** UserEntityModel */
export interface UserEntityModel {
	/**
	 * User Id
	 * @format uuid
	 */
	user_id: string
	/** App Name */
	app_name: string
	/** Entity Type */
	entity_type: string
	/** Entity Id */
	entity_id: string
}

/** UserModel */
export interface UserModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/**
	 * Email
	 * @format email
	 */
	email: string
	/** First Name */
	first_name?: string
	/** Last Name */
	last_name?: string
	/** Departments */
	departments: UserDepartmentModel[]
	/** Apps */
	apps: UserAppModel[]
}

/** UserProfileDataModel */
export interface UserProfileDataModel {
	/** First Name */
	first_name?: string
	/** Last Name */
	last_name?: string
}

/** UsersModel */
export interface UsersModel {
	/** Users */
	users: UserModel[]
	/** Domains */
	domains: string[]
}

/** ValidationError */
export interface ValidationError {
	/** Location */
	loc: (string | number)[]
	/** Message */
	msg: string
	/** Error Type */
	type: string
}

export interface ImportResultResponseData {
	rows_inserted?: number
	rows_updated?: number
}

export interface ImportResultResponseError {
	errors?: {
		anchors?: Record<string, Record<string, string>>
	}
}

export interface AnchoringProductModel {
	sku?: string
	title?: string
	name?: string
	line_id?: string
	sales_entity_id?: string
	cost?: number
	last_price?: number
	alerts?: string[]
	flat_anchor_sku?: string
	flat_anchor_add?: number
	flat_anchor_mul?: number
	flat_anchor_count?: number
	size_anchor_sku?: string
	size_anchor_add?: number
	size_anchor_mul?: number
	size_anchor_count?: number
	product_set_anchor_sku?: string
	product_set_anchor_add?: number
	product_set_anchor_mul?: number
	product_set_anchor_count?: number
	ladder_anchor_sku?: string
	ladder_anchor_add?: number
	ladder_anchor_mul?: number
	ladder_anchor_count?: number
	bundle_anchor_sku?: string
	bundle_anchor_add?: number
	bundle_anchor_mul?: number
	bundle_anchor_count?: number
	source_family_id?: string
	/** @format date-time */
	updated_at?: string
	/** @format uuid */
	updated_by?: string
}

export interface AnchoringProductsResponseData {
	total_count?: number
	data?: AnchoringProductModel[]
}

export interface AnchorsUpdateResultResponseData {
	rows_inserted?: number
	rows_updated?: number
}

export interface AnchorsDeleteResultResponseData {
	rows_deleted?: number
}

export interface AnchoringProductsRequestBody {
	format?: 'csv' | 'json' | 'xlsx'
	limit?: number
	search?: string
	offset?: number
	filters?: FilterRuleModel[]
	order_by?: {
		name?: string
		direction?: 'asc' | 'desc'
	}[]
}

export interface AnchoringAlertModel {
	/** Alert unique ID */
	id: string
	level: AlertLevel
	anchors: number
}

/** ExportColumnModel */
export interface ExportColumnModel {
	/** Name */
	name: string | null
	/** Header */
	header: string
	/** Static Value */
	static_value: string | null
}

/** ExportCreateModel */
export interface ExportCreateModel {
	/** Name */
	name: string
	type: ExportType
	/** Filters */
	filters: FilterModel[]
	group_by: ExportGroupBy | null
	/** Columns */
	columns: ExportColumnModel[] | null
}

/** ExportGroupBy */
export enum ExportGroupBy {
	Product = 'product',
	PricingLine = 'pricing_line',
}

/** ExportModel */
export interface ExportModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
	type: ExportType
	status: ExportStatus
	/** Created By */
	created_by: string
	/**
	 * Created At
	 * @format date-time
	 */
	created_at: string
	/** Finished At */
	finished_at: string | null
	/** Row Number */
	row_number: number | null
	/** File Size */
	file_size: number | null
}

/** ExportStatus */
export enum ExportStatus {
	Scheduled = 'scheduled',
	Started = 'started',
	Finished = 'finished',
	Failed = 'failed',
}

/** ExportTemplateModel */
export interface ExportTemplateModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
	/** Columns */
	columns: ExportColumnModel[]
}

/** ExportType */
export enum ExportType {
	Csv = 'csv',
	Xls = 'xls',
	Api = 'api',
	Bigquery = 'bigquery',
}

/** FilterModel */
export interface FilterModel {
	/** Name */
	name: string
	/** Operation */
	operation: string
	/** Value */
	value: any
}

/** ChatMessageContextItem */
export interface ChatMessageContextItem {
	/** Optimization Group Id */
	optimization_group_id: string | null
	/** Page */
	page: string
}

/** ChatMessageReactionRequestModel */
export interface ChatMessageReactionRequestModel {
	/** Id */
	id: string
	/** Reaction */
	reaction: string
}

/** ChatMessageRequestModel */
export interface ChatMessageRequestModel {
	/** Text */
	text: string
	/** Session Id */
	session_id: string
	context: ChatMessageContextItem
}

/** ChatMessageResponseModel */
export interface ChatMessageResponseModel {
	/** Id */
	id: string
	/** Text */
	text: string
}

/** ChatSettingsResponseModel */
export interface ChatSettingsResponseModel {
	/** Is Enabled */
	is_enabled: boolean
	/** Welcome Text */
	welcome_text: string
	/** Suggestions */
	suggestions: string[]
}

/** ScenarioRunCreateModel */
export interface ScenarioRunCreateModel {
	/** Name */
	name: string
	/**
	 * Scenario Id
	 * @format uuid
	 */
	scenario_id: string
	/** Settings */
	settings: object
}

/** ScenarioRunModel */
export interface ScenarioRunModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
	/**
	 * Scenario Id
	 * @format uuid
	 */
	scenario_id: string
	/**
	 * Scenario Run Set Id
	 * @format uuid
	 */
	scenario_run_set_id: string
	/** Settings */
	settings: object
	/**
	 * Optimization Id
	 * @format uuid
	 */
	optimization_id: string
}

/** ScenarioRunRequestModel */
export interface ScenarioRunRequestModel {
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/** Scenario Runs */
	scenario_runs: ScenarioRunCreateModel[]
}

/** ScenarioRunRestartModel */
export interface ScenarioRunRestartModel {
	/** Id */
	id: string | null
	/** Name */
	name: string
	/**
	 * Scenario Id
	 * @format uuid
	 */
	scenario_id: string
	/** Settings */
	settings: object
}

/** ScenarioRunRestartRequestModel */
export interface ScenarioRunRestartRequestModel {
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/** Scenario Runs */
	scenario_runs: ScenarioRunRestartModel[]
}

/** ScenarioRunSetModel */
export interface ScenarioRunSetModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/**
	 * Created At
	 * @format date-time
	 */
	created_at: string
	/**
	 * Created By
	 * @format uuid
	 */
	created_by: string
	/** Scenario Runs */
	scenario_runs: ScenarioRunModel[]
}

/** FilterRule */
export interface FilterRuleModel {
	/** Name */
	name: string
	/** Operation */
	operation: string
	/** Value */
	value?: string | string[] | number | number[] | null
}

/** OrderByRule */
export interface OrderByRule {
	/** Name */
	name: string
	/** Direction */
	direction: 'asc' | 'desc'
}

/** PricingCampaignCopySettingsRequestModel */
export interface PricingCampaignCopySettingsRequestModel {
	/** Dst Pricing Campaign Ids */
	dst_pricing_campaign_ids: string[]
	/** Pricing Campaign Settings */
	pricing_campaign_settings: object
}

/** PricingCampaignCopySettingsResponseModel */
export interface PricingCampaignCopySettingsResponseModel {
	/**
	 * Pricing Campaign Id
	 * @format uuid
	 */
	pricing_campaign_id: string
	/** Success */
	success: boolean
	/** Message */
	message: string
}

/** PricingCampaignCreateDataModel */
export interface PricingCampaignCreateDataModel {
	/** Id */
	id?: string | null
	/** Name */
	name: string
	/** Optimization Group Id */
	optimization_group_id?: string | null
	/** Scenario Id */
	scenario_id?: string | null
	/** Settings */
	settings: object
	/** Start Date */
	start_date?: string | null
	/** End Date */
	end_date?: string | null
}

/** PricingCampaignDuplicateRequest */
export interface PricingCampaignDuplicateRequest {
	/** Src Pricing Campaign Ids */
	src_pricing_campaign_ids: string[]
	/** Dst Optimization Group Ids */
	dst_optimization_group_ids: string[]
}

/** PricingCampaignDuplicateScenariosRequest */
export interface PricingCampaignDuplicateScenariosRequest {
	/** Src Pricing Campaign Ids */
	src_pricing_campaign_ids: string[]
	/** Dst Scenario Ids */
	dst_scenario_ids: string[]
}

/** PricingCampaignModel */
export interface PricingCampaignModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
	/** Optimization Group Id */
	optimization_group_id?: string | null
	/** Scenario Id */
	scenario_id?: string | null
	/** Order */
	order: number
	settings: PricingCampaignSettingsModel
	/** Start Date */
	start_date?: string | null
	/** End Date */
	end_date?: string | null
	/** Updated At */
	updated_at?: string | null
	/** Updated By */
	updated_by?: string | null
}

/** PricingCampaignOrderModel */
export interface PricingCampaignOrderModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Order */
	order: number
}

/** PricingCampaignProductsFilters */
export interface PricingCampaignProductsFilters {
	/** Filters */
	filters: FilterRuleModel[]
}

/** PricingCampaignReassignRequest */
export interface PricingCampaignReassignRequest {
	/** Src Pricing Campaign Ids */
	src_pricing_campaign_ids: string[]
	/**
	 * Dst Optimization Group Id
	 * @format uuid
	 */
	dst_optimization_group_id: string
}

/** PricingCampaignReorderRequestModel */
export interface PricingCampaignReorderRequestModel {
	/** Pricing Campaigns */
	pricing_campaigns: PricingCampaignOrderModel[]
}

/** PricingCampaignUpdateDataModel */
export interface PricingCampaignUpdateDataModel {
	/** Name */
	name?: string | null
	/** Settings */
	settings?: object | null
	/** Start Date */
	start_date?: string | null
	/** End Date */
	end_date?: string | null
}

/** ScenarioCreateDataModel */
export interface ScenarioCreateDataModel {
	/** Id */
	id?: string | null
	/** Name */
	name: string
	/** Description */
	description?: string | null
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/** Settings */
	settings?: object | null
}

/** ScenarioMainCopySettings */
export interface ScenarioMainCopySettings {
	/** Dst Optimization Group Ids */
	dst_optimization_group_ids: string[]
	/** Copy Business Settings */
	copy_business_settings: boolean
	/** Copy Strategy Settings */
	copy_strategy_settings: boolean
	/** Copy Stop List Settings */
	copy_stop_list_settings: boolean
}

/** ScenarioModel */
export interface ScenarioModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
	/** Description */
	description: string
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/** Is Main */
	is_main: boolean
	settings: ScenarioSettingsModel
	/** Updated At */
	updated_at?: string | null
	/** Updated By */
	updated_by?: string | null
}

/** ScenarioSetMainDataModel */
export interface ScenarioSetMainDataModel {
	settings?: ScenarioSetMainSettingsDataModel | null
}

/** ScenarioSetMainSettingsDataModel */
export interface ScenarioSetMainSettingsDataModel {
	/** Target */
	target: string
	/** Protect */
	protect: string
}

/** ScenarioUpdateDataModel */
export interface ScenarioUpdateDataModel {
	/** Name */
	name?: string | null
	/** Description */
	description?: string | null
	/** Settings */
	settings?: object | null
}

/** SettingsTemplateApplyRequestModel */
export interface SettingsTemplateApplyRequestModel {
	/** Ids */
	ids: string[]
}

/** SettingsTemplateCreateModel */
export interface SettingsTemplateCreateModel {
	/** Id */
	id?: string | null
	/** Name */
	name: string
	/** Description */
	description?: string | null
	template_type: SettingsTemplateType
	/** Body */
	body: object
}

/** SettingsTemplateModel */
export interface SettingsTemplateModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
	/** Description */
	description?: string | null
	template_type: SettingsTemplateType
	/** Body */
	body: object
	/**
	 * Created At
	 * @format date-time
	 */
	created_at: string
	/** Created By */
	created_by?: string | null
	/** Updated At */
	updated_at?: string | null
	/** Updated By */
	updated_by?: string | null
	/** is_favorite */
	is_favorite: boolean
}

/** SettingsTemplateQueryModel */
export interface SettingsTemplateQueryModel {
	/** Filters */
	filters?: FilterRuleModel[]
	/** Order By */
	order_by?: OrderByRule[]
	/** Limit */
	limit: number
	/** Offset */
	offset: number
}

/** SettingsTemplateQueryResponseModel */
export interface SettingsTemplateQueryResponseModel {
	/** Total */
	total: number
	/** Data */
	data: SettingsTemplateModel[]
}

/** SettingsTemplateType */
export enum SettingsTemplateType {
	Scenario = 'scenario',
	ScenarioStrategy = 'scenario_strategy',
	ScenarioStopList = 'scenario_stop_list',
	ScenarioBusinessConstraints = 'scenario_business_constraints',
	PricingCampaign = 'pricing_campaign',
	PricingCampaignTacticsRb = 'pricing_campaign_tactics_rb',
	PricingCampaignTacticsMl = 'pricing_campaign_tactics_ml',
	PricingCampaignTacticsMd = 'pricing_campaign_tactics_md',
	PricingCampaignLimits = 'pricing_campaign_limits',
	PricingCampaignMinimalSteps = 'pricing_campaign_minimal_steps',
	PricingCampaignRounding = 'pricing_campaign_rounding',
	PricingCampaignAnchoring = 'pricing_campaign_anchoring',
	PricingCampaignProductAssignment = 'pricing_campaign_product_assignment',
}

/** SettingsTemplateUpdateModel */
export interface SettingsTemplateUpdateModel {
	/** is_favorite */
	is_favorite?: boolean
	/** Name */
	name?: string | null
	/** Description */
	description?: string | null
	/** Body */
	body?: object | null
}

/** CreateReview */
export interface CreateReviewModel {
	/** Notify Author */
	notify_author: boolean
	/** Reviewers */
	reviewers: ReviewerItemModel[]
}

/** ListReviewResponse */
export interface ListReviewResponse {
	/** Objects */
	objects: ReviewModel[]
}

/** Review */
export interface ReviewModel {
	/**
	 * Iteration Id
	 * @format uuid
	 */
	iteration_id: string
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/**
	 * Author Id
	 * @format uuid
	 */
	author_id: string
	/** Notify Author */
	notify_author: boolean
	/** Reviewers */
	reviewers: ReviewerItemModel[]
	/**
	 * Created Dt
	 * @format date-time
	 */
	created_dt: string
	/**
	 * Updated Dt
	 * @format date-time
	 */
	updated_dt?: string
}

/** ReviewerItem */
export interface ReviewerItemModel {
	/** Id */
	id: string
	/**
	 * Is Approved
	 * @default false
	 */
	is_approved?: boolean
	/**
	 * Approved Dt
	 * @format date-time
	 */
	approved_dt?: string
}

/** UpdateReview */
export interface UpdateReviewModel {
	/**
	 * Notify Author
	 * @default false
	 */
	notify_author?: boolean
	/**
	 * Reviewers
	 * @default []
	 */
	reviewers?: ReviewerItemModel[]
}

/** ScheduleCreate */
export interface CreateScheduleModel {
	/**
	 * Id
	 * @format uuid
	 */
	id?: string
	/** Name */
	name: string
	/**
	 * Start Date
	 * @format date
	 */
	start_date: string
	/**
	 * Repeat
	 * @default false
	 */
	repeat?: boolean
	/** Repeat Days */
	repeat_days?: number[]
	/** Repeat Week Interval */
	repeat_week_interval?: number
	/** Optimization Groups */
	optimization_groups: string[]
	/** Notify Users */
	notify_users?: string[]
}

/** ScheduleResponse */
export interface ScheduleModel {
	/**
	 * Id
	 * @format uuid
	 */
	id: string
	/** Name */
	name: string
	/**
	 * Start Date
	 * @format date
	 */
	start_date: string
	/**
	 * Repeat
	 * @default false
	 */
	repeat?: boolean
	/** Repeat Days */
	repeat_days?: number[]
	/** Repeat Week Interval */
	repeat_week_interval?: number
	/** Optimization Groups */
	optimization_groups: string[]
	/** Notify Users */
	notify_users?: string[]
	/**
	 * Nearest Date
	 * @format date
	 */
	nearest_date: string
}

/** ScheduleUpdate */
export interface UpdateScheduleModel {
	/**
	 * Id
	 * @format uuid
	 */
	id?: string
	/** Name */
	name?: string
	/**
	 * Start Date
	 * @format date
	 */
	start_date?: string
	/** Repeat */
	repeat?: boolean
	/** Repeat Days */
	repeat_days?: number[]
	/** Repeat Week Interval */
	repeat_week_interval?: number
	/** Optimization Groups */
	optimization_groups?: string[]
	/** Notify Users */
	notify_users?: string[]
}

export interface MatchedProductsResponseModel {
	segments: {
		/** @format uuid */
		id: string
		count: number
	}[]
	pricing_campaigns: {
		/** @format uuid */
		id: string
		count: number
	}[]
}

export interface ProductsCountByEngineModel {
	pricing_campaign_engine?: string
	product_count?: number
	revenue?: number
}

export interface ProductCountByEngineRequestModel {
	filters?: FilterRuleModel[]
}

export interface MatchedProductModel {
	/** @format uuid */
	pricing_campaign_id?: string
	rules?: FilterRuleModel[]
	order?: number
	is_active?: boolean
	/** @format uuid */
	id?: string
}

export enum PricingEngineType {
	MD = 'MD',
	ML = 'ML',
	RB = 'RB',
}

export interface MatchedProductsAssignmentModel {
	id: string
	filters: FilterRuleModel[]
}

export interface MatchedProductsRequestModel {
	id: string
	order: number
	assignments: MatchedProductsAssignmentModel[]
}

export interface PreprocessingModel {
	id?: string
	/**
	 * @format date
	 * @example "2020-01-01"
	 */
	date?: string
	modes?: string[]
	status?: 'finished' | 'started' | 'failed'
}

/** Body_upload_promo_campaign_products_api_v1_promo_campaigns__promo_campaign_id__products__post */
export interface BodyUploadPromoCampaignProductsApiV1PromoCampaignsPromoCampaignIdProductsPost {
	/**
	 * File
	 * @format binary
	 */
	file: File
}

/** ErrorModel */
export interface ErrorModel {
	/** Detail */
	detail: string
}

/** FileRowErrorModel */
export interface FileRowErrorModel {
	/** Sku */
	sku: string | null
	/** Reason */
	reason: string
}

/** PromoCampaignCreateModel */
export interface PromoCampaignCreateModel {
	/** Name */
	name: string
	/** Description */
	description: string
	/**
	 * Date Start
	 * @format date
	 */
	date_start: string
	/**
	 * Date End
	 * @format date
	 */
	date_end: string
	/** Pos Ids */
	pos_ids: string[]
}

/** PromoCampaignModel */
export interface PromoCampaignModel {
	/** Id */
	id: string
	/** Name */
	name: string
	/** Description */
	description: string
	/**
	 * Date Start
	 * @format date
	 */
	date_start: string
	/**
	 * Date End
	 * @format date
	 */
	date_end: string
	/**
	 * User Created
	 * @format uuid
	 */
	user_created: string
	/** User Modified */
	user_modified: string | null
	source: PromoCampaignSource
	/** Is Active */
	is_active: boolean
	/**
	 * Created On
	 * @format date-time
	 */
	created_on: string
	/** Modified On */
	modified_on: string | null
	/** Pos Ids */
	pos_ids: string[]
}

/** PromoCampaignSource */
export enum PromoCampaignSource {
	Manual = 'manual',
	Integration = 'integration',
}

/** PromoCampaignUpdateModel */
export interface PromoCampaignUpdateModel {
	/** Name */
	name: string
	/** Description */
	description: string
	/**
	 * Date Start
	 * @format date
	 */
	date_start: string
	/**
	 * Date End
	 * @format date
	 */
	date_end: string
	/** Pos Ids */
	pos_ids: string[]
	/** Is Active */
	is_active: boolean
}

/** PromoProductFileErrorModel */
export interface PromoProductFileErrorModel {
	/** Error */
	error?: string | null
	/**
	 * Multiple Discounts Errors
	 * @default []
	 */
	multiple_discounts_errors?: FileRowErrorModel[]
	/**
	 * Duplicated Rows Errors
	 * @default []
	 */
	duplicated_rows_errors?: FileRowErrorModel[]
	/**
	 * Validation Errors
	 * @default []
	 */
	validation_errors?: FileRowErrorModel[]
}

/** PromoProductModel */
export interface PromoProductModel {
	/** Sku */
	sku: string
	/** Title */
	title: string
	/** Discount Price */
	discount_price?: number | null
	/** Discount Percent */
	discount_percent?: number | null
	/** Discount Sum */
	discount_sum?: number | null
	/** Predicted Growth Rate */
	predicted_growth_rate?: number | null
}

/** UploadPromoProductsErrorModel */
export interface UploadPromoProductsErrorModel {
	detail: PromoProductFileErrorModel
}

export interface CompetitiveDataPointModel {
	competitor_name: string
	availability: string
	url: string
	promo_type_id?: string
	price: number
	observed_on: string
}

export interface PriceHistoryDataPointModel {
	competitor_name?: string
	line_id: string
	sku: string
	optimization_group_id: string
	price: number
	observed_on: string
}

export interface CompetitiveDataResponseModel {
	next_page?: string
	objects?: {
		competitor_name?: string
		objects?: CompetitiveDataPointModel[]
	}[]
}

export interface PriceHistoryResponseModel {
	next_page?: string
	objects?: {
		competitive_data?: {
			competitor_name?: string
			objects?: PriceHistoryDataPointModel[]
		}[]
		prices?: PriceHistoryDataPointModel[]
	}
}

export interface CompetitorModel {
	name?: string
	competitor_id?: string
	source_id?: string
}

export interface CompetitorsResponseModel {
	next_page?: string
	objects?: CompetitorModel[]
}

/** AlertGroupBy */
export enum AlertGroupBy {
	OptimizationGroupId = 'optimization_group_id',
	PricingCampaignId = 'pricing_campaign_id',
}

/** AlertInfoModel */
export interface AlertInfoModel {
	/** Products */
	products: number
	/** Pricing Lines */
	pricing_lines: number
	/** Est Revenue */
	est_revenue: number
	/** Est Revenue Share */
	est_revenue_share: number
	/**
	 * Optimization Group Id
	 * Only present if group_by=optimization_group_id
	 */
	optimization_group_id?: string | null
	/**
	 * Pricing Campaign Id
	 * Only present if group_by=pricing_campaign_id
	 */
	pricing_campaign_id?: string | null
	/**
	 * Id
	 * Only present in by_id collection
	 */
	id?: string | null
	/** Level */
	level: string
}

/** AlertInfoRequestModel */
export interface AlertInfoRequestModel {
	/** Optimization Group Id */
	optimization_group_ids?: string[]
	group_by?: AlertGroupBy | null
	/**
	 * Total By Group
	 * @default false
	 */
	total_by_group?: boolean
	/**
	 * Filters
	 * @default []
	 */
	filters?: object[]
}

/** AlertsInfoResponseModel */
export interface AlertsInfoResponseModel {
	/** By Id */
	by_id: AlertInfoModel[]
	/** By Level */
	by_level: AlertInfoModel[]
}

/** BIAIRequest */
export interface BIAIRequest {
	/**
	 * Start Date
	 * @format date
	 */
	start_date: string
	/**
	 * End Date
	 * @format date
	 */
	end_date: string
	/** Metrics */
	metrics: string[]
	/**
	 * Filters
	 * @default []
	 */
	filters?: object[]
	/** Date Aggregation */
	date_aggregation:
		| 'date_key'
		| 'week_key'
		| 'month_key'
		| 'quarter_key'
		| 'year_key'
		| null
	/**
	 * Dimensions
	 * @default []
	 */
	dimensions?: string[]
	/**
	 * Skip Dates
	 * @default false
	 */
	skip_dates?: boolean
	/**
	 * Label
	 * @default "unknown"
	 */
	label?: string
}

/** BIAIResponse */
export interface BIAIResponse {
	/**
	 * Start Date
	 * @format date
	 */
	start_date: string
	/**
	 * End Date
	 * @format date
	 */
	end_date: string
	/** Filters */
	filters: object[]
	/** Meta */
	meta: string[]
	/** Data */
	data: any[][]
}

/** BIRequest */
export interface BIRequest {
	/**
	 * Start Date
	 * @format date
	 */
	start_date: string
	/**
	 * End Date
	 * @format date
	 */
	end_date: string
	/** Metrics */
	metrics: string[]
	/**
	 * Filters
	 * @default []
	 */
	filters?: object[]
	/**
	 * Product Filters
	 * @default []
	 */
	product_filters?: object[]
	/** Date Aggregation */
	date_aggregation:
		| 'date_key'
		| 'week_key'
		| 'month_key'
		| 'quarter_key'
		| 'year_key'
		| null
	/**
	 * Dimensions
	 * @default []
	 */
	dimensions?: string[]
	/**
	 * Skip Dates
	 * @default false
	 */
	skip_dates?: boolean
	/**
	 * Label
	 * @default "unknown"
	 */
	label?: string
}

/** BIResponse */
export interface BIResponse {
	/**
	 * Start Date
	 * @format date
	 */
	start_date: string
	/**
	 * End Date
	 * @format date
	 */
	end_date: string
	/** Filters */
	filters: object[]
	/** Product Filters */
	product_filters: object[]
	/** Meta */
	meta: string[]
	/** Data */
	data: any[][]
}

/** BIRevenueRequest */
export interface BIRevenueRequest {
	/**
	 * Start Date
	 * @format date
	 */
	start_date: string
	/**
	 * End Date
	 * @format date
	 */
	end_date: string
	/**
	 * Revenue Start Date
	 * @format date
	 */
	revenue_start_date: string
	/**
	 * Revenue End Date
	 * @format date
	 */
	revenue_end_date: string
	/** Metrics */
	metrics: string[]
	/**
	 * Filters
	 * @default []
	 */
	filters?: object[]
	/**
	 * Dimensions
	 * @default []
	 */
	dimensions?: string[]
	/**
	 * Label
	 * @default "unknown"
	 */
	label?: string
}

/** BIRevenueResponse */
export interface BIRevenueResponse {
	/**
	 * Start Date
	 * @format date
	 */
	start_date: string
	/**
	 * End Date
	 * @format date
	 */
	end_date: string
	/** Filters */
	filters: object[]
	/** Meta */
	meta: string[]
	/** Data */
	data: any[][]
}

/** BIRevision */
export interface BIRevision {
	/** Name */
	name: string
	/**
	 * Date
	 * @format date
	 */
	date: string
	/** Period Days */
	period_days: number
	/** Periods */
	periods: number
	/** Last */
	last: boolean
}

/** BusinessDataUnit */
export interface BusinessDataUnit {
	/** Max Pricing Line Numbers */
	max_pricing_line_numbers: number | null
}

/** ElasticityDataUnit */
export interface ElasticityDataUnit {
	/** Low */
	low: number
	/** Medium */
	medium: number
	/** High */
	high: number
	/** Median */
	median: number | null
	/** Raw */
	raw: ElasticityRawDataUnit[]
}

/** ElasticityRawDataUnit */
export interface ElasticityRawDataUnit {
	/** Bin */
	bin: number
	/** Height */
	height: number
}

/** Metric */
export interface MetricModel {
	/** Name */
	name: string
	/** Description */
	description: string
	/** Is Accumulative */
	is_accumulative: boolean
	measure: MetricMeasure
	delta_type: MetricDeltaType
	table: TableType
	type: MetricType
	/** Formula Sql */
	formula_sql: string
	/** Formula Text */
	formula_text: string
}

/** MetricDeltaType */
export enum MetricDeltaType {
	Absolute = 'absolute',
	Relative = 'relative',
}

/** MetricMeasure */
export enum MetricMeasure {
	Percent = 'percent',
	Unit = 'unit',
	Money = 'money',
}

/** MetricModel */
export interface MetricModel {
	/** Group Type */
	group_type: string | null
	/** Group Value */
	group_value: string | null
	/** Name */
	name: string
	/** Value */
	value: number
}

/** MetricType */
export enum MetricType {
	Business = 'business',
	Pricing = 'pricing',
	Reprice = 'reprice',
	AiImportance = 'ai_importance',
	Statistics = 'statistics',
	PredictDefault = 'predict_default',
	PredictCustom = 'predict_custom',
}

/** MetricsDataUnit */
export interface MetricsDataUnit {
	/** Init */
	init: number | null
	/** Optimal */
	optimal: number | null
	/** Final */
	final: number | null
}

/** MetricsListDataUnit */
export interface MetricsListDataUnit {
	sales: MetricsDataUnit
	revenue: MetricsDataUnit
	gross_profit: MetricsDataUnit
	total_gross_profit: MetricsDataUnit
	margin: MetricsDataUnit
	total_margin: MetricsDataUnit
}

/** MultipleAlertInfoModel */
export interface MultipleAlertInfoModel {
	/** Products */
	products: number
	/** Pricing Lines */
	pricing_lines: number
	/** Est Revenue */
	est_revenue: number
	/** Est Revenue Share */
	est_revenue_share: number
	/**
	 * Optimization Group Id
	 * Only present if group_by=optimization_group_id
	 */
	optimization_group_id?: string | null
	/**
	 * Pricing Campaign Id
	 * Only present if group_by=pricing_campaign_id
	 */
	pricing_campaign_id?: string | null
	/**
	 * Id
	 * Only present in by_id collection
	 */
	id?: string | null
	/** Level */
	level: string
	/**
	 * Optimization Id
	 * @format uuid
	 */
	optimization_id: string
}

/** MultipleAlertInfoRequestModel */
export interface MultipleAlertInfoRequestModel {
	/** Optimizations */
	optimizations: OptimizationIDModel[]
	group_by?: AlertGroupBy | null
	/**
	 * Total By Group
	 * @default false
	 */
	total_by_group?: boolean
}

/** MultipleAlertsInfoResponseModel */
export interface MultipleAlertsInfoResponseModel {
	/** By Id */
	by_id: MultipleAlertInfoModel[]
	/** By Level */
	by_level: MultipleAlertInfoModel[]
}

/** OptimizationIDModel */
export interface OptimizationIDModel {
	/**
	 * Optimization Id
	 * @format uuid
	 */
	optimization_id: string
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
}

/** OptimizationPricesModel */
export interface OptimizationPricesModel {
	/** Line Id */
	line_id: string
	/** Init Price */
	init_price?: number | null
	/** Optimal Price */
	optimal_price?: number | null
	/** Final Price */
	final_price?: number | null
	/** Init Esp */
	init_esp?: number | null
	/** Optimal Esp */
	optimal_esp?: number | null
	/** Final Esp */
	final_esp?: number | null
}

/** OptimizationStatisticModel */
export interface OptimizationStatisticModel {
	/**
	 * Optimization Id
	 * @format uuid
	 */
	optimization_id: string
	/** Total */
	total: number
	/** Price Locked */
	price_locked: number | null
	/** Price Decreased */
	price_decreased: number | null
	/** Price Increased */
	price_increased: number | null
	/** Price Not Changed */
	price_not_changed: number | null
	/** Average Price Init */
	average_price_init: number | null
	/** Average Price Opt */
	average_price_opt: number | null
}

/** PredictMetricModel */
export interface PredictMetricModel {
	/** Line Id */
	line_id: string
	/** Init Sales */
	init_sales?: number | null
	/** Optimal Sales */
	optimal_sales?: number | null
	/** Init Revenue */
	init_revenue?: number | null
	/** Optimal Revenue */
	optimal_revenue?: number | null
	/** Init Gross Profit */
	init_gross_profit?: number | null
	/** Optimal Gross Profit */
	optimal_gross_profit?: number | null
	/** Init Margin */
	init_margin?: number | null
	/** Optimal Margin */
	optimal_margin?: number | null
}

/** PriceChangeDataUnit */
export interface PriceChangeDataUnit {
	/** Products */
	products: number
	/** Pricing Lines */
	pricing_lines: number
}

/** RepricingTypeDataUnit */
export interface RepricingTypeDataUnit {
	/** Pricing Lines */
	pricing_lines: number
}

/** SettingsDataUnit */
export interface SettingsDataUnit {
	strategy: StrategyDataUnit
	business: BusinessDataUnit
}

/** StatisticsInterpretabilityRequest */
export interface StatisticsInterpretabilityRequest {
	/** Optimizations */
	optimizations: OptimizationIDModel[]
	/**
	 * Filters
	 * @default []
	 */
	filters?: object[]
	/**
	 * Label
	 * @default "unknown"
	 */
	label?: string
}

/** StatisticsInterpretabilityResponse */
export interface StatisticsInterpretabilityResponse {
	/** Optimization Id */
	optimization_id: string | null
	/** Metrics */
	metrics: object
	elasticity: ElasticityDataUnit
}

/** StatisticsRepricingRequest */
export interface StatisticsRepricingRequest {
	/** Optimizations */
	optimizations: OptimizationIDModel[]
	/**
	 * Filters
	 * @default []
	 */
	filters?: object[]
	/**
	 * Label
	 * @default "unknown"
	 */
	label?: string
}

/** StatisticsRepricingResponse */
export interface StatisticsRepricingResponse {
	settings: SettingsDataUnit
	/** Metrics */
	metrics: MetricModel[]
}

/** StatisticsRequest */
export interface StatisticsRequest {
	/** Optimizations */
	optimizations: OptimizationIDModel[]
	/** Metrics */
	metrics: string[]
	/**
	 * Filters
	 * @default []
	 */
	filters?: object[]
	/**
	 * Dimensions
	 * @default []
	 */
	dimensions?: string[]
	/**
	 * Label
	 * @default "unknown"
	 */
	label?: string
}

/** StatisticsResponse */
export interface StatisticsResponse {
	/** Filters */
	filters: object[]
	/** Meta */
	meta: string[]
	/** Data */
	data: any[][]
}

/** StatisticsTargetProtectDistributionRequest */
export interface StatisticsTargetProtectDistributionRequest {
	/** Optimizations */
	optimizations: OptimizationIDModel[]
	/**
	 * Filters
	 * @default []
	 */
	filters?: object[]
	/**
	 * Label
	 * @default "unknown"
	 */
	label?: string
}

/** StrategyDataUnit */
export interface StrategyDataUnit {
	/** Target */
	target: string | null
	/** Protect */
	protect: string | null
}

/** TableType */
export enum TableType {
	Bi = 'bi',
	FeatureImportance = 'feature_importance',
	Statistics = 'statistics',
}

/** TargetProtectDistributionModel */
export interface TargetProtectDistributionModel {
	/** Metric */
	metric: string
	/** Type */
	type: 'target' | 'protect' | 'other'
	/** Value */
	value: number
}

/** TreeMapProductGroupModel */
export interface TreeMapProductGroupModel {
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/**
	 * Product Group Id
	 * @format uuid
	 */
	product_group_id: string
	/** Category Id */
	category_id: string | null
	/** Products */
	products: number
	/** Revenue */
	revenue: number
	/** Revenue With Alerts */
	revenue_with_alerts: number
}

/** TreeMapRequestModel */
export interface TreeMapRequestModel {
	/** Optimization Group Ids */
	optimization_group_ids?: string[] | null
	/** Pos Ids */
	pos_ids?: string[] | null
	/**
	 * Filters
	 * @default []
	 */
	filters?: object[]
}

/** TreeMapSalesEntityModel */
export interface TreeMapSalesEntityModel {
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/** Sales Entity Id */
	sales_entity_id: string
	/** Products */
	products: number
	/** Revenue */
	revenue: number
	/** Revenue With Alerts */
	revenue_with_alerts: number
}

export interface SalesEntitiesResponseData {
	sales_levels: SalesLevelModel[]
	sales_entities: SalesEntityModel[]
}

export interface SalesLevelModel {
	level: number
	name: string
}

export interface SalesEntityModel {
	sales_level_id: number
	/** @format uuid */
	id: string
	parent_id: string | null
	name: string
}

export interface PcProductsCountsModel {
	/**
	 * optimization group id
	 * @format uuid
	 */
	optimization_group_id?: string
	/**
	 * pricing campaign id
	 * @format uuid
	 */
	pricing_campaign_id?: string
	/** count of products, cannot be 0, PCs with count 0 are not returned */
	product_count?: number
}

export type ProductsCountsByOgResponseData = {
	/**
	 * optimization group id
	 * @format uuid
	 */
	optimization_group_id?: string
	/**
	 * pricing campaign id
	 * @format uuid
	 */
	pricing_campaign_id?: string
	/** count of products, cannot be 0, PCs with count 0 are not returned */
	product_count?: number
}[]

/** Optimization Action */
export enum OptimizationAction {
	Lock = 'lock',
	Unlock = 'unlock',
	Start = 'start',
	Stop = 'stop',
	Update = 'update',
	DeleteExport = 'delete_export',
	Reset = 'reset',
	RevertPrices = 'revert_prices',
}

/** AlertLevel */
export enum AlertLevel {
	Unassigned = 'unassigned',
	Critical = 'critical',
	Warning = 'warning',
	Info = 'info',
}

/** AlertAnnotationModel */
export interface AlertAnnotationModel {
	description: string
	id: string
	level: AlertLevel
	name: string
	solution_tooltip: string
}

export interface BrandModel {
	id: string
	name: string
	/** @format uuid */
	optimization_group_id: string
	parent?: string
}

export interface CategoryModel {
	/** Category uniq id */
	id: string
	/** Category name */
	name: string
	/**
	 * Related optimization group id
	 * @format uuid
	 */
	optimization_group_id?: string
	/** Uniq id of parent category */
	parent_id: string | null
}

export interface UpdateOptimizationGroupInput {
	sales_level_id?: number
	name?: string
	description?: string
	sales_entities?: string[]
	product_groups?: OptimizationGroupProductGroupModel[]
}

export interface CreateOptimizationGroupInput {
	sales_level_id: number
	name?: string
	description?: string
	sales_entities: string[]
	product_groups: OptimizationGroupProductGroupModel[]
}

export interface OptimizationGroupModel {
	/** @format uuid */
	id: string
	sales_level_id: number
	name: string
	is_name_locked: boolean
	description?: string
	order?: number
	sales_entities: string[]
	pos_ids: string[]
	product_groups: OptimizationGroupProductGroupModel[]
	/**
	 * Created At
	 * @format date-time
	 */
	created_at: string
	/**
	 * Updated By
	 * @format uuid
	 */
	created_by: string
	/** Updated At */
	updated_at: string | null
	/** Updated By */
	updated_by: string | null
}

export interface OptimizationGroupProductGroupModel {
	product_group_id: string
	filters: FilterRuleModel[]
}

/** Optimization Status */
export enum OptimizationStatus {
	Locked = 'locked',
	Created = 'created',
	Scheduled = 'scheduled',
	Started = 'started',
	Finished = 'finished',
	Canceled = 'canceled',
	Updating = 'updating',
	UpdatingFailed = 'updating_failed',
	Pending = 'pending',
	Failed = 'failed',
	New = 'new',
}

export interface OptimizationModel {
	/** ID of the optimization */
	id: string
	/**
	 * ID of the optimization group
	 * @format uuid
	 */
	optimization_group_id: string
	status: OptimizationStatus
	/** Count of total products in the optimization */
	total_products: number
	/**
	 * Date when the optimization was created
	 * @format date-time
	 */
	created_date: string
	/**
	 * Date when the optimization was scheduled
	 * @format date-time
	 */
	scheduled_date?: string
	/**
	 * Date when the optimization was started
	 * @format date-time
	 */
	started_date?: string
	/**
	 * Date when the optimization was finished
	 * @format date-time
	 */
	finished_date?: string
	/**
	 * Date when the optimization was canceled
	 * @format date-time
	 */
	canceled_date?: string
	/**
	 * Date when the optimization was failed
	 * @format date-time
	 */
	failed_date?: string
	/**
	 * Date when the optimization was updated
	 * @format date-time
	 */
	last_updated_date?: string
	/** Flag indicating if the optimization results has been exported by the user */
	is_exported: boolean
}

export type BusinessSettingsModel = Record<string, any>

export interface StopListSettingsModel {
	/** Specific stoplist rules defined by the user */
	custom_rules: FilterRuleModel[]
	/** Default stoplist rules defined by the system */
	default_rules: Record<string, any>
}

export interface ScenarioSettingsModel {
	strategy: StrategiesSettingsModel
	stop_list: StopListSettingsModel
	business: BusinessSettingsModel
}

export interface StrategiesSettingsModel {
	demand_strategy: {
		target: string
		protect: string
	}
	markdown_strategy: {
		target?: string
	}
}

export interface SavedFilterCreateModel {
	name: string
	params: {
		description?: string
		query?: FilterRuleModel[]
	}
}

export interface SavedFilterUpdateModel {
	/** @format uuid */
	id?: string
	name: string
	params: {
		description?: string
		query?: FilterRuleModel[]
	}
}

export interface SavedFilterModel {
	/** @format uuid */
	id: string
	name: string
	params: {
		description?: string
		query?: FilterRuleModel[]
	}
}

export interface FinalFieldsListSchema {
	final_fields: {
		final_esp?: number
		final_price?: number
		sku: string
	}[]
}

export interface ColumnSchemaModel {
	/** @example "sku" */
	name: string
	/** @example "SKU" */
	tooltip?: string
	/** @example "SKU" */
	visible_name: string
	/** @example "first" */
	aggregation_type?: string
	/** @example "system" */
	category?: string
	/** @example "str" */
	type: string
	enum?: string[]
	/** @example false */
	editable?: boolean
	/** @example true */
	grouper?: boolean
	/** @example false */
	hidden?: boolean
	/** @example true */
	searchable?: boolean
	/** @example false */
	propagate?: boolean
	/** @example "field_schema_sku" */
	translate_key?: string
	ui_schema?: Record<string, any>
	filter_schema?: Record<string, any>
	/** @example 1 */
	default_order?: number
	/** @example "products[*].sku" */
	path?: string
	/** @example "prepro" */
	stage?: string
}

export interface OptimizationActionSchema {
	action: string
	/** @format uuid */
	optimization_group_id: string
	/** @format uuid */
	optimization_id: string
}

export interface OptimizationCopySchema {
	/** @default false */
	copy_business_settings?: boolean
	/** @default false */
	copy_default_pc?: boolean
	/** @default false */
	copy_pcs?: boolean
	/** @default false */
	copy_pcs_limits?: boolean
	/** @default false */
	copy_pcs_membership?: boolean
	/** @default false */
	copy_pcs_minimal_steps?: boolean
	/** @default false */
	copy_pcs_rounding?: boolean
	/** @default false */
	copy_pcs_segments?: boolean
	/** @default false */
	copy_stop_list_settings?: boolean
	/** @format uuid */
	to_optimization_group_id: string
}

export interface ProductModel {
	cost: number
	last_stock_dt?: string
	sku: string
	stock: number
	title: string
	custom_fields?: Record<string, any>
}

export interface PricingLineModel {
	line_id: string
	/** @format uuid */
	optimization_group_id: string
	fields: Record<string, any>
	products: ProductModel[]
}

export interface CountByFiltersRequestBody {
	filters?: FilterRuleModel[]
}

export interface PricingRuleModel {
	id: string
	params_schema: Record<string, any>
	ui_schema: Record<string, any>
}

export interface RoundingRuleModel {
	id: string
	params_schema: Record<string, any>
	ui_schema: Record<string, any>
}

export interface ProductDiffsSchema {
	/** @default true */
	ignore_empties?: boolean
	product_diffs: object[]
}

export interface ProductGroupModel {
	id: string
	name: string
	categories: Record<string, any>
}

export interface ExportTemplateModel1 {
	/** @format uuid */
	id?: string
	name?: string
	tier_ids?: string[]
}

export interface PreferencesModel {
	quick_filters_keys?: string[]
}

export interface OptimizationAnalyticsModel {
	period_beginning: string
	price_decreased?: number
	price_increased?: number
	price_locked?: number
	price_not_changed?: number
}

export interface OptimizationAnalyticsResponseModel {
	data?: OptimizationAnalyticsModel[]
}

export interface ProductsQueryResponseModel {
	total_skus: number
	total_count: number
	data: PricingLineModel[]
}

export interface ProductsQueryRequestModel {
	filters?: FilterRuleModel[]
	order_by?: {
		name?: string
		direction?: 'asc' | 'desc'
	}[]
	limit?: number
	search?: string
	offset?: number
}

export interface ProductsExportResponseModel {
	total_count?: number
	data?: PricingLineModel[]
}

export interface ProductsExportRequestModel {
	aggregation?: string
	format?: 'csv' | 'xlsx'
	fields?: string
	filters?: FilterRuleModel[]
	order_by?: {
		name?: string
		direction?: 'asc' | 'desc'
	}[]
	search?: string
}

export interface UpdateProductsFinalFieldsResponseModel {
	optimization?: OptimizationModel
	success?: boolean
}

export interface UpdateProductsFinalFieldsErrorResponseModel {
	optimization?: OptimizationModel
	success?: boolean
	errors?: {
		final_fields?: {
			additionalProperties?: {
				additionalProperties?: string[]
			}
		}
	}
}

export interface UpdateProductsModelFieldsResponseModel {
	optimization?: OptimizationModel
	success?: boolean
}

export interface UpdateProductsModelFieldsErrorResponseModel {
	optimization?: OptimizationModel
	success?: boolean
	errors?: {
		product_diffs?: {
			additionalProperties?: {
				additionalProperties?: string[]
			}
		}
	}
}

export interface DiffValidationResponseModel {
	errors?: Record<string, string[]>[]
	product_diffs?: object
	success?: boolean
}

export interface UpdateProductsFileldsByFiltersResponseModel {
	optimization?: OptimizationModel
}

export interface UpdateProductsFieldsByFiltersRequestModel {
	filters: FilterRuleModel[]
	values: Record<string, any>
}

export interface PricinRuleUiSchemaModel {
	id?: string
	ui_schema?: Record<string, any>
}

export interface AdditionalCommentDTO {
	/** Content of the comment. */
	body?: string
}

export interface ApprovalDTO {
	/** The REST API URL of the approval. */
	_links?: SelfLinkDTO
	/** Detailed list of the users who must provide a response to the approval. */
	approvers?: ApproverDTO[]
	/** Indicates whether the user making the request is one of the approvers and can respond to the approval (true) or not (false). */
	canAnswerApproval?: boolean
	/** Date the approval was completed. */
	completedDate?: DateDTO
	/** Date the approval was created. */
	createdDate?: DateDTO
	/** Outcome of the approval, based on the approvals provided by all approvers. */
	finalDecision?: 'approved' | 'declined' | 'pending'
	/** ID of the approval. */
	id?: string
	/** Description of the approval being sought or provided. */
	name?: string
}

export interface ApprovalDecisionRequestDTO {
	/** Response to the approval request. */
	decision?: 'approve' | 'decline'
}

export interface ApproverDTO {
	/** Details of the User who is providing approval. */
	approver?: UserDTO
	/** Decision made by the approver. */
	approverDecision?: 'approved' | 'declined' | 'pending'
}

export interface ArticleDTO {
	content?: ContentDTO
	/** Excerpt of the article which matches the given query string. */
	excerpt?: string
	/** Source of the article. */
	source?: SourceDTO
	/** Title of the article. */
	title?: string
}

/** Details of an Assets workspace ID. */
export interface AssetsWorkspaceDTO {
	/** The workspace ID used as the identifier to access the Assets REST API. */
	workspaceId?: string
}

export interface AttachmentCreateDTO {
	/** Comment about the attachments. */
	additionalComment?: AdditionalCommentDTO
	/** Indicates whether the attachments are to be public (true) or private/internal (false). */
	public?: boolean
	/** List of IDs for the temporary attachments to be added to the customer request. */
	temporaryAttachmentIds?: string[]
}

export interface AttachmentCreateResultDTO {
	/** List of the attachments added. */
	attachments?: PagedDTOAttachmentDTO
	/** Details of the comment included with the attachments. */
	comment?: CommentDTO
}

export interface AttachmentDTO {
	/** Various URLs for the attachment. */
	_links?: AttachmentLinkDTO
	/** Details of the user who attached the file. */
	author?: UserDTO
	/** Date the attachment was added. */
	created?: DateDTO
	/** Filename of the item attached. */
	filename?: string
	/** MIME type of the attachment. */
	mimeType?: string
	/**
	 * Size of the attachment in bytes.
	 * @format int64
	 */
	size?: number
}

export interface AttachmentLinkDTO {
	/**
	 * URL for the attachment.
	 * @format uri
	 */
	content?: string
	/**
	 * REST API URL for the attachment
	 * @format uri
	 */
	jiraRest?: string
	/** @format uri */
	self?: string
	/**
	 * URL for the attachment's thumbnail image.
	 * @format uri
	 */
	thumbnail?: string
}

export interface AvatarUrlsBean {
	/**
	 * The URL of the item's 16x16 pixel avatar.
	 * @format uri
	 */
	'16x16'?: string
	/**
	 * The URL of the item's 24x24 pixel avatar.
	 * @format uri
	 */
	'24x24'?: string
	/**
	 * The URL of the item's 32x32 pixel avatar.
	 * @format uri
	 */
	'32x32'?: string
	/**
	 * The URL of the item's 48x48 pixel avatar.
	 * @format uri
	 */
	'48x48'?: string
}

export interface CSATFeedbackFullDTO {
	/** (Optional) The comment provided with this feedback. */
	comment?: AdditionalCommentDTO
	/**
	 * A numeric representation of the rating, this must be an integer value between 1 and 5.
	 * @format int32
	 */
	rating?: number
	/** Indicates the type of feedback, supported values: `csat`. */
	type?: string
}

/** A change item. */
export interface ChangeDetails {
	/** The name of the field changed. */
	field?: string
	/** The ID of the field changed. */
	fieldId?: string
	/** The type of the field changed. */
	fieldtype?: string
	/** The details of the original value. */
	from?: string
	/** The details of the original value as a string. */
	fromString?: string
	/** The details of the new value. */
	to?: string
	/** The details of the new value as a string. */
	toString?: string
}

/** A log of changes made to issue fields. Changelogs related to workflow associations are currently being deprecated. */
export interface Changelog {
	/** The user who made the change. */
	author?: UserDetails
	/**
	 * The date on which the change took place.
	 * @format date-time
	 */
	created?: string
	/** The history metadata associated with the changed. */
	historyMetadata?: HistoryMetadata
	/** The ID of the changelog. */
	id?: string
	/** The list of items changed. */
	items?: ChangeDetails[]
}

export interface CommentCreateDTO {
	/** Content of the comment. */
	body?: string
	/** Indicates whether the comment is public (true) or private/internal (false). */
	public?: boolean
}

export interface CommentDTO {
	/** List of items that can be expanded in the response by specifying the expand query parameter. */
	_expands?: string[]
	/** REST API URL link to the comment. */
	_links?: SelfLinkDTO
	/** List of the attachments included in the comment. */
	attachments?: PagedDTOAttachmentDTO
	/** Details of the customer who authored the comment. */
	author?: UserDTO
	/** Content of the comment. */
	body?: string
	/** Date the comment was created. */
	created?: DateDTO
	/** ID of the comment. */
	id?: string
	/** Indicates whether the comment is public (true) or private/internal (false). */
	public?: boolean
	/** The rendered body of the comment. */
	renderedBody?: RenderedValueDTO
}

export interface ContentDTO {
	/** Url containing the body of the article (without title), suitable for rendering in an iframe */
	iframeSrc?: string
}

export interface CustomerCreateDTO {
	/** Customer's name for display in the UI. */
	displayName?: string
	/** Customer's email address. */
	email?: string
	/** Deprecated, please use 'displayName'. */
	fullName?: string
}

export interface CustomerRequestActionDTO {
	/** Indicates whether the user can undertake the action (true) or not (false). */
	allowed?: boolean
}

export interface CustomerRequestActionsDTO {
	/** Action of adding attachments to a request. */
	addAttachment?: CustomerRequestActionDTO
	/** Action of adding comments to a request. */
	addComment?: CustomerRequestActionDTO
	/** Action of adding participants to a request. */
	addParticipant?: CustomerRequestActionDTO
	/** Action of removing participants from a request. */
	removeParticipant?: CustomerRequestActionDTO
}

export interface CustomerRequestCreateMetaDTO {
	/** Flag indicating if participants can be added to a request (true) or not. */
	canAddRequestParticipants?: boolean
	/** Flag indicating if a request can be raised on behalf of another user (true) or not. */
	canRaiseOnBehalfOf?: boolean
	/** List of the fields included in this request. */
	requestTypeFields?: RequestTypeFieldDTO[]
}

export interface CustomerRequestDTO {
	/** List of items that can be expanded in the response by specifying the expand query parameter. */
	_expands?: string[]
	/** List of links associated with the request. */
	_links?: CustomerRequestLinkDTO
	/** List of actions that the user can take on the request. */
	actions?: CustomerRequestActionsDTO
	/** List of attachments included with the request. */
	attachments?: PagedDTOAttachmentDTO
	/** List of comments included with the request. */
	comments?: PagedDTOCommentDTO
	/** Date on which the request was created. */
	createdDate?: DateDTO
	/** Status of the request. */
	currentStatus?: CustomerRequestStatusDTO
	/** ID of the request, as the peer issue ID. */
	issueId?: string
	/** Key of the request, as the peer issue key. */
	issueKey?: string
	/** Expandable details of the customers participating in the request. */
	participants?: PagedDTOUserDTO
	/** Details of the customer reporting the request. */
	reporter?: UserDTO
	/** JSON map of Jira field IDs and their values representing the content of the request. This list does not include hidden fields. */
	requestFieldValues?: CustomerRequestFieldValueDTO[]
	/** Expandable details of the request type. */
	requestType?: RequestTypeDTO
	/** ID of the request type for the request. */
	requestTypeId?: string
	/** Expandable details of the service desk. */
	serviceDesk?: ServiceDeskDTO
	/** ID of the service desk the request belongs to. */
	serviceDeskId?: string
	/** Expandable details of the SLAs relating to the request. */
	sla?: PagedDTOSlaInformationDTO
	/** Expandable details of the request's status history. */
	status?: PagedDTOCustomerRequestStatusDTO
}

export interface CustomerRequestFieldValueDTO {
	/** ID of the field. */
	fieldId?: string
	/** Text label for the field. */
	label?: string
	/** Value of the field rendered in the UI. */
	renderedValue?: object
	/** Value of the field. */
	value?: any
}

export interface CustomerRequestLinkDTO {
	/**
	 * Jira agent view URL for the request.
	 * @format uri
	 */
	agent?: string
	/**
	 * REST API URL for the request.
	 * @format uri
	 */
	jiraRest?: string
	/** @format uri */
	self?: string
	/**
	 * Web URL for the request.
	 * @format uri
	 */
	web?: string
}

export interface CustomerRequestStatusDTO {
	/** Name of the status condition. */
	status?: string
	/** Status category the status belongs to. */
	statusCategory?: 'UNDEFINED' | 'NEW' | 'INDETERMINATE' | 'DONE'
	/** Date on which the status was attained. */
	statusDate?: DateDTO
}

export interface CustomerTransitionDTO {
	/** ID of the transition. */
	id?: string
	/** Name of the transition. */
	name?: string
}

export interface CustomerTransitionExecutionDTO {
	/** Comment explaining the reason for the transition. */
	additionalComment?: AdditionalCommentDTO
	/** ID of the transition to be performed. */
	id?: string
}

export interface DateDTO {
	/**
	 * Date as the number of milliseconds that have elapsed since 00:00:00 Coordinated Universal Time (UTC), 1 January 1970.
	 * @format int64
	 */
	epochMillis?: number
	/** Date in a user-friendly text format. */
	friendly?: string
	/** Date in ISO8601 format. */
	iso8601?: string
	/** Date in the format used in the Jira REST APIs, which is ISO8601 format but extended with milliseconds. For example, 2016-09-28T23:08:32.097+1000. */
	jira?: string
}

export interface DurationDTO {
	/** Duration in a user-friendly text format. */
	friendly?: string
	/**
	 * Duration in milliseconds.
	 * @format int64
	 */
	millis?: number
}

/** An entity property, for more information see [Entity properties](https://developer.atlassian.com/cloud/jira/platform/jira-entity-properties/). */
export interface EntityProperty {
	/** The key of the property. Required on create and update. */
	key?: string
	/** The value of the property. Required on create and update. */
	value?: any
}

export interface ErrorResponse {
	errorMessage?: string
	i18nErrorMessage?: I18NErrorMessage
}

export interface Expandable {
	_expands?: string[]
}

/** The metadata describing an issue field. */
export interface FieldMetadata {
	/** The list of values allowed in the field. */
	allowedValues?: any[]
	/** The URL that can be used to automatically complete the field. */
	autoCompleteUrl?: string
	/** The configuration properties. */
	configuration?: Record<string, any>
	/** The default value of the field. */
	defaultValue?: any
	/** Whether the field has a default value. */
	hasDefaultValue?: boolean
	/** The key of the field. */
	key: string
	/** The name of the field. */
	name: string
	/** The list of operations that can be performed on the field. */
	operations: string[]
	/** Whether the field is required. */
	required: boolean
	/** The data type of the field. */
	schema: JsonTypeBean
}

/** Details of issue history metadata. */
export interface HistoryMetadata {
	/** The activity described in the history record. */
	activityDescription?: string
	/** The key of the activity described in the history record. */
	activityDescriptionKey?: string
	/** Details of the user whose action created the history record. */
	actor?: HistoryMetadataParticipant
	/** Details of the cause that triggered the creation the history record. */
	cause?: HistoryMetadataParticipant
	/** The description of the history record. */
	description?: string
	/** The description key of the history record. */
	descriptionKey?: string
	/** The description of the email address associated the history record. */
	emailDescription?: string
	/** The description key of the email address associated the history record. */
	emailDescriptionKey?: string
	/** Additional arbitrary information about the history record. */
	extraData?: Record<string, string>
	/** Details of the system that generated the history record. */
	generator?: HistoryMetadataParticipant
	/** The type of the history record. */
	type?: string
	[key: string]: any
}

/** Details of user or system associated with a issue history metadata item. */
export interface HistoryMetadataParticipant {
	/** The URL to an avatar for the user or system associated with a history record. */
	avatarUrl?: string
	/** The display name of the user or system associated with a history record. */
	displayName?: string
	/** The key of the display name of the user or system associated with a history record. */
	displayNameKey?: string
	/** The ID of the user or system associated with a history record. */
	id?: string
	/** The type of the user or system associated with a history record. */
	type?: string
	/** The URL of the user or system associated with a history record. */
	url?: string
	[key: string]: any
}

export interface I18NErrorMessage {
	i18nKey?: string
	parameters?: string[]
}

export interface IncludedFields {
	/** @uniqueItems true */
	actuallyIncluded?: string[]
	/** @uniqueItems true */
	excluded?: string[]
	/** @uniqueItems true */
	included?: string[]
}

/** Details of an insight workspace ID. */
export interface InsightWorkspaceDTO {
	/** The workspace ID used as the identifier to access the Insight REST API. */
	workspaceId?: string
}

/** Details about an issue. */
export interface IssueBean {
	/** Details of changelogs associated with the issue. */
	changelog?: PageOfChangelogs
	/** The metadata for the fields on the issue that can be amended. */
	editmeta?: IssueUpdateMetadata
	/** Expand options that include additional issue details in the response. */
	expand?: string
	fields?: Record<string, any>
	fieldsToInclude?: IncludedFields
	/** The ID of the issue. */
	id?: string
	/** The key of the issue. */
	key?: string
	/** The ID and name of each field present on the issue. */
	names?: Record<string, string>
	/** The operations that can be performed on the issue. */
	operations?: Operations
	/** Details of the issue properties identified in the request. */
	properties?: Record<string, any>
	/** The rendered value of each field present on the issue. */
	renderedFields?: Record<string, any>
	/** The schema describing each field present on the issue. */
	schema?: Record<string, JsonTypeBean>
	/**
	 * The URL of the issue details.
	 * @format uri
	 */
	self?: string
	/** The transitions that can be performed on the issue. */
	transitions?: IssueTransition[]
	/** The versions of each field on the issue. */
	versionedRepresentations?: Record<string, Record<string, any>>
}

/** Details of an issue transition. */
export interface IssueTransition {
	/** Expand options that include additional transition details in the response. */
	expand?: string
	/** Details of the fields associated with the issue transition screen. Use this information to populate `fields` and `update` in a transition request. */
	fields?: Record<string, FieldMetadata>
	/** Whether there is a screen associated with the issue transition. */
	hasScreen?: boolean
	/** The ID of the issue transition. Required when specifying a transition to undertake. */
	id?: string
	/** Whether the transition is available to be performed. */
	isAvailable?: boolean
	/** Whether the issue has to meet criteria before the issue transition is applied. */
	isConditional?: boolean
	/** Whether the issue transition is global, that is, the transition is applied to issues regardless of their status. */
	isGlobal?: boolean
	/** Whether this is the initial issue transition for the workflow. */
	isInitial?: boolean
	looped?: boolean
	/** The name of the issue transition. */
	name?: string
	/** Details of the issue status after the transition. */
	to?: StatusDetails
	[key: string]: any
}

/** A list of editable field details. */
export interface IssueUpdateMetadata {
	fields?: Record<string, FieldMetadata>
}

/** The schema of a field. */
export interface JsonTypeBean {
	/** If the field is a custom field, the configuration of the field. */
	configuration?: Record<string, any>
	/** If the field is a custom field, the URI of the field. */
	custom?: string
	/**
	 * If the field is a custom field, the custom ID of the field.
	 * @format int64
	 */
	customId?: number
	/** When the data type is an array, the name of the field items within the array. */
	items?: string
	/** If the field is a system field, the name of the field. */
	system?: string
	/** The data type of the field. */
	type: string
}

/** Details a link group, which defines issue operations. */
export interface LinkGroup {
	groups?: LinkGroup[]
	/** Details about the operations available in this version. */
	header?: SimpleLink
	id?: string
	links?: SimpleLink[]
	styleClass?: string
	/** @format int32 */
	weight?: number
}

export interface Linkable {
	_links?: SelfLinkDTO
}

export interface LinkableAttachmentLinkDTO {
	_links?: AttachmentLinkDTO
}

export interface LinkableCustomerRequestLinkDTO {
	_links?: CustomerRequestLinkDTO
}

export interface LinkableUserLinkDTO {
	_links?: UserLinkDTO
}

/** Details of the operations that can be performed on the issue. */
export interface Operations {
	/** Details of the link groups defining issue operations. */
	linkGroups?: LinkGroup[]
	[key: string]: any
}

export interface OrganizationCreateDTO {
	/** Name of the organization. */
	name: string
}

export interface OrganizationDTO {
	/** REST API URL to the organization. */
	_links?: SelfLinkDTO
	/** A unique system generated ID for the organization. */
	id?: string
	/** Name of the organization. */
	name?: string
}

export interface OrganizationServiceDeskUpdateDTO {
	/**
	 * List of organizations, specified by 'ID' field values, to add to or remove from the service desk.
	 * @format int32
	 */
	organizationId: number
}

/** A page of changelogs. */
export interface PageOfChangelogs {
	/** The list of changelogs. */
	histories?: Changelog[]
	/**
	 * The maximum number of results that could be on the page.
	 * @format int32
	 */
	maxResults?: number
	/**
	 * The index of the first item returned on the page.
	 * @format int32
	 */
	startAt?: number
	/**
	 * The number of results on the page.
	 * @format int32
	 */
	total?: number
}

export interface PagedDTOApprovalDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: ApprovalDTO[]
}

export interface PagedDTOArticleDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: ArticleDTO[]
}

export interface PagedDTOAssetsWorkspaceDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: AssetsWorkspaceDTO[]
}

export interface PagedDTOAttachmentDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: AttachmentDTO[]
}

export interface PagedDTOCommentDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: CommentDTO[]
}

export interface PagedDTOCustomerRequestDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: CustomerRequestDTO[]
}

export interface PagedDTOCustomerRequestStatusDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: CustomerRequestStatusDTO[]
}

export interface PagedDTOCustomerTransitionDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: CustomerTransitionDTO[]
}

export interface PagedDTOInsightWorkspaceDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: InsightWorkspaceDTO[]
}

export interface PagedDTOIssueBean {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: IssueBean[]
}

export interface PagedDTOOrganizationDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: OrganizationDTO[]
}

export interface PagedDTOQueueDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: QueueDTO[]
}

export interface PagedDTORequestTypeDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: RequestTypeDTO[]
}

export interface PagedDTORequestTypeGroupDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: RequestTypeGroupDTO[]
}

export interface PagedDTOServiceDeskDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: ServiceDeskDTO[]
}

export interface PagedDTOSlaInformationDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: SlaInformationDTO[]
}

export interface PagedDTOUserDTO {
	_expands?: string[]
	/** List of the links relating to the page. */
	_links?: PagedLinkDTO
	/** Indicates if this is the last page of records (true) or not (false). */
	isLastPage?: boolean
	/**
	 * Number of items to be returned per page, up to the maximum set for these objects in the current implementation.
	 * @format int32
	 */
	limit?: number
	/**
	 * Number of items returned in the page.
	 * @format int32
	 */
	size?: number
	/**
	 * Index of the first item returned in the page.
	 * @format int32
	 */
	start?: number
	/** Details of the items included in the page. */
	values?: UserDTO[]
}

export interface PagedLinkDTO {
	/**
	 * Base URL for the REST API calls.
	 * @format uri
	 */
	base?: string
	context?: string
	/**
	 * REST API URL for the next page, if there is one.
	 * @format uri
	 */
	next?: string
	/**
	 * REST API URL for the previous page, if there is one.
	 * @format uri
	 */
	prev?: string
	/**
	 * REST API URL for the current page.
	 * @format uri
	 */
	self?: string
}

/** Property key details. */
export interface PropertyKey {
	/** The key of the property. */
	key?: string
	/** The URL of the property. */
	self?: string
}

/** List of property keys. */
export interface PropertyKeys {
	/** Property key details. */
	keys?: PropertyKey[]
}

export interface QueueDTO {
	/** REST API URL to the queue. */
	_links?: SelfLinkDTO
	/** Fields returned for each request in the queue. */
	fields?: string[]
	/** ID for the queue. */
	id?: string
	/**
	 * The count of customer requests in the queue.
	 * @format int64
	 */
	issueCount?: number
	/** JQL query that filters reqeusts for the queue. */
	jql?: string
	/** Short name for the queue. */
	name?: string
}

export interface RenderedValueDTO {
	html?: string
}

export interface RequestCreateDTO {
	/** (Experimental) Shows extra information for the request channel. */
	channel?: string
	/** (Experimental) Whether to accept rich text fields in Atlassian Document Format (ADF). */
	isAdfRequest?: boolean
	/** The `accountId` of the customer that the request is being raised on behalf of. */
	raiseOnBehalfOf?: string
	/** JSON map of Jira field IDs and their values representing the content of the request. */
	requestFieldValues?: Record<string, any>
	/** List of customers to participate in the request, as a list of `accountId` values. */
	requestParticipants?: string[]
	/** ID of the request type for the request. */
	requestTypeId?: string
	/** ID of the service desk in which to create the request. */
	serviceDeskId?: string
}

export interface RequestNotificationSubscriptionDTO {
	/** Indicates whether the user is subscribed (true) or not (false) to the request's notifications. */
	subscribed?: boolean
}

export interface RequestParticipantUpdateDTO {
	/** List of users, specified by account IDs, to add to or remove as participants in the request. */
	accountIds?: string[]
	/** This property is no longer available and will be removed from the documentation soon. See the [deprecation notice](https://developer.atlassian.com/cloud/jira/platform/deprecation-notice-user-privacy-api-migration-guide/) for details. Use `accountIds` instead. */
	usernames?: string[]
}

export interface RequestTypeCreateDTO {
	/** Description of the request type on the service desk. */
	description?: string
	/** Help text for the request type on the service desk. */
	helpText?: string
	/** ID of the request type to add to the service desk. */
	issueTypeId?: string
	/** Name of the request type on the service desk. */
	name?: string
}

export interface RequestTypeDTO {
	/** List of items that can be expanded in the response by specifying the expand query parameter. */
	_expands?: string[]
	/** REST API URL for the request type. */
	_links?: SelfLinkDTO
	/** Description of the request type. */
	description?: string
	/** Fields and additional metadata for creating a request that uses the request type */
	fields?: CustomerRequestCreateMetaDTO
	/** List of the request type groups the request type belongs to. */
	groupIds?: string[]
	/** Help text for the request type. */
	helpText?: string
	/** Links to the request type's icons. */
	icon?: RequestTypeIconDTO
	/** ID for the request type. */
	id?: string
	/** ID of the issue type the request type is based upon. */
	issueTypeId?: string
	/** Short name for the request type. */
	name?: string
	/** ID of the customer portal associated with the service desk project. */
	portalId?: string
	/** The request type's practice */
	practice?: string
	/** ID of the service desk the request type belongs to. */
	serviceDeskId?: string
}

export interface RequestTypeFieldDTO {
	/** List of default values for the field. */
	defaultValues?: RequestTypeFieldValueDTO[]
	/** Description of the field. */
	description?: string
	/** ID of the field. */
	fieldId?: string
	/** Jira specific implementation details for the field in the UI. */
	jiraSchema?: JsonTypeBean
	/** Name of the field. */
	name?: string
	/** List of preset values for the field. */
	presetValues?: string[]
	/** Indicates if the field is required (true) or not (false). */
	required?: boolean
	/** List of valid values for the field. */
	validValues?: RequestTypeFieldValueDTO[]
	visible?: boolean
}

export interface RequestTypeFieldValueDTO {
	/** List of child fields. */
	children?: RequestTypeFieldValueDTO[]
	/** Label for the field. */
	label?: string
	/** Value of the field. */
	value?: string
}

export interface RequestTypeGroupDTO {
	/** ID of the request type group */
	id?: string
	/** Name of the request type group. */
	name?: string
}

export interface RequestTypeIconDTO {
	/** Map of the URLs for the request type icons. */
	_links?: RequestTypeIconLinkDTO
	/** ID of the request type icon. */
	id?: string
}

export interface RequestTypeIconLinkDTO {
	/** URLs for the request type icons. */
	iconUrls?: Record<string, string>
}

export interface SelfLinkDTO {
	/** @format uri */
	self?: string
}

export interface ServiceDeskCustomerDTO {
	/**
	 * List of users, specified by account IDs, to add to or remove from a service desk.
	 * @uniqueItems true
	 */
	accountIds?: string[]
	/**
	 * This property is no longer available and will be removed from the documentation soon. See the [deprecation notice](https://developer.atlassian.com/cloud/jira/platform/deprecation-notice-user-privacy-api-migration-guide/) for details. Use `accountIds` instead.
	 * @uniqueItems true
	 */
	usernames?: string[]
}

export interface ServiceDeskDTO {
	/** REST API URL to the service desk. */
	_links?: SelfLinkDTO
	/** ID of the service desk. */
	id?: string
	/** ID of the peer project for the service desk. */
	projectId?: string
	/** Key of the peer project of the service desk. */
	projectKey?: string
	/** Name of the project and service desk. */
	projectName?: string
}

/** Details about the operations available in this version. */
export interface SimpleLink {
	href?: string
	iconClass?: string
	id?: string
	label?: string
	styleClass?: string
	title?: string
	/** @format int32 */
	weight?: number
}

export interface SlaInformationCompletedCycleDTO {
	/** Time and date at which the SLA cycle breached in case of completed breached cycle or would have breached in case of non-breached completed cycle. */
	breachTime?: DateDTO
	/** Indicates if the SLA (duration) was exceeded (true) or not (false). */
	breached?: boolean
	/** Duration in which the service was completed. */
	elapsedTime?: DurationDTO
	/** Duration within which the service should have been completed. */
	goalDuration?: DurationDTO
	/** Duration remaining after the service was completed. */
	remainingTime?: DurationDTO
	/** Time and date at which the SLA cycle started. */
	startTime?: DateDTO
	/** Time and date at which the SLA cycle completed. */
	stopTime?: DateDTO
}

export interface SlaInformationDTO {
	/** REST API URL for the SLA. */
	_links?: SelfLinkDTO
	/** List of completed cycles for the SLA. */
	completedCycles?: SlaInformationCompletedCycleDTO[]
	/** ID of the Service Level Agreement (SLA). */
	id?: string
	/** Description of the SLA. */
	name?: string
	/** Details of the active cycle for the SLA. */
	ongoingCycle?: SlaInformationOngoingCycleDTO
	/** Format in which SLA is to be displayed in the UI */
	slaDisplayFormat?: string
}

export interface SlaInformationOngoingCycleDTO {
	/** Time and date at which the SLA cycle would have breached its limit. */
	breachTime?: DateDTO
	/** Indicates whether the SLA has been breached (true) or not (false). */
	breached?: boolean
	/** Duration of the service. */
	elapsedTime?: DurationDTO
	/** Duration within which the service should be completed. */
	goalDuration?: DurationDTO
	/** Indicates whether the SLA is paused (true) or not (false). */
	paused?: boolean
	/** Duration remaining in which to complete the service. */
	remainingTime?: DurationDTO
	/** Time and date at which the SLA cycle started. */
	startTime?: DateDTO
	/** Indicates whether the SLA it timed during calendared working hours only (true) or not (false). */
	withinCalendarHours?: boolean
}

export interface SoftwareInfoDTO {
	/** REST API URL of the instance. */
	_links?: SelfLinkDTO
	/** Reference of the change set included in the build. */
	buildChangeSet?: string
	/** Date of the current build. */
	buildDate?: DateDTO
	/** Indicates whether the instance is licensed (true) or not (false). */
	isLicensedForUse?: boolean
	/** Jira Platform version upon which Service Desk is based. */
	platformVersion?: string
	/** Jira Service Management version. */
	version?: string
}

export interface SourceDTO {
	/** Type of the knowledge base source */
	type?: 'confluence'
	[key: string]: any
}

/** A status category. */
export interface StatusCategory {
	/** The name of the color used to represent the status category. */
	colorName?: string
	/**
	 * The ID of the status category.
	 * @format int64
	 */
	id?: number
	/** The key of the status category. */
	key?: string
	/** The name of the status category. */
	name?: string
	/** The URL of the status category. */
	self?: string
	[key: string]: any
}

/** A status. */
export interface StatusDetails {
	/** The description of the status. */
	description?: string
	/** The URL of the icon used to represent the status. */
	iconUrl?: string
	/** The ID of the status. */
	id?: string
	/** The name of the status. */
	name?: string
	/** The URL of the status. */
	self?: string
	/** The category assigned to the status. */
	statusCategory?: StatusCategory
	[key: string]: any
}

export interface UserDTO {
	/** URLs for the customer record and related items. */
	_links?: UserLinkDTO
	/** The accountId of the user, which uniquely identifies the user across all Atlassian products. For example, *5b10ac8d82e05b22cc7d4ef5*. */
	accountId?: string
	/** Indicates if the customer is active (true) or inactive (false) */
	active?: boolean
	/** Customer's name for display in a UI. Depending on the customer’s privacy settings, this may return an alternative value. */
	displayName?: string
	/** Customer's email address. Depending on the customer’s privacy settings, this may be returned as null. */
	emailAddress?: string
	/** This property is no longer available and will be removed from the documentation soon. See the [deprecation notice](https://developer.atlassian.com/cloud/jira/platform/deprecation-notice-user-privacy-api-migration-guide/) for details. */
	key?: string
	/** This property is no longer available and will be removed from the documentation soon. See the [deprecation notice](https://developer.atlassian.com/cloud/jira/platform/deprecation-notice-user-privacy-api-migration-guide/) for details. */
	name?: string
	/** Customer time zone. Depending on the customer’s privacy settings, this may be returned as null. */
	timeZone?: string
}

/**
 * User details permitted by the user's Atlassian Account privacy settings. However, be aware of these exceptions:
 *
 *  *  User record deleted from Atlassian: This occurs as the result of a right to be forgotten request. In this case, `displayName` provides an indication and other parameters have default values or are blank (for example, email is blank).
 *  *  User record corrupted: This occurs as a results of events such as a server import and can only happen to deleted users. In this case, `accountId` returns *unknown* and all other parameters have fallback values.
 *  *  User record unavailable: This usually occurs due to an internal service outage. In this case, all parameters have fallback values.
 */
export interface UserDetails {
	/**
	 * The account ID of the user, which uniquely identifies the user across all Atlassian products. For example, *5b10ac8d82e05b22cc7d4ef5*.
	 * @maxLength 128
	 */
	accountId?: string
	/** The type of account represented by this user. This will be one of 'atlassian' (normal users), 'app' (application user) or 'customer' (Jira Service Desk customer user) */
	accountType?: string
	/** Whether the user is active. */
	active?: boolean
	/** The avatars of the user. */
	avatarUrls?: AvatarUrlsBean
	/** The display name of the user. Depending on the user’s privacy settings, this may return an alternative value. */
	displayName?: string
	/** The email address of the user. Depending on the user’s privacy settings, this may be returned as null. */
	emailAddress?: string
	/** This property is no longer available and will be removed from the documentation soon. See the [deprecation notice](https://developer.atlassian.com/cloud/jira/platform/deprecation-notice-user-privacy-api-migration-guide/) for details. */
	key?: string
	/** This property is no longer available and will be removed from the documentation soon. See the [deprecation notice](https://developer.atlassian.com/cloud/jira/platform/deprecation-notice-user-privacy-api-migration-guide/) for details. */
	name?: string
	/** The URL of the user. */
	self?: string
	/** The time zone specified in the user's profile. Depending on the user’s privacy settings, this may be returned as null. */
	timeZone?: string
}

export interface UserLinkDTO {
	/** Links to the various sizes of the customer's avatar. Note that this property is deprecated, and will be removed in future versions. */
	avatarUrls?: Record<string, string>
	/**
	 * REST API URL for the customer.
	 * @format uri
	 */
	jiraRest?: string
	/** @format uri */
	self?: string
}

export interface UsersOrganizationUpdateDTO {
	/** List of customers, specific by account IDs, to add to or remove from the organization. */
	accountIds?: string[]
	/** This property is no longer available and will be removed from the documentation soon. See the [deprecation notice](https://developer.atlassian.com/cloud/jira/platform/deprecation-notice-user-privacy-api-migration-guide/) for details. Use `accountIds` instead. */
	usernames?: string[]
}

export interface PricingCampaignSettingsModel {
	product_assignments: ProductAssignmentModel
	/** @default {"engine":"ML","params":{}} */
	pricing_tactics:
		| DemandPricingTacticsModel
		| MarkdownPricingTacticsModel
		| RuleBasePricingTacticsModel
	minimal_steps: MinimalStepRuleModel[]
	price_limits: PriceLimitsModel
	rounding: any
	anchoring: any
}

/**
 * @uniqueItems true
 * @default []
 */
export type ProductAssignmentModel = {
	name: string
	filters: FilterModel[]
}[]

export interface MinimalStepRuleModel {
	/** @min 0 */
	min_price: number
	/** @min 0 */
	min_step_absolute: number
	/** @min 0 */
	min_step_relative: number
}

/** @default {} */
export interface PriceLimitsModel {
	/**
	 * @uniqueItems true
	 * @default [{"name":"last_price","value":-5,"type":"markup","on_violation":"correct"},{"name":"cost","value":4,"type":"markup","on_violation":"correct"}]
	 */
	lower: PriceLimitRuleModel[]
	/**
	 * @uniqueItems true
	 * @default [{"name":"last_price","value":5,"type":"markup","on_violation":"correct"}]
	 */
	upper: PriceLimitRuleModel[]
	/** @default {} */
	extra: {
		/** @default "no_reprice" */
		on_conflict: 'no_reprice' | 'lower' | 'upper'
	}
}

export interface PriceLimitRuleModel {
	name: string
	value: number
	/** @default "markup" */
	type?: 'markup' | 'margin' | 'absolute'
	/** @default "correct" */
	on_violation?: 'correct' | 'notify'
}

export interface MarkdownPricingTacticsModel {
	/** @default "MD" */
	engine: 'MD'
	params: {
		/**
		 * @uniqueItems false
		 * @default []
		 */
		periods: MarkdownPeriodModel[]
	}
}

export interface MarkdownPeriodModel {
	/** @format date */
	start_date: string
	target: {
		name: string
		value: number
	}
}

export interface RuleBasePricingTacticsModel {
	/** @default "RB" */
	engine: 'RB'
	params: {
		rule_params: object
		rule_id: string
	}
}

export interface DemandPricingTacticsModel {
	/** @default "ML" */
	engine: 'ML'
	/** @default {} */
	params: object
}

/** @default {"rules":[],"on_conflict":"ignore"} */
export interface AnchoringModel {
	/** @uniqueItems true */
	rules: AnchoringRuleModel[]
	on_conflict: 'ignore' | 'use_anchor_price'
}

export interface AnchoringRuleModel {
	anchoring:
		| 'flat_anchor'
		| 'sized_anchor'
		| 'product_set'
		| 'ladder_anchor'
		| 'bundle_anchor'
	high_range: number
	/** @minLength 1 */
	high_range_behavior: 'correct' | 'highlight'
	low_range: number
	/** @minLength 1 */
	low_range_behavior: 'correct' | 'highlight'
}

/** Mailing Domains */
export type UpdateMailingDomainsRequestModel = string[]

export interface LoginAuthLoginGetParams {
	/** Redirect Uri */
	redirect_uri: string
}

export interface AuthAuthAuthGetParams {
	/** Code */
	code: string
	/** Redirect Uri */
	redirect_uri: string
}

export interface GetPermissionsParams {
	/**
	 * Include Inner
	 * If to include inner permissions in the response
	 * @default true
	 */
	include_inner?: boolean
}

/** Preference Value */
export type SetCurrentUserPreferenceRequestModel = any

export interface ListAlertsRequestModel {
	search?: string
	filters?: FilterRuleModel[]
}

export interface ImportRelationsRequestModel {
	ignore_empties?: string
	/** @format binary */
	anchors?: File
}

export interface UpdateRelationsRequestModel {
	anchors?: AnchoringProductModel[]
}

export interface DeleteRelationsRequestModel {
	anchors?: {
		sku?: string
		sales_entity_id?: string
	}[]
}

export interface GetLastScenarioRunsParams {
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
}

/** Scenario Run Ids */
export type DeleteScenarioRunsRequestModel = string[]

export interface PricingCampaignListParams {
	/**
	 * Only Global
	 * If only global pricing campaigns are returned
	 * @default false
	 */
	only_global?: boolean | null
	/**
	 * Scenario Id
	 *
	 *         Scenario id for which to return pricing campaigns.
	 *         Always append global PCs.
	 *
	 */
	scenario_id?: string | null
	/**
	 * Optimization Group Id
	 *
	 *         Optimization group id for which return pricing campaigns.
	 *         Always append global PCs.
	 *
	 */
	optimization_group_id?: string | null
}

export interface ScenariosListParams {
	/** Optimization Group Id */
	optimization_group_id?: string | null
}

/** Scenario Ids */
export type DeleteScenariosBulkRequestModel = string[]

export interface ListInterpretationParams {
	/**
	 * Optimization Id
	 * @format uuid
	 */
	optimization_id: string
	/** optimization_group_id */
	optimization_group_id: string
	/** Line Id */
	line_id: string
	/** Locale */
	locale?: string
}

export interface ListInterpretationDataParams {
	/**
	 * Optimization Id
	 * @format uuid
	 */
	optimization_id: string
	/** optimization_group_id */
	optimization_group_id: string
	/** Line Id */
	line_id: string
}

export type GetMatchedProductsCountRequestModel = MatchedProductsRequestModel[]

export interface GetMatchedProductsCountParams {
	/**
	 * Optimization group id
	 * @format uuid
	 */
	optimization_group_id?: string
}

export interface ListCompetitiveDataParams {
	/** Limit */
	limit: number
	/** optimization_group_id */
	optimization_group_id: string
	/** Line id */
	line_id: string
	/** Sku */
	sku: string
	/** Order by */
	order_by: string
	/** Group by */
	group_by: string[]
	/** Observed on greater than */
	observed_on__gt: string
	/** Fields */
	fields: string[]
}

export interface ListPriceHistoryParams {
	/** Limit */
	limit: number
	/** optimization_group_id */
	optimization_group_id: string
	/** Line id */
	line_id: string
	/** Sku */
	sku: string
	/** Order by */
	order_by: string
	/** Group by */
	group_by: string[]
	/** Observed on greater than */
	observed_on__gt: string
	/** Fields */
	fields?: string[]
}

export interface ListCompetitorsParams {
	/** optimization_group_id */
	optimization_group_id?: string
	/** Order by field */
	order_by?: string
	/** Limit */
	limit: number
}

export interface GetStatisticsMultipleInterpretabilityParams {
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/** Optimization Ids */
	optimization_ids: string
}

export interface GetOptimizationsStatisticsParams {
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/** Optimization Ids */
	optimization_ids: string
}

export interface GetOptimizationPredictMetricsParams {
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/**
	 * Optimization Id
	 * @format uuid
	 */
	optimization_id: string
}

export interface GetOptimizationPricesParams {
	/**
	 * Optimization Group Id
	 * @format uuid
	 */
	optimization_group_id: string
	/**
	 * Optimization Id
	 * @format uuid
	 */
	optimization_id: string
}

export interface ListBrandsParams {
	/**
	 * Optimization group id
	 * @format uuid
	 */
	optimization_group_id?: string
}

export interface ListCategoriesParams {
	/**
	 * Optimization group id
	 * @format uuid
	 */
	optimization_group_id?: string
}

export interface MultiImportRequestModel {
	ignore_empties?: string
	optimization_groups?: string
	/** @format binary */
	product_diffs?: File
}

export type SendOptimizationActionsRequestModel = {
	action?: OptimizationAction
	/** @format uuid */
	optimization_id?: string
	/** @format uuid */
	optimization_group_id?: string
}[]

export interface GetOptimizationsParams {
	/** Optimization ids */
	optimization_ids: string
	/**
	 * Optimization group id
	 * @format uuid
	 */
	optimizationGroupId: string
}

export interface PostValidatorsRequestModel {
	/** @format binary */
	product_diffs: File
	ignore_empties?: string
}

export interface V1StarboardMetricsCreateRequestModel {
	filters?: FilterRuleModel[]
}

export interface GetCustomerRequestsParams {
	/** Filters customer requests where the request summary matches the `searchTerm`. [Wildcards](https://confluence.atlassian.com/display/JIRACORECLOUD/Search+syntax+for+text+fields) can be used in the `searchTerm` parameter. */
	searchTerm?: string
	/**
	 * Filters customer requests using the following values:
	 *
	 *  *  `OWNED_REQUESTS` returns customer requests where the user is the creator.
	 *  *  `PARTICIPATED_REQUESTS` returns customer requests where the user is a participant.
	 *  *  `ORGANIZATION` returns customer requests for an organization of which the user is a member when used in conjunction with `organizationId`.
	 *  *  `ALL_ORGANIZATIONS` returns customer requests that belong to all organizations of which the user is a member.
	 *  *  `APPROVER` returns customer requests where the user is an approver. Can be used in conjunction with `approvalStatus` to filter pending or complete approvals.
	 *  *  `ALL_REQUESTS` returns all customer requests. **Deprecated and will be removed, as the returned requests may change if more values are added in the future. Instead, explicitly list the desired filtering strategies.**
	 *
	 * Multiple values of the query parameter are supported. For example, `requestOwnership=OWNED_REQUESTS&requestOwnership=PARTICIPATED_REQUESTS` will only return customer requests where the user is the creator or a participant. If not specified, filtering defaults to `OWNED_REQUESTS`, `PARTICIPATED_REQUESTS`, and `ALL_ORGANIZATIONS`.
	 */
	requestOwnership?: string[]
	/**
	 * Filters customer requests where the request is closed, open, or either of the two where:
	 *
	 *  *  `CLOSED_REQUESTS` returns customer requests that are closed.
	 *  *  `OPEN_REQUESTS` returns customer requests that are open.
	 *  *  `ALL_REQUESTS` returns all customer requests.
	 */
	requestStatus?: string
	/**
	 * Filters results to customer requests based on their approval status:
	 *
	 *  *  `MY_PENDING_APPROVAL` returns customer requests pending the user's approval.
	 *  *  `MY_HISTORY_APPROVAL` returns customer requests where the user was an approver.
	 *
	 * **Note**: Valid only when used with requestOwnership=APPROVER.
	 */
	approvalStatus?: string
	/**
	 * Filters customer requests that belong to a specific organization (note that the user must be a member of that organization). **Note**: Valid only when used with requestOwnership=ORGANIZATION.
	 * @format int32
	 */
	organizationId?: number
	/**
	 * Filters customer requests by service desk.
	 * @format int32
	 */
	serviceDeskId?: number
	/**
	 * Filters customer requests by request type. Note that the `serviceDeskId` must be specified for the service desk in which the request type belongs.
	 * @format int32
	 */
	requestTypeId?: number
	/**
	 * A multi-value parameter indicating which properties of the customer request to expand, where:
	 *
	 *  *  `serviceDesk` returns additional details for each service desk.
	 *  *  `requestType` returns additional details for each request type.
	 *  *  `participant` returns the participant details, if any, for each customer request.
	 *  *  `sla` returns the SLA information on each customer request.
	 *  *  `status` returns the status transitions, in chronological order, for each customer request.
	 *  *  `attachment` returns the attachments for the customer request.
	 *  *  `action` returns the actions that the user can or cannot perform on this customer request.
	 *  *  `comment` returns the comments, if any, for each customer request.
	 *  *  `comment.attachment` returns the attachment details, if any, for each comment.
	 *  *  `comment.renderedBody` (Experimental) returns the rendered body in HTML format (in addition to the raw body) for each comment.
	 */
	expand?: string[]
	/**
	 * The starting index of the returned objects. Base index: 0. See the [Pagination](#pagination) section for more details.
	 * @format int32
	 */
	start?: number
	/**
	 * The maximum number of items to return per page. Default: 50. See the [Pagination](#pagination) section for more details.
	 * @format int32
	 */
	limit?: number
}

export interface GetAttachmentsForRequestParams {
	/**
	 * The starting index of the returned attachment. Base index: 0. See the [Pagination](#pagination) section for more details.
	 * @format int32
	 */
	start?: number
	/**
	 * The maximum number of comments to return per page. Default: 50. See the [Pagination](#pagination) section for more details.
	 * @format int32
	 */
	limit?: number
	/** The ID or key of the customer request from which the attachments will be listed. */
	issueIdOrKey: string
}

export interface GetAllRequestTypesParams {
	/** String to be used to filter the results. */
	searchQuery?: string
	/** Filter the request types by service desk Ids provided. Multiple values of the query parameter are supported. For example, `serviceDeskId=1&serviceDeskId=2` will return request types only for service desks 1 and 2. */
	serviceDeskId?: number[]
	/**
	 * The starting index of the returned objects. Base index: 0. See the [Pagination](#pagination) section for more details.
	 * @format int32
	 */
	start?: number
	/**
	 * The maximum number of items to return per page. Default: 50. See the [Pagination](#pagination) section for more details.
	 * @format int32
	 */
	limit?: number
	expand?: string[]
}

/** @format binary */
export type AttachTemporaryFileRequestModel = File

export interface GetCustomersParams {
	/** The string used to filter the customer list. */
	query?: string
	/**
	 * The starting index of the returned objects. Base index: 0. See the [Pagination](#pagination) section for more details.
	 * @format int32
	 */
	start?: number
	/**
	 * The maximum number of users to return per page. Default: 50. See the [Pagination](#pagination) section for more details.
	 * @format int32
	 */
	limit?: number
	/** The ID of the service desk the customer list should be returned from. This can alternatively be a [project identifier.](#project-identifiers) */
	serviceDeskId: string
}

export namespace Apps {
	/**
	 * No description
	 * @tags Apps
	 * @name GetApps
	 * @summary Get Apps
	 * @request GET:/api/v1/apps/
	 * @secure
	 * @response `200` `(AppModel)[]` Successful Response
	 */
	export namespace GetApps {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = AppModel[]
	}

	/**
	 * No description
	 * @tags Apps
	 * @name ActivateApp
	 * @summary Activate App
	 * @request POST:/api/v1/apps/{app_name}/activate/
	 * @secure
	 * @response `200` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ActivateApp {
		export type RequestParams = {
			/** App Name */
			appName: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags Apps
	 * @name DeactivateApp
	 * @summary Deactivate App
	 * @request POST:/api/v1/apps/{app_name}/deactivate/
	 * @secure
	 * @response `200` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace DeactivateApp {
		export type RequestParams = {
			/** App Name */
			appName: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags Apps
	 * @name GetHelpdeskOrganizationIdHandler
	 * @summary Get Helpdesk Organization Id Handler
	 * @request GET:/api/v1/apps/helpdesk-organization-id/
	 * @response `200` `string` Successful Response
	 */
	export namespace GetHelpdeskOrganizationIdHandler {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = string
	}

	/**
	 * No description
	 * @tags Apps
	 * @name GetMailingDomains
	 * @summary Get Mailing Domains
	 * @request GET:/api/v1/apps/mailing-domains/
	 * @response `200` `(string)[]` Successful Response
	 */
	export namespace GetMailingDomains {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = string[]
	}

	/**
	 * No description
	 * @tags Apps
	 * @name UpdateMailingDomains
	 * @summary Update Mailing Domains
	 * @request PUT:/api/v1/apps/mailing-domains/
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace UpdateMailingDomains {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = UpdateMailingDomainsRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

export namespace Auth {
	/**
	 * No description
	 * @tags Auth
	 * @name LoginAuthLoginGet
	 * @summary Login
	 * @request GET:/auth/login/
	 * @response `307` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace LoginAuthLoginGet {
		export type RequestParams = {}
		export type RequestQuery = {
			/** Redirect Uri */
			redirect_uri: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = any
	}

	/**
	 * No description
	 * @tags Auth
	 * @name AuthAuthAuthGet
	 * @summary Auth
	 * @request GET:/auth/auth/
	 * @response `200` `AccessTokenModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace AuthAuthAuthGet {
		export type RequestParams = {}
		export type RequestQuery = {
			/** Code */
			code: string
			/** Redirect Uri */
			redirect_uri: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = AccessTokenModel
	}

	/**
	 * No description
	 * @tags Auth
	 * @name RefreshTokenHandlerAuthRefreshTokenPost
	 * @summary Refresh Token Handler
	 * @request POST:/auth/refresh-token/
	 * @response `200` `AccessTokenModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace RefreshTokenHandlerAuthRefreshTokenPost {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = AccessTokenModel
	}

	/**
	 * No description
	 * @tags Auth
	 * @name LogoutAuthLogoutPost
	 * @summary Logout
	 * @request POST:/auth/logout/
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace LogoutAuthLogoutPost {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

export namespace Permissions {
	/**
	 * No description
	 * @tags Permissions
	 * @name GetPermissions
	 * @summary Get Permissions
	 * @request GET:/api/v1/permissions/
	 * @response `200` `(PermissionModel)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetPermissions {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Include Inner
			 * If to include inner permissions in the response
			 * @default true
			 */
			include_inner?: boolean
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PermissionModel[]
	}
}

export namespace Roles {
	/**
	 * No description
	 * @tags Roles
	 * @name GetRoles
	 * @summary Get Roles
	 * @request GET:/api/v1/roles/
	 * @secure
	 * @response `200` `(RoleModel)[]` Successful Response
	 */
	export namespace GetRoles {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = RoleModel[]
	}

	/**
	 * No description
	 * @tags Roles
	 * @name CreateRole
	 * @summary Create Role
	 * @request POST:/api/v1/roles/
	 * @secure
	 * @response `201` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace CreateRole {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = RoleDataModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags Roles
	 * @name GetRole
	 * @summary Get Role
	 * @request GET:/api/v1/roles/{role_id}/
	 * @response `200` `RoleModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetRole {
		export type RequestParams = {
			/**
			 * Role Id
			 * @format uuid
			 */
			roleId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = RoleModel
	}

	/**
	 * No description
	 * @tags Roles
	 * @name DeleteRole
	 * @summary Delete Role
	 * @request DELETE:/api/v1/roles/{role_id}/
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace DeleteRole {
		export type RequestParams = {
			/**
			 * Role Id
			 * @format uuid
			 */
			roleId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags Roles
	 * @name UpdateRole
	 * @summary Update Role
	 * @request PATCH:/api/v1/roles/{role_id}/
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace UpdateRole {
		export type RequestParams = {
			/**
			 * Role Id
			 * @format uuid
			 */
			roleId: string
		}
		export type RequestQuery = {}
		export type RequestBody = RoleUpdateDataModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

export namespace Users {
	/**
	 * No description
	 * @tags Users
	 * @name GetUsers
	 * @summary Get Users
	 * @request GET:/api/v1/users/
	 * @secure
	 * @response `200` `UsersModel` Successful Response
	 */
	export namespace GetUsers {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = UsersModel
	}

	/**
	 * No description
	 * @tags Users
	 * @name CreateUserHandler
	 * @summary Create User Handler
	 * @request POST:/api/v1/users/
	 * @secure
	 * @response `201` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace CreateUserHandler {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = UserDataModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags Users
	 * @name GetCurrentUser
	 * @summary Get Current User
	 * @request GET:/api/v1/users/current/
	 * @secure
	 * @response `200` `UserModel` Successful Response
	 */
	export namespace GetCurrentUser {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = UserModel
	}

	/**
	 * No description
	 * @tags Users
	 * @name UpdateCurrentUser
	 * @summary Update Current User
	 * @request PATCH:/api/v1/users/current/
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace UpdateCurrentUser {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = UserProfileDataModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags Users
	 * @name ResetCurrentUserPassword
	 * @summary Reset Current User Password
	 * @request PATCH:/api/v1/users/current/reset-password/
	 * @secure
	 * @response `204` `void` Successful Response
	 */
	export namespace ResetCurrentUserPassword {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags Users
	 * @name GetCurrentUserPreferences
	 * @summary Get Current User Preferences
	 * @request GET:/api/v1/users/current/preferences/
	 * @secure
	 * @response `200` `any` Successful Response
	 */
	export namespace GetCurrentUserPreferences {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = any
	}

	/**
	 * No description
	 * @tags Users
	 * @name GetCurrentUserPreference
	 * @summary Get Current User Preference
	 * @request GET:/api/v1/users/current/preferences/{preference_type}/
	 * @secure
	 * @response `200` `any` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetCurrentUserPreference {
		export type RequestParams = {
			/** Preference Type */
			preferenceType: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = any
	}

	/**
	 * No description
	 * @tags Users
	 * @name SetCurrentUserPreference
	 * @summary Set Current User Preference
	 * @request PATCH:/api/v1/users/current/preferences/{preference_type}/
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace SetCurrentUserPreference {
		export type RequestParams = {
			/** Preference Type */
			preferenceType: string
		}
		export type RequestQuery = {}
		export type RequestBody = SetCurrentUserPreferenceRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags Users
	 * @name GetUserHandler
	 * @summary Get User Handler
	 * @request GET:/api/v1/users/{user_id}/
	 * @secure
	 * @response `200` `UserModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetUserHandler {
		export type RequestParams = {
			/**
			 * User Id
			 * @format uuid
			 */
			userId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = UserModel
	}

	/**
	 * No description
	 * @tags Users
	 * @name DeleteUser
	 * @summary Delete User
	 * @request DELETE:/api/v1/users/{user_id}/
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace DeleteUser {
		export type RequestParams = {
			/**
			 * User Id
			 * @format uuid
			 */
			userId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags Users
	 * @name UpdateUser
	 * @summary Update User
	 * @request PATCH:/api/v1/users/{user_id}/
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace UpdateUser {
		export type RequestParams = {
			/**
			 * User Id
			 * @format uuid
			 */
			userId: string
		}
		export type RequestQuery = {}
		export type RequestBody = UserDataModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags Users
	 * @name ResetPassword
	 * @summary Reset Password
	 * @request PATCH:/api/v1/users/{user_id}/reset-password/
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ResetPassword {
		export type RequestParams = {
			/**
			 * User Id
			 * @format uuid
			 */
			userId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

export namespace Anchoring {
	/**
	 * @description Get list of all project products
	 * @tags anchoring
	 * @name ListRelations
	 * @request POST:/api/v1/anchors/
	 * @response `200` `AnchoringProductsResponseData` Successful Response
	 */
	export namespace ListRelations {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = AnchoringProductsRequestBody
		export type RequestHeaders = {}
		export type ResponseBody = AnchoringProductsResponseData
	}

	/**
	 * @description Get list alerts annotation
	 * @tags anchoring
	 * @name ListAlerts
	 * @request POST:/api/v1/anchors/alerts-info/query
	 * @response `200` `(AnchoringAlertModel)[]` Successful Response
	 */
	export namespace ListAlerts {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ListAlertsRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = AnchoringAlertModel[]
	}

	/**
	 * @description Get list alerts
	 * @tags anchoring
	 * @name ListAlertsAnnotation
	 * @request GET:/api/v1/anchors/alerts
	 * @response `200` `(AlertAnnotationModel)[]` Successful Response
	 */
	export namespace ListAlertsAnnotation {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = AlertAnnotationModel[]
	}

	/**
	 * @description Import from file
	 * @tags anchoring
	 * @name ImportRelations
	 * @request POST:/api/v1/anchors/import/
	 * @response `200` `ImportResultResponseData` Successful Response
	 * @response `400` `ImportResultResponseError` Error Response
	 */
	export namespace ImportRelations {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ImportRelationsRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = ImportResultResponseData
	}

	/**
	 * @description Update
	 * @tags anchoring
	 * @name UpdateRelations
	 * @request POST:/api/v1/anchors/upsert
	 * @response `200` `AnchorsUpdateResultResponseData` Successful Response
	 */
	export namespace UpdateRelations {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = UpdateRelationsRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = AnchorsUpdateResultResponseData
	}

	/**
	 * @description Delete anchors
	 * @tags anchoring
	 * @name DeleteRelations
	 * @request POST:/api/v1/anchors/delete/
	 * @response `200` `AnchorsDeleteResultResponseData` Successful Response
	 */
	export namespace DeleteRelations {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = DeleteRelationsRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = AnchorsDeleteResultResponseData
	}
}

export namespace Export {
	/**
	 * No description
	 * @tags export
	 * @name ListExports
	 * @summary List Exports
	 * @request GET:/api/v1/exports
	 * @response `200` `(ExportModel)[]` Successful Response
	 */
	export namespace ListExports {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ExportModel[]
	}

	/**
	 * No description
	 * @tags export
	 * @name CreateExport
	 * @summary Create Export
	 * @request POST:/api/v1/exports
	 * @response `201` `ExportModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace CreateExport {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ExportCreateModel
		export type RequestHeaders = {}
		export type ResponseBody = ExportModel
	}

	/**
	 * No description
	 * @tags export
	 * @name DownloadExportFile
	 * @summary Download Export File
	 * @request GET:/api/v1/exports/{export_id}/download
	 * @response `200` `any` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace DownloadExportFile {
		export type RequestParams = {
			/**
			 * Export Id
			 * @format uuid
			 */
			exportId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = any
	}

	/**
	 * No description
	 * @tags export
	 * @name DeleteExport
	 * @summary Delete Export
	 * @request DELETE:/api/v1/exports/{export_id}
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace DeleteExport {
		export type RequestParams = {
			/**
			 * Export Id
			 * @format uuid
			 */
			exportId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags export
	 * @name GetExportTemplates
	 * @summary Get Export Templates
	 * @request GET:/api/v1/exports/templates
	 * @response `200` `(ExportTemplateModel)[]` Successful Response
	 */
	export namespace GetExportTemplates {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ExportTemplateModel[]
	}
}

export namespace Assistant {
	/**
	 * No description
	 * @tags assistant
	 * @name AssistantChatSettingsGet
	 * @summary Assistant-Chat-Settings-Get
	 * @request GET:/api/v1/assistant/chat/settings
	 * @secure
	 * @response `200` `ChatSettingsResponseModel` Successful Response
	 */
	export namespace AssistantChatSettingsGet {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ChatSettingsResponseModel
	}

	/**
	 * No description
	 * @tags assistant
	 * @name AssistantChatMessagePost
	 * @summary Assistant-Chat-Message-Post
	 * @request POST:/api/v1/assistant/chat/message
	 * @secure
	 * @response `200` `ChatMessageResponseModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace AssistantChatMessagePost {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ChatMessageRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = ChatMessageResponseModel
	}

	/**
	 * No description
	 * @tags assistant
	 * @name AssistantChatReactionPost
	 * @summary Assistant-Chat-Reaction-Post
	 * @request POST:/api/v1/assistant/chat/reaction
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace AssistantChatReactionPost {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ChatMessageReactionRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

export namespace RepricingScenarioRuns {
	/**
	 * No description
	 * @tags Repricing Scenario Runs
	 * @name GetLastScenarioRuns
	 * @summary Get-Last-Scenario-Runs
	 * @request GET:/api/v1/scenario-runs
	 * @response `200` `(ScenarioRunSetModel | null)` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetLastScenarioRuns {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimization_group_id: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ScenarioRunSetModel | null
	}

	/**
	 * No description
	 * @tags Repricing Scenario Runs
	 * @name StartScenarioRuns
	 * @summary Start-Scenario-Runs
	 * @request POST:/api/v1/scenario-runs
	 * @response `201` `ScenarioRunSetModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace StartScenarioRuns {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ScenarioRunRequestModel
		export type RequestHeaders = {
			/** Auth-User-Id */
			'auth-user-id': string
		}
		export type ResponseBody = ScenarioRunSetModel
	}

	/**
	 * No description
	 * @tags Repricing Scenario Runs
	 * @name RestartScenarioRuns
	 * @summary Restart-Scenario-Runs
	 * @request POST:/api/v1/scenario-runs/restart
	 * @response `200` `ScenarioRunSetModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace RestartScenarioRuns {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ScenarioRunRestartRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = ScenarioRunSetModel
	}

	/**
	 * No description
	 * @tags Repricing Scenario Runs
	 * @name DeleteScenarioRuns
	 * @summary Delete-Scenario-Runs
	 * @request POST:/api/v1/scenario-runs/delete
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace DeleteScenarioRuns {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = DeleteScenarioRunsRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

export namespace PricingCampaign {
	/**
	 * @description List pricing campaign resources
	 * @tags pricing-campaign
	 * @name PricingCampaignList
	 * @summary List pricing campaigns
	 * @request GET:/api/v1/pricing-campaigns
	 * @secure
	 * @response `200` `(PricingCampaignModel)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignList {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Only Global
			 * If only global pricing campaigns are returned
			 * @default false
			 */
			only_global?: boolean | null
			/**
			 * Scenario Id
			 *
			 *         Scenario id for which to return pricing campaigns.
			 *         Always append global PCs.
			 *
			 */
			scenario_id?: string | null
			/**
			 * Optimization Group Id
			 *
			 *         Optimization group id for which return pricing campaigns.
			 *         Always append global PCs.
			 *
			 */
			optimization_group_id?: string | null
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PricingCampaignModel[]
	}

	/**
	 * @description Create pricing campaign resource. Combination of name and scenario_id must be unique. If `filter` is specified, products for that filter are added to the newlycreated global PC, but not local PC. They need to be added to local PC with `assign_product` separately.
	 * @tags pricing-campaign
	 * @name PricingCampaignCreate
	 * @summary Create pricing campaign
	 * @request POST:/api/v1/pricing-campaigns
	 * @secure
	 * @response `201` `PricingCampaignModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignCreate {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = PricingCampaignCreateDataModel
		export type RequestHeaders = {}
		export type ResponseBody = PricingCampaignModel
	}

	/**
	 * @description Get pricing campaign resource
	 * @tags pricing-campaign
	 * @name PricingCampaignGet
	 * @summary Get pricing campaign
	 * @request GET:/api/v1/pricing-campaigns/{pricing_campaign_id}
	 * @secure
	 * @response `200` `PricingCampaignModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignGet {
		export type RequestParams = {
			/**
			 * Pricing Campaign Id
			 * @format uuid
			 */
			pricingCampaignId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PricingCampaignModel
	}

	/**
	 * @description Update pricing campaign resource.
	 * @tags pricing-campaign
	 * @name PricingCampaignUpdate
	 * @summary Update pricing campaign
	 * @request PATCH:/api/v1/pricing-campaigns/{pricing_campaign_id}
	 * @secure
	 * @response `200` `PricingCampaignModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignUpdate {
		export type RequestParams = {
			/**
			 * Pricing Campaign Id
			 * @format uuid
			 */
			pricingCampaignId: string
		}
		export type RequestQuery = {}
		export type RequestBody = PricingCampaignUpdateDataModel
		export type RequestHeaders = {}
		export type ResponseBody = PricingCampaignModel
	}

	/**
	 * @description Delete pricing campaign resource
	 * @tags pricing-campaign
	 * @name PricingCampaignDelete
	 * @summary Delete pricing campaign
	 * @request DELETE:/api/v1/pricing-campaigns/{pricing_campaign_id}
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignDelete {
		export type RequestParams = {
			/**
			 * Pricing Campaign Id
			 * @format uuid
			 */
			pricingCampaignId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * @description Assign products to pricing campaign by filters. Products that were assigned previously, remain assigned. The pricing campaign can be global or local, but not dynamic.
	 * @tags pricing-campaign
	 * @name PricingCampaignAssignProducts
	 * @summary Assign products to pricing campaign by filters
	 * @request PATCH:/api/v1/pricing-campaigns/{pricing_campaign_id}/assign-products
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignAssignProducts {
		export type RequestParams = {
			/**
			 * Pricing Campaign Id
			 * @format uuid
			 */
			pricingCampaignId: string
		}
		export type RequestQuery = {}
		export type RequestBody = PricingCampaignProductsFilters
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * @description Unassign products from pricing campaigns by filters. Products that meet the filter criteria are unassigned from pricing campaigns in optimization groups to which the user has access.
	 * @tags pricing-campaign
	 * @name PricingCampaignUnassignProducts
	 * @summary Unassign products from pricing campaigns by filters
	 * @request PATCH:/api/v1/pricing-campaigns/unassign-products
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignUnassignProducts {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = PricingCampaignProductsFilters
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * @description Reorder pricing campaign resources
	 * @tags pricing-campaign
	 * @name PricingCampaignReorder
	 * @summary Reorder pricing campaigns
	 * @request POST:/api/v1/pricing-campaigns/reorder
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignReorder {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = PricingCampaignReorderRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * @description Duplicate pricing campaign resources
	 * @tags pricing-campaign
	 * @name PricingCampaignsDuplicate
	 * @summary Duplicate pricing campaigns
	 * @request POST:/api/v1/pricing-campaigns/duplicate
	 * @secure
	 * @response `200` `any` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignsDuplicate {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = PricingCampaignDuplicateRequest
		export type RequestHeaders = {}
		export type ResponseBody = any
	}

	/**
	 * @description Duplicate pricing campaign resources to scenarios
	 * @tags pricing-campaign
	 * @name PricingCampaignsDuplicateToScenarios
	 * @summary Duplicate pricing campaigns to scenarios
	 * @request POST:/api/v1/pricing-campaigns/duplicate-to-scenarios
	 * @secure
	 * @response `200` `any` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignsDuplicateToScenarios {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = PricingCampaignDuplicateScenariosRequest
		export type RequestHeaders = {}
		export type ResponseBody = any
	}

	/**
	 * @description Reassign pricing campaign resources
	 * @tags pricing-campaign
	 * @name PricingCampaignsReassign
	 * @summary Reassign pricing campaigns
	 * @request POST:/api/v1/pricing-campaigns/reassign
	 * @secure
	 * @response `200` `any` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignsReassign {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = PricingCampaignReassignRequest
		export type RequestHeaders = {}
		export type ResponseBody = any
	}

	/**
	 * @description Copy settings pricing campaign resources
	 * @tags pricing-campaign
	 * @name PricingCampaignsCopySettings
	 * @summary Copy settings pricing campaigns
	 * @request POST:/api/v1/pricing-campaigns/copy-settings
	 * @secure
	 * @response `200` `(PricingCampaignCopySettingsResponseModel)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace PricingCampaignsCopySettings {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = PricingCampaignCopySettingsRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = PricingCampaignCopySettingsResponseModel[]
	}
}

export namespace Scenario {
	/**
	 * @description List scenario resources
	 * @tags scenario
	 * @name ScenariosList
	 * @summary List scenarios
	 * @request GET:/api/v1/scenarios
	 * @secure
	 * @response `200` `(ScenarioModel)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ScenariosList {
		export type RequestParams = {}
		export type RequestQuery = {
			/** Optimization Group Id */
			optimization_group_id?: string | null
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ScenarioModel[]
	}

	/**
	 * @description Create scenario resource
	 * @tags scenario
	 * @name ScenarioCreate
	 * @summary Create scenario
	 * @request POST:/api/v1/scenarios
	 * @secure
	 * @response `201` `ScenarioModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ScenarioCreate {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ScenarioCreateDataModel
		export type RequestHeaders = {}
		export type ResponseBody = ScenarioModel
	}

	/**
	 * @description Get scenario resource
	 * @tags scenario
	 * @name ScenarioGet
	 * @summary Get scenario
	 * @request GET:/api/v1/scenarios/{scenario_id}
	 * @secure
	 * @response `200` `ScenarioModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ScenarioGet {
		export type RequestParams = {
			/**
			 * Scenario Id
			 * @format uuid
			 */
			scenarioId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ScenarioModel
	}

	/**
	 * @description Update scenario resource
	 * @tags scenario
	 * @name ScenarioUpdate
	 * @summary Update scenario
	 * @request PATCH:/api/v1/scenarios/{scenario_id}
	 * @secure
	 * @response `200` `ScenarioModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ScenarioUpdate {
		export type RequestParams = {
			/**
			 * Scenario Id
			 * @format uuid
			 */
			scenarioId: string
		}
		export type RequestQuery = {}
		export type RequestBody = ScenarioUpdateDataModel
		export type RequestHeaders = {}
		export type ResponseBody = ScenarioModel
	}

	/**
	 * @description Get main scenario resource
	 * @tags scenario
	 * @name ScenarioGetMain
	 * @summary Get main scenario
	 * @request GET:/api/v1/scenarios/main/{optimization_group_id}
	 * @secure
	 * @response `200` `ScenarioModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ScenarioGetMain {
		export type RequestParams = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ScenarioModel
	}

	/**
	 * @description Update main scenario resource
	 * @tags scenario
	 * @name ScenarioUpdateMain
	 * @summary Update main scenario
	 * @request PATCH:/api/v1/scenarios/main/{optimization_group_id}
	 * @secure
	 * @response `200` `ScenarioModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ScenarioUpdateMain {
		export type RequestParams = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = ScenarioUpdateDataModel
		export type RequestHeaders = {}
		export type ResponseBody = ScenarioModel
	}

	/**
	 * @description Delete scenario resources
	 * @tags scenario
	 * @name DeleteScenariosBulk
	 * @summary Delete scenarios
	 * @request POST:/api/v1/scenarios/delete
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace DeleteScenariosBulk {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = DeleteScenariosBulkRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * @description Duplicate scenario resource
	 * @tags scenario
	 * @name ScenarioDuplicate
	 * @summary Duplicate scenario
	 * @request POST:/api/v1/scenarios/{scenario_id}/duplicate
	 * @secure
	 * @response `200` `ScenarioModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ScenarioDuplicate {
		export type RequestParams = {
			/**
			 * Scenario Id
			 * @format uuid
			 */
			scenarioId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ScenarioModel
	}

	/**
	 * @description Set main scenario resource
	 * @tags scenario
	 * @name ScenarioSetMain
	 * @summary Set main scenario
	 * @request POST:/api/v1/scenarios/{scenario_id}/set-main
	 * @secure
	 * @response `200` `ScenarioModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ScenarioSetMain {
		export type RequestParams = {
			/**
			 * Scenario Id
			 * @format uuid
			 */
			scenarioId: string
		}
		export type RequestQuery = {}
		export type RequestBody = ScenarioSetMainDataModel
		export type RequestHeaders = {}
		export type ResponseBody = ScenarioModel
	}

	/**
	 * @description Main scenario copy settings resource
	 * @tags scenario
	 * @name ScenarioMainCopySettings
	 * @summary Main scenario copy settings
	 * @request POST:/api/v1/scenarios/main/{optimization_group_id}/copy-settings
	 * @secure
	 * @response `200` `any` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ScenarioMainCopySettings {
		export type RequestParams = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = ScenarioMainCopySettings
		export type RequestHeaders = {}
		export type ResponseBody = any
	}
}

export namespace SettingsTemplate {
	/**
	 * @description Query settings-template resource
	 * @tags settings-template
	 * @name SettingsTemplateQuery
	 * @summary Query settings-template
	 * @request POST:/api/v1/settings-templates/query
	 * @response `200` `SettingsTemplateQueryResponseModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace SettingsTemplateQuery {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = SettingsTemplateQueryModel
		export type RequestHeaders = {}
		export type ResponseBody = SettingsTemplateQueryResponseModel
	}

	/**
	 * @description Get settings-template resource
	 * @tags settings-template
	 * @name SettingsTemplateGet
	 * @summary Get settings-template
	 * @request GET:/api/v1/settings-templates/{settings_template_id}
	 * @response `200` `SettingsTemplateModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace SettingsTemplateGet {
		export type RequestParams = {
			/**
			 * Settings Template Id
			 * @format uuid
			 */
			settingsTemplateId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = SettingsTemplateModel
	}

	/**
	 * @description Update settings-template resource
	 * @tags settings-template
	 * @name SettingsTemplateUpdate
	 * @summary Update settings-template
	 * @request PATCH:/api/v1/settings-templates/{settings_template_id}
	 * @secure
	 * @response `200` `SettingsTemplateModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace SettingsTemplateUpdate {
		export type RequestParams = {
			/**
			 * Settings Template Id
			 * @format uuid
			 */
			settingsTemplateId: string
		}
		export type RequestQuery = {}
		export type RequestBody = SettingsTemplateUpdateModel
		export type RequestHeaders = {}
		export type ResponseBody = SettingsTemplateModel
	}

	/**
	 * @description Delete settings-template resource
	 * @tags settings-template
	 * @name SettingsTemplateDelete
	 * @summary Delete settings-template
	 * @request DELETE:/api/v1/settings-templates/{settings_template_id}
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace SettingsTemplateDelete {
		export type RequestParams = {
			/**
			 * Settings Template Id
			 * @format uuid
			 */
			settingsTemplateId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * @description Create settings-template resource
	 * @tags settings-template
	 * @name SettingsTemplateCreate
	 * @summary Create settings-template
	 * @request POST:/api/v1/settings-templates
	 * @secure
	 * @response `201` `SettingsTemplateModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace SettingsTemplateCreate {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = SettingsTemplateCreateModel
		export type RequestHeaders = {}
		export type ResponseBody = SettingsTemplateModel
	}

	/**
	 * @description Apply settings-template resource
	 * @tags settings-template
	 * @name SettingsTemplateApply
	 * @summary Apply settings-template
	 * @request POST:/api/v1/settings-templates/{settings_template_id}/apply
	 * @secure
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace SettingsTemplateApply {
		export type RequestParams = {
			/**
			 * Settings Template Id
			 * @format uuid
			 */
			settingsTemplateId: string
		}
		export type RequestQuery = {}
		export type RequestBody = SettingsTemplateApplyRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

export namespace Api {
	/**
	 * No description
	 * @name ListReviews
	 * @summary List-Reviews
	 * @request GET:/api/v1/reviews
	 * @response `200` `ListReviewResponse` Successful Response
	 */
	export namespace ListReviews {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ListReviewResponse
	}

	/**
	 * No description
	 * @name CreateReview
	 * @summary Create-Review
	 * @request POST:/api/v1/reviews/optimization_groups/{optimization_group_id}/
	 * @secure
	 * @response `201` `ReviewModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace CreateReview {
		export type RequestParams = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = CreateReviewModel
		export type RequestHeaders = {}
		export type ResponseBody = ReviewModel
	}

	/**
	 * No description
	 * @name DeleteReview
	 * @summary Delete-Review
	 * @request DELETE:/api/v1/reviews/optimization_groups/{optimization_group_id}/
	 * @response `204` `void` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace DeleteReview {
		export type RequestParams = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @name UpdateReview
	 * @summary Update-Review
	 * @request PATCH:/api/v1/reviews/optimization_groups/{optimization_group_id}/
	 * @secure
	 * @response `200` `ReviewModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace UpdateReview {
		export type RequestParams = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = UpdateReviewModel
		export type RequestHeaders = {}
		export type ResponseBody = ReviewModel
	}

	/**
	 * No description
	 * @name ApproveReview
	 * @summary Approve-Review
	 * @request POST:/api/v1/reviews/approve/optimization_groups/{optimization_group_id}/
	 * @secure
	 * @response `200` `ReviewModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ApproveReview {
		export type RequestParams = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ReviewModel
	}

	/**
	 * No description
	 * @name ListInterpretation
	 * @summary List Interpretation
	 * @request GET:/api/v1/repricing-interpretation/
	 * @response `200` `(object)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ListInterpretation {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Optimization Id
			 * @format uuid
			 */
			optimization_id: string
			/** optimization_group_id */
			optimization_group_id: string
			/** Line Id */
			line_id: string
			/** Locale */
			locale?: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = object[]
	}

	/**
	 * No description
	 * @name ListInterpretationData
	 * @summary List Interpretation Data
	 * @request GET:/api/v1/repricing-interpretation-data/
	 * @response `200` `BusinessSettingsModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ListInterpretationData {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Optimization Id
			 * @format uuid
			 */
			optimization_id: string
			/** optimization_group_id */
			optimization_group_id: string
			/** Line Id */
			line_id: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = BusinessSettingsModel
	}

	/**
	 * No description
	 * @name ListSchedule
	 * @summary List Schedule
	 * @request GET:/api/v1/schedules/
	 * @response `200` `(ScheduleModel)[]` Successful Response
	 */
	export namespace ListSchedule {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ScheduleModel[]
	}

	/**
	 * No description
	 * @name CreateSchedule
	 * @summary Create Schedule
	 * @request POST:/api/v1/schedules/
	 * @response `201` `ScheduleModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace CreateSchedule {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = CreateScheduleModel
		export type RequestHeaders = {}
		export type ResponseBody = ScheduleModel
	}

	/**
	 * No description
	 * @name GetSchedule
	 * @summary Get Schedule
	 * @request GET:/api/v1/schedules/{schedule_id}
	 * @response `200` `ScheduleModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetSchedule {
		export type RequestParams = {
			/**
			 * Schedule Id
			 * @format uuid
			 */
			scheduleId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ScheduleModel
	}

	/**
	 * No description
	 * @name DeleteSchedule
	 * @summary Delete Schedule
	 * @request DELETE:/api/v1/schedules/{schedule_id}
	 * @response `200` `any` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace DeleteSchedule {
		export type RequestParams = {
			/**
			 * Schedule Id
			 * @format uuid
			 */
			scheduleId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = any
	}

	/**
	 * No description
	 * @name UpdateSchedule
	 * @summary Update Schedule
	 * @request PATCH:/api/v1/schedules/{schedule_id}
	 * @response `200` `ScheduleModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace UpdateSchedule {
		export type RequestParams = {
			/**
			 * Schedule Id
			 * @format uuid
			 */
			scheduleId: string
		}
		export type RequestQuery = {}
		export type RequestBody = UpdateScheduleModel
		export type RequestHeaders = {}
		export type ResponseBody = ScheduleModel
	}

	/**
	 * @description Attention:<br/>    If pass >2000 optimization groups ids then nginx send `414 Request URI Too Large`<br/>
	 * @name ExportTemplate
	 * @summary Download products report for specified export template
	 * @request GET:/api/v1/export/{export_template_id}/
	 * @response `200` `void` Successful Response
	 */
	export namespace ExportTemplate {
		export type RequestParams = {
			/**
			 * export template id
			 * @format uuid
			 */
			exportTemplateId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * @description Filters related to user and optimization_group<br/>
	 * @name ListUserOgFilters
	 * @summary Get user filters
	 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/filters/
	 * @response `200` `void` Successful Response
	 */
	export namespace ListUserOgFilters {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @name CreateUserOgFilter
	 * @summary Add new user filter
	 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/filters/
	 * @response `200` `void` Successful Response
	 */
	export namespace CreateUserOgFilter {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = SavedFilterModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @name DeleteUserOgFilter
	 * @summary Delete filter by id
	 * @request DELETE:/api/v1/optimization_groups/{optimization_group_id}/filters/{filter_id}/
	 * @response `200` `void` Successful Response
	 */
	export namespace DeleteUserOgFilter {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
			filterId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @name UpdateUserOgFilter
	 * @summary Update filter by id
	 * @request PATCH:/api/v1/optimization_groups/{optimization_group_id}/filters/{filter_id}/
	 * @response `200` `void` Successful Response
	 */
	export namespace UpdateUserOgFilter {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
			filterId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

export namespace Bi {
	/**
 * No description
 * @tags bi
 * @name GetProductsCountByEngine
 * @summary Get products count by engine
 * @request POST:/api/v1/products-count-by-engine/
 * @response `200` `{
    data?: (ProductsCountByEngineModel)[],

}` Successful Response
*/
	export namespace GetProductsCountByEngine {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ProductCountByEngineRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = {
			data?: ProductsCountByEngineModel[]
		}
	}

	/**
	 * No description
	 * @tags bi
	 * @name ListMetrics
	 * @summary List-Metrics
	 * @request GET:/api/v1/metrics/
	 * @response `200` `(MetricModel)[]` Successful Response
	 */
	export namespace ListMetrics {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = MetricModel[]
	}

	/**
	 * No description
	 * @tags bi
	 * @name BiRevision
	 * @summary Bi-Revision
	 * @request GET:/api/v1/bi-revision/
	 * @response `200` `(BIRevision | null)` Successful Response
	 */
	export namespace BiRevision {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = BIRevision | null
	}

	/**
	 * No description
	 * @tags bi
	 * @name BiFields
	 * @summary Bi-Fields
	 * @request GET:/api/v1/bi/fields
	 * @response `200` `(object)[]` Successful Response
	 */
	export namespace BiFields {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = object[]
	}

	/**
	 * No description
	 * @tags bi
	 * @name BiFieldsValues
	 * @summary Bi-Fields-Values
	 * @request GET:/api/v1/bi/fields/{bi_field}/values
	 * @response `200` `((string)[] | null)` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace BiFieldsValues {
		export type RequestParams = {
			/** Bi Field */
			biField: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = string[] | null
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetBi
	 * @summary Get-Bi
	 * @request POST:/api/v1/bi/
	 * @secure
	 * @response `200` `(BIResponse | null)` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetBi {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = BIRequest
		export type RequestHeaders = {}
		export type ResponseBody = BIResponse | null
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetBiRevenue
	 * @summary Get-Bi-Revenue
	 * @request POST:/api/v1/bi-revenue/
	 * @secure
	 * @response `200` `BIRevenueResponse` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetBiRevenue {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = BIRevenueRequest
		export type RequestHeaders = {}
		export type ResponseBody = BIRevenueResponse
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetBiAi
	 * @summary Get-Bi-Ai
	 * @request POST:/api/v1/bi-ai/
	 * @secure
	 * @response `200` `BIAIResponse` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetBiAi {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = BIAIRequest
		export type RequestHeaders = {}
		export type ResponseBody = BIAIResponse
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetOgTreeMapBySalesEntities
	 * @summary Get-Og-Tree-Map-By-Sales-Entities
	 * @request POST:/api/v1/optimization-groups-tree-map/by-sales-entities
	 * @secure
	 * @response `200` `(TreeMapSalesEntityModel)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetOgTreeMapBySalesEntities {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = TreeMapRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = TreeMapSalesEntityModel[]
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetOgTreeMapByProductGroups
	 * @summary Get-Og-Tree-Map-By-Product-Groups
	 * @request POST:/api/v1/optimization-groups-tree-map/by-product-groups
	 * @secure
	 * @response `200` `(TreeMapProductGroupModel)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetOgTreeMapByProductGroups {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = TreeMapRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = TreeMapProductGroupModel[]
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetStatistics
	 * @summary Get-Statistics
	 * @request POST:/api/v1/statistics
	 * @secure
	 * @response `200` `StatisticsResponse` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetStatistics {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = StatisticsRequest
		export type RequestHeaders = {}
		export type ResponseBody = StatisticsResponse
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetStatisticsRepricing
	 * @summary Get-Statistics-Repricing
	 * @request POST:/api/v1/statistics/repricing
	 * @response `200` `(StatisticsRepricingResponse | null)` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetStatisticsRepricing {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = StatisticsRepricingRequest
		export type RequestHeaders = {
			/** Auth-User-Id */
			'auth-user-id': string
		}
		export type ResponseBody = StatisticsRepricingResponse | null
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetStatisticsInterpretability
	 * @summary Get-Statistics-Interpretability
	 * @request POST:/api/v1/statistics/interpretability
	 * @response `200` `(StatisticsInterpretabilityResponse | null)` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetStatisticsInterpretability {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = StatisticsInterpretabilityRequest
		export type RequestHeaders = {
			/** Auth-User-Id */
			'auth-user-id': string
		}
		export type ResponseBody = StatisticsInterpretabilityResponse | null
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetStatisticsTargetProtectDistribution
	 * @summary Get-Statistics-Target-Protect-Distribution
	 * @request POST:/api/v1/statistics/target-protect-distribution
	 * @response `200` `((TargetProtectDistributionModel)[] | null)` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetStatisticsTargetProtectDistribution {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = StatisticsTargetProtectDistributionRequest
		export type RequestHeaders = {
			/** Auth-User-Id */
			'auth-user-id': string
		}
		export type ResponseBody = TargetProtectDistributionModel[] | null
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetStatisticsMultipleInterpretability
	 * @summary Get-Statistics-Multiple-Interpretability
	 * @request GET:/api/v1/statistics/multiple-interpretability
	 * @secure
	 * @response `200` `(StatisticsInterpretabilityResponse)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetStatisticsMultipleInterpretability {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimization_group_id: string
			/** Optimization Ids */
			optimization_ids: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = StatisticsInterpretabilityResponse[]
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetOptimizationsStatistics
	 * @summary Get-Optimizations-Statistics
	 * @request GET:/api/v1/statistics/optimizations-statistics
	 * @secure
	 * @response `200` `(OptimizationStatisticModel)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetOptimizationsStatistics {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimization_group_id: string
			/** Optimization Ids */
			optimization_ids: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = OptimizationStatisticModel[]
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetAlertsInfo
	 * @summary Get-Alerts-Info
	 * @request POST:/api/v1/statistics/alerts-info
	 * @secure
	 * @response `200` `AlertsInfoResponseModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetAlertsInfo {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = AlertInfoRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = AlertsInfoResponseModel
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetOptimizationPredictMetrics
	 * @summary Get-Optimization-Predict-Metrics
	 * @request GET:/api/v1/statistics-internal/optimization-metrics
	 * @response `200` `(PredictMetricModel)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetOptimizationPredictMetrics {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimization_group_id: string
			/**
			 * Optimization Id
			 * @format uuid
			 */
			optimization_id: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PredictMetricModel[]
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetOptimizationPrices
	 * @summary Get-Optimization-Prices
	 * @request GET:/api/v1/statistics-internal/optimization-prices
	 * @response `200` `(OptimizationPricesModel)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetOptimizationPrices {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Optimization Group Id
			 * @format uuid
			 */
			optimization_group_id: string
			/**
			 * Optimization Id
			 * @format uuid
			 */
			optimization_id: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = OptimizationPricesModel[]
	}

	/**
	 * No description
	 * @tags bi
	 * @name GetMultipleAlertsInfo
	 * @summary Get-Multiple-Alerts-Info
	 * @request POST:/api/v1/statistics/multiple-alerts-info
	 * @response `200` `MultipleAlertsInfoResponseModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetMultipleAlertsInfo {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = MultipleAlertInfoRequestModel
		export type RequestHeaders = {
			/** Auth-User-Id */
			'auth-user-id': string
		}
		export type ResponseBody = MultipleAlertsInfoResponseModel
	}
}

export namespace Util {
	/**
	 * No description
	 * @tags util
	 * @name GetMatchedProductsCount
	 * @summary Get matched products count by pc
	 * @request POST:/api/v1/matched-products/
	 * @response `200` `MatchedProductsResponseModel` Successful Response
	 */
	export namespace GetMatchedProductsCount {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimization_group_id?: string
		}
		export type RequestBody = GetMatchedProductsCountRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = MatchedProductsResponseModel
	}

	/**
	 * No description
	 * @tags util
	 * @name GetImportTemplate
	 * @summary Get import template file for current project
	 * @request GET:/api/v1/import_template/
	 * @response `200` `void` Successful Response
	 */
	export namespace GetImportTemplate {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * @description Depends on project custom_fields schema<br/>product_diffs field is required<br/>return parsed product_diffs or list of errors<br/>
	 * @tags util
	 * @name PostValidators
	 * @summary Parse and validate csv, xls, xlsx input.
	 * @request POST:/api/v1/validators/
	 * @response `200` `DiffValidationResponseModel` Successful Response
	 */
	export namespace PostValidators {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = PostValidatorsRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = DiffValidationResponseModel
	}

	/**
	 * @description Get list of counts by og_id or pc_id
	 * @tags util
	 * @name GetProductsCountsByPc
	 * @request POST:/api/v1/products-count-by-pc/
	 * @response `200` `(PcProductsCountsModel)[]` Successful Response
	 */
	export namespace GetProductsCountsByPc {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = CountByFiltersRequestBody
		export type RequestHeaders = {}
		export type ResponseBody = PcProductsCountsModel[]
	}

	/**
	 * @description Get list of counts by og_id
	 * @tags util
	 * @name GetProductsCountsByOg
	 * @request POST:/api/v1/products-count-by-og/
	 * @response `200` `ProductsCountsByOgResponseData` Successful Response
	 */
	export namespace GetProductsCountsByOg {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = CountByFiltersRequestBody
		export type RequestHeaders = {}
		export type ResponseBody = ProductsCountsByOgResponseData
	}
}

export namespace Core {
	/**
	 * No description
	 * @tags core
	 * @name GetPreprocessing
	 * @summary Get preprocessing
	 * @request GET:/api/v1/preprocessing/
	 * @response `200` `PreprocessingModel` Successful Response
	 */
	export namespace GetPreprocessing {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PreprocessingModel
	}

	/**
	 * No description
	 * @tags core
	 * @name ListAlertsAnnotation
	 * @summary Get list of product alerts in project
	 * @request GET:/api/v1/alerts/
	 * @response `200` `(AlertAnnotationModel)[]` List of object with {id, name, description, level} fields
	 */
	export namespace ListAlertsAnnotation {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = AlertAnnotationModel[]
	}

	/**
 * No description
 * @tags core
 * @name ListBrands
 * @summary Get brands
 * @request GET:/api/v1/brands/
 * @response `200` `{
    success?: boolean,
    brands?: (BrandModel)[],

}` Successful Response
*/
	export namespace ListBrands {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimization_group_id?: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			brands?: BrandModel[]
		}
	}

	/**
 * No description
 * @tags core
 * @name ListCategoriesFull
 * @summary Get full categories for product groups
 * @request GET:/api/v1/categories-full/
 * @response `200` `{
    success?: boolean,
    categories?: (CategoryModel)[],

}` Successful Response
*/
	export namespace ListCategoriesFull {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			categories?: CategoryModel[]
		}
	}

	/**
 * No description
 * @tags core
 * @name ListCategories
 * @summary Get categories
 * @request GET:/api/v1/categories/
 * @response `200` `{
    success?: boolean,
    categories?: (CategoryModel)[],

}` Successful Response
*/
	export namespace ListCategories {
		export type RequestParams = {}
		export type RequestQuery = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimization_group_id?: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			categories?: CategoryModel[]
		}
	}

	/**
	 * @description <br/>Maybe add all export options (bq, file, etc.) into this endpoint in the future<br/>
	 * @tags core
	 * @name MassExportTemplates
	 * @summary List export templates for export.
	 * @request GET:/api/v1/export/
	 * @response `200` `void` Successful Response
	 */
	export namespace MassExportTemplates {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
 * No description
 * @tags core
 * @name MassExport
 * @summary Export all unlocked optimization_groups to bigquery customer-project.Entry.final_prices
 * @request POST:/api/v1/export/
 * @response `200` `{
    objects?: (ExportTemplateModel1)[],

}` Successful Response
*/
	export namespace MassExport {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			objects?: ExportTemplateModel1[]
		}
	}

	/**
 * @description Allowed file formats are csv, xlsx, xls<br/>Depends on project custom_fields schema<br/>product_diffs field is required<br/><br/>
 * @tags core
 * @name MultiImport
 * @summary Parse, validate and apply file input to list of optimization groups.
 * @request POST:/api/v1/multi_import/
 * @response `200` `{
    results?: Record<string,{
    optimization?: OptimizationModel,
    success?: boolean,

}>,
    counts?: Record<string,number>,
    success?: boolean,

}` Successful Response
*/
	export namespace MultiImport {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = MultiImportRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = {
			results?: Record<
				string,
				{
					optimization?: OptimizationModel
					success?: boolean
				}
			>
			counts?: Record<string, number>
			success?: boolean
		}
	}

	/**
 * @description <br/>.. code-block:: python<br/><br/>    [{"action": "lock", "optimization_id": <uuid>, "optimization_group_id": <uuid>}]<br/>
 * @tags core
 * @name SendOptimizationActions
 * @summary Actions: start, stop, lock, unlock, delete_export, update
 * @request POST:/api/v1/optimization-actions/
 * @response `200` `{
    results?: Record<string,{
    optimization?: OptimizationModel,
    success?: boolean,

}>,
    success?: boolean,

}` Successful Response
*/
	export namespace SendOptimizationActions {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = SendOptimizationActionsRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = {
			results?: Record<
				string,
				{
					optimization?: OptimizationModel
					success?: boolean
				}
			>
			success?: boolean
		}
	}

	/**
 * No description
 * @tags core
 * @name ListOptimizationGroups
 * @summary Get list of optimization_groups or optimization_group by id
 * @request GET:/api/v1/optimization_groups/
 * @response `200` `{
    success?: boolean,
    optimization_groups?: (OptimizationGroupModel)[],

}` Successful Response
*/
	export namespace ListOptimizationGroups {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			optimization_groups?: OptimizationGroupModel[]
		}
	}

	/**
	 * No description
	 * @tags core
	 * @name CreateOptimizationGroup
	 * @summary Create optiomzation group
	 * @request POST:/api/v1/optimization_groups/
	 * @response `200` `OptimizationGroupModel` Successful Response
	 */
	export namespace CreateOptimizationGroup {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = CreateOptimizationGroupInput
		export type RequestHeaders = {}
		export type ResponseBody = OptimizationGroupModel
	}

	/**
 * No description
 * @tags core
 * @name ListOptimizations
 * @summary Get Optimizations List
 * @request GET:/api/v1/optimization_groups/optimizations/
 * @response `200` `{
    success?: boolean,
    optimizations?: Record<string,OptimizationModel>,

}` Successful Response
*/
	export namespace ListOptimizations {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			optimizations?: Record<string, OptimizationModel>
		}
	}

	/**
	 * No description
	 * @tags core
	 * @name GetOptimizationGroup
	 * @summary Get list of optimization_groups or optimization_group by id
	 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/
	 * @response `200` `OptimizationGroupModel` Successful Response
	 */
	export namespace GetOptimizationGroup {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = OptimizationGroupModel
	}

	/**
	 * No description
	 * @tags core
	 * @name UpdateOptimizationGroup
	 * @summary Update optimization group
	 * @request PATCH:/api/v1/optimization_groups/{optimization_group_id}/
	 * @response `200` `OptimizationGroupModel` Successful Response
	 */
	export namespace UpdateOptimizationGroup {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = UpdateOptimizationGroupInput
		export type RequestHeaders = {}
		export type ResponseBody = OptimizationGroupModel
	}

	/**
	 * No description
	 * @tags core
	 * @name DeleteOptimizationGroup
	 * @summary Delete optimization group
	 * @request DELETE:/api/v1/optimization_groups/{optimization_group_id}/
	 * @response `200` `void` Successful Response
	 */
	export namespace DeleteOptimizationGroup {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
 * No description
 * @tags core
 * @name ListOptimizationGroupBrands
 * @summary Get brands
 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/brands/
 * @response `200` `{
    success?: boolean,
    brands?: (BrandModel)[],

}` Successful Response
*/
	export namespace ListOptimizationGroupBrands {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			brands?: BrandModel[]
		}
	}

	/**
 * No description
 * @tags core
 * @name ListOptimizationGroupCategories
 * @summary Get categories
 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/categories/
 * @response `200` `{
    success?: boolean,
    categories?: (CategoryModel)[],

}` Successful Response
*/
	export namespace ListOptimizationGroupCategories {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			categories?: CategoryModel[]
		}
	}

	/**
 * No description
 * @tags core
 * @name CreateOptimization
 * @summary Create new optimization
 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/
 * @response `200` `{
    optimization: OptimizationModel,

}` Successful Response
*/
	export namespace CreateOptimization {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			optimization: OptimizationModel
		}
	}

	/**
 * No description
 * @tags core
 * @name GetOptimizations
 * @summary Get Optimizations info by ids
 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/optimizations/
 * @response `200` `{
    optimizations?: (OptimizationModel)[],

}` Successful Response
*/
	export namespace GetOptimizations {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {
			/** Optimization ids */
			optimization_ids: string
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			optimizations?: OptimizationModel[]
		}
	}

	/**
	 * No description
	 * @tags core
	 * @name GetOptimization
	 * @summary Get Optimization info
	 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/
	 * @response `200` `OptimizationModel` Successful Response
	 */
	export namespace GetOptimization {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
			/**
			 * Optimization id
			 * @format uuid
			 */
			optimizationId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = OptimizationModel
	}

	/**
 * No description
 * @tags core
 * @name DoOptimizationAction
 * @summary Actions: start, stop, lock, unlock, delete_export, update
 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/{action}/
 * @response `200` `{
    optimization: OptimizationModel,
    success?: boolean,

}` Successful Response
*/
	export namespace DoOptimizationAction {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
			/**
			 * Optimization id
			 * @format uuid
			 */
			optimizationId: string
			action: OptimizationAction
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			optimization: OptimizationModel
			success?: boolean
		}
	}

	/**
	 * @description Target OG optimization status is validated (if it is not 'New', view is aborted)<br/><br/>If selected, optimization group settings are copied (stop list, business)<br/><br/>If selected, all pricing campaigns from source optimization group are copied,<br/>with all pricing campaigns in target OG being deleted and products moved to 'New products' PC<br/>New pricing campaigns are created, with engine settings identical to the ones from source OG<br/><br/>If selected, default pricing campaign property is copied<br/>If selected, products are assigned to same PCs as in source OG, if they exist in target OG<br/>If selected, repricing limits and minimal repricing step settings are copied<br/>
	 * @tags core
	 * @name SettingsOgCopy
	 * @summary Upon calling such endpoint, optimization_group_id and json body are passed
	 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/settings-copy/
	 * @response `200` `void` Successful Response
	 */
	export namespace SettingsOgCopy {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = OptimizationCopySchema
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
 * No description
 * @tags core
 * @name GetStopListMatchedProductsCounts
 * @summary Match optimization group products to stop-list rules
 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/settings/stop_list/matched-products
 * @response `200` `{
    products?: number,
    pricing_lines?: number,

}` Successful Response
*/
	export namespace GetStopListMatchedProductsCounts {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = StopListSettingsModel
		export type RequestHeaders = {}
		export type ResponseBody = {
			products?: number
			pricing_lines?: number
		}
	}

	/**
	 * No description
	 * @tags core
	 * @name ListProductGroups
	 * @summary Get list of product groups in project
	 * @request GET:/api/v1/product-groups/
	 * @response `200` `(ProductGroupModel)[]` List of object with {id, name} fields
	 */
	export namespace ListProductGroups {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = ProductGroupModel[]
	}

	/**
 * No description
 * @tags core
 * @name ListRoundingRules
 * @summary Get available rounding rules.
 * @request GET:/api/v1/rounding_rules/
 * @response `200` `{
    objects?: (RoundingRuleModel)[],

}` Successful Response
*/
	export namespace ListRoundingRules {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			objects?: RoundingRuleModel[]
		}
	}

	/**
 * @description types: int, float, bool, str, datetime<br/>
 * @tags core
 * @name ListColumnSchemas
 * @summary Get custom fields schema for project
 * @request GET:/api/v1/schemas/
 * @response `200` `{
    success?: boolean,
    schema?: (ColumnSchemaModel)[],

}` Successful Response
*/
	export namespace ListColumnSchemas {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			schema?: ColumnSchemaModel[]
		}
	}

	/**
 * No description
 * @tags core
 * @name ListSettingsSchemas
 * @summary Get settings JSON schemas
 * @request GET:/api/v1/settings-schemas
 * @response `200` `({
    $id?: string,
    type?: string,
    properties?: Record<string,any>,
    [key: string]: any,

})[]` Successful Response
*/
	export namespace ListSettingsSchemas {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			$id?: string
			type?: string
			properties?: Record<string, any>
			[key: string]: any
		}[]
	}

	/**
	 * No description
	 * @tags core
	 * @name ListUiSchemas
	 * @summary Get ui schemas for repricing rules
	 * @request GET:/api/v1/ui-schemas
	 * @response `200` `(PricinRuleUiSchemaModel)[]` Successful Response
	 */
	export namespace ListUiSchemas {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PricinRuleUiSchemaModel[]
	}

	/**
	 * No description
	 * @tags core
	 * @name GetSalesEntities
	 * @summary Get sales entities and levels
	 * @request GET:/api/v1/sales-entities/
	 * @response `200` `SalesEntitiesResponseData` Sales entities and levels
	 */
	export namespace GetSalesEntities {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = SalesEntitiesResponseData
	}
}

export namespace PromoCampaigns {
	/**
	 * No description
	 * @tags promo-campaigns
	 * @name ListPromoCampaigns
	 * @summary List Promo Campaigns
	 * @request GET:/api/v1/promo-campaigns/
	 * @response `200` `(PromoCampaignModel)[]` Successful Response
	 */
	export namespace ListPromoCampaigns {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PromoCampaignModel[]
	}

	/**
	 * No description
	 * @tags promo-campaigns
	 * @name CreatePromoCampaign
	 * @summary Create Promo Campaign
	 * @request POST:/api/v1/promo-campaigns/
	 * @response `201` `PromoCampaignModel` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace CreatePromoCampaign {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = PromoCampaignCreateModel
		export type RequestHeaders = {
			/** Auth-User-Id */
			'auth-user-id': string
		}
		export type ResponseBody = PromoCampaignModel
	}

	/**
	 * No description
	 * @tags promo-campaigns
	 * @name GetPromoCampaign
	 * @summary Get Promo Campaign
	 * @request GET:/api/v1/promo-campaigns/{promo_campaign_id}/
	 * @response `200` `PromoCampaignModel` Successful Response
	 * @response `404` `ErrorModel` Not Found
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace GetPromoCampaign {
		export type RequestParams = {
			/** Promo Campaign Id */
			promoCampaignId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PromoCampaignModel
	}

	/**
	 * No description
	 * @tags promo-campaigns
	 * @name UpdatePromoCampaign
	 * @summary Update Promo Campaign
	 * @request PATCH:/api/v1/promo-campaigns/{promo_campaign_id}/
	 * @response `200` `PromoCampaignModel` Successful Response
	 * @response `403` `ErrorModel` Forbidden
	 * @response `404` `ErrorModel` Not Found
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace UpdatePromoCampaign {
		export type RequestParams = {
			/** Promo Campaign Id */
			promoCampaignId: string
		}
		export type RequestQuery = {}
		export type RequestBody = PromoCampaignUpdateModel
		export type RequestHeaders = {
			/** Auth-User-Id */
			'auth-user-id': string
		}
		export type ResponseBody = PromoCampaignModel
	}

	/**
	 * No description
	 * @tags promo-campaigns
	 * @name DeletePromoCampaign
	 * @summary Delete Promo Campaign
	 * @request DELETE:/api/v1/promo-campaigns/{promo_campaign_id}/
	 * @response `204` `void` Successful Response
	 * @response `403` `ErrorModel` Forbidden
	 * @response `404` `ErrorModel` Not Found
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace DeletePromoCampaign {
		export type RequestParams = {
			/** Promo Campaign Id */
			promoCampaignId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags promo-campaigns
	 * @name ListPromoCampaignProducts
	 * @summary List Promo Campaign Products
	 * @request GET:/api/v1/promo-campaigns/{promo_campaign_id}/products/
	 * @response `200` `(PromoProductModel)[]` Successful Response
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace ListPromoCampaignProducts {
		export type RequestParams = {
			/** Promo Campaign Id */
			promoCampaignId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PromoProductModel[]
	}

	/**
	 * No description
	 * @tags promo-campaigns
	 * @name UploadPromoCampaignProducts
	 * @summary Upload Promo Campaign Products
	 * @request POST:/api/v1/promo-campaigns/{promo_campaign_id}/products/
	 * @response `201` `any` Successful Response
	 * @response `400` `UploadPromoProductsErrorModel` Bad Request
	 * @response `403` `ErrorModel` Forbidden
	 * @response `404` `ErrorModel` Not Found
	 * @response `422` `HTTPValidationError` Validation Error
	 */
	export namespace UploadPromoCampaignProducts {
		export type RequestParams = {
			/** Promo Campaign Id */
			promoCampaignId: string
		}
		export type RequestQuery = {}
		export type RequestBody =
			BodyUploadPromoCampaignProductsApiV1PromoCampaignsPromoCampaignIdProductsPost
		export type RequestHeaders = {
			/** Auth-User-Id */
			'auth-user-id': string
		}
		export type ResponseBody = any
	}
}

export namespace Healthcheck {
	/**
	 * No description
	 * @tags Healthcheck
	 * @name DoHealthCheckHealthGet
	 * @summary  Do Health Check
	 * @request GET:/health
	 * @response `200` `any` Successful Response
	 */
	export namespace DoHealthCheckHealthGet {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = any
	}
}

export namespace Cd {
	/**
	 * No description
	 * @tags cd
	 * @name ListCompetitiveData
	 * @summary Get competitive data
	 * @request GET:/api/v1/competitive-data/
	 * @response `200` `CompetitiveDataResponseModel` Successful Response
	 */
	export namespace ListCompetitiveData {
		export type RequestParams = {}
		export type RequestQuery = {
			/** Limit */
			limit: number
			/** optimization_group_id */
			optimization_group_id: string
			/** Line id */
			line_id: string
			/** Sku */
			sku: string
			/** Order by */
			order_by: string
			/** Group by */
			group_by: string[]
			/** Observed on greater than */
			observed_on__gt: string
			/** Fields */
			fields: string[]
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = CompetitiveDataResponseModel
	}

	/**
	 * No description
	 * @tags cd
	 * @name ListPriceHistory
	 * @summary Get price history
	 * @request GET:/api/v1/price-history/
	 * @response `200` `PriceHistoryResponseModel` Successful Response
	 */
	export namespace ListPriceHistory {
		export type RequestParams = {}
		export type RequestQuery = {
			/** Limit */
			limit: number
			/** optimization_group_id */
			optimization_group_id: string
			/** Line id */
			line_id: string
			/** Sku */
			sku: string
			/** Order by */
			order_by: string
			/** Group by */
			group_by: string[]
			/** Observed on greater than */
			observed_on__gt: string
			/** Fields */
			fields?: string[]
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PriceHistoryResponseModel
	}

	/**
	 * No description
	 * @tags cd
	 * @name ListCompetitors
	 * @summary Get competitors
	 * @request GET:/api/v1/competitors/
	 * @response `200` `CompetitorsResponseModel` Successful Response
	 */
	export namespace ListCompetitors {
		export type RequestParams = {}
		export type RequestQuery = {
			/** optimization_group_id */
			optimization_group_id?: string
			/** Order by field */
			order_by?: string
			/** Limit */
			limit: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = CompetitorsResponseModel
	}
}

export namespace PrometheusMetrics {
	/**
	 * No description
	 * @tags Prometheus metrics
	 * @name PrometheusMetricsMetricsGet
	 * @summary Prometheus-Metrics
	 * @request GET:/metrics
	 * @response `200` `any` Successful Response
	 */
	export namespace PrometheusMetricsMetricsGet {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = any
	}
}

export namespace SavedFilters {
	/**
 * No description
 * @tags saved-filters
 * @name ListGlobalFilters
 * @summary Get project global filters
 * @request GET:/api/v1/global_filters/
 * @response `200` `{
    success?: boolean,
    filters?: (SavedFilterModel)[],

}` Successful Response
*/
	export namespace ListGlobalFilters {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			filters?: SavedFilterModel[]
		}
	}

	/**
 * No description
 * @tags saved-filters
 * @name CreateGlobalFilter
 * @summary Add new project global filter
 * @request POST:/api/v1/global_filters/
 * @response `201` `{
    success?: boolean,
    filter_id?: string,

}` Successful Response
*/
	export namespace CreateGlobalFilter {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = SavedFilterCreateModel
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			filter_id?: string
		}
	}

	/**
 * No description
 * @tags saved-filters
 * @name DeleteGlobalFilter
 * @summary Delete filter by id
 * @request DELETE:/api/v1/global_filters/{filter_id}/
 * @response `200` `{
    success?: boolean,

}` Successful Response
*/
	export namespace DeleteGlobalFilter {
		export type RequestParams = {
			filterId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
		}
	}

	/**
	 * No description
	 * @tags saved-filters
	 * @name UpdateGlobalFilter
	 * @summary Update filter by id
	 * @request PATCH:/api/v1/global_filters/{filter_id}/
	 * @response `204` `void` Successful Response
	 */
	export namespace UpdateGlobalFilter {
		export type RequestParams = {
			filterId: string
		}
		export type RequestQuery = {}
		export type RequestBody = SavedFilterUpdateModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
 * No description
 * @tags saved-filters
 * @name ListUserFilters
 * @summary Get user filters
 * @request GET:/api/v1/user_filters/
 * @response `200` `{
    success?: boolean,
    filters?: (SavedFilterModel)[],

}` Successful Response
*/
	export namespace ListUserFilters {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			filters?: SavedFilterModel[]
		}
	}

	/**
 * No description
 * @tags saved-filters
 * @name CreateUserFilter
 * @summary Add new user filter
 * @request POST:/api/v1/user_filters/
 * @response `201` `{
    success?: boolean,
    filter_id?: string,

}` Successful Response
*/
	export namespace CreateUserFilter {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = SavedFilterCreateModel
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			filter_id?: string
		}
	}

	/**
 * No description
 * @tags saved-filters
 * @name DeleteUserFilter
 * @summary Delete filter by id
 * @request DELETE:/api/v1/user_filters/{filter_id}/
 * @response `200` `{
    success?: boolean,

}` Successful Response
*/
	export namespace DeleteUserFilter {
		export type RequestParams = {
			filterId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
		}
	}

	/**
	 * No description
	 * @tags saved-filters
	 * @name UpdateUserFilter
	 * @summary Update filter by id
	 * @request PATCH:/api/v1/user_filters/{filter_id}/
	 * @response `204` `void` Successful Response
	 */
	export namespace UpdateUserFilter {
		export type RequestParams = {
			filterId: string
		}
		export type RequestQuery = {}
		export type RequestBody = SavedFilterUpdateModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

export namespace Analytics {
	/**
	 * No description
	 * @tags analytics
	 * @name GetApplicanceAnalytics
	 * @summary Price appliance in the last repricing periods
	 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/appliance_analytics/
	 * @response `200` `void` Successful Response
	 */
	export namespace GetApplicanceAnalytics {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * No description
	 * @tags analytics
	 * @name GetOptimizationAnalytics
	 * @summary Dynamic data on repricing distribution in the last repricing periods
	 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/optimization_analytics/
	 * @response `200` `OptimizationAnalyticsResponseModel` Successful Response
	 */
	export namespace GetOptimizationAnalytics {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = OptimizationAnalyticsResponseModel
	}
}

export namespace Products {
	/**
	 * No description
	 * @tags products
	 * @name UpdateFinalFields
	 * @summary Update optimization final-fields
	 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/final_fields/
	 * @response `200` `UpdateProductsFinalFieldsResponseModel` Successful Response
	 * @response `400` `UpdateProductsFinalFieldsErrorResponseModel` Successful Response
	 */
	export namespace UpdateFinalFields {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
			/**
			 * Optimization id
			 * @format uuid
			 */
			optimizationId: string
		}
		export type RequestQuery = {}
		export type RequestBody = FinalFieldsListSchema
		export type RequestHeaders = {}
		export type ResponseBody = UpdateProductsFinalFieldsResponseModel
	}

	/**
	 * No description
	 * @tags products
	 * @name UpdateProducsModelFieldsByFilters
	 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/product_updates
	 * @response `200` `UpdateProductsFileldsByFiltersResponseModel` Successful Response
	 */
	export namespace UpdateProducsModelFieldsByFilters {
		export type RequestParams = {
			optimizationGroupId: string
			optimizationId: string
		}
		export type RequestQuery = {}
		export type RequestBody = UpdateProductsFieldsByFiltersRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = UpdateProductsFileldsByFiltersResponseModel
	}

	/**
	 * No description
	 * @tags products
	 * @name UpdateProducsFinalFieldsByFilters
	 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/final-fields-updates
	 * @response `200` `UpdateProductsFileldsByFiltersResponseModel` Successful Response
	 */
	export namespace UpdateProducsFinalFieldsByFilters {
		export type RequestParams = {
			optimizationGroupId: string
			optimizationId: string
		}
		export type RequestQuery = {}
		export type RequestBody = UpdateProductsFieldsByFiltersRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = UpdateProductsFileldsByFiltersResponseModel
	}

	/**
	 * No description
	 * @tags products
	 * @name UpdateProductDiffs
	 * @summary Update optimization final-fields
	 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/product_diffs/
	 * @response `200` `UpdateProductsModelFieldsResponseModel` Successful Response
	 * @response `400` `UpdateProductsModelFieldsErrorResponseModel` Successful Response
	 */
	export namespace UpdateProductDiffs {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
			/**
			 * Optimization id
			 * @format uuid
			 */
			optimizationId: string
		}
		export type RequestQuery = {}
		export type RequestBody = ProductDiffsSchema
		export type RequestHeaders = {}
		export type ResponseBody = UpdateProductsModelFieldsResponseModel
	}

	/**
 * No description
 * @tags products
 * @name ListOgProducts
 * @summary Download products table
 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/products/
 * @response `200` `{
    success?: boolean,
    data?: (PricingLineModel)[],

}` Successful Response
*/
	export namespace ListOgProducts {
		export type RequestParams = {
			/**
			 * Optimization group id
			 * @format uuid
			 */
			optimizationGroupId: string
		}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
			data?: PricingLineModel[]
		}
	}

	/**
	 * @description Get list of all project products
	 * @tags products
	 * @name ListProducts
	 * @request POST:/api/v1/products/query
	 * @response `200` `ProductsQueryResponseModel` Successful Response
	 */
	export namespace ListProducts {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ProductsQueryRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = ProductsQueryResponseModel
	}

	/**
	 * @description Get list of all project products
	 * @tags products
	 * @name ExportProducts
	 * @request POST:/api/v1/products/export
	 * @response `200` `ProductsExportResponseModel` Successful Response
	 */
	export namespace ExportProducts {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = ProductsExportRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = ProductsExportResponseModel
	}
}

export namespace Starboard {
	/**
 * No description
 * @tags starboard
 * @name V1StarboardMetricsCreate
 * @request POST:/api/v1/starboard-metrics/
 * @response `200` `{
  \** @default 0 *\
    gross_profit_init?: number,
  \** @default 0 *\
    gross_profit_opt?: number,
  \** @default 0 *\
    product_count?: number,
  \** @default 0 *\
    profit_margin_init?: number,
  \** @default 0 *\
    revenue_init?: number,
  \** @default 0 *\
    revenue_opt?: number,
  \** @default 0 *\
    sales_quantity?: number,
  \** @default 0 *\
    sold_items_cost?: number,

}` Successful Response
*/
	export namespace V1StarboardMetricsCreate {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = V1StarboardMetricsCreateRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = {
			/** @default 0 */
			gross_profit_init?: number
			/** @default 0 */
			gross_profit_opt?: number
			/** @default 0 */
			product_count?: number
			/** @default 0 */
			profit_margin_init?: number
			/** @default 0 */
			revenue_init?: number
			/** @default 0 */
			revenue_opt?: number
			/** @default 0 */
			sales_quantity?: number
			/** @default 0 */
			sold_items_cost?: number
		}
	}
}

export namespace Jsd {
	/**
	 * @description This method returns all customer requests for the user executing the query. The returned customer requests are ordered chronologically by the latest activity on each request. For example, the latest status transition or comment. **[Permissions](#permissions) required**: Permission to access the specified service desk. **Response limitations**: For customers, the list returned will include request they created (or were created on their behalf) or are participating in only.
	 * @tags jsd
	 * @name GetCustomerRequests
	 * @summary Get customer requests
	 * @request GET:/jira-rest/servicedeskapi/request
	 * @secure
	 * @response `200` `PagedDTOCustomerRequestDTO` Returns the customer requests, on the specified page of the results.
	 * @response `401` `ErrorResponse` Returned if the user is not logged in.
	 * @response `404` `ErrorResponse` Returned if the user does not have permission to access the service desk, the service desk does not exist, or the service desk does not support the request type.
	 * @response `500` `ErrorResponse` Internal Server Error.
	 */
	export namespace GetCustomerRequests {
		export type RequestParams = {}
		export type RequestQuery = {
			/** Filters customer requests where the request summary matches the `searchTerm`. [Wildcards](https://confluence.atlassian.com/display/JIRACORECLOUD/Search+syntax+for+text+fields) can be used in the `searchTerm` parameter. */
			searchTerm?: string
			/**
			 * Filters customer requests using the following values:
			 *
			 *  *  `OWNED_REQUESTS` returns customer requests where the user is the creator.
			 *  *  `PARTICIPATED_REQUESTS` returns customer requests where the user is a participant.
			 *  *  `ORGANIZATION` returns customer requests for an organization of which the user is a member when used in conjunction with `organizationId`.
			 *  *  `ALL_ORGANIZATIONS` returns customer requests that belong to all organizations of which the user is a member.
			 *  *  `APPROVER` returns customer requests where the user is an approver. Can be used in conjunction with `approvalStatus` to filter pending or complete approvals.
			 *  *  `ALL_REQUESTS` returns all customer requests. **Deprecated and will be removed, as the returned requests may change if more values are added in the future. Instead, explicitly list the desired filtering strategies.**
			 *
			 * Multiple values of the query parameter are supported. For example, `requestOwnership=OWNED_REQUESTS&requestOwnership=PARTICIPATED_REQUESTS` will only return customer requests where the user is the creator or a participant. If not specified, filtering defaults to `OWNED_REQUESTS`, `PARTICIPATED_REQUESTS`, and `ALL_ORGANIZATIONS`.
			 */
			requestOwnership?: string[]
			/**
			 * Filters customer requests where the request is closed, open, or either of the two where:
			 *
			 *  *  `CLOSED_REQUESTS` returns customer requests that are closed.
			 *  *  `OPEN_REQUESTS` returns customer requests that are open.
			 *  *  `ALL_REQUESTS` returns all customer requests.
			 */
			requestStatus?: string
			/**
			 * Filters results to customer requests based on their approval status:
			 *
			 *  *  `MY_PENDING_APPROVAL` returns customer requests pending the user's approval.
			 *  *  `MY_HISTORY_APPROVAL` returns customer requests where the user was an approver.
			 *
			 * **Note**: Valid only when used with requestOwnership=APPROVER.
			 */
			approvalStatus?: string
			/**
			 * Filters customer requests that belong to a specific organization (note that the user must be a member of that organization). **Note**: Valid only when used with requestOwnership=ORGANIZATION.
			 * @format int32
			 */
			organizationId?: number
			/**
			 * Filters customer requests by service desk.
			 * @format int32
			 */
			serviceDeskId?: number
			/**
			 * Filters customer requests by request type. Note that the `serviceDeskId` must be specified for the service desk in which the request type belongs.
			 * @format int32
			 */
			requestTypeId?: number
			/**
			 * A multi-value parameter indicating which properties of the customer request to expand, where:
			 *
			 *  *  `serviceDesk` returns additional details for each service desk.
			 *  *  `requestType` returns additional details for each request type.
			 *  *  `participant` returns the participant details, if any, for each customer request.
			 *  *  `sla` returns the SLA information on each customer request.
			 *  *  `status` returns the status transitions, in chronological order, for each customer request.
			 *  *  `attachment` returns the attachments for the customer request.
			 *  *  `action` returns the actions that the user can or cannot perform on this customer request.
			 *  *  `comment` returns the comments, if any, for each customer request.
			 *  *  `comment.attachment` returns the attachment details, if any, for each comment.
			 *  *  `comment.renderedBody` (Experimental) returns the rendered body in HTML format (in addition to the raw body) for each comment.
			 */
			expand?: string[]
			/**
			 * The starting index of the returned objects. Base index: 0. See the [Pagination](#pagination) section for more details.
			 * @format int32
			 */
			start?: number
			/**
			 * The maximum number of items to return per page. Default: 50. See the [Pagination](#pagination) section for more details.
			 * @format int32
			 */
			limit?: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PagedDTOCustomerRequestDTO
	}

	/**
	 * @description This method creates a customer request in a service desk. The JSON request must include the service desk and customer request type, as well as any fields that are required for the request type. A list of the fields required by a customer request type can be obtained using [servicedesk/\{serviceDeskId\}/requesttype/\{requestTypeId\}/field](#api-servicedesk-serviceDeskId-requesttype-requestTypeId-field-get). The fields required for a customer request type depend on the user's permissions: *  `raiseOnBehalfOf` is not available to Users who have the customer permission only. *  `requestParticipants` is not available to Users who have the customer permission only or if the feature is turned off for customers. `requestFieldValues` is a map of Jira field IDs and their values. See [Field input formats](#fieldformats), for details of each field's JSON semantics and the values they can take. **[Permissions](#permissions) required**: Permission to create requests in the specified service desk.
	 * @tags jsd
	 * @name CreateCustomerRequest
	 * @summary Create customer request
	 * @request POST:/jira-rest/servicedeskapi/request
	 * @secure
	 * @response `201` `CustomerRequestDTO` Returned if the customer request was created.
	 * @response `400` `ErrorResponse` Returned if the HTTP request call is invalid.
	 * @response `401` `ErrorResponse` Returned if the user is not logged in.
	 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
	 * @response `500` `ErrorResponse` Internal Server Error.
	 */
	export namespace CreateCustomerRequest {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = RequestCreateDTO
		export type RequestHeaders = {}
		export type ResponseBody = CustomerRequestDTO
	}

	/**
	 * @description This method returns all the attachments for a customer requests. **[Permissions](#permissions) required**: Permission to view the customer request. **Response limitations**: Customers will only get a list of public attachments.
	 * @tags jsd
	 * @name GetAttachmentsForRequest
	 * @summary Get attachments for request
	 * @request GET:/jira-rest/servicedeskapi/request/{issueIdOrKey}/attachment
	 * @secure
	 * @response `200` `PagedDTOAttachmentDTO` Returns the visible attachments from the customer request.
	 * @response `401` `ErrorResponse` Returned if the user is not logged in.
	 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
	 * @response `404` `ErrorResponse` Returned if the customer request does not exist.
	 * @response `500` `ErrorResponse` Internal Server Error.
	 */
	export namespace GetAttachmentsForRequest {
		export type RequestParams = {
			/** The ID or key of the customer request from which the attachments will be listed. */
			issueIdOrKey: string
		}
		export type RequestQuery = {
			/**
			 * The starting index of the returned attachment. Base index: 0. See the [Pagination](#pagination) section for more details.
			 * @format int32
			 */
			start?: number
			/**
			 * The maximum number of comments to return per page. Default: 50. See the [Pagination](#pagination) section for more details.
			 * @format int32
			 */
			limit?: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PagedDTOAttachmentDTO
	}

	/**
	 * @description This method adds one or more temporary files (attached to the request's service desk using [servicedesk/\{serviceDeskId\}/attachTemporaryFile](#api-servicedesk-serviceDeskId-attachTemporaryFile-post)) as attachments to a customer request and set the attachment visibility using the `public` flag. Also, it is possible to include a comment with the attachments. To get a list of attachments for a comment on the request use [servicedeskapi/request/\{issueIdOrKey\}/comment/\{commentId\}/attachment](#api-request-issueIdOrKey-comment-commentId-attachment-get). **[Permissions](#permissions) required**: Permission to add an attachment. **Request limitations**: Customers can set attachments to public visibility only.
	 * @tags jsd
	 * @name CreateAttachment
	 * @summary Create attachment
	 * @request POST:/jira-rest/servicedeskapi/request/{issueIdOrKey}/attachment
	 * @secure
	 * @response `201` `AttachmentCreateResultDTO` Returns the attachments and comment.
	 * @response `400` `ErrorResponse` Returned if the HTTP request is invalid, including missing the required `public` field or list of temporary file IDs, or if the temporary files have expired.
	 * @response `401` `ErrorResponse` Returned if the user is not logged in.
	 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
	 * @response `404` `ErrorResponse` Returned if the customer request does not exist.
	 * @response `500` `ErrorResponse` Internal Server Error.
	 */
	export namespace CreateAttachment {
		export type RequestParams = {
			/** The ID or key of the customer request to which the attachment will be added. */
			issueIdOrKey: string
		}
		export type RequestQuery = {}
		export type RequestBody = AttachmentCreateDTO
		export type RequestHeaders = {}
		export type ResponseBody = AttachmentCreateResultDTO
	}

	/**
	 * @description This method returns all customer request types used in the Jira Service Management instance, optionally filtered by a query string. Use [servicedeskapi/servicedesk/\{serviceDeskId\}/requesttype](#api-servicedesk-serviceDeskId-requesttype-get) to find the customer request types supported by a specific service desk. The returned list of customer request types can be filtered using the `query` parameter. The parameter is matched against the customer request types' `name` or `description`. For example, searching for "Install", "Inst", "Equi", or "Equipment" will match a customer request type with the *name* "Equipment Installation Request". **Note:** This API will filter out hidden request types (aka.request types without groups) when `query` is provided. **[Permissions](#permissions) required**: Any
	 * @tags jsd
	 * @name GetAllRequestTypes
	 * @summary Get all request types
	 * @request GET:/jira-rest/servicedeskapi/requesttype
	 * @secure
	 * @response `200` `PagedDTORequestTypeDTO` Returns the request types, on the specified page of the results.
	 * @response `401` `ErrorResponse` Returned if the user is not logged in.
	 * @response `500` `ErrorResponse` Internal Server Error.
	 */
	export namespace GetAllRequestTypes {
		export type RequestParams = {}
		export type RequestQuery = {
			/** String to be used to filter the results. */
			searchQuery?: string
			/** Filter the request types by service desk Ids provided. Multiple values of the query parameter are supported. For example, `serviceDeskId=1&serviceDeskId=2` will return request types only for service desks 1 and 2. */
			serviceDeskId?: number[]
			/**
			 * The starting index of the returned objects. Base index: 0. See the [Pagination](#pagination) section for more details.
			 * @format int32
			 */
			start?: number
			/**
			 * The maximum number of items to return per page. Default: 50. See the [Pagination](#pagination) section for more details.
			 * @format int32
			 */
			limit?: number
			expand?: string[]
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PagedDTORequestTypeDTO
	}

	/**
	 * @description This method adds one or more temporary attachments to a service desk, which can then be permanently attached to a customer request using [servicedeskapi/request/\{issueIdOrKey\}/attachment](#api-request-issueIdOrKey-attachment-post). **Note**: It is possible for a service desk administrator to turn off the ability to add attachments to a service desk. This method expects a multipart request. The media-type multipart/form-data is defined in RFC 1867. Most client libraries have classes that make dealing with multipart posts simple. For instance, in Java the Apache HTTP Components library provides [MultiPartEntity](http://hc.apache.org/httpcomponents-client-ga/httpmime/apidocs/org/apache/http/entity/mime/MultipartEntity.html). Because this method accepts multipart/form-data, it has XSRF protection on it. This means you must submit a header of X-Atlassian-Token: no-check with the request or it will be blocked. The name of the multipart/form-data parameter that contains the attachments must be `file`. For example, to upload a file called `myfile.txt` in the Service Desk with ID 10001 use curl -D- -u customer:customer -X POST -H "X-ExperimentalApi: opt-in" -H "X-Atlassian-Token: no-check" -F "file=@myfile.txt" https://your-domain.atlassian.net/rest/servicedeskapi/servicedesk/10001/attachTemporaryFile **[Permissions](#permissions) required**: Permission to add attachments in this Service Desk.
	 * @tags jsd
	 * @name AttachTemporaryFile
	 * @summary Attach temporary file
	 * @request POST:/jira-rest/servicedeskapi/servicedesk/{serviceDeskId}/attachTemporaryFile
	 * @secure
	 * @response `201` `void` Returns if the file(s) were attached.
	 * @response `400` `ErrorResponse` Returned if the attachments are not valid, or exceed the maximum configured attachment size.
	 * @response `401` `ErrorResponse` Returned if the user is not logged in.
	 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
	 * @response `404` `ErrorResponse` Returned if the service desk does not exist.
	 * @response `500` `ErrorResponse` Internal Server Error.
	 */
	export namespace AttachTemporaryFile {
		export type RequestParams = {
			/** The ID of the Service Desk to which the file will be attached. This can alternatively be a [project identifier.](#project-identifiers) */
			serviceDeskId: string
		}
		export type RequestQuery = {}
		export type RequestBody = AttachTemporaryFileRequestModel
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * @description This method returns a list of the customers on a service desk. The returned list of customers can be filtered using the `query` parameter. The parameter is matched against customers' `displayName`, `name`, or `email`. For example, searching for "John", "Jo", "Smi", or "Smith" will match a user with display name "John Smith". **[Permissions](#permissions) required**: Permission to view this Service Desk's customers.
	 * @tags jsd
	 * @name GetCustomers
	 * @summary Get customers
	 * @request GET:/jira-rest/servicedeskapi/servicedesk/{serviceDeskId}/customer
	 * @secure
	 * @response `200` `PagedDTOUserDTO` Returns the service desk's customer list.
	 * @response `401` `ErrorResponse` Returned if the user is not logged in.
	 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
	 * @response `404` `ErrorResponse` Returned if the service desk does not exist.
	 * @response `500` `ErrorResponse` Internal Server Error.
	 */
	export namespace GetCustomers {
		export type RequestParams = {
			/** The ID of the service desk the customer list should be returned from. This can alternatively be a [project identifier.](#project-identifiers) */
			serviceDeskId: string
		}
		export type RequestQuery = {
			/** The string used to filter the customer list. */
			query?: string
			/**
			 * The starting index of the returned objects. Base index: 0. See the [Pagination](#pagination) section for more details.
			 * @format int32
			 */
			start?: number
			/**
			 * The maximum number of users to return per page. Default: 50. See the [Pagination](#pagination) section for more details.
			 * @format int32
			 */
			limit?: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = PagedDTOUserDTO
	}

	/**
	 * @description Adds one or more customers to a service desk. If any of the passed customers are associated with the service desk, no changes will be made for those customers and the resource returns a 204 success code. **[Permissions](#permissions) required**: Service desk administrator
	 * @tags jsd
	 * @name AddCustomers
	 * @summary Add customers
	 * @request POST:/jira-rest/servicedeskapi/servicedesk/{serviceDeskId}/customer
	 * @secure
	 * @response `204` `void` Returned if all the customers were added to the service desk or were already associated with the service desk.
	 * @response `400` `ErrorResponse` Returned if any of the customers do not exist. Note that any valid customers are added, but no confirmation is returned.
	 * @response `401` `ErrorResponse` Returned if the user is not logged in.
	 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
	 * @response `404` `ErrorResponse` Returned if the service desk does not exist.
	 * @response `500` `ErrorResponse` Internal Server Error.
	 */
	export namespace AddCustomers {
		export type RequestParams = {
			/** The ID of the service desk the customer list should be returned from. This can alternatively be a [project identifier.](#project-identifiers) */
			serviceDeskId: string
		}
		export type RequestQuery = {}
		export type RequestBody = ServiceDeskCustomerDTO
		export type RequestHeaders = {}
		export type ResponseBody = void
	}

	/**
	 * @description This method removes one or more customers from a service desk. The service desk must have closed access. If any of the passed customers are not associated with the service desk, no changes will be made for those customers and the resource returns a 204 success code. **[Permissions](#permissions) required**: Services desk administrator
	 * @tags jsd
	 * @name RemoveCustomers
	 * @summary Remove customers
	 * @request DELETE:/jira-rest/servicedeskapi/servicedesk/{serviceDeskId}/customer
	 * @secure
	 * @response `204` `void` Returned if the customers were removed from the service desk, or any of the customers were not associated with the service desk.
	 * @response `400` `ErrorResponse` Returned if the service desk has public signup or open access enabled.
	 * @response `401` `ErrorResponse` Returned if the user is not logged in.
	 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
	 * @response `404` `ErrorResponse` Returned if the service desk does not exist.
	 * @response `500` `ErrorResponse` Internal Server Error.
	 */
	export namespace RemoveCustomers {
		export type RequestParams = {
			/** The ID of the service desk the customers should be removed from. This can alternatively be a [project identifier.](#project-identifiers) */
			serviceDeskId: string
		}
		export type RequestQuery = {}
		export type RequestBody = ServiceDeskCustomerDTO
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

import type {
	AxiosInstance,
	AxiosRequestConfig,
	HeadersDefaults,
	ResponseType,
} from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
	extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean
	/** request path */
	path: string
	/** content type of request body */
	type?: ContentType
	/** query params */
	query?: QueryParamsType
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType
	/** request body */
	body?: unknown
}

export type RequestParams = Omit<
	FullRequestParams,
	'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
	secure?: boolean
	format?: ResponseType
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance
	private securityData: SecurityDataType | null = null
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
	private secure?: boolean
	private format?: ResponseType

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || 'https://client-domain.competera.net',
		})
		this.secure = secure
		this.format = format
		this.securityWorker = securityWorker
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data
	}

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig
	): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method)

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[
						method.toLowerCase() as keyof HeadersDefaults
					]) ||
					{}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		}
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem)
		} else {
			return `${formItem}`
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		if (input instanceof FormData) {
			return input
		}
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key]
			const propertyContent: any[] =
				property instanceof Array ? property : [property]

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File
				formData.append(
					key,
					isFileType ? formItem : this.stringifyFormItem(formItem)
				)
			}

			return formData
		}, new FormData())
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<T> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{}
		const requestParams = this.mergeRequestParams(params, secureParams)
		const responseFormat = format || this.format || undefined

		if (
			type === ContentType.FormData &&
			body &&
			body !== null &&
			typeof body === 'object'
		) {
			body = this.createFormData(body as Record<string, unknown>)
		}

		if (
			type === ContentType.Text &&
			body &&
			body !== null &&
			typeof body !== 'string'
		) {
			body = JSON.stringify(body)
		}

		return this.instance
			.request({
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type ? { 'Content-Type': type } : {}),
				},
				params: query,
				responseType: responseFormat,
				data: body,
				url: path,
			})
			.then((response) => response.data)
	}
}

/**
 * @title FastAPI
 * @version 0.1.0
 * @baseUrl https://client-domain.competera.net
 */
export class Api<
	SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
	apps = {
		/**
		 * No description
		 *
		 * @tags Apps
		 * @name GetApps
		 * @summary Get Apps
		 * @request GET:/api/v1/apps/
		 * @secure
		 * @response `200` `(AppModel)[]` Successful Response
		 */
		getApps: (params: RequestParams = {}) =>
			this.request<AppModel[], any>({
				path: `/api/v1/apps/`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Apps
		 * @name ActivateApp
		 * @summary Activate App
		 * @request POST:/api/v1/apps/{app_name}/activate/
		 * @secure
		 * @response `200` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		activateApp: (appName: string, params: RequestParams = {}) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/apps/${appName}/activate/`,
				method: 'POST',
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Apps
		 * @name DeactivateApp
		 * @summary Deactivate App
		 * @request POST:/api/v1/apps/{app_name}/deactivate/
		 * @secure
		 * @response `200` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		deactivateApp: (appName: string, params: RequestParams = {}) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/apps/${appName}/deactivate/`,
				method: 'POST',
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Apps
		 * @name GetHelpdeskOrganizationIdHandler
		 * @summary Get Helpdesk Organization Id Handler
		 * @request GET:/api/v1/apps/helpdesk-organization-id/
		 * @response `200` `string` Successful Response
		 */
		getHelpdeskOrganizationIdHandler: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/api/v1/apps/helpdesk-organization-id/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Apps
		 * @name GetMailingDomains
		 * @summary Get Mailing Domains
		 * @request GET:/api/v1/apps/mailing-domains/
		 * @response `200` `(string)[]` Successful Response
		 */
		getMailingDomains: (params: RequestParams = {}) =>
			this.request<string[], any>({
				path: `/api/v1/apps/mailing-domains/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Apps
		 * @name UpdateMailingDomains
		 * @summary Update Mailing Domains
		 * @request PUT:/api/v1/apps/mailing-domains/
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		updateMailingDomains: (
			data: UpdateMailingDomainsRequestModel,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/apps/mailing-domains/`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	}
	auth = {
		/**
		 * No description
		 *
		 * @tags Auth
		 * @name LoginAuthLoginGet
		 * @summary Login
		 * @request GET:/auth/login/
		 * @response `307` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		loginAuthLoginGet: (
			query: LoginAuthLoginGetParams,
			params: RequestParams = {}
		) =>
			this.request<any, void | HTTPValidationError>({
				path: `/auth/login/`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Auth
		 * @name AuthAuthAuthGet
		 * @summary Auth
		 * @request GET:/auth/auth/
		 * @response `200` `AccessTokenModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		authAuthAuthGet: (
			query: AuthAuthAuthGetParams,
			params: RequestParams = {}
		) =>
			this.request<AccessTokenModel, HTTPValidationError>({
				path: `/auth/auth/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Auth
		 * @name RefreshTokenHandlerAuthRefreshTokenPost
		 * @summary Refresh Token Handler
		 * @request POST:/auth/refresh-token/
		 * @response `200` `AccessTokenModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		refreshTokenHandlerAuthRefreshTokenPost: (params: RequestParams = {}) =>
			this.request<AccessTokenModel, HTTPValidationError>({
				path: `/auth/refresh-token/`,
				method: 'POST',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Auth
		 * @name LogoutAuthLogoutPost
		 * @summary Logout
		 * @request POST:/auth/logout/
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		logoutAuthLogoutPost: (params: RequestParams = {}) =>
			this.request<void, HTTPValidationError>({
				path: `/auth/logout/`,
				method: 'POST',
				secure: true,
				...params,
			}),
	}
	permissions = {
		/**
		 * No description
		 *
		 * @tags Permissions
		 * @name GetPermissions
		 * @summary Get Permissions
		 * @request GET:/api/v1/permissions/
		 * @response `200` `(PermissionModel)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getPermissions: (query: GetPermissionsParams, params: RequestParams = {}) =>
			this.request<PermissionModel[], HTTPValidationError>({
				path: `/api/v1/permissions/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),
	}
	roles = {
		/**
		 * No description
		 *
		 * @tags Roles
		 * @name GetRoles
		 * @summary Get Roles
		 * @request GET:/api/v1/roles/
		 * @secure
		 * @response `200` `(RoleModel)[]` Successful Response
		 */
		getRoles: (params: RequestParams = {}) =>
			this.request<RoleModel[], any>({
				path: `/api/v1/roles/`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Roles
		 * @name CreateRole
		 * @summary Create Role
		 * @request POST:/api/v1/roles/
		 * @secure
		 * @response `201` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		createRole: (data: RoleDataModel, params: RequestParams = {}) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/roles/`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Roles
		 * @name GetRole
		 * @summary Get Role
		 * @request GET:/api/v1/roles/{role_id}/
		 * @response `200` `RoleModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getRole: (roleId: string, params: RequestParams = {}) =>
			this.request<RoleModel, HTTPValidationError>({
				path: `/api/v1/roles/${roleId}/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Roles
		 * @name DeleteRole
		 * @summary Delete Role
		 * @request DELETE:/api/v1/roles/{role_id}/
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		deleteRole: (roleId: string, params: RequestParams = {}) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/roles/${roleId}/`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Roles
		 * @name UpdateRole
		 * @summary Update Role
		 * @request PATCH:/api/v1/roles/{role_id}/
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		updateRole: (
			roleId: string,
			data: RoleUpdateDataModel,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/roles/${roleId}/`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	}
	users = {
		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetUsers
		 * @summary Get Users
		 * @request GET:/api/v1/users/
		 * @secure
		 * @response `200` `UsersModel` Successful Response
		 */
		getUsers: (params: RequestParams = {}) =>
			this.request<UsersModel, any>({
				path: `/api/v1/users/`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name CreateUserHandler
		 * @summary Create User Handler
		 * @request POST:/api/v1/users/
		 * @secure
		 * @response `201` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		createUserHandler: (data: UserDataModel, params: RequestParams = {}) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/users/`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetCurrentUser
		 * @summary Get Current User
		 * @request GET:/api/v1/users/current/
		 * @secure
		 * @response `200` `UserModel` Successful Response
		 */
		getCurrentUser: (params: RequestParams = {}) =>
			this.request<UserModel, any>({
				path: `/api/v1/users/current/`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name UpdateCurrentUser
		 * @summary Update Current User
		 * @request PATCH:/api/v1/users/current/
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		updateCurrentUser: (
			data: UserProfileDataModel,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/users/current/`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name ResetCurrentUserPassword
		 * @summary Reset Current User Password
		 * @request PATCH:/api/v1/users/current/reset-password/
		 * @secure
		 * @response `204` `void` Successful Response
		 */
		resetCurrentUserPassword: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/api/v1/users/current/reset-password/`,
				method: 'PATCH',
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetCurrentUserPreferences
		 * @summary Get Current User Preferences
		 * @request GET:/api/v1/users/current/preferences/
		 * @secure
		 * @response `200` `any` Successful Response
		 */
		getCurrentUserPreferences: (params: RequestParams = {}) =>
			this.request<any, any>({
				path: `/api/v1/users/current/preferences/`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetCurrentUserPreference
		 * @summary Get Current User Preference
		 * @request GET:/api/v1/users/current/preferences/{preference_type}/
		 * @secure
		 * @response `200` `any` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getCurrentUserPreference: (
			preferenceType: string,
			params: RequestParams = {}
		) =>
			this.request<any, HTTPValidationError>({
				path: `/api/v1/users/current/preferences/${preferenceType}/`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name SetCurrentUserPreference
		 * @summary Set Current User Preference
		 * @request PATCH:/api/v1/users/current/preferences/{preference_type}/
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		setCurrentUserPreference: (
			preferenceType: string,
			data: SetCurrentUserPreferenceRequestModel,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/users/current/preferences/${preferenceType}/`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetUserHandler
		 * @summary Get User Handler
		 * @request GET:/api/v1/users/{user_id}/
		 * @secure
		 * @response `200` `UserModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getUserHandler: (userId: string, params: RequestParams = {}) =>
			this.request<UserModel, HTTPValidationError>({
				path: `/api/v1/users/${userId}/`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name DeleteUser
		 * @summary Delete User
		 * @request DELETE:/api/v1/users/{user_id}/
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		deleteUser: (userId: string, params: RequestParams = {}) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/users/${userId}/`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name UpdateUser
		 * @summary Update User
		 * @request PATCH:/api/v1/users/{user_id}/
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		updateUser: (
			userId: string,
			data: UserDataModel,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/users/${userId}/`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name ResetPassword
		 * @summary Reset Password
		 * @request PATCH:/api/v1/users/{user_id}/reset-password/
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		resetPassword: (userId: string, params: RequestParams = {}) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/users/${userId}/reset-password/`,
				method: 'PATCH',
				secure: true,
				...params,
			}),
	}
	anchoring = {
		/**
		 * @description Get list of all project products
		 *
		 * @tags anchoring
		 * @name ListRelations
		 * @request POST:/api/v1/anchors/
		 * @response `200` `AnchoringProductsResponseData` Successful Response
		 */
		listRelations: (
			data: AnchoringProductsRequestBody,
			params: RequestParams = {}
		) =>
			this.request<AnchoringProductsResponseData, any>({
				path: `/api/v1/anchors/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get list alerts annotation
		 *
		 * @tags anchoring
		 * @name ListAlerts
		 * @request POST:/api/v1/anchors/alerts-info/query
		 * @response `200` `(AnchoringAlertModel)[]` Successful Response
		 */
		listAlerts: (data: ListAlertsRequestModel, params: RequestParams = {}) =>
			this.request<AnchoringAlertModel[], any>({
				path: `/api/v1/anchors/alerts-info/query`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get list alerts
		 *
		 * @tags anchoring
		 * @name ListAlertsAnnotation
		 * @request GET:/api/v1/anchors/alerts
		 * @response `200` `(AlertAnnotationModel)[]` Successful Response
		 */
		listAlertsAnnotation: (params: RequestParams = {}) =>
			this.request<AlertAnnotationModel[], any>({
				path: `/api/v1/anchors/alerts`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Import from file
		 *
		 * @tags anchoring
		 * @name ImportRelations
		 * @request POST:/api/v1/anchors/import/
		 * @response `200` `ImportResultResponseData` Successful Response
		 * @response `400` `ImportResultResponseError` Error Response
		 */
		importRelations: (
			data: ImportRelationsRequestModel,
			params: RequestParams = {}
		) =>
			this.request<ImportResultResponseData, ImportResultResponseError>({
				path: `/api/v1/anchors/import/`,
				method: 'POST',
				body: data,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update
		 *
		 * @tags anchoring
		 * @name UpdateRelations
		 * @request POST:/api/v1/anchors/upsert
		 * @response `200` `AnchorsUpdateResultResponseData` Successful Response
		 */
		updateRelations: (
			data: UpdateRelationsRequestModel,
			params: RequestParams = {}
		) =>
			this.request<AnchorsUpdateResultResponseData, any>({
				path: `/api/v1/anchors/upsert`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete anchors
		 *
		 * @tags anchoring
		 * @name DeleteRelations
		 * @request POST:/api/v1/anchors/delete/
		 * @response `200` `AnchorsDeleteResultResponseData` Successful Response
		 */
		deleteRelations: (
			data: DeleteRelationsRequestModel,
			params: RequestParams = {}
		) =>
			this.request<AnchorsDeleteResultResponseData, any>({
				path: `/api/v1/anchors/delete/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	}
	export = {
		/**
		 * No description
		 *
		 * @tags export
		 * @name ListExports
		 * @summary List Exports
		 * @request GET:/api/v1/exports
		 * @response `200` `(ExportModel)[]` Successful Response
		 */
		listExports: (params: RequestParams = {}) =>
			this.request<ExportModel[], any>({
				path: `/api/v1/exports`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags export
		 * @name CreateExport
		 * @summary Create Export
		 * @request POST:/api/v1/exports
		 * @response `201` `ExportModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		createExport: (data: ExportCreateModel, params: RequestParams = {}) =>
			this.request<ExportModel, HTTPValidationError>({
				path: `/api/v1/exports`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags export
		 * @name DownloadExportFile
		 * @summary Download Export File
		 * @request GET:/api/v1/exports/{export_id}/download
		 * @response `200` `any` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		downloadExportFile: (exportId: string, params: RequestParams = {}) =>
			this.request<any, HTTPValidationError>({
				path: `/api/v1/exports/${exportId}/download`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags export
		 * @name DeleteExport
		 * @summary Delete Export
		 * @request DELETE:/api/v1/exports/{export_id}
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		deleteExport: (exportId: string, params: RequestParams = {}) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/exports/${exportId}`,
				method: 'DELETE',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags export
		 * @name GetExportTemplates
		 * @summary Get Export Templates
		 * @request GET:/api/v1/exports/templates
		 * @response `200` `(ExportTemplateModel)[]` Successful Response
		 */
		getExportTemplates: (params: RequestParams = {}) =>
			this.request<ExportTemplateModel[], any>({
				path: `/api/v1/exports/templates`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	}
	assistant = {
		/**
		 * No description
		 *
		 * @tags assistant
		 * @name AssistantChatSettingsGet
		 * @summary Assistant-Chat-Settings-Get
		 * @request GET:/api/v1/assistant/chat/settings
		 * @secure
		 * @response `200` `ChatSettingsResponseModel` Successful Response
		 */
		assistantChatSettingsGet: (params: RequestParams = {}) =>
			this.request<ChatSettingsResponseModel, any>({
				path: `/api/v1/assistant/chat/settings`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags assistant
		 * @name AssistantChatMessagePost
		 * @summary Assistant-Chat-Message-Post
		 * @request POST:/api/v1/assistant/chat/message
		 * @secure
		 * @response `200` `ChatMessageResponseModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		assistantChatMessagePost: (
			data: ChatMessageRequestModel,
			params: RequestParams = {}
		) =>
			this.request<ChatMessageResponseModel, HTTPValidationError>({
				path: `/api/v1/assistant/chat/message`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags assistant
		 * @name AssistantChatReactionPost
		 * @summary Assistant-Chat-Reaction-Post
		 * @request POST:/api/v1/assistant/chat/reaction
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		assistantChatReactionPost: (
			data: ChatMessageReactionRequestModel,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/assistant/chat/reaction`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	}
	repricingScenarioRuns = {
		/**
		 * No description
		 *
		 * @tags Repricing Scenario Runs
		 * @name GetLastScenarioRuns
		 * @summary Get-Last-Scenario-Runs
		 * @request GET:/api/v1/scenario-runs
		 * @response `200` `(ScenarioRunSetModel | null)` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getLastScenarioRuns: (
			query: GetLastScenarioRunsParams,
			params: RequestParams = {}
		) =>
			this.request<ScenarioRunSetModel | null, HTTPValidationError>({
				path: `/api/v1/scenario-runs`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Repricing Scenario Runs
		 * @name StartScenarioRuns
		 * @summary Start-Scenario-Runs
		 * @request POST:/api/v1/scenario-runs
		 * @response `201` `ScenarioRunSetModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		startScenarioRuns: (
			data: ScenarioRunRequestModel,
			params: RequestParams = {}
		) =>
			this.request<ScenarioRunSetModel, HTTPValidationError>({
				path: `/api/v1/scenario-runs`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Repricing Scenario Runs
		 * @name RestartScenarioRuns
		 * @summary Restart-Scenario-Runs
		 * @request POST:/api/v1/scenario-runs/restart
		 * @response `200` `ScenarioRunSetModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		restartScenarioRuns: (
			data: ScenarioRunRestartRequestModel,
			params: RequestParams = {}
		) =>
			this.request<ScenarioRunSetModel, HTTPValidationError>({
				path: `/api/v1/scenario-runs/restart`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Repricing Scenario Runs
		 * @name DeleteScenarioRuns
		 * @summary Delete-Scenario-Runs
		 * @request POST:/api/v1/scenario-runs/delete
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		deleteScenarioRuns: (
			data: DeleteScenarioRunsRequestModel,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/scenario-runs/delete`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),
	}
	pricingCampaign = {
		/**
		 * @description List pricing campaign resources
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignList
		 * @summary List pricing campaigns
		 * @request GET:/api/v1/pricing-campaigns
		 * @secure
		 * @response `200` `(PricingCampaignModel)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignList: (
			query: PricingCampaignListParams,
			params: RequestParams = {}
		) =>
			this.request<PricingCampaignModel[], HTTPValidationError>({
				path: `/api/v1/pricing-campaigns`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create pricing campaign resource. Combination of name and scenario_id must be unique. If `filter` is specified, products for that filter are added to the newlycreated global PC, but not local PC. They need to be added to local PC with `assign_product` separately.
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignCreate
		 * @summary Create pricing campaign
		 * @request POST:/api/v1/pricing-campaigns
		 * @secure
		 * @response `201` `PricingCampaignModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignCreate: (
			data: PricingCampaignCreateDataModel,
			params: RequestParams = {}
		) =>
			this.request<PricingCampaignModel, HTTPValidationError>({
				path: `/api/v1/pricing-campaigns`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get pricing campaign resource
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignGet
		 * @summary Get pricing campaign
		 * @request GET:/api/v1/pricing-campaigns/{pricing_campaign_id}
		 * @secure
		 * @response `200` `PricingCampaignModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignGet: (
			pricingCampaignId: string,
			params: RequestParams = {}
		) =>
			this.request<PricingCampaignModel, HTTPValidationError>({
				path: `/api/v1/pricing-campaigns/${pricingCampaignId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update pricing campaign resource.
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignUpdate
		 * @summary Update pricing campaign
		 * @request PATCH:/api/v1/pricing-campaigns/{pricing_campaign_id}
		 * @secure
		 * @response `200` `PricingCampaignModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignUpdate: (
			pricingCampaignId: string,
			data: PricingCampaignUpdateDataModel,
			params: RequestParams = {}
		) =>
			this.request<PricingCampaignModel, HTTPValidationError>({
				path: `/api/v1/pricing-campaigns/${pricingCampaignId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete pricing campaign resource
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignDelete
		 * @summary Delete pricing campaign
		 * @request DELETE:/api/v1/pricing-campaigns/{pricing_campaign_id}
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignDelete: (
			pricingCampaignId: string,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/pricing-campaigns/${pricingCampaignId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Assign products to pricing campaign by filters. Products that were assigned previously, remain assigned. The pricing campaign can be global or local, but not dynamic.
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignAssignProducts
		 * @summary Assign products to pricing campaign by filters
		 * @request PATCH:/api/v1/pricing-campaigns/{pricing_campaign_id}/assign-products
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignAssignProducts: (
			pricingCampaignId: string,
			data: PricingCampaignProductsFilters,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/pricing-campaigns/${pricingCampaignId}/assign-products`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Unassign products from pricing campaigns by filters. Products that meet the filter criteria are unassigned from pricing campaigns in optimization groups to which the user has access.
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignUnassignProducts
		 * @summary Unassign products from pricing campaigns by filters
		 * @request PATCH:/api/v1/pricing-campaigns/unassign-products
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignUnassignProducts: (
			data: PricingCampaignProductsFilters,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/pricing-campaigns/unassign-products`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Reorder pricing campaign resources
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignReorder
		 * @summary Reorder pricing campaigns
		 * @request POST:/api/v1/pricing-campaigns/reorder
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignReorder: (
			data: PricingCampaignReorderRequestModel,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/pricing-campaigns/reorder`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Duplicate pricing campaign resources
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignsDuplicate
		 * @summary Duplicate pricing campaigns
		 * @request POST:/api/v1/pricing-campaigns/duplicate
		 * @secure
		 * @response `200` `any` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignsDuplicate: (
			data: PricingCampaignDuplicateRequest,
			params: RequestParams = {}
		) =>
			this.request<any, HTTPValidationError>({
				path: `/api/v1/pricing-campaigns/duplicate`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Duplicate pricing campaign resources to scenarios
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignsDuplicateToScenarios
		 * @summary Duplicate pricing campaigns to scenarios
		 * @request POST:/api/v1/pricing-campaigns/duplicate-to-scenarios
		 * @secure
		 * @response `200` `any` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignsDuplicateToScenarios: (
			data: PricingCampaignDuplicateScenariosRequest,
			params: RequestParams = {}
		) =>
			this.request<any, HTTPValidationError>({
				path: `/api/v1/pricing-campaigns/duplicate-to-scenarios`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Reassign pricing campaign resources
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignsReassign
		 * @summary Reassign pricing campaigns
		 * @request POST:/api/v1/pricing-campaigns/reassign
		 * @secure
		 * @response `200` `any` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignsReassign: (
			data: PricingCampaignReassignRequest,
			params: RequestParams = {}
		) =>
			this.request<any, HTTPValidationError>({
				path: `/api/v1/pricing-campaigns/reassign`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Copy settings pricing campaign resources
		 *
		 * @tags pricing-campaign
		 * @name PricingCampaignsCopySettings
		 * @summary Copy settings pricing campaigns
		 * @request POST:/api/v1/pricing-campaigns/copy-settings
		 * @secure
		 * @response `200` `(PricingCampaignCopySettingsResponseModel)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		pricingCampaignsCopySettings: (
			data: PricingCampaignCopySettingsRequestModel,
			params: RequestParams = {}
		) =>
			this.request<
				PricingCampaignCopySettingsResponseModel[],
				HTTPValidationError
			>({
				path: `/api/v1/pricing-campaigns/copy-settings`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	}
	scenario = {
		/**
		 * @description List scenario resources
		 *
		 * @tags scenario
		 * @name ScenariosList
		 * @summary List scenarios
		 * @request GET:/api/v1/scenarios
		 * @secure
		 * @response `200` `(ScenarioModel)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		scenariosList: (query: ScenariosListParams, params: RequestParams = {}) =>
			this.request<ScenarioModel[], HTTPValidationError>({
				path: `/api/v1/scenarios`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create scenario resource
		 *
		 * @tags scenario
		 * @name ScenarioCreate
		 * @summary Create scenario
		 * @request POST:/api/v1/scenarios
		 * @secure
		 * @response `201` `ScenarioModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		scenarioCreate: (
			data: ScenarioCreateDataModel,
			params: RequestParams = {}
		) =>
			this.request<ScenarioModel, HTTPValidationError>({
				path: `/api/v1/scenarios`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get scenario resource
		 *
		 * @tags scenario
		 * @name ScenarioGet
		 * @summary Get scenario
		 * @request GET:/api/v1/scenarios/{scenario_id}
		 * @secure
		 * @response `200` `ScenarioModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		scenarioGet: (scenarioId: string, params: RequestParams = {}) =>
			this.request<ScenarioModel, HTTPValidationError>({
				path: `/api/v1/scenarios/${scenarioId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update scenario resource
		 *
		 * @tags scenario
		 * @name ScenarioUpdate
		 * @summary Update scenario
		 * @request PATCH:/api/v1/scenarios/{scenario_id}
		 * @secure
		 * @response `200` `ScenarioModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		scenarioUpdate: (
			scenarioId: string,
			data: ScenarioUpdateDataModel,
			params: RequestParams = {}
		) =>
			this.request<ScenarioModel, HTTPValidationError>({
				path: `/api/v1/scenarios/${scenarioId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get main scenario resource
		 *
		 * @tags scenario
		 * @name ScenarioGetMain
		 * @summary Get main scenario
		 * @request GET:/api/v1/scenarios/main/{optimization_group_id}
		 * @secure
		 * @response `200` `ScenarioModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		scenarioGetMain: (
			optimizationGroupId: string,
			params: RequestParams = {}
		) =>
			this.request<ScenarioModel, HTTPValidationError>({
				path: `/api/v1/scenarios/main/${optimizationGroupId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update main scenario resource
		 *
		 * @tags scenario
		 * @name ScenarioUpdateMain
		 * @summary Update main scenario
		 * @request PATCH:/api/v1/scenarios/main/{optimization_group_id}
		 * @secure
		 * @response `200` `ScenarioModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		scenarioUpdateMain: (
			optimizationGroupId: string,
			data: ScenarioUpdateDataModel,
			params: RequestParams = {}
		) =>
			this.request<ScenarioModel, HTTPValidationError>({
				path: `/api/v1/scenarios/main/${optimizationGroupId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete scenario resources
		 *
		 * @tags scenario
		 * @name DeleteScenariosBulk
		 * @summary Delete scenarios
		 * @request POST:/api/v1/scenarios/delete
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		deleteScenariosBulk: (
			data: DeleteScenariosBulkRequestModel,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/scenarios/delete`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Duplicate scenario resource
		 *
		 * @tags scenario
		 * @name ScenarioDuplicate
		 * @summary Duplicate scenario
		 * @request POST:/api/v1/scenarios/{scenario_id}/duplicate
		 * @secure
		 * @response `200` `ScenarioModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		scenarioDuplicate: (scenarioId: string, params: RequestParams = {}) =>
			this.request<ScenarioModel, HTTPValidationError>({
				path: `/api/v1/scenarios/${scenarioId}/duplicate`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Set main scenario resource
		 *
		 * @tags scenario
		 * @name ScenarioSetMain
		 * @summary Set main scenario
		 * @request POST:/api/v1/scenarios/{scenario_id}/set-main
		 * @secure
		 * @response `200` `ScenarioModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		scenarioSetMain: (
			scenarioId: string,
			data: ScenarioSetMainDataModel,
			params: RequestParams = {}
		) =>
			this.request<ScenarioModel, HTTPValidationError>({
				path: `/api/v1/scenarios/${scenarioId}/set-main`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Main scenario copy settings resource
		 *
		 * @tags scenario
		 * @name ScenarioMainCopySettings
		 * @summary Main scenario copy settings
		 * @request POST:/api/v1/scenarios/main/{optimization_group_id}/copy-settings
		 * @secure
		 * @response `200` `any` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		scenarioMainCopySettings: (
			optimizationGroupId: string,
			data: ScenarioMainCopySettings,
			params: RequestParams = {}
		) =>
			this.request<any, HTTPValidationError>({
				path: `/api/v1/scenarios/main/${optimizationGroupId}/copy-settings`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	}
	settingsTemplate = {
		/**
		 * @description Query settings-template resource
		 *
		 * @tags settings-template
		 * @name SettingsTemplateQuery
		 * @summary Query settings-template
		 * @request POST:/api/v1/settings-templates/query
		 * @response `200` `SettingsTemplateQueryResponseModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		settingsTemplateQuery: (
			data: SettingsTemplateQueryModel,
			params: RequestParams = {}
		) =>
			this.request<SettingsTemplateQueryResponseModel, HTTPValidationError>({
				path: `/api/v1/settings-templates/query`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get settings-template resource
		 *
		 * @tags settings-template
		 * @name SettingsTemplateGet
		 * @summary Get settings-template
		 * @request GET:/api/v1/settings-templates/{settings_template_id}
		 * @response `200` `SettingsTemplateModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		settingsTemplateGet: (
			settingsTemplateId: string,
			params: RequestParams = {}
		) =>
			this.request<SettingsTemplateModel, HTTPValidationError>({
				path: `/api/v1/settings-templates/${settingsTemplateId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Update settings-template resource
		 *
		 * @tags settings-template
		 * @name SettingsTemplateUpdate
		 * @summary Update settings-template
		 * @request PATCH:/api/v1/settings-templates/{settings_template_id}
		 * @secure
		 * @response `200` `SettingsTemplateModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		settingsTemplateUpdate: (
			settingsTemplateId: string,
			data: SettingsTemplateUpdateModel,
			params: RequestParams = {}
		) =>
			this.request<SettingsTemplateModel, HTTPValidationError>({
				path: `/api/v1/settings-templates/${settingsTemplateId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete settings-template resource
		 *
		 * @tags settings-template
		 * @name SettingsTemplateDelete
		 * @summary Delete settings-template
		 * @request DELETE:/api/v1/settings-templates/{settings_template_id}
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		settingsTemplateDelete: (
			settingsTemplateId: string,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/settings-templates/${settingsTemplateId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Create settings-template resource
		 *
		 * @tags settings-template
		 * @name SettingsTemplateCreate
		 * @summary Create settings-template
		 * @request POST:/api/v1/settings-templates
		 * @secure
		 * @response `201` `SettingsTemplateModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		settingsTemplateCreate: (
			data: SettingsTemplateCreateModel,
			params: RequestParams = {}
		) =>
			this.request<SettingsTemplateModel, HTTPValidationError>({
				path: `/api/v1/settings-templates`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Apply settings-template resource
		 *
		 * @tags settings-template
		 * @name SettingsTemplateApply
		 * @summary Apply settings-template
		 * @request POST:/api/v1/settings-templates/{settings_template_id}/apply
		 * @secure
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		settingsTemplateApply: (
			settingsTemplateId: string,
			data: SettingsTemplateApplyRequestModel,
			params: RequestParams = {}
		) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/settings-templates/${settingsTemplateId}/apply`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	}
	api = {
		/**
		 * No description
		 *
		 * @name ListReviews
		 * @summary List-Reviews
		 * @request GET:/api/v1/reviews
		 * @response `200` `ListReviewResponse` Successful Response
		 */
		listReviews: (params: RequestParams = {}) =>
			this.request<ListReviewResponse, any>({
				path: `/api/v1/reviews`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name CreateReview
		 * @summary Create-Review
		 * @request POST:/api/v1/reviews/optimization_groups/{optimization_group_id}/
		 * @secure
		 * @response `201` `ReviewModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		createReview: (
			optimizationGroupId: string,
			data: CreateReviewModel,
			params: RequestParams = {}
		) =>
			this.request<ReviewModel, HTTPValidationError>({
				path: `/api/v1/reviews/optimization_groups/${optimizationGroupId}/`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name DeleteReview
		 * @summary Delete-Review
		 * @request DELETE:/api/v1/reviews/optimization_groups/{optimization_group_id}/
		 * @response `204` `void` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		deleteReview: (optimizationGroupId: string, params: RequestParams = {}) =>
			this.request<void, HTTPValidationError>({
				path: `/api/v1/reviews/optimization_groups/${optimizationGroupId}/`,
				method: 'DELETE',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name UpdateReview
		 * @summary Update-Review
		 * @request PATCH:/api/v1/reviews/optimization_groups/{optimization_group_id}/
		 * @secure
		 * @response `200` `ReviewModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		updateReview: (
			optimizationGroupId: string,
			data: UpdateReviewModel,
			params: RequestParams = {}
		) =>
			this.request<ReviewModel, HTTPValidationError>({
				path: `/api/v1/reviews/optimization_groups/${optimizationGroupId}/`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name ApproveReview
		 * @summary Approve-Review
		 * @request POST:/api/v1/reviews/approve/optimization_groups/{optimization_group_id}/
		 * @secure
		 * @response `200` `ReviewModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		approveReview: (optimizationGroupId: string, params: RequestParams = {}) =>
			this.request<ReviewModel, HTTPValidationError>({
				path: `/api/v1/reviews/approve/optimization_groups/${optimizationGroupId}/`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name ListInterpretation
		 * @summary List Interpretation
		 * @request GET:/api/v1/repricing-interpretation/
		 * @response `200` `(object)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		listInterpretation: (
			query: ListInterpretationParams,
			params: RequestParams = {}
		) =>
			this.request<object[], HTTPValidationError>({
				path: `/api/v1/repricing-interpretation/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name ListInterpretationData
		 * @summary List Interpretation Data
		 * @request GET:/api/v1/repricing-interpretation-data/
		 * @response `200` `BusinessSettingsModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		listInterpretationData: (
			query: ListInterpretationDataParams,
			params: RequestParams = {}
		) =>
			this.request<BusinessSettingsModel, HTTPValidationError>({
				path: `/api/v1/repricing-interpretation-data/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name ListSchedule
		 * @summary List Schedule
		 * @request GET:/api/v1/schedules/
		 * @response `200` `(ScheduleModel)[]` Successful Response
		 */
		listSchedule: (params: RequestParams = {}) =>
			this.request<ScheduleModel[], any>({
				path: `/api/v1/schedules/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name CreateSchedule
		 * @summary Create Schedule
		 * @request POST:/api/v1/schedules/
		 * @response `201` `ScheduleModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		createSchedule: (data: CreateScheduleModel, params: RequestParams = {}) =>
			this.request<ScheduleModel, HTTPValidationError>({
				path: `/api/v1/schedules/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name GetSchedule
		 * @summary Get Schedule
		 * @request GET:/api/v1/schedules/{schedule_id}
		 * @response `200` `ScheduleModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getSchedule: (scheduleId: string, params: RequestParams = {}) =>
			this.request<ScheduleModel, HTTPValidationError>({
				path: `/api/v1/schedules/${scheduleId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name DeleteSchedule
		 * @summary Delete Schedule
		 * @request DELETE:/api/v1/schedules/{schedule_id}
		 * @response `200` `any` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		deleteSchedule: (scheduleId: string, params: RequestParams = {}) =>
			this.request<any, HTTPValidationError>({
				path: `/api/v1/schedules/${scheduleId}`,
				method: 'DELETE',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name UpdateSchedule
		 * @summary Update Schedule
		 * @request PATCH:/api/v1/schedules/{schedule_id}
		 * @response `200` `ScheduleModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		updateSchedule: (
			scheduleId: string,
			data: UpdateScheduleModel,
			params: RequestParams = {}
		) =>
			this.request<ScheduleModel, HTTPValidationError>({
				path: `/api/v1/schedules/${scheduleId}`,
				method: 'PATCH',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Attention:<br/>    If pass >2000 optimization groups ids then nginx send `414 Request URI Too Large`<br/>
		 *
		 * @name ExportTemplate
		 * @summary Download products report for specified export template
		 * @request GET:/api/v1/export/{export_template_id}/
		 * @response `200` `void` Successful Response
		 */
		exportTemplate: (exportTemplateId: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/api/v1/export/${exportTemplateId}/`,
				method: 'GET',
				...params,
			}),

		/**
		 * @description Filters related to user and optimization_group<br/>
		 *
		 * @name ListUserOgFilters
		 * @summary Get user filters
		 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/filters/
		 * @response `200` `void` Successful Response
		 */
		listUserOgFilters: (
			optimizationGroupId: string,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/filters/`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name CreateUserOgFilter
		 * @summary Add new user filter
		 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/filters/
		 * @response `200` `void` Successful Response
		 */
		createUserOgFilter: (
			optimizationGroupId: string,
			data: SavedFilterModel,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/filters/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name DeleteUserOgFilter
		 * @summary Delete filter by id
		 * @request DELETE:/api/v1/optimization_groups/{optimization_group_id}/filters/{filter_id}/
		 * @response `200` `void` Successful Response
		 */
		deleteUserOgFilter: (
			optimizationGroupId: string,
			filterId: string,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/filters/${filterId}/`,
				method: 'DELETE',
				...params,
			}),

		/**
		 * No description
		 *
		 * @name UpdateUserOgFilter
		 * @summary Update filter by id
		 * @request PATCH:/api/v1/optimization_groups/{optimization_group_id}/filters/{filter_id}/
		 * @response `200` `void` Successful Response
		 */
		updateUserOgFilter: (
			optimizationGroupId: string,
			filterId: string,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/filters/${filterId}/`,
				method: 'PATCH',
				...params,
			}),
	}
	bi = {
		/**
 * No description
 *
 * @tags bi
 * @name GetProductsCountByEngine
 * @summary Get products count by engine
 * @request POST:/api/v1/products-count-by-engine/
 * @response `200` `{
    data?: (ProductsCountByEngineModel)[],

}` Successful Response
 */
		getProductsCountByEngine: (
			data: ProductCountByEngineRequestModel,
			params: RequestParams = {}
		) =>
			this.request<
				{
					data?: ProductsCountByEngineModel[]
				},
				any
			>({
				path: `/api/v1/products-count-by-engine/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name ListMetrics
		 * @summary List-Metrics
		 * @request GET:/api/v1/metrics/
		 * @response `200` `(MetricModel)[]` Successful Response
		 */
		listMetrics: (params: RequestParams = {}) =>
			this.request<MetricModel[], any>({
				path: `/api/v1/metrics/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name BiRevision
		 * @summary Bi-Revision
		 * @request GET:/api/v1/bi-revision/
		 * @response `200` `(BIRevision | null)` Successful Response
		 */
		biRevision: (params: RequestParams = {}) =>
			this.request<BIRevision | null, any>({
				path: `/api/v1/bi-revision/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name BiFields
		 * @summary Bi-Fields
		 * @request GET:/api/v1/bi/fields
		 * @response `200` `(object)[]` Successful Response
		 */
		biFields: (params: RequestParams = {}) =>
			this.request<object[], any>({
				path: `/api/v1/bi/fields`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name BiFieldsValues
		 * @summary Bi-Fields-Values
		 * @request GET:/api/v1/bi/fields/{bi_field}/values
		 * @response `200` `((string)[] | null)` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		biFieldsValues: (biField: string, params: RequestParams = {}) =>
			this.request<string[] | null, HTTPValidationError>({
				path: `/api/v1/bi/fields/${biField}/values`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetBi
		 * @summary Get-Bi
		 * @request POST:/api/v1/bi/
		 * @secure
		 * @response `200` `(BIResponse | null)` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getBi: (data: BIRequest, params: RequestParams = {}) =>
			this.request<BIResponse | null, HTTPValidationError>({
				path: `/api/v1/bi/`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetBiRevenue
		 * @summary Get-Bi-Revenue
		 * @request POST:/api/v1/bi-revenue/
		 * @secure
		 * @response `200` `BIRevenueResponse` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getBiRevenue: (data: BIRevenueRequest, params: RequestParams = {}) =>
			this.request<BIRevenueResponse, HTTPValidationError>({
				path: `/api/v1/bi-revenue/`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetBiAi
		 * @summary Get-Bi-Ai
		 * @request POST:/api/v1/bi-ai/
		 * @secure
		 * @response `200` `BIAIResponse` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getBiAi: (data: BIAIRequest, params: RequestParams = {}) =>
			this.request<BIAIResponse, HTTPValidationError>({
				path: `/api/v1/bi-ai/`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetOgTreeMapBySalesEntities
		 * @summary Get-Og-Tree-Map-By-Sales-Entities
		 * @request POST:/api/v1/optimization-groups-tree-map/by-sales-entities
		 * @secure
		 * @response `200` `(TreeMapSalesEntityModel)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getOgTreeMapBySalesEntities: (
			data: TreeMapRequestModel,
			params: RequestParams = {}
		) =>
			this.request<TreeMapSalesEntityModel[], HTTPValidationError>({
				path: `/api/v1/optimization-groups-tree-map/by-sales-entities`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetOgTreeMapByProductGroups
		 * @summary Get-Og-Tree-Map-By-Product-Groups
		 * @request POST:/api/v1/optimization-groups-tree-map/by-product-groups
		 * @secure
		 * @response `200` `(TreeMapProductGroupModel)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getOgTreeMapByProductGroups: (
			data: TreeMapRequestModel,
			params: RequestParams = {}
		) =>
			this.request<TreeMapProductGroupModel[], HTTPValidationError>({
				path: `/api/v1/optimization-groups-tree-map/by-product-groups`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetStatistics
		 * @summary Get-Statistics
		 * @request POST:/api/v1/statistics
		 * @secure
		 * @response `200` `StatisticsResponse` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getStatistics: (data: StatisticsRequest, params: RequestParams = {}) =>
			this.request<StatisticsResponse, HTTPValidationError>({
				path: `/api/v1/statistics`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetStatisticsRepricing
		 * @summary Get-Statistics-Repricing
		 * @request POST:/api/v1/statistics/repricing
		 * @response `200` `(StatisticsRepricingResponse | null)` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getStatisticsRepricing: (
			data: StatisticsRepricingRequest,
			params: RequestParams = {}
		) =>
			this.request<StatisticsRepricingResponse | null, HTTPValidationError>({
				path: `/api/v1/statistics/repricing`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetStatisticsInterpretability
		 * @summary Get-Statistics-Interpretability
		 * @request POST:/api/v1/statistics/interpretability
		 * @response `200` `(StatisticsInterpretabilityResponse | null)` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getStatisticsInterpretability: (
			data: StatisticsInterpretabilityRequest,
			params: RequestParams = {}
		) =>
			this.request<
				StatisticsInterpretabilityResponse | null,
				HTTPValidationError
			>({
				path: `/api/v1/statistics/interpretability`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetStatisticsTargetProtectDistribution
		 * @summary Get-Statistics-Target-Protect-Distribution
		 * @request POST:/api/v1/statistics/target-protect-distribution
		 * @response `200` `((TargetProtectDistributionModel)[] | null)` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getStatisticsTargetProtectDistribution: (
			data: StatisticsTargetProtectDistributionRequest,
			params: RequestParams = {}
		) =>
			this.request<
				TargetProtectDistributionModel[] | null,
				HTTPValidationError
			>({
				path: `/api/v1/statistics/target-protect-distribution`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetStatisticsMultipleInterpretability
		 * @summary Get-Statistics-Multiple-Interpretability
		 * @request GET:/api/v1/statistics/multiple-interpretability
		 * @secure
		 * @response `200` `(StatisticsInterpretabilityResponse)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getStatisticsMultipleInterpretability: (
			query: GetStatisticsMultipleInterpretabilityParams,
			params: RequestParams = {}
		) =>
			this.request<StatisticsInterpretabilityResponse[], HTTPValidationError>({
				path: `/api/v1/statistics/multiple-interpretability`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetOptimizationsStatistics
		 * @summary Get-Optimizations-Statistics
		 * @request GET:/api/v1/statistics/optimizations-statistics
		 * @secure
		 * @response `200` `(OptimizationStatisticModel)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getOptimizationsStatistics: (
			query: GetOptimizationsStatisticsParams,
			params: RequestParams = {}
		) =>
			this.request<OptimizationStatisticModel[], HTTPValidationError>({
				path: `/api/v1/statistics/optimizations-statistics`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetAlertsInfo
		 * @summary Get-Alerts-Info
		 * @request POST:/api/v1/statistics/alerts-info
		 * @secure
		 * @response `200` `AlertsInfoResponseModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getAlertsInfo: (data: AlertInfoRequestModel, params: RequestParams = {}) =>
			this.request<AlertsInfoResponseModel, HTTPValidationError>({
				path: `/api/v1/statistics/alerts-info`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetOptimizationPredictMetrics
		 * @summary Get-Optimization-Predict-Metrics
		 * @request GET:/api/v1/statistics-internal/optimization-metrics
		 * @response `200` `(PredictMetricModel)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getOptimizationPredictMetrics: (
			query: GetOptimizationPredictMetricsParams,
			params: RequestParams = {}
		) =>
			this.request<PredictMetricModel[], HTTPValidationError>({
				path: `/api/v1/statistics-internal/optimization-metrics`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetOptimizationPrices
		 * @summary Get-Optimization-Prices
		 * @request GET:/api/v1/statistics-internal/optimization-prices
		 * @response `200` `(OptimizationPricesModel)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getOptimizationPrices: (
			query: GetOptimizationPricesParams,
			params: RequestParams = {}
		) =>
			this.request<OptimizationPricesModel[], HTTPValidationError>({
				path: `/api/v1/statistics-internal/optimization-prices`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bi
		 * @name GetMultipleAlertsInfo
		 * @summary Get-Multiple-Alerts-Info
		 * @request POST:/api/v1/statistics/multiple-alerts-info
		 * @response `200` `MultipleAlertsInfoResponseModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getMultipleAlertsInfo: (
			data: MultipleAlertInfoRequestModel,
			params: RequestParams = {}
		) =>
			this.request<MultipleAlertsInfoResponseModel, HTTPValidationError>({
				path: `/api/v1/statistics/multiple-alerts-info`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	}
	util = {
		/**
		 * No description
		 *
		 * @tags util
		 * @name GetMatchedProductsCount
		 * @summary Get matched products count by pc
		 * @request POST:/api/v1/matched-products/
		 * @response `200` `MatchedProductsResponseModel` Successful Response
		 */
		getMatchedProductsCount: (
			query: GetMatchedProductsCountParams,
			data: GetMatchedProductsCountRequestModel,
			params: RequestParams = {}
		) =>
			this.request<MatchedProductsResponseModel, any>({
				path: `/api/v1/matched-products/`,
				method: 'POST',
				query: query,
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags util
		 * @name GetImportTemplate
		 * @summary Get import template file for current project
		 * @request GET:/api/v1/import_template/
		 * @response `200` `void` Successful Response
		 */
		getImportTemplate: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/api/v1/import_template/`,
				method: 'GET',
				...params,
			}),

		/**
		 * @description Depends on project custom_fields schema<br/>product_diffs field is required<br/>return parsed product_diffs or list of errors<br/>
		 *
		 * @tags util
		 * @name PostValidators
		 * @summary Parse and validate csv, xls, xlsx input.
		 * @request POST:/api/v1/validators/
		 * @response `200` `DiffValidationResponseModel` Successful Response
		 */
		postValidators: (
			data: PostValidatorsRequestModel,
			params: RequestParams = {}
		) =>
			this.request<DiffValidationResponseModel, any>({
				path: `/api/v1/validators/`,
				method: 'POST',
				body: data,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get list of counts by og_id or pc_id
		 *
		 * @tags util
		 * @name GetProductsCountsByPc
		 * @request POST:/api/v1/products-count-by-pc/
		 * @response `200` `(PcProductsCountsModel)[]` Successful Response
		 */
		getProductsCountsByPc: (
			data: CountByFiltersRequestBody,
			params: RequestParams = {}
		) =>
			this.request<PcProductsCountsModel[], any>({
				path: `/api/v1/products-count-by-pc/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get list of counts by og_id
		 *
		 * @tags util
		 * @name GetProductsCountsByOg
		 * @request POST:/api/v1/products-count-by-og/
		 * @response `200` `ProductsCountsByOgResponseData` Successful Response
		 */
		getProductsCountsByOg: (
			data: CountByFiltersRequestBody,
			params: RequestParams = {}
		) =>
			this.request<ProductsCountsByOgResponseData, any>({
				path: `/api/v1/products-count-by-og/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	}
	core = {
		/**
		 * No description
		 *
		 * @tags core
		 * @name GetPreprocessing
		 * @summary Get preprocessing
		 * @request GET:/api/v1/preprocessing/
		 * @response `200` `PreprocessingModel` Successful Response
		 */
		getPreprocessing: (params: RequestParams = {}) =>
			this.request<PreprocessingModel, any>({
				path: `/api/v1/preprocessing/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags core
		 * @name ListAlertsAnnotation
		 * @summary Get list of product alerts in project
		 * @request GET:/api/v1/alerts/
		 * @response `200` `(AlertAnnotationModel)[]` List of object with {id, name, description, level} fields
		 */
		listAlertsAnnotation: (params: RequestParams = {}) =>
			this.request<AlertAnnotationModel[], any>({
				path: `/api/v1/alerts/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name ListBrands
 * @summary Get brands
 * @request GET:/api/v1/brands/
 * @response `200` `{
    success?: boolean,
    brands?: (BrandModel)[],

}` Successful Response
 */
		listBrands: (query: ListBrandsParams, params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
					brands?: BrandModel[]
				},
				any
			>({
				path: `/api/v1/brands/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name ListCategoriesFull
 * @summary Get full categories for product groups
 * @request GET:/api/v1/categories-full/
 * @response `200` `{
    success?: boolean,
    categories?: (CategoryModel)[],

}` Successful Response
 */
		listCategoriesFull: (params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
					categories?: CategoryModel[]
				},
				any
			>({
				path: `/api/v1/categories-full/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name ListCategories
 * @summary Get categories
 * @request GET:/api/v1/categories/
 * @response `200` `{
    success?: boolean,
    categories?: (CategoryModel)[],

}` Successful Response
 */
		listCategories: (query: ListCategoriesParams, params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
					categories?: CategoryModel[]
				},
				any
			>({
				path: `/api/v1/categories/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * @description <br/>Maybe add all export options (bq, file, etc.) into this endpoint in the future<br/>
		 *
		 * @tags core
		 * @name MassExportTemplates
		 * @summary List export templates for export.
		 * @request GET:/api/v1/export/
		 * @response `200` `void` Successful Response
		 */
		massExportTemplates: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/api/v1/export/`,
				method: 'GET',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name MassExport
 * @summary Export all unlocked optimization_groups to bigquery customer-project.Entry.final_prices
 * @request POST:/api/v1/export/
 * @response `200` `{
    objects?: (ExportTemplateModel1)[],

}` Successful Response
 */
		massExport: (params: RequestParams = {}) =>
			this.request<
				{
					objects?: ExportTemplateModel1[]
				},
				any
			>({
				path: `/api/v1/export/`,
				method: 'POST',
				format: 'json',
				...params,
			}),

		/**
 * @description Allowed file formats are csv, xlsx, xls<br/>Depends on project custom_fields schema<br/>product_diffs field is required<br/><br/>
 *
 * @tags core
 * @name MultiImport
 * @summary Parse, validate and apply file input to list of optimization groups.
 * @request POST:/api/v1/multi_import/
 * @response `200` `{
    results?: Record<string,{
    optimization?: OptimizationModel,
    success?: boolean,

}>,
    counts?: Record<string,number>,
    success?: boolean,

}` Successful Response
 */
		multiImport: (data: MultiImportRequestModel, params: RequestParams = {}) =>
			this.request<
				{
					results?: Record<
						string,
						{
							optimization?: OptimizationModel
							success?: boolean
						}
					>
					counts?: Record<string, number>
					success?: boolean
				},
				any
			>({
				path: `/api/v1/multi_import/`,
				method: 'POST',
				body: data,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
 * @description <br/>.. code-block:: python<br/><br/>    [{"action": "lock", "optimization_id": <uuid>, "optimization_group_id": <uuid>}]<br/>
 *
 * @tags core
 * @name SendOptimizationActions
 * @summary Actions: start, stop, lock, unlock, delete_export, update
 * @request POST:/api/v1/optimization-actions/
 * @response `200` `{
    results?: Record<string,{
    optimization?: OptimizationModel,
    success?: boolean,

}>,
    success?: boolean,

}` Successful Response
 */
		sendOptimizationActions: (
			data: SendOptimizationActionsRequestModel,
			params: RequestParams = {}
		) =>
			this.request<
				{
					results?: Record<
						string,
						{
							optimization?: OptimizationModel
							success?: boolean
						}
					>
					success?: boolean
				},
				any
			>({
				path: `/api/v1/optimization-actions/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name ListOptimizationGroups
 * @summary Get list of optimization_groups or optimization_group by id
 * @request GET:/api/v1/optimization_groups/
 * @response `200` `{
    success?: boolean,
    optimization_groups?: (OptimizationGroupModel)[],

}` Successful Response
 */
		listOptimizationGroups: (params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
					optimization_groups?: OptimizationGroupModel[]
				},
				any
			>({
				path: `/api/v1/optimization_groups/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags core
		 * @name CreateOptimizationGroup
		 * @summary Create optiomzation group
		 * @request POST:/api/v1/optimization_groups/
		 * @response `200` `OptimizationGroupModel` Successful Response
		 */
		createOptimizationGroup: (
			data: CreateOptimizationGroupInput,
			params: RequestParams = {}
		) =>
			this.request<OptimizationGroupModel, any>({
				path: `/api/v1/optimization_groups/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name ListOptimizations
 * @summary Get Optimizations List
 * @request GET:/api/v1/optimization_groups/optimizations/
 * @response `200` `{
    success?: boolean,
    optimizations?: Record<string,OptimizationModel>,

}` Successful Response
 */
		listOptimizations: (params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
					optimizations?: Record<string, OptimizationModel>
				},
				any
			>({
				path: `/api/v1/optimization_groups/optimizations/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags core
		 * @name GetOptimizationGroup
		 * @summary Get list of optimization_groups or optimization_group by id
		 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/
		 * @response `200` `OptimizationGroupModel` Successful Response
		 */
		getOptimizationGroup: (
			optimizationGroupId: string,
			params: RequestParams = {}
		) =>
			this.request<OptimizationGroupModel, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags core
		 * @name UpdateOptimizationGroup
		 * @summary Update optimization group
		 * @request PATCH:/api/v1/optimization_groups/{optimization_group_id}/
		 * @response `200` `OptimizationGroupModel` Successful Response
		 */
		updateOptimizationGroup: (
			optimizationGroupId: string,
			data: UpdateOptimizationGroupInput,
			params: RequestParams = {}
		) =>
			this.request<OptimizationGroupModel, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/`,
				method: 'PATCH',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags core
		 * @name DeleteOptimizationGroup
		 * @summary Delete optimization group
		 * @request DELETE:/api/v1/optimization_groups/{optimization_group_id}/
		 * @response `200` `void` Successful Response
		 */
		deleteOptimizationGroup: (
			optimizationGroupId: string,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/`,
				method: 'DELETE',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name ListOptimizationGroupBrands
 * @summary Get brands
 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/brands/
 * @response `200` `{
    success?: boolean,
    brands?: (BrandModel)[],

}` Successful Response
 */
		listOptimizationGroupBrands: (
			optimizationGroupId: string,
			params: RequestParams = {}
		) =>
			this.request<
				{
					success?: boolean
					brands?: BrandModel[]
				},
				any
			>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/brands/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name ListOptimizationGroupCategories
 * @summary Get categories
 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/categories/
 * @response `200` `{
    success?: boolean,
    categories?: (CategoryModel)[],

}` Successful Response
 */
		listOptimizationGroupCategories: (
			optimizationGroupId: string,
			params: RequestParams = {}
		) =>
			this.request<
				{
					success?: boolean
					categories?: CategoryModel[]
				},
				any
			>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/categories/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name CreateOptimization
 * @summary Create new optimization
 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/
 * @response `200` `{
    optimization: OptimizationModel,

}` Successful Response
 */
		createOptimization: (
			optimizationGroupId: string,
			params: RequestParams = {}
		) =>
			this.request<
				{
					optimization: OptimizationModel
				},
				any
			>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/optimizations/`,
				method: 'POST',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name GetOptimizations
 * @summary Get Optimizations info by ids
 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/optimizations/
 * @response `200` `{
    optimizations?: (OptimizationModel)[],

}` Successful Response
 */
		getOptimizations: (
			{ optimizationGroupId, ...query }: GetOptimizationsParams,
			params: RequestParams = {}
		) =>
			this.request<
				{
					optimizations?: OptimizationModel[]
				},
				any
			>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/optimizations/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags core
		 * @name GetOptimization
		 * @summary Get Optimization info
		 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/
		 * @response `200` `OptimizationModel` Successful Response
		 */
		getOptimization: (
			optimizationGroupId: string,
			optimizationId: string,
			params: RequestParams = {}
		) =>
			this.request<OptimizationModel, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/optimizations/${optimizationId}/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name DoOptimizationAction
 * @summary Actions: start, stop, lock, unlock, delete_export, update
 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/{action}/
 * @response `200` `{
    optimization: OptimizationModel,
    success?: boolean,

}` Successful Response
 */
		doOptimizationAction: (
			optimizationGroupId: string,
			optimizationId: string,
			action: OptimizationAction,
			params: RequestParams = {}
		) =>
			this.request<
				{
					optimization: OptimizationModel
					success?: boolean
				},
				any
			>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/optimizations/${optimizationId}/${action}/`,
				method: 'POST',
				format: 'json',
				...params,
			}),

		/**
		 * @description Target OG optimization status is validated (if it is not 'New', view is aborted)<br/><br/>If selected, optimization group settings are copied (stop list, business)<br/><br/>If selected, all pricing campaigns from source optimization group are copied,<br/>with all pricing campaigns in target OG being deleted and products moved to 'New products' PC<br/>New pricing campaigns are created, with engine settings identical to the ones from source OG<br/><br/>If selected, default pricing campaign property is copied<br/>If selected, products are assigned to same PCs as in source OG, if they exist in target OG<br/>If selected, repricing limits and minimal repricing step settings are copied<br/>
		 *
		 * @tags core
		 * @name SettingsOgCopy
		 * @summary Upon calling such endpoint, optimization_group_id and json body are passed
		 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/settings-copy/
		 * @response `200` `void` Successful Response
		 */
		settingsOgCopy: (
			optimizationGroupId: string,
			data: OptimizationCopySchema,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/settings-copy/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name GetStopListMatchedProductsCounts
 * @summary Match optimization group products to stop-list rules
 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/settings/stop_list/matched-products
 * @response `200` `{
    products?: number,
    pricing_lines?: number,

}` Successful Response
 */
		getStopListMatchedProductsCounts: (
			optimizationGroupId: string,
			data: StopListSettingsModel,
			params: RequestParams = {}
		) =>
			this.request<
				{
					products?: number
					pricing_lines?: number
				},
				any
			>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/settings/stop_list/matched-products`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags core
		 * @name ListProductGroups
		 * @summary Get list of product groups in project
		 * @request GET:/api/v1/product-groups/
		 * @response `200` `(ProductGroupModel)[]` List of object with {id, name} fields
		 */
		listProductGroups: (params: RequestParams = {}) =>
			this.request<ProductGroupModel[], any>({
				path: `/api/v1/product-groups/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name ListRoundingRules
 * @summary Get available rounding rules.
 * @request GET:/api/v1/rounding_rules/
 * @response `200` `{
    objects?: (RoundingRuleModel)[],

}` Successful Response
 */
		listRoundingRules: (params: RequestParams = {}) =>
			this.request<
				{
					objects?: RoundingRuleModel[]
				},
				any
			>({
				path: `/api/v1/rounding_rules/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * @description types: int, float, bool, str, datetime<br/>
 *
 * @tags core
 * @name ListColumnSchemas
 * @summary Get custom fields schema for project
 * @request GET:/api/v1/schemas/
 * @response `200` `{
    success?: boolean,
    schema?: (ColumnSchemaModel)[],

}` Successful Response
 */
		listColumnSchemas: (params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
					schema?: ColumnSchemaModel[]
				},
				any
			>({
				path: `/api/v1/schemas/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags core
 * @name ListSettingsSchemas
 * @summary Get settings JSON schemas
 * @request GET:/api/v1/settings-schemas
 * @response `200` `({
    $id?: string,
    type?: string,
    properties?: Record<string,any>,
    [key: string]: any,

})[]` Successful Response
 */
		listSettingsSchemas: (params: RequestParams = {}) =>
			this.request<
				{
					$id?: string
					type?: string
					properties?: Record<string, any>
					[key: string]: any
				}[],
				any
			>({
				path: `/api/v1/settings-schemas`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags core
		 * @name ListUiSchemas
		 * @summary Get ui schemas for repricing rules
		 * @request GET:/api/v1/ui-schemas
		 * @response `200` `(PricinRuleUiSchemaModel)[]` Successful Response
		 */
		listUiSchemas: (params: RequestParams = {}) =>
			this.request<PricinRuleUiSchemaModel[], any>({
				path: `/api/v1/ui-schemas`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags core
		 * @name GetSalesEntities
		 * @summary Get sales entities and levels
		 * @request GET:/api/v1/sales-entities/
		 * @response `200` `SalesEntitiesResponseData` Sales entities and levels
		 */
		getSalesEntities: (params: RequestParams = {}) =>
			this.request<SalesEntitiesResponseData, any>({
				path: `/api/v1/sales-entities/`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	}
	promoCampaigns = {
		/**
		 * No description
		 *
		 * @tags promo-campaigns
		 * @name ListPromoCampaigns
		 * @summary List Promo Campaigns
		 * @request GET:/api/v1/promo-campaigns/
		 * @response `200` `(PromoCampaignModel)[]` Successful Response
		 */
		listPromoCampaigns: (params: RequestParams = {}) =>
			this.request<PromoCampaignModel[], any>({
				path: `/api/v1/promo-campaigns/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags promo-campaigns
		 * @name CreatePromoCampaign
		 * @summary Create Promo Campaign
		 * @request POST:/api/v1/promo-campaigns/
		 * @response `201` `PromoCampaignModel` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		createPromoCampaign: (
			data: PromoCampaignCreateModel,
			params: RequestParams = {}
		) =>
			this.request<PromoCampaignModel, HTTPValidationError>({
				path: `/api/v1/promo-campaigns/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags promo-campaigns
		 * @name GetPromoCampaign
		 * @summary Get Promo Campaign
		 * @request GET:/api/v1/promo-campaigns/{promo_campaign_id}/
		 * @response `200` `PromoCampaignModel` Successful Response
		 * @response `404` `ErrorModel` Not Found
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		getPromoCampaign: (promoCampaignId: string, params: RequestParams = {}) =>
			this.request<PromoCampaignModel, ErrorModel | HTTPValidationError>({
				path: `/api/v1/promo-campaigns/${promoCampaignId}/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags promo-campaigns
		 * @name UpdatePromoCampaign
		 * @summary Update Promo Campaign
		 * @request PATCH:/api/v1/promo-campaigns/{promo_campaign_id}/
		 * @response `200` `PromoCampaignModel` Successful Response
		 * @response `403` `ErrorModel` Forbidden
		 * @response `404` `ErrorModel` Not Found
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		updatePromoCampaign: (
			promoCampaignId: string,
			data: PromoCampaignUpdateModel,
			params: RequestParams = {}
		) =>
			this.request<PromoCampaignModel, ErrorModel | HTTPValidationError>({
				path: `/api/v1/promo-campaigns/${promoCampaignId}/`,
				method: 'PATCH',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags promo-campaigns
		 * @name DeletePromoCampaign
		 * @summary Delete Promo Campaign
		 * @request DELETE:/api/v1/promo-campaigns/{promo_campaign_id}/
		 * @response `204` `void` Successful Response
		 * @response `403` `ErrorModel` Forbidden
		 * @response `404` `ErrorModel` Not Found
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		deletePromoCampaign: (
			promoCampaignId: string,
			params: RequestParams = {}
		) =>
			this.request<void, ErrorModel | HTTPValidationError>({
				path: `/api/v1/promo-campaigns/${promoCampaignId}/`,
				method: 'DELETE',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags promo-campaigns
		 * @name ListPromoCampaignProducts
		 * @summary List Promo Campaign Products
		 * @request GET:/api/v1/promo-campaigns/{promo_campaign_id}/products/
		 * @response `200` `(PromoProductModel)[]` Successful Response
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		listPromoCampaignProducts: (
			promoCampaignId: string,
			params: RequestParams = {}
		) =>
			this.request<PromoProductModel[], HTTPValidationError>({
				path: `/api/v1/promo-campaigns/${promoCampaignId}/products/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags promo-campaigns
		 * @name UploadPromoCampaignProducts
		 * @summary Upload Promo Campaign Products
		 * @request POST:/api/v1/promo-campaigns/{promo_campaign_id}/products/
		 * @response `201` `any` Successful Response
		 * @response `400` `UploadPromoProductsErrorModel` Bad Request
		 * @response `403` `ErrorModel` Forbidden
		 * @response `404` `ErrorModel` Not Found
		 * @response `422` `HTTPValidationError` Validation Error
		 */
		uploadPromoCampaignProducts: (
			promoCampaignId: string,
			data: BodyUploadPromoCampaignProductsApiV1PromoCampaignsPromoCampaignIdProductsPost,
			params: RequestParams = {}
		) =>
			this.request<
				any,
				UploadPromoProductsErrorModel | ErrorModel | HTTPValidationError
			>({
				path: `/api/v1/promo-campaigns/${promoCampaignId}/products/`,
				method: 'POST',
				body: data,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),
	}
	healthcheck = {
		/**
		 * No description
		 *
		 * @tags Healthcheck
		 * @name DoHealthCheckHealthGet
		 * @summary  Do Health Check
		 * @request GET:/health
		 * @response `200` `any` Successful Response
		 */
		doHealthCheckHealthGet: (params: RequestParams = {}) =>
			this.request<any, any>({
				path: `/health`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	}
	cd = {
		/**
		 * No description
		 *
		 * @tags cd
		 * @name ListCompetitiveData
		 * @summary Get competitive data
		 * @request GET:/api/v1/competitive-data/
		 * @response `200` `CompetitiveDataResponseModel` Successful Response
		 */
		listCompetitiveData: (
			query: ListCompetitiveDataParams,
			params: RequestParams = {}
		) =>
			this.request<CompetitiveDataResponseModel, any>({
				path: `/api/v1/competitive-data/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cd
		 * @name ListPriceHistory
		 * @summary Get price history
		 * @request GET:/api/v1/price-history/
		 * @response `200` `PriceHistoryResponseModel` Successful Response
		 */
		listPriceHistory: (
			query: ListPriceHistoryParams,
			params: RequestParams = {}
		) =>
			this.request<PriceHistoryResponseModel, any>({
				path: `/api/v1/price-history/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags cd
		 * @name ListCompetitors
		 * @summary Get competitors
		 * @request GET:/api/v1/competitors/
		 * @response `200` `CompetitorsResponseModel` Successful Response
		 */
		listCompetitors: (
			query: ListCompetitorsParams,
			params: RequestParams = {}
		) =>
			this.request<CompetitorsResponseModel, any>({
				path: `/api/v1/competitors/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),
	}
	prometheusMetrics = {
		/**
		 * No description
		 *
		 * @tags Prometheus metrics
		 * @name PrometheusMetricsMetricsGet
		 * @summary Prometheus-Metrics
		 * @request GET:/metrics
		 * @response `200` `any` Successful Response
		 */
		prometheusMetricsMetricsGet: (params: RequestParams = {}) =>
			this.request<any, any>({
				path: `/metrics`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	}
	savedFilters = {
		/**
 * No description
 *
 * @tags saved-filters
 * @name ListGlobalFilters
 * @summary Get project global filters
 * @request GET:/api/v1/global_filters/
 * @response `200` `{
    success?: boolean,
    filters?: (SavedFilterModel)[],

}` Successful Response
 */
		listGlobalFilters: (params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
					filters?: SavedFilterModel[]
				},
				any
			>({
				path: `/api/v1/global_filters/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags saved-filters
 * @name CreateGlobalFilter
 * @summary Add new project global filter
 * @request POST:/api/v1/global_filters/
 * @response `201` `{
    success?: boolean,
    filter_id?: string,

}` Successful Response
 */
		createGlobalFilter: (
			data: SavedFilterCreateModel,
			params: RequestParams = {}
		) =>
			this.request<
				{
					success?: boolean
					filter_id?: string
				},
				any
			>({
				path: `/api/v1/global_filters/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags saved-filters
 * @name DeleteGlobalFilter
 * @summary Delete filter by id
 * @request DELETE:/api/v1/global_filters/{filter_id}/
 * @response `200` `{
    success?: boolean,

}` Successful Response
 */
		deleteGlobalFilter: (filterId: string, params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
				},
				any
			>({
				path: `/api/v1/global_filters/${filterId}/`,
				method: 'DELETE',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags saved-filters
		 * @name UpdateGlobalFilter
		 * @summary Update filter by id
		 * @request PATCH:/api/v1/global_filters/{filter_id}/
		 * @response `204` `void` Successful Response
		 */
		updateGlobalFilter: (
			filterId: string,
			data: SavedFilterUpdateModel,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/api/v1/global_filters/${filterId}/`,
				method: 'PATCH',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
 * No description
 *
 * @tags saved-filters
 * @name ListUserFilters
 * @summary Get user filters
 * @request GET:/api/v1/user_filters/
 * @response `200` `{
    success?: boolean,
    filters?: (SavedFilterModel)[],

}` Successful Response
 */
		listUserFilters: (params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
					filters?: SavedFilterModel[]
				},
				any
			>({
				path: `/api/v1/user_filters/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags saved-filters
 * @name CreateUserFilter
 * @summary Add new user filter
 * @request POST:/api/v1/user_filters/
 * @response `201` `{
    success?: boolean,
    filter_id?: string,

}` Successful Response
 */
		createUserFilter: (
			data: SavedFilterCreateModel,
			params: RequestParams = {}
		) =>
			this.request<
				{
					success?: boolean
					filter_id?: string
				},
				any
			>({
				path: `/api/v1/user_filters/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags saved-filters
 * @name DeleteUserFilter
 * @summary Delete filter by id
 * @request DELETE:/api/v1/user_filters/{filter_id}/
 * @response `200` `{
    success?: boolean,

}` Successful Response
 */
		deleteUserFilter: (filterId: string, params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
				},
				any
			>({
				path: `/api/v1/user_filters/${filterId}/`,
				method: 'DELETE',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags saved-filters
		 * @name UpdateUserFilter
		 * @summary Update filter by id
		 * @request PATCH:/api/v1/user_filters/{filter_id}/
		 * @response `204` `void` Successful Response
		 */
		updateUserFilter: (
			filterId: string,
			data: SavedFilterUpdateModel,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/api/v1/user_filters/${filterId}/`,
				method: 'PATCH',
				body: data,
				type: ContentType.Json,
				...params,
			}),
	}
	analytics = {
		/**
		 * No description
		 *
		 * @tags analytics
		 * @name GetApplicanceAnalytics
		 * @summary Price appliance in the last repricing periods
		 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/appliance_analytics/
		 * @response `200` `void` Successful Response
		 */
		getApplicanceAnalytics: (
			optimizationGroupId: string,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/appliance_analytics/`,
				method: 'GET',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags analytics
		 * @name GetOptimizationAnalytics
		 * @summary Dynamic data on repricing distribution in the last repricing periods
		 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/optimization_analytics/
		 * @response `200` `OptimizationAnalyticsResponseModel` Successful Response
		 */
		getOptimizationAnalytics: (
			optimizationGroupId: string,
			params: RequestParams = {}
		) =>
			this.request<OptimizationAnalyticsResponseModel, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/optimization_analytics/`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	}
	products = {
		/**
		 * No description
		 *
		 * @tags products
		 * @name UpdateFinalFields
		 * @summary Update optimization final-fields
		 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/final_fields/
		 * @response `200` `UpdateProductsFinalFieldsResponseModel` Successful Response
		 * @response `400` `UpdateProductsFinalFieldsErrorResponseModel` Successful Response
		 */
		updateFinalFields: (
			optimizationGroupId: string,
			optimizationId: string,
			data: FinalFieldsListSchema,
			params: RequestParams = {}
		) =>
			this.request<
				UpdateProductsFinalFieldsResponseModel,
				UpdateProductsFinalFieldsErrorResponseModel
			>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/optimizations/${optimizationId}/final_fields/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags products
		 * @name UpdateProducsModelFieldsByFilters
		 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/product_updates
		 * @response `200` `UpdateProductsFileldsByFiltersResponseModel` Successful Response
		 */
		updateProducsModelFieldsByFilters: (
			optimizationGroupId: string,
			optimizationId: string,
			data: UpdateProductsFieldsByFiltersRequestModel,
			params: RequestParams = {}
		) =>
			this.request<UpdateProductsFileldsByFiltersResponseModel, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/optimizations/${optimizationId}/product_updates`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags products
		 * @name UpdateProducsFinalFieldsByFilters
		 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/final-fields-updates
		 * @response `200` `UpdateProductsFileldsByFiltersResponseModel` Successful Response
		 */
		updateProducsFinalFieldsByFilters: (
			optimizationGroupId: string,
			optimizationId: string,
			data: UpdateProductsFieldsByFiltersRequestModel,
			params: RequestParams = {}
		) =>
			this.request<UpdateProductsFileldsByFiltersResponseModel, any>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/optimizations/${optimizationId}/final-fields-updates`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags products
		 * @name UpdateProductDiffs
		 * @summary Update optimization final-fields
		 * @request POST:/api/v1/optimization_groups/{optimization_group_id}/optimizations/{optimization_id}/product_diffs/
		 * @response `200` `UpdateProductsModelFieldsResponseModel` Successful Response
		 * @response `400` `UpdateProductsModelFieldsErrorResponseModel` Successful Response
		 */
		updateProductDiffs: (
			optimizationGroupId: string,
			optimizationId: string,
			data: ProductDiffsSchema,
			params: RequestParams = {}
		) =>
			this.request<
				UpdateProductsModelFieldsResponseModel,
				UpdateProductsModelFieldsErrorResponseModel
			>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/optimizations/${optimizationId}/product_diffs/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags products
 * @name ListOgProducts
 * @summary Download products table
 * @request GET:/api/v1/optimization_groups/{optimization_group_id}/products/
 * @response `200` `{
    success?: boolean,
    data?: (PricingLineModel)[],

}` Successful Response
 */
		listOgProducts: (optimizationGroupId: string, params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
					data?: PricingLineModel[]
				},
				any
			>({
				path: `/api/v1/optimization_groups/${optimizationGroupId}/products/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Get list of all project products
		 *
		 * @tags products
		 * @name ListProducts
		 * @request POST:/api/v1/products/query
		 * @response `200` `ProductsQueryResponseModel` Successful Response
		 */
		listProducts: (
			data: ProductsQueryRequestModel,
			params: RequestParams = {}
		) =>
			this.request<ProductsQueryResponseModel, any>({
				path: `/api/v1/products/query`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get list of all project products
		 *
		 * @tags products
		 * @name ExportProducts
		 * @request POST:/api/v1/products/export
		 * @response `200` `ProductsExportResponseModel` Successful Response
		 */
		exportProducts: (
			data: ProductsExportRequestModel,
			params: RequestParams = {}
		) =>
			this.request<ProductsExportResponseModel, any>({
				path: `/api/v1/products/export`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	}
	starboard = {
		/**
 * No description
 *
 * @tags starboard
 * @name V1StarboardMetricsCreate
 * @request POST:/api/v1/starboard-metrics/
 * @response `200` `{
  \** @default 0 *\
    gross_profit_init?: number,
  \** @default 0 *\
    gross_profit_opt?: number,
  \** @default 0 *\
    product_count?: number,
  \** @default 0 *\
    profit_margin_init?: number,
  \** @default 0 *\
    revenue_init?: number,
  \** @default 0 *\
    revenue_opt?: number,
  \** @default 0 *\
    sales_quantity?: number,
  \** @default 0 *\
    sold_items_cost?: number,

}` Successful Response
 */
		v1StarboardMetricsCreate: (
			data: V1StarboardMetricsCreateRequestModel,
			params: RequestParams = {}
		) =>
			this.request<
				{
					/** @default 0 */
					gross_profit_init?: number
					/** @default 0 */
					gross_profit_opt?: number
					/** @default 0 */
					product_count?: number
					/** @default 0 */
					profit_margin_init?: number
					/** @default 0 */
					revenue_init?: number
					/** @default 0 */
					revenue_opt?: number
					/** @default 0 */
					sales_quantity?: number
					/** @default 0 */
					sold_items_cost?: number
				},
				any
			>({
				path: `/api/v1/starboard-metrics/`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	}
	jsd = {
		/**
		 * @description This method returns all customer requests for the user executing the query. The returned customer requests are ordered chronologically by the latest activity on each request. For example, the latest status transition or comment. **[Permissions](#permissions) required**: Permission to access the specified service desk. **Response limitations**: For customers, the list returned will include request they created (or were created on their behalf) or are participating in only.
		 *
		 * @tags jsd
		 * @name GetCustomerRequests
		 * @summary Get customer requests
		 * @request GET:/jira-rest/servicedeskapi/request
		 * @secure
		 * @response `200` `PagedDTOCustomerRequestDTO` Returns the customer requests, on the specified page of the results.
		 * @response `401` `ErrorResponse` Returned if the user is not logged in.
		 * @response `404` `ErrorResponse` Returned if the user does not have permission to access the service desk, the service desk does not exist, or the service desk does not support the request type.
		 * @response `500` `ErrorResponse` Internal Server Error.
		 */
		getCustomerRequests: (
			query: GetCustomerRequestsParams,
			params: RequestParams = {}
		) =>
			this.request<PagedDTOCustomerRequestDTO, ErrorResponse>({
				path: `/jira-rest/servicedeskapi/request`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This method creates a customer request in a service desk. The JSON request must include the service desk and customer request type, as well as any fields that are required for the request type. A list of the fields required by a customer request type can be obtained using [servicedesk/\{serviceDeskId\}/requesttype/\{requestTypeId\}/field](#api-servicedesk-serviceDeskId-requesttype-requestTypeId-field-get). The fields required for a customer request type depend on the user's permissions: *  `raiseOnBehalfOf` is not available to Users who have the customer permission only. *  `requestParticipants` is not available to Users who have the customer permission only or if the feature is turned off for customers. `requestFieldValues` is a map of Jira field IDs and their values. See [Field input formats](#fieldformats), for details of each field's JSON semantics and the values they can take. **[Permissions](#permissions) required**: Permission to create requests in the specified service desk.
		 *
		 * @tags jsd
		 * @name CreateCustomerRequest
		 * @summary Create customer request
		 * @request POST:/jira-rest/servicedeskapi/request
		 * @secure
		 * @response `201` `CustomerRequestDTO` Returned if the customer request was created.
		 * @response `400` `ErrorResponse` Returned if the HTTP request call is invalid.
		 * @response `401` `ErrorResponse` Returned if the user is not logged in.
		 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
		 * @response `500` `ErrorResponse` Internal Server Error.
		 */
		createCustomerRequest: (
			data: RequestCreateDTO,
			params: RequestParams = {}
		) =>
			this.request<CustomerRequestDTO, ErrorResponse>({
				path: `/jira-rest/servicedeskapi/request`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This method returns all the attachments for a customer requests. **[Permissions](#permissions) required**: Permission to view the customer request. **Response limitations**: Customers will only get a list of public attachments.
		 *
		 * @tags jsd
		 * @name GetAttachmentsForRequest
		 * @summary Get attachments for request
		 * @request GET:/jira-rest/servicedeskapi/request/{issueIdOrKey}/attachment
		 * @secure
		 * @response `200` `PagedDTOAttachmentDTO` Returns the visible attachments from the customer request.
		 * @response `401` `ErrorResponse` Returned if the user is not logged in.
		 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
		 * @response `404` `ErrorResponse` Returned if the customer request does not exist.
		 * @response `500` `ErrorResponse` Internal Server Error.
		 */
		getAttachmentsForRequest: (
			{ issueIdOrKey, ...query }: GetAttachmentsForRequestParams,
			params: RequestParams = {}
		) =>
			this.request<PagedDTOAttachmentDTO, ErrorResponse>({
				path: `/jira-rest/servicedeskapi/request/${issueIdOrKey}/attachment`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This method adds one or more temporary files (attached to the request's service desk using [servicedesk/\{serviceDeskId\}/attachTemporaryFile](#api-servicedesk-serviceDeskId-attachTemporaryFile-post)) as attachments to a customer request and set the attachment visibility using the `public` flag. Also, it is possible to include a comment with the attachments. To get a list of attachments for a comment on the request use [servicedeskapi/request/\{issueIdOrKey\}/comment/\{commentId\}/attachment](#api-request-issueIdOrKey-comment-commentId-attachment-get). **[Permissions](#permissions) required**: Permission to add an attachment. **Request limitations**: Customers can set attachments to public visibility only.
		 *
		 * @tags jsd
		 * @name CreateAttachment
		 * @summary Create attachment
		 * @request POST:/jira-rest/servicedeskapi/request/{issueIdOrKey}/attachment
		 * @secure
		 * @response `201` `AttachmentCreateResultDTO` Returns the attachments and comment.
		 * @response `400` `ErrorResponse` Returned if the HTTP request is invalid, including missing the required `public` field or list of temporary file IDs, or if the temporary files have expired.
		 * @response `401` `ErrorResponse` Returned if the user is not logged in.
		 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
		 * @response `404` `ErrorResponse` Returned if the customer request does not exist.
		 * @response `500` `ErrorResponse` Internal Server Error.
		 */
		createAttachment: (
			issueIdOrKey: string,
			data: AttachmentCreateDTO,
			params: RequestParams = {}
		) =>
			this.request<AttachmentCreateResultDTO, ErrorResponse>({
				path: `/jira-rest/servicedeskapi/request/${issueIdOrKey}/attachment`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This method returns all customer request types used in the Jira Service Management instance, optionally filtered by a query string. Use [servicedeskapi/servicedesk/\{serviceDeskId\}/requesttype](#api-servicedesk-serviceDeskId-requesttype-get) to find the customer request types supported by a specific service desk. The returned list of customer request types can be filtered using the `query` parameter. The parameter is matched against the customer request types' `name` or `description`. For example, searching for "Install", "Inst", "Equi", or "Equipment" will match a customer request type with the *name* "Equipment Installation Request". **Note:** This API will filter out hidden request types (aka.request types without groups) when `query` is provided. **[Permissions](#permissions) required**: Any
		 *
		 * @tags jsd
		 * @name GetAllRequestTypes
		 * @summary Get all request types
		 * @request GET:/jira-rest/servicedeskapi/requesttype
		 * @secure
		 * @response `200` `PagedDTORequestTypeDTO` Returns the request types, on the specified page of the results.
		 * @response `401` `ErrorResponse` Returned if the user is not logged in.
		 * @response `500` `ErrorResponse` Internal Server Error.
		 */
		getAllRequestTypes: (
			query: GetAllRequestTypesParams,
			params: RequestParams = {}
		) =>
			this.request<PagedDTORequestTypeDTO, ErrorResponse>({
				path: `/jira-rest/servicedeskapi/requesttype`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This method adds one or more temporary attachments to a service desk, which can then be permanently attached to a customer request using [servicedeskapi/request/\{issueIdOrKey\}/attachment](#api-request-issueIdOrKey-attachment-post). **Note**: It is possible for a service desk administrator to turn off the ability to add attachments to a service desk. This method expects a multipart request. The media-type multipart/form-data is defined in RFC 1867. Most client libraries have classes that make dealing with multipart posts simple. For instance, in Java the Apache HTTP Components library provides [MultiPartEntity](http://hc.apache.org/httpcomponents-client-ga/httpmime/apidocs/org/apache/http/entity/mime/MultipartEntity.html). Because this method accepts multipart/form-data, it has XSRF protection on it. This means you must submit a header of X-Atlassian-Token: no-check with the request or it will be blocked. The name of the multipart/form-data parameter that contains the attachments must be `file`. For example, to upload a file called `myfile.txt` in the Service Desk with ID 10001 use curl -D- -u customer:customer -X POST -H "X-ExperimentalApi: opt-in" -H "X-Atlassian-Token: no-check" -F "file=@myfile.txt" https://your-domain.atlassian.net/rest/servicedeskapi/servicedesk/10001/attachTemporaryFile **[Permissions](#permissions) required**: Permission to add attachments in this Service Desk.
		 *
		 * @tags jsd
		 * @name AttachTemporaryFile
		 * @summary Attach temporary file
		 * @request POST:/jira-rest/servicedeskapi/servicedesk/{serviceDeskId}/attachTemporaryFile
		 * @secure
		 * @response `201` `void` Returns if the file(s) were attached.
		 * @response `400` `ErrorResponse` Returned if the attachments are not valid, or exceed the maximum configured attachment size.
		 * @response `401` `ErrorResponse` Returned if the user is not logged in.
		 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
		 * @response `404` `ErrorResponse` Returned if the service desk does not exist.
		 * @response `500` `ErrorResponse` Internal Server Error.
		 */
		attachTemporaryFile: (
			serviceDeskId: string,
			data: AttachTemporaryFileRequestModel,
			params: RequestParams = {}
		) =>
			this.request<void, ErrorResponse>({
				path: `/jira-rest/servicedeskapi/servicedesk/${serviceDeskId}/attachTemporaryFile`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
		 * @description This method returns a list of the customers on a service desk. The returned list of customers can be filtered using the `query` parameter. The parameter is matched against customers' `displayName`, `name`, or `email`. For example, searching for "John", "Jo", "Smi", or "Smith" will match a user with display name "John Smith". **[Permissions](#permissions) required**: Permission to view this Service Desk's customers.
		 *
		 * @tags jsd
		 * @name GetCustomers
		 * @summary Get customers
		 * @request GET:/jira-rest/servicedeskapi/servicedesk/{serviceDeskId}/customer
		 * @secure
		 * @response `200` `PagedDTOUserDTO` Returns the service desk's customer list.
		 * @response `401` `ErrorResponse` Returned if the user is not logged in.
		 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
		 * @response `404` `ErrorResponse` Returned if the service desk does not exist.
		 * @response `500` `ErrorResponse` Internal Server Error.
		 */
		getCustomers: (
			{ serviceDeskId, ...query }: GetCustomersParams,
			params: RequestParams = {}
		) =>
			this.request<PagedDTOUserDTO, ErrorResponse>({
				path: `/jira-rest/servicedeskapi/servicedesk/${serviceDeskId}/customer`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Adds one or more customers to a service desk. If any of the passed customers are associated with the service desk, no changes will be made for those customers and the resource returns a 204 success code. **[Permissions](#permissions) required**: Service desk administrator
		 *
		 * @tags jsd
		 * @name AddCustomers
		 * @summary Add customers
		 * @request POST:/jira-rest/servicedeskapi/servicedesk/{serviceDeskId}/customer
		 * @secure
		 * @response `204` `void` Returned if all the customers were added to the service desk or were already associated with the service desk.
		 * @response `400` `ErrorResponse` Returned if any of the customers do not exist. Note that any valid customers are added, but no confirmation is returned.
		 * @response `401` `ErrorResponse` Returned if the user is not logged in.
		 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
		 * @response `404` `ErrorResponse` Returned if the service desk does not exist.
		 * @response `500` `ErrorResponse` Internal Server Error.
		 */
		addCustomers: (
			serviceDeskId: string,
			data: ServiceDeskCustomerDTO,
			params: RequestParams = {}
		) =>
			this.request<void, ErrorResponse>({
				path: `/jira-rest/servicedeskapi/servicedesk/${serviceDeskId}/customer`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description This method removes one or more customers from a service desk. The service desk must have closed access. If any of the passed customers are not associated with the service desk, no changes will be made for those customers and the resource returns a 204 success code. **[Permissions](#permissions) required**: Services desk administrator
		 *
		 * @tags jsd
		 * @name RemoveCustomers
		 * @summary Remove customers
		 * @request DELETE:/jira-rest/servicedeskapi/servicedesk/{serviceDeskId}/customer
		 * @secure
		 * @response `204` `void` Returned if the customers were removed from the service desk, or any of the customers were not associated with the service desk.
		 * @response `400` `ErrorResponse` Returned if the service desk has public signup or open access enabled.
		 * @response `401` `ErrorResponse` Returned if the user is not logged in.
		 * @response `403` `ErrorResponse` Returned if the user does not have permission to complete this request.
		 * @response `404` `ErrorResponse` Returned if the service desk does not exist.
		 * @response `500` `ErrorResponse` Internal Server Error.
		 */
		removeCustomers: (
			serviceDeskId: string,
			data: ServiceDeskCustomerDTO,
			params: RequestParams = {}
		) =>
			this.request<void, ErrorResponse>({
				path: `/jira-rest/servicedeskapi/servicedesk/${serviceDeskId}/customer`,
				method: 'DELETE',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	}
}
