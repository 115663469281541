import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
	SettingsTemplateApplyRequestModel,
	SettingsTemplateCreateModel,
	SettingsTemplateModel,
	SettingsTemplateUpdateModel,
} from '@/generated'
import { client } from '@/network/client'

export function useCreateSettingsTemplateMutation(
	options?: UseMutationOptions<
		SettingsTemplateModel,
		AxiosError,
		SettingsTemplateCreateModel
	>
) {
	return useMutation<
		SettingsTemplateModel,
		AxiosError,
		SettingsTemplateCreateModel
	>({
		mutationFn: (data: SettingsTemplateCreateModel) =>
			client.settingsTemplate.settingsTemplateCreate(data),
		...(options || {}),
	})
}
export function useUpdateSettingsTemplateMutation(
	settingsTemplateId: string,
	options?: UseMutationOptions<
		SettingsTemplateModel,
		AxiosError,
		SettingsTemplateUpdateModel
	>
) {
	return useMutation<
		SettingsTemplateModel,
		AxiosError,
		SettingsTemplateUpdateModel
	>({
		mutationFn: (data: SettingsTemplateUpdateModel) =>
			client.settingsTemplate.settingsTemplateUpdate(settingsTemplateId, data),
		...(options || {}),
	})
}
export function useDeleteSettingsTemplateMutation(
	options?: UseMutationOptions<void, AxiosError, string>
) {
	return useMutation<void, AxiosError, string>({
		mutationFn: (settingsTemplateId: string) =>
			client.settingsTemplate.settingsTemplateDelete(settingsTemplateId),
		...(options || {}),
	})
}

type PayloadType = {
	templateId: string
	data: SettingsTemplateApplyRequestModel
}
export function useApplySettingsTemplateMutation(
	options?: UseMutationOptions<void, AxiosError, PayloadType>
) {
	return useMutation<void, AxiosError, PayloadType>({
		mutationFn: ({ templateId, data }: PayloadType) =>
			client.settingsTemplate.settingsTemplateApply(templateId, data),
		...(options || {}),
	})
}
