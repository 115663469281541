import * as qs from 'qs'
import React, { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
	CellContext,
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'

import { LinkButton } from '@cmpkit/base'
import RelationGraphIcon from '@cmpkit/icon/lib/glyph/relation-graph'
import Tooltip from '@cmpkit/tooltip'

import NumberField from '@/components/data-grid/fields/Number/TableCell'
import { ColumnDataType } from '@/components/data-grid/types'
import { DataTable } from '@/components/tables'
import intl from '@/locale'

export type AnchoringRow = {
	is_anchor?: boolean
	is_current?: boolean
	sku: string
	title?: string
	add_idx: number
	mul_idx: number
}
export default function AnchoringTypeTable({ rows }: { rows: AnchoringRow[] }) {
	const [sorting, setSorting] = useState<SortingState>([
		{
			id: 'is_anchor',
			desc: true,
		},
	])
	const columns = useMemo(() => getColumns(), [])
	const table = useReactTable<AnchoringRow>({
		data: rows,
		columns,
		state: {
			sorting,
		},
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return (
		<div className='flex max-h-[350px] w-full flex-col overflow-hidden rounded-lg border border-solid border-base'>
			<DataTable
				compact
				getRowClassName={(row) => {
					return row?.original.is_current ? 'h-9 bg-brand-25' : 'h-9'
				}}
				table={table}
			/>
		</div>
	)
}
const columnHelper = createColumnHelper<AnchoringRow>()
const getColumns = () => [
	columnHelper.accessor('sku', {
		size: 120,
		header: () => intl.get('sku'),
		cell: (info) => {
			return <SkuCell info={info} />
		},
	}),
	columnHelper.accessor('add_idx', {
		size: 65,
		header: () => intl.get('add_idx').d('Add idx'),
		cell: (info) => {
			return (
				<NumberField
					value={info.getValue()}
					schema={{ type: ColumnDataType.Number }}
				/>
			)
		},
	}),
	columnHelper.accessor('mul_idx', {
		size: 60,
		header: () => intl.get('mul_idx').d('Mul idx'),
		cell: (info) => {
			return (
				<NumberField
					value={info.getValue()}
					schema={{ type: ColumnDataType.Number }}
				/>
			)
		},
	}),
]

function SkuCell({ info }: { info: CellContext<AnchoringRow, string> }) {
	const location = useLocation()
	const query = qs.parse(location.search, { ignoreQueryPrefix: true })

	return (
		<span className='flex items-center space-x-1'>
			<Tooltip
				content={
					<>
						{info.row.original.title}
						<br />
						{info.getValue()}
					</>
				}
			>
				<LinkButton variant='brand' asChild className='truncate'>
					<Link
						to={qs.stringify(
							{
								...query,
								selectedSku: info.row.original.sku,
							},
							{ addQueryPrefix: true }
						)}
					>
						{info.row.original?.is_anchor && (
							<RelationGraphIcon className='fill-base' />
						)}
						{info.getValue()}
					</Link>
				</LinkButton>
			</Tooltip>
		</span>
	)
}
