import { addMonths, format } from 'date-fns'
import { pipe, prop } from 'lodash/fp'
import React, { useMemo } from 'react'

import { DeltaValue } from '@cmpkit/base'
import { Column } from '@cmpkit/data-table'

import intl from '@/locale'
import { useBIAnalyticQuery } from '@/modules/bi/queries'
import { zipSeries } from '@/modules/bi/utils'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { MainDashboardFilter } from '../../Filter'
import TableView from '../../TableView'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetEmptyState from '../../WidgetEmptyState'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetProps } from '../types'
import { createRows, getStructuredData } from './calculations'

const getRowId = prop('key')

export default function OptimizationHistoryTableWidget({
	widget,
	params,
	commonFilters,
	onChangeParams,
}: WidgetProps) {
	const filters = [...commonFilters, ...(params.filters ?? [])]
	const biQuery = useBIAnalyticQuery({
		label: widget.kind,
		start_date: format(addMonths(new Date(), -2), DateFormat.system),
		end_date: format(addMonths(new Date(), 1), DateFormat.system),
		metrics: ['assortment', 'average_price_change'],
		dimensions: ['optimization_lag', 'price_change_status'],
		filters: [
			...filters,
			{
				name: 'optimization_lag',
				operation: 'lte',
				value: 5,
			},
			{
				name: 'is_repricing_today',
				operation: 'bl',
				value: true,
			},
		],
		skip_dates: true,
		date_aggregation: null,
	})

	const rows = createRows(
		getStructuredData(
			zipSeries(biQuery.data?.meta || [], biQuery.data?.data || [])
		)
	)
	const columns = useMemo(getColumns, [])
	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.optmization_history_table.title')}
				tooltip={intl.get('widget.optmization_history_table.tooltip')}
			>
				<div className='ml-auto flex items-center'>
					<MainDashboardFilter
						irremovableCount={commonFilters.length}
						filters={filters ?? []}
						onChange={(filters) => {
							analytic.logEvent(`dashboard: bi: apply filter ${widget.kind}`, {
								'filter by': filters.map(prop('name')).join(','),
							})
							onChangeParams({ ...params, filters })
						}}
					/>
				</div>
			</WidgetHeader>
			<WidgetLoadingState isTinted={biQuery.isLoading} />
			<WidgetErrorState isTinted={biQuery.isError} />
			<WidgetContent>
				{biQuery.data?.data?.length && !biQuery.isLoading ? (
					<TableView
						getRowHeight={() => 35}
						className='h-52'
						rows={rows}
						columns={columns}
						isLoading={biQuery.isLoading}
						getRowId={getRowId}
					/>
				) : (
					<WidgetEmptyState />
				)}
			</WidgetContent>
		</Widget>
	)
}
function getColumns(): Column[] {
	return [
		new Column({
			id: 'key',
			title: intl.get('table.col.distr.name'),
			locked: true,
			sortable: true,
			valueGetter: pipe([
				prop('key'),
				(key) => intl.get(`overview_price_${key}`).d(key),
			]),
			width: 142,
		}),
		new Column({
			id: 'op1_assortment',
			title: intl.get('table.col.avg_last.name'),
			sortable: true,
			valueGetter: prop('op1_assortment'),
			renderer: (value) => formatNumber(value),
			width: 100,
		}),
		new Column({
			id: 'op1_price_delta',
			title: intl.get('table.col.delt.name'),
			sortable: true,
			valueGetter: prop('op1_price_delta'),
			renderer: (value, { op1_assortment }) =>
				op1_assortment !== 0 ? (
					<DeltaValue value={value}>
						{formatNumber(value, NumberFormats.Percent)}
					</DeltaValue>
				) : (
					'-'
				),
			width: 90,
		}),
		new Column({
			id: 'op2_assortment',
			title: intl.get('table.col.avg_past.name'),
			sortable: true,
			valueGetter: prop('op2_assortment'),
			renderer: (value) => formatNumber(value),
			width: 100,
		}),
		new Column({
			id: 'op2_price_delta',
			title: intl.get('table.col.delt.name'),
			sortable: true,
			valueGetter: prop('op2_price_delta'),
			renderer: (value, { op2_assortment }) =>
				op2_assortment !== 0 ? (
					<DeltaValue value={value}>
						{formatNumber(value, NumberFormats.Percent)}
					</DeltaValue>
				) : (
					'-'
				),
			width: 90,
		}),
		new Column({
			id: 'op4_price_delta',
			title: intl.get('table.col.avg_period.name'),
			sortable: true,
			valueGetter: prop('op4_price_delta'),
			renderer: (value) =>
				!Number.isNaN(value) ? (
					<DeltaValue value={value}>
						{formatNumber(value, NumberFormats.Percent)}
					</DeltaValue>
				) : (
					'-'
				),
			width: 90,
		}),
	]
}
