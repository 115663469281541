import Highcharts from 'highcharts'
import { any, prop, uniqBy } from 'lodash/fp'
import React, { Fragment, useCallback, useMemo, useState } from 'react'

import {
	Header,
	SegmentGroup,
	SegmentGroupItem,
	treeHelpers,
} from '@cmpkit/base'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import Tooltip from '@cmpkit/tooltip'

import Chart from '@/components/Chart'
import {
	filterNodesOfTree,
	flatTree,
	mapNodesOfTree,
} from '@/components/TreePicker/helpers'
import {
	SalesLevelModel,
	TreeMapProductGroupModel,
	TreeMapSalesEntityModel,
} from '@/generated'
import intl from '@/locale'
import { getStatusPlainColor } from '@/modules/core/utils'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { getProductGroupsTree, getSalesEntitiesTree } from '../../../helpers'
import {
	useDryCategoriesQuery,
	useOgTreeMapByProductGroupsQuery,
	useOgTreeMapBySalesEntitiesQuery,
	useProductGroupsQuery,
	useSalesEntitiesQuery,
} from '../../../queries'
import { OptimizationGroupTableRow } from '../../../types'
import {
	buildTree,
	getLevelsStatistics,
	getProductGroupsSatistics,
	getSalesEntitySatistics,
	GetterArgs,
	getTreeNodesIds,
} from './helpers'
import { ChartTreePoint } from './types'

export default function OptimizationGroupsTreeChart({
	data,
	height,
}: {
	data: OptimizationGroupTableRow[]
	height: number
}) {
	const [viewBy, setViewBy] = useState<'sales_entity' | 'product_group'>(
		'sales_entity'
	)
	const [coloredBy, setColoredBy] = useState<'alerts' | 'status'>('alerts')
	const statisticsBySalesEntitiesQuery = useOgTreeMapBySalesEntitiesQuery({})
	const statisticsByProductGroupsQuery = useOgTreeMapByProductGroupsQuery({})
	const statisticsByNodes =
		viewBy === 'sales_entity'
			? statisticsBySalesEntitiesQuery.data
			: statisticsByProductGroupsQuery.data
	const salesEntitiesQuery = useSalesEntitiesQuery()
	const productGroupsQuery = useProductGroupsQuery()
	const categoriesQuery = useDryCategoriesQuery()
	const salesEntitiesTree = useMemo(
		() => getSalesEntitiesTree(salesEntitiesQuery.data),
		[salesEntitiesQuery.data]
	)

	const productGroupsTree = useMemo(
		() =>
			getProductGroupsTree(
				productGroupsQuery.data,
				uniqBy('id', categoriesQuery.data)
			),
		[categoriesQuery.data, productGroupsQuery.data]
	)
	const getEntities = useCallback(
		(row: OptimizationGroupTableRow) => {
			if (viewBy === 'sales_entity') {
				const ids = getTreeNodesIds(
					salesEntitiesTree,
					treeHelpers.getChildrenIds(
						treeHelpers.findNodes(salesEntitiesTree, row.group.sales_entities)
					)
				)

				const filterdTree = filterNodesOfTree(
					salesEntitiesTree,
					(item) =>
						ids.includes(item.id) || ids.includes(item.parent?.id as string)
				)

				//const rootId = filterdTree[0]?.id
				const flattree = flatTree(
					mapNodesOfTree(
						filterdTree,
						(node) => node
						//node.parent?.id === rootId ? { ...node, parent: undefined } : node
					)
				)

				// TODO: pay attention to this filter it may be needed to remove root node
				return flattree //.filter((item) => item.id !== rootId)
			} else {
				const ids = getTreeNodesIds(
					productGroupsTree,
					treeHelpers.getChildrenIds(
						treeHelpers.findNodes(productGroupsTree, row.product_groups)
					)
				)
				return (
					flatTree(productGroupsTree)?.filter(
						(item) =>
							ids.includes(item.id) || ids.includes(item.parent?.id as string)
					) || []
				)
			}
		},
		[salesEntitiesTree, productGroupsTree, viewBy]
	)
	const getChildrenStatistics = useCallback(
		(filterIds: string[], ids: string[]) => {
			return getLevelsStatistics(salesEntitiesTree, filterIds, ids)
		},
		[salesEntitiesTree]
	)
	const getAdditionalData = useCallback(
		(params: GetterArgs) => {
			if (viewBy === 'sales_entity') {
				return getSalesEntitySatistics(
					statisticsByNodes as TreeMapSalesEntityModel[],
					params
				)
			} else {
				return getProductGroupsSatistics(
					statisticsByNodes as TreeMapProductGroupModel[],
					params
				)
			}
		},
		[statisticsByNodes]
	)
	const getNodeValue = useCallback(
		(params: GetterArgs) => {
			if (viewBy === 'sales_entity') {
				const stat = getSalesEntitySatistics(
					statisticsByNodes as TreeMapSalesEntityModel[],
					params
				)
				return stat?.revenue || 0
			} else {
				const stat = getProductGroupsSatistics(
					statisticsByNodes as TreeMapProductGroupModel[],
					params
				)
				return stat?.revenue || 0
			}
		},
		[statisticsByNodes]
	)

	const getNodeColoring = useCallback(
		(params: GetterArgs) => {
			if (coloredBy === 'alerts') {
				if (viewBy === 'sales_entity') {
					const stat = getSalesEntitySatistics(
						statisticsByNodes as TreeMapSalesEntityModel[],
						params
					)
					const percent =
						((stat?.revenue_with_alerts || 0) / (stat?.revenue || 1)) * 100
					return {
						colorValue: percent,
					}
				} else {
					const stat = getProductGroupsSatistics(
						statisticsByNodes as TreeMapProductGroupModel[],
						params
					)
					const percent =
						((stat?.revenue_with_alerts || 0) / (stat?.revenue || 1)) * 100
					return {
						colorValue: percent,
					}
				}
			} else {
				return {
					color: getStatusPlainColor(params.row.optimization.status),
				}
			}
		},
		[coloredBy, viewBy, statisticsByNodes]
	)
	const points = useMemo(() => {
		if (
			!data.length ||
			!salesEntitiesQuery.data?.sales_entities?.length ||
			!productGroupsQuery.data?.length ||
			!categoriesQuery.data?.length
		) {
			return []
		}
		return buildTree(data, {
			getEntities,
			getNodeValue,
			getAdditionalData,
			getChildrenStatistics,
			getNodeColoring,
		})
	}, [
		data,
		getEntities,
		getNodeValue,
		getAdditionalData,
		getChildrenStatistics,
		getNodeColoring,
		coloredBy,
		viewBy,
	])

	const isLoading = any(prop('isLoading'), [
		statisticsBySalesEntitiesQuery,
		statisticsByProductGroupsQuery,
		salesEntitiesQuery,
		productGroupsQuery,
		categoriesQuery,
	])

	if (isLoading) {
		return <Loader />
	}
	return (
		<>
			<Header className='mb-2 flex items-center space-x-2'>
				<div className='mr-auto flex items-center space-x-2'>
					<h6 className='text-lg font-semibold'>
						{intl
							.get('optimization_groups_by_revenue')
							.d('Optimization groups by Revenue')}
					</h6>
					<Tooltip
						content={intl
							.get('app.optimization_groups_by_revenue.tooltip')
							.d(
								'Potential Revenue calculated current stock volume and prices'
							)}
					>
						<InfoIcon />
					</Tooltip>
				</div>

				<div className='flex items-center space-x-1'>
					<span className='text-xs font-semibold'>
						{intl.get('app.show_by').d('Show by')}:
					</span>
					<SegmentGroup size='small'>
						<SegmentGroupItem
							active={viewBy === 'sales_entity'}
							onClick={() => setViewBy('sales_entity')}
						>
							{intl.get('sales_units').d('Sales units')}
						</SegmentGroupItem>
						<SegmentGroupItem
							active={viewBy === 'product_group'}
							onClick={() => setViewBy('product_group')}
						>
							{intl.get('product_groups').d('Product groups')}
						</SegmentGroupItem>
					</SegmentGroup>
				</div>
				<div className='flex items-center space-x-1'>
					<span className='text-xs font-semibold'>
						{intl.get('app.colored_by').d('Colored by')}:
					</span>
					<SegmentGroup size='small'>
						<SegmentGroupItem
							active={coloredBy === 'alerts'}
							onClick={() => setColoredBy('alerts')}
						>
							{intl.get('alerts').d('Alerts')}
						</SegmentGroupItem>
						<SegmentGroupItem
							active={coloredBy === 'status'}
							onClick={() => setColoredBy('status')}
						>
							{intl.get('app.status').d('Status')}
						</SegmentGroupItem>
					</SegmentGroup>
				</div>
			</Header>
			<TreeChart
				viewBy={viewBy}
				coloredBy={coloredBy}
				height={height}
				points={points}
				levels={salesEntitiesQuery.data?.sales_levels || []}
			/>
		</>
	)
}
function TreeChart({
	viewBy,
	coloredBy,
	height,
	points,
	levels,
}: {
	viewBy: 'sales_entity' | 'product_group'
	coloredBy: 'alerts' | 'status'
	height: number
	points: ChartTreePoint[]
	levels: SalesLevelModel[]
}) {
	return (
		<Chart
			key={`${viewBy}_${coloredBy}`}
			config={getChartConfig({
				isLengendEnabled: coloredBy === 'alerts',
				height,
				points,
				levels,
			})}
		/>
	)
}
function getChartConfig({
	height,
	points,
	levels,
	isLengendEnabled,
}: {
	height: number
	points: ChartTreePoint[]
	levels: SalesLevelModel[]
	isLengendEnabled?: boolean
}) {
	function formatter(
		this: any, // eslint-disable-line @typescript-eslint/no-explicit-any
		options: Highcharts.BreadcrumbOptions
	) {
		const name = options?.levelOptions?.name || ''
		return `${name?.length > 50 ? name?.slice(0, 50) + '...' : name}`
	}
	return {
		chart: {
			height,
			spacingTop: 0,
			spacingRight: 0,
			spacingBottom: 0,
			marginTop: 0,
			spacingLeft: 0,
			plotBorderWidth: 0,
			marginRight: 0,
			marginLeft: 0,
		},
		colorAxis: {
			minColor: '#CCEFD5',
			maxColor: '#FBD1CE',
		},
		navigation: {
			enabled: false,
			breadcrumbs: {
				separator: {
					text: '/',
				},
				buttonTheme: {
					style: {
						paddingLeft: 8,
						paddingRight: 8,
					},
				},
				useHTML: true,
				formatter:
					formatter as unknown as Highcharts.BreadcrumbsFormatterCallbackFunction,
			},
		},
		plotOptions: {
			treemap: {
				turboThreshold: 0,
				borderColor: 'var(--cmp-application)',
				borderWidth: 2,
			},
		},
		legend: {
			margin: 0,
			enabled: isLengendEnabled,
		},

		tooltip: {
			outside: false,
			followPointer: true,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter: function (this: Highcharts.Point) {
				const self = this as unknown as any // eslint-disable-line
				const point = self.point as any // eslint-disable-line
				const additioalData = point['additional']()

				const statistics = additioalData?.statistics || {}
				const stat = Object.entries(statistics)
					.map(
						([lvl, value]) =>
							`<div>${levels.find((item) => item.level == Number(lvl))?.name}: <strong>${value}</strong> </div>`
					)
					.join(' ')
				const showAlerts = point.colorValue !== undefined
				const showStatus = !showAlerts
				const alertsCount = point.colorValue || 0
				const status = additioalData.row?.optimization?.status
				return `
				<div class="chart-tooltip min-w-[300px] max-w-[500px] overflow-hidden text-wrap">
					<h6>${this.key}</h6>
					<div class="mt-2 space-y-0.5">
					<div>${intl.get('inventory_value').d('Inventory value')}: <strong>${formatNumber(point.value!, NumberFormats.Compact)}</strong></div>
					<div>${intl.get('sku').d('SKU')}: <strong>${formatNumber(additioalData?.products || 0)}</strong></div>
					${showAlerts ? `<div>${intl.get('alerts').d('Alerts')}: <strong>${formatNumber(alertsCount, NumberFormats.Percent)}</strong></div>` : ''}
					${showStatus ? `<div>${intl.get('app.status').d('Status')}: <strong>${intl.get('opt_' + status).d(status)}</strong></div>` : ''}
					${stat}
					</div>
					
				</div>`
			},
		},
		series: [
			{
				name: intl.get('optimization_groups').d('Optimization groups'),
				type: 'treemap',
				layoutAlgorithm: 'squarified',
				alternateStartingDirection: true,
				allowTraversingTree: true,
				animationLimit: 1000,
				borderRadius: 4,
				dataLabels: {
					enabled: false,
					verticalAlign: 'middle',
					align: 'center',
				},

				crisp: false,
				clip: false,
				cropThreshold: 1200,
				inactiveOtherPoints: true,
				levelIsConstant: false,
				states: {
					hover: {
						borderColor: 'var(--cmp-application)',
					},
				},
				levels: [
					{
						level: 1,
						borderWidth: 6,
						dataLabels: {
							verticalAlign: 'top',
							align: 'left',
							//allowOverlap: true,
							enabled: true,
							formatter(this: Highcharts.Point) {
								const self = this as unknown as any // eslint-disable-line
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								const info = (self.point as any).additional()
								return `<a href="${info.link}" target="_blank" class="bg-application text-foreground truncate px-2 py-1 rounded-md block m-2">${this.key}</span>`
							},
							useHTML: true,
							padding: 0,
						},
					},
					{
						borderWidth: 6,
						dataLabels: {
							verticalAlign: 'middle',
							align: 'center',
						},
					},
				],
				accessibility: {
					exposeAsGroupOnly: true,
				},
				data: points,
			},
		] as Highcharts.SeriesOptionsType[],
		subtitle: {
			text: '',
		},
		title: {
			text: '',
		},
	}
}
function Loader() {
	return (
		<div className='grid h-full grid-flow-col grid-rows-2 gap-3'>
			<div className='row-span-3 animate-pulse rounded-lg bg-accent-4' />
			<div className='col-span-2 animate-pulse rounded-lg bg-accent-4' />
			<div className='col-span-2 row-span-2 animate-pulse rounded-lg bg-accent-4' />
		</div>
	)
}
