import { SeriesAreaOptions } from 'highcharts'
import { any, isNil, last, pipe, prop, props, zipObject } from 'lodash/fp'
import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'

import {
	Button,
	Card,
	Checkbox,
	SegmentGroup,
	SegmentGroupItem,
} from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import XCircleIcon from '@cmpkit/icon/lib/glyph/x-circle'
import Popover from '@cmpkit/popover'
import Select, { components, OptionProps, StylesConfig } from '@cmpkit/select'
import { GroupBase, MultiValue, SingleValue } from '@cmpkit/select/dist/types'
import Tooltip from '@cmpkit/tooltip'

import { filterPricingAndBusinessMetrics } from '@/components/BI/utils'
import intl from '@/locale'
import { useBIAnalyticQuery, useMetricsQuery } from '@/modules/bi/queries'
import {
	BIResponse,
	DateAggregation,
	MetricOption,
	MetricsDropDown,
} from '@/modules/bi/types'
import {
	formatMetrics,
	sanitiezeResponseBySelectedMetrics,
	zipSeries,
} from '@/modules/bi/utils'
import analytic from '@/services/analytics'
import { utcToZonedTime } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { MainDashboardFilter } from '../../Filter'
import PercentDifferenceBadge from '../../PercentDifferenceBadge'
import { MultiPeriodsSelect } from '../../Periods'
import { Widget, WidgetContent } from '../../Widget'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetPeriods, WidgetProps } from '../types'
import BusinessMetricsChart from './Chart'

export default function BusinessMetrics({
	widget,
	params,
	onChangeParams,
	commonPeriods,
	commonFilters,
}: WidgetProps) {
	/**
	 * State Hooks
	 */
	const [aggregation, setAggregation] = useState<DateAggregation>(
		DateAggregation.DateKey
	)
	const [mainMetric, setMainMetric] = useState<string>('revenue')
	const [additionalMetrics, setAdditionalMetrics] = useState<string[]>([
		'sales_items',
		'gross_profit_margin',
	])
	const [periods, setPeriods] = useState<WidgetPeriods | null>(commonPeriods)
	const defaultPeriods = commonPeriods

	const filters = [...commonFilters, ...(params.filters ?? [])]
	/**
	 * Queries
	 */
	const { data: metrics, ...metricsQuery } = useMetricsQuery<MetricOption[]>({
		placeholderData: [],
		select: selectMetrics,
	})
	/**
	 * Bi Request body
	 */
	const commonQueryParams = {
		label: widget.kind,
		filters,
		metrics: [...(metrics || []).map(prop('value'))] as string[],
		dimensions: [],
		date_aggregation: aggregation,
	}
	const commonQueryConfig = {
		enabled: !!metrics?.length,
		select: (res: BIResponse | null) =>
			res &&
			sanitiezeResponseBySelectedMetrics(res, [
				mainMetric,
				...additionalMetrics,
			]),
	}

	const mainSummaryQuery = useBIAnalyticQuery(
		{
			...commonPeriods.main,
			...(periods?.main || {}),
			...commonQueryParams,
			date_aggregation: null,
		},
		commonQueryConfig
	)

	const comparedSummaryQuery = useBIAnalyticQuery(
		{
			...commonPeriods.compared,
			...(periods?.compared || {}),
			...commonQueryParams,
			date_aggregation: null,
		},
		commonQueryConfig
	)

	const mainPeriodQuery = useBIAnalyticQuery(
		{
			...commonPeriods.main,
			...(periods?.main || {}),
			...commonQueryParams,
		},
		commonQueryConfig
	)
	const comparedPeriodQuery = useBIAnalyticQuery(
		{
			...commonPeriods.compared,
			...(periods?.compared || {}),
			...commonQueryParams,
		},
		commonQueryConfig
	)

	/**
	 * Calculated data
	 */
	const isLoading = any(prop('isLoading'), [
		metricsQuery,
		mainPeriodQuery,
		comparedPeriodQuery,
		mainSummaryQuery,
		comparedSummaryQuery,
	])
	const isError = any(prop('isError'), [
		metricsQuery,
		mainPeriodQuery,
		comparedPeriodQuery,
		mainSummaryQuery,
		comparedSummaryQuery,
	])
	const chartParams = buildSeries(
		mainPeriodQuery.data,
		metrics || [],
		mainMetric
	)
	const lastPoints = getLastPoints(
		mainSummaryQuery.data,
		comparedSummaryQuery.data
	)

	const handleResetAddtitionalMetrics = () => setAdditionalMetrics([])
	const orderedMetrics: MetricOption[] = metrics || []
	const orderedAdditionalMetrics = orderedMetrics
		.filter((metric) => additionalMetrics.includes(metric.value))
		.map((metric) => metric.value)
	return (
		<div className={'widget__root space-y-3 p-5'}>
			<div className='flex items-center justify-between'>
				<h3 className='text-2xl font-bold'>
					{intl.get('widget.business_metrics.title')}
					<Tooltip content={intl.get('widget.business_metrics.tooltip')}>
						<InfoIcon className='ml-2' />
					</Tooltip>
				</h3>
				<div className='cmp-border-bottom p-2'>
					<MultiPeriodsSelect
						periods={periods!}
						defaultPeriods={defaultPeriods}
						onChangePeriods={setPeriods}
					/>
				</div>
			</div>
			<div className='flex justify-between'>
				<div className='flex items-center'>
					<div className='cmp-border-right flex'>
						<MainMetricDropDown
							className='mr-2'
							options={orderedMetrics.filter(
								(metric) => !additionalMetrics.includes(metric.value)
							)}
							value={mainMetric}
							onChange={({ value }: MetricOption) => {
								analytic.logEvent(
									'dashboard: bi: main metric choice for historical graph',
									{ value }
								)
								setMainMetric(value)
							}}
						/>
						<AdditionalMetricsDropDown
							className='mr-3'
							options={orderedMetrics.filter(
								(metric) => mainMetric !== metric.value
							)}
							values={additionalMetrics}
							onClear={handleResetAddtitionalMetrics}
							onChange={(metrics: MetricOption[]) => {
								const values = metrics.map(({ value }) => value)
								analytic.logEvent(
									'dashboard: bi: additional metrics choise for historical graph',
									{ values }
								)
								setAdditionalMetrics(values)
							}}
						/>
					</div>
					<div className='ml-2'>
						<MainDashboardFilter
							irremovableCount={commonFilters.length}
							filters={filters || []}
							onChange={(filters) => {
								onChangeParams({ ...params, filters })
							}}
						/>
					</div>
				</div>
				<div className='flex'>
					{metrics?.length &&
						[mainMetric, ...orderedAdditionalMetrics.slice(0, 2)].map((key) => {
							const metric = metrics.find((metric) => metric.value === key)!
							const currentValue = Number(lastPoints.current?.[key] || 0)
							const prevValue = Number(lastPoints.compared?.[key] || 0)
							const diffPercent = (currentValue * 100) / prevValue - 100
							return (
								<div key={key} className='mr-4'>
									<span className='text-xs font-medium text-muted'>
										{metric.label}
									</span>
									{isLoading ? (
										<SkeletonLoader />
									) : (
										<div className='flex items-center'>
											<h4 className='mr-1 text-2xl font-medium'>
												{isNil(currentValue)
													? '-'
													: formatNumber(currentValue, NumberFormats.Compact)}
											</h4>
											{Number.isFinite(diffPercent) && (
												<div className='flex flex-col'>
													<PercentDifferenceBadge value={diffPercent} />
												</div>
											)}
										</div>
									)}
								</div>
							)
						})}
				</div>
			</div>
			<Widget className='shadow-none'>
				<WidgetLoadingState isTinted={isLoading} />
				<WidgetErrorState isTinted={isError} />
				<WidgetContent className={'p-0'}>
					<div className='mb-3 flex items-center justify-between'>
						<SegmentGroup size='small'>
							{[
								DateAggregation.DateKey,
								DateAggregation.WeekKey,
								DateAggregation.MonthKey,
							].map((freqOption) => {
								return (
									<SegmentGroupItem
										icon
										className='px-2'
										key={freqOption}
										active={aggregation === freqOption}
										onClick={() => {
											analytic.logEvent(
												'dashboard: bi: switch revenue period in metric to compare',
												{
													value: freqOption,
												}
											)
											setAggregation(freqOption)
										}}
									>
										{intl.get(`agg_short_${freqOption}`)}
									</SegmentGroupItem>
								)
							})}
						</SegmentGroup>
					</div>
					<BusinessMetricsChart {...chartParams} />
				</WidgetContent>
			</Widget>
		</div>
	)
}

const selectMetrics = pipe([filterPricingAndBusinessMetrics, formatMetrics])

const getLastPoints = (
	main?: BIResponse | null,
	compared?: BIResponse | null
) => {
	if (!main?.meta || !compared?.meta) {
		return {
			current: null,
			compared: null,
		}
	}
	return {
		current: last(zipSeries(main?.meta, [last(main?.data)!])),
		compared: last(zipSeries(main?.meta, [last(compared?.data)!])),
	}
}
const buildSeries = (
	main: BIResponse | null | undefined,
	metrics: MetricOption[],
	mainMetric: string
): {
	series: SeriesAreaOptions[]
} => {
	if (!main?.meta) {
		return {
			series: [],
		}
	}
	const outputArr = main?.meta
		.map((key, index) => [
			key,
			main.data
				.map(pipe([props([0, index]), zipObject(['x', 'y'])]))
				.map((point) => [
					Number(utcToZonedTime(new Date(point.x), 'UTC')),
					point.y,
				]),
		])
		.filter((_item, i) => i !== 0)
	return {
		series: outputArr
			.map(([name, data]) => {
				const metric = metrics.find((metric) => metric.value === name)!
				const isMainMetric = name === mainMetric
				const color = metric.color
				return {
					color,
					data: data as [number, number][],
					type: 'area' as const,
					fillOpacity: 0,
					name: (metric?.label || name) as string,
					dashStyle: isMainMetric ? ('Solid' as const) : ('Dash' as const),
					lineWidth: 2,
					yAxis: (isMainMetric ? 'main' : name) as string,
					marker: {
						enabled: false,
					},
				}
			})
			.sort((a, b) => {
				if (a.yAxis === 'main' && b.yAxis !== 'main') {
					return -1
				} else if (a.yAxis !== 'main' && b.yAxis === 'main') {
					return 1
				}
				return 0
			}),
	}
}
const SkeletonLoader = () => (
	<ContentLoader
		speed={2}
		width={300}
		height={42}
		viewBox={`0 0 ${300} ${42}`}
		backgroundColor='var(--cmp-accent-4)'
		foregroundColor='var(--cmp-accent-3)'
	>
		<rect x='0' y='0' rx='10' ry='10' width={150} height={42} />
		<rect x='210' y='6' rx='10' ry='10' width={50} height={12} />
		<rect x='210' y='20' rx='10' ry='10' width={80} height={20} />
	</ContentLoader>
)

function AdditionalMetricsOption(
	props: OptionProps<MetricOption, true, GroupBase<MetricOption>>
) {
	return (
		<components.Option {...props}>
			<div
				className='flex items-center space-x-2 text-xs'
				onClick={props.innerProps.onClick}
			>
				<Checkbox checked={props.isSelected} />
				<div className='grow flex-nowrap overflow-x-hidden text-ellipsis'>
					{props.children}
				</div>
			</div>
		</components.Option>
	)
}

const styles = {
	control: (provided: object) => ({ ...provided, margin: 8 }),
	menu: () => ({
		position: 'relative',
		fontSize: 12,
		width: 200,
	}),
} as StylesConfig<MetricOption>
const Control = ({
	children,
	innerProps,
	innerRef,
}: {
	children: React.ReactNode
	innerProps: object
	innerRef: React.Ref<HTMLDivElement>
}) => (
	<div ref={innerRef} className='cmp-border-bottom flex p-1' {...innerProps}>
		{children}
	</div>
)
const DropdownIndicator = () => <SearchIcon className='mr-1' />

function MainMetricDropDown({
	options,
	value,
	className,
	...props
}: MetricsDropDown & {
	onChange(value: SingleValue<MetricOption>): void
}) {
	const popover = useDisclosure()
	const currentValue = options.find(
		(metric: MetricOption) => metric.value === value
	)
	const content = (
		<Card className='border pt-0 shadow'>
			<Select<MetricOption, false>
				menuPlacement='auto'
				options={options}
				isClearable={false}
				controlShouldRenderValue={false}
				components={{
					Control,
					DropdownIndicator,
				}}
				hideSelectedOptions={false}
				menuIsOpen
				styles={styles}
				tabSelectsValue={false}
				value={currentValue}
				autoFocus
				{...props}
				onChange={(data) => {
					if (!data) return
					props.onChange(data)
					popover.close()
				}}
			/>
		</Card>
	)
	return (
		<Popover
			onDismiss={popover.close}
			isOpen={popover.isOpen}
			content={content}
			placement='bottom-start'
		>
			<Button
				className={className}
				onClick={popover.toggle}
				iconAfter={<CaretDownIcon className='shrink-0' />}
			>
				{intl.get('widget.business_metrics.main_metric_title').d('Main metric')}
				{':  '}
				<span className='font-medium'>{currentValue?.label}</span>
			</Button>
		</Popover>
	)
}
function AdditionalMetricsDropDown({
	options,
	values,
	className,
	...props
}: MetricsDropDown & {
	onChange(value: MultiValue<MetricOption>): void
}) {
	const popover = useDisclosure()
	const content = (
		<Card className='border pt-0 shadow'>
			<Select<MetricOption, true>
				{...props}
				menuPlacement='auto'
				options={options}
				isClearable={false}
				components={{
					Control,
					DropdownIndicator,
					Option: AdditionalMetricsOption,
				}}
				value={options.filter((metric) => values?.includes(metric.value))}
				autoFocus
				isMulti
				closeMenuOnSelect={false}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				menuIsOpen
				styles={styles}
				tabSelectsValue={false}
			/>
			<div className='w-full border-t border-solid border-base'>
				<Button
					className='justify-start rounded-none'
					iconBefore={<XCircleIcon />}
					onClick={() => {
						props.onClear?.()
						popover.close()
					}}
				>
					{intl.get('general_clear')}
				</Button>
			</div>
		</Card>
	)
	return (
		<Popover
			onDismiss={popover.close}
			isOpen={popover.isOpen}
			content={content}
			placement='bottom-start'
		>
			<Button
				className={className}
				onClick={popover.toggle}
				iconAfter={<CaretDownIcon className='shrink-0' />}
			>
				{intl
					.get('widget.business_metrics.additional_metric_title')
					.d('Additional metrics')}{' '}
				<span className='font-medium'>{`(${values ? values.length : 0})`}</span>
			</Button>
		</Popover>
	)
}
