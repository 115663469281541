import clsx from 'clsx'
import { isNaN } from 'lodash/fp'
import React, { ChangeEvent, KeyboardEvent, memo, useState } from 'react'

import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	Input,
	LinkButton,
} from '@cmpkit/base'
import Popover from '@cmpkit/popover'

import intl from '@/locale'
import { getEntityFieldValue } from '@/modules/og-products/utils'
import analytic from '@/services/analytics'
import { isNumberValid } from '@/tools/check'
import { numericOnly } from '@/tools/constants'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { CellPropControlled } from '../fields'
import NumberField from '../fields/Number/TableCell'
import { ColumnDataType } from '../types'

const event = 'table: new price editing'
const convertValue = (val?: number | string | null) =>
	val ? (isNaN(Number(val)) ? val : Number(val).toFixed(2)) : ''
function PopoverContent({
	onChange,
	row,
	value,
	schema,
	close,
	isDisabled,
}: CellPropControlled & { close: () => void }) {
	/**
	 * Configuration keys for current column
	 */
	const currentKey: string =
		schema?.ui_schema?.data?.init_column_name ?? 'last_price'
	const dependedKey: string =
		schema?.ui_schema?.data?.optimal_column_name ?? 'optimal_price'

	/**
	 * Supportive values
	 * Current and depended values
	 */
	const currValue: number | null = getEntityFieldValue(row, currentKey)
	const dependedValue: number | null = getEntityFieldValue(row, dependedKey)

	const initialValue = value ? convertValue(value) : value
	const [localValue, setLocalValue] = useState(initialValue)

	/**
	 * Handlers
	 */
	const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
		setLocalValue(event.target.value.replace(numericOnly, ''))
	const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) =>
		event.keyCode === 13 && handleConfirm()
	const handleBlur = () => (localValue != initialValue ? handleConfirm() : null)

	const handleChangePrice = (value: number) => {
		if (!value) return
		onChange?.({
			row,
			schema,
			value,
		})
		close()
	}

	const _handleSetOptimal = () => {
		if (dependedValue) {
			analytic.logEvent(event, { 'Set new price': 'Optimal' })
			handleChangePrice(dependedValue as number)
		}
	}
	const _handleSetCurrent = () => {
		if (currValue) {
			analytic.logEvent(event, { 'Set new price': 'Current' })
			handleChangePrice(currValue as number)
		}
	}

	const handleConfirm = () => {
		analytic.logEvent(event, { 'Set new price': 'Manual' })
		const valueToSet =
			isNumberValid(localValue) && localValue > 0
				? localValue
				: row.fields[currentKey]
		onChange?.({
			row,
			schema,
			value: valueToSet,
		})
		setLocalValue(convertValue(valueToSet))
		close()
	}

	return (
		<Card className='w-72 border border-solid shadow'>
			<CardHeader>
				<CardTitle>{intl.get('products_select_new_price')}</CardTitle>
			</CardHeader>
			<CardContent>
				<ul className='grid gap-3'>
					<li className='flex items-center justify-between'>
						<span className='text-muted'>{intl.get('products_current')}</span>
						<LinkButton onClick={_handleSetCurrent}>
							{currValue !== null
								? formatNumber(currValue, NumberFormats.Fixed)
								: '-'}
						</LinkButton>
					</li>
					<li className='flex items-center justify-between'>
						<span className='text-muted'>{intl.get('products_optimized')}</span>
						<LinkButton onClick={_handleSetOptimal} className='font-medium'>
							{dependedValue !== null
								? formatNumber(dependedValue, NumberFormats.Fixed)
								: '-'}
						</LinkButton>
					</li>
					<li className='flex items-center justify-between gap-10 font-semibold'>
						<span className='text-muted'>{intl.get('products_manual')}</span>
						<Input
							autoFocus
							className='w-full'
							disabled={isDisabled}
							value={localValue}
							onBlur={handleBlur}
							onKeyUp={handleKeyUp}
							onChange={handleChange}
						/>
					</li>
				</ul>
			</CardContent>
		</Card>
	)
}
export const FinalDataField = (props: CellPropControlled) => {
	const cellContent = (
		<div
			className={clsx('size-full cursor-pointer', {
				'text-brand': props.isChanged,
				'cursor-pointer': props.schema?.editable,
			})}
		>
			<NumberField
				value={props.value}
				schema={{ type: ColumnDataType.Float }}
			/>
		</div>
	)
	if (!props.schema?.editable) {
		return cellContent
	}
	return (
		<Popover
			placement='bottom-start'
			content={({ close }) => <PopoverContent {...props} close={close} />}
		>
			{cellContent}
		</Popover>
	)
}
export default memo(FinalDataField)
