import { filter, map, pipe, prop, propEq, propOr, sum } from 'lodash/fp'
import { useNavigate } from 'react-router'

import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import { Operators } from '@cmpkit/query-builder'

import { encodeComplexQuery } from '@/components/data-grid/helpers'
import intl from '@/locale'
import { useProductsCountByEngineQuery } from '@/modules/bi/queries'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'
import { toQueryString } from '@/tools/location'

import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import { WidgetProps } from '../types'

const getUnassignedItems = pipe([
	prop('data'),
	filter(propEq('pricing_campaign_engine', 'unassigned')),
	map(propOr(0, 'product_count')),
	sum,
])

export default function NumericUnasignedItemsWidget({
	commonFilters,
}: WidgetProps) {
	const navigate = useNavigate()
	const commonQueryParams = {
		filters: [...commonFilters],
	}
	const { isFetching, data } = useProductsCountByEngineQuery<number>(
		commonQueryParams,
		{
			select: getUnassignedItems,
		}
	)

	return (
		<Widget
			className='relative flex flex-auto cursor-pointer flex-col hover:border-brand'
			onClick={() => {
				analytic.logEvent('dashboard: bi: proceed to unassigned list')
				navigate(
					`/p/assortment/products${toQueryString({
						qf: encodeComplexQuery({
							filters: [
								{
									name: 'pricing_campaign_id',
									operation: Operators.IS_EMPTY,
									value: 1,
								},
							],
						}),
					})}`
				)
			}}
		>
			<WidgetHeader
				title={intl.get('general_new_items')}
				tooltip={intl.get('unassigned_items.tooltip')}
				subtitle={intl.get('items_without_pc').d('items without PC')}
			/>
			<WidgetContent className='truncate'>
				<div className='mt-auto w-full'>
					{isFetching ? (
						<div className='col-span-2 h-10 w-32 animate-pulse rounded-lg bg-accent-3' />
					) : (
						<div className='flex items-center justify-between'>
							<span className='truncate text-3xl font-semibold'>
								{formatNumber(data!)}
							</span>
							<CaretRightIcon
								width={40}
								height={40}
								className='fill-muted dark:fill-white'
							/>
						</div>
					)}
				</div>
			</WidgetContent>
		</Widget>
	)
}
