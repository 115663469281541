import clsx from 'clsx'
import { indexBy } from 'lodash/fp'
import React from 'react'
import { Link } from 'react-router-dom'

import { linkButtonVariants } from '@cmpkit/base'

import { OptimizationGroupModel } from '@/generated'
import { useOptimizationGroupsQuery } from '@/modules/core/queries'

import { CellProp } from '../fields'

export default function OptimizationGroupField({ value }: CellProp<string>) {
	const groupsQuery = useOptimizationGroupsQuery<
		Record<string, OptimizationGroupModel>
	>({
		select: indexBy<OptimizationGroupModel>('id'),
	})
	return (
		<Link
			to={`/p/og/${value}/products`}
			className={clsx(
				'font-medium no-underline',
				linkButtonVariants({ variant: 'brand' })
			)}
		>
			{groupsQuery?.data?.[value]?.name ?? value}
		</Link>
	)
}
