import { PuzzleIcon } from 'lucide-react'
import React from 'react'
import { NavLink, Outlet, useParams } from 'react-router'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
	Content,
	Header,
	Layout,
	Separator,
} from '@cmpkit/base'
import {
	Sidebar,
	SidebarContent,
	SidebarGroup,
	SidebarGroupContent,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	SidebarMenuSub,
	SidebarMenuSubButton,
	SidebarMenuSubItem,
	SidebarProvider,
} from '@cmpkit/sidebar'

import { SettingsTemplateType } from '@/generated'
import intl from '@/locale'

import { PageNavigatorProvider } from '../PageNavigator'

const data = {
	navMain: [
		{
			title: intl
				.get(`template.type.${SettingsTemplateType.Scenario}`)
				.d('Scenario'),
			key: SettingsTemplateType.Scenario,
			pathname: `${SettingsTemplateType.Scenario}`,
			items: [
				{
					title: intl
						.get(`template.type.${SettingsTemplateType.ScenarioStrategy}`)
						.d('Scenario strategy'),
					key: SettingsTemplateType.ScenarioStrategy,
					pathname: `${SettingsTemplateType.ScenarioStrategy}`,
				},
				{
					title: intl
						.get(
							`template.type.${SettingsTemplateType.ScenarioBusinessConstraints}`
						)
						.d('Scenario business rules'),
					key: SettingsTemplateType.ScenarioBusinessConstraints,
					pathname: `${SettingsTemplateType.ScenarioBusinessConstraints}`,
				},
				{
					title: intl
						.get(`template.type.${SettingsTemplateType.ScenarioStopList}`)
						.d('Scenario stop list'),
					key: SettingsTemplateType.ScenarioStopList,
					pathname: `${SettingsTemplateType.ScenarioStopList}`,
				},
			],
		},
		{
			title: intl
				.get(`template.type.${SettingsTemplateType.PricingCampaign}`)
				.d('Pricing campaign'),
			key: SettingsTemplateType.PricingCampaign,
			pathname: `${SettingsTemplateType.PricingCampaign}`,
			items: [
				{
					title: intl
						.get(
							`template.type.${SettingsTemplateType.PricingCampaignProductAssignment}`
						)
						.d('Assignments'),
					key: SettingsTemplateType.PricingCampaignProductAssignment,
					pathname: `${SettingsTemplateType.PricingCampaignProductAssignment}`,
				},
				{
					title: intl
						.get(
							`template.type.${SettingsTemplateType.PricingCampaignTacticsRb}`
						)
						.d('Tactic rule based'),
					key: SettingsTemplateType.PricingCampaignTacticsRb,
					pathname: `${SettingsTemplateType.PricingCampaignTacticsRb}`,
				},
				{
					title: intl
						.get(
							`template.type.${SettingsTemplateType.PricingCampaignTacticsMd}`
						)
						.d('Tactic markdown'),
					key: SettingsTemplateType.PricingCampaignTacticsMd,
					pathname: `${SettingsTemplateType.PricingCampaignTacticsMd}`,
				},
				{
					title: intl
						.get(`template.type.${SettingsTemplateType.PricingCampaignLimits}`)
						.d('Price limits'),
					key: SettingsTemplateType.PricingCampaignLimits,
					pathname: `${SettingsTemplateType.PricingCampaignLimits}`,
				},
				{
					title: intl
						.get(
							`template.type.${SettingsTemplateType.PricingCampaignRounding}`
						)
						.d('Rounding'),
					key: SettingsTemplateType.PricingCampaignRounding,
					pathname: `${SettingsTemplateType.PricingCampaignRounding}`,
				},
				{
					title: intl
						.get(
							`template.type.${SettingsTemplateType.PricingCampaignAnchoring}`
						)
						.d('Anchoring'),
					key: SettingsTemplateType.PricingCampaignAnchoring,
					pathname: `${SettingsTemplateType.PricingCampaignAnchoring}`,
				},
				{
					title: intl
						.get(
							`template.type.${SettingsTemplateType.PricingCampaignMinimalSteps}`
						)
						.d('Minimal steps'),
					key: SettingsTemplateType.PricingCampaignMinimalSteps,
					pathname: `${SettingsTemplateType.PricingCampaignMinimalSteps}`,
				},
			],
		},
	],
}

function TemplatesSideBar() {
	return (
		<Sidebar
			collapsible='none'
			className='!w-72 shrink-0 border-r !bg-accent-2'
		>
			<SidebarContent>
				<SidebarGroup>
					<SidebarGroupContent>
						<SidebarMenu>
							{data.navMain.map((item) => (
								<SidebarMenuItem key={item.title}>
									<SidebarMenuButton asChild>
										<NavLink to={item.pathname}>{item.title}</NavLink>
									</SidebarMenuButton>
									{item.items?.length ? (
										<SidebarMenuSub>
											{item.items.map((item) => (
												<SidebarMenuSubItem key={item.title}>
													<SidebarMenuSubButton asChild>
														<NavLink to={item.pathname}>{item.title}</NavLink>
													</SidebarMenuSubButton>
												</SidebarMenuSubItem>
											))}
										</SidebarMenuSub>
									) : null}
								</SidebarMenuItem>
							))}
						</SidebarMenu>
					</SidebarGroupContent>
				</SidebarGroup>
			</SidebarContent>
		</Sidebar>
	)
}
export default function TemplatesHost() {
	const { type } = useParams()

	return (
		<PageNavigatorProvider>
			<SidebarProvider className='!min-h-full items-start'>
				<Layout className='h-full'>
					<Header className='h-12 items-center border-b px-4 py-2'>
						<PuzzleIcon className='size-4' />
						<Separator orientation='vertical' className='mx-2 h-5' />
						<Breadcrumb className='text-xs'>
							<BreadcrumbList>
								<BreadcrumbItem>
									<BreadcrumbPage>
										{intl.get('templates').d('Templates')}
									</BreadcrumbPage>
								</BreadcrumbItem>
								<BreadcrumbSeparator />
								<BreadcrumbItem>
									<BreadcrumbPage>
										{intl.get(`template.type.${type}`).d(type || '')}
									</BreadcrumbPage>
								</BreadcrumbItem>
							</BreadcrumbList>
						</Breadcrumb>
					</Header>
					<Layout row className='h-full overflow-hidden'>
						<TemplatesSideBar />
						<Content className='relative w-full flex-col overflow-y-auto bg-accent-1'>
							<Outlet />
						</Content>
					</Layout>
				</Layout>
			</SidebarProvider>
		</PageNavigatorProvider>
	)
}
