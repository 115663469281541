import { dialog } from '@/components/dialogs'
import { OptimizationAction, OptimizationStatus } from '@/generated'
import intl from '@/locale'
import { client } from '@/network/client'

import ExportDialogActions from '../components/SaveExportedPricesDialogActions'
import { useOptimizationQuery } from '../queries'

type UseCheckOptimizationForDeleteExport = (
	fn: (...arr: any[]) => void // eslint-disable-line
) => (...arg: any[]) => Promise<void> // eslint-disable-line

export function useCheckOptimizationForDeleteExport(
	optimizationGroupId?: string | null
): UseCheckOptimizationForDeleteExport {
	const { data: optimization } = useOptimizationQuery(optimizationGroupId!, {
		enabled: !!optimizationGroupId,
	})
	return (fn) => {
		return async (...arg) => {
			if (
				optimizationGroupId &&
				optimization?.status === OptimizationStatus.Locked &&
				optimization?.is_exported
			) {
				const answer = await dialog.form({
					title: intl.get('optimization_remove_export_title'),
					text: intl.get('optimization_remove_export_message'),
					form(resolve) {
						return <ExportDialogActions resolve={resolve} />
					},
				})
				if (!answer || answer.action === 'close') {
					return
				}

				if (answer.action === 'ok') {
					if (!answer?.state?.save) {
						await client.core.doOptimizationAction(
							optimizationGroupId,
							'last',
							OptimizationAction.DeleteExport
						)
					}
					return await fn(...arg)
				}
			} else {
				return await fn(...arg)
			}
		}
	}
}
