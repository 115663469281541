import { ReactNode } from 'react'

import {
	flexRender as _flexRender,
	Renderable,
	RowData,
} from '@tanstack/react-table'

import { MColumnHelper, MDisplayColumnDef, MGroupColumnDef } from '../types'

export const flexRender = _flexRender as (
	Comp: Renderable<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
	props: any // eslint-disable-line @typescript-eslint/no-explicit-any
) => ReactNode

export function createMasterColumnHelper<
	TData extends RowData,
>(): MColumnHelper<TData> {
	return {
		accessor: (accessor, column) => {
			return typeof accessor === 'function'
				? ({
						...column,
						accessorFn: accessor,
					} as any) // eslint-disable-line @typescript-eslint/no-explicit-any
				: {
						...column,
						accessorKey: accessor,
					}
		},
		display: (column) => column as MDisplayColumnDef<TData>,
		group: (column) => column as MGroupColumnDef<TData>,
	}
}
