import { Localization, Operators } from '@cmpkit/query-builder'

import intl from '@/locale'

export const localization: Localization = {
	getOperatorLabel(operatorKey: string | Operators) {
		return defaultOperatorsNameMapping[operatorKey] || operatorKey
	},
	getFieldPlaceholder() {
		return intl.get('general_select').d('Select')
	},
	getOperationPlaceholder() {
		return intl.get('general_select').d('Select')
	},
	getDuplicatedRowsMessage() {
		return 'Conditions conflict! Only the last duplicate filter will be applied.'
	},
	getSelectValuePlaceholder() {
		return 'Select a value'
	},
	getEnterValuePlaceholder() {
		return 'Enter a value'
	},
}

const defaultOperatorsNameMapping: Record<string, string> = {
	[Operators.CONTAINS]: intl.get('op.contains').d('contains'),
	[Operators.NOT_CONTAINS]: intl.get('op.not_contains').d('not contains'),
	[Operators.IS]: intl.get('op.is').d('is'),
	[Operators.IN]: intl.get('op.in').d('in'),
	[Operators.NOT_IN]: intl.get('op.not_in').d('not in'),
	[Operators.IS_NOT]: intl.get('op.is_not').d('is not'),
	[Operators.IS_EMPTY]: intl.get('op.is_empty').d('is empty'),
	[Operators.IS_NOT_EMPTY]: intl.get('op.is_not_empty').d('is not empty'),
	[Operators.GT]: '>',
	[Operators.LT]: '<',
	[Operators.EQ]: '=',
	[Operators.LTE]: '≤',
	[Operators.GTE]: '≥',
	[Operators.NE]: '≠',
	[Operators.BL]: '=',
	[Operators.ANY_OF]: intl.get('op.any_of').d('any of'),
	[Operators.ALL_OF]: intl.get('op.all_of').d('all of'),
	[Operators.EXACT]: intl.get('op.exact').d('is exactly'),
	[Operators.NONE_OF]: intl.get('op.none_of').d('is none of'),
	[Operators.BETWEEN]: intl.get('op.between').d('between'),
	[Operators.DATE_AFTER]: intl.get('op.after').d('after'),
	[Operators.DATE_BEFORE]: intl.get('op.before').d('before'),
	[Operators.DATE_AT]: intl.get('op.at').d('at'),
	[Operators.DATE_BETWEEN]: intl.get('op.between').d('between'),
}
