import React, { forwardRef } from 'react'

import { Badge, BadgeProps } from '@cmpkit/base'

import { PricingEngineType } from '../types'

type Props = BadgeProps & {
	className?: string
	children: React.ReactNode
	engine?: PricingEngineType | string
	size?: 'small'
}
export const getPcBadgeVariant = (
	engine?: PricingEngineType | string
): BadgeProps['variant'] => {
	switch (engine) {
		case PricingEngineType.RB:
			return 'warning'
		case PricingEngineType.MD:
			return 'epic'
		case PricingEngineType.ML:
			return 'info'
		default:
			return 'neutral'
	}
}
const EngineBadge = forwardRef<HTMLSpanElement, Props>(
	({ className, children, engine, ...props }, ref) => {
		return (
			<Badge
				variant={getPcBadgeVariant(engine)}
				ref={ref}
				className={className}
				{...props}
			>
				{children}
			</Badge>
		)
	}
)
export default EngineBadge
