import React, { ChangeEvent } from 'react'

import { Textfield } from '@cmpkit/base'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'

interface SearchFieldProps {
	onChange(value: string): void
	onClear(): void
	className?: string
	placeholder?: string
	value: string
	count?: number
}
export default function SearchField({
	onClear,
	onChange,
	value,
	count,
	...props
}: SearchFieldProps) {
	return (
		<Textfield
			{...props}
			value={value}
			onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
			iconAfter={[
				<div key='1'> {value && count ? count : null}</div>,
				!value ? (
					<SearchIcon key='2' className='input-suffix' />
				) : (
					<CrossIcon
						key='2'
						onClick={onClear}
						className='input-suffix cursor-pointer'
					/>
				),
			]}
		/>
	)
}
