import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
	ChatMessageReactionRequestModel,
	ChatMessageRequestModel,
	ChatMessageResponseModel,
	HTTPValidationError,
} from '@/generated'
import { client } from '@/network/client'

export function useSentChatMessageMutation(
	options?: UseMutationOptions<
		ChatMessageResponseModel,
		AxiosError<HTTPValidationError>,
		ChatMessageRequestModel
	>
) {
	return useMutation<
		ChatMessageResponseModel,
		AxiosError<HTTPValidationError>,
		ChatMessageRequestModel
	>({
		mutationFn: (payload) => client.assistant.assistantChatMessagePost(payload),
		...(options || {}),
	})
}

export function useSentChatMessageReactionMutation(
	options?: UseMutationOptions<
		void,
		AxiosError<HTTPValidationError>,
		ChatMessageReactionRequestModel
	>
) {
	return useMutation<
		void,
		AxiosError<HTTPValidationError>,
		ChatMessageReactionRequestModel
	>({
		mutationFn: (payload) =>
			client.assistant.assistantChatReactionPost(payload),
		...(options || {}),
	})
}
