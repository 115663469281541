import clsx from 'clsx'
import fastEq from 'fast-deep-equal'
import { entries, fromPairs, isEmpty, map, pipe } from 'lodash/fp'
import React, { useEffect, useMemo } from 'react'
import ReactMarkdown, { Components } from 'react-markdown'

import Anchor from '@/components/DataWidgets/Anchor'
import CompetitorsTable from '@/components/DataWidgets/CompetitorsTable'
import Contribution from '@/components/DataWidgets/Contribution'
import Elasticity from '@/components/DataWidgets/Elasticity'
import GrowthDecomposition from '@/components/DataWidgets/GrowthDecomposition/index'
import Lifecycle from '@/components/DataWidgets/Lifecycle'
import LimitationRange from '@/components/DataWidgets/LimitationRange'
import StopListRules from '@/components/DataWidgets/Locks'
import ErrorBoundary from '@/components/ErrorBoundary'
import Loader from '@/components/Loader'
import FallbackError from '@/components/placeholders/FallbackError'
import { useMainSourceProperty, useSchemaFieldNames } from '@/hooks/data'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl, { currentLocale } from '@/locale'
import {
	useInterpretationQuery,
	useOptimizationQuery,
} from '@/modules/core/queries'
import { ProductEntity } from '@/modules/og-products/types'
import analytic from '@/services/analytics'
import { sprintf } from '@/tools/utils'

import EmptyData from './EmptyData'

const widgets: Record<
	string,
	| React.FunctionComponent<Record<string, unknown>>
	| ((props: any) => React.ReactNode) // eslint-disable-line @typescript-eslint/no-explicit-any
> = {
	Limitations: LimitationRange,
	Elasticity,
	Lifecycle,
	Anchor,
	Contribution,
	GrowthDecomposition,
	CompetitorsTable,
	StopListRules,
}
const components = {
	a: (props: { href: string; children: React.ReactNode }) => (
		<a href={props.href} target='_blank' rel='nofollow noopener noreferrer'>
			{props.children}
		</a>
	),
}
const isChanged = (localData = {}, nativeData = {}) =>
	!fastEq({ ...nativeData, ...localData }, nativeData)
type TextProps = {
	content?: string
	className?: string
}
const Text = ({ content, className }: TextProps) => (
	<div className={clsx('markdown-txt', className)}>
		<ReactMarkdown components={components as Components}>
			{content ?? ''}
		</ReactMarkdown>
	</div>
)
function useIntrpretation({ line_id }: { line_id: string }): {
	interpretation: InterpretationModel[]
	sourceProp: string
	isLoading: boolean
} {
	const optimizationGroupId = useOptimizationGroupId()!
	const { data: optimization } = useOptimizationQuery(optimizationGroupId)
	const sourceProp = useMainSourceProperty()
	const optimization_id = optimization?.id

	useEffect(() => {
		analytic.logEvent('products: interpretation', {
			Engine: 'ml',
		})
	}, [line_id, optimization_id])

	const { data: interpretation, isLoading } = useInterpretationQuery(
		{
			optimization_id: optimization_id!,
			optimization_group_id: optimizationGroupId,
			line_id,
			locale: currentLocale,
		},
		{
			enabled: !!optimization_id,
		}
	)
	return useMemo(
		() => ({
			isLoading,
			interpretation: (interpretation as InterpretationModel[]) || [],
			sourceProp,
		}),
		[isLoading, interpretation, sourceProp]
	)
}

const translateDict = pipe([
	entries,
	map(([key, val]) => [key, intl.get(String(val)).d(String(val))]),
	fromPairs,
])
const createFieldsValuesGetter = (schemaNames: Record<string, string>) =>
	pipe([
		entries,
		map(([key, val]) => [key, schemaNames?.[val] ?? val]),
		fromPairs,
	])
type InterpretationModel = {
	comp: string
	type: string
	title: string
	children: string
	values: object
	fields: object
	props: object
}
type DataWidgetsContainerProps = {
	schema: InterpretationModel[]
	sourceProp: string
}
export const DataWidgetsContainer = ({
	schema,
	sourceProp,
}: DataWidgetsContainerProps) => {
	const schemaFieldNames = translateDict(useSchemaFieldNames())
	const getTranslatedTemplateFieldsValues =
		createFieldsValuesGetter(schemaFieldNames)
	return (
		<>
			{schema.map((widget, index) => {
				const Comp = widgets?.[widget.comp]
				const childrenTemplateValues = {
					...translateDict(widget?.values || {}),
					...getTranslatedTemplateFieldsValues(widget?.fields || {}),
				}
				return (
					<div key={`${widget.type}_${index}`} className='data-widget'>
						<ErrorBoundary fallback={FallbackError}>
							<Text content={widget.title} />
							{widget.type == 'text' && (
								<Text
									content={sprintf(widget.children, childrenTemplateValues)}
								/>
							)}
							{widget.type == 'widget' && Comp && (
								<Comp {...widget.props} sourceProp={sourceProp} />
							)}
						</ErrorBoundary>
					</div>
				)
			})}
		</>
	)
}
type InterpretationProps = {
	localData: Record<string, object>
	content: ProductEntity
}
export function Interpretation({ localData, content }: InterpretationProps) {
	const { interpretation, sourceProp, isLoading } = useIntrpretation({
		line_id: content?.line_id,
	})
	if (!isLoading && isEmpty(interpretation)) {
		return <EmptyData />
	}
	const line_id = content?.line_id
	const isDataChanged = isChanged(localData?.[line_id], content)
	return (
		<div
			className={clsx('relative mb-2.5 space-y-4 px-5 pb-16', {
				'opacity-50': isDataChanged,
			})}
		>
			{isLoading && <Loader size='30' className='bg-transparent opacity-50' />}
			{!isLoading && (
				<DataWidgetsContainer schema={interpretation} sourceProp={sourceProp} />
			)}
		</div>
	)
}

export default Interpretation
