import {
	addMonths,
	addQuarters,
	addWeeks,
	differenceInDays,
	eachMonthOfInterval,
	eachQuarterOfInterval,
	eachWeekOfInterval,
	endOfMonth,
	endOfQuarter,
	endOfWeek,
	format,
	getQuarter,
	startOfMonth,
	startOfQuarter,
	startOfWeek,
} from 'date-fns'

import { DateFormat } from '@/tools/dates'

import { Frequency, TimelineRange } from './types'

type HeperArgs = {
	minDate: Date
	maxDate: Date
	frequency: Frequency
}

export const diffInDays = (d1: Date, d2: Date) => differenceInDays(d1, d2) + 1
export function getWeekRanges({
	minDate,
	maxDate,
	frequency,
}: HeperArgs): TimelineRange[] {
	return eachWeekOfInterval({
		start: minDate,
		end: maxDate,
	}).map((date) => {
		const start = startOfWeek(date, { weekStartsOn: 1 })
		const end = endOfWeek(date, { weekStartsOn: 1 })
		return {
			start,
			end,
			frequency,
			daysCount: diffInDays(end, start),
		}
	})
}
export function getMonthRanges({
	minDate,
	maxDate,
	frequency,
}: HeperArgs): TimelineRange[] {
	return eachMonthOfInterval({
		start: minDate,
		end: maxDate,
	}).map((date) => {
		const start = startOfMonth(date)
		const end = endOfMonth(date)
		return {
			start,
			end,
			frequency,
			daysCount: diffInDays(end, start),
		}
	})
}
export function getQuarterRanges({
	minDate,
	maxDate,
	frequency,
}: HeperArgs): TimelineRange[] {
	return eachQuarterOfInterval({
		start: minDate,
		end: maxDate,
	}).map((date) => {
		const start = startOfQuarter(date)
		const end = endOfQuarter(date)
		return {
			start,
			end,
			frequency,
			daysCount: diffInDays(end, start),
		}
	})
}
export function generateTimelineRanges({
	minDate,
	maxDate,
	frequency,
}: HeperArgs): TimelineRange[] {
	switch (frequency) {
		case 'month':
			return getMonthRanges({ minDate, maxDate, frequency })
		case 'quarter':
			return getQuarterRanges({ minDate, maxDate, frequency })
		default:
			return getWeekRanges({ minDate, maxDate, frequency })
	}
}
export function getDimmensions({ minDate, maxDate, frequency }: HeperArgs) {
	switch (frequency) {
		case 'month':
			return {
				minDate: startOfMonth(minDate),
				maxDate: addMonths(endOfMonth(maxDate), 1),
				dayWidth: 9,
			}
		case 'quarter':
			return {
				minDate: startOfQuarter(minDate),
				maxDate: addQuarters(endOfQuarter(maxDate), 1),
				dayWidth: 5,
			}
		default:
			return {
				minDate: startOfWeek(minDate, { weekStartsOn: 1 }),
				maxDate: addWeeks(endOfWeek(maxDate, { weekStartsOn: 1 }), 1),
				dayWidth: 50,
			}
	}
}

export function formatHeaderTitile(range: TimelineRange) {
	if (range.frequency === 'week') {
		return `${format(range.start, 'MMMM')} ${format(range.start, DateFormat.default)} - ${format(
			range.end,
			DateFormat.default
		)}`
	}
	if (range.frequency === 'quarter') {
		return `Q${getQuarter(range.start)} ${format(range.start, 'MMMM')} - ${format(range.end, 'MMMM yyyy')}`
	}
	return format(range.start, 'MMMM')
}
