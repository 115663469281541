import { format } from 'date-fns'

import {
	FilterRuleEntity,
	Operators,
	ValueEditorType,
	ValueType,
} from '@cmpkit/query-builder'

import { ColumnSchemaModel } from '@/generated'
import intl from '@/locale'
import { DateFormat } from '@/tools/dates'

import { ColumnDataType } from '../data-grid/types'
import { FilterField } from './types'

/* Cutom filter editors */

/* const MultiSelectEditor = composeFilter(
	[Operators.IN, Operators.NOT_IN, Operators.IS_EMPTY, Operators.IS_NOT_EMPTY],
	EditorMultiSelect
)
const DumbMultiSelectEditor = composeFilter([Operators.IN], EditorMultiSelect, { hideOperationField: true })
const ArrayMultiSelectEditor = composeFilter(
	[Operators.ANY_OF, Operators.IS_EMPTY, Operators.IS_NOT_EMPTY],
	EditorMultiSelect
)
const CheckboxTreeEditor = composeFilter([Operators.IN], EditorCheckboxTree)
const DateRangeEditor = composeFilter([Operators.DATE_BETWEEN], EditorDateRange)
 */
//export const getCustomRuleEditorComponent = (field) => {
/* if (
		field.valueEditorType === ValueEditorType.multiselect &&
		field.valueType === ValueType.str &&
		field.operations?.length === 1 &&
		field.operations[0] === Operators.IN
	) {
		return DumbMultiSelectEditor
	}
	if (field.valueType === ValueType.str && field.valueEditorType === ValueEditorType.checkboxTree) {
		return CheckboxTreeEditor
	}
	if (field.valueType === ValueType.array && field.valueEditorType === ValueEditorType.multiselect) {
		return ArrayMultiSelectEditor
	}
	if (field.valueEditorType === ValueEditorType.multiselect) {
		return MultiSelectEditor
	}
	if (field.valueEditorType === ValueEditorType.daterange) {
		return DateRangeEditor
	} */
//}

/**
 * Get filter field props by column type name
 * @param type  - column type
 * @returns  - filter field props
 */
export const getEditorProps = (
	dataType: string,
	editorType?: string
):
	| Pick<FilterField, 'valueType' | 'valueEditorType' | 'operations'>
	| object => {
	const type = editorType || dataType

	switch (type) {
		case 'intersection_categories':
		case 'tree':
			return {
				operations: [Operators.ANY_OF],
				valueEditorType: ValueEditorType.checkboxTree,
			}
		case 'pricing_type':
			return {
				valueType: ValueType.str,
				valueEditorType: ValueEditorType.multiselect,
				operations: [Operators.IN],
			}
		case ColumnDataType.Datetime:
			return {
				valueEditorType: ValueEditorType.date,
			}

		case 'intersection_alerts':
		case 'multiselect': {
			const fragment = {
				valueEditorType: ValueEditorType.multiselect,
			}
			if (dataType === ColumnDataType.String) {
				return {
					...fragment,
					operations: [
						Operators.IN,
						Operators.NOT_IN,
						Operators.IS_EMPTY,
						Operators.IS_NOT_EMPTY,
					],
				}
			}
			return fragment
		}
		case ColumnDataType.Array:
			return {
				operations: [
					Operators.ANY_OF,
					Operators.IS_EMPTY,
					Operators.IS_NOT_EMPTY,
				],
				valueEditorType: ValueEditorType.multiselect,
			}
		default:
			return {}
	}
}

/**
 * Transform date type in filter rule to system date format
 * @param _handler	- handler function
 */
export const proxyRuleChange =
	(_handler: (data: { index: number; query: FilterRuleEntity }) => void) =>
	(params: { index: number; query: FilterRuleEntity }) => {
		const index = params.index
		let query = params.query
		if (
			[
				Operators.DATE_AFTER,
				Operators.DATE_AT,
				Operators.DATE_BEFORE,
				Operators.DATE_BETWEEN,
			].includes(query.operation as Operators)
		) {
			if (Array.isArray(query.value)) {
				query = {
					...query,
					value: query.value.map((date) => format(date, DateFormat.system)),
				}
			} else {
				query = {
					...query,
					value: isValidDate(query.value)
						? format(query.value, DateFormat.system)
						: '',
				}
			}
		}
		return _handler({ index, query })
	}
const isValidDate = (date: string) => {
	const parsedDate = new Date(date)
	return !isNaN(parsedDate.getTime())
}
/**
 * Transform column schema to filter field model
 * @param schema  - column schema model
 * @returns  - adapted filter field
 */
export const toAdaptedSchema = (schema: ColumnSchemaModel): FilterField => {
	const value = schema.name === 'categories' ? 'category_ids' : schema.name
	const valueType = schema.type as unknown as ValueType
	const label = schema.translate_key
		? intl.get(schema.translate_key).d(schema.visible_name)
		: schema.visible_name
	return {
		value,
		label,
		valueType,
		enum: schema.enum || [],
		note: schema.tooltip && intl.get(schema.tooltip).d(''),
		...getEditorProps(schema.type, schema?.filter_schema?.type),
	}
}
