import { addDays, format } from 'date-fns'
import { prop, sum } from 'lodash/fp'
import React from 'react'

import { PALETTE } from '@cmpkit/theme'

import intl from '@/locale'
import { useBIRevenueAnalyticQuery } from '@/modules/bi/queries'
import { sortIn, zipSeries } from '@/modules/bi/utils'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'

import DonutView from '../../DonutView/DonutView'
import { MainDashboardFilter } from '../../Filter'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetEmptyState from '../../WidgetEmptyState'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetProps } from '../types'

type DataRow = {
	revenue: number
	price_change_status: string
}
const getColor = (name: string) => {
	switch (name) {
		case 'decreased':
			return PALETTE.orange[75]
		case 'increased':
			return PALETTE.green[75]
		case 'not_changed':
			return PALETTE.blue[75]
		case 'promo':
			return PALETTE.neutral[150]
		case 'locked':
			return PALETTE.neutral[200]
		default:
			return ''
	}
}
const buildDataSeries = (data: DataRow[], total: number) => {
	return data
		?.sort(
			sortIn(
				['increased', 'decreased', 'not_changed', 'locked', 'promo'],
				prop('price_change_status')
			)
		)
		?.map(({ price_change_status, revenue }) => {
			const subvalue = +((revenue / total) * 100).toFixed(1)
			return {
				name: intl
					.get(`overview_price_${price_change_status}`)
					.d(price_change_status),
				color: getColor(price_change_status),
				y: subvalue,
				value: revenue,
			}
		})
}
export default function RevenueUnderManagementByPriceChange({
	widget,
	params,
	onChangeParams,
	commonPeriods,
	commonFilters,
}: WidgetProps) {
	const start_date = format(
		addDays(new Date(commonPeriods.main.end_date), -14),
		DateFormat.system
	)
	const end_date = commonPeriods.main.end_date
	const filters = [...commonFilters, ...(params.filters ?? [])]
	const biQuery = useBIRevenueAnalyticQuery<DataRow[]>(
		{
			label: widget.kind,
			start_date,
			end_date,
			revenue_start_date: start_date,
			revenue_end_date: end_date,
			metrics: ['revenue'],
			dimensions: ['price_change_status'],
			filters: [
				{
					name: 'optimization_lag',
					operation: 'eq',
					value: 1,
				},
				{
					name: 'is_repricing_today',
					operation: 'bl',
					value: true,
				},
				{
					name: 'price_change_status',
					operation: 'is_not',
					value: 'locked',
				},
				...filters,
			],
		},
		{
			select: (data) => zipSeries<DataRow>(data.meta, data.data),
		}
	)
	const data = biQuery.data || []
	const total = sum(data?.map(prop('revenue')))
	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.revenue_under_management_by_change.title')}
				tooltip={intl.get('widget.revenue_under_management_by_change.tooltip')}
				subtitle={intl.get('app.based_on_last_repricing')}
			/>
			<WidgetContent>
				<div className='flex items-center'>
					<MainDashboardFilter
						irremovableCount={commonFilters.length}
						filters={filters ?? []}
						onChange={(filters) => {
							analytic.logEvent(`dashboard: bi: apply filter ${widget.kind}`, {
								'filter by': filters.map(prop('name')).join(','),
							})
							onChangeParams({ ...params, filters })
						}}
					/>
				</div>
				<WidgetLoadingState isTinted={biQuery.isLoading} />
				<WidgetErrorState isTinted={biQuery.isError} />
				{data.length && !biQuery.isLoading ? (
					<DonutView
						data={buildDataSeries(data, total)}
						formatValue={(val) => formatNumber(val, NumberFormats.Compact)}
						total={total}
					/>
				) : (
					<WidgetEmptyState />
				)}
			</WidgetContent>
		</Widget>
	)
}
