import { JSONSchemaType as AjvJSONSchemaType } from 'ajv/dist/core'
import { RefResolver } from 'json-schema-ref-resolver'
import {
	entries,
	fromPairs,
	isArray,
	isEmpty,
	isObject,
	map,
	merge,
	pipe,
	reduce,
	zip,
	zipObject,
} from 'lodash/fp'
import { ChangeEvent } from 'react'

import { DataOption } from '@/common.types'
import intl from '@/locale'
import { SettigSchemaModel } from '@/modules/og-settings/types'

type JSONSchemaType =
	| 'string'
	| 'number'
	| 'integer'
	| 'boolean'
	| 'array'
	| 'object'
	| 'null'

interface JSONSchemaBase {
	type: JSONSchemaType
	title?: string
	description?: string
	measure?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	default?: any
}

export interface JSONSchemaString extends JSONSchemaBase {
	type: 'string'
	minLength?: number
	maxLength?: number
	pattern?: string
	enumNames?: string[]
	enum?: string[]
}

export interface JSONSchemaNumber extends JSONSchemaBase {
	type: 'number' | 'integer'
	minimum?: number
	maximum?: number
	exclusiveMinimum?: number
	exclusiveMaximum?: number
	multipleOf?: number
}

export interface JSONSchemaBoolean extends JSONSchemaBase {
	type: 'boolean'
}

export interface JSONSchemaNull extends JSONSchemaBase {
	type: 'null'
}

export interface JSONSchemaArray<T> extends JSONSchemaBase {
	type: 'array'
	items: JSONSchema<T>
	minItems?: number
	maxItems?: number
	uniqueItems?: boolean
}

export interface JSONSchemaObject<T> extends JSONSchemaBase {
	type: 'object'
	properties: {
		[K in keyof T]: JSONSchema<T[K]>
	}
	required?: (keyof T)[]
	additionalProperties?: boolean | JSONSchema<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type JSONSchema<T = any> =
	| JSONSchemaString
	| JSONSchemaNumber
	| JSONSchemaBoolean
	| JSONSchemaNull
	| JSONSchemaArray<T>
	| JSONSchemaObject<T>

export type UISchema = any // eslint-disable-line @typescript-eslint/no-explicit-any
export type ErrorSchema = any // eslint-disable-line @typescript-eslint/no-explicit-any
// Matches a string that ends in a . character, optionally followed by a sequence of
// digits followed by any number of 0 characters up until the end of the line.
// Ensuring that there is at least one prefixed character is important so that

// you don't incorrectly match against "0".
const trailingCharMatcherWithPrefix = /\.([0-9]*0)*$/

// This is used for trimming the trailing 0 and . characters without affecting
// the rest of the string. Its possible to use one RegEx with groups for this
// functionality, but it is fairly complex compared to simply defining two
// different matchers.
const trailingCharMatcher = /[0.]0*$/
export function mapChangeEventValue(
	fn: (v: string) => string | number | null | undefined
) {
	return (e: ChangeEvent<HTMLInputElement>) => {
		return {
			target: {
				name: e.target.name,
				value: fn(e.target.value),
			},
		} as ChangeEvent<HTMLInputElement>
	}
}

export function valueAsNumber(value: string | number | null) {
	if (`${value}`.charAt(0) === '.') {
		value = `0${value}`
	}

	// Check that the value is a string (this can happen if the widget used is a
	// <select>, due to an enum declaration etc) then, if the value ends in a
	// trailing decimal point or multiple zeroes, strip the trailing values
	return typeof value === 'string' && value.match(trailingCharMatcherWithPrefix)
		? asNumber(value.replace(trailingCharMatcher, ''))
		: asNumber(String(value))
}

/**
 * Sholuld be used to translate json-schema keys
 * @param schema - json-schema
 * @returns - translated json-schema
 */
export function translateSchemaKeys<T>(
	schema: AjvJSONSchemaType<T>
): AjvJSONSchemaType<T> {
	return fromPairs(
		Object.entries(schema).map(([key, value]) => {
			if (!Array.isArray(value) && isObject(value)) {
				return [key, translateSchemaKeys(value as JSONSchema<T>)]
			} else if (Array.isArray(value) && key === 'enumNames') {
				return [key, value.map((val) => intl.get(val).d(val as string))]
			} else if (
				Array.isArray(value) &&
				['oneOf', 'anyOf', 'allOf'].includes(key)
			) {
				return [
					key,
					value.map((val) => translateSchemaKeys(val as JSONSchema<T>)),
				]
			} else if (['description', 'title'].includes(key)) {
				return [key, intl.get(value as string).d(value as string)]
			}
			return [key, value]
		})
	) as JSONSchema<T>
}
export function asNumber(value?: string | null) {
	if (value === '') {
		return undefined
	}
	if (value === null || value === undefined) {
		return null
	}
	if (/\.$/.test(value)) {
		// '3.' can't really be considered a number even if it parses in js. The
		// user is most likely entering a float.
		return value
	}
	if (/\.0$/.test(value)) {
		// we need to return this as a string here, to allow for input like 3.07
		return value
	}

	if (/\.\d*0$/.test(value)) {
		// It's a number, that's cool - but we need it as a string so it doesn't screw
		// with the user when entering dollar amounts or other values (such as those with
		// specific precision or number of significant digits)
		return value
	}

	const n = Number(value)
	const valid = typeof n === 'number' && !Number.isNaN(n)

	return valid ? n : value
}
/** Given a specific `value` attempts to guess the type of a schema element. In the case where we have to implicitly
 *  create a schema, it is useful to know what type to use based on the data we are defining.
 *
 * @param value - The value from which to guess the type
 * @returns - The best guess for the object type
 */
export function guessType(
	value:
		| string
		| number
		| boolean
		| null
		| (string | number | boolean)[]
		| object
) {
	if (Array.isArray(value)) {
		return 'array'
	}
	if (typeof value === 'string') {
		return 'string'
	}
	if (value == null) {
		return 'null'
	}
	if (typeof value === 'boolean') {
		return 'boolean'
	}
	if (!isNaN(value as number)) {
		return 'number'
	}
	if (typeof value === 'object') {
		return 'object'
	}
	// Default to string if we can't figure it out
	return 'string'
}

/**
 * Function which returns default state for schema bases on default values
 * @param schema - JSONSchema
 * @returns - default state
 */
export function getSchemaDefaultState<T>(
	schema: AjvJSONSchemaType<T>
): Record<string, unknown> {
	if (!schema) {
		return {}
	}
	const mapper = (acc = [], [key, value]: [string, JSONSchema<T>]) => {
		if (value?.default) {
			if (value.type === 'object') {
				const objDefaults = getSchemaDefaultState(value)
				return [
					...acc,
					[
						key,
						!isEmpty(objDefaults)
							? merge(value.default, objDefaults)
							: value.default,
					],
				]
			}
			return [...acc, [key, value.default]]
		}
		if (value?.type === 'object') {
			return [...acc, [key, getSchemaDefaultState(value)]]
		}
		return acc
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return pipe([entries, reduce(mapper as any, []), fromPairs])(
		schema.properties
	)
}

/**
 * Function which returns array of options with translated labels from JSONSchema field
 */
export const translateOptions = map<DataOption, DataOption>(
	(option: DataOption) => ({
		...option,
		label: intl.get(option.label).d(option.label),
	})
)
/**
 * Function which returns array of options from JSONSchema field
 * @param schema - JSONSchema
 * @returns - array of options
 */
export const getEnumOptions = (schema: JSONSchemaString): DataOption[] => {
	const enumValues = schema?.enum || []
	const enumNames = schema?.enumNames || schema?.enum || []
	return map(
		zipObject(['label', 'value']),
		zip(enumNames, enumValues)
	) as unknown as DataOption[]
}

/**
 * Function which returns uiSchema with mapped "ui:option"
 * @param uiSchema - uiSchema
 * @param options - object with options to map on "ui:option" type
 * @returns - uiSchema with mapped "ui:option"
 */
export const getUiSchema = (
	uiSchema: UISchema,
	options: Record<string, unknown>
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const mapperFn = ([key, value]: [string, any]) => {
		if (key === 'ui:options') {
			return [key, options?.[value?.key] || []]
		} else if (isArray(value)) {
			return [key, value]
		} else if (isObject(value)) {
			return [key, getUiSchema(value, options)]
		} else {
			return [key, value]
		}
	}
	const build: (s: UISchema) => UISchema = pipe([
		entries,
		map(mapperFn),
		fromPairs,
	])
	return build(uiSchema)
}

/**
 * Get resolved schema by schemaId
 * @param schemaId - schemaId to resolve
 * @returns - resolved schema
 */
export const getResolvedSchema = <T>(schemaId: string) => {
	return (schemas: SettigSchemaModel): AjvJSONSchemaType<T> => {
		return getSchemaResolver(schemas).getDerefSchema(schemaId)
	}
}

/**
 * Get schema resolver for schemas
 * @param schemas - SettigSchemaModel
 * @returns - schema resolver
 */
export const getSchemaResolver = (schemas: SettigSchemaModel): RefResolver => {
	const refResolver = new RefResolver()
	schemas.forEach((schema) => {
		refResolver.addSchema(schema)
	})
	return refResolver
}
