import {
	any,
	entries,
	filter,
	find,
	flatten,
	isEqual,
	last,
	map,
	pipe,
	prop,
	reduce,
	size,
	snakeCase,
	uniq,
	uniqBy,
	values,
} from 'lodash/fp'

import { treeHelpers } from '@cmpkit/base'
import { PALETTE } from '@cmpkit/theme'

import { CategoryModel } from '@/generated'

import { UPDATING } from './constants'
import {
	AlertLevel,
	OptimizationGroupTableRow,
	OptimizationModel,
	OptimizationStatus,
	ScheduleModel,
} from './types'

export const getStatusBadgeVariant = (status?: OptimizationStatus) => {
	switch (status) {
		case OptimizationStatus.Finished:
			return 'success'

		case OptimizationStatus.Canceled:
		case OptimizationStatus.New:
			return 'neutral'

		case OptimizationStatus.Updating:
			return 'neutral'

		case OptimizationStatus.Started:
		case OptimizationStatus.Pending:
			return 'warning'

		case OptimizationStatus.UpdatingFailed:
		case OptimizationStatus.Failed:
			return 'danger'

		case OptimizationStatus.Locked:
			return 'info'

		default:
			return 'default'
	}
}
export const getStatusPlainColor = (status?: OptimizationStatus) => {
	switch (status) {
		case OptimizationStatus.Finished:
			return PALETTE.green[50]

		case OptimizationStatus.Canceled:
		case OptimizationStatus.New:
			return PALETTE.neutral[50]

		case OptimizationStatus.Updating:
			return PALETTE.neutral[100]

		case OptimizationStatus.Started:
		case OptimizationStatus.Scheduled:
		case OptimizationStatus.Pending:
			return PALETTE.orange[50]

		case OptimizationStatus.UpdatingFailed:
		case OptimizationStatus.Failed:
			return PALETTE.red[50]

		case OptimizationStatus.Locked:
			return PALETTE.blue[50]

		default:
			return PALETTE.neutral[50]
	}
}
export const getOptStatus = (status?: OptimizationStatus) => {
	switch (status) {
		case OptimizationStatus.Finished:
			return OptimizationStatus.Finished

		case OptimizationStatus.Canceled:
		case OptimizationStatus.Updating:
		case OptimizationStatus.New:
			return OptimizationStatus.New

		case OptimizationStatus.Started:
		case OptimizationStatus.Scheduled:
			return OptimizationStatus.Started

		case OptimizationStatus.UpdatingFailed:
		case OptimizationStatus.Failed:
			return OptimizationStatus.Failed

		case OptimizationStatus.Locked:
			return OptimizationStatus.Locked

		default:
			return status
	}
}
export const getOgStatus = (status: OptimizationStatus): OptimizationStatus => {
	switch (status) {
		case OptimizationStatus.Finished:
			return OptimizationStatus.Finished

		case OptimizationStatus.Canceled:
		case OptimizationStatus.New:
			return OptimizationStatus.New

		case OptimizationStatus.Updating:
			return OptimizationStatus.Updating

		case OptimizationStatus.Started:
		case OptimizationStatus.Scheduled:
			return OptimizationStatus.Started

		case OptimizationStatus.UpdatingFailed:
			return OptimizationStatus.UpdatingFailed

		case OptimizationStatus.Failed:
			return OptimizationStatus.Failed

		case OptimizationStatus.Locked:
			return OptimizationStatus.Locked

		default:
			return status
	}
}
export type AssociatedStatus =
	| OptimizationStatus.New
	| OptimizationStatus.Started
	| OptimizationStatus.Pending
	| OptimizationStatus.Failed
	| OptimizationStatus.Finished
	| OptimizationStatus.Locked
export const getAssociatedStatus = (
	status: OptimizationStatus
): AssociatedStatus | OptimizationStatus => {
	switch (status) {
		case OptimizationStatus.Canceled:
		case OptimizationStatus.Updating:
			return OptimizationStatus.New
		case OptimizationStatus.Scheduled:
			return OptimizationStatus.Started
		case OptimizationStatus.UpdatingFailed:
			return OptimizationStatus.Failed
		default:
			return status
	}
}

export const groupOptimizationsByStatus = (
	groups: OptimizationGroupTableRow[]
) => {
	const statuses: Record<AssociatedStatus, OptimizationGroupTableRow[]> = {
		[OptimizationStatus.New]: [],
		[OptimizationStatus.Started]: [],
		[OptimizationStatus.Pending]: [],
		[OptimizationStatus.Failed]: [],
		[OptimizationStatus.Finished]: [],
		[OptimizationStatus.Locked]: [],
	}
	return groups.reduce((result, item) => {
		const status = getAssociatedStatus(item?.optimization?.status)

		if (!status) {
			return result
		}
		result[status as AssociatedStatus] ||= []
		result[status as AssociatedStatus].push(item)
		return result
	}, statuses)
}

export const getBulkImportResult = pipe([
	values,
	filter((v: number) => v > 0),
	reduce(
		(acc, data: number) => {
			acc.og_total += 1
			acc.sku_total += data
			return acc
		},
		{
			og_total: 0,
			sku_total: 0,
		}
	),
])
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBukImportErrorSummary = (err: any[]) => {
	const rowsErrors = map(
		pipe([
			entries,
			map(
				([key, val]: [string, string]) =>
					snakeCase(val[0]) + '::' + snakeCase(key)
			),
		])
	)(err) as unknown as string[]

	const getSummary = pipe([
		flatten,
		uniq,
		reduce(
			(acc, errHash: string) => ({
				...acc,
				[errHash]:
					rowsErrors.filter((row) => row.includes(errHash)).length || 0,
			}),
			{}
		),
		entries,
		map(([hash, rows]) => {
			const [error, field] = hash.split('::')
			return {
				error,
				field,
				rows,
			}
		}),
	])
	return getSummary(rowsErrors)
}

/**
 *
 * @param {Object} optimizations
 * Get optimizations count where status is equal UPDATING
 */
export const getUpdatingOptimizationsCount: (
	optimizations: OptimizationModel[]
) => number = pipe([
	values,
	filter(Boolean),
	filter({ status: UPDATING }),
	size,
])

/**
 *
 * @param {Object} optimizations
 * @param {Array} list
 * Return true if no one optimization has UPDATING status
 */
export const canMakeActionOnList = (items: OptimizationGroupTableRow[]) =>
	!items?.some(({ optimization: { status } }) => isEqual(status, UPDATING))

export const getScheduleByOptimizationGroupId = (
	schedules: ScheduleModel[],
	id: string
) => find((schedule) => schedule.optimization_groups.includes(id), schedules)

export const hasExportedOptimization = any(prop('optimization.is_exported'))

export const isEditableStatus = (status: OptimizationStatus) =>
	[
		OptimizationStatus.Finished,
		OptimizationStatus.New,
		OptimizationStatus.Failed,
		OptimizationStatus.UpdatingFailed,
	].includes(status)

export function getAlertVariant(level: string) {
	switch (level) {
		case AlertLevel.Warning:
			return 'warning'
		case AlertLevel.Info:
			return 'success'
		case AlertLevel.Unassigned:
		case AlertLevel.Critical:
			return 'danger'
		default:
			return 'info'
	}
}

export const getLastUpdateDateList = (list: OptimizationModel[]): Date[] =>
	list
		.map(prop('last_updated_date'))
		.filter(Boolean)
		.map((last_updated_date) => new Date(last_updated_date + 'Z'))
		.sort()
export const getLastUpdateDate = (list: OptimizationModel[]): Date =>
	last(getLastUpdateDateList(list)) as Date

export const toCategoriesTree = pipe([
	map((cat: CategoryModel) => ({ ...cat, parent: cat.parent_id })),
	uniqBy('id'),
	treeHelpers.getTree,
])
