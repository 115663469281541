import clsx from 'clsx'
import React from 'react'

import ColumnLabel from './ColumnLabel'
import ColumnOptions from './ColumnOptions'
import ColumnSortLabel from './ColumnSortLabel'
import { MHeader, TableInstance } from './types'

export default function MasterTableHeaderCell<T>({
	header,
	table,
}: {
	header: MHeader<T>
	table: TableInstance<T>
}) {
	const { column } = header
	const {
		options: { enableColumnActions },
	} = table
	const showColumnActions =
		(enableColumnActions || column.columnDef.enableColumnActions) &&
		column.columnDef.enableColumnActions !== false
	return (
		<>
			<div className='whitespace-nowrap'>
				{header.isPlaceholder ? null : (
					<div className='flex items-center justify-between text-xs'>
						<div
							onClick={column.getToggleSortingHandler()}
							className={clsx('flex items-center truncate', {
								'cursor-pointer': column.getCanSort(),
							})}
						>
							<ColumnLabel table={table} header={header} />
							{column.getCanSort() && (
								<ColumnSortLabel table={table} header={header} />
							)}
						</div>
						{showColumnActions && (
							<ColumnOptions table={table} header={header} />
						)}
					</div>
				)}
			</div>
			{header.column.getCanResize() && (
				<div
					{...{
						onDoubleClick: () => header.column.resetSize(),
						onMouseDown: header.getResizeHandler(),
						onTouchStart: header.getResizeHandler(),
						className: `resizer ${
							table.options.columnResizeDirection
						} ${header.column.getIsResizing() ? 'isResizing' : ''}`,
					}}
				/>
			)}
		</>
	)
}
