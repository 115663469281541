import { map, paths, pipe, prop, propOr, values } from 'lodash/fp'
import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { StateButton } from '@cmpkit/base'
import FilterIcon from '@cmpkit/icon/lib/glyph/filter'
import {
	FilterRuleEntity,
	Operators,
	ValueEditorType,
	ValueType,
} from '@cmpkit/query-builder'

import FiltersPopover from '@/components/filter/FiltersPopover'
import { rulesToQueryObject, toFilterRules } from '@/components/filter/utils'
import { getFiledConfig } from '@/components/refinement-bar/helpers'
import intl from '@/locale'
import { QuickFiltersBar } from '@/modules/preferences'
import { useAllUsersQuery } from '@/modules/Users/queries'
import { getLocationQuery, toQueryString } from '@/tools/location'
import { toOption } from '@/tools/utils'

import {
	FAILED,
	FINISHED,
	LOCKED,
	NEW,
	PENDING,
	STARTED,
} from '../../constants'
import { getSalesEntitiesTree } from '../../helpers'
import { useSalesEntitiesQuery, useSchedulesQuery } from '../../queries'
import { useProductGroupsTreeQuery } from '../../hooks/useProductGroupsTreeQuery'

const getSalesLevels = pipe([
	prop('sales_levels'),
	map(({ level, name }) => ({ value: String(level), label: name })),
])
export default function OptimizationGroupsFilters() {
	const navigate = useNavigate()
	const [storedKeys, setStoredKeys] = useState<string[]>([])
	const { search } = useLocation()
	const query = getLocationQuery(search)
	const users = useAllUsersQuery()
	const salesEntitiesQuery = useSalesEntitiesQuery()
	const productGroupsTreeQuery = useProductGroupsTreeQuery()

	const reviewers = usersOptions(users)
	const schedules = useSchedulesQuery()
	const fields = useMemo(() => getFields(), [])
	const statuses = useMemo(() => getStatuses(), [])
	const filters = toFilterRules(query)
	const onApply = (filters: FilterRuleEntity[]) => {
		navigate(toQueryString(rulesToQueryObject(filters)))
	}
	return (
		<>
			<FiltersPopover
				fields={fields}
				filters={filters ?? []}
				dataChoices={{
					'schedule.id': schedulesOptions(schedules),
					'group.sales_level_id': getSalesLevels(salesEntitiesQuery.data) || [],
					'group.pos_ids': getSalesEntitiesTree(salesEntitiesQuery.data) || [],
					product_groups: productGroupsTreeQuery.data || [],
					ui_status: statuses,
					reviewers,
				}}
				onApply={onApply}
			>
				<StateButton
					data-testid='og-table-filters-button'
					size='small'
					iconBefore={<FilterIcon />}
					onClear={() => onApply([])}
					active={!!filters.length}
				>
					{`${intl.get('general_filter')}`}{' '}
					{!!filters.length ? `(${filters.length})` : ''}
				</StateButton>
			</FiltersPopover>
			{salesEntitiesQuery.isLoading || productGroupsTreeQuery.isLoading ? (
				<div className='flex items-center space-x-2'>
					<div className='h-6 w-28 animate-pulse rounded-lg bg-accent-4' />
					<div className='h-6 w-20 animate-pulse rounded-lg bg-accent-4' />
					<div className='h-6 w-24 animate-pulse rounded-lg bg-accent-4' />
				</div>
			) : (
				<QuickFiltersBar
					irremovableKeys={['product_groups', 'group.pos_ids']}
					rules={filters}
					storedKeys={storedKeys}
					onSaveStoredKeys={setStoredKeys}
					onApply={onApply}
					fieldsConfig={getFiledConfig(
						fields.filter((f) =>
							['product_groups', 'group.pos_ids'].includes(f.value)
						),
						{
							'group.pos_ids':
								getSalesEntitiesTree(salesEntitiesQuery.data) || [],
							product_groups: productGroupsTreeQuery.data || [],
						}
					)}
					limit={2}
				/>
			)}
		</>
	)
}

const usersOptions = pipe([
	propOr([], 'data'),
	map(paths(['id', 'email'])),
	toOption,
])
const schedulesOptions = pipe([
	propOr({}, 'data'),
	values,
	map(paths(['id', 'name'])),
	toOption,
])
const getStatuses = () =>
	[NEW, STARTED, FAILED, FINISHED, LOCKED, PENDING].map((status) => ({
		value: status,
		label: intl.get(`opt_${status}`),
	}))
const getFields = () => [
	{
		value: 'ui_status',
		label: intl.get('optimization_groups_repricing_status'),
		valueType: ValueType.str,
		valueEditorType: ValueEditorType.multiselect,
		operations: [Operators.IN],
		enum: [],
	},
	{
		value: 'schedule.id',
		label: intl.get('general_schedule'),
		valueType: ValueType.array,
		valueEditorType: ValueEditorType.multiselect,
		enum: [],
	},
	{
		value: 'reviewers',
		label: intl.get('reviewers'),
		valueType: ValueType.array,
		valueEditorType: ValueEditorType.multiselect,
		enum: [],
	},
	{
		value: 'review_completed',
		label: intl.get('review_completed'),
		valueType: ValueType.boolean,
		valueEditorType: ValueEditorType.checkbox,
		enum: [],
	},
	{
		value: 'optimization.total_products',
		label: intl.get('general_products'),
		valueType: ValueType.number,
		enum: [],
	},
	{
		value: 'notifications_count',
		label: intl.get('field_schema_alerts'),
		valueType: ValueType.number,
		enum: [],
	},
	{
		value: 'group.sales_level_id',
		label: intl.get('og.level'),
		valueType: ValueType.str,
		valueEditorType: ValueEditorType.multiselect,
		operations: [Operators.IN],
		enum: [],
	},
	{
		value: 'group.pos_ids',
		label: intl.get('sales_unit').d('Sales unit'),
		valueType: ValueType.array,
		valueEditorType: ValueEditorType.checkboxTree,
		operations: [Operators.ANY_OF],
		enum: [],
	},
	{
		value: 'product_groups',
		label: intl.get('app.product_group').d('Product group'),
		valueType: ValueType.array,
		valueEditorType: ValueEditorType.checkboxTree,
		operations: [Operators.ANY_OF],
		enum: [],
	},
]
