import { AnimatePresence, motion } from 'framer-motion'
import { any, capitalize, prop } from 'lodash/fp'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Button, InlineMessage, LinkButton, Result } from '@cmpkit/base'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import dataUpdating from '@/assets/img/svg/data_updating_proccess.svg'
import { dialog } from '@/components/dialogs'
import { FormProvider, RHFCheckbox } from '@/components/HookForm'
import RHFFileDropArea from '@/components/HookForm/RHFFileDropArea'
import notify from '@/components/toasts'
import {
	OptimizationAction,
	OptimizationGroupModel,
	OptimizationModel,
} from '@/generated'
import intl from '@/locale'
import analytic from '@/services/analytics'
import { downloadFile } from '@/tools/report'

import { LOCKED } from '../constants'
import {
	useBulkOptimizationMutation,
	useImportOptimizationGroups,
} from '../mutations'
import { getBukImportErrorSummary, getBulkImportResult } from '../utils'

interface Props {
	items: {
		group: OptimizationGroupModel
		optimization: OptimizationModel
	}[]
	onResetSelection(): void
	close(): void
	isOpen: boolean
	optimizationGroupsCount: number
}
const ImportFromFileSchema = Yup.object().shape({
	product_diffs: Yup.mixed<File>()
		.required('File is required')
		.test({
			message: 'Please provide a supported file type. (xlsx, xls, csv)',
			test: (file, context) => {
				const isValid = ['xlsx', 'xls', 'csv'].includes(
					file?.name?.split('.').pop() || ''
				)
				if (!isValid) context?.createError()
				return isValid
			},
		}),
	ignore_empties: Yup.boolean(),
})
type ImportFromFileFormData = Yup.InferType<typeof ImportFromFileSchema>

export default function OptimizationGroupsImport({
	close,
	isOpen,
	items,
	optimizationGroupsCount,
	onResetSelection,
}: Props) {
	const importOptimizationGroups = useImportOptimizationGroups()
	const bulkAction = useBulkOptimizationMutation()
	const methods = useForm<ImportFromFileFormData>({
		defaultValues: {
			ignore_empties: true,
		},
		resolver: yupResolver<ImportFromFileFormData>(ImportFromFileSchema),
	})
	const [errors, setErrors] = useState<
		| string
		| {
				error: string
				field: string
				rows: string
		  }[]
		| null
	>(null)

	const onSubmit = async (formData: ImportFromFileFormData) => {
		try {
			const hasLockedOptimizations = any(
				({ optimization }) =>
					optimization?.is_exported && optimization?.status === LOCKED,
				items
			)
			if (hasLockedOptimizations) {
				const answer = await dialog.confirm({
					title: intl.get('action_confirm_title'),
					text: intl.get('optimization_remove_export_message'),
					okText: intl.get('general_remove'),
					cancelText: intl.get('general_cancel'),
				})
				if (answer) {
					await bulkAction.mutateAsync(
						items.map(({ group, optimization }) => ({
							optimization_group_id: group.id,
							optimization_id: optimization.id,
							action: OptimizationAction.DeleteExport,
						}))
					)
				} else {
					return
				}
			}
			const selected = items.map(prop('group.id'))
			setErrors(null)
			analytic.logEvent('mass repricing: update data from file', {
				'Ignore empty fields': formData.ignore_empties,
			})
			const { counts } = await importOptimizationGroups.mutateAsync({
				product_diffs: formData.product_diffs as File,
				ignore_empties: String(formData.ignore_empties),
				optimization_groups: selected.join(','),
			})
			const { sku_total, og_total } = getBulkImportResult(counts)
			notify.info({
				text: (
					<div>{`${intl.get('og_bulk_import_will_be_processed_items')} ${sku_total} ${intl.get(
						'og_bulk_import_will_be_processed_og'
					)} ${og_total}`}</div>
				),
			})

			onResetSelection()
			close()

			// TODO: fix any type
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error?.message) {
				setErrors(error?.message)
			} else if (error.errors) {
				setErrors(getBukImportErrorSummary(error.errors))
			} else {
				setErrors(intl.get('fatal_error_title'))
			}
		}
	}

	const handleClose = () => close()
	const handleBackToImport = () => setErrors(null)
	const handleDownloadTemplate = () =>
		downloadFile('/api/v1/import_template/', 'import_template.xlsx')
	const scene = useMemo(() => {
		const isMainScene = !importOptimizationGroups.isPending && !errors
		const isErrorScene = !importOptimizationGroups.isPending && errors
		if (isMainScene) {
			return 'form'
		} else if (isErrorScene) {
			return 'errors'
		} else {
			return 'proccess'
		}
	}, [importOptimizationGroups.isPending, errors])

	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={handleClose} size='mini'>
					<ModalHeader>
						<ModalTitle>{intl.get('og_bulk_import_title')}</ModalTitle>
					</ModalHeader>
					<ModalBody className='relative flex flex-col'>
						<AnimatePresence mode={'wait'}>
							{scene === 'errors' && (
								<StepContent key='errors'>
									<div className='flex flex-col'>
										<h3 className='text-center'>
											{intl.get('og_bulk_import_error')}
										</h3>
										<p className='pb-5 text-center'>
											{intl.get('og_bulk_import_canceled')}
										</p>
										{errors && Array.isArray(errors) ? (
											<div>
												<h4>{intl.get('general_error_types')}:</h4>
												{errors.map((error, i) => {
													return (
														<p key={error + ' ' + i}>
															{capitalize(error.error.split('_').join(' '))}
															<span className='ml-1'>in</span>
															<span className='mx-1 text-brand'>
																{error.field}
															</span>
															{'column:'}
															<span className='mx-1 text-brand'>
																{error.rows}
															</span>
														</p>
													)
												})}
											</div>
										) : (
											<div>
												<p className='pb-2.5 text-danger'>{errors}</p>
												<h4 className='pb-2.5'>
													{intl.get('general_possible_reasons')}:
												</h4>
												<p
													className='ml-5'
													dangerouslySetInnerHTML={{
														__html: intl.get('og_import_error_hint_html'),
													}}
												/>
											</div>
										)}
									</div>
								</StepContent>
							)}
							{scene === 'proccess' && (
								<StepContent key='proccess'>
									<Result
										className='py-5'
										icon={<img src={dataUpdating} className='animate-pulse' />}
										title={intl.get('og_bulk_import_updating')}
										subtitle={intl.get('og_bulk_import_updating_hint')}
									/>
								</StepContent>
							)}
							{scene === 'form' && (
								<StepContent key='form'>
									<FormProvider
										methods={methods}
										onSubmit={methods.handleSubmit(onSubmit)}
										className='flex flex-col'
									>
										<div className='mb-4 flex items-center gap-1'>
											<strong>{items.length}</strong>
											<span>{intl.get('general_of')}</span>
											<strong>{optimizationGroupsCount}</strong>
											<span>{intl.get('og_bulk_import_count_hint')}</span>
										</div>
										<RHFFileDropArea
											className='min-h-28'
											name='product_diffs'
										/>
										<RHFCheckbox
											name='ignore_empties'
											label={intl.get('modal_import_ignore_empties')}
											/* description={intl.get('modal_import_ignore_empties_hint')} */
										/>
										<h4 className='mb-2 text-sm font-semibold'>
											{intl.get('modal_import_guide')}
										</h4>
										<ul className='mb-4 list-inside list-decimal'>
											<li>
												{intl.get('og_import_step_1')}
												<LinkButton
													variant='brand'
													onClick={handleDownloadTemplate}
													className='ml-1 text-blue-200'
												>
													{intl.get('og_import_types')}
												</LinkButton>
											</li>
											<li>{intl.get('og_import_step_2')}</li>
											<li>{intl.get('og_import_step_3')}</li>
										</ul>
										<InlineMessage
											variant='warning'
											className='text-xs font-medium'
										>
											{intl.get('og_import_optimizations_hint')}
										</InlineMessage>
									</FormProvider>
								</StepContent>
							)}
						</AnimatePresence>
					</ModalBody>
					{scene === 'form' && (
						<ModalFooter className='justify-between space-x-2'>
							<Button onClick={handleClose}>
								{intl.get('general_cancel')}
							</Button>
							<Button
								variant='primary-brand'
								disabled={!methods.watch('product_diffs')}
								onClick={methods.handleSubmit(onSubmit)}
							>
								{intl.get('general_apply')}
							</Button>
						</ModalFooter>
					)}
					{scene === 'errors' && (
						<ModalFooter className='justify-start'>
							<Button
								onClick={handleBackToImport}
								iconBefore={<CaretLeftIcon />}
							>
								{intl.get('general_back')}
							</Button>
						</ModalFooter>
					)}
				</Modal>
			)}
		</ModalTransition>
	)
}
function StepContent({ children }: { children: React.ReactNode }) {
	return (
		<motion.div
			initial={{ opacity: 0, x: 100 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: -30 }}
			className={'flex size-full flex-col'}
			transition={{
				duration: 0.2,
			}}
		>
			{children}
		</motion.div>
	)
}
