import { JSONSchemaType } from 'ajv/dist/core'
import { sortBy } from 'lodash/fp'
import React, { MouseEvent, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
	Button,
	FormGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
	Textfield,
} from '@cmpkit/base'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import Select, { SingleValue } from '@cmpkit/select'

import { DataOption } from '@/common.types'
import ErrorBoundary from '@/components/ErrorBoundary'
import intl from '@/locale'

import analytic from '@/services/analytics'
import { numericOnlyPositive } from '@/tools/constants'
import { MinimalStepRuleModel } from '@/generated'

const getValueTypes = (): DataOption[] => [
	{
		label: intl.get('relative'),
		value: 'relative',
	},
	{
		label: intl.get('absolute'),
		value: 'absolute',
	},
]
const opozitProp = (prop: string) =>
	prop === 'min_step_relative' ? 'min_step_absolute' : 'min_step_relative'

/**
 * Return first not empty type or first type if all empty
 * @param item MinimalStepRuleModel
 * @param options Option[]
 * @returns Option | null
 */
const getInitStepValueType = (
	item: MinimalStepRuleModel,
	options: DataOption[]
): DataOption | undefined => {
	const types = Object.entries(item).filter(([key]) => key !== 'min_price')
	const notEmptyType = types.find(([key, value]) => key && value !== 0)
	if (notEmptyType) {
		return options.find(({ value }) => `min_step_${value}` === notEmptyType[0])
	} else {
		return options.find(({ value }) => `min_step_${value}` === types[0][0])
	}
}

type StepItemProps = {
	isRemovable: boolean
	item: MinimalStepRuleModel
	index: number
	max: number | string
	onRemoveClick(index: number): void
	onChange(index: number, name: string, value: string): void
	onBlur(): void
}

function StepItem({
	isRemovable,
	item,
	index,
	max,
	onBlur,
	onRemoveClick,
	onChange,
}: StepItemProps) {
	const valueTypesOptions = getValueTypes()
	const [stepValueType, setStepValueType] = useState<DataOption>(
		getInitStepValueType(item, valueTypesOptions) ?? valueTypesOptions[0]
	)
	useEffect(() => {
		setStepValueType(
			getInitStepValueType(item, valueTypesOptions) ?? valueTypesOptions[0]
		)
	}, [item])

	const stepValuePropName = `min_step_${stepValueType.value}`
	const stepValue = item?.[stepValuePropName as keyof MinimalStepRuleModel]

	const _onRemoveClick = () => onRemoveClick(index)
	const _onBlur = () => onBlur()
	const _onMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		onChange(index, 'min_price', event.target.value)
	const _onMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		onChange(index + 1, 'min_price', event.target.value)
	const _onStepChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange(index, stepValuePropName, event.target.value)
	}
	const _onValueTypeChange = (stepValueType: SingleValue<DataOption>) => {
		if (!stepValueType?.value) return
		const stepValuePropName = `min_step_${stepValueType.value}`
		onChange(
			index,
			stepValuePropName,
			String(item?.[opozitProp(stepValuePropName)] ?? '')
		)
		setStepValueType(stepValueType)
	}
	return (
		<TableRow data-testid={`pc-min-price-step-row-${index}`}>
			<TableCell className='border-none p-2'>
				<div className='flex items-center'>
					<FormGroup
						className='mb-0'
						data-testid={'pc-min-price-step-rule-min'}
					>
						<Textfield
							value={item.min_price}
							onBlur={_onBlur}
							disabled={index === 0}
							invalid={!('' + item.min_price).length}
							placeholder={intl.get('empty')}
							onChange={_onMinPriceChange}
						/>
					</FormGroup>
					<span className='mx-2.5'>{'-'}</span>
					<FormGroup
						className='mb-0'
						data-testid={'pc-min-price-step-rule-max'}
					>
						<Textfield value={max} disabled onChange={_onMaxPriceChange} />
					</FormGroup>
				</div>
			</TableCell>
			<TableCell className='border-none p-2'>
				<div className='flex items-center'>
					<FormGroup
						className='mb-0 mr-2.5 w-64'
						data-testid={'pc-min-price-step-rule-value-type'}
					>
						<Select
							menuPortalTarget={document.body}
							value={stepValueType}
							options={valueTypesOptions}
							onChange={_onValueTypeChange}
						/>
					</FormGroup>
					<FormGroup
						className='mb-0'
						data-testid={'pc-min-price-step-rule-value'}
					>
						<Textfield
							value={stepValue}
							invalid={!stepValue && stepValue !== 0}
							onChange={_onStepChange}
							addonAfter={stepValueType.value === 'absolute' ? '#' : '%'}
						/>
					</FormGroup>
					{isRemovable ? (
						<Button
							variant='tertiary'
							iconBefore={<CrossIcon />}
							className='ml-auto'
							onClick={_onRemoveClick}
							data-testid={'pc-min-price-step-rule-remove'}
						/>
					) : (
						<div className='w-8 shrink-0' />
					)}
				</div>
			</TableCell>
		</TableRow>
	)
}
function MinimalPriceStepManager(props: {
	formData: MinimalStepRuleModel[]
	onChange: (data: MinimalStepRuleModel[]) => void
	schema: JSONSchemaType<MinimalStepRuleModel[]>
}) {
	const { formData, onChange, schema } = props
	const steps: MinimalStepRuleModel[] = formData || schema.default || []
	const handleChange = (index: number, name: string, value: string) => {
		const maxValue = Number(value.replace(numericOnlyPositive, ''))
		const data = steps.map((item, i) => {
			return index === i
				? ({
						min_price: item.min_price,
						// Params order is important for correct work
						[name]: maxValue < 0 ? 1 : maxValue,
						[opozitProp(name)]: 0,
					} as unknown as MinimalStepRuleModel)
				: item
		})
		onChange(data)
	}
	const handleAddStep = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		analytic.logEvent('settings: pricing campaigns: add a minimal price change')
		const prevStep = steps[steps.length - 1]
		const newItem: MinimalStepRuleModel = {
			min_price: +prevStep.min_price + 500,
			min_step_absolute: prevStep.min_step_absolute || 0,
			min_step_relative: prevStep.min_step_relative || 0,
		}
		onChange([...steps, newItem])
	}
	const handleRemoveStep = (index: number) => {
		analytic.logEvent(
			'settings: pricing campaigns: delete a minimal price change'
		)
		onChange(steps.filter((item, i) => i !== index))
	}
	const onBlur = () => onChange(sortBy('min_price', [...steps]))

	return (
		<>
			<div className='mb-2 w-full border-b border-solid'>
				<Table>
					<TableHeader className='!top-auto'>
						<TableRow>
							<TableHead className='w-1/3 bg-transparent p-2'>
								{intl.get('general_range')}
							</TableHead>
							<TableHead className='w-2/3 bg-transparent p-2'>
								{intl.get('value')}
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{steps?.length > 0 ? (
							steps.map((item: MinimalStepRuleModel, index: number) => {
								const max = steps[index + 1]
									? steps[index + 1].min_price - 1
									: '∞'
								return (
									<StepItem
										key={index}
										max={max}
										onBlur={onBlur}
										isRemovable={steps.length > 1 && index > 0}
										onRemoveClick={handleRemoveStep}
										onChange={handleChange}
										item={item}
										index={index}
									/>
								)
							})
						) : (
							<TableRow>
								<TableCell colSpan={2} className='border-none'>
									<div className='p-5 text-center text-muted'>
										{intl.get('general_no_data')}
									</div>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<Button
				disabled={steps.length >= 15}
				variant='tertiary'
				iconBefore={<PlusIcon />}
				data-testid={'pc-min-price-step-add-rule'}
				onClick={handleAddStep}
			>
				{intl.get('optimization_groups_settings_add_price')}
			</Button>
		</>
	)
}

export default function MinimalPriceStep({
	name,
	shouldUnregister,
	defaultValue,
	schema,
}: {
	name: string
	shouldUnregister?: boolean
	defaultValue?: MinimalStepRuleModel[]
	schema: JSONSchemaType<MinimalStepRuleModel[]>
}) {
	const { control } = useFormContext()
	return (
		<Controller
			name={name}
			control={control}
			shouldUnregister={shouldUnregister}
			defaultValue={defaultValue}
			render={({ field: { value, onChange } }) => {
				return (
					<ErrorBoundary>
						<MinimalPriceStepManager
							formData={value}
							onChange={onChange}
							schema={schema}
						/>
					</ErrorBoundary>
				)
			}}
		/>
	)
}
