import React from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import {
	FormError,
	FormGroup,
	FormHint,
	FormLabel,
	Required,
	Textfield,
} from '@cmpkit/base'
import HelpCircleIcon from '@cmpkit/icon/lib/glyph/help-circle'
import Tooltip from '@cmpkit/tooltip'

import { asNumber } from '@/tools/utils'

const trailingCharMatcherWithPrefix = /\.([0-9]*0)*$/
const trailingCharMatcher = /[0.]0*$/

const convertToNumber = (value: string) => {
	return typeof value === 'string' && value.match(trailingCharMatcherWithPrefix)
		? asNumber(value.replace(trailingCharMatcher, ''))
		: asNumber(value)
}
export default function RHFTextField({
	name,
	label,
	hint,
	description,
	defaultValue,
	shouldUnregister,
	required,
	...other
}: UseControllerProps & {
	type?: string
	label: string
	hint?: string
	description?: string
	required?: boolean
	placeholder?: string
	addonAfter?: React.ReactNode
	addonBefore?: React.ReactNode
} & React.InputHTMLAttributes<HTMLInputElement>) {
	const { control } = useFormContext()
	return (
		<FormGroup>
			<FormLabel>
				{label}
				{required && <Required />}
			</FormLabel>
			<Controller
				name={name}
				defaultValue={defaultValue}
				shouldUnregister={shouldUnregister}
				control={control}
				render={({ field, fieldState: { error } }) => (
					<>
						<div className='flex w-full items-center gap-2'>
							<Textfield
								{...other}
								name={name}
								value={field.value}
								invalid={!!error}
								onChange={(e) => {
									return ['number', 'integer'].includes(other?.type as string)
										? field.onChange(convertToNumber(e.target.value))
										: field.onChange(e.target.value)
								}}
							/>
							{hint && (
								<Tooltip placement='top' content={hint}>
									<HelpCircleIcon className='text-muted' />
								</Tooltip>
							)}
						</div>
						{!!description && (
							<FormHint className='items-center'>{description}</FormHint>
						)}
						{error && <FormError>{error.message}</FormError>}
					</>
				)}
			/>
		</FormGroup>
	)
}
