import React, { createContext } from 'react'

import { RowData } from '@tanstack/react-table'

import { TableInstance } from './types'

export interface MasterTableContextValue<T extends RowData = RowData> {
	table: TableInstance<T>
}

export const MasterTableContext = createContext<MasterTableContextValue>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	{} as any
)

export default function MasterTableProvider<T extends RowData>({
	children,
	table,
}: {
	children: React.ReactNode
	table: TableInstance<T>
}) {
	return (
		<MasterTableContext.Provider
			value={
				{
					table,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} as any
			}
		>
			{children}
		</MasterTableContext.Provider>
	)
}
