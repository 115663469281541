import { useEffect } from 'react'
import toast from 'react-hot-toast'

import { queryClient } from '@/lib/query-client'
import { Message } from '@/network/websocket/types'
import {
	OptimizationUpdateEventPayload,
	SocketMessagesNotification,
	useSocketChannel,
} from '@/providers/SocketProvider'

const socketMessagesNotification = new SocketMessagesNotification()
export const useOptimizationUpdateChannel = () => {
	const channel = useSocketChannel('optimization')
	useEffect(() => {
		toast.dismiss('common')
	}, [location.search])
	useEffect(() => {
		const handler = (msg: Message<OptimizationUpdateEventPayload>) => {
			sessionStorage.setItem('outdated', 'true')
			socketMessagesNotification.subscribe()
			socketMessagesNotification.addMessage({
				msgBody: msg,
				showButton: true,
				onClick: () => {
					resetCommonQueries()
					sessionStorage.setItem('outdated', 'false')
				},
			})
		}
		if (channel) {
			channel.subscribe()
			channel.on('update', handler)
		}

		return () => {
			toast.dismiss('common')
			channel?.off('update', handler)
		}
	}, [channel])
	useEffect(() => {
		return () => socketMessagesNotification?.unsubscribe()
	}, [])
}

//Reset common queries
const resetCommonQueries = () => {
	queryClient.invalidateQueries({
		queryKey: ['optimizations'],
	})
	queryClient.invalidateQueries({
		queryKey: ['optimization-groups'],
	})
	queryClient.invalidateQueries({
		queryKey: ['pricing-alerts'],
	})
	queryClient.invalidateQueries({
		queryKey: ['assortment-list'],
	})
}
