import {
	entries,
	filter,
	fromPairs,
	isEqual,
	pickAll,
	pipe,
	uniq,
} from 'lodash/fp'
import memoize from 'memoize-one'
import React, { useEffect, useRef, useState } from 'react'

import { Button } from '@cmpkit/base'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'

import { Meta, OptionType, SelectMeta, ValuesType } from '../types'
import { cloneObj, isEqualArr, stringCompare } from '../utils'
import {
	CommonProps,
	ProviderContext,
	useRefinementBar,
} from './ContextProvider'
import { FilterButton } from './FilterButton'
import { FilterManager } from './FilterManager'
import Popup, { DialogInner } from './Popup'

type RefinementBarProps = {
	/** The key of the "active" popup; use this with `onPopupOpen` and `onPopupClose` to take control of the field popups. */
	activePopupKey?: string | null
	/** Called when a field popup is opened, with the field key. */
	onPopupOpen?: (key: string) => void
	/** Called when a field popup is closed. */
	onPopupClose?: () => void
	/** Access the field elements by reference. Any keys present should match those of the `fieldConfig`. */
	refs?: Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
	/** The maximum number of fields to display before collapsing. */
	limit?: number
	/** Whether the refinement bar should be expandable. */
	isExpandable?: boolean
}
type PopupTargetProps = {
	ref: React.Ref<HTMLButtonElement>
	onClick: () => void
	onKeyDown: () => void
	isOpen: boolean
}

export function RefinementBar(
	props: RefinementBarProps & { context: ProviderContext }
) {
	const { isExpandable = false, limit = 5 } = props
	const [activePopupKey, setActivePopupKey] = useState<string | null>(null)
	const [invalidState, setInvalidState] = useState<{ [key: string]: string }>(
		{}
	)
	const [isExpanded, setIsExpanded] = useState<boolean>(true)
	const [stateValues, setStateValues] = useState<ValuesType>({})

	useEffect(() => {
		setStateValues(props.context.value)
	}, [props.context.value])

	const showLessRef = useRef<HTMLButtonElement | null>(null)
	const showAllRef = useRef(null)
	const mapKeyToOption = (value: string) => {
		const field = props.context.fieldConfig[value]
		const label = field?.label || value
		return { label, value }
	}
	const filterOptions = props.context.removeableKeys.map(mapKeyToOption)

	const getActivePopup = () => {
		return props.activePopupKey === undefined
			? activePopupKey
			: props.activePopupKey
	}

	const openPopup = (key: string) => {
		const { onPopupOpen } = props
		if (onPopupOpen) onPopupOpen(key)
		setActivePopupKey(key)
	}

	const closePopup = () => {
		const { onPopupClose } = props

		if (onPopupClose) onPopupClose()
		setActivePopupKey(null)
	}

	const handleFieldAdd = async (key: string) => {
		const field = props.context.fieldConfig[key]
		const data = field.getInitialValue()
		const meta: Meta = { action: 'add', key, data }
		const values = await cloneObj(stateValues, { add: { [key]: data } })

		openPopup(key)
		setStateValues(values)
		isExpandable && setIsExpanded(true)
		props.context.onChange(values, meta)
	}

	const handleFieldRemove = async (key: string, event?: Event) => {
		if (event) event.preventDefault()
		const values = await cloneObj(stateValues, { remove: key })
		setStateValues(values)
		props.context.onChange(values, { action: 'remove', key })
	}
	const handleBulkFieldRemove = (keys: string[]) => {
		let values = stateValues
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i]
			values = cloneObj(stateValues, { remove: key })
		}
		setStateValues(values)
		props.context.onChange(values, { action: 'bulk-remove', keys })
	}

	const handleFieldClear = (key: string) => {
		const field = props.context.fieldConfig[key]
		const value = field.getInitialValue()
		const values = cloneObj(stateValues, { add: { [key]: value } })
		setStateValues(values)
		props.context.onChange(values, { action: 'clear', key })
	}

	const handleFieldReset = (key: string) => () => {
		const originalValue = props.context.value[key]
		if (!originalValue) {
			const values = cloneObj(stateValues, { remove: key })
			setStateValues(values)
			setInvalidState(cloneObj(invalidState, { remove: key }))
			props.context.onChange(values, { action: 'reset', key } as Meta)
		} else {
			const values = cloneObj(stateValues, { add: { [key]: originalValue } })
			setInvalidState(cloneObj(invalidState, { remove: key }))
			setStateValues(values)
			props.context.onChange(values, { action: 'reset', key } as Meta)
		}
	}

	const getValidValues = pipe([
		entries,
		filter(([key]) => !invalidState[key]),
		fromPairs,
	])
	const handleFieldApply = (key: string) => () => {
		if (stateValues[key] === props.context.value[key]) {
			return
		}
		const { fieldConfig } = props.context
		const value = stateValues[key]
		const oldInvalid = invalidState
		const field = fieldConfig[key]
		const invalidMessage = field.validate(value)
		let invalid = oldInvalid
		if (invalidMessage) {
			invalid = cloneObj(oldInvalid, { add: { [key]: invalidMessage } })
			setInvalidState(invalid)
			return
		} else if (oldInvalid[key]) {
			invalid = cloneObj(oldInvalid, { remove: key })
			setInvalidState(invalid)
		}
		const data = stateValues[key]
		const meta: Meta = { action: 'update', key, data }
		props.context.onChange(getValidValues(stateValues), meta)
	}
	const handleFieldChange =
		(key: string) => (value: string | number | boolean | string[]) => {
			const values = cloneObj(stateValues, { add: { [key]: value } })
			setStateValues({ ...values })
		}

	const makeField = (config?: { isRemovable?: boolean }) => (key: string) => {
		const fieldModel = props.context.fieldConfig[key]

		// Catch invalid configurations
		if (!fieldModel) {
			const likelySource = config?.isRemovable ? 'value' : 'irremovableKeys'
			// eslint-disable-next-line no-console
			console.error(
				`Couldn't find a matching field config for key "${key}". There may be stale or invalid keys in \`${likelySource}\`.`
			)
			return null
		}

		const field = fieldModel
		const FieldView = field.type.view as React.ComponentType<any> // eslint-disable-line @typescript-eslint/no-explicit-any

		// Catch missing views:
		// This should only really happen when developing a new field type
		if (!FieldView) {
			// eslint-disable-next-line no-console
			console.error(
				`Couldn't find the View (${field.type.name}) for key "${key}".`
			)
			return null
		}

		const invalidMessage = invalidState[key]
		const isInvalid = Boolean(invalidMessage)

		// Values
		const initialValue = field.getInitialValue()
		const storedValue = props.context.value[key] || initialValue
		const localValue = stateValues[key] || initialValue

		const hasPopup = typeof field.formatLabel === 'function'
		const popupIsOpen = getActivePopup() === key

		const fieldUI = (renderContextProps: object) => {
			const extra = { ...config, ...renderContextProps }
			return (
				<FieldView
					closePopup={hasPopup ? closePopup : undefined}
					field={field}
					invalidMessage={invalidMessage}
					key={key}
					isDirty={
						!isEqual(
							pickAll(['operation', 'value'], storedValue),
							pickAll(['operation', 'value'], localValue)
						)
					}
					onClear={() => handleFieldClear(key)}
					onReset={handleFieldReset(key)}
					onRemove={() => handleFieldRemove(key)}
					onChange={handleFieldChange(key)}
					onApply={handleFieldApply(key)}
					refinementBarValue={props.context.value}
					storedValue={storedValue}
					localValue={localValue}
					{...extra}
				/>
			)
		}

		return hasPopup ? (
			<Popup
				key={key}
				isOpen={popupIsOpen}
				onOpen={() => openPopup(key)}
				onClose={closePopup}
				allowClose={false}
				target={(targetProps: PopupTargetProps) => (
					<FilterButton
						{...targetProps}
						isInvalid={isInvalid}
						isSelected={popupIsOpen}
						onClear={
							stringCompare(storedValue, initialValue)
								? undefined
								: () => handleFieldClear(key)
						}
					>
						{field.formatLabel(storedValue)}
					</FilterButton>
				)}
			>
				{fieldUI}
			</Popup>
		) : (
			fieldUI({ innerRef: props.refs?.[key as string] })
		)
	}

	const onChangeFilter = (options: OptionType[], meta: SelectMeta) => {
		switch (meta.action) {
			case 'clear-options':
				//options.forEach((o) => handleFieldRemove(o.value))
				handleBulkFieldRemove(options.map((o) => o.value as string))
				break
			case 'select-option':
				handleFieldAdd(meta.option?.value as string)
				break
			case 'deselect-option':
				handleFieldRemove(meta.option?.value as string)
				break
			default:
		}
		closePopup()
	}

	const getFilterValue = memoize((keys) => {
		return keys.map(mapKeyToOption)
	})

	const showAll = (isExpanded: boolean) => () => {
		setIsExpanded(isExpanded)
		const target = isExpanded ? showLessRef.current : showAllRef.current
		if (target && typeof target.focus === 'function') {
			target.focus()
		}
	}

	const shouldDisplayAddUI = () => {
		const { fieldKeys, irremovableKeys, selectedKeys } = props.context

		const visibleSelectedKeys = selectedKeys.filter(
			(key) => props.context.fieldConfig?.[key]
		)
		return (
			!isEqualArr(fieldKeys, irremovableKeys) &&
			uniq([...irremovableKeys, ...visibleSelectedKeys])?.length < limit
		)
	}

	const { irremovableKeys, selectedKeys } = props.context

	const FILTER_POPUP_KEY = '__refinement-bar-more-menu__'

	return (
		<div className='flex flex-wrap space-x-1'>
			{irremovableKeys.map(makeField({ isRemovable: false }))}
			{isExpanded && selectedKeys.map(makeField({ isRemovable: true }))}

			{/* Show More/Less Control */}
			{!isExpanded && selectedKeys.length ? (
				<Button ref={showAllRef} size='small' onClick={showAll(true)}>
					{intl.get('general_show_more', {
						value: selectedKeys.length,
					})}
				</Button>
			) : null}

			{/* Add Filter Popup */}
			{shouldDisplayAddUI() ? (
				<Popup
					onOpen={() => openPopup(FILTER_POPUP_KEY)}
					onClose={closePopup}
					isOpen={getActivePopup() === FILTER_POPUP_KEY}
					target={(targetProps: PopupTargetProps) => (
						<Tooltip
							delay={500}
							content={intl.get('app.add_quick_filter').d('Add quick filter')}
						>
							<Button
								{...targetProps}
								variant='dashed'
								size='small'
								iconBefore={<PlusIcon />}
								active={targetProps.isOpen}
							>
								{intl.get('app.add_filter').d('Add filter')}
							</Button>
						</Tooltip>
					)}
				>
					{() => (
						<DialogInner className='min-w-[220px]'>
							<FilterManager
								options={filterOptions}
								onChange={onChangeFilter}
								value={getFilterValue(selectedKeys)}
							/>
						</DialogInner>
					)}
				</Popup>
			) : null}

			{isExpandable && isExpanded && selectedKeys.length ? (
				<Button size='small' ref={showLessRef} onClick={showAll(false)}>
					{intl.get('app.show_less').d('Show less')}
				</Button>
			) : null}
		</div>
	)
}

type RefinementBarControlledProps = CommonProps & RefinementBarProps

const RefinementBarControlled = ({
	initialKeys,
	activePopupKey,
	fieldConfig,
	irremovableKeys,
	onChange,
	onPopupClose,
	onPopupOpen,
	refs,
	value,
	isExpandable,
	limit,
}: RefinementBarControlledProps) => {
	const context = useRefinementBar({
		fieldConfig,
		initialKeys,
		irremovableKeys,
		onChange,
		value,
	})
	return (
		<RefinementBar
			activePopupKey={activePopupKey}
			onPopupOpen={onPopupOpen}
			onPopupClose={onPopupClose}
			context={context}
			isExpandable={isExpandable}
			limit={limit}
			refs={refs}
		/>
	)
}

export default RefinementBarControlled
