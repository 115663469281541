import { isNil, prop } from 'lodash/fp'

import { Column } from '@cmpkit/data-table'

import intl from '@/locale'
import { formatNumber, NumberFormats } from '@/tools/locale'
import NumberField from '@/components/data-grid/fields/Number/TableCell'
import { ColumnDataType } from '@/components/data-grid/types'

const getTextColor = (value?: number) => {
	if (value === undefined || value === null) {
		return ''
	}
	if (value > 0) {
		return 'text-success'
	}
	if (value < 0) {
		return 'text-danger'
	}
}
export default function getColumns({
	metrics,
}: {
	metrics: string[]
}): Column[] {
	return [
		new Column({
			id: 'name',
			title: intl.get('pricing_campaign').d('Pricing Campaign'),
			locked: true,
			sortable: true,
			valueGetter: (row) => row?.pricing_campaign_name || 'n/a',
			width: 290,
		}),
		...metrics.reduce((cols, metric) => {
			cols.push(
				new Column({
					id: metric,
					title: intl.get(metric),
					sortable: true,
					fixable: true,
					locked: false,
					renderer: (value) => {
						return (
							<NumberField
								value={value}
								schema={{ type: ColumnDataType.Float }}
							/>
						)
					},
					valueGetter: prop(metric),
					width: 125,
				})
			)

			cols.push(
				new Column({
					id: metric + '_difference',
					title: intl.get('field_schema_delta_abs').d('Δ'),
					sortable: true,
					fixable: true,
					renderer: (value?: number) => {
						return (
							<div className='flex items-center justify-end font-mono'>
								<p className={getTextColor(value)}>
									{isNil(value) && value != 0 ? '-' : formatNumber(value)}
								</p>
							</div>
						)
					},
					valueGetter: prop(metric + '_difference'),
					width: 125,
				})
			)
			cols.push(
				new Column({
					id: metric + '_difference_percent',
					title: intl.get('field_schema_delta_pp').d('Δ %'),
					sortable: true,
					fixable: true,
					renderer: (value?: number) => {
						return (
							<div className='flex items-center justify-end font-mono'>
								<p className={getTextColor(value)}>
									{isNil(value) && value != 0
										? '-'
										: formatNumber(value, NumberFormats.Percent)}
								</p>
							</div>
						)
					},
					valueGetter: prop(metric + '_difference_percent'),
					width: 125,
				})
			)
			return cols
		}, [] as Column[]),
	]
}
