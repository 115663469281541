import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { map, prop } from 'lodash/fp'
import * as qs from 'qs'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

import {
	Button,
	Content,
	Header,
	Label,
	Layout,
	Loader,
	Switch,
	TabItem,
	Tabs,
	Tag,
} from '@cmpkit/base'
import Drawer from '@cmpkit/drawer'
import AnalyticsGrowIcon from '@cmpkit/icon/lib/glyph/analytics-grow'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import { NumberFormats } from '@cmpkit/numbers'
import { FilterRuleEntity } from '@cmpkit/query-builder'

import { decodeComplexQuery } from '@/components/data-grid/helpers'
import ErrorBoundary from '@/components/ErrorBoundary'
import intl from '@/locale'
import { FINISHED, LOCKED } from '@/modules/core/constants'
import { useDrawer } from '@/modules/drawers/store'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'

import { useOptimizationGroupsByProductFiltersQuery } from '../../queries'
import {
	OptimizationGroupsType,
	SelectedOptimizationGroupType,
} from './helpers'
import { MetricsSummaryWidget } from './MetricsSummaryWidget'
import { OptimizationGroupsDropdown } from './OptimizationGroupsDropdown'
import { OptimizationTab } from './OptimizationTab'
import { ProgressTab } from './ProgressGlobalTab'
import { StatisticsTab } from './StatisticsTab'

const emptyArray: [] = []
export const OptimizationsSummary = ({
	handleClose,
	filters: queryFilters,
}: {
	handleClose: () => void
	filters: FilterRuleEntity[]
}) => {
	const [shouldUseQueryFilters, setShouldUseQueryFilters] = useState(false)
	const [selectedOptimizationGroups, setSelectedOptimizationGroups] = useState<
		OptimizationGroupsType[] | null
	>(null)
	const [activeTab, setActiveTab] = useState('statistics')
	const filters = useMemo(
		() => (shouldUseQueryFilters ? queryFilters : emptyArray),
		[shouldUseQueryFilters, queryFilters]
	)

	/**Queries */
	const { data: optimizationGroupsByFilters, isLoading } =
		useOptimizationGroupsByProductFiltersQuery({ filters })

	/**Calculated */
	useEffect(() => {
		const optimizationGroupsByProductFilters =
			optimizationGroupsByFilters?.filter(
				({ status }: OptimizationGroupsType) =>
					status === FINISHED || status === LOCKED
			)
		return setSelectedOptimizationGroups(optimizationGroupsByProductFilters!)
	}, [optimizationGroupsByFilters])
	const { finishedOgsSummary, queryParams } = useMemo(
		() => ({
			finishedOgsSummary: optimizationGroupsByFilters && {
				total: optimizationGroupsByFilters?.length,
				totolFinished: selectedOptimizationGroups?.length || 0,
				totalLocked:
					selectedOptimizationGroups?.filter(({ status }) => status === LOCKED)
						.length || 0,
			},
			queryParams: selectedOptimizationGroups
				? {
						optimizations: buildOptimizationsQueryObject(
							selectedOptimizationGroups
						),
						filters: filters?.filter(
							({ name }) => name !== 'optimization_group_id'
						),
					}
				: null,
		}),
		[selectedOptimizationGroups]
	)
	/**Handlers */
	const handleApply = (selectedOgs: SelectedOptimizationGroupType[]) => {
		const optimizationGroups: OptimizationGroupsType[] =
			selectedOgs.map(
				({ id }) =>
					optimizationGroupsByFilters?.find(
						(data) => data.id === id
					) as OptimizationGroupsType
			) || []
		return setSelectedOptimizationGroups(optimizationGroups)
	}

	return (
		<Layout className='cmp-border h-full'>
			<Header className='cmp-border-bottom flex flex-col space-y-3 px-5 py-3'>
				<div className='flex justify-between'>
					<h2>{intl.get('opt.summary')}</h2>
					<Button
						variant='tertiary'
						iconBefore={<CrossIcon />}
						onClick={handleClose}
					/>
				</div>
				<MetricsSummaryWidget isLoading={isLoading} queryParams={queryParams} />
				{isLoading && (
					<div className='flex space-x-2'>
						<div className='h-6 w-20 animate-pulse rounded bg-accent-4' />
						<div className='h-6 w-20 animate-pulse rounded bg-accent-4' />
					</div>
				)}
				{finishedOgsSummary && !isLoading && (
					<div className='space-x-2'>
						<Tag variant={'success'}>
							{intl.get('og.status.finished.badge').d('Finished OGs')}:{' '}
							{formatNumber(
								(finishedOgsSummary.totolFinished / finishedOgsSummary.total) *
									100 || 0,
								NumberFormats.Percent
							) || 0}
						</Tag>
						<Tag variant='info'>
							{intl.get('og.status.applied.badge').d('Applied OGs')}:{' '}
							{formatNumber(
								(finishedOgsSummary.totalLocked / finishedOgsSummary.total) *
									100 || 0,
								NumberFormats.Percent
							) || 0}
						</Tag>
					</div>
				)}
				{isLoading ? (
					<div className='flex justify-between'>
						<div className='h-8 w-32 animate-pulse rounded bg-accent-4' />
						<div className='h-8 w-32 animate-pulse rounded bg-accent-4' />
					</div>
				) : (
					<div className='flex items-center justify-between'>
						<div className='flex items-center'>
							<Switch
								checked={shouldUseQueryFilters}
								onChange={() => {
									analytic.logEvent(
										'opt summary glob: use table filters switch',
										{
											value: !shouldUseQueryFilters,
										}
									)
									setShouldUseQueryFilters((prev) => !prev)
								}}
							/>
							<Label className='mx-2 flex items-center'>
								{intl
									.get('opt.summary.use_table_filters')
									.d('Use table filter')}
							</Label>
						</div>
						<OptimizationGroupsDropdown
							optimizationGroups={
								getFinishedOpimizationGroups(
									optimizationGroupsByFilters || []
								) || []
							}
							isDisabled={isLoading || shouldUseQueryFilters}
							selectedOgs={selectedOptimizationGroups}
							handleClear={() => setSelectedOptimizationGroups([])}
							handleApply={handleApply}
						/>
					</div>
				)}
			</Header>
			<Tabs fit className='bg-accent-1'>
				<TabItem
					active={'statistics' === activeTab}
					onClick={() => setActiveTab('statistics')}
				>
					{intl.get('app.statistics').d('Statistics')}
				</TabItem>
				<TabItem
					active={'optimization' === activeTab}
					onClick={() => setActiveTab('optimization')}
				>
					{intl.get('app.optimization').d('Optimization')}
				</TabItem>
				<TabItem
					active={'progress' === activeTab}
					onClick={() => setActiveTab('progress')}
				>
					{intl.get('app.progress').d('Progress')}
				</TabItem>
			</Tabs>
			<Content className='relative overflow-y-auto'>
				{isLoading ? (
					<Layout className='flex h-full items-center justify-center overflow-y-hidden'>
						<Loader />
					</Layout>
				) : (
					<AnimatePresence mode={'wait'}>
						{'statistics' === activeTab && (
							<TabContent key='statistics'>
								<StatisticsTab
									isLoading={isLoading}
									queryParams={queryParams}
								/>
							</TabContent>
						)}
						{'optimization' === activeTab && (
							<TabContent key='optimization'>
								<OptimizationTab
									queryParams={queryParams}
									isLoading={isLoading}
								/>
							</TabContent>
						)}
						{'progress' === activeTab && (
							<TabContent key='progress'>
								<ProgressTab
									isLoading={isLoading}
									productFilters={filters}
									optimizationGroups={getSelectedOGs(
										selectedOptimizationGroups,
										optimizationGroupsByFilters || []
									)}
								/>
							</TabContent>
						)}
					</AnimatePresence>
				)}
			</Content>
		</Layout>
	)
}

const getSelectedOGs = (
	selectedOptimizationGroups: SelectedOptimizationGroupType[] | null,
	optimizationsGroups: OptimizationGroupsType[]
) => {
	const ogsIds = map(prop('id'), selectedOptimizationGroups)
	return optimizationsGroups?.filter(({ id }) => ogsIds.includes(id))
}

export function OptimizationsSummaryTrigger({
	disabled,
}: {
	disabled?: boolean
}) {
	const drawer = useDrawer('OPTIMIZATIONS_SUMMARY')
	useEffect(() => {
		return () => {
			drawer.close()
		}
	}, [])
	return (
		<Button
			variant='primary-brand'
			disabled={disabled}
			iconBefore={<AnalyticsGrowIcon />}
			onClick={() => drawer.open({})}
		/>
	)
}

export function OptimizationsSummaryDrawer({
	isOpen,
	close,
}: {
	isOpen: boolean
	close: () => void
}) {
	const location = useLocation()
	const locationQuery = useMemo(
		() => qs.parse(location.search, { ignoreQueryPrefix: true }),
		[location.search]
	)
	const { filters = [] } = useMemo(
		(): {
			filters: FilterRuleEntity[]
		} =>
			decodeComplexQuery(locationQuery?.['qf'] as string) || {
				filters: [],
			},
		[location.search]
	)
	return (
		<Drawer
			isOpen={isOpen}
			orient='right'
			disableBlanket
			onCloseComplete={close}
			disableFocusTrap
			shouldUnmountOnExit={false}
		>
			<ErrorBoundary>
				<OptimizationsSummary handleClose={close} filters={filters} />
			</ErrorBoundary>
		</Drawer>
	)
}
const buildOptimizationsQueryObject = (
	optimizationGroups: OptimizationGroupsType[]
) => {
	return optimizationGroups?.map(({ id, optimization }) => ({
		optimization_id: optimization,
		optimization_group_id: id,
	}))
}
export const TabContent = ({
	children,
	className,
}: {
	children: React.ReactNode
	className?: string
}) => {
	return (
		<motion.div
			initial={{ opacity: 0, x: 100 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: -30 }}
			className={clsx(
				'flex size-full flex-col divide-y divide-solid divide-base',
				className
			)}
			transition={{
				duration: 0.2,
			}}
		>
			{children}
		</motion.div>
	)
}
const getFinishedOpimizationGroups = (
	optimizationGroups: OptimizationGroupsType[]
) =>
	optimizationGroups?.filter(
		({ status }) => status === FINISHED || status === LOCKED
	) || []
