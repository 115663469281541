import { format } from 'date-fns'
import { SeriesAreaOptions } from 'highcharts'

import Chart from '@/components/Chart'
import { DateFormat } from '@/tools/dates'
import { formatNumber } from '@/tools/locale'

export default function BusinessMetricsChart({
	series,
}: {
	series: SeriesAreaOptions[]
}) {
	return (
		<Chart
			config={getChartConfig({
				series,
			})}
		/>
	)
}

export function formatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	const head = `<div class="w-full flex justify-between mb-2.5"><small>${format(
		new Date(this.x as number),
		DateFormat.default
	)}</small></div>`
	const body = self.points
		?.map((point: Highcharts.Point) => {
			return `<div class="w-full flex justify-between mb-2.5">
				<div class="flex items-center">
				<div class="color-dot rounded-lg mr-4" style="background: ${point.color};"></div>
				<strong class="truncate">${point.series.name}</strong>
				</div>
				<strong class="flex items-center">${formatNumber(point.y as number)}</strong>
				</div>`
		})
		.join('')
	return `<div class="chart-tooltip w-72">
		${head}
		${body}
	</div>`
}
const getChartConfig = ({
	series,
}: {
	series: SeriesAreaOptions[]
}): Highcharts.Options => {
	const yAxis = series.map((serie) => ({
		visible: serie.yAxis === 'main', // show only main axis
		id: String(serie.yAxis),
		title: {
			text: '',
		},
	}))
	return {
		chart: {
			type: 'area',
		},
		//allowNegativeLog: true,
		tooltip: {
			animation: true,
			outside: true,
			shared: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter,
		},
		plotOptions: {
			series: {
				marker: {
					lineWidth: 0,
					lineColor: undefined,
					symbol: 'circle',
				},
				//dashStyle: series.dashStyle,
			},
		},
		yAxis,
		xAxis: {
			type: 'datetime',
			labels: {
				padding: 0,
				formatter: function () {
					return format(new Date(this.value), DateFormat.short)
				},
			},
			endOnTick: true,
			tickmarkPlacement: 'on',
			startOnTick: false,
			minPadding: 0,
			maxPadding: 0,
			offset: 0,
		},
		series,
	}
}
