import { Button } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import Popover from '@cmpkit/popover'
import { FilterRuleEntity } from '@cmpkit/query-builder'

import intl from '@/locale'

import { OptimizationsActions } from './OptimizationsActions'

type OptimizationActionsDropdownProps = {
	rules: FilterRuleEntity[]
	onApply: (rules: FilterRuleEntity[]) => void
	handleExport: () => void
}

export const OptimizationActionsDropdown = ({
	rules,
	onApply,
	handleExport,
}: OptimizationActionsDropdownProps) => {
	const popover = useDisclosure()

	return (
		<Popover
			onDismiss={popover.close}
			isOpen={popover.isOpen}
			content={() => (
				<OptimizationsActions
					close={popover.close}
					onApply={onApply}
					rules={rules}
					handleExport={handleExport}
				/>
			)}
			placement='bottom-start'
		>
			<Button
				variant='tertiary'
				onClick={popover.toggle}
				iconAfter={<CaretDownIcon />}
			>
				{intl
					.get('optimization_actions_button.title')
					.d('Optimization actions')}
			</Button>
		</Popover>
	)
}
