import React from 'react'
import { Link } from 'react-router-dom'

import { Badge, Spinner } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'

import { encodeComplexQuery } from '@/components/data-grid/helpers'
import intl from '@/locale'
import { useUnassignedProductsCountQuery } from '@/modules/global-assortment/queries'
import { formatNumber } from '@/tools/locale'
import { toQueryString } from '@/tools/location'

export function UassigneProductsBage() {
	const { isLoading, data, isRefetching } = useUnassignedProductsCountQuery()
	return (
		!!data && (
			<div className='flex items-center'>
				<Badge
					variant='warning'
					className={'text-nowrap no-underline'}
					interactive
					asChild
				>
					<Link
						to={`/p/assortment/products${toQueryString({
							qf: encodeComplexQuery({
								filters: [
									{
										name: 'pricing_campaign_id',
										operation: Operators.IS_EMPTY,
										value: 1,
									},
								],
							}),
						})}`}
					>
						<div className='flex items-center space-x-2'>
							{isLoading || isRefetching ? (
								<Spinner />
							) : (
								<strong className='fade-in'>{formatNumber(data!)}</strong>
							)}
							<span>{intl.get('unassigned_item_not_to_repricing')} </span>
						</div>
					</Link>
				</Badge>
			</div>
		)
	)
}
export default UassigneProductsBage
