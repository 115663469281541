import React from 'react'

export default function ContactLink() {
	return (
		<a
			href='mailto:po-support@competera.net'
			className='text-muted no-underline'
		>
			Any problems? Contact us!
		</a>
	)
}
