import {
	AxiosError,
	AxiosInstance,
	AxiosRequestHeaders,
	InternalAxiosRequestConfig,
	ParamsSerializerOptions,
} from 'axios'
import * as qs from 'qs'

import { TokenStorage } from '@/modules/Auth/token-storage'
import { getLoginUrl } from '@/modules/Auth/utils'

import { Api } from '../generated/index'

const retryWrapper = (
	instance: AxiosInstance,
	options?: { delay: number; retryStatusCodes: number[]; maxRetry: number }
) => {
	const maxRetry = options?.maxRetry ?? 20
	const delay = options?.delay ?? 2000
	const retryStatusCodes = options?.retryStatusCodes ?? [502]
	let counter = 0
	return (error: AxiosError) => {
		const config = error?.config
		if (
			counter < maxRetry &&
			retryStatusCodes.includes(error?.response?.status as number)
		) {
			counter++
			return new Promise((resolve) => {
				setTimeout(() => resolve(instance(config!)), delay)
			})
		}
		return Promise.reject(error)
	}
}
const tokenErrorIterceptor = (instance: AxiosInstance) => {
	let newTokenOnTheWay = false
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let unautherizationRequests: any[] = []
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const addToUnautherizationRequests = (callback: any) =>
		unautherizationRequests.push(callback)
	const onTokenRefreshed = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		unautherizationRequests = unautherizationRequests.filter((callback: any) =>
			callback()
		)
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return async (err: any) => {
		const { response, config } = err
		if (
			config.url !== '/auth/login/' &&
			config.url !== '/auth/auth/' &&
			config.url !== '/auth/refresh-token/' &&
			response &&
			response.status === 401 &&
			!config._retry
		) {
			// Access Token was expired
			config._retry = true
			if (!newTokenOnTheWay) {
				newTokenOnTheWay = true
				try {
					await TokenStorage.getNewToken()
					onTokenRefreshed()
					newTokenOnTheWay = false
					config.headers = {
						...config.headers,
						...TokenStorage.getAuthentication(),
					}
					return instance(config)
				} catch (error) {
					newTokenOnTheWay = false
					TokenStorage.clear()
					window.location.href = getLoginUrl()
					return Promise.reject(err)
				}
			} else {
				return new Promise((resolve) => {
					addToUnautherizationRequests(() => {
						config.headers = TokenStorage.getAuthentication()
						resolve(instance(config))
					})
				})
			}
		}
		return Promise.reject(err)
	}
}
const authenticationIterceptor = (config: InternalAxiosRequestConfig) => {
	if (TokenStorage.isAuthenticated() && !config.headers?.['Authorization']) {
		config.headers = {
			...config.headers,
			...TokenStorage.getAuthentication(),
		} as AxiosRequestHeaders
	}
	return config
}
const paramsSerializer: ParamsSerializerOptions = {
	serialize: (params: object) => qs.stringify(params, { arrayFormat: 'comma' }),
	indexes: false,
}

const createApiClient = () => {
	const client = new Api({
		baseURL: '/',
		withCredentials: true,
		paramsSerializer,
	})

	/**
	 * Add Authentication headers to all requests
	 */
	client.instance?.interceptors.request.use(authenticationIterceptor)

	/**
	 * Handle 401 errors and refresh token if needed and retry
	 * request with new token or redirect to login page	if token refresh failed
	 */
	client.instance?.interceptors.response.use(
		null,
		tokenErrorIterceptor(client.instance)
	)

	/**
	 * Retry request if response status code is 502 (Bad Gateway)
	 */
	client.instance?.interceptors.response.use(
		null,
		retryWrapper(client.instance)
	)

	return client
}
export const client = createApiClient()
