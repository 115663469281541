import { map, pipe, prop } from 'lodash/fp'
import { useEffect, useMemo, useState } from 'react'

import { ColumnOptionsType } from '@cmpkit/data-table/dist/types'

import { ColumnSchemaModel } from '@/generated'
import intl from '@/locale'
import { mergedConfiguration } from '@/tools/columns-schema-utils'

export type ColumnDefinition = {
	schema: ColumnSchemaModel
	column: ColumnOptionsType
}

/**
 * Transform remote schema to column options
 * @param columnSchema
 * @returns
 */
export function toColumnDefinition(
	columnSchema: ColumnSchemaModel
): ColumnOptionsType {
	return {
		id: columnSchema.name,
		title: columnSchema.translate_key
			? intl.get(columnSchema.translate_key).d(columnSchema.visible_name)
			: columnSchema.visible_name,
		tooltip: !!columnSchema.tooltip
			? intl.get(columnSchema.tooltip).d(columnSchema.tooltip)
			: undefined,
		valueGetter: prop(columnSchema.name),
		locked: false,
		fixable: true,
		sortable: true,
		width: 150,
	}
}

/**
 * Build column options to create new Column in future
 *
 * @param schema
 * @param extensions
 * @returns
 */
export function useColumnsDefinitions(
	schema: ColumnSchemaModel[],
	extensions: ((opt: ColumnDefinition) => ColumnDefinition)[] = []
): ColumnDefinition[] {
	return useMemo((): ColumnDefinition[] => {
		const options: ColumnDefinition[] = map(
			(schema) => ({
				schema,
				column: toColumnDefinition(schema),
			}),
			schema
		)
		if (extensions.length > 0) {
			return options.map(pipe(extensions))
		} else {
			return options
		}
	}, [schema, extensions])
}

export type StoredColumnConfig = {
	key: string
	enabled?: boolean
	locked?: boolean
	width?: number
	category?: string
}

/**
 * Transform remote schema to config object
 * @param columnSchema remote schema object
 * @returns config object
 */
export function toStoredColumnConfig(
	columnOptionConfig: ColumnDefinition
): StoredColumnConfig {
	return {
		key: columnOptionConfig?.schema.name,
		enabled: !columnOptionConfig.schema.hidden,
		locked: columnOptionConfig.column.locked ?? false,
		width: columnOptionConfig.column.width as number,
	}
}

export interface ColumnsConfigController {
	rowHeight: number
	columnsWidth: Record<string, number>
	columnsConfig: StoredColumnConfig[]
	setRowHeight(rowHeight: number): void
	setColumnsWidth(widths: Record<string, number>): void
	setColumnsConfig(config: StoredColumnConfig[]): void
	restore(): void
}
/**
 * Columns config controller allows to manage table configuration
 * @param schema
 * @returns ColumnsConfigController
 */
export function useColumnsConfig(
	columnOptions: ColumnDefinition[],
	extra?: string
): ColumnsConfigController {
	const initialColumnsConfig = (
		extra
			? [
					{
						schema: { name: extra },
						column: { id: extra, locked: true },
					} as ColumnDefinition,
					...columnOptions,
				]
			: columnOptions
	).map(toStoredColumnConfig)

	const [rowHeight, setRowHeight] = useState(34)
	const [columnsWidth, setColumnsWidth] = useState({})
	const [columnsConfig, setColumnsConfig] = useState(initialColumnsConfig || [])
	useEffect(() => {
		setColumnsConfig(initialColumnsConfig)
	}, [columnOptions])
	const restore = () => {
		setColumnsConfig(columnOptions.map(toStoredColumnConfig))
		setColumnsWidth({})
		setRowHeight(34)
	}
	return {
		rowHeight,
		columnsWidth,
		columnsConfig,
		setRowHeight,
		setColumnsWidth,
		setColumnsConfig,
		restore,
	}
}

interface TableConfig {
	rowHeight: number
	columnsWidth: Record<string, number>
	columnsConfig: StoredColumnConfig[]
	setRowHeight(rowHeight: number): void
	setColumnsWidth(widths: Record<string, number>): void
	setColumnsConfig(config: StoredColumnConfig[]): void
}
/**
 * Columns configuration manager hook for control local columns state such as width/order/visability
 * @param configController
 * @param columnsDefinitions
 * @returns ColumnsConfigController
 */
export const useColumnManagerControllerAdapter = (
	configController: TableConfig,
	columnsDefinitions: ColumnDefinition[]
): ColumnsConfigController => {
	const { rowHeight, setRowHeight, setColumnsWidth, setColumnsConfig } =
		configController
	const restore = () => {
		setColumnsConfig([])
		setColumnsWidth({})
		setRowHeight(34)
	}
	const { columnsWidth, columnsConfig } = configController
	return {
		rowHeight,
		columnsWidth,
		columnsConfig: mergedConfiguration(columnsDefinitions, columnsConfig),
		setRowHeight,
		setColumnsWidth,
		setColumnsConfig,
		restore,
	}
}
