import React from 'react'

import Container from '../Container'

type CompetitorsTableRow = {
	competitor: string
	price: number | string | null
	url?: string
}
type CompetitorsTableProps = {
	rows: CompetitorsTableRow[]
}
export default function CompetitorsTable({ rows }: CompetitorsTableProps) {
	return (
		<Container>
			<table className='table-bordered table-sm table'>
				<thead>
					<tr>
						<th scope='col' className='font-bold'>
							Competitor
						</th>
						<th scope='col' className='font-bold'>
							Price
						</th>
					</tr>
				</thead>
				<tbody>
					{rows.map(({ competitor, price, url }, i) => (
						<tr key={i}>
							<th>
								{url ? (
									<a href={url} target='_blank'>
										{competitor}
									</a>
								) : (
									competitor
								)}
							</th>
							<td>{price}</td>
						</tr>
					))}
				</tbody>
			</table>
		</Container>
	)
}
