import { prop } from 'lodash/fp'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Badge, Spinner } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'

import { encodeComplexQuery } from '@/components/data-grid/helpers'
import { ProductsQueryRequestModel } from '@/generated'
import intl from '@/locale'
import { getAssortmentShare } from '@/modules/pricing-campaigns/helpers'
import { formatNumber } from '@/tools/locale'
import { toQueryString } from '@/tools/location'

import {
	useAssortmentListQuery,
	useUnassignedProductsCountQuery,
} from '../queries'

export default function GlobalAssortmentUnassignedItems() {
	const totalQuery = useAssortmentListQuery<number>(defaultBody, queryOptions)
	const unassignedQuery = useUnassignedProductsCountQuery()

	const isLoading = unassignedQuery.isLoading || totalQuery.isLoading
	const count = unassignedQuery.data || 0
	const share = getAssortmentShare(totalQuery.data ?? 0, count)
	const variant = share == 100 ? 'danger' : share > 5 ? 'warning' : 'success'
	const link = useMemo(
		() =>
			`../products${toQueryString({
				qf: encodeComplexQuery({
					filters: [
						{
							name: 'pricing_campaign_id',
							operation: Operators.IS_EMPTY,
							value: 1,
						},
					],
				}),
			})}`,
		[]
	)
	return (
		<Badge
			variant={variant}
			className='fade-in gap-1 no-underline'
			interactive
			asChild
		>
			<Link to={link}>
				{isLoading ? (
					<Spinner size='small' />
				) : (
					`${formatNumber(count)} (${share}%)`
				)}
				<span>{intl.get('general_new_items')}</span>
			</Link>
		</Badge>
	)
}

const defaultBody: ProductsQueryRequestModel = {
	limit: 1,
	offset: 0,
	order_by: [],
	filters: [],
}
const queryOptions = {
	select: prop('total_count'),
}
