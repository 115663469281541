import React, { useMemo } from 'react'

import { Button } from '@cmpkit/base'
import StarIcon from '@cmpkit/icon/lib/glyph/star'
import StarFilledIcon from '@cmpkit/icon/lib/glyph/star-filled'
import Tooltip from '@cmpkit/tooltip'

import { FilterRuleModel } from '@/generated'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'
import {
	useGlobalFiltersQuery,
	usePersonalFiltersQuery,
} from '@/modules/preferences/queries'
import { cyrb53 } from '@/tools/utils'

export default function SaveReportButton({
	filters,
	disabled,
}: {
	filters: FilterRuleModel[]
	disabled?: boolean
}) {
	const { showModal } = useModalStore()
	const globalFiltersQuery = useGlobalFiltersQuery()
	const personalFiltersQuery = usePersonalFiltersQuery()
	const exised = useMemo(() => {
		const curr = cyrb53(JSON.stringify(filters))
		return [
			...(globalFiltersQuery.data || []),
			...(personalFiltersQuery.data || []),
		].find((item) => cyrb53(JSON.stringify(item.params.query)) === curr)
	}, [globalFiltersQuery.data, personalFiltersQuery.data, filters])
	return (
		<Tooltip
			content={
				disabled
					? intl
							.get('app.save_report.disabled')
							.d('Please provide some filters to save report')
					: intl.get('app.save_report')
			}
		>
			<Button
				disabled={disabled}
				variant='tertiary'
				iconBefore={
					exised ? <StarFilledIcon className='text-warning' /> : <StarIcon />
				}
				onClick={() =>
					exised
						? showModal('UPDTAE_ASSORTMENT_REPORT', { report: exised })
						: showModal('CREATE_ASSORTMENT_REPORT', {
								filters,
							})
				}
			/>
		</Tooltip>
	)
}
