import { CircleDollarSignIcon } from 'lucide-react'
import React, { useCallback, useContext, useState } from 'react'
import { useLocation } from 'react-router'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbList,
	BreadcrumbPage,
	Button,
	Content,
	Header,
	Separator,
} from '@cmpkit/base'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import UploadIcon from '@cmpkit/icon/lib/glyph/upload'
import Tooltip from '@cmpkit/tooltip'

import CompactSearch from '@/components/CompactSearch'
import DocumentTitle from '@/components/DocumentTitle'
import ParentSize from '@/components/ParentSize'
import NoData from '@/components/placeholders/NoData'
import { BoundaryWithUser } from '@/components/withErrorBoundary'
import intl from '@/locale'
import { Authorization } from '@/modules/Auth/authorization'
import {
	AssortmentPricingAlertsButton,
	OptimizationScheduleWidget,
} from '@/modules/core'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'
import { getLocationQuery } from '@/tools/location'

import { SkeletonLoad } from '../loaders'
import OptimizationGroupsFilters from './OptimizationGroupsFilters'
import {
	OptimizationGroupsContext,
	OptimizationGroupsProvider,
} from './OptimizationGroupsProvider'
import OptimizationGroupsStatusesManager, {
	Preloader,
} from './OptimizationGroupsStatusesManager'
import OptimizationGroupsTable from './OptimizationGroupsTable'
import { OptimizationGroupsTreeChart } from './OptimizationGroupsTreeChart'
import UassigneProductsBage from './UassignedProductsBage'
import ViewSwitcher from './ViewSwitcher'

function OptimizationGroupsContent() {
	const location = useLocation()

	const {
		getSelectedItems,
		data,
		dataList,
		isLoading,
		searchText,
		setSearchText,
		selected,
		setSelected,
	} = useContext(OptimizationGroupsContext)
	const { showModal } = useModalStore()
	const query = getLocationQuery(location.search)
	const [view, setView] = useState<'table' | 'tree'>('table')

	/**
	 * Actions
	 */
	const resetSelection = () => setSelected([])
	const handleSearchChange = useCallback((searchText: string) => {
		analytic.logEvent('mass repricing: search')
		setSearchText(searchText)
	}, [])

	const handleOpenBulkImport = () => {
		analytic.logEvent('og list: import from file: click')
		const items = selected.length > 0 ? getSelectedItems() : dataList
		showModal('OPTIMIZATION_GROUPS_BULK_IMPORT', {
			items,
			optimizationGroupsCount: dataList.length,
			onResetSelection: resetSelection,
		})
	}

	return (
		<>
			<Header className='flex-col border-b bg-accent-2 px-4 py-2'>
				{data.length === 0 ? (
					<Preloader />
				) : (
					<div className='fade-in flex items-center justify-between'>
						<div className='relative flex items-center'>
							<OptimizationGroupsStatusesManager />
							<Separator orientation='vertical' className='mx-4 h-5' />
							<OptimizationScheduleWidget
								isActive={Boolean(query['schedule.id__is_not_empty'])}
							/>
						</div>
					</div>
				)}
			</Header>
			<Header className='w-full px-4 py-2'>
				<div className='flex w-full items-center justify-between'>
					<div className='flex items-center space-x-2'>
						<CompactSearch
							value={searchText}
							onChange={(event) => handleSearchChange(event.target.value)}
							placeholder={intl.get('general_search')}
							onClear={() => handleSearchChange('')}
						/>
						<OptimizationGroupsFilters />
					</div>
					<div className='flex items-center space-x-2'>
						<UassigneProductsBage />
						<div className='flex items-center space-x-2'>
							<Tooltip content={intl.get('optimization_groups_bulk_tooltip')}>
								<Button
									data-testid='bulk-og-import-button'
									iconBefore={<UploadIcon />}
									variant='secondary'
									onClick={handleOpenBulkImport}
								>
									{`${intl.get('app.update_data').d('Update data')}` +
										(!!selected?.length ? ` (${selected.length})` : '')}
								</Button>
							</Tooltip>
							<Authorization permissions={['MANAGE_OPTIMIZATION_GROUP']}>
								<Tooltip
									content={intl
										.get('og.create.tooltip')
										.d('Create optimization group')}
								>
									<Button
										data-testid='create-og-trigger'
										iconBefore={<PlusIcon />}
										variant='primary-brand'
										onClick={() => {
											showModal('CREATE_OPTIMIZATION_GROUP')
											analytic.logEvent('og list: create og: click')
										}}
									/>
								</Tooltip>
							</Authorization>
						</div>
						<Separator orientation='vertical' className='h-5' />
						<AssortmentPricingAlertsButton />
						<ViewSwitcher
							value={view}
							onChange={(v) => setView(v as 'table' | 'tree')}
							analyticEventName={'og: view'}
						/>
					</div>
				</div>
			</Header>
			<Content className='overflow-hidden px-4 pb-4'>
				{view === 'table' && (
					<div className='flex h-full flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
						{isLoading ? (
							<SkeletonLoad />
						) : dataList.length > 0 ? (
							<OptimizationGroupsTable data={dataList} />
						) : (
							<NoData />
						)}
					</div>
				)}
				{view === 'tree' && (
					<div className='relative size-full'>
						{isLoading ? (
							<SkeletonLoad />
						) : dataList.length > 0 ? (
							<ParentSize>
								{({ height }) => (
									<OptimizationGroupsTreeChart
										height={height - 30}
										data={dataList}
									/>
								)}
							</ParentSize>
						) : (
							<NoData />
						)}
					</div>
				)}
			</Content>
		</>
	)
}

function OptimizationGroupsPage() {
	return (
		<BoundaryWithUser config={{ showDialog: true }}>
			<OptimizationGroupsProvider>
				<DocumentTitle title={intl.get('optimization_groups_title_page')}>
					<Header className='h-11 items-center border-b px-4 py-2'>
						<CircleDollarSignIcon className='size-4' />
						<Separator orientation='vertical' className='mx-2 h-5' />
						<Breadcrumb className='text-xs'>
							<BreadcrumbList>
								<BreadcrumbItem>
									<BreadcrumbPage>
										{intl.get('menu.og').d('Optimization groups')}
									</BreadcrumbPage>
								</BreadcrumbItem>
							</BreadcrumbList>
						</Breadcrumb>
					</Header>
					<OptimizationGroupsContent />
				</DocumentTitle>
			</OptimizationGroupsProvider>
		</BoundaryWithUser>
	)
}

export default OptimizationGroupsPage
