import { entries, map, pipe, prop } from 'lodash/fp'

import { InlineMessage } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import { FilterRuleModel, MetricModel, MetricsDataUnit } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'

import {
	useInterpretabilityStatisticQuery,
	useOptimizationQuery,
	useRepricingStatisticQuery,
} from '../../queries'
import {
	getMetricDiffByType,
	getTypesByMetricSchema,
	METRICS_WIDGET_TYPES,
	MetricsWidgetType,
	selectAdditionalMetrics,
	selectCustomMetrics,
	selectTargetMetrics,
} from './helpers'
import { FormattedMetric, MetricRow } from './MetricRow'

export default function OptimizationMetricsWidget({
	filters,
	widgetType = METRICS_WIDGET_TYPES.TARGET,
	metricsSchema,
}: {
	filters: FilterRuleModel[]
	widgetType: MetricsWidgetType
	metricsSchema: MetricModel[]
}) {
	const optimizationGroupId = useOptimizationGroupId()!
	const optimizationQuery = useOptimizationQuery(optimizationGroupId)

	//** Queries */
	const repricingStatisticQuery = useRepricingStatisticQuery(
		{
			optimizations: [
				{
					optimization_id: optimizationQuery.data!.id,
					optimization_group_id: optimizationGroupId,
				},
			],
			filters: [],
		},
		{
			enabled: !!optimizationGroupId && !!optimizationQuery.data?.id,
			select: prop('settings.strategy'),
		}
	)
	const interpretabilityStatistic = useInterpretabilityStatisticQuery(
		{
			optimizations: [
				{
					optimization_group_id: optimizationGroupId,
					optimization_id: optimizationQuery.data!.id,
				},
			],
			filters,
		},
		{
			enabled:
				!!optimizationQuery.data?.id &&
				!!repricingStatisticQuery.data &&
				!!metricsSchema,
			select: pipe([
				getMetricsByWidgetType(widgetType, repricingStatisticQuery.data),
				entries,
				map((data: [string, MetricsDataUnit]) =>
					formatMetric(data, metricsSchema, widgetType)
				),
			]),
		}
	)
	if (interpretabilityStatistic.isLoading) {
		return <LoadingPlaceholder />
	}
	if (repricingStatisticQuery.isError || interpretabilityStatistic.isError) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger' className='w-full'>
				{interpretabilityStatistic.error?.message ||
					repricingStatisticQuery.error?.message ||
					intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}
	return (
		<div>
			{interpretabilityStatistic.data?.map((item: FormattedMetric) => {
				return <MetricRow key={item.key} item={item} />
			})}
		</div>
	)
}

function LoadingPlaceholder() {
	return (
		<div className='flex flex-col items-center space-y-2'>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-20 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-10 animate-pulse rounded-lg bg-accent-4' />
			</div>
			<div className='flex w-full items-center justify-between'>
				<div className='h-5 w-24 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-5 w-12 animate-pulse rounded-lg bg-accent-4' />
			</div>
		</div>
	)
}

const formatMetric = (
	[key, values]: [string, MetricsDataUnit],
	metricsSchema: MetricModel[],
	widgetType: string
): FormattedMetric => {
	const metricSchema = metricsSchema?.find(
		({ name }) => name.split('.')[0] === `${key}`
	)
	const { dataType, diffType } = getTypesByMetricSchema(metricSchema!)

	const data = {
		type: dataType,
		values,
	}
	const diff = {
		type: diffType,
		value: getMetricDiffByType(values, diffType),
	}
	return {
		widgetType,
		key,
		data,
		diff,
		values,
		description: metricSchema?.description || '',
	}
}

const getMetricsByWidgetType = (
	widgetType: string,
	strategy: { target: string; protect: string }
) => {
	switch (widgetType) {
		case METRICS_WIDGET_TYPES.TARGET:
			return selectTargetMetrics(strategy)
		case METRICS_WIDGET_TYPES.ADDITIONAL:
			return selectAdditionalMetrics(strategy)
		default:
			return selectCustomMetrics()
	}
}
