/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * Creates a timer that accepts a `timerCalc` function to perform
 * calculated timeout retries, such as exponential backoff.
 *
 * @example
 * let reconnectTimer = new Timer(() => this.connect(), function(tries){
 *   return [1000, 5000, 10000][tries - 1] || 10000
 * })
 * reconnectTimer.scheduleTimeout() // fires after 1000
 * reconnectTimer.scheduleTimeout() // fires after 5000
 * reconnectTimer.reset()
 * reconnectTimer.scheduleTimeout() // fires after 1000
 *
 * @param {Function} callback
 * @param {Function} timerCalc
 */
export default class Timer {
	callback: any
	timerCalc: any
	timer?: any
	tries: number
	constructor(callback: () => void, timerCalc: any) {
		this.callback = callback
		this.timerCalc = timerCalc
		this.timer = null
		this.tries = 0
	}

	reset() {
		this.tries = 0
		clearTimeout(this.timer)
	}

	/**
	 * Cancels any previous scheduleTimeout and schedules callback
	 */
	scheduleTimeout() {
		clearTimeout(this.timer)

		this.timer = setTimeout(
			() => {
				this.tries = this.tries + 1
				this.callback()
			},
			this.timerCalc(this.tries + 1)
		)
	}
}
