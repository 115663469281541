import { any, flatten, orderBy, prop } from 'lodash/fp'
import React, { ReactNode, useMemo } from 'react'

import { Spinner } from '@cmpkit/base'
import { GroupBase, MultiValue } from '@cmpkit/select'

import { MenuList } from '@/components/SelectComponents'
import intl from '@/locale'

import { ClearOption, Text } from '../../components/common'
import { DialogInner } from '../../components/Popup'
import PopupFooter from '../../components/PopupFooter'
import { BaseSelect, selectComponents } from '../../components/Select'
import { FieldViewProps, OptionType, ValueState } from '../../types'
import SelectController from './Controller'

type SelectViewProps = FieldViewProps<SelectController> & {
	components: Record<string, ReactNode>
	storedValue: ValueState<string[]>
	localValue: ValueState<string[]>
}
export default function SelectView({
	field,
	isDirty,
	isRemovable,
	localValue,
	onChange,
	onRemove,
	onReset,
	onApply,
	closePopup,
	...props
}: SelectViewProps) {
	const { operation } = localValue
	const { useSelectOptions } = field
	const selectOptions = useSelectOptions()
	const components = useMemo(
		() => ({
			...defaultComponents,
			...props.components,
			MenuList,
			...(field?.components || {}),
		}),
		[props.components]
	)

	const options = useMemo(() => {
		const list = selectOptions.data || []
		if (isGroupedOptions(list)) {
			return list
		} else {
			return getOptions(list)
		}
	}, [selectOptions.data])
	const flatOptions = useMemo(
		() =>
			flatten(
				options?.map((v) => {
					if ((v as GroupBase<OptionType>).options) {
						return (v as GroupBase<OptionType>).options
					} else {
						return [v] as OptionType[]
					}
				})
			) as OptionType[],
		[options]
	)

	const value = useMemo(() => {
		return (
			flatOptions?.filter((option) =>
				localValue?.value?.includes(option.value as string)
			) || []
		)
	}, [localValue, selectOptions.data])

	const handleChange = (value: MultiValue<OptionType>) => {
		if (value && Array.isArray(value) && value.includes(CLEAR_DATA)) {
			onChange({ operation, value: [] })
		} else {
			onChange({ operation, value: value.map(({ value }) => value) })
		}
	}

	return (
		<DialogInner className='min-w-[320px] max-w-96'>
			{selectOptions.isLoading ? (
				<div className='flex h-24 items-center justify-center space-x-2'>
					<Spinner />
					<Text>{intl.get('loading').d('Loading...')}</Text>
				</div>
			) : (
				<BaseSelect
					isLoading={selectOptions.isLoading}
					onMenuScrollToBottom={field.onMenuScrollToBottom}
					onMenuScrollToTop={field.onMenuScrollToTop}
					options={options}
					placeholder={field.placeholder}
					{...props}
					components={components}
					value={value}
					onChange={handleChange}
				/>
			)}
			<PopupFooter
				isDisabledApply={!isDirty}
				onRemove={isRemovable ? onRemove : undefined}
				onCancel={() => {
					onReset()
					closePopup()
				}}
				onApply={() => {
					onApply()
					closePopup()
				}}
			/>
		</DialogInner>
	)
}

// ==============================
// Helper Utilities
// ==============================

const isGroupedOptions = (
	options: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
): options is GroupBase<OptionType>[] => {
	return any(prop('options'), options)
}

const getOptions = (options: OptionType[]): OptionType[] => {
	return orderBy<OptionType>(['label'], ['asc'], options)
}

const CLEAR_DATA = {
	value: '__clear-selected',
	label: intl.get('clear_selected').d('Clear selected'),
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Option = (props: any) =>
	props.data === CLEAR_DATA ? (
		<ClearOption {...props} />
	) : (
		<selectComponents.Option {...props} />
	)

const defaultComponents = { ...selectComponents, Option }
