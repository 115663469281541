import { all, find, isEqual, keyBy, prop } from 'lodash/fp'
import React, { useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { useQueryClient } from '@tanstack/react-query'

import notify from '@/components/toasts/helpers'
import {
	ColumnSchemaModel,
	ExportGroupBy,
	ExportType,
	FilterModel,
	ProductsQueryRequestModel,
} from '@/generated'
import intl from '@/locale'
import { useColumnsSchemaQuery } from '@/modules/core/queries'
import analytic from '@/services/analytics'
import { StoredColumnConfig } from '@/tools/columns'

import { mergeConfigs, schemaToConfig, storedToConfig } from '../helpers'
import { useCreateExportMutation } from '../mutations'
import { useExportTemplatesQuery } from '../queries'
import { ColumnConfig } from '../types'
import ExportConfigurationProvider from './ExportConfigurationProvider'
import { ExportFieldsFormData } from './ExportFieldsModal'
import ExportModal from './ExportModal'
import { ExportParamsFormData } from './ExportParamsModal'

interface ExportAssortmentModalProps {
	/**
	 * Export context data (filters, order_by, search)
	 */
	context: Partial<
		Pick<ProductsQueryRequestModel, 'filters' | 'order_by' | 'search'>
	>
	/**
	 * Default export name
	 */
	defaultName?: string
	/**
	 * Stored table columns configuration
	 */
	columnsConfigs: StoredColumnConfig[]
	isOpen: boolean
	close(): void
}
const DATA_KIND = 'assortment'

export default function ExportAssortmentModal({
	columnsConfigs,
	...props
}: ExportAssortmentModalProps) {
	const queryClient = useQueryClient()
	const [customExportColumns, setCustomExportColumns] = useLocalStorage<
		ColumnConfig[]
	>('export_fields_og_assortment', [])
	const schemaQuery = useColumnsSchemaQuery()
	const exportTemplates = useExportTemplatesQuery()
	const createExport = useCreateExportMutation({
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['exports'],
			})
		},
	})
	const schemaMap = useMemo(
		() => keyBy('name', schemaQuery.data),
		[schemaQuery.data]
	)
	const fieldsPresetOptions = useMemo(() => {
		const initialConfig = schemaToConfig(schemaQuery.data || [])
		const storedConfig = storedToConfig(
			columnsConfigs.filter(({ key }) => schemaMap[key]),
			schemaQuery.data || []
		)
		return [
			{
				value: 'all',
				label: intl.get('app.all_columns').d('All columns'),
				preset: initialConfig,
			},
			{
				value: 'table',
				label: intl.get('app.current_table_state').d('Current table state'),
				preset: storedConfig?.length
					? mergeConfigs(initialConfig, storedConfig)
					: initialConfig,
			},
			{
				value: 'custom',
				label: intl.get('app.custom_columns').d('Custom columns'),
				preset: customExportColumns?.length
					? mergeConfigs(initialConfig, customExportColumns)
					: initialConfig,
			},
			...(exportTemplates.data?.map((template) => {
				const preset = schemaMap
					? mergeConfigs(
							initialConfig,
							template.columns.map((column) => {
								const schema = schemaMap[column.name!]
								return {
									key: column.name,
									type: schema?.type || 'str',
									name: column.header,
									custom_name: column.header,
									static_value: column.static_value,
									enabled: true,
								} as ColumnConfig
							})
						)
					: []
				return {
					value: template.id,
					label: template.name,
					preset,
				}
			}) || []),
		]
	}, [
		schemaQuery.data,
		columnsConfigs,
		customExportColumns,
		exportTemplates.data,
	])
	const handleSubmit = (
		formData: ExportParamsFormData &
			ExportFieldsFormData & { exportDestination: string }
	) => {
		// Save custom columns if they are changed
		if (
			formData.exportDestination === 'local' &&
			isAllColumnsExisted(schemaMap, formData.fields) &&
			!isEqual(
				find({ value: formData.fields_preset }, fieldsPresetOptions)?.preset,
				formData.fields
			)
		) {
			setCustomExportColumns(formData.fields as ColumnConfig[])
		}

		analytic.logEvent('export: modal: create export', {
			export_kind: DATA_KIND,
			...formData,
		})

		createExport
			.mutateAsync(
				formData.exportDestination === 'local'
					? {
							name: formData.name,
							type: formData.format as ExportType,
							filters: (props.context.filters as FilterModel[]) || [],
							group_by: formData.aggregation as ExportGroupBy,
							columns: formData.fields.filter(prop('enabled')).map((field) => ({
								name: field.key,
								header: field.custom_name,
								static_value: field.static_value || null,
							})),
						}
					: {
							name: formData.name,
							type: ExportType.Bigquery,
							filters: (props.context.filters as FilterModel[]) || [],
							group_by: ExportGroupBy.PricingLine,
							columns: formData.fields.filter(prop('enabled')).map((field) => ({
								name: field.key,
								header: field.custom_name,
								static_value: field.static_value || null,
							})),
						}
			)
			.then((res) => {
				notify.loading(
					{
						text: intl
							.get('export.started.body', { name: res.name })
							.d(`Export "${res.name}" was started`),
					},
					{ id: res.id, duration: 9999999999 }
				)
			})
			.catch(() => {
				notify.error({
					text: intl
						.get('export.failed.body')
						.d('Export was failed on creating. Please try again.'),
				})
			})

		props.close()
	}

	return (
		<ExportConfigurationProvider
			kind={DATA_KIND}
			defaultValues={{
				name: props.defaultName || 'Assortment',
			}}
			isLoading={schemaQuery.isLoading || exportTemplates.isLoading}
			fieldsPresetOptions={fieldsPresetOptions}
			onSubmit={handleSubmit}
		>
			<ExportModal isOpen={props.isOpen} close={props.close} />
		</ExportConfigurationProvider>
	)
}
const isAllColumnsExisted = (
	schemaMap: Record<string, ColumnSchemaModel>,
	configs: StoredColumnConfig[] | ColumnConfig[]
) => {
	return all(
		(c: StoredColumnConfig | ColumnConfig) => c?.key && schemaMap[c?.key],
		configs
	)
}
