import { intlFormat } from 'date-fns'
import orderBy from 'lodash/fp/orderBy'

import { formatNumber } from '@/tools/locale'

export function colorForTopic(count: number, index: number) {
	const hue = Math.round((360 * index) / (count + 1))
	return `hsl(${hue}, 74%, 65%)`
}
const hue2rgb = (p: number, q: number, t: number) => {
	if (t < 0) t += 1
	if (t > 1) t -= 1
	if (t < 1 / 6) return p + (q - p) * 6 * t
	if (t < 1 / 2) return q
	if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
	return p
}
export function hslToHex(color: string) {
	const regexp = /hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/g
	// @ts-expect-error: Just ignore
	// eslint-disable-next-line
	let [h, s, l]: any[] = regexp.exec(color).slice(1)
	h /= 360
	s /= 100
	l /= 100
	let r, g, b
	if (s === 0) {
		r = g = b = l // achromatic
	} else {
		const q = l < 0.5 ? l * (1 + s) : l + s - l * s
		const p = 2 * l - q
		r = hue2rgb(p, q, h + 1 / 3)
		g = hue2rgb(p, q, h)
		b = hue2rgb(p, q, h - 1 / 3)
	}
	const toHex = (x: number) => {
		const hex = Math.round(x * 255).toString(16)
		return hex.length === 1 ? '0' + hex : hex
	}
	return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}
export const hour = 60 * 60 * 1000
export const day = 24 * hour
export const week = 7 * day
export const month = 30 * day
export const year = 365 * day
export const getDays = (date: number) => Math.floor(date / day)
export const normalizeDate = (date: number, interval: number) =>
	Math.floor(date / interval)
export const durations = {
	hour,
	day,
	week,
	month,
	quarter: 3 * month,
	half_year: 6 * month,
	year: 12 * month,
}
const seriePointCircle = (color: string) => `
	<div style="height: 3px;width: 20px;display: inline-flex;justify-content: center;background: ${color};margin-right: 5px">
		<div class="rounded" style="display: inline-block;width: 8px;height:8px;background:${color};margin-top: -2px;"></div>
	</div>`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderPoint = (s: any, point: any) => `${s}
<div class="${point.series.name == 'My price' ? 'text-brand' : ''} flex w-full items-center mt-1">${seriePointCircle(
	point.series.color
)}
<span class="mr-2.5">${point.series.name}:</span> <span class="ml-auto">${formatNumber(point.y)}</span></div>`
export function historicalPricesFormatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	const date = new Date(this.x as number)
	const content = orderBy(['y'], ['desc'], self.points).reduce(
		renderPoint,
		`<div>${intlFormat(date)}</div>`
	)
	return `<div class="chart-tooltip">${content}</div>`
}
