import clsx from 'clsx'
import { useMemo } from 'react'

import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	Spinner,
	TreeNodeType,
} from '@cmpkit/base'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import { getQueryDescription } from '@cmpkit/query-builder'

import { toAdaptedSchema } from '@/components/filter/adapter'
import { FilterField } from '@/components/filter/types'
import { StopListSettingsModel } from '@/generated'
import intl from '@/locale'
import {
	useBrandsQuery,
	useCategoriesQuery,
	useColumnsSchemaQuery,
} from '@/modules/core/queries'
import { toCategoriesTree } from '@/modules/core/utils'
import { JSONSchema } from '@/tools/json-schema-utils'

import { useOptimizationGroupSchemaQuery } from '../hooks/useOptimizationGroupSchema'

export function StopListCard({
	data,
	onClick,
}: {
	data?: StopListSettingsModel
	onClick: () => void
}) {
	const schemaQuery = useOptimizationGroupSchemaQuery()
	const fieldsQuery = useColumnsSchemaQuery<FilterField[]>({
		select: (data) =>
			data
				.filter(({ stage }) => ['prepro', 'pre_init_fields'].includes(stage!))
				.map(toAdaptedSchema),
	})
	const brandsQuery = useBrandsQuery({})
	const categoriesQuery = useCategoriesQuery<TreeNodeType[]>(
		{},
		{
			select: toCategoriesTree,
		}
	)
	const fields = useMemo(() => fieldsQuery.data || [], [fieldsQuery.data])
	const dataChoices = useMemo(
		() => ({
			brand: brandsQuery.data || [],
			category: categoriesQuery.data || [],
		}),
		[brandsQuery.data, categoriesQuery.data]
	)
	const isLoading =
		schemaQuery.isLoading ||
		fieldsQuery.isLoading ||
		brandsQuery.isLoading ||
		categoriesQuery.isLoading

	return (
		<Card
			className='fade-in group/card relative col-span-2 h-full cursor-pointer border hover:border-brand'
			onClick={onClick}
		>
			<CardHeader>
				<CardTitle>
					{intl.get('repricing_stop_list').d('Repricing stop list')}
				</CardTitle>
			</CardHeader>
			<CardContent>
				{isLoading ? (
					<Spinner />
				) : (
					<>
						<ul>
							{Object.entries(
								(schemaQuery.data?.properties.stop_list.properties.default_rules
									.properties || {}) as Record<string, JSONSchema>
							).map(([settingName, { measure }]) => {
								const settingValue = data?.default_rules?.[settingName]
								return (
									<li
										key={settingName}
										className={clsx('flex items-center gap-2', {
											'text-disabled': !settingValue,
										})}
									>
										{settingValue ? <CheckIcon /> : <CrossIcon />}{' '}
										{intl.get(`og_stoplist_default_rules_${settingName}`)}
										{!!settingValue && Number.isFinite(settingValue)
											? ` ${settingValue} `
											: ''}
										{!!measure && !!settingValue ? intl.get(measure) : ''}
									</li>
								)
							})}
						</ul>
						<div className='line-clamp-2 space-x-2'>
							{getQueryDescription(data?.custom_rules || [], {
								fields,
								dataChoices,
							}).map((item, index) => {
								return (
									<span
										key={index}
										className='break-all rounded bg-accent-3 text-xs'
									>
										<span className='px-1 font-semibold'>{item?.label}</span>
										<span className='rounded bg-accent-3 px-1 text-blue'>
											{item?.operation}
										</span>
										<span className='rounded bg-accent-3 px-1 font-medium italic text-muted'>
											{item?.value}
										</span>
									</span>
								)
							})}
						</div>
					</>
				)}
			</CardContent>
		</Card>
	)
}
