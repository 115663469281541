import { filter, find, map, pipe, prop } from 'lodash/fp'
import React, { Dispatch, SetStateAction, useState } from 'react'

import Grid, {
	Column,
	DataRow,
	Sort,
	Table,
	TableColumnFixed,
	TableColumnOrder,
	TableColumnResizing,
	TableColumnVisibility,
	TableSelection,
	TableTree,
	Template,
	TemplateConnector,
} from '@cmpkit/data-table'

import { TableSorting } from '@/components/_TableSorting'
import { PricingLineModel, ProductModel } from '@/generated'
import analytic from '@/services/analytics'
import { ColumnsConfigController, StoredColumnConfig } from '@/tools/columns'

export type PageTableProps = {
	rows: PricingLineModel[]
	columns: Column[]
	sort: Sort[]
	getChildTreeItems?: (row: DataRow<PricingLineModel>) => ProductModel[]
	setSort(sort: Sort[]): void
	getRowId(row: PricingLineModel | ProductModel): string
	rowHeightHandler?: (row: DataRow<PricingLineModel | ProductModel>) => number
	rowClassNameGetter?: (
		i: number,
		row: DataRow<PricingLineModel | ProductModel>
	) => string
	columnsConfig: ColumnsConfigController
	emptyDataPlaceholder?: React.ReactNode
	selected: string[]
	setSelected: Dispatch<SetStateAction<string[]>>
}
export default function ProductsTable({
	rows,
	columns,
	sort,
	setSort,
	getRowId = prop('id'),
	rowHeightHandler = () => 30,
	rowClassNameGetter = () => '',
	getChildTreeItems,
	columnsConfig,
	emptyDataPlaceholder,
	selected,
	setSelected,
}: PageTableProps) {
	const [expandedRowIds, setExpandedRowIds] = useState<string[]>([])
	const columnsOrders = map(prop('key'), columnsConfig.columnsConfig)

	const hiddenColumns = columnsConfig.columnsConfig
		?.filter(({ enabled }: StoredColumnConfig) => enabled === false)
		.map(prop('key')) as string[]

	const columnsLocks = pipe([filter(prop('locked')), map(prop('key'))])(
		columnsConfig.columnsConfig
	)
	const handleColumnsLocksChange = (columnsLocks?: string[]) => {
		columnsConfig.setColumnsConfig(
			columnsConfig.columnsConfig.map((columnConfig: StoredColumnConfig) => ({
				...columnConfig,
				locked: columnsLocks?.includes(columnConfig.key),
			}))
		)
	}
	const handleColumnsOrdersChange = (columnsOrder?: string[]) => {
		analytic.logEvent('table: move column')
		columnsConfig.setColumnsConfig(
			columnsOrder
				?.map((key) => find({ key }, columnsConfig.columnsConfig))
				.filter(Boolean) as StoredColumnConfig[]
		)
	}

	return (
		<Grid
			rows={rows}
			columns={columns || []}
			getRowId={getRowId}
			getRowHeight={rowHeightHandler}
		>
			<Table
				className='layout-content overflow-hidden rounded-lg border'
				rowClassNameGetter={rowClassNameGetter}
				divideX
				divideY
			/>
			<TableSelection selected={selected} setSelected={setSelected} />
			<TableColumnVisibility hiddenColumns={hiddenColumns || []} />
			<TableColumnResizing
				columnsWidth={columnsConfig.columnsWidth}
				onSaveColumnsWidth={columnsConfig.setColumnsWidth}
			/>
			<TableTree
				getSubItems={getChildTreeItems}
				expandedRowIds={expandedRowIds}
				setExpandedRowIds={setExpandedRowIds}
				selectionEnabled
			/>
			<TableColumnOrder
				defaultOrders={columnsOrders}
				columnsOrders={columnsOrders}
				onColumnsOrdersChange={handleColumnsOrdersChange}
			/>
			<TableColumnFixed
				defaultLocks={columnsLocks}
				columnsLocks={columnsLocks}
				onColumnsLocksChange={handleColumnsLocksChange}
			/>
			<TableSorting sort={sort} onSortChange={setSort} />
			<Template name='tableNoData'>
				<TemplateConnector>{() => emptyDataPlaceholder}</TemplateConnector>
			</Template>
		</Grid>
	)
}
