import { Operators } from '@cmpkit/query-builder'

import { FilterRuleModel } from '@/components/filter/types'
import { StopListSettingsModel } from '@/generated'
import { isValidFilter } from '@/tools/filtering'

/**
 * Checks if rule is sku rule
 * @param rule -  FilterRuleModel
 * @returns - true if rule is sku rule
 */
export const isSkuRule = (rule: FilterRuleModel): boolean =>
	rule.name === 'sku' && rule.operation === Operators.IS && !!rule.value

/**
 * Checks if rule is valid
 * Rule is valid if it has name, operation and value
 * @example { name: 'sku', operation: 'is', value: '123' } is valid
 * @example { name: 'sku', operation: 'is', value: null } is not valid
 * @param rule -  FilterRuleModel
 * @returns - true if rule is valid
 */
export const isValidRule = (rule: FilterRuleModel): boolean =>
	!!rule.name && !!rule.operation && isValidFilter(rule.operation, rule.value)

/**
 * Checks if all rules are valid
 * @param settings - StopListSettingsModel
 * @returns - true if all rules are valid
 */
export const isValidStopListSettings = (
	settings: StopListSettingsModel
): boolean =>
	settings?.custom_rules?.length ==
	settings?.custom_rules?.filter(isValidRule)?.length

/**
 * Sanitizes StopListSettingsModel settings by removing invalid rules
 * @param settings - StopListSettingsModel
 * @returns - StopListSettingsModel with only valid rules
 */
export const sanitizeOnlyValidSettings = (
	settings: StopListSettingsModel
): StopListSettingsModel => ({
	...settings,
	custom_rules: settings?.custom_rules?.filter(isValidRule),
})

/**
 * Get only sku rules from settings
 * @param settings - StopListSettingsModel
 * @returns - FilterRuleModel[] with only sku rules
 */
export const getSkuRules = (
	settings: StopListSettingsModel
): FilterRuleModel[] => settings?.custom_rules?.filter(isSkuRule) ?? []

/**
 * Get StopListSettingsModel with only non sku rules from settings
 * @param settings - StopListSettingsModel
 * @returns - StopListSettingsModel without sku rules
 */
export const stopListWithoutSkuRules = (
	settings: StopListSettingsModel
): StopListSettingsModel => ({
	...settings,
	custom_rules: (settings?.custom_rules ?? []).filter(
		(item) => !isSkuRule(item)
	),
})
