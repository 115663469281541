import {
	ArrowDownWideNarrowIcon,
	ArrowUpDownIcon,
	ArrowUpWideNarrowIcon,
} from 'lucide-react'
import React from 'react'

import { Button, Card, MenuItem, MenuList, Separator } from '@cmpkit/base'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'
import Popover from '@cmpkit/popover'

import { MHeader, TableInstance } from './types'
import { flexRender } from './utils/tanstack.helpers'

export default function ColumnOptions<T>({
	header,
	table,
}: {
	header: MHeader<T>
	table: TableInstance<T>
}) {
	const { column } = header

	const {
		options: {
			enableColumnPinning,
			enableHiding,
			enableMultiSort,
			enableSorting,
		},
	} = table
	if (
		!enableColumnPinning &&
		!enableHiding &&
		!enableMultiSort &&
		!enableSorting
	) {
		return null
	}
	if (!column.getCanPin() && !column.getCanSort() && !column.getCanHide()) {
		return null
	}
	const showDot = !!column.getIsSorted()

	return (
		<Popover
			placement='bottom-end'
			content={
				<Card className='min-w-60 border shadow'>
					<div className='min-w-44 overflow-hidden rounded-lg'>
						<div className='flex items-center bg-accent-3 p-3'>
							<div className='min-w-44 overflow-hidden rounded-lg'>
								<div className='ml-2 leading-4'>
									<div className='font-semibold'>
										{flexRender(column.columnDef.header, header.getContext())}
									</div>
									<div className='text-xs text-muted'>
										{flexRender(
											column.columnDef.description,
											header.getContext()
										)}
									</div>
								</div>
							</div>
						</div>
						<MenuList className='my-2 w-full'>
							{column.getCanPin() && <Pining header={header} table={table} />}
							{column.getCanPin() && column.getCanSort() && (
								<Separator className='my-1' />
							)}
							{column.getCanSort() ? (
								<Sorting header={header} table={table} />
							) : null}
						</MenuList>
					</div>
				</Card>
			}
		>
			<span className='relative'>
				{showDot && (
					<span className='absolute -right-1 -top-1 size-1.5 rounded bg-danger' />
				)}
				<Button
					variant='tertiary'
					size='mini'
					iconBefore={<DotsFilledIcon className='size-3' />}
				/>
			</span>
		</Popover>
	)
}
const Pining = <T,>({
	header,
	table,
}: {
	header: MHeader<T>
	table: TableInstance<T>
}) => {
	const { column } = header
	const {
		options: { localization },
	} = table
	return (
		<>
			{column.getIsPinned() !== 'left' ? (
				<MenuItem
					onClick={() => {
						column.pin('left')
					}}
				>
					{localization.pinToLeft}
				</MenuItem>
			) : null}
			{column.getIsPinned() ? (
				<MenuItem
					onClick={() => {
						column.pin(false)
					}}
				>
					{localization.unpin}
				</MenuItem>
			) : null}
			{column.getIsPinned() !== 'right' ? (
				<MenuItem
					onClick={() => {
						column.pin('right')
					}}
				>
					{localization.pinToRight}
				</MenuItem>
			) : null}
			{/* {column.getCanHide() ? (
				<MenuItem
					onClick={() => {
						column.pin('right')
					}}
				>
					{localization}
				</MenuItem>
			) : null} */}
		</>
	)
}
const Sorting = <T,>({
	header,
	table,
}: {
	header: MHeader<T>
	table: TableInstance<T>
}) => {
	const { column } = header
	const {
		options: { localization },
	} = table
	const handleClearSort = () => {
		column.clearSorting()
	}
	const handleSortAsc = () => {
		column.toggleSorting(false)
	}
	const handleSortDesc = () => {
		column.toggleSorting(true)
	}
	return (
		<>
			<MenuItem onClick={handleClearSort} disabled={!column.getIsSorted()}>
				<ArrowUpDownIcon className='size-4' />
				{localization.clearSort}
			</MenuItem>
			<MenuItem
				onClick={handleSortAsc}
				disabled={column.getIsSorted() === 'asc'}
			>
				<ArrowDownWideNarrowIcon className='size-4' />
				{localization.sortByColumnAsc}
			</MenuItem>
			<MenuItem
				onClick={handleSortDesc}
				disabled={column.getIsSorted() === 'desc'}
			>
				<ArrowUpWideNarrowIcon className='size-4' />
				{localization.sortByColumnDesc}
			</MenuItem>
		</>
	)
}
