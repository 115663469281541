import { useEffect } from 'react'
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router'

import * as Sentry from '@sentry/react'

import * as config from '@/config'
import { getEnvFromHost, getProjectFromHost } from '@/tools/utils'

/*eslint-disable*/
const client = getProjectFromHost(location.hostname)
const environment = getEnvFromHost(location.hostname)
Sentry.init({
	dsn: config.REACT_APP_SENTRY_DSN as string,
	environment,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		Sentry.extraErrorDataIntegration({
			depth: 3,
		}),
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),

		Sentry.replayIntegration(),
	],
	tracesSampleRate: 0.25,
	release: `${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_BUILD}`,
	ignoreErrors: [
		'ResizeObserver loop completed with undelivered notifications',
		'ResizeObserver loop limit exceeded',
		'AuthorizationError',
		'BadRequestError',
		'NetworkError',
		'NotFoundError',
		'UNAUTHORIZED',
		'BAD REQUEST',
		'Network Error',
		'TypeError: Failed to fetch',
		'TypeError: NetworkError when attempting to fetch resource.',
		"null is not an object (evaluating 'e.parentNode.removeChild')",
	],
	normalizeDepth: 10,
	denyUrls: [
		/extensions\//i,
		/^chrome:\/\//i,
		/api\.amplitude\.com/i,
		/app\.satismeter\.com/i,
		/moz-extension:/i,
	],
})
if (client) {
	Sentry.setTag('competera.client', client)
}

export default Sentry
