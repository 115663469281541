import { create } from 'zustand'
//import { persist } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

import { FilterRuleModel } from '@/components/filter/types'

/**
 * Zustand store
 */
interface DashboardState {
	filters: {
		[key: string]: FilterRuleModel[]
	}
	setFilters(key: string, filters: FilterRuleModel[]): void
	setStoredKeys(key: string, storedKeys: string[]): void
	storedKeys: {
		[key: string]: string[]
	}
}

export const useDashboardStore = create<DashboardState>(
	/* persist( */
	(set) => ({
		filters: {},
		storedKeys: {},
		setStoredKeys: (key: string, storedKeys: string[]) =>
			set((state) => {
				return {
					storedKeys: {
						...state.storedKeys,
						[key]: storedKeys,
					},
				}
			}),
		setFilters: (key: string, filters: FilterRuleModel[]) =>
			set((state) => {
				return {
					filters: {
						...state.filters,
						[key]: filters,
					},
				}
			}),
	}) /* ,
		{
			name: 'dashboards_state_v1',
		}) */
)

export const selectDashboardFilters = (key: string) =>
	useShallow((state: DashboardState) => ({
		setFilters: (filters: FilterRuleModel[]) => state.setFilters(key, filters),
		filters: state.filters[key],
	}))
export const selectDashboardFiltersKeys = (key: string) =>
	useShallow((state: DashboardState) => ({
		setStoredKeys: (storedKeys: string[]) =>
			state.setStoredKeys(key, storedKeys),
		storedKeys: state.storedKeys[key],
	}))
