import { omitAll } from 'lodash/fp'
import { useMemo } from 'react'
import { useLocation } from 'react-router'

import { toFilterRules } from '@/components/filter/utils'
import { getLocationQuery } from '@/tools/location'

export function useLocationStateManager() {
	const location = useLocation()
	const query = getLocationQuery(location.search)
	const filters = useMemo(
		() => toFilterRules(omitAll(['offset', 'limit', 'sort'], query)),
		[query]
	)
	return {
		query,
		filters,
		pagination: {
			limit: Number(query?.limit) || 100,
			offset: Number(query?.offset) || 0,
		},
	}
}
