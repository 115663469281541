import { pick } from 'lodash/fp'
import React, { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { Button, InlineMessage } from '@cmpkit/base'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import {
	Modal,
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import ErrorBoundary from '@/components/ErrorBoundary'
import { PricingCampaignModel, PricingCampaignSettingsModel } from '@/generated'
import useStep from '@/hooks/useStep'
import intl from '@/locale'

import { getPricingCampaignEngine } from '../../helpers'
import { useCopyPricingCampaignSettingsMutation } from '../../mutations'
import { usePricingCampaignQuery } from '../../queries'
import SelectPricingCampaignView from '../SelectPricingCampaignsView'
import Checkout from './CheckoutView'
import ExecutionView from './ExecutionView'
import SelectSettingsView from './SelectSettingsView'

//import CoreService from '@/modules/core/service'

const getModalHead = (step: number) => {
	switch (step) {
		case 1:
			return {
				title: intl.get('pc.modal.copy_settings_1.title'),
				subtitle: intl.get('pc.modal.copy_settings_1.subtitle'),
			}
		case 2:
			return {
				title: intl.get('pc.modal.copy_settings_2.title'),
				subtitle: intl.get('pc.modal.copy_settings_2.subtitle'),
			}
		case 3:
			return {
				title: intl.get('pc.modal.copy_settings_3.title'),
				subtitle: intl.get('pc.modal.copy_settings_3.subtitle'),
			}
		default:
			return {
				title: intl.get('pc.modal.copy_settings_4.title'),
				subtitle: intl.get('pc.modal.copy_settings_4.subtitle'),
			}
	}
}
type Props = {
	isOpen: boolean
	close(): void
	selected: string
	resetSelectedItems?(): void
	initialStep?: number
	selectedSetting?: string[]
}
const getPartialSettings = (
	pc: PricingCampaignModel | undefined,
	settingsKeys: string[]
) => {
	const keys = [...settingsKeys]
	if (keys.includes('settings.pricing_rule')) {
		keys.push('settings.pricing_rule_params')
	}
	return pick(keys, pc)
}
export default function CopyPricingCampaignSettingsModal({
	isOpen,
	close,
	selected: selectedCampaign,
	resetSelectedItems,
	initialStep = 1,
	selectedSetting,
}: Props) {
	/**
	 * Common hooks
	 */
	const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([])
	const [selectedSettings, setSelectedSettings] = useState<string[]>(
		selectedSetting || []
	)
	const [currentStep, helpers] = useStep({ maxStep: 4, initialStep })
	const { canGoToPrevStep, canGoToNextStep, goToNextStep, goToPrevStep } =
		helpers

	const { title, subtitle } = getModalHead(currentStep)

	/**
	 * Queries
	 */
	const pricingCampaignQuery = usePricingCampaignQuery(selectedCampaign)

	const queryClient = useQueryClient()
	const copySettings = useCopyPricingCampaignSettingsMutation({
		mutationKey: ['copyPricingCampaignSettings'],
		onSuccess() {
			resetSelectedItems && resetSelectedItems()
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricings-settings'],
			})
			queryClient.invalidateQueries({
				queryKey: ['products-counts-by-pc'],
			})
		},
	})

	/**
	 * Computed data
	 */
	const formData = {
		campaigns: selectedCampaigns,
		settings: selectedSettings,
		pricingCampaignPartialSettings: getPartialSettings(
			pricingCampaignQuery.data,
			selectedSettings
		) as PricingCampaignSettingsModel,
	}

	const validateCampaign = (campaign: PricingCampaignModel): string[] => {
		const warnings: string[] = []
		if (
			!pricingCampaignQuery.data ||
			getPricingCampaignEngine(pricingCampaignQuery.data) !==
				getPricingCampaignEngine(campaign)
		) {
			warnings.push('engine_mismatch')
		}
		return warnings.filter(Boolean)
	}
	const showBackButton = currentStep !== 2 || initialStep !== 2
	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={close} showCloseButton size='large'>
					<ModalHeader>
						<ModalTitle>{title}</ModalTitle>
						<ModalDescription>{subtitle}</ModalDescription>
					</ModalHeader>
					<ErrorBoundary>
						{currentStep === 1 && (
							<ModalBody className='space-y-3'>
								<SelectSettingsView
									pricingCmapaignId={selectedCampaign[0]}
									selected={selectedSettings}
									setSelected={setSelectedSettings}
								/>
							</ModalBody>
						)}
						{currentStep === 2 && (
							<ModalBody className='space-y-3'>
								<SelectPricingCampaignView
									pricingCmapaignId={selectedCampaign[0]}
									validateCampaign={validateCampaign}
									selected={selectedCampaigns}
									setSelected={setSelectedCampaigns}
								/>
								<InlineMessage
									icon={<InfoIcon />}
									variant='warning'
									className='text-xs font-medium'
								>
									{intl.get('pc.modal.copy_settings_2.note')}
								</InlineMessage>
							</ModalBody>
						)}
						{currentStep === 3 && (
							<ModalBody className='space-y-3'>
								<Checkout formData={formData} />
							</ModalBody>
						)}
						{currentStep === 4 && (
							<ModalBody className='space-y-3'>
								<ExecutionView run={copySettings} formData={formData} />
							</ModalBody>
						)}
					</ErrorBoundary>
					<ModalFooter className='justify-end space-x-2'>
						{canGoToNextStep && (
							<Button
								disabled={copySettings.isPending || copySettings.isSuccess}
								className='mr-auto'
								onClick={close}
							>
								{intl.get('general_cancel')}
							</Button>
						)}
						{canGoToPrevStep && showBackButton && (
							<Button
								disabled={copySettings.isPending || copySettings.isSuccess}
								onClick={goToPrevStep}
								iconBefore={<CaretLeftIcon />}
							>
								{intl.get('general_back')}
							</Button>
						)}
						{canGoToNextStep && (
							<Button
								variant='primary-brand'
								disabled={
									(currentStep === 1 && selectedSettings.length === 0) ||
									(currentStep === 2 && selectedCampaigns.length === 0)
								}
								onClick={goToNextStep}
								iconAfter={<CaretRightIcon />}
							>
								{currentStep === 3
									? intl.get('app.copy_settings_to_pc')
									: intl.get('general_next')}
							</Button>
						)}
						{!canGoToNextStep && (
							<Button
								variant='primary-brand'
								disabled={copySettings.isPending}
								onClick={close}
							>
								{intl.get('general_close_and_finish')}
							</Button>
						)}
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}
