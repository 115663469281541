import {
	filter,
	flatten,
	isNil,
	map,
	negate,
	orderBy,
	pipe,
	prop,
	uniq,
} from 'lodash/fp'

import { isFilterValid } from '@cmpkit/query-builder'

import { DataOption } from '@/common.types'
import {
	MatchedProductsAssignmentModel,
	MatchedProductsRequestModel,
	PricingCampaignModel,
	PricingEngineType,
} from '@/generated'
import intl from '@/locale'
import { client } from '@/network/client'

import { getPricingCampaignEngine, isGlobalPricingCampaign } from './helpers'

export const pcToOption = (
	item: PricingCampaignModel
): DataOption & { engine: PricingEngineType } => ({
	label: item.name || intl.get('pc_new_products'),
	engine: getPricingCampaignEngine(item),
	value: item.id || 'null',
})

export const isValidAssignment = ({
	assignments,
}: {
	assignments: MatchedProductsAssignmentModel[]
}) =>
	!isNil(assignments) &&
	assignments?.every(({ filters }) => filters?.every(isFilterValid))

export const isValidMatchedProductsAssignments = (
	assignments: MatchedProductsRequestModel[]
) => {
	return !isNil(assignments) && assignments.every(isValidAssignment)
}

export const alignPricingCampaignsOrders = (
	data: PricingCampaignModel[]
): PricingCampaignModel[] => {
	// Separate global and local PC
	const global = orderBy(
		['order'],
		['asc'],
		data.filter(isGlobalPricingCampaign)
	)
	// Separate global and local PC
	const local = orderBy(
		['order'],
		['asc'],
		data.filter(negate(isGlobalPricingCampaign))
	)

	// Assign order to each PC to avoid conflicts
	return [...global, ...local].map((pc, index) => ({
		...pc,
		order: index + 1,
	}))
}
const uniqFlat = pipe([flatten, filter(Boolean), uniq])
/**
 * Get list of affected optimization groups
 * @param pricingCampaign - PricingCampaignModel
 * @returns - Affected optimization groups
 */
export const getAffectedOptimizationGroups = async (
	pricingCampaign: PricingCampaignModel
) => {
	const filterGroups = pricingCampaign?.settings.product_assignments?.map(
		({ filters }) => filters || []
	)
	const ids = await Promise.all(
		filterGroups.map((filters) =>
			client.util
				.getProductsCountsByOg({
					filters,
				})
				.then(map(prop('optimization_group_id')))
		)
	)
	return uniqFlat(ids) || []
}
