import { ChangeEvent, Fragment, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
	Button,
	FormError,
	FormGroup,
	FormLabel,
	Required,
	Switch,
	TreeNodeType,
} from '@cmpkit/base'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { SingleDateRangePicker } from '@/components/date-range'
import DateRangeButton from '@/components/date-range/DateRangeButton'
import { RHFTextArea, RHFTextField } from '@/components/HookForm'
import SearchInput from '@/components/SearchInput'
import { filterNodesOfTree, TreeCheckbox } from '@/components/TreePicker'
import intl from '@/locale'

export default function PromoForm({
	options,
}: {
	options: Record<string, TreeNodeType[]>
}) {
	const { control } = useFormContext()
	return (
		<>
			<RHFTextField
				className='w-full'
				required
				autoComplete='off'
				placeholder='eg. Summer sale'
				label={intl.get('general_name')}
				name='name'
			/>
			<RHFTextArea
				required
				autoComplete='off'
				placeholder='eg. Summer sale with 50% discount on all products'
				label={intl.get('general_description')}
				name='description'
				rows={6}
			/>
			<FormGroup className='flex items-center'>
				<FormLabel>Is active</FormLabel>
				<Controller
					name={'is_active'}
					control={control}
					render={({ field }) => <Switch {...field} checked={field.value} />}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>
					Period
					<Required />
				</FormLabel>
				<Controller
					name='date'
					control={control}
					render={({ field: { value, onChange }, fieldState: { error } }) => {
						return (
							<>
								<SingleDateRangePicker
									value={[value?.start || new Date(), value?.end || new Date()]}
									onApply={(period) => {
										onChange({
											start: period[0],
											end: period[1],
										})
									}}
								>
									<DateRangeButton
										startDate={value?.start}
										endDate={value?.end}
										className={'cmp-input w-full'}
									/>
								</SingleDateRangePicker>
								{error && <FormError>{error.message}</FormError>}
							</>
						)
					}}
				/>
			</FormGroup>
			<FormGroup>
				<FormLabel>Sales units</FormLabel>
				<Controller
					name='pos_ids'
					control={control}
					render={({ field: { value, onChange }, fieldState: { error } }) => {
						return (
							<>
								<PopupArraySelectWidget
									value={value}
									onChange={onChange}
									options={options.pos_ids || []}
								/>
								{error && <FormError>{error.message}</FormError>}
							</>
						)
					}}
				/>
			</FormGroup>
		</>
	)
}

const PopupArraySelectWidget = ({
	value,
	onChange,
	options,
}: {
	value: string[]
	onChange: (value: string[]) => void
	options: TreeNodeType[]
}) => {
	const title = intl.get('sales_unit')
	const [isOpen, setIsOpen] = useState(false)
	const [searchText, setSearchText] = useState('')
	const [selected, setSelected] = useState(value || [])
	const close = () => setIsOpen(false)
	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) =>
		setSearchText(event.target.value)
	const handleClose = () => close()
	const handleApply = () => {
		onChange(selected)
		close()
	}
	const filteredList = filterNodesOfTree(options, (item) =>
		item.name.toLowerCase().includes(searchText.toLowerCase())
	)
	return (
		<Fragment>
			<div className='flex items-center justify-between rounded-lg border border-solid border-base bg-accent-3 px-4 py-3'>
				<p className='text-muted'>
					{value?.length
						? intl.get('categories_selected') + ' ' + value?.length
						: intl.get('itmes_not_selected')}
				</p>
				<Button
					onClick={(e) => {
						e.preventDefault()
						setIsOpen(true)
					}}
				>
					{intl.get('general_select')}
				</Button>
			</div>
			<ModalTransition>
				{isOpen && (
					<Modal onClose={close} showCloseButton size='mini'>
						<ModalHeader>
							<ModalTitle>{title}</ModalTitle>
						</ModalHeader>
						<ModalBody className='text-sm'>
							<SearchInput
								className='mb-2.5'
								value={searchText}
								onChange={handleInputChange}
								placeholder={intl.get('general_search')}
							/>
							<div className='h-64 overflow-y-scroll rounded-lg border px-2.5'>
								<TreeCheckbox
									tree={filteredList}
									value={selected}
									onChange={setSelected}
								/>
							</div>
						</ModalBody>
						<ModalFooter className='justify-between border-t p-2.5'>
							<Button onClick={handleClose}>
								{intl.get('general_cancel')}
							</Button>
							<Button variant='primary-brand' onClick={handleApply}>
								{intl.get('general_apply')}
							</Button>
						</ModalFooter>
					</Modal>
				)}
			</ModalTransition>
		</Fragment>
	)
}
