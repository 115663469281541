import { SparklesIcon } from 'lucide-react'
import React from 'react'

import { useDisclosure } from '@cmpkit/hooks'
import Popover from '@cmpkit/popover'
import { SidebarMenuButton, SidebarMenuItem } from '@cmpkit/sidebar'

import intl from '@/locale'

import { useAiChatSettingssQuery } from '../queries'
import AssistantProvider from './AssistantContext'
import AssistantConversation from './AssistantConversation'

export default function AssistantMenuItem() {
	const { data } = useAiChatSettingssQuery()

	const popover = useDisclosure()
	if (!data?.is_enabled) {
		return null
	}
	return (
		<AssistantProvider>
			<Popover
				isOpen={popover.isOpen}
				placement='right-start'
				content={() => <AssistantConversation close={() => popover.close()} />}
			>
				<SidebarMenuItem>
					<SidebarMenuButton
						data-testid='ai-assistnt-menu-item'
						tooltip={intl.get('app.assistant').d('AI Assistant')}
						onClick={() => {
							popover.toggle()
						}}
						size='sm'
					>
						<SparklesIcon className='!text-brand' />
						<span> {intl.get('app.assistant').d('AI Assistant')}</span>
					</SidebarMenuButton>
				</SidebarMenuItem>
			</Popover>
		</AssistantProvider>
	)
}
