import React, { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { Button, InlineMessage } from '@cmpkit/base'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import {
	Modal,
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import ErrorBoundary from '@/components/ErrorBoundary'
import { OptimizationModel, PricingCampaignDuplicateRequest } from '@/generated'
import useStep from '@/hooks/useStep'
import intl from '@/locale'
import CoreService from '@/modules/core/service'

import { useDublicatePricingCampaignsMutation } from '../../mutations'
import SelectOptimizationGroupView from '../SelectOptimizationGroupsView'
import Checkout from './CheckoutView'
import ExecutionView from './ExecutionView'

type Props = {
	isOpen: boolean
	close(): void
	selected: string[]
	resetSelectedItems(): void
}
const getModalHead = (step: number) => {
	switch (step) {
		case 1:
			return {
				title: intl.get('pc.modal.dublicate_1.title'),
				subtitle: intl.get('pc.modal.dublicate_1.subtitle'),
			}
		case 2:
			return {
				title: intl.get('pc.modal.dublicate_2.title'),
				subtitle: intl.get('pc.modal.dublicate_2.subtitle'),
			}
		case 3:
			return {
				title: intl.get('pc.modal.dublicate_3.title'),
				subtitle: intl.get('pc.modal.dublicate_3.subtitle'),
			}
		default:
			return {
				title: intl.get('pc.modal.dublicate_4.title'),
				subtitle: intl.get('pc.modal.dublicate_4.subtitle'),
			}
	}
}
export default function DublicatePricingCampaigns({
	isOpen,
	close,
	selected: selectedCampaigns,
	resetSelectedItems,
}: Props) {
	const [selectedGroups, setSelectedGroups] = useState<string[]>([])
	const [currentStep, helpers] = useStep({ maxStep: 3 })
	const { canGoToPrevStep, canGoToNextStep, goToNextStep, goToPrevStep } =
		helpers

	const queryClient = useQueryClient()
	const dublicate = useDublicatePricingCampaignsMutation({
		mutationKey: ['dublicatePricingCampaign'],
		async onMutate(variables: PricingCampaignDuplicateRequest) {
			const optimizations = queryClient.getQueryData<
				Record<string, OptimizationModel>
			>(['optimizations'])
			await CoreService.resetOptimizations(
				optimizations!,
				variables.dst_optimization_group_ids
			)
		},
		onSuccess() {
			resetSelectedItems()
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns-segments'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricings-settings'],
			})
			queryClient.invalidateQueries({
				queryKey: ['products-counts-by-pc'],
			})
		},
	})

	const { title, subtitle } = getModalHead(currentStep)

	const formData = {
		campaigns: selectedCampaigns,
		groups: selectedGroups,
	}
	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={close} showCloseButton size='large'>
					<ModalHeader>
						<ModalTitle>{title}</ModalTitle>
						<ModalDescription>{subtitle}</ModalDescription>
					</ModalHeader>
					<ErrorBoundary>
						{currentStep === 1 && (
							<ModalBody className='space-y-3'>
								<SelectOptimizationGroupView
									selected={selectedGroups}
									setSelected={setSelectedGroups}
								/>
								<InlineMessage
									icon={<InfoIcon />}
									variant='brand'
									className='text-xs font-medium'
								>
									{intl.get('note.copy_pc_general_note')}
								</InlineMessage>
							</ModalBody>
						)}
						{currentStep === 2 && (
							<ModalBody className='space-y-3'>
								<Checkout formData={formData} />
								<InlineMessage
									icon={<InfoIcon />}
									variant='brand'
									className='text-xs font-medium'
								>
									{intl.get('note.copy_pc_general_note')}
								</InlineMessage>
							</ModalBody>
						)}
						{currentStep === 3 && (
							<ModalBody className='space-y-3'>
								<ExecutionView formData={formData} run={dublicate} />
							</ModalBody>
						)}
					</ErrorBoundary>
					<ModalFooter>
						{canGoToNextStep && (
							<Button
								disabled={dublicate.isPending || dublicate.isSuccess}
								className='mr-auto'
								onClick={close}
							>
								{intl.get('general_cancel')}
							</Button>
						)}
						{canGoToPrevStep && (
							<Button
								disabled={dublicate.isPending || dublicate.isSuccess}
								onClick={goToPrevStep}
								iconBefore={<CaretLeftIcon />}
							>
								{intl.get('general_back')}
							</Button>
						)}
						{canGoToNextStep && (
							<Button
								variant='primary-brand'
								disabled={currentStep === 1 && selectedGroups.length === 0}
								onClick={goToNextStep}
								iconAfter={<CaretRightIcon />}
							>
								{currentStep === 2
									? intl.get('app.duplicate_pcs')
									: intl.get('general_next')}
							</Button>
						)}
						{!canGoToNextStep && (
							<Button
								variant='primary-brand'
								disabled={dublicate.isPending}
								onClick={close}
							>
								{intl.get('general_close_and_finish')}
							</Button>
						)}
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}
