import { AxiosError } from 'axios'

import { useQuery } from '@tanstack/react-query'

import {
	ElasticityDataUnit,
	HTTPValidationError,
	MultipleAlertsInfoResponseModel,
	OptimizationGroupModel,
	OptimizationModel,
	OptimizationStatisticModel,
	ScenarioModel,
	ScenarioRunModel,
	ScenarioRunSetModel,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { FAILED, FINISHED } from '@/modules/core/constants'
import { client } from '@/network/client'

const getOptimizations = async (
	optimizationGroupId: string,
	optimizationIds: string,
	refetchInterval: number = 1000
) => {
	const delay = (ms: number): Promise<void> =>
		new Promise((resolve) => setTimeout(resolve, ms))
	while (true) {
		const { optimizations } = await client.core.getOptimizations({
			optimizationGroupId,
			optimization_ids: optimizationIds,
		})
		if (
			optimizations?.every(({ status }) => [FINISHED, FAILED].includes(status))
		) {
			return optimizations
		}
		await delay(refetchInterval)
	}
}

const getScenarioRunsItems = async (
	optimizationGroupId: string,
	optimizationGroup: OptimizationGroupModel
): Promise<ScenarioRunsItems> => {
	const runSet = await client.repricingScenarioRuns.getLastScenarioRuns({
		optimization_group_id: optimizationGroupId,
	})
	const scenarioRuns: ScenarioRunModel[] = runSet?.scenario_runs || []
	if (!scenarioRuns.length) {
		return {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
	}
	const optimizationIds = scenarioRuns
		.map(({ optimization_id }) => optimization_id)
		.join(',')
	const optimizations = await getOptimizations(
		optimizationGroupId,
		optimizationIds
	)
	const [
		optimizationStatistics,
		multipleInterpretabilityStatistics,
		multipleAlertsInfo,
	] = await Promise.all([
		client.bi.getOptimizationsStatistics({
			optimization_group_id: optimizationGroup.id,
			optimization_ids: optimizationIds,
		}),
		client.bi.getStatisticsMultipleInterpretability({
			optimization_group_id: optimizationGroup.id,
			optimization_ids: optimizationIds,
		}),
		client.bi.getMultipleAlertsInfo({
			optimizations: optimizations.map(({ id, optimization_group_id }) => ({
				optimization_id: id,
				optimization_group_id,
			})),
		}),
	])

	const scenarios = await client.scenario.scenariosList({
		optimization_group_id: optimizationGroupId,
	})
	const extendedScenarioRunsData: (ScenarioRunModel & {
		description?: string
	})[] = scenarioRuns?.map((scenario) => {
		const description = scenarios?.find(
			({ id }) => id === scenario?.scenario_id
		)?.description
		return { ...scenario, description, optimizationGroupId }
	})
	return {
		scenarioRuns: extendedScenarioRunsData,
		optimizations,
		optimizationStatistics,
		multipleInterpretabilityStatistics:
			multipleInterpretabilityStatistics as multipleInterpretabilityStatistics[],
		multipleAlertsInfo,
	}
}
export type multipleInterpretabilityStatistics = {
	optimization_id: string
	metrics: {
		[key: string]: number | null
	}
	elasticity: ElasticityDataUnit
}
type ScenarioRunsItems = {
	scenarioRuns: ScenarioRunModel[]
	optimizations: OptimizationModel[]
	optimizationStatistics: OptimizationStatisticModel[]
	multipleInterpretabilityStatistics: multipleInterpretabilityStatistics[]
	multipleAlertsInfo: MultipleAlertsInfoResponseModel
}
export const useScenarioRunsItems = <T = ScenarioRunsItems>(
	optimizationGroupId: string,
	optimizationGroup: OptimizationGroupModel,
	options?: UseQueryOptions<ScenarioRunsItems, Error, T>
) => {
	const queryParams = { optimization_group_id: optimizationGroupId }
	return useQuery<ScenarioRunsItems, Error, T>({
		queryKey: ['scenarios-runs-items', queryParams],
		queryFn: () => getScenarioRunsItems(optimizationGroupId, optimizationGroup),
		...(options || {}),
	})
}

/**
 * Hook for get scenarios in optimization group
 * @param optimizationGroupId - Optimization group id
 * @param options - Query options
 * @returns - Query result
 */
export const useScenariosQuery = <T = ScenarioModel[]>(
	optimizationGroupId?: string,
	options?: UseQueryOptions<ScenarioModel[], AxiosError<HTTPValidationError>, T>
) => {
	const queryParams = optimizationGroupId
		? { optimization_group_id: optimizationGroupId }
		: {}
	return useQuery<ScenarioModel[], AxiosError<HTTPValidationError>, T>({
		queryKey: ['scenarios', queryParams],
		queryFn: () => client.scenario.scenariosList(queryParams),
		...(options || {}),
	})
}

/**
 * Hook for get scenario by id
 * @param scenarioId - Scenario id
 * @param options - Query options
 * @returns - Query result
 */
export const useScenarioQuery = <T = ScenarioModel>(
	scenarioId: string,
	options?: UseQueryOptions<ScenarioModel, Error, T>
) => {
	return useQuery<ScenarioModel, Error, T>({
		queryKey: ['scenarios', scenarioId],
		queryFn: () => client.scenario.scenarioGet(scenarioId),
		...(options || {}),
	})
}

export const useScenariosRunsQuery = (
	optimizationGroupId: string,
	options?: UseQueryOptions<ScenarioRunSetModel | null, HTTPValidationError>
) => {
	const queryParams = { optimization_group_id: optimizationGroupId }
	return useQuery<ScenarioRunSetModel | null, HTTPValidationError>({
		queryKey: ['scenarios-run-sets', queryParams],
		queryFn: () =>
			client.repricingScenarioRuns.getLastScenarioRuns({
				optimization_group_id: optimizationGroupId,
			}),
		...(options || {}),
	})
}
