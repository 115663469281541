import React from 'react'

import * as Sentry from '@sentry/react'

import FatalError from './FatalError'

const defaultFallback = (props: { resetError?: () => void }) => {
	return <FatalError {...props} />
}
export default ({
	children,
	fallback,
}: {
	children: React.ReactNode
	fallback?: React.ReactElement | React.ComponentFactory<any, any> // eslint-disable-line
}) => (
	<Sentry.ErrorBoundary fallback={fallback ?? defaultFallback}>
		{children}
	</Sentry.ErrorBoundary>
)
