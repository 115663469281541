import clsx from 'clsx'
import { SparkleIcon, SparklesIcon } from 'lucide-react'
import React, { forwardRef, Ref, SVGProps, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { Button, Card, Textfield } from '@cmpkit/base'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import RefreshIcon from '@cmpkit/icon/lib/glyph/refresh'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'
import analytic from '@/services/analytics'

import { FormProvider } from '../../../components/HookForm'
import { useAssistantConversation } from './AssistantContext'

export const ThumbsDownIcon = forwardRef(
	(
		{ className, ...props }: SVGProps<SVGSVGElement>,
		ref: Ref<SVGSVGElement>
	) => (
		<svg
			fill='currentColor'
			viewBox='0 0 24 24'
			role='presentation'
			width={16}
			height={16}
			className={clsx('cmp-icon', className)}
			ref={ref}
			{...props}
		>
			<path
				fillRule='evenodd'
				d='M5.9 3.2a1 1 0 0 1 .6-.2H16v10.269a3 3 0 0 0-1.445 1.394v.002l-3.128 6.245a2.131 2.131 0 0 1-1.456-2.55v-.004l1-4.12A1 1 0 0 0 10 13H4.17a1 1 0 0 1-.96-1.28l2.33-8a1 1 0 0 1 .36-.52ZM18 13h2a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-2v10ZM17 1h3a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-2.76a1 1 0 0 0-.894.555l-.002.003-3.45 6.89a1 1 0 0 1-.906.552 4.13 4.13 0 0 1-3.96-5.118l.001-.002.7-2.88h-4.56a3 3 0 0 1-2.88-3.84l2.33-8A3.001 3.001 0 0 1 6.5 1H17Z'
				clipRule='evenodd'
			/>
		</svg>
	)
)
export const ThumbsUpIcon = forwardRef(
	(
		{ className, ...props }: SVGProps<SVGSVGElement>,
		ref: Ref<SVGSVGElement>
	) => (
		<svg
			fill='currentColor'
			viewBox='0 0 24 24'
			role='presentation'
			width={16}
			height={16}
			className={clsx('cmp-icon', className)}
			ref={ref}
			{...props}
		>
			<path
				fillRule='evenodd'
				d='M12.012 1a1 1 0 0 0-.906.552l-3.45 6.89-.002.003A1 1 0 0 1 6.76 9H4a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h13.5a3 3 0 0 0 2.88-2.16l2.33-8A3 3 0 0 0 19.83 9h-4.558l.699-2.88v-.002A4.13 4.13 0 0 0 12.012 1ZM8 21h9.5a1 1 0 0 0 .96-.72l2.33-8a1.002 1.002 0 0 0-.513-1.174.999.999 0 0 0-.447-.106H14a1 1 0 0 1-.972-1.236l1-4.12.001-.004a2.13 2.13 0 0 0-1.456-2.55L9.446 9.334l-.001.001A3 3 0 0 1 8 10.731V21ZM6 11v10H4a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h2Z'
				clipRule='evenodd'
			/>
		</svg>
	)
)
export const SendIcon = forwardRef(
	(
		{ className, ...props }: SVGProps<SVGSVGElement>,
		ref: Ref<SVGSVGElement>
	) => (
		<svg
			fill='currentColor'
			viewBox='0 0 24 24'
			role='presentation'
			width={16}
			height={16}
			className={clsx('cmp-icon', className)}
			ref={ref}
			{...props}
		>
			<g fillRule='evenodd' clipRule='evenodd'>
				<path d='M22.707 1.293a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414-1.414l11-11a1 1 0 0 1 1.414 0Z' />
				<path d='M22.707 1.293a1 1 0 0 1 .237 1.037l-7 20a1 1 0 0 1-1.858.076l-3.844-8.648-8.648-3.844a1 1 0 0 1 .076-1.858l20-7a1 1 0 0 1 1.037.237ZM4.711 9.11l6.695 2.975a1 1 0 0 1 .508.508l2.975 6.695L20.37 3.63 4.711 9.11Z' />
			</g>
		</svg>
	)
)

export default function AssistantConversation({
	close,
}: {
	close: () => void
}) {
	const container = useRef<HTMLDivElement | null>(null)
	const {
		welcome_text,
		suggestions,
		messages,
		messageReaction,
		isBusy,
		sendMessage,
		resetConversation,
	} = useAssistantConversation()
	const methods = useForm<{
		message: string
	}>({
		defaultValues: {
			message: '',
		},
	})
	useEffect(() => {
		analytic.logEvent('ai: chat: opened')
		return () => {
			analytic.logEvent('ai: chat: closed')
		}
	}, [])
	useEffect(() => {
		container.current?.scrollTo({
			top: container.current.scrollHeight,
			behavior: 'smooth',
		})
	}, [messages])

	const handleSubmit = methods.handleSubmit(({ message }) => {
		sendMessage(message)
		analytic.logEvent('ai: chat: sent user message')
		methods.reset({ message: '' })
	})
	return (
		<Card className='mt-5 flex h-[90vh] w-[512px] flex-col overflow-hidden border shadow-md'>
			<div className='flex items-center justify-between rounded-t-lg border-b border-solid border-base bg-accent-3 px-5 pb-3 pt-4'>
				<div className='flex items-center gap-2'>
					<SparklesIcon className='size-4 text-brand' />
					<h4>{intl.get('app.assistant').d('AI Assistant')}</h4>
				</div>
				<div>
					<Tooltip content={intl.get('assistant.reset_chat').d('Reset chat')}>
						<Button
							data-testid='chat-reset'
							variant='tertiary'
							iconBefore={<RefreshIcon />}
							onClick={() => {
								analytic.logEvent('ai: chat: reset conversation')
								resetConversation()
							}}
						/>
					</Tooltip>
					<Tooltip content={intl.get('assistant.close_chat').d('Close chat')}>
						<Button
							onClick={() => close()}
							data-testid='chat-close'
							variant='tertiary'
							iconBefore={<CrossIcon />}
						/>
					</Tooltip>
				</div>
			</div>
			<div className='relative flex w-full flex-1 shrink-0 grow basis-0 flex-col items-start'>
				<div className='flex w-full shrink-0 grow basis-0 flex-col items-start justify-end overflow-hidden'>
					<div
						className='flex w-full flex-col gap-4 overflow-auto p-4'
						ref={container}
					>
						<ChatBotMessage>
							<span className='text-body font-body text-default-font markdown-txt shrink-0 grow basis-0 whitespace-pre-wrap'>
								<ReactMarkdown remarkPlugins={[remarkGfm]}>
									{welcome_text}
								</ReactMarkdown>
							</span>
						</ChatBotMessage>
						{messages.map((message) => {
							if (message.sender === 'assistant') {
								return (
									<ChatBotMessage key={message.id}>
										<span className='text-body font-body text-default-font markdown-txt shrink-0 grow basis-0'>
											<ReactMarkdown remarkPlugins={[remarkGfm]}>
												{message.text}
											</ReactMarkdown>
										</span>
										{!message.is_system && (
											<div className='ml-auto'>
												<Button
													data-testid='chat-bot-message-like'
													size='mini'
													active={message.reaction === 'like'}
													onClick={() =>
														!message.reaction &&
														messageReaction(message.id, 'like')
													}
													variant='tertiary'
													iconBefore={<ThumbsUpIcon />}
												/>
												<Button
													data-testid='chat-bot-message-dislike'
													size='mini'
													active={message.reaction === 'dislike'}
													variant='tertiary'
													iconBefore={<ThumbsDownIcon />}
													onClick={() =>
														!message.reaction &&
														messageReaction(message.id, 'dislike')
													}
												/>
											</div>
										)}
									</ChatBotMessage>
								)
							}
							return (
								<ChatUserMessage key={message.id}>
									<span className='text-body font-body text-default-font shrink-0 grow basis-0'>
										{message.text}
									</span>
								</ChatUserMessage>
							)
						})}
					</div>
				</div>
				{!messages?.length && suggestions?.length && (
					<div className='flex w-full flex-col items-end gap-2 border-t border-solid border-base p-4'>
						<span className='text-xs text-muted'>
							Select from popular questions:
						</span>
						{suggestions.map((suggestion) => (
							<ChatSuggestion key={suggestion} text={suggestion} />
						))}
					</div>
				)}
				{isBusy && (
					<div className='px-4 py-2'>
						<div className='flex w-full items-start gap-2'>
							<div className='flex size-8 shrink-0 grow-0 items-center justify-center rounded-full bg-brand-25 text-brand'>
								<SparkleIcon className='size-4' />
							</div>
							<div className='flex w-full flex-col items-start gap-2 rounded-lg px-3 py-2'>
								<div className='dot-typing' />
							</div>
						</div>
					</div>
				)}
				<div className='flex w-full flex-col items-start gap-8 border-t border-solid border-base bg-accent-3 px-4 py-3'>
					<FormProvider
						methods={methods}
						onSubmit={handleSubmit}
						className='flex w-full flex-col items-start gap-2'
					>
						<div className='flex w-full items-center gap-2'>
							<Textfield
								data-testid='chat-message-input'
								disabled={isBusy}
								autoComplete='off'
								autoCorrect='off'
								placeholder='Type a message'
								className='shrink-0 grow basis-0'
								{...methods.register('message', {
									required: true,
								})}
							/>
							<Button
								data-testid='chat-message-submit'
								disabled={isBusy}
								iconBefore={<SendIcon />}
								variant='primary-brand'
								type='submit'
							/>
						</div>
					</FormProvider>
				</div>
			</div>
		</Card>
	)
}

function ChatUserMessage({ children }: { children: React.ReactNode }) {
	return (
		<div
			className='flex w-full items-end justify-end gap-2'
			data-testid='chat-user-message'
		>
			<div className='flex flex-col items-start gap-2 rounded-lg bg-accent-4 p-2 text-xs font-medium'>
				{children}
			</div>
		</div>
	)
}

function ChatBotMessage({ children }: { children: React.ReactNode }) {
	return (
		<div
			className='flex w-full items-start gap-2'
			data-testid='chat-bot-message'
		>
			<div className='flex size-8 shrink-0 grow-0 items-center justify-center rounded-full bg-brand-25 text-brand'>
				<SparklesIcon className='size-4' />
			</div>
			<div className='flex flex-col items-start gap-2 rounded-lg bg-accent-3 px-3 py-2 text-xs font-medium'>
				{children}
			</div>
		</div>
	)
}
function ChatSuggestion({ text }: { text: string }) {
	const { sendMessage } = useAssistantConversation()
	return (
		<div
			data-testid='chat-suggestion'
			onClick={() => {
				analytic.logEvent('ai: chat: clicked suggestion', { suggestion: text })
				sendMessage(text)
			}}
			className='cursor-pointer rounded-lg bg-accent-3 p-2 text-xs font-semibold hover:bg-brand-50 hover:text-brand'
		>
			{text}
		</div>
	)
}
