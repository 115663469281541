import { isEqual, map, prop, sortedUniqBy } from 'lodash/fp'
import React, { useMemo, useState } from 'react'

import { Button, Card, Checkbox } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import Popover from '@cmpkit/popover'
import { CheckboxOption, CheckboxSelect, MultiValue } from '@cmpkit/select'

import { DataOption } from '@/common.types'
import intl from '@/locale'

import {
	OptimizationGroupsType,
	SelectedOptimizationGroupType,
} from './helpers'

type OptimizationGroupsDropdownProps = {
	selectedOgs: SelectedOptimizationGroupType[] | null
	handleClear: () => void
	handleApply: (value: SelectedOptimizationGroupType[]) => void
	isDisabled: boolean
	optimizationGroups: OptimizationGroupsType[]
}

export const OptimizationGroupsDropdown = (
	props: OptimizationGroupsDropdownProps
) => {
	const popover = useDisclosure()
	const options = useMemo(() => {
		if (props.optimizationGroups) {
			return props.optimizationGroups.map(({ id, name }) => ({
				value: id,
				label: name,
			}))
		}
		return []
	}, [props.optimizationGroups])
	return (
		<Popover
			onDismiss={popover.close}
			isOpen={popover.isOpen}
			content={() => (
				<Content
					{...props}
					handleClose={popover.close}
					options={[
						{
							value: 'select all',
							label: intl.get('general_select_all').d('Select all'),
						},
						...options,
					]}
					optimizationGropus={props.optimizationGroups}
				/>
			)}
			placement='bottom-end'
			className='relative bottom-2'
		>
			<Button
				onClick={popover.toggle}
				variant='tertiary'
				iconAfter={<CaretDownIcon />}
			>
				{intl.get('opt.summary.select_og').d('Select OG')}{' '}
				{`(${props.selectedOgs?.length || 0})`}
			</Button>
		</Popover>
	)
}

export const Content = ({
	selectedOgs,
	handleClose,
	handleApply,
	options,
	optimizationGropus,
}: {
	selectedOgs: SelectedOptimizationGroupType[] | null
	handleClose: () => void
	handleApply: (value: SelectedOptimizationGroupType[]) => void
	options: DataOption[]
	optimizationGropus: OptimizationGroupsType[]
}) => {
	const [selected, setSelected] = useState<
		SelectedOptimizationGroupType[] | null
	>(selectedOgs)
	const initialSelectedOgs = useMemo(() => selectedOgs, [])
	const values = selected?.map(({ id }) => id) || []
	const isAllSelected = selected?.length === optimizationGropus.length
	/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
	const Option = (props: any) => {
		if (props.data.value === 'select all') {
			return (
				<SelectAllDataOption
					label={props.label}
					handleChange={() =>
						setSelected(
							isAllSelected
								? []
								: optimizationGropus.map(({ id, name }) => ({ id, name }))
						)
					}
					checked={isAllSelected}
					indeterminate={!isAllSelected && !!selected?.length}
				/>
			)
		}
		return <CheckboxOption {...props} />
	}
	const handleChange = (
		optimizationGroups: MultiValue<{ value: string; label: string }>
	) => {
		const selected = optimizationGroups.map(({ value, label }) => ({
			name: label,
			id: value,
		}))
		setSelected(selected)
	}
	const isButtonsDisabled =
		isEqual(
			map(prop('id'), sortedUniqBy(prop('id'), selected)),
			map(prop('id'), sortedUniqBy(prop('id'), initialSelectedOgs))
		) || !selected?.length
	return (
		<Card className={'w-80 border text-xs shadow'}>
			<CheckboxSelect
				className={'cmp-border-bottom'}
				styles={selectStyles}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				menuIsOpen
				components={{
					DropdownIndicator,
					Control,
					Option,
				}}
				isClearable={false}
				isMulti
				options={options}
				value={options.filter(({ value }: { value: string }) =>
					values?.includes(value)
				)}
				placeholder={intl.get('general_search').d('Search')}
				tabSelectsValue={false}
				onChange={handleChange}
			/>
			<div className='flex justify-end space-x-2 bg-accent-2 px-4 py-2'>
				<Button size='small' onClick={handleClose}>
					{intl.get('general_cancel')}
				</Button>
				<Button
					disabled={isButtonsDisabled}
					variant={'primary-brand'}
					size='small'
					onClick={() => {
						handleApply(selected!)
						handleClose()
					}}
				>
					{intl.get('general_apply')}
				</Button>
			</div>
		</Card>
	)
}
const Control = ({
	children,
	innerProps,
	innerRef,
}: {
	children: React.ReactNode
	innerProps: object
	innerRef: React.Ref<HTMLDivElement>
}) => (
	<div
		ref={innerRef}
		className='cmp-border-bottom flex cursor-text p-1'
		{...innerProps}
	>
		{children}
	</div>
)
const selectStyles = {
	control: (provided: object) => ({ ...provided, margin: 8 }),
	menu: () => ({
		position: 'relative',
		'font-size': '12px',
	}),
} as any //eslint-disable-line

const DropdownIndicator = () => <SearchIcon className='mr-1' />
const SelectAllDataOption = ({
	handleChange,
	checked,
	indeterminate,
	label,
}: {
	handleChange: () => void
	indeterminate: boolean
	checked: boolean
	label: string
}) => (
	<div className='px-3 py-2'>
		<label className='flex items-center space-x-2'>
			<Checkbox
				indeterminate={indeterminate}
				checked={checked}
				name={label}
				onChange={handleChange}
			/>
			<span className='select-none font-medium'>{label}</span>
		</label>
	</div>
)
