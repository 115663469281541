import React, { forwardRef } from 'react'

import {
	autoUpdate,
	detectOverflow,
	flip,
	FloatingFocusManager,
	FloatingPortal,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useId,
	useInteractions,
	useRole,
} from '@floating-ui/react'

import { Panel } from '@cmpkit/base'
import { useControlled } from '@cmpkit/hooks'

type PopupProps = {
	innerRef?: React.Ref<HTMLDivElement>
	isOpen?: boolean
	onOpen?: () => void
	onClose?: () => void
	allowClose?: boolean
	target: (props: {
		ref: React.Ref<HTMLButtonElement>
		onClick: () => void
		onKeyDown: () => void
		isOpen: boolean
	}) => React.ReactNode
	children: React.ReactNode | ((renderContextProps: object) => React.ReactNode)
}

export default function Popup({
	isOpen,
	onOpen,
	onClose,
	allowClose = true,
	target,
	children,
}: PopupProps) {
	const [open, setOpen] = useControlled({
		controlledValue: isOpen,
		defaultValue: false,
	})

	const { refs, floatingStyles, context } = useFloating({
		placement: 'bottom-start',
		open,
		onOpenChange: (isOpen) => {
			if (!isOpen && onClose) {
				onClose()
			}
			if (isOpen && onOpen) {
				onOpen()
			}
			setOpen(isOpen)
		},
		middleware: [
			offset(10),
			flip({ fallbackAxisSideDirection: 'end' }),
			shift(),
			closeOnScrollMiddleware(),
		],
		whileElementsMounted: autoUpdate,
	})

	const click = useClick(context)
	const dismiss = useDismiss(context, {
		enabled: allowClose,
	})
	const role = useRole(context)

	const { getReferenceProps, getFloatingProps } = useInteractions([
		click,
		dismiss,
		role,
	])

	const headingId = useId()

	return (
		<>
			{/* eslint-disable-next-line */}
			{target({ ref: refs.setReference, ...(getReferenceProps() as any) })}
			{open && (
				<FloatingPortal>
					<FloatingFocusManager context={context} modal={false}>
						<div
							ref={refs.setFloating}
							style={{ ...floatingStyles, zIndex: 1000 }}
							aria-labelledby={headingId}
							{...getFloatingProps()}
						>
							<Panel className='cmp-border overflow-hidden'>
								{typeof children === 'function' ? children({}) : children}
							</Panel>
						</div>
					</FloatingFocusManager>
				</FloatingPortal>
			)}
		</>
	)
}

export const DialogInner = forwardRef(
	(
		props: React.HTMLAttributes<HTMLDivElement>,
		ref: React.Ref<HTMLDivElement>
	) => <div ref={ref} {...props} />
)

const closeOnScrollMiddleware = () => {
	return {
		name: 'closeOnScroll',
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async fn(state: any) {
			const overflow = await detectOverflow(state, {
				boundary: document.body,
			})
			if (overflow.top >= 0) {
				return {
					y: overflow.top >= 0 ? 0 : overflow.top,
				}
			}
			return {}
		},
	}
}
