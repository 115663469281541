import { useMemo } from 'react'

import { Row, RowData } from '@tanstack/react-table'

import { TableInstance } from '../types'
import { getRows } from '../utils/rows.utils'

export const useRows = <TData extends RowData>(
	table: TableInstance<TData>
): Row<TData>[] => {
	const {
		getRowModel,
		getState,
		options: { data },
	} = table
	const { expanded, rowPinning, sorting } = getState()

	const rows = useMemo(
		() => getRows(table),
		[data, expanded, getRowModel().rows, rowPinning, sorting]
	)

	return rows
}
