import clsx from 'clsx'
import { filter, find, map, orderBy, pipe, prop, take } from 'lodash/fp'
import React from 'react'
import { Link } from 'react-router-dom'

import { Badge, linkButtonVariants } from '@cmpkit/base'

import { AlertGroupBy } from '@/generated'
import intl from '@/locale'
import {
	useOptimizationGroupsQuery,
	usePricingAlertsQuery,
} from '@/modules/core/queries'
import {
	AlertLevel,
	AlertsInfoResponseModel,
	OptimizationGroupModel,
} from '@/modules/core/types'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'

import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'

const getOprimizationGroupsErrors = (
	groups: OptimizationGroupModel[],
	alerts: AlertsInfoResponseModel
): {
	id: string
	name: string
	count: number
	ids: string[]
}[] => {
	const mapGroup = (group: OptimizationGroupModel) => {
		const alertTotal = find(
			{ level: AlertLevel.Critical, optimization_group_id: group.id },
			alerts?.by_level
		)
		const alertItems = filter(
			{ level: AlertLevel.Critical, optimization_group_id: group.id },
			alerts?.by_id
		)
		return {
			id: group.id,
			name: group.name,
			count: alertTotal?.products || 0,
			ids: alertItems?.map(prop('id')) || [],
		}
	}
	return pipe([map(mapGroup), orderBy(['count'], ['desc']), take(7)])(groups)
}
const trackEvent = () =>
	analytic.logEvent('dashboard: bi: proceed to product warnings')
export default function PriceChangeSegmented() {
	const optimizationGroupsQuery = useOptimizationGroupsQuery()
	const alertsQuery = usePricingAlertsQuery({
		group_by: AlertGroupBy.OptimizationGroupId,
	})
	const list = getOprimizationGroupsErrors(
		optimizationGroupsQuery.data!,
		alertsQuery.data!
	)
	return (
		<Widget className='relative flex-auto'>
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.top_og_errors.title')}
				tooltip={intl.get('widget.top_og_errors.tooltip')}
				subtitle={intl.get('widget.top_og_errors.subtitle')}
			/>
			<WidgetLoadingState
				isTinted={optimizationGroupsQuery.isLoading || alertsQuery.isLoading}
			/>
			<WidgetErrorState
				isTinted={optimizationGroupsQuery.isError || alertsQuery.isError}
			/>
			<WidgetContent className='mt-auto pt-2'>
				<div className='space-y-2'>
					{list?.map(({ id, name, count, ids }) => {
						return (
							<div
								key={id}
								className='flex w-full items-center justify-between gap-2 truncate'
							>
								<Link
									onClick={trackEvent}
									to={`/p/og/${id}/products?alerts__array_any_of=${ids.join(',')}`}
									className={clsx(
										linkButtonVariants({ variant: 'brand' }),
										'!block truncate'
									)}
								>
									{name}
								</Link>

								<Badge className='shrink-0' variant='danger'>
									{formatNumber(count)}
								</Badge>
							</div>
						)
					})}
				</div>
			</WidgetContent>
		</Widget>
	)
}
