import { find, prop } from 'lodash/fp'

import BusinessMetrics from './BusinessMetrics'
import HistoricalPriceAcceptance from './HistoricalPriceAcceptance'
import MetricPerfomance from './MetricPerfomance'
import MetricsByBrand from './MetricsByBrand'
import MetricsByOptimizationGroup from './MetricsByOptimizationGroup'
import MetricsByPricingCampaign from './MetricsByPricingCampaign'
import MetricToCompare from './MetricToCompare'
import NumericAvgPriceChange from './NumericAvgPriceChange'
import NumericBlockedForRepricing from './NumericBlockedForRepricing'
import NumericGrossProfit from './NumericGrossProfit'
import NumericItemsIntegrated from './NumericItemsIntegrated'
import NumericPriceAcceptance from './NumericPriceAcceptance'
import NumericPricesRecommended from './NumericPricesRecommended'
import NumericReadyForOptimization from './NumericReadyForOptimization'
import NumericRevenue from './NumericRevenue'
import NumericRevenueUnderManagement from './NumericRevenueUnderManagement'
import NumericSalesItems from './NumericSalesItems'
import NumericUnassignedItems from './NumericUassignedItems'
import OptimizationGroupsErrors from './OptimizationGroupsErrors'
import OptimizationGroupsStatus from './OptimizationGroupsStatus'
import OptimizationHistoryDonut from './OptimizationHistoryDonut'
import OptimizationHistoryTable from './OptimizationHistoryTable'
import PriceChangeSegmented from './PriceChangeSegmented'
import ReadyForOprimization from './ReadyForOptimization'
import RepricedByPricingEngine from './RepricedByPricingEngine'
import RevenueUnderManagementByEngine from './RevenueUnderManagementByEngine'
import RevenueUnderManagementByPriceChange from './RevenueUnderManagementByPriceChange'
import SalesFactorsImpact from './SalesFactorsImpact'
import SalesFactorsImpactByCategories from './SalesFactorsImpactByCategories'
import SalesFactorsImpactDynamic from './SalesFactorsImpactDynamic'
import { WidgetSchema } from './types'

export const WIDGETS = [
	BusinessMetrics,
	MetricsByPricingCampaign,
	RevenueUnderManagementByPriceChange,
	RevenueUnderManagementByEngine,
	PriceChangeSegmented,
	NumericGrossProfit,
	NumericRevenue,
	NumericSalesItems,
	MetricPerfomance,
	MetricToCompare,
	MetricsByBrand,
	MetricsByOptimizationGroup,
	OptimizationGroupsErrors,
	OptimizationGroupsStatus,
	NumericUnassignedItems,
	NumericItemsIntegrated,
	NumericPriceAcceptance,
	HistoricalPriceAcceptance,
	SalesFactorsImpact,
	SalesFactorsImpactDynamic,
	SalesFactorsImpactByCategories,
	OptimizationHistoryDonut,
	OptimizationHistoryTable,
	NumericReadyForOptimization,
	ReadyForOprimization,
	NumericPricesRecommended,
	NumericAvgPriceChange,
	NumericBlockedForRepricing,
	NumericRevenueUnderManagement,
	RepricedByPricingEngine,
]
export {
	BusinessMetrics,
	MetricsByPricingCampaign,
	RevenueUnderManagementByPriceChange,
	RevenueUnderManagementByEngine,
	PriceChangeSegmented,
	NumericGrossProfit,
	NumericRevenue,
	NumericSalesItems,
	MetricPerfomance,
	MetricToCompare,
	MetricsByBrand,
	MetricsByOptimizationGroup,
	OptimizationGroupsErrors,
	OptimizationGroupsStatus,
	NumericUnassignedItems,
	NumericItemsIntegrated,
	NumericPriceAcceptance,
	HistoricalPriceAcceptance,
	SalesFactorsImpact,
	SalesFactorsImpactDynamic,
	SalesFactorsImpactByCategories,
	OptimizationHistoryDonut,
	OptimizationHistoryTable,
	NumericReadyForOptimization,
	ReadyForOprimization,
	NumericPricesRecommended,
	NumericAvgPriceChange,
	NumericBlockedForRepricing,
	NumericRevenueUnderManagement,
	RepricedByPricingEngine,
}
/**
 * Returns specifieldfic property of field config
 * @param property - The field config prop to retrieve
 * @param kind - The field type to get the config from
 * @returns The field config prop value
 */
export const getWidgetProp = (
	property: keyof WidgetSchema,
	kind: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
	return prop(property, getWidget(kind))
}

/**
 * Returns field config
 * @param kind - The field type to get the config from
 * @returns The field config
 */
export const getWidget = (kind: string): WidgetSchema => {
	return find({ kind }, WIDGETS)!
}
