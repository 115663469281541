import intl from '@/locale'

export const getAnchoringOnConflictChoices = () => [
	{
		label: intl.get('anchoring.on_conflict.ignore').d('Ignore'),
		value: 'ignore',
	},
	{
		label: intl
			.get('anchoring.on_conflict.use_anchor_price')
			.d('Use anchor price as priority'),
		value: 'use_anchor_price',
	},
]
export const getAnchoringBehaviorChoices = () => [
	{
		label: intl.get('behavior.highlight').d('Just highlight'),
		value: 'highlight',
	},
	{
		label: intl.get('behavior.correct').d('Correct to boundary'),
		value: 'correct',
	},
]
export const getAnchoringTypeChoices = () => [
	{
		label: intl.get('anchoring.flat_anchor').d('Flat anchor'),
		value: 'flat_anchor',
		variant: 'info',
	},
	{
		label: intl.get('anchoring.sized_anchor').d('Sized anchor'),
		value: 'sized_anchor',
		variant: 'danger',
	},
	{
		label: intl.get('anchoring.product_set').d('Product set'),
		value: 'product_set',
		variant: 'warning',
	},
	{
		label: intl.get('anchoring.ladder_anchor').d('Ladder'),
		value: 'ladder_anchor',
		variant: 'success',
	},
	{
		label: intl.get('anchoring.bundle_anchor').d('Bundle'),
		value: 'bundle_anchor',
		variant: 'epic',
	},
]
