import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { SendOptimizationActionsRequestModel } from '@/generated'
import { client } from '@/network/client'

import { OptimizationModel } from '../core/types'

export function useBulkOptimizationMutation(
	options?: UseMutationOptions<
		{
			results?:
				| Record<
						string,
						{
							optimization?: OptimizationModel | undefined
							success?: boolean | undefined
						}
				  >
				| undefined
			success?: boolean | undefined
		},
		Error,
		SendOptimizationActionsRequestModel
	>
) {
	return useMutation<
		{
			results?:
				| Record<
						string,
						{
							optimization?: OptimizationModel | undefined
							success?: boolean | undefined
						}
				  >
				| undefined
			success?: boolean | undefined
		},
		Error,
		SendOptimizationActionsRequestModel
	>({
		mutationFn: (payload) => {
			return client.core.sendOptimizationActions(payload)
		},
		...(options || {}),
	})
}
