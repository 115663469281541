import { Row, RowData } from '@tanstack/react-table'
import { TableInstance } from '../types'

export const getRows = <TData extends RowData>(
	table: TableInstance<TData>,
	all?: boolean
): Row<TData>[] => {
	const {
		getCenterRows,
		getPrePaginationRowModel,
		getRowModel,
		getTopRows,
		options: { enableRowPinning },
	} = table
	const rowPinningDisplayMode = 'sticky'
	let rows: Row<TData>[] =
		!enableRowPinning || rowPinningDisplayMode?.includes('sticky')
			? all
				? getPrePaginationRowModel().rows
				: getRowModel().rows
			: getCenterRows()

	// row pinning adjustments
	if (enableRowPinning && rowPinningDisplayMode?.includes('sticky')) {
		const centerPinnedRowIds = rows
			.filter((row) => row.getIsPinned())
			.map((r) => r.id)

		rows = [
			...getTopRows().filter((row) => !centerPinnedRowIds.includes(row.id)),
			...rows,
		]
	}

	return rows
}
