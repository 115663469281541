import { JSONSchemaType } from 'ajv'
import clsx from 'clsx'
import { any, isEmpty, pipe, prop } from 'lodash/fp'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate } from 'react-router'

import { ajvResolver } from '@hookform/resolvers/ajv'
import { useQueryClient } from '@tanstack/react-query'

import {
	Button,
	Content,
	Footer,
	InlineMessage,
	Layout,
	Loader,
	TreeNodeType,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'

import CollapsibleSection from '@/components/CollapsibleSection'
import { toAdaptedSchema } from '@/components/filter/adapter'
import { FilterField } from '@/components/filter/types'
import {
	FormProvider,
	RHFSelect,
	RHFTextArea,
	RHFTextField,
} from '@/components/HookForm'
import RHFSettingsWidget from '@/components/HookForm/RHFSettingsWidget'
import RHFStoplistCustomRules from '@/components/HookForm/RHFStoplistCustomRules'
import NavigationPrompt from '@/components/NavigationPrompt'
import {
	ColumnSchemaModel,
	ScenarioSettingsModel,
	SettingsTemplateType,
} from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import {
	useBrandsQuery,
	useCategoriesQuery,
	useColumnsSchemaQuery,
} from '@/modules/core/queries'
import { toCategoriesTree } from '@/modules/core/utils'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import { SchemaParts, SettigSchemaModel } from '@/modules/og-settings/types'
import analytic from '@/services/analytics'
import {
	getEnumOptions,
	getResolvedSchema,
	translateOptions,
	translateSchemaKeys,
} from '@/tools/json-schema-utils'

import { useUpdateScenarioMutation } from '../../mutations'
import { useScenarioQuery } from '../../queries'
import ScenarioPreloader from './ScenarioPreloader'
import ScenarioPricingCampaigns from './ScenarioPricingCampaigns'
import SettingActionsDropdown from './SettingsActionsDropdown'
import { useSettingActions } from '../../hooks/useSettingsActions'

type ScenarioFormProps = {
	scenarioId: string
}

const categoriesQueryConfig = {
	select: toCategoriesTree,
}
const columnsSchemaQueryConfig = {
	select: (data: ColumnSchemaModel[]) =>
		data
			.filter(({ stage }) =>
				['prepro', 'pre_init_fields'].includes(stage as string)
			)
			.map(toAdaptedSchema),
}

export default function ScenarioForm({ scenarioId }: ScenarioFormProps) {
	/**
	 * Common hooks for scenario form
	 */
	const optimizationGroupId = useOptimizationGroupId()
	const queryClient = useQueryClient()
	const actions = useSettingActions({
		handleUseTemplate: handleUseTemplate,
	})

	/**
	 * Queries for scenario form
	 */
	const { data: fields, ...fieldsQuery } = useColumnsSchemaQuery<FilterField[]>(
		columnsSchemaQueryConfig
	)
	const { data: brands, ...brandsQuery } = useBrandsQuery(
		optimizationGroupId ? { optimization_group_id: optimizationGroupId } : {}
	)
	const { data: categories, ...categoriesQuery } = useCategoriesQuery<
		TreeNodeType[]
	>({ optimization_group_id: optimizationGroupId }, categoriesQueryConfig)

	const scenarioQuery = useScenarioQuery(scenarioId)
	const settingsSchemaQuery = useSettingsSchemasQuery<
		JSONSchemaType<SettigSchemaModel>
	>({
		select: pipe([
			getResolvedSchema(SchemaParts.EntityScenario),
			translateSchemaKeys,
		]),
	})

	/**
	 * Mutations for scenario form
	 */
	const updateScenarioMutation = useUpdateScenarioMutation(scenarioId, {
		onSuccess: () =>
			Promise.all([
				scenarioQuery.refetch(),
				queryClient.invalidateQueries({
					queryKey: ['scenarios'],
				}),
			]),
	})

	const settingsSchema = settingsSchemaQuery.data
	const scenario = scenarioQuery.data

	const isLoading = any(prop('isLoading'), [
		scenarioQuery,
		settingsSchemaQuery,
		fieldsQuery,
		brandsQuery,
		categoriesQuery,
	])
	const isUpdating = any(prop('isPending'), [updateScenarioMutation])

	useEffect(() => {
		methods.reset(scenario)
	}, [scenario])

	const methods = useForm({
		defaultValues: scenario ?? undefined,
		resolver: ajvResolver(settingsSchema!, {
			strict: false,
		}),
	})
	if (!isEmpty(methods.formState.errors)) {
		console.error(methods.formState.errors) // eslint-disable-line
	}

	/**
	 * Handlers for scenario form
	 */
	function handleUseTemplate({
		settings,
		templateType,
	}: {
		settings: ScenarioSettingsModel
		templateType: SettingsTemplateType
	}) {
		const path = getPathByType(templateType)
		methods.setValue(`settings.${path}`, settings[path], {
			shouldDirty: true,
		})
	}
	const handleResetForm = () => {
		analytic.logEvent('what-if: scenario: reset form')
		methods.reset()
	}
	const _handleSubmit = methods.handleSubmit((data) => {
		analytic.logEvent('what-if: scenario: save changes')
		updateScenarioMutation.mutate(data)
	})

	if (isLoading) {
		return <ScenarioPreloader />
	}

	if (!scenario) {
		return <Navigate to='.' />
	}
	const isMain = scenario?.is_main
	const strategyWatcher = methods.watch('settings.strategy')
	const stopListWatcher = methods.watch('settings.stop_list')
	const businessConstraintsWatcher = methods.watch('settings.business')
	return (
		<FormProvider
			methods={methods}
			onSubmit={_handleSubmit}
			className='fade-in size-full overflow-hidden'
		>
			<NavigationPrompt
				when={methods.formState.isDirty}
				title={intl.get('navigation.blocked.scenario_settings.title')}
				message={intl.getHTML('navigation.blocked.scenario_settings.message')}
				okText={intl.get('navigation.blocked.scenario_settings.ok')}
				cancelText={intl.get('navigation.blocked.scenario_settings.cancel')}
			/>

			<Layout className='size-full overflow-y-auto'>
				<Blanket
					className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-sm dark:bg-black/50'
					isTinted={isUpdating}
				>
					<Loader />
				</Blanket>
				<Content className='relative flex-col px-6 py-3'>
					{isMain && (
						<div>
							<InlineMessage
								variant='brand'
								icon={<InfoIcon />}
								className='mb-2 mr-20 text-xs font-medium'
							>
								{intl.get('scenario.read_only.text')}
							</InlineMessage>
						</div>
					)}
					<fieldset className='contents' disabled={isMain}>
						<div
							className={clsx({
								'opacity-50': isMain,
							})}
						>
							{scenario?.is_main ? (
								<>
									<h2>
										{intl
											.get('scenario.form.title_default')
											.d('Scenario settings (Main)')}
									</h2>
									<p className='section-subtitle mb-3'>
										{intl.get('scenario.form.subtitle_default')}
									</p>
								</>
							) : (
								<>
									<h2>
										{intl.get('scenario.form.title').d('Scenario settings')}
									</h2>
									<p className='section-subtitle mb-3'>
										{intl.get('scenario.form.subtitle')}
									</p>
								</>
							)}

							<div className='flex flex-col'>
								{/* Genral scenario inputs */}

								<RHFTextField
									placeholder={intl.get('scenario.name.placeholder')}
									autoComplete='off'
									required
									name='name'
									label={intl.get('scenario.name').d('Name')}
								/>
								<RHFTextArea
									placeholder={intl.get('scenario.description.placeholder')}
									rows={3}
									name='description'
									label={intl.get('scenario.description').d('Description')}
								/>

								{/* Scenario demand strategy settings */}
								<CollapsibleSection
									className='pb-4 pt-1.5'
									header={
										<div className='flex w-full items-center justify-between'>
											<h4>
												{intl
													.get('settings.strategy.long')
													.d('Pricing strategy')}
											</h4>
											{!isMain && (
												<SettingActionsDropdown
													actions={actions}
													templateType={SettingsTemplateType.ScenarioStrategy}
													settingName={scenario.name}
													settings={{
														strategy: strategyWatcher,
													}}
												>
													<Button
														data-testid={'pc-actions-popover-trigger'}
														variant='tertiary'
														className='w-xs ml-2'
														iconBefore={<DotsFilledIcon />}
													/>
												</SettingActionsDropdown>
											)}
										</div>
									}
									subtitle={intl.get('scenario.form.demand_strategy.subtitle')}
									isOpen={!isMain}
								>
									<div>
										{methods.formState.errors?.settings?.strategy?.root
											?.message && (
											<InlineMessage variant='danger' className='mb-3 text-xs'>
												{
													methods.formState.errors?.settings?.strategy?.root
														?.message
												}
											</InlineMessage>
										)}
										<RHFSelect
											isDisabled={isMain}
											name='settings.strategy.demand_strategy.target'
											className='w-56'
											label={prop(
												'properties.settings.properties.strategy.properties.demand_strategy.properties.target.title',
												settingsSchema
											)}
											description={prop(
												'properties.settings.properties.strategy.properties.demand_strategy.properties.target.description',
												settingsSchema
											)}
											options={translateOptions(
												getEnumOptions(
													prop(
														'properties.settings.properties.strategy.properties.demand_strategy.properties.target',
														settingsSchema
													)
												)
											)}
										/>
										<RHFSelect
											isDisabled={isMain}
											name='settings.strategy.demand_strategy.protect'
											className='w-56'
											label={prop(
												'properties.settings.properties.strategy.properties.demand_strategy.properties.protect.title',
												settingsSchema
											)}
											description={prop(
												'properties.settings.properties.strategy.properties.demand_strategy.properties.protect.description',
												settingsSchema
											)}
											options={translateOptions(
												getEnumOptions(
													prop(
														'properties.settings.properties.strategy.properties.demand_strategy.properties.protect',
														settingsSchema
													)
												)
											)}
										/>
									</div>
								</CollapsibleSection>

								{/* Scenario markdown strategy settings */}
								<CollapsibleSection
									className='pb-4 pt-1.5'
									header={
										<h4>
											{intl.get(
												'optimization_groups_settings_tab_markdown_title'
											)}
										</h4>
									}
									subtitle={intl.get(
										'scenario.form.markdown_strategy.subtitle'
									)}
									isOpen={!isMain}
								>
									<RHFSelect
										isDisabled={isMain}
										name='settings.strategy.markdown_strategy.target'
										className='w-56'
										label={prop(
											'properties.settings.properties.strategy.properties.markdown_strategy.properties.target.title',
											settingsSchema
										)}
										description={prop(
											'properties.settings.properties.strategy.properties.markdown_strategy.properties.target.description',
											settingsSchema
										)}
										options={translateOptions(
											getEnumOptions(
												prop(
													'properties.settings.properties.strategy.properties.markdown_strategy.properties.target',
													settingsSchema
												)
											)
										)}
									/>
								</CollapsibleSection>
								{/* Scenario business settings */}
								<CollapsibleSection
									className='pb-4 pt-1.5'
									header={
										<div className='flex w-full justify-between'>
											<h4>
												{intl
													.get('settings.business.long')
													.d('Business constrains')}
											</h4>
											{!isMain && (
												<SettingActionsDropdown
													actions={actions}
													templateType={
														SettingsTemplateType.ScenarioBusinessConstraints
													}
													settingName={scenario.name}
													settings={{
														business: businessConstraintsWatcher,
													}}
												>
													<Button
														data-testid={'pc-actions-popover-trigger'}
														variant='tertiary'
														className='w-xs ml-2'
														iconBefore={<DotsFilledIcon />}
													/>
												</SettingActionsDropdown>
											)}
										</div>
									}
									subtitle={intl.get(
										'scenario.form.business_settings.subtitle'
									)}
									isOpen={!isMain}
								>
									<div className={'mb-4'}>
										<RHFSettingsWidget
											name='settings.business'
											schema={
												settingsSchema?.properties.settings.properties.business
											}
										/>
									</div>
								</CollapsibleSection>
								{/* Scenario stop list default settings */}
								<CollapsibleSection
									className='pb-4 pt-1.5'
									header={
										<div className='flex w-full justify-between'>
											<h4>{intl.get('stop_list')}</h4>
											{!isMain && (
												<SettingActionsDropdown
													actions={actions}
													templateType={SettingsTemplateType.ScenarioStopList}
													settingName={scenario.name}
													settings={{
														stop_list: stopListWatcher,
													}}
												>
													<Button
														data-testid={'pc-actions-popover-trigger'}
														variant='tertiary'
														className='w-xs ml-2'
														iconBefore={<DotsFilledIcon />}
													/>
												</SettingActionsDropdown>
											)}
										</div>
									}
									subtitle={intl.get('scenario.form.stop_list.subtitle')}
									isOpen={!isMain}
								>
									<div className={'mb-4'}>
										<RHFSettingsWidget
											name={'settings.stop_list.default_rules'}
											schema={
												settingsSchema?.properties.settings.properties.stop_list
													.properties.default_rules
											}
										/>
									</div>
								</CollapsibleSection>
								{/* Scenario stop list custom settings */}
								<CollapsibleSection
									className='pb-4 pt-1.5'
									header={<h4>{intl.get('stop_list.custom_rules.title')}</h4>}
									subtitle={intl.get('stop_list.custom_rules.subtitle')}
									isOpen={!isMain}
								>
									<RHFStoplistCustomRules
										name={'settings.stop_list.custom_rules'}
										fields={fields || []}
										isLoading={isLoading}
										dataChoices={{
											brands: brands || [],
											category_ids: categories || [],
										}}
									/>
								</CollapsibleSection>
								{/* Scenario pricing campaigns */}
								<h4 className='mt-4'>
									{intl.get('scenario.form.pcs.title').d('What-if PCs')}
								</h4>
								<p className='section-subtitle mb-3'>
									{intl.get('scenario.form.pcs.subtitle')}
								</p>
								<ScenarioPricingCampaigns scenario={scenario} />
							</div>
						</div>
					</fieldset>
				</Content>
				{!scenario?.is_main && methods.formState.isDirty && (
					<Footer className='fade-in sticky bottom-0 z-10 justify-end space-x-2 border-t border-solid border-base bg-white/50 px-6 py-3 backdrop-blur-md dark:bg-black/30'>
						<Button onClick={handleResetForm} iconBefore={<UndoIcon />}>
							{intl.get('scenario.reset_settings').d('Reset scenario settings')}
						</Button>
						<Button
							variant='primary-brand'
							onClick={_handleSubmit}
							iconBefore={<CheckIcon />}
						>
							{intl.get('scenario.save_settings').d('Save scenario settings')}
						</Button>
					</Footer>
				)}
			</Layout>
		</FormProvider>
	)
}
const getPathByType = (type: SettingsTemplateType) => {
	switch (type) {
		case SettingsTemplateType.ScenarioBusinessConstraints:
			return 'business'
		case SettingsTemplateType.ScenarioStopList:
			return 'stop_list'
		case SettingsTemplateType.ScenarioStrategy:
			return 'strategy'
		default:
			return 'strategy'
	}
}

/* function getValidationSchema(settingsSchema: Record<string, JSONSchema>) {
	return {
		type: 'object',
		required: ['name'],
		properties: {
			name: {
				type: 'string',
				minLength: 4,
				maxLength: 75,
				errorMessage: {
					minLength: intl.get('validation.min_length', { val: 4 }),
					maxLength: intl.get('validation.max_length', { val: 75 }),
				},
			},
			description: {
				type: 'string',
				maxLength: 500,
				errorMessage: {
					maxLength: intl.get('validation.max_length', { val: 500 }),
				},
			},
			settings: {
				type: 'object',
				properties: {
					business: settingsSchema?.business_settings?.properties?.business,
					stop_list: settingsSchema?.stop_list?.properties?.stop_list,
					strategy: settingsSchema?.strategies?.properties?.strategy,
				},
			},
		},
	}
}
 */
