import React, { useContext } from 'react'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import { Button, Card } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import Popover from '@cmpkit/popover'
import Select, { SingleValue, StylesConfig } from '@cmpkit/select'

import { DataOption } from '@/common.types'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'
import { useScenariosQuery } from '@/modules/scenarios/queries'
import analytic from '@/services/analytics'
import { processingMessage, successActionMessage } from '@/tools/message'

import { useDublicatePricingCampaignsToScenarioMutation } from '../mutations'
import { PricingCampaignsContext } from './Campaigns/PricingCampaignsProvider'

const selectStyles = {
	control: (provided: object) => ({ ...provided, margin: 8 }),
	menu: () => ({
		position: 'relative',
	}),
} as StylesConfig<DataOption>
const Control = ({
	children,
	innerProps,
	innerRef,
}: {
	children: React.ReactNode
	innerProps: object
	innerRef: React.Ref<HTMLDivElement>
}) => (
	<div ref={innerRef} className='flex border-b p-1' {...innerProps}>
		{children}
	</div>
)
const DropdownIndicator = () => <SearchIcon className='mr-1' />

export default function AssignToScenarioDropdown({
	optimizationGroupId,
}: {
	optimizationGroupId: string
}) {
	const { selected, setSelected } = useContext(PricingCampaignsContext)
	const { showModal } = useModalStore()
	const queryClient = useQueryClient()

	const popover = useDisclosure()

	const dublicate = useDublicatePricingCampaignsToScenarioMutation({
		onMutate() {
			toast.loading(processingMessage(), {
				id: 'scenario-copy-pcs',
			})
			popover.close()
			setSelected([])
		},
		onSuccess() {
			toast.success(successActionMessage('Pricing campaign(s)', 'copied'), {
				duration: 3000,
				id: 'scenario-copy-pcs',
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
		},
	})
	const scenariosQuery = useScenariosQuery(optimizationGroupId)

	const content = (
		<Card className='min-w-72 border pt-0 shadow'>
			<Select<DataOption, false>
				className='min-h-52 min-w-72'
				options={scenariosQuery.data
					?.filter((scenario) => !scenario.is_main)
					?.map((scenario) => ({
						label: scenario.name,
						value: scenario.id,
					}))}
				autoFocus
				backspaceRemovesValue={false}
				components={{
					IndicatorSeparator: null,
					DropdownIndicator,
					Control,
				}}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				isClearable={false}
				menuIsOpen
				styles={selectStyles}
				placeholder={intl.get('general_select').d('Select')}
				tabSelectsValue={false}
				onChange={(data: SingleValue<DataOption>) => {
					analytic.logEvent('what-if: add pcs to scenario', {
						pcs: selected.length,
					})
					data?.value &&
						dublicate.mutate({
							src_pricing_campaign_ids: selected,
							dst_scenario_ids: [data?.value],
						})
				}}
			/>
			<div className='flex justify-end border-t bg-accent-3 px-4 py-2'>
				<Button
					size='small'
					iconBefore={<PlusIcon />}
					onClick={() => {
						analytic.logEvent('what-if: create scenario: modal: open', {
							location: 'pricing_campaigns_list',
						})
						showModal('CREATE_SCENARIO', {
							pricingCampaignsIds: selected,
						})
						popover.close()
					}}
				>
					{intl.get('general_create')}
				</Button>
			</div>
		</Card>
	)
	return (
		<Popover
			onDismiss={popover.close}
			isOpen={popover.isOpen}
			content={content}
			placement='bottom-start'
		>
			<Button
				onClick={popover.toggle}
				iconAfter={<CaretDownIcon className='shrink-0' />}
			>
				{intl.get('pc.action.add_to_scenario').d('Add to what-if...')}
			</Button>
		</Popover>
	)
}
