import { ChevronsUpDownIcon } from 'lucide-react'
import React from 'react'

import { Card, MenuHeader, MenuItem, MenuList } from '@cmpkit/base'
import CompeteraIcon from '@cmpkit/icon/glyph/cmp-logo'
import ProductCdIcon from '@cmpkit/icon/glyph/product-cd'
import Popover from '@cmpkit/popover'
import {
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
} from '@cmpkit/sidebar'

import intl from '@/locale'
import { useAuth } from '@/modules/Auth/AuthContext'

export const getProjectName = () =>
	window.location.hostname.split('.')[0].replace('-workspace', '')
const project = getProjectName()
const isDev = project.includes('dev-')

export default function AppSidebarHeader() {
	return (
		<SidebarHeader>
			<SidebarMenu>
				<Popover
					placement='bottom-start'
					content={<AppSidebarHeaderDropdown />}
				>
					<SidebarMenuItem>
						<SidebarMenuButton size='lg' className='border-none'>
							<div className='flex aspect-square size-8 cursor-pointer items-center justify-center rounded-lg bg-sidebar-primary p-1.5 text-sidebar-primary-foreground'>
								<svg
									width='338'
									height='338'
									viewBox='0 0 338 338'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M185.782 35.6005C188.654 55.185 175.105 73.3892 155.521 76.2607C110.964 82.7936 76.5 121.442 76.5 168.233C76.5 219.739 118.023 261.225 169.005 261.225C219.986 261.225 261.509 219.739 261.509 168.233C261.509 148.439 277.555 132.393 297.349 132.393C317.143 132.393 333.189 148.439 333.189 168.233C333.189 259.071 259.829 332.905 169.005 332.905C78.1801 332.905 4.81995 259.071 4.81995 168.233C4.81995 85.4946 65.7438 16.9775 145.122 5.33895C164.707 2.46744 182.911 16.016 185.782 35.6005Z'
										fill='#fff'
									/>
									<path
										d='M252.433 111.896C274.919 111.896 293.147 93.5985 293.147 71.0278C293.147 48.4571 274.919 30.1599 252.433 30.1599C229.948 30.1599 211.72 48.4571 211.72 71.0278C211.72 93.5985 229.948 111.896 252.433 111.896Z'
										fill='#fff'
									/>
								</svg>
							</div>
							<div className='grid flex-1 text-left text-sm leading-tight'>
								<span className='truncate font-semibold'>Pricing platform</span>
							</div>
							<ChevronsUpDownIcon className='ml-auto' />
						</SidebarMenuButton>
					</SidebarMenuItem>
				</Popover>
			</SidebarMenu>
		</SidebarHeader>
	)
}

export function AppSidebarHeaderDropdown() {
	return (
		<Card className='w-60 border py-2 shadow'>
			<CompeteraApps />
		</Card>
	)
}

const getAppData = (name: string) => {
	switch (name) {
		case 'workspace':
			return {
				label: 'Workspace',
				href: `https://${project}-workspace.competera.net`,
				icon: (
					<div className='flex size-8 items-center justify-center rounded-lg bg-[#142a45]'>
						<CompeteraIcon className='size-4 fill-white' />
					</div>
				),
			}
		case 'competitive_data':
			return {
				label: 'Competitive data',
				href: `https://${project.replace('dev-', '')}${
					isDev ? '-cd2-stg' : '-dashboard'
				}.competera.net/accounts/keycloak/login/`,
				icon: (
					<div className='flex size-8 items-center justify-center rounded-lg bg-success'>
						<ProductCdIcon className='size-4 text-white' />
					</div>
				),
			}
		default:
			return null
	}
}
function CompeteraApps() {
	const { user } = useAuth()
	return (
		<MenuList>
			<MenuHeader>{intl.get('switch_to').d('Switch to')}</MenuHeader>
			{user?.apps?.map((app) => {
				if (app.name === 'pricing_platform') return null
				const appData = getAppData(app.name)
				return (
					<MenuItem
						disabled={!app.enabled}
						key={app.name}
						className='select-none pl-2 font-bold'
						onClick={
							app.enabled
								? () => window.open(appData!.href, '_blank')
								: undefined
						}
					>
						{appData?.icon}
						{appData?.label}
					</MenuItem>
				)
			})}
		</MenuList>
	)
}
