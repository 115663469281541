import { any, prop } from 'lodash/fp'
import React from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'

import { Button, InlineMessage, Loader } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import {
	FormProvider,
	RHFCheckbox,
	RHFTextArea,
	RHFTextField,
} from '@/components/HookForm'
import { FilterRuleModel } from '@/generated'
import intl from '@/locale'
import { useAuthorization } from '@/modules/Auth/authorization'
import analytic from '@/services/analytics'
import { actSaved, errorMessage, successActionMessage } from '@/tools/message'

import {
	useCreateGlobalFilterMutation,
	useCreatePersonalFilterMutation,
} from '../mutations'

type SaveFiltersModalProps = {
	query: FilterRuleModel[]
	isOpen: boolean
	close(): void
}
const FormDataSchema = Yup.object().shape({
	name: Yup.string().min(3).max(500).required('Name is required'),
	description: Yup.string().max(500),
	is_global: Yup.boolean(),
})
type FormData = Yup.InferType<typeof FormDataSchema>

export default function SaveFiltersModal({
	isOpen,
	close,
	query,
}: SaveFiltersModalProps) {
	const queryClient = useQueryClient()
	const { checkPermissons } = useAuthorization()
	const canManageGlobalFilters = checkPermissons(['MANAGE_GLOBAL_FILTERS'])
	const methods = useForm({
		resolver: yupResolver<FormData>(FormDataSchema),
	})
	/**
	 * Mutations
	 */
	const createGlobalFilter = useCreateGlobalFilterMutation({
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['global-filters'],
			}),
	})
	const createPersonalFilter = useCreatePersonalFilterMutation({
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['personal-filters'],
			}),
	})
	const isPending = any(prop('isPending'), [
		createGlobalFilter,
		createPersonalFilter,
	])

	const onSubmit = async (data: FormData) => {
		if (isPending || !canManageGlobalFilters) return
		const isGlobal = data.is_global
		const body = {
			name: data.name,
			params: {
				description: data.description,
				query,
			},
		}
		try {
			if (isGlobal) {
				analytic.logEvent('table: save filter', {
					'Saved filter type': 'Global',
				})
				await createGlobalFilter.mutateAsync(body)
			} else {
				analytic.logEvent('table: save filter', {
					'Saved filter type': 'Personal',
				})
				await createPersonalFilter.mutateAsync(body)
				toast.success(successActionMessage(intl.get('app.filters'), actSaved()))
			}
		} catch (error) {
			toast.error(errorMessage())
		} finally {
			close()
		}
	}
	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={close} showCloseButton size='mini' zIndex={1850}>
					<ModalHeader>
						<ModalTitle>{intl.get('global_filter_create')}</ModalTitle>
					</ModalHeader>
					<ModalBody className='flex flex-col'>
						<FormProvider
							methods={methods}
							onSubmit={methods.handleSubmit(onSubmit)}
						>
							<Blanket
								className='absolute flex items-center justify-center bg-white/50 backdrop-blur-sm dark:bg-black/50'
								isTinted={isPending}
							>
								<Loader />
							</Blanket>
							<RHFTextField
								required
								className='w-full'
								name='name'
								label={intl.get('general_name')}
								placeholder={intl
									.get('filter.input.name.placeholder')
									.d('Enter a filter name')}
							/>
							<RHFTextArea
								name='description'
								label={intl.get('general_description')}
								placeholder={intl
									.get('filter.input.description.placeholder')
									.d('Enter a filter description')}
							/>
							<RHFCheckbox
								name='is_global'
								label={intl.get('general_global_filter')}
							/>
							<InlineMessage variant='brand' icon={<InfoIcon />}>
								{intl
									.get('filter.input.is_global.hint')
									.d(
										'Making saved filter public will make it available for use by other users.'
									)}
							</InlineMessage>
						</FormProvider>
					</ModalBody>
					<ModalFooter className='justify-between'>
						<Button onClick={close}>{intl.get('general_cancel')}</Button>
						<Button
							variant='primary-brand'
							disabled={!methods.formState.isDirty || isPending}
							onClick={methods.handleSubmit(onSubmit)}
						>
							{intl.get('general_save_and_apply')}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}
