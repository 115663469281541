import { any, prop } from 'lodash/fp'
import React, { createContext, useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import toast from 'react-hot-toast'

import { ajvResolver } from '@hookform/resolvers/ajv'
import { useQueryClient } from '@tanstack/react-query'

import {
	SettingsTemplateCreateModel,
	SettingsTemplateModel,
	SettingsTemplateType,
	SettingsTemplateUpdateModel,
} from '@/generated'
import intl from '@/locale'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import {
	getResolvedSchema,
	getSchemaDefaultState,
} from '@/tools/json-schema-utils'

import {
	useCreateSettingsTemplateMutation,
	useUpdateSettingsTemplateMutation,
} from '../../mutations'
import { useSettingsTemplateQuery } from '../../queries'
import { SettingsTemplateFormData } from '../../types'

export interface SettingsTemplateContextType {
	templateId?: string
	templateType: SettingsTemplateType
	isNew: boolean
	template?: SettingsTemplateModel
	isPending: boolean
	isLoading: boolean
	methods: UseFormReturn<SettingsTemplateFormData>
	handleFinish: () => void
	handleSubmit: () => void
	handleReset: () => void
}
export default function SettingsTemplateProvider({
	children,
	templateId,
	templateType,
	onFinish,
}: {
	children: React.ReactNode
	templateId?: string
	templateType: SettingsTemplateType
	onFinish?: () => void
}) {
	const queryClient = useQueryClient()
	const isNew = !templateId
	const template = useSettingsTemplateQuery(templateId!, {
		enabled: !!templateId,
	})
	const schemaQuery = useSettingsSchemasQuery({
		select: getResolvedSchema(`template:${templateType}`),
	})

	const createTemplateMutation = useCreateSettingsTemplateMutation({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['settings-templates'],
			})
		},
	})
	const updateTemplateMutation = useUpdateSettingsTemplateMutation(
		templateId!,
		{
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: ['settings-templates'],
				})
			},
		}
	)
	const methods = useForm<SettingsTemplateFormData>({
		resolver(values, context, options) {
			const validate = ajvResolver(schemaQuery.data!, {
				strict: false,
			})
			/*
				Hack for set name of pricing campaign
				We need to validate name in pricing campaign template
				Reason: we want to make pricing campaign name inherit from template name and dont allow users to see it
			 */
			if (templateType === SettingsTemplateType.PricingCampaign) {
				return validate(
					{
						...values,
						body: {
							...values.body,
							name: values.name,
						},
					},
					context,
					options
				)
			} else {
				return validate(values, context, options)
			}
		},
	})

	useEffect(() => {
		if (template.data) {
			methods.reset({
				name: template.data?.name,
				description: template.data?.description || '',
				body: template.data?.body,
			})
		} else if (!template.data && schemaQuery.data) {
			methods.reset(getSchemaDefaultState(schemaQuery.data))
		}
	}, [template.data, schemaQuery.data])

	const handleReset = () => methods.reset()
	const handleSubmit = methods.handleSubmit(
		async (data: SettingsTemplateCreateModel | SettingsTemplateUpdateModel) => {
			if (isNew) {
				await createTemplateMutation.mutateAsync(
					{
						...data,
						template_type: templateType,
					} as SettingsTemplateCreateModel,
					{
						onError: () =>
							toast.error(
								intl.get('toast.entity.failed', {
									entity: intl.get('entity.template'),
								}),
								{
									duration: 5000,
									id: 'settigs-template',
								}
							),
						onSuccess: () =>
							toast.success(
								intl.get('toast.entity.created', {
									entity: intl.get('entity.template'),
								}),
								{
									duration: 5000,
									id: 'settigs-template',
								}
							),
					}
				)
				onFinish?.()
			} else {
				await updateTemplateMutation.mutateAsync(
					{
						...data,
					} as SettingsTemplateUpdateModel,
					{
						onError: () =>
							toast.error(
								intl.get('toast.entity.failed', {
									entity: intl.get('entity.template'),
								}),
								{
									duration: 5000,
									id: 'settigs-template',
								}
							),
						onSuccess: () =>
							toast.success(
								intl.get('toast.entity.updated', {
									entity: intl.get('entity.template'),
								}),
								{
									duration: 5000,
									id: 'settigs-template',
								}
							),
					}
				)
				onFinish?.()
			}
		}
	)
	const isPending = any(prop('isPending'), [
		createTemplateMutation,
		updateTemplateMutation,
	])
	const isLoading = any(prop('isLoading'), [template, schemaQuery])
	return (
		<SettingsTemplateContext.Provider
			value={{
				isNew,
				methods,
				isPending,
				isLoading,
				templateId,
				template: template?.data,
				templateType,
				handleFinish: () => onFinish?.(),
				handleReset,
				handleSubmit,
			}}
		>
			{children}
		</SettingsTemplateContext.Provider>
	)
}
export const SettingsTemplateContext =
	createContext<SettingsTemplateContextType>({
		isNew: true,
		isPending: false,
		isLoading: false,
		templateType: SettingsTemplateType.PricingCampaign,
		methods: {} as UseFormReturn<SettingsTemplateFormData>,
		handleFinish: () => {},
		handleSubmit: () => {},
		handleReset: () => {},
	})

export function useSettingsTemplateContext() {
	return React.useContext(SettingsTemplateContext)
}
