import clsx from 'clsx'
import { PuzzleIcon } from 'lucide-react'
import React, { useState } from 'react'

import { Placement } from '@floating-ui/react-dom'

import {
	Button,
	Content,
	Footer,
	Header,
	Loader,
	MenuItem,
	MenuList,
	Panel,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import { useDisclosure } from '@cmpkit/hooks'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import QueueIcon from '@cmpkit/icon/lib/glyph/queue'
import Popover from '@cmpkit/popover'
import { Operators } from '@cmpkit/query-builder'

import ErrorBoundary from '@/components/ErrorBoundary'
import {
	ProductAssignmentModel,
	SettingsTemplateModel,
	SettingsTemplateType,
} from '@/generated'
import intl from '@/locale'
import { SettingsTemplateGenaratedShortDescription } from '@/modules/settings-templates'
import { useSettingsTemplatesQuery } from '@/modules/settings-templates/queries'
import { SettingsTemplateModelType } from '@/modules/settings-templates/types'
import analytic from '@/services/analytics'

import {
	SettingsEntity,
	SettingType,
	UseSettingActions,
} from './Campaign/usePricingCampaignActions'

type SettingActionDropdownProps = {
	placement?: Placement
	children: React.ReactElement & {
		ref?: React.Ref<HTMLElement>
	}
	actions: UseSettingActions
	type: SettingsTemplateType
	saveAsTemplateProps: {
		pricingCampaignName: string
		pricingCampaignId: string
		settings: { [key: string]: SettingType | ProductAssignmentModel }
	}
	copyToPCProps: {
		settingName: string
	}
	useTemplateProps: {
		isReadyToChange: boolean
	}
}

export default function SettingActionsDropdown({
	placement = 'bottom-end',
	children,
	saveAsTemplateProps: { pricingCampaignName, pricingCampaignId, settings },
	copyToPCProps: { settingName },
	useTemplateProps: { isReadyToChange },
	type,
	actions: { handleCopy, handleSaveAsTemplate, handleUseTemplate },
}: SettingActionDropdownProps) {
	const isNew = !pricingCampaignId
	const dropdownContent = ({ close }: { close: () => void }) => (
		<Panel className='w-56 border py-2'>
			<MenuList>
				{handleCopy && !isNew && (
					<MenuItem
						onClick={() => {
							handleCopy({ pricingCampaignId, settingName })
							close()
						}}
					>
						<QueueIcon />
						{intl.get('pc.settings.action.copy').d('Copy to ')}
					</MenuItem>
				)}

				{handleSaveAsTemplate && (
					<MenuItem
						onClick={() => {
							handleSaveAsTemplate({
								pricingCampaignName,
								templateType: type,
								settings,
							})
							close()
						}}
					>
						<PlusIcon />
						{intl.get('settings.action.save_as_template').d('Save as template')}
					</MenuItem>
				)}
				{handleUseTemplate && (
					<SettingTemplatesPopover
						templateType={type}
						handleApply={(templateSettings: SettingsEntity) => {
							handleUseTemplate({
								templateSettings,
								settingName,
							})
						}}
						isDisable={!isReadyToChange}
						onMenuClose={close}
					/>
				)}
			</MenuList>
		</Panel>
	)
	return (
		<Popover content={dropdownContent} placement={placement}>
			{children}
		</Popover>
	)
}

const SettingTemplatesPopover = ({
	isDisable,
	onMenuClose,
	...props
}: {
	isDisable: boolean
	handleApply(props: { [key: string]: SettingType }): void
	templateType: SettingsTemplateType
	onMenuClose: () => void
}) => {
	const { isOpen, open, close: handleClose } = useDisclosure()
	return (
		<Popover
			placement='left'
			isOpen={isOpen}
			content={() => (
				<PopoverContent
					{...props}
					close={() => {
						handleClose()
						onMenuClose()
					}}
				/>
			)}
		>
			<MenuItem
				disabled={isDisable}
				className={clsx(isDisable && 'pointer-events-none')}
				onClick={() => {
					analytic.logEvent(
						'settings: pricing campaign: dropdown: apply setting template'
					)
					open()
				}}
			>
				<PuzzleIcon className='size-4' />
				{intl.get('settings.action.use_template').d('Use template')}
			</MenuItem>
		</Popover>
	)
}

type PopoverContentProps = {
	close(): void
	handleApply(props: { [key: string]: SettingType | SettingType[] }): void
	templateType: SettingsTemplateType
}
const PopoverContent = ({
	handleApply,
	templateType,
	close,
}: PopoverContentProps) => {
	const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
	const { data: templates, isLoading } = useSettingsTemplatesQuery({
		limit: 100,
		offset: 0,
		filters: [
			{
				name: 'template_type',
				operation: Operators.IN,
				value: templateType,
			},
		],
	})
	const onApply = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const template: any = templates?.data.find(
			({ id }) => id === selectedTemplateId
		)
		handleApply(template.body)
		close()
	}
	const isSelected = (id: string) => selectedTemplateId === id
	return (
		<Panel className='w-[700px] border px-5 py-2'>
			<ErrorBoundary>
				<Header className='mb-2 items-center bg-inherit' as={'h3'}>
					{intl.get(`template.type.${templateType}`).d(templateType || '')}
					<Button
						variant='tertiary'
						onClick={close}
						className='ml-auto'
						iconBefore={<CrossIcon />}
					/>
				</Header>
				<Content>
					<div className='w-full rounded-lg border'>
						<Blanket
							isTinted={isLoading}
							className='absolute flex items-center justify-center rounded-lg bg-white/30 backdrop-blur-sm dark:bg-black/30'
						>
							<Loader />
						</Blanket>
						{!isLoading && (
							<div className='max-h-[300px] overflow-auto rounded-lg'>
								{!!templates?.total ? (
									templates.data?.map((template) => (
										<TemplatesSection
											template={template}
											handleClick={setSelectedTemplateId}
											active={isSelected(template.id)}
										/>
									))
								) : (
									<div className='p-5 text-center text-muted'>
										{intl
											.get('general_no_templates')
											.d('There is no templates')}
									</div>
								)}
							</div>
						)}
					</div>
				</Content>
				<Footer className='bg-inherit'>
					<Button
						variant='primary-brand'
						onClick={onApply}
						disabled={!selectedTemplateId}
						className='ml-auto mt-2'
					>
						{intl.get('general_apply')}
					</Button>
				</Footer>
			</ErrorBoundary>
		</Panel>
	)
}
const TemplatesSection = ({
	template,
	handleClick,
	active,
}: {
	template: SettingsTemplateModel
	handleClick: (id: string) => void
	active: boolean
}) => {
	return (
		<div
			className={clsx(
				'w-full border-b',
				active && 'bg-accent-3 dark:bg-black/50',
				!active && 'hover:bg-accent-2 dark:hover:bg-black/30'
			)}
		>
			<div className={clsx('flex items-center space-x-2 p-2')}>
				<div
					className='w-full cursor-pointer'
					onClick={() => handleClick(template.id)}
				>
					<div className='text-sm font-medium'>{template.name}</div>
					<SettingsTemplateGenaratedShortDescription
						template={
							{
								template_type: template.template_type,
								body: template.body,
							} as SettingsTemplateModelType
						}
					/>
				</div>
			</div>
		</div>
	)
}
