import { find, fromPairs } from 'lodash/fp'

import { useQuery } from '@tanstack/react-query'

import { Operators } from '@cmpkit/query-builder'

import {
	AlertAnnotationModel,
	AnchoringAlertModel,
	AnchoringProductModel,
	AnchoringProductsRequestBody,
	AnchoringProductsResponseData,
	ListAlertsRequestModel,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'

import { ANCHORS_TYPES } from './contstants'

export function useAnchoringRelationsListQuery<
	T = AnchoringProductsResponseData,
>(
	params: AnchoringProductsRequestBody,
	options?: UseQueryOptions<AnchoringProductsResponseData, Error, T>
) {
	return useQuery<AnchoringProductsResponseData, Error, T>({
		queryKey: ['anchoring-list', params],
		queryFn: () => {
			return client.anchoring.listRelations(params)
		},
		...(options || {}),
	})
}

export function useAnchoringAlertsQuery<T = AnchoringAlertModel[]>(
	params: ListAlertsRequestModel,
	options?: UseQueryOptions<AnchoringAlertModel[], Error, T>
) {
	return useQuery<AnchoringAlertModel[], Error, T>({
		queryKey: ['anchoring-alerts', params],
		queryFn: () => {
			return client.anchoring.listAlerts(params)
		},
		...(options || {}),
	})
}
export function useAnchoringAlertsAnnotationsQuery<T = AlertAnnotationModel[]>(
	options?: UseQueryOptions<AlertAnnotationModel[], Error, T>
) {
	return useQuery<AlertAnnotationModel[], Error, T>({
		queryKey: ['anchoring-alerts-annotations'],
		queryFn: () => {
			return client.anchoring.listAlertsAnnotation()
		},
		...(options || {}),
	})
}

export type AnchoringRelationGroup = {
	type: string
	rows: (AnchoringProductModel | { sku: string })[]
}
export function useAnchoringRelationsByTypeQuery<T = AnchoringRelationGroup[]>(
	anchor: AnchoringProductModel,
	options?: UseQueryOptions<AnchoringRelationGroup[], Error, T>
) {
	return useQuery<AnchoringRelationGroup[], Error, T>({
		queryKey: [
			'anchoring-relation-groups',
			{ sku: anchor.sku, sales_entity_id: anchor.sales_entity_id },
		],
		queryFn: async () => {
			const { data } = await client.anchoring.listRelations({
				filters: [
					{
						name: 'sku',
						operation: Operators.IN,
						value: [
							anchor.flat_anchor_sku,
							anchor.bundle_anchor_sku,
							anchor.ladder_anchor_sku,
							anchor.product_set_anchor_sku,
							anchor.size_anchor_sku,
						].filter(Boolean) as string[],
					},
				],
			})
			const parentSkus = await Promise.all(
				ANCHORS_TYPES.map(async (anchorType) => {
					const skus = await client.anchoring.listRelations({
						filters: [
							{
								name: `${anchorType}_anchor_sku`,
								value: anchor.sku,
								operation: 'is',
							},
						],
					})
					return [anchorType, skus.data || []]
				})
			)

			const getAnchorSkuRow = (sku: string) => {
				if (!sku) return undefined
				const row = find({ sku }, data)
				return row || { sku }
			}
			const maping = fromPairs(parentSkus)
			return ANCHORS_TYPES.map((anchorType) => ({
				type: anchorType,
				rows: [
					getAnchorSkuRow(
						anchor[
							`${anchorType}_anchor_sku` as keyof AnchoringProductModel
						] as string
					),
					anchor,
					...maping[anchorType],
				].filter(Boolean),
			}))
		},
		...(options || {}),
	})
}
