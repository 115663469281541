import Edit2Icon from '@cmpkit/icon/lib/glyph/edit-2'
import clsx from 'clsx'
import React from 'react'

export default function InlineEditText({
	className,
	children,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	const childrenArray = React.Children.toArray(children).map((child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				...child.props,
				'data-gramm': 'false',
				className: clsx(
					child.props?.className,
					'-ml-1 block w-full cursor-pointer resize-none overflow-hidden rounded-lg border-transparent bg-transparent p-1 pr-10 leading-6 hover:bg-accent-2 focus:bg-accent-3 focus:outline-none'
				),
			})
		}
		return child
	})
	return (
		<div {...props} className={clsx('group relative', className)}>
			{childrenArray}
			<div className='pointer-events-none invisible absolute right-0 top-1.5 flex items-center pr-3 group-focus-within:hidden group-hover:visible group-hover:text-brand'>
				<Edit2Icon />
			</div>
		</div>
	)
}
