import { format } from 'date-fns'
import React from 'react'

import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'
import { formatSchedule } from '@/modules/core'
import { ScheduleModel } from '@/modules/core/types'

interface ScheduleDate {
	className?: string
	schedule: ScheduleModel
}
export default function ScheduleDate({ className, schedule }: ScheduleDate) {
	return (
		<Tooltip
			placement='top'
			content={
				<div className='whitespace-break-spaces p-1 text-center'>{`"${schedule.name}"\n${formatSchedule(schedule)}`}</div>
			}
		>
			<span className={className}>
				{intl.get('on')} {format(new Date(schedule.nearest_date), 'MMM dd')}
			</span>
		</Tooltip>
	)
}
