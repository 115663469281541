import { format } from 'date-fns'
import { SeriesAreaOptions } from 'highcharts'
import { entries, filter, map, omitAll, prop, propOr } from 'lodash/fp'
import React from 'react'

import Chart from '@/components/Chart'
import intl from '@/locale'
import { useAiBIAnalyticQuery, useMetricsQuery } from '@/modules/bi/queries'
import { foldData, zipSeries } from '@/modules/bi/utils'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { AiDashboardFilter } from '../../Filter'
import { getDefautFields } from '../../MainDashboard/useFilterFiledsConfig'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetEmptyState from '../../WidgetEmptyState'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetProps } from '../types'

const translateMetric = (metric: string) => intl.get(`metric.${metric}`)

export default function SalesFactorsImpactDynamicWidget({
	commonFilters,
	commonPeriods,
	params,
	widget,
	onChangeParams,
}: WidgetProps) {
	const { data: metrics } = useMetricsQuery<string[]>({
		select: (data) => map('name', filter({ type: 'ai_importance' }, data)),
	})
	const commonQueryParams = {
		label: widget.kind,
		...(commonPeriods?.main || {
			start_date: null,
			end_date: null,
		}),
		skip_dates: true,
		metrics: metrics as string[],
		dimensions: [],
		date_aggregation: 'date_key' as const,
		filters: [...commonFilters, ...(params.filters ?? [])],
	}
	const biQuery = useAiBIAnalyticQuery(commonQueryParams, {
		enabled: !!metrics?.length,
		select: (data) => foldData(data?.meta, zipSeries(data?.meta, data.data)),
	})
	const series =
		map(
			([name, data]) => ({
				name: translateMetric(name),
				data,
				type: 'area' as const,
			}),
			entries(omitAll(['date_key'], biQuery.data))
		) || []
	const categories =
		propOr([], 'date_key', biQuery.data).map((date) =>
			format(new Date(date), DateFormat.short)
		) || []

	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetLoadingState isTinted={biQuery.isLoading} />
			<WidgetErrorState isTinted={biQuery.isError} />
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.sales_factors_impact_dynamic.title')}
			/>
			<WidgetContent className='space-y-3 pb-0'>
				<div className='flex items-center justify-between'>
					<AiDashboardFilter
						filters={params.filters ?? []}
						fields={getDefautFields().filter(
							({ value }) => value !== 'pricing_campaign_engine'
						)}
						onChange={(filters) => {
							analytic.logEvent(`dashboard: bi: apply filter ${widget.kind}`, {
								'filter by': filters.map(prop('name')).join(','),
							})
							onChangeParams({ ...params, filters })
						}}
					/>
				</div>
				{!categories?.length ? (
					<WidgetEmptyState />
				) : (
					<Chart config={getChartConfig({ series, categories })} />
				)}
			</WidgetContent>
		</Widget>
	)
}

function formatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	const head = `<div class="w-full flex justify-between mb-2.5"><small>${this.x}</small></div>`
	const points = self.points
		?.map((point: Highcharts.Point) => {
			return `
			<div class="flex justify-between">
				<div class="flex items-center">
					<div class="color-dot rounded-lg mr-4" style="background: ${point.color};"></div>
					<strong class="truncate">${point.series.name}</strong>
				</div>
				<div class="flex items-center">
					<strong class="flex items-center mr-2">${formatNumber(point.y as number, NumberFormats.Percent)}</strong>
				</div>
			</div>`
		})
		.join('')
	return `<div class="chart-tooltip w-64">
		${head}
		${points}
	</div>`
}
function getChartConfig({
	series,
	categories,
}: {
	series: SeriesAreaOptions[]
	categories: string[]
}): Highcharts.Options {
	return {
		chart: {
			type: 'area',
			height: 390,
			animation: true,
		},
		yAxis: {
			title: {
				text: null,
			},
			labels: { format: '{text}%' },
		},
		xAxis: {
			categories,
		},
		tooltip: {
			animation: true,
			outside: true,
			shared: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter,
		},
		colors: ['#C1C3C5', '#92DDB7', '#FF9800', '#32B2F3', '#3F51B5'],
		plotOptions: {
			area: {
				stacking: 'percent',
				fillOpacity: 0.5,
				marker: {
					lineWidth: 0,
					lineColor: undefined,
					symbol: 'circle',
				},
			},
		},
		series,
	}
}
