import { find } from 'lodash/fp'
import React from 'react'
import toast from 'react-hot-toast'
import { useCopyToClipboard } from 'usehooks-ts'

import {
	Badge,
	Button,
	Content,
	Header,
	InlineMessage,
	Layout,
	LinkButton,
	Result,
} from '@cmpkit/base'
import Drawer from '@cmpkit/drawer'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import QueueIcon from '@cmpkit/icon/lib/glyph/queue'
import RelationGraphIcon from '@cmpkit/icon/lib/glyph/relation-graph'
import { Operators } from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import noData from '@/assets/img/svg/no-data.svg'
import { DataOption } from '@/common.types'
import ErrorBoundary from '@/components/ErrorBoundary'
import { AnchoringProductModel } from '@/generated'
import intl from '@/locale'

import { ANCHORS_TYPES_CHOICES } from '../../contstants'
import { getAchorTypes } from '../../helpers'
import {
	useAnchoringRelationsByTypeQuery,
	useAnchoringRelationsListQuery,
} from '../../queries'
import { AnchoringRow } from './AnchoringTypeTable'
import AnchoringTypeTableSection from './AnchoringTypeTableSection'

export default function AnchoringManagementDatailsDrawer({
	selectedSku,
	onClose,
}: {
	selectedSku: string | null
	onClose(): void
}) {
	const selectedItemQuery = useAnchoringRelationsListQuery(
		{
			limit: 1,
			filters: [
				{
					name: 'sku',
					value: selectedSku?.split('__')[1],
					operation: Operators.IS,
				},
				{
					name: 'sales_entity_id',
					value: selectedSku?.split('__')[0],
					operation: Operators.IS,
				},
			],
		},
		{
			enabled: !!selectedSku,
			select: ({ data }) => data?.[0],
		}
	)

	const handleClose = () => onClose()

	return (
		<Drawer
			orient='right'
			className='anchor-item-eastside'
			disableBlanket
			disableFocusTrap
			isOpen={!!selectedSku}
			onClose={handleClose}
		>
			<ErrorBoundary>
				{selectedItemQuery.isLoading && (
					<Layout className='h-full'>
						<Header className='flex flex-col space-y-3 bg-accent-1 px-5 py-3'>
							<HeaderSkeleton />
						</Header>
						<Content className='flex flex-col overflow-y-auto overflow-x-hidden bg-application p-5'>
							<ContentSkeleton />
						</Content>
					</Layout>
				)}
				{!selectedItemQuery.isLoading &&
					(!!selectedItemQuery.data ? (
						<AnchoringProductDeatils
							selectedItem={selectedItemQuery.data}
							onClose={handleClose}
						/>
					) : (
						<Layout className='h-full'>
							<Header className='flex flex-col space-y-3 bg-accent-1 px-5 py-3'>
								<div className='flex items-start justify-end'>
									<Button
										variant='tertiary'
										iconBefore={<CrossIcon />}
										onClick={onClose}
									/>
								</div>
							</Header>
							<Content className='flex flex-col overflow-y-auto overflow-x-hidden p-5'>
								<div className='flex h-full items-center justify-center'>
									<Result
										icon={
											<img
												className='pointer-events-none select-none'
												alt='No data'
												src={noData}
											/>
										}
										title={intl.get('error.sku_not_found')}
										subtitle={intl.get('error.sku_not_found_description')}
									/>
								</div>
							</Content>
						</Layout>
					))}
			</ErrorBoundary>
		</Drawer>
	)
}
function AnchoringProductDeatils({
	selectedItem,
	onClose,
}: {
	selectedItem: AnchoringProductModel
	onClose(): void
}) {
	const [value, copy] = useCopyToClipboard() // eslint-disable-line
	const alerts = selectedItem?.alerts?.filter(Boolean)
	const anchorsGroups = useAnchoringRelationsByTypeQuery(selectedItem)

	return (
		<Layout className='h-full'>
			<Header className='flex flex-col space-y-3 bg-accent-1 px-5 py-3'>
				<div className='flex items-start justify-between'>
					<h4 className='font-bold'>{selectedItem?.title || '-'}</h4>
					<div className='absolute right-5 top-4'>
						<Button
							variant='tertiary'
							iconBefore={<CrossIcon />}
							onClick={onClose}
						/>
					</div>
				</div>
				<div className='flex flex-col space-y-2'>
					<div className='flex items-center space-x-1 text-xs'>
						<span>{intl.get('product_line_id')}:</span>
						<strong>{selectedItem?.line_id || '-'}</strong>
					</div>
					{selectedItem?.sku && (
						<div className='flex items-center space-x-1 text-xs'>
							<span>{intl.get('sku')}:</span>
							<strong>{selectedItem?.sku}</strong>
							<Tooltip content={intl.get('general_copy_sku')}>
								<LinkButton
									variant='brand'
									onClick={() =>
										copy(selectedItem?.sku || '').then(() =>
											toast.success(intl.get('general_successful_copied'))
										)
									}
								>
									<QueueIcon width={14} height={14} />
								</LinkButton>
							</Tooltip>
						</div>
					)}
					<div className='flex items-center space-x-1 text-xs'>
						<span>{intl.get('app.anchors_types')}:</span>
						{getAchorTypes(selectedItem)
							.map(
								(value) =>
									find({ value }, ANCHORS_TYPES_CHOICES) as DataOption & {
										variant: string
									}
							)
							.map((type) => (
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								<Badge variant={type.variant as any}>
									{(selectedItem[
										`${type.value}_anchor_count` as keyof AnchoringProductModel
									] as number) > 0 ? (
										<RelationGraphIcon className='mr-1' />
									) : null}
									{type.label}
								</Badge>
							))}
					</div>
				</div>
			</Header>
			<Content className='flex flex-col overflow-y-auto overflow-x-hidden bg-application'>
				<ErrorBoundary>
					<div className='p-5'>
						{alerts?.length && (
							<div className='mb-3 space-y-1'>
								{alerts?.map((alert) => {
									return (
										<InlineMessage
											icon={<AlertIcon />}
											variant='danger'
											className='w-full'
											key={alert}
										>
											{intl.get(`anchor_alerts.${alert}.name`).d(alert)}
										</InlineMessage>
									)
								})}
							</div>
						)}

						{anchorsGroups.isLoading ? (
							<ContentSkeleton />
						) : (
							anchorsGroups.data?.map(({ type, rows }) => {
								return (
									<AnchoringTypeTableSection
										type={type}
										key={type}
										rows={rows.map(
											(item: AnchoringProductModel, index): AnchoringRow => ({
												sku: item.sku!,
												title: item.title,
												is_anchor: index === 0,
												is_current: item.sku === selectedItem.sku,
												add_idx: item[
													`${type}_anchor_add` as ItemKey
												]! as number,
												mul_idx: item[
													`${type}_anchor_mul` as ItemKey
												]! as number,
											})
										)}
									/>
								)
							})
						)}
					</div>
				</ErrorBoundary>
			</Content>
		</Layout>
	)
}
const HeaderSkeleton = () => (
	<>
		<div className='mb-5 h-5 w-44 animate-pulse rounded-lg bg-accent-3' />
		<div className='mb-2 h-4 w-32 animate-pulse rounded-lg bg-accent-3' />
		<div className='mb-2 h-4 w-32 animate-pulse rounded-lg bg-accent-3' />
		<div className='mb-10 h-5 w-full animate-pulse rounded-lg bg-accent-3' />
	</>
)
const ContentSkeleton = () => (
	<div className='space-y-5'>
		<div className='h-5 w-28 animate-pulse rounded-lg bg-accent-3' />
		<div className='h-12 w-full animate-pulse rounded-lg bg-accent-3' />
		<div className='h-5 w-28 animate-pulse rounded-lg bg-accent-3' />
		<div className='h-12 w-full animate-pulse rounded-lg bg-accent-3' />
		<div className='h-5 w-28 animate-pulse rounded-lg bg-accent-3' />
		<div className='h-12 w-full animate-pulse rounded-lg bg-accent-3' />
		<div className='h-5 w-28 animate-pulse rounded-lg bg-accent-3' />
		<div className='h-12 w-full animate-pulse rounded-lg bg-accent-3' />
		<div className='h-5 w-28 animate-pulse rounded-lg bg-accent-3' />
		<div className='h-12 w-full animate-pulse rounded-lg bg-accent-3' />
	</div>
)
type ItemKey = keyof AnchoringProductModel
