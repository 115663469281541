import { indexBy, pipe, prop } from 'lodash/fp'
import React, { useMemo } from 'react'

import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	Content,
	Spinner,
	treeHelpers,
} from '@cmpkit/base'

import LabeledValue from '@/components/LabeledValue'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { LockedForChangesNotification } from '@/modules/core'
import {
	flattifyProductGroups,
	getSalesEntitiesTree,
} from '@/modules/core/helpers'
import { useProductGroupsTreeQuery } from '@/modules/core/hooks/useProductGroupsTreeQuery'
import {
	useOptimizationGroupsQuery,
	useSalesEntitiesQuery,
} from '@/modules/core/queries'
import { useModalStore } from '@/modules/modals/store'
import { PricingCampaigns } from '@/modules/pricing-campaigns'

import { useOptimizationGroupSettingsQuery } from '../queries'
import { BusinessSettingsCard } from './BusinessSettingsCard'
import { StopListCard } from './StopListCard'
import { StrateryCard } from './StrategyCard'
import OptimizationGroupActionsDropdown from './OptimizationGroupActionsDropdown'

export default function OptimizationGroupSettingsPage() {
	return (
		<Content className='overflow-y-auto'>
			<div className='w-full py-4'>
				<div className='flex flex-col px-4'>
					<LockedForChangesNotification className='mb-2' />
					<div className='mb-4 flex items-center justify-between'>
						<h1 className='text-lg font-bold'>
							{intl.get('og.settings').d('Optimization Group settings')}
						</h1>
						<OptimizationGroupActionsDropdown />
					</div>

					<div className='mb-8 grid w-full grid-cols-6 gap-4'>
						<OptimizationGroupStructureCard />
						<OptimizationGroupStrateryCard />
						<OptimizationGroupBusinessSettingsCard />
						<OptimizationGroupStopListCard />
					</div>
				</div>
				<h1 className='px-4 text-lg font-bold'>
					{intl.get('general_pc').d('Pricing campaigns')}
				</h1>
				<PricingCampaigns type='local' />
			</div>
		</Content>
	)
}

function OptimizationGroupStructureCard() {
	const { showModal } = useModalStore()
	const optimizationGroupId = useOptimizationGroupId()!
	const optimizationGroupQuery = useOptimizationGroupsQuery({
		select: (data) => data.find((group) => group.id === optimizationGroupId),
	})
	const salesEntitiesQuery = useSalesEntitiesQuery()
	const productGroupsTreeQuery = useProductGroupsTreeQuery()
	const salesEntitiesTree = useMemo(
		() => getSalesEntitiesTree(salesEntitiesQuery.data),
		[salesEntitiesQuery.data]
	)
	const isLoading =
		optimizationGroupQuery.isLoading ||
		salesEntitiesQuery.isLoading ||
		productGroupsTreeQuery.isLoading

	const { sales_entities, product_groups } = useMemo(() => {
		if (!optimizationGroupQuery.data || !salesEntitiesTree) {
			return {
				sales_entities: [],
				product_groups: [],
			}
		}
		const { sales_entities, product_groups } = optimizationGroupQuery.data
		return {
			sales_entities: treeHelpers.getChildrenIds(
				treeHelpers.findNodes(salesEntitiesTree, sales_entities)
			),
			product_groups: treeHelpers.getChildrenIds(
				treeHelpers.findNodes(
					productGroupsTreeQuery.data,
					flattifyProductGroups(product_groups)
				)
			),
		}
	}, [optimizationGroupQuery.data, productGroupsTreeQuery.data])
	const sales_level = useMemo(() => {
		if (!optimizationGroupQuery.data || !salesEntitiesQuery.data) {
			return undefined
		}
		const salesLevelsDict = pipe([
			prop('sales_levels'),
			indexBy(prop('level')),
		])(salesEntitiesQuery.data)
		return salesLevelsDict?.[optimizationGroupQuery.data.sales_level_id]
	}, [salesEntitiesQuery.data, optimizationGroupQuery.data])

	return (
		<Card
			className='group/card relative cursor-pointer border hover:border-brand'
			onClick={() =>
				showModal('OPTIMIZATION_GROUP_MODAL', {
					optimizationGroupId,
					initialTabId: 'structure',
				})
			}
		>
			<CardHeader>
				<CardTitle>{intl.get('og.structure').d('OG structure')}</CardTitle>
			</CardHeader>
			<CardContent>
				<LabeledValue label={intl.get('levels').d('Levels')}>
					{isLoading ? <Spinner /> : <strong>{sales_level?.name}</strong>}
				</LabeledValue>
				<LabeledValue label={intl.get('sales_units').d('Sales units')}>
					{isLoading ? <Spinner /> : <strong>{sales_entities?.length}</strong>}
				</LabeledValue>
				<LabeledValue label={intl.get('product_groups').d('Product groups')}>
					{isLoading ? <Spinner /> : <strong>{product_groups?.length}</strong>}
				</LabeledValue>
			</CardContent>
		</Card>
	)
}
function OptimizationGroupStrateryCard() {
	const { showModal } = useModalStore()
	const optimizationGroupId = useOptimizationGroupId()!
	const settingsQuery = useOptimizationGroupSettingsQuery(optimizationGroupId)

	const isLoading = settingsQuery.isLoading
	if (isLoading) {
		return null
	}
	return (
		<StrateryCard
			data={settingsQuery.data?.settings.strategy}
			onClick={() =>
				showModal('OPTIMIZATION_GROUP_MODAL', {
					optimizationGroupId,
					initialTabId: 'strategy',
				})
			}
		/>
	)
}

function OptimizationGroupBusinessSettingsCard() {
	const { showModal } = useModalStore()
	const optimizationGroupId = useOptimizationGroupId()!
	const settingsQuery = useOptimizationGroupSettingsQuery(optimizationGroupId)
	const isLoading = settingsQuery.isLoading
	if (isLoading) {
		return null
	}
	return (
		<BusinessSettingsCard
			data={settingsQuery.data?.settings.business}
			onClick={() =>
				showModal('OPTIMIZATION_GROUP_MODAL', {
					optimizationGroupId,
					initialTabId: 'business',
				})
			}
		/>
	)
}

function OptimizationGroupStopListCard() {
	const { showModal } = useModalStore()
	const optimizationGroupId = useOptimizationGroupId()!
	const settingsQuery = useOptimizationGroupSettingsQuery(optimizationGroupId)

	const isLoading = settingsQuery.isLoading
	if (isLoading) {
		return null
	}
	return (
		<StopListCard
			data={settingsQuery.data?.settings.stop_list}
			onClick={() =>
				showModal('OPTIMIZATION_GROUP_MODAL', {
					optimizationGroupId,
					initialTabId: 'stop_list',
				})
			}
		/>
	)
}
