import * as Sentry from '@sentry/react'

import * as config from '@/config'

type Extra = Record<string, unknown>

class Logger {
	/**
	 * Log information
	 *
	 * @param category A log message category that will be prepended
	 * @param extra Arbitrary data to be logged that will appear in prod logs
	 */
	info(label: string, message: string, extra?: Extra) {
		console.info(`[${label}] ${message}`, extra) // eslint-disable-line
	}

	/**
	 * Debug information
	 *
	 * @param category A log message category that will be prepended
	 * @param extra Arbitrary data to be logged
	 */
	debug(label: string, message: string, extra?: Extra) {
		if (!config.isProdEnv) {
			console.info(`[${label}] ${message}`, extra) // eslint-disable-line
		}
	}

	/**
	 * Log a warning
	 *
	 * @param message A warning message
	 * @param extra Arbitrary data to be logged that will appear in prod logs
	 */
	warn(message: string, extra?: Extra) {
		if (config.REACT_APP_SENTRY_DSN) {
			Sentry.withScope(function (scope) {
				scope.setLevel('warning')

				for (const key in extra) {
					scope.setExtra(key, extra[key])
				}

				Sentry.captureMessage(message)
			})
		}

		console.warn(message, extra) // eslint-disable-line
	}

	/**
	 * Report a runtime error
	 *
	 * @param message A description of the error
	 * @param error The error that occurred
	 * @param extra Arbitrary data to be logged that will appear in prod logs
	 */
	error(message: string, error: Error, extra?: Extra) {
		if (config.REACT_APP_SENTRY_DSN) {
			Sentry.withScope(function (scope) {
				scope.setLevel('error')

				for (const key in extra) {
					scope.setExtra(key, extra[key])
				}

				Sentry.captureException(error)
			})
		}
		/* eslint-disable */
		console.error(message, {
			error,
			extra,
		})
		/* eslint-enable */
	}
}

export default new Logger()
