/* eslint-disable @typescript-eslint/no-explicit-any */

import { CHANNEL_EVENTS, CHANNEL_STATES, SOCKET_EVENTS } from './constants'
import Observer from './observer'
import Push from './push'
import Socket from './socket'
import Timer from './timer'
import { closure } from './utils'

type BindingCallback = {
	ref?: any
	event: string
	callback: any
}
/**
 *
 * @param {string} topic
 * @param {(Object|function)} params
 * @param {Socket} socket
 */
export default class Channel extends Observer {
	state: CHANNEL_STATES
	topic: string
	params: any
	socket: Socket
	timeout: number
	bindings: BindingCallback[]
	joinedOnce: boolean
	joinPush: Push
	pushBuffer: Push[]
	rejoinTimer: Timer
	bindingRef: number
	stateChangeRefs: any[]
	constructor(topic: string, params: object, socket: Socket) {
		super()
		this.state = CHANNEL_STATES.closed
		this.topic = topic
		this.params = closure(params || {})
		this.socket = socket
		this.bindings = []
		this.bindingRef = 0
		this.timeout = this.socket.timeout
		this.joinedOnce = false
		this.joinPush = new Push(
			this,
			CHANNEL_EVENTS.subscribe,
			this.params,
			this.timeout
		)
		this.pushBuffer = []
		this.stateChangeRefs = []

		this.rejoinTimer = new Timer(() => {
			if (this.socket.isConnected()) {
				this.resubscribe()
			}
		}, this.socket.rejoinAfterMs)
		this.stateChangeRefs.push(
			this.socket.on(SOCKET_EVENTS.error, () => this.rejoinTimer.reset())
		)
		this.stateChangeRefs.push(
			this.socket.on(SOCKET_EVENTS.open, () => {
				this.rejoinTimer.reset()
				if (this.isErrored()) {
					this.resubscribe()
				}
			})
		)
		this.joinPush.receive('ok', () => {
			this.state = CHANNEL_STATES.subscribed
			this.rejoinTimer.reset()
			this.pushBuffer.forEach((pushEvent) => pushEvent.send())
			this.pushBuffer = []
		})
		this.joinPush.receive('error', () => {
			this.state = CHANNEL_STATES.errored
			if (this.socket.isConnected()) {
				this.rejoinTimer.scheduleTimeout()
			}
		})
		this.onClose(() => {
			this.rejoinTimer.reset()
			if (this.socket.hasLogger())
				this.socket.log('channel', `close ${this.topic} ${this.joinRef()}`)
			this.state = CHANNEL_STATES.closed
			this.socket.remove(this)
		})
		this.onError((reason: any) => {
			if (this.socket.hasLogger())
				this.socket.log('channel', `error ${this.topic}`, reason)
			if (this.isSubscribing()) {
				this.joinPush.reset()
			}
			this.state = CHANNEL_STATES.errored
			if (this.socket.isConnected()) {
				this.rejoinTimer.scheduleTimeout()
			}
		})
		this.joinPush.receive('timeout', () => {
			if (this.socket.hasLogger())
				this.socket.log(
					'channel',
					`timeout ${this.topic} (${this.joinRef()})`,
					this.joinPush.timeout
				)
			const leavePush = new Push(
				this,
				CHANNEL_EVENTS.unsubscribe,
				closure({}),
				this.timeout
			)
			leavePush.send()
			this.state = CHANNEL_STATES.errored
			this.joinPush.reset()
			if (this.socket.isConnected()) {
				this.rejoinTimer.scheduleTimeout()
			}
		})
		//this.on(/* CHANNEL_EVENTS.reply */ 'subscribe', (message, ref) => {
		//	console.log('🚀 subscribe message', message, ref, this.replyEventName(ref))
		//	console.log('bindings', this.bindings)
		//
		//	this.trigger(this.replyEventName(ref), message)
		//})
	}

	/**
	 * Join the channel
	 * @param {integer} timeout
	 * @returns {Push}
	 */
	subscribe(timeout = this.timeout) {
		if (this.joinedOnce) {
			return this.joinPush
		} else {
			this.timeout = timeout
			this.joinedOnce = true
			this.resubscribe()
			return this.joinPush
		}
	}

	/**
	 * Hook into channel close
	 * @param {Function} callback
	 */
	onClose(callback: any) {
		this.on(CHANNEL_EVENTS.close, callback)
	}

	/**
	 * Hook into channel errors
	 * @param {Function} callback
	 */
	onError(callback: any) {
		return this.on(CHANNEL_EVENTS.error, (reason: any) => callback(reason))
	}

	/**
	 * Subscribes on channel events
	 *
	 * Subscription returns a ref counter, which can be used later to
	 * unsubscribe the exact event listener
	 *
	 * @example
	 * const ref1 = channel.on("event", do_stuff)
	 * const ref2 = channel.on("event", do_other_stuff)
	 * channel.off("event", ref1)
	 * // Since unsubscription, do_stuff won't fire,
	 * // while do_other_stuff will keep firing on the "event"
	 *
	 * @param {string} event
	 * @param {Function} callback
	 * @returns {integer} ref
	 */
	//on(event: string, callback: any) {
	//	let ref = this.bindingRef++
	//	this.bindings.push({ event, ref, callback })
	//	return ref
	//}

	/**
	 * Unsubscribes off of channel events
	 *
	 * Use the ref returned from a channel.on() to unsubscribe one
	 * handler, or pass nothing for the ref to unsubscribe all
	 * handlers for the given event.
	 *
	 * @example
	 * // Unsubscribe the do_stuff handler
	 * const ref1 = channel.on("event", do_stuff)
	 * channel.off("event", ref1)
	 *
	 * // Unsubscribe all handlers from event
	 * channel.off("event")
	 *
	 * @param {string} event
	 * @param {integer} ref
	 */
	//off(event: string, ref?: any) {
	//	this.bindings = this.bindings.filter((bind) => {
	//		return !(bind.event === event && (typeof ref === 'undefined' || ref === bind.ref))
	//	})
	//}

	/**
	 * @private
	 */
	canPush() {
		return this.socket.isConnected() && this.isSubscribed()
	}

	/**
	 * Sends a message `event` to  with the payload `payload`.
	 * Phoenix receives this in the `handle_in(event, payload, socket)`
	 * function. if  replies or it times out (default 10000ms),
	 * then optionally the reply can be received.
	 *
	 * @example
	 * channel.push("event")
	 *   .receive("ok", payload => console.log(" replied:", payload))
	 *   .receive("error", err => console.log(" errored", err))
	 *   .receive("timeout", () => console.log("timed out pushing"))
	 * @param {string} event
	 * @param {Object} payload
	 * @param {number} [timeout]
	 * @returns {Push}
	 */
	push(event: any, payload: any, timeout = this.timeout) {
		payload = payload || {}
		if (!this.joinedOnce) {
			throw new Error(
				`tried to push '${event}' to '${this.topic}' before joining. Use channel.subscribe() before pushing events`
			)
		}
		const pushEvent = new Push(
			this,
			event,
			function () {
				return payload
			},
			timeout
		)
		if (this.canPush()) {
			pushEvent.send()
		} else {
			pushEvent.startTimeout()

			this.pushBuffer.push(pushEvent)
		}
		return pushEvent
	}

	/** Leaves the channel
	 *
	 * Unsubscribes from server events, and
	 * instructs channel to terminate on server
	 *
	 * Triggers onClose() hooks
	 *
	 * To receive leave acknowledgements, use the `receive`
	 * hook to bind to the server ack, ie:
	 *
	 * @example
	 * channel.leave().receive("ok", () => alert("left!") )
	 *
	 * @param {integer} timeout
	 * @returns {Push}
	 */
	leave(timeout = this.timeout) {
		this.rejoinTimer.reset()
		this.joinPush.cancelTimeout()

		this.state = CHANNEL_STATES.leaving
		const onClose = () => {
			if (this.socket.hasLogger())
				this.socket.log('channel', `leave ${this.topic}`)
			this.trigger(CHANNEL_EVENTS.close, {})
		}
		const leavePush = new Push(
			this,
			CHANNEL_EVENTS.unsubscribe,
			closure({}),
			timeout
		)
		leavePush.receive('ok', () => onClose()).receive('timeout', () => onClose())
		leavePush.send()
		if (!this.canPush()) {
			leavePush.trigger('ok', {})
		}

		return leavePush
	}

	/**
	 * Overridable message hook
	 *
	 * Receives all events for specialized message handling
	 * before dispatching to the channel callbacks.
	 *
	 * Must return the payload, modified or unmodified
	 * @param {string} event
	 * @param {Object} payload
	 * @param {integer} ref
	 * @returns {Object}
	 */
	onMessage(message: any) {
		return message
	}

	/**
	 * @private
	 */
	isMember({ topic }: any) {
		return this.topic === topic
		/* if (this.topic !== topic) {
			return false
		}
		return true */
		/* if (joinRef && joinRef !== this.joinRef()) {
			if (this.socket.hasLogger())
				this.socket.log('channel', 'dropping outdated message', { topic, event, payload, joinRef })
			return false
		} else {
			return true
		} */
	}

	/**
	 * @private
	 */
	joinRef() {
		return this.joinPush.ref
	}

	/**
	 * @private
	 */
	resubscribe(timeout = this.timeout) {
		if (this.isLeaving()) {
			return
		}
		this.socket.leaveOpenTopic(this.topic)
		this.state = CHANNEL_STATES.subscribing
		this.joinPush.resend(timeout)
	}

	/**
	 * @private
	 */
	trigger(event: string, message: any) {
		const handledPayload = this.onMessage(message)
		if (message?.payload && !handledPayload) {
			throw new Error(
				'channel onMessage callbacks must return the payload, modified or unmodified'
			)
		}
		this.fireEvent(
			[event, message.ref].filter(Boolean).join(':'),
			handledPayload
		)
		//this.fireEvent(event, handledPayload)
	}

	/**
	 * @private
	 */
	replyEventName(ref: string) {
		return `chan_reply_${ref}`
	}

	/**
	 * @private
	 */
	isClosed() {
		return this.state === CHANNEL_STATES.closed
	}

	/**
	 * @private
	 */
	isErrored() {
		return this.state === CHANNEL_STATES.errored
	}

	/**
	 * @private
	 */
	isSubscribed() {
		return this.state === CHANNEL_STATES.subscribed
	}

	/**
	 * @private
	 */
	isSubscribing() {
		return this.state === CHANNEL_STATES.subscribing
	}

	/**
	 * @private
	 */
	isLeaving() {
		return this.state === CHANNEL_STATES.leaving
	}
}
