import { all, find } from 'lodash/fp'
import React, { Fragment, useEffect, useMemo } from 'react'

import { Button, Spinner } from '@cmpkit/base'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'
import PlayIcon from '@cmpkit/icon/lib/glyph/play'
import RefreshIcon from '@cmpkit/icon/lib/glyph/refresh'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import { Operators } from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import { OptimizationStatus, ProductsQueryRequestModel } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import { usePrevious } from '@/hooks/usePrevious'
import intl from '@/locale'
import { useAuth } from '@/modules/Auth/AuthContext'
import {
	createProductDiffs,
	getViewState,
	isEditedData,
	OptimizationSummaryTrigger,
} from '@/modules/core'
import { useUpdateProductsFieldsMutation } from '@/modules/core/hooks/useUpdateProductsFieldsMutation'
import useOptimization from '@/modules/core/hooks/useOptimization'
import { useApproveReviewMutation } from '@/modules/core/mutations'
import { useOptimizationGroup, useReviewsQuery } from '@/modules/core/queries'
import { ReviewModel } from '@/modules/core/types'
import { useDrawer } from '@/modules/drawers/store'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'

import {
	useProductsStore,
	useTableConfigStore,
} from '@/modules/og-products/store'

export default function OptimizationGroupActions({
	requestBody,
}: {
	requestBody: Partial<
		Pick<ProductsQueryRequestModel, 'filters' | 'search' | 'order_by'>
	>
}) {
	/**
	 * Hooks
	 */
	const { user } = useAuth()
	const optimizationGroupId = useOptimizationGroupId()!
	const optimizationGroup = useOptimizationGroup(optimizationGroupId)

	const { showModal } = useModalStore()
	const { localData, setLocalData } = useProductsStore()
	const drawer = useDrawer('OPTIMIZATION_SUMMARY')
	const updateProducts = useUpdateProductsFieldsMutation(optimizationGroupId)
	const {
		commit,
		start,
		stop,
		lock,
		unlock,
		create,
		createAndStart,
		optimization,
		isProcessAction,
		isOptimizationLoading,
	} = useOptimization(optimizationGroupId)
	const { columnsConfig } = useTableConfigStore()
	const { data: review } = useReviewsQuery<ReviewModel | undefined>({
		select: find<ReviewModel>({ optimization_group_id: optimizationGroupId }),
	})
	useEffect(() => {
		return () => drawer.close()
	}, [])
	/**
	 * Mutations
	 */
	const approveReview = useApproveReviewMutation()

	/**
	 * Computed
	 */

	const state = useMemo(
		() => getViewState(optimization?.status, isEditedData(localData)),
		[optimization?.status, localData]
	)
	const isSummaryAvailable = useMemo(
		() =>
			optimization?.status &&
			[OptimizationStatus.Locked, OptimizationStatus.Finished].includes(
				optimization.status
			),
		[optimization?.status]
	)
	const prevState = usePrevious(state)
	useEffect(() => {
		if (prevState === 'stop' && state === 'apply') {
			drawer.open({})
		}
	}, [state])

	/**
	 * Handlers
	 */
	const handleApproveReview = () =>
		approveReview.mutateAsync(optimizationGroupId)

	/**
	 * Buttons
	 */
	const approveBtn = (
		<Button
			disabled={isProcessAction || updateProducts.isPending}
			data-testid='optimization-action-approve'
			variant='primary-brand'
			key='approve_prices'
			onClick={handleApproveReview}
			iconBefore={
				isProcessAction || updateProducts.isPending ? (
					<Spinner size='small' variant='invert' />
				) : (
					<CheckIcon />
				)
			}
		>
			{intl.get('approve_prices')}
		</Button>
	)
	const revertBtn = (
		<Tooltip content={intl.get('general_revert')}>
			<Button
				data-testid='optimization-action-revert'
				key='revert'
				onClick={() => setLocalData({})}
				iconBefore={<UndoIcon />}
			/>
		</Tooltip>
	)
	const saveBtn = (
		<Button
			disabled={
				isProcessAction || updateProducts.isPending || state === 'updating'
			}
			variant='primary-warning'
			key='save'
			data-testid='optimization-action-save'
			onClick={async () => {
				analytic.logEvent('table: save changes')
				await updateProducts.mutateAsync({
					changes: createProductDiffs(localData),
					ignore_empties: false,
				})
				setLocalData({})
			}}
			iconBefore={
				isProcessAction || updateProducts.isPending || state === 'updating' ? (
					<Spinner size='small' variant='invert' />
				) : (
					<CheckIcon />
				)
			}
		>
			{intl.get('general_save_and_apply')}
		</Button>
	)

	const updatingBtn = (
		<div className='inline-flex h-8 w-40 animate-pulse items-center rounded-lg bg-accent-4' />
	)
	const applyBtn = (
		<Button
			disabled={isProcessAction || updateProducts.isPending}
			key='apply'
			data-testid='optimization-action-apply'
			variant='primary-brand'
			onClick={() => {
				analytic.logEvent('repricing: apply prices', {
					'OG name': optimizationGroup.name,
					'Products amount': optimization?.total_products,
				})
				lock()
			}}
			iconBefore={
				isProcessAction || updateProducts.isPending ? (
					<Spinner size='small' variant='invert' />
				) : (
					<CheckIcon />
				)
			}
		>
			{intl.get('status_panel_apply_prices')}
		</Button>
	)
	const exportBtn = (
		<Button
			disabled={isProcessAction || updateProducts.isPending}
			key='export'
			data-testid='optimization-action-export'
			iconBefore={<DownloadIcon />}
			onClick={() => {
				analytic.logEvent('repricing: export applied proices', {
					'OG name': optimizationGroup.name,
					'Products amount': optimization?.total_products,
				})
				const context = {
					...requestBody,
					filters: [
						...(requestBody?.filters || []),
						{
							name: 'optimization_group_id',
							operation: Operators.IS,
							value: optimizationGroupId,
						},
					],
				}
				showModal('EXPORT_ASSORTMENT', {
					defaultName: `Applied prices ${optimizationGroup.name}`,
					columnsConfigs: columnsConfig,
					context,
				})
			}}
		>
			{intl.get('status_panel_download_report')}
		</Button>
	)
	const stopBtn = (
		<Button
			disabled={isProcessAction || updateProducts.isPending}
			key='stop'
			data-testid='optimization-action-stop'
			variant='primary-danger'
			onClick={stop}
			iconBefore={
				isProcessAction || updateProducts.isPending ? (
					<Spinner size='small' variant='invert' />
				) : (
					<UndoIcon />
				)
			}
		>
			{intl.get('general_stop_and_cancel')}
		</Button>
	)
	const startBtn = (
		<Button
			disabled={isProcessAction || updateProducts.isPending}
			key='start'
			data-testid='optimization-action-start'
			variant='primary-brand'
			onClick={start}
			iconBefore={
				isProcessAction || updateProducts.isPending ? (
					<Spinner size='small' variant='invert' />
				) : (
					<PlayIcon />
				)
			}
		>
			{intl.get('products_start_optimization')}
		</Button>
	)
	const restartBtn = (
		<Button
			disabled={isProcessAction || updateProducts.isPending}
			key='restart'
			data-testid='optimization-action-restart'
			variant='primary-brand'
			onClick={start}
			iconBefore={
				isProcessAction || updateProducts.isPending ? (
					<Spinner size='small' variant='invert' />
				) : (
					<PlayIcon />
				)
			}
		>
			{intl.get('general_restart')}
		</Button>
	)
	const сreateAndStartBtn = (
		<Button
			disabled={isProcessAction || updateProducts.isPending}
			variant='primary-brand'
			onClick={createAndStart}
			iconBefore={
				isProcessAction || updateProducts.isPending ? (
					<Spinner size='small' variant='invert' />
				) : (
					<PlayIcon />
				)
			}
		>
			{intl.get('general_restart')}
		</Button>
	)
	const cancelBtn = (
		<Button
			disabled={isProcessAction || updateProducts.isPending}
			key='cancel'
			data-testid='optimization-action-cancel'
			onClick={unlock}
			iconBefore={
				isProcessAction || updateProducts.isPending ? (
					<Spinner size='small' variant='invert' />
				) : (
					<UndoIcon />
				)
			}
		>
			{intl.get('general_discard')}
		</Button>
	)
	const revokeBtn = (
		<Button
			disabled={isProcessAction || updateProducts.isPending}
			key='revoke'
			data-testid='optimization-action-revoke'
			onClick={create}
			iconBefore={
				isProcessAction || updateProducts.isPending ? (
					<Spinner size='small' variant='invert' />
				) : (
					<UndoIcon />
				)
			}
		>
			{intl.get('general_revoke').d('Revoke')}
		</Button>
	)
	const commitBtn = (
		<Button
			variant='primary-warning'
			key='commit'
			data-testid='optimization-action-commit'
			onClick={commit}
			iconBefore={
				isProcessAction || updateProducts.isPending ? (
					<Spinner size='small' variant='invert' />
				) : (
					<RefreshIcon />
				)
			}
		>
			{intl.get('app.update_settings')}
		</Button>
	)

	const getButtons = (state: string) => {
		switch (state) {
			case 'save':
				return [revertBtn, saveBtn]
			case 'commit':
				return [commitBtn]
			case 'updating':
				return [updatingBtn]
			case 'create_and_start':
				return [сreateAndStartBtn]
			case 'start':
				return [startBtn]
			case 'stop':
				return [stopBtn]
			case 'apply':
				if (review) {
					const reviewer = find({ id: user!.id }, review.reviewers)
					if (review.author_id === user!.id) {
						// when I`m author
						return [revokeBtn, applyBtn]
					} else if (reviewer) {
						// when I`m reviewer
						if (all({ is_approved: true }, review.reviewers)) {
							// approved by both reviewers
							return [revokeBtn, applyBtn]
						} else if (reviewer.is_approved) {
							return [revokeBtn]
						} else {
							return [revokeBtn, approveBtn]
						}
					} else {
						// when I`m random user
						return [revokeBtn, applyBtn]
					}
				}
				return [revokeBtn, applyBtn]
			case 'unlock':
				return [cancelBtn, exportBtn]
			case 'restart':
				return [revokeBtn, restartBtn]
			default:
				return ''
		}
	}
	if (isOptimizationLoading) {
		return (
			<div className='inline-flex h-8 w-40 animate-pulse items-center rounded-lg bg-accent-4' />
		)
	}
	return (
		<Fragment>
			<div className='flex items-center space-x-2'>
				{getButtons(state!)}
				<OptimizationSummaryTrigger disabled={!isSummaryAvailable} />
			</div>
		</Fragment>
	)
}
