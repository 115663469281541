import React from 'react'
import { useLocation, useNavigate } from 'react-router'

import { Button } from '@cmpkit/base'

import intl from '@/locale'
import analytic from '@/services/analytics'
import { getRecentlyUsedFilter } from '@/tools/filtering'

function ReecentlyFilter() {
	const location = useLocation()
	const navigate = useNavigate()
	const recentlyFilter = getRecentlyUsedFilter()
	if (!recentlyFilter || location.search == recentlyFilter.search) return null
	const applyRecentlyFilter = () => {
		const recentlyFilter = getRecentlyUsedFilter()
		analytic.logEvent('table: apply latest filter')
		navigate(recentlyFilter.search)
	}
	return (
		<div className='mb-2.5 flex items-center rounded-lg bg-accent-3 p-5'>
			<div>
				<p>{intl.get('recently_filter_available')}</p>
			</div>
			<Button className='ml-auto' onClick={applyRecentlyFilter}>
				{intl.get('recently_filter_apply')}
			</Button>
		</div>
	)
}
export default ReecentlyFilter
