import { any, map, prop } from 'lodash/fp'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Button } from '@cmpkit/base'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { DataOption } from '@/common.types'
import { dialog } from '@/components/dialogs'
import { FormProvider, RHFSelect } from '@/components/HookForm'
import Loader from '@/components/Loader'
import intl from '@/locale'
import { useMassExportMutation } from '@/modules/core/mutations'
import analytic from '@/services/analytics'
import { exportStreamFile } from '@/tools/report'

import { useExportTemplatesQuery } from '../queries'

const ExportFileSchema = Yup.object().shape({
	exportType: Yup.string().required('Export type is required'),

	exportFormat: Yup.string().required('Export Format is required'),
})
const ExportBQSchema = Yup.object().shape({
	exportType: Yup.string().required('Export type is required'),
})

interface Props {
	isOpen: boolean
	isLoading: boolean
	exportTypesOptions: DataOption[]

	exportFormatsOptions: DataOption[]
	onSubmit(data: FormaDataType): void
	onClose(): void
}
type FormaDataType = {
	exportType: string
	exportFormat?: string
}

function OptimizationGroupsModalForm({
	isOpen,
	isLoading,
	exportTypesOptions,
	exportFormatsOptions,
	onSubmit,
	onClose,
}: Props) {
	const methods = useForm<FormaDataType>({
		resolver: (values, options, result) => {
			if (values.exportType === 'file') {
				return yupResolver<FormaDataType>(ExportFileSchema)(
					values,
					options,
					result
				)
			} else {
				return yupResolver<Pick<FormaDataType, 'exportType'>>(ExportBQSchema)(
					values,
					options,
					result
				)
			}
		},
	})
	const { watch, handleSubmit } = methods
	const values = watch()
	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={onClose} showCloseButton size='mini'>
					<ModalHeader>
						<ModalTitle>{intl.get('products_export_modal_title')}</ModalTitle>
					</ModalHeader>

					<ModalBody className='p-5'>
						{isLoading ? (
							<Loader size={30} />
						) : (
							<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
								<RHFSelect
									name='exportType'
									className='w-full'
									rules={{ required: true }}
									label={intl.get('optimization_groups_export_to')}
									options={exportTypesOptions}
								/>
								{/* {values.exportType === 'file' && (
									<RHFSelect
										name='pricingTier'
										className='w-full'
										shouldUnregister
										rules={{ required: values.exportType === 'file' }}
										label={intl.get('optimization_groups_pricelist')}
										options={pricingTiersOptions}
										styles={{
											option: (
												styles: object,
												{
													data,
												}: {
													data: DataOption
												}
											) => ({
												...styles,
												textDecoration:
													data.value === 'all' ? 'underline' : 'none',
												fontWeight: data.value === 'all' ? 'bold' : 'normal',
											}),
										}}
									/>
								)} */}
								{values.exportType === 'file' && (
									<RHFSelect
										className='w-full'
										name='exportFormat'
										shouldUnregister
										rules={{ required: values.exportType === 'file' }}
										label={intl.get('optimization_groups_export_type')}
										options={exportFormatsOptions}
									/>
								)}
							</FormProvider>
						)}
					</ModalBody>
					<ModalFooter className='justify-between'>
						<Button onClick={onClose}>{intl.get('general_cancel')}</Button>
						<Button variant='primary-brand' onClick={handleSubmit(onSubmit)}>
							{intl.get('app.export')}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}
interface OptimizationGroupsModalProps {
	close(): void
	selected: string[]
	isOpen: boolean
}
export default function OptimizationGroupsModal({
	close,
	selected,
	isOpen,
}: OptimizationGroupsModalProps) {
	const { data: templates, ...templatesQuery } = useExportTemplatesQuery<
		DataOption[]
	>({
		placeholderData: [],
		select: map((item) => ({
			value: item.id,
			label: item.name,
		})),
	})
	const mutation = useMassExportMutation({
		onSuccess: () => close(),
	})
	const isLoading = any(prop('isLoading'), [templatesQuery, mutation])
	const exportTypesOptions = [
		{ value: 'file', label: intl.get('optimization_groups_file') },
		{ value: 'bigquery', label: intl.get('optimization_groups_bigquery') },
	]
	const handleDownloadReport = ({ exportFormat }: FormaDataType) => {
		const optimization_groups = selected
		analytic.logEvent('mass repricing: action: export prices', {
			'Mass action by': selected.length > 0 ? 'Selected' : 'All',
		})
		exportStreamFile(exportFormat!, { optimization_groups })
		close()
	}

	const handleBigQueryExport = () =>
		dialog
			.confirm({
				title: intl.get('optimization_groups_confirm_export_title'),
				text: intl.get('optimization_groups_confirm_export_description'),
				okText: intl.get('general_save_changes'),
			})
			.then((answer: boolean) => {
				if (answer) {
					mutation.mutate()
				}
			})

	const handleSubmit = (formData: FormaDataType) => {
		formData.exportType === 'file'
			? handleDownloadReport(formData)
			: handleBigQueryExport()
	}
	return (
		<OptimizationGroupsModalForm
			isOpen={isOpen}
			isLoading={isLoading || mutation.isPending}
			exportTypesOptions={exportTypesOptions}
			exportFormatsOptions={templates!}
			onSubmit={handleSubmit}
			onClose={close}
		/>
	)
}
