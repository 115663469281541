import { AxiosError } from 'axios'

import {
	useMutation,
	UseMutationOptions,
	useQueryClient,
} from '@tanstack/react-query'

import {
	Core,
	CreateOptimizationGroupInput,
	CreateReviewModel,
	CreateScheduleModel,
	ExportTemplateModel1,
	FinalFieldsListSchema,
	HTTPValidationError,
	MultiImportRequestModel,
	OptimizationAction,
	OptimizationGroupModel,
	OptimizationModel,
	ProductDiffsSchema,
	ReviewModel,
	ScheduleModel,
	SendOptimizationActionsRequestModel,
	UpdateOptimizationGroupInput,
	UpdateProductsFinalFieldsErrorResponseModel,
	UpdateProductsFinalFieldsResponseModel,
	UpdateProductsModelFieldsErrorResponseModel,
	UpdateProductsModelFieldsResponseModel,
} from '@/generated'
import { useOptimizationStatus } from '@/hooks/data'
import { NEW, PENDING, UPDATING } from '@/modules/core/constants'
import { client } from '@/network/client'

import CoreService from './service'

export function useBeforeGroupChange(optimizationGroupId?: string | null) {
	const queryClient = useQueryClient()
	const status = useOptimizationStatus(optimizationGroupId)
	const onMutate = async () => {
		if (
			optimizationGroupId &&
			status &&
			![NEW, PENDING, UPDATING].includes(status)
		) {
			await client.core.createOptimization(optimizationGroupId)
			await queryClient.invalidateQueries({
				queryKey: ['optimization', optimizationGroupId],
			})
			await queryClient.invalidateQueries({
				queryKey: ['optimizations'],
			})
		}
	}
	return {
		onMutate,
	}
}

/**
 * Schedule
 */
export function useCreateScheduleMutation() {
	const queryClient = useQueryClient()
	return useMutation<ScheduleModel, HTTPValidationError, CreateScheduleModel>({
		mutationFn: (payload) => {
			return client.api.createSchedule(payload)
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['schedules'],
			})
		},
	})
}
export function useDeleteScheduleMutation() {
	const queryClient = useQueryClient()
	return useMutation<void, HTTPValidationError, string>({
		mutationFn: (payload) => {
			return client.api.deleteSchedule(payload)
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['schedules'],
			})
		},
	})
}
export function useUpdateScheduleMutation() {
	const queryClient = useQueryClient()
	return useMutation<ScheduleModel, HTTPValidationError, CreateScheduleModel>({
		mutationFn: (payload) => {
			return client.api.updateSchedule(payload.id!, payload)
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['schedules'],
			})
		},
	})
}

/**
 * Optimizations
 */
export function useCreateOptimizationMutation(
	options?: UseMutationOptions<OptimizationModel, AxiosError, string>
) {
	return useMutation<OptimizationModel, AxiosError, string>({
		mutationFn: (optimizationGroupId) => {
			return client.core
				.createOptimization(optimizationGroupId)
				.then(({ optimization }) => optimization)
		},
		...(options || {}),
	})
}
export function useUpdateOptimizationWithDiffsMutation(
	optimizationGroupId: string,
	options?: UseMutationOptions<
		UpdateProductsModelFieldsResponseModel,
		AxiosError<UpdateProductsModelFieldsErrorResponseModel>,
		ProductDiffsSchema
	>
) {
	const queryClient = useQueryClient()
	return useMutation<
		UpdateProductsModelFieldsResponseModel,
		AxiosError<UpdateProductsModelFieldsErrorResponseModel>,
		ProductDiffsSchema
	>({
		mutationFn: (payload) => {
			return client.products.updateProductDiffs(
				optimizationGroupId,
				'last',
				payload
			)
		},
		...(options || {}),
		onSuccess() {
			queryClient.removeQueries({
				queryKey: ['repricing-statistic', optimizationGroupId],
			})
			queryClient.removeQueries({
				queryKey: ['interpretability-statistic', optimizationGroupId],
			})
		},
	})
}
export function useUpdateOptimizationFinalFieldsMutation(
	optimizationGroupId: string,
	options?: UseMutationOptions<
		UpdateProductsFinalFieldsResponseModel,
		AxiosError<UpdateProductsFinalFieldsErrorResponseModel>,
		FinalFieldsListSchema
	>
) {
	const queryClient = useQueryClient()
	return useMutation<
		UpdateProductsFinalFieldsResponseModel,
		AxiosError<UpdateProductsFinalFieldsErrorResponseModel>,
		FinalFieldsListSchema
	>({
		mutationFn: (payload) => {
			return client.products.updateFinalFields(
				optimizationGroupId,
				'last',
				payload
			)
		},
		...(options || {}),
		onSuccess() {
			queryClient.removeQueries({
				queryKey: ['repricing-statistic', optimizationGroupId],
			})
			queryClient.removeQueries({
				queryKey: ['interpretability-statistic', optimizationGroupId],
			})
		},
	})
}
export function useStartOptimizationMutation(
	options?: UseMutationOptions<OptimizationModel, AxiosError, string>
) {
	return useMutation<OptimizationModel, AxiosError, string>({
		mutationFn: (optimizationGroupId) => {
			return client.core
				.doOptimizationAction(
					optimizationGroupId,
					'last',
					OptimizationAction.Start
				)
				.then(({ optimization }) => optimization)
		},
		...(options || {}),
	})
}
export function useCommitOptimizationMutation(
	options?: UseMutationOptions<OptimizationModel, AxiosError, string>
) {
	return useMutation<OptimizationModel, AxiosError, string>({
		mutationFn: (optimizationGroupId) => {
			return client.core
				.doOptimizationAction(
					optimizationGroupId,
					'last',
					OptimizationAction.Update
				)
				.then(({ optimization }) => optimization)
		},
		...(options || {}),
	})
}
export function useDeleteExportOptimizationMutation(
	options?: UseMutationOptions<OptimizationModel, AxiosError, string>
) {
	return useMutation<OptimizationModel, AxiosError, string>({
		mutationFn: (optimizationGroupId) => {
			return client.core
				.doOptimizationAction(
					optimizationGroupId,
					'last',
					OptimizationAction.DeleteExport
				)
				.then(({ optimization }) => optimization)
		},
		...(options || {}),
	})
}
export function useStopOptimizationMutation(
	options?: UseMutationOptions<OptimizationModel, AxiosError, string>
) {
	return useMutation<OptimizationModel, AxiosError, string>({
		mutationFn: (optimizationGroupId) => {
			return client.core
				.doOptimizationAction(
					optimizationGroupId,
					'last',
					OptimizationAction.Stop
				)
				.then(({ optimization }) => optimization)
		},
		...(options || {}),
	})
}
export function useLockOptimizationMutation(
	options?: UseMutationOptions<OptimizationModel, AxiosError, string>
) {
	return useMutation<OptimizationModel, AxiosError, string>({
		mutationFn: (optimizationGroupId) => {
			return client.core
				.doOptimizationAction(
					optimizationGroupId,
					'last',
					OptimizationAction.Lock
				)
				.then(({ optimization }) => optimization)
		},
		...(options || {}),
	})
}
export function useUnlockOptimizationMutation(
	options?: UseMutationOptions<OptimizationModel, AxiosError, string>
) {
	return useMutation<OptimizationModel, AxiosError, string>({
		mutationFn: (optimizationGroupId) => {
			return client.core
				.doOptimizationAction(
					optimizationGroupId,
					'last',
					OptimizationAction.Unlock
				)
				.then(({ optimization }) => optimization)
		},
		...(options || {}),
	})
}
export function useBulkOptimizationMutation() {
	const queryClient = useQueryClient()
	return useMutation<
		{
			results?:
				| Record<
						string,
						{
							optimization?: OptimizationModel | undefined
							success?: boolean | undefined
						}
				  >
				| undefined
			success?: boolean | undefined
		},
		Error,
		SendOptimizationActionsRequestModel
	>({
		mutationFn: (payload) => {
			return client.core.sendOptimizationActions(payload)
		},
		async onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['pricing-alerts'],
			})
			return queryClient.invalidateQueries({
				queryKey: ['optimizations'],
			})
		},
	})
}
export function useImportOptimizationGroups() {
	const queryClient = useQueryClient()
	return useMutation<
		Core.MultiImport.ResponseBody,
		Error,
		MultiImportRequestModel
	>({
		mutationFn: async (formData) => {
			const optimizations = queryClient.getQueryData<
				Record<string, OptimizationModel>
			>(['optimizations'])
			await CoreService.resetOptimizations(
				optimizations || {},
				formData.optimization_groups?.split(',') || []
			)
			return await client.core.multiImport(formData)
		},
		async onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['pricing-alerts'],
			})
			return queryClient.invalidateQueries({
				queryKey: ['optimizations'],
			})
		},
	})
}

/**
 * Reviews
 */
export function useCreateReviewMutation() {
	const queryClient = useQueryClient()
	return useMutation<
		ReviewModel,
		HTTPValidationError,
		{
			optimizationGroupId: string
			body: CreateReviewModel
		}
	>({
		mutationFn: (payload) => {
			return client.api.createReview(payload.optimizationGroupId, payload.body)
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['reviews'],
			})
		},
	})
}
export function useUpdateReviewMutation() {
	const queryClient = useQueryClient()
	return useMutation<
		ReviewModel,
		HTTPValidationError,
		{
			optimizationGroupId: string
			body: CreateReviewModel
		}
	>({
		mutationFn: (payload) => {
			return client.api.updateReview(payload.optimizationGroupId, payload.body)
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['reviews'],
			})
		},
	})
}
export function useApproveReviewMutation() {
	const queryClient = useQueryClient()
	return useMutation<ReviewModel, HTTPValidationError, string>({
		mutationFn: (optimizationGroupId) => {
			return client.api.approveReview(optimizationGroupId)
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['reviews'],
			})
		},
	})
}
export function useDeleteReviewMutation() {
	const queryClient = useQueryClient()
	return useMutation<void, HTTPValidationError, string>({
		mutationFn: (optimizationGroupId) => {
			return client.api.deleteReview(optimizationGroupId)
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['reviews'],
			})
		},
	})
}

export const useMassExportMutation = (
	options?: UseMutationOptions<
		{
			objects?: ExportTemplateModel1[] | undefined
		},
		Error
	>
) => {
	return useMutation({
		mutationFn: () => client.core.massExport(),
		...(options || {}),
	})
}

export function useCreateOptimizationGroupMutation(
	options?: UseMutationOptions<
		OptimizationGroupModel,
		AxiosError,
		CreateOptimizationGroupInput
	>
) {
	return useMutation<
		OptimizationGroupModel,
		AxiosError,
		CreateOptimizationGroupInput
	>({
		mutationFn: (data) => {
			return client.core.createOptimizationGroup(data)
		},
		...(options || {}),
	})
}
export function useDeleteOptimizationGroupMutation(
	options?: UseMutationOptions<void, AxiosError, string>
) {
	return useMutation<void, AxiosError, string>({
		mutationFn: (id) => {
			return client.core.deleteOptimizationGroup(id)
		},
		...(options || {}),
	})
}
export function useUpdateOptimizationGroupMutation(
	id: string,
	options?: UseMutationOptions<
		OptimizationGroupModel,
		AxiosError,
		UpdateOptimizationGroupInput
	>
) {
	return useMutation<
		OptimizationGroupModel,
		AxiosError,
		UpdateOptimizationGroupInput
	>({
		mutationFn: (data) => {
			return client.core.updateOptimizationGroup(id, data)
		},
		...(options || {}),
	})
}
