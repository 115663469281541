import { flatten, map, pipe, size, uniq } from 'lodash/fp'
import React, { MouseEvent } from 'react'
import ContentLoader from 'react-content-loader'
import { useNavigate } from 'react-router'

import { Button, LinkButton, Spinner } from '@cmpkit/base'
import CalendarIcon from '@cmpkit/icon/lib/glyph/calendar'
import PenEditIcon from '@cmpkit/icon/lib/glyph/pen-edit'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'
import { useSchedulesQuery } from '@/modules/core/queries'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'

export const Skeleton = () => (
	<ContentLoader
		speed={2}
		width={120}
		height={46}
		viewBox={`0 0 ${120} ${46}`}
		backgroundColor='var(--cmp-accent-4)'
		foregroundColor='var(--cmp-accent-3)'
	>
		<rect x='0' y='0' rx='10' ry='10' width={85} height={23} />
		<rect x='0' y='26' rx='10' ry='10' width={120} height={20} />
	</ContentLoader>
)
const groupsCount = pipe([map('optimization_groups'), flatten, uniq, size])

export default function OptimizationScheduleWidget({
	isActive,
}: {
	isActive: boolean
}) {
	const { showModal } = useModalStore()
	const navigate = useNavigate()
	const schedules = useSchedulesQuery()
	const handleCreateNewClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		showModal('OPTIMIZATION_SCHEDULE_MODAL')
	}
	const handleShowListClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		showModal('OPTIMIZATION_SCHEDULE_LIST_MODAL')
	}
	const handleFilterOgsClick = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault()
		analytic.logEvent('autorun: Filter scheduled OGs')
		navigate('/p/og?schedule.id__is_not_empty=1')
	}

	if (schedules.data?.length == 0) {
		if (schedules.isLoading) {
			return <Skeleton />
		}
		return (
			<Button onClick={handleCreateNewClick} iconBefore={<CalendarIcon />}>
				{intl.get('schedule_repricing')}
			</Button>
		)
	} else {
		return (
			<div
				data-active={isActive}
				onClick={handleFilterOgsClick}
				className={
					'cursor-pointer rounded-lg px-3 py-1 transition-all hover:bg-neutral-25 data-[active=true]:bg-neutral-25 hover:dark:bg-neutral-300 data-[active=true]:dark:bg-neutral-300'
				}
			>
				<h4 className='flex items-center gap-2'>
					{intl.get('general_schedule')}
					<Tooltip content={intl.get('general_create')}>
						<LinkButton onClick={handleCreateNewClick}>
							<CalendarIcon />
						</LinkButton>
					</Tooltip>
					<Tooltip content={intl.get('general_edit')}>
						<LinkButton onClick={handleShowListClick}>
							<PenEditIcon />
						</LinkButton>
					</Tooltip>
				</h4>
				<div className='mt-1 flex items-center text-xs font-medium text-muted'>
					{schedules.isLoading ? (
						<Spinner className='mr-2.5' />
					) : (
						groupsCount(schedules.data)
					)}{' '}
					{intl.get('scheduled_ogs')}
				</div>
			</div>
		)
	}
}
