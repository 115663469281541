import clsx from 'clsx'
import { intlFormat } from 'date-fns'
import { isNil, merge, negate, omitAll, pipe } from 'lodash/fp'
import * as qs from 'qs'
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useNavigate, useParams } from 'react-router'
import { useDebounceValue } from 'usehooks-ts'

import { ErrorBoundary } from '@sentry/react'
import {
	createColumnHelper,
	ExpandedState,
	getCoreRowModel,
	getExpandedRowModel,
	getSortedRowModel,
	SortingState,
} from '@tanstack/react-table'

import {
	Button,
	buttonVariants,
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	Content,
	Footer,
	Header,
	Layout,
	LinkButton,
	Loader,
	Pagination,
	StateButton,
	usePagination,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import FilterIcon from '@cmpkit/icon/lib/glyph/filter'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import StarIcon from '@cmpkit/icon/lib/glyph/star'
import StarFilledIcon from '@cmpkit/icon/lib/glyph/star-filled'
import {
	FilterRuleEntity,
	Operators,
	ValueEditorType,
	ValueType,
} from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import CompactSearch from '@/components/CompactSearch'
import { DatetimeField } from '@/components/data-grid/fields'
//import { decodeComplexQuery } from '@/components/data-grid/helpers'
import FiltersPopover from '@/components/filter/FiltersPopover'
import NoData from '@/components/placeholders/NoData'
import { useMasterTable } from '@/components/table/hooks/useMasterTable'
import MasterTable from '@/components/table/MasterTable'
import UsernameById from '@/components/UsernameById'
import {
	FilterRuleModel,
	SettingsTemplateModel,
	SettingsTemplateQueryModel,
	SettingsTemplateType,
} from '@/generated'
import { useLocationStateManager } from '@/hooks/useLocationStateManager'
import { queryClient } from '@/lib/query-client'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'
import { useAllUsersQuery } from '@/modules/Users/queries'
import { getUsername } from '@/modules/Users/utils'

import { useUpdateSettingsTemplateMutation } from '../mutations'
import { useSettingsTemplatesQuery } from '../queries'
import { SettingsTemplateModelType } from '../types'
import SettingsTemplateActions from './SettingsTemplateActions'
import {
	SettingsTemplateGenaratedShortDescription,
	SettingsTemplateGeneratedCardContent,
} from './SettingsTemplateGenaratedDescription'

export function SettingsTemplatesPage() {
	const { type } = useParams()

	if (!type) {
		return null
	}
	return <TemplatesPage type={type as SettingsTemplateType} />
}

export function TemplatesPage({ type }: { type: SettingsTemplateType }) {
	const hardFilters = useMemo(
		() => [
			{
				name: 'template_type',
				operation: Operators.IN,
				value: [type],
			},
		],
		[type]
	)
	const nvaigate = useNavigate()
	const { filters } = useLocationStateManager()
	const { showModal } = useModalStore()
	const [rules, setRules] = useState<FilterRuleModel[]>([])
	const [searchText, setSearchText] = useState('')
	const [searchTextDebounced] = useDebounceValue(searchText, 1000)
	const locationQuery = useMemo(
		() => qs.parse(location.search, { ignoreQueryPrefix: true }),
		[location.search]
	)
	const handleChangePagination = useCallback(
		(paginationMeta: { limit?: number; offset?: number }) => {
			nvaigate(
				qs.stringify(
					pipe([omitAll(['limit', 'offset']), merge(paginationMeta)])(
						locationQuery
					),
					{
						addQueryPrefix: true,
					}
				)
			)
		},
		[locationQuery]
	)
	const requestBody: SettingsTemplateQueryModel = {
		limit: Number(locationQuery?.limit) || 100,
		offset: Number(locationQuery?.offset) || 0,
		filters: [
			...hardFilters,
			...filters,
			...rules,
			searchTextDebounced?.length
				? {
						name: 'name',
						operation: Operators.CONTAINS,
						value: searchTextDebounced,
					}
				: null,
		].filter(negate(isNil)) as FilterRuleEntity[],
		order_by: [
			{ name: 'is_favorite', direction: 'desc' },
			{ name: 'name', direction: 'asc' },
		],
	}

	const usersQuery = useAllUsersQuery()
	const templatesQuery = useSettingsTemplatesQuery(requestBody)

	const _handleSearchChange = (e: ChangeEvent<HTMLInputElement>) =>
		setSearchText(e.target.value)
	const _handleClearInputClick = () => setSearchText('')
	const dataChoices = useMemo(
		() => ({
			updated_by:
				usersQuery.data?.map((user) => ({
					label: getUsername(user),
					value: user.id,
				})) || [],
		}),
		[usersQuery.data]
	)
	const filterFields = useMemo(() => getFilterFields(), [])
	/**
	 * Pagination business logic
	 */
	const pagination = usePagination({
		total: templatesQuery.data?.total ?? 0,
		limit: Number(requestBody.limit),
		offset: Number(requestBody.offset),
		onChange: handleChangePagination,
	})
	const data = templatesQuery.data?.data || []
	const isLoading = templatesQuery.isLoading

	return (
		<Layout>
			<Header className='my-3 flex items-center justify-between px-4'>
				<div className='mr-auto flex items-center space-x-2'>
					<CompactSearch
						onClear={_handleClearInputClick}
						value={searchText}
						onChange={_handleSearchChange}
						placeholder={intl.get('general_search')}
					/>
					<FiltersPopover
						fields={filterFields}
						onApply={setRules}
						dataChoices={dataChoices}
						filters={rules || []}
					>
						<StateButton
							data-testid='table-filters-button'
							size='small'
							iconBefore={<FilterIcon />}
							onClear={() => setRules([])}
							active={!!rules.length}
						>
							{`${intl.get('general_filter')}`}{' '}
							{!!rules.length ? `(${rules.length})` : ''}
						</StateButton>
					</FiltersPopover>
				</div>
				<Tooltip content={intl.get('general_create')}>
					{type === SettingsTemplateType.Scenario ? (
						<Link
							to='../editor'
							data-testid='create-settings-template-trigger'
							className={buttonVariants({
								variant: 'primary-brand',
								icon: true,
							})}
						>
							<PlusIcon />
						</Link>
					) : (
						<Button
							data-testid='create-settings-template-trigger'
							variant='primary-brand'
							iconBefore={<PlusIcon />}
							onClick={() =>
								showModal('SETTINGS_TEMPLATE_MODAL', {
									templateType: type,
								})
							}
						/>
					)}
				</Tooltip>
			</Header>
			<Content
				className={clsx(
					'px-4',
					[SettingsTemplateType.Scenario].includes(type)
						? 'overflow-y-auto'
						: 'overflow-hidden'
				)}
			>
				<Blanket
					className='absolute flex flex-col items-center justify-center space-y-5 rounded-lg bg-transparent'
					isTinted={isLoading}
				>
					<div className='fade-in z-50 rounded-lg bg-white/50 p-5 shadow backdrop-blur-md dark:bg-black/50'>
						<Loader />
					</div>
				</Blanket>
				{!isLoading && [SettingsTemplateType.Scenario].includes(type) ? (
					<SettingsTemplatesLayout data={data} />
				) : (
					<SettingsTemplatesTable data={data as SettingsTemplateModelType[]} />
				)}
			</Content>
			<Footer className='px-5 py-2'>
				{pagination && (
					<div className='mr-auto flex items-center gap-6'>
						<Pagination pagination={pagination} />
					</div>
				)}
			</Footer>
		</Layout>
	)
}
const getFilterFields = () => [
	{
		value: 'name',
		label: intl.get('app.name').d('Name'),
		valueType: ValueType.str,
		valueEditorType: ValueEditorType.text,
		enum: [],
	},
	{
		value: 'updated_by',
		label: intl.get('last_modified_by'),
		valueType: ValueType.str,
		operations: [Operators.IN],
		valueEditorType: ValueEditorType.multiselect,
		enum: [],
	},
	{
		value: 'updated_at',
		label: intl.get('last_modified_at'),
		valueType: ValueType.datetime,
		valueEditorType: ValueEditorType.daterange,
		enum: [],
	},
]
function SettingsTemplatesLayout({ data }: { data: SettingsTemplateModel[] }) {
	if (!data.length) {
		return (
			<NoData
				title={intl.get('general_no_templates').d('There is no templates')}
			/>
		)
	}
	return (
		<div className='flex h-fit w-full flex-wrap overflow-hidden bg-accent-1'>
			{data.map((template: SettingsTemplateModel, i) => (
				<SettingsTemplateCard key={`${i}_${template.id}`} template={template} />
			))}
		</div>
	)
}
function SettingsTemplateCard({
	template,
}: {
	template: SettingsTemplateModel
}) {
	const updateTemplateMutation = useUpdateSettingsTemplateMutation(
		template.id,
		{
			onSuccess: (result) => {
				queryClient.invalidateQueries({
					queryKey: ['settings-templates'],
				})
				queryClient.setQueryData(
					['settings-templates'],
					(outdated?: SettingsTemplateModel[]) =>
						outdated?.map((item) => (template.id === item.id ? result : item))
				)
				toast.success(
					intl.get('toast.entity.updated', {
						entity: intl.get('entity.template'),
					}),
					{
						duration: 5000,
						id: 'settigs-template',
					}
				)
			},
		}
	)
	const handleToggleFavorite = async () => {
		const data = {
			is_favorite: !template.is_favorite,
		}
		await updateTemplateMutation.mutateAsync(data)
	}
	return (
		<div className='w-1/3 p-2'>
			<Card
				className='min-h-36 border shadow-[0px_4px_8px_0px_rgba(20,42,69,0.10)]'
				data-testid='settings-template-card'
			>
				<CardHeader className='p-4 pb-2'>
					<div className='flex items-center justify-between'>
						<LinkButton asChild variant='brand'>
							<Link
								to={`../editor/${template.id}`}
								className='block max-w-64 overflow-hidden text-ellipsis text-nowrap'
							>
								{template.name}
							</Link>
						</LinkButton>
						<div>
							<Button
								variant='tertiary'
								disabled={updateTemplateMutation.isPending}
								iconBefore={
									template.is_favorite ? (
										<StarFilledIcon className='text-warning' />
									) : (
										<StarIcon />
									)
								}
								onClick={handleToggleFavorite}
							/>
							<SettingsTemplateActions template={template} />
						</div>
					</div>
				</CardHeader>
				<CardContent className='m-0 px-4 pb-2 text-xl'>
					<SettingsTemplateGeneratedCardContent template={template} />
				</CardContent>
				<CardFooter className='gap-2 px-4 pb-4'>
					<span className='text-xs text-muted'>
						{`${intl.get('updated')}: ${template.updated_at ? intlFormat(new Date(template.updated_at + 'Z')) : '-'} `}{' '}
					</span>
					<div className='flex items-center text-xs text-muted'>
						<span className='mr-1'>{`${intl.get('by')} `}</span>
						{template.updated_by ? (
							<UsernameById userId={template.updated_by} />
						) : (
							<>{intl.get('app.unknown').d('Unknown')}</>
						)}
					</div>
				</CardFooter>
			</Card>
		</div>
	)
}

export function SettingsTemplatesTable({
	data,
}: {
	data: SettingsTemplateModelType[]
}) {
	const [sorting, setSorting] = useState<SortingState>([
		{
			id: 'updated_at',
			desc: true,
		},
	])
	const columns = useColumns()
	const [expanded, setExpanded] = React.useState<ExpandedState>({})
	const table = useMasterTable<SettingsTemplateModelType>({
		data,
		columns,
		state: {
			sorting,
			expanded,
		},
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		enableColumnPinning: false,
		enableExpanding: true,
		enableVirtualization: false,
		onExpandedChange: setExpanded,
		getRowHeight: () => 100,
		getRowCanExpand: () => true,
		getExpandedRowModel: getExpandedRowModel(),
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return (
		<div className='flex h-full flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
			<MasterTable table={table} />
		</div>
	)
}
const columnHelper = createColumnHelper<SettingsTemplateModelType>()
function useColumns() {
	return useMemo(
		() => [
			columnHelper.accessor('name', {
				enableSorting: true,
				header: () => intl.get('app.name').d('Name'),
				cell: (info) => {
					const { showModal } = useModalStore()
					return (
						<div className='flex items-start py-2'>
							<div className='mt-0.5 flex flex-col gap-1 truncate'>
								{info.row.original.template_type ===
								SettingsTemplateType.Scenario ? (
									<LinkButton asChild variant='brand' className='truncate'>
										<Link to={`../editor/${info.row.original.id}`}>
											{info.getValue()}
										</Link>
									</LinkButton>
								) : (
									<LinkButton
										variant='brand'
										className='truncate'
										onClick={() =>
											showModal('SETTINGS_TEMPLATE_MODAL', {
												templateId: info.row.original.id,
												templateType: info.row.original.template_type,
											})
										}
									>
										{info.getValue()}
									</LinkButton>
								)}
								<div className='truncate'>
									<ErrorBoundary>
										<SettingsTemplateGenaratedShortDescription
											template={info.row.original}
										/>
									</ErrorBoundary>
								</div>
							</div>
						</div>
					)
				},
			}),
			columnHelper.accessor('template_type', {
				size: 220,
				enableSorting: true,
				enablePinning: false,
				header: () => intl.get('template_type').d('Template type'),
				cell: (info) => {
					return <span>{intl.get(`template.type.${info.getValue()}`)}</span>
				},
			}),
			columnHelper.accessor('created_at', {
				size: 120,
				enableSorting: true,
				enablePinning: false,
				header: () => intl.get('created_at').d('Created at'),
				cell: (info) => {
					return <DatetimeField value={info.getValue()} />
				},
			}),
			columnHelper.accessor('updated_at', {
				enableSorting: true,
				enablePinning: false,
				size: 120,
				header: () => intl.get('updated_at').d('Updated at'),
				cell: (info) => {
					return <DatetimeField value={info.getValue()} />
				},
			}),
			columnHelper.accessor('id', {
				size: 56,
				enablePinning: false,
				enableSorting: true,
				header: () => null,
				cell: (info) => {
					return <SettingsTemplateActions template={info.row.original} />
				},
			}),
		],
		[]
	)
}
