import { find, prop, size, throttle, uniqBy } from 'lodash/fp'
import * as React from 'react'
import toast from 'react-hot-toast'
import { useLocalStorage } from 'usehooks-ts'

import { useQueryClient } from '@tanstack/react-query'

import { Button, LinearProgress } from '@cmpkit/base'
import RedoIcon from '@cmpkit/icon/lib/glyph/redo'

import notify from '@/components/toasts'
import { OptimizationGroupModel, OptimizationStatus } from '@/generated'
import intl from '@/locale'
import { Channel, Socket } from '@/network/websocket'
import { SOCKET_EVENTS } from '@/network/websocket/constants'
import Observer from '@/network/websocket/observer'
import { Message } from '@/network/websocket/types'
import logger from '@/tools/logger'
import { PENDING, UPDATING } from '@/modules/core/constants'
import Deferred from '@/network/websocket/deferred'

export const socket = new Socket(`${location.host}/api/v1`, {
	params: { env: 'dev' },
	transport: WebSocket,
	encode: (data: object, cb: (v: string) => void) => cb(JSON.stringify(data)),
	decode: (data: string, cb: (v: object) => void) => cb(JSON.parse(data)),
	logger: (kind: string, msg: unknown, data: unknown) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		logger.debug('CONN', `${kind}: ${msg}`, data as any)
	},
})
export function useSocketChannel(topic: string) {
	const channel = React.useRef<Channel | undefined>(undefined)
	React.useEffect(() => {
		socket.on('channels', (channels: Channel[]) => {
			channel.current = channels.find((channel) => channel.topic === topic)
		})
		channel.current = socket.channels.find((channel) => channel.topic === topic)
	}, [topic])

	return channel.current
}
export const SocketContext = React.createContext<Socket | null>(null)

export type OptimizationUpdateEventPayload = {
	optimization_id: string
	optimization_group_id: string
	optimization_status: string
	action?: string
	run_type: 'default' | 'dry_run'
	iteration_id?: string
}
type ExportUpdateEventPayload = {
	id: string
	name: string
	status: string
}

// Optimization update event observer
const $optimizationUpdate = new Observer()

/**
 * Listen for optimization group status change and wait for it to change to one of the specified statuses
 * @param id - optimization group id to wait for
 * @param statuses - statuses to wait for
 * @returns promise - promise that resolves when optimization group status changes to one of the specified statuses
 */
export function waitGroupStatus(id: string, statuses: string[]) {
	const deferred = new Deferred()
	const handler = (msg: Message<OptimizationUpdateEventPayload>) => {
		if (statuses.includes(msg.payload.optimization_status)) {
			deferred.resolve(msg.payload.optimization_status)
			$optimizationUpdate.off(`optimization:${id}:update`, handler)
		}
	}
	$optimizationUpdate.on(`optimization:${id}:update`, handler)
	return deferred.promise
}
type CallbackExecutor = {
	selector: (msg: Message<OptimizationUpdateEventPayload>) => boolean
	action: (msg: Message<OptimizationUpdateEventPayload>) => void
}
/**
 * Hook for subscribing to optimization update event for specific optimization group
 * @param optimizationGroupId - optimization group id to subscribe
 * @returns - function to register callback for optimization update event
 */
export function useOptimizationCallback(optimizationGroupId: string) {
	const callbacks = React.useRef<CallbackExecutor[]>([])
	const handler = React.useCallback(
		(msg: Message<OptimizationUpdateEventPayload>) => {
			logger.debug('WS CALLBACK', 'optimization:update', msg)
			callbacks.current.forEach((callback) => {
				if (callback.selector(msg)) {
					callback.action(msg)
				}
			})
		},
		[]
	)
	React.useEffect(() => {
		$optimizationUpdate.on(
			`optimization:${optimizationGroupId}:update`,
			handler
		)
		return () => {
			$optimizationUpdate.off(
				`optimization:${optimizationGroupId}:update`,
				handler
			)
		}
	}, [])

	/**
	 * Function to register callback for optimization update event
	 * @example useOptimizationCallback('optimizationGroupId')((msg) => msg.payload.optimization_status === 'finished', (msg) => {})
	 * @param {function} selector - function to check if callback should be called
	 * @param {function} action - callback function
	 */
	return (
		selector: CallbackExecutor['selector'],
		action: CallbackExecutor['action']
	) => {
		callbacks.current.push({ selector, action } as CallbackExecutor)
	}
}

type NotifyMessage = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	msgBody: any
	optimizationGroup?: OptimizationGroupModel
	showButton?: boolean
	onClick?: () => void
}
type AddMessageProps = {
	msgBody: Message
	optimizationGroup?: OptimizationGroupModel
	showButton?: boolean
	onClick?: () => void
}
enum ConnectionStatus {
	CONNECTED = 'connected',
	DISCONNECTED = 'disconnected',
}

export class SocketMessagesNotification {
	private _messages: NotifyMessage[] = []
	private _active = true
	public addMessage({
		msgBody,
		optimizationGroup,
		showButton = false,
		onClick,
	}: AddMessageProps) {
		if (!this._active) return
		this._messages.push({
			msgBody,
			optimizationGroup,
			showButton,
			onClick,
		})
		this.showMessages()
	}
	public subscribe() {
		this._active = true
	}
	public unsubscribe() {
		this._active = false
	}
	public showMessages = throttle(5000, () => {
		if (!this._active) return
		if (this._messages.length === 0) return
		if (
			this._messages.length === 1 &&
			this._messages[0].optimizationGroup?.name
		) {
			const { msgBody, optimizationGroup } = this._messages[0]
			const statusText = intl
				.get(`opt_${msgBody.payload.optimization_status}`)
				.d(msgBody.payload.optimization_status)
			const content = {
				title: intl
					.get('notification.og_status_changed.title', {
						name: optimizationGroup?.name,
					})
					.d(`Optimization group "${optimizationGroup?.name}"`),
				text: intl
					.get('notification.og_status_changed.body', {
						status: statusText,
					})
					.d(`status was changed to "${statusText}".`),
			}
			if (msgBody.payload.optimization_status === 'finished') {
				notify.success(content, { id: msgBody.payload.optimization_group_id })
			} else {
				notify.info(content, { id: msgBody.payload.optimization_group_id })
			}
		} else {
			const count = size(
				uniqBy(prop('msgBody.payload.optimization_group_id'), this._messages)
			)
			const showRefreshButton =
				this._messages[0].showButton && this._messages[0].onClick
			const handleClick = this._messages[0]?.onClick
			const toastId = 'common'
			const content = {
				text: showRefreshButton ? (
					<div className='space-y-1'>
						<div>
							{intl
								.get('notification.count_of_og_updated.title', { count })
								.d(`${count} Optimization groups were updated.`)}
						</div>
						<Button
							size='small'
							onClick={() => {
								handleClick?.()
								toast.dismiss(toastId)
							}}
							iconBefore={<RedoIcon />}
						>
							{intl.get('general_refresh').d('Refresh')}
						</Button>
					</div>
				) : (
					intl
						.get('notification.count_of_og_updated.title', { count })
						.d(`${count} Optimization groups were updated.`)
				),
			}
			notify.info(content, {
				id: toastId,
				duration: showRefreshButton ? Infinity : 0,
			})
		}
		this._messages = []
	})
}

export const socketMessagesNotification = new SocketMessagesNotification()

export default function SocketProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const [connectionStatus, setConnectionStatus] =
		React.useState<ConnectionStatus | null>(null)
	const queryClient = useQueryClient()
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [newExports, setNewExports] = useLocalStorage(
		'new_exports_unreaded',
		false
	)
	const resetCommonQueries = React.useCallback(
		throttle(1000, () => {
			queryClient.invalidateQueries({
				queryKey: ['optimizations'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-alerts'],
			})
			queryClient.invalidateQueries({
				queryKey: ['products-counts-by-pc'],
			})
		}),
		[queryClient]
	)
	React.useEffect(() => {
		logger.debug('WS', 'Create new Socket')
		socket.connect()
		socket.on(SOCKET_EVENTS.open, () =>
			setConnectionStatus(ConnectionStatus.CONNECTED)
		)
		socket.on(SOCKET_EVENTS.close, () =>
			setConnectionStatus(ConnectionStatus.DISCONNECTED)
		)
		socket.on(SOCKET_EVENTS.error, () =>
			setConnectionStatus(ConnectionStatus.DISCONNECTED)
		)
		socket.on(SOCKET_EVENTS.message, (message: Message) => {
			logger.debug('WS', 'new message', message)
		})

		// Authenticate socket
		socket.auth.authenticate(() => {
			const optimizationChannel = socket.channel('optimization')
			const exportsChannel = socket.channel('export')
			// Subscribe to exports channel to receive updates
			exportsChannel.subscribe()
			// Subscribe to optimization channel to receive updates
			optimizationChannel.subscribe()
			exportsChannel.on('update', (msg: Message<ExportUpdateEventPayload>) => {
				queryClient.invalidateQueries({
					queryKey: ['exports'],
				})
				if (msg.payload.status === 'started') {
					notify.loading(
						{
							text: intl
								.get('export.started.body', { name: msg.payload.name })
								.d(`Export "${msg.payload.name}" was started`),
						},
						{ id: msg.payload.id }
					)
				} else if (msg.payload.status === 'finished') {
					notify.success(
						{
							text: intl
								.get('export.finished.body', { name: msg.payload.name })
								.d(`Export "${msg.payload.name}" was finished`),
						},
						{ id: msg.payload.id, duration: 5000 }
					)
					setNewExports(true)
				}
			})
			// Handle optimization update event
			optimizationChannel.on(
				'update',
				(msg: Message<OptimizationUpdateEventPayload>) => {
					sessionStorage.setItem('outdated', 'true')

					// Check if optimization update is dry run (unimportant)
					if (msg.payload.run_type === 'dry_run') {
						// Skip dry run optimization updates
						return
					}
					logger.debug('WS', 'optimization:update', msg)

					// Fire event for optimization group id
					$optimizationUpdate.fireEvent(
						`optimization:${msg.payload.optimization_group_id}:update`,
						msg
					)

					// Get optimization group name
					const optimizationGroup = find(
						{ id: msg.payload.optimization_group_id },
						queryClient.getQueryData(['optimization-groups']) || []
					) as OptimizationGroupModel

					// Reset all releated queries
					queryClient.invalidateQueries({
						queryKey: ['optimization', msg.payload.optimization_group_id],
					})

					if (
						![UPDATING, PENDING].includes(
							msg.payload.optimization_status as OptimizationStatus
						)
					) {
						queryClient.invalidateQueries({
							queryKey: [
								'og-assortment-list',
								msg.payload.optimization_group_id,
							],
						})
					}

					resetCommonQueries()

					// Show notification about optimization status change for optimization group
					socketMessagesNotification.addMessage({
						msgBody: msg,
						optimizationGroup,
					})
				}
			)
		})
		return () => {
			socket.disconnect()
		}
	}, [])
	return (
		<SocketContext.Provider value={socket}>
			{connectionStatus !== ConnectionStatus.CONNECTED && (
				<LinearProgress className='pos-fix' />
			)}
			{children}
		</SocketContext.Provider>
	)
}
