import React, { ChangeEvent } from 'react'

import { Button } from '@cmpkit/base'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'

import InputAffix from './InputAffix'

type Props = {
	className?: string
	value: string
	onChange(e: React.ChangeEvent<HTMLInputElement>): void
	placeholder?: string
}
export default function SearchInput({
	className,
	value,
	onChange,
	placeholder,
}: Props) {
	return (
		<InputAffix
			className={className}
			suffix={
				value?.length > 0 ? (
					<Button
						iconBefore={<CrossIcon />}
						className='btn-text-icon'
						size='small'
						onClick={() =>
							onChange({
								target: { value: '' },
							} as ChangeEvent<HTMLInputElement>)
						}
						variant='tertiary'
					/>
				) : (
					<SearchIcon className='ml-2.5 text-muted' />
				)
			}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
		/>
	)
}
