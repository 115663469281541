import clsx from 'clsx'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'

import { Result } from '@cmpkit/base'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import { FilterRuleEntity } from '@cmpkit/query-builder'

import CollapsibleSection from '@/components/CollapsibleSection'
import ErrorBoundary from '@/components/ErrorBoundary'
import intl from '@/locale'
import { OptimizationIDModel } from '@/generated'
import analytic from '@/services/analytics'

import { BusinessMetricsTable } from './BusinessMetricTable'
import OptimizationMetricsWidget from './OptimizationMetricsGlobalWidget'

export const OptimizationTab = (props: {
	queryParams: {
		optimizations: OptimizationIDModel[]
		filters: FilterRuleEntity[]
	} | null
	isLoading: boolean
}) => {
	useEffect(() => {
		analytic.logEvent('opt summary glob: open optimization tab')
	}, [])
	return (
		<div className='flex w-full flex-col divide-y divide-solid divide-base bg-accent-1 px-5 pb-8 pt-4'>
			<ErrorBoundary>
				<CollapsibleSection
					className={'cmp-border-top pb-4 pt-3'}
					isOpen
					header={
						<h4>
							{intl.get('opt.summary.metrics_forecast').d('Metrics forecast')}
						</h4>
					}
				>
					<div className='space-y-3'>
						<OptimizationMetricsWidget {...props} />
						<BusinessMetricsTable {...props} />
					</div>
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-3'
					isOpen
					header={
						<h4>
							{intl
								.get('elasticity.distribution')
								.d('Distribution of elasticity')}
						</h4>
					}
					subtitle={intl
						.get('elasticity.distribution.subtitle')
						.d('Click on tab to see different additional insights.')}
				>
					<div className='space-y-3'>
						<NoData />
						<NoData />
					</div>
				</CollapsibleSection>
			</ErrorBoundary>
		</div>
	)
}

const NoData = () => (
	<div className='flex h-40 w-full items-center justify-center rounded-lg bg-accent-4 px-5'>
		<Result
			icon={<SearchIcon width={56} height={56} className='fill-neutral-125' />}
			subtitle={intl.get('no_data').d('Looks like there’s no data.')}
		/>
	</div>
)

export const TabContent = ({
	children,
	className,
}: {
	children: React.ReactNode
	className?: string
}) => {
	return (
		<motion.div
			initial={{ opacity: 0, x: 100 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: -30 }}
			className={clsx(
				'flex size-full flex-col divide-y divide-solid divide-base',
				className
			)}
			transition={{
				duration: 0.2,
			}}
		>
			{children}
		</motion.div>
	)
}
