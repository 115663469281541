import clsx from 'clsx'
import React, { FocusEvent, MouseEvent, useRef, useState } from 'react'

import { Textfield } from '@cmpkit/base'

interface InputAffixProps {
	prefix?: React.ReactNode
	suffix?: React.ReactNode
	className?: string
	onBlur?(e: FocusEvent<HTMLInputElement>): void
	onFocus?(e: FocusEvent<HTMLInputElement>): void
	style?: React.CSSProperties
	[e: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}
export default function InputAffix({
	prefix,
	suffix,
	className,
	onBlur,
	onFocus,
	style,
	...props
}: InputAffixProps) {
	const [focused, setFocused] = useState(false)
	const wrapper = useRef<HTMLDivElement | null>(null)
	const input = useRef<HTMLInputElement | null>(null)
	const onMouseUp = (e: MouseEvent<HTMLInputElement>) => {
		if (wrapper.current?.contains?.(e.target as Node)) {
			setFocused(true)
			input.current?.focus()
		}
	}
	const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
		setFocused(true)
		onFocus?.(e)
	}
	const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
		setFocused(false)
		onBlur?.(e)
	}
	return (
		<div
			style={style}
			className={clsx('input-affix-wrapper', className, {
				'input-affix-wrapper-focused': focused,
			})}
			ref={wrapper}
			onMouseUp={onMouseUp}
		>
			<Textfield
				{...props}
				iconBefore={prefix}
				iconAfter={suffix}
				onFocus={handleFocus}
				onBlur={handleBlur}
				ref={input}
			/>
		</div>
	)
}
