import React, { useState } from 'react'

import { Spinner } from '@cmpkit/base'
import Select, { InputActionMeta, SelectProps } from '@cmpkit/select'

import intl from '@/locale'

import { OptionType } from '../types'
import { Control, DropdownIndicator, Menu, Option, Text } from './common'

type BaseSelectProps = Omit<
	SelectProps<OptionType, true>,
	'components' | 'filterOption'
> & {
	isLoading?: boolean
	filterOption?(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		option: any, //OptionType & { data: OptionType },
		rawInput: string
	): boolean
	onMenuScrollToBottom?(): void
	onMenuScrollToTop?(): void
	components?: Record<string, React.FunctionComponent<any> | any> // eslint-disable-line @typescript-eslint/no-explicit-any
	placeholder?: string
	closeMenuOnSelect?: boolean
}
export const selectComponents = {
	Control,
	DropdownIndicator,
	Menu,
	Option,
	IndicatorSeparator: null,
	LoadingIndicator: null,
}
export function BaseSelect({
	inputValue: _inputValue,
	...props
}: BaseSelectProps) {
	const [inputValue, setInputValue] = useState<string>(_inputValue || '')

	const handleInputChange = (inputValue: string, meta: InputActionMeta) => {
		if (props.onInputChange) {
			props.onInputChange(inputValue, meta)
		}
		if (meta.action === 'set-value') {
			return
		}
		setInputValue(inputValue)
	}

	return (
		<Select<OptionType, true>
			backspaceRemovesValue={false}
			closeMenuOnSelect={false}
			controlShouldRenderValue={false}
			hideSelectedOptions={false}
			isClearable={false}
			isMulti
			menuIsOpen
			menuShouldScrollIntoView={false}
			tabSelectsValue={false}
			noOptionsMessage={noOptionsMessage}
			loadingMessage={loadingMessage}
			inputValue={inputValue}
			{...props}
			onInputChange={handleInputChange}
		/>
	)
}

const noOptionsMessage = () => (
	<Text>{intl.get('no_matches_found').d('No matches found')}</Text>
)
const loadingMessage = () => (
	<div className='flex items-center justify-center space-x-2'>
		<Spinner />
		<Text>{intl.get('loading').d('Loading...')}</Text>
	</div>
)
