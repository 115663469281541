import clsx from 'clsx'
import React from 'react'

type ProgressBarProps = {
	className?: string
	value: number
}
export default function ProgressBar({ className, value }: ProgressBarProps) {
	return (
		<div
			className={clsx('progress-bar', className)}
			role='progressbar'
			style={{ width: `${value}%` }}
			aria-value-now={value}
			aria-value-min='0'
			aria-value-max='100'
		/>
	)
}
