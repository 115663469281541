import clsx from 'clsx'

import Tooltip from '@cmpkit/tooltip'

import { Progress, ProgressBar } from '@/components/Progress'

export default function ProgressRow({
	label,
	onClick,
	absoluteValue,
	relativeValue,
	extraValue,
	barClassName,
	disableTooltip,
}: {
	onClick?: () => void
	label: string
	relativeValue: number
	absoluteValue: number | string
	extraValue?: number | string
	barClassName?: string
	disableTooltip?: boolean
}) {
	return (
		<div className='flex w-full flex-col'>
			<div
				onClick={onClick}
				className={clsx(
					'mb-1 flex items-center justify-between text-xs font-normal',
					{
						'cursor-pointer hover:opacity-70': !!onClick,
					}
				)}
			>
				<span>{label}</span>
				{disableTooltip ? (
					<span>
						{absoluteValue} {extraValue !== undefined ? `(${extraValue})` : ''}
					</span>
				) : (
					<Tooltip content={`SKUs ${extraValue !== undefined ? '(PLs)' : ''}`}>
						<span>
							{absoluteValue}{' '}
							{extraValue !== undefined ? `(${extraValue})` : ''}
						</span>
					</Tooltip>
				)}
			</div>
			<Progress>
				<ProgressBar className={barClassName} value={relativeValue} />
			</Progress>
		</div>
	)
}
