import clsx from 'clsx'
import { orderBy, uniq, xor } from 'lodash/fp'
import React, { Fragment, useState } from 'react'

import { Checkbox, treeHelpers, TreeNodeType } from '@cmpkit/base'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'

export default function TreeCheckbox({
	tree,
	disabled,
	value: selected = [],
	onChange,
}: {
	disabled?: boolean
	tree: TreeNodeType[]
	value: string[]
	onChange: (value: string[]) => void
}) {
	const [expanded, setExpanded] = useState<string[]>([])
	const setSelected = (ids: string[]) =>
		onChange(treeHelpers.normalizeToEndNodes(tree, ids))
	const isExpanded = (id: string) => expanded.includes(id)
	const isSelected = (id: string) => selected.includes(id)
	const handleToggleExpand = (id: string) =>
		setExpanded(treeHelpers.toggleItem(expanded, id))

	const renderItem = (
		level: number,
		node: TreeNodeType,
		index: number,
		nodesCount: number
	) => {
		const isFirst = index === 0
		const isLast = index === nodesCount - 1
		const canExpand = Boolean(node.children?.length)
		const childrenIds = treeHelpers.getChildrenIds(node.children || [])
		const expanded = isExpanded(node.id)
		const checked =
			isSelected(node.id) ||
			(canExpand && childrenIds.every((id) => selected.includes(id)))
		const indeterminate = childrenIds.some((id) => selected.includes(id))
		return (
			<Fragment key={node.id}>
				<li
					className={clsx('tree-node', {
						last: isLast,
						first: isFirst,
						expanded,
					})}
				>
					<div className='content'>
						<div className='inline-flex items-center'>
							{canExpand && (
								<div className='flex w-5 items-center'>
									<button
										type='button'
										className='flex cursor-pointer items-center justify-center border-none bg-transparent'
										data-nodeid={node.id}
										onClick={() => handleToggleExpand(node.id)}
									>
										{expanded ? (
											<CaretDownIcon className='text-muted' />
										) : (
											<CaretRightIcon className='text-muted' />
										)}
									</button>
								</div>
							)}
							<label className='flex items-center space-x-2'>
								<Checkbox
									disabled={disabled}
									className='mr-1'
									data-nodeid={node.id}
									checked={checked}
									indeterminate={!checked && indeterminate}
									onChange={() => {
										// Has children
										if (canExpand) {
											if (checked) {
												setSelected(xor(selected, childrenIds))
											} else {
												setSelected(uniq([...selected, ...childrenIds]))
											}
										} else {
											if (checked) {
												setSelected(xor(selected, [node.id]))
											} else {
												setSelected(uniq([...selected, node.id]))
											}
										}
									}}
								/>
								<span className='select-none font-medium'>{node.name}</span>
							</label>
						</div>
					</div>
					{canExpand && expanded && (
						<ul>{renderTree(node.children || [], level + 1)}</ul>
					)}
				</li>
			</Fragment>
		)
	}
	const renderTree = (nodes: TreeNodeType[], level: number) => {
		return (
			<>
				{nodes.map((node, index) =>
					renderItem(level, node, index, nodes.length)
				)}
			</>
		)
	}
	return (
		<ul className='cmp-tree'>
			{!!tree?.length &&
				renderTree(orderBy(['children.length'], ['asc'], tree), 0)}
		</ul>
	)
}
