import { entries, values } from 'lodash/fp'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Button, LinkButton, Loader } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { FormProvider, RHFCheckbox } from '@/components/HookForm'
import RHFFileDropArea from '@/components/HookForm/RHFFileDropArea'
import {
	UpdateProductsFinalFieldsErrorResponseModel,
	UpdateProductsModelFieldsErrorResponseModel,
} from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useUpdateProductsFieldsMutation } from '@/modules/core/hooks/useUpdateProductsFieldsMutation'
import analytic from '@/services/analytics'
import { downloadFile } from '@/tools/report'

import { useValidateProductDiffFileMutation } from '../mutations'
import ProductsBulkUpdateErrors from './ProductsBulkUpdateErrors'

const ImportSchema = Yup.object().shape({
	product_diffs: Yup.mixed<File>()
		.required('required')
		.test('fileFormat', 'Only xls, xlsx, csv files are allowed', (value) => {
			if (value) {
				const supportedFormats = ['xls', 'xlsx', 'csv']
				return supportedFormats.includes(value.name.split('.').pop() as string)
			}
			return true
		}),
	ignore_empties: Yup.boolean(),
})
type FormData = Yup.InferType<typeof ImportSchema>

type ProductsImportModalProps = {
	close(): void
	isOpen: boolean
}
export default function ProductsImportModal({
	close,
	isOpen,
}: ProductsImportModalProps) {
	const optimizationGroupId = useOptimizationGroupId()!
	const updateProductsFields =
		useUpdateProductsFieldsMutation(optimizationGroupId)

	const [errors, setErrors] = useState<Record<string, string[]>[]>([])
	const validateProductDiffFile = useValidateProductDiffFileMutation()
	const methods = useForm<FormData>({
		defaultValues: {
			product_diffs: undefined,
			ignore_empties: true,
		},
		resolver: yupResolver(ImportSchema),
	})
	const _handleSubmit = async ({ product_diffs, ignore_empties }: FormData) => {
		analytic.logEvent('table: import from file', {
			'Ignore empty fields': ignore_empties,
		})
		setErrors([])
		try {
			const updates = await validateProductDiffFile.mutateAsync({
				product_diffs: product_diffs!,
				ignore_empties: String(ignore_empties),
			})
			if (updates.errors?.length) {
				setErrors(updates.errors)
			} else {
				await updateProductsFields.mutateAsync({
					changes: entries(updates.product_diffs as object).map(
						([key, value]) => ({
							sku: key,
							...value,
						})
					),
					ignore_empties: !!ignore_empties,
				})
				close()
			}
		} catch (error) {
			toast.error(intl.get('modal_import_error').d('Failed to import products'))
		}
	}
	const handleSubmit = methods.handleSubmit(_handleSubmit)
	const handleClose = () => close()
	const handleDownloadTemplate = () =>
		downloadFile('/api/v1/import_template/', 'import_template.xlsx')

	const isPending =
		validateProductDiffFile.isPending || updateProductsFields.isPending

	const errorsList = values(
		(
			updateProductsFields.error?.response
				?.data as UpdateProductsFinalFieldsErrorResponseModel
		)?.errors?.final_fields ||
			(
				updateProductsFields.error?.response
					?.data as UpdateProductsModelFieldsErrorResponseModel
			)?.errors?.product_diffs ||
			errors
	)
	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					onClose={close}
					size='mini'
					zIndex={1850}
					showCloseButton
					testId='products-import-modal'
				>
					<ModalHeader>
						<ModalTitle>{intl.get('modal_import_title')}</ModalTitle>
					</ModalHeader>
					<ModalBody className='relative flex flex-col'>
						<FormProvider
							methods={methods}
							onSubmit={handleSubmit}
							className='flex flex-col'
						>
							<Blanket
								className='absolute flex flex-col items-center justify-center space-y-5 rounded-lg bg-white/50 backdrop-blur-md dark:bg-black/50'
								isTinted={isPending}
							>
								<Loader />
							</Blanket>
							<div className='mb-4 flex items-center gap-1'>
								<strong>1</strong>
								<span>
									{intl
										.get('modal_import_og_will_be_updated')
										.d('optimization group(s) will be updated')}
								</span>
							</div>
							<RHFFileDropArea className='min-h-28' name='product_diffs' />
							<RHFCheckbox
								name='ignore_empties'
								label={intl.get('modal_import_ignore_empties')}
								/* description={intl.get('modal_import_ignore_empties_hint')} */
							/>
							<h4 className='mb-2 text-sm font-semibold'>
								{intl.get('modal_import_guide')}
							</h4>
							<ul className='mb-4 list-inside list-decimal'>
								<li>
									{intl.get('og_import_step_1')}
									<LinkButton
										variant='brand'
										onClick={handleDownloadTemplate}
										className='ml-1 text-blue-200'
									>
										{intl.get('og_import_types')}
									</LinkButton>
								</li>
								<li>{intl.get('og_import_step_2')}</li>
								<li>{intl.get('og_import_step_3')}</li>
							</ul>
							{errorsList?.length > 0 && (
								<div className='mt-5'>
									<ProductsBulkUpdateErrors errorsList={errorsList} />
								</div>
							)}
						</FormProvider>
					</ModalBody>
					<ModalFooter className='justify-between'>
						<Button onClick={handleClose}>{intl.get('general_cancel')}</Button>
						<Button
							variant='primary-brand'
							disabled={isPending}
							onClick={handleSubmit}
						>
							{intl.get('general_apply')}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}
