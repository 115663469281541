import { intlFormat } from 'date-fns'
import { isNil, prop } from 'lodash/fp'
import React, { useMemo, useState } from 'react'

import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	Row,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'

import { Badge, Checkbox, LinkButton } from '@cmpkit/base'
import ArrowRightIcon from '@cmpkit/icon/lib/glyph/arrow-right'

import { DataTable } from '@/components/tables'
import { PricingCampaignModel } from '@/generated'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'
import { EngineBadge, GlobalPcBadge } from '@/modules/pricing-campaigns'
import {
	getPricingCampaignEngine,
	isGlobalPricingCampaign,
} from '@/modules/pricing-campaigns/helpers'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'

import { PricingCampaignTableRow } from '../types'

const isDisabledInteraction = (campaign: PricingCampaignModel) =>
	isGlobalPricingCampaign(campaign)

const getTableRowClassName = (row?: Row<PricingCampaignTableRow>) => {
	return row?.original.campaign && isDisabledInteraction(row.original.campaign)
		? 'opacity-60 h-12'
		: 'h-12'
}

export default function PricingCampaignsTable({
	selected,
	setSelected,
	data,
}: {
	selected: string[]
	setSelected: (selected: string[]) => void
	data: PricingCampaignTableRow[]
}) {
	const [sorting, setSorting] = useState<SortingState>([])
	const columns = useMemo(
		() => getColumns({ selected, setSelected }),
		[selected, setSelected]
	)
	const table = useReactTable<PricingCampaignTableRow>({
		data,
		columns,
		state: {
			sorting,
		},
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return <DataTable table={table} getRowClassName={getTableRowClassName} />
}

const columnHelper = createColumnHelper<PricingCampaignTableRow>()
const getColumns = ({
	selected,
	setSelected,
}: {
	selected: string[]
	setSelected: (selected: string[]) => void
}) => [
	columnHelper.accessor((row) => row.campaign.id, {
		size: 28,
		id: 'control',
		enableSorting: false,
		header: () => null,
		cell: (info) => {
			const id = info.getValue()
			const isSelected = selected.includes(id)
			return (
				<Checkbox
					disabled={isDisabledInteraction(info.row.original.campaign)}
					checked={isSelected}
					onChange={() =>
						setSelected(
							isSelected
								? selected.filter((item) => item !== id)
								: [...selected, id]
						)
					}
				/>
			)
		},
	}),
	columnHelper.accessor('campaign.name', {
		size: 340,
		header: () => intl.get('pc_name'),
		cell: (info) => {
			const { campaign } = info.row.original
			const { showModal } = useModalStore()
			return (
				<div className='flex items-center space-x-2'>
					{isGlobalPricingCampaign(campaign) ? (
						<span className='block cursor-not-allowed text-brand'>
							{info.getValue()}
						</span>
					) : (
						<LinkButton
							variant='brand'
							className='text-left'
							onClick={() => {
								analytic.logEvent('settings: pricing campaigns: view: click', {
									location: 'scenario-pricing-campaigns-table',
								})
								showModal('PRICING_CAMPAIGN_MODAL', {
									pricingCampaignId: campaign.id,
									optimizationGroupId: campaign.optimization_group_id,
									scenarioId: campaign.scenario_id,
								})
							}}
						>
							{info.getValue()}
						</LinkButton>
					)}
				</div>
			)
		},
	}),
	columnHelper.accessor('campaign.order', {
		size: 70,
		header: () => intl.get('general_priority'),
		cell: (info) => {
			const value = info.getValue()
			return value ? <Badge>{value}</Badge> : '-'
		},
	}),
	columnHelper.accessor('campaign', {
		size: 170,
		header: () => intl.get('pricing_engine'),
		cell: (info) => {
			const { campaign } = info.row.original
			const engine = getPricingCampaignEngine(campaign)
			return (
				<div className='flex items-center space-x-1'>
					<EngineBadge engine={engine} className={'mr-1'}>
						{intl.get('campaigns_om_title_' + engine)}
					</EngineBadge>

					{isGlobalPricingCampaign(campaign) && <GlobalPcBadge />}
				</div>
			)
		},
	}),
	columnHelper.accessor('products_count', {
		size: 120,
		header: () => intl.get('items_in_pc'),
		cell: (info) => {
			const value = info.getValue()
			const { assortment_share } = info.row.original
			if (isNil(value)) {
				return '-'
			}
			return `${formatNumber(value)} (${assortment_share}%)`
		},
	}),
	columnHelper.accessor(prop('campaign.id'), {
		id: 'period',
		header: () => intl.get('period'),
		cell: (info) => {
			const { campaign } = info.row.original
			const { start_date, end_date } = campaign
			return (
				<div className='flex items-center space-x-1 font-normal'>
					{start_date && <span>{intlFormat(new Date(start_date + 'Z'))}</span>}
					{(start_date || end_date) && <ArrowRightIcon className='shrink-0' />}
					{end_date && <span>{intlFormat(new Date(end_date + 'Z'))}</span>}
				</div>
			)
		},
	}),
]
