import { find } from 'lodash/fp'
import React, { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { useCopyToClipboard } from 'usehooks-ts'

import {
	Button,
	Content,
	Header,
	Layout,
	LinkButton,
	TabItem,
	Tabs,
} from '@cmpkit/base'
import Drawer from '@cmpkit/drawer'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import QueueIcon from '@cmpkit/icon/lib/glyph/queue'
import Tooltip from '@cmpkit/tooltip'

import ErrorBoundary from '@/components/ErrorBoundary'
import { PricingCampaignModel, PricingLineModel } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { ANCHORS_TYPES } from '@/modules/anchoring-management/contstants'
import { useModalStore } from '@/modules/modals/store'
import { EngineBadge, GlobalPcBadge } from '@/modules/pricing-campaigns'
import {
	getPricingCampaignEngine,
	isGlobalPricingCampaign,
} from '@/modules/pricing-campaigns/helpers'
import { usePricingCampaignsQuery } from '@/modules/pricing-campaigns/queries'
import analytic from '@/services/analytics'

import { useProductsStore } from '../../store'
import { ProductEntity } from '../../types'
import EastsidePanelMarket from './Tabs/Market/Content'
import EastsidePanelPerfomance from './Tabs/Perfomance/Content'
import EastsidePanelRuleInfo from './Tabs/PriceInfo/Content'

type EastsidePanelProps = {
	closeEastsidePanel(): void
	content: ProductEntity | PricingLineModel
}
const getTabs = () => [
	{
		title: intl.get('eastside_panel_tab_price_info'),
		id: 'price_info',
		Component: EastsidePanelRuleInfo,
	},
	{
		title: intl.get('eastside_panel_tab_market'),
		id: 'market',
		Component: EastsidePanelMarket,
	},
	{
		title: intl.get('eastside_panel_tab_perfomance'),
		id: 'perfomance',
		Component: EastsidePanelPerfomance,
	},
]

/**
 * Get anchors from product custom fields
 * @param productCustomFields - product custom fields from the product
 * @returns  array of anchors data
 */
function getAnchorsData(
	productCustomFields: Record<
		string,
		number | string | null | (number | string)[]
	>
) {
	return ANCHORS_TYPES.map((anchorType) => {
		const count = productCustomFields?.[`${anchorType}_anchor_count`] as number
		const sku = productCustomFields?.[`${anchorType}_anchor_sku`] as string
		return {
			type: anchorType,
			sku,
			count,
		}
	}).filter(({ count, sku }) => count || sku)
}

function AnchorsList({
	anchors,
	sku,
}: {
	anchors: { type: string; sku: string; count: number }[]
	sku: string
}) {
	return (
		<div className='flex items-start space-x-1 text-xs'>
			<span>{intl.get('app.anchors').d('Anchors')}:</span>
			<div className='flex w-full flex-col overflow-hidden'>
				{anchors.map((anchor) => (
					<div key={anchor.type} className='truncate'>
						{intl.get(`app.${anchor.type}_anchor`).d(anchor.type)}{' '}
						<span className='text-muted'>
							({intl.get('app.is_anchor').d('Is anchor')}{' '}
							<Link to={`?${anchor.type}_anchor_sku__is=${sku}`}>
								{anchor.count}
							</Link>
							, {intl.get('app.anchored_to').d('Anchored to')}{' '}
							<Link className='truncate' to={`?sku__is=${anchor.sku}`}>
								{anchor.sku}
							</Link>
							)
						</span>
					</div>
				))}
			</div>
		</div>
	)
}
function EastsideMeta({
	content,
	pricingCampaign,
}: {
	content: ProductEntity | PricingLineModel
	pricingCampaign?: PricingCampaignModel
}) {
	const { showModal } = useModalStore()
	const productLineId = content?.line_id
	const pricingCampaignName = pricingCampaign?.name
	const sku = (content as ProductEntity)?.sku
	const isAggregated = content?.products
	const customFields = (content as ProductEntity)?.custom_fields
	const anchors = useMemo(() => getAnchorsData(customFields), [customFields])
	const [value, copy] = useCopyToClipboard() // eslint-disable-line

	return (
		<div className='flex flex-col space-y-2 pl-5'>
			<div className='flex items-center space-x-1 text-xs'>
				<span>{intl.get('product_line_id')}:</span>
				<strong>{productLineId}</strong>
			</div>
			{anchors?.length > 0 ? <AnchorsList anchors={anchors} sku={sku} /> : null}
			<div className='flex items-center space-x-2 overflow-hidden'>
				{sku && !isAggregated && (
					<div className='flex items-center space-x-1 text-xs'>
						<span>{intl.get('sku')}:</span>
						<strong>{sku}</strong>
						<Tooltip content={intl.get('general_copy_sku')}>
							<LinkButton
								variant='brand'
								onClick={() =>
									copy(sku).then(() => {
										analytic.logEvent('eastside: copy SKU')
										toast.success(intl.get('general_successful_copied'), {
											duration: 3000,
											id: 'copy',
										})
									})
								}
							>
								<QueueIcon width={14} height={14} />
							</LinkButton>
						</Tooltip>
					</div>
				)}
				{pricingCampaignName && (
					<>
						<Tooltip
							content={pricingCampaignName || intl.get('pc_new_products')}
						>
							<EngineBadge
								interactive
								engine={getPricingCampaignEngine(pricingCampaign)}
								onClick={() => {
									analytic.logEvent(
										'settings: pricing campaigns: view: click',
										{
											location: 'eastside-header',
										}
									)
									showModal('PRICING_CAMPAIGN_MODAL', {
										pricingCampaignId: pricingCampaign.id,
									})
								}}
								className={'pointer'}
							>
								{pricingCampaignName || intl.get('pc_new_products')}
							</EngineBadge>
						</Tooltip>

						{isGlobalPricingCampaign(pricingCampaign) && <GlobalPcBadge />}
					</>
				)}
			</div>
		</div>
	)
}
function EastsidePanel(props: EastsidePanelProps) {
	const { content, closeEastsidePanel } = props

	const { localData } = useProductsStore()
	const [activeTab, setActiveTab] = useState('price_info')
	const tabs = useMemo(getTabs, [])

	useEffect(() => {
		analytic.logEvent('table: open SKU panel')
	}, [])

	const optimizationGroupId = useOptimizationGroupId()
	const pricingCampaign = usePricingCampaignsQuery<PricingCampaignModel>(
		{
			optimization_group_id: optimizationGroupId,
		},
		{
			enabled: !!optimizationGroupId,
			select: find({ id: content?.fields?.pricing_campaign_id }) as (
				data: PricingCampaignModel[]
			) => PricingCampaignModel,
		}
	)

	const title = (content as ProductEntity)?.title || content?.fields?.name
	const _handleCloseClick = () => closeEastsidePanel()
	const _handleTabClick = (tab: string) => setActiveTab(tab)
	return (
		<Layout className='h-full'>
			<Header className='flex flex-col bg-accent-1 pb-2'>
				<h4 className='pb-2 pl-5 pr-14 pt-5 font-bold'>{title}</h4>
				<EastsideMeta
					content={content}
					pricingCampaign={pricingCampaign.data}
				/>
				<div className='absolute right-5 top-4'>
					<Button
						variant='tertiary'
						iconBefore={<CrossIcon />}
						onClick={_handleCloseClick}
					/>
				</div>
			</Header>
			<Tabs fit className='w-full font-medium'>
				{tabs.map((tab) => (
					<TabItem
						key={tab.id}
						active={tab.id == activeTab}
						onClick={_handleTabClick.bind(null, tab.id)}
					>
						{tab.title}
					</TabItem>
				))}
			</Tabs>
			<Content className='flex flex-col overflow-y-auto overflow-x-hidden bg-application'>
				<div>
					{tabs
						.filter(({ id }) => id == activeTab)
						.map(({ Component, id }) => (
							<Component
								key={id}
								localData={localData}
								{...props}
								pricingCampaign={pricingCampaign.data}
								content={content}
							/>
						))}
				</div>
			</Content>
		</Layout>
	)
}

export default function ProductEastsideDrawer({
	isOpen,
	close,
	content,
}: {
	isOpen: boolean
	close(): void
	content: ProductEntity | PricingLineModel
}) {
	return (
		<Drawer
			className='products-eastside'
			orient='right'
			isOpen={isOpen}
			disableBlanket
			disableFocusTrap
			onCloseComplete={close}
		>
			<ErrorBoundary>
				<EastsidePanel content={content!} closeEastsidePanel={close} />
			</ErrorBoundary>
		</Drawer>
	)
}
