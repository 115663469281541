import React from 'react'

import { Badge } from '@cmpkit/base'

import intl from '@/locale'

import Container from '../Container'
import ChartView from './ChartView'
import { getElasticityProps, getSortedSeriesData } from './utils'

type ElasticityProps = {
	relative_elasticity: string
	init_price: number
	new_price: number
	sourceProp: string
	demand_curve: {
		price: number[]
		revenue: number[]
		sales_items: number[]
	}
}
export default function Elasticity({
	relative_elasticity,
	demand_curve,
	init_price,
	new_price,
}: ElasticityProps) {
	return (
		<Container>
			<div className='mb-2 flex items-center justify-between rounded-lg bg-accent-2 px-5 py-2'>
				<span className='text-xs font-semibold'>
					{intl.get('general_price_elasticity')}
				</span>
				<Badge variant='warning'>{relative_elasticity}</Badge>
			</div>
			<ChartView
				{...getElasticityProps(
					getSortedSeriesData(demand_curve),
					init_price,
					new_price /* sourceProp */
				)}
			/>
		</Container>
	)
}
