import React, { useEffect } from 'react'

export default function DocumentTitle({
	title,
	children,
}: {
	title?: string
	children: React.ReactNode
}) {
	useEffect(() => {
		document.title = title || 'Competera'
		return () => {
			document.title = 'Competera'
		}
	}, [title])
	return children
}
