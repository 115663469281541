import { uniqBy } from 'lodash/fp'
import { useMemo } from 'react'

import { getProductGroupsTree } from '../helpers'
import { useDryCategoriesQuery, useProductGroupsQuery } from '../queries'

export function useProductGroupsTreeQuery() {
	const productGroupsQuery = useProductGroupsQuery()
	const categoriesQuery = useDryCategoriesQuery()
	const data = useMemo(
		() =>
			getProductGroupsTree(
				productGroupsQuery.data,
				uniqBy('id', categoriesQuery.data)
			) || [],
		[productGroupsQuery.data, categoriesQuery.data]
	)
	return {
		isLoading: productGroupsQuery.isLoading || categoriesQuery.isLoading,
		data,
	}
}
