/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from './auth'
import Channel from './channel'
import {
	CHANNEL_EVENTS,
	DEFAULT_TIMEOUT,
	DEFAULT_VSN,
	global,
	SOCKET_EVENTS,
	SOCKET_STATES,
	TRANSPORTS,
	WS_CLOSE_NORMAL,
} from './constants'
import Observer from './observer'
import Serializer from './serializer'
import Timer from './timer'
import { Message } from './types'
//import querystring from 'qs'
import { closure } from './utils'

/** Initializes the Socket *
 *
 * For IE8 support use an ES5-shim (https://github.com/es-shims/es5-shim)
 *
 * @param {string} endPoint - The string WebSocket endpoint, ie, `"ws://example.com/socket"`,
 *                                               `"wss://example.com"`
 *                                               `"/socket"` (inherited host & protocol)
 * @param {Object} [opts] - Optional configuration
 * @param {Function} [opts.transport] - The Websocket Transport, for example WebSocket or Phoenix.LongPoll.
 *
 * Defaults to WebSocket with automatic LongPoll fallback.
 * @param {Function} [opts.encode] - The function to encode outgoing messages.
 *
 * Defaults to JSON encoder.
 *
 * @param {Function} [opts.decode] - The function to decode incoming messages.
 *
 * Defaults to JSON:
 *
 * ```javascript
 * (payload, callback) => callback(JSON.parse(payload))
 * ```
 *
 * @param {number} [opts.timeout] - The default timeout in milliseconds to trigger push timeouts.
 *
 * Defaults `DEFAULT_TIMEOUT`
 * @param {number} [opts.heartbeatIntervalMs] - The millisec interval to send a heartbeat message
 * @param {number} [opts.reconnectAfterMs] - The optional function that returns the millsec
 * socket reconnect interval.
 *
 * Defaults to stepped backoff of:
 *
 * ```javascript
 * function(tries){
 *   return [10, 50, 100, 150, 200, 250, 500, 1000, 2000][tries - 1] || 5000
 * }
 * ````
 *
 * @param {number} [opts.rejoinAfterMs] - The optional function that returns the millsec
 * rejoin interval for individual channels.
 *
 * ```javascript
 * function(tries){
 *   return [1000, 2000, 5000][tries - 1] || 10000
 * }
 * ````
 *
 * @param {Function} [opts.logger] - The optional function for specialized logging, ie:
 *
 * ```javascript
 * function(kind, msg, data) {
 *   console.log(`${kind}: ${msg}`, data)
 * }
 * ```
 *
 * @param {number} [opts.longpollerTimeout] - The maximum timeout of a long poll AJAX request.
 *
 * Defaults to 20s (double the server long poll timer).
 *
 * @param {(Object|function)} [opts.params] - The optional params to pass when connecting
 * @param {string} [opts.binaryType] - The binary type to use for binary WebSocket frames.
 *
 * Defaults to "arraybuffer"
 *
 * @param {vsn} [opts.vsn] - The serializer's protocol version to send on connect.
 *
 * Defaults to DEFAULT_VSN.
 */
type Options = {
	vsn?: string
	timeout?: number
	transport?: any
	encode?: any
	decode?: any
	heartbeatIntervalMs?: number
	reconnectAfterMs?: any
	logger?: any
	longpollerTimeout?: number
	params?: any
	binaryType?: string
	rejoinAfterMs?: any
}
export default class Socket extends Observer {
	sendBuffer: Array<() => void> = []
	conn: WebSocket | null = null
	auth: Auth
	channels: Channel[] = []
	ref = 0
	timeout: number
	transport: WebSocket | any
	encode: any
	decode: any
	heartbeatIntervalMs: number
	reconnectAfterMs: any
	logger: (kind: any, msg?: any, data?: any) => void
	endPoint: string
	heartbeatTimer: any = null
	pendingHeartbeatRef: any = null
	longpollerTimeout: number
	params: any
	defaultEncoder: any
	defaultDecoder: any
	//stateChangeCallbacks: any
	reconnectTimer: Timer
	establishedConnections = 0
	closeWasClean = false
	binaryType: string
	connectClock = 1
	rejoinAfterMs?: any
	vsn: string
	constructor(endPoint: string, opts: Options = {}) {
		super()
		//this.stateChangeCallbacks = { open: [], close: [], error: [], message: [] }
		this.timeout = opts.timeout || DEFAULT_TIMEOUT
		this.transport = opts.transport || global!.WebSocket
		this.defaultEncoder = Serializer.encode.bind(Serializer)
		this.defaultDecoder = Serializer.decode.bind(Serializer)
		this.binaryType = opts.binaryType || 'arraybuffer'
		this.encode = opts.encode || this.defaultEncoder
		this.decode = opts.decode || this.defaultDecoder
		this.auth = new Auth(this)

		//let awaitingConnectionOnPageShow = null
		/* if (phxWindow && phxWindow.addEventListener) {
			phxWindow.addEventListener('pagehide', (_e) => {
				if (this.conn) {
					this.disconnect()
					awaitingConnectionOnPageShow = this.connectClock
				}
			})
			phxWindow.addEventListener('pageshow', (_e) => {
				if (awaitingConnectionOnPageShow === this.connectClock) {
					awaitingConnectionOnPageShow = null
					this.connect()
				}
			})
		} */
		this.heartbeatIntervalMs = opts.heartbeatIntervalMs || 30000
		this.rejoinAfterMs = (tries: number) => {
			if (opts.rejoinAfterMs) {
				return opts.rejoinAfterMs(tries)
			} else {
				return [1000, 2000, 5000][tries - 1] || 10000
			}
		}
		this.reconnectAfterMs = (tries: number) => {
			if (opts.reconnectAfterMs) {
				return opts.reconnectAfterMs(tries)
			} else {
				return [10, 50, 100, 150, 200, 250, 500, 1000, 2000][tries - 1] || 5000
			}
		}
		this.logger = opts.logger || null
		this.longpollerTimeout = opts.longpollerTimeout || 20000
		this.params = closure(opts.params || {})
		this.endPoint = `${endPoint}/${TRANSPORTS.websocket}`
		this.vsn = opts.vsn || DEFAULT_VSN
		this.reconnectTimer = new Timer(() => {
			this.teardown(() => this.connect())
		}, this.reconnectAfterMs)
	}

	/**
	 * Disconnects and replaces the active transport
	 *
	 * @param {Function} newTransport - The new transport class to instantiate
	 *
	 */
	replaceTransport(newTransport: any) {
		this.connectClock++
		this.closeWasClean = true
		this.reconnectTimer.reset()
		this.sendBuffer = []
		if (this.conn) {
			this.conn.close()
			this.conn = null
		}
		this.transport = newTransport
	}

	/**
	 * Returns the socket protocol
	 *
	 * @returns {string}
	 */
	protocol() {
		return location.protocol.match(/^https/) ? 'wss' : 'ws'
	}

	/**
	 * The fully qualifed socket url
	 *
	 * @returns {string}
	 */
	endPointURL() {
		return `${this.protocol()}://${this.endPoint}`
	}

	/**
	 * Disconnects the socket
	 *
	 * See https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent#Status_codes for valid status codes.
	 *
	 * @param {Function} callback - Optional callback which is called after socket is disconnected.
	 * @param {integer} code - A status code for disconnection (Optional).
	 * @param {string} reason - A textual description of the reason to disconnect. (Optional)
	 */
	disconnect(callback?: any, code?: any, reason?: any) {
		this.connectClock++
		this.closeWasClean = true
		this.reconnectTimer.reset()
		this.teardown(callback, code, reason)
	}

	/**
	 *
	 * @param {Object} params - The params to send when connecting, for example `{user_id: userToken}`
	 *
	 * Passing params to connect is deprecated; pass them in the Socket constructor instead:
	 * `new Socket("/socket", {params: {user_id: userToken}})`.
	 */
	connect(params?: any) {
		if (params) {
			// eslint-disable-next-line
			console.log(
				'passing params to connect is deprecated. Instead pass :params to the Socket constructor'
			)
			this.params = closure(params)
		}
		if (this.conn) {
			return
		}

		this.connectClock++
		this.closeWasClean = false
		this.conn = new this.transport(this.endPointURL())
		//this.conn.binaryType = this.binaryType
		//this.conn.timeout = this.longpollerTimeout
		this.conn!.onopen = () => this.onConnOpen()
		this.conn!.onerror = (error) => this.onConnError(error)
		this.conn!.onmessage = (event) => this.onConnMessage(event)
		this.conn!.onclose = (event) => this.onConnClose(event)
	}

	/**
	 * Logs the message. Override `this.logger` for specialized logging. noops by default
	 * @param {string} kind
	 * @param {string} msg
	 * @param {Object} data
	 */
	log(kind?: any, msg?: any, data?: any) {
		this.logger(kind, msg, data)
	}

	/**
	 * Returns true if a logger has been set on this socket.
	 */
	hasLogger() {
		return this.logger !== null
	}

	/**
	 * Pings the server and invokes the callback with the RTT in milliseconds
	 * @param {Function} callback
	 *
	 * Returns true if the ping was pushed or false if unable to be pushed.
	 */
	ping(callback: (d: number) => void) {
		if (!this.isConnected()) {
			return false
		}
		const ref: any = this.makeRef()
		const startTime = Date.now()
		this.push({ topic: 'system', event: '_ping', payload: {}, ref: ref })
		const onMsgRef = this.on(SOCKET_EVENTS.message, (msg: any) => {
			if (msg.ref === ref) {
				onMsgRef.off()
				callback(Date.now() - startTime)
			}
		})
		return true
	}

	/**
	 * @private
	 */
	onConnOpen() {
		if (this.hasLogger())
			this.log('transport', `connected to ${this.endPointURL()}`)
		this.closeWasClean = false
		this.establishedConnections++
		this.flushSendBuffer()
		this.reconnectTimer.reset()
		this.resetHeartbeat()
		this.fireEvent(SOCKET_EVENTS.open)
	}

	/**
	 * @private
	 */

	heartbeatTimeout() {
		if (this.pendingHeartbeatRef) {
			this.pendingHeartbeatRef = null
			if (this.hasLogger()) {
				this.log(
					'transport',
					'heartbeat timeout. Attempting to re-establish connection'
				)
			}
			this.abnormalClose('heartbeat timeout')
		}
	}

	resetHeartbeat() {
		/* 		if (this.conn && this.conn.skipHeartbeat) {
			return
		} */
		this.pendingHeartbeatRef = null
		clearTimeout(this.heartbeatTimer)
		setTimeout(() => this.sendHeartbeat(), this.heartbeatIntervalMs)
	}

	teardown(callback: any, code?: any, reason?: any) {
		if (!this.conn) {
			return callback && callback()
		}

		this.waitForBufferDone(() => {
			if (this.conn) {
				if (code) {
					this.conn.close(code, reason || '')
				} else {
					this.conn.close()
				}
			}

			this.waitForSocketClosed(() => {
				if (this.conn) {
					this.conn.onclose = function () {} // noop
					this.conn = null
				}

				callback && callback()
			})
		})
	}

	waitForBufferDone(callback: () => void, tries = 1) {
		if (tries === 5 || !this.conn || !this.conn.bufferedAmount) {
			callback()
			return
		}

		setTimeout(() => {
			this.waitForBufferDone(callback, tries + 1)
		}, 150 * tries)
	}

	waitForSocketClosed(callback: () => void, tries = 1) {
		if (
			tries === 5 ||
			!this.conn ||
			this.conn.readyState === SOCKET_STATES.closed
		) {
			callback()
			return
		}

		setTimeout(() => {
			this.waitForSocketClosed(callback, tries + 1)
		}, 150 * tries)
	}

	onConnClose(event: any) {
		const closeCode = event && event.code
		if (this.hasLogger()) this.log('transport', 'close', event)
		this.triggerChanError()
		clearTimeout(this.heartbeatTimer)
		if (!this.closeWasClean && closeCode !== 1000) {
			this.reconnectTimer.scheduleTimeout()
		}
		this.fireEvent(SOCKET_EVENTS.close, event)
	}

	/**
	 * @private
	 */
	onConnError(error: any) {
		if (this.hasLogger()) this.log('transport', error)
		const transportBefore = this.transport
		const establishedBefore = this.establishedConnections
		this.fireEvent(
			SOCKET_EVENTS.error,
			error,
			transportBefore,
			establishedBefore
		)
		/* this.stateChangeCallbacks.error.forEach(([, callback]) => {
			callback(error, transportBefore, establishedBefore)
		}) */
		if (transportBefore === this.transport || establishedBefore > 0) {
			this.triggerChanError()
		}
	}

	/**
	 * @private
	 */
	triggerChanError() {
		this.channels.forEach((channel) => {
			if (!(channel.isErrored() || channel.isLeaving() || channel.isClosed())) {
				channel.trigger(CHANNEL_EVENTS.error, {})
			}
		})
	}

	/**
	 * @returns {string}
	 */
	connectionState() {
		switch (this.conn && this.conn.readyState) {
			case SOCKET_STATES.connecting:
				return 'connecting'
			case SOCKET_STATES.open:
				return 'open'
			case SOCKET_STATES.closing:
				return 'closing'
			default:
				return 'closed'
		}
	}

	/**
	 * @returns {boolean}
	 */
	isConnected() {
		return this.connectionState() === 'open'
	}

	/**
	 * @returns {boolean}
	 */
	isAuthenticated() {
		return this.auth.authenticted
	}

	/**
	 * @private
	 *
	 * @param {Channel}
	 */
	remove(channel: Channel) {
		channel.stateChangeRefs.forEach(({ off }) => off())
		this.channels = this.channels.filter(
			(c) => c.joinRef() !== channel.joinRef()
		)
	}

	/**
	 * Initiates a new channel for the given topic
	 *
	 * @param {string} topic
	 * @param {Object} chanParams - Parameters for the channel
	 * @returns {Channel}
	 */
	channel(topic: string, chanParams = {}) {
		const existed = this.channels.find((c) => c.topic === topic)
		if (existed) {
			return existed
		}
		const chan = new Channel(topic, chanParams, this)
		this.channels.push(chan)
		this.fireEvent('channels', this.channels)
		return chan
	}

	/**
	 * @param {Object} data
	 */
	push(data: Message) {
		if (this.hasLogger()) {
			const { topic, event, payload, ref } = data
			this.log('push', `${topic} ${event} (${ref})`, payload)
		}

		if (this.isConnected()) {
			this.encode(data, (result: any) => this.conn!.send(result))
		} else {
			this.sendBuffer.push(() =>
				this.encode(data, (result: any) => this.conn!.send(result))
			)
		}
	}

	/**
	 * Return the next message ref, accounting for overflows
	 * @returns {string}
	 */
	makeRef() {
		const newRef = this.ref + 1
		if (newRef === this.ref) {
			this.ref = 0
		} else {
			this.ref = newRef
		}

		return this.ref //.toString()
	}
	sendHeartbeat() {
		if (this.pendingHeartbeatRef && !this.isConnected()) {
			return
		}

		this.pendingHeartbeatRef = this.makeRef()
		this.push({
			topic: 'system',
			event: '_ping',
			payload: {},
			ref: this.pendingHeartbeatRef,
		})
		this.heartbeatTimer = setTimeout(
			() => this.heartbeatTimeout(),
			this.heartbeatIntervalMs
		)
	}

	abnormalClose(reason: any) {
		this.closeWasClean = false
		if (this.isConnected()) {
			this.conn!.close(WS_CLOSE_NORMAL, reason)
		}
	}

	flushSendBuffer() {
		if (this.isConnected() && this.sendBuffer.length > 0) {
			this.sendBuffer.forEach((callback) => callback())
			this.sendBuffer = []
		}
	}

	onConnMessage(rawMessage: any) {
		this.decode(rawMessage.data, (msg: Message) => {
			const { topic, event, payload, ref, status } = msg
			if (this.hasLogger())
				this.log(
					'receive',
					`${status || ''} ${topic} ${event} ${(ref && '(' + ref + ')') || ''}`,
					payload
				)
			if (
				topic === 'system' ||
				['_auth', '_auth_jwt_refresh', '_auth_expired'].includes(
					event as string
				)
			) {
				if (ref && ref === this.pendingHeartbeatRef) {
					clearTimeout(this.heartbeatTimer)
					this.pendingHeartbeatRef = null
					setTimeout(() => this.sendHeartbeat(), this.heartbeatIntervalMs)
				} else {
					this.auth.fireEvent(event as string, msg)
				}
			} else {
				for (let i = 0; i < this.channels.length; i++) {
					const channel = this.channels[i]
					if (!channel.isMember({ topic })) {
						continue
					}
					channel.trigger(event as string, msg)
				}

				this.fireEvent(SOCKET_EVENTS.message, msg)
			}
		})
	}

	leaveOpenTopic(topic: any) {
		const dupChannel = this.channels.find(
			(c) => c.topic === topic && (c.isSubscribed() || c.isSubscribing())
		)
		if (dupChannel) {
			if (this.hasLogger())
				this.log('transport', `leaving duplicate topic "${topic}"`)
			dupChannel.leave()
		}
	}
}
