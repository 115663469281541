import { addWeeks, format } from 'date-fns'
import {
	compact,
	entries,
	equals,
	filter,
	find,
	flatten,
	last,
	map,
	pipe,
	prop,
	propEq,
	props,
} from 'lodash/fp'
import React, { useEffect, useMemo, useState } from 'react'

import {
	createColumnHelper,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table'

import notAvailable from '@/assets/img/svg/market_tab_not_available.svg'
import Loader from '@/components/Loader'
import EmptyState from '@/components/placeholders/EmptyState'
import { DataTable } from '@/components/tables'
import notify from '@/components/toasts'
import {
	PriceHistoryDataPointModel,
	PricingCampaignModel,
	PricingLineModel,
} from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useDrawersStore } from '@/modules/drawers/store'
import { unwrapPricingLine } from '@/modules/og-products/data'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import { usePricingCampaignQuery } from '@/modules/pricing-campaigns/queries'
import { client } from '@/network/client'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'
import { getResolvedSchema } from '@/tools/json-schema-utils'

import { ProductEntity } from '../../../../types'
import { isAggregated } from '../../../../utils'
import PriceHistoryChart from './PriceHistoryChart'
import PricesTable from './PricesTable'
import {
	ChartSerie,
	CompetitiveDataPointExtended,
	CompetitorDataGroup,
} from './types'
import {
	alignDatasets,
	createColorMap,
	createSerie,
	enrichWithColors,
	pickLastData,
	prepareSerieDataset,
} from './utils'

type ContentProps = {
	content: ProductEntity | PricingLineModel
	pcRuleCompetitors: string[]
}
export function Content({ content, pcRuleCompetitors }: ContentProps) {
	/**
	 * Local states
	 */
	const optimizationGroupId = useOptimizationGroupId()!
	const [isLoading, setIsLoading] = useState(false)
	const [marketPrices, setMarketPrices] = useState<
		CompetitiveDataPointExtended[]
	>([])
	const [visibleOnChart, setVisibleOnChart] = useState<string[]>([])
	const [chartSeries, setChartSeries] = useState<ChartSerie[]>([])
	const [isError, setIsError] = useState(false)

	useEffect(() => {
		analytic.logEvent('table: open SKU panel: Market')
	}, [])

	useEffect(() => {
		let isSubscribed = true
		setIsLoading(true)
		setIsError(false)
		const observed = format(addWeeks(new Date(), -6), DateFormat.system)
		const query = {
			limit: 0,
			optimization_group_id: optimizationGroupId,
			line_id: content.line_id,
			sku: (content as ProductEntity).sku,
			order_by: 'observed_on',
			group_by: ['competitor_name'],
			observed_on__gt: observed,
		}
		if (optimizationGroupId) {
			Promise.all([
				client.cd.listPriceHistory(query),
				client.cd.listCompetitiveData({
					...query,
					fields: [
						'observed_on',
						'price',
						'promo_type_id',
						'availability',
						'url',
					],
				}),
			])
				.then(([historicalData, marketData]) => {
					if (isSubscribed) {
						const myData = historicalData.objects?.prices || []
						const competitiveData =
							historicalData.objects?.competitive_data || []
						const colorsMap: Record<string, string> = createColorMap(
							marketData.objects || []
						)

						const marketDataset = enrichWithColors(
							marketData.objects || [],
							colorsMap
						)
						const historyDataset = enrichWithColors(competitiveData, colorsMap)

						setChartSeries(createHistoricalSeries(myData, historyDataset))
						setVisibleOnChart(
							createVisibleSeries(marketDataset, pcRuleCompetitors) as string[]
						)
						setMarketPrices(concatMarketData(marketDataset, myData))
						setIsLoading(false)
					}
				})
				.catch(() => {
					notify.error({
						text: intl.get('fatal_error_title'),
					})

					setIsError(true)
					setIsLoading(false)
				})
		}

		return () => {
			isSubscribed = false
		}
	}, [(content as ProductEntity).sku, pcRuleCompetitors])

	const series = chartSeries.filter(({ name }) => visibleOnChart.includes(name))
	const toggleVisability = (name: string) =>
		setVisibleOnChart((visibleOnChart) => {
			if (visibleOnChart.includes(name)) {
				return visibleOnChart.filter((i) => i != name)
			} else {
				return [...visibleOnChart, name]
			}
		})

	if (isError) {
		return (
			<EmptyState
				imageUrl={notAvailable}
				imgClasses='h-52'
				wrapperClasses='p-5 fade-in w-full text-center'
				description={intl.get('eastside_market_unavailable')}
			/>
		)
	}
	const isInRuleOrMyPrice = (label: string) =>
		pcRuleCompetitors?.includes(label) || label == 'My price'
	const inRuleData = marketPrices.filter(({ label }) =>
		isInRuleOrMyPrice(label)
	)
	const otherData = marketPrices.filter(
		({ label }) => !isInRuleOrMyPrice(label)
	)

	return (
		<>
			<h4 className='fade-in px-5 py-2.5'>
				{intl.get('eastside_market_price_history')}
			</h4>
			{isLoading ? (
				<div className='relative h-52'>
					<Loader size={30} />
				</div>
			) : (
				<PriceHistoryChart series={alignDatasets(series)} />
			)}
			<div className='eastside-panel-content pb-16'>
				<div className='p-5'>
					<h5 className='mb-2 text-xs text-muted'>
						{intl
							.get('app.competitors_used_in_rule')
							.d('Competitors used in the rule')}
					</h5>
					<div className='max-w-full overflow-hidden rounded-lg border border-solid border-base'>
						{isLoading ? (
							<TableLoadingPlaceholder />
						) : (
							<PricesTable
								rows={inRuleData}
								visibleOnChart={visibleOnChart}
								onToggleSerie={toggleVisability}
							/>
						)}
					</div>
					{otherData?.length > 0 ? (
						<>
							<h5 className='mb-2 mt-3 text-xs text-muted'>
								{intl.get('app.other_competitors').d('Other competitors')}
							</h5>
							<div className='max-w-full overflow-hidden rounded-lg border border-solid border-base'>
								<PricesTable
									rows={otherData}
									visibleOnChart={visibleOnChart}
									onToggleSerie={toggleVisability}
								/>
							</div>
						</>
					) : null}
				</div>
			</div>
		</>
	)
}
type EastsidePanelMarketProps = {
	content: ProductEntity | PricingLineModel
}
export default function EastsidePanelMarket({
	content,
}: EastsidePanelMarketProps) {
	const { openDrawer } = useDrawersStore()

	const isSkuSelected = !isAggregated(content as PricingLineModel)
	const pricingCampaignQuery = usePricingCampaignQuery<PricingCampaignModel>(
		content.fields.pricing_campaign_id,
		{
			enabled: !!content.fields.pricing_campaign_id,
		}
	)
	const ruleId = prop(
		'settings.pricing_tactics.params.rule_id',
		pricingCampaignQuery.data
	)
	const pricingRuleComperitorsKeys = useSettingsSchemasQuery<string[]>({
		select: pipe([
			getResolvedSchema('part:pricing_rules'),
			prop('oneOf'),
			find(propEq('properties.rule_id.const', ruleId)),
			prop('properties.rule_params.properties'),
			entries,
			filter(pipe([prop('[1].logicalType'), equals('competitors')])),
			map(prop(0)),
		]),
		enabled: !!pricingCampaignQuery.data,
	})

	const pcRuleCompetitors = pipe([
		prop('settings.pricing_tactics.params.rule_params'),
		props(pricingRuleComperitorsKeys?.data as string[]),
		flatten,
		compact,
	])(pricingCampaignQuery.data)

	if (!isSkuSelected) {
		return (
			<div className='p-5'>
				<h3 className='mb-2 text-sm font-bold'>
					{intl.get('eastside_market_select_sku_pls')}
				</h3>

				<SelectSkuTable
					rows={unwrapPricingLine(content as PricingLineModel)}
					onSelect={(row) => {
						openDrawer('PRODUCT_EASTSIDE', {
							content: row,
						})
					}}
				/>
			</div>
		)
	} else {
		return (
			<Content
				content={content as ProductEntity}
				pcRuleCompetitors={pcRuleCompetitors}
			/>
		)
	}
}
const columnHelper = createColumnHelper<ProductEntity>()
function SelectSkuTable({
	rows,
	onSelect,
}: {
	rows: ProductEntity[]
	onSelect: (row: ProductEntity) => void
}) {
	const columns = useMemo(
		() => [
			columnHelper.accessor('sku', {
				size: 140,
				enableSorting: false,
				header: () => intl.get('sku'),
				cell: (info) => {
					return (
						<div
							className='text-link truncate text-xs font-medium'
							onClick={() => onSelect(info.row.original)}
						>
							{info.getValue()}
						</div>
					)
				},
			}),
			columnHelper.accessor('title', {
				enableSorting: false,
				header: () => intl.get('field_schema_title'),
				cell: (info) => {
					return (
						<div className='truncate text-xs font-medium'>
							{info.getValue()}
						</div>
					)
				},
			}),
		],
		[onSelect]
	)
	const table = useReactTable<ProductEntity>({
		data: rows,
		columns,
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		getCoreRowModel: getCoreRowModel(),
	})
	return (
		<div className='flex max-h-[350px] w-full flex-col overflow-hidden rounded-lg border border-solid border-base'>
			<DataTable getRowClassName={() => 'h-9'} compact table={table} />
		</div>
	)
}
function TableLoadingPlaceholder() {
	return (
		<div className='flex w-full animate-pulse flex-col space-y-3 p-2'>
			<div className='items-cneter flex'>
				<div className='h-3 w-20 rounded-lg bg-accent-4' />
				<div className='ml-10 h-3 w-8 rounded-lg bg-accent-4' />
				<div className='ml-7 h-3 w-11 rounded-lg bg-accent-4' />
				<div className='ml-auto h-3 w-6 rounded-lg bg-accent-4' />
			</div>
			<div className='items-cneter flex'>
				<div className='h-3 w-24 rounded-lg bg-accent-4' />
				<div className='ml-6 h-3 w-9 rounded-lg bg-accent-4' />
				<div className='ml-6 h-3 w-11 rounded-lg bg-accent-4' />
				<div className='ml-auto h-3 w-5 rounded-lg bg-accent-4' />
			</div>
			<div className='items-cneter flex'>
				<div className='h-3 w-20 rounded-lg bg-accent-4' />
				<div className='ml-10 h-3 w-11 rounded-lg bg-accent-4' />
				<div className='ml-4 h-3 w-11 rounded-lg bg-accent-4' />
				<div className='ml-auto h-3 w-4 rounded-lg bg-accent-4' />
			</div>
			<div className='items-cneter flex'>
				<div className='h-3 w-28 rounded-lg bg-accent-4' />
				<div className='ml-2 h-3 w-10 rounded-lg bg-accent-4' />
				<div className='ml-5 h-3 w-11 rounded-lg bg-accent-4' />
				<div className='ml-auto h-3 w-5 rounded-lg bg-accent-4' />
			</div>
		</div>
	)
}

const MY_SERIE_NAME = 'My price'
const LIMIT_TO_SHOW_SERIES = 7

const createMyData = (
	data: PriceHistoryDataPointModel[]
): PriceHistoryDataPointModel & {
	label: string
	color: string
} =>
	Object.assign({}, last<PriceHistoryDataPointModel>(data), {
		label: MY_SERIE_NAME,
		color: '#6B7176',
	})

const concatMarketData = (
	marketData: CompetitorDataGroup[],
	myData: PriceHistoryDataPointModel[]
): CompetitiveDataPointExtended[] => [
	createMyData(myData),
	...pickLastData(marketData),
]

const createHistoricalSeries = (
	myData: PriceHistoryDataPointModel[],
	competitiveData: CompetitorDataGroup[]
) => [
	createSerie(MY_SERIE_NAME, '#6B7176', prepareSerieDataset(myData)),
	...competitiveData.map(({ competitor_name, color, objects }) =>
		createSerie(competitor_name!, color!, prepareSerieDataset(objects))
	),
]
const createVisibleSeries = (
	dataset: CompetitorDataGroup[],
	competitors: string[]
) =>
	dataset
		.filter(({ competitor_name }) => competitors.includes(competitor_name!))
		.map(prop('competitor_name'))
		.splice(0, LIMIT_TO_SHOW_SERIES - 1)
		.concat([MY_SERIE_NAME])
