import React from 'react'

import { components, MenuProps } from '@cmpkit/select'

import { DataOption } from '@/common.types'

const Menu = ({
	children,
	...props
}: {
	children: React.ReactNode
} & MenuProps<DataOption, false> & {
		selectProps: {
			topCaption?: string
			bottomCaption?: string
		}
	}) => {
	return (
		// eslint-disable-next-line
		<components.Menu {...(props as any)}>
			{props.selectProps.topCaption && (
				<div className='p-2.5'>{props.selectProps.topCaption}</div>
			)}
			{children}
			{props.selectProps.bottomCaption && (
				<div className='p-2.5'>{props.selectProps.bottomCaption}</div>
			)}
		</components.Menu>
	)
}
export default Menu
