import React from 'react'

import authBgImg from '@/assets/img/authorization.jpg'

const bgImgStyle = {
	backgroundImage: `url(${authBgImg})`,
}
export default function CoverBackground() {
	return (
		<div className='z-[-1] size-full bg-cover bg-center' style={bgImgStyle} />
	)
}
