import React, { ReactNode } from 'react'

import { Button, Card, MenuItem, MenuList } from '@cmpkit/base'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import Popover from '@cmpkit/popover'

import intl from '@/locale'

type MoveOrCopyPcsDropdownProps = {
	actions: {
		label: string
		enhanceBefore: ReactNode
		onClick(): void
	}[]
}
function Content({
	actions,
	close,
}: MoveOrCopyPcsDropdownProps & { close(): void }) {
	return (
		<Card className='border py-2 shadow'>
			<MenuList className='w-56'>
				{actions?.map((action) => (
					<MenuItem
						key={action.label}
						onClick={() => {
							action.onClick()
							close()
						}}
					>
						{action.enhanceBefore}
						<span>{action.label}</span>
					</MenuItem>
				))}
			</MenuList>
		</Card>
	)
}
export default function MoveOrCopyPcsDropdown(
	props: MoveOrCopyPcsDropdownProps
) {
	return (
		<Popover
			placement='bottom-end'
			content={({ close }) => <Content {...props} close={close} />}
		>
			<Button iconAfter={<CaretDownIcon />}>
				{intl.get('pc.action.copy_or_reassign').d('Copy/reassign PCs')}
			</Button>
		</Popover>
	)
}
