import clsx from 'clsx'
import React, { ChangeEvent, useState } from 'react'

import { InlineEdit, Input } from '@cmpkit/base'

import { CellPropControlled } from '../'
import TableCell from './TableCell'

export default function TableCellEditor(
	props: CellPropControlled<string | null>
) {
	const [editValue, setEditValue] = useState(props.value)
	const isEdited = !!props?.isChanged

	const onChange = (event: ChangeEvent<HTMLInputElement>) =>
		setEditValue(event.target.value)
	const onConfirm = () => {
		if (editValue === props.value) {
			return
		}
		props.onChange?.({
			row: props.row,
			schema: props.schema,
			value: editValue,
		})
		setEditValue(null)
	}

	return (
		<InlineEdit
			className={clsx('cursor-pointer', {
				'text-brand': isEdited,
			})}
			areActionButtonsHidden
			shouldConfirmOnEnter
			editView={
				<Input
					defaultValue={props.value as string}
					value={editValue as string}
					autoFocus
					onChange={onChange}
				/>
			}
			readView={<TableCell {...props} value={props.value || '-'} />}
			onConfirm={onConfirm}
			onCancel={onConfirm}
		/>
	)
}
