import { indexBy, isNull, map, omitBy, prop } from 'lodash/fp'

import { ColumnSchemaModel } from '@/modules/core/types'
import Storage from '@/services/local-storage'

import {
	ColumnDefinition,
	StoredColumnConfig,
	toStoredColumnConfig,
} from './columns'
import { toQueryObject } from './location'

const TABLE_KEY = 'products_1'

/**
 * Create soirting function for columns based on configuration
 * @param config array of column configuration objects
 * @returns function that can be used to sort columns
 */
const getOrderingFunction = (config: StoredColumnConfig[]) => {
	const sorting = map(prop('key'), config)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (c1: any, c2: any) => {
		const index1 = sorting.indexOf(c1.key)
		const index2 = sorting.indexOf(c2.key)
		return (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity)
	}
}

/**
 * Get merged configuration from columns and configuration
 * @param columns array of column objects from schema
 * @param config array of column configuration objects
 * @returns array of column configuration objects with merged configuration
 */
export const mergedConfiguration = (
	columnsDefinitions: ColumnDefinition[],
	config: StoredColumnConfig[]
): StoredColumnConfig[] => {
	const confObj = indexBy('key', config)
	return columnsDefinitions
		.map(
			(columnOptionConfig) =>
				confObj[columnOptionConfig.column.id] ||
				toStoredColumnConfig(columnOptionConfig)
		)
		.sort(getOrderingFunction(config))
}

/**
 * Apply configuration to columns
 * @param columns array of column objects from schema
 * @param config array of column configuration objects
 * @returns array of column objects with applied configuration
 */
export const applyConfiguration = (
	columns: {
		key: string
		enabled: boolean
	}[],
	config: StoredColumnConfig[]
) => {
	const confObj = indexBy('key', config)
	return columns
		.filter((col) => confObj?.[col.key]?.enabled ?? col.enabled)
		.map((col) => ({
			...col,
			locked: confObj?.[col.key]?.locked ?? false, //?? col.locked ?? false,
			width: confObj?.[col.key]?.width ?? 100, // ?? col.width ?? 100,
		}))
		.sort(getOrderingFunction(config))
}

/**
 * Get stored configuration for table template
 * @returns - object with row_height, columns_width, grouped_by_key, columns_config properties
 */
export const getLocalTemplateConfig = () => {
	try {
		const data = Storage.get(['row_height', 'columns_width', 'grouped_by_key'])
		const columns_config = Storage.get(`table_${TABLE_KEY}_config`) || undefined
		const [row_height, columns_width, grouped_by_key] = data
		return {
			row_height: row_height ?? 34,
			columns_width: columns_width ?? null,
			grouped_by_key: grouped_by_key ?? null,
			columns_config: columns_config ?? [],
		}
	} catch (error) {
		return {
			row_height: 34,
			columns_width: null,
			grouped_by_key: null,
			columns_config: [],
		}
	}
}

/**
 * Get query object for request to API
 * @param fields  - array of fields
 * @param format  - format of response
 * @param aggregation  - aggregation type
 * @returns object with fields, format, aggregation properties
 */
export const createQuery = (
	fields: string[],
	format: string,
	aggregation?: boolean
) => {
	const globalQueryString = toQueryObject(window.location.search)
	const optionsQuery: Record<string, string | string[] | boolean> = {
		fields,
		format,
	}
	if (aggregation) optionsQuery['aggregation'] = 'product_line'
	return { ...globalQueryString, ...omitBy(isNull, optionsQuery) }
}

const getLocalConfig = () => getLocalTemplateConfig()?.columns_config ?? []

/**
 * Prepare fields for request to API
 * @param schema - array of column objects from schema
 * @param ignoredFields  - array of ignored fields
 * @returns  array of fields
 */
export const prepareFieldsWithLocalConfig = (
	schema: ColumnSchemaModel[],
	ignoredFields: string[]
) => prepareFields(schema, ignoredFields, getLocalConfig())

/**
 * Get stored configuration for table template
 * @param schema - array of column objects from schema
 * @param ignoredFields  - array of ignored fields
 * @param config  - array of column configuration objects
 * @returns  array of fields
 */
export const prepareFields = (
	schema: ColumnSchemaModel[] = [],
	ignoredFields: string[] = [],
	config: StoredColumnConfig[] = []
) => {
	const fullColumnsSchema = schema.map(({ name, hidden }) => ({
		key: name,
		enabled: !hidden,
	}))
	return applyConfiguration(fullColumnsSchema, config)
		.map<string>(prop('key'))
		.filter((i: string) => !ignoredFields.includes(i))
}
