import React from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'

import { Button, Loader, Spinner } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import {
	FormProvider,
	RHFCheckbox,
	RHFTextArea,
	RHFTextField,
} from '@/components/HookForm'
import { FilterRuleModel } from '@/generated'
import intl from '@/locale'
import { useAuthorization } from '@/modules/Auth/authorization'
import {
	useCreateGlobalFilterMutation,
	useCreatePersonalFilterMutation,
} from '@/modules/preferences/mutations'
import analytic from '@/services/analytics'
import {
	actCreated,
	errorMessage,
	processingMessage,
	successActionMessage,
} from '@/tools/message'

const SaveReportSchema = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	description: Yup.string().max(
		255,
		'Description must be less than 255 characters'
	),
	is_global: Yup.boolean(),
})

type CreateReportModal = {
	filters: FilterRuleModel[]
	isOpen: boolean
	close(): void
}

export default function SavedReportsCreateReportModal({
	isOpen,
	close,
	filters,
}: CreateReportModal) {
	const { checkPermissons } = useAuthorization()
	const queryClient = useQueryClient()

	const canManageGlobalFilters = checkPermissons(['MANAGE_GLOBAL_FILTERS'])

	/**
	 * Mutations
	 */
	const createGlobalFilter = useCreateGlobalFilterMutation({
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['global-filters'],
			}),
	})
	const createPersonalFilter = useCreatePersonalFilterMutation({
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['personal-filters'],
			}),
	})

	const isLoading =
		createGlobalFilter.isPending || createPersonalFilter.isPending

	const methods = useForm({
		defaultValues: {
			name: '',
			description: '',
			is_global: false,
		},
		resolver: yupResolver(SaveReportSchema),
	})
	const _handleSubmit = async ({
		is_global,
		name,
		description,
	}: {
		is_global?: boolean
		name: string
		description?: string
	}) => {
		const body = {
			name,
			params: {
				description,
				query: filters,
			},
		}
		toast.loading(processingMessage(), {
			id: 'filters',
		})
		analytic.logEvent('reports: create report', {
			'Is global': is_global,
		})
		try {
			if (is_global) {
				await createGlobalFilter.mutateAsync(body)
			} else {
				await createPersonalFilter.mutateAsync(body)
			}
			toast.success(successActionMessage('Report', actCreated()), {
				id: 'filters',
			})
		} catch (error) {
			toast.error(errorMessage(), {
				id: 'filters',
			})
		} finally {
			close()
		}
	}
	const handleSubmit = methods.handleSubmit(_handleSubmit)

	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={close} showCloseButton size='mini' zIndex={1850}>
					<ModalHeader>
						<ModalTitle>
							{intl.get('saved_reports.create.modal.title')}
						</ModalTitle>
					</ModalHeader>
					<ModalBody className='flex flex-col'>
						<Blanket
							isTinted={isLoading}
							className='absolute flex items-center justify-center bg-white/50 backdrop-blur-sm dark:bg-black/50'
						>
							<Loader />
						</Blanket>
						<FormProvider methods={methods} onSubmit={handleSubmit}>
							<RHFTextField
								required
								label={intl.get('report_name')}
								name='name'
								className='w-full'
							/>
							<RHFTextArea
								label={intl.get('general_description')}
								name='description'
								className='w-full'
								rows={6}
							/>
							{canManageGlobalFilters && (
								<RHFCheckbox
									label={intl.get('make_visible_for_all')}
									name='is_global'
								/>
							)}
						</FormProvider>
					</ModalBody>
					<ModalFooter className='justify-between'>
						<Button onClick={close}>{intl.get('general_cancel')}</Button>
						<Button
							variant='primary-brand'
							iconBefore={isLoading && <Spinner variant='invert' />}
							disabled={isLoading}
							onClick={handleSubmit}
						>
							{intl.get('general_save_and_apply')}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}
