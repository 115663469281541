import { negate } from 'lodash/fp'
import React, { useMemo } from 'react'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import { Button, Card, LinkButton, MenuItem, MenuList } from '@cmpkit/base'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'
import PenEditIcon from '@cmpkit/icon/lib/glyph/pen-edit'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import QueueIcon from '@cmpkit/icon/lib/glyph/queue'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import Popover from '@cmpkit/popover'

import { dialog } from '@/components/dialogs'
import notify from '@/components/toasts/helpers'
import { SettingsTemplateType } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useAuthorization } from '@/modules/Auth/authorization'
import { useOptimizationIsReadyForChange } from '@/modules/core/hooks/useOptimizationIsReadyForChange'
import { useDeleteOptimizationGroupMutation } from '@/modules/core/mutations'
import { useModalStore } from '@/modules/modals/store'
import { isGlobalPricingCampaign } from '@/modules/pricing-campaigns/helpers'
import { useCreateSettingsTemplateMutation } from '@/modules/settings-templates'
import { client } from '@/network/client'
import analytic from '@/services/analytics'
import { processingMessage } from '@/tools/message'

export default function OptimizationGroupActionsDropdown() {
	const optimizationGroupId = useOptimizationGroupId()!
	return (
		<Popover
			placement='bottom-end'
			content={({ close }) => (
				<OptimizationGroupActionsMenu
					optimizationGroupId={optimizationGroupId}
					close={close}
				/>
			)}
		>
			<Button
				data-testid='og-actions-trigger'
				variant='tertiary'
				iconBefore={<DotsFilledIcon />}
			/>
		</Popover>
	)
}

export function OptimizationGroupActionsMenu({
	close,
	optimizationGroupId,
}: {
	close: () => void
	optimizationGroupId: string
}) {
	const menuItems = useOptimizationGroupActionsList(optimizationGroupId)
	return (
		<Card className='min-w-56 border py-2'>
			<MenuList>
				{menuItems.map(({ testId, onClick, icon: Icon, label, ...props }) => {
					return (
						<MenuItem
							key={testId}
							data-testid={testId}
							onClick={() => {
								onClick()
								close()
							}}
							{...props}
						>
							<Icon />
							{label}
						</MenuItem>
					)
				})}
			</MenuList>
		</Card>
	)
}

export function useOptimizationGroupActionsList(optimizationGroupId: string) {
	const queryClient = useQueryClient()
	const { showModal } = useModalStore()
	const { checkPermissons } = useAuthorization()
	const canManage = checkPermissons(['MANAGE_OPTIMIZATION_GROUP'])
	const isReadyToChange = useOptimizationIsReadyForChange(optimizationGroupId)
	const createTemplateMutation = useCreateSettingsTemplateMutation({
		onMutate() {
			toast.loading(processingMessage(), {
				id: 'saving_template',
			})
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['settings-templates'],
			})
		},
		onSettled() {
			toast.dismiss('saving_template')
		},
	})
	const deleteOptimizationGroupMutation = useDeleteOptimizationGroupMutation({
		onSuccess() {
			queryClient.invalidateQueries({ queryKey: ['optimization-groups'] })
			queryClient.invalidateQueries({ queryKey: ['main-scenario'] })
		},
	})
	const handleEditInfo = () => {
		analytic.logEvent('og settings: description modal: open')
		showModal('UPDATE_OPTIMIZATION_GROUP_INFO', {
			optimizationGroupId,
		})
	}
	const handleSaveAsTemplate = async () => {
		analytic.logEvent('og settings: save scenario as template')
		try {
			const pricingCampaigns = await client.pricingCampaign.pricingCampaignList(
				{
					optimization_group_id: optimizationGroupId,
				}
			)
			const scenario =
				await client.scenario.scenarioGetMain(optimizationGroupId)
			const optimizationGroup =
				await client.core.getOptimizationGroup(optimizationGroupId)
			const result = await createTemplateMutation.mutateAsync({
				template_type: SettingsTemplateType.Scenario,
				name: optimizationGroup.name,
				body: {
					name: optimizationGroup.name,
					settings: scenario.settings,
					pricing_campaigns: pricingCampaigns
						.filter(negate(isGlobalPricingCampaign))
						.map((campaign) => ({
							name: campaign.name,
							order: campaign.order,
							settings: campaign.settings,
						})),
				},
			})
			if (result) {
				notify.success(
					{
						text: (
							<div>
								{intl.get('templates').d('Template')}{' '}
								<LinkButton
									variant='brand'
									onClick={() =>
										showModal('SETTINGS_TEMPLATE_MODAL', {
											templateId: result.id,
											templateType: result.template_type,
										})
									}
								>
									{result.name}
								</LinkButton>{' '}
								{intl
									.get('template_saved_successfully')
									.d('was saved successfully')}
							</div>
						),
						variant: 'success',
					},
					{
						duration: 5000,
					}
				)
			}
		} catch (error) {
			notify.error({
				text: intl.get('fatal_error_title'),
			})
		}
	}
	const handleDelete = async () => {
		const answer = await dialog.confirmDelete({
			title: intl.get('general_detete_confirm_title'),
			text: intl.get('general_detete_confirm_subtitle'),
			okText: intl.get('general_delete'),
		})
		if (answer) {
			analytic.logEvent('og settings: delete og')
			await toast.promise(
				deleteOptimizationGroupMutation.mutateAsync(optimizationGroupId),
				{
					loading: intl.get('deleting.proccess').d('Deleting...'),
					success: intl.get('deleting.success').d('Deleted successfully'),
					error: intl.get('deleting.error').d('Error while deleting'),
				}
			)
		}
	}
	const showCopySettingsModal = () => {
		analytic.logEvent('settings: copy settings: start')
		showModal('COPY_SETTINGS', {
			optimizationGroupId,
		})
	}
	const canCopySettings = checkPermissons([
		'CREATE_PRICING_CAMPAIGNS',
		'EDIT_PRICING_CAMPAIGNS_SETTINGS',
		'EDIT_PRICING_CAMPAIGNS_PRODUCTS',
		'DELETE_PRICING_CAMPAIGNS',
		'CHANGE_DEFAULT_PRICING_CAMPAIGN',
	])
	return useMemo(() => {
		return [
			{
				label: intl.get('app.edit_name').d('Edit name'),
				icon: PenEditIcon,
				onClick: handleEditInfo,
				disabled: !canManage,
				testId: 'og-action-edit-name',
			},
			{
				label: intl.get('cp_og_setting_btn'),
				icon: QueueIcon,
				onClick: showCopySettingsModal,
				disabled: !canCopySettings,
				testId: 'og-action-copy-settings',
			},
			{
				label: intl
					.get('settings.action.save_as_template')
					.d('Save as template'),
				icon: PlusIcon,
				onClick: handleSaveAsTemplate,
				testId: 'og-action-save-as-template',
			},
			{
				label: intl.get('general_delete'),
				icon: Trash2Icon,
				onClick: handleDelete,
				disabled: !canManage || !isReadyToChange,
				variant: 'destructive' as const,
				testId: 'og-action-delete',
			},
		]
	}, [canCopySettings, optimizationGroupId, canManage, isReadyToChange])
}
