import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
	AnchorsDeleteResultResponseData,
	AnchorsUpdateResultResponseData,
	DeleteRelationsRequestModel,
	ImportRelationsRequestModel,
	ImportResultResponseData,
	ImportResultResponseError,
	UpdateRelationsRequestModel,
} from '@/generated'
import { client } from '@/network/client'

export function useImportAnchoringFromFileMutation(
	options?: UseMutationOptions<
		ImportResultResponseData,
		AxiosError<ImportResultResponseError>,
		ImportRelationsRequestModel
	>
) {
	return useMutation<
		ImportResultResponseData,
		AxiosError<ImportResultResponseError>,
		ImportRelationsRequestModel
	>({
		mutationFn: (payload) => {
			return client.anchoring.importRelations(payload)
		},
		...(options || {}),
	})
}
export function useUpdateAnchoringMutation(
	options?: UseMutationOptions<
		AnchorsUpdateResultResponseData,
		Error,
		UpdateRelationsRequestModel
	>
) {
	return useMutation<
		AnchorsUpdateResultResponseData,
		Error,
		UpdateRelationsRequestModel
	>({
		mutationFn: (payload) => {
			return client.anchoring.updateRelations(payload)
		},
		...(options || {}),
	})
}
export function useDeleteAnchoringMutation(
	options?: UseMutationOptions<
		AnchorsDeleteResultResponseData,
		Error,
		DeleteRelationsRequestModel
	>
) {
	return useMutation<
		AnchorsDeleteResultResponseData,
		Error,
		DeleteRelationsRequestModel
	>({
		mutationFn: (payload) => {
			return client.anchoring.deleteRelations(payload)
		},
		...(options || {}),
	})
}
