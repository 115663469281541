import clsx from 'clsx'
import { formatDistanceToNow } from 'date-fns'
import { any, find, prop } from 'lodash/fp'
import React, { useMemo, useState } from 'react'
import { Controller, FieldErrors } from 'react-hook-form'

import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CardSubtitle,
	CardTitle,
	InlineMessage,
	Loader,
	Separator,
	Spinner,
	TabItem,
	Tabs,
	Tag,
	TreeNodeType,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import { useDebounce } from '@cmpkit/hooks'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'
import Popover from '@cmpkit/popover'

import { dialog } from '@/components/dialogs'
import ErrorBoundary from '@/components/ErrorBoundary'
import { toAdaptedSchema } from '@/components/filter/adapter'
import { FilterField } from '@/components/filter/types'
import { FormProvider, RHFSelect } from '@/components/HookForm'
import RHFSettingsWidget from '@/components/HookForm/RHFSettingsWidget'
import RHFStoplistCustomRules from '@/components/HookForm/RHFStoplistCustomRules'
import LabeledValue from '@/components/LabeledValue'
import NavigationPrompt from '@/components/NavigationPrompt'
import { TreeCheckbox } from '@/components/TreePicker'
import UsernameById from '@/components/UsernameById'
import {
	SettingsTemplateType,
	StopListSettingsModel,
	StrategiesSettingsModel,
} from '@/generated'
import intl from '@/locale'
import { ScheduleDate } from '@/modules/core'
import {
	useBrandsQuery,
	useCategoriesQuery,
	useColumnsSchemaQuery,
	useStopListSuggestsQuery,
} from '@/modules/core/queries'
import {
	getOgStatus,
	getStatusBadgeVariant,
	toCategoriesTree,
} from '@/modules/core/utils'
import { getEnumOptions, translateOptions } from '@/tools/json-schema-utils'
import { formatNumber } from '@/tools/locale'

import { useSettingActions } from '../hooks/useSettingsActions'
import { BusinessSettingsModel, OptimizationGroupFormData } from '../types'
import { OptimizationGroupActionsMenu } from './OptimizationGroupActionsDropdown'
import {
	OptimizationGroupItemProvider,
	useOptimizationGroupItemContext,
} from './OptimizationGroupItemProvider'
import SettingActionsDropdown from './SettingsActionsDropdown'

export default function OptimizationGroupModal({
	isOpen,
	close,
	optimizationGroupId,
	initialTabId,
}: {
	isOpen: boolean
	close: () => void
	optimizationGroupId: string
	initialTabId?: 'structure' | 'strategy' | 'business' | 'stop_list'
}) {
	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					disableFocusTrap
					position='none'
					size={'xlarge'}
					testId='og-modal'
				>
					<ErrorBoundary>
						<OptimizationGroupItemProvider
							onFinish={close}
							optimizationGroupId={optimizationGroupId}
						>
							<OptimizationGroupForm
								initialTabId={initialTabId}
								close={close}
							/>
						</OptimizationGroupItemProvider>
					</ErrorBoundary>
				</Modal>
			)}
		</ModalTransition>
	)
}

function OptimizationGroupForm({
	close,
	initialTabId,
}: {
	close: () => void
	initialTabId?: 'structure' | 'strategy' | 'business' | 'stop_list'
}) {
	const context = useOptimizationGroupItemContext()
	const { optimizationGroup, optimization, isReadyToChange, schedule } = context

	const [currentTab, setCurrentTab] = useState<string>(
		initialTabId || 'structure'
	)
	const tabs = useMemo(() => getTabs(), [])
	const TabContent = useMemo(
		() => find({ key: currentTab }, tabs)?.TabContent ?? 'div',
		[currentTab]
	)
	const handleClose = async () => {
		if (context.methods.formState.isDirty) {
			const answer = await dialog.confirm({
				title: intl.get('navigation.blocked.pc_settings.title'),
				text: intl.getHTML('navigation.blocked.pc_settings.message'),
				okText: intl.get('navigation.blocked.pc_settings.ok'),
				cancelText: intl.get('navigation.blocked.pc_settings.cancel'),
			})
			if (answer) {
				close()
			}
		} else {
			close()
		}
	}

	return (
		<>
			<NavigationPrompt
				when={context.methods.formState?.isDirty}
				title={intl.get('navigation.blocked.pc_settings.title')}
				message={intl.getHTML('navigation.blocked.pc_settings.message')}
				okText={intl.get('navigation.blocked.pc_settings.ok')}
				cancelText={intl.get('navigation.blocked.pc_settings.cancel')}
			/>
			<FormProvider
				methods={context.methods}
				onSubmit={context.handleSubmit}
				className='flex h-full flex-col overflow-hidden'
			>
				<Blanket
					className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-lg dark:bg-black/50'
					isTinted={context.isPending}
				>
					<div className='flex flex-col items-center justify-center gap-2'>
						<Loader />
						<span className='text-muted'>
							{intl
								.get('og.modal.pending_hint')
								.d('Oprimization group is saving...')}
						</span>
					</div>
				</Blanket>
				<ModalHeader className='pr-10'>
					<div className='flex w-full items-start justify-between'>
						<ModalTitle data-testid='og-modal-title'>
							{context.methods.watch('name') || 'None'}
						</ModalTitle>
						<div className='flex items-center gap-2'>
							{optimizationGroup && (
								<Popover
									placement='bottom-end'
									content={({ close }) => (
										<OptimizationGroupActionsMenu
											optimizationGroupId={optimizationGroup.id}
											close={close}
										/>
									)}
								>
									<Button
										data-testid='og-actions-trigger'
										variant='tertiary'
										size='small'
										iconBefore={<DotsFilledIcon />}
									/>
								</Popover>
							)}
							<Separator orientation='vertical' className='mr-2 h-6' />
						</div>
					</div>
				</ModalHeader>
				<div className='relative border-b border-solid'>
					<div className='flex flex-col gap-1 p-4'>
						<div className='mt-2 flex items-start gap-10'>
							<LabeledValue label={intl.get('general_status').d('Status')}>
								{optimization ? (
									<Tag
										variant={getStatusBadgeVariant(
											getOgStatus(optimization.status)
										)}
									>
										{intl.get(`opt_${getOgStatus(optimization.status)}`)}
									</Tag>
								) : (
									'-'
								)}
							</LabeledValue>
							<LabeledValue label={intl.get('og.items').d('Items in OG')}>
								{optimization ? (
									<span className='font-medium'>
										{formatNumber(optimization?.total_products)}
									</span>
								) : (
									'-'
								)}
							</LabeledValue>
							<LabeledValue
								label={intl.get('general_schedule').d('Schedule repricing')}
							>
								{schedule ? (
									<ScheduleDate className='font-medium' schedule={schedule} />
								) : (
									'-'
								)}
							</LabeledValue>
							<LabeledValue
								label={intl.get('last_modified_at').d('Last modified')}
							>
								{!!optimizationGroup?.updated_at ? (
									<p className='flex items-center font-medium'>
										{formatDistanceToNow(
											new Date(optimizationGroup.updated_at + 'Z'),
											{ addSuffix: true }
										)}
										{optimizationGroup?.updated_by && ' by '}
										{optimizationGroup?.updated_by && (
											<UsernameById userId={optimizationGroup.updated_by} />
										)}
									</p>
								) : optimizationGroup?.created_at ? (
									<p className='flex items-center font-medium'>
										{formatDistanceToNow(
											new Date(optimizationGroup.created_at + 'Z'),
											{ addSuffix: true }
										)}
										{optimizationGroup?.created_by && ' by '}
										{optimizationGroup?.created_by && (
											<UsernameById userId={optimizationGroup.created_by} />
										)}
									</p>
								) : (
									'-'
								)}
							</LabeledValue>
						</div>
						{!isReadyToChange && (
							<InlineMessage
								variant='default'
								className={'mt-2 inline-flex items-center text-xs font-medium'}
								icon={<InfoIcon />}
							>
								{intl.get(
									`app.optimization.update_is_forbidden.${optimization?.status}`
								)}
							</InlineMessage>
						)}
					</div>
					<Tabs className='-mb-px'>
						{tabs.map((tab) => (
							<TabItem
								data-testid='og-modal-tab'
								data-tabid={tab.key}
								key={tab.key}
								active={currentTab === tab.key}
								onClick={() => setCurrentTab(tab.key)}
								className={clsx({
									'text-danger': tab.hasError(context.methods.formState.errors),
								})}
							>
								{tab.label}
								{tab.hasError(context.methods.formState.errors) && (
									<AlertIcon />
								)}
							</TabItem>
						))}
					</Tabs>
				</div>
				<ModalBody className='bg-accent-2'>
					<TabContent />
				</ModalBody>
				<ModalFooter className='justify-between border-t'>
					<Button
						onClick={handleClose}
						data-testid='og-modal-cancel'
						disabled={context.isPending}
					>
						{intl.get('general_cancel')}
					</Button>
					<div className='flex items-center gap-2'>
						{context.isReadyToChange && context.methods.formState.isDirty && (
							<div className='flex items-center space-x-2'>
								<Button
									disabled={context.isPending}
									data-testid={'og-form-revert-changes'}
									onClick={context.handleReset}
									variant='tertiary'
									iconBefore={<UndoIcon />}
								>
									{intl.get('app.revert_changes')}
								</Button>
								<Button
									disabled={context.isPending}
									data-testid={'og-form-save-and-update'}
									onClick={context.handleSubmitAndUpdate}
								>
									{intl.get('app.save_and_update')}
								</Button>
								<Button
									variant='primary-warning'
									data-testid={'og-form-save'}
									onClick={context.handleSubmit}
									disabled={context.isPending}
									iconBefore={<CheckIcon />}
								>
									{intl.get('general_save')}
								</Button>
							</div>
						)}
					</div>
				</ModalFooter>
				<ModalCloseButton onClick={handleClose} />
			</FormProvider>
		</>
	)
}

const getTabs = () => [
	{
		key: 'structure',
		label: intl.get('settings.structure.short').d('Structure'),
		hasError: (errors: FieldErrors<OptimizationGroupFormData>) =>
			errors?.sales_level_id ||
			errors?.sales_entities ||
			errors?.product_groups,
		TabContent: StructureTab,
	},
	{
		key: 'strategy',
		label: intl.get('settings.straregy.short').d('Strategy'),
		hasError: (errors: FieldErrors<OptimizationGroupFormData>) =>
			errors?.strategy,
		TabContent: StrategyTab,
	},

	{
		key: 'business',
		label: intl.get('settings.business.short').d('Constrains'),
		hasError: (errors: FieldErrors<OptimizationGroupFormData>) =>
			errors?.business,
		TabContent: BusinessConstraintsTab,
	},
	{
		key: 'stop_list',
		label: intl.get('settings.stop_list.short').d('Stop list'),
		hasError: (errors: FieldErrors<OptimizationGroupFormData>) =>
			errors?.stop_list,
		TabContent: StopListTab,
	},
]
function StructureTab() {
	const {
		isLoading,
		canManage,
		methods,
		salesEntitiesTree,
		productGroupsTree,
	} = useOptimizationGroupItemContext()
	const isDiabled = !canManage

	if (isLoading) {
		return (
			<div className='flex size-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	return (
		<>
			<Card
				data-testid='og-settings-sales-entities-panel'
				className='border shadow-none'
			>
				<CardHeader>
					<CardTitle as='h3'>{intl.get('sales_entities')}</CardTitle>
					<CardSubtitle>
						{intl.get('og_settings.general.sales_entities.subtitle')}
					</CardSubtitle>
				</CardHeader>
				<CardContent>
					<ErrorBoundary>
						<Controller
							control={methods.control}
							name='sales_entities'
							render={({ field, fieldState }) => {
								return (
									<>
										<TreeCheckbox
											disabled={isDiabled}
											tree={salesEntitiesTree || []}
											value={field.value}
											onChange={field.onChange}
										/>
										{!!fieldState.error?.message && (
											<InlineMessage variant='danger' className='mt-5 w-full'>
												{fieldState.error?.message}
											</InlineMessage>
										)}
									</>
								)
							}}
						/>
					</ErrorBoundary>
				</CardContent>
			</Card>
			<Card
				data-testid='og-settings-product-groups-panel'
				className='mt-3 border shadow-none'
			>
				<CardHeader>
					<CardTitle as='h3'>{intl.get('product_groups')}</CardTitle>
					<CardSubtitle>
						{intl.get('og_settings.general.product_groups.subtitle')}
					</CardSubtitle>
				</CardHeader>
				<CardContent>
					<ErrorBoundary>
						<Controller
							control={methods.control}
							name='product_groups'
							render={({ field, fieldState }) => (
								<>
									<TreeCheckbox
										disabled={isDiabled}
										tree={productGroupsTree || []}
										value={field.value}
										onChange={field.onChange}
									/>
									{!!fieldState.error?.message && (
										<InlineMessage variant='danger' className='mt-5 w-full'>
											{fieldState.error?.message}
										</InlineMessage>
									)}
								</>
							)}
						/>
					</ErrorBoundary>
				</CardContent>
			</Card>
		</>
	)
}
function StrategyTab() {
	const { schema, isLoading, isReadyToChange, optimizationGroup, methods } =
		useOptimizationGroupItemContext()

	const handleUseTemplate = ({
		strategy,
	}: {
		strategy: StrategiesSettingsModel
	}) =>
		methods.setValue('strategy', strategy, {
			shouldDirty: true,
		})
	const settingActions = useSettingActions({
		handleUseTemplate: handleUseTemplate,
	})
	const strategySettingsWatcher = methods.watch('strategy')
	if (isLoading) {
		return (
			<div className='flex size-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	return (
		<Card
			data-testid='og-settings-strategies-panel'
			className='border shadow-none'
		>
			<CardHeader>
				<div className='flex justify-between'>
					<CardTitle as='h3'>
						{intl.get('settings.strategy.long').d('Pricing strategy')}
					</CardTitle>
					<SettingActionsDropdown
						actions={settingActions}
						templateType={SettingsTemplateType.ScenarioStrategy}
						isReadyToChange={!!isReadyToChange}
						settingName={optimizationGroup!.name}
						settings={{
							strategy: strategySettingsWatcher as StrategiesSettingsModel,
						}}
					>
						<Button
							data-testid={'pc-actions-popover-trigger'}
							variant='tertiary'
							className='w-xs ml-2'
							iconBefore={<DotsFilledIcon />}
						/>
					</SettingActionsDropdown>
				</div>
			</CardHeader>
			<CardContent>
				<Blanket
					isTinted={isLoading}
					className='absolute flex items-center justify-center rounded-lg bg-white/30 backdrop-blur-sm dark:bg-black/30'
				>
					<Loader />
				</Blanket>
				<ErrorBoundary>
					<RHFSelect
						name='strategy.demand_strategy.target'
						className='w-56'
						label={prop(
							'properties.strategy.properties.demand_strategy.properties.target.title',
							schema
						)}
						description={prop(
							'properties.strategy.properties.demand_strategy.properties.target.description',
							schema
						)}
						options={translateOptions(
							getEnumOptions(
								prop(
									'properties.strategy.properties.demand_strategy.properties.target',
									schema
								)
							)
						)}
					/>
					<RHFSelect
						name='strategy.demand_strategy.protect'
						className='w-56'
						label={prop(
							'properties.strategy.properties.demand_strategy.properties.protect.title',
							schema
						)}
						description={prop(
							'properties.strategy.properties.demand_strategy.properties.protect.description',
							schema
						)}
						options={translateOptions(
							getEnumOptions(
								prop(
									'properties.strategy.properties.demand_strategy.properties.protect',
									schema
								)
							)
						)}
					/>
				</ErrorBoundary>
			</CardContent>
			<CardHeader>
				<CardTitle as='h3'>
					{intl.get('optimization_groups_settings_tab_markdown_title')}
				</CardTitle>
				<CardSubtitle>
					{intl.get('optimization_groups_settings_tab_markdown_subtitle')}
				</CardSubtitle>
			</CardHeader>
			<CardContent>
				<ErrorBoundary>
					<RHFSelect
						name='strategy.markdown_strategy.target'
						className='w-56'
						label={prop(
							'properties.strategy.properties.markdown_strategy.properties.target.title',
							schema
						)}
						description={prop(
							'properties.strategy.properties.markdown_strategy.properties.target.description',
							schema
						)}
						options={translateOptions(
							getEnumOptions(
								prop(
									'properties.strategy.properties.markdown_strategy.properties.target',
									schema
								)
							)
						)}
					/>
				</ErrorBoundary>
			</CardContent>
		</Card>
	)
}
function BusinessConstraintsTab() {
	const { schema, isLoading, isReadyToChange, methods, optimizationGroup } =
		useOptimizationGroupItemContext()
	const handleUseTemplate = ({
		business,
	}: {
		business: BusinessSettingsModel
	}) =>
		methods.setValue('business', business, {
			shouldDirty: true,
		})
	const settingActions = useSettingActions({
		handleUseTemplate: handleUseTemplate,
	})
	const settingsWatcher = methods.watch('business')

	if (isLoading) {
		return (
			<div className='flex size-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	return (
		<Card
			data-testid='og-settings-business-panel'
			className='border shadow-none'
		>
			<CardHeader>
				<div className='flex justify-between'>
					<div>
						<CardTitle as='h3'>
							{intl.get('settings.business.long').d('Business constrains')}
						</CardTitle>
						<CardSubtitle>
							{intl.get('optimization_groups_settings_business_subtitle')}
						</CardSubtitle>
					</div>
					<SettingActionsDropdown
						actions={settingActions}
						templateType={SettingsTemplateType.ScenarioBusinessConstraints}
						isReadyToChange={!!isReadyToChange}
						settingName={optimizationGroup!.name}
						settings={{
							business: settingsWatcher as BusinessSettingsModel,
						}}
					>
						<Button
							data-testid={'pc-actions-popover-trigger'}
							variant='tertiary'
							className='w-xs ml-2'
							iconBefore={<DotsFilledIcon />}
						/>
					</SettingActionsDropdown>
				</div>
			</CardHeader>
			<CardContent>
				<Blanket
					isTinted={isLoading}
					className='absolute flex items-center justify-center rounded-lg bg-white/30 backdrop-blur-sm dark:bg-black/30'
				>
					<Loader />
				</Blanket>
				<ErrorBoundary>
					<RHFSettingsWidget
						name='business'
						schema={schema?.properties.business}
					/>
				</ErrorBoundary>
			</CardContent>
		</Card>
	)
}
function StopListTab() {
	const {
		schema,
		skuRules,
		methods,
		optimizationGroupId,
		optimizationGroup,
		isReadyToChange,
	} = useOptimizationGroupItemContext()
	const handleUseTemplate = ({
		stop_list,
	}: {
		stop_list: StopListSettingsModel
	}) =>
		methods.setValue('stop_list', stop_list, {
			shouldDirty: true,
		})
	const settingActions = useSettingActions({
		handleUseTemplate: handleUseTemplate,
	})
	const settingsWatcher = methods.watch('stop_list')
	const fieldsQuery = useColumnsSchemaQuery<FilterField[]>({
		select: (data) =>
			data
				.filter(({ stage }) => ['prepro', 'pre_init_fields'].includes(stage!))
				.map(toAdaptedSchema),
	})
	const brandsQuery = useBrandsQuery(
		optimizationGroupId ? { optimization_group_id: optimizationGroupId } : {}
	)
	const categoriesQuery = useCategoriesQuery<TreeNodeType[]>(
		{ optimization_group_id: optimizationGroupId! },
		{
			select: toCategoriesTree,
		}
	)
	const fields = useMemo(() => fieldsQuery.data || [], [fieldsQuery.data])
	const dataChoices = useMemo(() => {
		return {
			brands: brandsQuery.data || [],
			category_ids: categoriesQuery.data || [],
		}
	}, [brandsQuery.data, categoriesQuery.data])
	const debounceSettings = useDebounce(methods.watch('stop_list'), 2000)
	const predictedStopListCountsQuery = useStopListSuggestsQuery(
		optimizationGroupId!,
		debounceSettings
	)

	const isLoading = any(prop('isLoading'), [
		fieldsQuery,
		brandsQuery,
		categoriesQuery,
	])
	if (isLoading) {
		return (
			<div className='flex size-full items-center justify-center'>
				<Loader />
			</div>
		)
	}
	return (
		<div className='flex flex-col gap-3'>
			<Card
				data-testid='og-settings-stop-list-dafault-rules-panel'
				className='border shadow-none'
			>
				<CardContent>
					<ErrorBoundary>
						<div className='flex items-start divide-x'>
							<div className='flex w-1/3 flex-col gap-3'>
								<h3 className='space-y-3 font-bold'>
									{intl
										.get('manually_lock_products')
										.d('Manually lock products')}
								</h3>
								<LabeledValue label={intl.get('general_skus')}>
									<span
										data-testid='stop_list-locked-skus-manually-count'
										className='text-lg font-semibold'
									>
										{skuRules?.length ?? 0}
									</span>
								</LabeledValue>
							</div>
							<div className='flex w-2/3 flex-col gap-3 pl-5'>
								<h3 className='font-bold'>
									{intl.get('og_stoplist_suggest_title')}
								</h3>
								{predictedStopListCountsQuery.isLoading ? (
									<Spinner />
								) : (
									<div className='fade-in flex items-start gap-10'>
										<LabeledValue label={intl.get('general_skus')}>
											<span
												data-testid='stop_list-locked-skus-by-rules-count'
												className='text-lg font-semibold'
											>
												{predictedStopListCountsQuery.data?.products ?? 0}
											</span>
										</LabeledValue>
										<LabeledValue label={intl.get('general_product_lines')}>
											<span
												data-testid='stop_list-locked-pls-by-rules-count'
												className='text-lg font-semibold'
											>
												{predictedStopListCountsQuery.data?.pricing_lines ?? 0}
											</span>
										</LabeledValue>
									</div>
								)}
							</div>
						</div>
					</ErrorBoundary>
				</CardContent>
			</Card>
			<Card
				data-testid='og-settings-stop-list-custom-rules-panel'
				className='border shadow-none'
			>
				<CardHeader>
					<div className='flex justify-between'>
						<div>
							<CardTitle as='h3'>
								{intl.get('stop_list.default_rules.title')}
							</CardTitle>
							<CardSubtitle>
								{intl.get('stop_list.default_rules.subtitle')}
							</CardSubtitle>
						</div>
						<SettingActionsDropdown
							actions={settingActions}
							templateType={SettingsTemplateType.ScenarioStopList}
							isReadyToChange={!!isReadyToChange}
							settingName={optimizationGroup!.name}
							settings={{
								stop_list: settingsWatcher as StopListSettingsModel,
							}}
						>
							<Button
								data-testid={'pc-actions-popover-trigger'}
								variant='tertiary'
								className='w-xs ml-2'
								iconBefore={<DotsFilledIcon />}
							/>
						</SettingActionsDropdown>
					</div>
				</CardHeader>
				<CardContent>
					<ErrorBoundary>
						<RHFSettingsWidget
							name={'stop_list.default_rules'}
							schema={schema?.properties.stop_list.properties.default_rules}
						/>
					</ErrorBoundary>
				</CardContent>
				<CardHeader>
					<CardTitle as='h3'>
						{intl.get('stop_list.custom_rules.title')}
					</CardTitle>
					<CardSubtitle>
						{intl.get('stop_list.custom_rules.subtitle')}
					</CardSubtitle>
				</CardHeader>
				<CardContent>
					<ErrorBoundary>
						<RHFStoplistCustomRules
							name={'stop_list.custom_rules'}
							fields={fields}
							dataChoices={dataChoices}
						/>
					</ErrorBoundary>
				</CardContent>
			</Card>
		</div>
	)
}
