import { useQuery } from '@tanstack/react-query'

import { Operators } from '@cmpkit/query-builder'

import {
	ProductsQueryRequestModel,
	ProductsQueryResponseModel,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'

export function useAssortmentListQuery<T = ProductsQueryResponseModel>(
	params?: ProductsQueryRequestModel,
	options?: UseQueryOptions<ProductsQueryResponseModel, Error, T>
) {
	return useQuery<ProductsQueryResponseModel, Error, T>({
		queryKey: ['assortment-list', params],
		queryFn: () => {
			return client.products.listProducts(params || {})
		},
		...(options || {}),
	})
}

export function useUnassignedProductsCountQuery(
	params?: ProductsQueryRequestModel,
	options?: UseQueryOptions<
		ProductsQueryResponseModel,
		Error,
		number | undefined
	>
) {
	return useQuery<ProductsQueryResponseModel, Error, number | undefined>({
		queryKey: ['unassigned-assortment-count', params],
		queryFn: () => {
			return client.products.listProducts({
				limit: 1,
				...(params || {}),
				filters: [
					{
						name: 'pricing_campaign_id',
						operation: Operators.IS_EMPTY,
						value: 1,
					},
					...(params?.filters || []),
				],
			})
		},
		...(options || {}),
		select: (data) => data.total_count,
	})
}
