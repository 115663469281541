import React from 'react'

import { Button, Card, MenuItem, MenuList } from '@cmpkit/base'
import LayersIcon from '@cmpkit/icon/lib/glyph/layers'
import UploadIcon from '@cmpkit/icon/lib/glyph/upload'
import ZapIcon from '@cmpkit/icon/lib/glyph/zap'
import Popover from '@cmpkit/popover'

import intl from '@/locale'

type ProductsBulkUpdateDropdownProps = {
	onOpenBulkModal(): void
	onOpenImportModal(): void
}
function Content({
	onOpenBulkModal,
	onOpenImportModal,
	close,
}: ProductsBulkUpdateDropdownProps & { close(): void }) {
	const handleOpenBulkModal = () => {
		onOpenBulkModal()
		close()
	}
	const handleOpenImportModal = () => {
		onOpenImportModal()
		close()
	}
	return (
		<Card className='border py-2 shadow'>
			<MenuList className='w-56'>
				<MenuItem onClick={handleOpenBulkModal}>
					<LayersIcon />
					<span>{intl.get('import_dropdown_bulk_update')}</span>
				</MenuItem>
				<MenuItem onClick={handleOpenImportModal}>
					<UploadIcon />
					<span>{intl.get('import_dropdown_data_import')}</span>
				</MenuItem>
			</MenuList>
		</Card>
	)
}
export default function ProductsBulkUpdateDropdown({
	canEditModelInput,
	...props
}: ProductsBulkUpdateDropdownProps & { canEditModelInput: boolean }) {
	return (
		<Popover
			placement='bottom-start'
			content={({ close }) => <Content {...props} close={close} />}
		>
			<Button
				disabled={!canEditModelInput}
				size='small'
				iconBefore={<ZapIcon />}
			>
				{intl.get('general_bulk_update')}
			</Button>
		</Popover>
	)
}
