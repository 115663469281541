import React, { useMemo } from 'react'

import TimelineGridProvider from './TimelineGridContext'
import TimelineGridLeftPane from './TimelineGridLeftPane'
import TimelineGridRightPane from './TimelineGridRightPane'
import TimelineGridRoot from './TimelineGridRoot'
import { Frequency, TimelineRow } from './types'

type TimelineGridProps<T> = {
	innerRef: React.MutableRefObject<unknown>
	RowContent: <T>({ row }: { row: TimelineRow<T> }) => React.ReactNode
	PaneRowContent: <T>({ row }: { row: TimelineRow<T> }) => React.ReactNode
	frequency: Frequency
	rows: TimelineRow<T>[]
}
type ComponentsTypes = {
	PaneRowContent: <T>({ row }: { row: TimelineRow<T> }) => React.ReactNode
	RowContent: <T>({ row }: { row: TimelineRow<T> }) => React.ReactNode
}
export default function TimelineGrid<V = unknown>({
	RowContent,
	PaneRowContent,
	innerRef,
	...props
}: TimelineGridProps<V>) {
	const components = useMemo(
		() =>
			({
				PaneRowContent: PaneRowContent<V>,
				RowContent: RowContent<V>,
			}) as ComponentsTypes,
		[[PaneRowContent, RowContent]]
	)
	return (
		<TimelineGridProvider<V> {...props}>
			<TimelineGridRoot innerRef={innerRef}>
				<TimelineGridLeftPane<V> components={components} />
				<TimelineGridRightPane<V> components={components} />
			</TimelineGridRoot>
		</TimelineGridProvider>
	)
}
