import { differenceInDays, formatDistanceToNow, intlFormat } from 'date-fns'
import { find } from 'lodash/fp'
import React from 'react'
import { Link } from 'react-router-dom'

import { Badge, DeltaValue, Dot, Tag } from '@cmpkit/base'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'
import { ColumnSchemaModel } from '@/generated'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { ColumnDataType } from '../types'
import CheckboxFieldEditor from './Checkbox/TableCellEditor'
import NumberField from './Number/TableCell'
import NumberFieldEditor from './Number/TableCellEditor'
import PlainTextField from './PlainText/TableCell'
import PlainTextFieldEditor from './PlainText/TableCellEditor'

export interface Choice extends DataOption {
	variant?: string
	color?: string
	icon?: React.ReactNode
}
/* eslint-disable react/no-unused-prop-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CellProp<TValue extends any = any, TRow extends any = any> {
	/**
	 * Current row value
	 */
	value: TValue
	/**
	 * Row original initial value
	 */
	originalValue: TValue

	/**
	 * Columns schema model
	 */
	schema?: Partial<ColumnSchemaModel>

	/**
	 * Row data object
	 */
	row: TRow

	/**
	 * Choices for choice fields
	 */
	choices?: DataOption[]
	isChanged?: boolean
	isDisabled?: boolean
	onChange?(changeEvent: {
		schema: Partial<ColumnSchemaModel>
		row: TRow
		value: TValue
	}): void
}
/* eslint-enable react/no-unused-prop-types */
export interface CellPropControlled<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	TValue extends any = any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	TRow extends any = any,
> extends CellProp<TValue, TRow> {
	schema: Partial<ColumnSchemaModel>
}
export function DatetimeField(props: Partial<CellProp>) {
	return <>{props.value ? intlFormat(new Date(props.value + 'Z')) : '-'}</>
}
export function DatetimeDistanceField(props: Partial<CellProp>) {
	const value = props.value ? new Date(props.value) : null
	if (value) {
		const diff = differenceInDays(value, new Date())
		if (diff > 5) {
			return (
				<Tooltip
					content={intlFormat(value, {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
					})}
				>
					<span>{intlFormat(new Date(props.value))}</span>
				</Tooltip>
			)
			return
		}
		return (
			<Tooltip
				content={intlFormat(value, {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
				})}
			>
				<span>{formatDistanceToNow(value)}</span>
			</Tooltip>
		)
	}
	return '-'
}
export function SizeField(props: CellProp) {
	return <div className='cell-data-number'>{props.value?.length}</div>
}
export function LinkField(props: CellProp) {
	return <a href={props.value}>{props.value}</a>
}
export function InternalLinkField(props: CellProp) {
	return <Link to={props.value}>{props.value}</Link>
}
export function PercentageField(props: CellProp) {
	return (
		<div className='cell-data-number'>
			{formatNumber(props.value, NumberFormats.Percent)}
		</div>
	)
}
export function BadgeField(props: CellProp) {
	const getChoice = (value: string | boolean | number | null) =>
		find({ value }, props.choices) || null
	const items = (Array.isArray(props.value) ? props.value : [props.value])
		?.map(getChoice)
		?.filter(Boolean) as Choice[]
	return (
		<div className='flex items-center space-x-1'>
			{items.length > 0
				? items?.map(({ value, label, variant, icon }) => {
						return (
							// FIXME: Badge variant type
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							<Badge className='shrink-0' variant={variant as any} key={value}>
								{icon}
								{label}
							</Badge>
						)
					})
				: '-'}
		</div>
	)
}
export function ChipField(props: CellProp) {
	const getChoice = (value: string | boolean | number | null) =>
		find({ value }, props.choices) || null
	const items = (Array.isArray(props.value) ? props.value : [props.value])
		?.map(getChoice)
		?.filter(Boolean) as Choice[]
	return (
		<div className='flex items-center space-x-1'>
			{items.length > 0
				? items?.map(({ value, label, variant, icon }) => {
						return (
							// FIXME: Badge variant type
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							<Tag className='shrink-0' variant={variant as any} key={value}>
								{icon}
								{label}
							</Tag>
						)
					})
				: '-'}
		</div>
	)
}
export function ChoiceField(props: CellProp) {
	const items = Array.isArray(props.value) ? props.value : [props.value]
	const getChoice = (value: string | boolean | number | null) =>
		(find({ value }, props.choices) as Choice) || { value, label: value }

	return (
		<div className='flex items-center space-x-1'>
			{items?.length > 0
				? items
						?.map(getChoice)
						?.map(({ value, label }) => <span key={value}>{label}</span>)
				: '-'}
		</div>
	)
}
export function IndicatorField(props: CellProp) {
	const items = Array.isArray(props.value) ? props.value : [props.value]
	const getChoice = (value: string | boolean | number | null) =>
		(find({ value }, props.choices) as Choice) || { value, label: value }
	return (
		<div className='flex items-center space-x-1'>
			{items?.length > 0
				? items?.map(getChoice)?.map(({ value, label, color }) => {
						return (
							<span key={value} className='inline-flex items-center'>
								<Dot className='mr-2 size-1.5' color={color} />
								{label}
							</span>
						)
					})
				: '-'}
		</div>
	)
}
export function DifferenceField(props: CellProp) {
	return (
		<DeltaValue value={props.value}>
			<NumberField {...props} />
		</DeltaValue>
	)
}
export function FlagField(props: Partial<CellProp>) {
	if (props.value === undefined || props.value === null) {
		return <>-</>
	}
	return (
		<>
			{props.value ? (
				<CheckIcon className='fill-success' />
			) : (
				<CrossIcon className='fill-danger' />
			)}
		</>
	)
}
export function ImageField(props: CellProp) {
	return (
		<div className='flex items-center justify-center'>
			<div className='image-type-wrapper'>
				<img className='image-type' src={props.value} />
			</div>
		</div>
	)
}

/**
 * Get component for rendering cell value by schema type
 * @param cellType ColumnSchemaModel.type
 * @returns  React.ElementType<CellProp>
 */
export function getFieldBySchemaType(
	cellType: string
): React.ElementType<CellProp> {
	switch (cellType) {
		case ColumnDataType.Integer:
		case ColumnDataType.Float:
		case 'number':
			return NumberField
		case 'title':
		case ColumnDataType.String:
			return PlainTextField
		case 'diff':
			return DifferenceField
		case 'badge':
			return BadgeField
		case 'image':
			return ImageField
		case ColumnDataType.Datetime:
			return DatetimeField
		case 'size':
			return SizeField
		case ColumnDataType.Boolean:
			return FlagField
		default:
			return PlainTextField
	}
}

/**
 * Get component for editing cell value by schema type
 * @param cellType  ColumnSchemaModel.type
 * @returns - React.ElementType<CellProp>
 */
export function getFieldEditorBySchemaType(
	cellType: string
): React.ElementType<CellPropControlled> {
	switch (cellType) {
		case ColumnDataType.Integer:
		case ColumnDataType.Float:
		case 'number':
			return NumberFieldEditor
		case ColumnDataType.Boolean:
			return CheckboxFieldEditor
		default:
			return PlainTextFieldEditor
	}
}
