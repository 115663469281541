import { entries } from 'lodash/fp'
import toast from '@/components/toasts'

import { OptimizationModel, ProductsExportRequestModel } from '@/generated'
import intl from '@/locale'
import { client } from '@/network/client'
import { download, getFileNameFromResponse } from '@/tools/report'

import { getStatusBadgeVariant } from '../core/utils'

/**
 * Functions to download assortment file by filters and name
 * @example downloadAssortmentFile({ filters: { ... } }, 'assortment.csv')
 * @param body - ProductsRequestBody without limit and offset
 * @param name - name of file to download
 * @returns Promise<void>
 */
export const downloadAssortmentFile = (
	body: Omit<ProductsExportRequestModel, 'limit' | 'offset'>,
	name?: string
) => {
	toast.loading(
		{
			text: intl.get('report_file_loading'),
		},
		{
			id: 'export',
		}
	)

	return client.instance
		.post('/api/v1/products/export', body, {
			responseType: 'blob',
		})
		.then((response) => {
			download(
				new Blob([response.data]),
				name ?? getFileNameFromResponse(response)
			)
			toast.success(
				{
					text: 'Success',
				},
				{
					id: 'export',
				}
			)
		})
		.catch(() => {
			toast.error(
				{
					text: 'Downloading failed, please try again.',
				},
				{
					id: 'export',
				}
			)
		})
}

export const getOptimizationGroupStatus = (
	optimizations: Record<string, OptimizationModel>
) =>
	entries(optimizations).map(([value, opt]) => ({
		value,
		label: intl.get(`opt_${opt?.status}`).d(opt?.status),
		variant: getStatusBadgeVariant(opt?.status),
	}))
