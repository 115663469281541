import { kebabCase } from 'lodash/fp'
import React, { useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import {
	AccessorKeyColumnDef,
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'

import { Badge, Button } from '@cmpkit/base'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import {
	Modal,
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'
import Tooltip from '@cmpkit/tooltip'

import { DatetimeDistanceField } from '@/components/data-grid/fields'
import PlainTextField from '@/components/data-grid/fields/PlainText/TableCell'
import { dialog } from '@/components/dialogs'
import NoData from '@/components/placeholders/NoData'
import { VirtualDataTable } from '@/components/tables'
import UsernameById from '@/components/UsernameById'
import { ExportModel, ExportStatus, ExportType } from '@/generated'
import intl from '@/locale'
import { client } from '@/network/client'
import analytic from '@/services/analytics'
import { download } from '@/tools/report'
import { humanFileSize } from '@/tools/utils'

import { useDeleteExportMutation } from '../mutations'
import { useExportsQuery } from '../queries'

type ExportsModalProps = {
	isOpen: boolean
	close(): void
}
export default function ExportsModal({ isOpen, close }: ExportsModalProps) {
	const exportsQuery = useExportsQuery()
	const deleteExportMutation = useDeleteExportMutation({
		onSuccess: () => {
			exportsQuery.refetch()
		},
	})
	const handleDownload = (exportItem: ExportModel) => {
		const downloadExport = client.export
			.downloadExportFile(exportItem.id, {
				format: 'blob',
			})
			.then((res) => {
				download(
					res as unknown as Blob,
					`${kebabCase(exportItem.name)}.${exportItem.type}`
				)
			})
		toast.promise(downloadExport, {
			loading: intl
				.get('export.downloading.loading')
				.d('Start export downloading...'),
			success: intl.get('export.downloading.success').d('Export is downloaded'),
			error: intl
				.get('export.downloading.failed')
				.d('Export downloading failed, please try again.'),
		})
	}
	const handleDelete = async (exportItem: ExportModel) => {
		const answer = await dialog.confirmDelete({
			title: intl.get('general_detete_confirm_title'),
			text: intl.get('general_detete_confirm_subtitle'),
			okText: intl.get('general_delete'),
		})
		if (answer) {
			analytic.logEvent('exports: delete export')
			toast.promise(deleteExportMutation.mutateAsync(exportItem.id), {
				loading: intl.get('export.deleting.loading').d('Deleting...'),
				success: intl.get('export.deleting.success').d('Export deleted'),
				error: intl
					.get('export.deleting.failed')
					.d('Export deleting failed, please try again.'),
			})
		}
	}
	const columns = useMemo(
		() =>
			getColumns({
				onDelete: handleDelete,
				onDownload: handleDownload,
			}),
		[handleDelete, handleDownload]
	)
	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					onClose={close}
					showCloseButton
					size={'large'}
					testId='exports-list-modal-form'
				>
					<ModalHeader>
						<ModalTitle>
							{intl.get('exports.modal_title').d('Export service')}
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						{!exportsQuery.isLoading ? (
							exportsQuery.data?.length ? (
								<ExportsTable rows={exportsQuery.data} columns={columns} />
							) : (
								<NoData
									imgClassName={'w-72'}
									className='flex-col py-10'
									title={intl
										.get('exports.table.empty.title')
										.d('Looks like there are no "Exports" yet')}
									subtitle={intl
										.get('exports.table.empty.subtitle')
										.d('You will see all your exports here')}
								/>
							)
						) : (
							<SkeletonLoader />
						)}
					</ModalBody>
				</Modal>
			)}
		</ModalTransition>
	)
}

function ExportsTable({
	rows,
	columns,
}: {
	rows: ExportModel[]
	columns: AccessorKeyColumnDef<ExportModel, any>[] // eslint-disable-line @typescript-eslint/no-explicit-any
}) {
	const [sorting, setSorting] = useState<SortingState>([
		{
			desc: true,
			id: 'created_at',
		},
	])

	const table = useReactTable<ExportModel>({
		data: rows,
		columns,
		state: {
			sorting,
		},
		defaultColumn: {
			minSize: 55,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return (
		<div className='flex h-96 flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1 text-xs'>
			<VirtualDataTable table={table} getRowClassName={() => 'h-9'} />
		</div>
	)
}
const columnHelper = createColumnHelper<ExportModel>()
const getColumns = ({
	onDelete,
	onDownload,
}: {
	onDelete(item: ExportModel): void
	onDownload(item: ExportModel): void
}) => [
	columnHelper.accessor('name', {
		header: () => intl.get('app.name').d('Name'),
		cell: (info) => {
			return (
				<Tooltip content={info.getValue()}>
					<div className='cell-data-text truncate'>{info.getValue()}</div>
				</Tooltip>
			)
		},
	}),
	columnHelper.accessor('type', {
		size: 88,
		header: () => intl.get('app.type').d('Type'),
		cell: (info) => {
			const value = info.getValue()
			return (
				<PlainTextField value={intl.get(`export_type.${value}`).d(value)} />
			)
		},
	}),
	columnHelper.accessor('status', {
		size: 100,
		header: () => intl.get('app.status').d('Status'),
		cell: (info) => {
			return getExportStatusBadge(info.getValue())
		},
	}),
	columnHelper.accessor('file_size', {
		size: 78,
		header: () => intl.get('app.size').d('Size'),
		cell: (info) => {
			const value = info.getValue()
			return <PlainTextField value={value ? humanFileSize(value) : '-'} />
		},
	}),
	columnHelper.accessor('created_at', {
		size: 100,
		header: () => intl.get('app.start_date').d('Start date'),
		cell: (info) => {
			return <DatetimeDistanceField value={info.getValue() + 'Z'} />
		},
	}),
	columnHelper.accessor('finished_at', {
		size: 100,
		header: () => intl.get('app.finish_date').d('Finish date'),
		cell: (info) => {
			const value = info.getValue()
			return !!value ? (
				<DatetimeDistanceField value={info.getValue() + 'Z'} />
			) : (
				'-'
			)
		},
	}),
	columnHelper.accessor('created_by', {
		size: 200,
		header: () => intl.get('app.created_by').d('Created by'),
		cell: (info) => {
			return (
				<div className='flex items-center justify-between'>
					<UsernameById userId={info.getValue()} />
					<div className='flex items-center space-x-1'>
						{info.row.original.type !== ExportType.Bigquery &&
							info.row.original.status === ExportStatus.Finished && (
								<Button
									size='small'
									iconBefore={<DownloadIcon />}
									variant='tertiary'
									onClick={() => onDownload(info.row.original)}
								/>
							)}
						<Button
							size='small'
							iconBefore={<Trash2Icon />}
							variant='tertiary'
							onClick={() => onDelete(info.row.original)}
						/>
					</div>
				</div>
			)
		},
	}),
]
function getExportStatusBadge(status: ExportStatus) {
	switch (status) {
		case ExportStatus.Finished:
			return (
				<Badge size='small' variant='success'>
					{intl.get('app.ready').d('Ready')}
				</Badge>
			)
		case ExportStatus.Started:
			return (
				<Badge size='small' variant='warning'>
					{intl.get('app.in_progress').d('In progress')}
				</Badge>
			)
		case ExportStatus.Failed:
			return (
				<Badge size='small' variant='danger'>
					{intl.get('app.failed').d('Failed')}
				</Badge>
			)
	}
}
function SkeletonLoader() {
	return (
		<div className='flex h-96 flex-1 flex-col overflow-hidden rounded-lg bg-accent-1'>
			<div className='flex animate-pulse flex-col space-y-4'>
				<div className='flex items-center'>
					<div className='w-4/12'>
						<div className='h-5 w-44 rounded-lg bg-accent-3' />
					</div>
					<div className='w-2/12'>
						<div className='h-5 w-16 rounded-lg bg-accent-3' />
					</div>
					<div className='w-2/12'>
						<div className='h-5 w-24 rounded-lg bg-accent-3' />
					</div>
					<div className='w-4/12'>
						<div className='ml-auto h-5 w-32 rounded-lg bg-accent-3' />
					</div>
				</div>
				<div className='flex items-center opacity-85'>
					<div className='w-4/12'>
						<div className='h-5 w-44 rounded-lg bg-accent-3' />
					</div>
					<div className='w-2/12'>
						<div className='h-5 w-16 rounded-lg bg-accent-3' />
					</div>
					<div className='w-2/12'>
						<div className='h-5 w-24 rounded-lg bg-accent-3' />
					</div>
					<div className='w-4/12'>
						<div className='ml-auto h-5 w-32 rounded-lg bg-accent-3' />
					</div>
				</div>
				<div className='flex items-center opacity-60'>
					<div className='w-4/12'>
						<div className='h-5 w-44 rounded-lg bg-accent-3' />
					</div>
					<div className='w-2/12'>
						<div className='h-5 w-16 rounded-lg bg-accent-3' />
					</div>
					<div className='w-2/12'>
						<div className='h-5 w-24 rounded-lg bg-accent-3' />
					</div>
					<div className='w-4/12'>
						<div className='ml-auto h-5 w-32 rounded-lg bg-accent-3' />
					</div>
				</div>
				<div className='flex items-center opacity-40'>
					<div className='w-4/12'>
						<div className='h-5 w-44 rounded-lg bg-accent-3' />
					</div>
					<div className='w-2/12'>
						<div className='h-5 w-16 rounded-lg bg-accent-3' />
					</div>
					<div className='w-2/12'>
						<div className='h-5 w-24 rounded-lg bg-accent-3' />
					</div>
					<div className='w-4/12'>
						<div className='ml-auto h-5 w-32 rounded-lg bg-accent-3' />
					</div>
				</div>
			</div>
		</div>
	)
}
