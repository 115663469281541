import { any, isNil } from 'lodash/fp'
import React from 'react'

import { Col, Dot, Row } from '@cmpkit/base'

import { useSchemaFieldNames } from '@/hooks/data'
import intl from '@/locale'

import Container from '../Container'
import ChartView from './ChartView'
import { LimitationRangeWidgetProps, SchemaFieldNames } from './types'

const colors = [
	'rgba(255, 152, 0, 0.5)',
	'rgba(0, 181, 87, 0.5)',
	'rgba(63, 81, 181, 0.5)',
	'rgba(244, 66, 54, 0.5)',
	'rgba(50, 178, 243, 0.5)',
	'#142A45',
]

export function LimitationRange(props: LimitationRangeWidgetProps) {
	const schemaFieldNames = useSchemaFieldNames() as SchemaFieldNames
	const data = props.series
		.filter(({ data }) => any((d) => d != null, data))
		.map((serie, i) => ({
			...serie,
			color: colors[i],
		}))

	return (
		<Container>
			<Row>
				<Col sm='12'>
					<ChartView
						{...props}
						schemaFieldNames={schemaFieldNames}
						data={data}
					/>
				</Col>
				<Col sm='6'>
					<strong>{intl.get('general_limitations_by')}</strong>
				</Col>
				<Col sm='6' className='text-right'>
					<strong>{intl.get('general_possible_value_range')}</strong>
				</Col>
				<Col sm='12'>
					{data.map((serie) => (
						<div key={serie.name} className='mt-1 flex justify-between'>
							<div>
								<Dot className='mr-2 inline-block' color={serie.color} />{' '}
								{intl
									.get(schemaFieldNames?.[serie.name] || serie.name)
									.d(schemaFieldNames?.[serie.name] || serie.name)}
							</div>
							<div>
								{!isNil(serie.data[0]) && (
									<>
										{intl.get('from')} <strong>{serie.data[0]}</strong>
									</>
								)}{' '}
								{!isNil(serie.data[1]) && (
									<>
										{intl.get('to')} <strong>{serie.data[1]}</strong>
									</>
								)}
							</div>
						</div>
					))}
				</Col>
			</Row>
		</Container>
	)
}

export default LimitationRange
