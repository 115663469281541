import React from 'react'
import { flushSync } from 'react-dom'

import { Column, Row } from '@tanstack/react-table'

import { getViewport } from './helpers'
import { memo } from './memo'
import { Viewport } from './types'
import { getCollapsedGrids } from './utils'

export interface Rect {
	width: number
	height: number
}
const useIsomorphicLayoutEffect =
	typeof document !== 'undefined' ? React.useLayoutEffect : React.useEffect
type ElementDimm = {
	scrollTop: number
	scrollLeft: number
	width: number
	height: number
}

class Virt<T> {
	isScrolling: boolean = false
	targetWindow: (Window & typeof globalThis) | null = null
	scrollElement: HTMLElement | null = null
	scrollRect: Rect = { width: 0, height: 0 }
	dimm: ElementDimm = {
		scrollTop: 0,
		scrollLeft: 0,
		width: 0,
		height: 0,
	}
	viewport: Viewport = {
		columns: [[0, 0]],
		rows: [0, 0],
	}
	options: VirtOptions<T> = {
		getScrollElement: () => document.body,
		columns: [],
		rows: [],
		getRowHeight: () => 0,
		getColSize: (column: Column<T>) => column?.getSize(),
	}
	constructor(options: VirtOptions<T>) {
		this.options = {
			...this.options,
			...options,
		}
	}
	private notify = (sync: boolean) => {
		this.options.onChange?.(this, sync)
	}
	private maybeNotify = memo(
		() => {
			return [this.isScrolling, this.getViewport()]
		},
		(isScrolling) => {
			this.notify(isScrolling)
		},
		{
			key: process.env.NODE_ENV !== 'production' && 'maybeNotify',
		}
	)
	private cleanup = () => {
		this.scrollElement = null
		this.targetWindow = null
	}
	setOptions = (opts: VirtOptions<T>) => {
		Object.entries(opts).forEach(([key, value]) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if (typeof value === 'undefined') delete (opts as any)[key]
		})
		this.options = {
			...this.options,
			...opts,
		}
	}
	getDimmensions = () => this.dimm
	getViewport = memo(
		() => [this.options.rows, this.options.columns, this.getDimmensions()],
		(bodyRows, columns, dimm) => {
			const newWieport = getViewport<T>(
				{
					top: dimm.scrollTop,
					left: dimm.scrollLeft,
					height: dimm.height,
					width: dimm.width,
					skipItems: [0, 0],
				},
				{
					columns,
					bodyRows,
					getRowSize: this.options.getRowHeight,
					getColSize: this.options.getColSize,
				}
			)
			this.viewport = newWieport

			return this.viewport
		},
		{ key: 'getViewport' }
	)

	didMount = () => {
		return () => {
			this.cleanup()
		}
	}

	willUpdate = () => {
		const scrollElement = this.options.enabled
			? this.options.getScrollElement()
			: null

		if (this.scrollElement !== scrollElement) {
			this.cleanup()

			if (!scrollElement) {
				this.maybeNotify()
				return
			}

			this.scrollElement = scrollElement

			if (this.scrollElement && 'ownerDocument' in this.scrollElement) {
				this.targetWindow = this.scrollElement.ownerDocument.defaultView
			} else {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.targetWindow = (this.scrollElement as any)?.window ?? null
			}
			/* observeElementRect(this, (rect) => {
	 

				this.dimm = { ...this.dimm, ...rect }
				this.maybeNotify()
			}) */
			observeElementOffset(this, (dimm, isScrolling) => {
				this.dimm = { ...dimm }
				this.isScrolling = isScrolling
				this.maybeNotify()
			})
		}
	}
	getGrid = memo(
		() => [
			this.getViewport(),
			this.options.rows,
			this.options.columns,
			this.getDimmensions(),
			this.options.getRowHeight,
		],
		(viewport, bodyRows, columns, dimm, getRowHeight) => {
			const headerRows: Row<T>[] = []
			const footerRows: Row<T>[] = []
			const getColumnWidth = (column: Column<T>) => column?.getSize()
			const getCellColSpan = () => 1

			const skipItems: [number, number] = [0, 0]

			return getCollapsedGrids<T>({
				headerRows,
				bodyRows,
				footerRows,
				columns,
				skipItems,
				loadedRowsStart: 0,
				totalRowCount: bodyRows.length,
				viewport,
				getCellColSpan,
				getRowHeight,
				getColumnWidth,
			})
		},
		{
			key: 'getGrid',
		}
	)
}
type VirtOptions<T> = {
	enabled?: boolean
	getScrollElement: () => HTMLElement
	columns: Column<T, unknown>[]
	rows: Row<T>[]
	getRowHeight: (row?: Row<T>) => number
	getColSize:
		| ((column: Column<T>) => number)
		| ((column?: Column<T>) => undefined)
	onChange?(instance: Virt<T>, sync: boolean): void
}
export function useVirt<T>(options: VirtOptions<T>) {
	const rerender = React.useReducer(() => ({}), {})[1]
	const resolvedOptions = {
		...options,
		onChange: (instance: Virt<T>, sync: boolean) => {
			if (sync) {
				flushSync(rerender)
			} else {
				rerender()
			}
			options.onChange?.(instance, sync)
		},
	}
	const [instance] = React.useState(() => new Virt<T>(resolvedOptions))
	instance.setOptions(resolvedOptions)
	React.useEffect(() => {
		if (!options.enabled) return
		return instance.didMount()
	}, [])

	useIsomorphicLayoutEffect(() => {
		if (!options.enabled) return
		return instance.willUpdate()
	})

	return instance
}

export const observeElementOffset = <T>(
	instance: Virt<T>,
	cb: (dimm: ElementDimm, isScrolling: boolean) => void
) => {
	const element = instance.scrollElement
	if (!element) {
		return
	}

	const createHandler = (isScrolling: boolean) => () => {
		cb(
			{
				scrollTop: element.scrollTop,
				scrollLeft: element.scrollLeft,
				width: element.clientWidth,
				height: element.clientHeight,
			},
			isScrolling
		)
	}
	const handler = createHandler(true)
	const endHandler = createHandler(false)
	endHandler()

	element.addEventListener('scroll', handler, { passive: true })
	element.addEventListener('scrollend', endHandler, { passive: true })

	return () => {
		element.removeEventListener('scroll', handler)
		element.removeEventListener('scrollend', endHandler)
	}
}

export const observeElementRect = <T>(
	instance: Virt<T>,
	cb: (rect: Rect) => void
) => {
	const element = instance.scrollElement
	if (!element) {
		return
	}
	const targetWindow = instance.targetWindow
	if (!targetWindow) {
		return
	}

	const handler = (rect: Rect) => {
		const { width, height } = rect
		cb({ width: Math.round(width), height: Math.round(height) })
	}

	handler(element.getBoundingClientRect())

	if (!targetWindow.ResizeObserver) {
		return () => {}
	}

	const observer = new targetWindow.ResizeObserver((entries) => {
		const entry = entries[0]
		if (entry?.borderBoxSize) {
			const box = entry.borderBoxSize[0]
			if (box) {
				handler({ width: box.inlineSize, height: box.blockSize })
				return
			}
		}
		handler(element.getBoundingClientRect())
	})

	observer.observe(element, { box: 'border-box' })

	return () => {
		observer.unobserve(element)
	}
}
