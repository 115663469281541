import React from 'react'

import Tooltip from '@cmpkit/tooltip'

import { MHeader, TableInstance } from './types'
import { flexRender } from './utils/tanstack.helpers'

export default function ColumnLabel<T>({
	header,
	table,
}: {
	header: MHeader<T>
	table: TableInstance<T>
}) {
	const { column } = header
	const {
		options: { enableHeaderTooltip },
	} = table

	const showTooltip =
		(enableHeaderTooltip || column.columnDef.enableHeaderTooltip) &&
		column.columnDef.enableHeaderTooltip !== false

	if (showTooltip) {
		const hidden =
			column.columnDef.enableHeaderTooltip === false &&
			column.columnDef.enableDescriptionTooltip === false
		return (
			<Tooltip
				hidden={hidden}
				content={
					<div>
						{column.columnDef.enableHeaderTooltip !== false && (
							<div>
								{flexRender(
									header.column.columnDef.header,
									header.getContext()
								)}
							</div>
						)}
						{column.columnDef.enableDescriptionTooltip !== false &&
							flexRender(column.columnDef.description, header.getContext())}
					</div>
				}
			>
				<span className='truncate'>
					{flexRender(header.column.columnDef.header, header.getContext())}
				</span>
			</Tooltip>
		)
	}

	return (
		<span className='truncate'>
			{flexRender(header.column.columnDef.header, header.getContext())}
		</span>
	)
}
