import { AxiosError } from 'axios'
import clsx from 'clsx'
import {
	CircleDollarSignIcon,
	ListCollapseIcon,
	Settings2Icon,
	TrendingUpDownIcon,
} from 'lucide-react'
import React from 'react'
import { Link, NavLink, Outlet } from 'react-router'

import { useQueryClient } from '@tanstack/react-query'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
	buttonVariants,
	Header,
	Layout,
	Result,
	Separator,
} from '@cmpkit/base'
import Tooltip from '@cmpkit/tooltip'

import ErrorBoundary from '@/components/ErrorBoundary'
import FatalError from '@/components/FatalError'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { OptimizationGroupSwitcher } from '@/modules/core'

import { PageNavigatorHost, PageNavigatorProvider } from '../PageNavigator'

function OptimizationGroupError({
	optimizationGroupId,
}: {
	optimizationGroupId: string
}) {
	const client = useQueryClient()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const state = client.getQueryState<any, AxiosError<any>>([
		'optimization',
		optimizationGroupId,
	])

	if (
		!!state?.error?.response?.status &&
		state?.error?.response?.status >= 400
	) {
		return (
			<div className='flex h-screen flex-col items-center justify-center'>
				<Result
					title={intl
						.get('error.og.not_found.title')
						.d('Can`t get optimization group')}
					subtitle={intl
						.get('error.og.not_found.subtitle')
						.d('It could heppens becasue of permissions or network issues')}
				/>
				<div className='mt-5'>
					<ul className='mt-3 list-inside list-disc space-y-2 text-xs'>
						<li>
							{intl
								.get('error.og.not_found.sol_1')
								.d('Check your permissions.')}
						</li>
						<li>
							{intl
								.get('error.og.not_found.sol_2')
								.d('Ensure that this optimzation group exists.')}
						</li>
						<li>
							{intl
								.get('error.og.not_found.sol_3')
								.d('Please contact your administrator.')}
						</li>
					</ul>
				</div>
			</div>
		)
	} else {
		return <FatalError />
	}
}
export default function OptimizationGroupHost() {
	const optimizationGroupId = useOptimizationGroupId()
	return (
		<PageNavigatorProvider>
			<ErrorBoundary
				fallback={() => (
					<OptimizationGroupError optimizationGroupId={optimizationGroupId!} />
				)}
			>
				<Layout>
					<Header className='h-11 items-center border-b px-4 py-2'>
						<CircleDollarSignIcon className='size-4' />
						<Separator orientation='vertical' className='mx-2 h-5' />
						<Breadcrumb className='text-xs'>
							<BreadcrumbList>
								<BreadcrumbItem>
									<BreadcrumbLink asChild>
										<Link
											to={'/p/og'}
											data-test-id='breadcrumb-link'
											className='max-w-32 truncate'
										>
											{intl.get('menu.og').d('Optimization groups')}
										</Link>
									</BreadcrumbLink>
								</BreadcrumbItem>
								<BreadcrumbSeparator />
								<BreadcrumbItem>
									<BreadcrumbPage>
										<OptimizationGroupSwitcher />
									</BreadcrumbPage>
								</BreadcrumbItem>
							</BreadcrumbList>
						</Breadcrumb>
						<Separator orientation='vertical' className='mx-2 h-5' />
						<div className='flex items-center gap-1'>
							<Tooltip content={intl.get('products').d('Products')}>
								<NavLink
									to={'products'}
									className={clsx(
										buttonVariants({ variant: 'tertiary', icon: true }),
										'text-foreground'
									)}
								>
									<ListCollapseIcon className='size-4' />
								</NavLink>
							</Tooltip>
							<Tooltip content={intl.get('settings').d('Settings')}>
								<NavLink
									to={'settings'}
									className={clsx(
										buttonVariants({ variant: 'tertiary', icon: true }),
										'text-foreground'
									)}
								>
									<Settings2Icon className='size-4' />
								</NavLink>
							</Tooltip>

							<Tooltip content={intl.get('whatif').d('What if')}>
								<NavLink
									to={'scenarios'}
									className={clsx(
										buttonVariants({ variant: 'tertiary', icon: true }),
										'text-foreground'
									)}
								>
									<TrendingUpDownIcon className='size-4' />
								</NavLink>
							</Tooltip>
						</div>
						<div className='ml-auto'>
							<PageNavigatorHost />
						</div>
					</Header>
					<Outlet />
				</Layout>
			</ErrorBoundary>
		</PageNavigatorProvider>
	)
}
