import clsx from 'clsx'
import { merge } from 'lodash/fp'
import React, { cloneElement } from 'react'

import { Placement } from '@floating-ui/react-dom'

import { Button, Card, TreeNodeType } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import Popover from '@cmpkit/popover'
import QueryBuilder, {
	FilterRuleEntity,
	getFiledConfig,
	useFilters,
} from '@cmpkit/query-builder'
import { FieldConfigType } from '@cmpkit/query-builder/dist/types'

import { DataOption } from '@/common.types'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'

import { proxyRuleChange } from './adapter'
import { localization } from './localization'
import ReecentlyFilter from './RecentlyFilter'
import { FilterField, FilterRuleModel } from './types'
import { isValidFilterRules, trimFilterRuleValues } from './utils'

type FiltersCommonProps = {
	enableSaveFilters?: boolean
	enableRecentFilters?: boolean
	filters?: FilterRuleModel[]
	dataChoices?: Record<string, DataOption[] | TreeNodeType[]>
	fields: FilterField[]
	onApply(rules: FilterRuleEntity[]): void
	extendedFieldConfig?: FieldConfigType
	irremovableCount?: number
}
type FiltersPopoverContentProps = FiltersCommonProps & {
	handleClose(): void
}
type FiltersPopoverProps = FiltersCommonProps & {
	children: React.ReactElement
	placement?: Placement
	inline?: boolean
}

function FiltersPopoverContent({
	filters,
	dataChoices = {},
	fields = [],
	onApply,
	handleClose,
	enableSaveFilters,
	enableRecentFilters,
	extendedFieldConfig,
	irremovableCount,
}: FiltersPopoverContentProps) {
	const { showModal } = useModalStore()
	const rulesController = useFilters({ filters })
	const handleCreateGlobalFilter = () => {
		showModal('SAVE_FILTERS', { query: rulesController.rules })
	}
	const handelApplyClick = () => {
		onApply(trimFilterRuleValues(rulesController.rules))
		handleClose()
	}
	const handelClearClick = () => {
		onApply([])
		handleClose()
	}
	const handleAddRule = () =>
		rulesController.addRule({
			name: '',
			operation: '',
			value: '',
		})

	return (
		<Card className='cmp-w-600 rounded-lg border shadow'>
			<div className='p-5'>
				{enableRecentFilters && (
					<div className='px-1'>
						<ReecentlyFilter />
					</div>
				)}
				<p className='mb-1 text-xs text-muted'>
					{intl.get('app.filters').d('Filters')}
				</p>
				<QueryBuilder
					irremovableCount={irremovableCount}
					rules={rulesController.rules}
					fieldConfig={merge(
						getFiledConfig(fields, dataChoices),
						extendedFieldConfig
					)}
					locale={localization}
					onChange={proxyRuleChange(rulesController.changeRule)}
					onDelete={rulesController.removeRule}
					onAdd={handleAddRule}
				/>
				{rulesController.rules?.length === 0 && (
					<Button
						iconBefore={<PlusIcon />}
						data-testid='filters-popover-add'
						onClick={handleAddRule}
						size='small'
						variant='tertiary'
					>
						{intl.get('filters_add_filter')}
					</Button>
				)}
			</div>
			<div
				className={clsx(
					'flex items-center space-x-2 rounded-b-lg bg-accent-3 px-5 py-3',
					{
						'justify-between': enableSaveFilters,
						'justify-end': !enableSaveFilters,
					}
				)}
			>
				<Button onClick={handelClearClick} data-testid='filters-popover-clear'>
					{intl.get('general_clear')}
				</Button>
				<div className='flex items-center space-x-2'>
					{enableSaveFilters && (
						<Button
							onClick={handleCreateGlobalFilter}
							data-testid='filters-popover-save'
						>
							{intl.get('general_save_filters')}
						</Button>
					)}
					<Button
						variant='primary-brand'
						onClick={handelApplyClick}
						data-testid='filters-popover-apply'
						disabled={!isValidFilterRules(rulesController.rules)}
					>
						{intl.get('general_apply')}
					</Button>
				</div>
			</div>
		</Card>
	)
}
export default function FiltersPopover({
	inline,
	children,
	placement = 'bottom-start',
	...props
}: FiltersPopoverProps) {
	const { isOpen, close: handleClose, toggle: handleToggle } = useDisclosure()
	const triggerBtn: React.ReactElement = cloneElement(children, {
		onClick: handleToggle,
	})
	return (
		<Popover
			placement={placement}
			onDismiss={handleClose}
			isOpen={isOpen}
			content={() => (
				<FiltersPopoverContent {...props} handleClose={handleClose} />
			)}
			inline={inline}
		>
			{triggerBtn}
		</Popover>
	)
}
