import React from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import { FormError, FormGroup, FormLabel, Label, Radio } from '@cmpkit/base'

import { DataOption } from '@/common.types'

export default function RHFRadioGroup({
	name,
	label,
	rules,
	shouldUnregister,
	defaultValue,
	options,
	disabled,
}: UseControllerProps & {
	label: string
	disabled?: boolean
	options: DataOption[]
}) {
	const { control } = useFormContext()
	return (
		<FormGroup>
			<FormLabel>{label}</FormLabel>
			<Controller
				name={name}
				rules={rules}
				shouldUnregister={shouldUnregister}
				defaultValue={defaultValue}
				control={control}
				render={({ field, fieldState: { error } }) => (
					<>
						{options.map((option, i) => {
							const checked = option.value === field.value
							return (
								<div className='mt-2 flex items-center space-x-2' key={i}>
									<Radio
										id={`radio-${name}-${option.value}`}
										value={option.value}
										checked={checked}
										disabled={disabled}
										title={option.label}
										name={name}
										onChange={() => field.onChange(option.value)}
									/>
									<Label htmlFor={`radio-${name}-${option.value}`}>
										{option.label}
									</Label>
								</div>
							)
						})}
						{error && <FormError>{error.message}</FormError>}
					</>
				)}
			/>
		</FormGroup>
	)
}
