import clsx from 'clsx'
import React from 'react'

import { Badge } from '@cmpkit/base'

import { AlertLevel } from '@/generated'
import intl from '@/locale'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'

import ListGroup, {
	ListGroupContent,
	ListGroupEnd,
	ListGroupInner,
	ListGroupItem,
} from '../ListGroup'

interface AnchoringAlertsGroupProps {
	analyticPerfix: string
	count: number
	kind: AlertLevel
	alerts: { name: string; anchors: number; id: string }[]
	onFilterClick(ids: string[]): void
}
export default function AnchoringAlertsGroup({
	analyticPerfix,
	kind,
	count,
	alerts,
	onFilterClick,
}: AnchoringAlertsGroupProps) {
	const handleShowButtonClick = (id: string) => {
		analytic.logEvent(`${analyticPerfix} hit show notification`, {
			kind,
		})
		onFilterClick([id])
	}

	return (
		<ListGroup className='fade-in list-group-rounded relative overflow-hidden rounded-lg shadow'>
			<ListGroupItem className={clsx('relative bg-accent-3 px-3 py-2')}>
				<ListGroupInner>
					<ListGroupContent className='text-xs font-medium'>
						{intl.get('app.total_amount').d('Total amount')}
					</ListGroupContent>
					<ListGroupEnd>
						<Badge variant={'danger'}>{`${formatNumber(count)}`}</Badge>
					</ListGroupEnd>
				</ListGroupInner>
			</ListGroupItem>
			{alerts?.map(({ name, anchors, id }) => {
				return (
					<ListGroupItem key={id} className='relative px-3 py-2'>
						<ListGroupInner>
							<ListGroupContent className='text-xs'>
								<span>{intl.get(name).d(name)}</span>
							</ListGroupContent>
							<ListGroupEnd>
								<Badge
									data-testid={`badge_${kind}_${id}`}
									variant={'danger'}
									className='cursor-pointer hover:opacity-70'
									onClick={handleShowButtonClick.bind(null, id)}
								>
									{`${formatNumber(anchors)}`}
								</Badge>
							</ListGroupEnd>
						</ListGroupInner>
					</ListGroupItem>
				)
			})}
		</ListGroup>
	)
}
