import {
	ControllerConfig,
	HasValueSignature,
	InitialValueSignature,
	OperationOption,
	ValidateSignature,
} from '../types'

export default class FieldController {
	constructor(config: ControllerConfig) {
		this.config = config
		this.key = config.key
		this.label = config.label
		this.type = config.type
		this.validate = config.validate || this.defaultValidate
		this.getInitialValue = config.getInitialValue || this.getInitialValue

		if (!this.label) {
			throw Error(`"${this.key}" requires a label.`)
		}
	}

	config: object
	key: string
	note?: string
	label: string
	placeholder?: string
	validate: ValidateSignature
	type: {
		controller: object
		name: string
		view: object
	}

	hasValue?: HasValueSignature = () => {
		throw Error(
			`Missing \`hasValue\` method in the "${this.type.name}" controller.`
		)
	}

	getInitialValue: InitialValueSignature = () => {
		throw Error(
			`Missing \`getInitialValue\` method in the "${this.type.name}" controller.`
		)
	}
	getFilterTypes = (): OperationOption[] => []
	defaultValidate: ValidateSignature = () => null
}
