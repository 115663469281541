import { filter, find, isNil, pipe, prop, size, uniqBy } from 'lodash/fp'

import { ValueType } from '@cmpkit/query-builder'

import { ColumnSchemaModel } from '@/generated'
import intl from '@/locale'
import { StoredColumnConfig } from '@/tools/columns'
import { formatNumber } from '@/tools/locale'

import { ColumnConfig } from './types'

export const getEnabledColumnsCount = pipe([filter({ enabled: true }), size])

/**
 * Convert schema to config object
 * @param schemas column schema
 * @returns column config object
 */
export function schemaToConfig(schemas: ColumnSchemaModel[]): ColumnConfig[] {
	return schemas.map((schema) => {
		const name = schema.translate_key
			? intl.get(schema.translate_key).d(schema.visible_name)
			: schema.visible_name
		return {
			key: schema.name,
			name: name,
			custom_name: name,
			static_value: null,
			type: schema.type,
			enabled: !schema.hidden,
		}
	})
}

/**
 * Create columns configuration used initial and predefined configs
 * *they can have different length
 * @param initialConfig initial column config
 * @param storedConfig stored column config
 * @returns merged column config
 */
export function mergeConfigs(
	initialConfig: ColumnConfig[],
	storedConfig: ColumnConfig[]
) {
	if (initialConfig.length === storedConfig.length) {
		return storedConfig
	} else {
		return uniqBy('key', [
			...storedConfig,
			...initialConfig.map((field) => ({ ...field, enabled: false })),
		])
	}
}

/**
 * Convert stored config to config object
 * @param columnsConfig stored column config
 * @param schemas column schema for getting column name
 * @returns config object
 */
export function storedToConfig(
	columnsConfig: StoredColumnConfig[],
	schemas: ColumnSchemaModel[]
): ColumnConfig[] {
	return columnsConfig.map((column) => {
		const schema = find({ name: column.key }, schemas)
		const name = schema?.translate_key
			? intl.get(schema.translate_key).d(schema.visible_name)
			: schema?.visible_name
		return {
			key: column.key,
			type: schema?.type || ValueType.str,
			name: name,
			custom_name: name,
			static_value: null,
			enabled: column.enabled,
		} as ColumnConfig
	})
}

/**
 * Associate schema type with string
 * @param type data type from schema
 * @returns string representation of the type
 */
export const getSchemaTypeString = (type: string) => {
	switch (type) {
		case ValueType.str:
			return 'String'
		case ValueType.float:
		case ValueType.number:
		case ValueType.int:
			return 'Number'
		case ValueType.boolean:
			return 'Boolean'
		case ValueType.date:
		case ValueType.datetime:
			return 'DatetimeRange'
		case ValueType.array:
			return 'MultiSelect'
		default:
			return 'NONE'
	}
}

export const createExcelFile = async ({
	name,
	fields,
	rows,
	format,
}: {
	name: string
	fields: ColumnConfig[]
	rows: Record<string, unknown>[]
	format: 'xlsx' | 'csv'
}) => {
	const Excel = await import('exceljs')
	const workbook = new Excel.Workbook()

	workbook.creator = 'Competera'
	workbook.lastModifiedBy = 'Competera'
	workbook.created = new Date()
	workbook.modified = new Date()
	workbook.lastPrinted = new Date()
	const worksheet = workbook.addWorksheet('Sheet1', {
		properties: { defaultColWidth: 15 },
	})
	worksheet.addTable({
		name,
		ref: 'A1',
		headerRow: true,
		style: {
			theme: 'TableStyleMedium1',
		},
		columns: fields.map(({ key, custom_name }) => ({
			name: custom_name,
			key,
		})),
		rows: rows.map((row) =>
			fields.map(({ key, type }) => {
				const value = prop(key, row)
				if (
					[ValueType.number, ValueType.int, ValueType.float].includes(
						type as ValueType
					)
				) {
					return isNil(value) ? '' : formatNumber(value as number)
				}
				return value as string
			})
		),
	})
	worksheet.getColumn(1).width = 20
	const buffer = await workbook[format].writeBuffer()
	return new Blob([buffer], {
		type: 'application/octet-binary;charset=utf-8',
	})
}
