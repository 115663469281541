import { all, find, orderBy, prop, propOr } from 'lodash/fp'

/**
 * Filter list of items by search text
 * @example searchData([{name: 'John'}, {name: 'Jane'}], 'John', 'name') // [{name: 'John'}]
 * @param list - list of items to searchs
 * @param searchText - text to search
 * @param propPath - path to property to search
 * @returns  - filtered list
 */
export const searchData = <T = object>(
	list: T[],
	searchText: string,
	propPath: string
) =>
	list.filter((item) =>
		prop(propPath, item)?.toLowerCase?.().includes(searchText.toLowerCase())
	)

/**
 * Sort items by sortable object
 * @example orderData([{name: 'John'}, {name: 'Jane'}], {type: 'name', order: 'asc'}) // [{name: 'Jane'}, {name: 'John'}]
 * @param list - list of items to sort
 * @param sortable  - sortable object
 * @returns - sorted list
 */
export const orderData = <T = object>(
	list: T[],
	sortable: {
		type: string
		order: 'asc' | 'desc'
	}
) => orderBy([sortable.type], [sortable.order], list)

/**
 * Filter list of items by filters rules
 * @example filterData([{name: 'John'}, {name: 'Jane'}], [{columnId: 'name', matcher: (value) => value === 'John'}]) // [{name: 'John'}]
 * @param list - list of items to filter
 * @param filters - list of filters
 * @returns - filtered list
 */

export const filterData = <T = object>(list: T[], filters: Filter[]) =>
	list.filter((item) =>
		all((filter) => filter.matcher(prop(filter.columnId, item)), filters)
	)

export const getFilterValue = (
	filters: Filter[],
	column: string,
	def: unknown
) =>
	propOr(
		def,
		'value',
		find((f) => f.columnId === column, filters)
	)
export default class Filter {
	columnId: string
	matcher: (i?: unknown) => boolean
	value: unknown
	constructor(
		columnId: string,
		matcher: (i?: unknown) => boolean,
		value: unknown
	) {
		if (columnId == null) throw new Error('Column id is required')

		if (matcher == null) throw new Error('Filter matcher is required')

		this.columnId = columnId
		this.matcher = matcher
		this.value = value
	}
}
