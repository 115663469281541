import { useColumnsSchemaQuery } from '../core/queries'
import { ColumnSchemaModel } from '../core/types'
import staticSchema from './schema.json'

const insertAtIndex = (
	arr: ColumnSchemaModel[],
	index: number,
	item: ColumnSchemaModel[]
): ColumnSchemaModel[] => {
	return [
		...arr.slice(0, index),
		...(Array.isArray(item) ? item : [item]),
		...arr.slice(index),
	]
}
export function useColumnsSchema(): ColumnSchemaModel[] {
	const { data: schema } = useColumnsSchemaQuery({
		select: (data) => {
			if (!!data.length) {
				return insertAtIndex(
					data.map((item) => ({ ...item, editable: false })),
					2,
					staticSchema as unknown as ColumnSchemaModel[]
				)
			}
		},
		placeholderData: [],
	})
	return schema as ColumnSchemaModel[]
}
