import React from 'react'

import { Badge } from '@cmpkit/base'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'
import intl from '@/locale'

import { CellProp } from '../fields'

const TooltipContent = ({
	alerts,
	choices,
}: {
	alerts: string[]
	choices?: DataOption[]
}) => {
	return (
		<>
			{alerts.map((alertId: string) => {
				const annotation = choices?.find(({ value }) => value === alertId)
				const alertName =
					annotation?.label ||
					intl.get(alertId) ||
					intl.get(`anchor_alerts.${alertId}.name`) ||
					intl.get(`product_alert.${alertId}.name`).d(alertId)
				return <div key={alertId}>{alertName}</div>
			})}
		</>
	)
}
export default function AlertsCountField({
	value,
	choices,
}: Pick<CellProp, 'value' | 'choices'>) {
	const alerts = value?.filter(Boolean)
	return alerts?.length > 0 ? (
		<Tooltip content={<TooltipContent alerts={alerts} choices={choices} />}>
			<Badge className='cursor-help' variant='danger'>
				{alerts?.length}
			</Badge>
		</Tooltip>
	) : (
		'-'
	)
}
