import { Button, MenuItem, MenuList, Panel, Spinner } from '@cmpkit/base'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'
import LayersIcon from '@cmpkit/icon/lib/glyph/layers'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import Popover from '@cmpkit/popover'

import { ScenarioModel } from '@/generated'
import intl from '@/locale'

type ScenarioActionsDropdownProps = {
	scenario: ScenarioModel
	isPending: boolean
	onDublicateClick: (scenarioId: string) => Promise<void>
	onDeleteClick: (scenarioId: string) => Promise<void>
}
export default function ScenarioActionsDropdown({
	scenario,
	isPending,
	onDublicateClick,
	onDeleteClick,
}: ScenarioActionsDropdownProps) {
	const dropdownContent = ({ close }: { close: () => void }) => (
		<Panel className='w-56 py-2'>
			<MenuList>
				<MenuItem
					disabled={isPending}
					onClick={() => {
						onDublicateClick?.(scenario?.id)
						close()
					}}
				>
					{isPending ? <Spinner /> : <LayersIcon />}
					<span>{intl.get('scenario.duplicate').d('Duplicate scenario')}</span>
				</MenuItem>
				{!scenario?.is_main && (
					<MenuItem
						variant='destructive'
						onClick={() => {
							onDeleteClick?.(scenario?.id)
							close()
						}}
					>
						<Trash2Icon />
						<span>{intl.get('scenario.delete').d('Delete scenario')}</span>
					</MenuItem>
				)}
			</MenuList>
		</Panel>
	)
	return (
		<Popover content={dropdownContent} placement='bottom-end'>
			<Button variant='tertiary' size='small' iconBefore={<DotsFilledIcon />} />
		</Popover>
	)
}
