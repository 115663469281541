import { useCallback } from 'react'

import { Column, Row, RowData } from '@tanstack/react-table'

import { TableInstance } from '../types'
import { useVirt } from '../virt/useVirt'
import { useRows } from './useRows'

export function useGridVirtualizer<T extends RowData>(
	table: TableInstance<T>,
	{
		getRowHeight,
		getColSize,
	}: {
		getRowHeight: (row?: Row<T>) => number
		getColSize:
			| ((column: Column<T>) => number)
			| ((column?: Column<T>) => undefined)
	}
) {
	const {
		options: { enableVirtualization },
		refs: { tableContainerRef },
	} = table

	if (!enableVirtualization) {
		return undefined
	}

	const rows = useRows(table)
	const visibleColumns = table.getVisibleLeafColumns()
	const getScrollElement = useCallback(
		() => tableContainerRef.current!,
		[tableContainerRef]
	)

	const virt = useVirt<T>({
		enabled: enableVirtualization,
		rows,
		columns: visibleColumns,
		getScrollElement,
		getRowHeight,
		getColSize,
	})

	return virt.getGrid()
}
