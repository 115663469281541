import React, { useEffect } from 'react'

import { Button, Result } from '@cmpkit/base'

import dataWarn from '@/assets/img/svg/data_proccesing_warning.svg'
import dataUpdating from '@/assets/img/svg/data_updating_proccess.svg'
import dataResult from '@/assets/img/svg/result.svg'
import intl from '@/locale'

type FormData = {
	settings: string[]
	groups: string[]
}
type Processing = {
	isLoading: boolean
	applyFormData(): void
	requestResult: {
		success?: boolean
		optimization_group_id?: string
	}[]
	formData: FormData
	onRetry(): void
}

export default function Processing({
	isLoading,
	applyFormData,
	requestResult,
	formData,
	onRetry,
}: Processing) {
	const groups = formData.groups
	useEffect(() => {
		applyFormData()
	}, [])
	const success = requestResult.filter(({ success }) => success).length | 0
	const failed = requestResult.filter(({ success }) => !success).length | 0
	const total = groups.length || 0
	if (isLoading) {
		return (
			<Result
				icon={<img src={dataUpdating} />}
				className='flex h-full flex-col justify-center py-10'
				title={intl.get('cp_og_settings_updating_count', {
					count: success + failed,
					total,
				})}
			/>
		)
	} else if (failed > 0) {
		return (
			<Result
				icon={<img src={dataWarn} />}
				className='flex h-full flex-col justify-center py-10'
				title={intl.get('cp_og_settings_result_with_errors', {
					failed,
					success,
				})}
			>
				<Button variant='primary-brand' onClick={onRetry}>
					{intl.get('cp_og_settings_retry')}
				</Button>
			</Result>
		)
	} else if (success > 0) {
		return (
			<Result
				icon={<img src={dataResult} />}
				className='flex h-full flex-col justify-center py-10'
				title={intl.get('cp_og_settings_result_done')}
			/>
		)
	} else {
		return (
			<Result
				icon={<img src={dataWarn} />}
				className='flex h-full flex-col justify-center py-10'
				title={intl.get('fatal_error_title')}
			/>
		)
	}
}
