import clsx from 'clsx'
import React from 'react'

export function ListGroup({
	className,
	...props
}: {
	className?: string
} & React.HTMLAttributes<HTMLUListElement>) {
	return <ul className={clsx('list-group', className)} {...props} />
}
export default ListGroup
