import { values } from 'lodash'
import { useMemo } from 'react'

import {
	createColumnHelper,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table'

import { InlineMessage } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import { DataTable } from '@/components/tables'
import {
	FilterRuleModel,
	MetricMeasure,
	MetricModel,
	OptimizationIDModel,
	TargetProtectDistributionModel,
} from '@/generated'
import intl from '@/locale'
import { useMetricsQuery } from '@/modules/bi/queries'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { MetricDataType } from '../../constants'
import { useStatisticsTargetProtectDistributionQuery } from '../../queries'
import { getUnprefixedMetricKey, roundIfFloat } from './helpers'

export const BusinessMetricsTable = ({
	queryParams,
	isLoading: isDataLoading,
}: {
	queryParams: {
		optimizations: OptimizationIDModel[]
		filters: FilterRuleModel[]
	} | null
	isLoading: boolean
}) => {
	const metricsQuery = useMetricsQuery<MetricModel[]>()

	const targetProtectDistributionStatisticQuery =
		useStatisticsTargetProtectDistributionQuery(queryParams!, {
			enabled: !!metricsQuery.data && !!queryParams && !isDataLoading,
			select: (data) =>
				values(
					data?.reduce(
						(
							/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
							acc: Record<string, any>,
							{ metric, type, value }: TargetProtectDistributionModel
						) => {
							if (!acc[metric]) {
								acc[metric] = {
									name: metric,
								}
							}
							const metricSchema = metricsQuery.data?.find(
								({ name }) => name === metric || name === metric.split('.')[1]
							)
							acc[metric][type] = value
							acc[metric].dataType = metricSchema?.measure
							return acc
						},
						{}
					)
				),
		})
	const isLoading =
		targetProtectDistributionStatisticQuery.isLoading || isDataLoading
	const isError = targetProtectDistributionStatisticQuery.isError
	const columns = useMemo(() => getColumns(), [])
	const table = useReactTable<DistributionStatistic>({
		columns,
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		data: targetProtectDistributionStatisticQuery.data || [],
		getCoreRowModel: getCoreRowModel(),
	})
	if (isLoading) {
		return <LoadingPlaceholder />
	}
	if (isError) {
		return (
			<InlineMessage icon={<AlertIcon />} variant='danger' className='w-full'>
				{targetProtectDistributionStatisticQuery.error.message ||
					intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}
	return (
		<div className='cmp-border overflow-hidden rounded-lg'>
			<DataTable compact table={table} getRowClassName={() => 'h-8'} />
		</div>
	)
}
function getColumns() {
	const columnHelper = createColumnHelper<DistributionStatistic>()
	return [
		columnHelper.accessor('name', {
			header: () =>
				intl.get('opt.summary.business_metrics').d('Business metrics'),
			cell: (info) => {
				const value = info.getValue()
				const key = getUnprefixedMetricKey(value).split('.')[0]
				return <div className='text-xs'>{intl.get(key).d(value)}</div>
			},
		}),
		columnHelper.accessor('target', {
			size: 70,
			header: () => intl.get('og_metric_to_grow').d('Grow'),
			cell: (info) => {
				const metricType = info.row.original.dataType
				return (
					<div className='text-xs'>
						{formatValueByMetricType({
							metricType,
							value: info.getValue(),
						})}
					</div>
				)
			},
		}),
		columnHelper.accessor('protect', {
			size: 70,
			header: () => intl.get('og_metric_to_maintain').d('Maintain'),
			cell: (info) => {
				const metricType = info.row.original.dataType
				return (
					<div className='text-xs'>
						{formatValueByMetricType({
							metricType,
							value: info.getValue(),
						})}
					</div>
				)
			},
		}),
		columnHelper.accessor('other', {
			size: 70,
			header: () => intl.get('og_metric_to_other').d('Other'),
			cell: (info) => {
				const metricType = info.row.original.dataType
				return (
					<div className='text-xs'>
						{formatValueByMetricType({
							metricType,
							value: info.getValue(),
						})}
					</div>
				)
			},
		}),
	]
}
const formatValueByMetricType = ({
	metricType,
	value,
}: {
	metricType: string
	value: number
}) => {
	switch (metricType) {
		case MetricMeasure.Percent:
			return formatNumber(value * 100, NumberFormats.Percent)
		case MetricMeasure.Money:
			return formatNumber(value, NumberFormats.Compact)
		case MetricMeasure.Unit:
			return formatNumber(roundIfFloat(value))
		default:
			return formatNumber(value, NumberFormats.Compact)
	}
}
type DistributionStatistic = {
	name: string
	protect: number
	target: number
	other: number
	dataType: MetricDataType
}
function LoadingPlaceholder() {
	return (
		<div className='flex max-h-[400px] w-full flex-col overflow-hidden rounded border border-solid border-base p-3'>
			<div className='flex flex-col items-center space-y-2'>
				<div className='flex w-full items-center justify-between'>
					<div className='h-5 w-20 animate-pulse rounded bg-accent-4' />
					<div className='h-5 w-10 animate-pulse rounded bg-accent-4' />
				</div>
				<div className='flex w-full items-center justify-between'>
					<div className='h-5 w-24 animate-pulse rounded bg-accent-4' />
					<div className='h-5 w-12 animate-pulse rounded bg-accent-4' />
				</div>
				<div className='flex w-full items-center justify-between'>
					<div className='h-5 w-28 animate-pulse rounded bg-accent-4' />
					<div className='h-5 w-12 animate-pulse rounded bg-accent-4' />
				</div>
			</div>
		</div>
	)
}
