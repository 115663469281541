import {
	all,
	filter,
	find,
	map,
	negate,
	orderBy,
	paths,
	pipe,
	prop,
	propEq,
	uniq,
} from 'lodash/fp'
import { CircleDollarSignIcon } from 'lucide-react'
import {
	ChangeEvent,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbList,
	BreadcrumbPage,
	Button,
	Content,
	Header,
	Layout,
	Loader,
	SegmentGroup,
	SegmentGroupItem,
	Separator,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import ConnectIcon from '@cmpkit/icon/lib/glyph/connect'
import FilterIcon from '@cmpkit/icon/lib/glyph/filter'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import QueueIcon from '@cmpkit/icon/lib/glyph/queue'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import { Operators, ValueEditorType, ValueType } from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'
import CompactSearch from '@/components/CompactSearch'
import FiltersPopover from '@/components/filter/FiltersPopover'
//import { PageNavigatorRegister } from '@/components/PageNavigator'
import NoData from '@/components/placeholders/NoData'
import { BoundaryWithUser } from '@/components/withErrorBoundary'
import { FilterRuleModel } from '@/generated'
import { useMainScenarioId } from '@/hooks/useMainScenarioId'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { Authorization } from '@/modules/Auth/authorization'
//
import { useOptimizationGroupsQuery } from '@/modules/core/queries'
import { useDrawersStore } from '@/modules/drawers/store'
import { useModalStore } from '@/modules/modals/store'
import { useAllUsersQuery } from '@/modules/Users/queries'
import { getUsername } from '@/modules/Users/utils'
import analytic from '@/services/analytics'
import { compareByOperation } from '@/tools/filtering'
import { toOption } from '@/tools/utils'

import { getPricingCampaignEngines } from '../../constants'
import { isGlobalPricingCampaign } from '../../helpers'
import AssignToScenarioDropdown from '../AssignToScenarioDropdown'
import MoveOrCopyPcsDropdown from '../MoveOrCopyPcsDropdown'
import { Frequency } from '../TimelineGrid'
import CampaignsProvider, {
	PricingCampaignsContext,
	PricingCampaignTableRow,
} from './PricingCampaignsProvider'
import PricingCampaignsTable from './TableView'
import TimelineView from './TimelineView'
import ViewSwitcher from './ViewSwitcher'

export function PricingCampaigns({ type = 'all' }: { type: 'local' | 'all' }) {
	if (type === 'all') {
		// Render all pricing campaigns including global and local
		return <PricingCampaignsPage />
	} else if (type === 'local') {
		// Render only local pricing campaigns by oprtimization group id
		return <LocalPricingCampaigns />
	}
	return null
}
export function PricingCampaignsPage() {
	return (
		<BoundaryWithUser config={{ showDialog: true }}>
			<Layout>
				<Header className='h-11 items-center border-b px-4 py-2'>
					<CircleDollarSignIcon className='size-4' />
					<Separator orientation='vertical' className='mx-2 h-5' />
					<Breadcrumb className='text-xs'>
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbPage>
									{intl.get('menu.pricing_campaigns').d('Pricing campaigns')}
								</BreadcrumbPage>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
				</Header>
				<CampaignsProvider
					scope={useMemo(() => ({}), [])}
					analyticsPrefix='settings: global pricing campaigns:'
				>
					<PricingCampaignsView />
				</CampaignsProvider>
			</Layout>
		</BoundaryWithUser>
	)
}
export function LocalPricingCampaigns() {
	const optimizationGroupId = useOptimizationGroupId()!
	const scenarioId = useMainScenarioId(optimizationGroupId)

	const scope = useMemo(
		() => ({
			optimization_group_id: optimizationGroupId!,
			scenario_id: scenarioId!,
		}),
		[optimizationGroupId, scenarioId]
	)

	return (
		<BoundaryWithUser config={{ showDialog: true }}>
			<CampaignsProvider
				scope={scope}
				analyticsPrefix='settings: pricing campaigns:'
			>
				<PricingCampaignsView />
			</CampaignsProvider>
		</BoundaryWithUser>
	)
}

export function PricingCampaignsView() {
	const {
		data,
		scope,
		deleteSelectedPricingCampaigns,
		isLoading,
		selected,
		setSelected,
	} = useContext(PricingCampaignsContext)
	const timelienRef = useRef<null | {
		scrollToCurrentDate: () => void
	}>(null)

	const { showModal } = useModalStore()
	const { closeDrawer } = useDrawersStore()
	const [rules, setRules] = useState<FilterRuleModel[]>([])
	const [searchText, setSearchText] = useState('')
	const [view, setView] = useState<'table' | 'timeline' | 'priority'>('table')
	const [frequency, setFrequency] = useState<Frequency>('week')

	useEffect(() => {
		analytic.logEvent('browse: global pricing campaigns')
		return () => {
			closeDrawer('PRICING_ALERTS')
		}
	}, [])

	useEffect(() => {
		view === 'timeline' && setSelected([])
	}, [view])

	const handleCopyPricingCampaignClick = () => {
		analytic.logEvent('settings: pricing campaigns: table: copy PC')
		showModal('SELECT_COPY_PRICING_CAMPAIGN_FLOW', {
			selected,
			resetSelectedItems: () => setSelected([]),
		})
	}
	const handleReassignPricingCampaignsClick = () => {
		analytic.logEvent(
			'settings: pricing campaigns: table: reassign to another OG'
		)
		showModal('MOVE_PRICING_CANPAIGNS', {
			selected,
			resetSelectedItems: () => setSelected([]),
		})
	}
	const handleDublicatePricingCampaignsClick = () => {
		analytic.logEvent('settings: pricing campaigns: table: duplicate PCs')
		showModal('DUBLICATE_PRICING_CANPAIGNS', {
			selected,
			resetSelectedItems: () => setSelected([]),
		})
	}

	const usersQuery = useAllUsersQuery()
	const optimizationGroupsQuery = useOptimizationGroupsQuery<DataOption[]>({
		select: pipe([
			map(paths(['id', 'name'])),
			toOption,
			orderBy(['label'], ['asc']),
		]),
	})
	/**
	 * Computed
	 */

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) =>
		setSearchText(e.target.value)
	const handleClearInputClick = () => setSearchText('')
	const handleCreateNewPricingCampaign = () => {
		analytic.logEvent('settings: pricing campaigns: create pc: click', {
			location: 'top-bar',
		})
		showModal(
			'CREATE_PRICING_CAMPAIGN_MODAL',
			scope.optimization_group_id
				? {
						optimizationGroupId: scope.optimization_group_id,
					}
				: {}
		)
	}

	const handleDeleteSelectedPricingCampaigns = async () => {
		const ids = filter(({ campaign }) => selected.includes(campaign.id), rows)
			.map(prop('optimization_groups'))
			.flat()
		const confirmation = ids.length
			? await confirmChanges(ids as string[])
			: true
		if (confirmation) {
			await deleteSelectedPricingCampaigns()
		}
	}
	const filterBySearchText = filter((i: PricingCampaignTableRow) =>
		searchText.length > 0 ? i?.campaign?.name?.includes?.(searchText) : true
	)
	const filterByRules = filter((item) => {
		return all((filter: FilterRuleModel) => {
			return compareByOperation(
				prop(filter.name!, item),
				filter.operation!,
				filter.value!
			)
		}, rules)
	})
	const confirmChanges = (optimizationGroupsIds: string[]) =>
		new Promise((resolve) =>
			showModal('AFFECTED_OPTIMIZATION_GROUPS_MODAL', {
				title: intl.get('global.pc.affect.modal.title'),
				subtitle: intl.get('global.pc.affect.modal.subtitle'),
				optimizationGroupsIds,
				onConfirm: () => resolve(true),
				onCancel: () => resolve(false),
			})
		)

	/* const handlers = useMemo(
		() => ({
			onDeleteClick: async (id: string) => {
				const ids =
					find(propEq('campaign.id', id), rows)?.optimization_groups || []
				const confirmation = ids.length ? await confirmChanges(ids) : true
				if (confirmation) {
					await deletePricingCampaign(id)
				}
			},
		}),
		[deletePricingCampaign]
	) */

	const dataChoices = useMemo(
		() => ({
			optimization_groups: optimizationGroupsQuery.data || [],
			'campaign.updated_by':
				usersQuery.data?.map((user) => ({
					label: getUsername(user),
					value: user.id,
				})) || [],
			'campaign.settings.pricing_tactics.engine': getPricingCampaignEngines(),
		}),
		[usersQuery.data]
	)
	const filterFields = useMemo(() => getFilterFields(), [])
	const [staticFilter, setStaticFilter] = useState<'all' | 'local' | 'global'>(
		'all'
	)

	const selectedOptimizationGroupsIds = useMemo((): string[] => {
		const pcs = selected?.map((id) => find(propEq('campaign.id', id), data))
		const groupIds = map(prop('campaign.optimization_group_id'), pcs)
		return uniq(groupIds)
	}, [selected, data])

	// Check if all selected pricing campaigns are only local from one optimization group
	const isOnlyLocalSelected = useMemo(() => {
		return (
			selectedOptimizationGroupsIds.every(Boolean) &&
			selectedOptimizationGroupsIds?.length === 1
		)
	}, [selectedOptimizationGroupsIds])

	// Check if all selected pricing campaigns are only global
	const isOnlyGlobalSelected = useMemo(() => {
		const pcs = selected?.map((id) => find(propEq('campaign.id', id), data))
		const groupIds = map(prop('campaign.optimization_group_id'), pcs)
		return groupIds.every(negate(Boolean)) && uniq(groupIds)?.length === 1
	}, [selected, data])

	// Filter data by rules and search text and static filter
	const rows = useMemo(() => {
		const rows = filterByRules(
			filterBySearchText(data)
		) as PricingCampaignTableRow[]
		if (staticFilter === 'local') {
			return rows.filter((row) => !isGlobalPricingCampaign(row.campaign))
		} else if (staticFilter === 'global') {
			return rows.filter((row) => isGlobalPricingCampaign(row.campaign))
		} else {
			return rows
		}
	}, [data, rules, searchText, staticFilter])

	return (
		<Layout className='relative flex h-full flex-1 flex-col'>
			<Blanket
				isTinted={isLoading}
				className='absolute flex items-center justify-center bg-white/50 backdrop-blur-sm dark:bg-black/50'
			>
				<Loader />
			</Blanket>
			<div
				className={
					isLoading
						? 'flex flex-col overflow-hidden opacity-50'
						: 'flex h-full flex-col overflow-hidden'
				}
			>
				<Header className='my-3 flex items-center px-4'>
					<div className='mr-auto flex items-center space-x-2'>
						<CompactSearch
							onClear={handleClearInputClick}
							value={searchText}
							onChange={handleSearchChange}
							placeholder={intl.get('general_search')}
						/>
						<FiltersPopover
							fields={filterFields}
							onApply={setRules}
							dataChoices={dataChoices}
							filters={rules || []}
						>
							<Button iconBefore={<FilterIcon />}>
								{intl.get('general_filter')}&nbsp;
								{rules.length ? `(${rules.length})` : ''}
							</Button>
						</FiltersPopover>
						{view === 'timeline' && (
							<>
								<SegmentGroup>
									{(['week', 'month', 'quarter'] as Frequency[]).map(
										(item: Frequency) => (
											<Tooltip
												content={intl.get(`timeline.frequency.${item}.hint`)}
											>
												<SegmentGroupItem
													icon
													className='px-3 capitalize'
													active={frequency === item}
													key={item}
													onClick={() => {
														analytic.logEvent(
															'pcs timeline: frequency: click',
															{
																value: item,
															}
														)
														setFrequency(item)
													}}
												>
													{item[0]}
												</SegmentGroupItem>
											</Tooltip>
										)
									)}
								</SegmentGroup>

								<Button
									onClick={() => timelienRef.current?.scrollToCurrentDate()}
								>
									{intl.get('today').d('Today')}
								</Button>
							</>
						)}
						<SegmentGroup>
							<SegmentGroupItem
								onClick={() => setStaticFilter('all')}
								active={staticFilter == 'all'}
							>
								{intl.get('all').d('All')}
							</SegmentGroupItem>
							<SegmentGroupItem
								onClick={() => setStaticFilter('local')}
								active={staticFilter == 'local'}
							>
								{intl.get('local').d('Local')}
							</SegmentGroupItem>
							<SegmentGroupItem
								onClick={() => setStaticFilter('global')}
								active={staticFilter == 'global'}
							>
								{intl.get('global').d('Global')}
							</SegmentGroupItem>
						</SegmentGroup>
					</div>
					<div className='flex items-center space-x-2'>
						{selected?.length === 0 && (
							<>
								<Authorization
									permissions={['CREATE_GLOBAL_PRICING_CAMPAIGNS']}
								>
									<Tooltip
										content={intl
											.get('pc.create.tooltip')
											.d('Create pricing campaign')}
									>
										<Button
											variant='primary-brand'
											iconBefore={<PlusIcon />}
											onClick={handleCreateNewPricingCampaign}
											data-testid='create-pc'
										/>
									</Tooltip>
								</Authorization>
							</>
						)}

						{isOnlyLocalSelected && (
							<AssignToScenarioDropdown
								optimizationGroupId={selectedOptimizationGroupsIds[0]}
							/>
						)}
						{isOnlyLocalSelected && selected?.length === 1 && (
							<MoveOrCopyPcsDropdown
								actions={[
									{
										enhanceBefore: <QueueIcon />,
										label: intl.get('pc.action.copy'),
										onClick: handleCopyPricingCampaignClick,
									},
									{
										enhanceBefore: <ConnectIcon />,
										label: intl.get('pc.action.reassign'),
										onClick: handleReassignPricingCampaignsClick,
									},
								]}
							/>
						)}
						{isOnlyLocalSelected && selected?.length > 1 && (
							<MoveOrCopyPcsDropdown
								actions={[
									{
										enhanceBefore: <QueueIcon />,
										label: intl.get('pc.action.dublicate'),
										onClick: handleDublicatePricingCampaignsClick,
									},
									{
										enhanceBefore: <ConnectIcon />,
										label: intl.get('pc.action.reassign'),
										onClick: handleReassignPricingCampaignsClick,
									},
								]}
							/>
						)}
						{isOnlyLocalSelected && (
							<Button
								iconBefore={<Trash2Icon />}
								onClick={deleteSelectedPricingCampaigns}
							>
								{intl.get('pc.action.bulk_delete', {
									value: selected?.length,
								})}
							</Button>
						)}
						{isOnlyGlobalSelected && selected?.length > 0 && (
							<>
								<Authorization
									permissions={['DELETE_GLOBAL_PRICING_CAMPAIGNS']}
								>
									<Button
										iconBefore={<Trash2Icon />}
										onClick={handleDeleteSelectedPricingCampaigns}
									>
										{intl.get('pc.action.bulk_delete', {
											value: selected?.length,
										})}
									</Button>
								</Authorization>
							</>
						)}
						<ViewSwitcher
							value={view}
							onChange={(view) =>
								setView(view as 'table' | 'timeline' | 'priority')
							}
							analyticEventName={'global pcs: table view'}
						/>
					</div>
				</Header>
				{rows.length === 0 && !isLoading && (
					<Content className='overflow-hidden px-4 pb-4'>
						<div className='flex h-full flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
							{rows?.length !== data?.length ? (
								<NoData
									className='flex-col py-10'
									title={intl
										.get('pc.table.empty.title.filters')
										.d('Looks like there are no items suitable for filters')}
								/>
							) : (
								<NoData
									className='flex-col py-10'
									title={intl
										.get('pc.table.empty.title')
										.d('Looks like there are no "Pricing campaigns"')}
									subtitle={intl
										.get('pc.table.empty.subtitle')
										.d('Create new to use it for your repricing.')}
								>
									<Button
										variant='primary-brand'
										iconBefore={<PlusIcon />}
										onClick={handleCreateNewPricingCampaign}
										data-testid='no-data-create-pc'
									>
										{intl.get('general_create')}
									</Button>
								</NoData>
							)}
						</div>
					</Content>
				)}
				{rows.length > 0 && view === 'table' && (
					<Content className='overflow-hidden px-4 pb-4'>
						<div className='flex h-full flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
							<PricingCampaignsTable data={rows} scope={scope} />
						</div>
					</Content>
				)}
				{rows.length > 0 && view === 'timeline' && (
					<Content className='overflow-hidden px-4 pb-4'>
						<div className='flex h-full flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-2'>
							<TimelineView
								innerRef={timelienRef}
								frequency={frequency}
								data={rows}
								scope={scope}
							/>
						</div>
					</Content>
				)}
			</div>
		</Layout>
	)
}

const getFilterFields = () => [
	{
		value: 'campaign.name',
		label: intl.get('pc_name'),
		valueType: ValueType.str,
		valueEditorType: ValueEditorType.text,
		enum: [],
	},
	{
		value: 'campaign.updated_by',
		label: intl.get('last_modified_by'),
		valueType: ValueType.str,
		operations: [Operators.IN],
		valueEditorType: ValueEditorType.multiselect,
		enum: [],
	},
	{
		value: 'campaign.updated_at',
		label: intl.get('last_modified_at'),
		valueType: ValueType.datetime,
		valueEditorType: ValueEditorType.daterange,
		enum: [],
	},
	{
		value: 'products_count',
		label: intl.get('items_in_pc'),
		valueType: ValueType.number,
		enum: [],
	},
	{
		value: 'campaign.settings.pricing_tactics.engine',
		label: intl.get('general_pricing_approach'),
		valueType: ValueType.str,
		valueEditorType: ValueEditorType.select,
		enum: [],
	},
	{
		value: 'optimization_groups',
		label: intl.get('optimization_group'),
		valueType: ValueType.array,
		valueEditorType: ValueEditorType.multiselect,
		enum: [],
	},
]
