import {
	filter,
	fromPairs,
	indexBy,
	negate,
	pickAll,
	pipe,
	prop,
	propEq,
} from 'lodash/fp'

import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useQueryClient } from '@tanstack/react-query'

import { Button, Spinner } from '@cmpkit/base'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'
import { Step, Steps } from '@cmpkit/steps'

import { FormProvider } from '@/components/HookForm'
import { MultiChoiceManager } from '@/components/MultiChoiceManager'
import notify from '@/components/toasts'
import { BoundaryWithUser } from '@/components/withErrorBoundary'
import { OptimizationGroupModel, ScenarioMainCopySettings } from '@/generated'
import useStep from '@/hooks/useStep'
import intl from '@/locale'
import {
	useOptimizationGroupsQuery,
	useOptimizationsQuery,
} from '@/modules/core/queries'
import CoreService from '@/modules/core/service'
import { client } from '@/network/client'
import analytic from '@/services/analytics'
import Sentry from '@/services/sentry'

import Confirmation from './Confirmation'
import Processing from './Processing'
import SettingsTree from './SettingsTree'

type CopySettingsModalProps = {
	isOpen: boolean
	close(): void
	optimizationGroupId: string
	initialStep?: number
}
type FormData = {
	settings: string[]
	groups: string[]
}
export default function CopySettingsModal({
	isOpen,
	close,
	optimizationGroupId,
	initialStep = 1,
}: CopySettingsModalProps) {
	const queryClient = useQueryClient()
	const optimizationsQuery = useOptimizationsQuery()
	const [isLoading, setIsLoading] = useState(false)
	const [requestResult, setRequestResult] = useState<
		{
			success?: boolean
			optimization_group_id?: string
		}[]
	>([])

	const [currentStep, helpers] = useStep({ maxStep: 4, initialStep })
	const { canGoToPrevStep, canGoToNextStep, goToNextStep, goToPrevStep } =
		helpers

	const methods = useForm<FormData>({
		defaultValues: {
			settings: [],
			groups: [],
		},
	})

	const { data: groups } = useOptimizationGroupsQuery<
		Record<string, OptimizationGroupModel>
	>({
		select: pipe([
			filter(negate(propEq('id', optimizationGroupId))),
			indexBy('id'),
		]),
	})

	const copySettingsToOptGroups = async (
		groups: string[],
		settings: ScenarioMainCopySettings
	) => {
		try {
			const optimizations = optimizationsQuery?.data || {}
			await CoreService.resetOptimizations(optimizations, groups)
			const res = await client.scenario.scenarioMainCopySettings(
				optimizationGroupId,
				{
					...settings,
					dst_optimization_group_ids: groups,
				}
			)
			setRequestResult(res)
			await queryClient.invalidateQueries({
				queryKey: ['optimizations'],
			})
			await queryClient.invalidateQueries({
				queryKey: ['settings'],
			})
		} catch (error) {
			Sentry.captureException(error)
			notify.error({
				text: intl.get('fatal_error_title'),
			})
		}
	}
	const onApplyFormData = async (formData: FormData) => {
		setIsLoading(true)
		try {
			const groups = formData.groups
			await copySettingsToOptGroups(groups, getSettings(formData))
		} finally {
			setIsLoading(false)
		}
	}

	const handleRetry = async (formData: FormData) => {
		setIsLoading(true)
		try {
			const groups = requestResult
				.filter(({ success }) => !success)
				.map(prop('optimization_group_id')) as unknown as string[]
			methods.setValue('groups', groups)
			setRequestResult([])
			await copySettingsToOptGroups(groups, getSettings(formData))
		} finally {
			setIsLoading(false)
		}
	}
	const handleNext = () => {
		const next = Math.min(4, currentStep + 1)
		if (next === 2) {
			analytic.logEvent('settings: copy settings: select settings', {
				'Settings to copy': methods.watch('settings').join(' / '),
			})
		}
		if (next === 3) {
			analytic.logEvent('settings: copy settings: select target OG')
		}
		if (next === 4) {
			analytic.logEvent('settings: copy settings: confirm')
		}
		goToNextStep()
	}
	const handleBack = () => goToPrevStep()

	const closeModal = () => {
		if (currentStep === 4) {
			analytic.logEvent('settings: copy settings: finish')
		}
		close()
	}
	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					onClose={isLoading ? noop : closeModal}
					size='large'
					showCloseButton={!isLoading}
					testId='copy-og-settings-modal'
				>
					<ModalHeader>
						<ModalTitle>{intl.get('cp_og_settings_modal_title')}</ModalTitle>
					</ModalHeader>
					<BoundaryWithUser config={{ showDialog: true }}>
						<FormProvider
							methods={methods}
							onSubmit={methods.handleSubmit(onApplyFormData)}
						>
							<ModalBody className='p-5'>
								<Steps current={currentStep - 1}>
									<Step title={intl.get('cp_og_settings_step_1')} />
									<Step title={intl.get('cp_og_settings_step_2')} />
									<Step title={intl.get('cp_og_settings_step_3')} />
									<Step
										title={intl.get('cp_og_settings_step_4')}
										icon={
											isLoading && (
												<div
													className='flex items-center justify-center'
													style={{ height: 28 }}
												>
													<Spinner />
												</div>
											)
										}
									/>
								</Steps>
								<div className='mt-5'>
									{currentStep === 1 && (
										<Controller
											name='settings'
											control={methods.control}
											render={({ field }) => {
												return (
													<SettingsTree
														value={field.value}
														onChange={field.onChange}
														settingsKeys={settingsKeys}
													/>
												)
											}}
										/>
									)}
									{currentStep === 2 && (
										<Controller
											name='groups'
											control={methods.control}
											render={({ field }) => {
												return (
													<MultiChoiceManager
														value={field.value}
														onChange={field.onChange}
														groups={groups!}
													/>
												)
											}}
										/>
									)}
									{currentStep === 3 && (
										<Confirmation formData={methods.watch()} groups={groups} />
									)}
									{currentStep === 4 && (
										<Processing
											isLoading={isLoading}
											formData={methods.watch()}
											applyFormData={methods.handleSubmit(onApplyFormData)}
											onRetry={methods.handleSubmit(handleRetry)}
											requestResult={requestResult}
										/>
									)}
								</div>
							</ModalBody>
						</FormProvider>
					</BoundaryWithUser>
					{!isLoading && (
						<ModalFooter className='justify-end space-x-2'>
							{canGoToNextStep && (
								<Button
									className='mr-auto'
									onClick={close}
									data-testid='copy-og-settings-modal-cancel'
								>
									{intl.get('general_cancel')}
								</Button>
							)}
							<Button
								onClick={handleBack}
								disabled={!canGoToPrevStep}
								iconBefore={<CaretLeftIcon />}
								data-testid='copy-og-settings-modal-back'
							>
								{intl.get('general_back')}
							</Button>
							{canGoToNextStep && (
								<Button
									variant='primary-brand'
									disabled={!canGoNext(currentStep, methods.watch())}
									onClick={handleNext}
									data-testid='copy-og-settings-modal-next'
									iconAfter={<CaretRightIcon />}
								>
									{intl.get('general_next')}
								</Button>
							)}
							{!canGoToNextStep && (
								<Button
									variant='primary-brand'
									onClick={closeModal}
									data-testid='copy-og-settings-modal-finish'
								>
									{intl.get('general_close_and_finish')}
								</Button>
							)}
						</ModalFooter>
					)}
				</Modal>
			)}
		</ModalTransition>
	)
}

const noop = () => {}
const settingsKeys = [
	'copy_stop_list_settings',
	'copy_business_settings',
	'copy_strategy_settings',
]

const keysToObject = (keys: string[], flag: boolean) =>
	fromPairs(keys.map((key) => [key, flag]))

const getSettings = (formData: FormData) =>
	pickAll(settingsKeys, {
		...keysToObject(settingsKeys, false),
		...keysToObject(formData.settings, true),
	}) as unknown as ScenarioMainCopySettings

const canGoNext = (step: number, formData: FormData) => {
	switch (step) {
		case 1:
			return formData.settings.length > 0
		case 2:
			return formData.groups.length > 0
		case 3:
			return true
		case 4:
			return true
		default:
			return false
	}
}
