import { find } from 'lodash/fp'
import React, { useState } from 'react'

import { Button, Card, Col, MenuItem, MenuList, Row } from '@cmpkit/base'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import Popover from '@cmpkit/popover'

import intl from '@/locale'

import Container from '../Container'
import ChartView from './ChartView'

const toPercent = (data: number) => data * 100
const createSeries = ({ data }: { data: { own: number; other: number } }) => [
	{
		type: 'solidgauge' as const,
		name: intl.get('es_contribution_own'),
		data: [
			{
				color: '#00b557',
				radius: '112%',
				innerRadius: '88%',
				y: Number(toPercent(data.own).toFixed(3)),
			},
		],
	},
	{
		type: 'solidgauge' as const,
		name: intl.get('es_contribution_other'),
		data: [
			{
				color: '#32b2f3',
				radius: '87%',
				innerRadius: '63%',
				y: Number(toPercent(data.other).toFixed(3)),
			},
		],
	},
]
type ContributionSerie = {
	data: { own: number; other: number }
	name: string
}
type ContributionProps = {
	series: ContributionSerie[]
}

export default function Contribution({ series, ...props }: ContributionProps) {
	const [contribution, setContribution] = useState(series?.[0]?.name)
	const content = (
		<Card className='border py-2 shadow'>
			<MenuList>
				{series.map(({ name }, i) => (
					<MenuItem
						data-testid={`contribution_item_${i}`}
						className='cursor-pointer'
						key={name}
						onClick={() => setContribution(name)}
					>
						{name}
					</MenuItem>
				))}
			</MenuList>
		</Card>
	)
	return (
		<Container>
			<Row>
				<Col sm='7'>
					<div className='pt-15'>
						<p className='mb-2 text-xs'>
							{intl.get('general_contribution_to')}
						</p>
						<p className='flex items-center'>
							<Popover placement='bottom-start' content={content}>
								<Button
									size='small'
									className='flex items-center'
									data-testid='contribution_drop_down'
									iconBefore={<CaretDownIcon />}
								>
									{contribution}
								</Button>
							</Popover>
							<span className='ml-2 text-xs text-muted'>
								{intl.get('general_upfit')}
							</span>
						</p>
					</div>
				</Col>
				<Col sm='5'>
					<div className='relative items-center justify-center'>
						<ChartView
							{...props}
							series={createSeries(
								find({ name: contribution }, series) as ContributionSerie
							)}
						/>
					</div>
				</Col>
			</Row>
		</Container>
	)
}
