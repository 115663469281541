import React from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { Button, Spinner } from '@cmpkit/base'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'

import { OptimizationStatus } from '@/generated'
import intl from '@/locale'
import {
	FAILED,
	FINISHED,
	LOCKED,
	UPDATING_FAILED,
} from '@/modules/core/constants'
import { useCheckOptimizationForDeleteExport } from '@/modules/core/hooks/useCheckOptimizationForDeleteExport'
import { useCreateOptimizationMutation } from '@/modules/core/mutations'

export default function RevokeOptimizationAction({
	status,
	optimizationGroupId,
}: {
	status?: OptimizationStatus
	optimizationGroupId: string
}) {
	const queryClient = useQueryClient()
	const checkExport = useCheckOptimizationForDeleteExport(optimizationGroupId)
	const create = useCreateOptimizationMutation({
		onSuccess: (optimization) => {
			queryClient.setQueryData(
				['optimizations', optimization.optimization_group_id],
				optimization
			)
			queryClient.setQueryData(['optimizations'], {
				...queryClient.getQueryData(['optimizations']),
				[optimization.optimization_group_id]: optimization,
			})
		},
	})
	if (status && [FINISHED, FAILED, UPDATING_FAILED].includes(status)) {
		return (
			<Button
				data-testid='revoke-optimization-button'
				size='small'
				disabled={create.isPending}
				iconBefore={create.isPending ? <Spinner /> : <UndoIcon />}
				onClick={() => create.mutate(optimizationGroupId)}
			>
				{intl.get('general_revoke').d('Revoke')}
			</Button>
		)
	}
	if (status === LOCKED) {
		return (
			<Button
				data-testid='revoke-optimization-button'
				size='small'
				disabled={create.isPending}
				iconBefore={create.isPending ? <Spinner /> : <UndoIcon />}
				onClick={checkExport(() => create.mutate(optimizationGroupId))}
			>
				{intl.get('general_revoke').d('Revoke')}
			</Button>
		)
	}
	return null
}
