import { map, pipe, prop } from 'lodash/fp'

import { useQuery } from '@tanstack/react-query'

import { toFilterRules } from '@/components/filter/utils'
import {
	FilterRuleModel,
	PreferencesModel,
	SavedFilterModel,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'

const adaptFilter = (filter: {
	params: { query: object | FilterRuleModel[] }
}) => ({
	...filter,
	params: {
		...filter.params,
		query: Array.isArray(filter.params.query)
			? filter.params.query
			: toFilterRules(filter.params.query),
	},
})

const adaptFilters = pipe([prop('filters'), map(adaptFilter)])

export const useGlobalFiltersQuery = <T = SavedFilterModel[]>(
	options?: UseQueryOptions<SavedFilterModel[], Error, T>
) => {
	return useQuery<SavedFilterModel[], Error, T>({
		queryKey: ['global-filters'],
		queryFn: () => client.savedFilters.listGlobalFilters().then(adaptFilters),
		...(options || {}),
	})
}
export const usePersonalFiltersQuery = <T = SavedFilterModel[]>(
	options?: UseQueryOptions<SavedFilterModel[], Error, T>
) => {
	return useQuery<SavedFilterModel[], Error, T>({
		queryKey: ['personal-filters'],
		queryFn: () => client.savedFilters.listUserFilters().then(adaptFilters),
		...(options || {}),
	})
}

export const useFiltersPreferencesQuery = <T = PreferencesModel>(
	options?: UseQueryOptions<PreferencesModel, Error, T>
) => {
	return useQuery<PreferencesModel, Error, T>({
		queryKey: ['preferences', 'filters'],
		queryFn: () => client.users.getCurrentUserPreferences(),
		...(options || {}),
	})
}
