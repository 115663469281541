import { find, pipe, prop } from 'lodash/fp'
import React from 'react'

import { InlineMessage, LinkButton } from '@cmpkit/base'

import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'
import { useSettingsSchemasQuery } from '@/modules/og-settings/queries'
import { usePricingCampaignContext } from '@/modules/pricing-campaigns'
import { useScenarioQuery } from '@/modules/scenarios/queries'

import analytic from '@/services/analytics'
import {
	getEnumOptions,
	getResolvedSchema,
	translateOptions,
	translateSchemaKeys,
} from '@/tools/json-schema-utils'
import { ScenarioModel } from '@/generated'

{
	/* <InfoBadge
					message={intl
						.get('global.pc.settings.demand_strategy.info_message')
						.d(
							'Please note that the pricing strategy is established at the Optimization group level and influences this Pricing campaign solely within the realm of products associated with a particular optimization group.	'
						)}
				/> */
}

export default function DemandTactic() {
	const { showModal } = useModalStore()
	const { isScenarioContext, scenarioId, optimizationGroupId, isGlobal } =
		usePricingCampaignContext()
	const settingsStrategy = useScenarioQuery<
		ScenarioModel['settings']['strategy']
	>(scenarioId!, {
		select: prop('settings.strategy'),
		refetchOnWindowFocus: 'always',
		enabled: !!scenarioId,
	})
	const schema = useSettingsSchemasQuery({
		select: pipe([
			getResolvedSchema('part:strategy'),
			prop('properties.demand_strategy'),
			translateSchemaKeys,
		]),
		refetchOnWindowFocus: 'always',
	})

	const targetOptions = translateOptions(
		getEnumOptions(prop('properties.target', schema.data))
	)
	const protectOptions = translateOptions(
		getEnumOptions(prop('properties.protect', schema.data))
	)

	const targetStrategyLabel =
		find(
			{ value: settingsStrategy.data?.demand_strategy?.target },
			targetOptions
		)?.label || '-'
	const protectStrategyLabel =
		find(
			{ value: settingsStrategy.data?.demand_strategy?.protect },
			protectOptions
		)?.label || '-'
	const label = targetStrategyLabel
	if (isGlobal) {
		return (
			<InlineMessage variant='info' className='text-xs font-medium'>
				{intl
					.get('global.pc.settings.demand_strategy.message')
					.d(
						'Pricing strategy will be inherited from the Optimization group settings.'
					)}
			</InlineMessage>
		)
	}
	return (
		<>
			<p className='mb-2 flex items-center gap-2'>
				{!settingsStrategy.isLoading ? (
					intl
						.get('app.grow_metric', {
							label,
						})
						.d(`Grow ${label}`)
				) : (
					<div className='h-4 w-32 animate-pulse rounded-lg bg-accent-3' />
				)}
				{!isScenarioContext && (
					<LinkButton
						variant='brand'
						onClick={() =>
							showModal('OPTIMIZATION_GROUP_MODAL', {
								optimizationGroupId: optimizationGroupId!,
							})
						}
					>
						{intl.get('app.change').d('Change')}
					</LinkButton>
				)}
			</p>

			<p className='mb-5 flex items-center gap-2'>
				{!settingsStrategy.isLoading ? (
					intl
						.get('app.maintain_metric', {
							protectStrategyLabel,
						})
						.d(`Maintain ${protectStrategyLabel}`)
				) : (
					<div className='h-4 w-32 animate-pulse rounded-lg bg-accent-3' />
				)}
				{!isScenarioContext && (
					<LinkButton
						variant='brand'
						onClick={() =>
							analytic.logEvent(
								'settings: pricing campaigns: demand strategy: change click'
							)
						}
					>
						{intl.get('app.change').d('Change')}
					</LinkButton>
				)}
			</p>
		</>
	)
}
