import { any, keys, lt, map, pickAll, pipe, size, values } from 'lodash/fp'

import {
	CANCELED,
	FAILED,
	FINISHED,
	LOCKED,
	NEW,
	PENDING,
	SCHEDULED,
	STARTED,
	UPDATING,
	UPDATING_FAILED,
} from '@/modules/core/constants'
import { OptimizationStatus } from '../../types'

export const getViewState = (status: OptimizationStatus, isEdit?: boolean) => {
	switch (true) {
		case (status === NEW || status === CANCELED) && !isEdit:
			return 'start'
		case status === SCHEDULED || status === STARTED:
			return 'stop'
		case status === PENDING:
			return 'commit'
		case status == FINISHED && !isEdit:
			return 'apply'
		case status === LOCKED:
			return 'unlock'
		case status === FAILED && !isEdit:
			return 'restart'
		case isEdit && ![SCHEDULED, STARTED, LOCKED, UPDATING].includes(status):
			return 'save'
		case status === UPDATING:
			return 'updating'
		case status === UPDATING_FAILED:
			return 'create_and_start'
		default:
			return null
	}
}
export const getTitle = (state?: string) => {
	switch (state) {
		case 'updating':
			return 'og_title_updating'
		case 'save':
			return 'og_title_unsaved'
		case 'commit':
			return 'og_title_commit'
		case 'start':
			return 'og_title_ready_to_start'
		case 'stop':
			return 'og_title_in_progress'
		case 'apply':
			return 'og_title_ready_to_apply'
		case 'unlock':
			return 'og_title_applied'
		case 'restart':
			return 'og_title_failed'
		case 'create_and_start':
			return 'og_title_updating_failed'
		default:
			return '-'
	}
}

/**
 * Fields that do not trigger resetting optimization
 */
export const FINAL_FIELDS = [
	'final_price',
	'final_esp',
	'freeze_final_prices',
	'review_check_cm',
	'review_comment_pricing',
	'review_comment_cm',
	'review_price_proposal',
	'review_done_pricing',
]

interface ClientSpecificOptimizationTargetEntity {
	[key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}
const entityHasFinalFields = (item: ClientSpecificOptimizationTargetEntity) =>
	any((field) => item.hasOwnProperty(field), FINAL_FIELDS)
const entityHasNotFinalFields = (
	item: ClientSpecificOptimizationTargetEntity
) =>
	any((field) => field !== 'sku' && !FINAL_FIELDS.includes(field), keys(item))

export const isEditedFinalFields = pipe([values, any(entityHasFinalFields)])
export const isEditedModelFields = pipe([values, any(entityHasNotFinalFields)])
export const getOnlyFinalFields = map(pickAll(['sku', ...FINAL_FIELDS]))
export const isEditedData = pipe([values, size, lt(0)])

export type ChangesModel = {
	final_esp?: number
	final_price?: number
	sku: string
	[key: string]: unknown
}
/**
 * Create product diffs from local changes to send to backend
 * @param localData - loal changes
 * @returns  - products list with local changes
 */
export const createProductDiffs = (
	localData: Record<string, object>
): ChangesModel[] => {
	const diffs: ChangesModel[] = []
	Object.entries(localData).forEach(([key, value]: [string, object]) => {
		const [lineId, sku] = key.split('__')
		if (sku) {
			const diff = {
				...(localData[`${lineId}__`] || {}),
				...value,
				sku,
			}
			diffs.push(diff)
		}
	})
	return diffs
}
