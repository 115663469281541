import memoizeOne from 'memoize-one'
import * as qs from 'qs'

/**
 * Transforms query string to object with comma separated arrays
 * @example  '?a=1&b=2,3' => { a: 1, b: [2, 3] }
 * @param search - location.search string to be parsed
 * @returns - object with query params
 */
export const toQueryObject = (search: string) =>
	qs.parse(search, { ignoreQueryPrefix: true, comma: true })

/**
 * Transforms object to query string with comma separated arrays and without prefix
 * @example  { a: 1, b: [2, 3] } => '?a=1&b=2,3'
 * @param options - object to be converted to query string
 * @returns - query string
 */
export const toQueryString = (options: object) =>
	qs.stringify(options, {
		arrayFormat: 'comma',
		addQueryPrefix: true,
		encode: false,
	})

export const getLocationQuery = memoizeOne((search) =>
	qs.parse(search, { ignoreQueryPrefix: true, comma: true })
)
