import { any, equals, find, orderBy, pipe, prop } from 'lodash/fp'
import { useMemo, useState } from 'react'

import { Column } from '@cmpkit/data-table'
import { ValueType } from '@cmpkit/query-builder'

import { BIResponse, FilterRuleModel } from '@/generated'
import intl from '@/locale'
import { useBIAnalyticQuery } from '@/modules/bi/queries'
import { comparePeriod, zipSeries } from '@/modules/bi/utils'
import { ColumnConfig } from '@/modules/exports/types'
import analytic from '@/services/analytics'

import MetricPerfomanceHeader from '../../MetricPerfomanceHeader'
import TableView from '../../TableView'
import { WidgetPeriods, WidgetProps } from '../types'
import getColumns from './columns'

const metrics = [
	'sales_items',
	'revenue',
	'gross_profit',
	'gross_profit_margin',
]
type DataRow = {
	brand: string
	sales_items: number
	revenue: number
	gross_profit: number
	gross_profit_margin: number
}
type AnalyticsResponse = Omit<BIResponse, 'data'> & { data: DataRow[] }
const getRowId = prop('brand')

function comparePeriods(
	mainZipped: DataRow[] = [],
	comparedZipped: DataRow[] = [],
	meta: string[] = [],
	getUniqKey: (i: DataRow) => string
): Array<DataRow> {
	if (!mainZipped || !comparedZipped) {
		return []
	}
	const output: DataRow[] = []
	for (let i = 0; i < mainZipped.length; i++) {
		const current = mainZipped[i]
		const prev = find(
			pipe([getUniqKey, equals(getUniqKey(current))]),
			comparedZipped
		) as DataRow
		output.push(comparePeriod<DataRow>(mainZipped[i], prev, meta))
	}
	return output
}
const getReportSchema = (columns: Column[]): ColumnConfig[] => [
	...columns.map(({ key, title }) => ({
		key: key === 'name' ? 'brand' : key,
		type: key === 'name' ? ValueType.str : ValueType.number,
		name: title,
		custom_name: title,
		static_value: null,
		enabled: true,
	})),
]
const getExportName = (name: string, periods: WidgetPeriods): string =>
	`${name} ${periods.main.start_date}-${periods.main.end_date} -> ${periods.compared.start_date}-${
		periods.compared.end_date
	}`
export default function MetricsByBrand({
	widget,
	params,
	onChangeParams,
	commonPeriods,
	commonFilters,
}: WidgetProps) {
	const [localPeriods, setLocalPeriods] = useState<WidgetPeriods | null>(null)
	const defaultPeriods = commonPeriods
	/**
	 * Common Query Parameters
	 */
	const commonQueryParams = {
		label: widget.kind,
		metrics,
		dimensions: ['brand'],
		date_aggregation: null,
		filters: [...commonFilters, ...(params.filters ?? [])],
	}
	const select = (data: BIResponse | null): AnalyticsResponse => ({
		...data!,
		data: data
			? zipSeries<DataRow>(data?.meta, orderBy([0], ['asc'], data?.data))
			: [],
	})
	/**
	 * Queries
	 */
	const mainPeriodQuery = useBIAnalyticQuery<AnalyticsResponse>(
		{
			...(localPeriods ? localPeriods?.main : commonPeriods.main),
			...commonQueryParams,
		},
		{
			select,
		}
	)
	const comparisonPeriodQuery = useBIAnalyticQuery<AnalyticsResponse>(
		{
			...(localPeriods ? localPeriods?.compared : commonPeriods.compared),
			...commonQueryParams,
		},
		{
			select,
		}
	)

	/**
	 * Calculated Values
	 */
	const isLoading = any(prop('isLoading'), [
		mainPeriodQuery,
		comparisonPeriodQuery,
	])
	const columns = useMemo(() => getColumns({ metrics }), [])
	const fields = getReportSchema(columns)
	const rows = comparePeriods(
		mainPeriodQuery?.data?.data,
		comparisonPeriodQuery?.data?.data,
		mainPeriodQuery?.data?.meta,
		getRowId
	)
	const name = getExportName(
		intl.get('widget.metric_by_brand.title'),
		localPeriods || commonPeriods
	)
	/**
	 * Handlers
	 */
	const handleFilterChange = (filters: FilterRuleModel[]) => {
		analytic.logEvent(`dashboard: bi: apply filter ${widget.kind}`, {
			'filter by': filters.map(prop('name')).join(','),
		})
		onChangeParams({ ...params, filters })
	}

	return (
		<div className='flex-auto space-y-3'>
			<h3>{intl.get('widget.metric_by_brand.title')}</h3>
			<MetricPerfomanceHeader
				data={{ fields, rows, name, kind: 'metric_by_brand' }}
				params={params}
				commonFilters={commonFilters}
				onFilterChange={handleFilterChange}
				periods={localPeriods || commonPeriods}
				defaultPeriods={defaultPeriods}
				setPeriods={setLocalPeriods}
			/>
			<TableView
				rows={rows}
				columns={columns}
				isLoading={isLoading}
				getRowId={getRowId}
			/>
		</div>
	)
}
