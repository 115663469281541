import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
	PromoCampaignCreateModel,
	PromoCampaignModel,
	PromoCampaignUpdateModel,
} from '@/generated'
import { client } from '@/network/client'

export const mergeBy =
	(result: PromoCampaignModel) => (old?: PromoCampaignModel[]) => [
		...(old || []),
		result,
	]
export function useCreatePromoCampaignMutation(
	options?: UseMutationOptions<
		PromoCampaignModel,
		AxiosError,
		PromoCampaignCreateModel
	>
) {
	return useMutation<PromoCampaignModel, AxiosError, PromoCampaignCreateModel>({
		mutationFn: (payload) => client.promoCampaigns.createPromoCampaign(payload),
		...(options || {}),
	})
}

export function useUpdatePromoCampaignMutation(
	options?: UseMutationOptions<
		PromoCampaignModel,
		AxiosError,
		{ promoCampaignId: string; data: PromoCampaignUpdateModel }
	>
) {
	return useMutation<
		PromoCampaignModel,
		AxiosError,
		{ promoCampaignId: string; data: PromoCampaignUpdateModel }
	>({
		mutationFn: (payload) =>
			client.promoCampaigns.updatePromoCampaign(
				payload.promoCampaignId,
				payload.data
			),
		...(options || {}),
	})
}
export function useDeletePromoCampaignMutation(
	options?: UseMutationOptions<void, AxiosError, string>
) {
	return useMutation<void, AxiosError, string>({
		mutationFn: (id) => client.promoCampaigns.deletePromoCampaign(id),
		...(options || {}),
	})
}
