import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { StoredColumnConfig } from '@/tools/columns'

export interface TableConfigState {
	rowHeight: number
	columnsWidth: {
		[key: string]: number
	}
	columnsConfig: StoredColumnConfig[]
	setRowHeight: (rowHeight: number) => void
	setColumnsWidth: (columnsWidth: { [key: string]: number }) => void
	setColumnsConfig: (columnsConfig: StoredColumnConfig[]) => void
	setConfig: (data: {
		rowHeight: number
		groupKey: string | null
		columnsConfig: StoredColumnConfig[]
		columnsWidth: {
			[key: string]: number
		}
	}) => void
}
export const useTableConfigStore = create<
	TableConfigState,
	[['zustand/persist', TableConfigState]]
>(
	persist(
		(set) => ({
			rowHeight: 34,
			columnsWidth: {},
			columnsConfig: [],
			setRowHeight: (rowHeight) => set((state) => ({ ...state, rowHeight })),
			setColumnsWidth: (columnsWidth) =>
				set((state) => ({ ...state, columnsWidth })),
			setColumnsConfig: (columnsConfig) =>
				set((state) => ({ ...state, columnsConfig }), true),
			setConfig: (config) => set((state) => ({ ...state, ...config }), true),
		}),
		{
			name: 'assortment_table_config',
		}
	)
)
