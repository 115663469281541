import clsx from 'clsx'
import { any, map, prop, uniq } from 'lodash/fp'

import { Badge } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'

import intl from '@/locale'
import { getAlertLevelStyling } from '@/modules/core'
import analytic from '@/services/analytics'
import { formatNumber, NumberFormats } from '@/tools/locale'

import ListGroup, {
	ListGroupContent,
	ListGroupEnd,
	ListGroupInner,
	ListGroupItem,
	ListGroupStart,
} from '../ListGroup'
import { PricingAlertsSolution } from './PricingAlertsSolution'
import { PricingAlertsSolutionPopup } from './PricingAlertsSolutionPopup'
import { PricingAlertsTreeAlert } from './PricingAlerts'

interface PricingAlertsGroupProps {
	analyticPerfix: string
	count: number
	kind: string
	percent: number
	alerts: PricingAlertsTreeAlert[]
	onFilterClick?: (ids: string[]) => void
	collapsedByDefault?: boolean
	optimizationGroupId?: string
}
export default function PricingAlertsGroup({
	analyticPerfix,
	kind,
	count,
	alerts,
	percent,
	onFilterClick,
	collapsedByDefault = false,
}: PricingAlertsGroupProps) {
	const { isOpen, toggle } = useDisclosure(collapsedByDefault)
	const { backgroundClassNames, badgeVariant } = getAlertLevelStyling(kind)
	const handleCarretClick = () => {
		analytic.logEvent(`${analyticPerfix} hit the list of notifications`)
		toggle()
	}
	const handleShowAllButtonClick = () => {
		analytic.logEvent(`${analyticPerfix} hit show notifications`, {
			kind,
		})
		onFilterClick && onFilterClick(uniq(map(prop('id'), alerts)))
	}
	const handleShowButtonClick = (id: string) => {
		analytic.logEvent(`${analyticPerfix} hit show notification`, {
			kind,
		})
		onFilterClick && onFilterClick([id])
	}

	const ArrowIcon = isOpen ? CaretDownIcon : CaretRightIcon
	return (
		<ListGroup className='fade-in rounded-lg shadow'>
			<ListGroupItem
				className={clsx(
					'list-group-rounded relative cursor-text px-3 py-2',
					backgroundClassNames
				)}
			>
				<ListGroupInner
					className={clsx(any(prop('solution'), alerts) && 'pr-9')}
				>
					<ListGroupStart
						className='mr-1 cursor-pointer'
						onClick={handleCarretClick}
					>
						<ArrowIcon
							width={18}
							height={18}
							className='flex items-center text-muted dark:text-white'
						/>
					</ListGroupStart>
					<ListGroupContent className='text-xs font-medium'>
						{intl.get(kind).d(kind)}
					</ListGroupContent>
					<ListGroupEnd>
						<Badge
							data-testid={`badge_${kind}`}
							variant={badgeVariant}
							{...(onFilterClick && {
								onClick: handleShowAllButtonClick,
								interactive: true,
								className: 'cursor-pointer',
							})}
						>{`${formatNumber(count)} (${formatNumber(
							percent,
							NumberFormats.Percent
						)})`}</Badge>
					</ListGroupEnd>
				</ListGroupInner>
			</ListGroupItem>
			{isOpen && (
				<>
					{alerts?.map(
						({ name, products, id, est_revenue_share, solution }) => {
							const formattedMetrics = {
								products: formatNumber(products),
								revenue: formatNumber(est_revenue_share, NumberFormats.Percent),
							}
							return (
								<ListGroupItem
									key={id}
									className={clsx(
										'list-group-rounded relative cursor-text bg-accent-1 py-2',
										solution ? 'pe-1 ps-3' : 'px-3'
									)}
									data-testid={`alert_${id}`}
								>
									<ListGroupInner>
										<ListGroupContent className='text-xs'>
											{intl.get(name).d(name)}
										</ListGroupContent>
										<ListGroupEnd>
											<Badge
												data-testid={`badge_${kind}_${id}`}
												variant={badgeVariant}
												{...(onFilterClick && {
													onClick: () => handleShowButtonClick(id),
													interactive: true,
													className: 'cursor-pointer',
												})}
											>
												{formattedMetrics.products} ({formattedMetrics.revenue})
											</Badge>
											{solution && (
												<PricingAlertsSolutionPopup
													event={`${analyticPerfix}: click show solution`}
												>
													<PricingAlertsSolution
														badgeContent={{ formattedMetrics, badgeVariant }}
														name={name}
														solution={solution}
													/>
												</PricingAlertsSolutionPopup>
											)}
										</ListGroupEnd>
									</ListGroupInner>
								</ListGroupItem>
							)
						}
					)}
				</>
			)}
		</ListGroup>
	)
}
