import { map, pipe, prop, sum } from 'lodash/fp'
import * as qs from 'qs'
import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'

import { Button, Spinner } from '@cmpkit/base'
import AlertOctagonIcon from '@cmpkit/icon/lib/glyph/alert-octagon'
import { FilterRuleEntity } from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import { decodeComplexQuery } from '@/components/data-grid/helpers'
import intl from '@/locale'
import { useDrawer } from '@/modules/drawers/store'
import analytic from '@/services/analytics'

import { useAnchoringAlertsQuery } from '../queries'

const getTotalCount = pipe([map(prop('anchors')), sum])

export default function AnchoringManagementAlertsButton() {
	const drawer = useDrawer('ANCHORING_MANAGEMENT_ALERTS')
	const location = useLocation()
	const filters = useMemo(
		(): FilterRuleEntity[] =>
			decodeComplexQuery(
				qs.parse(location.search, { ignoreQueryPrefix: true }).qf as string
			)?.filters || [],
		[location.search]
	)

	const { data, isLoading } = useAnchoringAlertsQuery<number>(
		{ filters },
		{
			select: getTotalCount,
		}
	)
	useEffect(() => {
		return () => drawer.close()
	}, [])

	return (
		<Tooltip content={intl.get('app.pricing_alerts')} placement='top-end'>
			<Button
				onClick={() => {
					analytic.logEvent('alerts: anchoring: click open sidebar')
					drawer.open({
						drawerClassName: 'assortment-alerts-eastside',
					})
				}}
				className='relative'
				iconBefore={
					<>
						{isLoading ? <Spinner /> : <AlertOctagonIcon />}
						{!!data && (
							<span className='absolute -right-1 -top-1 flex size-3 ease-in-out'>
								<span className='absolute inline-flex size-full animate-ping rounded-full bg-danger-100' />
								<span className='relative inline-flex size-3 rounded-full bg-danger' />
							</span>
						)}
					</>
				}
			/>
		</Tooltip>
	)
}
