/* eslint-disable @typescript-eslint/no-explicit-any */

/* The default serializer for encoding and decoding messages */
import { CHANNEL_EVENTS } from './constants'

export default {
	HEADER_LENGTH: 1,
	META_LENGTH: 4,
	KINDS: { push: 0, reply: 1, broadcast: 2 },

	encode(msg: any, callback: (d: any) => void) {
		if (msg.payload.constructor === ArrayBuffer) {
			return callback(this.binaryEncode(msg))
		} else {
			const payload = [msg.join_ref, msg.ref, msg.topic, msg.event, msg.payload]
			return callback(JSON.stringify(payload))
		}
	},

	decode(rawPayload: any, callback: (d: any) => void) {
		if (rawPayload.constructor === ArrayBuffer) {
			return callback(this.binaryDecode(rawPayload))
		} else {
			const [join_ref, ref, topic, event, payload] = JSON.parse(rawPayload)
			return callback({ join_ref, ref, topic, event, payload })
		}
	},

	// private

	binaryEncode(message: any) {
		const { join_ref, ref, event, topic, payload } = message
		const metaLength =
			this.META_LENGTH +
			join_ref.length +
			ref.length +
			topic.length +
			event.length
		const header = new ArrayBuffer(this.HEADER_LENGTH + metaLength)
		const view = new DataView(header)
		let offset = 0

		view.setUint8(offset++, this.KINDS.push) // kind
		view.setUint8(offset++, join_ref.length)
		view.setUint8(offset++, ref.length)
		view.setUint8(offset++, topic.length)
		view.setUint8(offset++, event.length)
		Array.from(join_ref, (char: any) =>
			view.setUint8(offset++, char.charCodeAt(0))
		)
		Array.from(ref, (char: any) => view.setUint8(offset++, char.charCodeAt(0)))
		Array.from(topic, (char: any) =>
			view.setUint8(offset++, char.charCodeAt(0))
		)
		Array.from(event, (char: any) =>
			view.setUint8(offset++, char.charCodeAt(0))
		)

		const combined = new Uint8Array(header.byteLength + payload.byteLength)
		combined.set(new Uint8Array(header), 0)
		combined.set(new Uint8Array(payload), header.byteLength)

		return combined.buffer
	},

	binaryDecode(buffer: any) {
		const view = new DataView(buffer)
		const kind = view.getUint8(0)
		const decoder = new TextDecoder()
		switch (kind) {
			case this.KINDS.push:
				return this.decodePush(buffer, view, decoder)
			case this.KINDS.reply:
				return this.decodeReply(buffer, view, decoder)
			case this.KINDS.broadcast:
				return this.decodeBroadcast(buffer, view, decoder)
		}
	},

	decodePush(buffer: any, view: any, decoder: any) {
		const joinRefSize = view.getUint8(1)
		const topicSize = view.getUint8(2)
		const eventSize = view.getUint8(3)
		let offset = this.HEADER_LENGTH + this.META_LENGTH - 1 // pushes have no ref
		const joinRef = decoder.decode(buffer.slice(offset, offset + joinRefSize))
		offset = offset + joinRefSize
		const topic = decoder.decode(buffer.slice(offset, offset + topicSize))
		offset = offset + topicSize
		const event = decoder.decode(buffer.slice(offset, offset + eventSize))
		offset = offset + eventSize
		const data = buffer.slice(offset, buffer.byteLength)
		return {
			join_ref: joinRef,
			ref: null,
			topic: topic,
			event: event,
			payload: data,
		}
	},

	decodeReply(buffer: any, view: any, decoder: any) {
		const joinRefSize = view.getUint8(1)
		const refSize = view.getUint8(2)
		const topicSize = view.getUint8(3)
		const eventSize = view.getUint8(4)
		let offset = this.HEADER_LENGTH + this.META_LENGTH
		const joinRef = decoder.decode(buffer.slice(offset, offset + joinRefSize))
		offset = offset + joinRefSize
		const ref = decoder.decode(buffer.slice(offset, offset + refSize))
		offset = offset + refSize
		const topic = decoder.decode(buffer.slice(offset, offset + topicSize))
		offset = offset + topicSize
		const event = decoder.decode(buffer.slice(offset, offset + eventSize))
		offset = offset + eventSize
		const data = buffer.slice(offset, buffer.byteLength)
		const payload = { status: event, response: data }
		return {
			join_ref: joinRef,
			ref: ref,
			topic: topic,
			event: CHANNEL_EVENTS.reply,
			payload: payload,
		}
	},

	decodeBroadcast(buffer: any, view: any, decoder: any) {
		const topicSize = view.getUint8(1)
		const eventSize = view.getUint8(2)
		let offset = this.HEADER_LENGTH + 2
		const topic = decoder.decode(buffer.slice(offset, offset + topicSize))
		offset = offset + topicSize
		const event = decoder.decode(buffer.slice(offset, offset + eventSize))
		offset = offset + eventSize
		const data = buffer.slice(offset, buffer.byteLength)

		return {
			join_ref: null,
			ref: null,
			topic: topic,
			event: event,
			payload: data,
		}
	},
}
