export default class Deferred<T = unknown> {
	promise: Promise<T>
	// @ts-expect-error: Let's ignore a compile error like this unreachable code
	resolve: (value: T) => void
	// @ts-expect-error: Let's ignore a compile error like this unreachable code
	reject: (value: Error) => void
	constructor() {
		const self = this // eslint-disable-line @typescript-eslint/no-this-alias
		this.promise = new Promise(function (resolve, reject) {
			self.resolve = resolve
			self.reject = reject
		})
	}
}
