import { filter, find, map, prop, uniqBy } from 'lodash/fp'
import React, { useMemo } from 'react'

import { CategoryModel, FilterRuleModel } from '@/generated'
import intl from '@/locale'
import { useAiBIAnalyticQuery, useMetricsQuery } from '@/modules/bi/queries'
import { zipSeries } from '@/modules/bi/utils'
import { useCategoriesQuery } from '@/modules/core/queries'
import analytic from '@/services/analytics'

import { AiDashboardFilter } from '../../Filter'
import TableView from '../../TableView'
import { WidgetProps } from '../types'
import getColumns from './columns'

const getRowId = prop('category.id')
type DataRow = {
	category_id: string
	price_feature: number
	product_feature: number
	support_feature: number
	trend_feature: number
	season_featur: number
}
export default function SalesFactorsImpactByCategoriesWidget({
	commonFilters,
	commonPeriods,
	params,
	widget,
	onChangeParams,
}: WidgetProps) {
	const { data: metrics } = useMetricsQuery<string[]>({
		placeholderData: [],
		select: (data) => map('name', filter({ type: 'ai_importance' }, data)),
	})
	const commonQueryParams = {
		label: widget.kind,
		...(commonPeriods?.main || {
			start_date: null,
			end_date: null,
		}),
		skip_dates: true,
		metrics: metrics!,
		dimensions: ['category_id'],
		date_aggregation: null,
		filters: [...commonFilters, ...(params.filters ?? [])],
	}
	const biQuery = useAiBIAnalyticQuery<DataRow[]>(commonQueryParams, {
		enabled: !!metrics?.length,
		select: (data) => zipSeries<DataRow>(data?.meta, data?.data),
	})
	const categoriesQuery = useCategoriesQuery<CategoryModel[]>(
		{},
		{
			select: uniqBy<CategoryModel>('id'),
		}
	)

	/**
	 * Handlers
	 */

	const handleFilterChange = (filters: FilterRuleModel[]) => {
		analytic.logEvent(`dashboard: bi: apply filter ${widget.kind}`, {
			'filter by': filters.map(prop('name')).join(','),
		})
		onChangeParams({ ...params, filters })
	}
	const rows = filter(
		prop('category'),
		map(
			(data) => ({
				category: find({ id: data.category_id }, categoriesQuery.data),
				metrics: data,
			}),
			biQuery.data
		)
	)
	const columns = useMemo(
		() =>
			getColumns({
				metrics: metrics || [],
			}),
		[metrics]
	)
	return (
		<div className='flex-auto space-y-3'>
			<h3>{intl.get('widget.sales_factors_impact_by_categories.title')}</h3>
			<div className='flex'>
				<AiDashboardFilter
					filters={params.filters ?? []}
					onChange={handleFilterChange}
				/>
			</div>
			<TableView
				rows={rows ?? []}
				columns={columns}
				isLoading={categoriesQuery.isLoading || biQuery.isLoading}
				getRowId={getRowId}
			/>
		</div>
	)
}
