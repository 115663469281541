import React from 'react'

import { TreeNodeType } from '@cmpkit/base'

import { DataOption } from '@/common.types'
import { ColumnSchemaModel } from '@/generated'
import { ColumnDefinition } from '@/tools/columns'

import {
	CellProp,
	getFieldBySchemaType,
	getFieldEditorBySchemaType,
} from './fields'
import { TableChanges } from './helpers'

type RowData = Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
type CellValue = any // eslint-disable-line @typescript-eslint/no-explicit-any

type ProxyCellProps = {
	isDisabled?: boolean
	onChange?(params: {
		row: RowData
		schema: ColumnSchemaModel
		value: CellValue
	}): void
	isChanged?: boolean
	originalValue: CellValue
	value: CellValue
	schema: ColumnSchemaModel
	choices?: DataOption[] | TreeNodeType[]
	row: RowData
	extendFieldsTypes?: Record<string, React.ElementType<CellProp>>
}
/**
 * Proxy component for render cell based on schema type and mapping of renderer components
 * @param props - ProxyCellProps
 * @returns
 */
export function ProxyCell({
	originalValue,
	value,
	schema,
	choices,
	row,
	extendFieldsTypes,
	onChange,
	isChanged,
	isDisabled,
}: ProxyCellProps) {
	const cellType = schema.ui_schema?.type || schema.type
	if (schema.editable && cellType !== 'image') {
		const Component =
			extendFieldsTypes?.[cellType] ?? getFieldEditorBySchemaType(cellType)
		return (
			<Component
				isChanged={isChanged}
				isDisabled={isDisabled}
				onChange={onChange}
				row={row}
				value={value}
				originalValue={originalValue}
				choices={choices as DataOption[]}
				schema={schema}
			/>
		)
	} else {
		if (value === undefined || value === null) {
			return <div className={`cell-data-${schema.type}`}>-</div>
		}
		const Component =
			extendFieldsTypes?.[cellType] ?? getFieldBySchemaType(cellType)
		return (
			<Component
				originalValue={originalValue}
				row={row}
				value={value}
				choices={choices as DataOption[]}
				schema={schema}
			/>
		)
	}
}

export type ColumnModificator = (conf: ColumnDefinition) => ColumnDefinition

export type CellsConfigSchema = {
	extendFieldsTypes: Record<string, React.ElementType<CellProp>>
	dataChoices?: Record<string, DataOption[] | TreeNodeType[]>
	tableDataChanges?: TableChanges
}
/**
 * Create fuction middleware to assign renderer to column config based on schema name and mapping of renderer components
 * @param config - config for assignRenderers function
 * @returns - function that accepts ColumnDefinition and returns ColumnDefinition
 */
export function assignRenderers({
	dataChoices,
	extendFieldsTypes,
	tableDataChanges,
}: CellsConfigSchema): ColumnModificator {
	return function ({ schema, column }: ColumnDefinition): ColumnDefinition {
		return {
			schema,
			column: {
				...column,
				renderer: (originalValue, _item) => {
					const isChanged = tableDataChanges?.hasChanges(_item, schema)
					const value = isChanged
						? tableDataChanges?.getChanges(_item, schema)
						: originalValue
					return (
						<ProxyCell
							onChange={tableDataChanges?.onChange}
							isChanged={isChanged}
							value={value}
							originalValue={originalValue}
							row={_item}
							schema={schema}
							extendFieldsTypes={extendFieldsTypes}
							choices={dataChoices?.[schema.name]}
						/>
					)
				},
			},
		}
	}
}

/**
 * Create fuction middleware to assign prop to column config based on schema name and mapping
 * @example assignProp('renderer', { 'is_active': (value) => value ? <CheckIcon /> : <CrossIcon /> })
 * @param propName - name of the prop to assign
 * @param mapping - mapping of schema name to prop value
 * @returns - ColumnModificator
 */
export function assignProp(
	propName: string,
	mapping: Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
): ColumnModificator {
	return function (conf: ColumnDefinition): ColumnDefinition {
		const propValue = mapping?.[conf.schema.name]
		if (propValue !== undefined) {
			return {
				...conf,
				column: {
					...conf.column,
					[propName]: propValue,
				},
			}
		}
		return conf
	}
}
