import { ColumnDataType } from '@/components/data-grid/types'

import { ColumnSchemaModel } from '../core/types'
import { ANCHORS_TYPES } from './contstants'
import schema from './schema.json'

const createColumnSchema = (schema: object = {}): ColumnSchemaModel => {
	return {
		aggregation_type: 'uniq:ormixed',
		category: undefined,
		editable: false,
		grouper: false,
		hidden: false,
		propagate: false,
		searchable: true,
		filter_schema: {},
		ui_schema: {},
		...schema,
	} as ColumnSchemaModel
}
export function geColumnsSchema(): ColumnSchemaModel[] {
	return [
		...schema,
		...ANCHORS_TYPES.map((anchorType) => [
			createColumnSchema({
				type: ColumnDataType.Float,
				name: `${anchorType}_anchor_count`,
				comparator: `${anchorType}_anchor_count`,
				translate_key: `field_schema_${anchorType}_anchor_count`,
				visible_name: `${anchorType}_anchor_count`,
				filterable: true,
				ui_schema: {
					type: 'anchor_count',
				},
			}),
			createColumnSchema({
				type: ColumnDataType.String,
				editable: true,
				name: `${anchorType}_anchor_sku`,
				comparator: `${anchorType}_anchor_sku`,
				translate_key: `field_schema_${anchorType}_anchor_sku`,
				visible_name: `${anchorType}_anchor_sku`,
				filterable: true,
			}),
			createColumnSchema({
				type: ColumnDataType.Float,
				editable: true,
				name: `${anchorType}_anchor_add`,
				comparator: `${anchorType}_anchor_add`,
				translate_key: `field_schema_${anchorType}_anchor_add`,
				visible_name: `${anchorType}_anchor_add`,
				filterable: true,
			}),
			createColumnSchema({
				type: ColumnDataType.Float,
				editable: true,
				name: `${anchorType}_anchor_mul`,
				comparator: `${anchorType}_anchor_mul`,
				translate_key: `field_schema_${anchorType}_anchor_mul`,
				visible_name: `${anchorType}_anchor_mul`,
				filterable: true,
			}),
		]).flat(),
	] as ColumnSchemaModel[]
}
