import clsx from 'clsx'
import { capitalize } from 'lodash/fp'
import React from 'react'

import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import Tooltip from '@cmpkit/tooltip'

import imgBundleAnchorType from '@/assets/img/illustrations/bundle_anchor_type.jpg'
import imgFlatAnchorType from '@/assets/img/illustrations/flat_anchor_type.jpg'
import imgLadderAnchorType from '@/assets/img/illustrations/ladder_anchor_type.jpg'
import imgProductSetAnchorType from '@/assets/img/illustrations/product_set_anchor_type.jpg'
import imgSizeAnchorType from '@/assets/img/illustrations/size_anchor_type.jpg'
import CollapsibleSection from '@/components/CollapsibleSection'
import intl from '@/locale'

import AnchoringTypeTable, { AnchoringRow } from './AnchoringTypeTable'

export default function AnchoringTypeTableSection({
	type,
	rows,
}: {
	type: string
	rows: AnchoringRow[]
}) {
	const isEmpty = rows.length < 2
	const className = clsx('mb-2', {
		'text-muted': isEmpty,
	})
	return (
		<CollapsibleSection
			isOpen
			key={type}
			className={className}
			disabled={isEmpty}
			header={
				<>
					<h4 className='mr-2'>
						{intl.get(`app.${type}_anchor`).d(capitalize(type))}
					</h4>
					<Tooltip
						boundary={document.getElementsByClassName('drawer-wrapper')[0]}
						content={
							<div className='my-1 flex w-[260px] flex-col'>
								{intl.get(`app.${type}_anchor.description`)}
								<img
									src={ANCHOR_TYPE_IMAGES[type]}
									alt={intl.get(`app.${type}_anchor`)}
									className='mt-2 rounded-sm'
								/>
							</div>
						}
					>
						<InfoIcon />
					</Tooltip>
				</>
			}
		>
			{!isEmpty && <AnchoringTypeTable rows={rows} />}
		</CollapsibleSection>
	)
}
const ANCHOR_TYPE_IMAGES: Record<string, string> = {
	bundle: imgBundleAnchorType,
	flat: imgFlatAnchorType,
	ladder: imgLadderAnchorType,
	product_set: imgProductSetAnchorType,
	size: imgSizeAnchorType,
}
