import React from 'react'

import { Loader } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'

export default function WidgetLoadingState({
	isTinted,
}: {
	isTinted?: boolean
}) {
	return (
		<Blanket
			className='absolute flex items-center justify-center rounded-lg bg-white/50 backdrop-blur-lg dark:bg-black/50'
			isTinted={isTinted}
		>
			<Loader />
		</Blanket>
	)
}
