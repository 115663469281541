import React from 'react'

import { Button } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'

import { PricingCampaignDuplicationResultModel } from '../types'

export default function PricingCampaignManagementResults({
	response,
}: {
	response?: PricingCampaignDuplicationResultModel[]
}) {
	const panel = useDisclosure()
	if (!response) {
		return null
	}
	return (
		<div className='flex w-full flex-col'>
			<Button
				variant='tertiary'
				size='small'
				onClick={panel.toggle}
				iconAfter={<CaretDownIcon />}
			>
				Details
			</Button>
			{panel.isOpen && (
				<div className='space-y-1 rounded-lg bg-accent-3 p-3 text-left'>
					{response
						?.filter((item) => !item.success)
						?.map((item, i) => {
							return (
								<div key={`${item.message}_${i}`} className='text-xs'>
									{item.message}
								</div>
							)
						})}
				</div>
			)}
		</div>
	)
}
