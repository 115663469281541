import { prop } from 'lodash/fp'
import React from 'react'

import { Spinner } from '@cmpkit/base'

import { FilterRuleModel, ProductsQueryRequestModel } from '@/generated'
import { useAssortmentListQuery } from '@/modules/global-assortment/queries'
import { getAssortmentShare } from '@/modules/pricing-campaigns/helpers'
import { formatNumber, NumberFormats } from '@/tools/locale'

type SavedReportsReportCounterProps = {
	filters: FilterRuleModel[]
}
const defaultBody: ProductsQueryRequestModel = {
	limit: 1,
	offset: 0,
	order_by: [],
	filters: [],
}
const queryOptions = {
	select: prop('total_count'),
}
export default function SavedReportsReportCounter({
	filters,
}: SavedReportsReportCounterProps) {
	const totalQuery = useAssortmentListQuery<number>(defaultBody, queryOptions)
	const filteredQuery = useAssortmentListQuery<number>(
		{
			...defaultBody,
			filters,
		},
		queryOptions
	)
	const isLoading = totalQuery.isLoading || filteredQuery.isLoading
	return (
		<>
			{isLoading ? (
				<Spinner />
			) : (
				`${formatNumber(filteredQuery.data as number)} (${formatNumber(
					getAssortmentShare(totalQuery.data || 0, filteredQuery.data || 0),
					NumberFormats.Percent
				)})`
			)}
		</>
	)
}
