import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { ScenarioModel, ScenarioUpdateDataModel } from '@/generated'
import { useBeforeGroupChange } from '@/modules/core/mutations'
import { client } from '@/network/client'

export function useUpdateScenarioMutation(
	scenarioId: string,
	options?: UseMutationOptions<
		ScenarioModel,
		AxiosError,
		ScenarioUpdateDataModel
	>
) {
	return useMutation<ScenarioModel, AxiosError, ScenarioUpdateDataModel>({
		mutationFn: (payload) =>
			client.scenario.scenarioUpdate(scenarioId, payload),
		...(options || {}),
	})
}
export function useUpdateMainScenarioMutation(
	optimizationGroupId: string,
	options?: UseMutationOptions<
		ScenarioModel,
		AxiosError,
		ScenarioUpdateDataModel
	>
) {
	const { onMutate } = useBeforeGroupChange(optimizationGroupId)
	return useMutation<ScenarioModel, AxiosError, ScenarioUpdateDataModel>({
		mutationFn: (payload) =>
			client.scenario.scenarioUpdateMain(optimizationGroupId, payload),
		onMutate,
		...(options || {}),
	})
}
