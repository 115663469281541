import clsx from 'clsx'
import React, { ChangeEvent, DragEvent, useState } from 'react'

import ReportPlusIcon from '@cmpkit/icon/lib/glyph/report-plus'

import intl from '@/locale'

interface DragNDropAreaProps {
	multiple?: boolean
	onChange?(files: FileList | File): void
	value?: FileList | File
	className?: string
	isInvalid?: boolean
}
export default function DragNDropArea({
	onChange,
	className,
	isInvalid,
	multiple,
	value,
}: DragNDropAreaProps) {
	const hasFiles = value instanceof FileList ? value.length > 0 : !!value
	const [fileNames, setFileNames] = useState<string[]>([])
	const [dragover, setDragover] = useState(false)

	const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		event.stopPropagation()
		setDragover(true)
	}

	const handleDrop = (
		event: DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>
	) => {
		event.preventDefault()
		event.stopPropagation()
		const files =
			(event as DragEvent<HTMLDivElement>)?.dataTransfer?.files ||
			(event as ChangeEvent<HTMLInputElement>)?.target?.files
		if (!files.length) return
		const list = []
		for (let i = 0; i < files.length; i++) {
			const file = files[i]
			list.push(file)
		}

		setFileNames(list.map((file) => file.name))
		setDragover(false)
		onChange?.(multiple ? files : files[0])
	}

	return (
		<div
			className='flex w-full flex-1'
			onDrop={handleDrop}
			onDragOver={handleDragOver}
		>
			<label
				htmlFor='dropzone-file'
				className={clsx(
					'flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-base p-3 text-center',
					className,
					{
						'bg-danger-10': isInvalid,
						'bg-accent-3': !isInvalid,
						'border-brand bg-brand-10': (hasFiles || dragover) && !isInvalid,
						'border-danger': isInvalid,
					}
				)}
			>
				<div className='flex flex-col items-center justify-center'>
					<ReportPlusIcon
						height={24}
						width={24}
						className={clsx(
							'mb-2',
							{ hidden: fileNames.length },
							isInvalid ? 'text-danger' : 'text-muted'
						)}
					/>
					{fileNames.length ? (
						<div className='mb-1 text-xs italic'>
							{fileNames.map((name) => (
								<div key={name}>{name}</div>
							))}
						</div>
					) : (
						<p className='mb-1 text-xs font-bold text-muted'>
							{intl.get('dropzone.title').d('Upload your files here')}
						</p>
					)}

					<p className='text-xs text-muted dark:text-muted'>
						{intl.get('dropzone.text').d('Drag and drop or click to select')}
					</p>
				</div>
				<input
					id='dropzone-file'
					type='file'
					onChange={handleDrop}
					className='hidden'
				/>
			</label>
		</div>
	)
}
