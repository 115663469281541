import { prop, sum } from 'lodash/fp'

import { PALETTE } from '@cmpkit/theme'

import { PricingEngineType, ProductsCountByEngineModel } from '@/generated'
import intl from '@/locale'
import { useProductsCountByEngineQuery } from '@/modules/bi/queries'
import { sortIn } from '@/modules/bi/utils'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { DonutViewDataItem } from '../../DonutView'
import DonutView from '../../DonutView/DonutView'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetEmptyState from '../../WidgetEmptyState'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetProps } from '../types'

const getColor = (name: string) => {
	switch (name) {
		case PricingEngineType.RB:
			return PALETTE.orange[75]
		case PricingEngineType.ML:
			return PALETTE.blue[75]
		case PricingEngineType.MD:
			return PALETTE.purple[75]
		default:
			return PALETTE.neutral[150]
	}
}

const buildDataSeries = (
	data: ProductsCountByEngineModel[],
	total: number
): DonutViewDataItem[] => {
	return data
		?.sort(
			sortIn(
				[
					PricingEngineType.ML,
					PricingEngineType.RB,
					PricingEngineType.MD,
					'unassigned',
				],
				prop('pricing_campaign_engine')
			)
		)
		?.map((item: ProductsCountByEngineModel): DonutViewDataItem => {
			return {
				name: intl.get(
					`campaigns_om_title_${item.pricing_campaign_engine || 'not_reprice'}`
				),
				color: getColor(item.pricing_campaign_engine!),
				subvalue: item.revenue,
				y: +((item.revenue! / total) * 100).toFixed(1),
				value: item.product_count!,
			}
		})
}
export default function ReadyForOprimizationWidget({
	commonFilters,
}: WidgetProps) {
	const commonQueryParams = {
		filters: [...commonFilters],
	}
	const { isLoading, data, isError } = useProductsCountByEngineQuery<
		ProductsCountByEngineModel[]
	>(commonQueryParams, {
		select: prop('data') as (data: {
			data?: ProductsCountByEngineModel[]
		}) => ProductsCountByEngineModel[],
	})
	const totalSkus = sum(data?.map(prop('product_count')))
	const totalRevenue = sum(data?.map(prop('revenue')))
	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.ready_for_optimization.title')}
				tooltip={intl.get('widget.ready_for_optimization.tooltip')}
				subtitle={intl
					.get('widget.ready_for_optimization.subtitle')
					.d('[Description]')}
			/>
			<WidgetContent>
				<WidgetLoadingState isTinted={isLoading} />
				<WidgetErrorState isTinted={isError} />
				{data?.length && !isLoading ? (
					<DonutView
						data={buildDataSeries(data, totalRevenue)}
						formatSubValue={(val) => formatNumber(val, NumberFormats.Compact)}
						formatValue={formatNumber}
						total={totalSkus}
					/>
				) : (
					<WidgetEmptyState />
				)}
			</WidgetContent>
		</Widget>
	)
}
