import { any, last, prop } from 'lodash/fp'
import React from 'react'

import { selectDefaultMetricsKeys } from '@/components/BI/utils'
import { BIResponse } from '@/generated'
import intl from '@/locale'
import { useBIAnalyticQuery, useMetricsQuery } from '@/modules/bi/queries'
import { zipSeries } from '@/modules/bi/utils'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import { WidgetProps } from '../types'

type DataRow = Record<string, number>

export default function NumericAvgPriceChangeWidget({
	widget,
	commonPeriods,
	commonFilters,
}: WidgetProps) {
	const { data: metrics, ...metricsQuery } = useMetricsQuery<string[]>({
		placeholderData: [],
		select: selectDefaultMetricsKeys,
	})
	const biCommonQuery = {
		label: widget.kind,
		metrics: metrics!,
		dimensions: [],
		filters: [...commonFilters],
		date_aggregation: null,
	}
	const biQueryConfig = {
		enabled: !!metrics?.length,
		select: (data: BIResponse | null) =>
			prop('sales_items', last(zipSeries<DataRow>(data!.meta, data!.data))),
	}
	const biComparedPeriodQuery = useBIAnalyticQuery<number | undefined>(
		{
			...commonPeriods.compared,
			...biCommonQuery,
		},
		biQueryConfig
	)
	const biMainPeriodQuery = useBIAnalyticQuery<number | undefined>(
		{
			...commonPeriods.main,
			...biCommonQuery,
		},
		biQueryConfig
	)
	const isLoading = any(prop('isFetching'), [
		biMainPeriodQuery,
		biComparedPeriodQuery,
		metricsQuery,
	])

	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				title={intl.get('widget.avg_price_change.title')}
				subtitle={intl.get('app.based_on_last_integration')}
			/>
			<WidgetContent className='truncate pt-0'>
				{isLoading ? (
					<div className='col-span-2 h-10 w-32 animate-pulse rounded-lg bg-accent-3' />
				) : (
					<div className='flex flex-col space-y-1'>
						<div className='flex items-center space-x-2'>
							<span className='truncate text-xs text-muted'>
								({intl.get('last')}: {formatNumber(1.5, NumberFormats.Percent)})
							</span>
							<span className='truncate text-xs text-muted'>
								({intl.get('past')}: {formatNumber(4.67, NumberFormats.Percent)}
								)
							</span>
						</div>
						<span className='truncate text-3xl font-semibold'>
							{formatNumber(2, NumberFormats.Percent)}
						</span>
					</div>
				)}
			</WidgetContent>
		</Widget>
	)
}
