import React from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import { Checkbox, FormError, FormGroup, FormHint } from '@cmpkit/base'

export default function RHFCheckbox({
	name,
	label,
	rules,
	shouldUnregister,
	defaultValue,
	description,
	disabled,
}: UseControllerProps & { label?: string; description?: string }) {
	const { control } = useFormContext()
	return (
		<FormGroup>
			<Controller
				name={name}
				rules={rules}
				shouldUnregister={shouldUnregister}
				defaultValue={defaultValue}
				control={control}
				render={({ field, fieldState: { error } }) => {
					return (
						<label className='items-top mt-2 flex space-x-2'>
							<Checkbox
								disabled={disabled}
								onChange={() => field.onChange(!field.value)}
								checked={field.value}
							/>
							<div className='flex flex-col gap-1'>
								<span className='select-none font-medium leading-4'>
									{label}
								</span>
								{!!description && (
									<FormHint className='items-center'>{description}</FormHint>
								)}

								{error && <FormError>{error.message}</FormError>}
							</div>
						</label>
					)
				}}
			/>
		</FormGroup>
	)
}
