import React from 'react'

import ColoredSelect from '@/components/ColoredSelect'
import intl from '@/locale'
import { MetricOption } from '@/modules/bi/types'

export default function Controls({
	metrics,
	businessMetric,
	onBusinessMetricChange,
	priceMetric,
	onPriceMetricChange,
}: {
	metrics?: MetricOption[]
	businessMetric: string
	onBusinessMetricChange: (metric: string) => void
	priceMetric: string
	onPriceMetricChange: (metric: string) => void
}) {
	return (
		<>
			<div className='mx-5 rounded-lg bg-accent-3 p-5'>
				<h4 className='mb-2.5'>{intl.get('overview_business_metric')}</h4>
				<ColoredSelect
					options={metrics?.filter(({ type }) => type === 'business') || []}
					value={businessMetric}
					placeholder={intl.get('select_metric')}
					onChange={(option) =>
						option?.value && onBusinessMetricChange(option.value)
					}
				/>
				<h4 className='my-2.5'>{intl.get('overview_price_metric')}</h4>
				<ColoredSelect
					options={metrics?.filter(({ type }) => type === 'pricing') || []}
					value={priceMetric}
					placeholder={intl.get('select_metric')}
					onChange={(option) =>
						option?.value && onPriceMetricChange(option.value)
					}
				/>
			</div>
		</>
	)
}
