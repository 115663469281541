import React, {
	ChangeEvent,
	FormEvent,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'

import { Label, Radio, Textfield } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'

import { Note } from '../../components/common'
import { Group } from '../../components/InputGroup'
import { DialogInner } from '../../components/Popup'
import { FieldViewProps, ValueState } from '../../types'
import TextController from './Controller'

const NOOP = () => {}

type State = ValueState
type TextViewProps = FieldViewProps<TextController>

export default function TextView({
	storedValue,
	field,
	invalidMessage,
	isRemovable,
	isDirty,
	localValue,
	onRemove,
	onReset,
	onApply,
	onChange,
	closePopup,
}: TextViewProps) {
	const focusTimeoutId = useRef<ReturnType<typeof setTimeout> | undefined>(
		undefined
	)
	const nextInputRef = useRef<HTMLInputElement | null>(null)
	const [state, setState] = useState<State>(storedValue)

	const focusNextInput = () => {
		const target = nextInputRef.current
		if (target) {
			focusTimeoutId.current = setTimeout(() => {
				target.focus()
			}, 10)
		}
	}

	useLayoutEffect(() => {
		focusNextInput()
		return () => clearTimeout(focusTimeoutId.current)
	})

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		if (invalidMessage) return
		onApply?.()
		closePopup?.()
	}

	const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
		const operation = event.target.value as Operators
		const isKeyboardEvent =
			(event.nativeEvent as PointerEvent).screenX === 0 &&
			(event.nativeEvent as PointerEvent).screenY === 0
		const callback = isKeyboardEvent ? NOOP : focusNextInput

		setState({ ...state, operation })
		callback()
		const value = [Operators.IS_EMPTY, Operators.IS_NOT_EMPTY].includes(
			operation
		)
			? '1'
			: state.value
		onChange({ operation, value })
	}

	const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
		const { operation } = state

		setState({ ...state, value: event.target.value })
		const value = [Operators.IS_EMPTY, Operators.IS_NOT_EMPTY].includes(
			operation as Operators
		)
			? '1'
			: event.target.value
		onChange({ operation, value })
	}

	const filterTypes = field.getFilterTypes()

	const { operation } = state
	const isInvalid = Boolean(invalidMessage)

	return (
		<Group
			onSubmit={handleSubmit}
			onRemove={isRemovable ? onRemove : undefined}
			isDisabledApply={
				!isDirty || Boolean(isInvalid || field.validate(localValue))
			}
			onCancel={() => {
				onReset?.()
				closePopup()
			}}
		>
			<DialogInner className='min-w-[260px] max-w-[260px] space-y-2 p-5'>
				<div className='flex flex-col space-y-3'>
					{filterTypes.map((m) => {
						const isCurrent = m.operation === operation
						return (
							<div className='space-y-1' key={m.operation}>
								<div className='flex items-center space-x-2'>
									<Radio
										id={`radio-mode-${m.operation}}`}
										checked={isCurrent}
										name='mode'
										onChange={onChangeCheckbox}
										value={m.operation}
									/>
									<Label htmlFor={`radio-mode-${m.operation}}`}>
										{m.label}
									</Label>
								</div>
								{isCurrent && m.hasInput ? (
									<>
										<Textfield
											key={m.operation}
											ref={nextInputRef}
											invalid={isInvalid}
											onChange={onChangeInput}
											value={state.value}
											autoComplete='off'
											autoCorrect='off'
										/>
										{invalidMessage && <Note>{invalidMessage}</Note>}
									</>
								) : null}
							</div>
						)
					})}
				</div>
				{field.note && <Note>{field.note}</Note>}
			</DialogInner>
		</Group>
	)
}
