import {
	ChartPieIcon,
	ChevronDownIcon,
	ChevronRightIcon,
	CircleDollarSignIcon,
	DownloadIcon,
	HeadsetIcon,
	LayersIcon,
	PuzzleIcon,
} from 'lucide-react'
import React from 'react'
import { matchPath, NavLink, useLocation } from 'react-router'
import { useLocalStorage } from 'usehooks-ts'

import {
	Card,
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from '@cmpkit/base'

import Popover from '@cmpkit/popover'
import {
	Sidebar,
	SidebarContent,
	SidebarGroup,
	SidebarGroupContent,
	SidebarMenu,
	SidebarMenuBadge,
	SidebarMenuButton,
	SidebarMenuItem,
	SidebarMenuSub,
	SidebarMenuSubButton,
	SidebarMenuSubItem,
	SidebarRail,
	SidebarSeparator,
	useSidebar,
} from '@cmpkit/sidebar'

import intl from '@/locale'
import { AssistantMenuItem } from '@/modules/ai'
import { useAuthorization } from '@/modules/Auth/authorization'
import { useExportsQuery } from '@/modules/exports/queries'
import { useModalStore } from '@/modules/modals/store'

import AppSidebarFooter from './AppSidebarFooter'
import AppSidebarHeader from './AppSidebarHeader'
import AppSidebarIntegrationMenuItem from './AppSidebarIntegrationMenuItem'

const getPricingItems = ({
	checkPermissons,
}: {
	checkPermissons: (permissions: string[]) => boolean
}) => [
	{
		title: intl.get('menu.general_overview'),
		icon: <ChartPieIcon />,
		enabled: checkPermissons(['ACCESS_BI']),
		items: [
			{
				title: intl.get('main_dashboard_title'),
				pathname: '/p/dashboard/main',
			},
			{
				title: intl.get('ai_dashboard_title'),
				pathname: '/p/dashboard/ai',
			},
		],
	},
	{
		title: intl.get('menu.pricing').d('Pricing'),
		icon: <CircleDollarSignIcon />,
		items: [
			{
				title: intl.get('menu.og').d('Optimization groups'),
				pathname: '/p/og',
			},
			{
				title: intl.get('menu.pricing_campaigns').d('Pricing campaigns'),
				pathname: '/p/pc',
			},
			{
				title: intl.get('menu.promo'),
				pathname: '/p/promo',
			},
			{
				title: intl.get('app.products'),
				pathname: '/p/products',
			},
		],
	},

	{
		title: intl.get('menu.assortment'),
		icon: <LayersIcon />,
		enabled: true,
		items: [
			{
				title: intl.get('assortment.page.title'),
				pathname: '/p/assortment/products',
			},
			{
				title: intl
					.get('app.anchoring_management.page.title')
					.d('Anchor management'),
				pathname: '/p/assortment/management',
			},
			{
				title: intl.get('saved_reports.page.title'),
				pathname: '/p/assortment/reports',
			},
		],
	},

	{
		title: intl.get('templates').d('Templates'),
		pathname: '/p/templates',
		icon: <PuzzleIcon />,
	},
]

export default function AppSidebar() {
	const { showModal } = useModalStore()
	const location = useLocation()
	const { open } = useSidebar()
	const { checkPermissons } = useAuthorization()
	const pricngItems = getPricingItems({ checkPermissons })

	return (
		<Sidebar collapsible='icon' className='!bg-accent-2'>
			<AppSidebarHeader />
			<SidebarContent>
				<SidebarGroup>
					<SidebarGroupContent>
						<SidebarMenu>
							{pricngItems.map((item) => {
								const locationPathname = location.pathname
								const matched = item.items?.some((i) =>
									matchPath(locationPathname, i.pathname)
								)
								if (item.pathname && !item.items) {
									return (
										<SidebarMenuItem key={item.title}>
											<SidebarMenuButton tooltip={item.title} asChild>
												<NavLink to={item.pathname}>
													{item.icon}
													<span>{item.title}</span>
												</NavLink>
											</SidebarMenuButton>
										</SidebarMenuItem>
									)
								}
								return (
									<Collapsible key={item.title} className='group/collapsible'>
										<SidebarMenuItem key={item.title}>
											{open ? (
												<CollapsibleTrigger asChild>
													<SidebarMenuButton tooltip={item.title}>
														{item.icon}
														<span className='truncate'>{item.title}</span>
														<ChevronRightIcon className='ml-auto group-data-[state=open]/collapsible:hidden' />
														<ChevronDownIcon className='ml-auto group-data-[state=closed]/collapsible:hidden' />
													</SidebarMenuButton>
												</CollapsibleTrigger>
											) : (
												<Popover
													placement='right-start'
													trigger='hover'
													content={({ close }) => (
														<Card
															className='w-72 border bg-sidebar-background shadow'
															onMouseLeave={() => close()}
														>
															<div className='px-4 py-2 font-medium text-muted'>
																<span>{item.title}</span>
															</div>
															<SidebarMenuSub className='ml-5'>
																{item.items?.map((item) => (
																	<SidebarMenuSubItem
																		className='group/menu-sub-item relative'
																		key={item.title}
																	>
																		<SidebarMenuSubButton
																			asChild
																			className='truncate'
																		>
																			<NavLink to={item.pathname}>
																				{item.title}
																			</NavLink>
																		</SidebarMenuSubButton>
																	</SidebarMenuSubItem>
																))}
															</SidebarMenuSub>
														</Card>
													)}
												>
													<span>
														<SidebarMenuButton isActive={matched}>
															{item.icon}
															<span>{item.title}</span>
														</SidebarMenuButton>
													</span>
												</Popover>
											)}

											{item.items?.length ? (
												<CollapsibleContent>
													<SidebarMenuSub>
														{item.items.map((item) => (
															<SidebarMenuSubItem
																className='group/menu-sub-item relative'
																key={item.title}
															>
																<SidebarMenuSubButton
																	asChild
																	className='truncate [&.active]:font-semibold'
																>
																	<NavLink to={item.pathname}>
																		{item.title}
																	</NavLink>
																</SidebarMenuSubButton>
															</SidebarMenuSubItem>
														))}
													</SidebarMenuSub>
												</CollapsibleContent>
											) : null}
										</SidebarMenuItem>
									</Collapsible>
								)
							})}
						</SidebarMenu>
					</SidebarGroupContent>
				</SidebarGroup>
				<SidebarSeparator />
				<SidebarGroup>
					<SidebarGroupContent>
						<SidebarMenu>
							<ExportsMenuItem />
						</SidebarMenu>
					</SidebarGroupContent>
				</SidebarGroup>
				<SidebarGroup className='mt-auto'>
					<SidebarGroupContent>
						<SidebarMenu>
							<SidebarMenuItem>
								<SidebarMenuButton
									data-testid='exports-menu-item'
									tooltip={intl.get('contact_support').d('Contact support')}
									onClick={() => showModal('CREATE_JSD_TICKET')}
								>
									<HeadsetIcon />
									<span>
										{intl.get('contact_support').d('Contact support')}
									</span>
								</SidebarMenuButton>
							</SidebarMenuItem>
							<AssistantMenuItem />
							<AppSidebarIntegrationMenuItem />
						</SidebarMenu>
					</SidebarGroupContent>
				</SidebarGroup>
			</SidebarContent>
			<SidebarSeparator />
			<AppSidebarFooter />
			<SidebarRail />
		</Sidebar>
	)
}

function ExportsMenuItem() {
	const { showModal } = useModalStore()
	const [newExports, setNewExports] = useLocalStorage(
		'new_exports_unreaded',
		false
	)
	const exportsQuery = useExportsQuery()
	return (
		<SidebarMenuItem>
			<SidebarMenuButton
				data-testid='exports-menu-item'
				tooltip={intl.get('app.export_service').d('Export service')}
				onClick={() => {
					setNewExports(false)
					showModal('EXPORTS')
				}}
			>
				<DownloadIcon />
				{newExports && (
					<span className='absolute left-0.5 top-0.5 size-2 shrink-0 rounded-full bg-warning' />
				)}
				<span className='truncate'>
					{intl.get('app.export_service').d('Export service')}
				</span>
			</SidebarMenuButton>
			<SidebarMenuBadge>{exportsQuery.data?.length}</SidebarMenuBadge>
		</SidebarMenuItem>
	)
}
