import { format } from 'date-fns'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Button } from '@cmpkit/base'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { FormProvider, RHFSelect } from '@/components/HookForm'
import intl from '@/locale'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'

import { downloadAssortmentFile } from '../helpers'

const ExportSchema = Yup.object().shape({
	report_type: Yup.string().required('Report type is required'),
	file_format: Yup.string().required('File format is required'),
	by_pricing_line: Yup.boolean(),
})
type FormData = Yup.InferType<typeof ExportSchema>
type ExportTableModalContentProps = {
	close(): void
	reportName?: string
}
function ExportTableModalContent({
	close,
	reportName,
}: ExportTableModalContentProps) {
	const methods = useForm<FormData>({
		defaultValues: {
			report_type: 'full',
			file_format: 'xlsx',
			by_pricing_line: true,
		},
		resolver: yupResolver(ExportSchema),
	})
	const _handleSubmit = ({ file_format, report_type }: FormData) => {
		analytic.logEvent('anchoring: export: download', {
			file_format,
			report_type,
		})
		const fileName = reportName
			? reportName.replace(/[^a-z0-9]/gi, '_').toLowerCase()
			: 'anchors_report'
		downloadAssortmentFile(
			{
				//filters: filters,
				format: file_format as 'xlsx' | 'csv',
				//order_by: [],
			},
			`${fileName}_${format(new Date(), DateFormat.system)}.${file_format}`
		)
		close()
	}
	const handleSubmit = methods.handleSubmit(_handleSubmit)
	return (
		<Modal onClose={close} showCloseButton size='mini' zIndex={1850}>
			<ModalHeader>
				<ModalTitle>{intl.get('app.download_report')}</ModalTitle>
			</ModalHeader>
			<ModalBody className='flex flex-col'>
				<FormProvider methods={methods} onSubmit={handleSubmit}>
					<RHFSelect
						label={intl.get('app.report_type').d('Report type')}
						className='w-56'
						name='report_type'
						options={[
							{
								value: 'full',
								label: intl.get('app.all_columns'),
							},
						]}
					/>
					<RHFSelect
						className='w-56'
						label={intl.get('app.file_format').d('File format')}
						name='file_format'
						options={[
							{ value: 'xlsx', label: 'Excel' },
							{ value: 'csv', label: 'CSV' },
						]}
					/>
				</FormProvider>
			</ModalBody>
			<ModalFooter className='justify-between space-x-2'>
				<Button onClick={close}>{intl.get('general_cancel')}</Button>
				<Button variant='primary-brand' onClick={handleSubmit}>
					{intl.get('app.download_report')}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
export default function AnchorsExportTableModal(
	props: ExportTableModalContentProps & { isOpen: boolean }
) {
	return (
		<ModalTransition>
			{props.isOpen && <ExportTableModalContent {...props} />}
		</ModalTransition>
	)
}
