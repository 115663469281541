import React from 'react'

import { Operators } from '@cmpkit/query-builder'

import intl from '@/locale'

import {
	ControllerConfig,
	HasValueSignature,
	InitialValueSignature,
	OptionType,
	UseSelectOptionsSignature,
	ValueState,
} from '../../types'
import { isObject } from '../../utils'
import FieldController from '../Controller'

export default class SelectController extends FieldController {
	constructor(config: ControllerConfig) {
		super(config)
		this.onMenuScrollToBottom = config.onMenuScrollToBottom
		this.onMenuScrollToTop = config.onMenuScrollToTop
		this.useSelectOptions = config.useSelectOptions
		this.getInitialValue = config.getInitialValue || this.getInitialValue
		this.placeholder = config.placeholder || this.placeholder
		this.components = config.components
	}
	components?: Record<string, React.FunctionComponent<any> | any> // eslint-disable-line @typescript-eslint/no-explicit-any
	onMenuScrollToBottom?: () => void

	onMenuScrollToTop?: () => void

	placeholder?: string = intl.get('general_search').d('Search')

	useSelectOptions: () => UseSelectOptionsSignature<OptionType>

	hasValue: HasValueSignature = ({ value }) =>
		Array.isArray(value) ? value.length > 0 : isObject(value)

	getInitialValue: InitialValueSignature = () => ({
		operation: Operators.IN,
		value: [],
	})

	formatLabel = ({ value }: ValueState<string[]>) => {
		if (Array.isArray(value) && value.length > 0)
			return `${this.label} (${value.length})`
		return this.label
	}
}
