import { format } from 'date-fns'
import React from 'react'

import Chart from '@/components/Chart'
import { DateFormat } from '@/tools/dates'
import { formatNumber } from '@/tools/locale'

export function formatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	// eslint-disable-next-line
	const points = self.points?.map((p: any) => ({
		label: p.point.series.name,
		value: p.y,
		color: p.color,
	}))
	const list = points
		?.map(
			({
				label,
				value,
				color,
			}: {
				label: string
				value: number
				color: string
			}) =>
				`<div class="w-full flex justify-between items-center mb-1"><div class="flex items-center"><div class="color-dot rounded-lg mr-4" style="background: ${color};"></div><strong class="truncate">${label}</strong></div><strong>${formatNumber(
					value as number
				)}</strong></div>`
		)
		.join('\n')
	const head = `<div class="w-full flex justify-between mb-2.5"><span>${format(
		new Date(this.x as number),
		DateFormat.short
	)}</span></div>`
	return `<div class="chart-tooltip w-72">
		${head}
		${list}
	</div>`
}
const getConfig = ({
	series,
	categories,
}: {
	series: Highcharts.SeriesOptionsType[]
	categories: string[]
}): Highcharts.Options => ({
	chart: {
		height: 240,
		type: 'spline',
	},
	xAxis: {
		categories: categories,
		title: { text: '' },
		type: 'datetime',
		labels: {
			formatter: function () {
				return format(new Date(this.value), DateFormat.short)
			},
		},
	},
	yAxis: [
		{
			title: {
				text: '',
			},
		},
		{
			title: {
				text: '',
			},
			opposite: true,
		},
	],
	time: {
		timezone: undefined,
	},
	legend: {
		enabled: false,
	},
	tooltip: {
		shared: true,
		animation: false,
		outside: true,
		followPointer: true,
		useHTML: true,
		backgroundColor: 'transparent',
		borderRadius: 10,
		borderWidth: 0,
		shadow: false,
		formatter,
	},
	plotOptions: {
		series: {
			cursor: 'pointer',
			animation: false,
		},
	},
	series,
})
export default function CahrtView({
	series,
	categories,
}: {
	series: Highcharts.SeriesOptionsType[]
	categories: string[]
}) {
	return <Chart config={getConfig({ series, categories })} />
}
