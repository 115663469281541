import React from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import {
	FormError,
	FormGroup,
	FormHint,
	FormLabel,
	Required,
	TextArea,
} from '@cmpkit/base'
import HelpCircleIcon from '@cmpkit/icon/lib/glyph/help-circle'
import Tooltip from '@cmpkit/tooltip'

export default function RHFTextArea({
	name,
	label,
	hint,
	description,
	defaultValue,
	shouldUnregister,
	required,
	...other
}: UseControllerProps &
	React.HTMLAttributes<HTMLTextAreaElement> & {
		label: string
		hint?: string
		required?: boolean
		placeholder?: string
		description?: string
		rows?: number
		autoComplete?: string
		autoCorrect?: string
	}) {
	const { control } = useFormContext()
	return (
		<FormGroup>
			<FormLabel>
				{label}
				{required && <Required />}
			</FormLabel>
			<Controller
				name={name}
				defaultValue={defaultValue}
				shouldUnregister={shouldUnregister}
				control={control}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<>
						<div className='flex w-full items-center'>
							<TextArea {...other} value={value} onChange={onChange} />
							{hint && (
								<Tooltip placement='top' content={hint}>
									<HelpCircleIcon className='mx-2.5 flex items-center text-muted' />
								</Tooltip>
							)}
						</div>
						{!!description && (
							<FormHint className='items-center'>{description}</FormHint>
						)}
						{error && <FormError>{error.message}</FormError>}
					</>
				)}
			/>
		</FormGroup>
	)
}
