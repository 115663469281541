import { entries, fromPairs, map, pipe } from 'lodash/fp'

import { FilterRuleEntity } from '@cmpkit/query-builder'

import { encodeRuleValue, normilizeValue } from '@/tools/filtering'

import { FilterRuleModel } from './types'

/**
 * Transform query object to filter rules array
 * @example { a__eq: 1, b__eq: 2 } => [
 * 	{ name: 'a', operation: 'eq', value: 1 },
 * 	{ name: 'b', operation: 'eq', value: 2 }
 * ]
 * @param queryObject - object with query params to be converted to filter rules
 * @returns - array of filter rules
 */
export const toFilterRules: (queryObject: object) => FilterRuleModel[] = pipe([
	entries,
	map<[string, string | number], FilterRuleModel>(([item, value]) => {
		const [name, operation] = item.split('__')
		return {
			name,
			operation,
			value: normilizeValue(operation, value),
		} as FilterRuleModel
	}),
])

/**
 * Trim filter rule values
 * @param filters array of filter rules
 * @returns array of filter rules with trimmed values
 */
export function trimFilterRuleValues(
	filters: FilterRuleEntity[]
): FilterRuleEntity[] {
	return filters?.map((filter) => {
		if (filter.value && typeof filter.value === 'string') {
			filter.value = filter.value.trim()
		}
		if (filter.value && Array.isArray(filter.value)) {
			filter.value = filter.value.map((item) => {
				if (typeof item === 'string') {
					return item.trim()
				}
				return item
			})
		}
		return filter
	})
}

/**
 * Transform filter rules to query object
 * @example
 * ```javascript
 * [
 * 	{ name: 'a', operation: 'eq', value: 1 },
 * 	{ name: 'b', operation: 'eq', value: 2 }
 * ] => {
 * 	a__eq: 1,
 * 	b__eq: 2
 * }
 * ```
 * @param rules - array of filter rules to be converted to query object
 * @returns - object with query params
 */
export const rulesToQueryObject = (rules: FilterRuleModel[]) =>
	fromPairs(
		rules.map((rule) => [
			`${rule.name}__${rule.operation}`,
			encodeRuleValue(rule.value),
		])
	)

export function isValidRule(rule: FilterRuleModel) {
	return !!rule.name && !!rule.operation && rule.value !== undefined
}
export function isValidFilterRules(rules: FilterRuleModel[]) {
	return rules.every(isValidRule)
}
