import intl from 'react-intl-universal'

import { Badge, BadgeProps, Header, LinkButton } from '@cmpkit/base'

import { PricingCampaignModel } from '@/generated'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'

import CollapsibleSection from '../CollapsibleSection'

type PricingAlertsSolutionProps = {
	name: string
	badgeContent: {
		formattedMetrics: {
			products: string
			revenue: string
		}
		badgeVariant: BadgeProps['variant']
	}
	solution: {
		description: string
		pricingCampaigns: PricingCampaignModel[] | null
		tooltip: string
		optimizationGroupId: string
	}
	optimizationGroupId?: string
}

export const PricingAlertsSolution = ({
	name,
	badgeContent: { formattedMetrics, badgeVariant },
	solution,
}: PricingAlertsSolutionProps) => {
	const { showModal } = useModalStore()
	const { pricingCampaigns, tooltip, description, optimizationGroupId } =
		solution
	return (
		<div>
			<div className='cmp-border-bottom bg-white p-4'>
				<Header className='mb-1 font-semibold'>{intl.get(name).d(name)}</Header>
				{description && (
					<div className='mb-2 text-xs text-muted'>{intl.get(description)}</div>
				)}
				<Badge variant={badgeVariant}>
					{intl.get('alert.sku.number').d('Number of SKUs')}
					{': '}
					{formattedMetrics.products} ({intl.get('value')}
					{': '}
					{formattedMetrics.revenue})
				</Badge>
			</div>
			<div className='max-h-44 overflow-auto'>
				<div className='cmp-border-bottom space-y-2 p-4 text-xs'>
					<div className='font-semibold'>
						{intl.get('alert.solution.tooltip').d('Solution(s)')}
					</div>
					{tooltip && (
						<p
							dangerouslySetInnerHTML={{
								__html: intl.get(tooltip).d(tooltip),
							}}
						/>
					)}
				</div>
				{!!pricingCampaigns?.length && (
					<CollapsibleSection
						header={intl
							.get('alert.pricing_compaign_tooltip')
							.d('Related pricing campaigns')}
						className='cmp-border-bottom p-4 text-xs font-semibold'
					>
						<div className='space-y-2'>
							{pricingCampaigns.map((pc: PricingCampaignModel) => {
								return (
									<div>
										<LinkButton
											className='text-left'
											variant='brand'
											onClick={() => {
												analytic.logEvent(
													'settings: pricing campaigns: view: click',
													{
														location: 'alert-solution',
													}
												)
												showModal('PRICING_CAMPAIGN_MODAL', {
													optimizationGroupId,
													pricingCampaignId: pc.id,
												})
											}}
										>
											{pc.name}
										</LinkButton>
									</div>
								)
							})}
						</div>
					</CollapsibleSection>
				)}
				<div className='space-y-1 p-4 text-xs font-semibold'>
					<p>{intl.get('alert.support.title').d('Still have questions?')}</p>
					<LinkButton
						variant='brand'
						className='text-xs'
						onClick={() => showModal('CREATE_JSD_TICKET')}
					>
						{intl.get('alert.support.button').d('Contact our support team')}
					</LinkButton>
				</div>
			</div>
		</div>
	)
}
