import React from 'react'

import { Avatar, Button, Card, MenuItem, MenuList } from '@cmpkit/base'

import ChevronLeftIcon from '@cmpkit/icon/lib/glyph/chevron-left'
import ChevronRightIcon from '@cmpkit/icon/lib/glyph/chevron-right'
import HeadphonesIcon from '@cmpkit/icon/lib/glyph/headphones'
import LogOutIcon from '@cmpkit/icon/lib/glyph/log-out'
import MoonIcon from '@cmpkit/icon/lib/glyph/moon'
import SunIcon from '@cmpkit/icon/lib/glyph/sun'
import Popover from '@cmpkit/popover'
import {
	SidebarFooter,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	useSidebar,
} from '@cmpkit/sidebar'
import { ChevronsUpDownIcon } from 'lucide-react'
import { useTheme } from '@/hooks/useTheme'
import intl from '@/locale'
import { useAuth } from '@/modules/Auth/AuthContext'
import { useModalStore } from '@/modules/modals/store'

export default function AppSidebarFooter() {
	const { user } = useAuth()
	const username = [user?.first_name, user?.last_name].join(' ')
	return (
		<SidebarFooter>
			<SidebarMenu>
				<Popover
					placement='top-start'
					content={
						<Card className='w-60 border shadow'>
							<AppSidebarFooterDropdown />
						</Card>
					}
				>
					<SidebarMenuItem>
						<SidebarMenuButton
							size='lg'
							className='cursor-pointer border-none data-[state=open]:bg-accent-2 data-[state=open]:text-sidebar-accent-foreground'
						>
							<Avatar name={username} size='medium' className='rounded-md' />
							<div className='grid flex-1 text-left text-sm leading-tight'>
								<span className='truncate font-semibold'>{username}</span>
								<span className='truncate text-xs text-muted'>
									{user?.email}
								</span>
							</div>
							<ChevronsUpDownIcon className='ml-auto size-4' />
						</SidebarMenuButton>
					</SidebarMenuItem>
				</Popover>
				<SidebarTrigger />
			</SidebarMenu>
		</SidebarFooter>
	)
}
const SidebarTrigger = ({ onClick }: React.ComponentProps<typeof Button>) => {
	const { toggleSidebar, open } = useSidebar()
	return (
		<SidebarMenuItem>
			<SidebarMenuButton
				size='sm'
				onClick={(event) => {
					onClick?.(event)
					toggleSidebar()
				}}
			>
				{!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
				<span>{intl.get('app.close_sidebar').d('Close sidebar')}</span>
			</SidebarMenuButton>
		</SidebarMenuItem>
	)
}
export function AppSidebarFooterDropdown() {
	const [theme, toggleTheme] = useTheme()
	const { showModal } = useModalStore()
	const { user, logout } = useAuth()
	const username = [user?.first_name, user?.last_name].join(' ')
	return (
		<div className='overflow-hidden truncate rounded-lg'>
			<div className='flex items-center truncate bg-accent-3 px-4 py-3'>
				<Avatar name={username} className='rounded-md' />
				<div className='ml-2 truncate leading-4'>
					<div className='font-semibold'>{username}</div>
					<div className='truncate text-xs text-muted'>{user?.email}</div>
				</div>
			</div>
			<MenuList className='my-2 w-56'>
				<MenuItem onClick={() => showModal('CREATE_JSD_TICKET')}>
					<HeadphonesIcon />
					<span className='truncate'>
						{intl.get('contact_support').d('Contact support')}
					</span>
				</MenuItem>
				<MenuItem onClick={() => toggleTheme()}>
					{theme !== 'dark' ? <MoonIcon /> : <SunIcon />}
					<span>
						{theme !== 'dark'
							? intl.get('app.swith_theme_dark').d('Switch to dark mode')
							: intl.get('app.swith_theme_base').d('Switch to base mode')}
					</span>
				</MenuItem>
				<MenuItem onClick={() => logout()}>
					<LogOutIcon />
					<span>{intl.get('app.logout').d('Logout')}</span>
				</MenuItem>
			</MenuList>
		</div>
	)
}
