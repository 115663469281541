/* eslint-disable @typescript-eslint/no-explicit-any */
export class Storage {
	private prefix = ''
	private cache: Record<string, any> = {}
	constructor(prefix: string) {
		this.prefix = prefix
	}
	static create(p = 'po_') {
		return new Storage(p)
	}
	set(obj: Record<string, any>) {
		for (const k in obj) {
			if (obj.hasOwnProperty(k)) {
				const value = obj[k]
				const key = this.prefix + k
				this.cache[key] = value
				localStorage.setItem(key, JSON.stringify(value))
			}
		}
	}
	get<T = any>(payload: string[] | string): T {
		let keys = payload
		let single = false
		if (!Array.isArray(keys)) {
			keys = [payload] as string[]
			single = keys.length == 1
		}
		const result: any[] = []
		for (let i = 0; i < keys.length; i++) {
			const key = (keys[i] = this.prefix + keys[i])
			if (key.substr(0, 3) != 'xt_' && this.cache[key] !== undefined) {
				result.push(this.cache[key] as never)
			} else {
				let value = localStorage.getItem(key)
				try {
					value =
						value === undefined || value === null ? null : JSON.parse(value)
				} catch (e) {
					localStorage.removeItem(key)
					value = null
				}
				result.push((this.cache[key] = value) as never)
			}
		}
		return single ? result[0] : result
	}
	remove(payload: string[] | string) {
		let keys = payload
		if (!Array.isArray(keys)) {
			keys = [payload] as string[]
		}
		for (let i = 0; i < keys.length; i++) {
			const key = (keys[i] = this.prefix + keys[i])
			delete this.cache[key]
			localStorage.removeItem(key)
		}
		return true
	}

	clear() {
		this.cache = {}
		localStorage.clear()
	}
}
export default Storage.create()
