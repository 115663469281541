import { useMemo } from 'react'

import { treeHelpers, TreeNodeType } from '@cmpkit/base'
import Tooltip from '@cmpkit/tooltip'

import { CellProp } from '../fields'

export default function TreeField({
	value,
	choices,
}: Pick<CellProp, 'value'> & {
	choices: TreeNodeType[]
}) {
	const { names } = useMemo(() => {
		const nodes = treeHelpers.findNodes(
			(choices as TreeNodeType[]) || [],
			value
		)
		const names = nodes.map((node) => node.name)
		return {
			nodes,
			names,
		}
	}, [value, choices])
	return (
		<Tooltip
			delay={500}
			placement='left'
			content={
				<ul className='my-0'>
					{names.map((name) => {
						return <li key={name}>{name}</li>
					})}
				</ul>
			}
		>
			<span>{names.join(', ')}</span>
		</Tooltip>
	)
}
