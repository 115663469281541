import { formatDistanceToNow } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import { any, filter, map, matches, omitAll, pipe, prop, sum } from 'lodash/fp'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import {
	Button,
	Content,
	DeltaValue,
	Header,
	InlineMessage,
	Label,
	Layout,
	Loader,
	Result,
	Switch,
	TabItem,
	Tabs,
} from '@cmpkit/base'
import Drawer from '@cmpkit/drawer'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import AnalyticsGrowIcon from '@cmpkit/icon/lib/glyph/analytics-grow'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import EmptySearchIcon from '@cmpkit/icon/lib/glyph/empty-search'
import Tooltip from '@cmpkit/tooltip'

import CollapsibleSection from '@/components/CollapsibleSection'
import ErrorBoundary from '@/components/ErrorBoundary'
import { rulesToQueryObject, toFilterRules } from '@/components/filter/utils'
import LabeledValue from '@/components/LabeledValue'
import {
	FilterRuleModel,
	MetricModel,
	MetricsDataUnit,
	OptimizationStatus,
	PricingEngineType,
	StrategyDataUnit,
} from '@/generated'
import { useOptimizationStatus } from '@/hooks/data'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useMetricsQuery } from '@/modules/bi/queries'
import { useDrawer } from '@/modules/drawers/store'
import { usePricingCampaignsQuery } from '@/modules/pricing-campaigns/queries'
import { useScenariosQuery } from '@/modules/scenarios/queries'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'
import { getLocationQuery, toQueryString } from '@/tools/location'

import {
	Metric,
	MetricGroup,
	MetricGroupValue,
	MetricName,
} from '../../constants'
import {
	useInterpretabilityStatisticQuery,
	useOptimizationQuery,
	useRepricingStatisticQuery,
} from '../../queries'
import AverageValuesWidget from './AverageValuesWidget'
import ElasticityDistributionWidget from './ElasticityDistributionWidget'
import {
	formatMericValue,
	formatTotalDiffValue,
	getMetricDiffByType,
	getPrefixedMetric,
	getTranslationKey,
	getTypesByMetricSchema,
	METRIC_PREFIX,
	METRICS_WIDGET_TYPES,
	PREDICT_METRICS,
} from './helpers'
import ItemsChangedWidget from './ItemsChangedWidget'
import OptimizationMetricsWidget from './OptimizationMetricsWidget'
import PricingAlertsWidget from './PricingAlertsWidget'
import PricingLinesChangeTypeWidget from './PricingLinesChangeTypeWidget'
import ProgressWidgets from './ProgressWidgets'
import PromoWidget from './PromoWidget'

export function OptimizationSummaryTrigger({
	disabled,
}: {
	disabled: boolean
}) {
	const drawer = useDrawer('OPTIMIZATION_SUMMARY')
	return (
		<Tooltip
			placement='bottom-end'
			content={intl
				.get('opt.summary.disabled.tooltip')
				.d(
					'Optimization summary available only for "Finished" and "Applied" optimizations'
				)}
		>
			<Button
				variant='primary-brand'
				key='view'
				disabled={disabled}
				data-testid='optimization-action-view'
				onClick={() => {
					if (drawer.isOpen) {
						drawer.close()
					} else {
						drawer.open({})
					}
				}}
				iconBefore={drawer.isOpen ? <CrossIcon /> : <AnalyticsGrowIcon />}
			/>
		</Tooltip>
	)
}
export function OptimizationSummaryDrawer({
	isOpen,
	close,
}: {
	isOpen: boolean
	close: () => void
}) {
	const optimizationGroupId = useOptimizationGroupId()
	const status = useOptimizationStatus(optimizationGroupId!)
	return (
		<Drawer
			className='products-eastside'
			orient='right'
			isOpen={
				[OptimizationStatus.Finished, OptimizationStatus.Locked].includes(
					status!
				) && isOpen
			}
			disableBlanket
			disableFocusTrap
			onCloseComplete={close}
		>
			<ErrorBoundary>
				<OptimizationSummary onClose={close} />
			</ErrorBoundary>
		</Drawer>
	)
}
export function OptimizationSummary({ onClose }: { onClose: () => void }) {
	const { search } = useLocation()
	const [activeTab, setActiveTab] = useState('statistics')
	const [shouldUseQueryFilters, setShouldUseQueryFilters] = useState(false)
	const optimizationGroupId = useOptimizationGroupId()
	const filters = useMemo(
		() =>
			shouldUseQueryFilters
				? toFilterRules(omitAll(['limit', 'offset'], getLocationQuery(search)))
				: [],
		[search, shouldUseQueryFilters]
	)

	/** Queries */
	const optimizationQuery = useOptimizationQuery(optimizationGroupId!)
	useEffect(() => {
		!!optimizationQuery.data && analytic.logEvent('opt summary: view')
	}, [optimizationQuery.data])
	const mainScenarioIdQuery = useScenariosQuery(optimizationGroupId!, {
		select: (data) => data.find(({ is_main }) => is_main)?.id,
		enabled: !!optimizationGroupId,
	})
	const pricingCampaignEnginesQuery = usePricingCampaignsQuery<
		PricingEngineType[]
	>(
		{
			optimization_group_id: optimizationGroupId,
			scenario_id: mainScenarioIdQuery.data,
		},
		{
			select: (data) => data.map(prop('engine')) as PricingEngineType[],
			enabled: !!mainScenarioIdQuery.data,
		}
	)

	/** Calculated */
	const isRuleBasedGroup = useMemo(
		() => pricingCampaignEnginesQuery?.data?.every((engine) => engine === 'RB'),
		[pricingCampaignEnginesQuery.data]
	)
	const isLoading = any(prop('isLoading'), [
		optimizationQuery,
		mainScenarioIdQuery,
		pricingCampaignEnginesQuery,
	])
	if (isLoading) {
		return (
			<Layout className='flex h-full items-center justify-center overflow-y-hidden'>
				<Loader />
			</Layout>
		)
	}
	return (
		<Layout className='h-full overflow-y-hidden'>
			<Header className='flex flex-col border-b border-solid border-base bg-accent-1 pb-2 pl-5 pt-5'>
				<Button
					className='absolute right-5 top-4'
					variant='tertiary'
					iconBefore={<CrossIcon />}
					onClick={onClose}
				/>
				<h4 className='pr-14 text-lg font-semibold'>
					{intl.get('opt.summary').d('Optimization summary')}
				</h4>
				{!!optimizationQuery.data!.finished_date && (
					<span className='text-xs text-muted'>
						{intl.get('status_panel_repricing_finished')}:{' '}
						{formatDistanceToNow(
							new Date(optimizationQuery.data!.finished_date + 'Z'),
							{
								addSuffix: true,
							}
						)}
					</span>
				)}
				<TargetMericsSummary filters={filters} />
				<div className='mt-2 flex items-center'>
					<Switch
						checked={shouldUseQueryFilters}
						onChange={() => {
							analytic.logEvent('opt summary: use table filters switch', {
								value: !shouldUseQueryFilters,
							})
							setShouldUseQueryFilters(!shouldUseQueryFilters)
						}}
					/>
					<Label className='mx-2 flex items-center'>
						{intl.get('opt.summary.use_table_filters').d('Use table filter')}
					</Label>
				</div>
			</Header>
			<Tabs fit className='bg-accent-1'>
				<TabItem
					active={'statistics' === activeTab}
					onClick={() => setActiveTab('statistics')}
				>
					{intl.get('app.statistics').d('Statistics')}
				</TabItem>
				<TabItem
					active={'optimization' === activeTab}
					onClick={() => setActiveTab('optimization')}
				>
					{intl.get('app.optimization').d('Optimization')}
				</TabItem>
				<TabItem
					active={'progress' === activeTab}
					onClick={() => setActiveTab('progress')}
				>
					{intl.get('app.progress').d('Progress')}
				</TabItem>
			</Tabs>
			<Content className='relative overflow-y-auto bg-accent-1'>
				<AnimatePresence mode={'wait'}>
					{'statistics' === activeTab && (
						<TabContent key='statistics'>
							<StatisticsTab filters={filters} />
						</TabContent>
					)}
					{'optimization' === activeTab && (
						<TabContent key='optimization'>
							<OptimizationTab filters={filters} />
						</TabContent>
					)}
					{'progress' === activeTab && (
						<TabContent key='progress'>
							<ErrorBoundary>
								{isRuleBasedGroup ? (
									<TabIsNotAvailable />
								) : (
									<ProgressWidgets filters={filters} />
								)}
							</ErrorBoundary>
						</TabContent>
					)}
				</AnimatePresence>
			</Content>
		</Layout>
	)
}
function StatisticsTab({ filters }: { filters: FilterRuleModel[] }) {
	useEffect(() => {
		analytic.logEvent('opt summary: open statistics tab')
	}, [])
	const navigate = useNavigate()
	const optimizationGroupId = useOptimizationGroupId()!
	const optimizationQuery = useOptimizationQuery(optimizationGroupId)
	const repricingStatisticQuery = useRepricingStatisticQuery(
		{
			optimizations: [
				{
					optimization_id: optimizationQuery.data!.id,
					optimization_group_id: optimizationGroupId,
				},
			],
			filters,
		},
		{
			enabled: !!optimizationGroupId && !!optimizationQuery.data?.id,
			refetchOnMount: true,
			staleTime: 0,
			select: pipe(
				prop('metrics'),
				filter(
					matches({
						group_type: MetricGroup.PriceChangeStatus,
						name: MetricName.ProductCount,
					})
				)
			),
		}
	)
	const changed = formatNumber(
		sum(
			map(
				prop('value'),
				filter(
					({ group_value }) =>
						[MetricGroupValue.Increased, MetricGroupValue.Decreased].includes(
							group_value
						),
					repricingStatisticQuery.data as Metric[]
				)
			)
		) || 0
	)
	const queryParams = useMemo(
		() => ({
			optimizations: [
				{
					optimization_id: optimizationQuery.data!.id,
					optimization_group_id: optimizationGroupId,
				},
			],
			filters,
		}),
		[optimizationQuery.data, optimizationGroupId, filters]
	)
	const isLoading = any(prop('isLoading'), [
		optimizationQuery,
		repricingStatisticQuery,
	])
	const total = formatNumber(optimizationQuery.data?.total_products || 0)
	/**Handlers */
	const handleNavigate = ({
		widget,
		widgetFilter,
	}: {
		widget: string
		widgetFilter: FilterRuleModel[]
	}) => {
		analytic.logEvent(`opt summary: ${widget}: click row`, {
			filters: widgetFilter,
		})
		navigate(toQueryString(rulesToQueryObject([...filters, ...widgetFilter])))
	}
	return (
		<div className='flex w-full flex-col divide-y divide-solid divide-base bg-accent-1 pb-8'>
			<ErrorBoundary>
				<CollapsibleSection
					className='pb-4 pt-1.5'
					isOpen
					header={<h4>{intl.get('alerts')}</h4>}
				>
					<PricingAlertsWidget />
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-1.5'
					isOpen
					header={<h4>{intl.get('skus.changed').d('SKUs changed')}</h4>}
					subtitle={intl
						.get('skus.changed.subtitle', {
							changed,
							total,
						})
						.d(`${changed} of ${total} SKUs were optimized`)}
				>
					<ItemsChangedWidget filters={filters} />
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-1.5'
					isOpen
					header={
						<h4>
							{intl.get('pls.change_types').d('Pricing lines by change types')}
						</h4>
					}
				>
					<PricingLinesChangeTypeWidget
						queryParams={queryParams}
						isLoading={isLoading}
					/>
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-1.5'
					isOpen
					header={<h4>{intl.get('promo')}</h4>}
				>
					<PromoWidget
						handleNavigate={handleNavigate}
						queryParams={queryParams}
						isLoading={isLoading}
					/>
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-1.5'
					isOpen
					header={<h4>{intl.get('average_price').d('Average shelf price')}</h4>}
				>
					<AverageValuesWidget
						queryParams={queryParams}
						isLoading={isLoading}
					/>
				</CollapsibleSection>
			</ErrorBoundary>
		</div>
	)
}
function OptimizationTab({ filters }: { filters: FilterRuleModel[] }) {
	useEffect(() => {
		analytic.logEvent('opt summary: open optimization tab')
	}, [])
	const metricsQuery = useMetricsQuery<MetricModel[]>({
		select: filter(({ type }) => PREDICT_METRICS.includes(type)),
	})
	const optimizationGroupId = useOptimizationGroupId()!
	const optimizationQuery = useOptimizationQuery(optimizationGroupId)
	const interpretabilityStatistic = useInterpretabilityStatisticQuery(
		{
			optimizations: [
				{
					optimization_group_id: optimizationGroupId,
					optimization_id: optimizationQuery.data!.id,
				},
			],
			filters,
		},
		{
			enabled: !!optimizationQuery.data?.id,
			refetchOnMount: true,
			staleTime: 0,
		}
	)

	if (interpretabilityStatistic.error?.response?.status === 404) {
		return <TabIsNotAvailable />
	}
	if (interpretabilityStatistic.error?.response?.status === 500) {
		return (
			<div className='flex h-full items-center justify-center'>
				<Result
					icon={<AlertIcon width={72} height={72} />}
					title={intl.get('fatal_error_title')}
					subtitle={intl.get('fatal_error_desc')}
				/>
			</div>
		)
	}
	// When optimization is rule-based, we don't have interpretability data.
	if (
		interpretabilityStatistic.isFetched &&
		!interpretabilityStatistic.data &&
		metricsQuery.isFetched
	) {
		return <TabIsNotAvailable />
	}
	const hasCustomMetrics = !!metricsQuery.data?.filter(
		({ type }) => type === 'predict_custom'
	).length
	return (
		<div className='flex w-full flex-col divide-y divide-solid divide-base bg-accent-1 pb-8'>
			<ErrorBoundary>
				<CollapsibleSection
					className='pb-4 pt-1.5'
					isOpen
					header={<h4>{intl.get('status_panel_targeted')}</h4>}
				>
					<OptimizationMetricsWidget
						widgetType={METRICS_WIDGET_TYPES.TARGET}
						filters={filters}
						metricsSchema={metricsQuery.data!}
					/>
				</CollapsibleSection>
				<CollapsibleSection
					className='pb-4 pt-1.5'
					isOpen
					header={<h4>{intl.get('status_panel_forecast')}</h4>}
				>
					<OptimizationMetricsWidget
						widgetType={METRICS_WIDGET_TYPES.ADDITIONAL}
						filters={filters}
						metricsSchema={metricsQuery.data!}
					/>
				</CollapsibleSection>
				{hasCustomMetrics && (
					<CollapsibleSection
						className='pb-4 pt-1.5'
						isOpen
						header={
							<h4>{intl.get('status_panel_custom').d('Custom metrics')}</h4>
						}
					>
						<OptimizationMetricsWidget
							widgetType={METRICS_WIDGET_TYPES.CUSTOM}
							filters={filters}
							metricsSchema={metricsQuery.data!}
						/>
					</CollapsibleSection>
				)}
				<CollapsibleSection
					className='pb-4 pt-1.5'
					isOpen
					header={
						<h4>
							{intl
								.get('elasticity.distribution')
								.d('Distribution of elasticity')}
						</h4>
					}
					subtitle={intl
						.get('elasticity.distribution.subtitle')
						.d('Click on tab to see different additional insights.')}
				>
					<ElasticityDistributionWidget filters={filters} />
				</CollapsibleSection>
			</ErrorBoundary>
		</div>
	)
}

function TargetMericsSummary({ filters }: { filters?: FilterRuleModel[] }) {
	const optimizationGroupId = useOptimizationGroupId()!
	const optimizationQuery = useOptimizationQuery(optimizationGroupId)

	const metricsQuery = useMetricsQuery<MetricModel[]>({
		select: pipe([filter(({ type }) => type === 'predict_default')]),
	})
	const repricingStatisticQuery = useRepricingStatisticQuery<StrategyDataUnit>(
		{
			optimizations: [
				{
					optimization_id: optimizationQuery.data!.id,
					optimization_group_id: optimizationGroupId,
				},
			],
			filters: [],
		},
		{
			enabled: !!optimizationGroupId && !!optimizationQuery.data?.id,
			refetchOnMount: true,
			staleTime: 0,
			select: prop('settings.strategy'),
		}
	)
	const interpretabilityStatistic = useInterpretabilityStatisticQuery(
		{
			optimizations: [
				{
					optimization_group_id: optimizationGroupId,
					optimization_id: optimizationQuery.data!.id,
				},
			],
			filters,
		},
		{
			refetchOnMount: true,
			staleTime: 0,
			enabled:
				!!optimizationQuery.data?.id &&
				!!metricsQuery.data &&
				!!repricingStatisticQuery.data,
			select: (data) => {
				const { target, protect } = getMainMetricsNames(
					repricingStatisticQuery.data as StrategyDataUnit
				)
				const mainMetricValues = extractMetricsValues(
					data?.metrics as MetricsDataStatistic,
					{ target, protect }
				)
				return {
					target: getMetricValues({
						key: target,
						data: mainMetricValues.target,
						metricsSchema: metricsQuery.data!,
					}),
					protect: getMetricValues({
						key: protect,
						data: mainMetricValues.protect,
						metricsSchema: metricsQuery.data!,
					}),
				}
			},
		}
	)

	if (interpretabilityStatistic.isLoading) {
		return (
			<div className='mt-2 flex w-full space-x-4'>
				<div className='h-10 w-24 animate-pulse rounded-lg bg-accent-4' />
				<div className='h-10 w-20 animate-pulse rounded-lg bg-accent-4' />
			</div>
		)
	}
	if (interpretabilityStatistic.isError) {
		return (
			<InlineMessage
				icon={<AlertIcon />}
				variant='danger'
				className='mr-5 text-xs'
			>
				{interpretabilityStatistic.error.message ||
					intl.get('fatal_error_title')}
			</InlineMessage>
		)
	}
	const data = interpretabilityStatistic.data

	return (
		<div className='mt-2 flex space-x-4'>
			{data?.target && (
				<LabeledValue
					label={`${intl.get(getTranslationKey(data?.target.key))} (${intl.get('to_grow').d('to grow')})`}
				>
					<span className='font-semibold'>{data?.target.value}</span>
					<DeltaValue className='ml-1 text-xs' value={data?.target.diffValue}>
						{formatTotalDiffValue(
							data?.target.diffValue,
							data?.target.diffType
						)}
					</DeltaValue>
				</LabeledValue>
			)}
			{data?.protect && (
				<LabeledValue
					label={`${intl.get(getTranslationKey(data?.protect.key))} (${intl.get('to_maintain').d('to maintain')})`}
				>
					<span className='font-semibold'>{data?.protect.value}</span>
					<DeltaValue className='ml-1 text-xs' value={data?.protect.diffValue}>
						{formatTotalDiffValue(
							data?.protect.diffValue,
							data?.protect.diffType
						)}
					</DeltaValue>
				</LabeledValue>
			)}
		</div>
	)
}
function TabContent({ children }: { children: React.ReactNode }) {
	return (
		<motion.div
			initial={{ opacity: 0, x: 100 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: -30 }}
			className={
				'flex size-full flex-col divide-y divide-solid divide-base px-5'
			}
			transition={{
				duration: 0.2,
			}}
		>
			{children}
		</motion.div>
	)
}

function TabIsNotAvailable() {
	return (
		<div className='flex h-full items-center justify-center'>
			<Result
				icon={<EmptySearchIcon width={56} height={56} />}
				subtitle={intl
					.get('opt.summary.not_available_for_rb')
					.d('This tab is not available for rule-based groups.')}
			/>
		</div>
	)
}
const getMetricValues = ({
	key,
	data,
	metricsSchema,
}: {
	key: string
	data: MetricsDataUnit
	metricsSchema: MetricModel[]
}) => {
	const metricSchema = metricsSchema?.find(
		({ name }) => name.split('.')[0] === `${key}`
	)
	if (!metricSchema) {
		return
	}
	const { diffType, dataType } = getTypesByMetricSchema(metricSchema!)
	const diffValue = getMetricDiffByType(data, diffType)
	return {
		key,
		value: formatMericValue({
			value: data.final || 0,
			type: dataType,
		}),
		diffType,
		diffValue,
	}
}
type MetricsDataStatistic = {
	[key: string]: number
}
const extractMetricsValues = (
	data: MetricsDataStatistic,
	mainMetrics: { target: string; protect: string }
) => {
	const { target, protect } = mainMetrics
	return {
		target: {
			init: data?.[`${target}.init`],
			final: data?.[`${target}.final`],
			optimal: data?.[`${target}.optimal`],
		},
		protect: {
			init: data?.[`${protect}.init`],
			final: data?.[`${protect}.final`],
			optimal: data?.[`${protect}.optimal`],
		},
	}
}
const getMainMetricsNames = (statistics: StrategyDataUnit) => ({
	target: getPrefixedMetric({
		metric: statistics?.target as string,
		prefix: METRIC_PREFIX,
	}),
	protect: getPrefixedMetric({
		metric: statistics?.protect as string,
		prefix: METRIC_PREFIX,
	}),
})
