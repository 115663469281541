import { ReactNode, useContext, useEffect } from 'react'

import { PageNavigatorContext } from './PageNavigator'

export default function PageNavigatorRegister({
	children,
	id,
}: {
	children: ReactNode
	id?: string
}) {
	const { register, unregister } = useContext(PageNavigatorContext)
	useEffect(() => {
		register(children)
		return () => {
			unregister()
		}
	}, [id, children])
	return null
}
