import React from 'react'
import toast from 'react-hot-toast'

import LabeledValue from '@/components/LabeledValue'
import intl from '@/locale'
import { formatNumber } from '@/tools/locale'

import Container from '../Container'

const handleCopyToClipboard = async (text: string) => {
	if (!navigator.clipboard) {
		return
	}
	try {
		await navigator.clipboard.writeText(text)
		toast.success(intl.get('general_successful_copied'))
	} catch (err) {
		//eslint-disable-next-line
		console.error('Failed to copy.', err)
		toast.error(intl.get('general_fail_copy'))
	}
}

type AnchorProps = {
	anchor_id: string
	anchor_pi: number
}
export default function Anchor({ anchor_id, anchor_pi }: AnchorProps) {
	const sku = anchor_id
	const pi = anchor_pi
	return (
		<Container className='flex items-center space-x-10'>
			<LabeledValue label={intl.get('general_anchor_sku')}>
				<span
					onClick={() => handleCopyToClipboard(sku)}
					className='cursor-pointer truncate text-xs font-semibold'
				>
					{sku}
				</span>
			</LabeledValue>
			<LabeledValue label={intl.get('general_price_index')}>
				<span className='text-xs font-semibold'>{formatNumber(pi)}</span>
			</LabeledValue>
		</Container>
	)
}
