import { ReactNode, useMemo } from 'react'

import {
	FieldConfigType,
	FieldKeys,
	Meta,
	ValueState,
	ValuesType,
} from '../types'
import { diffArr, objectMap } from '../utils'

export type CommonProps = {
	/** The configuration object for each field that may be rendered in the refinement bar. */
	fieldConfig: FieldConfigType
	/** Which fields, if any, may not be removed from the refinement-bar by the user. */
	irremovableKeys: FieldKeys
	/** Which fields, if any, should be selected by default. */
	initialKeys: FieldKeys
	/** Handle what happens when one of the field's values changes. */
	onChange: (value: Record<string, ValueState>, meta: Meta) => void
	/** The current value of each field in the refinement bar. */
	value: ValuesType
}
export type ProviderContext = CommonProps & {
	fieldKeys: FieldKeys
	removeableKeys: FieldKeys
	selectedKeys: FieldKeys
	valueKeys: FieldKeys
}
type ProviderProps = CommonProps & {
	children?: ReactNode
}
export function useRefinementBar({
	fieldConfig: initFields,
	value,
	initialKeys = [],
	irremovableKeys = [],
	onChange,
}: ProviderProps): ProviderContext {
	const fieldConfig = useMemo(
		() =>
			objectMap(initFields, (field, key) => {
				const Controller = field.type.controller
				return new Controller({ key, ...field })
			}),
		[initFields]
	)
	return useMemo(() => {
		const fieldKeys: FieldKeys = Object.keys(fieldConfig)
		const valueKeys: FieldKeys = Object.keys(value)
		const removeableKeys: FieldKeys = diffArr(fieldKeys, irremovableKeys)
		const selectedKeys: FieldKeys = initialKeys //diffArr(valueKeys, irremovableKeys)
		return {
			fieldConfig,
			fieldKeys,
			irremovableKeys,
			removeableKeys,
			selectedKeys,
			value,
			initialKeys,
			onChange,
			valueKeys,
		}
	}, [fieldConfig, value, irremovableKeys, initialKeys])
}
