import { CheckIcon, PuzzleIcon, RotateCcwIcon } from 'lucide-react'
import { Link } from 'react-router'

import {
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
	Button,
	Content,
	Header,
	Layout,
	Loader,
	Separator,
} from '@cmpkit/base'
import { SidebarProvider } from '@cmpkit/sidebar'
import Tooltip from '@cmpkit/tooltip'

import { FormProvider } from '@/components/HookForm'
import { SettingsTemplateModel, SettingsTemplateType } from '@/generated'
import intl from '@/locale'
import analytic from '@/services/analytics'

import {
	ScenarioBusinessConstraintsTemplateModel,
	ScenarioStopListTemplateModel,
	ScenarioStrategiesTemplateModel,
} from '../../types'
import DragOverlayWrapper from './DragOverlayWrapper'
import TemplateBuilderCenterPanel from './TemplateBuilderCenterPanel'
import { useTemplateBuilderContext } from './TemplateBuilderProvider'
import TemplatesBuilderLeftPanel from './TemplatesBuilderLeftPanel'

export default function TemplateBuilder() {
	const {
		setActiveType,
		methods,
		handleSubmit,
		handleReset,
		isPending,
		isLoading,
	} = useTemplateBuilderContext()

	const addSection = (
		path:
			| 'settings.strategy'
			| 'settings.business'
			| 'settings.stop_list'
			| 'pricing_campaigns',
		data: any // eslint-disable-line
	) => {
		// eslint-disable-next-line
		methods.setValue(`body.${path}` as any, data, {
			shouldDirty: true,
		})
	}
	const applyDraggable = (item: SettingsTemplateModel) => {
		analytic.logEvent('builder: drop', {
			template_type: item.template_type,
		})
		if (item.template_type === 'scenario_strategy') {
			addSection(
				'settings.strategy',
				(item as ScenarioStrategiesTemplateModel).body.strategy
			)
			setActiveType(null)
		} else if (item.template_type === 'scenario_business_constraints') {
			addSection(
				'settings.business',
				(item as ScenarioBusinessConstraintsTemplateModel).body.business
			)
			setActiveType(null)
		} else if (item.template_type === 'scenario_stop_list') {
			addSection(
				'settings.stop_list',
				(item as ScenarioStopListTemplateModel).body.stop_list
			)
			setActiveType(null)
		} else if (item.template_type === 'pricing_campaign') {
			addSection(
				'pricing_campaigns',
				[
					...(methods.watch('body.pricing_campaigns') || []),
					{
						...item.body,
					},
				].map((pc, index) => ({
					...pc,
					order: index + 1,
				}))
			)
		}
	}
	const handleDragEnd = (event: DragEndEvent) => {
		if (!event.active || !event.over) return

		if (
			event.active.data.current?.block?.template_type ===
				SettingsTemplateType.PricingCampaign &&
			event.over.data.current?.isPricingCampaign
		) {
			const newPricingCampaigns = [
				...(methods.watch('body.pricing_campaigns') || []),
			]
			newPricingCampaigns.splice(
				event.over.data.current.index + 1,
				0,
				event.active.data.current.block.body
			)

			addSection(
				'pricing_campaigns',
				newPricingCampaigns.map((pc, index) => ({
					...pc,
					order: index + 1,
				}))
			)
		}

		const block = event.active.data.current?.block
		if (
			block &&
			event.over.data?.current?.accepts &&
			event.over.data.current.accepts.includes(block.template_type)
		) {
			applyDraggable(block)
		}
	}
	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 10, // 10px
			},
		}),
		useSensor(TouchSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)

	return (
		<SidebarProvider
			className='!min-h-full items-start'
			style={
				{
					'--cmp-sidebar-width': '400px',
				} as any // eslint-disable-line
			}
		>
			<FormProvider
				methods={methods}
				onSubmit={handleSubmit}
				className='flex size-full w-full flex-col overflow-hidden'
			>
				<DndContext sensors={sensors} onDragEnd={handleDragEnd}>
					<Layout data-testid='builder-layout' className='overflow-hidden'>
						<Header className='h-12 items-center border-b px-4 py-2'>
							<PuzzleIcon className='size-4' />
							<Separator orientation='vertical' className='mx-2 h-5' />
							<Breadcrumb className='text-xs'>
								<BreadcrumbList>
									<BreadcrumbItem>
										<BreadcrumbLink asChild>
											<Link to='..'>
												{intl
													.get(`template.type.${SettingsTemplateType.Scenario}`)
													.d(SettingsTemplateType.Scenario)}
											</Link>
										</BreadcrumbLink>
									</BreadcrumbItem>
									<BreadcrumbSeparator />
									<BreadcrumbItem>
										<BreadcrumbPage>
											{methods.watch('name') || intl.get('new').d('New')}
										</BreadcrumbPage>
									</BreadcrumbItem>
								</BreadcrumbList>
							</Breadcrumb>
							<div className='ml-auto flex items-center gap-2'>
								{methods.formState.isDirty && (
									<Tooltip content={intl.get('app.revert_changes')}>
										<Button
											data-testid='builder-revert'
											type='button'
											onClick={handleReset}
											iconBefore={<RotateCcwIcon />}
											variant='tertiary'
										/>
									</Tooltip>
								)}
								<Button
									data-testid='builder-save'
									onClick={handleSubmit}
									iconBefore={<CheckIcon />}
									disabled={!methods.formState.isDirty || isPending}
									variant='primary-brand'
								>
									{intl.get('general_save').d('Save')}
								</Button>
							</div>
						</Header>
						<Content className='overflow-hidden'>
							<div className='bg-default-background flex size-full flex-col items-start overflow-hidden'>
								<div className='mobile:flex-col mobile:flex-wrap mobile:gap-0 flex size-full w-full shrink-0 grow basis-0 flex-wrap items-start'>
									{isLoading ? (
										<div className='flex size-full items-center justify-center'>
											<Loader />
										</div>
									) : (
										<>
											<TemplatesBuilderLeftPanel />
											<TemplateBuilderCenterPanel />
										</>
									)}
								</div>
							</div>
						</Content>
					</Layout>
					<DragOverlayWrapper />
				</DndContext>
			</FormProvider>
		</SidebarProvider>
	)
}
