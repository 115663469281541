import { AxiosError } from 'axios'
import React, { createContext, useContext, useMemo } from 'react'

import {
	QueryObserverResult,
	RefetchOptions,
	UseMutateAsyncFunction,
	useQueryClient,
} from '@tanstack/react-query'

import SplashScreen from '@/components/SplashScreen'
import { HTTPValidationError, UserModel } from '@/generated'
import analytic from '@/services/analytics'

import { useSignOutMutation } from './mutations'
import { useMeQuery } from './queries'
import { TokenStorage } from './token-storage'

export interface AuthContextValue {
	user: UserModel | undefined
	isLoading: boolean
	logout: UseMutateAsyncFunction<void, AxiosError<HTTPValidationError>>
	isLoggingOut: boolean
	refetchUser: (
		options?: RefetchOptions | undefined
	) => Promise<QueryObserverResult<UserModel, Error>>
	error: Error | null
}

export interface AuthProviderProps {
	children: React.ReactNode
}

const AuthContext = createContext<AuthContextValue | null>(null)

AuthContext.displayName = 'AuthContext'

export function AuthProvider({ children }: AuthProviderProps) {
	const queryClient = useQueryClient()
	const {
		data: user,
		error,
		status,
		isLoading,
		isError,
		isSuccess,
		refetch,
	} = useMeQuery()
	const logoutMutation = useSignOutMutation({
		onMutate() {
			analytic.logEvent('browse: logout')
		},
		onSettled: () => {
			TokenStorage.clear()
			queryClient.clear()
		},
	})

	const value = useMemo(
		() => ({
			user,
			error,
			isLoading,
			refetchUser: refetch,
			logout: logoutMutation.mutateAsync,
			isLoggingOut: logoutMutation.isPending,
		}),
		[
			user,
			error,
			refetch,
			isLoading,
			logoutMutation.mutateAsync,
			logoutMutation.isPending,
		]
	)
	if (isSuccess || isError) {
		return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	}

	if (isLoading) {
		return <SplashScreen />
	}

	return <div>Unhandled status: {status}</div>
}

export function useAuth() {
	const context = useContext(AuthContext)
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider')
	}
	return context
}
