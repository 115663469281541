import { AxiosError } from 'axios'
import { any, filter, flatten, map, pipe, prop, propOr, uniq } from 'lodash/fp'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import { PricingCampaignModel } from '@/generated'
import intl from '@/locale'
import { useCheckOptimizationForDeleteExport } from '@/modules/core/hooks/useCheckOptimizationForDeleteExport'
import { useModalStore } from '@/modules/modals/store'
import { client } from '@/network/client'
import analytic from '@/services/analytics'
import Sentry from '@/services/sentry'
import { actSaved, errorMessage, successActionMessage } from '@/tools/message'

import {
	getGlobalPricingCampaigns,
	getLocaPricingCampaigns,
	orderCampaigns,
} from '../../helpers'
import { useReorderPricingCampaignsMutation } from '../../mutations'
import { usePricingCampaignsQuery } from '../../queries'

type OrderingFormData = {
	global: PricingCampaignModel[]
	local: PricingCampaignModel[]
}
export function usePricingCampaignOrdering({
	optimizationGroupId,
	scenarioId,
}: {
	optimizationGroupId?: string | null
	scenarioId?: string | null
}) {
	const { showModal } = useModalStore()
	const queryClient = useQueryClient()
	const [isAffectedOgsLoading, setIsAffectedOgsLoading] = useState(false)
	const isGlobal = !optimizationGroupId
	const queryParams = isGlobal
		? { only_global: true }
		: scenarioId
			? {
					scenario_id: scenarioId,
					optimization_group_id: optimizationGroupId,
				}
			: { optimization_group_id: optimizationGroupId }

	const checkExport = useCheckOptimizationForDeleteExport(optimizationGroupId)
	const pricingCampaignsQuery = usePricingCampaignsQuery(queryParams)

	const reorderMutation = useReorderPricingCampaignsMutation({
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			}),
	})

	const isProcessing =
		any(prop('isPending'), [reorderMutation]) || isAffectedOgsLoading
	const isLoading = any(prop('isLoading'), [pricingCampaignsQuery])

	const formData = useMemo(() => {
		const data = pricingCampaignsQuery.data || []

		return {
			global: orderCampaigns(getGlobalPricingCampaigns(data)),
			local: orderCampaigns(getLocaPricingCampaigns(data)),
		}
	}, [pricingCampaignsQuery.data])

	const methods = useForm<OrderingFormData>({
		defaultValues: formData ?? undefined,
	})
	useEffect(() => {
		methods.reset(formData)
	}, [formData])

	const confirmChanges = (optimizationGroupsIds: string[]) =>
		new Promise((resolve) =>
			showModal('AFFECTED_OPTIMIZATION_GROUPS_MODAL', {
				title: intl.get('global.pc.affect.modal.title'),
				subtitle: intl.get('global.pc.affect.modal.subtitle'),
				optimizationGroupsIds,
				onConfirm: () => resolve(true),
				onCancel: () => resolve(false),
			})
		)
	const _handleLocalOrdering = async ({ local }: OrderingFormData) => {
		analytic.logEvent('pc reorder: priority save', {
			pcs_type: 'local',
		})
		try {
			await reorderMutation.mutateAsync({
				pricing_campaigns: local.map(({ id, order }) => ({ id, order })),
			})
			toast.success(
				successActionMessage(intl.get('general_priority'), actSaved()),
				{
					duration: 3000,
				}
			)
		} catch (error) {
			//console.log('🚀 ~ const_handleLocalOrdering= ~ error:', { error })
			Sentry.captureException(error)
			if (error instanceof AxiosError) {
				toast.error(
					`${intl.get('toast.entity.failed', {
						entity: intl.get('entity.pricing_campaign'),
					})} "${error.response?.data.detail}"`,
					{
						duration: 5000,
					}
				)
			} else {
				toast.error(errorMessage(), {
					duration: 3000,
				})
			}
		}
	}

	const _handleGlobalOrdering = async ({ global }: OrderingFormData) => {
		analytic.logEvent('pc reorder: priority save', {
			pcs_type: 'global',
		})
		try {
			setIsAffectedOgsLoading(true)
			const ogIds = await getAffectedOptimizationGroups(global)
			setIsAffectedOgsLoading(false)
			if (ogIds.length > 0) {
				const confirmed = await confirmChanges(ogIds)
				if (!confirmed) return
			}
			await reorderMutation.mutateAsync({
				pricing_campaigns: global.map(({ id, order }) => ({ id, order })),
			})
			toast.success(successActionMessage('Ordering', actSaved()), {
				duration: 3000,
			})
		} catch (error) {
			toast.error(errorMessage(), {
				duration: 3000,
			})
		}
	}
	const handleSubmit = isGlobal
		? methods.handleSubmit(_handleGlobalOrdering)
		: methods.handleSubmit(checkExport(_handleLocalOrdering))
	return {
		methods,
		handleSubmit,
		isProcessing,
		isLoading,
	}
}

const uniqFlat = pipe([flatten, filter(Boolean), uniq])
const getAffectedOptimizationGroups = async (
	ordering: PricingCampaignModel[]
) => {
	const filters = ordering
		.map(({ settings: { product_assignments } }) => product_assignments)
		.flat()
		.map(propOr([], 'filters'))

	const ids = await Promise.all(
		filters.map((filters) =>
			client.util
				.getProductsCountsByOg({
					filters,
				})
				.then(map(prop('optimization_group_id')))
		)
	)
	return uniqFlat(ids) || []
}
