import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
	HTTPValidationError,
	ScenarioCreateDataModel,
	ScenarioModel,
	ScenarioRunRequestModel,
	ScenarioRunRestartRequestModel,
	ScenarioRunSetModel,
	ScenarioSetMainDataModel,
	ScenarioUpdateDataModel,
} from '@/generated'
import { client } from '@/network/client'

/**
 * Hook for create a new scenario in optimization group
 * @param options - Mutate options
 * @returns
 */
export function useCreateScenarioMutation(
	options?: UseMutationOptions<
		ScenarioModel,
		AxiosError<HTTPValidationError>,
		ScenarioCreateDataModel
	>
) {
	return useMutation<
		ScenarioModel,
		AxiosError<HTTPValidationError>,
		ScenarioCreateDataModel
	>({
		mutationFn: (payload) => client.scenario.scenarioCreate(payload),
		...(options || []),
	})
}

/**
 * Hook for update a scenario in optimization group
 * @param scenarioId - Scenario id
 * @param options - Mutate options
 * @returns - Mutate function
 */
export function useUpdateScenarioMutation(
	scenarioId: string,
	options?: UseMutationOptions<
		ScenarioModel,
		AxiosError<HTTPValidationError>,
		ScenarioUpdateDataModel
	>
) {
	return useMutation<
		ScenarioModel,
		AxiosError<HTTPValidationError>,
		ScenarioUpdateDataModel
	>({
		mutationFn: (payload) =>
			client.scenario.scenarioUpdate(scenarioId, payload),
		...(options || []),
	})
}

/**
 * Hook for delete a scenario in optimization group
 * @param options - Mutate options
 * @returns - Mutate function
 */
export function useDeleteScenarioMutation(
	options?: UseMutationOptions<void, AxiosError<HTTPValidationError>, string[]>
) {
	return useMutation<void, AxiosError<HTTPValidationError>, string[]>({
		mutationFn: (payload) => client.scenario.deleteScenariosBulk(payload),
		...(options || []),
	})
}

/**
 * Hook for duplicate a scenario in optimization group
 * @param options - Mutate options
 * @returns - Mutate function
 */
export function useDuplicateScenarioMutation(
	options?: UseMutationOptions<
		ScenarioModel,
		AxiosError<HTTPValidationError>,
		string
	>
) {
	return useMutation<ScenarioModel, AxiosError<HTTPValidationError>, string>({
		mutationFn: (payload) => client.scenario.scenarioDuplicate(payload),
		...(options || []),
	})
}
export function useRestartScenariosMutation(
	options?: UseMutationOptions<
		ScenarioRunSetModel,
		AxiosError<HTTPValidationError>,
		ScenarioRunRestartRequestModel
	>
) {
	return useMutation<
		ScenarioRunSetModel,
		AxiosError<HTTPValidationError>,
		ScenarioRunRestartRequestModel
	>({
		mutationFn: (payload) =>
			client.repricingScenarioRuns.restartScenarioRuns(payload),
		...(options || []),
	})
}
export function useRunScenariosMutation(
	options?: UseMutationOptions<
		ScenarioRunSetModel,
		AxiosError<HTTPValidationError>,
		ScenarioRunRequestModel
	>
) {
	return useMutation<
		ScenarioRunSetModel,
		AxiosError<HTTPValidationError>,
		ScenarioRunRequestModel
	>({
		mutationFn: (payload) =>
			client.repricingScenarioRuns.startScenarioRuns(payload),
		...(options || []),
	})
}

export function useSetMainScenarioMutation(
	options?: UseMutationOptions<
		ScenarioModel,
		AxiosError<HTTPValidationError>,
		{
			scenario_id: string
			payload: ScenarioSetMainDataModel
		}
	>
) {
	return useMutation<
		ScenarioModel,
		AxiosError<HTTPValidationError>,
		{
			scenario_id: string
			payload: ScenarioSetMainDataModel
		}
	>({
		mutationFn: ({ scenario_id, payload }) =>
			client.scenario.scenarioSetMain(scenario_id, payload),
		...(options || []),
	})
}

/**
 * Hook for duplicate a scenario in optimization group
 * @param options - Mutate options
 * @payload - string[]
 * @returns - Mutate function
 */
export function useDeleteScenarioRunsMutation(
	options?: UseMutationOptions<void, AxiosError<HTTPValidationError>, string[]>
) {
	return useMutation<void, AxiosError<HTTPValidationError>, string[]>({
		mutationFn: (payload) =>
			client.repricingScenarioRuns.deleteScenarioRuns(payload),
		...(options || []),
	})
}
