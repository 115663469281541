import clsx from 'clsx'

import { Card, CardContent, CardHeader, CardTitle, Spinner } from '@cmpkit/base'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'

import { BusinessSettingsModel } from '@/generated'
import intl from '@/locale'
import { JSONSchema } from '@/tools/json-schema-utils'

import { useOptimizationGroupSchemaQuery } from '../hooks/useOptimizationGroupSchema'

export function BusinessSettingsCard({
	data,
	onClick,
}: {
	data?: BusinessSettingsModel
	onClick: () => void
}) {
	const schemaQuery = useOptimizationGroupSchemaQuery()
	const isLoading = schemaQuery.isLoading
	return (
		<Card
			className='fade-in group/card relative col-span-2 h-full cursor-pointer border hover:border-brand'
			onClick={onClick}
		>
			<CardHeader>
				<CardTitle>
					{intl.get('og.settings.business'.d('Business constrains'))}
				</CardTitle>
			</CardHeader>
			<CardContent>
				{isLoading ? (
					<Spinner />
				) : (
					<ul>
						{Object.entries(
							(schemaQuery.data?.properties.business.properties ||
								{}) as Record<string, JSONSchema>
						).map(([settingName, { title, measure }]) => {
							const settingValue = data?.[settingName]
							return (
								<li
									key={settingName}
									className={clsx('flex items-center gap-2', {
										'text-disabled': !settingValue,
									})}
								>
									{settingValue ? (
										<CheckIcon className='shrink-0' />
									) : (
										<CrossIcon className='shrink-0' />
									)}{' '}
									{title}
									{!!settingValue && Number.isFinite(settingValue)
										? ` ${settingValue} `
										: ''}
									{!!measure && !!settingValue ? intl.get(measure) : ''}
								</li>
							)
						})}
					</ul>
				)}
			</CardContent>
		</Card>
	)
}
