import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretUpIcon from '@cmpkit/icon/lib/glyph/caret-up'
import Tooltip from '@cmpkit/tooltip'
import React from 'react'
import { MHeader, TableInstance } from './types'

export default function ColumnSortLabel<T>({
	header,
	table,
}: {
	header: MHeader<T>
	table: TableInstance<T>
}) {
	const { column } = header
	const {
		options: { localization },
	} = table
	const isSorted = !!column.getIsSorted()

	const sortTooltip = column.getIsSorted()
		? column.getIsSorted() === 'desc'
			? localization.sortedByColumnDesc
			: localization.sortedByColumnAsc
		: column.getNextSortingOrder() === 'desc'
			? localization.sortedByColumnDesc
			: localization.sortedByColumnAsc

	const direction = isSorted
		? (column.getIsSorted() as 'asc' | 'desc')
		: undefined
	if (direction === undefined) {
		return null
	}
	return (
		<Tooltip content={sortTooltip}>
			{direction === 'asc' ? (
				<CaretUpIcon className='shrink-0' />
			) : (
				<CaretDownIcon className='shrink-0' />
			)}
		</Tooltip>
	)
}
