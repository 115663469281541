import toast from 'react-hot-toast'

import {
	Button,
	Card,
	MenuDivider,
	MenuHeader,
	MenuItem,
	MenuList,
} from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import ShuffleIcon from '@cmpkit/icon/lib/glyph/shuffle'
import XCircleIcon from '@cmpkit/icon/lib/glyph/x-circle'
import Popover from '@cmpkit/popover'

import { FilterRuleModel, PricingCampaignModel } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'
import { truncate } from '@/tools/utils'

import { getPricingCampaignEngine, isGlobalPricingCampaign } from '../helpers'
import { usePricingCampaignsQuery } from '../queries'
import EngineBadge from './EngineBadge'
import GlobalPcBadge from './GlobalPcBadge'

const firstCharSort = (a: PricingCampaignModel, b: PricingCampaignModel) => {
	const nameA = (a?.name || '').toLowerCase().charAt(0),
		nameB = (b?.name || '').toLowerCase().charAt(0)
	if (nameA < nameB) return -1
	if (nameA > nameB) return 1
	return 0
}

interface AssignToPricingCampaignDropdownProps {
	/**
	 * Handle when select campaign or trye to unassign
	 * @param campaign PricingCampaignModel | string
	 */
	onSelectCampaign(campaign: PricingCampaignModel | string): void

	/**
	 * Handle when create new campaign form submit
	 * @param temp - temporary campaign data
	 */
	onCreateCampaign(temp: {
		optimizationGroupId?: string | null
		scenarioId?: string
		draft?: {
			name?: string
			engine?: string
			assignment_filters?: FilterRuleModel[]
		}
	}): void

	/**
	 * Scope for query
	 */
	scope: {
		only_global?: boolean
		optimization_group_id?: string
	}

	/**
	 * Disable actions
	 */
	isActionsDisabled?: boolean
}
export default function AssignToPricingCampaignDropdown({
	onSelectCampaign,
	onCreateCampaign,
	scope,
	isActionsDisabled,
}: AssignToPricingCampaignDropdownProps) {
	const { showModal } = useModalStore()
	const optimizationGroupId = useOptimizationGroupId()
	const { data: campaigns } = usePricingCampaignsQuery(scope, {
		placeholderData: [],
		select: (campaigns) =>
			campaigns
				.filter((campaign) =>
					!!campaign.id && !scope?.only_global
						? !!campaign.optimization_group_id
						: true
				)
				.sort(firstCharSort),
	})

	const deniedAssignToPc = () => {
		return toast(intl.get('products_select_disabled'), {
			icon: <AlertIcon className='fill-warn' />,
		})
	}
	const handleCreatePricingCampagnAndAssignListTo = () => {
		analytic.logEvent('settings: pricing campaigns: create pc: click', {
			location: 'products-table',
		})
		showModal('CREATE_PRICING_CAMPAIGN_MODAL', {
			optimizationGroupId,
			disableOptimizationGroups: true,
			onCreateCampaign,
		})
	}

	const content = (
		<Card className='w-80 border py-2 shadow'>
			<MenuList>
				<MenuHeader>{intl.get('assing_selected_list_to')}</MenuHeader>
				{campaigns?.length ? (
					<div className='max-h-96 overflow-y-auto'>
						{campaigns.map((campaign) => {
							return (
								<MenuItem
									key={campaign.id}
									onClick={
										isActionsDisabled
											? deniedAssignToPc
											: () => onSelectCampaign(campaign)
									}
								>
									{truncate(campaign.name, 20)}
									<div className='inline-flex items-center space-x-1'>
										<EngineBadge
											className='ml-2'
											engine={getPricingCampaignEngine(campaign)}
										>
											{getPricingCampaignEngine(campaign)}
										</EngineBadge>
										{isGlobalPricingCampaign(campaign) && <GlobalPcBadge />}
									</div>
								</MenuItem>
							)
						})}
					</div>
				) : (
					<p className='p-5 text-center'>
						{intl.get('pricing_campaigns_list_is_empty')}
					</p>
				)}

				<MenuDivider />
				<MenuItem
					onClick={
						isActionsDisabled
							? deniedAssignToPc
							: handleCreatePricingCampagnAndAssignListTo
					}
				>
					<PlusIcon />
					<span>{intl.get('pc.assign_products.create_new')}</span>
				</MenuItem>
				<MenuDivider />
				<MenuItem
					onClick={
						isActionsDisabled ? deniedAssignToPc : () => onSelectCampaign('')
					}
				>
					<XCircleIcon />
					<span>{intl.get('pc.unassign_products')}</span>
				</MenuItem>
			</MenuList>
		</Card>
	)
	return (
		<Popover content={content} placement='bottom-start'>
			<Button size='small' iconBefore={<ShuffleIcon />}>
				{intl.get('app.assign').d('Assign')}
			</Button>
		</Popover>
	)
}
