import React from 'react'

import { RowData } from '@tanstack/react-table'

import { TableHead, TableHeader, TableRow } from '@cmpkit/base'

import MasterTableHeaderCell from './MasterTableHeaderCell'
import { TableInstance } from './types'
import { getHeaderPinningStyles, getHeaders } from './utils/utils'

export default function MasterTableHeader<T extends RowData>({
	table,
	layoutMode,
}: {
	table: TableInstance<T>
	layoutMode?: string
}) {
	const headers = getHeaders(table)

	return (
		<TableHeader>
			{headers.map((headers, i) => {
				return (
					<TableRow key={i}>
						{headers.map(({ header, isPinned, left, right }) => {
							return (
								<TableHead
									data-testid='mt-header-cell'
									data-columnid={header.id}
									colSpan={header.colSpan}
									key={`${header.id}`}
									className='h-9 px-2'
									style={getHeaderPinningStyles(header, {
										isPinned,
										left,
										right,
										layoutMode,
									})}
								>
									<MasterTableHeaderCell table={table} header={header} />
								</TableHead>
							)
						})}
					</TableRow>
				)
			})}
		</TableHeader>
	)
}
