import { AxiosError } from 'axios'

import { useQuery } from '@tanstack/react-query'

import {
	AccessTokenModel,
	AuthAuthAuthGetParams,
	HTTPValidationError,
	UserModel,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'

// eslint-disable-next-line
export const useOauthExchange = (data: any) => {
	return useQuery({
		queryKey: ['oauth2'],
		queryFn: () => {
			return client.instance.post<
				{ redirect_uri: string },
				{ redirect_uri: string }
			>('/oauth2/auth', data, {
				withCredentials: true,
			})
		},
		retry: false,
	})
}
export const useMeQuery = <T = UserModel>(
	options?: UseQueryOptions<UserModel, Error, T>
) => {
	return useQuery<UserModel, Error, T>({
		queryKey: ['me'],
		queryFn: () => client.users.getCurrentUser(),
		retry: false,
		...(options || {}),
	})
}
export const useExchangeCode = <T = AccessTokenModel>(
	query: AuthAuthAuthGetParams,
	options?: UseQueryOptions<
		AccessTokenModel,
		AxiosError<HTTPValidationError>,
		T
	>
) => {
	return useQuery<AccessTokenModel, AxiosError<HTTPValidationError>, T>({
		queryKey: ['exchange'],
		queryFn: () => client.auth.authAuthAuthGet(query),
		retry: false,
		...(options || {}),
	})
}
