import React, { useEffect, useMemo, useState } from 'react'

import type registry from './registry'

export type Registry = typeof registry
export type ModalType = keyof Registry
export type ModalProps<K extends ModalType> = Omit<
	React.ComponentProps<Registry[K]>,
	'isOpen' | 'close'
>
export type ModalEntity = {
	id: ModalType
	props?: ModalProps<ModalType>
	visible: boolean
}

interface ModalsManagerState {
	hideModal(modalType: ModalType): void
	showModal<K extends ModalType>(modalType: K, modalProps?: ModalProps<K>): void
}

const DRAWERS_LIMIT = 20

export enum ActionType {
	SHOW_MODAL,
	UPDATE_MODAL,
	UPSERT_MODAL,
	DISMISS_MODAL,
	REMOVE_MODAL,
}

type Action =
	| {
			type: ActionType.SHOW_MODAL
			modal: ModalEntity
	  }
	| {
			type: ActionType.UPSERT_MODAL
			modal: ModalEntity
	  }
	| {
			type: ActionType.UPDATE_MODAL
			modal: Partial<ModalEntity>
	  }
	| {
			type: ActionType.DISMISS_MODAL
			modalId?: ModalType
	  }
	| {
			type: ActionType.REMOVE_MODAL
			modalId?: ModalType
	  }

interface State {
	modals: ModalEntity[]
}
const modalTimeouts = new Map<
	ModalEntity['id'],
	ReturnType<typeof setTimeout>
>()
const addToRemoveQueue = (modalId: ModalType) => {
	if (modalTimeouts.has(modalId)) {
		return
	}

	const timeout = setTimeout(() => {
		modalTimeouts.delete(modalId)
		dispatch({
			type: ActionType.REMOVE_MODAL,
			modalId,
		})
	}, 300)

	modalTimeouts.set(modalId, timeout)
}

const clearFromRemoveQueue = (modalId: ModalType) => {
	const timeout = modalTimeouts.get(modalId)
	if (timeout) {
		clearTimeout(timeout)
	}
}

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case ActionType.SHOW_MODAL:
			return {
				...state,
				modals: [action.modal, ...state.modals].slice(0, DRAWERS_LIMIT),
			}

		case ActionType.UPDATE_MODAL: {
			if (action.modal.id) {
				clearFromRemoveQueue(action.modal.id)
			}
			return {
				...state,
				modals: state.modals.map((t) =>
					t.id === action.modal.id ? { ...t, ...action.modal } : t
				),
			}
		}

		case ActionType.UPSERT_MODAL: {
			const { modal } = action
			return state.modals.find((t) => t.id === modal.id)
				? reducer(state, { type: ActionType.UPDATE_MODAL, modal })
				: reducer(state, { type: ActionType.SHOW_MODAL, modal })
		}

		case ActionType.DISMISS_MODAL: {
			const { modalId } = action
			if (modalId) {
				addToRemoveQueue(modalId)
			} else {
				state.modals.forEach((modal) => {
					addToRemoveQueue(modal.id)
				})
			}
			return {
				...state,
				modals: state.modals.map((t) =>
					t.id === modalId || modalId === undefined
						? {
								...t,
								visible: false,
							}
						: t
				),
			}
		}
		case ActionType.REMOVE_MODAL: {
			if (action.modalId === undefined) {
				return {
					...state,
					modals: [],
				}
			}
			return {
				...state,
				modals: state.modals.filter((t) => t.id !== action.modalId),
			}
		}
	}
}

const listeners: Array<(state: State) => void> = []

let memoryState: State = { modals: [] }

export const dispatch = (action: Action) => {
	memoryState = reducer(memoryState, action)
	listeners.forEach((listener) => {
		listener(memoryState)
	})
}

export const useStore = (): State => {
	const [state, setState] = useState<State>(memoryState)
	useEffect(() => {
		listeners.push(setState)
		return () => {
			const index = listeners.indexOf(setState)
			if (index > -1) {
				listeners.splice(index, 1)
			}
		}
	}, [state])

	return {
		...state,
		modals: state.modals,
	}
}

const manager: ModalsManagerState = {
	showModal: (id, props) =>
		dispatch({
			type: ActionType.UPSERT_MODAL,
			modal: {
				id,
				props,
				visible: true,
			},
		}),
	hideModal: (modalId: ModalType) =>
		dispatch({
			type: ActionType.DISMISS_MODAL,
			modalId,
		}),
}
export const useModalStore = () => {
	return manager
}

export function useModal<K extends ModalType>(
	modalId: K
): {
	id: K
	isOpen: boolean
	props: ModalProps<K> | undefined
	open(props: ModalProps<K>): void
	close(): void
} {
	const store = useStore()
	const state = useMemo(() => {
		const modal = store.modals?.find((modal) => modal.id === modalId)
		return {
			id: modalId,
			isOpen: !!modal?.visible,
			props: modal?.props as ModalProps<K>,
		}
	}, [store.modals])
	return {
		...state,
		open: (props: ModalProps<K>) => {
			manager.showModal(modalId, props)
		},
		close: () => {
			manager.hideModal(modalId)
		},
	}
}
