import { map, pipe, sum, values } from 'lodash/fp'
import React from 'react'

import intl from '@/locale'
import { useOptimizationsQuery } from '@/modules/core/queries'
import { formatNumber } from '@/tools/locale'

import { Widget, WidgetContent, WidgetHeader } from '../../Widget'

const selectTitalItegratedItems = pipe([values, map('total_products'), sum])

export default function NumericItemsIntegratedWidget() {
	const optimizationsQuery = useOptimizationsQuery<number>({
		select: selectTitalItegratedItems,
	})

	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				title={intl.get('widget.items_integrated.title')}
				tooltip={intl.get('widget.items_integrated.tooltip')}
				subtitle={intl.get('app.based_on_last_integration')}
			/>
			<WidgetContent className='truncate'>
				<div className='mt-auto w-full'>
					{optimizationsQuery.isLoading ? (
						<div className='col-span-2 h-10 w-32 animate-pulse rounded-lg bg-accent-3' />
					) : (
						<span className='text-3xl font-semibold'>
							{formatNumber(optimizationsQuery.data ?? 0)}
						</span>
					)}
				</div>
			</WidgetContent>
		</Widget>
	)
}
