import { Header, LinkButton } from '@cmpkit/base'

import intl from '@/locale'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'

export default function SelectionMessagePanel({
	selectedProducts,
	totalProducts,
	isAllSelected,
	onSelectAllProducts,
	onClearSelection,
}: {
	selectedProducts: number
	totalProducts: number
	isAllSelected: boolean
	onSelectAllProducts: () => void
	onClearSelection: () => void
}) {
	const count = formatNumber(totalProducts)
	const handleSelectAllProducts = () => {
		analytic.logEvent('table: select all items on all pages')
		onSelectAllProducts()
	}
	const handleClearSelected = () => {
		analytic.logEvent('table: clear selection')
		onClearSelection()
	}
	return (
		<Header className='mb-2 h-8 items-center justify-center gap-1 rounded-lg border bg-accent-3 text-xs'>
			<span className='font-bold'>
				{isAllSelected ? count : selectedProducts}
			</span>
			{intl.get('app.products_selected').d('products selected')}.
			{isAllSelected ? (
				<LinkButton variant='brand' onClick={handleClearSelected}>
					{intl.get('app.clear_selection').d('Clear selection')}
				</LinkButton>
			) : (
				<LinkButton variant='brand' onClick={handleSelectAllProducts}>
					{intl
						.get('app.select_all_products', {
							count,
						})
						.d(`Select all ${count} products`)}
				</LinkButton>
			)}
		</Header>
	)
}
