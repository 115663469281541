import { prop } from 'lodash/fp'
import React from 'react'

import * as Sentry from '@sentry/react'

import FatalError from '@/components/FatalError'
import { useAuth } from '@/modules/Auth/AuthContext'
import { getUsername } from '@/modules/Users/utils'

interface Boundary {
	children: React.ReactNode
	user: { name: string; email: string }
	config?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}
export const Boundary = ({ children, user, config = {} }: Boundary) => (
	<Sentry.ErrorBoundary
		fallback={(props) => <FatalError {...props} />}
		dialogOptions={{ user }}
		showDialog={config.showDialog}
	>
		{children}
	</Sentry.ErrorBoundary>
)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BoundaryWithUser = (props: any) => {
	const { user } = useAuth()
	return (
		<Boundary
			{...props}
			user={{
				name: getUsername(user),
				email: prop('email', user),
			}}
		/>
	)
}
export default (config = {}) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(Component: React.ComponentFactory<any, any>) =>
	(props: object) => (
		<BoundaryWithUser config={config}>
			<Component {...props} />
		</BoundaryWithUser>
	)
