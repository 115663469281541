import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { HTTPValidationError } from '@/generated'
import { client } from '@/network/client'

export function useSignOutMutation(
	options?: UseMutationOptions<void, AxiosError<HTTPValidationError>>
) {
	return useMutation<void, AxiosError<HTTPValidationError>>({
		mutationFn: () => {
			return client.auth.logoutAuthLogoutPost()
		},
		...(options || {}),
	})
}
