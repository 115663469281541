import { format } from 'date-fns'
import fastEq from 'fast-deep-equal'
import { any, map, prop, props, some } from 'lodash/fp'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import { useQueryClient } from '@tanstack/react-query'

import { Badge, Button, Content, Header, Layout, Spinner } from '@cmpkit/base'
import { Column } from '@cmpkit/data-table'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import LayersIcon from '@cmpkit/icon/lib/glyph/layers'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import UndoIcon from '@cmpkit/icon/lib/glyph/undo'
import { ValueType } from '@cmpkit/query-builder'
import Tooltip from '@cmpkit/tooltip'

import { dialog } from '@/components/dialogs'
import ErrorBoundary from '@/components/ErrorBoundary'
import { useOptimizationStatus } from '@/hooks/data'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { NEW, PENDING } from '@/modules/core/constants'
import { useOptimizationGroup } from '@/modules/core/queries'
import { ColumnConfig } from '@/modules/exports/types'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'
import { uuid } from '@/tools/utils'
import { useDrawersStore } from '@/modules/drawers/store'

import { getCopyName } from '../../helpers'
import { useScenariosSummary } from '../../hooks/useScenariosSummary'
import {
	useDeleteScenarioRunsMutation,
	useRestartScenariosMutation,
	useSetMainScenarioMutation,
} from '../../mutations'
import { useScenariosRunsQuery } from '../../queries'
import { GetScenarioByRowIdProps, ScenariosTableDataType } from '../../types'
import useColumns from './columns'
import { ScenarioSummaryTable } from './ScenariosSummaryTable'

export default function ScenariosSummaryPage() {
	const { showModal } = useModalStore()
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	const [rows, setRows] = useState<ScenariosTableDataType[]>([])
	const [copies, setCopies] = useState<ScenariosTableDataType[]>([])
	const [selected, setSelected] = useState<string[]>([])
	const { closeDrawer } = useDrawersStore()

	useEffect(() => {
		return () => {
			closeDrawer('PRICING_ALERTS')
		}
	}, [])

	const optimizationGroupId = useOptimizationGroupId()!
	const optimizationGroup = useOptimizationGroup(optimizationGroupId)
	const optimizationGroupStatus = useOptimizationStatus(optimizationGroupId)
	const scenariosResultsQuery = useScenariosRunsQuery(optimizationGroupId)
	const scenariosSummary = useScenariosSummary(optimizationGroupId)

	useEffect(() => {
		!scenariosSummary?.isLoading && setRows(scenariosSummary?.data)
	}, [scenariosSummary?.isLoading, scenariosSummary?.data])

	const removeQuery = () => {
		queryClient.removeQueries({
			queryKey: [
				'scenarios-run-sets',
				{ optimization_group_id: optimizationGroupId },
			],
		})
		queryClient.removeQueries({
			queryKey: [
				'scenarios-runs-items',
				{ optimization_group_id: optimizationGroupId },
			],
		})
	}
	/**
	 * Mutations
	 */

	const deleteScenarioRunsMutation = useDeleteScenarioRunsMutation({
		onSuccess: () => removeQuery(),
	})
	const applyScenarioMutation = useSetMainScenarioMutation({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['scenarios'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns-segments'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
			queryClient.invalidateQueries({
				queryKey: ['settings'],
			})
		},
	})
	const restartScenariosMutation = useRestartScenariosMutation({
		onSuccess: () => {
			removeQuery()
			setCopies([])
			setSelected([])
		},
	})
	const tableRows = useMemo(() => [...rows, ...copies], [rows, copies])

	/**
	 * Handlers
	 */
	const handleRevertChanges = () => {
		analytic.logEvent('what-if: summary: revert')
		setCopies([])
		setRows(scenariosSummary?.data)
	}
	const handleOptionsClick = useCallback(
		({ rowId, value, key }: { rowId: string; value: string; key: string }) => {
			const { id, optimizationId } = parseRowId(rowId)
			const existedIn = some((row: ScenariosTableDataType) => row?.id === id)
			const currentScenario = getScenarioByRowId({
				scenarios: tableRows,
				rowId,
			})
			const updatedScenario = {
				...currentScenario,
				status: PENDING,
				settings: {
					...currentScenario.settings,
					strategy: {
						...currentScenario.settings.strategy,
						demand_strategy: {
							...(currentScenario.settings.strategy?.demand_strategy || {}),
							[key]: value,
						},
					},
				},
			}

			const updateRowsState = (
				prev?: ScenariosTableDataType[]
			): ScenariosTableDataType[] =>
				prev?.map((scenario) =>
					scenario?.id === id && scenario.optimization_id === optimizationId
						? (updatedScenario as ScenariosTableDataType)
						: scenario
				) || []

			if (existedIn(copies)) {
				setCopies(updateRowsState)
			}
			if (existedIn(rows)) {
				setRows(updateRowsState)
			}
		},
		[rows, copies, setCopies, setRows]
	)
	const handleBulkDeleteScenario = async () => {
		const answer = await dialog.confirmDelete({
			title:
				selected?.length === 1
					? intl.get('scenario.detete_single.confirm.title')
					: intl.get('scenario.detete_bulk.confirm.title'),
			text: intl.get('scenario.detete_single.confirm.subtitle'),
			okText: intl.get('general_delete'),
		})
		if (answer) {
			analytic.logEvent(
				selected?.length === 1
					? 'what-if: summary: delete row'
					: 'what-if: summary: bulk delete rows'
			)
			const parsedSelectedIds = selected?.map(parseRowId)
			const copiesToDelete = copies?.filter((copy) =>
				parsedSelectedIds?.some(
					({ id, optimizationId }) =>
						id === copy?.id && optimizationId === copy.optimization_id
				)
			)
			const rowsToDelete = rows?.filter((row) =>
				parsedSelectedIds?.some(
					({ id, optimizationId }) =>
						id === row?.id && optimizationId === row.optimization_id
				)
			)
			if (!!rowsToDelete?.length) {
				const selectedScenarioRunIds: string[] = map(
					prop('scenario_run_id'),
					rowsToDelete
				)
				deleteScenarioRunsMutation.mutate(selectedScenarioRunIds)
			}
			if (!!copiesToDelete?.length) {
				setCopies((prev) =>
					prev?.filter(
						(copy) =>
							!copiesToDelete.some(
								({ id, optimization_id }) =>
									copy.id === id && copy.optimization_id === optimization_id
							)
					)
				)
			}
			setSelected([])
		}
	}
	const handleReRunScenario = () => {
		analytic.logEvent('what-if: summary: re-run')
		const scenariosToRun = selected.map((rowId) =>
			getScenarioByRowId({ scenarios: tableRows, rowId })
		)
		const body = {
			optimization_group_id: optimizationGroupId,
			scenario_runs: scenariosToRun.map(
				({ id, name, settings, scenario_run_id }) => ({
					scenario_id: id.split('_')?.[0],
					name,
					settings,
					id: scenario_run_id || null,
				})
			),
		}
		restartScenariosMutation.mutate(body)
	}
	const handleApplyScenario = async () => {
		if (![NEW, PENDING].includes(optimizationGroupStatus!)) {
			await dialog.alert({
				title: '',
				text: intl.get('scenario.apply.confirm.check_opt_status'),
				okText: intl.get('general_close'),
			})
		} else {
			const answer = await dialog.confirm({
				title: intl.get('scenario.apply.confirm.title').d('Apply scenario?'),
				text: intl.get('scenario.apply.confirm.subtitle'),
				okText: intl.get('general_apply'),
			})
			if (answer) {
				analytic.logEvent('what-if: summary: apply scenario')
				const scenario: ScenariosTableDataType = getScenarioByRowId({
					scenarios: tableRows,
					rowId: selected[0],
				})
				if (scenario && scenario?.settings?.strategy?.demand_strategy) {
					await applyScenarioMutation.mutateAsync({
						scenario_id: scenario?.id.split('_')?.[0],
						payload: {
							settings: scenario?.settings?.strategy?.demand_strategy,
						},
					})
					navigate(`/p/og/${optimizationGroupId}/settings`)
				}
			}
		}
	}
	const handleCopyScenarios = async () => {
		const answer = await dialog.confirm({
			title: intl.get('scenario.copy.confirm.title').d('Copy scenario?'),
			text: intl
				.get('scenario.copy.confirm.subtitle')
				.d('This action will create editable copy of selected scenario.'),
			okText: intl.get('scenario.copy.confirm.ok').d('Create a copy'),
		})
		if (answer) {
			analytic.logEvent('what-if: summary: copy row')

			const newCopies = selected
				.map((rowId) => getScenarioByRowId({ scenarios: tableRows, rowId }))
				.reduce((newCopies, scenario) => {
					const optId = uuid()
					return [
						...newCopies,
						{
							name: getCopyName({
								scenarioName: scenario?.name,
								scenarios: [...tableRows, ...newCopies],
							}),
							optimization_id: optId,
							status: PENDING,
							id: [scenario?.id, optId].join('_'),
							settings: scenario?.settings,
						},
					] as ScenariosTableDataType[]
				}, [] as ScenariosTableDataType[])
			setCopies((prev) => [...prev, ...newCopies])
			setSelected([])
		}
	}

	const handleExportScenario = () => {
		showModal('EXPORT_ADDITIONAL_DATA', {
			kind: 'scenarios_summary',
			defaultName: getExportName(
				intl
					.get('scenarios.summary.title', { name: optimizationGroup?.name })
					.d(`${optimizationGroup?.name} What-if results`),
				format(new Date(), 'MM/dd/yyyy')
			),
			fields: getReportSchema(columns),
			rows: tableRows,
		})
	}
	const columns = useColumns({ onOptionClick: handleOptionsClick })
	const isChanged = useMemo(
		() => !fastEq(scenariosSummary?.data, tableRows),
		[scenariosSummary?.data, tableRows]
	)
	const isLoading = any(prop('isLoading'), [scenariosSummary])
	const isPending = any(prop('isPending'), [
		restartScenariosMutation,
		deleteScenarioRunsMutation,
	])
	const isActionsDisabled = isLoading || isPending || !tableRows?.length

	return (
		<Layout>
			<Content className='flex-col overflow-hidden p-4'>
				<ErrorBoundary>
					<Header className='mb-2 items-center justify-between'>
						<div className='flex items-center gap-2'>
							<Button
								iconBefore={<CaretLeftIcon />}
								variant='tertiary'
								onClick={() => navigate('../scenarios')}
							/>
							<h1>{intl.get('scenarios.summary.title')}</h1>
							<Badge>
								{scenariosResultsQuery.data?.created_at && (
									<span className='text-xs font-medium'>{`${intl.get('app.last_run')}: ${format(
										new Date(scenariosResultsQuery.data.created_at + 'Z'),
										DateFormat.defaultTime
									)}`}</span>
								)}
							</Badge>
						</div>
						<div className='flex space-x-2'>
							{isChanged && (
								<Button
									variant='tertiary'
									onClick={handleRevertChanges}
									iconBefore={<UndoIcon />}
								>
									{intl.get('app.revert_changes')}
								</Button>
							)}
							{!!selected?.length ? (
								<>
									<Button
										onClick={handleBulkDeleteScenario}
										iconBefore={<Trash2Icon />}
									>
										{intl.get('app.delete_selected').d('Delete Selected')}
									</Button>
									<Button
										onClick={handleCopyScenarios}
										iconBefore={<LayersIcon />}
									>
										{intl.get('app.duplicate_selected').d('Duplicate selected')}
									</Button>
									<Button
										disabled={isActionsDisabled}
										iconBefore={
											restartScenariosMutation?.isPending && <Spinner />
										}
										onClick={handleReRunScenario}
									>
										{intl.get('scenario.rerun_scenarios').d('Re-run what-if')}{' '}
										{`(${selected?.length})`}
									</Button>
								</>
							) : (
								<>
									<Button
										disabled={isActionsDisabled}
										onClick={handleExportScenario}
									>
										{intl.get('app.export_result').d('Export results')}
									</Button>
									<Tooltip
										content={intl
											.get('scenario.rerun.disabled.tooltip')
											.d('Select one or more senario(s) to restart what-if')}
									>
										<span>
											<Button
												disabled={isActionsDisabled || !selected?.length}
												iconBefore={
													restartScenariosMutation?.isPending && <Spinner />
												}
												onClick={handleReRunScenario}
											>
												{intl
													.get('scenario.rerun_scenarios')
													.d('Re-run what-if')}
											</Button>
										</span>
									</Tooltip>
								</>
							)}
							{selected?.length === 1 ? (
								<Button
									variant='primary-warning'
									iconBefore={<CheckIcon />}
									onClick={handleApplyScenario}
								>
									{intl.get('scenario.apply').d('Apply scenario')}
								</Button>
							) : (
								<Tooltip
									content={intl
										.get('scenario.apply.disabled.tooltip')
										.d('Select one of allowed scenario configuration')}
								>
									<span>
										<Button
											variant='primary-warning'
											iconBefore={<CheckIcon />}
											onClick={handleApplyScenario}
											disabled
										>
											{intl.get('scenario.apply').d('Apply scenario')}
										</Button>
									</span>
								</Tooltip>
							)}
						</div>
					</Header>
					{/* <div className='mb-3 flex justify-between'>
						<div className='flex items-center space-x-2'>
							{scenariosResultsQuery.data?.created_at && (
								<span className='text-xs font-medium text-muted'>{`${intl.get('app.last_run')}: ${format(
									new Date(scenariosResultsQuery.data.created_at + 'Z'),
									DateFormat.defaultTime
								)}`}</span>
							)}
						</div>
						<div className='flex space-x-2'>
							{isChanged && (
								<Button onClick={handleRevertChanges} iconBefore={<UndoIcon />}>
									{intl.get('app.revert_changes')}
								</Button>
							)}
							{!!selected?.length ? (
								<>
									<Button
										onClick={handleBulkDeleteScenario}
										iconBefore={<Trash2Icon />}
									>
										{intl.get('app.delete_selected').d('Delete Selected')}
									</Button>
									<Button
										onClick={handleCopyScenarios}
										iconBefore={<LayersIcon />}
									>
										{intl.get('app.duplicate_selected').d('Duplicate selected')}
									</Button>
									<Button
										disabled={isActionsDisabled}
										iconBefore={
											restartScenariosMutation?.isPending && <Spinner />
										}
										onClick={handleReRunScenario}
									>
										{intl.get('scenario.rerun_scenarios').d('Re-run what-if')}{' '}
										{`(${selected?.length})`}
									</Button>
								</>
							) : (
								<>
									<Button
										disabled={isActionsDisabled}
										onClick={handleExportScenario}
									>
										{intl.get('app.export_result').d('Export results')}
									</Button>
									<Tooltip
										content={intl
											.get('scenario.rerun.disabled.tooltip')
											.d('Select one or more senario(s) to restart what-if')}
									>
										<span>
											<Button
												disabled={isActionsDisabled || !selected?.length}
												iconBefore={
													restartScenariosMutation?.isPending && <Spinner />
												}
												onClick={handleReRunScenario}
											>
												{intl
													.get('scenario.rerun_scenarios')
													.d('Re-run what-if')}
											</Button>
										</span>
									</Tooltip>
								</>
							)}
							{selected?.length === 1 ? (
								<Button
									variant='primary-warning'
									iconBefore={<CheckIcon />}
									onClick={handleApplyScenario}
								>
									{intl.get('scenario.apply').d('Apply scenario')}
								</Button>
							) : (
								<Tooltip
									content={intl
										.get('scenario.apply.disabled.tooltip')
										.d('Select one of allowed scenario configuration')}
								>
									<span>
										<Button
											variant='primary-warning'
											iconBefore={<CheckIcon />}
											onClick={handleApplyScenario}
											disabled
										>
											{intl.get('scenario.apply').d('Apply scenario')}
										</Button>
									</span>
								</Tooltip>
							)}
						</div>
					</div> */}
					<ScenarioSummaryTable
						rows={tableRows}
						columns={columns}
						isError={scenariosSummary?.isError}
						isLoading={scenariosSummary?.isLoading}
						getRowId={getRowId}
						selected={selected}
						setSelected={setSelected}
					/>
				</ErrorBoundary>
			</Content>
		</Layout>
	)
}

const getRowId = (row: ScenariosTableDataType): string => {
	return props(['id', 'optimization_id', 'name'], row).join('__')
}
const parseRowId = (
	key: string
): { id: string; optimizationId: string; name: string } => {
	const [id, optimizationId, name] = key.split('__')
	return {
		id,
		optimizationId,
		name,
	}
}
/**
 * Get report coulmns schema
 * @param columns - columns from table
 * @returns - array of columns
 */
const getReportSchema = (columns: Column[]): ColumnConfig[] => {
	const isStringColumn = (key: string) =>
		[
			'control',
			'name',
			'settings.strategy.demand_strategy.target',
			'settings.strategy.demand_strategy.protect',
			'status',
		].includes(key)
	return columns
		?.filter(({ key }) => key !== 'control' && key !== 'status')
		?.map(({ key, title }) => ({
			key,
			type: isStringColumn(key) ? ValueType.str : ValueType.number,
			name: title,
			custom_name: title,
			static_value: null,
			enabled: true,
		}))
}
const getExportName = (name: string, date: string): string => `${name} ${date}`
const getScenarioByRowId = ({
	scenarios,
	rowId,
}: GetScenarioByRowIdProps): ScenariosTableDataType => {
	const { id, optimizationId } = parseRowId(rowId)
	return scenarios.find(
		(row) => row?.id === id && row?.optimization_id === optimizationId
	)!
}
