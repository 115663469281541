import { isAfter, isBefore, isEqual } from 'date-fns'
import { format as formatTz, toZonedTime } from 'date-fns-tz'

export function formatInTimeZone(
	value: Date,
	template: DateFormat | string = DateFormat.default,
	timeZone = 'UTC'
) {
	return formatTz(toZonedTime(new Date(value), timeZone), template, {
		timeZone,
	})
}
export const utcToZonedTime = toZonedTime

export const getUTCDate = (date: Date) => {
	return new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
		date.getUTCSeconds()
	)
}
export const startOfUTC = (date: Date) => new Date(date.setUTCHours(0, 0, 0, 0))
export const endOfUTC = (date: Date) =>
	new Date(date.setUTCHours(23, 59, 59, 999))
export enum DateFormat {
	default = 'MM/dd/yyyy', // L
	defaultTime = 'MM/dd/yyyy hh:mm',
	system = 'yyyy-MM-dd',
	short = 'dd MMM',
	long = 'MMMM d yyyy', // LL
	longTime = 'MMMM d yyyy hh:mm', // LL
}
export const isBetween = (
	date: Date,
	from: Date,
	to: Date,
	inclusivity = '()'
) => {
	if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
		throw new Error('Inclusivity parameter must be one of (), [], (], [)')
	}

	const isBeforeEqual = inclusivity[0] === '[',
		isAfterEqual = inclusivity[1] === ']'

	return (
		(isBeforeEqual
			? isEqual(from, date) || isBefore(from, date)
			: isBefore(from, date)) &&
		(isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
	)
}
