import clsx from 'clsx'
import React from 'react'

import { Checkbox } from '@cmpkit/base'

import { CellPropControlled } from '../'

export default function TableCellEditor(props: CellPropControlled<boolean>) {
	const checked = Boolean(props.value)
	const onChange = () =>
		props.onChange?.({
			row: props.row,
			schema: props.schema,
			value: !checked,
		})

	return (
		<Checkbox
			className={clsx(
				'm-auto',
				!props.schema.editable && 'pointer-events-none'
			)}
			checked={checked}
			onChange={onChange}
		/>
	)
}
