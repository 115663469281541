import {
	any,
	differenceBy,
	filter,
	find,
	fromPairs,
	map,
	pipe,
	prop,
	size,
	values,
} from 'lodash/fp'
import React, { ChangeEvent, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import {
	Button,
	Checkbox,
	Col,
	Content,
	InlineMessage,
	Input,
	Layout,
	Loader,
	Row,
	Sider,
	TextArea,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'
import { DataChoice, FieldSelect, Operators } from '@cmpkit/query-builder'
import Select, { SingleValue } from '@cmpkit/select'

import { DataOption } from '@/common.types'
import { ColumnDataType } from '@/components/data-grid/types'
import {
	ColumnSchemaModel,
	FilterRuleModel,
	UpdateProductsFinalFieldsErrorResponseModel,
	UpdateProductsModelFieldsErrorResponseModel,
} from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useUpdateProductsFieldsByFiltersMutation } from '@/modules/core/hooks/useUpdateProductsFieldsByFiltersMutation'
import { useUpdateProductsFieldsMutation } from '@/modules/core/hooks/useUpdateProductsFieldsMutation'
import { useColumnsSchemaQuery } from '@/modules/core/queries'
import { usePricingCampaignsQuery } from '@/modules/pricing-campaigns/queries'
import { pcToOption } from '@/modules/pricing-campaigns/utils'
import analytic from '@/services/analytics'
import { numericOnly } from '@/tools/constants'

import { useProductsListQuery } from '../queries'
import ProductsBulkUpdateErrors from './ProductsBulkUpdateErrors'

type ProductsBulkModalProps = {
	filters: FilterRuleModel[]
	isOpen: boolean
	close(): void
}
type FieldOption = {
	value: string
	label: string
	type: string
	schema: ColumnSchemaModel
}
type ConditionRow = {
	inputValue?: string | boolean
	value: string
	type?: string
	schema?: ColumnSchemaModel
}
const selectCampaigns = pipe([
	map(pcToOption),
	map((item: DataChoice) =>
		item.value == 'null'
			? { ...item, value: null }
			: { ...item, value: item.label }
	),
])
export default function ProductsBulkUpdateModal({
	filters,
	isOpen,
	close,
}: ProductsBulkModalProps) {
	const optimizationGroupId = useOptimizationGroupId()!
	const assortmentQuery = useProductsListQuery(
		optimizationGroupId,
		{
			limit: 1,
			filters: [
				{
					name: 'optimization_group_id',
					operation: Operators.IS,
					value: optimizationGroupId,
				},
				...(filters || []),
			],
		},
		{
			enabled: filters?.length > 0,
		}
	)

	/**
	 * Queries
	 */
	const updateProductsFieldsByFilters =
		useUpdateProductsFieldsByFiltersMutation(optimizationGroupId)
	const updateProductsFields =
		useUpdateProductsFieldsMutation(optimizationGroupId)

	const { data: fields } = useColumnsSchemaQuery({
		select: pipe([filter(prop('editable')), toOptions]),
	})
	const { data: campaigns } = usePricingCampaignsQuery(
		{
			optimization_group_id: optimizationGroupId,
		},
		{
			select: selectCampaigns,
		}
	)
	const dataChoices: Record<string, DataOption[]> = {
		pricing_campaign_id: campaigns,
	}
	const byFilters = !!assortmentQuery.data?.total_skus

	/**
	 * State management and handlers
	 */
	const [selectedItemsText, setSelectedItemsText] = useState('')
	////selected.join(',').replace(/,/g, '\n')
	const [selectedArr, setSelectedArr] = useState<string[]>([])
	const [selectedFields, setSelectedFields] = useState<ConditionRow[]>([])

	useEffect(() => {
		if (isInvalidSelectedText(selectedArr)) {
			toast.error(intl.get('bulk_modal_invalid_selected_input'))
		}
	}, [selectedArr])

	/**
	 * Handlers
	 */
	const handleClose = () => close()
	const handleChangeTextarea = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const skus = event.target.value.replace(/,/g, '\n')
		setSelectedItemsText(skus)
		setSelectedArr(skus.length > 0 ? skus.split(/\n/) : [])
	}
	const handleRemoveField = (index: number) =>
		setSelectedFields(selectedFields.filter((f, i) => i != index))
	const handleSelectType = (
		index: number,
		{
			value,
			type,
			schema,
		}: {
			value: string
			type: string
			schema: ColumnSchemaModel
		}
	) =>
		setSelectedFields(
			selectedFields.map((row, i: number) =>
				i === index ? { ...row, value, type, schema } : row
			)
		)
	const handleAddField = () =>
		setSelectedFields([...selectedFields, { value: '', inputValue: '' }])

	const handleChange = (
		index: number,
		inputValue: string | boolean | undefined
	) => {
		setSelectedFields(
			selectedFields.map((filter, i) =>
				i === index
					? {
							...filter,
							inputValue,
						}
					: filter
			)
		)
	}
	const handleApply = async () => {
		analytic.logEvent('table: manual update', {
			'Amount of fields for updating': selectedFields.length,
		})
		const values = createChanges(selectedFields)
		const data = selectedArr.map((sku) => ({
			sku,
			...values,
		}))
		if (!byFilters) {
			await updateProductsFields.mutateAsync({
				changes: data,
				ignore_empties: false,
			})
		} else {
			await updateProductsFieldsByFilters.mutateAsync({
				filters,
				values,
			})
		}
		close()
	}

	const getFieldOptions = (item: ConditionRow) => {
		if (!item.schema) return []
		return (
			dataChoices?.[item.value] ||
			(item.schema.enum || []).map((option) => ({
				value: option,
				label: option,
			}))
		)
	}

	/**
	 * Calculations and checks
	 */
	const isInvalidSelectedFields = !selectedFields.filter(
		(item) => item.value !== undefined
	).length
	const isDisabled = isInvalidSelectedFields
	const isError =
		updateProductsFields.isError || updateProductsFieldsByFilters.isError
	const availableFields = differenceBy(
		'value',
		fields,
		selectedFields
	) as unknown as FieldOption[]
	const errorsList = values(
		(
			updateProductsFields.error?.response
				?.data as UpdateProductsFinalFieldsErrorResponseModel
		)?.errors?.final_fields ||
			(
				updateProductsFields.error?.response
					?.data as UpdateProductsModelFieldsErrorResponseModel
			)?.errors?.product_diffs
	)
	return (
		<ModalTransition>
			{isOpen && (
				<Modal showCloseButton onClose={handleClose}>
					<ModalHeader>
						<ModalTitle>
							{intl.get('bulk_modal_bulk_update') +
								' ' +
								(assortmentQuery.data?.total_skus
									? `(${assortmentQuery.data?.total_skus})`
									: '')}
						</ModalTitle>
					</ModalHeader>
					<ModalBody className='min-h-[300px]'>
						<div className='flex h-full flex-col'>
							{isError && (
								<InlineMessage variant='danger' className='mb-5'>
									{intl.get('fatal_error_title')}
								</InlineMessage>
							)}
							<Blanket
								className='absolute flex flex-col items-center justify-center space-y-5 rounded-lg bg-white/50 backdrop-blur-md dark:bg-black/50'
								isTinted={
									assortmentQuery.isLoading ||
									updateProductsFields.isPending ||
									updateProductsFieldsByFilters.isPending
								}
							>
								<Loader />
							</Blanket>
							<Layout row className='h-full space-x-5'>
								{!byFilters && (
									<Sider className='w-48'>
										<div className='flex h-full flex-1'>
											<TextArea
												className='h-full resize-none'
												value={selectedItemsText}
												placeholder={intl.get('bulk_modal_enter_sku')}
												onChange={handleChangeTextarea}
											/>
										</div>
									</Sider>
								)}
								<Layout>
									<Content className='flex-col'>
										<h6 className='mb-1 text-xs'>
											{intl
												.get('bulk_modal_bulk_update.fields')
												.d('Fields to update')}
										</h6>
										<div className='mb-2 w-full space-y-2 border-b border-solid pb-2'>
											{selectedFields.length
												? selectedFields.map((item, index) => {
														return (
															<Row key={index} noGutters>
																<Col sm={5}>
																	<div className='flex items-center space-x-1'>
																		<Button
																			variant='tertiary'
																			onClick={handleRemoveField.bind(
																				null,
																				index
																			)}
																			iconBefore={
																				<CrossIcon className='shrink-0 cursor-pointer' />
																			}
																		/>
																		<FieldSelect
																			name='field'
																			placeholder={intl.get('general_search')}
																			options={availableFields}
																			value={find(
																				{ value: item.value },
																				fields
																			)}
																			onChange={handleSelectType.bind(
																				null,
																				index
																			)}
																		/>
																	</div>
																</Col>
																<Col className='flex items-center'>
																	<div className='ml-1 flex w-full items-center'>
																		{getFieldEditor({
																			item,
																			value: item.inputValue,
																			options: getFieldOptions(item),
																			onChange: handleChange.bind(null, index),
																		})}
																	</div>
																</Col>
															</Row>
														)
													})
												: null}
										</div>
										<div>
											{availableFields.length &&
											selectedFields.length < fields.length ? (
												<Button
													variant='tertiary'
													onClick={handleAddField}
													iconBefore={<PlusIcon />}
												>
													{intl.get('bulk_modal_add_field')}
												</Button>
											) : null}
										</div>
									</Content>
								</Layout>
							</Layout>
							{errorsList?.length > 0 && (
								<div className='mt-5'>
									<ProductsBulkUpdateErrors errorsList={errorsList} />
								</div>
							)}
						</div>
					</ModalBody>
					<ModalFooter className='justify-between'>
						<Button onClick={handleClose}>{intl.get('general_cancel')}</Button>
						<Button
							variant='primary-brand'
							disabled={isDisabled}
							onClick={handleApply}
						>
							{intl.get('general_apply')}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}

const isInvalidSelectedText = any((i: string) => size(i) > 50)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createChanges = (fields: any) =>
	fromPairs(
		map(
			(item) => [
				item.value,
				item.type === ColumnDataType.Boolean
					? !!item.inputValue
					: item.inputValue,
			],
			fields
		)
	)

const toOptions = (fields: ColumnSchemaModel[]): FieldOption[] =>
	fields.map(
		(schema): FieldOption => ({
			value: schema.name,
			label: schema.translate_key
				? intl.get(schema.translate_key).d(schema.visible_name)
				: schema.visible_name,
			type: schema.type,
			schema,
		})
	)

const getFieldEditor = ({
	item,
	value,
	options,
	onChange,
}: {
	item: ConditionRow
	value: string | boolean | undefined
	options: DataOption[]
	onChange: (value: string | boolean | undefined) => void
}) => {
	const type = item?.schema?.filter_schema?.type || item.type
	switch (type) {
		case ColumnDataType.Boolean:
			return (
				<Checkbox
					checked={Boolean(value)}
					onChange={(event) => onChange(event.target.checked)}
				/>
			)
		case 'multiselect':
		case 'select':
			return (
				<Select
					className='w-full'
					value={find({ value }, options) as DataOption}
					options={options}
					onChange={(data: SingleValue<DataOption>) => onChange(data?.value)}
				/>
			)
		default:
			return (
				<Input
					value={String(item.inputValue)}
					onChange={(event) =>
						onChange(
							[ColumnDataType.Integer, ColumnDataType.Float].includes(type)
								? event.target.value.replace(numericOnly, '')
								: event.target.value
						)
					}
				/>
			)
	}
}
