import clsx from 'clsx'
import { format, intlFormat } from 'date-fns'
import { any, filter, map, pipe, prop } from 'lodash/fp'
import { useEffect, useMemo } from 'react'

import { Result } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import EmptySearchIcon from '@cmpkit/icon/lib/glyph/empty-search'
import { Operators } from '@cmpkit/query-builder'

import Chart from '@/components/Chart'
import CollapsibleSection from '@/components/CollapsibleSection'
import { BIRequest, FilterRuleModel, MetricModel } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import {
	useBIAnalyticQuery,
	useBIRevisionQuery,
	useMetricsQuery,
} from '@/modules/bi/queries'
import {
	useInterpretabilityStatisticQuery,
	useOptimizationQuery,
	useRepricingStatisticQuery,
} from '@/modules/core/queries'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'
import { formatNumber } from '@/tools/locale'

import {
	getForecastPeriod,
	getMetricAnalytic,
	getMetricForecast,
	getMetricsAnalyticData,
	getPeriod,
	getPrefixedMetric,
	getTypesByMetricSchema,
	METRIC_PREFIX,
	MetricsConfig,
	roundIfFloat,
	sortMetricsConfigByStrategy,
	ValueTypes,
} from './helpers'
import { MetricDataType } from '../../constants'

export default function ProgressWidgets({
	filters,
}: {
	filters: FilterRuleModel[]
}) {
	useEffect(() => {
		analytic.logEvent('opt summary: open progress tab')
	}, [])

	const optimizationGroupId = useOptimizationGroupId()!
	const defaultFilter = {
		name: 'optimization_group_id',
		operation: Operators.IS,
		value: optimizationGroupId,
	}

	/** Queries */
	const optimizationQuery = useOptimizationQuery(optimizationGroupId)
	const metricsQuery = useMetricsQuery({
		select: filter(({ type }) => type === 'predict_default'),
	})
	const repricingStatisticQuery = useRepricingStatisticQuery(
		{
			optimizations: [
				{
					optimization_id: optimizationQuery.data!.id,
					optimization_group_id: optimizationGroupId,
				},
			],
			filters: [],
		},
		{
			enabled: !!optimizationGroupId && !!optimizationQuery.data?.id,
			select: prop('settings.strategy'),
		}
	)
	const interpretabilityStatisticQuery = useInterpretabilityStatisticQuery(
		{
			optimizations: [
				{
					optimization_id: optimizationQuery.data!.id,
					optimization_group_id: optimizationGroupId,
				},
			],
			filters,
		},
		{
			enabled: !!optimizationQuery.data!.id,
		}
	)
	const biRevisionDateQuery = useBIRevisionQuery<string>({
		refetchOnMount: 'always',
		select: (data) => data?.['date'] as string,
	})
	const metricsConfig = useMemo(
		() => getMetricsConfig({ metricsSchema: metricsQuery.data! }),
		[metricsQuery.data]
	)
	const biAnalyticBody: BIRequest = useMemo(() => {
		return {
			date_aggregation: 'date_key',
			metrics: map(prop('analyticKey'))(metricsConfig),
			product_filters: [defaultFilter, ...filters],
			filters: [],
			...getPeriod(biRevisionDateQuery.data),
		}
	}, [biRevisionDateQuery.data, filters])

	const biAnalyticQuery = useBIAnalyticQuery(biAnalyticBody, {
		enabled: !!biRevisionDateQuery.data,
	})

	/** Calculated */

	const isError = any(prop('isError'), [
		biRevisionDateQuery,
		biAnalyticQuery,
		interpretabilityStatisticQuery,
		repricingStatisticQuery,
		metricsQuery,
	])
	const isLoading = any(prop('isLoading'), [
		biRevisionDateQuery,
		biAnalyticQuery,
		interpretabilityStatisticQuery,
		repricingStatisticQuery,
		metricsQuery,
	])
	const analyticsData = useMemo(
		() => getMetricsAnalyticData(biAnalyticQuery.data!),
		[biAnalyticQuery.data]
	)

	const enrichMetricsConfig = (config: MetricsConfig[]) => {
		const forecastPeriod = getForecastPeriod(biRevisionDateQuery.data)
		return config?.map(
			({
				analyticKey,
				prefixedKey,
				metricValueTypes,
				...rest
			}: MetricsConfig) => {
				const metricKey = `${prefixedKey}.final`
				const forecastData =
					(
						interpretabilityStatisticQuery.data?.metrics as MetricsDataStatistic
					)?.[metricKey] || 0
				/** The 'final' value, returned for the margin metric, represents a raw value and needs to be multiplied by 100 to express it as a percentage */
				const isMetricDataTypePercent =
					metricValueTypes.dataType === MetricDataType.Percent
				const formattedForcastData = isMetricDataTypePercent
					? forecastData * 100
					: forecastData
				return {
					...rest,
					analyticKey,
					metricValueTypes,
					data: [
						...getMetricAnalytic(analyticKey, analyticsData),
						isMetricDataTypePercent
							? [forecastPeriod, formattedForcastData]
							: getMetricForecast(forecastPeriod, formattedForcastData),
					],
				}
			}
		)
	}

	const metrics: MetricType[] = useMemo(() => {
		const strategy = repricingStatisticQuery.data
		return (
			strategy &&
			pipe(sortMetricsConfigByStrategy, enrichMetricsConfig)(
				metricsConfig,
				strategy
			)
		)
	}, [
		analyticsData,
		interpretabilityStatisticQuery.data,
		repricingStatisticQuery.data,
	])

	if (isError) {
		return (
			<div className='flex h-full items-center justify-center'>
				<Result
					icon={<AlertIcon width={72} height={72} />}
					title={intl.get('fatal_error_title')}
					subtitle={intl.get('fatal_error_desc')}
				/>
			</div>
		)
	}
	if (!isLoading && !biRevisionDateQuery.data) {
		return (
			<div className='flex h-full items-center justify-center'>
				<Result
					icon={<EmptySearchIcon width={56} height={56} />}
					subtitle={intl
						.get('opt.summary.not_available_for_rb')
						.d('This tab is not available for rule-based groups.')}
				/>
			</div>
		)
	}

	return (
		<div className='py-4'>
			{isLoading && <Loader />}
			{!isLoading &&
				!!analyticsData?.length &&
				metrics?.map((metric, i) => {
					const config = getChartConfig(metric)
					return (
						<div key={`${metric.title}_${i}`}>
							<CollapsibleSection
								isOpen
								header={<h4>{metric.title}</h4>}
								subtitle={metric.subtitle}
								className={clsx(
									'cmp-border-bottom pb-4 pt-3',
									i === 0 && 'cmp-border-top'
								)}
							>
								<Chart config={config} />
							</CollapsibleSection>
						</div>
					)
				})}
		</div>
	)
}

const getChartConfig = (metric: MetricType): Highcharts.Options => {
	const zoneTransitionValue: [number, number] =
		metric?.data?.[metric.data.length - 2]
	return {
		chart: {
			type: 'area',
			height: 238,
		},
		//allowNegativeLog: true,
		tooltip: {
			animation: true,
			outside: true,
			shared: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter,
		},
		legend: {
			enabled: false,
		},
		plotOptions: {
			line: {
				dataLabels: {
					enabled: true,
				},
			},
			series: {
				dashStyle: 'Solid',
				cursor: 'pointer',
			},
		},
		yAxis: {
			title: {
				text: '',
			},
			labels: {
				style: {
					fontSize: '10px',
				},
			},
			...(metric.metricValueTypes.dataType === MetricDataType.Percent && {
				labels: {
					formatter: ({ value }) => `${value}%`,
					style: {
						fontSize: '10px',
					},
				},
			}),
		},
		xAxis: {
			type: 'datetime',
			labels: {
				padding: 0,
				formatter: function () {
					return format(new Date(this.value), DateFormat.short)
				},
				style: {
					fontSize: '10px',
				},
			},
			endOnTick: true,
			tickmarkPlacement: 'on',
			startOnTick: false,
			minPadding: 0,
			maxPadding: 0,
			offset: 0,
		},
		series: [
			{
				...metric,
				fillOpacity: 1,
				fillColor: {
					linearGradient: [0, 0, 0, 250],
					stops: [
						[0, 'rgba(50, 178, 243, 0.72)'],
						[1, 'rgba(118, 162, 184, 0)'],
					],
				},
				data: metric?.data?.map((point: [number, number]) => {
					const isSalesItem = metric.name === intl.get('metric.sales_items')
					return {
						x: point[0],
						y: isSalesItem ? roundIfFloat(point[1]) : point[1],
						marker: {
							lineWidth: 0,
							lineColor: undefined,
							symbol: 'circle',
							dashStyle: 'Solid',
							cursor: 'pointer',
							enabled: point === zoneTransitionValue,
						},
					}
				}),
				dashStyle: 'Solid',
				lineWidth: 2,
				color: '#32B2F3',
				zoneAxis: 'x',
				zones: [
					{
						value: zoneTransitionValue?.[0],
					},
					{
						dashStyle: 'dash',
						fillColor: {
							linearGradient: [0, 0, 0, 400],
							stops: [
								[0, 'rgba(50, 178, 243, 0.32)'],
								[1, 'rgba(108, 163, 191, 0)'],
							],
						},
					},
				],
			},
			// FIXME: Fix the type of the series
		] as any, // eslint-disable-line @typescript-eslint/no-explicit-any
	}
}

export function formatter(this: Highcharts.Point) {
	const head = `<div class="mb-2.5"><small>${intlFormat(new Date(this.x as number))}</small></div>`
	const self = this as unknown as any // eslint-disable-line
	const body = self.points
		?.map((point: Highcharts.Point) => {
			return `<div class="flex justify-between">
				<div class="flex items-center">
					<div class="color-dot rounded-lg mr-4" style="background: ${point.color};"></div>
					<strong class="truncate">${point.series.name}</strong>
				</div>
				<div class="flex items-center">
					<strong class="flex items-center mr-2">${formatNumber(point.y as number)}</strong>
				</div>
			</div>`
		})
		.join('')
	return `<div class="chart-tooltip w-72">
		${head}
		${body}
	</div>`
}

const Loader = () => (
	<div className='space-y-2'>
		<div className='mb-3 space-y-2'>
			<div className='h-5 w-full animate-pulse rounded-lg bg-accent-4' />
			<div className='h-4 w-full animate-pulse rounded-lg bg-accent-4' />
			<div className='h-96 w-full animate-pulse rounded-lg bg-accent-4' />
		</div>
		<div className='mb-3 space-y-2'>
			<div className='h-5 w-full animate-pulse rounded-lg bg-accent-4' />
			<div className='h-4 w-full animate-pulse rounded-lg bg-accent-4' />
			<div className='h-96 w-full animate-pulse rounded-lg bg-accent-4' />
		</div>
		<div className='mb-3 space-y-2'>
			<div className='h-5 w-full animate-pulse rounded-lg bg-accent-4' />
			<div className='h-4 w-full animate-pulse rounded-lg bg-accent-4' />
			<div className='h-96 w-full animate-pulse rounded-lg bg-accent-4' />
		</div>
		<div className='mb-3 space-y-2'>
			<div className='h-5 w-full animate-pulse rounded-lg bg-accent-4' />
			<div className='h-4 w-full animate-pulse rounded-lg bg-accent-4' />
			<div className='h-96 w-full animate-pulse rounded-lg bg-accent-4' />
		</div>
	</div>
)
const METRIC_KEYS = [
	'gross_profit',
	'gross_profit_margin',
	'sales_items',
	'revenue',
]
const getMetricsConfig = ({
	metricsSchema,
}: {
	metricsSchema: MetricModel[]
}): MetricsConfig[] => {
	return METRIC_KEYS.map((key: string) => {
		const prefixedKey = getPrefixedMetric({
			metric: key,
			prefix: METRIC_PREFIX,
		})
		const metricSchema = metricsSchema?.find(
			({ name }) => name.split('.')[0] === `${prefixedKey}`
		)
		return {
			analyticKey: key,
			metricValueTypes: getTypesByMetricSchema(metricSchema!),
			name: intl.get(`metric.${key}`),
			prefixedKey,
		}
	})
}

type MetricType = {
	analyticKey: string
	title: string
	subtitle: string
	data: [number, number][]
	name: string
	metricValueTypes: ValueTypes
}
type MetricsDataStatistic = {
	[key: string]: number
}
