import { JSONSchemaType } from 'ajv'
import { find, pipe, prop } from 'lodash/fp'
import React, { ChangeEvent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ReactMarkdown from 'react-markdown'

import {
	Button,
	Col,
	FormError,
	FormGroup,
	Row,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
	Textfield,
} from '@cmpkit/base'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import Select, { SingleValue } from '@cmpkit/select'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'
import { ColumnDataType } from '@/components/data-grid/types'
import { ColumnSchemaModel } from '@/generated'
import intl from '@/locale'
import { useColumnsSchemaQuery } from '@/modules/core/queries'
import {
	ErrorSchema,
	getEnumOptions,
	getSchemaDefaultState,
	JSONSchema,
	JSONSchemaArray,
	mapChangeEventValue,
	valueAsNumber,
} from '@/tools/json-schema-utils'

type RepricingLimitEntity = {
	name: string
	on_violation: string
	type: string
	value: string
}
type RepricingLimitRuleProps = {
	rule: RepricingLimitEntity
	onRemove(): void
	schema: JSONSchema
	onChange(
		e:
			| ChangeEvent<HTMLInputElement>
			| {
					target: {
						value: string | null | undefined
					}
			  }
	): void
	errorSchema?: ErrorSchema
	columnsOptions: DataOption[]
}
const getErrorString = prop('message')
function RepricingLimitRule({
	rule,
	onRemove,
	schema,
	onChange,
	errorSchema,
	columnsOptions,
}: RepricingLimitRuleProps) {
	const onViolationOptions = getEnumOptions(
		prop('items.properties.on_violation', schema)
	)
	const typeOptions = getEnumOptions(prop('items.properties.type', schema))

	const nameError = getErrorString(errorSchema?.name)
	const valueError = getErrorString(errorSchema?.value)
	const onViolationError = getErrorString(errorSchema?.on_violation)
	const typeError = getErrorString(errorSchema?.type)

	const handleSelectChange =
		(name: string) => (option: SingleValue<DataOption>) =>
			onChange({ target: { name, value: option?.value } })

	return (
		<>
			<TableCell className='border-none p-2'>
				<Row noGutters className='space-x-2'>
					<Col sm={5}>
						<FormGroup className='mb-0' data-testid={'pc-repricing-limit-name'}>
							<Select
								menuPortalTarget={document.body}
								menuPlacement='auto'
								options={columnsOptions}
								placeholder={intl.get('general_select').d('Select')}
								onChange={handleSelectChange('name')}
								name={'name'}
								value={find({ value: rule.name }, columnsOptions)}
								invalid={Boolean(nameError)}
							/>
							{nameError && <FormError>{nameError}</FormError>}
						</FormGroup>
					</Col>

					<Col>
						<FormGroup
							className='mb-0'
							data-testid={'pc-repricing-limit-value'}
						>
							<Textfield
								name={'value'}
								type='number'
								autoComplete='off'
								autoCorrect='off'
								invalid={Boolean(valueError)}
								value={rule.value}
								onChange={pipe([mapChangeEventValue(valueAsNumber), onChange])}
								addonAfter={rule?.type === 'absolute' ? '#' : '%'}
							/>
							{valueError && <FormError>{valueError}</FormError>}
						</FormGroup>
					</Col>
					<Col sm={4} className='flex space-x-2'>
						<FormGroup
							className='mb-0 w-full'
							data-testid={'pc-repricing-limit-type'}
						>
							<Select
								options={typeOptions}
								onChange={handleSelectChange('type')}
								menuPortalTarget={document.body}
								menuPlacement='auto'
								name={'type'}
								value={find({ value: rule.type }, typeOptions)}
								invalid={Boolean(typeError)}
							/>
							{typeError && <FormError>{typeError}</FormError>}
						</FormGroup>
						<Tooltip
							content={
								<ReactMarkdown>
									{prop('items.properties.type.description', schema)}
								</ReactMarkdown>
							}
							placement='top'
						>
							<span className='flex h-8 items-center'>
								<InfoIcon />
							</span>
						</Tooltip>
					</Col>
				</Row>
			</TableCell>
			<TableCell className='border-none p-2'>
				<div className='ml-auto flex items-start justify-end gap-2'>
					<FormGroup
						className='mb-0'
						data-testid={'pc-repricing-limit-on_violation'}
					>
						<Select
							className='w-44'
							options={onViolationOptions}
							onChange={handleSelectChange('on_violation')}
							menuPortalTarget={document.body}
							menuPlacement='auto'
							name={'on_violation'}
							value={find({ value: rule.on_violation }, onViolationOptions)}
							invalid={Boolean(onViolationError)}
						/>
						{onViolationError && <FormError>{onViolationError}</FormError>}
					</FormGroup>
					<Button
						variant='tertiary'
						iconBefore={<CrossIcon />}
						onClick={onRemove}
					/>
				</div>
			</TableCell>
		</>
	)
}

export function RepricingLimits(props: {
	formData: RepricingLimitEntity[] | []
	onChange(data: RepricingLimitEntity[] | []): void
	schema: JSONSchemaArray<RepricingLimitEntity>
	errorSchema: ErrorSchema
	columns?: ColumnSchemaModel[]
}) {
	const { formData, onChange, schema, errorSchema, columns } = props
	const columnsOptions = columns
		?.filter(({ stage, type, category }: ColumnSchemaModel) => {
			return (
				['prepro', 'pre_init_fields'].includes(stage!) &&
				type === ColumnDataType.Float &&
				category === 'price'
			)
		})
		.map(({ name, translate_key, visible_name }: ColumnSchemaModel) => ({
			value: name,
			label: translate_key
				? intl.get(translate_key).d(visible_name)
				: visible_name, //formatRequiredMessage([translate_key, visible_name], visible_name),
		}))
	const rules: RepricingLimitEntity[] = formData || schema.default || []
	const handleAddRule = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		const newItem = getSchemaDefaultState<RepricingLimitEntity>(
			schema.items as JSONSchemaType<RepricingLimitEntity>
		) as RepricingLimitEntity
		onChange([...rules, newItem])
	}
	const handleRemoveRule = (index: number) => {
		onChange(rules.filter((item, i) => i !== index))
	}
	const handleChange = (index: number) => {
		return (e: ChangeEvent<HTMLInputElement>) => {
			const {
				target: { value, name },
			} = e
			const data = [...rules]
			data[index] = { ...data[index], [name]: value }
			onChange([...data])
		}
	}
	return (
		<div className='space-y-2'>
			<div className='space-y-2 border-b border-solid'>
				<Table>
					<TableHeader className='!top-auto'>
						<TableRow>
							<TableHead className='w-7/12 bg-transparent p-2'>
								{schema?.title}
							</TableHead>
							<TableHead className='w-5/12 bg-transparent p-2 text-right'>
								{prop('items.properties.on_violation.title', schema)}
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{rules.length > 0 ? (
							rules.map((rule, index) => {
								return (
									<TableRow
										key={index}
										data-testid={`pc-repricing-limit-row-${index}`}
									>
										<RepricingLimitRule
											columnsOptions={columnsOptions || []}
											errorSchema={errorSchema?.[index]}
											onChange={handleChange(index)}
											schema={schema}
											key={index}
											rule={rule}
											onRemove={() => handleRemoveRule(index)}
										/>
									</TableRow>
								)
							})
						) : (
							<TableRow>
								<TableCell colSpan={2} className='border-none'>
									<div className='p-5 text-center text-muted'>
										{intl.get('general_no_data')}
									</div>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<Button
				variant='tertiary'
				iconBefore={<PlusIcon />}
				onClick={handleAddRule}
			>
				{intl.get('add_interval')}
			</Button>
		</div>
	)
}
export function RepricingLimitsFieldController({
	name,
	defaultValue,
	schema,
}: {
	name: string
	defaultValue: RepricingLimitEntity[]
	schema: JSONSchema
}) {
	const { control } = useFormContext()
	const { data: columns } = useColumnsSchemaQuery()
	return (
		<Controller
			name={name}
			defaultValue={defaultValue}
			control={control}
			render={({ field, fieldState: { error } }) => {
				return (
					<>
						<RepricingLimits
							columns={columns}
							schema={schema as JSONSchemaArray<RepricingLimitEntity>}
							errorSchema={error}
							formData={field.value}
							onChange={field.onChange}
						/>
						{error?.message && <FormError>{error?.message}</FormError>}
					</>
				)
			}}
		/>
	)
}
