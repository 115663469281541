import { forwardRef, ReactNode } from 'react'

import { Button, StateButton } from '@cmpkit/base'

type FilterButtonProps = {
	children: ReactNode
	isInvalid: boolean
	isSelected: boolean
	onClick?(): void
	onClear?(): void
}

export const FilterButton = forwardRef<HTMLElement, FilterButtonProps>(
	({ children, isInvalid, isSelected, onClick, onClear }, ref) => {
		return onClear ? (
			<StateButton
				variant={isInvalid ? 'primary-danger' : 'tertiary'}
				size='small'
				onClick={onClick}
				onClear={onClear}
				active={!!onClear}
				ref={ref}
			>
				{children} {isInvalid ? '(!)' : ''}
			</StateButton>
		) : (
			<Button
				variant={isInvalid ? 'primary-danger' : 'tertiary'}
				size='small'
				onClick={onClick}
				active={isSelected}
				ref={ref}
			>
				{children} {isInvalid ? '(!)' : ''}
			</Button>
		)
	}
)
