import { useEffect } from 'react'

import IntercomSDK, { shutdown } from '@intercom/messenger-js-sdk'
import { InitType } from '@intercom/messenger-js-sdk/dist/types'

const canUseDOM = !!(
	typeof window !== 'undefined' &&
	window.document &&
	window.document.createElement
)

export default function Intercom({
	appID,
	...props
}: { appID: string } & Partial<InitType>) {
	useEffect(() => {
		IntercomSDK({ ...props, app_id: appID })
		return () => {
			if (!canUseDOM) {
				return
			}

			shutdown()
		}
	}, [])

	return null
}
