import { flatten, prop } from 'lodash/fp'
import React from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import {
	FormError,
	FormGroup,
	FormHint,
	FormLabel,
	Required,
} from '@cmpkit/base'
import HelpCircleIcon from '@cmpkit/icon/lib/glyph/help-circle'
import { AsyncSelect, AsyncSelectProps, GroupBase } from '@cmpkit/select'
import Tooltip from '@cmpkit/tooltip'

import { DataOption } from '@/common.types'

export default function RHFAsyncMultiSelect<OptionType extends DataOption>({
	name,
	label,
	rules,
	hint,
	shouldUnregister,
	defaultValue,
	required,
	description,
	...other
}: UseControllerProps &
	AsyncSelectProps<OptionType, true> & {
		label?: string
		hint?: string
		required?: boolean
		description?: string
	}) {
	const { control } = useFormContext()
	const flatOptions = flatten(
		other.options?.map((v) => {
			if ((v as GroupBase<OptionType>).options) {
				return (v as GroupBase<OptionType>).options
			} else {
				return [v] as OptionType[]
			}
		})
	) as OptionType[]
	const filterOptions = (inputValue: string) =>
		flatOptions?.filter((i) =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		) || []

	return (
		<FormGroup>
			<FormLabel>
				{label}
				{required && <Required />}
			</FormLabel>
			<Controller
				name={name}
				rules={rules}
				shouldUnregister={shouldUnregister}
				defaultValue={defaultValue}
				control={control}
				render={({ field, fieldState: { error } }) => {
					return (
						<>
							<div className='flex w-full items-center gap-2'>
								<AsyncSelect<OptionType, true>
									className='w-full'
									menuPlacement='auto'
									menuPortalTarget={document.body}
									closeMenuOnSelect={false}
									hideSelectedOptions={false}
									defaultOptions={other.options}
									loadOptions={(inputValue, callback) => {
										setTimeout(() => callback(filterOptions(inputValue)), 1000)
									}}
									{...other}
									value={flatOptions?.filter(({ value }) =>
										field.value.includes(value)
									)}
									onChange={(values) => {
										field.onChange(values?.map(prop('value')) || [])
									}}
									isMulti
								/>

								{hint && (
									<Tooltip placement='top' content={hint}>
										<HelpCircleIcon className='text-muted' />
									</Tooltip>
								)}
							</div>
							{!!description && (
								<FormHint className='items-center'>{description}</FormHint>
							)}
							{error && <FormError>{error.message}</FormError>}
						</>
					)
				}}
			/>
		</FormGroup>
	)
}
