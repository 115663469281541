import {
	entries,
	find,
	fromPairs,
	indexBy,
	isEmpty,
	map,
	pipe,
	prop,
	propEq,
	sum,
	values,
} from 'lodash/fp'
import React, { useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { Button, Spinner } from '@cmpkit/base'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import {
	Modal,
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import ErrorBoundary from '@/components/ErrorBoundary'
import { PricingCampaignModel } from '@/generated'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import { useOptimizationQuery } from '@/modules/core/queries'
import {
	getPreOrderedCampaigns,
	isGlobalPricingCampaign,
} from '@/modules/pricing-campaigns/helpers'
import { useDublicatePricingCampaignsToScenarioMutation } from '@/modules/pricing-campaigns/mutations'
import {
	useMatchedProductsQuery,
	usePricingCampaignsQuery,
} from '@/modules/pricing-campaigns/queries'
import analytic from '@/services/analytics'

import { getPricingCampaignsTableRows } from '../helpers'
import { PricingCampaignTableRow } from '../types'
import PricingCampaignsTable from './PricingCampaignsTable'

type Props = {
	scenarioId: string
	scenarioName: string
	close(): void
}
function CopyPricingCampaignToScenarioModalContent({
	close,
	scenarioId,
	scenarioName,
}: Props) {
	/**
	 * Common hooks
	 */
	const queryClient = useQueryClient()
	const optimizationGroupId = useOptimizationGroupId()!
	const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([])

	/**
	 * Queries
	 */

	const campaigns = usePricingCampaignsQuery<PricingCampaignModel[]>(
		{
			optimization_group_id: optimizationGroupId,
		},
		{
			select: getPreOrderedCampaigns,
		}
	)

	const predictedCounts = useMatchedProductsQuery(
		{ optimization_group_id: optimizationGroupId },
		campaigns.data?.map((campaign) => ({
			id: campaign.id,
			order: campaign.order,
			assignments: campaign.settings.product_assignments.map((assignment) => ({
				id: assignment.name,
				filters: assignment.filters,
			})),
		})) || [],
		{
			select: pipe([
				prop('pricing_campaigns'),
				indexBy(prop('id')),
				entries,
				map(([id, { count }]) => [id, count]),
				fromPairs,
			]),
			enabled: !isEmpty(campaigns.data),
		}
	)
	const totalProducts = useOptimizationQuery<number>(optimizationGroupId, {
		select: prop('total_products'),
	})

	const unassignedCount =
		(totalProducts.data ?? 0) - (sum(values(predictedCounts.data ?? {})) ?? 0)

	/**
	 * Mutations
	 */
	const dublicate = useDublicatePricingCampaignsToScenarioMutation({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns'],
			})
			queryClient.invalidateQueries({
				queryKey: ['pricing-campaigns-segments'],
			})
			close()
		},
	})

	const data: PricingCampaignTableRow[] = useMemo(() => {
		return getPricingCampaignsTableRows(
			campaigns.data || [],
			predictedCounts.data || {},
			totalProducts.data
		)
	}, [
		campaigns.data,
		predictedCounts.data,
		totalProducts.data,
		unassignedCount,
	])
	const handleSubmit = () => {
		analytic.logEvent('what-if: duplicate pcs to scenario: modal: submit', {
			pcs: selectedCampaigns.length,
		})
		dublicate.mutate({
			src_pricing_campaign_ids: selectedCampaigns,
			dst_scenario_ids: [scenarioId],
		})
	}

	const isValidSelection = useMemo(() => {
		return (
			selectedCampaigns.length > 0 &&
			selectedCampaigns.every((id: string) => {
				const pc = find(propEq('campaign.id', id), data)
				return pc?.campaign && !isGlobalPricingCampaign(pc.campaign)
			})
		)
	}, [selectedCampaigns, data])

	return (
		<Modal onClose={close} position='center' showCloseButton size='large'>
			<ModalHeader>
				<ModalTitle>
					{intl
						.get('scenario.copy_pcs.modal.title', { scenarioName })
						.d('Select Pricing Campaigns that you want to apply settings to')}
				</ModalTitle>
				<ModalDescription>
					{intl
						.get('scenario.copy_pcs.modal.subtitle')
						.d(`Select PCs to copy to ${scenarioName}`)}
				</ModalDescription>
			</ModalHeader>
			<ErrorBoundary>
				<ModalBody className='space-y-3'>
					<div className='flex h-96 flex-1 flex-col overflow-hidden rounded-lg border border-solid border-base bg-accent-1'>
						{!data.length ? (
							<div className='flex size-full flex-col items-center justify-center space-y-2 bg-accent-4 text-xs'>
								<SearchIcon height={72} width={72} />
								<p className='font-medium text-muted'>
									{intl.get('scenario.empty_pcs.title')}
								</p>
							</div>
						) : (
							<PricingCampaignsTable
								data={data}
								selected={selectedCampaigns}
								setSelected={setSelectedCampaigns}
							/>
						)}
					</div>
				</ModalBody>
			</ErrorBoundary>
			<ModalFooter className='justify-between space-x-2'>
				<Button
					onClick={() => {
						analytic.logEvent(
							'what-if: duplicate pcs to scenario: modal: close'
						)
						close()
					}}
				>
					{intl.get('general_cancel')}
				</Button>
				<Button
					variant='primary-brand'
					disabled={
						dublicate.isPending ||
						selectedCampaigns.length === 0 ||
						!isValidSelection
					}
					onClick={handleSubmit}
					iconAfter={dublicate.isPending ? <Spinner /> : <CaretRightIcon />}
				>
					{intl.get('scenario.copy_to_scenario')}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
export default function CopyPricingCampaignToScenarioModal({
	isOpen,
	...props
}: Props & { isOpen: boolean }) {
	return (
		<ModalTransition>
			{isOpen && <CopyPricingCampaignToScenarioModalContent {...props} />}
		</ModalTransition>
	)
}
