import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { client } from '@/network/client'
import { ChatSettingsResponseModel } from '@/generated'

export function useAiChatSettingssQuery<T = ChatSettingsResponseModel>(
	options?: UseQueryOptions<ChatSettingsResponseModel, Error, T>
) {
	return useQuery<ChatSettingsResponseModel, Error, T>({
		queryKey: ['ai-chat', 'settings'],
		queryFn: () => client.assistant.assistantChatSettingsGet(),
		...(options || {}),
	})
}
