import { PricingLineModel } from '@/generated'
import intl from '@/locale'

export const COLUMNS_WIDTH_DICT = {
	checkbox: 50,
	sku: 100,
	title: 200,
	cost: 160,
	is_locked: 56,
	last_price: 100,
	final_price: 100,
	diff_esp: 100,
	diff_esp_pp: 100,
	pricing_type: 120,
	alerts: 120,
	pricing_campaign_id: 120,
	category_ids: 200,
	brand: 200,
	stock: 60,
	last_stock_dt: 140,
	last_price_dt: 150,
}

export const COLUMNS_FIXED_DICT = {
	sku: true,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const COLUMNS_VALUE_GETTER_DICT: Record<string, (item: any) => string> =
	{
		pricing_campaign_name: (item: PricingLineModel) =>
			item?.fields?.pricing_campaign_id,
		optimization_group_name: (item) => item.fields?.optimization_group_id,
		sku: (item) => item.sku ?? item.line_id,
		title: (item) => item.title ?? item?.fields?.name,
	}

export const COLUMNS_CLASSNAME_DICT = {
	prod_image_link: 'cell-image',
}

export const PRICING_TYPE_CHOICES = [
	{
		value: 'rule_based_repricing',
		label: intl.get('pricing_type_rule_based_repricing'),
		variant: 'warning',
	},
	{
		value: 'demand_based_repricing',
		label: intl.get('pricing_type_demand_based_repricing'),
		variant: 'info',
	},
	{
		value: 'markdown_repricing',
		label: intl.get('pricing_type_markdown_repricing'),
		variant: 'epic',
	},
	{
		value: 'manual_repricing',
		label: intl.get('pricing_type_manual_repricing'),
		variant: 'default',
	},
	{
		value: 'none',
		label: intl.get('none'),
		variant: 'neutral',
	},
]
