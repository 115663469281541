import notify from '@/components/toasts'
import { AnchoringProductModel, ProductsExportRequestModel } from '@/generated'
import intl from '@/locale'
import { client } from '@/network/client'
import Sentry from '@/services/sentry'
import { download, getFileNameFromResponse } from '@/tools/report'

/**
 * Functions to download assortment file by filters and name
 * @example downloadAssortmentFile({ filters: { ... } }, 'assortment.csv')
 * @param body - ProductsRequestBody without limit and offset
 * @param name - name of file to download
 * @returns Promise<void>
 */
export const downloadAssortmentFile = (
	body: Omit<ProductsExportRequestModel, 'limit' | 'offset'>,
	name?: string
) => {
	notify.loading(
		{
			text: intl.get('report_file_loading'),
		},
		{ id: 'export' }
	)
	return client.instance
		.post('/api/v1/anchors/', body, {
			responseType: 'blob',
		})
		.then((response) => {
			download(
				new Blob([response.data]),
				name ?? getFileNameFromResponse(response)
			)
			notify.success(
				{
					text: 'Success',
				},
				{ id: 'export' }
			)
		})
		.catch(() => {
			notify.error(
				{
					text: 'Downloading failed, please try again.',
				},
				{ id: 'export' }
			)
		})
}

export async function downloadImportTemplate() {
	try {
		const Excel = await import('exceljs')
		const rows = [
			{
				sku: 's1',
				flat_anchor_sku: 's2',
				flat_anchor_add: 0.25,
				flat_anchor_mul: 1.5,
				size_anchor_sku: 's2',
				size_anchor_add: 0.25,
				size_anchor_mul: 1.5,
				product_set_anchor_sku: 's2',
				product_set_anchor_add: 0.25,
				product_set_anchor_mul: 1.5,
				ladder_anchor_sku: 's2',
				ladder_anchor_add: 0.25,
				ladder_anchor_mul: 1.5,
				bundle_anchor_sku: 's2',
				bundle_anchor_add: 0.25,
				bundle_anchor_mul: 1.5,
				source_family_id: 'f1',
				sales_entity_id: 'se1',
			},
		]

		const workbook = new Excel.Workbook()
		workbook.creator = 'Competera'
		const worksheet = workbook.addWorksheet('Sheet1')
		worksheet.columns = [
			'sku',
			'flat_anchor_sku',
			'flat_anchor_add',
			'flat_anchor_mul',
			'size_anchor_sku',
			'size_anchor_add',
			'size_anchor_mul',
			'product_set_anchor_sku',
			'product_set_anchor_add',
			'product_set_anchor_mul',
			'ladder_anchor_sku',
			'ladder_anchor_add',
			'ladder_anchor_mul',
			'bundle_anchor_sku',
			'bundle_anchor_add',
			'bundle_anchor_mul',
			'source_family_id',
			'sales_entity_id',
		].map((key) => ({ header: key, key, width: 20, outlineLevel: 1 }))
		worksheet.addRows(rows)
		const buffer = await workbook.xlsx.writeBuffer()
		const blob = new Blob([buffer], {
			type: 'application/octet-binary;charset=utf-8',
		})
		download(blob, 'anchors_import_template.xlsx')
	} catch (error) {
		Sentry.captureException(error)
		notify.warning(
			{
				text: intl.get('fatal_error_title'),
			},
			{ id: 'export' }
		)
	}
}

export function getAchorTypes(item: AnchoringProductModel) {
	return [
		(item.bundle_anchor_sku || item.bundle_anchor_count) && 'bundle',
		(item.flat_anchor_sku || item.flat_anchor_count) && 'flat',
		(item.ladder_anchor_sku || item.ladder_anchor_count) && 'ladder',
		(item.product_set_anchor_sku || item.product_set_anchor_count) &&
			'product_set',
		(item.size_anchor_sku || item.size_anchor_count) && 'size',
	].filter(Boolean)
}
