import { useCallback, useEffect, useState } from 'react'

import analytic from '@/services/analytics'
import Storage from '@/services/local-storage'

export function useTheme(): [string, () => void] {
	const [theme, setTheme] = useState<string>('')
	useEffect(() => {
		setTheme(Storage.get('theme'))
	}, [])

	const onSwithTheme = useCallback(() => {
		const theme = Storage.get('theme')
		const newTheme = theme === 'dark' ? 'light' : 'dark'
		analytic.logEvent(`theme: switch to ${newTheme} mode`)
		const identifyObj = new analytic.Identify()
		identifyObj.set('uiTheme', newTheme)
		analytic.identify(identifyObj)
		setTheme(newTheme)
		if (theme === 'dark') {
			Storage.remove('theme')
			document.body.removeAttribute('data-theme')
		} else {
			Storage.set({ theme: newTheme })
			document.body.setAttribute('data-theme', newTheme)
		}
	}, [setTheme])

	return [theme, onSwithTheme]
}
