import { addDays, format } from 'date-fns'
import { any, prop } from 'lodash/fp'
import React from 'react'

import intl from '@/locale'
import { useBIRevenueAnalyticQuery } from '@/modules/bi/queries'
import { getDifferenceInPercents } from '@/modules/dashboards/helpers'
import { DateFormat } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'

import PercentDifferenceBadge from '../../PercentDifferenceBadge'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import { WidgetProps } from '../types'

export default function NumericRevenueUnderManagementWidget({
	widget,
	commonPeriods,
	commonFilters,
}: WidgetProps) {
	const start_date = format(
		addDays(new Date(commonPeriods.main.end_date), -14),
		DateFormat.system
	)
	const end_date = commonPeriods.main.end_date
	const biCommonQuery = {
		start_date,
		end_date,
		revenue_start_date: start_date,
		revenue_end_date: end_date,
		label: widget.kind,
		metrics: ['revenue'],
		date_aggregation: null,
		dimensions: [],
		skip_dates: true,
	}
	const biQueryConfig = {
		select: prop('data.0.0'),
	}

	const biComparedPeriodQuery = useBIRevenueAnalyticQuery<number>(
		{
			...biCommonQuery,
			filters: [
				{
					name: 'optimization_lag',
					operation: 'eq',
					value: 2,
				},
				{
					name: 'is_repricing_today',
					operation: 'bl',
					value: true,
				},
				{
					name: 'price_change_status',
					operation: 'is_not',
					value: 'locked',
				},
				...commonFilters,
			],
		},
		biQueryConfig
	)
	const biMainPeriodQuery = useBIRevenueAnalyticQuery<number>(
		{
			...biCommonQuery,
			filters: [
				{
					name: 'optimization_lag',
					operation: 'eq',
					value: 1,
				},
				{
					name: 'is_repricing_today',
					operation: 'bl',
					value: true,
				},
				{
					name: 'price_change_status',
					operation: 'is_not',
					value: 'locked',
				},
				...commonFilters,
			],
		},
		biQueryConfig
	)
	const isLoading = any(prop('isFetching'), [
		biMainPeriodQuery,
		biComparedPeriodQuery,
	])
	const diff = getDifferenceInPercents(
		biMainPeriodQuery.data!,
		biComparedPeriodQuery.data!
	)
	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				title={intl.get('widget.num_revenue_under_management.title')}
				tooltip={intl.get('widget.revenue_under_management.tooltip')}
			/>
			<WidgetContent className='truncate'>
				{isLoading ? (
					<div className='col-span-2 mt-auto h-10 w-32 animate-pulse rounded-lg bg-accent-3' />
				) : (
					<div className='flex flex-col space-y-1'>
						{Number.isFinite(diff) ? (
							<div className='flex items-center space-x-2'>
								<PercentDifferenceBadge value={diff} />
								<span className='truncate text-xs text-muted'>
									({intl.get('past')}:{' '}
									{formatNumber(
										biComparedPeriodQuery.data!,
										NumberFormats.Compact
									)}
									)
								</span>
							</div>
						) : null}
						<span className='truncate text-3xl font-semibold'>
							{formatNumber(biMainPeriodQuery.data!, NumberFormats.Compact)}
						</span>
					</div>
				)}
			</WidgetContent>
		</Widget>
	)
}
