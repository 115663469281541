import { useState } from 'react'

import { Button, Checkbox } from '@cmpkit/base'

import intl from '@/locale'

export default function ExportDialogActions({
	resolve,
}: {
	resolve: (value: { action: 'ok' | 'close'; state: { save: boolean } }) => void
}) {
	const [save, setSave] = useState(false)
	return (
		<div className='flex items-center px-5 pb-5 pt-2'>
			<Button
				onClick={() => {
					resolve({ action: 'close', state: { save } })
				}}
			>
				{intl.get('general_cancel')}
			</Button>
			<div className='ml-auto' />
			<label className='flex items-center space-x-2'>
				<Checkbox checked={save} onChange={() => setSave(!save)} />
				<span className='select-none font-medium'>
					{intl.get('optimization_remove_export_checkbox')}
				</span>
			</label>

			<Button
				variant='primary-brand'
				className='ml-2'
				onClick={() => {
					resolve({ action: 'ok', state: { save } })
				}}
			>
				{intl.get('general_ok')}
			</Button>
		</div>
	)
}
