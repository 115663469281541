import { Column, Row } from '@tanstack/react-table'

import {
	Boundary,
	CollapsedGrid,
	GridCell,
	GridColumn,
	GridRow,
	Viewport,
} from './types'

export const TABLE_FLEX_TYPE = Symbol('flex')
export const TABLE_STUB_TYPE = Symbol('stub')
export const TABLE_DATA_TYPE = Symbol('data')
export const TABLE_NODATA_TYPE = Symbol('nodata')

const empty = {
	start: Number.POSITIVE_INFINITY,
	end: Number.NEGATIVE_INFINITY,
}
const intersect = (
	a: {
		start: number
		end: number
	},
	b: {
		start: number
		end: number
	}
) => {
	if (a.end < b.start || b.end < a.start) {
		return empty
	}
	return {
		start: Math.max(a.start, b.start),
		end: Math.min(a.end, b.end),
	}
}
export const getVisibleBoundaryWithFixed = <T>(
	visibleBoundary: [number, number],
	items: Column<T>[]
) => {
	return items.reduce(
		(acc, item, index) => {
			if (
				item.getIsPinned() &&
				(index < visibleBoundary[0] || index > visibleBoundary[1])
			) {
				acc.push([index, index])
			}
			return acc
		},
		[visibleBoundary]
	)
}

export const getVisibleBoundary = <T>(
	items: T[],
	viewportStart: number,
	viewportSize: number,
	getItemSize: (item?: T) => number,
	skipItems: Boundary,
	offset = 0
): Boundary => {
	let start: number | undefined
	let end: number | undefined

	let index =
		// eslint-disable-next-line
		items[0] && (items[0] as any).index >= skipItems[0] ? 0 : skipItems[0]
	const itemSize = getItemSize()
	let beforePosition = offset !== 0 ? (offset - skipItems[0]) * itemSize : 0

	const viewportEnd = viewportStart + viewportSize

	while (end === undefined && index < items.length) {
		const item = items[index]
		const afterPosition = beforePosition + getItemSize(item)
		const isVisible =
			(beforePosition >= viewportStart && beforePosition < viewportEnd) ||
			(afterPosition > viewportStart && afterPosition <= viewportEnd) ||
			(beforePosition < viewportStart && afterPosition > viewportEnd)
		if (isVisible && start === undefined) {
			start = index
		}
		if (!isVisible && start !== undefined) {
			end = index - 1
			break
		}
		index += 1
		beforePosition = afterPosition
	}
	if (start !== undefined && end === undefined) {
		end = index - 1
	}
	end = end === undefined ? 0 : end
	start = start === undefined ? 0 : start

	return [start + offset, end + offset]
}

export const getRenderBoundary = (
	itemsCount: number,
	visibleBoundary: [number, number],
	overscan: number
): [number, number] => {
	let [start, end] = visibleBoundary
	start = Math.max(0, start - overscan)
	end = Math.min(itemsCount - 1, end + overscan)

	return [start, end]
}

export const getColumnBoundaries = <T>(
	columns: Column<T>[],
	left: number,
	width: number,
	getColSize:
		| ((column: Column<T>) => number)
		| ((column?: Column<T>) => undefined)
) =>
	getVisibleBoundaryWithFixed(
		getColumnsRenderBoundary(
			columns.length,
			getVisibleBoundary(
				columns,
				left,
				width,
				getColSize as (column?: Column<T>) => number,
				[0, 0],
				0
			)
		),
		columns
	)
export const getRowsVisibleBoundary = <T>(
	rows: Row<T>[],
	top: number,
	height: number,
	getRowHeight: (row?: Row<T>) => number,
	skipItems: Boundary,
	offset: number,
	isDataRemote: boolean
): Boundary => {
	const rowHeight = getRowHeight()
	const beforePosition = offset !== 0 ? (offset - skipItems[0]) * rowHeight : 0
	const noVisibleRowsLoaded =
		(rowHeight > 0 && beforePosition + rows.length * rowHeight < top) ||
		top < beforePosition

	let boundaries
	if (isDataRemote && noVisibleRowsLoaded) {
		const topIndex: number = Math.round(top / rowHeight) + skipItems[0]
		boundaries = [topIndex, topIndex] as Boundary
	} else {
		boundaries = getVisibleBoundary(
			rows,
			top,
			height,
			getRowHeight,
			skipItems,
			offset
		)
	}

	return boundaries
}

export const getColumnsRenderBoundary = (
	columnCount: number,
	visibleBoundary: Boundary
) => getRenderBoundary(columnCount, visibleBoundary, 1)

export const getRowsRenderBoundary = (
	rowsCount: number,
	visibleBoundary: Boundary
) => getRenderBoundary(rowsCount, visibleBoundary, 3)

export const getSpanBoundary = <T>(
	items: T[],
	visibleBoundaries: Boundary[],
	getItemSpan: (item: T) => number
): Boundary[] =>
	visibleBoundaries.map((visibleBoundary) => {
		const endIndex = Math.min(visibleBoundary[1], items.length - 1)
		let end = endIndex
		let start = visibleBoundary[0] <= end ? visibleBoundary[0] : 0

		for (let index = 0; index <= endIndex; index += 1) {
			const span = getItemSpan(items[index])
			if (index < visibleBoundary[0] && index + span > visibleBoundary[0]) {
				start = index
			}
			if (index + (span - 1) > visibleBoundary[1]) {
				end = index + (span - 1)
			}
		}
		return [start, end]
	})

export const collapseBoundaries = (
	itemsCount: number,
	visibleBoundaries: Boundary[],
	spanBoundaries: Boundary[][]
) => {
	const breakpoints = new Set([0, itemsCount])
	spanBoundaries.forEach((rowBoundaries) =>
		rowBoundaries.forEach((boundary) => {
			breakpoints.add(boundary[0])
			// next interval starts after span end point
			breakpoints.add(Math.min(boundary[1] + 1, itemsCount))
		})
	)

	visibleBoundaries
		.filter((boundary) =>
			boundary.every((bound) => 0 <= bound && bound < itemsCount)
		)
		.forEach((boundary) => {
			for (let point = boundary[0]; point <= boundary[1]; point += 1) {
				breakpoints.add(point)
			}
			if (boundary[1] + 1 < itemsCount) {
				// close last visible point
				breakpoints.add(boundary[1] + 1)
			}
		})

	const bp = [...(breakpoints as unknown as number[])].sort((a, b) => a - b)
	const bounds: Boundary[] = []
	for (let i = 0; i < bp.length - 1; i += 1) {
		bounds.push([bp[i], bp[i + 1] - 1])
	}

	return bounds
}

const getItemsSize = <T>(
	items: T[],
	startIndex: number,
	endIndex: number,
	getItemSize: (item: T) => number
) => {
	let size = 0
	for (let i = startIndex; i <= endIndex; i += 1) {
		size += getItemSize(items[i])
	}
	return size
}

export const getCollapsedColumns = <T>(
	columns: Column<T>[],
	visibleBoundaries: Boundary[],
	boundaries: Boundary[],
	getColumnWidth: (column: Column<T>) => number
) => {
	const collapsedColumns: GridColumn<T>[] = []
	boundaries.forEach((boundary) => {
		const isVisible = visibleBoundaries.reduce(
			(acc, visibleBoundary) =>
				acc ||
				(visibleBoundary[0] <= boundary[0] &&
					boundary[1] <= visibleBoundary[1]),
			false
		)

		if (isVisible) {
			const column = columns[boundary[0]]
			collapsedColumns.push({
				...column,
				width: getColumnWidth(column),
			})
		} else {
			collapsedColumns.push({
				key: `${TABLE_STUB_TYPE.toString()}_${boundary[0]}_${boundary[1]}`,
				type: TABLE_STUB_TYPE,
				width: getItemsSize(columns, boundary[0], boundary[1], getColumnWidth),
			})
		}
	})
	return collapsedColumns
}

export const getCollapsedRows = <T>(
	rows: Row<T>[],
	visibleBoundary: Boundary,
	boundaries: Boundary[],
	skipItems: Boundary,
	getRowHeight: (row: Row<T>) => number,
	getCells: (row: Row<T>) => GridCell<T>[],
	offset: number
) => {
	const collapsedRows: GridRow<T>[] = []
	boundaries.forEach((boundary) => {
		const isVisible =
			visibleBoundary[0] <= boundary[0] && boundary[1] <= visibleBoundary[1]

		const row = rows[boundary[0] - offset]
		if (isVisible) {
			collapsedRows.push({
				row,
				dataIndex: boundary[0] - offset,
				cells: getCells(row),
			})
		} else {
			collapsedRows.push({
				row: {
					key: `${TABLE_STUB_TYPE.toString()}_${boundary[0]}_${boundary[1]}`,
					type: TABLE_STUB_TYPE,
					height: calculateRowHeight(
						rows,
						skipItems,
						getRowHeight,
						boundary[0],
						boundary[1]
					),
				},
				cells: getCells(row),
			})
		}
	})
	return collapsedRows
}

const calculateRowHeight = <T>(
	rows: Row<T>[],
	skipItems: Boundary,
	getRowHeight: (row: Row<T>) => number,
	bound1: number,
	bound2: number
) => {
	if (bound1 === 0) {
		let end = bound2
		if (rows.length && bound2 > Number(rows[rows.length - 1].index!)) {
			end = bound2 - skipItems[1]
		}
		return getItemsSize(rows, skipItems[0], end, getRowHeight)
	}
	return getItemsSize(rows, bound1, bound2 - skipItems[1], getRowHeight)
}

/* const getStubSpan = ([startIndex, endIndex]: [number, number]) =>
	1 + (endIndex - startIndex) */
export const getCollapsedCells = <T>(
	row: Row<T>,
	columns: Column<T>[],
	spanBoundaries: Boundary[],
	boundaries: Boundary[],
	getColSpan: (row: Row<T>, column: Column<T>) => number
) => {
	const collapsedCells: GridCell<T>[] = []
	let index = 0
	while (index < boundaries.length) {
		const boundary = boundaries[index]
		const isSpan = spanBoundaries.reduce(
			(acc, spanBoundary) =>
				acc ||
				(spanBoundary[0] <= boundary[0] && boundary[1] <= spanBoundary[1]),
			false
		)
		if (isSpan) {
			const column = columns[boundary[0]]
			const realColSpan = getColSpan(row, column)
			if (realColSpan + index - 1 !== columns.length) {
				const realColSpanEnd = realColSpan + boundary[0] - 1
				const colSpanEnd = boundaries.findIndex(
					(colSpanBoundary) =>
						colSpanBoundary[0] <= realColSpanEnd &&
						realColSpanEnd <= colSpanBoundary[1]
				)
				collapsedCells.push({
					column,
					index: boundary[0],
					colSpan: colSpanEnd - index + 1,
				})
			} else {
				collapsedCells.push({
					column,
					index: boundary[0],
					colSpan: realColSpan,
				})
			}
			index += 1
		} else {
			collapsedCells.push({
				index,
				column: {
					key: `${TABLE_STUB_TYPE.toString()}_${boundary[0]}_${boundary[1]}`,
					type: TABLE_STUB_TYPE,
					width: getItemsSize(columns, boundary[0], boundary[1], (col) =>
						col.getSize()
					),
				},
				colSpan: 1, //getStubSpan(boundary),
			})
			index += 1
		}
	}
	return collapsedCells
}

const getVisibleColumnBoundaries = <T>(
	rows: Row<T>[],
	boundaries: Boundary,
	columns: Column<T>[],
	columnsVisibleBoundary: Boundary[],
	getColSpan: (row: Row<T>, column: Column<T>) => number
) => {
	const rowSpanBoundaries = rows
		.slice(boundaries[0], boundaries[1] + 1)
		.map((row): Boundary[] =>
			getSpanBoundaryByRow(row, columns, columnsVisibleBoundary, getColSpan)
		)
	return collapseBoundaries(
		columns.length,
		columnsVisibleBoundary,
		rowSpanBoundaries
	)
}

export const getCollapsedGrid = <T>({
	rows,
	columns,
	rowsVisibleBoundary,
	columnsVisibleBoundary,
	getColumnWidth,
	getRowHeight,
	getColSpan,
	totalRowCount,
	offset,
}: {
	rows: Row<T>[]
	columns: Column<T>[]
	rowsVisibleBoundary: Boundary
	columnsVisibleBoundary: Boundary[]
	getColumnWidth: (column: Column<T>) => number
	getRowHeight: (row: Row<T>) => number
	getColSpan: (row: Row<T>, column: Column<T>) => number
	totalRowCount: number
	offset: number
}) => {
	if (!columns.length) {
		return {
			columns: [],
			rows: [],
		}
	}

	const boundaries = rowsVisibleBoundary || [0, rows.length - 1 || 1]
	const columnBoundaries = getVisibleColumnBoundaries(
		rows,
		boundaries,
		columns,
		columnsVisibleBoundary,
		getColSpan
	)
	const rowBoundaries = collapseBoundaries(totalRowCount!, [boundaries], [])

	return {
		columns: getCollapsedColumns<T>(
			columns,
			columnsVisibleBoundary,
			columnBoundaries,
			getColumnWidth
		),
		rows: getCollapsedRows<T>(
			rows,
			boundaries,
			rowBoundaries,
			[0, 0],
			getRowHeight,
			(row) =>
				getCollapsedCells(
					row,
					columns,
					getSpanBoundaryByRow(
						row,
						columns,
						columnsVisibleBoundary,
						getColSpan
					),
					columnBoundaries,
					getColSpan
				),
			offset
		),
	}
}

/* export const getColumnWidthGetter = (
	tableColumns,
	tableWidth,
	minColumnWidth
) => {
	const colsHavingWidth = tableColumns.filter(
		(col) => typeof col.width === 'number'
	)
	const columnsWidth = colsHavingWidth.reduce(
		(acc, col) => acc + (col.width as number)!,
		0
	)
	const autoWidth =
		(tableWidth - columnsWidth) / (tableColumns.length - colsHavingWidth.length)
	const autoColWidth = Math.max(autoWidth, minColumnWidth!)

	return (column) => {
		if (column) {
			return column.type === TABLE_FLEX_TYPE
				? 0
				: typeof column.width === 'number'
					? column.width
					: autoColWidth
		}
		return autoColWidth
	}
} */

const getSpanBoundaryByRow = <T>(
	row: Row<T>,
	columns: Column<T>[],
	visibleColumns: Boundary[],
	getColSpan: (row: Row<T>, column: Column<T>) => number
): Boundary[] =>
	getSpanBoundary(columns, visibleColumns, (column) => getColSpan(row, column))

export const getCollapsedGrids = <T>({
	bodyRows,
	columns,
	loadedRowsStart,
	totalRowCount,
	getCellColSpan,
	viewport,
	skipItems,
	getRowHeight,
	getColumnWidth,
}: {
	headerRows: Row<T>[]
	bodyRows: Row<T>[]
	footerRows: Row<T>[]
	columns: Column<T>[]
	loadedRowsStart: number
	totalRowCount: number
	getCellColSpan: (row: Row<T>, column: Column<T>) => number
	viewport: Viewport
	skipItems: [number, number]
	getRowHeight: (row: Row<T>) => number
	getColumnWidth: (column: Column<T>) => number
}): CollapsedGrid<T> => {
	if (!columns.length) {
		return {
			headerGrid: { columns: [], rows: [] },
			bodyGrid: { columns: [], rows: [] },
			footerGrid: { columns: [], rows: [] },
		}
	}

	const getCollapsedGridRows = (
		rows: Row<T>[],
		rowsBoundary: Boundary,
		columnsBoundary: Boundary[],
		rowCount: number = rows.length,
		offset: number = 0
	) => {
		return getCollapsedRows(
			rows,
			rowsBoundary,
			collapseBoundaries(rowCount, [rowsBoundary], []),
			skipItems,
			getRowHeight,
			(row) =>
				getCollapsedCells(
					row,
					columns,
					getSpanBoundaryByRow(row, columns, viewport.columns, getCellColSpan),
					columnsBoundary,
					getCellColSpan
				),
			offset
		)
	}

	const rowsVisibleBoundary = adjustedRenderRowBounds(
		viewport.rows,
		bodyRows.length,
		loadedRowsStart
	)
	const columnBoundaries = getVisibleColumnBoundaries<T>(
		bodyRows,
		rowsVisibleBoundary,
		columns,
		viewport.columns,
		getCellColSpan
	)
	const commonColumns = getCollapsedColumns(
		columns,
		viewport.columns,
		columnBoundaries,
		getColumnWidth
	)

	return {
		headerGrid: {
			columns: commonColumns,
			rows: [],
		},
		bodyGrid: {
			columns: commonColumns,
			rows: getCollapsedGridRows(
				bodyRows,
				rowsVisibleBoundary,
				columnBoundaries,
				totalRowCount || 1,
				loadedRowsStart
			),
		},
		footerGrid: {
			columns: commonColumns,
			rows: [],
		},
	}
}

const adjustedRenderRowBounds = (
	visibleBounds: Boundary,
	rowCount: number,
	loadedRowsStart: number
): Boundary => {
	const renderRowBoundaries = getRowsRenderBoundary(
		loadedRowsStart + rowCount,
		visibleBounds
	)
	const adjustedInterval = intersect(
		{ start: renderRowBoundaries[0], end: renderRowBoundaries[1] },
		{ start: loadedRowsStart, end: loadedRowsStart + rowCount }
	)
	return [adjustedInterval.start, adjustedInterval.end]
}
