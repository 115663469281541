import * as qs from 'qs'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'

import { Button, LinkButton } from '@cmpkit/base'
import DownloadIcon from '@cmpkit/icon/lib/glyph/download'
import LinkIcon from '@cmpkit/icon/lib/glyph/link'
import Tooltip from '@cmpkit/tooltip'

import { FlagField } from '@/components/data-grid/fields'
import { encodeComplexQuery } from '@/components/data-grid/helpers'
import { DataTable } from '@/components/tables'
import { SavedFilterModel } from '@/generated'
import intl from '@/locale'

import SavedReportsActionsDropdown, {
	SavedReportsActionsHandlers,
} from './SavedReportsActionsDropdown'
import SavedReportsReportCounter from './SavedReportsReportCounter'

type SavedReportTableRow = SavedFilterModel & {
	is_global: boolean
}
export default function SavedReportsTable({
	rows,
	handlers,
}: {
	rows: SavedReportTableRow[]
	handlers: SavedReportsActionsHandlers
}) {
	const [sorting, setSorting] = useState<SortingState>([])
	const columns = useMemo(() => getColumns({ handlers }), [handlers])
	const table = useReactTable<SavedReportTableRow>({
		data: rows,
		columns,
		state: {
			sorting,
		},
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	return <DataTable table={table} getRowClassName={() => 'h-12'} />
}

const columnHelper = createColumnHelper<SavedReportTableRow>()
const getColumns = ({
	handlers,
}: {
	handlers: SavedReportsActionsHandlers
}) => [
	columnHelper.accessor('name', {
		size: 350,
		header: () => intl.get('report_name'),
		cell: (info) => {
			return (
				<LinkButton variant='brand' asChild className='truncate'>
					<Link
						to={`/p/assortment/products${qs.stringify(
							{
								qf: encodeComplexQuery({
									filters: info.row.original.params.query,
								}),
							},
							{
								addQueryPrefix: true,
							}
						)}`}
					>
						<div className='flex items-center space-x-2'>{info.getValue()}</div>
					</Link>
				</LinkButton>
			)
		},
	}),
	columnHelper.accessor('is_global', {
		size: 100,
		header: () => intl.get('app.is_global'),
		cell: (info) => {
			return <FlagField value={info.getValue()} />
		},
	}),
	columnHelper.accessor('params.query', {
		size: 200,
		header: () => intl.get('app.products'),
		cell: (info) => {
			const filters = info.getValue()
			return (
				<LinkButton variant='brand' asChild className='truncate'>
					<Link
						to={`/p/assortment/products${qs.stringify(
							{
								qf: encodeComplexQuery({
									filters,
								}),
							},
							{
								addQueryPrefix: true,
							}
						)}`}
					>
						<SavedReportsReportCounter filters={filters || []} />
					</Link>
				</LinkButton>
			)
		},
	}),
	columnHelper.accessor('params.description', {
		header: () => intl.get('general_description'),
		cell: (info) => {
			return <span className='block truncate'>{info.getValue()}</span>
		},
	}),
	columnHelper.accessor('id', {
		size: 56,
		header: () => null,
		cell: (info) => {
			return (
				<div className='flex items-center justify-end space-x-1'>
					<Tooltip
						content={intl.get('app.download_report').d('Download report')}
					>
						<Button
							variant='tertiary'
							size='small'
							onClick={() => handlers.onDownloadClick?.(info.row.original)}
							iconBefore={<DownloadIcon />}
						/>
					</Tooltip>
					<Tooltip
						content={intl
							.get('action.open_in_new_window')
							.d('Open in new window')}
					>
						<Button
							variant='tertiary'
							size='small'
							onClick={() => handlers.onOpenClick?.(info.row.original)}
							iconBefore={<LinkIcon />}
						/>
					</Tooltip>
					<SavedReportsActionsDropdown
						reportItem={info.row.original}
						{...handlers}
					/>
				</div>
			)
		},
	}),
]
