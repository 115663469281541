import { any, map, paths, pipe, prop, uniqBy } from 'lodash/fp'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import { Button, Loader, Spinner } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { DataOption } from '@/common.types'
import { dialog } from '@/components/dialogs'
import { toAdaptedSchema } from '@/components/filter/adapter'
import {
	FormProvider,
	RHFCheckbox,
	RHFTextArea,
	RHFTextField,
} from '@/components/HookForm'
import RHFQueryBuilder from '@/components/HookForm/RHFQueryBuilder'
import { FilterRuleModel, SavedFilterModel } from '@/generated'
import intl from '@/locale'
import { useAuthorization } from '@/modules/Auth/authorization'
import { getAlertAnnotationDataChoices } from '@/modules/core'
import { getSalesEntitiesTree } from '@/modules/core/helpers'
import {
	useBrandsQuery,
	useCategoriesQuery,
	useOptimizationGroupsQuery,
	usePricingAlertsAnnotationsQuery,
	useSalesEntitiesQuery,
} from '@/modules/core/queries'
import { toCategoriesTree } from '@/modules/core/utils'
import { useColumnsSchema } from '@/modules/global-assortment/columns'
import {
	useCreateGlobalFilterMutation,
	useCreatePersonalFilterMutation,
	useDeleteGlobalFilterMutation,
	useDeletePersonalFilterMutation,
	useUpdateGlobalFilterMutation,
	useUpdatePersonalFilterMutation,
} from '@/modules/preferences/mutations'
import { usePricingCampaignsQuery } from '@/modules/pricing-campaigns/queries'
import analytic from '@/services/analytics'
import {
	actUpdated,
	errorMessage,
	processingMessage,
	successActionMessage,
} from '@/tools/message'
import { toOption } from '@/tools/utils'

type UpdateReportModal = {
	report: SavedFilterModel & { is_global?: boolean }
	isOpen: boolean
	close(): void
}
const getSalesLevels = pipe([
	prop('sales_levels'),
	map(paths(['level', 'name'])),
	toOption,
])
export default function SavedReportsUpdateReportModal({
	isOpen,
	close,
	report,
}: UpdateReportModal) {
	const { checkPermissons } = useAuthorization()
	const queryClient = useQueryClient()
	const schema = useColumnsSchema()

	const canManageGlobalFilters = checkPermissons(['MANAGE_GLOBAL_FILTERS'])

	const onSuccess = () => {
		return Promise.all([
			queryClient.invalidateQueries({
				queryKey: ['global-filters'],
			}),
			queryClient.invalidateQueries({
				queryKey: ['personal-filters'],
			}),
		])
	}

	/**
	 * Queries
	 */
	const { data: categories } = useCategoriesQuery<DataOption[]>(
		{},
		{ select: toCategoriesTree }
	)
	const { data: brands } = useBrandsQuery<DataOption[]>(
		{},
		{ select: formatOptions }
	)
	const { data: pricingCampaigns } = usePricingCampaignsQuery<DataOption[]>(
		{},
		{
			select: formatOptions,
		}
	)
	const { data: optimizationGroups } = useOptimizationGroupsQuery<DataOption[]>(
		{
			select: formatOptions,
		}
	)
	const salesEntitiesQuery = useSalesEntitiesQuery()
	const { data: alerts } = usePricingAlertsAnnotationsQuery({
		select: getAlertAnnotationDataChoices,
	})

	/**
	 * Mutations
	 */
	const deleteGlobalFilter = useDeleteGlobalFilterMutation({
		onSuccess,
	})
	const updateGlobalFilter = useUpdateGlobalFilterMutation({
		onSuccess,
	})
	const deletePersonalFilter = useDeletePersonalFilterMutation({
		onSuccess,
	})
	const updatePersonalFilter = useUpdatePersonalFilterMutation({
		onSuccess,
	})
	const createGlobalFilter = useCreateGlobalFilterMutation({
		onSuccess,
	})
	const createPersonalFilter = useCreatePersonalFilterMutation({
		onSuccess,
	})

	/**
	 * Computed values
	 */

	const fields = useMemo(
		() =>
			schema
				.map((schema) =>
					schema.name === 'optimization_group_name'
						? {
								...schema,
								name: 'optimization_group_id',
								translation_key: 'field_schema_optimization_group',
								filter_schema: {
									...schema.filter_schema,
									type: 'multiselect',
								},
							}
						: schema
				)
				.filter(({ name }) => !excludeSchemaFields.includes(name))
				.map(toAdaptedSchema),
		[schema]
	)

	const dataChices = {
		alerts: alerts || [],
		optimization_group_id: optimizationGroups || [],
		pricing_campaign_id: pricingCampaigns || [],
		brands: brands || [],
		category_ids: categories || [],
		sales_level_id: getSalesLevels(salesEntitiesQuery.data) || [],
		pos_ids: getSalesEntitiesTree(salesEntitiesQuery.data) || [],
	}

	const isPending = any(prop('isPending'), [
		deleteGlobalFilter,
		updateGlobalFilter,
		deletePersonalFilter,
		updatePersonalFilter,
		createGlobalFilter,
		createPersonalFilter,
	])

	const methods = useForm({
		defaultValues: {
			name: report.name,
			description: report.params.description,
			is_global: report.is_global,
			query: report.params.query ?? [],
		},
	})
	const handleDelete = async () => {
		if (!report?.id) return
		const answer = await dialog.confirmDelete({
			title: intl.get('general_detete_confirm_title'),
			text: intl.get('general_detete_confirm_subtitle'),
		})
		if (!answer) return
		if (!report.is_global) {
			close()
			await deletePersonalFilter.mutateAsync(report.id)
		} else {
			await deleteGlobalFilter.mutateAsync(report.id)
			close()
		}
	}
	const _handleSubmit = async ({
		is_global,
		name,
		description,
		query,
	}: {
		is_global?: boolean
		name: string
		description?: string
		query: FilterRuleModel[]
	}) => {
		toast.loading(processingMessage(), {
			id: 'filters',
		})
		analytic.logEvent('reports: update report', {
			'Is global': is_global,
		})
		const body = {
			name,
			params: {
				description,
				query,
			},
		}
		try {
			if (is_global) {
				if (!report.is_global) {
					await deletePersonalFilter.mutateAsync(report.id)
					await createGlobalFilter.mutateAsync(body)
				} else {
					await updateGlobalFilter.mutateAsync({ id: report.id, ...body })
				}
			} else {
				if (report.is_global) {
					await deleteGlobalFilter.mutateAsync(report.id)
					await createPersonalFilter.mutateAsync(body)
				} else {
					await updatePersonalFilter.mutateAsync({ id: report.id, ...body })
				}
			}
			toast.success(successActionMessage('Report', actUpdated()), {
				id: 'filters',
			})
		} catch (error) {
			toast.error(errorMessage(), {
				id: 'filters',
			})
		} finally {
			close()
		}
	}
	const handleSubmit = methods.handleSubmit(_handleSubmit)

	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={close} showCloseButton size='small' zIndex={1850}>
					<ModalHeader>
						<ModalTitle>
							{intl.get('saved_reports.update.modal.title')}
						</ModalTitle>
					</ModalHeader>
					<ModalBody className='flex flex-col'>
						<Blanket
							isTinted={isPending}
							className='absolute flex items-center justify-center bg-white/50 backdrop-blur-sm dark:bg-black/50'
						>
							<Loader />
						</Blanket>
						<FormProvider methods={methods} onSubmit={handleSubmit}>
							<RHFTextField
								label={intl.get('report_name')}
								name='name'
								className='w-full'
							/>
							<RHFTextArea
								label={intl.get('general_description')}
								name='description'
								className='w-full'
								rows={6}
							/>
							{canManageGlobalFilters && (
								<RHFCheckbox
									label={intl.get('make_visible_for_all')}
									name='is_global'
								/>
							)}
							<RHFQueryBuilder
								name={'query'}
								label={intl.get('app.filters').d('Filters')}
								fields={fields}
								dataChoices={dataChices}
							/>
						</FormProvider>
					</ModalBody>
					<ModalFooter className='justify-between'>
						<Button onClick={close}>{intl.get('general_cancel')}</Button>
						<div className='flex items-center gap-2'>
							<Button iconBefore={<Trash2Icon />} onClick={handleDelete}>
								{intl.get('general_delete')}
							</Button>
							<Button
								variant='primary-brand'
								iconBefore={
									isPending ? <Spinner variant='invert' /> : <CheckIcon />
								}
								disabled={isPending}
								onClick={handleSubmit}
							>
								{intl.get('general_save')}
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}

const formatOptions = pipe([
	map(paths(['id', 'name'])),
	toOption,
	uniqBy('value'),
])
const excludeSchemaFields = ['optimization_group_status']
