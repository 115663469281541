/* eslint-disable @typescript-eslint/no-explicit-any */
export const isObject = (o: any) =>
	typeof o === 'object' && o !== null && !Array.isArray(o)
export const isPromise = (p: any) => p.then && typeof p.then === 'function'
export const isEmptyString = (str: any) =>
	typeof str === 'string' && str.length === 0

type X = Record<string, any>
type Z = Array<any>

// Array Helpers
// ------------------------------

export const diffArr = (a: Z, b: Z): Z => a.filter((i) => b.indexOf(i) < 0)

// NOTE: only use `isEqualArr` for primitives (string, number etc.) like "keys"
const stringifyArr = (a: Z): string => a.filter(Boolean).sort().join(',')
export const isEqualArr = (a: Z, b: Z): boolean => {
	return stringifyArr(a) === stringifyArr(b)
}

// Object Helpers
// ------------------------------

type CloneObjOptions = { add?: X; remove?: string }
export const cloneObj = (obj: X, options: CloneObjOptions = {}) => {
	const { add, remove } = options
	// add key/value pair
	if (add) {
		return { ...obj, ...add }
	}

	// remove by key
	if (remove) {
		const n = { ...obj }
		delete n[remove]
		return n
	}

	return { ...obj }
}

export const objectMap = (object: X, mapFn: (a: any, b: string) => any) => {
	return Object.keys(object).reduce((res, key) => {
		const result = cloneObj(res)
		const value = mapFn(object[key], key)

		if (value) {
			result[key] = value
			return result
		}

		return res
	}, {})
}

// String Helpers
// ------------------------------

export const stringCompare = (a: any, b: any): boolean => {
	return JSON.stringify(a) === JSON.stringify(b)
}
