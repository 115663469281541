import React from 'react'
import { useParams } from 'react-router'

import { Loader, Result } from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import { getSalesEntitiesTree } from '@/modules/core/helpers'
import { useSalesEntitiesQuery } from '@/modules/core/queries'

import { usePromoCampaignsQuery } from '../queries'
import PromoCampaignForm from './PromoCampaignForm'

export default function PromoCampaignPage() {
	const { id } = useParams()
	const isNew = !id

	const promoCampaigns = usePromoCampaignsQuery()
	const salesEntitiesQuery = useSalesEntitiesQuery()

	const promoCampaign = promoCampaigns?.data?.find(
		(campaign) => campaign.id === id
	)
	const propsTo = {
		isLoadingList: promoCampaigns.isLoading,
		promoCampaign,
		isNew,
		canEdit: promoCampaign ? promoCampaign.source != 'integration' : true,
		options: {
			pos_ids: getSalesEntitiesTree(salesEntitiesQuery.data) || [],
		},
	}
	if (promoCampaigns.isLoading && !promoCampaign) {
		return (
			<Blanket className='bg-backdrop blanket-abs blanket-products' isTinted>
				<Loader />
			</Blanket>
		)
	} else if (!promoCampaign && !isNew) {
		return (
			<Result
				className='flex h-full flex-col items-center justify-center'
				icon={<AlertIcon height={50} width={50} />}
				title='Pricing campaign not found'
			/>
		)
	}
	return <PromoCampaignForm {...propsTo} />
}
