import { format } from 'date-fns'

import { ScheduleModel } from '@/generated'
import intl from '@/locale'

export const weekdays = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
]
export const formatSchedule = (schedule: ScheduleModel) => {
	let text = ''
	text += `${intl.get('schedule_start_on')} ${format(new Date(schedule.start_date), 'MMM dd')} \n`
	if (schedule.repeat) {
		text += `${intl.get('schedule_repeat_on')} ${schedule.repeat_days
			?.map((d) => intl.get(weekdays[d]).substr(0, 3))
			.join(
				', '
			)} ${intl.get('every')} ${schedule.repeat_week_interval} ${intl.get('general_weeks')}\n`
	}
	if (schedule.nearest_date) {
		text += `${intl.get('schedule_next_repricing')} ${intl.get('on')} ${format(
			new Date(schedule.nearest_date),
			'MMM dd'
		)}`
	}

	return text
}
