import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import { Col, InlineMessage, Row } from '@cmpkit/base'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'

import { OptimizationGroupModel, ScheduleModel } from '@/generated'
import intl from '@/locale'

import { formatSchedule } from './utils'

type FormData = Omit<ScheduleModel, 'id' | 'nearest_date'>
function Confirmation({
	methods,
	groups,
}: {
	methods: UseFormReturn<FormData, any, undefined> // eslint-disable-line
	groups: Record<string, OptimizationGroupModel>
}) {
	const selectedGroups = methods.watch('optimization_groups')
	return (
		<Row>
			<Col sm='6'>
				<h4>
					{intl.get('schedule_confirmation_groups')}({selectedGroups.length}):
				</h4>
				<ul className='h-64 overflow-y-scroll'>
					{selectedGroups.map((id: string) => (
						<li key={id}>{groups?.[id]?.name || 'None'}</li>
					))}
				</ul>
			</Col>
			<Col sm='6'>
				<h4>{intl.get('schedule_confirmation_settings')}</h4>
				<div className='h-64 overflow-y-scroll'>
					<div className='mt-2.5'>
						<h5>{intl.get('general_when')}</h5>
						<p className='whitespace-break-spaces text-wrap'>
							{formatSchedule(methods.watch() as ScheduleModel)}
						</p>
					</div>
				</div>
			</Col>
			<Col sm='12'>
				<InlineMessage icon={<InfoIcon />} variant='warning' className='mt-5'>
					{intl.get('cp_og_settings_confirmation_hint')}
				</InlineMessage>
			</Col>
		</Row>
	)
}
export default Confirmation
