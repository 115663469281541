import { format } from 'date-fns'
import { SeriesColumnOptions } from 'highcharts'
import { orderBy, prop, propOr, zip } from 'lodash/fp'
import { useState } from 'react'

import { PALETTE } from '@cmpkit/theme'

import Chart from '@/components/Chart'
import intl from '@/locale'
import { useBIAnalyticQuery } from '@/modules/bi/queries'
import { DateAggregation } from '@/modules/bi/types'
import { foldData, zipSeries } from '@/modules/bi/utils'
import analytic from '@/services/analytics'
import { DateFormat } from '@/tools/dates'
import { formatNumber, NumberFormats } from '@/tools/locale'

import { MainDashboardFilter } from '../../Filter'
import { SinglePeriodSelect } from '../../Periods'
import { Widget, WidgetContent, WidgetHeader } from '../../Widget'
import WidgetErrorState from '../../WidgetErrorState'
import WidgetLoadingState from '../../WidgetLoadingState'
import { WidgetPeriods, WidgetProps } from '../types'

type FoldedData = {
	accepted: number[]
	not_accepted: number[]
	locked: number[]
	unassigned: number[]
	changed_manually: number[]
	accepted_percent: number[]
	not_accepted_percent: number[]
	locked_percent: number[]
	unassigned_percent: number[]
	changed_manually_percent: number[]
} & Record<DateAggregation, string>

export default function HistoricalPriceAcceptanceWidget({
	commonFilters,
	commonPeriods,
	params,
	widget,
	onChangeParams,
}: WidgetProps) {
	const [period, setPeriod] = useState<WidgetPeriods['main']>(
		commonPeriods.main
	)
	const defaultPeriod = commonPeriods.main
	const filters = [...commonFilters, ...(params.filters ?? [])]
	const commonQueryParams = {
		label: widget.kind,
		...(commonPeriods?.main || {
			start_date: null,
			end_date: null,
		}),
		skip_dates: true,
		metrics: [
			'accepted',
			'not_accepted',
			'locked',
			'unassigned',
			'changed_manually',
			'accepted_percent',
			'not_accepted_percent',
			'locked_percent',
			'unassigned_percent',
			'changed_manually_percent',
		],
		dimensions: [],
		filters,
	}
	const biQuery = useBIAnalyticQuery<FoldedData>(
		{
			...commonQueryParams,
			...(period ?? {}),
			date_aggregation: DateAggregation.DateKey,
		},
		{
			select: (data) =>
				foldData(
					data?.meta || [],
					orderBy(['date_key'], ['asc'], zipSeries(data?.meta, data?.data))
				) as unknown as FoldedData,
		}
	)
	const foldedData = biQuery.data as FoldedData
	const categories = (
		propOr<FoldedData, keyof FoldedData, string[]>(
			[],
			DateAggregation.DateKey,
			foldedData
		) as string[]
	).map((date) => format(new Date(date), DateFormat.short))

	const series: SeriesColumnOptions[] = [
		{
			type: 'column' as const,
			name: intl.get('accepted'),
			color: PALETTE.green[75],
			data: getSerieData('accepted', foldedData),
		},
		{
			type: 'column' as const,
			name: intl.get('changed_manually'),
			color: PALETTE.red[75],
			data: getSerieData('changed_manually', foldedData),
		},
		{
			type: 'column' as const,
			name: intl.get('unassigned'),
			color: PALETTE.orange[75],
			data: getSerieData('unassigned', foldedData),
		},
		{
			type: 'column' as const,
			name: intl.get('locked'),
			color: PALETTE.blue[75],
			data: getSerieData('locked', foldedData),
		},
		{
			type: 'column' as const,
			name: intl.get('not_accepted'),
			color: PALETTE.neutral[150],
			data: getSerieData('not_accepted', foldedData),
		},
	]

	return (
		<Widget className='relative flex flex-auto flex-col'>
			<WidgetHeader
				titleAs='h2'
				title={intl.get('widget.price_acceptance_dashboard.title')}
				tooltip={intl.get('widget.historical_price_acceptance.tooltip')}
			>
				<div className='ml-auto flex items-center justify-between'>
					<MainDashboardFilter
						irremovableCount={commonFilters.length}
						filters={filters ?? []}
						onChange={(filters) => {
							analytic.logEvent(`dashboard: bi: apply filter ${widget.kind}`, {
								'filter by': filters.map(prop('name')).join(','),
							})
							onChangeParams({ ...params, filters })
						}}
					/>
					<SinglePeriodSelect
						period={period}
						defaultPeriod={defaultPeriod}
						onChangePeriod={setPeriod}
						maxDate={new Date()}
					/>
				</div>
			</WidgetHeader>
			<WidgetContent className='mt-0 pb-0'>
				<WidgetLoadingState isTinted={biQuery.isLoading} />
				<WidgetErrorState isTinted={biQuery.isError} />
				<Chart config={getChartConfig({ series, categories })} />
			</WidgetContent>
		</Widget>
	)
}

function formatter(this: Highcharts.Point) {
	const self = this as unknown as any // eslint-disable-line
	const head = `<div class="w-full flex justify-between mb-2.5"><small>${this.x}</small></div>`
	const points = self.points
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		?.map((point: any) => {
			return `
			<div class="flex justify-between">
				<div class="flex items-center mr-5">
					<div class="color-dot rounded-lg mr-4" style="background: ${point.color};"></div>
					<strong class="truncate">${point.series.name}</strong>
				</div>
				<div class="flex items-center">
					<strong class="flex items-center mr-2">${formatNumber(
						point.y as number,
						NumberFormats.Integer
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					)}</strong> (${formatNumber((point.point as any)['pp'], NumberFormats.Percent)})
				</div>
			</div>`
		})
		.join('')
	return `<div class="chart-tooltip ">
		${head}
		${points}
	</div>`
}
function getChartConfig({
	series,
	categories,
}: {
	series: SeriesColumnOptions[]
	categories: string[]
}): Highcharts.Options {
	return {
		chart: {
			type: 'column',
			height: 250,
			animation: true,
		},
		yAxis: {
			title: {
				text: null,
			},
			labels: { format: '{text}%' },
		},
		xAxis: {
			categories,
		},
		tooltip: {
			animation: true,
			outside: true,
			shared: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter,
		},
		plotOptions: {
			column: {
				stacking: 'percent',
				borderRadius: 2,
			},
		},
		series,
	}
}
const getSerieData = (
	propName: string,
	data: FoldedData
): {
	y: number
	pp: number
}[] =>
	(
		zip(
			propOr([], propName, data),
			propOr([], `${propName}_percent`, data)
		) as unknown as [number, number][]
	)?.map(([y, pp]: [number, number]) => ({ y, pp }))
