import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Col, InlineMessage, Row } from '@cmpkit/base'

import PublicLayout from '@/components/layouts/Public'
import Loader from '@/components/Loader'
import { AuthAuthAuthGetParams } from '@/generated'
import intl from '@/locale'
import { toQueryObject } from '@/tools/location'

import { useAuth } from '../AuthContext'
import { useExchangeCode } from '../queries'
import { TokenStorage } from '../token-storage'
import { getAuthenticationCallbackUrl } from '../utils'
import ContactLink from './ContactLink'

export const AuthExchange = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { refetchUser } = useAuth()
	const { code, session_state } = toQueryObject(location.search)
	const { isError, isLoading, data } = useExchangeCode(
		{
			code,
			session_state,
			redirect_uri: getAuthenticationCallbackUrl(),
		} as AuthAuthAuthGetParams,
		{
			enabled: !!code,
		}
	)
	useEffect(() => {
		if (data) {
			TokenStorage.storeToken(data.access_token)
			refetchUser().then(() => navigate('/p/og'))
		}
	}, [data])
	return (
		<PublicLayout title='Authorization: Competera'>
			<Row className='size-full overflow-hidden' noGutters>
				<Col lg={12} className='size-full overflow-y-scroll'>
					<div>
						<div className='m-auto max-w-md p-5 pt-24 text-center'>
							<h1 className='text-3xl font-black'>
								Welcome to <b className='text-brand'>Competera</b>
								<br />
								<b className='text-brand'>Pricing Platform</b>
							</h1>
							<p className='pb-12 pt-5 text-muted'>
								Machine Learning & Maths & Big Data to build predictable sales
								and margins
							</p>
							<div className='relative mb-2.5 h-52'>
								{isError && (
									<InlineMessage variant='danger' className='mb-2.5 w-full'>
										{/* {(error as )?.error_description?.toString?.()} */}
										{intl.get('auth_exchange_error').d('Authorization error')}
									</InlineMessage>
								)}
								{isLoading && (
									<Loader
										size={30}
										className='overlay fade-in'
										backdrop
										opacity
									/>
								)}
							</div>
							<ContactLink />
						</div>
					</div>
				</Col>
			</Row>
		</PublicLayout>
	)
}
export default AuthExchange
