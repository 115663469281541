import clsx from 'clsx'
import React from 'react'

import { InlineMessage } from '@cmpkit/base'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'

import { useOptimizationStatus } from '@/hooks/data'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'

import { LOCKED, STARTED, UPDATING } from '../../constants'

export default function LockedForChangesNotification({
	className,
}: {
	className?: string
}) {
	const optimizationGroupId = useOptimizationGroupId()!
	const status = useOptimizationStatus(optimizationGroupId)

	const isReady = status && ![UPDATING, STARTED, LOCKED].includes(status)
	return isReady === false ? (
		<InlineMessage
			variant='default'
			className={clsx('flex items-center text-xs font-medium', className)}
			icon={<InfoIcon />}
		>
			{intl.get(`app.optimization.update_is_forbidden.${status}`)}
		</InlineMessage>
	) : null
}
