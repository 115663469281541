import React from 'react'
// form
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import { FormGroup, Label, Switch } from '@cmpkit/base'

// ----------------------------------------------------------------------

export default function RHFSwitch({
	name,
	label,
	shouldUnregister,
	defaultValue,
	...other
}: UseControllerProps & {
	label?: string
}) {
	const { control } = useFormContext()
	return (
		<FormGroup className='flex items-center'>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				shouldUnregister={shouldUnregister}
				render={({ field }) => (
					<Switch {...field} checked={field.value} {...other} />
				)}
			/>
			<Label className='mx-5 flex items-center'>{label}</Label>
		</FormGroup>
	)
}
