import { AnimatePresence, motion } from 'framer-motion'
import { any, prop, uniqBy } from 'lodash/fp'
import React, { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useStep } from 'usehooks-ts'

import { useQueryClient } from '@tanstack/react-query'

import {
	Button,
	InlineMessage,
	Loader,
	treeHelpers,
	TreeNodeType,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import {
	Modal,
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { TreeCheckbox } from '@/components/TreePicker'
import intl from '@/locale'
import analytic from '@/services/analytics'

import {
	getProductGroupsTree,
	getSalesEntitiesTree,
	stratifyProductGroups,
} from '../../helpers'
import { useCreateOptimizationGroupMutation } from '../../mutations'
import {
	useDryCategoriesQuery,
	useProductGroupsQuery,
	useSalesEntitiesQuery,
} from '../../queries'
import CheckDataView from './CheckDataView'

const animation = {
	in: { opacity: 0, x: 100 },
	visible: { opacity: 1, x: 0 },
	out: { opacity: 0, x: -30 },
}
type State = {
	selectedSalesEntities: string[]
	selectedProductGroups: string[]
}
interface CreateOptimizationGroupModalProps {
	close(): void
	isOpen: boolean
}
const sleep = (t: number) => new Promise((r) => setTimeout(() => r(true), t))
function CreateOptimizationGroupModalContent({
	handleClose,
}: {
	handleClose(): void
}) {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const [step, stepHelpers] = useStep(3)
	const steps = getSteps()
	const { Component: StepComponent, title, subtitle } = steps[step - 1]
	const [draftState, setDraftState] = useState<State>({
		selectedSalesEntities: [],
		selectedProductGroups: [],
	})
	const salesEntitiesQuery = useSalesEntitiesQuery()
	const productGroupsQuery = useProductGroupsQuery()
	const categoriesQuery = useDryCategoriesQuery()

	const createOptimizationGroupMutation = useCreateOptimizationGroupMutation({
		async onSuccess(data) {
			await sleep(2000)
			toast.success('Optimization group created')
			await queryClient.invalidateQueries({
				queryKey: ['optimization-groups'],
			})
			await queryClient.invalidateQueries({
				queryKey: ['optimizations'],
			})
			navigate(`/p/og/${data.id}/settings`)
			analytic.logEvent('create og: close modal')
			handleClose()
		},
	})
	const salesEntitiesTree = useMemo(
		() => getSalesEntitiesTree(salesEntitiesQuery.data),
		[salesEntitiesQuery.data]
	)
	const productGroupsTree = useMemo(
		() =>
			getProductGroupsTree(
				productGroupsQuery.data,
				uniqBy('id', categoriesQuery.data)
			),
		[categoriesQuery.data, productGroupsQuery.data]
	)

	const isLoading = any(prop('isLoading'), [
		salesEntitiesQuery,
		categoriesQuery,
		productGroupsQuery,
	])
	const isPending = any(prop('isPending'), [createOptimizationGroupMutation])
	const getSelectedSalesEntities = () =>
		treeHelpers.getCalculatedSelection(
			treeHelpers.findNodes(
				salesEntitiesTree,
				draftState.selectedSalesEntities
			),
			draftState.selectedSalesEntities
		)
	const isValidSalesItems = () => {
		const { level, selected } = getSelectedSalesEntities()
		return (
			!(level === 1 && selected.length > 1) &&
			draftState.selectedSalesEntities.length > 0
		)
	}
	const handleSubmit = () => {
		analytic.logEvent('create og: submit form')
		const { level, selected } = getSelectedSalesEntities()
		createOptimizationGroupMutation.mutate({
			sales_level_id: level!,
			sales_entities: selected.map(prop('id')),
			product_groups: stratifyProductGroups(draftState.selectedProductGroups),
		})
	}

	const errorMessage =
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		(createOptimizationGroupMutation.error?.response?.data as any)?.error
	return (
		<Modal onClose={handleClose} showCloseButton size='large'>
			{isLoading ? (
				<div className='flex h-[400px] w-full items-center justify-center'>
					<Loader />
				</div>
			) : (
				<>
					<ModalHeader>
						<ModalTitle>{title}</ModalTitle>
						<ModalDescription>{subtitle}</ModalDescription>
					</ModalHeader>
					<ModalBody className='relative flex flex-col overflow-hidden p-0'>
						<Blanket
							isTinted={isPending}
							className='absolute flex items-center justify-center rounded-lg bg-white/30 backdrop-blur-sm dark:bg-black/30'
						>
							<Loader />
						</Blanket>
						{createOptimizationGroupMutation.error && (
							<div className='m-5 flex flex-col'>
								<InlineMessage variant='danger'>
									{errorMessage || intl.get('fatal_error_title')}
								</InlineMessage>
							</div>
						)}
						<AnimatePresence mode='wait'>
							<motion.div
								key={`${step}_step`}
								className='flex h-[400px] flex-col overflow-y-auto'
								initial='in'
								animate='visible'
								exit='out'
								variants={animation}
								transition={{
									duration: 0.2,
								}}
							>
								<StepComponent
									state={draftState}
									setState={setDraftState}
									salesEntitiesTree={salesEntitiesTree}
									productGroupsTree={productGroupsTree}
									salesLevels={salesEntitiesQuery.data?.sales_levels}
									productGroups={productGroupsQuery.data}
									categories={categoriesQuery.data}
								/>
							</motion.div>
						</AnimatePresence>
					</ModalBody>
					<ModalFooter className='justify-between space-x-2'>
						<Button
							onClick={() => {
								analytic.logEvent('create og: close modal')
								handleClose()
							}}
						>
							{intl.get('general_cancel')}
						</Button>
						<div className='flex items-center space-x-2'>
							<Button
								iconBefore={<CaretLeftIcon />}
								disabled={!stepHelpers.canGoToPrevStep}
								onClick={() => {
									analytic.logEvent('create og: go prev step')
									stepHelpers.goToPrevStep()
								}}
							>
								{intl.get('general_back')}
							</Button>
							{stepHelpers.canGoToNextStep ? (
								<Button
									variant='primary-brand'
									iconAfter={<CaretRightIcon />}
									disabled={
										!stepHelpers.canGoToNextStep ||
										(step === 1 && !isValidSalesItems()) ||
										(step === 2 &&
											draftState.selectedProductGroups.length === 0)
									}
									onClick={() => {
										analytic.logEvent('create og: go next step')
										stepHelpers.goToNextStep()
									}}
								>
									{intl.get('general_next')}
								</Button>
							) : (
								<Button
									variant='primary-brand'
									disabled={isPending}
									onClick={handleSubmit}
								>
									{intl.get('app.create_og')}
								</Button>
							)}
						</div>
					</ModalFooter>
				</>
			)}
		</Modal>
	)
}
export default function CreateOptimizationGroupModal({
	close,
	isOpen,
}: CreateOptimizationGroupModalProps) {
	return (
		<ModalTransition>
			{isOpen && <CreateOptimizationGroupModalContent handleClose={close} />}
		</ModalTransition>
	)
}
const getSteps = () => [
	{
		title: intl.get('app.create_og_step_1.title'),
		subtitle: intl.get('app.create_og_step_1.subtitle'),
		Component: SalesLevelTreeView,
	},
	{
		title: intl.get('app.create_og_step_2.title'),
		subtitle: intl.get('app.create_og_step_2.subtitle'),
		Component: ProductLevelTreeView,
	},
	{
		title: intl.get('app.create_og_step_3.title'),
		subtitle: intl.get('app.create_og_step_3.subtitle'),
		Component: CheckDataView,
	},
]

type ProductLevelTreeViewProps = {
	state: State
	setState: React.Dispatch<React.SetStateAction<State>>
	productGroupsTree: TreeNodeType[]
}
function ProductLevelTreeView({
	state,
	setState,
	productGroupsTree,
}: ProductLevelTreeViewProps) {
	if (productGroupsTree) {
		return (
			<div className={'relative flex flex-col overflow-y-auto p-5'}>
				<TreeCheckbox
					tree={productGroupsTree}
					value={state.selectedProductGroups}
					onChange={(items) =>
						setState((prevState) => ({
							...prevState,
							selectedProductGroups: items,
						}))
					}
				/>
			</div>
		)
	}
}
type SalesLevelTreeViewProps = {
	state: State
	setState: React.Dispatch<React.SetStateAction<State>>
	salesEntitiesTree: TreeNodeType[]
}
function SalesLevelTreeView({
	state,
	setState,
	salesEntitiesTree,
}: SalesLevelTreeViewProps) {
	if (salesEntitiesTree) {
		return (
			<div className={'relative flex flex-col overflow-y-auto p-5'}>
				<TreeCheckbox
					tree={salesEntitiesTree}
					value={state.selectedSalesEntities}
					onChange={(items) =>
						setState((prevState) => ({
							...prevState,
							selectedSalesEntities: items,
						}))
					}
				/>
			</div>
		)
	}
}
