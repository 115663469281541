import ReactGridLayout from 'react-grid-layout'

import { DashboardWidgetEntity } from './types'

const main_operations: DashboardWidgetEntity[] = [
	{
		widget: {
			id: 111,
			kind: 'NumericItemsIntegrated',
			params: {},
			config: { section: 'operations' },
		},
		layout: { w: 2, h: 1, x: 0, y: 1 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 1,
			kind: 'OptimizationGroupsStatus',
			params: {},
			config: { section: 'operations' },
		},
		layout: { w: 2, h: 1, x: 6, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 112,
			kind: 'ReadyForOprimization',
			params: {},
			config: { section: 'operations' },
		},
		layout: { w: 4, h: 2, x: 2, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 222,
			kind: 'NumericReadyForOptimization',
			params: {},
			config: { section: 'operations' },
		},
		layout: { w: 2, h: 1, x: 0, y: 0 } as ReactGridLayout.Layout,
	},
	/* {
		widget: {
			id: 122,
			kind: 'NumericPricesRecommended',
			params: {},
			config: { section: 'operations' },
		},
		layout: { w: 2, h: 1, x: 0, y: 2 } as ReactGridLayout.Layout,
	}, */
	/* {
		widget: {
			id: 123,
			kind: 'NumericAvgPriceChange',
			params: {},
			config: { section: 'operations' },
		},
		layout: { w: 2, h: 1, x: 2, y: 2 } as ReactGridLayout.Layout,
	}, */

	{
		widget: {
			id: 7,
			kind: 'OptimizationGroupsErrors',
			params: {},
			config: { section: 'operations' },
		},
		layout: { w: 4, h: 2, x: 8, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 3,
			kind: 'NumericUassignedItems',
			params: {},
			config: { section: 'operations' },
		},
		layout: { w: 2, h: 1, x: 6, y: 1 } as ReactGridLayout.Layout,
	},
]
const main_pricing: DashboardWidgetEntity[] = [
	{
		widget: {
			id: 124,
			kind: 'NumericRevenueUnderManagement',
			params: {},
			config: { section: 'pricing' },
		},
		layout: { w: 2, h: 1, x: 0, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 125,
			kind: 'NumericBlockedForRepricing',
			params: {},
			config: { section: 'pricing' },
		},
		layout: { w: 2, h: 1, x: 2, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 16,
			kind: 'NumericPriceAcceptance',
			params: {},
			config: { section: 'pricing' },
		},
		layout: { w: 4, h: 2, x: 0, y: 3 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 17,
			kind: 'HistoricalPriceAcceptance',
			params: {},
			config: { section: 'pricing' },
		},
		layout: { w: 8, h: 2, x: 4, y: 3 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 16,
			kind: 'OptimizationHistoryDonut',
			params: {},
			config: { section: 'pricing' },
		},
		layout: { w: 5, h: 2, x: 0, y: 4 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 166,
			kind: 'OptimizationHistoryTable',
			params: {},
			config: { section: 'pricing' },
		},
		layout: { w: 7, h: 2, x: 5, y: 4 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 126,
			kind: 'RepricedByPricingEngine',
			params: {},
			config: { section: 'pricing' },
		},
		layout: { w: 4, h: 2, x: 0, y: 1 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 6,
			kind: 'RevenueUnderManagementByPriceChange',
			params: {},
			config: { section: 'pricing' },
		},
		layout: { w: 4, h: 2, x: 4, y: 1 } as ReactGridLayout.Layout,
	},
]
const main_business: DashboardWidgetEntity[] = [
	{
		widget: {
			id: 4,
			kind: 'NumericSalesItems',
			params: {},
			config: { section: 'business' },
		},
		layout: { w: 2, h: 1, x: 0, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 41,
			kind: 'NumericRevenue',
			params: {},
			config: { section: 'business' },
		},
		layout: { w: 2, h: 1, x: 2, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 5,
			kind: 'NumericGrossProfit',
			params: {},
			config: { section: 'business' },
		},
		layout: { w: 2, h: 1, x: 0, y: 1 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 6,
			kind: 'RevenueUnderManagementByEngine',
			params: {},
			config: { section: 'business' },
		},
		layout: { w: 4, h: 2, x: 8, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 8,
			kind: 'MetricToCompare',
			params: {},
			config: { section: 'business' },
		},
		layout: { w: 4, h: 2, x: 0, y: 4 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 121,
			kind: 'PriceChangeSegmented',
			params: {},
			config: { section: 'business' },
		},
		layout: { w: 8, h: 2, x: 4, y: 4 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 9,
			kind: 'MetricPerfomance',
			params: { metric: 'sales_items', format: 'integer' },
			config: { section: 'business' },
		},
		layout: { w: 4, h: 2, x: 4, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 10,
			kind: 'MetricPerfomance',
			params: { metric: 'revenue', format: 'integer' },
			config: { section: 'business' },
		},
		layout: { w: 4, h: 2, x: 4, y: 2 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 11,
			kind: 'MetricPerfomance',
			params: { metric: 'gross_profit', format: 'integer' },
			config: { section: 'business' },
		},
		layout: { w: 4, h: 2, x: 0, y: 2 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 12,
			kind: 'BusinessMetrics',
			params: {},
			config: { section: 'business' },
		},
		layout: { w: 12, h: 4, x: 0, y: 6 } as ReactGridLayout.Layout,
	},
]
const main_metrics: DashboardWidgetEntity[] = [
	{
		widget: {
			id: 13,
			kind: 'MetricsByOptimizationGroup',
			params: { filters: [] },
			config: { section: 'metrics' },
		},
		layout: { w: 12, h: 3, x: 0, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 14,
			kind: 'MetricsByBrand',
			params: {},
			config: { section: 'metrics' },
		},
		layout: { w: 12, h: 3, x: 0, y: 3 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 15,
			kind: 'MetricsByPricingCampaign',
			params: {},
			config: { section: 'metrics' },
		},
		layout: { w: 12, h: 3, x: 0, y: 6 } as ReactGridLayout.Layout,
	},
]
export const main: DashboardWidgetEntity[] = [
	...main_operations,
	...main_pricing,
	...main_business,
	...main_metrics,
]
export const ai: DashboardWidgetEntity[] = [
	{
		widget: {
			id: 18,
			kind: 'SalesFactorsImpact',
			params: {},
			config: { section: 'common' },
		},
		layout: { w: 6, h: 3, x: 0, y: 0 } as ReactGridLayout.Layout,
	},

	{
		widget: {
			id: 19,
			kind: 'SalesFactorsImpactDynamic',
			params: {},
			config: { section: 'common' },
		},
		layout: { w: 6, h: 3, x: 6, y: 0 } as ReactGridLayout.Layout,
	},
	{
		widget: {
			id: 20,
			kind: 'SalesFactorsImpactByCategories',
			params: {},
			config: { section: 'common' },
		},
		layout: { w: 9, h: 3, x: 0, y: 3 } as ReactGridLayout.Layout,
	},
]
