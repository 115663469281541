import toast from 'react-hot-toast'

import { LinkButton } from '@cmpkit/base'

import { queryClient } from '@/lib/query-client'
import {
	BusinessSettingsModel,
	SettingsTemplateModel,
	SettingsTemplateType,
	StopListSettingsModel,
	StrategiesSettingsModel,
} from '@/generated'
import analytic from '@/services/analytics'
import intl from '@/locale'
import { processingMessage } from '@/tools/message'
import { useCreateSettingsTemplateMutation } from '@/modules/settings-templates/mutations'
import notify from '@/components/toasts/helpers'
import { useModalStore } from '@/modules/modals/store'

export type Settings =
	| BusinessSettingsModel
	| StopListSettingsModel
	| StrategiesSettingsModel

export type HandleSaveSettingAsTemplateProps = {
	name: string
	templateType: SettingsTemplateType
	settings: {
		[key: string]: Settings
	}
}
export interface SettingsActions {
	handleSaveAsTemplate(props: HandleSaveSettingAsTemplateProps): void
	handleUseTemplate(settings: Settings): void
}
export interface UseSettingActionsProps {
	handleUseTemplate(settings: Settings): void
}
export function useSettingActions({
	handleUseTemplate,
}: UseSettingActionsProps): SettingsActions {
	const { showModal } = useModalStore()
	/**Mutations */
	const createTemplateMutation = useCreateSettingsTemplateMutation({
		onMutate() {
			toast.loading(processingMessage(), {
				id: 'saving_template',
			})
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['settings-templates'],
			})
		},
		onSettled() {
			toast.dismiss('saving_template')
		},
	})
	const handleOpenTemplate = ({
		id,
		template_type,
	}: {
		id: string
		template_type: SettingsTemplateType
	}) => {
		showModal('SETTINGS_TEMPLATE_MODAL', {
			templateId: id,
			templateType: template_type,
		})
	}
	/**Handlers */
	const handleSaveAsTemplate = ({
		name,
		templateType,
		settings,
	}: HandleSaveSettingAsTemplateProps) => {
		analytic.logEvent('settings: og: dropdown: save setting as template')

		createTemplateMutation.mutate(
			{
				template_type: templateType,
				name,
				body: settings,
			},
			{
				onSuccess: (data: SettingsTemplateModel) => {
					notify.success(
						{
							text: (
								<div>
									{intl.get('templates').d('Template')}{' '}
									<LinkButton
										variant='brand'
										onClick={() => {
											handleOpenTemplate({
												id: data.id,
												template_type: templateType,
											})
										}}
									>
										{data.name}
									</LinkButton>{' '}
									{intl
										.get('template_saved_successfully')
										.d('was saved successfully')}
								</div>
							),

							variant: 'success',
						},
						{
							duration: 5000,
						}
					)
				},
			}
		)
	}
	return {
		handleSaveAsTemplate,
		handleUseTemplate,
	}
}
