import clsx from 'clsx'
import React from 'react'

export default function WidgetContent({
	children,
	className,
	...props
}: {
	children: React.ReactNode
	className?: string
} & React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div className={clsx('widget__content', className)} {...props}>
			{children}
		</div>
	)
}
