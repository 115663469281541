import React from 'react'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import { Button, Card, MenuItem, MenuList } from '@cmpkit/base'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'
import LayersIcon from '@cmpkit/icon/lib/glyph/layers'
import Trash2Icon from '@cmpkit/icon/lib/glyph/trash-2'
import Popover from '@cmpkit/popover'

import { dialog } from '@/components/dialogs'
import { SettingsTemplateModel } from '@/generated'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'

import {
	useCreateSettingsTemplateMutation,
	useDeleteSettingsTemplateMutation,
} from '../mutations'

export default function SettingsTemplateActions({
	template,
	onFinish,
}: {
	template: SettingsTemplateModel
	onFinish?: () => void
}) {
	return (
		<Popover
			placement='bottom-end'
			content={({ close }) => (
				<SettingsTemplateActionsMenu
					template={template}
					onFinish={onFinish}
					close={close}
				/>
			)}
		>
			<Button size='small' variant='tertiary' iconBefore={<DotsFilledIcon />} />
		</Popover>
	)
}

function SettingsTemplateActionsMenu({
	template,
	close,
	onFinish,
}: {
	template: SettingsTemplateModel
	close: () => void
	onFinish?: () => void
}) {
	const { showModal } = useModalStore()
	const queryClient = useQueryClient()
	const createTemplateMutation = useCreateSettingsTemplateMutation({
		onSuccess: () => {
			onFinish?.()
			queryClient.invalidateQueries({
				queryKey: ['settings-templates'],
			})
		},
	})
	const deleteTemplateMutation = useDeleteSettingsTemplateMutation({
		onSuccess: () => {
			onFinish?.()
			queryClient.invalidateQueries({
				queryKey: ['settings-templates'],
			})
		},
	})
	const handleDeleteClick = async () => {
		close?.()
		const answer = await dialog.confirmDelete({
			title: intl.get('general_detete_confirm_title'),
			text: intl.get('general_detete_confirm_subtitle'),
			okText: intl.get('general_delete'),
		})
		if (answer) {
			toast.promise(deleteTemplateMutation.mutateAsync(template.id), {
				loading: intl.get('deleting.proccess').d('Deleting...'),
				success: intl.get('deleting.success').d('Deleted successfully'),
				error: intl.get('deleting.error').d('Error while deleting'),
			})
		}
	}
	const handleDuplicateClick = () => {
		close?.()
		toast.promise(
			createTemplateMutation.mutateAsync({
				name: template.name,
				description: template.description || '',
				body: template.body,
				template_type: template.template_type,
			}),
			{
				loading: intl.get('duplicating.proccess').d('Duplicating...'),
				success: intl.get('duplicating.success').d('Duplicated successfully'),
				error: intl.get('duplicating.error').d('Error while duplicating'),
			}
		)
	}
	return (
		<Card className='min-w-48 border py-2 shadow'>
			<MenuList>
				<MenuItem onClick={handleDuplicateClick}>
					<LayersIcon />
					{intl.get('general_dublicate')}
				</MenuItem>
				<MenuItem
					onClick={() =>
						showModal('SETTINGS_TEMPLATE_APPLY_MODAL', {
							template,
						})
					}
				>
					<CheckIcon />
					{intl.get('app.apply_to').d('Apply to ...')}
				</MenuItem>
				<MenuItem variant='destructive' onClick={handleDeleteClick}>
					<Trash2Icon />
					{intl.get('general_delete').d('Contact support')}
				</MenuItem>
			</MenuList>
		</Card>
	)
}
