import {
	entries,
	find,
	flatten,
	groupBy,
	map,
	paths,
	pipe,
	prop,
	propOr,
	split,
	uniq,
	values,
} from 'lodash/fp'

import { treeHelpers, TreeNodeType } from '@cmpkit/base'

import {
	CategoryModel,
	FilterRuleModel,
	OptimizationGroupProductGroupModel,
	ProductGroupModel,
	SalesEntitiesResponseData,
	SalesEntityModel,
} from '@/generated'

export const stratifyProductGroups: (
	ids: string[]
) => OptimizationGroupProductGroupModel[] = pipe([
	map(split('|')),
	groupBy(prop('0')),
	entries,
	map(([productGroupId, categories]: [string, string[]]) => ({
		product_group_id: productGroupId,
		filters: [
			{
				name: 'category_id',
				operation: 'in',
				value: categories.map(prop('1')).filter(Boolean),
			},
		],
	})),
])
export const flattifyProductGroups: (
	groups: OptimizationGroupProductGroupModel[]
) => string[] = pipe([
	map(paths(['product_group_id', 'filters'])),
	map(([productGroupId, filters]: [string, FilterRuleModel[]]) => {
		if (filters?.length) {
			const categories =
				(find({ name: 'category_id' }, filters)?.value as string[]) || []
			if (categories?.length) {
				return categories.map((filter) => `${productGroupId}|${filter}`)
			}
			return [productGroupId]
		}
		return [productGroupId]
	}),
	flatten,
])
export const getSalesEntitiesTree: (
	data?: SalesEntitiesResponseData
) => TreeNodeType[] = pipe([
	propOr([], 'sales_entities'),
	map((item: SalesEntityModel) => ({
		parent: item.parent_id,
		id: item.id,
		name: item.name,
		level: item.sales_level_id,
	})),
	treeHelpers.getTree,
])
export const getProductGroupStartCategories = pipe([values, flatten, uniq])
export const getProductGroupsTree = (
	productGroupsData?: ProductGroupModel[],
	categoriesData?: CategoryModel[]
) => {
	const createProductGroupTree = ({
		id,
		name,
		categories,
	}: ProductGroupModel) => {
		const productGroupStartFromCategories =
			getProductGroupStartCategories(categories)
		const getParentId = (category: CategoryModel) => {
			if (productGroupStartFromCategories.length > 0) {
				if (productGroupStartFromCategories.includes(category.id)) {
					return id
				} else {
					return category.parent_id ? `${id}|${category.parent_id}` : 'NONE'
				}
			} else {
				return category.parent_id ? `${id}|${category.parent_id}` : id
			}
		}
		const rootNode = {
			parent: null,
			id,
			name,
		}
		const categoriesNodes =
			categoriesData?.map((category) => ({
				parent: getParentId(category),
				id: `${id}|${category.id}`,
				name: category.name,
			})) || []

		return [rootNode, ...categoriesNodes]
	}
	return treeHelpers.getTree(
		productGroupsData?.map(createProductGroupTree).flat() || []
	)
}
