import clsx from 'clsx'
import { eq, indexBy } from 'lodash/fp'
import React, { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'

import { TreeNodeType } from '@cmpkit/base'
import {
	Column,
	ColumnCheckboxHeaderTemplate,
	ColumnCheckboxRowTemplate,
	ColumnOptionsType,
} from '@cmpkit/data-table'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import { DataOption } from '@/common.types'
import { assignProp, ProxyCell } from '@/components/data-grid/column-helpers'
import AlertsCountField from '@/components/data-grid/custom/AlertsCountField'
import FinalDataField from '@/components/data-grid/custom/FinalDataField'
import LockField from '@/components/data-grid/custom/LockField'
import OptimizationGroupField from '@/components/data-grid/custom/OptimizationGroup'
import PathField from '@/components/data-grid/custom/PathField'
import ProductIdentField from '@/components/data-grid/custom/ProductIdentField'
import SelectField from '@/components/data-grid/custom/SelectField'
import TitleField from '@/components/data-grid/custom/TitleField'
import { BadgeField } from '@/components/data-grid/fields'
import { ProductModel } from '@/generated'
import { useCanEditModelInput } from '@/hooks/data'
import { useOptimizationGroupId } from '@/hooks/useOptimzationGroupId'
import intl from '@/locale'
import {
	useColumnsSchemaQuery,
	usePricingAlertsAnnotationsQuery,
} from '@/modules/core/queries'
import {
	AlertAnnotationModel,
	AlertLevel,
	ColumnSchemaModel,
} from '@/modules/core/types'
import { ColumnDefinition, useColumnsDefinitions } from '@/tools/columns'

import {
	COLUMNS_FIXED_DICT,
	COLUMNS_VALUE_GETTER_DICT,
	COLUMNS_WIDTH_DICT,
} from './constants'
import { useProductsStore } from './store'
import { PricingLineModel } from './types'
import { getAssortmentItemId, getEntityFieldValue, getNodeId } from './utils'

type UseColumnsParams = {
	dataChoices: Record<string, DataOption[] | TreeNodeType[]>
}
type UseColumnsParamsExtended = UseColumnsParams & {
	isDisabledEditing: boolean
	pricingAlertsAnnotations: Record<string, AlertAnnotationModel>
}
function getControlColumn(
	config: ColumnOptionsType,
	props: UseColumnsParamsExtended
) {
	const className = ({
		dataRow: { content },
	}: {
		dataRow: { content: ProductModel | PricingLineModel }
	}) => {
		const aggregated = (content as PricingLineModel).products?.length
		const pricingAlertsAnnotations = (
			content as PricingLineModel
		).fields?.alerts?.map(
			(id: string) => props.pricingAlertsAnnotations?.[id]?.level
		)
		return clsx('border-r-0', {
			'v-table-space-imit': !aggregated,
			'row-label-success': pricingAlertsAnnotations?.some(
				eq(AlertLevel.Unassigned)
			),
			//FIXME: remove row-label-warn after UIKIT update
			'row-label-warning row-label-warn': pricingAlertsAnnotations?.some(
				eq(AlertLevel.Warning)
			),
			'row-label-error': pricingAlertsAnnotations?.some(
				eq(AlertLevel.Critical)
			),
		})
	}
	return new Column({
		...config,
		headerRenderer: () => <ColumnCheckboxHeaderTemplate />,
		renderer: (id, item, row) => (
			<ColumnCheckboxRowTemplate item={item} row={row} />
		),
		valueGetter: () => null,
		headerClassName: 'border-r-0',
		className,
	})
}
const extendFieldsTypes = {
	pc: BadgeField,
	og: OptimizationGroupField,
	lock: LockField,
	size: AlertsCountField,
	path: PathField,
	pricing_type: BadgeField,
	final_data: FinalDataField,
	title: TitleField,
	ident: ProductIdentField,
	select: SelectField,
}

export function useColumns(config: UseColumnsParams): {
	columns: Column[]
	columnsDefinitions: ColumnDefinition[]
} {
	const { data: schema } = useColumnsSchemaQuery()
	const optimizationGroupId = useOptimizationGroupId()!
	const isDisabledEditing = !useCanEditModelInput(optimizationGroupId)

	const { data: pricingAlertsAnnotations } = usePricingAlertsAnnotationsQuery<
		Record<string, AlertAnnotationModel>
	>({
		select: indexBy<AlertAnnotationModel>('id'),
	})

	const cellsConfig = useMemo(
		() => ({
			extendFieldsTypes,
			dataChoices: config.dataChoices,
		}),
		[config.dataChoices]
	)
	const enrichRenderers = useCallback(
		assignRenderers(cellsConfig, isDisabledEditing),
		[cellsConfig, isDisabledEditing]
	)
	const columnExtensions = useMemo(
		() => [
			enrichRenderers,
			enrichValueGetters,
			enrichSortable,
			enrichLocked,
			enrichTitle,
			enrichWidth,
		],
		[enrichRenderers]
	)
	/**
	 * Columns business logic formation based on `schema` and `columnExtensions`
	 */
	const columnsDefinitions = useColumnsDefinitions(
		schema || [],
		columnExtensions
	)
	const props: UseColumnsParamsExtended = {
		isDisabledEditing,
		pricingAlertsAnnotations: pricingAlertsAnnotations || {},
		...config,
	}
	return useMemo(() => {
		const columnsDefinitionsWithControl = [
			{
				schema: {
					name: 'control',
					hidden: false,
				},
				column: {
					id: 'control',
					title: 'Control',
					width: 50,
					locked: true,
					fixable: false,
					filterable: false,
					sortable: false,
				},
			} as ColumnDefinition,
			...columnsDefinitions,
		]
		return {
			columnsDefinitions: columnsDefinitionsWithControl,
			columns: columnsDefinitionsWithControl.map(({ column }) => {
				if (column.id === 'control') {
					return getControlColumn(column, props)
				}
				return new Column(column)
			}),
		}
	}, [schema, isDisabledEditing, columnsDefinitions])
}

const selectRowLocalDataControl =
	(
		row: PricingLineModel | ProductModel,
		schema: ColumnSchemaModel,
		originalValue: string | number | null
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(state: any) => {
		const isPricingLine = Boolean((row as PricingLineModel).products)
		return {
			value: state.localData[getAssortmentItemId(row)]?.[schema.name],
			onChange: ({
				value,
				row,
				schema,
			}: {
				value: any // eslint-disable-line @typescript-eslint/no-explicit-any
				row: PricingLineModel | ProductModel
				schema: ColumnSchemaModel
			}) => {
				const newState = { ...state.localData }
				const updateState = (id: string) => {
					if (value == originalValue) {
						delete newState?.[id]?.[schema.name]
						if (Object.keys(newState[id]).length === 0) {
							delete newState[id]
						}
					} else {
						newState[id] = {
							...(newState[id] || {}),
							[schema.name]: value,
						}
					}
				}
				const products = (row as PricingLineModel).products
				if (isPricingLine) {
					// Set value for all products in line if it is a pricing line
					updateState(getAssortmentItemId(row))
					products.forEach((product) => {
						const productId = `${(row as PricingLineModel).line_id}__${product.sku}`
						updateState(productId)
					})
				} else {
					// Set value for all products in line if it is not a pricing line
					updateState(`${getNodeId(row)}__`)
					updateState(getAssortmentItemId(row))
				}
				state.setLocalData(newState)
			},
		}
	}
type CellResolverProps = {
	value: any // eslint-disable-line @typescript-eslint/no-explicit-any
	row: PricingLineModel | ProductModel
	schema: ColumnSchemaModel
	extendFieldsTypes: any // eslint-disable-line @typescript-eslint/no-explicit-any
	dataChoices: Record<string, DataOption[] | TreeNodeType[]>
	isDisabled: boolean
}
function CellResolver({
	value: originalValue,
	row,
	schema,
	extendFieldsTypes,
	dataChoices,
	isDisabled,
}: CellResolverProps) {
	const { onChange, value } = useProductsStore(
		selectRowLocalDataControl(row, schema, originalValue)
	)
	const isChanged =
		value !== undefined && value !== null && value != originalValue

	// Avoid onChange call if disabled
	const disabledOnChange = () =>
		toast(intl.get('products_select_disabled'), {
			icon: <AlertIcon className='fill-warn' />,
		})
	return (
		<ProxyCell
			isDisabled={isDisabled}
			isChanged={isChanged}
			onChange={isDisabled ? disabledOnChange : onChange}
			originalValue={originalValue}
			value={isChanged ? value : originalValue}
			row={row}
			schema={schema}
			extendFieldsTypes={extendFieldsTypes}
			choices={dataChoices?.[schema.name]}
		/>
	)
}

const enrichValueGetters = assignProp('valueGetter', COLUMNS_VALUE_GETTER_DICT)
const enrichSortable = assignProp('sortable', {
	pricing_campaign_name: false,
})
const enrichLocked = assignProp('locked', COLUMNS_FIXED_DICT)
const enrichWidth = assignProp('width', COLUMNS_WIDTH_DICT)
const enrichTitle = assignProp('title', {
	sku: `${intl.get('field_schema_sku')}/${intl.get('field_schema_product_line_id')}`,
	title: `${intl.get('field_schema_title')}/${intl.get('field_schema_product_line_name')}`,
})

function assignRenderers(
	{
		dataChoices,
		extendFieldsTypes,
	}: {
		dataChoices: Record<string, DataOption[] | TreeNodeType[]>
		extendFieldsTypes: Record<string, React.ElementType>
	},
	isDiabledEditing: boolean
) {
	return function ({ schema, column }: ColumnDefinition): ColumnDefinition {
		return {
			schema,
			column: {
				...column,
				valueGetter: (row) => getEntityFieldValue(row, schema.name),
				renderer: (_value, _item) => {
					return (
						<CellResolver
							isDisabled={isDiabledEditing}
							dataChoices={dataChoices}
							extendFieldsTypes={extendFieldsTypes}
							schema={schema}
							value={_value}
							row={_item}
						/>
					)
				},
			},
		}
	}
}
