import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { Button } from '@cmpkit/base'

import { dialog } from '@/components/dialogs'
import {
	OptimizationStatus,
	UpdateProductsFinalFieldsErrorResponseModel,
	UpdateProductsFinalFieldsResponseModel,
	UpdateProductsModelFieldsErrorResponseModel,
	UpdateProductsModelFieldsResponseModel,
} from '@/generated'
import intl from '@/locale'
import {
	ChangesModel,
	getOnlyFinalFields,
	isEditedFinalFields,
	isEditedModelFields,
} from '@/modules/core'
import { client } from '@/network/client'

import { useOptimizationQuery } from '../queries'

function dialogActions(resolve: (flag: string | boolean) => void) {
	return (
		<div className='flex items-center px-5 pb-5 pt-2'>
			<Button onClick={() => resolve(false)}>
				{intl.get('general_cancel')}
			</Button>
			<Button
				variant='primary-brand'
				className='ml-auto'
				onClick={() => resolve('model')}
			>
				{intl.get('general_apply')}
			</Button>
			<Button
				variant='primary-brand'
				className='ml-2'
				onClick={() => resolve('final')}
			>
				{intl.get('apply_only_final_price_changes')}
			</Button>
		</div>
	)
}

type UpdateProductsFieldsPayload = {
	changes: ChangesModel[]
	ignore_empties: boolean
}
export function useUpdateProductsFieldsMutation(
	optimizationGroupId: string,
	options?: UseMutationOptions<
		| UpdateProductsModelFieldsResponseModel
		| UpdateProductsFinalFieldsResponseModel
		| void,
		AxiosError<
			| UpdateProductsModelFieldsErrorResponseModel
			| UpdateProductsFinalFieldsErrorResponseModel
		>,
		UpdateProductsFieldsPayload
	>
) {
	const { data: optimization } = useOptimizationQuery(optimizationGroupId, {
		enabled: !!optimizationGroupId,
	})

	return useMutation<
		| UpdateProductsModelFieldsResponseModel
		| UpdateProductsFinalFieldsResponseModel
		| void,
		AxiosError<
			| UpdateProductsModelFieldsErrorResponseModel
			| UpdateProductsFinalFieldsErrorResponseModel
		>,
		UpdateProductsFieldsPayload
	>({
		mutationFn: async ({ changes, ignore_empties }) => {
			const isFinalFieldsChanged = isEditedFinalFields(changes)
			const isModelFieldsChanged = isEditedModelFields(changes)
			if (
				[OptimizationStatus.Finished, OptimizationStatus.Failed].includes(
					optimization!.status
				)
			) {
				if (isModelFieldsChanged && isFinalFieldsChanged) {
					const saveStartegy = await dialog.actions({
						title: intl.get('products_saving_or_final_title'),
						text: intl.get('products_saving_model_or_final'),
						actions: dialogActions,
					})
					if (saveStartegy === 'model') {
						await client.core.createOptimization(optimizationGroupId)
						return client.products.updateProductDiffs(
							optimizationGroupId,
							'last',
							{
								ignore_empties,
								product_diffs: changes,
							}
						)
					} else if (saveStartegy === 'final') {
						return client.products.updateFinalFields(
							optimizationGroupId,
							'last',
							{
								final_fields: getOnlyFinalFields(changes) as ChangesModel[],
							}
						)
					}
				} else if (isModelFieldsChanged) {
					const confirmation = await dialog.confirm({
						title: intl.get('products_saving_title'),
						text: intl.get('products_saving_model'),
						okText: intl.get('general_save_changes'),
					})
					if (confirmation) {
						await client.core.createOptimization(optimizationGroupId)
						return client.products.updateProductDiffs(
							optimizationGroupId,
							'last',
							{
								ignore_empties,
								product_diffs: changes,
							}
						)
					}
				} else {
					return client.products.updateFinalFields(
						optimizationGroupId,
						'last',
						{
							final_fields: getOnlyFinalFields(changes) as ChangesModel[],
						}
					)
				}
			} else {
				return client.products.updateProductDiffs(optimizationGroupId, 'last', {
					ignore_empties,
					product_diffs: changes,
				})
			}
		},
		...(options || {}),
	})
}
