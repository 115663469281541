import {
	filter,
	find,
	first,
	fromPairs,
	map,
	pipe,
	prop,
	propOr,
} from 'lodash/fp'

import {
	CANCELED,
	FAILED,
	FINISHED,
	NEW,
	PENDING,
} from '@/modules/core/constants'
import {
	useColumnsSchemaQuery,
	useOptimizationGroupsQuery,
	useOptimizationQuery,
} from '@/modules/core/queries'
import { OptimizationGroupModel } from '@/modules/core/types'

const getMainSourceInSchema = pipe([
	filter(propOr(false, 'ui_schema.main')),
	map(prop('name')),
	first,
])

export function useOptimizationGroup(
	optimizationGroupId: string
): OptimizationGroupModel {
	const { data: group } = useOptimizationGroupsQuery<
		OptimizationGroupModel | undefined
	>({
		select: find<OptimizationGroupModel>({ id: optimizationGroupId }),
	})
	return group!
}
export function useMainSourceProperty(): string {
	const { data: prop } = useColumnsSchemaQuery<string>({
		select: getMainSourceInSchema,
	})
	return prop ?? 'last_price'
}
export function useOptimizationStatus(optimizationGroupId?: string | null) {
	const { data: optimization } = useOptimizationQuery(optimizationGroupId!, {
		enabled: !!optimizationGroupId,
	})
	return optimization?.status
}
export function useCanEditFinalPrice(optimizationGroupId: string): boolean {
	const status = useOptimizationStatus(optimizationGroupId)
	return status === FINISHED
}
export function useCanEditModelInput(optimizationGroupId: string): boolean {
	const status = useOptimizationStatus(optimizationGroupId)
	return [NEW, CANCELED, FINISHED, FAILED, PENDING].includes(status!)
}

export function useSchemaFieldNames() {
	const { data: schema } = useColumnsSchemaQuery<Record<string, string>>({
		select: pipe([
			map(({ name, translate_key, visible_name }) => [
				name,
				translate_key || visible_name,
			]),
			fromPairs,
		]),
	})
	return schema
}
