import { formatNumber, NumberFormats } from '@/tools/locale'

import { CellProp } from '../'
import { ColumnDataType } from '../../types'

export default function NumberField(props: Pick<CellProp, 'value' | 'schema'>) {
	if (props.value === null || props.value === undefined) {
		return <div className='cell-data-number'>-</div>
	}
	if (props.schema?.type === ColumnDataType.Integer) {
		return (
			<div className='cell-data-number'>
				{formatNumber(props.value, NumberFormats.Integer)}
			</div>
		)
	} else if (props.schema?.type == ColumnDataType.Float) {
		return (
			<div className='cell-data-number'>
				{formatNumber(props.value, NumberFormats.Fixed)}
			</div>
		)
	}
	return <div className='cell-data-number'>{formatNumber(props.value)}</div>
}
