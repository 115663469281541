import find from 'lodash/fp/find'
import React from 'react'

import { Dot } from '@cmpkit/base'
import Select, {
	components,
	SingleValue as SingleValueType,
} from '@cmpkit/select'

import { DataOption } from '@/common.types'

interface Props {
	value: string | number | null
	options: DataOption[]
	onChange(value: SingleValueType<DataOption>): void
	className?: string
	placeholder?: string
}
// FIXME: desribe SelectCustomComponentProps type
type SelectCustomComponentProps = any // eslint-disable-line @typescript-eslint/no-explicit-any
const Option = ({ children, ...props }: SelectCustomComponentProps) => (
	<components.Option {...props}>
		<Dot className='mr-2' color={props.data.color} />
		{children}
	</components.Option>
)
const SingleValue = ({ children, ...props }: SelectCustomComponentProps) => (
	<components.SingleValue {...props}>
		<Dot className='mr-2' color={props.data.color} />
		{children}
	</components.SingleValue>
)

function ColoredSelect({ options, value, ...props }: Props) {
	return (
		<Select
			menuPlacement='auto'
			options={options}
			components={{ SingleValue, Option }}
			value={find({ value }, options) as SingleValueType<DataOption>}
			{...props}
		/>
	)
}

export default ColoredSelect
