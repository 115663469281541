import { isNil } from 'lodash/fp'
import React from 'react'

import { Dot } from '@cmpkit/base'

import { formatNumber, NumberFormats } from '@/tools/locale'

import { DonutViewDataItem } from './types'

type DonutLegendProps = {
	data: DonutViewDataItem[]
	formatValue?(value: number): string
	formatSubValue?(value: number): string
}
export default function DonutLegend({
	data,
	formatValue,
	formatSubValue = (v) => formatNumber(v, NumberFormats.Percent),
}: DonutLegendProps) {
	const renderSubvalue = (value: number) => formatSubValue?.(value) || value
	const renderValue = (value: number) => formatValue?.(value) || value
	return (
		<>
			{data.map(({ name, color, value, subvalue }) => {
				const subvalueItem = !isNil(subvalue) ? renderSubvalue(subvalue) : null
				const valueItem = renderValue(value)
				return (
					<div className='flex items-center justify-between' key={name}>
						<div className='flex items-center space-x-2'>
							<Dot color={color} />
							<p>{name}</p>
						</div>
						<span className='font-semibold'>
							{!isNil(subvalue) ? `${subvalueItem} (${valueItem})` : valueItem}
						</span>
					</div>
				)
			})}
		</>
	)
}
