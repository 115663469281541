import React, { memo } from 'react'

import MODAL_COMPONENTS from '../registry'
import { ActionType, dispatch, useStore } from '../store'

export function ModalsStack() {
	const { modals } = useStore()

	return (
		<>
			{modals.map((modal) => {
				const SpecificModal = MODAL_COMPONENTS[modal.id]
				const onRequestClose = () =>
					dispatch({ type: ActionType.DISMISS_MODAL, modalId: modal.id })
				return SpecificModal ? (
					<SpecificModal
						isOpen={modal.visible}
						key={modal.id}
						close={onRequestClose}
						// eslint-disable-next-line
						{...(modal?.props || ({} as any))}
					/>
				) : null
			})}
		</>
	)
}

export default memo(ModalsStack)
