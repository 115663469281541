export const JSD_API_HEADER = process.env.ATLASSIAN_JSD_AUTH
export const JSD_ID = '20'

export const JSD_REQUEST_TYPES = ['141', '241', '217']

export const JSD_SPECIFIC_REQUEST_TYPES = [
	'280',
	'178',
	'281',
	'282',
	'283',
	'284',
	'285',
]
