import React from 'react'

import Chart from '@/components/Chart'
import intl from '@/locale'
import { formatNumber } from '@/tools/locale'

type Props = {
	// eslint-disable-next-line
	cannibalization: number
	// eslint-disable-next-line
	incremental_sales: number
}
export function formatter(this: Highcharts.Point) {
	return `<div class="chart-tooltip">${this.x}: <strong>${formatNumber(this.y as number)}%</strong></div>`
}
const getConfig = ({
	cannibalization,
	incremental_sales,
}: Props): Highcharts.Options => {
	const inc_sales = incremental_sales * 100
	const cann = cannibalization * 100
	const total = cann + inc_sales
	return {
		chart: {
			type: 'waterfall',
			backgroundColor: 'transparent',
			height: 300,
		},
		xAxis: {
			type: 'category',
			title: { text: '' },
			crosshair: true,
			categories: [
				'data_widget_portfolio_canibalization',
				'data_widget_incremental_sales',
				'data_widget_expected_growth',
			].map((text) => intl.get(text).d(text)),
		},
		yAxis: {
			title: { text: '' },
			labels: {
				formatter: ({ value }) => `${value}%`,
			},
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			animation: false,
			outside: true,
			shared: true,
			followPointer: false,
			useHTML: true,
			backgroundColor: 'transparent',
			borderRadius: 10,
			borderWidth: 0,
			shadow: false,
			formatter,
		},
		plotOptions: {
			areaspline: {
				color: 'rgba(143, 162, 172, 0.5)',
				animation: {
					duration: 800,
				},
			},
			line: {
				marker: {
					enabled: false,
				},
				animation: {
					duration: 800,
				},
			},
		},
		series: [
			{
				type: 'waterfall',
				pointPadding: 0,
				borderWidth: 0,
				name: intl.get('data_widget_title_growth_factors_decomposition'),
				upColor: '#00B557',
				color: '#F44236',
				data: [
					{
						y: cann,
						color: total > inc_sales ? '#00B557' : '#F44236',
					},
					{
						y: inc_sales,
					},
					{
						isIntermediateSum: true,
						color: total > 0 ? '#00B557' : '#F44236',
					},
				],
			},
		],
	}
}

export default (props: Props) => <Chart config={getConfig(props)} />
