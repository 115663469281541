import { findIndex, map, max, pipe, prop, sum, uniqBy } from 'lodash/fp'
import React, { useMemo } from 'react'
import { useDebounceValue } from 'usehooks-ts'

import { Spinner } from '@cmpkit/base'
import { isFilterValid } from '@cmpkit/query-builder'

import {
	MatchedProductsRequestModel,
	PricingCampaignModel,
	ProductAssignmentModel,
} from '@/generated'
import intl from '@/locale'
import { formatNumber } from '@/tools/locale'

import {
	useMatchedProductsQuery,
	usePricingCampaignsQuery,
} from '../../queries'
import {
	alignPricingCampaignsOrders,
	isValidMatchedProductsAssignments,
} from '../../utils'

const getAssignmentPayload = (pc: PricingCampaignModel) => ({
	id: pc.id,
	order: pc.order,
	assignments: pc.settings.product_assignments.map(({ name, filters }) => ({
		id: name,
		filters,
	})),
})
function addVitualAssignmentPayload({
	currentAssignments,
	pricingCampaign,
}: {
	currentAssignments: ProductAssignmentModel
	pricingCampaignId?: string
	pricingCampaign?: PricingCampaignModel
}) {
	return (assignments: MatchedProductsRequestModel[]) => {
		const result = [...assignments]

		// Find the max order of the list of assignments
		const maxOrder = max(assignments.map(prop('order'))) as number

		// Find the index of the current PC in the list of assignments
		const currentIndex = findIndex(
			({ id }) => id === pricingCampaign?.id,
			assignments
		)
		// Check if the current PC is existed in the list of assignments
		const isExisted = currentIndex > -1

		if (isExisted && isValidProductAssignments(currentAssignments)) {
			// Update the current PC in the list of assignments with the new assignments
			result[currentIndex].assignments = currentAssignments.map(
				({ name, filters }) => ({
					id: name,
					filters,
				})
			)
		} else if (isValidProductAssignments(currentAssignments) && !isExisted) {
			// Add new PC to the list of assignments
			result.push({
				id: pricingCampaign?.id || 'new',
				order: pricingCampaign?.order || maxOrder + 1,
				assignments: currentAssignments.map(({ name, filters }) => ({
					id: name,
					filters,
				})),
			} as MatchedProductsRequestModel)
		}

		return uniqBy(prop('id'), result)
	}
}
function isValidProductAssignments(assignments: ProductAssignmentModel) {
	return (
		assignments?.length > 0 &&
		assignments?.every(
			({ filters, name }) =>
				name?.length > 0 && filters.length > 0 && filters.every(isFilterValid)
		)
	)
}
export function PricingCampaignAssignmentPrediction({
	assignments: currentAssignments,
	pricingCampaign,
	isGlobal,
	scenarioId,
	optimizationGroupId,
}: {
	assignments: ProductAssignmentModel
	pricingCampaign?: PricingCampaignModel
	isGlobal: boolean
	scenarioId?: string
	optimizationGroupId?: string | null
}) {
	const queryParams = isGlobal
		? { only_global: true }
		: scenarioId
			? {
					scenario_id: scenarioId,
					optimization_group_id: optimizationGroupId,
				}
			: { optimization_group_id: optimizationGroupId }

	const pricingCampaignsQuery = usePricingCampaignsQuery<
		MatchedProductsRequestModel[]
	>(queryParams, {
		enabled: isValidProductAssignments(currentAssignments),
		select: pipe([
			alignPricingCampaignsOrders,
			map(getAssignmentPayload),
			addVitualAssignmentPayload({
				currentAssignments,
				pricingCampaign,
			}),
		]),
	})

	const [allAssignmentsDebounced] = useDebounceValue(
		pricingCampaignsQuery.data || [],
		1000
	)

	const isEnabledMatchedQuery = useMemo(
		() =>
			allAssignmentsDebounced?.length > 0 &&
			isValidMatchedProductsAssignments(allAssignmentsDebounced),
		[allAssignmentsDebounced]
	)
	const matchedProductsQuery = useMatchedProductsQuery<{
		current: number
	}>(
		isGlobal ? {} : { optimization_group_id: optimizationGroupId! },
		allAssignmentsDebounced,
		{
			retry: 1,
			enabled:
				isEnabledMatchedQuery && isValidProductAssignments(currentAssignments),
			select: (data) => {
				const current = sum(
					data.pricing_campaigns
						.filter(({ id }) => id === pricingCampaign?.id || id === 'new')
						.map(prop('count'))
				)
				return {
					current,
				}
			},
		}
	)
	if (matchedProductsQuery.isError) {
		return null
	}
	if (
		(!matchedProductsQuery.isLoading && !matchedProductsQuery.data) ||
		!currentAssignments?.length
	) {
		return null
	}

	return (
		<div className='sticky bottom-2 mt-4 flex items-center justify-center'>
			{matchedProductsQuery.isLoading ? (
				<div className='flex h-6 items-center justify-center gap-2 rounded-full border bg-accent-4 p-1 text-xs font-medium shadow-lg'>
					<Spinner />
					<span className='font-medium text-muted'>Loading...</span>
				</div>
			) : matchedProductsQuery.data ? (
				<div className='min-w-7 items-center justify-center rounded-full border bg-accent-4 px-2 py-1 text-xs font-medium shadow-lg'>
					{intl
						.get('pc.assignment.predict', {
							value: formatNumber(matchedProductsQuery.data.current),
						})
						.d(
							`${formatNumber(matchedProductsQuery.data.current)} matching products we have found.`
						)}
				</div>
			) : null}
		</div>
	)
}
