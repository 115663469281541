import { format } from 'date-fns'
import { useCallback, useMemo } from 'react'

import { Button, Card } from '@cmpkit/base'
import {
	createStaticRange,
	DateRangeMenuPanel,
	DatesRange,
	defaultPresets,
	useSelectedPreset,
} from '@cmpkit/calendar'
import { useDisclosure } from '@cmpkit/hooks'
import CalendarIcon from '@cmpkit/icon/lib/glyph/calendar'
import Popover from '@cmpkit/popover'

import { DateFormat } from '@/tools/dates'

import { WidgetPeriod } from '../widgets/types'

type SinglePeriodsSelectProps = {
	period: WidgetPeriod
	onChangePeriod: (period: WidgetPeriod) => void
	defaultPeriod: WidgetPeriod
	maxDate?: Date
}
const toRangeValues = (value: {
	start_date: string
	end_date: string
}): DatesRange =>
	[value.start_date, value.end_date].map((v) => new Date(v)) as DatesRange
export default function SinglePeriodSelect({
	period,
	onChangePeriod,
	maxDate,
	defaultPeriod,
}: SinglePeriodsSelectProps) {
	const value = useMemo(() => toRangeValues(period), [period])
	const setValue = useCallback(
		(range: DatesRange) =>
			onChangePeriod({
				start_date: format(new Date(range[0] as Date), DateFormat.system),
				end_date: format(new Date(range[1] as Date), DateFormat.system),
			}),
		[]
	)
	return (
		<PeriodPicker
			value={value}
			defaultValue={toRangeValues(defaultPeriod)}
			setValue={setValue}
			maxDate={maxDate!}
		/>
	)
}

function PeriodPicker({
	value,
	setValue,

	defaultValue,
	maxDate,
}: {
	value: DatesRange
	setValue: (value: DatesRange) => void
	defaultValue: DatesRange
	maxDate?: Date
}) {
	const { isOpen, close, toggle } = useDisclosure()
	const staticRanges = createStaticRange(defaultPresets)
	const selected = useSelectedPreset({
		value,
		staticRanges,
	})
	return (
		<Popover
			placement='bottom-start'
			isOpen={isOpen}
			onDismiss={close}
			content={
				<Card className='flex flex-col border shadow'>
					<DateRangeMenuPanel
						value={value}
						maxDate={maxDate}
						defaultValue={defaultValue}
						defaultPresets={staticRanges}
						onChange={(range) => {
							setValue(range)
							close()
						}}
					/>
				</Card>
			}
		>
			<Button variant='tertiary' onClick={toggle} iconBefore={<CalendarIcon />}>
				{selected?.label || 'Select dates'}
			</Button>
		</Popover>
	)
}
