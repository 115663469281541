import React, { useState } from 'react'

import { Button, Label, Radio } from '@cmpkit/base'
import {
	Modal,
	ModalBody,
	ModalDescription,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@cmpkit/modal'

import { DataOption } from '@/common.types'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'

type Props = {
	isOpen: boolean
	close(): void
	selected: string[]
	resetSelectedItems?(): void
}
export default function SelectCopyPricingCampaignFlow({
	isOpen,
	close,
	selected,
	resetSelectedItems,
}: Props) {
	const [type, setType] = useState<
		'COPY_PRICING_CAMPAIGN' | 'COPY_PRICING_CAMPAIGN_SETTINGS'
	>('COPY_PRICING_CAMPAIGN_SETTINGS')
	const { showModal } = useModalStore()
	const options: DataOption[] = [
		{
			value: 'COPY_PRICING_CAMPAIGN_SETTINGS',
			label: intl.get('pc.modal.select_copy_mode.copy_settings'),
		},
		{
			value: 'COPY_PRICING_CAMPAIGN',
			label: intl.get('pc.modal.select_copy_mode.copy_as_dublicate'),
		},
	]
	const handleNextClick = () => {
		analytic.logEvent(
			'settings: pricing campaigns: table: select copy option',
			{
				value: type,
			}
		)
		showModal(type, {
			selected,
			resetSelectedItems,
		})
		close()
	}
	const handleChange = ({
		target: { value },
	}: React.ChangeEvent<HTMLInputElement>) =>
		setType(value as 'COPY_PRICING_CAMPAIGN' | 'COPY_PRICING_CAMPAIGN_SETTINGS')
	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={close} showCloseButton size='mini'>
					<ModalHeader>
						<ModalTitle>
							{intl.get('pc.modal.select_copy_mode.title')}
						</ModalTitle>
						<ModalDescription>
							{intl.get('pc.modal.select_copy_mode.subtitle')}
						</ModalDescription>
					</ModalHeader>
					<ModalBody className='space-y-5 py-5'>
						{options.map(({ value, label }: DataOption) => (
							<div className='flex items-center space-x-2'>
								<Radio
									key={value}
									id={`radio-${value}`}
									value={value}
									name='copy_type'
									checked={type === value}
									onChange={handleChange}
								/>
								<Label htmlFor={`radio-${value}`}>{label}</Label>
							</div>
						))}
					</ModalBody>
					<ModalFooter className='justify-between'>
						<Button onClick={close}>{intl.get('general_cancel')}</Button>
						<Button variant='primary-brand' onClick={handleNextClick}>
							{intl.get('general_next')}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	)
}
