import { useQuery } from '@tanstack/react-query'

import {
	CountByFiltersRequestBody,
	GetMatchedProductsCountParams,
	MatchedProductsRequestModel,
	MatchedProductsResponseModel,
	PcProductsCountsModel,
	PricingCampaignListParams,
	PricingCampaignModel,
	PricinRuleUiSchemaModel,
	ProductsCountsByOgResponseData,
} from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'
import { cyrb53 } from '@/tools/utils'

/**
 * Queries
 */

export const usePricingCampaignsQuery = <T = PricingCampaignModel[]>(
	queryParams: PricingCampaignListParams,
	options?: UseQueryOptions<PricingCampaignModel[], Error, T>
) => {
	return useQuery<PricingCampaignModel[], Error, T>({
		queryKey: ['pricing-campaigns', queryParams],
		queryFn: () => client.pricingCampaign.pricingCampaignList(queryParams),
		...(options || {}),
	})
}
export const usePricingCampaignQuery = <T = PricingCampaignModel>(
	id: string,
	options?: UseQueryOptions<PricingCampaignModel, Error, T>
) => {
	return useQuery<PricingCampaignModel, Error, T>({
		queryKey: ['pricing-campaigns', {}, id],
		queryFn: () => {
			return client.pricingCampaign.pricingCampaignGet(id)
		},
		...(options || {}),
	})
}

export const usePricingRulesUiSchemasQuery = <T = PricinRuleUiSchemaModel[]>(
	options?: UseQueryOptions<PricinRuleUiSchemaModel[] | undefined, Error, T>
) => {
	return useQuery<PricinRuleUiSchemaModel[] | undefined, Error, T>({
		queryKey: ['pricing-rules-ui-schemas'],
		queryFn: () => client.core.listUiSchemas(),
		...(options || {}),
	})
}

export const useMatchedProductsQuery = <T = MatchedProductsResponseModel>(
	queryParams: GetMatchedProductsCountParams,
	items: MatchedProductsRequestModel[],
	options?: UseQueryOptions<MatchedProductsResponseModel, Error, T>
) => {
	return useQuery<MatchedProductsResponseModel, Error, T>({
		queryKey: ['matched-products', queryParams, cyrb53(JSON.stringify(items))],
		queryFn: () => client.util.getMatchedProductsCount(queryParams, items),
		...(options || {}),
	})
}
export const useProductsCountsByPcQuery = <T = PcProductsCountsModel[]>(
	body: CountByFiltersRequestBody,
	options?: UseQueryOptions<PcProductsCountsModel[], Error, T>
) => {
	return useQuery<PcProductsCountsModel[], Error, T>({
		queryKey: ['products-counts-by-pc', body],
		queryFn: () => client.util.getProductsCountsByPc(body),
		...(options || {}),
	})
}
export const useProductsCountsByOgQuery = <T = PcProductsCountsModel[]>(
	body: CountByFiltersRequestBody,
	options?: UseQueryOptions<PcProductsCountsModel[], Error, T>
) => {
	return useQuery<ProductsCountsByOgResponseData, Error, T>({
		queryKey: ['products-counts-by-og', body],
		queryFn: () => client.util.getProductsCountsByOg(body),
		...(options || {}),
	})
}
