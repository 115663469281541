import { intlFormat } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import { groupBy, orderBy } from 'lodash/fp'
import { ChevronLeftIcon, ChevronRight, PlusIcon } from 'lucide-react'
import React, { Fragment, useMemo } from 'react'

import { Button, LinkButton, Separator } from '@cmpkit/base'
import { Operators } from '@cmpkit/query-builder'
import {
	Sidebar,
	SidebarContent,
	SidebarFooter,
	SidebarGroup,
	SidebarGroupContent,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
} from '@cmpkit/sidebar'

import UsernameById from '@/components/UsernameById'
import { SettingsTemplateType } from '@/generated'
import intl from '@/locale'
import { useModalStore } from '@/modules/modals/store'

import { useSettingsTemplatesQuery } from '../../queries'
import SettingsTemplateActions from '../SettingsTemplateActions'
import BuilderBlockElement from './BuilderBlockElement'
import { useTemplateBuilderContext } from './TemplateBuilderProvider'

export default function TemplatesBuilderLeftPanel() {
	const { activeType } = useTemplateBuilderContext()
	return (
		<Sidebar
			collapsible='none'
			data-testid='builder-sidebar'
			className='fade-in !w-[400px] border-r !bg-accent-2'
		>
			<AnimatePresence mode={'wait'} initial={false}>
				{!activeType ? (
					<motion.div
						key={'general'}
						className='flex size-full flex-col'
						initial='in'
						animate='visible'
						exit='out'
						variants={{
							in: { opacity: 0, x: -20 },
							visible: { opacity: 1, x: 0 },
							out: { opacity: 0, x: -20 },
						}}
						transition={{
							duration: 0.1,
						}}
					>
						<SidabarGeneralContent />
					</motion.div>
				) : (
					<motion.div
						key={activeType}
						className='flex size-full flex-col'
						initial='in'
						animate='visible'
						exit='out'
						variants={{
							in: { opacity: 0, x: 20 },
							visible: { opacity: 1, x: 0 },
							out: { opacity: 0, x: 20 },
						}}
						transition={{
							duration: 0.1,
						}}
					>
						<SidabarEntityContent />
					</motion.div>
				)}
			</AnimatePresence>
		</Sidebar>
	)
}

function SidabarGeneralContent() {
	const { setActiveType, meta, template, handleFinish } =
		useTemplateBuilderContext()
	return (
		<Fragment>
			<SidebarHeader className='border-b'>
				<div className='flex w-full items-center justify-between'>
					<div className='font-bold'>
						{intl.get('settings.general.title').d('General')}
					</div>
					{template && (
						<SettingsTemplateActions
							onFinish={handleFinish}
							template={template}
						/>
					)}
				</div>
			</SidebarHeader>
			<SidebarContent className='flex w-full flex-col items-start pt-2'>
				<SidebarGroup className='border-b'>
					<SidebarGroupContent>
						<SidebarMenu>
							{[
								SettingsTemplateType.PricingCampaign,
								SettingsTemplateType.ScenarioBusinessConstraints,
								SettingsTemplateType.ScenarioStopList,
								SettingsTemplateType.ScenarioStrategy,
							].map((section) => {
								return (
									<SidebarMenuItem>
										<SidebarMenuButton
											type='button'
											onClick={() => setActiveType(section)}
										>
											<span className='font-medium'>
												{intl.get(`template.type.${section}`).d(section)}
											</span>
											<ChevronRight className='ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90' />
										</SidebarMenuButton>
									</SidebarMenuItem>
								)
							})}
						</SidebarMenu>
					</SidebarGroupContent>
				</SidebarGroup>
			</SidebarContent>
			<SidebarFooter className='border-t'>
				<div className='mt-auto flex flex-col gap-3 p-3'>
					{meta?.created_by && (
						<section>
							<span className='text-xs font-medium text-muted'>
								{intl.get('general_created')}:
							</span>
							<p className='flex items-center gap-1 text-xs font-medium'>
								{intl.get('by')} <UsernameById userId={meta?.created_by} />,{' '}
								{meta?.created_at && intlFormat(new Date(meta?.created_at))}
							</p>
						</section>
					)}
					{meta?.updated_by && (
						<section>
							<span className='text-xs font-medium text-muted'>
								{intl.get('general_last_updated')}:
							</span>
							<p className='flex items-center gap-1 text-xs font-medium'>
								{intl.get('by')} <UsernameById userId={meta?.updated_by} />,{' '}
								{meta?.updated_at && intlFormat(new Date(meta?.updated_at))}
							</p>
						</section>
					)}
				</div>
			</SidebarFooter>
		</Fragment>
	)
}
function SidabarEntityContent() {
	const { showModal } = useModalStore()
	const { activeType, setActiveType } = useTemplateBuilderContext()
	const templatesQuery = useSettingsTemplatesQuery({
		offset: 0,
		limit: 1000,
		filters: [
			{
				name: 'template_type',
				operation: Operators.IN,
				value: [
					SettingsTemplateType.ScenarioBusinessConstraints,
					SettingsTemplateType.ScenarioStrategy,
					SettingsTemplateType.ScenarioStopList,
					SettingsTemplateType.PricingCampaign,
				],
			},
		],
	})

	const sectionsItems = useMemo(() => {
		const templates = templatesQuery.data?.data || []

		return orderBy(
			['created_at'],
			['desc'],
			groupBy('template_type', templates)[activeType as SettingsTemplateType]
		)
	}, [templatesQuery.data, activeType])

	return (
		<Fragment>
			<SidebarHeader className='border-b'>
				<div className='flex w-full items-center gap-2'>
					<Button
						iconBefore={<ChevronLeftIcon />}
						variant='tertiary'
						onClick={() => setActiveType(null)}
						size='small'
					/>
					<Separator orientation='vertical' className='h-5' />
					<div className='font-bold'>
						{intl.get(`template.type.${activeType}`).d(activeType || '')}
					</div>
				</div>
				<div className='flex items-center gap-2 text-xs'>
					<span className='text-muted'>
						{sectionsItems.length} {intl.get('entity.templates').d('Templates')}
					</span>
					<Separator orientation='vertical' className='h-5' />
					<LinkButton
						onClick={() =>
							showModal('SETTINGS_TEMPLATE_MODAL', {
								templateType: activeType as SettingsTemplateType,
							})
						}
					>
						<PlusIcon />
						{intl.get('general_create').d('Create')}
					</LinkButton>
				</div>
			</SidebarHeader>
			<SidebarContent className='flex w-full flex-col items-start'>
				<SidebarGroup>
					<SidebarGroupContent>
						<SidebarMenu>
							{templatesQuery.isPending ? (
								<>
									<div className='h-11 w-full animate-pulse rounded-lg bg-accent-4' />
									<div className='h-11 w-full animate-pulse rounded-lg bg-accent-4 opacity-75' />
									<div className='h-11 w-full animate-pulse rounded-lg bg-accent-4 opacity-50' />
									<div className='h-11 w-full animate-pulse rounded-lg bg-accent-4 opacity-25' />
								</>
							) : (
								sectionsItems.map((template) => {
									return (
										<BuilderBlockElement key={template.id} block={template} />
									)
								})
							)}
						</SidebarMenu>
					</SidebarGroupContent>
				</SidebarGroup>
			</SidebarContent>
		</Fragment>
	)
}
