import { JSONSchemaType } from 'ajv'
import { any, isNaN } from 'lodash/fp'
import React, { ChangeEvent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormError, Switch, Textfield } from '@cmpkit/base'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import Tooltip from '@cmpkit/tooltip'

import intl from '@/locale'
import { numericOnly } from '@/tools/constants'
import { ErrorSchema } from '@/tools/json-schema-utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyJSONScheme = JSONSchemaType<any>
const transformValue = (value: string) => {
	try {
		if (any((i) => value == i, ['', '-', '-0', '-0.'])) return value
		const val = Number(value.replace(numericOnly, ''))
		return isNaN(val) ? value : val
	} catch {
		return value
	}
}
type RHFSettingsFieldProps = {
	value: any // eslint-disable-line @typescript-eslint/no-explicit-any
	name: string
	onChange: (value: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
	schema: JSONSchemaType<any> // eslint-disable-line @typescript-eslint/no-explicit-any
	errorSchema?: ErrorSchema
}
function RHFSettingsField({
	value,
	name,
	onChange,
	schema,
	errorSchema,
}: RHFSettingsFieldProps) {
	const defaultValue = schema?.default || 1
	const nullable = schema.type.includes('null')
	const bool = schema.type.includes('boolean')
	const checked = bool ? value : value !== null
	const type = schema.type.filter((t: string) => t != 'null')[0]
	const error = errorSchema?.message
	const translatedDescription = schema.description
		? intl.get(schema.description).d(schema.description)
		: ''
	const translatedTitle = intl
		.get(schema.title || `${'og_stoplist_default_rules'}_${name}`)
		.d(schema.title)

	const handleToggleField = (e: ChangeEvent<HTMLInputElement>) => {
		onChange(
			type === 'boolean'
				? e.target.checked
				: e.target.checked
					? defaultValue
					: null
		)
	}
	const handleChangeField = (e: ChangeEvent<HTMLInputElement>) => {
		onChange(transformValue(e.target.value))
	}
	return (
		<div className='flex flex-col' key={name}>
			<div className='flex h-8 items-center space-x-3'>
				{(nullable || bool) && (
					<Switch name={name} checked={checked} onChange={handleToggleField} />
				)}
				<div className='flex items-center space-x-3'>
					<label className='flex items-center'>{translatedTitle}</label>
					{!bool && value !== null && (
						<Textfield
							type={type}
							value={value}
							name={`${name}_input_value`}
							invalid={(!value && value !== 0) || error}
							onChange={handleChangeField}
							addonAfter={
								schema.measure && intl.get(schema.measure).d(schema.measure)
							}
						/>
					)}
				</div>
				{schema.description && translatedDescription?.length > 100 && (
					<Tooltip content={translatedDescription}>
						<span className='inline-flex'>
							<InfoIcon />
						</span>
					</Tooltip>
				)}
			</div>
			{error && <FormError>{error}</FormError>}
			{schema.description && translatedDescription.length < 100 && (
				<p className='text-muted'>{translatedDescription}</p>
			)}
		</div>
	)
}
type RHFSettingsWidgetProps = {
	schema: AnyJSONScheme
	name?: string
	shouldUnregister?: boolean
}
export default function RHFSettingsWidget({
	schema,
	name,
	shouldUnregister,
}: RHFSettingsWidgetProps) {
	const { control } = useFormContext()
	const options = Object.entries(
		schema.properties as Record<string, AnyJSONScheme>
	)

	return (
		<div className='space-y-2 text-xs'>
			{options.map(([fieldName, fieldSchema]: [string, AnyJSONScheme]) => {
				const defaultValue = fieldSchema?.default
				return (
					<Controller
						key={fieldName}
						name={name ? `${name}.${fieldName}` : fieldName}
						shouldUnregister={shouldUnregister}
						defaultValue={defaultValue}
						control={control}
						render={({ field: { onChange, value }, fieldState: { error } }) => {
							return (
								<RHFSettingsField
									errorSchema={error}
									value={value}
									name={fieldName}
									schema={fieldSchema}
									onChange={onChange}
								/>
							)
						}}
					/>
				)
			})}
		</div>
	)
}
