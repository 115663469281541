import { filter, find } from 'lodash/fp'

import { BadgeProps } from '@cmpkit/base'

import {
	AlertAnnotationModel,
	AlertInfoModel,
	AlertLevel,
	AlertsInfoResponseModel,
	PricingCampaignModel,
} from '@/generated'
import intl from '@/locale'

export type AlertStyling = {
	backgroundClassNames: string
	badgeVariant: BadgeProps['variant']
}
type SolutionData = {
	pricingCampaigns: PricingCampaignModel[] | null
	alertsByPc: Record<string, string[]>
	optimizationGroupId: string
}
type GetPricingAlertsTreeProps = {
	pricingAlerts?: AlertsInfoResponseModel
	annotations?: AlertAnnotationModel[]
	solutionData?: SolutionData
}
export const getAlertLevelStyling = (level: string): AlertStyling => {
	switch (level) {
		case AlertLevel.Warning:
			return {
				backgroundClassNames: 'bg-warning-25 dark:bg-warning-150',
				badgeVariant: 'warning',
			}
		case AlertLevel.Info:
			return {
				backgroundClassNames: 'bg-success-25 dark:bg-success-150',
				badgeVariant: 'success',
			}
		case AlertLevel.Unassigned:
		case AlertLevel.Critical:
			return {
				backgroundClassNames: 'bg-danger-25 dark:bg-danger-175',
				badgeVariant: 'danger',
			}
		default:
			return {
				backgroundClassNames: '',
				badgeVariant: 'neutral',
			}
	}
}

/**
 * Get the pricing alerts tree from the alerts and annotations
 * @param pricingAlerts - The alerts
 * @param annotations - The annotations
 * @param solutionData - The solution data
 * @returns - The pricing alerts tree
 */
export function getPricingAlertsTree({
	pricingAlerts,
	annotations,
	solutionData,
}: GetPricingAlertsTreeProps) {
	return pricingAlerts?.by_level
		?.sort(
			sortedLevels([
				AlertLevel.Unassigned,
				AlertLevel.Critical,
				AlertLevel.Warning,
				AlertLevel.Info,
			])
		)
		?.map(
			({ level, products, est_revenue, est_revenue_share }: AlertInfoModel) => {
				return {
					level: level!,
					products,
					est_revenue,
					est_revenue_share,
					alerts: filter({ level }, pricingAlerts?.by_id)?.map(
						({ id, products, est_revenue, est_revenue_share }) => {
							const alertAnnotation = find(
								{ id },
								annotations
							) as AlertAnnotationModel
							return {
								id: id!,
								name: alertAnnotation?.name || id!,
								products,
								est_revenue,
								est_revenue_share,
								...(solutionData && {
									solution: {
										description: alertAnnotation?.description ?? '',
										tooltip: alertAnnotation?.solution_tooltip ?? '',
										pricingCampaigns:
											solutionData?.pricingCampaigns?.filter((pc) =>
												solutionData.alertsByPc?.[id!]?.includes(pc.id)
											) || null,
										optimizationGroupId: solutionData.optimizationGroupId,
									},
								}),
							}
						}
					),
				}
			}
		)
}
const sortedLevels =
	(levels: AlertLevel[]) =>
	(
		a: AlertInfoModel | AlertAnnotationModel,
		b: AlertInfoModel | AlertAnnotationModel
	) => {
		const aIndex = levels.indexOf(a.level! as AlertLevel)
		const bIndex = levels.indexOf(b.level! as AlertLevel)

		if (aIndex > bIndex) return 1
		if (aIndex < bIndex) return -1

		return 0
	}
export const getAlertAnnotationDataChoices = (
	annotations: AlertAnnotationModel[]
) =>
	annotations
		?.sort(
			sortedLevels([
				AlertLevel.Unassigned,
				AlertLevel.Critical,
				AlertLevel.Warning,
				AlertLevel.Info,
			])
		)
		.map(alertAnnotationToDataChoice)

export const alertAnnotationToDataChoice = ({
	id,
	name,
	level,
}: {
	id: string
	name: string
	level: AlertLevel
}) => ({
	label: name?.includes(' ') ? name : intl.get(name).d(name),
	value: id,
	className: `text-${getAlertLevelStyling(level).badgeVariant}`,
})
