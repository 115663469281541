import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { ExportModel, ExportTemplateModel } from '@/generated'
import { client } from '@/network/client'

export function useExportsQuery<T = ExportModel[]>(
	options?: UseQueryOptions<ExportModel[], Error, T>
) {
	return useQuery<ExportModel[], Error, T>({
		queryKey: ['exports'],
		queryFn: () => client.export.listExports(),
		...(options || {}),
	})
}
export function useExportTemplatesQuery<T = ExportTemplateModel[]>(
	options?: UseQueryOptions<ExportTemplateModel[], Error, T>
) {
	return useQuery<ExportTemplateModel[], Error, T>({
		queryKey: ['exports', 'templates'],
		queryFn: () => client.export.getExportTemplates(),
		...(options || {}),
	})
}
