import clsx from 'clsx'
import { formatDistanceToNow, intlFormat, isToday } from 'date-fns'
import { indexBy, prop } from 'lodash/fp'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useIsFetching } from '@tanstack/react-query'
import {
	CellContext,
	getCoreRowModel,
	getExpandedRowModel,
	getSortedRowModel,
	Row,
	SortingState,
} from '@tanstack/react-table'

import {
	Badge,
	Button,
	Checkbox,
	LinkButton,
	linkButtonVariants,
	Separator,
} from '@cmpkit/base'
import ArrowRightIcon from '@cmpkit/icon/lib/glyph/arrow-right'
import CaretDownIcon from '@cmpkit/icon/lib/glyph/caret-down'
import CaretRightIcon from '@cmpkit/icon/lib/glyph/caret-right'
import DotsFilledIcon from '@cmpkit/icon/lib/glyph/dots-filled'
import PlusIcon from '@cmpkit/icon/lib/glyph/plus'
import SortIcon from '@cmpkit/icon/lib/glyph/sort'
import Tooltip from '@cmpkit/tooltip'

import OptimizationGroupsTooltipList from '@/components/OptimizationGroupsTooltipList'
import { getGroupedRowModel } from '@/components/table/getGroupedRowModel'
import { useMasterTable } from '@/components/table/hooks/useMasterTable'
import MasterTable from '@/components/table/MasterTable'
import { createMasterColumnHelper } from '@/components/table/utils/tanstack.helpers'
import UsernameById from '@/components/UsernameById/UsernameById'
import { OptimizationGroupModel } from '@/generated'
import intl from '@/locale'
import {
	useOptimizationGroupsQuery,
	useOptimizationsQuery,
} from '@/modules/core/queries'
import { useDrawersStore } from '@/modules/drawers/store'
import { useModalStore } from '@/modules/modals/store'
import analytic from '@/services/analytics'
import { formatNumber } from '@/tools/locale'
import { useOptimizationIsReadyForChange } from '@/modules/core/hooks/useOptimizationIsReadyForChange'

import {
	getPricingCampaignEngine,
	globalPricingCampaignProductsLink,
	isGlobalPricingCampaign,
	loclaPricingCampaignProductsLink,
} from '../../helpers'
import { PricingCampaignsScope } from '../../types'
import { usePricingCampaignActions } from '../Campaign/usePricingCampaignActions'
import EngineBadge from '../EngineBadge'
import GlobalPcBadge from '../GlobalPcBadge'
import PricingCampaignActionsDropdown from '../PricingCampaignActionsDropdown'
import {
	PricingCampaignsContext,
	PricingCampaignTableRow,
} from './PricingCampaignsProvider'

type PricingCampaignsTableProps = {
	data: PricingCampaignTableRow[]
	scope?: PricingCampaignsScope
}

export default function PricingCampaignsTable({
	data,
	scope,
}: PricingCampaignsTableProps) {
	const [sorting, setSorting] = useState<SortingState>([
		{
			desc: false,
			id: 'order',
		},
	])

	useEffect(() => {
		analytic.logEvent('settings: pricing campaigns: table: sort by', {
			value: sorting.map(prop('id')),
		})
	}, [sorting])
	const summary = useMemo(() => {
		return {
			items: data.length,
			alerts: data.reduce(
				(acc: number, curr: PricingCampaignTableRow) =>
					acc + (curr.alerts_count || 0),
				0
			),
			products: data.reduce(
				(acc: number, curr: PricingCampaignTableRow) =>
					acc + (curr.products_count || 0),
				0
			),
		}
	}, [data])
	const columns = useMemo(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		() => getColumns({ scope, summary }) as any,
		[scope, summary]
	)
	const table = useMasterTable<PricingCampaignTableRow>({
		data,
		columns,
		state: {
			sorting,
		},
		initialState: {
			columnVisibility: scope?.optimization_group_id
				? {
						optimization_groups: false, //hide this column by default
					}
				: {},
			expanded: true,
			grouping: ['campaign.optimization_group_id'],
		},
		enableGrouping: true,
		enableColumnActions: false,
		enableTableFooter: true,
		groupedColumnMode: 'remove',
		defaultColumn: {
			minSize: 28,
			size: Number.MAX_SAFE_INTEGER,
			maxSize: Number.MAX_SAFE_INTEGER,
		},
		enableColumnPinning: false,
		enableSorting: true,
		layoutMode: 'grid',
		enableVirtualization: true,
		getRowHeight(row) {
			return row?.depth === 0 ? 80 : 36
		},
		renderGroup: ({ row }) => <TableOptimzationGroupGroup row={row} />,
		onSortingChange: setSorting,
		getExpandedRowModel: getExpandedRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getGroupedRowModel: getGroupedRowModel(),
		getCoreRowModel: getCoreRowModel(),
	})

	return <MasterTable table={table} />
}

const columnHelper = createMasterColumnHelper<PricingCampaignTableRow>()
const getColumns = ({
	summary,
	scope,
}: {
	summary: {
		items: number
		alerts: number
		products: number
	}
	scope?: PricingCampaignsScope
}) => {
	const isAllGlobal = !scope?.optimization_group_id
	return [
		columnHelper.accessor((row) => row.campaign.id, {
			id: 'control',
			enableHeaderTooltip: false,
			enableColumnActions: false,
			enableSorting: false,
			header: () => '',
			cell: (info) => {
				return <ControlCell info={info} isAllGlobal={isAllGlobal} />
			},
			size: 28,
		}),
		columnHelper.accessor('campaign.name', {
			header: () => intl.get('pc_name'),
			cell: (info) => {
				return <PricingCampaignNameCell info={info} />
			},
			size: 340,
		}),
		columnHelper.accessor('order', {
			header: () => intl.get('general_priority'),
			cell: (info) => {
				return (
					<Badge className='font-mono text-foreground no-underline'>
						{info.getValue()}
					</Badge>
				)
			},
			sortingFn: (rowA, rowB) => {
				if (rowA.depth === 0) {
					if (rowA.groupingValue === 'null') {
						return -1
					}
					return 0
				}
				return rowA.original.order > rowB.original.order
					? 1
					: rowA.original.order < rowB.original.order
						? -1
						: 0
			},
			size: 70,
		}),
		columnHelper.accessor('campaign.settings.pricing_tactics.engine', {
			header: () => intl.get('pricing_engine'),
			cell: (info) => {
				return <PricingCampaignEngineCell info={info} />
			},
			size: 180,
		}),
		columnHelper.accessor('products_count', {
			header: () => intl.get('items_in_pc'),
			cell: (info) => {
				return <ProductsCountCell info={info} scope={scope} />
			},
			footer: () => {
				return (
					<div>
						<div className='text-xs text-muted'>
							{intl.get('total').d('Total')}:
						</div>
						<div className='font-bold'>{formatNumber(summary.products)}</div>
					</div>
				)
			},
			size: 120,
		}),
		columnHelper.accessor('campaign.optimization_group_id', {
			id: 'campaign.optimization_group_id',
			header: () => intl.get('items_in_pc'),
			getGroupingValue(row) {
				return row.campaign.optimization_group_id
			},
			cell: (info) => {
				return <div className='truncate'>{info.getValue()}</div>
			},
			size: 120,
		}),
		// Visible only for global pricing campaigns
		!scope?.optimization_group_id &&
			columnHelper.accessor('optimization_groups', {
				header: () => intl.get('ogs').d('OGs'),
				cell: (info) => {
					return <OptimizationGroupsCell info={info} />
				},
				size: 70,
			}),
		columnHelper.accessor('alerts_count', {
			header: () => intl.get('alerts').d('Alerts'),
			cell: (info) => {
				return <AlertsCell info={info} />
			},
			footer: () => {
				return (
					<div>
						<div className='text-xs text-muted'>
							{intl.get('total').d('Total')}:
						</div>
						<div className='font-bold'>{formatNumber(summary.alerts)}</div>
					</div>
				)
			},
			size: 80,
		}),
		columnHelper.accessor(prop('campaign.id'), {
			id: 'period',
			header: () => intl.get('period'),
			cell: (info) => {
				return <ActivityPeriodCell info={info} />
			},
			size: 200,
		}),
		columnHelper.accessor('campaign.updated_at', {
			header: () => intl.get('last_modified_at'),
			cell: (info) => {
				const isOgReadyForChange = useOptimizationIsReadyForChange(
					info?.row.original.campaign.optimization_group_id
				)
				return (
					<LastModifiedCell
						info={info}
						isAllGlobal={isAllGlobal}
						isOgReadyForChange={Boolean(isOgReadyForChange)}
					/>
				)
			},
		}),
	].filter(Boolean)
}

function ControlCell({
	info,
	isAllGlobal,
}: {
	info: CellContext<PricingCampaignTableRow, string>
	isAllGlobal: boolean
}) {
	const id = info.getValue()
	const { campaign } = info.row.original
	const { selected, setSelected } = useContext(PricingCampaignsContext)
	const isSelected = selected.includes(id)
	const isDisabled = isGlobalPricingCampaign(campaign) && !isAllGlobal
	return (
		<Checkbox
			disabled={isDisabled}
			checked={isSelected}
			onChange={() =>
				setSelected(
					isSelected
						? selected.filter((item) => item !== id)
						: [...selected, id]
				)
			}
		/>
	)
}
function AlertsCell({
	info,
}: {
	info: CellContext<PricingCampaignTableRow, number | undefined>
}) {
	const value = info.getValue()
	const { campaign, optimization_groups } = info.row.original
	const { openDrawer } = useDrawersStore()
	const isFetching = useIsFetching({
		queryKey: [
			'pricing-alerts',
			{
				group_by: 'pricing_campaign_id',
				optimization_group_id: campaign.optimization_group_id || undefined,
			},
		],
	})
	if (isFetching && !value) {
		return <div className='h-4 w-11 animate-pulse rounded-lg bg-accent-4' />
	}
	const isGlobal = isGlobalPricingCampaign(campaign)
	const optimizationGroupIds = isGlobal
		? optimization_groups
		: [campaign.optimization_group_id]
	return value ? (
		<Badge
			variant='danger'
			className='cursor-pointer font-mono'
			interactive
			onClick={() => {
				analytic.logEvent('pcs: pricing campaigns: alerts by pc: open')
				openDrawer('PRICING_ALERTS', {
					type: 'byPricingCampaign',
					pricingCampaign: campaign,
					optimizationGroupIds: optimizationGroupIds as string[],
					drawerClassName: isGlobal
						? 'global-pc-alerts-eastside'
						: 'pc-alerts-eastside',
				})
			}}
		>
			{formatNumber(value || 0)}
		</Badge>
	) : (
		'-'
	)
}

function OptimizationGroupsCell({
	info,
}: {
	info: CellContext<PricingCampaignTableRow, string[] | undefined>
}) {
	const value = info.getValue()
	const navigate = useNavigate()
	const groupsQuery = useOptimizationGroupsQuery<
		Record<string, OptimizationGroupModel>
	>({
		select: indexBy<OptimizationGroupModel>('id'),
	})
	return info.row.original.campaign?.optimization_group_id ? (
		<Link
			to={`/p/og?group.id__in=${info.row.original?.campaign?.optimization_group_id}`}
			className={clsx(linkButtonVariants(), '!block !truncate')}
		>
			{
				groupsQuery.data?.[info.row.original?.campaign?.optimization_group_id]
					?.name
			}
		</Link>
	) : !!value?.length ? (
		<Tooltip
			placement='left'
			content={<OptimizationGroupsTooltipList ids={value} />}
		>
			<Badge
				interactive
				className='cursor-pointer'
				onClick={() => navigate(`/p/og?group.id__in=${value.join(',')}`)}
			>
				{formatNumber(value?.length || 0)}
			</Badge>
		</Tooltip>
	) : (
		'-'
	)
}
function PricingCampaignNameCell({
	info,
}: {
	info: CellContext<PricingCampaignTableRow, string>
}) {
	const { showModal } = useModalStore()
	const { campaign } = info.row.original
	return (
		<div className='flex items-center gap-2'>
			<LinkButton
				variant='brand'
				className='text-left'
				onClick={() => {
					analytic.logEvent('settings: pricing campaigns: view: click', {
						location: 'pricing-camapigns-table',
					})
					showModal('PRICING_CAMPAIGN_MODAL', {
						pricingCampaignId: campaign.id,
						optimizationGroupId: campaign.optimization_group_id,
						scenarioId: campaign.scenario_id,
					})
				}}
			>
				{info.getValue()}
			</LinkButton>
		</div>
	)
}
function ProductsCountCell({
	info,
	scope,
}: {
	info: CellContext<PricingCampaignTableRow, number>
	scope?: PricingCampaignsScope
}) {
	const value = info.getValue()
	const { campaign, assortment_share } = info.row.original
	const isFetching = useIsFetching({
		queryKey: ['products-counts-by-pc'],
	})
	if (isFetching) {
		return <div className='h-4 w-9 animate-pulse rounded-lg bg-accent-4' />
	}
	return (
		<LinkButton variant='brand' asChild className='font-mono'>
			<Link
				to={
					scope?.optimization_group_id
						? loclaPricingCampaignProductsLink(campaign, scope)
						: globalPricingCampaignProductsLink(campaign)
				}
			>
				{formatNumber(value)} ({`${assortment_share}%`})
			</Link>
		</LinkButton>
	)
}
function PricingCampaignEngineCell({
	info,
}: {
	info: CellContext<PricingCampaignTableRow, string>
}) {
	const { campaign } = info.row.original
	const engine = getPricingCampaignEngine(campaign)
	return (
		<div className='flex items-center gap-1'>
			<EngineBadge engine={engine}>
				{intl.get('campaigns_om_title_' + engine)}
			</EngineBadge>
			{isGlobalPricingCampaign(campaign) && <GlobalPcBadge />}
		</div>
	)
}
function ActivityPeriodCell({
	info,
}: {
	info: CellContext<PricingCampaignTableRow, string>
}) {
	const { campaign } = info.row.original
	const { start_date, end_date } = campaign
	return (
		<div className='flex items-center space-x-1 font-normal'>
			{start_date && <span>{intlFormat(new Date(start_date + 'Z'))}</span>}
			{(start_date || end_date) && <ArrowRightIcon className='shrink-0' />}
			{end_date && <span>{intlFormat(new Date(end_date + 'Z'))}</span>}
		</div>
	)
}
function LastModifiedCell({
	info,
	isAllGlobal,
	isOgReadyForChange,
}: {
	info: CellContext<PricingCampaignTableRow, string | null | undefined>
	isAllGlobal: boolean
	isOgReadyForChange: boolean
}) {
	const { campaign } = info.row.original
	const date = campaign.updated_at && new Date(campaign.updated_at + 'Z')
	const isGlobal = isGlobalPricingCampaign(campaign)
	const isReadyToChange = isGlobal || isOgReadyForChange

	const actions = usePricingCampaignActions({
		pricingCampaign: campaign,
		optimizationGroupId: campaign.optimization_group_id,
		scenarioId: campaign.scenario_id,
	})
	return (
		<div className='flex items-center justify-between font-normal'>
			<div className='truncate'>
				{date && (
					<Tooltip
						content={intlFormat(date, {
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
							hour: 'numeric',
							minute: 'numeric',
						})}
					>
						<span>
							{isToday(date)
								? formatDistanceToNow(date, {
										addSuffix: true,
									})
								: intlFormat(date)}
						</span>
					</Tooltip>
				)}
				{campaign.updated_by && date && ` ${intl.get('by').d('by')} `}
				{campaign.updated_by ? (
					<UsernameById userId={campaign.updated_by} />
				) : (
					'-'
				)}
			</div>
			{isAllGlobal ? (
				<PricingCampaignActionsDropdown
					pricingCampaign={campaign}
					actions={actions}
					isReadyToChange={isReadyToChange}
				>
					<Button
						variant='tertiary'
						className='w-xs ml-2'
						iconBefore={<DotsFilledIcon />}
					/>
				</PricingCampaignActionsDropdown>
			) : (
				!isGlobal &&
				actions && (
					<PricingCampaignActionsDropdown
						pricingCampaign={campaign}
						actions={actions}
						isReadyToChange={isReadyToChange}
					>
						<Button
							variant='tertiary'
							className='w-xs ml-2'
							iconBefore={<DotsFilledIcon />}
						/>
					</PricingCampaignActionsDropdown>
				)
			)}
		</div>
	)
}

function TableOptimzationGroupGroup({
	row,
}: {
	row: Row<PricingCampaignTableRow>
}) {
	const optimizations = useOptimizationsQuery()
	const groupsQuery = useOptimizationGroupsQuery<
		Record<string, OptimizationGroupModel>
	>({
		select: indexBy<OptimizationGroupModel>('id'),
	})
	const { showModal } = useModalStore()
	const group = groupsQuery.data?.[row.groupingValue as string]
	const optimization = group ? optimizations.data?.[group?.id] : undefined
	const name = group?.name || intl.get('global').d('Global')
	return (
		<div className='flex h-20 items-start gap-2 pt-2'>
			<Button
				variant='tertiary'
				size='small'
				onClick={row.getToggleExpandedHandler()}
				iconBefore={
					row.getIsExpanded() ? <CaretDownIcon /> : <CaretRightIcon />
				}
			/>
			<div className='flex flex-col gap-1'>
				<h4 className='font-bold'>{name}</h4>
				<div className='flex items-center gap-2 text-xs text-muted'>
					<span>
						{row.subRows.length} {intl.get('general_pc')}
					</span>
					<Separator orientation='vertical' className='h-4' />
					{group && (
						<>
							<span>
								{formatNumber(optimization?.total_products || 0)}{' '}
								{intl.get('general_products')}
							</span>
							<Separator orientation='vertical' className='h-4' />
						</>
					)}
					<LinkButton
						data-testid='pcs-priority-modal-trigger'
						onClick={() => {
							analytic.logEvent('settings: pricing campaigns: reorder: click', {
								location: 'table-group-header',
							})
							showModal('PRICING_CAMPAIGNS_PRIORITY', {
								optimizationGroupId: group?.id,
							})
						}}
					>
						<SortIcon /> {intl.get('pc.change_priority').d('Change priority')}
					</LinkButton>
					<Separator orientation='vertical' className='h-4' />
					<LinkButton
						data-testid='create-pc-modal-trigger'
						onClick={() => {
							analytic.logEvent(
								'settings: pricing campaigns: create pc: click',
								{
									location: 'table-group-header',
								}
							)
							showModal('CREATE_PRICING_CAMPAIGN_MODAL', {
								optimizationGroupId: group?.id,
							})
						}}
					>
						<PlusIcon /> {intl.get('general_create')}
					</LinkButton>
				</div>
			</div>
		</div>
	)
}
