import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { ExportCreateModel, ExportModel } from '@/generated'
import { client } from '@/network/client'

export function useCreateExportMutation(
	options?: UseMutationOptions<ExportModel, Error, ExportCreateModel>
) {
	return useMutation<ExportModel, Error, ExportCreateModel>({
		mutationFn: (body) => client.export.createExport(body),
		...(options || {}),
	})
}

export function useDeleteExportMutation(
	options?: UseMutationOptions<void, Error, string>
) {
	return useMutation<void, Error, string>({
		mutationFn: (exportId) => client.export.deleteExport(exportId),
		...(options || {}),
	})
}

export function useDownloadExportFileMutation(
	options?: UseMutationOptions<Blob, Error, string>
) {
	return useMutation<Blob, Error, string>({
		mutationFn: (exportId) =>
			client.export.downloadExportFile(exportId, {
				format: 'blob',
			}),
		...(options || {}),
	})
}
